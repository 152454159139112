
    <div>
  <el-dialog
  title="Warning"
  :visible.sync="openDrawer"
  append-to-body 
  :show-close="false"
  :show-header="false"
  width="40%"
  center
  class="dialog"
  >
  <div class="body">
    <img 
        src="./assets/alert.svg"
        alt="close"
     >
    <span>
    {{ message }}
</span>
  </div>

  <template v-slot:footer>
    <span
    class="dialog-footer"
    >
    <el-button @click="onCancelClick">Cancel</el-button>
    <el-button
     type="primary"
      @click="onDeleteConfirm"
      >
      Confirm
    </el-button>
    </span>
    </template>
</el-dialog>
    </div>
