
<div id="draw">
  <el-drawer
    :title="title"
    :visible="isOpen"
    :size="computedDrawerSize"
    :append-to-body="true"
    :show-close="!isMobileView"
    :direction="drawerDirection"
    :before-close="handleCloseDialog"
    :with-header="title ? true : false"
  >
    <template v-slot:title>
      <div v-if="isMobileView" class="title-container">
        <b-icon
          class="back-arrow"
          icon="arrow-left-short"
          @click="handleCloseDialog"
        />
        {{ title }}
      </div>
    </template>
    <div class="drawer">
      <slot name="header"></slot>
      <slot name="body"></slot>
      <slot name="footer"></slot>
      <div class="pinned-footer">
        <slot name="pinned-footer"></slot>
      </div>
    </div>
  </el-drawer>
</div>
