<template>
    <div class="main_div">
<img 
src="./assets/integration.png"
 alt="" 
>
<p class="info">
    Looks like you have not integrated your Email Client with the CRM.
</p>
    <p class="info">
        Click below to get started on the Integration.
    </p>
<el-button 
type="primary" 
@click="handleIntegrateButton"
>
Integrate With your Google Account.
</el-button>
    </div>
</template>

<script>
export default {
methods:{
    handleIntegrateButton(){
        this.$router.push("/integrations")
    }
}
}
</script>

<style scoped>
.main_div{
    display:flex;
    align-items:center;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    padding: 10px;
}
.info{
    font-size: 14px;
    font-weight: 400;
}
</style>