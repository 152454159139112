
  <div id="newProject">
    <el-dialog
      :visible="isNewProjectFormVisible"
      :close-on-click-modal="false"
      :show-close="!isIntegration"
      style="min-width: 800px;"
      title="Create a New Project"
      width="65%"
      @open="assignIntegratedProjectData"
      @close="onNewProjectDialogClose"
    >
      <div>
        <div class="new-project-container">
          <div class="fields-container">
            <el-form
              :model="projectData"
              size="mini"
              label-position="left"
              label-width="130px"
            >

              <el-form-item :label="flagForUS ? 'Project Address*' : 'Enter Location*'">
                <div class="searchBarWrapper">
                  <GoogleMapsAutocompleteInputVue
                    :isNew="true"
                    @input="showManualInputError = false"
                    @placesChanged="showManualInputError = false"
                  />
                </div>
                <p class="formErrors" v-show="showManualInputError">
                  Please select an address from the dropdown.
                </p>
              </el-form-item>

              <el-form-item label="Project Name*">
                <el-input
                  v-validate="projectNameValidation"
                  v-model="projectData.projectName"
                  :disabled="isIntegration"
                  name="Project Name"
                />
                <p class="formErrors" v-show="errors.first('Project Name')">
                  {{ errors.first("Project Name") }}
                </p>
              </el-form-item>

              <!-- <p class="formHeadings">Client Details</p> -->

              <el-form-item label="Client Name*">
                <el-input
                  v-validate="nameValidation"
                  v-model="projectData.clientName"
                  :disabled="isIntegration"
                  name="Name"
                />
                <p class="formErrors" v-show="errors.first('Name')">
                  {{ errors.first("Name") }}
                </p>
              </el-form-item>

              <el-form-item label="Client Number">
                <!-- <el-input
                 type="text" onkeypress="return ((event.charCode > 47 && 
	                event.charCode < 58) || (event.charCode === 40 || event.charCode === 41 || event.charCode === 43||event.charCode === 45))"
                  v-model="projectData.clientContactNumber"
                  :disabled="isIntegration"
                  name="Client Number"
                /> -->
                <!-- {{ projectData.clientContactNumber }} -->
                <vue-tel-input 
                  v-model="projectData.clientContactNumber"
                  :key="counter"
                  :defaultCountry="defaultCountryCode" 
                  :dropdownOptions="{showFlags:true,showDialCodeInSelection:true,showDialCodeInList:true}" 
                  :autoFormat="false"
                  :inputOptions="{maxlength:15}"
                  :mode="international"
                  :validCharactersOnly="true"
                  @country-changed="setCountryCode"
                  @validate="validateClientNumber"
                  @open="onDropdownOpen(true)"
                  @close="onDropdownOpen(false)"
                  name="Client Number"
                  >
                  <template v-slot:arrow-icon>
                    <span>{{ open ? '▲' : '▼' }}</span> 
                  </template>
                </vue-tel-input>
                <!-- <p class="formErrors" >
                  {{ errors.first("Client Number") }}
                </p> -->
                <!-- <p class="formErrors" v-if="projectData.clientContactNumber && !isMobileNumberValid && isAllowedToShowMobileValidation">
                  Please Enter Valid Mobile Number
                </p> -->
               
              </el-form-item>

              <el-form-item label="Client Email" v-if="flagForUS">
                <el-input
                  v-model="projectData.clientEmail"
                  :disabled="isIntegration"
                />
              </el-form-item>

              <el-form-item label="Email Id" v-else>
                <el-input
                  v-model="projectData.clientEmail"
                  :disabled="isIntegration"
                />
              </el-form-item>

              <el-form-item label="Display Address">
                <el-input
                  v-model="projectData.clientAddress"
                  :disabled="isIntegration"
                  placeholder="Address to show in proposal"
                />
              </el-form-item>

              <el-form-item label="Project Type">
                <div class="group_radio">
                  <label>
                    <input
                      type="radio"
                      name="radio"
                      value="residential"
                      v-model="projectType"
                    />
                    <div class="box">
                      <img src="../../../pages/consumptionProfile/assests/Group 1684.svg" class="commercialSvg" />
                      <span>Residential</span>
                    </div>
                  </label>
                  <label :class="{ 'disabled': disableCommercial }">
                    <input
                      type="radio"
                      name="radio"
                      value="commercial"
                      v-model="projectType"
                      :disabled="disableCommercial"
                    />
                    <div class="box">
                      <img src="../../../pages/consumptionProfile/assests/Group 1685.svg" class="commercialSvg" />
                      <span>Commercial</span>
                      <el-tooltip 
                        v-if="disableCommercial"
                        effect="dark"
                        content="Commercial Project is not available for permit package."
                        placement="top"> <i class="fas fa-info-circle info"></i></el-tooltip>
                    </div>
                  </label>
                </div>
              </el-form-item>
              <el-form-item
                v-if="PLANS_HAVING_SMALL_AND_MEDIUM.includes(userTier)"
                v-show="isAccountSubscribed"
                label="Project Size"
              >
                <el-tooltip
                  :disabled="availableProjectSizes.small"
                  :content="TOOLTIP_CONTENT_QUOTA_EXHAUSTED"
                  effect="light"
                  placement="bottom"
                >
                  <el-radio
                    v-model="quotaType"
                    :disabled="!availableProjectSizes.small"
                    :label="QUOTA_TYPE.SMALL"
                  >
                    Small (&#60; {{ QUOTA_TYPES_DC_CAP_SIZE.SMALL }} kW)
                  </el-radio>
                </el-tooltip>
                <el-tooltip
                  :content="getMediumTooltipContent"
                  effect="light"
                  placement="bottom"
                >
                  <el-radio
                    v-model="quotaType"
                    :disabled="!availableProjectSizes.medium"
                    :label="QUOTA_TYPE.MEDIUM"
                  >
                    Medium (&#60; {{ QUOTA_TYPES_DC_CAP_SIZE.MEDIUM }} kW)
                  </el-radio>
                </el-tooltip>
              </el-form-item>
            </el-form>
          </div>

          <div class="map-parent-container">
            <!-- <p class="formHeadings"> Mark Location </p> -->
            <newProjectMapSelector
              class="map-container"
              :geo-location="geoLocation"
              :place="placeForMap"
              :isSelfDesignPopup="isSelfDesignPopup"
              :totalPayableCreditsNonUS="totalPayableCreditsNonUS"
              :totalPayableCreditsUS="totalPayableCreditsUS"
              :selfDesigningInfo="selfDesigningInfo"
              :selectedAddOnChecked="selectedAddOnChecked"
              @change="showManualInputError = false"
            />
            <p class="map-prompt-text">Zoom in to fill the project area.</p>
          </div>
          <div class="third-container">
            <div v-if="isSelfDesignPopup">
              <div class="creditDetailsOne">
                <p class="detailsLight">Credit Balance</p>
                <p class="detailsLight">{{totalCredits}}</p>
              </div>
              <div class="creditDetailsTwo">
                <p class="details">Total Payable Credits:</p>
                <p class="details" v-if="selfDesigningInfo.input_dropdown.length > 0">{{totalPayableCreditsNonUS.credits}}</p>
                <p class="details" v-if="selfDesigningInfo.input_checkbox.length > 0">{{totalPayableCreditsUS}}</p>
              </div>
            </div>
            <div class="sharingForm" v-if="!(flagForUS || isSelfDesignPopup || isTataOrg || isHavellsOrg)">
              <p class="shareFormHeading">
                Share Project
              </p>
              <el-switch
                v-model="isPublicShared"
                style="display: block; text-align: right; margin-top: -34px"
              />
              <div class="disclaimer">
                This project is
                <span v-show="!isPublicShared"> not </span> visible to everyone
                from your organisation.
              </div>

              <div
                v-show="!isPublicShared"
                label=""
                style="margin-bottom: 0px;"
              >
                <div style="display: flex; line-height: 0px; align-items: baseline;">
                  <infiniteScrollUsers
                    :user.sync="selectedUser"
                    style="flex-grow: 1"
                  />
                  <button
                    :disabled="
                      Object.entries(selectedUser).length === 0 &&
                        selectedUser.constructor === Object
                    "
                    class="button-confirm"
                    style="height: 48px !important;
                            margin: 0;
                            width: 70px;
                            border-radius: 0px 4px 4px 0px !important;
                            padding: 0 15px;"
                    @click="addUserToSharerList"
                  >
                    <span>Add</span>
                  </button>
                </div>
              </div>

              <div
                v-show="usersHavingAccessList"
                style="height: 17vh; padding: 10px 0 10px 10px; text-align: left"
              >
                <VuePerfectScrollbar class="scroll-area-users-list">
                  <el-row
                    v-for="(persons, index) in usersSharedWithList"
                    :key="index"
                    style="text-align: left; padding-top: 1.5vh"
                  >
                    <el-col :span="15" class="verticalAligner">
                      {{ persons.first_name }}
                      {{ persons.last_name }}
                    </el-col>
                    <el-col :span="6" style="text-align: right">
                      <el-select
                        v-model="persons.permission"
                        class="permissions"
                        popper-class="lightDropdown"
                      >
                        <el-option
                          v-for="(options,
                          indexPermission) in permissionsAvailable"
                          :key="indexPermission"
                          :value="options.value"
                          :label="options.label"
                        />
                      </el-select>
                    </el-col>
                    <el-col
                      :span="3"
                      style="text-align: right; line-height: 15px"
                    >
                      <i
                        class="el-icon-close"
                        style="cursor: pointer;
                        padding: 0px 18px 0 0;
                        font-size: 10px"
                        @click="removeUserFromSharerList(index)"
                      />
                    </el-col>
                  </el-row>
                </VuePerfectScrollbar>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template v-slot:footer>
        <span :class="[isSelfDesignPopup && !isOnProjectSummaryPage ? 'footer' : '']">
          <el-button v-if="isSelfDesignPopup && !isOnProjectSummaryPage" class="backBtn" @click="isNewProjectFormVisible=false">Back</el-button>
          <button
            id="newProjectConfirmButton"
            :disabled="errors.items.length > 0 || isProjectGettingCreated"
            class="button-confirm create-button"
            style="width: 74px;"
            @click="confirmOnClickAction()"
          >
            <span v-show="!isProjectGettingCreated">{{isSelfDesignPopup  ? "Pay & Create Design":"Create Project"}}</span>
            <i v-show="isProjectGettingCreated" class="el-icon-loading" />
          </button>
        </span>
      </template>
    </el-dialog>
  </div>
