<template>
  <div :class="showBorder ? 'borderSelector':'selector'" id="menu">
    <div v-for="(menu, inx) in menuItems" :key="inx" class="el-menu-demo">
      <span class="icons" v-if="!isDrillDown">
        <img
          v-if="menu.icon == 'sort'"
          src="../../assets/sort.svg"
          alt="alter"
          v-show="!isMobileView"
        />

        <img
          v-if="menu.icon == 'filter'"
          src="../../../leadManagement/components/assets/filter_alt.svg"
          v-show="!isMobileView"
          alt="alter"
        />
      </span>
      <el-menu
        :collapse="openCollapse"
        :menu-trigger="openMenu"
        :default-active="'1'"
        mode="horizontal"
        @select="menu.callback"
        text-color="#222"
        active-text-color="#222"
        :label="menu.title"
        :value="menu.value"
        :style="{ width: 'fit-content' }"

      >
        <el-submenu
          index="1-1 "
          :class="isDrillDown ? 'submenu-with-border' : ''"
          :style="getCollapseStyles(menu)"
          popper-class="lead-filter-sub-menu"
        >
          <template v-slot:title>
            {{ !isMobileView ? menu.title : '' }}
          <img v-if="menu.icon == 'sort'"
            src="../../assets/sort.svg"
            alt="alter"
            v-show="isMobileView"
          />
          <img
            v-if="menu.icon == 'filter'"
            src="../../../leadManagement/components/assets/filter_alt.svg"
            alt="alter"
            v-show="isMobileView"
          />
        </template>
          <div v-if="menu.singleLevel" class="title-container">
            <el-menu-item
              v-for="item in menu.options"
              :default-active="'order_status'"
              :key="item.value"
              :index="item.value"
              :label="item.label"
              :value="item.value"
            >
              {{ item.label }}
            </el-menu-item>
          </div>
          <div v-if="!menu.singleLevel" 
            @mouseenter="HoverEffect">
            <el-submenu
              v-for="item in menu.options"
              :key="item.value"
              :index="item.label"
              :label="item.label"
              :value="item.value"
              class="menu-sub-items"
            >
              <template v-slot:title>
                <span class="filter-sub-menu-title">
                  {{ item.value }}
                </span>
              <b-icon icon="chevron-right" class="chevron-right" v-show="isMobileView"/>
              </template>
              <template
                v-if="item.type === 'checkbox' && item.value == 'Lead Source'"
              >
                <div class="checkBoxborder">
                  <el-checkbox-group
                    class="checkBox"
                    v-model="selectedLeadCheckboxes"
                    @change="updateLeadCheckbox"
                  >
                  <div class="checkbox-container">
                    <el-checkbox
                      v-for="subItem in item.subOptions"
                      :key="subItem.value"
                      :index="subItem.label"
                      :label="subItem.label"
                      :value="subItem.value"
                    >
                    <span class="cb-label-mobile">
                      {{ subItem.value }}
                    </span>
                    </el-checkbox>
                  </div>
                    <!-- <el-button @click="updateLeadCheckbox" class="updateButton"
                      :disabled="selectedLeadCheckboxes.length === 0" type="primary">Update</el-button> -->
                  </el-checkbox-group>
                </div>
              </template>
              <template
                v-if="item.type === 'checkbox' && item.value == 'Region'"
              >
                <div class="checkBoxborder">
                  <el-checkbox-group
                    class="checkBox"
                    v-model="selectedRegion"
                    @change="updateRegionCheckbox"
                  >
                  <div class="checkbox-container">
                    <el-checkbox
                      v-for="subItem in item.subOptions"
                      :key="subItem.value"
                      :index="subItem.label"
                      :label="subItem.label"
                      :value="subItem.value"
                    >
                    <span class="cb-label-mobile">
                      {{ subItem.value }}
                    </span>
                    </el-checkbox>
                  </div>
                    <!-- <el-button @click="updateLeadCheckbox" class="updateButton"
                      :disabled="selectedLeadCheckboxes.length === 0" type="primary">Update</el-button> -->
                  </el-checkbox-group>
                </div>
              </template>
              <template v-if="item.type === 'checkbox' && item.value == 'Tags'">
                <div class="checkBoxborder">
                  <el-checkbox-group
                    class="checkBox"
                    v-model="selectedTagCheckboxes"
                    @change="updateTagCheckbox"
                  >
                    <div class="checkbox-container">
                      <el-checkbox
                        v-for="subItem in item.subOptions"
                        :key="subItem.value"
                        :index="subItem.label"
                        :label="subItem.label"
                        :value="subItem.value"
                      >
                        {{ subItem.value }}
                      </el-checkbox>
                    </div>
                  </el-checkbox-group>
                </div>
              </template>
              <template v-if="item.type === 'checkbox' && item.value == 'Lead Owner'">
                <div>
                  <div class="search-bar">
                    <el-input
                      v-model="search"
                      placeholder="Search"
                      @change="searchUsers"
                    >
                    <template v-slot:prefix>
                        <b-icon style="margin-top:15px; margin-left:10px;font-size:16px;color:#777" icon="search"></b-icon>
                    </template>
                    </el-input>
                  </div>
                  <div class="checkBoxborder">
                    <el-checkbox-group
                      class="checkBox2"
                      v-model="selectedUserCheckboxes"
                      @change="updateUserCheckbox"
                    >
                      <div
                        class="checkbox-container2"
                        v-infinite-scroll="loadMore2"
                        infinite-scroll-disabled="busy"
                        infinite-scroll-distance="10"
                      >
                      <el-checkbox
                        v-for="subItem in item.subOptions"
                        :key="subItem.value"
                        :index="subItem.value"
                        :label="subItem.value"
                        :value="subItem.value"
                        >
                          <el-avatar
                          style="margin-left:10px"
                            :size="28"
                            :style="getSelectionAvatarStyle(subItem.label)"
                          >
                            <span>{{ generateInitials(subItem.label) }}</span>
                          </el-avatar>
                          <span>{{ subItem.label }}</span>
                        </el-checkbox>
                      </div>
                    </el-checkbox-group>
                  </div>
                </div>
              </template>
              <template v-if="item.type === 'checkbox' && item.value == 'Products'">
                <div 
                style="max-height: 330px; overflow: scroll;"
                >
                  <div class="productHeadingText">
                    <span >PowerGazebo with Inverter</span>
                  </div>
                  <el-checkbox-group
                    class="checkBox"
                    v-model="selectedPowerGazeboProductsWithInverter"
                    @change="handleSelectedProductsUpdated"
                    :label="'sda'"
                    >
                      <div class="checkbox-container3">
                        <el-checkbox
                          v-for="subItem in powerGazeboProductsWithInverter"
                          :key="subItem.id"
                          :label="subItem.id"
                        >
                          {{ subItem.sku_name }}
                        </el-checkbox>
                      </div>
                    </el-checkbox-group>
                    <div class="productHeadingText">
                      <span >PowerGazebo without Inverter</span>
                    </div>
                    <el-checkbox-group
                      class="checkBox"
                      v-model="selectedPowerGazeboProductsWithoutInverter"
                      @change="handleSelectedProductsUpdated"
                      >
                      <div class="checkbox-container3">
                        <el-checkbox
                          v-for="subItem in powerGazeboProductsWithoutInverter"
                          :key="subItem.id"
                          :label="subItem.id"
                        >
                          {{ subItem.sku_name }}
                        </el-checkbox>
                        </div>  
                    </el-checkbox-group>
                    <div class="productHeadingText">
                      <span >PowerPatio with Inverter</span>
                    </div>
                    <el-checkbox-group
                      class="checkBox"
                      v-model="selectedPowerPatioProductsWithInverter"
                      @change="handleSelectedProductsUpdated"
                      >
                      <div class="checkbox-container3">
                        <el-checkbox
                          v-for="subItem in powerPatioProductsWithInverter"
                          :key="subItem.id"
                          :label="subItem.id"
                        >
                          {{ subItem.sku_name }}
                        </el-checkbox>
                      </div> 
                    </el-checkbox-group>
                    <div class="productHeadingText">
                      <span >PowerPatio without Inverter</span>
                    </div>
                    <el-checkbox-group
                      class="checkBox"
                      v-model="selectedPowerPatioProductsWithoutInverter"
                      @change="handleSelectedProductsUpdated"
                      >
                      <div class="checkbox-container3">
                        <el-checkbox
                          v-for="subItem in powerPatioProductsWithoutInverter"
                          :key="subItem.id"
                          :label="subItem.id"
                        >
                          {{ subItem.sku_name }}
                        </el-checkbox>
                      </div> 
                    </el-checkbox-group>
          
                </div>
              </template>
              <template v-if="item.type === 'checkbox' && (item.value==='Accessory'||item.value==='Accessory SKU')">
                <div class="checkBoxborder">
                  <el-checkbox-group
                    class="checkBox"
                    v-model="selectedAccessories"
                    @change="updateCatalogStatusbox(item)"
                  >
                    <div class="checkbox-container">
                      <el-checkbox
                        v-for="subItem in item.subOptions"
                        :key="subItem.value"
                        :index="subItem.label"
                        :label="subItem.label"
                        :value="subItem.value"
                      >
                        {{ subItem.value }}
                      </el-checkbox>
                    </div>
                    <!-- <el-button @click="updateTagCheckbox" class="updateButton fixed-button"
                      :disabled="selectedTagCheckboxes.length === 0" type="primary">Update</el-button> -->
                  </el-checkbox-group>
                </div>
              </template>
              <template v-if="item.type === 'checkbox' && (item.value==='Product'||item.value==='Product SKU')">
                <div class="checkBoxborder">
                  <el-checkbox-group
                    class="checkBox"
                    v-model="selectedProducts"
                    @change="updateCatalogProducts(item)"
                  >
                    <div class="checkbox-container">
                      <el-checkbox
                        v-for="subItem in item.subOptions"
                        :key="subItem.value"
                        :index="subItem.label"
                        :label="subItem.label"
                        :value="subItem.value"
                       
                      >
                        {{ subItem.value }}
                      </el-checkbox>
                    </div>
                    <!-- <el-button @click="updateTagCheckbox" class="updateButton fixed-button"
                      :disabled="selectedTagCheckboxes.length === 0" type="primary">Update</el-button> -->
                  </el-checkbox-group>
                </div>
              </template>
              <template
                v-if="item.type === 'checkbox' && item.value == 'Status'"
              >
                <div class="checkBoxborder">
                  <el-checkbox-group
                    class="checkBox"
                    v-model="selectedStatusCheckboxes"
                    @change="updateStatusCheckbox"
                  >
                    <div class="checkbox-container">
                      <el-checkbox
                        v-for="subItem in item.subOptions"
                        :key="subItem.value"
                        :index="subItem.label"
                        :label="subItem.label"
                        :value="subItem.value"
                      >
                        {{ subItem.value }}
                      </el-checkbox>
                    </div>
                  </el-checkbox-group>
                </div>
              </template>
              <template
                v-else-if="
                  item.type === 'date' && item.label == 'Expected closure'
                "
              >
                <div class="dateContainer">
                  <!-- <el-date-picker
                    v-model="selectedDate"
                    type="daterange"
                    range-separator="-"
                    @focus="clickEvent"
                    @change="updateDate"
                    start-placeholder="From Date"
                    end-placeholder="To Date"
                  >
                  </el-date-picker> -->
                  <el-date-picker   @focus="clickEvent"
                    class="datePick"
                    v-model="selectedFromDate"
                    type="date"
                    :picker-options="datePickerOptions"
                    placeholder="After Date"
                  >
                  </el-date-picker>
                  <el-date-picker   @focus="clickEvent"
                    class="datePick"
                    v-model="selectedToDate"
                    type="date"
                    :picker-options="datePickerOptions"
                    placeholder="Before Date"
                  >
                  </el-date-picker>
                  <el-button @click="updateDate" :disabled="selectedFromDate==='' || selectedToDate==''" type="primary">Update</el-button>
                  <!-- <button class="updateButton">Update</button> -->
                </div>
              </template>
              <template
                v-else-if="item.type === 'date' && item.value == 'Start Date'"
              >
                <div class="dateContainer" >
                  <!-- <el-date-picker
                  ref="datepicker"
                    v-model="selectedStartDate"
                    type="daterange"
                    range-separator="-"
                    @focus="clickEvent"
                    @change="updateStartDate"
                    start-placeholder="From Date"
                    end-placeholder="To Date"
                  >
                  </el-date-picker> -->
                  <el-date-picker   @focus="clickEvent"
                    class="datePick"
                    v-model="selectedStartDateFrom"
                    type="date"
                    :picker-options="datePickerOptions"
                    placeholder="After Date"
                  >
                  </el-date-picker>
                  <el-date-picker   @focus="clickEvent"
                    class="datePick"
                    v-model="selectedStartDateTo"
                    type="date"
                    :picker-options="datePickerOptions"
                    placeholder="Before Date"
                  >
                  </el-date-picker>
                  <el-button @click="updateStartDate" :disabled="selectedStartDateFrom==='' || selectedStartDateTo==''" type="primary">Update</el-button>
                </div>
              </template>
              <template
                v-else-if="item.type === 'date' && item.value == 'Due Date'"
              >
                <div class="dateContainer">
                  <!-- <el-date-picker
                    v-model="selectedDueDate"
                    type="daterange"
                    range-separator="-"
                    @focus="clickEvent"
                    @change="updateDueDate"
                    start-placeholder="From Date"
                    end-placeholder="To Date"
                  >
                  </el-date-picker> -->
                  <el-date-picker   @focus="clickEvent"
                    class="datePick"
                    v-model="selectedDueDateFrom"
                    type="date"
                    :picker-options="datePickerOptions"
                    placeholder="After Date"
                  >
                  </el-date-picker>
                  <el-date-picker   @focus="clickEvent"
                    class="datePick"
                    v-model="selectedDueDateTo"
                    type="date"
                    :picker-options="datePickerOptions"
                    placeholder="Before Date"
                  >
                  </el-date-picker>
                  <el-button @click="updateDueDate" :disabled="selectedDueDateFrom==='' || selectedDueDateTo==''" type="primary">Update</el-button>
                </div>
              </template>
              <template v-else-if="item.type === 'dropdown'">
                <div class="countryDropContainer">
                  <el-select
                    class="countryselect"
                    v-model="selectedOption"
                    placeholder="USA"
                  >
                    <el-option
                      v-for="subItem in item.subMenu"
                      :key="subItem.value"
                      :label="subItem.label"
                      :value="subItem.value"
                    >
                    </el-option>
                  </el-select>
                  <el-select
                    class="countryselect"
                    v-model="selectedOption"
                    placeholder="California"
                  >
                    <el-option
                      v-for="subItem in item.subMenu"
                      :key="subItem.value"
                      :label="subItem.label"
                      :value="subItem.value"
                    >
                    </el-option>
                  </el-select>
                  <el-select
                    class="countryselect"
                    v-model="selectedOption"
                    placeholder="90001"
                  >
                    <el-option
                      v-for="subItem in item.subMenu"
                      :key="subItem.value"
                      :label="subItem.label"
                      :value="subItem.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </template>
              <template v-else-if="item.type === 'input' && item.label=='DC Capacity'">
                <div class="inputContainer">
                  <div style="display:flex">
                    <label>
                      <input
                        class="menuInput"
                        v-model="moreValue"
                        type="number"
                        placeholder="More Than or Equal to"
                      />
                      <span class="labelText2"> kW</span>
                      <span class="labelText" style="margin-left:5px" > - </span>
                    </label>
                    <label>
                      <input
                        class="menuInput"
                        v-model="lessValue"
                        type="number"
                        placeholder="Less Than or Equal to"
                      />
                      <span class="labelText2"> kW</span>
                    </label>
                   
                  </div>
                  <el-button
                    class="updateButton"
                    @click="UpdateDCvalue"
                    :disabled="lessValue === '' || moreValue === ''"
                    type="primary"
                    >Update</el-button
                  >
                </div>
              </template>
              <template v-else-if="item.type === 'input' && item.label=='probability'">
                <div class="inputContainer">
                  <div style="display:flex">
                    <label>
                      <input
                        class="menuInput"
                        v-model="maxProbability"
                        type="number"
                        :min="0" :max="100" @input="validatemaxInput"
                        placeholder="More Than or Equal to"
                      />
                      <span class="labelText2"> %</span>
                      <span class="labelText" style="margin-left:5px" > - </span>
                    </label>
                    <label>
                      <input
                        class="menuInput"
                        v-model="minProbability"
                        type="number"
                        :min="0" :max="100" @input="validateminInput"
                        placeholder="Less Than or Equal to"
                      />
                      <span class="labelText2"> %</span>
                    </label>
                  </div>
                 
                  <el-button
                    class="updateButton"
                    @click="updateProbability"
                    :disabled="minProbability === '' || maxProbability === ''"
                    type="primary"
                    >Update</el-button
                  >
                </div>
              </template>
              <template v-else-if="item.type === 'collaborators'">
                <div>
                  <div class="search-bar">
                    <el-input
                      v-model="search"
                      placeholder="Search"
                      @change="searchUsers"
                    >
                    <template v-slot:prefix>
                        <b-icon style="margin-top:15px; margin-left:10px;font-size:16px;color:#777" icon="search"></b-icon>
                    </template>
                    </el-input>
                  </div>
                  <div class="checkBoxborder">
                    <el-checkbox-group
                      class="checkBox2"
                      v-model="selectedValue"
                      @change="updateCollaborators"
                    >
                      <div
                        class="checkbox-container2"
                        v-infinite-scroll="loadMore2"
                        infinite-scroll-disabled="busy"
                        infinite-scroll-distance="10"
                      >
                        <el-checkbox
                          v-for="avatar in item.subOptions"
                          :key="avatar.id"
                          :index="avatar.id"
                          :label="avatar.id"
                          :value="avatar.name"
                        >
                          <el-avatar
                          style="margin-left:10px"
                            :size="28"
                            :style="getSelectionAvatarStyle(avatar.name)"
                          >
                            <span>{{ generateInitials(avatar.name) }}</span>
                          </el-avatar>
                          <span>{{ avatar.name }}</span>
                        </el-checkbox>
                      </div>
                    </el-checkbox-group>
                  </div>
                </div>
              </template>
              <template v-else-if="item.type === 'homeowner'">
                <div>
                  <div class="search-bar">
                    <el-input
                      v-model="searchTerm"
                      placeholder="Search"
                      @change="searchLeads"
                    >
                    <template v-slot:prefix>
                      <b-icon style="margin-top:15px; margin-left:10px;font-size:16px;color:#777" icon="search"></b-icon>
                    </template>
                  </el-input>
                  </div>
                  <div class="checkBoxborder">
                    <el-checkbox-group
                      class="checkBox2"
                      v-model="homeOwnerSelect"
                      @change="updateHomeownerCheckbox"
                    >
                      <div
                        class="checkbox-container2"
                        v-infinite-scroll="loadMore"
                        infinite-scroll-disabled="busy"
                        infinite-scroll-distance="10"
                      >
                        <el-checkbox
                          v-for="subItem in item.subOptions"
                          :key="subItem.id"
                          :index="subItem.id"
                          :label="subItem.id"
                          :value="subItem.name"
                        >
                          <el-avatar
                          style="margin-left:10px;"
                            :size="28"
                            :style="getSelectionAvatarStyle(subItem.name)"
                          >
                            <span>{{ generateInitials(subItem.name) }}</span>
                          </el-avatar>
                          <span>{{ subItem.name }}</span>
                        </el-checkbox>
                      </div>
                    </el-checkbox-group>
                  </div>
                </div>
              </template>
              <template v-else-if="item.type === 'radio' && item.label == 'priority'">
                <div class="radiogroup">
                  <el-radio-group
                    v-model="selectedRadioGroup"
                    @change="UpdatePriorityValue"
                  >
                    <div
                      v-for="subItem in item.subOptions"
                      :key="subItem.value"
                    >
                      <el-radio :label="subItem.label" style="color: #222;">
                        {{ subItem.value }}
                      </el-radio>
                    </div>
                  </el-radio-group>
                </div>
              </template>
              <template v-else-if="item.type === 'radio' && item.label == 'proposal_status'">
                <div class="radiogroup">
                  <el-radio-group
                    v-model="selectedProposalRadioGroup"
                    @change="updateProposalStatusRadio"
                  >
                    <div
                      v-for="subItem in item.subOptions"
                      :key="subItem.value"
                    >
                      <el-radio :label="subItem.label" style="color: #222;">
                        {{ subItem.value }}
                      </el-radio>
                    </div>
                  </el-radio-group>
                </div>
              </template>

              <template v-else-if="item.type === '$input'">
                <div class="inputContainer">
                  <div style="display:flex">
                    <label>
                      <span class="labelText">$</span>
                      <input
                        class="menuInput"
                        v-model="moreCost"
                        type="number"
                        placeholder="More Than or Equal to"
                      />
                    </label>
                    <label>
                      <span class="labelText" style="margin-left:5px" > - </span>
                      <span class="labelText">$</span>
                      <input
                        class="menuInput"
                        v-model="lessCost"
                        type="number"
                        placeholder="Less Than or Equal to"
                      />
                    </label>
                   
                  </div>
                    <el-button
                    @click="updateCost"
                    class="updateButton"
                    :disabled="lessCost === '' || moreCost === '' || lessCost === 0 || moreCost ===0"
                    type="primary"
                    >Update</el-button
                  >
                  
                </div>
              </template>
              <!-- <template v-else-if="!item.type">
                <el-menu-item
                  v-for="subItem in item.subOptions"
                  :key="subItem.value"
                  :index="subItem.label"
                  :label="subItem.label"
                  :value="subItem.value"
                  :multiple="true"
                >
                  {{ subItem.value }}
                </el-menu-item>
              </template> -->
              <div
                v-if="
                  item.type !== 'checkbox' &&
                  item.type !== 'radio' &&
                  item.type !== 'homeowner' &&
                  item.type !== 'collaborators' 
                "
                class="checkbox-container"
              >
                <el-menu-item
                  v-for="subItem in item.subOptions"
                  :key="subItem.value"
                  :index="subItem.index ? subItem.index : subItem.label"
                  :label="subItem.label"
                  :value="subItem.value"
                  :multiple="true"
                >
                  {{ subItem.value
                  }}<span
                    v-if="subItem.competence"
                    :style="{ paddingLeft: '1rem' }"
                  >
                    <el-tag
                      v-for="(comp, indx) in subItem.competence"
                      :key="indx"
                      class="tag"
                      :color="handleColorLightning(handleColor(comp), 80)"
                      :style="{
                        color: `${handleColor(comp)}`,
                        marginLeft: '0.2rem',
                      }"
                      type="red"
                      size="mini"
                    >
                      {{ comp }}
                    </el-tag>
                    
                  </span>
                </el-menu-item>
                <el-submenu index="4" v-if="item.type === 'text' && item.value === 'Time Period'"  >
                  <template v-slot:title >Custom range</template>
                  <div class="dateContainer">

                    <el-date-picker   @focus="clickEvent"
                      class="datePick"
                      v-model="selectedCreatedAtFromDate"
                      type="date"
                      :picker-options="datePickerOptionsFrom"
                      placeholder="After Date"
                    >
                    </el-date-picker>
                    <el-date-picker   @focus="clickEvent"
                      class="datePick"
                      v-model="selectedCreatedAtToDate"
                      type="date"
                      :picker-options="datePickerOptionsTo"
                      placeholder="Before Date"
                    >
                    </el-date-picker>
                    <el-button @click="updateSelectedCreatedDates" :disabled="selectedCreatedAtToDate==='' || selectedCreatedAtFromDate==''" type="primary">Update</el-button>
                  </div>
                </el-submenu>
              </div>
            </el-submenu>
          </div>
        </el-submenu>
      </el-menu>
    </div>
  </div>
</template>

<script>
import {
  getLightenedColor,
  handleCompetenceColor,
} from "../../../../utils/colorGenerator.js";
import { useUiStore } from "../../../../stores/ui";
import { mapState } from "pinia";
import { generateAvatarColor } from "../../../../utils";
import API from "@/services/api/";

export default {
  emits: [
  "searchUsers",
  "loadMore",  
  "searchLeads",
  "loadMore2",  
  "ExpectedClosure",  
  "customCreatedDateUpdate",
  "due-Date",
  "start-Date",
  "leadCheckbox",     
  "regionCheckbox",
  "collaboratorCheckbox",
  "homeownerCheckbox",
  "tagCheckbox",
  "userCheckbox",
  "selectedProductsUpdated",
  "statusCheckbox",
  "catalog_product_accessory",
  "priorityCheck",
  "proposalStatusRadio",
  "DCValue",
  "probabilityValue",
  "systemCost",
  "update:clearall",
  "update:startDateClear",
  "update:dueDateClear",
  "update:status",
  "update:homeOwn",
  "update:colloabo",
  "update:prior",
  "update:clearAll",
  "update:dateClear",
  "update:DCvalueClear",
  "update:probabilityClear",
  "update:leadSourceClear",
  "update:productListClear",
  "update:regionClear",
  "update:proposalStatusClear",
  "update:accessoryClear",
  "update:productClear",
  "update:tagsClear",
  "update:usersClear",
  ],
  data() {
    return {
      datePickerOptionsFrom: {
        disabledDate: (time) => {
          const today = new Date();
          today.setHours(0, 0, 0, 0);
          return time.getTime() > today.getTime();
        }
      },
      datePickerOptionsTo: {
        disabledDate: (time) => {
          if (this.selectedCreatedAtFromDate) {
            const fromDate = new Date(this.selectedCreatedAtFromDate);
            fromDate.setDate(fromDate.getDate());
            fromDate.setHours(0, 0, 0, 0);
            return time.getTime() < fromDate.getTime() || time.getTime() > Date.now();
          }
          return time.getTime() > Date.now();
        }
      },
      selectionAvatars: [
        { id: 1, name: "Kaarn Kaarn" },
        { id: 2, name: "Charan M1231" },
        { id: 3, name: "Qdasdsa Adasdasd" },
        { id: 4, name: "Adasd Adsad" },
        { id: 5, name: "Cdasdsaads Bsaddsa" },
        { id: 6, name: "Sasdsadsda Sdasdsadas" },
        { id: 7, name: "Ussssss Asdadsa" },
      ],
      startDate: [],
      dueDate: [],
      openMenu: "hover",
      openCollapse:false,
      busy: false,
      searchTerm: "",
      search: "",
      selectedProposalRadioGroup: "",
      homeOwnerSelect: [],
      selectedLeadCheckboxes: [],
      selectedRegion:[],
      selectedValue: [],
      selectedTagCheckboxes: [],
      selectedUserCheckboxes:[],
      selectedAccessories:[],
      selectedProducts:[],
      selectedStatusCheckboxes: [],
      selectedRadioGroup: "",
      datePickerOptions: {},
      selectedFromDate:'' , 
      selectedToDate:'',
      selectedCreatedAtFromDate:'',
      selectedCreatedAtToDate:'',
      selectedStartDateFrom: "",
      selectedStartDateTo:'',
      selectedDueDateFrom: "",
      selectedDueDateTo:'',
      dueStartDate: "",
      dueEndDate: "",
      minProbability:'',
      maxProbability:'',
      probabilityValue:[],
      lessValue: "",
      DCValue: [],
      cost: [],
      moreValue: "",
      date: [],
      lessCost: "",
      moreCost: "",
      selectedOption: "",
      activeBackgroundColor: "#FF0000",
      selectedSubMenuItem: "",
      powerGazeboProductsWithInverter: [],
      powerGazeboProductsWithoutInverter: [],
      powerPatioProductsWithInverter: [],
      powerPatioProductsWithoutInverter: [],
      selectedPowerGazeboProductsWithInverter: [],
      selectedPowerGazeboProductsWithoutInverter: [],
      selectedPowerPatioProductsWithInverter: [],
      selectedPowerPatioProductsWithoutInverter: [],
    };
  },
  props: {
    menuItems: {
      type: [Array,String],
    },
    showBorder:{
      type: Boolean,
      default:false,
    },
    clearAll: {
      type: Boolean,
    },
    status: {
      type: Boolean,
    },
    homeOwn: {
      type: Boolean,
    },
    prior: {
      type: Boolean,
    },
    colloabo: {
      type: Boolean,
    },
    startDateClear:{
      type:Boolean,
    },
    dueDateClear:{
      type:Boolean,
    },
    tagsClear: {
      type: Boolean,
    },
    usersClear:{
      type: Boolean,
    },
    regionClear: {
      type: Boolean,
    },
    proposalStatusClear: {
      type: Boolean,
    },
    leadSourceClear: {
      type: Boolean,
    },
    productListClear:{
      type: Boolean,
    },
    DCvalueClear: {
      type: Boolean,
    },
    dateClear: {
      type: Boolean,
    },
    costClear: {
      type: Boolean,
    },
    accessoryClear:{
      type: Boolean,
    },
    probabilityClear: {
      type: Boolean,
    },
    clearall: {
      type: Boolean,
    },

    isDrillDown: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {
    this.colorUL();
    this.getProductData();
  },
  computed: {
    ...mapState(useUiStore, {
      isMobileView: state => state.isMobileView
    }),
  },
  methods: {
    handleSelect(key, keyPath) {
        console.log(key, keyPath);
      },
    async getProductData(){
      // const patioResponse = await API.POWERMODELS.GET_PATIOS();
      // const gazeboResponse = await API.POWERMODELS.GET_GAZEBOS();
      const arkaSolutionModels = await API.POWERMODELS.GET_ARKA_SOLUTION_MODELS();
      arkaSolutionModels.data.PowerPatio.without_inverter.forEach(data => {
          this.powerPatioProductsWithoutInverter.push({
              ...data,
              with_inverter: false,
          });
      });
      arkaSolutionModels.data.PowerPatio.with_inverter.forEach(data => {
        this.powerPatioProductsWithInverter.push({
            ...data,
            with_inverter: true,
        });
      });
      arkaSolutionModels.data.PowerGazebo.without_inverter.forEach(data => {
          this.powerGazeboProductsWithoutInverter.push({
              ...data,
              with_inverter: false,
          });
      });
      arkaSolutionModels.data.PowerGazebo.with_inverter.forEach(data => {
          this.powerGazeboProductsWithInverter.push({
              ...data,
              is_inverter: true,
          });
      });
    },
    validateminInput() {
      this.minProbability = this.minProbability.replace(/^0|[^0-9]/g, ""); 
      const input = this.minProbability;
      if (input < 0) {
        this.minProbability = 0;
      } else if (input > 100) {
        this.minProbability = 100;
      }
      
    },
    validatemaxInput(){
      this.maxProbability = this.maxProbability.replace(/^0|[^0-9]/g, "");
      const maxInput = this.maxProbability;
      if (maxInput < 0) {
        this.maxProbability = 0;
      } else if (maxInput > 100) {
        this.maxProbability = 100;
      }
    },
    searchLeads(val) {
      this.$emit("searchLeads", val);
    },
    searchUsers(val) {
      this.$emit("searchUsers", val);
    },
    loadMore() {
      this.$emit("loadMore");
    },
    loadMore2() {
      this.$emit("loadMore2");
    },
    generateInitials(name) {
      if (!name || name.trim().length === 0) {
        return "N/A"; // Return empty string for blank names
      }

      const names = name.trim().split(" ");
      const initials = names.map((n) => n.charAt(0).toUpperCase());

      if (initials.length === 1) {
        return initials[0]; // Return single initial if only one name is available
      } else {
        return initials[0] + initials[initials.length - 1]; // Return first and last initials for multiple names
      }
    },

  getCollapseStyles(data){
    if(data.title==="Column Type"){
      if(this.$props.isDrillDown){
        return{
        width:"150px",
        border:"1px solid ",
        borderRadius:"4px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }
      }else{
        return{
        width:"150px",
      }
      }
    }
  },




    getSelectionAvatarStyle(value) {
      const backgroundColor = generateAvatarColor(value);
      return {
        marginRight: "2.5px",
        marginTop: "2.5px",
        backgroundColor: backgroundColor,
        fontSize: "10px",
        position: "relative",
        top: "4px",
        left: "-5px",
      };
    },
    colorUL() {
      let nodeList = document.querySelectorAll(".el-submenu__title");
      for (let i = 0; i < nodeList.length; i++) {
        nodeList[i].style.color = "#222";
        if (this.$props.isDrillDown) {
          nodeList[i].style.minWidth = "150px";
        }
      }

      let arrowList = document.querySelectorAll(".el-icon-arrow-right");
      for (let i = 0; i < arrowList.length; i++) {
        arrowList[i].style.color = "#222 !important";
        arrowList[i].style.fontSize = "16px";
        arrowList[i].style.fontWeight = "bold";
      }

      let checkboxLabel = document.querySelectorAll(".el-checkbox__label");
      for (let i = 0; i < checkboxLabel.length; i++) {
        checkboxLabel[i].style.color = "#222";
      }
    },
    handleColor(data) {
      return handleCompetenceColor(data);
    },
    handleColorLightning(color, perc) {
      return getLightenedColor(color, perc);
    },
    useImg(url) {
      return url;
    },
    selectSubMenuItem(value) {
      this.selectedSubMenuItem = value;
    },
    updateDate() {
      this.date=[]
      this.openMenu="hover"
      this.openCollapse=true
      this.date.push(this.selectedFromDate,this.selectedToDate)
      this.$emit("ExpectedClosure", this.date);
    },
    updateSelectedCreatedDates(){
      this.openCollapse=true
      this.$emit("customCreatedDateUpdate", [this.selectedCreatedAtFromDate,this.selectedCreatedAtToDate]);
    },
    updateDueDate() {
      this.dueDate=[]
      this.openMenu="hover"
      this.openCollapse=true
      this.dueDate.push(this.selectedDueDateFrom,this.selectedDueDateTo)
      this.$emit("due-Date", this.dueDate);
    },
    clickEvent(){
      this.openMenu="click"
    },
    HoverEffect(){
      setTimeout(() => {
        this.openMenu="hover"
        }, 50)
      
    },
    updateStartDate() {
      this.startDate=[]  
      this.openMenu="hover"
      this.openCollapse=true
      this.startDate.push(this.selectedStartDateFrom,this.selectedStartDateTo)
      this.$emit("start-Date", this.startDate);
    },
    updateLeadCheckbox() {
      this.$emit("leadCheckbox", this.selectedLeadCheckboxes);
    },
    updateRegionCheckbox(){
      this.$emit("regionCheckbox", this.selectedRegion);
    },
    updateCollaborators() {
      this.$emit("collaboratorCheckbox", this.selectedValue);
    },
    updateHomeownerCheckbox() {
      this.$emit("homeownerCheckbox", this.homeOwnerSelect);
    },
    updateTagCheckbox() {
      this.$emit("tagCheckbox", this.selectedTagCheckboxes);
    },
    updateUserCheckbox(val){
      this.$emit("userCheckbox", this.selectedUserCheckboxes);
    },
    handleSelectedProductsUpdated(){
      let selectedProducts = [];
      this.selectedPowerGazeboProductsWithInverter.forEach((product) => {
          selectedProducts.push({
              id: product,
              name: this.powerGazeboProductsWithInverter.find((item) => item.id === product).sku_name,
              with_inverter: true,
              type:'gazebo'
            })
      });
      this.selectedPowerGazeboProductsWithoutInverter.forEach((product) => {
          selectedProducts.push({
              id: product,
              name: this.powerGazeboProductsWithoutInverter.find((item) => item.id === product).sku_name,
              with_inverter: false,
              type:'gazebo'
          })
      });
      this.selectedPowerPatioProductsWithInverter.forEach((product) =>{
        selectedProducts.push({
          id: product,
          name: this.powerPatioProductsWithInverter.find((item) => item.id === product).sku_name,
          with_inverter:true,
          type:'patio'
        })
      })
      this.selectedPowerPatioProductsWithoutInverter.forEach((product) =>{
        selectedProducts.push({
          id: product,
          name: this.powerPatioProductsWithoutInverter.find((item) => item.id === product).sku_name,
          with_inverter:false,
          type:'patio'
        })
      })
      this.$emit('selectedProductsUpdated',selectedProducts)
    },
    updateStatusCheckbox() {
      this.$emit("statusCheckbox", this.selectedStatusCheckboxes);
    },
    updateCatalogStatusbox(item){
      this.$emit('catalog_product_accessory',this.selectedAccessories,item)
    },
    updateCatalogProducts(item){
      this.$emit('catalog_product_accessory',this.selectedProducts,item)
    },
    UpdatePriorityValue() {
      this.$emit("priorityCheck", this.selectedRadioGroup);
    },
    updateProposalStatusRadio() {
      this.$emit("proposalStatusRadio", this.selectedProposalRadioGroup);
    },
    UpdateDCvalue() {
      this.openCollapse=true
      const moreValue = Number(this.moreValue);
      const lessValue = Number(this.lessValue);
      if(lessValue<= moreValue){
        this.DCValue = [moreValue, lessValue];
        this.$emit("DCValue", this.DCValue); 
      }else{
        this.DCValue = [lessValue, moreValue];
        this.$emit("DCValue", this.DCValue);
      }
    },
    updateProbability(){
      this.openCollapse=true
      if(this.minProbability<= this.maxProbability){
        this.probabilityValue = [this.maxProbability, this.minProbability];
        this.$emit("probabilityValue", this.probabilityValue);
      }else{
        this.probabilityValue = [this.minProbability, this.maxProbability];
        this.$emit("probabilityValue", this.probabilityValue);
      }
    },
    updateCost() {
      const moreCost = Number(this.moreCost);
      const lessCost = Number(this.lessCost);
      this.openCollapse=true
      if(lessCost <= moreCost){
        this.cost = [moreCost, lessCost];
        this.$emit("systemCost", this.cost);
      }else{
        this.cost = [lessCost, moreCost];
        this.$emit("systemCost", this.cost);
      }
    },
   
  },
  watch: {
    selectedCreatedAtFromDate(newVal) {
      if (newVal && this.selectedCreatedAtToDate) {
        const fromDate = new Date(newVal);
        fromDate.setDate(fromDate.getDate() + 1);
        if (new Date(this.selectedCreatedAtToDate).getTime() < fromDate.getTime()) {
          this.selectedCreatedAtToDate = null;
        }
      }
    },
    selectedCreatedAtToDate(newVal) {
      if (newVal && this.selectedCreatedAtFromDate) {
        const toDate = new Date(newVal);
        toDate.setDate(toDate.getDate() );
        if (new Date(this.selectedCreatedAtFromDate).getTime() > toDate.getTime()) {
          this.selectedCreatedAtFromDate = null;
        }
      }
    },
    openMenu:{
      handler(val) {
        // if(val=='hover'){
        //     this.openCollapse= true;
        // }
      },
    },
    openCollapse:{
      handler(val) {
        if(val==true){
          setTimeout(() => {
            this.openCollapse= false;
        }, 50)
        }
      },
    },

    isDrillDown: {
      immediate: true,
      handler(val) {
      },
    },
    menuItems(newval) {
    },
    searchTerm(val) {
      this.searchLeads(val);
    },
    search(val) {
      this.searchUsers(val);
    },
    clearall(value) {
      if (value == true) {
        this.selectedStatusCheckboxes = [];
        this.homeOwnerSelect = [];
        this.selectedValue = [];
        this.selectedRadioGroup = "";
        this.selectedStartDateFrom = "";
        this.selectedStartDateTo = "";
        this.selectedDueDateFrom = "";
        this.selectedDueDateTo = "";
      }
      this.$emit("update:clearall", false);
    },
    startDateClear(value) {
      if (value == true) {
        this.selectedStartDateFrom = "";
        this.selectedStartDateTo = "";
        this.startDate=[]
      }
      this.$emit("update:startDateClear", false);
    },
    dueDateClear(value) {
      if (value == true) {
        this.selectedDueDateTo = "";
        this.selectedDueDateFrom = "";
        this.dueDate=[]
      }
      this.$emit("update:dueDateClear", false);
    },
    status(value) {
      if (value == true) {
        this.selectedStatusCheckboxes = [];
      }
      this.$emit("update:status", false);
    },
    homeOwn(value) {
      if (value == true) {
        this.homeOwnerSelect = [];
      }
      this.$emit("update:homeOwn", false);
    },
    colloabo(value) {
      if (value == true) {
        this.selectedValue = [];
      }
      this.$emit("update:colloabo", false);
    },
    prior(value) {
      if (value == true) {
        this.selectedRadioGroup = "";
      }
      this.$emit("update:prior", false);
    },
    clearAll(value) {
      this.lessValue = "";
      this.moreValue = "";
      this.minProbability="";
      this.maxProbability="";
      this.selectedToDate = "";
      this.selectedFromDate=""
      this.date = [];
      this.lessCost = "";
      this.moreCost = "";
      this.selectedTagCheckboxes = [];
      this.selectedUserCheckboxes=[];
      this.selectedLeadCheckboxes = [];
      this.selectedRegion=[];
      this.selectedAccessories=[];
      this.selectedProducts=[];
      this.selectedPowerGazeboProductsWithInverter = [];
      this.selectedPowerGazeboProductsWithoutInverter = [];
      this.selectedPowerPatioProductsWithInverter = [];
      this.selectedPowerPatioProductsWithoutInverter = [];
      this.selectedProposalRadioGroup = "";
      this.$emit("update:clearAll", false);
    },
    dateClear(value){
      if (value == true) {
        this.selectedFromDate = "";
        this.selectedToDate="";
        this.date = [];
      }
      this.$emit("update:dateClear", false);
    },
    DCvalueClear(value){
      if (value == true) {
        this.lessValue = "";
        this.moreValue = "";
      }
      this.$emit("update:DCvalueClear", false);
    },
    probabilityClear(value){
      if (value == true) {
        this.minProbability="";
        this.maxProbability="";
      }
      this.$emit("update:probabilityClear", false);
    },
    costClear(value){
      if (value == true) {
        this.lessCost = "";
        this.moreCost = "";
      }
      this.$emit("update:probabilityClear", false);
    },
    leadSourceClear(value){
      if (value == true) {
        this.selectedLeadCheckboxes = [];
      }
      this.$emit("update:leadSourceClear", false);
    },
    productListClear(value){
      if (value == true) {
        this.selectedPowerGazeboProductsWithInverter = [];
        this.selectedPowerGazeboProductsWithoutInverter = [];
        this.selectedPowerPatioProductsWithInverter = [];
        this.selectedPowerPatioProductsWithoutInverter = [];
        this.handleSelectedProductsUpdated();
      }
      this.$emit("update:productListClear", false);
    },
    regionClear(value){
      if (value == true) {
        this.selectedRegion = [];
      }
      this.$emit("update:regionClear", false);
    },
    proposalStatusClear(value){
      if (value == true) {
        this.selectedProposalRadioGroup = "";
      }
      this.$emit("update:proposalStatusClear", false);
    },
    accessoryClear(value){
      if (value == true) {
        this.selectedAccessories = [];
      }
      this.$emit("update:accessoryClear", false);
    },
    productClear(value){
      if (value == true) {
        this.selectedProducts = [];
      }
      this.$emit("update:productClear", false);
    },
    tagsClear(value){
      if (value == true) {
        this.selectedTagCheckboxes = [];
      }
      this.$emit("update:tagsClear", false);
    },
    usersClear(value){
      if (value == true) {
        this.selectedUserCheckboxes = [];
      }
      this.$emit("update:usersClear", false);
    },
  },
};
</script>

<style scoped>
.img {
  max-width: none !important;
}

.selector,.borderSelector {
  display: flex;
}

.datePick {
  padding: 5%;
}

.countryselect {
  padding: 5px 10px;
  text-align: center;
  border: none;
}

.updateRadioButton {
  border: none;
  color: white;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  float: right;
  margin-right: 12px;
  margin-top: 15px;
}

.updateButton {
  /* background-color: #1c191c2e; */
  border: none;
  color: white;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  float: right;
  margin-right: 12px;
}

.inputContainer {
  padding: 10px 10px;
}

.inputContainer label {
  display: flex;
  align-items: center;
}

.inputContainer input {
  margin-bottom: 10px;
}

.labelText {
  margin-right: 5px;
  margin-bottom: 5px;
  color: #777777;
}
.search-bar ::v-deep.el-input__inner {
  background-color: #e8edf2;
  border: none;
  margin-left: 10px;
  margin-top: 3px;
  border-radius: 0;
  max-width: 240px;
}
.labelText2 {
  margin-left: 5px;
  margin-bottom: 5px;
  color: #777777;
}

/* .el-submenu__title {
  background-color: aqua;
} */
.el-submenu.is-active .el-submenu__title {
  border-bottom-color: transparent;
  background-color: red;
}

.el-menu--horizontal >>> .el-submenu.is-active .el-submenu__title {
  border-bottom: transparent;
  color: red;
  height: 40px;
  line-height: 40px;
}

.el-menu--horizontal >>> .el-submenu .el-submenu__title {
  height: 40px;
  line-height: 40px;
  border-bottom: 2px solid transparent;
  color: #222 !important;
  font-size: 16px;
  display: flex;
  justify-content:space-between ;
  align-items: center;
}
.el-menu--horizontal >>> .el-submenu {
    float: left;
    width: 100%;
    
}
.menuInput {
  padding: 8px;
  gap: 8px;
  width: 238px;
  height: 40px;
  background: #e8edf2;
  border-radius: 4px;
  border-color: transparent;
}

.el-menu--horizontal .el-menu .el-menu-item {
  /* background-color: #FFFFFF; */
  float: none;
  height: 36px;
  width: 100%;
  line-height: 36px;
  padding: 0 10px;
  color: #222 !important;
}

.radiogroup {
  width: 10px;
  margin-top: 10px;
  margin-left: 10px;
}

.radiogroup::v-deep .el-radio {
  padding-bottom: 10px;
}

.countryDropContainer {
  padding: 5px 10px;
}

.dateContainer {
  padding: 5px 10px;
  text-align: right;
  border: none;
}
.dateContainer>>>.el-button--primary {
  margin-right: 10px;
}
.dateContainer>>>.el-input__icon{
  padding-left: 10px;
  /* padding-right : 10px; */
}
.dateContainer>>>.el-input__suffix {
    position: absolute;
    height: 100%;
    right: 20px;
    top: 0;
    text-align: center;
    color: #C0C4CC;
    transition: all 0.3s;
    pointer-events: none;
}
.checkBox {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  max-height: 400px;
  overflow-y: auto;
  width: 195px;
  overflow-x: auto;
}
.checkBox2 {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin: 5px;
}

.checkbox-container {
  max-height: 330px;
  /* Adjust the maximum height as needed */
  overflow-y: auto;
  width: 195px;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}
.checkbox-container2 {
  max-height: 330px;
  /* Adjust the maximum height as needed */
  overflow-y: auto;
  width: 250px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
}
.checkbox-container3 {
  /* Adjust the maximum height as needed */
  overflow-y: auto;
  width: 195px;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

.fixed-button {
  width: 100px;
  margin-left: auto;
}

.checkBox .el-checkbox {
  padding: 10px;
  display: flex;
}
.checkBox2 .el-checkbox {
  padding: 10px;
  display: flex;
}

.selector>>>.el-menu {
  display: flex;
  align-items: center;
  border-radius: 4px;
  font-weight: 600 !important;
  width: 100px;
}
.borderSelector>>>.el-menu {
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-weight: 500 !important;
  width: 150px;
}
.el-menu-demo {
  display: flex;
  margin-right: 0.5rem;
  align-items: center;
}

.icons {
  margin-right: 0.5rem;
}

.tag {
  border-radius: 20px;
}

.sort {
  width: 20px;
}

.el-menu-item.is-active {
  /* background-color: #e8edf2 !important; */
}

.el-menu-item {
  color: #222 !important;
}

.menu-container {
  height: 300px;
  /* Set the desired height */
  overflow-y: auto;
  /* Enable vertical scrollbar */
}

.selector >>> .el-submenu__icon-arrow {
  position: absolute;
  top: 50%;
  right: 20px;
  color: #222222;
  padding-top: 5px;
  margin-top: -7px;
  transition: transform 0.3s;
  font-size: 18px;
  font-weight: bold;
}
.borderSelector>>> .el-submenu__icon-arrow {
  position: absolute;
  top: 50%;
  right: 20px;
  padding-top: 5px;
  color: #222222 !important;
  margin-top: -7px;
  transition: transform 0.3s;
  font-size: 18px !important;
  font-weight: bold !important;
}

.productHeadingText {
  font-size: 14px;
  padding-left: 5px;
  font-weight: 100;
  color: #ccc;
}

.checkBoxborder ::v-deep .el-checkbox__inner {
  /* display: inline-block;
    position: relative; */
  border: 1px solid #222;
}

.checkBoxborder ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #409eff;
  border-color: #409eff !important;
}

.checkBoxborder ::v-deep.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #222;
}

.checkBoxborder ::v-deep.el-checkbox__input + .el-checkbox__label {
  color: #222;
  display: flex;
  align-items: flex-end;
}
.el-submenu >>>.el-submenu__title i {
    color: #222;
    font-size: 16px;
    font-weight: 600;
}
.checkBoxborder>>>.el-checkbox__input {
    white-space: nowrap;
    cursor: pointer;
    outline: none;
    display: inline-block;
    line-height: 1;
    margin-top: 13px;
    position: relative;
    vertical-align: middle;
}
.selection-avatars >>> .el-input__inner {
  width: 100%;
  border: none;
  display: absolute;
}

.selection-avatars:hover {
}

.selection-avatars >>> .el-input.is-focus .el-input__inner {
  width: 100%;
}

.selection-avatars >>> .el-select__caret {
}
.submenu-with-border >>> .el-submenu__title {
  border: 1px solid black; /* Add your desired border style */
  border-bottom-color: black !important;
  border-bottom: 1px solid black !important;
  border-radius: 4px;
  display: flex;
  align-items: center;

  justify-content: space-between;
}




.title-container {
  min-width: 150px;
}

/* .el-menu--horizontal >>>  .el-menu .el-submenu__title {
  color: #222;
} */
@media (max-width: 768px){
  /* img{
    min-width: 20px !important;
  } */
  .el-menu-demo >>> .el-menu--horizontal{
    width: 30px !important;
    padding: 0;
  }
  .menu-sub-items{
    margin-left: 12px;
  }
  .el-submenu >>> .el-submenu__title{
    padding: 0 !important;
    max-width: 100px !important;
  }
  .borderSelector>>>.el-menu {
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid transparent;
  font-weight: 400 !important;
  width: 150px;
}
  .el-menu.el-menu--horizontal{
    border-bottom: none !important;
  }
  .selector,.borderSelector >>> .el-menu-demo{
    margin: 0;
  }
  .el-submenu >>> .el-submenu__title:hover, .el-menu-demo >>> .el-menu--horizontal{
    background-color: transparent !important;
  }
  .el-submenu >>> .el-submenu__icon-arrow{
    display: none;
  }
  .el-menu-demo >>> .el-submenu__title{
    padding: 0 !important;
  }
  .chevron-right{
    font-size: 16px;
    color: rgb(34, 34, 34);
    font-weight: bold;
    margin-left: auto;
    position: absolute;
    right: -15px;
    top: 12px;
  }
  .filter-sub-menu-title{
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px !important;
    display: inline-block;
  }
  div >>> .el-submenu__title{
    position: relative;
  }
  .checkbox-container, .checkbox-container2{
    width: 150px !important;
  }
  .checkBox >>> .el-checkbox__label, .cb-label-mobile{
    overflow: hidden;
    max-width: 100px;
    text-overflow: ellipsis;
    display: inline-block;
  }
  .el-checkbox{
    margin-right: 0 !important;
  }
  .menuInput{
    width: 90px;
  }
  .labelText2{
    margin: 0 0 10px 4px;
  }
}
</style>
