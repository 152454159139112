import { convertImperialToMetric, convertMetricToImperial } from "../../../components/ui/length/utils";
import { useTilesStore } from "../../../stores/tilesStore";
import { CREATED_STATE, DELETED_STATE } from "../../coreConstants";
import { rad2Deg, rotationAroundPoint } from "../../utils/utils";
import FixedObject from "../FixedObject";
import { COLOR_MAPPINGS } from "../visualConstants";

export default class PowerRoofCombinerBox extends FixedObject{
    constructor(stage){
        super(stage);
        this.stage = stage;
        this.isPlacedOutdoors = true;
    }

    getColorMapForObject() {
        return COLOR_MAPPINGS.INVERTER;
    }

    getDimensions() {
        return {
            length: '0.6',
            width: '1.4',
        }
    }
    getModuleDimensions() {
        return {
            moduleWidth: '1.4',
            moduleHeight: '0.6',
            moduleDepth: '0.6',
            pillarRadius: 0.02,
        };
    }
    rotateObjectHelper(angleInRad, centroidPoint) {
        const currentPosition = this.getPosition();
        const outlineDeltaXY = rotationAroundPoint(
            centroidPoint.x,
            centroidPoint.y,
            currentPosition.x,
            currentPosition.y,
            angleInRad,
        );
        this.moveObject(outlineDeltaXY[0] - currentPosition.x, outlineDeltaXY[1] - currentPosition.y);
        const calcAzimuth = parseFloat((this.azimuth - rad2Deg(angleInRad)).toFixed(2)) % 360;
        const finalAzimuth = calcAzimuth > 0 ? calcAzimuth : calcAzimuth + 360;
        this.azimuth = finalAzimuth;
        this.updateRotation();
    }

    getState() {
        const combinerBoxData = {
            id: this.id,
            uuid: this.uuid,
            name: this.name,
            baseHeight: this.baseHeight,
            pillarHeight: this.pillarHeight,
            azimuth: this.azimuth,
            isPlacedOutdoors: this.isPlacedOutdoors,

            // saving outline points
            outlinePoints: [...this.outlinePoints],

            parent: this.getParent() ? this.getParent().uuid : null,
        };
        return combinerBoxData;
    }

    loadState(state, fromState) {
        if (state === CREATED_STATE || state === DELETED_STATE) {
            this.clearState();
        }
        else {
            // load id and name
            this.id = state.id;
            this.name = state.name;

            // load properties
            this.baseHeight = state.baseHeight;
            this.pillarHeight = state.pillarHeight;
            this.azimuth = state.azimuth;
            this.isPlacedOutdoors = state.isPlacedOutdoors;
            this.updateVisualsAfterLoadingAndCreation();

            // update parent
            const parentObject = this.stage.getObject(state.parent);
            if (parentObject && this.getParent() !== parentObject) {
                this.changeParent(parentObject);
                this.addToPowerRoof();
            }

            if (fromState === CREATED_STATE || fromState === DELETED_STATE) {
                useTilesStore().isCombinerBoxPresentInScene = true;
                // add objectsGroup to scene
                this.stage.sceneManager.scene.add(this.objectsGroup);

                this.outlinePoints = [...state.outlinePoints];
                this.updateRotation();
            }
            else {
                if (this.outlinePoints.length !== state.outlinePoints.length) {
                    console.error('PowerRoofCombinerBox: loadState: vertices length don\'t match');
                    return null;
                }
                this.outlinePoints = [...state.outlinePoints];
            }

            // update geometry
            this.updateGeometry();
            this.setOpacityAccordingToLocation(this.isPlacedOutdoors);
        }
    }

    clearState() {
        // select ground if selected
        if (this.stage.selectionControls.getSelectedObject() === this) {
            this.stage.selectionControls.setSelectedObject(this.stage.ground);
        }
        this.removeFromPowerRoof();
        useTilesStore().isCombinerBoxPresentInScene = false;
        this.stage.sceneManager.scene.remove(this.objectsGroup);
        this.getParent().removeChild(this);
    }

    saveObject(isCopy = false) {
        const combinerModelData = {
            type: PowerRoofCombinerBox.getObjectType(),
        };

        // save id and name
        // combinerModelData.id = this.id;
        // combinerModelData.name = this.name;
        if (isCopy) {
            combinerModelData.uuid = this.uuid;
        }

        combinerModelData.baseHeight = this.baseHeight;
        combinerModelData.pillarHeight = this.pillarHeight;
        combinerModelData.azimuth = this.azimuth;
        combinerModelData.isPlacedOutdoors = this.isPlacedOutdoors;
        combinerModelData.outlinePoints = [...this.outlinePoints];

        return combinerModelData;
    }

    loadObject(combinerModelData, isPaste = false) {
        // load id and name
        if (!isPaste) {
            this.id = combinerModelData.id;
            this.name = combinerModelData.name;
        }

        this.baseHeight = combinerModelData.baseHeight;
        this.pillarHeight = combinerModelData.pillarHeight;
        this.azimuth = combinerModelData.azimuth;
        this.isPlacedOutdoors = combinerModelData.isPlacedOutdoors;
        this.outlinePoints = [...combinerModelData.outlinePoints];

        // update geometry
        this.updateGeometry();
        useTilesStore().isCombinerBoxPresentInScene = true;
        this.setOpacityAccordingToLocation(this.isPlacedOutdoors);
        if (isPaste) {
            this.saveState({ withoutContainer: false });
        }
        else {
            this.saveState({ withoutContainer: true });
        }
    }

   async updateObject(data){
        let saveStateReq = false;
        await super.updateObject(data);
        if (Object.prototype.hasOwnProperty.call(data, 'position') &&
            data.position !== this.isPlacedOutdoors) {
            this.isPlacedOutdoors = data.position;
            saveStateReq = true;
            this.setOpacityAccordingToLocation(this.isPlacedOutdoors);
        }
        if(saveStateReq) this.saveState();
    }

    setOpacityAccordingToLocation(position){
        if(position){
            this.meshMaterial2D.opacity = 1;
            this.meshMaterial3D.transparent = false;
            this.meshMaterial3D.opacity = 1;
        }
        else{
            this.meshMaterial2D.opacity = 0.2;
            this.meshMaterial3D.transparent = true;
            this.meshMaterial3D.opacity = 0.2;
        }
    }


    setHeightOnRoof() {
        let heightToMove = -parseFloat(this.getModuleDimensions().moduleDepth);
        if(convertMetricToImperial(this.baseHeight)[0]>=6){
            heightToMove = convertImperialToMetric([6,0]) - parseFloat(this.getModuleDimensions().moduleDepth) - this.baseHeight;
        }
        this.moveObject(0,0,heightToMove);
    }

    addToPowerRoof() {
        const connectedPowerRoof = this.parent?.parent?.connectedPowerRoof;
        if(connectedPowerRoof){
            connectedPowerRoof.addCombinerBox(this);
            connectedPowerRoof.combinerPlaced = true;
        }
    }

    static getObjectType() {
        return 'PowerRoofCombinerBox';
    }

    removeFromPowerRoof() {
        if (this.parent?.parent?.connectedPowerRoof?.connectedCombinerBox) {
            this.parent.parent.connectedPowerRoof.connectedCombinerBox = null;
        }
    }
    removeObject(){
        this.removeFromPowerRoof();
        super.removeObject();
        useTilesStore().isCombinerBoxPresentInScene = false;
    }
}