
<div id="parentContainer">
  <el-dialog
    :visible="isProjectUpgradePopupVisible"
    :close-on-click-modal="false"
    title="Project Ad-ons"
    width="976px"
    @close="onDialogClose"
  >
    <div class="container">
      <div class="leftContainer" v-loading="isLoading">
        <div class="headerContainer">
          <p class="headContent">
            Select features to add and use in the project.
          </p>
        </div>
        <div class="drpDownContainer" v-if="isInfoInDropdown">
          <el-select v-model="selectedOption" placeholder="Select">
            <el-option
              v-for="options in availableFeaturesInDropdown"
              :key="options.id"
              :value="options.type"
              class="loanDropdown"
            >
            <div class="flexContainer">
              <div class="">
                <p class="flexContainer">
                  {{options.type }}
                </p>
                <p class="uptoKW">{{options.description}}</p>
              </div>                                    
              <p class="droDwnCredits">{{formatNumberWithCommas(options.credits)}} Credits</p>
            </div>   
            </el-option>
          </el-select>
        </div>
        <!-- {{availedFeaturesIdsList}} -->
        <div v-if="isInfoInCheckbox" >
          <el-checkbox-group v-model="availedFeaturesIdsList" class="checkBoxContainer">
          <el-checkbox
            :label="option.id"
            v-for="option in filteredFeatures"
            :key="option.id"
            :disabled="option.isSelected"
          >
            {{option.name}} ({{formatNumberWithCommas(option.credits) + ' Credits'}})
            <div v-if="option.description" class="hover_information">
              <i class="fas fa-info-circle"></i>
              <div class="tooltip">
                <p>
                  {{option.description}}
                </p>
              </div>
            </div>
          </el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="" v-if="isInfoInDropdown">
          <p class="detailsValue" v-if="selectedOption">
            <span class="tickSign">✓</span>Project size {{selectedDropdownObj.description}}
          </p>
        </div>
      </div>
      <div class="rightContainer">
        <div class="headerContainerTwo">
          <h3 class="headings">Payment Details</h3>
        </div>
        <div class="selectedPayments" v-if="isInfoInCheckbox">
          <div class="packageDetails" v-for="data in availableFeaturesInCheckbox" :key="data.id"  >
            <p class="detailsSmall" v-if="!data.isSelected && availedFeaturesIdsList.includes(data.id)">{{data.name}}</p>
            <p class="detailsValue" v-if="!data.isSelected && availedFeaturesIdsList.includes(data.id)">{{formatNumberWithCommas(data.credits)}} Credits</p>
          </div>
        </div>
        <div class="selectedPayments" v-else>
          <div class="packageDetails" v-if="selectedOption">
            <p class="detailsSmall">{{selectedDropdownObj.type}}</p>
            <p class="detailsValue" >{{formatNumberWithCommas(selectedDropdownObj.credits)}} Credits</p>
          </div>
        </div>
        <div class="estDeleiveryTimeContainerTwo">
          <h3 class="creditBal">Credit Balance</h3>
          <div class="packageDetails">
            <div class="crdtBalChkBoxContainer">
              <el-checkbox v-model="isUsingPromotionalBalance"
                >Promotional Credit Balance
                <div class="hover_information">
                  <i class="fas fa-info-circle"></i>
                  <div class="tooltip">
                    <p>
                      Use your bonus credits for sales proposals, revision requests, self-design creation, and some upgrades on a design level.
                    </p>
                  </div>
                </div>
              </el-checkbox>
            </div>
            <p class="details">{{formatNumberWithCommas(creditsInfo.promotional_credits)}}</p>
          </div>
        </div>
        <div class="purCredBalContainer">
          <div class="packageDetails">
            <p class="detailsSmall">Purchased Credit Balance</p>
            <p class="detailsValue">{{formatNumberWithCommas(creditsInfo.purchased_credits)}}</p>
          </div>
        </div>
        <div class="footerBtnContainer">
          <div class="packageDetails">
            <p class="totalPayCred">Total Payable Credits:</p>
            <p class="totalPayCredVal">{{formatNumberWithCommas(totalPayableCredits)}}</p>
          </div>
          <el-tooltip
            class="item"
            effect="dark"
            content="Please select a feature to use in project."
            placement="top"
            :disabled="!noOptionsSelected"
          >
            <el-button
              class="footerBtn"
              type="primary"
              :loading="loadingStateButton"
              :disabled="totalPayableCredits < 0 || noOptionsSelected"
              @click="dynamicButtonName == 'Pay Now'? upgradeFeatures() : isAddCreditPopupVisible = true"
            >
              {{dynamicButtonName}}
            </el-button>
        </el-tooltip>
        </div>
      </div>
    </div>
  </el-dialog>
  <add-credit-popup v-if="isAddCreditPopupVisible"
    :isAddCreditPopupVisible.sync="isAddCreditPopupVisible" :preFilledCreditCount ="insufficientCredits"/>
</div>
