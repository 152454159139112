export default {
    SOLAR_ACCESS_LOADING: 'SOLAR_ACCESS_LOADING',
    SET_SUN_SIMULATION_STATUS: 'SET_SUN_SIMULATION_STATUS',
    SET_SLIDER_TIME: 'SET_SLIDER_TIME',
    SET_PICKER_TIME: 'SET_PICKER_TIME',
    SET_SLIDER_DATE: 'SET_SLIDER_DATE',
    SET_PICKER_DATE: 'SET_PICKER_DATE',
    SET_DAY_RUNNING: 'SET_DAY_RUNNING',
    SET_YEAR_RUNNING: 'SET_YEAR_RUNNING',
    SET_TIMER: 'SET_TIMER',
    SET_LIDAR_STATUS: 'SET_LIDAR_STATUS',
};
