
  <div id="dashboard">
    <GlobalPageHeader>
      <template v-slot:children>
        <div>
          <div class="heading-container">
            <div class="cardFilter">
              <popoverSelect
                :dashboardsData.sync="dashboardsData"
                :selecteddashboardData="selecteddashboardData"
                @selected-dashboard="getSelectedDashBoard"
                @update-dashboard="updateDashboard"
                @dashboardChanged="handleDashboardChange"
                :currentDashboardId="currentDashboardId"
                @load-more="loadMoreData"
                :editMode.sync="editMode"
                @get-dashboard="getdashboards"
              />
              <!-- <el-tooltip content="Edit">
                <span
                  v-if="!isMobile && selecteddashboardData?.id"
                  class="pencil"
                  @click="handleEdit"
                  >
                  <img src="./assests/pencil.svg" class="pencil-class"
                /></span>
              </el-tooltip> -->
            </div>

            <!-- <div class="heading" >
              <div style="display: flex; align-items: center">
                <el-tooltip content="Back to Dashboard">
                  <b-icon
                    :icon="'arrow-left'"
                    class="arrow-icon-left"
                    style="
                      margin-right: 5px;
                      font-size: 1rem;
                      font-weight: 800;
                      cursor: pointer;
                    "
                    @click="handleBack(selecteddashboardData)"
                  ></b-icon>
                </el-tooltip>

                <p style="color: #1c3366; font-size: 20px; font-weight: 600">
                  {{ selecteddashboardData.name }}
                  <span class="icons-content">
                    <el-tooltip content="Edit">
                      <b-icon :icon="'pencil'"></b-icon
                    ></el-tooltip>
                  </span>
                </p>
              </div>
              <div>
                <el-button
                  type="text"
                  @click="handleCreateWidget()"
                  style="
                    border: 1px solid #409eff;
                    padding-left: 1rem;
                    padding-right: 1rem;
                  "
                  >Add Widget</el-button
                >
                <el-button type="primary">Save</el-button>
              </div>
            </div> -->
          </div>
          <div class="dashboardContainer">
            <div  v-if="!isEmpty" v-loading="isDashboardData" class="fullWidth">
              <!-- edit toogle -->
              <!-- <div>
              <el-switch
                v-model="isEditMode"
                active-color="#13ce66"
                inactive-color="#ff4949"
              >
              </el-switch>
            </div> -->
              <div v-if="dashboardsData.length && !isEmpty" class="fullWidth">
                <!-- <div class="cardFilter">
                  <popoverSelect
                    :dashboardsData.sync="dashboardsData"
                    @selected-dashboard="getSelectedDashBoard"
                    @update-dashboard="updateDashboard"
                    @dashboardChanged="handleDashboardChange"
                    :currentDashboardId="currentDashboardId"
                    @load-more="loadMoreData"
                    :editMode.sync="editMode"
                  />
                  <el-tooltip content="Edit">
                    <span class="pencil" @click="handleEdit"
                      ><img src="./assests/pencil.svg" class="pencil-class"
                    /></span>
                  </el-tooltip>
                </div> -->

                <div
                  v-if="selectedDashboardLayoutData.length"
                  v-loading="isWidgetsData"
                  class="fullWidth"
                >
                  <grid-layout
                    :layout.sync="selectedDashboardLayoutData"
                    :row-height="100"
                    :is-draggable="false"
                    :is-resizable="false"
                    :is-mirrored="false"
                    :vertical-compact="true"
                    :margin="[10, 10]"
                    :use-css-transforms="false"
                    :auto-size="false"
                    :prevent-collision="false"
                    :responsive="true"
                    :cols="{ lg: 4, md: 2, sm: 2, xs: 1, xxs: 1 }"
                  >
                    <grid-item
                      v-for="item in selectedDashboardLayoutData"
                      class="grid-item"
                      :x="item.x"
                      :y="item.y"
                      :w="item.w"
                      :h="item.h"
                      :i="item.i"
                      :minW="item.minW"
                      :minH="item.minH"
                      :maxW="item.maxW"
                      :maxH="item.maxH"
                      :key="item.i"
                    >
                    <div class="graph-container"    v-if="item.type === 'KPI'">
                      <CardComponentCRM
                     
                        :id="item.i"
                        :unit="'dollar'"
                        @drilldown-kpi="handleDrilldownOpen"
                        :editMode="false"
                        @mode-open="handleOpenMode"
                        :updateCheck.sync="updateCheck"
                      ></CardComponentCRM>
                    </div>
                    <div class="graph-container"     v-else-if="item.type === 'Table'">
                      <LeadsTableCRM
                      
                        :data="[]"
                        :id="item.i"
                        :headingText="'LeadsTable'"
                        :dropDown="false"
                        :pageRedirectFlag="'dashboard'"
                        :chartSource="chartSource"
                        @handleRowClick="handleRowClick"
                        @showDrawer="showDrawer"
                        :editMode="false"
                        @mode-open="handleOpenMode"
                        :updateCheck.sync="updateCheck"
                      />
</div>
<div class="graph-container"       v-else>
                      <AllChart
                     
                        :id="item.i"
                        :type="item.type"
                        :isMap="item.type == 'Map'"
                        @click-drilldown="handleDrilldownOpen"
                        :widgetsData="selectedDashboardLayoutData"
                        :dashboardId="dashboardId"
                        :updateWidgetData.sync="updateWidgetData"
                        :updateCheck.sync="updateCheck"
                        :editMode="false"
                        @mode-open="handleOpenMode"
                      />
                      </div>
                    </grid-item>
                  </grid-layout>
                </div>
                <div v-else-if="editMode">
                  <AddNewWidgetspage @handle-create="handleCreateWidget" />
                </div>
                <div v-else>
                  <el-row
                    :gutter="20"
                    class="card-row nodata"
                    style="margin-bottom: 20px"
                  >
                    No Widgets Available
                  </el-row>
                </div>
                <GlobalDialog
                  :dialogVisible.sync="open"
                  @handleClose="closeDialog"
                  :width="'70%'"
                  :isDrilldown="open"
                  v-if="
                    chartSource === 'Tasks' ||
                    chartSource === 'Leads' ||
                    chartSource === 'Activities'
                  "
                >
                  <template v-slot:body>
                    <div class="dialog-body">
                      <LeadManagement
                        v-if="
                          chartSource === 'Leads' ||
                          chartSource === 'Activities'
                        "
                        :isDrilldown.sync="open"
                        :drillDownUrl.sync="drillDownUrl"
                        :widgetId="widgetId"
                        @csv-download="downloadCsv"
                        @handleClose="closeDialog"
                        @updateWidget="updateWidget"
                      />
                      <TaskMangment
                        :isDrilldown.sync="open"
                        :drillDownUrl.sync="drillDownUrl"
                        :widgetId="widgetId"
                        @csv-download="downloadCsv"
                        v-if="chartSource === 'Tasks'"
                        @handleClose="closeDialog"
                        @updateWidget="updateWidget"
                      />
                    </div>
                  </template>
                  <template v-slot:footer>
                    <div></div>
                  </template>
                </GlobalDialog>
              </div>
              <div
                v-else
                :style="{
                  height:
                    !dashboardsData.length > 0 && !isEmpty
                      ? 'calc(100vh - 250px)'
                      : 0,
                }"
              >
                <!-- <div>
              <NoDataOverView :infoData="'No Dashboard Data Found.'" />
            </div> -->
              </div>
            </div>
            <div v-else>
              <div>
                <NoDataOverView :infoData="'No Dashboard Data Found.'" />
              </div>
            </div>
          </div>

          <leadsDrawer
            v-if="isDrawerOpen"
            :drawer.sync="isDrawerOpen"
            :lead="selectedLead"
            :activeTab="'first'"
            :focusNote="true"
            @close="[(isDrawerOpen = false), (selectedLead = null)]"
            :widgetId="widgetId"
            :isDrilldown="isDrawerOpen"
          />
          <GlobalDrawer
            :title="'View Task'"
            :isOpen="isTaskDrawerOpen"
            :drawer-size="750"
            :handleCloseDialog="handleTaskDrawerClose"
            class="task-drawer-dialog"
          >
            <template v-if="isTaskDrawerOpen" v-slot:body>
              <taskDrawer
                v-if="isTaskDrawerOpen"
                :reminderData.sync="reminderDetails"
                @updateTask="updateTask"
                :fieldsArray="returnFieldsForCreateTask()"
                :mode="'view'"
                :taskData="selectedLead"
                :avatars="selectedAvatars"
                @loadMore="debounceCollaboratorLoadMore"
                :widgetId="widgetId"
                @allowDocumentUpload="handleAllowDocUpload"
                :documentUploaded="documentUploaded"
                @documentUploading="documentUploaded = false"
              />
            </template>
          </GlobalDrawer>

          <!-- Create Edit delete Widgets -->
          <GlobalDrawer
            :isOpen="drawerOpen"
            :handleCloseDialog="handleShowOrHideDrawer"
            :drawer-size="750"
            class="task-drawer-dialog"
          >
            <template v-if="drawerOpen" v-slot:header>
              <div class="drawerheader">
                <p>{{ drawerTitle }}</p>
                <!-- <p class="cross" @click="handleShowOrHideDrawer">
                  <b-icon :icon="'x'"></b-icon>
                </p> -->
              </div>
            </template>
            <template v-if="drawerOpen" v-slot:body>
              <div class="drawer-body">
                <CreateEditDrawer
                  :drawerData.sync="drawerData"
                  :widgetId="activeWidgetId"
                  :isCopyDrawer="isCopyDrawer"
                  :selectedChartType="selectedChartType"
                />
              </div>
            </template>
            <template v-if="drawerOpen" v-slot:pinned-footer>
              <div class="footer">
                <el-button @click="handleShowOrHideDrawer">Cancel</el-button>
                <el-button
                  :disabled="isDisabled"
                  type="primary"
                  @click="addWidget"
                  >{{ drawerBtn }}</el-button
                >
              </div>
            </template>
          </GlobalDrawer>
          <GlobalDialog
            dialogTitle="Edit Dashboard"
            :dialogVisible.sync="openDash"
            @handleClose="closeDialog"
            :width="'40%'"
          >
            <template v-slot:bodyya>
              <div class="dialogbody">
                <p class="label">Name</p>
                <el-input
                  v-model="selectedDashboard"
                  :placeholder="'Enter Dashboard Name'"
                />
              </div>
            </template>
            <template v-slot:footer>
              <div class="alignment">
                <el-button type="primary" @click="updateDashboard"
                  >Update</el-button
                >
              </div>
            </template>
          </GlobalDialog>

          <DeleteDialog
            :dialogVisible="deleteOpen"
            :footerButtonType="'primary'"
            :footerButtonText="'Yes'"
            :disableCancel="true"
            :width="'20%'"
            :deleteMessage="'Are you sure you want to delete this widget?'"
            @handleClick="handleDelete"
            @handleClose="deleteOpen = false"
          >
          </DeleteDialog>
        </div>
      </template>
    </GlobalPageHeader>
  </div>
