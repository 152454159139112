
<div :class="{ 'liner': showTitle, 'spacing': detailsData[0]?.title=='Component Details' || detailsData[0]?.title=='Battery Details' }">
  <el-row class="sections" v-for="(proj, index) in detailsData" :key="index">
    <el-row class="section-header" v-if="proj.title && showTitle==0">
      <el-col>{{ proj.title }}</el-col>
    </el-row>
    <el-row v-else-if="!proj.label">
    </el-row>     
    <el-row v-else>
      <el-col :span="6" class="label">{{ proj.label }} </el-col>
      <el-col :span="18" class="value">
      {{
        orderData[proj.prop] ? orderData[proj.prop] : "NA"
      }}</el-col>
    </el-row>
  </el-row>
</div>
