
<div class="mappinngContainer">
    <div class="headerCont">
        <p class="heading">Stages in file</p>
        <p class="heading">Pipeline Stages </p>
    </div>
    <div class="bodyCont">
        <div class="rows" v-for="(key, index) in Object.keys(keyValuePairForStages)" :key="index">
            <div class="positionR">
                <div class="values">
                    <p class="font16Normal">{{ key == "" ? 'Empty' : key }}</p>
                </div>
            </div>
            <el-select v-model="keyValuePairForStages[key]" placeholder="Select Stage" @change="updateButtonStatus">
                <el-option v-for="stage in stages" :key="stage.id" :label="stage.name" :value="stage.id">
                </el-option>
            </el-select>
        </div>
    </div>
</div>
