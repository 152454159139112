<template>
    <div id="parent-container" :class="isPuppeteer ? 'parentPuppeteer' : ''">
        <div :class="[computedPageClass, isPuppeteer ? 'marginRemove' : '']" id="financial-containerDummy"
            style="visibility: hidden;">
            <div class="headerContainerPS">
                <p class="font14Bold">Sr No.</p>
                <p class="font14Bold">Event</p>
                <p class="font14Bold">Description</p>
                <p class="font14Bold">Portion</p>
                <p class="font14Bold leftAlign">Amount</p>
            </div>
            <div class="bodyContainer" id="bodyIDDummy">
                <div class="rowsDummy" v-for="(detail, index) in tableData" :key="index">
                    <p class="font14Normal">{{ index + 1 }}</p>
                    <p class="font14Normal">{{ detail.title }}</p>
                    <p class="font14Normal lineClamp">{{ detail.description }}</p>
                    <p class="font14Normal" v-if="detail.type == 'absolute'">{{ formatNumber(detail.value, 'currency', countryCode) }}</p>
                    <p class="font14Normal" v-else>{{ detail.value }}%</p>
                    <p class="font14Normal leftAlign">{{ formatNumber(detail.amount, 'currency', countryCode)  }}</p>
                </div>
            </div>
        </div>
        <div :class="[computedPageClass, isPuppeteer ? 'marginRemove' : '']" id="financial-container">
            <div class="headerContainerPS">
                <p class="font14Bold">Sr No.</p>
                <p class="font14Bold">Event</p>
                <p class="font14Bold">Description</p>
                <p class="font14Bold">Portion</p>
                <p class="font14Bold leftAlign">Amount</p>
            </div>
            <div class="bodyContainer" id="bodyID">
            </div>
        </div>
    </div>
</template>

<script>

import API from "@/services/api/";
import { useContractManagementStore } from '../../../../stores/contractManagement';
import { mapState , mapActions } from 'pinia';
import {  isUSFlagEnabled} from "@/utils.js";
import {
  formatNumber
} from "../../../../utils/numberFormat/currencyFormatter";
import { useUiStore } from '../../../../stores/ui';

export default {

    data() {
        return {
			token: this.$route.params.token,
            tableData: [],
            visibleDetails: [],
            currentIndex: 0,
            pageCounter: 0,
            countryCode: null,
            isUSFlagEnabledFromParam : this.$route.params.isUSFlagEnabled,
        }
    },
    props:{
        isInPreviewMode:{
            type:Boolean,
            default:false
        }
    },

    async created(){
		await API.SET_TOKEN_HEADER(this.token);
	},

    async mounted() {
        setTimeout(
            this.checkHeightAndClone
            , 1000);
        await this.getTableData();
    },

    computed: {
        ...mapState(useContractManagementStore, {
			CMStore: (state) => state
    	}),
        computedPageClass(){
            let IsUSFlag = isUSFlagEnabled()
            if( IsUSFlag ||  this.isUSFlagEnabledFromParam == 'true' ){
                return 'pageUS';
            }
            else{
                return 'pageNonUS';
            }
        },
        totalContractCost() {
            let sum = 0;
            for (let index = 0; index < this.tableData.length; index++) {
                let total = this.tableData[index].amount;
                sum = sum + total;
            };
            return sum;
        },
        designId(){
            return (this.$route.params.designId || this.CMStore.selectedDesignIdForPreview);
        },
        isPuppeteer() {
            if(this.$route.name == 'paymentSchedulePuppeteer') {
                return true;
            } else {
                return false;
            }
        },
    },

    methods: {
        ...mapActions(useUiStore, {
            hideChatIcon:"hideChatIcon"
        }),

        formatNumber,
        isUSFlagEnabled,
        async getTableData() {
            try {
                let res = await API.PAYMENT_SCHEDULE.GET_DESIGN_SCHEDULE(this.designId)
                this.tableData = res.data;
                this.countryCode = this.tableData[0]?.country_details.country_code;
            } catch (err) {
                this.$message({
                    showClose: true,
                    message: "Unable to fetch payment schedule data.",
                    type: "error",
                    center: true
                });
            }
        },

        paymentScheduleLoaded(){
            let parentDiv = document.getElementById('parent-container');
            setTimeout(()=>{
                this.hideChatIcon()
                parentDiv.classList.add('reportLoaded');
            },500)
        },

        async checkHeightAndClone() {
            await this.$nextTick();
            const element = this.$el.querySelector('#bodyIDDummy');
            let rowsHeight = 0;

            for (let i = this.currentIndex; i < this.tableData.length; i++) {
                this.visibleDetails.push(this.tableData[i]);
                await this.$nextTick();
                const rows = element.querySelectorAll('.rowsDummy');
                if (rows[i]) {
                    rowsHeight += rows[i].clientHeight;
                    if (rowsHeight > 700) {
                        this.visibleDetails.pop();
                        this.clonePage();
                        this.pageCounter++;
                        this.visibleDetails = [];
                        this.visibleDetails.push(this.tableData[i]);
                        rowsHeight = rows[i].clientHeight;
                    };
                };
            };

            if (rowsHeight < 700) {
                this.clonePage();
                this.pageCounter++;
                document.getElementById("financial-containerDummy").style.display = "none";
                document.getElementById("financial-container").style.display = "none";

                // Select the last .rows element
                const rows = document.querySelectorAll('.rows');
                const lastRow = rows[rows.length - 1];

                if (lastRow) {
                    // Create a new div element
                    const footer = document.createElement('div');

                    // Add content or style to the new div
                    footer.classList.add('footerContainer');
                    footer.innerHTML = `
                        <p class="font14Bold">Contract Cost</p>
                        <p class="font14Bold">${formatNumber(this.totalContractCost, 'currency', this.countryCode)}</p>
                    `;
                    // Insert the new div after the last .rows element
                    lastRow.parentNode.insertBefore(footer, lastRow.nextSibling);
                };
            };
            this.paymentScheduleLoaded();
        },

        clonePage() {
            const originalNode = this.$el.querySelector('#financial-container');
            const clone = originalNode.cloneNode(true);
            const cloneBody = clone.querySelector('#bodyID');
            cloneBody.innerHTML = '';
            this.visibleDetails.forEach((detail) => {
                const row = document.createElement('div');
                row.classList.add('rows');
                row.innerHTML = `
                    <p class="font14Normal">${this.currentIndex + 1}</p>
                    <p class="font14Normal">${detail.title}</p>
                    <p class="font14Normal lineClamp">${detail.description}</p>
                    <p class="font14Normal">${ detail.type == 'absolute' ? formatNumber(detail.value, 'currency', this.countryCode) : '' }${ detail.type == 'percentage' ? detail.value+'%' : ''  }</p>
                    <p class="font14Normal leftAlign">${formatNumber(detail.amount, 'currency', this.countryCode)  }</p>
                `;
                cloneBody.appendChild(row);
                this.currentIndex++;
            });

            originalNode.parentNode.appendChild(clone);
        },
    },
}

</script>


<style scoped>
#parent-container{
    display: flex; 
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}
#parent-container ::v-deep .pageUS {
    font-size: 16px;
    min-width: 612px;
    height: 792px;
    max-width: 612px;
    margin: 0 auto;
    margin-bottom: 0px !important;
	padding: 24px 24px 0px 24px !important;
    border: 1px solid #ccc;
}
#parent-container ::v-deep .pageNonUS {
    font-size: 16px;
    min-width: 595px;
    height: 842px;
    max-width: 595px;
    margin: 0 auto;
    margin-bottom: 0px !important;
	padding: 24px 24px 0px 24px !important;
    border: 1px solid #ccc;
}

#parent-container ::v-deep .marginRemove {
    margin: 0 !important;
}

#parent-container ::v-deep .parentPuppeteer {
    gap: 0px !important;
}

#parent-container ::v-deep .font14Bold,
#parent-container ::v-deep .font14Normal {
    font-size: 14px;
    color: #000;
}

#parent-container ::v-deep .font14Bold {
    font-weight: bold;
}

#parent-container ::v-deep .leftAlign {
    text-align: right;
}

#parent-container ::v-deep .headerContainerPS,
#parent-container ::v-deep .rows,
#parent-container ::v-deep .rowsDummy {
    display: grid;
    grid-template-columns: 0.5fr 1fr 2.5fr 1fr 1fr;
    justify-content: space-between;
    gap: 10px;
    padding-bottom: 6px;
    border-bottom: 1px solid #ccc;
}

#parent-container ::v-deep .rows,
#parent-container ::v-deep .rowsDummy {
    padding: 6px 0px;
}

#parent-container ::v-deep .lineClamp {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

#parent-container ::v-deep .footerContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 0px;
    border-bottom: 1px solid #ccc;
}
</style>