import axios from "axios";

export default {
    GET_DESIGN_SCHEDULE(designId) {
        return axios.get(`api/designs/${designId}/payment_schedule/`)
    },
    POST_DESIGN_SCHEDULE(designId, data) {
        return axios.post(`api/designs/${designId}/payment_schedule/`, data)
    },
    UPDATE_DESIGN_SCHEDULE(designId, id, data) {
        return axios.patch(`api/designs/${designId}/payment_schedule/${id}/`, data)
    },
    UPDATE_DESIGN_SEQUENCE(designId, id, sequence) {
        return axios.patch(`api/designs/${designId}/payment_schedule/${id}/`, sequence)
    },
    DELETE_DESING_SCHEDULE(designId, id) {
        return axios.delete(`api/designs/${designId}/payment_schedule/${id}/`)
    },
    FETCH_ALL_PAYMENT_SCHEDULE() {
        return axios.get(`/api/payment-schedule/`);
    },
    POST_ORG_PAYMENT_SCHEDULE(data) {
        return axios.post(`/api/payment-schedule/`, data)
    },
    UPDATE_ORG_PAYMENT_SCHEDULE(id, data) {
        return axios.patch(`/api/payment-schedule/${id}/`, data)
    },
    UPDATE_ORG_PAYMENT_SCHEDULE_SEQUENCE(id, sequence) {
        return axios.patch(`/api/payment-schedule/${id}/`, sequence)
    },
    DELETE_ORG_PAYMENT_SCHEDULE(id) {
        return axios.delete(`/api/payment-schedule/${id}/`)
    },
   
} 