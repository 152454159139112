
<div class="parentContainer">
  <el-dialog
    :visible="isDeleteComponentPopup"
    :close-on-click-modal="false"
    title="Add User"
    width="472px"
    class="delete_module"
    @close="closeModal"
  >
    <div class="close_button">
      <img
        src="../assets/img/Group 166.svg"
        alt="close"
        @click="$emit('cancelDelete')"
      />
    </div>
    <div class="alert">
      <img
        src="../assets/img/alert (1).svg"
        alt="alert"
        class="warningImage"
      />
    </div>
    <p class="msg">Are you sure you want to delete this component?</p>
    <div class="button_container">
      <el-button id="delete-btn" type="primary" @click="$emit('confirmDelete')"
        >Yes</el-button
      >
    </div>
  </el-dialog>
</div>
