
<div class='deleteModule'>
    <el-dialog 
        :visible="isDeleteProjectDocumentPopupOpen"
        :close-on-click-modal="false"
        title="Add User"
        width="30%"
        class="delete_module"
        @close="closeModal"
        append-to-body
    >
        <div class='close_button'>
            <img
                src='../../../home/assets/img/close.svg' 
                alt='close' 
                @click="$emit('cancelDelete')"
            >
        </div>
        <div class='alert'>
            <b-icon
          :style="{
           
            color:iconColor ,
          }"
          class="alert-icon"
          icon="exclamation-triangle"
        ></b-icon>
        </div>
        <p class='msg'>{{ message }}</p>
        <div class='button_container'>
            <el-button type="primary"  @click="confirmDelete()">Yes</el-button>
        </div>
    </el-dialog>
</div>
