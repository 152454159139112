
<div class="mappinngContainer">
    <p v-show="false">{{ isAllFieldsSelected }}</p>
    <div class="headerCont">
        <p class="heading">Fields in CRM</p>
        <p class="heading">Fields in file</p>
        <p class="heading">Default values
            <el-tooltip placement="top-end"
                content="These values will be applied to fields that are missing in your lead information.">
                <b-icon icon="exclamation-circle-fill" style="color: #777; cursor: pointer;"></b-icon>
            </el-tooltip>
        </p>
    </div>
    <div class="bodyCont">
        <div class="rows">
            <p class="label">Full Name<span class="asterisk"> *</span></p>
            <div>
                <el-select v-model="bulkLeadImportState.selectedHeaderValues.fullName"
                @change="validateDuplicateFields()"
                    placeholder="Select field to import">
                    <el-option v-for="item in bulkLeadImportState.selectedHeaderValues.headerKeys" :key="item.value"
                        :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
                <p class="errorMsg" v-if="allValidationSection.showNameError">{{ allValidationSection.nameErrorMsg
                    }}
                </p>
                <p class="errorMsg" v-if="headerValueAndCountMapping[bulkLeadImportState.selectedHeaderValues.fullName]>1">Duplicate value.</p>
            </div>
            <div class="emptyDiv"></div>
        </div>
        <div class="rows">
            <p class="label">Assignee<span class="asterisk"> *</span></p>
            <div>
                <el-select v-model="bulkLeadImportState.selectedHeaderValues.assignTo"
                @change="validateDuplicateFields()"
                    placeholder="Select field to import">
                    <el-option v-for="item in bulkLeadImportState.selectedHeaderValues.headerKeys" :key="item.value"
                        :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
                <p class="errorMsg" v-if="allValidationSection.showAssignToError">{{
                    allValidationSection.assignToErrorMsg }}</p>
                <p class="errorMsg" v-if="headerValueAndCountMapping[bulkLeadImportState.selectedHeaderValues.assignTo]>1">Duplicate value.</p>
            </div>
            <el-select placeholder="Select an Owner"
                @change="validateDuplicateFields()"
                v-model="bulkLeadImportState.defaultFiledsValues.defaultAssignTo" v-infinite-scroll="loadMoreUsers"
                infinite-scroll-disabled="busy" infinite-scroll-distance="10">
                <el-option v-for="owner in bulkLeadImportState.defaultFiledsValues.owners" :key="owner.id"
                    :label="owner.first_name + ' ' + owner.last_name" :value="owner.email">
                </el-option>
            </el-select>
            <p class="errorMsg" v-if="headerValueAndCountMapping[bulkLeadImportState.selectedHeaderValues.defaultAssignTo]>1">Duplicate value.</p>
        </div>
        <div class="rows">
            <p class="label">Email ID<span class="asterisk"> *</span></p>
            <div>
                <el-select v-model="bulkLeadImportState.selectedHeaderValues.emailID"
                    @change="validateDuplicateFields()"
                    placeholder="Select field to import">
                    <el-option v-for="item in bulkLeadImportState.selectedHeaderValues.headerKeys" :key="item.value"
                        :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
                <p class="errorMsg" v-if="allValidationSection.showEmailError">{{
                    allValidationSection.emailIDErrorMsg
                }}</p>
                <p class="errorMsg" v-if="headerValueAndCountMapping[bulkLeadImportState.selectedHeaderValues.emailID]>1">Duplicate value.</p>
            </div>
            <div class="emptyDiv"></div>
        </div>
        <div class="rows">
            <p class="label">Phone Number<span class="asterisk"> *</span></p>
            <div>
                <el-select v-model="bulkLeadImportState.selectedHeaderValues.phoneNo"
                    @change="validateDuplicateFields()"
                    placeholder="Select field to import">
                    <el-option v-for="item in bulkLeadImportState.selectedHeaderValues.headerKeys" :key="item.value"
                        :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
                <p class="errorMsg" v-if="allValidationSection.showPhoneNoError">{{
                    allValidationSection.phoneNoErrorMsg
                }}</p>
                <p class="errorMsg" v-if="headerValueAndCountMapping[bulkLeadImportState.selectedHeaderValues.phoneNo]>1">Duplicate value.</p>
            </div>
            <div class="emptyDiv"></div>
        </div>
        <div class="rows">
            <p class="label">Property Type<span class="asterisk"> *</span></p>
            <div>
                <el-select v-model="bulkLeadImportState.selectedHeaderValues.propertyType"
                    @change="validateDuplicateFields()"
                    placeholder="Select field to import">
                    <el-option v-for="item in bulkLeadImportState.selectedHeaderValues.headerKeys" :key="item.value"
                        :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
                <p class="errorMsg" v-if="allValidationSection.showPropertyTypeError">{{
                    allValidationSection.propertyTypeErrorMsg }}</p>
                <p class="errorMsg" v-if="headerValueAndCountMapping[bulkLeadImportState.selectedHeaderValues.propertyType]>1">Duplicate value.</p>
            </div>
            <div>
                <el-select placeholder="Select a property type"
                    v-model="bulkLeadImportState.defaultFiledsValues.defaultPropertyType" name="propertyType">
                    <el-option v-for="propertyType in bulkLeadImportState.defaultFiledsValues.propertyTypeTypes"
                        :key="propertyType.value" :label="propertyType.label" :value="propertyType.value">
                    </el-option>
                </el-select>
                <p class="errorMsg" v-if="headerValueAndCountMapping[bulkLeadImportState.selectedHeaderValues.defaultPropertyType]>1">Duplicate value.</p>
            </div>
        </div>
        <div class="rows">
            <p class="label">Stage<span class="asterisk"> *</span></p>
            <div>
                <el-select v-model="bulkLeadImportState.selectedHeaderValues.stage"
                    @change="validateDuplicateFields()"
                    placeholder="Select field to import">
                    <el-option v-for="item in bulkLeadImportState.selectedHeaderValues.headerKeys" :key="item.value"
                        :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
                <p class="errorMsg" v-if="headerValueAndCountMapping[bulkLeadImportState.selectedHeaderValues.stage]>1">Duplicate value.</p>
            </div>
            <div class="emptyDiv"></div>
        </div>
        <div class="rows">
            <p class="label">Lead Source<span class="asterisk"> *</span></p>
            <div>
                <el-select v-model="bulkLeadImportState.selectedHeaderValues.leadSource"
                    @change="validateDuplicateFields()"
                    placeholder="Select field to import">
                    <el-option v-for="item in bulkLeadImportState.selectedHeaderValues.headerKeys" :key="item.value"
                        :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
                <p class="errorMsg" v-if="allValidationSection.showLeadSourceError">
                    {{ allValidationSection.leadSourceErrorMsg }}</p>
                <p class="errorMsg" v-if="headerValueAndCountMapping[bulkLeadImportState.selectedHeaderValues.leadSource]>1">Duplicate value.</p>
            </div>
            <el-select placeholder="Select a lead source type"
                v-model="bulkLeadImportState.defaultFiledsValues.defaultLeadSource">
                <el-option v-for="stage in bulkLeadImportState.defaultFiledsValues.leadSourceTypes" :key="stage.id"
                    :label="stage.name" :value="stage.name">
                </el-option>
            </el-select>
            <p class="errorMsg" v-if="headerValueAndCountMapping[bulkLeadImportState.selectedHeaderValues.defaultLeadSource]>1">Duplicate value.</p>
        </div>
        <div class="rows">
            <p class="label">Address</p>
            <div>
                <el-select v-model="bulkLeadImportState.selectedHeaderValues.address"
                @change="validateDuplicateFields()"
                placeholder="Select field to import">
                <el-option v-for="item in bulkLeadImportState.selectedHeaderValues.headerKeys" :key="item.value"
                    :label="item.label" :value="item.value">
                </el-option>
                </el-select>
                <p class="errorMsg" v-if="headerValueAndCountMapping[bulkLeadImportState.selectedHeaderValues.address]>1">Duplicate value.</p>
            </div>
            <div class="emptyDiv"></div>
        </div>
        <div class="rows">
            <p class="label">Target Close Date</p>
            <div>
                <el-select v-model="bulkLeadImportState.selectedHeaderValues.closeDate"
                    @change="validateDuplicateFields()"
                    placeholder="Select field to import">
                    <el-option v-for="item in bulkLeadImportState.selectedHeaderValues.headerKeys" :key="item.value"
                        :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
                <p class="errorMsg" v-if="allValidationSection.showDateFormatError">
                    {{ allValidationSection.dateFormatErrorMsg }}</p>
                <p class="errorMsg" v-if="headerValueAndCountMapping[bulkLeadImportState.selectedHeaderValues.closeDate]>1">Duplicate value.</p>
            </div>
            <DatePicker :label="false" @dateChanged="onDateChange" :disabledPrev="true" placeholder="" />
        </div>
        <div class="rows">
            <p class="label">Deal Value</p>
            <div>
                <el-select v-model="bulkLeadImportState.selectedHeaderValues.dealValue"
                    @change="validateDuplicateFields()"
                    placeholder="Select field to import">
                    <el-option v-for="item in bulkLeadImportState.selectedHeaderValues.headerKeys" :key="item.value"
                        :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
                <p class="errorMsg" v-if="allValidationSection.showDealValueError">{{
                    allValidationSection.dealValueErrorMsg }}</p>
                <p class="errorMsg" v-if="headerValueAndCountMapping[bulkLeadImportState.selectedHeaderValues.dealValue]>1">Duplicate value.</p>
            </div>
            <div class="positionR">
                <el-input placeholder="" v-model="bulkLeadImportState.defaultFiledsValues.defaultDealValue"
                    type="number">
                </el-input>
                <p class="currencySymbolIcon">{{ currencySymbol }}</p>
            </div>
        </div>
        <div class="rows">
            <p class="label">Tags</p>
            <div>
                <el-select v-model="bulkLeadImportState.selectedHeaderValues.tags"
                @change="validateDuplicateFields()" placeholder="Select field to import">
                <el-option v-for="item in bulkLeadImportState.selectedHeaderValues.headerKeys" :key="item.value"
                    :label="item.label" :value="item.value">
                </el-option>
                </el-select>
                <p class="errorMsg" v-if="headerValueAndCountMapping[bulkLeadImportState.selectedHeaderValues.tags]>1">Duplicate value.</p>
            </div>
            <Tags :isCreateLeadMode="true" :isUsingStore="false" @fetchTagsToSendForLead="fetchTagsToSendForLead" />
        </div>
    </div>
</div>
