
<div :class="[zeroPadding?'noPadding dialog-global' :'dialog-global']">
  <CustomOverLay v-if="!isModal||!isModalAppend" :showOverlay="dialogVisible" />
  <el-dialog 
    :title="dialogTitle"
    :visible.sync="dialogVisible"
    :width="width"
    :before-close="handleClose"
    :lock-scroll="true"
    :close-on-click-modal="onClickClose"
    :class="isDrilldown || !dialogTitle ? 'flag-dialog' : ''"
    :modal-append-to-body="isModalAppend"
    :modal="isModal"
  >
  <div :style="dialogBodyStyle"  v-loading="loading">
    <slot name="body" >
      <span>This is a message</span>
    </slot>
  </div>
  <template v-slot:footer>
    <slot name="footer">
      <span class="dialog-footer">
        <el-button @click="handleClose" v-if="!disableCancel"
          >Cancel</el-button
        >
        <el-button
          :type="footerButtonType"
          @click="handleClick"
          :disabled="disableButton"
          >{{ footerButtonText }}</el-button
        >
      </span>
    </slot>
  </template>
  </el-dialog>
</div>
