<template>
    <div class="select-container">
      <p class="title">
        {{ getTitle }} <span v-if="items.isStar" class="astrisk"> *</span>
      </p>
     
      <el-tooltip :disabled="tooltipDisabled" class="item" effect="dark" :content="placeholderText"  placement="top-start" v-if="isDisabled">
        <el-select v-model="selectedItem" :placeholder="items.placeholder" @change="handleSelect" filterable ref="mySelect"
          :disabled="isDisabled" :filter-method="debounceSearchOption" @visible-change="handleVisibleChange"   :loading="loading"  :loading-text="''" loading-icon="el-icon-loading" >
          <template #prefix v-if="selectedIcon">
            <!-- Customize the prefix content here -->
            <span style="padding-top:10px"> <img :src="selectedIcon" alt="" /></span>
          </template>
          <el-option v-for="(item, indx) in uniqueItems" :key="indx" :label="item?.label || item" :value="item || item">
            <div :class="uniqueItems.length - 1 > indx ? 'option-lables' : ''"  v-infinite-scroll="debounceLoadMore" style="overflow: hidden; white-space: nowrap;max-width: 300px;text-overflow: ellipsis; padding-right: 20px; ">
              <p style="overflow: hidden; white-space: nowrap;max-width: 200px;text-overflow: ellipsis; padding-right: 20px; ">
                <!-- <span v-if="item.isSubTitle"> {{ item?.isSubTitle }}</span> -->
                <span v-if="!item.isSubTitle"> {{ item?.label || item?.name||'' }}</span>
              </p>
            </div>
          </el-option>
        </el-select>
      </el-tooltip>
      <el-tooltip :disabled="tooltipDisabled" class="item" effect="dark" :content="selectedItem=='NA'? 'Please select a value to update':selectedItem" placement="top-start" v-else-if="selectedItem " >
      <el-select  v-model="selectedItem" :placeholder="items.placeholder" @change="handleSelect" filterable
        ref="mySelect" :disabled="isDisabled" :filter-method="debounceSearchOption"  @visible-change="handleVisibleChange"  :loading="loading"  :loading-text="''" loading-icon="el-icon-loading"  >
        <template #prefix v-if="selectedIcon">
          <!-- Customize the prefix content here -->
          <span style="padding-top:10px"> <img :src="selectedIcon" alt="" /></span>
        </template>
        <el-option v-for="(item, indx) in uniqueItems" :key="indx" :label="item?.label || item" :value="item || item">
          <div :class="uniqueItems.length - 1 > indx ? 'option-lables' : ''"  v-infinite-scroll="debounceLoadMore" style="overflow: hidden; white-space: nowrap;max-width: 300px;text-overflow: ellipsis; padding-right: 20px; ">
            <p style="overflow: hidden; white-space: nowrap;max-width: 200px;text-overflow: ellipsis; padding-right: 20px; ">
              <span v-if="!item.isSubTitle"> {{ item?.label || item?.name||'' }}</span>
            </p>
          </div>
        </el-option>
      </el-select>
    </el-tooltip>
    
    <el-select v-else v-model="selectedItem" :placeholder="items.placeholder" @change="handleSelect" filterable
        ref="mySelect" :disabled="isDisabled" :filter-method="debounceSearchOption"   @visible-change="handleVisibleChange" :loading="loading"  :loading-text="' '" :loading-icon="'el-icon-loading'" >
        <template #prefix v-if="selectedIcon">
          <!-- Customize the prefix content here -->
          <span style="padding-top:10px"> <img :src="selectedIcon" alt="" /></span>
        </template>
        <div v-loading="loading">
        <el-option v-for="(item, indx) in filteredUniqueItems" :key="indx" :label="item?.label || item" :value="item || item" >
          <div :class="uniqueItems.length - 1 > indx ? 'option-lables' : ''"  v-infinite-scroll="handleLoadmore"  infinite-scroll-distance="10" style="max-width: 200px;overflow: hidden; white-space: nowrap;max-width: 300px;text-overflow: ellipsis; padding-right: 20px; " >
            <p style="width:200px;overflow: hidden; white-space: nowrap;max-width: 200px;text-overflow: ellipsis; padding-right: 20px; ">
              <span v-if="!item?.isSubTitle"> {{ item?.label || item?.name||'' }}</span>
            </p>
          </div>
        </el-option>
        <el-option v-if="!uniqueItems" :key="-1" :label="'No options available'" :value="null"></el-option>
      </div>
      </el-select>
    </div>
  </template>
  
  <script>
  import KpiIcon from "./assests/drawerIcons/kpi.svg";
  import BarIcon from "./assests/drawerIcons/bar.svg";
  import ColumnIcon from "./assests/drawerIcons/column.svg";
  import DonutIcon from "./assests/drawerIcons/donut.svg";
  import TrendLineIcon from "./assests/drawerIcons/lineGraph.svg";
  import SimpleTable from "./assests/drawerIcons/simple_t.svg";
  import GroupedTable from "./assests/drawerIcons/groupedTable.svg";
  import MapIcon from "./assests/drawerIcons/map.svg";
import API from "../../../../services/api";
import infiniteScroll from "vue-infinite-scroll";
import debounce from "debounce";
import { placeholder } from "lodash/bind";


  
  export default {
    emits: [
    "item-selected",
    "update:isCustomTime",
    "update:isCustomTimeActivity",
    "update:isCustomTimeClosed",
    "update:isGrouped",
    ],

    name: "selectComponentView",
  
    props: {
      items: {
        type: Object,
        default()  { 
          return {}
        },
      },
      selectedValue: {
        type: [String, Number],
        default: '',
      },
      selected: {
        type: String,
        default: () => "",
      },
      indexOfOptions: {
        type: Number,
        default: null,
      },
      isGrouped: {
        type: Boolean,
        default: false,
      },
      widgetType: {
        type: String,
        default: "",
      },
      isCustomTime: {
        type: Boolean,
        default: false
      },
      isCustomTimeClosed: {
        type: Boolean,
        default: false
      },
      isCustomTimeActivity: {
        type: Boolean,
        default: false
      },
      seletedSource: {
        type: String,
        default: ''
      },
      placeholderText: {
        type: String,
        default: 'Select'
      },
      options:{
        type:Array,
        default() {
          return []
        }
      }
    },

  
    data() {
      return {
        selectedItem: "",
        radio: "",
        selectedData: "",
        optionsData: [],
  
        KpiIcon,
        BarIcon,
        ColumnIcon,
        DonutIcon,
        TrendLineIcon,
        SimpleTable,
        GroupedTable,
        MapIcon,
        iconsData: {
          kpi: KpiIcon,
          bar: BarIcon,
          column: ColumnIcon,
          donut: DonutIcon,
          trendline: TrendLineIcon,
          simpletable: SimpleTable,
          groupedtable: GroupedTable,
          map: MapIcon,
        },
        groupedCheck: false,
        selectedIcon: '',
        nextUrl:'',
        optionsList:[],
        loading:false,
        loadingMore:false,
        busy:false,
        count:0,
        tooltipDisabled:false,

      };
    },
    computed: {
      filteredUniqueItems() {
        return this.uniqueItems.length>0 ? this.uniqueItems : [];
      },
      getTitle() {
        let title = "";
        if (this.$props.widgetType === "Donut" && this.$props.items.titleType) {
          title = this.$props.items.titleType;
        } else {
          title = this.$props.items.title;
        }
  
        return title;
      },
  
      isDisabled() {
        if ((this.$props.items.prop !== 'type' && this.$props.items.prop !== 'source') && !this.$props.seletedSource) {
          return true
        } else
          return false
      },
      uniqueItems() {
    // Create an object to store unique items based on 'id'
    const uniqueItemsMap = {};

    // Filter out the duplicate items and keep only the first occurrence of each 'id'
    this.optionsList.forEach(item => {
      if (!uniqueItemsMap[item.id]) {
        uniqueItemsMap[item.id] = item;
      }
    });

    // Convert the unique items object back to an array
    const uniqueItemsArray = Object.values(uniqueItemsMap);

    return !this.optionsList?null: uniqueItemsArray;
  }
    },

    created(){
        this.debounceLoadMore = debounce(this.LoadMoreData, 300);
this.debounceSearchOption=debounce(this.fetchOptionsFromAPI, 300);

    },
    mounted() {
  this.nextUrl=this.$props.items?.nextUrl;
  const dataValue=[...this.$props.items?.options];
  this.optionsList=dataValue
  // let updatedData=this.$props.items;
  if(dataValue&&this.$props.items.optionObject){
const checkValue= dataValue.find(dv=>dv.id===this.$props.selectedValue);
if(!checkValue){
  dataValue.push(this.$props.items?.optionObject);
  this.optionsList=dataValue;
  // updatedData.options=dataValue;
  //       this.$emit('update:items',updatedData)
}
  }

  if(!this.$props.selectedValue||this.$props.items?.noData=='NA'){

    this.$emit(
          "item-selected",
          this.$props.items.prop,
        '',
         "",
          this.$props.items,
          this.$props.indexOfOptions,
          this.groupedCheck,
          "",
          "",
          {}

        );


      }
      this.selectedItem =this.$props.selectedValue? this.$props.selectedValue:this.$props.items?.noData=='NA'?"NA": '';

    },
    updated() {
  
    },
  
    methods: {
      handleLoadmore(){
        this.loadingMore=false;
    this.debounceLoadMore()
      },
      handleVisibleChange(visible) {
      if (!visible ) {
        this.debounceSearchOption("")
        // Reset the search value when the dropdown is closed without selection
        // this.$refs.mySelect.$refs.input.$refs.input.value = "";

      }
    },
      async  LoadMoreData(){
        this.count++;
      
        // let updatedData=this.$props.items;
this.busy=true;
        if (this.loadingMore||this.count>1) {
          this.count=0;
      return;
    }

    try{
      if(this.nextUrl!==null&&this.nextUrl){
              
              let { data } = await API.ADDERS_DISCOUNTS.LOAD_MORE_ADDERS(
        this.nextUrl
      );
      const resp=data.results?data.results:data;
      resp.map(d=>{
        d.label=d?.label||d?.name||d
      })
      this.nextUrl=null;
      this.optionsList.push(...resp)
      this.nextUrl=data?.next;
          }
          this.loadingMore=true;

    }catch(err){
      console.error()
    }

    this.busy=false;

      
        },
      async fetchOptionsFromAPI(query) {
        if(query){
          this.tooltipDisabled=true;

        }
        this.loading=true;
        // this.selectedItem=query;
        this.loadingMore=true;
        this.count=2;
        let queryParams='';
        // let updatedData=this.$props.items;
        queryParams = {
          related_model_id: this.$props.items?.related_model_id||"",

          contains: query,
        };
        this.nextUrl='';
        this.optionsList=[];

        if (queryParams) {
        let params = new URLSearchParams(queryParams).toString();

        try {
          const { data } =
            await API.CRM_DASHBOARD.GET_WIDGET_FILTER_VALUE_OPTIONS(params);
this.nextUrl=data?.next||'';
let optionsData=data.results?data.results:data;
optionsData.map(op=>{
    op.label=op?.label||op?.name||op;
    op.value=op?.value||op?.label||op?.name||op;
});

this.optionsList=optionsData;

        // updatedData.options=optionsData;
        // this.$emit('update:items',updatedData)
        } catch (err) {
          console.error(err);
        }
        this.loading=false;
      }
      
      },


  
  
      getIcons(url) {
  
        return this.iconsData[url];
        // return new URL(url, import.meta.url).href;
      },
  
      handleSelect(value) {
        
        if (value?.icon) {
          this.selectedIcon = this.iconsData[value.icon]
        }
        this.selectedData = value;
  
        if (this.$props.items.prop === "time_range") {
          if (value.value === 'custom') {
            this.$emit('update:isCustomTime', true)
          } else {
            this.$emit('update:isCustomTime', false)
          }
        }
  
        if (this.$props.items.prop === "activity_time") {
          if (value.value === 'custom') {
            this.$emit('update:isCustomTimeActivity', true)
            this.$emit('update:isCustomTimeClosed', false)
          } else {
            this.$emit('update:isCustomTimeActivity', false)
          }
        }
        if (this.$props.items.prop === "closed_at") {
          if (value.value === 'custom') {
            this.$emit('update:isCustomTimeClosed', true)
            this.$emit('update:isCustomTimeActivity', false)
          } else {
            this.$emit('update:isCustomTimeClosed', false)
          }
        }
  
  
        if (this.$props.items.prop === "type") {
  
          if (value.label === "TableType - Grouped") {
            this.groupedCheck = true
            this.$emit("update:isGrouped", true);
          } else {
            this.groupedCheck = false
            this.$emit("update:isGrouped", false);
          }
        }
        this.selectedItem = value.label ? value.label : value;
  
        let tableCheck = "";
        if (
          this.$props.items.prop === "type" &&
          (value.label === "TableType - Simple" ||
            value.label === "TableType - Grouped")
        ) {
          tableCheck = JSON.parse(JSON.stringify(value));
  
          tableCheck.label = "Table";
          tableCheck.value = "Table";
        } else {
          tableCheck = "";
        }
        setTimeout(() => {
          if (this.$refs.mySelect) {
            this.$refs.mySelect.blur();
          }
        }, 0);
  
  
  
  
        // if (value !== "Table") {
        this.$emit(
          "item-selected",
          this.$props.items.prop,
          tableCheck ? tableCheck : value,
          tableCheck.value ? tableCheck.value : value.value ? value.value :value.label==='None'?null: value,
          this.$props.items,
          this.$props.indexOfOptions,
          this.groupedCheck,
          "",
          "",
          value

        );
        // }
      },
    },
  
    watch: {
      options:{
immediate:true,
deep:true,
handler(val){

}
      },
  
      selectedValue: {
        handler(val) {
          if(!val){
            this.debounceSearchOption('')
          }
          if (this.$props.items.prop === "type") {

            const selectedType = this.$props.items?.options.find(d => d.label === val)
            this.selectedIcon = this.iconsData[selectedType.icon]
  
  
            if (
              val === "TableType - Grouped" ||
              this.selectedItem === "TableType - Grouped"
            ) {
              this.$emit("update:isGrouped", true);
            } else if (
              val === "Table" &&
              this.$props.isGrouped &&
              this.selectedItem !== "TableType - Simple"
            ) {
              this.$emit("update:isGrouped", true);
            } else {
              this.$emit("update:isGrouped", false);
            }
          }
          if (
            val === "Table" ||
            this.selectedItem === "TableType - Grouped" ||
            this.selectedItem === "TableType - Simple"
          ) {

            if (
              this.$props.isGrouped ||
              this.selectedItem === "TableType - Grouped"
            ) {
              this.selectedItem = "TableType - Grouped";
            } else {
              {
                this.selectedItem = "TableType - Simple";
              }
            }
          } else {
            this.selectedItem =this.$props.items?.noData=='NA'?"NA" : val;
          }
        },
      },
      radio: {
        // immediate: true,
        handler(val) {
          // if (!val) {
          //   this.radio = "Simple";
          // } else {
          //   this.radio = val;
          // }
  
          if (this.selectedData?.value === "Table") {
            let isGroup = val === "Grouped" ? true : false;
            this.$emit("update:isGrouped", isGroup);
  
            // this.isGrouped = this.radio === "Grouped" ? true : false;
            this.$emit(
              "item-selected",
              this.$props.items.prop,
              this.selectedData,
              this.selectedData.value
                ? this.selectedData.value
                : this.selectedData,
              this.$props.items,
              this.$props.indexOfOptions,
              isGroup
            );
          }
        },
      },
      selectedData(value) {
        if (value) {
          this.tooltipDisabled=false;
          // if (value.value !== "Table") {
          //   this.$emit(
          //     "item-selected",
          //     this.$props.items.prop,
          //     value,
          //     value.value ? value.value : value,
          //     this.$props.items,
          //     this.$props.indexOfOptions,
          //     false
          //   );
          // }
        }
        //   this.selectedItem = null;
      },
      items: {
        immediate: true,
        deep:true,
        handler(val) {
          this.optionsList=[]
          if(val.options){
            this.optionsList=val.options;
          }
          const arr = JSON.parse(JSON.stringify(val.options));
          const tableIndex = arr.findIndex((item) => item.label === "Table");
  
          if (tableIndex !== -1) {
            // Remove the object with label "Table"
            arr.splice(tableIndex, 1);
  
            // Insert two new objects after it
            arr.splice(
              tableIndex,
              0,
              {
                isSubTitle: "",
                label: "TableType - Simple",
                value: "TableType - Simple",
                icon: "simpletable",
              },
              {
                isSubTitle: "",
                label: "TableType - Grouped",
                value: "TableType - Grouped",
                icon: "groupedtable",
              }
            );
          }
          this.optionsData = arr;
        },
      },
      isGrouped: {
        immediate: true,
        handler(val) {
          if (val === true) {
            this.radio = "Grouped";
          } else {
            this.radio = "Simple";
          }
        },
      },

    },
  };
  </script>
  
  <style scoped>
  .select-container {}
  
  .title {
    padding-bottom: 0.5rem;
    font-size: 12px;
    color: #777;
    font-weight: 600;
  }
  
  .el-select {
    width: 100%;
  }
  
  .select-container>>>.el-input__inner {
    border: none;
    background: #e8edf2;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 40px;
    color: black
  }
  
  .select-container>>>.el-select .el-input .el-select__caret {
    color: black;
    font-size: 20px;
  }
  
  .select-container>>>.el-input__inner::placeholder {
    font-size: 14px;
    color: #777;
  }
  
  .el-select-dropdown__item {
    min-height: 42px !important;
    height: auto !important;
    line-height: 40px !important;
    padding: 0px 12px !important;
    color: black;
   width:200px;overflow: hidden; white-space: nowrap;max-width: 200px;text-overflow: ellipsis; padding-right: 20px; 
  }
  .el-select-dropdown__empty{
    width:200px !important;
  }
  
  .el-select-dropdown__item.selected {
    color: black !important;
    font-weight: 500;
  }
  
  .select-container>>>.el-input__prefix {
    padding-top: 10px !important
  }
  
  .option-lables {
    border-bottom: 1px solid #ccc;
    color: black;
  }
  
  .icons {
    padding-right: 0.5rem;
  }
  
  .el-radio__inner {
    width: 1rem !important;
    /* Adjust this value to increase or decrease the size of the dot */
    height: 1rem !important;
    /* Adjust this value to increase or decrease the size of the dot */
  }
  
  .select-container>>>.el-radio__input.is-checked .el-radio__inner::after {
    transform: translate(-50%, -50%) scale(2.5) !important;
    background: white;
  }

  .astrisk {
    font-size: 12px;
    font-weight: 600;
    color: #f00;
  }
  </style>
  