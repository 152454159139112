import axios from "axios";

export default {
    GET_HEAT_MAP_DATA_URL(design_id) {
        return axios.get(`/api/designs/${design_id}/heatmap_image/`);
    },

    SAVE_HEAT_MAP_DATA_URL(design_id, payload) {
        return axios.patch(`/api/designs/${design_id}/heatmap_image/`, payload);
    }
};