<template>
  <div :class="{ 'liner': showTitle, 'spacing': detailsData[0]?.title=='Component Details' || detailsData[0]?.title=='Battery Details' }">
    <el-row class="sections" v-for="(proj, index) in detailsData" :key="index">
      <el-row class="section-header" v-if="proj.title && showTitle==0">
        <el-col>{{ proj.title }}</el-col>
      </el-row>
      <el-row v-else-if="!proj.label">
      </el-row>     
      <el-row v-else>
        <el-col :span="6" class="label">{{ proj.label }} </el-col>
        <el-col :span="18" class="value">
        {{
          orderData[proj.prop] ? orderData[proj.prop] : "NA"
        }}</el-col>
      </el-row>
    </el-row>
  </div>
</template>

<script>
export default {
  computed:{
    detailsData(){
      if(this.$props.orderData['revision_version'] ){
        return this.$props.DetailsData

      }else{
        return this.$props.DetailsData.filter(d=>d.prop!=='revision_version')
      }
    }
  },
  props: {
    DetailsData: {
      type: Array,
      default() {
        return []
      },
    },
    orderData: {
      type: [Object,Number],
    },
    
    showTitle: {
      type: Number,
      default: 0,
    }
  },
};
</script>

<style scoped>
.section-header {
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: 600;
}
.sections {
  margin-top: 0.5rem;
}
.label {
  color: grey;
}
.liner {
  border-top: 1px solid;
}

.spacing{
  padding-bottom: 0.5rem;
}
</style>
