<template>
    <section :class="['right_section', isCrmUser() ? 'right_sectionCRM' : '', isDragEnabled ? 'disable-text-selection' : '']">
        <div class="content_section">
            <div class="filter_section">
                <p class="backBtn" @click="handleBack()">{{backAction}}</p>
                <div class="flexCont">
                    <div class="btnFlexCont" v-show="!isEditTemplateMode && !isPreviewMode">
                        <el-button class="previewBtn btn" @click="handleCancel">Cancel</el-button>
                        <el-tooltip :disabled="enableSave">
                            <template v-slot:content>
                                <div>Please wait for the template files to load</div>
                            </template>
                            <el-button class="saveBtn btn" type="primary" :disabled="!enableSave" @click="createTemplate">Save</el-button>
                        </el-tooltip>
                    </div>
                    <el-button class="previewBtn btn" @click="openLinkToPreview" v-if="isPreviewMode || isEditTemplateMode">Preview</el-button>
                    <el-button :class="[formData.is_default || !canUserEdit() ? 'hidden-icon' : '', 'saveBtn btn']" type="primary" @click="openEditMode" v-if="isPreviewMode">Edit Form</el-button>
                </div>
            </div>
            <div class="box-card">
                <div class="custom-scroll" 
                    :style="{height: `${scrollThumbHeight}px`, 
                    top: `${scrollTopValue}px`}"
                    @mouseup="endDrag"
                    @mousemove="handleDrag"
                    @mouseleave="isDragEnabled = false"
                    v-if="showCustomScroll"
                >
                    <div @mousedown="startDrag" class="custom-scroll-thumb">
                    </div>
                </div>
                <div class="bodyContainer" @scroll="handleScroll" ref="scrollRef">
                    <div class="flexContTwo" v-if="!isPreviewMode">
                        <div class="inputsCont">
                            <div class="flexColumn">
                                <label for="" class="labelClass">Form Title</label>
                                <el-input placeholder="Form Title" v-model="formData.title" @change="prepareFormObjectToStore('title')"></el-input>
                            </div>
                            <div class="flexColumn">
                                <label for="" class="labelClass">Form Description</label>
                                <el-input class="placeholderPadding" type="textarea" :rows="3" placeholder="Form Description" v-model="formData.description" @change="prepareFormObjectToStore('description')"></el-input>
                            </div>
                        </div>
                    </div>
                    <div class="previewContainer" v-else>
                        <div class="flexContPV">
                            <p class="heading">{{formData.title}}</p>
                        </div>
                        <hr class="lineHR"/>
                        <p class="description">{{filteredDescription}}
                        </p>
                    </div>
                    <div class="sectionCards">
                            <div v-for="(section, index) in formData.sections" :key="index"  style="width: 100%">
                                <SectionCard 
                                :sectionIndex="index +1" 
                                :sectionsLength="formData.sections.length"
                                :section="section"
                                :templateId="templateId"
                                @addSection="addSection" 
                                @deleteCurrentSection="deleteCurrentSection"
                                @duplicateCurrentSection="duplicateCurrentSection"
                                @addFieldToSection="addFieldToSection"
                                @deleteCurrentField="deleteCurrentField"
                                @duplicateCurrentField="duplicateCurrentField"
                                @duplicateCurrentFile="duplicateCurrentFile"
                                @deleteCurrentFile="deleteCurrentFile"
                                @patchTemplate="propagatePatchDataFromDescendants"
                                @checkScrollThumb="checkScrollThumb"
                                ref="sectionsRef" 
                                />   
                            </div>
                    </div>
                </div>
            </div>
        </div>
        <DeleteDialog :dialogVisible="openCancelChangesDialog" :footerButtonType="'primary'" :footerButtonText="'Yes'"
            :disableCancel="true" :width="'20%'" :deleteMessage="`Do you really want to leave? `"
            :infoMessage="` You have unsaved changes!`" @handleClick="showSiteSurveyFormFunc" @handleClose="openCancelChangesDialog = false">
        </DeleteDialog>
    </section>
</template>
    
<script>
import API from '@/services/api';
import debounce from "debounce";
import { isCrmUser, isAdmin } from "../../../utils";
import SectionCard from "./sectionCard.vue";
import DeleteDialog from '@/pages/dashboardCRM/components/dashboardV2/deleteDialog.vue';
import { mapState, mapActions } from 'pinia';
import { useEditableSiteSurveyStore } from "../../../stores/editableSiteSurvey";
import { DATABASE_URL_FOR_SITE_SURVEY,  } from "../../../constants";
export default {
    emits: [
        "toggleComponent",        
        "addSection",             
        "deleteCurrentSection",   
        "duplicateCurrentSection",
        "addFieldToSection",      
        "duplicateCurrentField",  
        "deleteCurrentField",     
        "duplicateCurrentFile",   
        "deleteCurrentFile",      
        "patchTemplate",          
        "createTemplate",         
    ],
    components: {
        SectionCard,
        DeleteDialog,
    },
    props:{
        formData:{
            type: Object,
        },
        templateId: {
            type: Number,
            required: false,
        },
        isNewTemplate: {
            type: Boolean,
            required: false,
        },
        isDuplicateTemplate: {
            type: Boolean,
            required: false,
        },
    },
    mounted(){
        this.currentWindowHeight = window.innerHeight
        const vm = this;
        window.addEventListener("beforeunload", this.onConfirmRefresh);
        window.addEventListener(
            "resize",
            function(e){
                this.handleResize(e)
            }.bind(vm)
        );
        this.enableSaveButton();
    },
    unmounted() {
        window.removeEventListener('beforeunload', this.onConfirmRefresh);
    },
    data() {
        return {
            title:'',
            description:'',
            openCancelChangesDialog: false,
            scrollTopPercentage: 0,
            currentWindowHeight: null,
            isDragEnabled: false,
            showCustomScroll: false,
            headerHeight: 101, // height of the header
            percentageDiff: 0,
            enableSave: true,
        };
    },
    computed: {
        ...mapState(useEditableSiteSurveyStore, {
            getTemplateDataFromStore: "GET_FORM_DATA",
            changeInFormData: "GET_FORM_DATA_FLAG",
            isPreviewMode : "GET_PREVIEW_FLAG",
            duplicateTemplateFilesLoadedFlag:"GET_DUPLICATE_TEMPLATE_FILES_LOADED_FLAG",
            newFilesUploadedFlag:"GET_NEW_FILES_UPLOADED_FLAG",
        }),
        filteredDescription(){
            if (!this.formData.description) return ''
            return this.formData.description.replaceAll('\\n', "");
        },
        scrollThumbHeight(){
            if(!this.$refs.scrollRef) return this.currentWindowHeight * 0.20
            let thumbHeight = this.currentWindowHeight * (this.percentageDiff/100) - this.headerHeight
            return thumbHeight < 100 ? 100 : thumbHeight
        },
        scrollTopValue(){
            return ((this.currentWindowHeight - this.headerHeight - this.scrollThumbHeight) * this.scrollTopPercentage/100) + this.headerHeight
        },
        isEditTemplateMode(){
            // to determine the actual edit template mode
            return this.templateId !== 0 && !this.isPreviewMode;
        },
        backAction(){
            if(this.isEditTemplateMode){
                return "Back to Preview";
            }
            else if(this.templateId && this.isPreviewMode)
                return this.formData.title;
            return "Back";
        }
    },
    methods: {
        isCrmUser,
        isAdmin,
        ...mapActions(useEditableSiteSurveyStore, {
            addRequiredKeysToFormData:"ADD_REQUIRED_KEYS_TO_FORMDATA",
            setPreviewModeFalse : "SET_PREVIEW_MODE_FALSE",
            setPreviewModeTrue : "SET_PREVIEW_MODE_TRUE",
        }),
        enableSaveButton(){
            if(this.isNewTemplate){
                this.enableSave = this.newFilesUploadedFlag;
            }
            else if(this.isDuplicateTemplate)
                this.enableSave = this.duplicateTemplateFilesLoadedFlag && this.newFilesUploadedFlag;
            else this.enableSave = true;
        },
        canUserEdit(){
            const user = JSON.parse(localStorage.getItem("user")) || {};
            if(this.isAdmin() || user.user_id === this.formData.created_by){
                return true;
            }
            return false;
        },
        scrollTopMax(){
            return (this.$refs.scrollRef.scrollHeight - this.$refs.scrollRef.offsetHeight)
        },
        checkScrollThumb(){
            setTimeout(() => {
                this.percentageDiff = (this.$refs.scrollRef?.offsetHeight / this.$refs.scrollRef?.scrollHeight) * 100
                this.showCustomScroll = this.$refs.scrollRef?.scrollHeight > this.$refs.scrollRef?.offsetHeight || false;
            }, 500)
        },
        handleDrag(e){
            if(!this.isDragEnabled) return
            let incrementValue = this.scrollTopMax() * 0.001 < 2 ? 2 : this.scrollTopMax() * 0.001
            if(e.movementY > 0){
                if(this.$refs.scrollRef.scrollTop < this.scrollTopMax()){
                    for(let i = 0; i<e.movementY; i++) this.$refs.scrollRef.scrollTop += incrementValue
                }else{
                    this.$refs.scrollRef.scrollTop = this.scrollTopMax()
                }
            }else if(e.movementY < 0){
                if(this.$refs.scrollRef.scrollTop > 0){
                    for(let i = 0; i<(-1*e.movementY); i++) this.$refs.scrollRef.scrollTop -= incrementValue
                }else{
                    this.$refs.scrollRef.scrollTop = 0
                }
            }
        },
        startDrag(){
            this.isDragEnabled = true;
        },
        endDrag() {
            this.isDragEnabled = false
        },
        handleScroll(){
            this.scrollTopPercentage = 100 * (this.$refs.scrollRef.scrollTop / this.scrollTopMax());
        },
        handleResize(){
            this.currentWindowHeight = window.innerHeight;
        },
        openLinkToPreview(){
            const previewUrl = `${DATABASE_URL_FOR_SITE_SURVEY}siteSurveyPreview/${this.templateId}`;
            window.open(previewUrl, '_blank');
        },
        handleCancel(){
            //if we are creating template, go back to the list page if there are no changes made
            //if we are on template edit, we need to go back to the preview page if there are no changes made
            // on edit, the formData will have the created_at && created_by properties
            if(!(this.formData.created_at || this.formData.created_by)){
                this.handleBack();
            }
            else{
                this.setPreviewModeTrue();
                this.handleBack();
            }
        },
        handleBack() {
            if (this.changeInFormData) {
                this.openCancelChangesDialog = true;
            } else {
                this.showSiteSurveyFormFunc();
            }
        },
        onConfirmRefresh(event) {
            if(this.changeInFormData){
                event.preventDefault();
                event.returnValue = 'required';
            }
        },
        showSiteSurveyFormFunc() {
            this.$emit('toggleComponent');
        },
        ...mapActions(useEditableSiteSurveyStore, {
            updateFormData:"UPDATE_FORM_DATA",
        }),
        addSection(sectionIndex){
            this.$emit("addSection",sectionIndex, () => {
                this.scrollToSection(sectionIndex + 1);
            });
        },
        deleteCurrentSection(sectionIndex){
            this.$emit("deleteCurrentSection",sectionIndex);
        },
        duplicateCurrentSection([sectionToCopy, sectionIndex]){
            this.$emit("duplicateCurrentSection", [sectionToCopy, sectionIndex], () => {
                this.scrollToSection(sectionIndex + 1);
            });
        },
        addFieldToSection(sectionIndex, callback){
            this.$emit("addFieldToSection", sectionIndex, callback);
        },
        duplicateCurrentField([fieldIndex,sectionIndex], callback){
            this.$emit("duplicateCurrentField", [fieldIndex, sectionIndex], callback)
        },
        deleteCurrentField([fieldIndex,sectionIndex]){
            this.$emit("deleteCurrentField", [fieldIndex,sectionIndex]);
        },
        duplicateCurrentFile([fileIndex, fieldIndex, sectionIndex]){
            this.$emit("duplicateCurrentFile", [fileIndex, fieldIndex, sectionIndex]);
        },
        deleteCurrentFile([fileIndex, fieldIndex, sectionIndex]){
            this.$emit("deleteCurrentFile", [fileIndex, fieldIndex, sectionIndex]);
        },
        scrollToSection(sectionIndex){
            this.$nextTick(() => {
                // Get a reference to the newly added section
                const newSectionRef = this.$refs.sectionsRef[sectionIndex];
                if (newSectionRef) {
                    newSectionRef.$el.scrollIntoView({
                    block: 'center', // Scroll to the top of the screen vertically
                    });
                }
            });
        },
        prepareFormObjectToStore(propertyName){
            if(this.isEditTemplateMode){
                let templateBody = {
                    [propertyName]: this.formData[propertyName].trim(),
                    isTemplateLevel: true,
                }
                this.$emit("patchTemplate",templateBody);
            }
            else
                this.updateFormData(this.formData);
        },
        propagatePatchDataFromDescendants(descendantBody){
            if(this.isEditTemplateMode){
                this.$emit("patchTemplate",descendantBody);
            }
        },
        createTemplate(){
            this.$emit("createTemplate");
        },
        openEditMode(){
            this.setPreviewModeFalse();
            this.addRequiredKeysToFormData();
        }
    },
    watch: {
        formData: {
            handler(newFormData) {
                // Perform necessary actions when formData is updated
                // For example, update your local data properties
                this.formData = newFormData;
            },
            immediate: true, // Trigger the handler immediately when the component is created
        },
        formData: {
            deep: true,
            immediate: true,
            handler(val){
                this.$nextTick(() => {
                    this.percentageDiff = (this.$refs.scrollRef?.offsetHeight / this.$refs.scrollRef?.scrollHeight) * 100
                    this.showCustomScroll = this.$refs.scrollRef?.scrollHeight > this.$refs.scrollRef?.offsetHeight || false;
                })
            }
        },
        newFilesUploadedFlag: {
            handler(newVal){
                this.enableSaveButton();
            }
        },
        duplicateTemplateFilesLoadedFlag: {
            handler(newVal){
                this.enableSaveButton();
            }
        },
    },
};
</script>
    
<style scoped>
.disable-text-selection{
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}
.custom-scroll{
    width: 40px;
    background-color: transparent;
    position: absolute;
    right: 0;
    animation-timing-function: linear;
    display: flex;
    justify-content: end;
    padding-right: 1px;
}
.custom-scroll-thumb{
    width: 6px;
    background-color: #777777;
    height: inherit;
    border-radius: 8px;
    cursor: pointer;
    opacity: 0.5;
}
p {
    word-break: break-word;
}

.main-controller .right_section {
    background: var(--step-50);
}

.content_section {
    padding: 24px 32px;
    min-height: calc(100vh - 100px);
    word-break: break-word;
}

.filter_section,
.flexCont,
.flexSwitch,
.flexContTwo,
.btnFlexCont,
.flexContPV {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
}

.flexSwitch {
    gap: 8px;
}

.backBtn {
    font-size: 16px;
    color: #777;
    display: flex;
    gap: 10px;
    cursor: pointer;
}

.switchTxt {
    font-size: 16px;
    font-weight: 600;
    color: #555;
}

.backBtn::before {
    content: "";
    background: url('../assets/ArrowLeft.svg');
    width: 20px;
    height: 20px;
    display: block;
}

.btn{
    font-size: 1.125rem;
    font-weight: 600;
    padding: 0.5rem 0.75rem;
    line-height: 1.8125rem;
    letter-spacing: 0px;
    text-align: left;
    letter-spacing: 0px;
    margin-left: 0px !important;
    border-radius: 4px;   
}

.previewBtn {
    background-color: #E8EDF2;
    color: #409EFF;
    border: 2px solid var(--Primary, #409EFF);
}

.box-card {
    margin-top: 20px;
    border-radius: 16px;
    box-shadow: none !important;
    border: 1px solid #ccc;
    background-color: #fff;
}

.bodyContainer {
    margin-bottom: 16px;
    margin-top: 16px;
    padding: 30px;
    padding-top: 14px;
    overflow: hidden;
    overflow-y: scroll;
    height: calc(100vh - 244px);
    scrollbar-width: none;
}
.bodyContainer::-webkit-scrollbar{
    width: 0;
}

.flexColumn {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.content_section>>>.el-input__inner,
.content_section >>> .el-textarea__inner {
    height: 48px;
    background-color: #e8edf2;
    border: none;
    font-size: 16px;
    color: #222;
}

.content_section >>> .el-textarea__inner {
    height: auto;
}

.flexContTwo>>>.el-input__inner::placeholder,
.content_section >>> .el-textarea__inner::placeholder {
    /*color: #777;*/
    color: #999999;

}

.placeholderPadding >>>.el-textarea__inner::placeholder{
    padding-top: 5px;
}

.content_section>>>.el-select {
    height: 45px;
    background-color: #e8edf2;
    width: 100%;
    max-width: none;
    border-radius: 4px;
}

.content_section>>>.el-select .el-input .el-select__caret {
    color: #222;
    font-size: 16px;
    font-weight: 600;
}

.flexContTwo {
    align-items: flex-start;
    gap: 36px;
}

.inputsCont {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    margin-bottom: 40px;
}

.btnFlexCont {
    gap: 1.5rem;
}

.cancelBtn {
    color: #409EFF;
    border: 1px solid #409EFF;
}

.sectionCards {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.heading {
    font-size: 24px;
    color: #1C3366;
}

.editTxt {
    font-size: 16px;
    color: #409EFF;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
}

.lineHR {
    color: #777;
    margin: 24px 0px;
    width: 100%;
}

.description {
    color: #777;
    font-size: 20px;
    margin-bottom: 30px;
    white-space: break-spaces;
}

.hidden-icon {
    display: none;
}

@media (min-width: 1281px) {
    .main-controller .right_section {
        width: calc(100% - 260px);
        margin-left: auto;
    }

    .right_sectionCRM {
        width: calc(100% - 74px) !important;
    }
}
@media (max-width: 900px) {
    .custom-scroll{
        display: none;
    }
}
@media (max-width: 760px) {}
</style>
    