
<div class="pop">
  <el-popover
    placement="bottom-end"
    width="auto"
    trigger="click"
    v-model="popVisible"
    popper-class="popover-custom"
    id="kpop"
  >
    <template v-slot:reference>
    <p style="cursor: pointer">
      <b-icon
        :style="{
          marginRight: '5px',
          color: 'black',
        }"
        :icon="'three-dots-vertical'"
      ></b-icon>
    </p>
    </template>
    <div @mouseleave="handleMouseleave" class="popover-content">
      <div v-for="(data, inx) in options" :key="inx">
        <p class="content-container" @click="handleSelect(data)">
          <span v-if="data.icon" class="icons"
            ><img :src="getIcon(data.icon)" alt=""
          /></span>
          {{ data.label }}
        </p>
      </div>
    </div>
  </el-popover>
</div>
