
<div>
    <div class="main-card"  v-for="(notification , index) in notificationsList" :key="index" @click="handleClick(notification)">
        <div class="main-card-div" :style="{'--padding-left': getAvatarExist(notification) ? '5px' : '35px'}">
            <p
                :class="[$props.isSlide ? 'flex-card-slide' : 'flex-card']"
                :style="{maxWidth: $props.maxWidth}" 
                :key="index"
            >
                <span
                    v-for="(comp, index) in notification.notification_details.components"
                    :key="index"
                >
                    <template v-if="comp == '{action_by}'">
                        <div class="avatar-container">
                            <el-avatar 
                                :size="24"
                                :style="getAvatarStyle(notification.notification_details.action_by_name)"
                                >{{ generateInitials(notification.notification_details.action_by_name) }}
                            </el-avatar>
                        </div>
                        <span :class="['assigner', notification.notification_details.content_obj_type == 'user' ? 'dark-text' : '' ] "
                        >{{ getFirstName(notification.notification_details.action_by_name) }}</span>
                    </template>
                    <span
                        v-else-if="comp == '{content_object_name}'"
                        class="activity-div"
                        :class="[ isSlide ? 'activity-assignee-toast' : 'activity-assignee', notification.notification_details.content_obj_type == 'user' ? 'dark-text' : '' ]" 
                    >
                        <img
                            v-if="notification.notification_details.content_obj_type == 'lead'"
                            class="person-badge"
                            src="../../assets/img/PersonBadge.svg"
                            alt="person-badge"
                        >
                        <span class="people-icon" v-else-if="notification.notification_details.content_obj_type == 'team'">
                            <b-icon style="width: 1.2em; height: 1.2em" icon="people-fill"></b-icon>
                        </span>
                        <span  v-else-if="notification.notification_details.content_obj_type == 'user'">
                            <el-avatar 
                                :size="24"
                                :style="getAvatarStyles(notification.notification_details.content_obj_name)"
                                >{{ generateInitials(notification.notification_details.content_obj_name) }}
                            </el-avatar>
                        </span>

                        <span
                            v-else
                            class="task-icon-container"
                        >
                            <img class="circle-icon" 
                            src="../../assets/img/circleNotif.svg" alt="circle-icon">
                            <img class="tick-icon" 
                            src="../../assets/img/tickNotif.svg" alt="tick-icon">
                        </span>
                        <span class="assignee-text">
                            {{ notification.notification_details.content_obj_name }}
                        </span>
                    </span>
                    <template v-else-if="comp == '{action_for}'" >
                        <span v-if="isSelf(notification.notification_details.action_for)" class="self-span">you</span>
                        <span v-else :class=" ['notification-action-text',  isPopup && 'notification-word-break']" >
                            {{ notification.notification_details.action_for_name }}
                        </span>
                    </template>
                    <span v-else :class=" ['notification-text', isPopup && 'notification-word-break']">
                        {{ comp }}
                    </span>
                </span>
            </p>

            <div class="right-info" v-if="!isSlide">    
                <span class="time">{{ generateNotificationTime(notification.notification_details.created_at) }}</span>
                <span class="blue-dot"> <img src="../../assets/img/blueDotNotif.svg" alt="blue-dot" width="8px" height="8px" v-show="!notification.is_read"> </span>
            </div>
        </div>

    </div>
</div>
