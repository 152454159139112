
<div :class="['parentTabContainer', isWeatherOptions ? 'parentContainerForWeather' : '',
    isTabDisabled ? 'disable' : '']">
    <div :class="['tabBox', isFullWidth ? 'tabBoxFullWidth' : '']" v-if="showTabs">
        <el-radio-group v-model="selectedOption" style="margin-bottom: 30px;">
            <el-tooltip v-for="option in options" :key="option" :content="tooltipMsg"
                placement="top" :disabled="!isTooltipEnabled(option)">
                <el-radio-button :label="option" :disabled="isDisabled(option)" :class="{ 'disabled-tab': isDisabled(option) }"> {{ option }}
                </el-radio-button>
            </el-tooltip>
        </el-radio-group>
    </div>
</div>
