
<div class="datePicker forLabel">
  <!-- <input type="date" id="birthday" name="birthday" v-model="date">
      <date-pick v-model="date" :hasInputElement="false"></date-pick> -->
  <label for="" class="labelClass" v-if="label">Date Picker</label>
  <el-date-picker
    v-model="pickedDate"
    type="date"
    @change="onChange"
    :picker-options="pickerOptions"
    :placeholder="placeholder"
    :format="dateFormat"
  >
  </el-date-picker>
</div>
