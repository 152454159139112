import axios from 'axios';

export default {
    FETCH_ELECTRIC_VEHICLE(id) {
        return axios.get(`/api/master-data/electric-cars/${id}/`);
    },
    FETCH_ALL_ELECTRIC_VEHICLES(){
        return axios.get(`/api/master-data/electric-cars/`);
    },
    LOAD_MORE_ELECTRIC_VEHICLE(url){
        return axios.get(url);
    },
    SEARCH_ELECTRIC_VEHICLE(query) {
        return axios.get(`/api/master-data/electric-cars/?query=${query}`);
    },
}