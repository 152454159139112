import { defineStore } from 'pinia';
import TextLeftIcon from "../pages/siteSurveyForm/assets/TextLeft.svg";
import NumberIcon from "../pages/siteSurveyForm/assets/number.svg";

export const useEditableSiteSurveyStore = defineStore('editableSiteSurveyStore', {
    state : () => ({
        formData: {
            title: '',
            description: '',
            boundaryAdded: false,
            lastBoundaryRemoved: false,
            template_type: '',
            sections: [
                {
                    title: '',
                    description: '',
                    fields: [
                        {
                            label:'',
                            description:'',
                            question_type: '',
                            is_required: false,
                            photos_videos_required: false,
                            options: [],
                            files:[],
                            additional_info: {
                                allowed_types_toggle: false,
                                max_files: '1' ,
                                allowed_types: [],    
                                sequence:0     
                            }, 
                        },
                    ]
                }
            ],
        },
        originalFormData: {
            title: '',
            description: '',
            boundaryAdded: false,
            lastBoundaryRemoved: false,
            template_type: '',
            sections: [
                {
                    title: '',
                    description: '',
                    fields: [
                        {
                            label:'',
                            description:'',
                            question_type: '',
                            is_required: false,
                            photos_videos_required: false,
                            options: [],
                            files:[],
                            additional_info: {
                                allowed_types_toggle: false,
                                max_files: '1' ,
                                allowed_types: [],  
                                sequence:0      
                            }, 
                        },
                    ]
                }
            ],
        },
        questionTypeList:[
            {
                value: 'short_answer',
                label: 'Short Response',
                iconType: 'image',
                icons: TextLeftIcon
            },{
                value: 'paragraph',
                label: 'Paragraph',
                iconType: 'icon',
                icons: 'text-left'
            },{
                value: 'email',
                label: 'Email',
                iconType: 'icon',
                icons: 'envelope'
            },{
                value: 'phone_number',
                label: 'Phone Number',
                iconType: 'icon',
                icons: 'telephone'
            },{
                value: 'number',
                label: 'Numbers',
                iconType: 'image',
                icons: NumberIcon
            },{
                value: 'tab_choice',
                label: 'Selection - Tab',
                iconType: 'icon',
                icons: 'segmented-nav'
            },{
                value: 'multiple_choice',
                label: 'Selection - Multiple Choice',
                iconType: 'icon',
                icons: 'record-circle'
            },
            {
                value: 'checkbox',
                label: 'Selection - Checkboxes (multiple)',
                iconType: 'icon',
                icons: 'check-square'
                
            },{
                value: 'dropdown',
                label: 'Selection - Dropdown (any one)',
                iconType: 'icon',
                icons: 'caret-down-square'
            },{
                value: 'file_upload',
                label: 'Upload File',
                iconType: 'icon',
                icons: 'upload'
            },{
                value: 'slider',
                label: 'Slider',
                iconType: 'icon',
                icons: 'sliders'
            },{
                value: 'poi',
                label: 'POI (Point of Interest) / Draw Path',
                iconType: 'icon',
                icons: 'geo-alt'
            },{
                value: 'boundary',
                label: 'Boundaries',
                iconType: 'icon',
                icons: 'bounding-box-circles'
            },{
                value: 'date',
                label: 'Date',
                iconType: 'icon',
                icons: 'calendar-event'
            },{
                value: 'time',
                label: 'Time',
                iconType: 'icon',
                icons: 'clock'
            },
        ],
        complexQTypes: ['file_upload', 'multiple_choice', 'tab_choice', 'single_choice', 'checkbox', 'dropdown'],
        fileTypeItems: [
            { label: 'Document', value: "document" },
            // { label: 'PDF', value:"pdf" },
            { label: 'Image' , value:"image"},
            { label: 'Video' , value:"video"},
        ],
        sliderSubDetail:[
            {
                label: 'Unit of Measurements',
                value: 'unit_of_measurements',
                type: 'text',
            },
            {
                label: 'Minimum Value',
                value: 'minimum_value',
                lowerLimit: -9999999,
                upperLimit: 9999999,
                type: 'number',
                default: 0,
            },
            {
                label: 'Maximum Value',
                value: 'maximum_value',
                lowerLimit: -9999999,
                upperLimit: 9999999,
                type: 'number',
                default: 100,
            },
            {
                label: 'Step',
                value:'step',
                type:'number',
                default: 1,
            },
        ],
        formDataHasBeenModified: false,
        // flag for separating preview mode from create&edit
        isPreviewMode: false,
        allowFieldPatchFlag: true,
        duplicateTemplateFilesLoadedFlag: true,
        newFilesUploadedFlag: true,
        newFilesStatusArray: [],
        countNewFilesToUpload: 0,
    }),
    getters: {
        GET_COMPLETE_STATE: state => state,
        GET_FORM_DATA: state => state.formData,
        GET_ORIGINAL_FORM_DATA: state => state.originalFormData,
        GET_QUESTIONS_LIST: state => state.questionTypeList,
        GET_COMPLEX_Q_TYPES: state => state.complexQTypes,
        GET_FILE_TYPES: state => state.fileTypeItems,
        GET_SLIDER_SUBDETAIL: state => state.sliderSubDetail,
        GET_FORM_DATA_FLAG: state => state.formDataHasBeenModified,
        GET_PREVIEW_FLAG: state => state.isPreviewMode,
        GET_ALLOW_FIELD_PATCH_FLAG: state => state.allowFieldPatchFlag,
        GET_DUPLICATE_TEMPLATE_FILES_LOADED_FLAG: state => state.duplicateTemplateFilesLoadedFlag,
        GET_NEW_FILES_UPLOADED_FLAG: state => state.newFilesUploadedFlag,
        GET_NEW_FILES_STATUS_ARRAY: state => state.newFilesStatusArray,
        GET_COUNT_NEW_FILES_TO_UPLOAD: state => state.countNewFilesToUpload,
    },
    actions: {
        ADD_FILE_TO_NEW_FILES_STATUS_ARRAY(file){
            this.newFilesStatusArray.push(file);
        },
        INCREMENT_COUNT_NEW_FILES_TO_UPLOAD(){
            this.countNewFilesToUpload++;
        },
        SET_NEW_FILES_UPLOADED_FLAG_TRUE(){
            this.newFilesUploadedFlag = true;    
        },
        SET_NEW_FILES_UPLOADED_FLAG_FALSE(){
            this.newFilesUploadedFlag = false;
        },
        SET_DUPLICATE_TEMPLATE_FILES_LOADED_TRUE(){
            this.duplicateTemplateFilesLoadedFlag = true;
        },
        SET_DUPLICATE_TEMPLATE_FILES_LOADED_FALSE(){
            this.duplicateTemplateFilesLoadedFlag = false;
        },
        SET_ALLOW_FIELD_PATCH_FLAG_TRUE(){
            this.allowFieldPatchFlag = true;
        },
        SET_ALLOW_FIELD_PATCH_FLAG_FALSE(){ 
            this.allowFieldPatchFlag = false;   
        },
        SET_PREVIEW_MODE_FALSE(){
            this.isPreviewMode = false;
        },
        SET_PREVIEW_MODE_TRUE(){
            this.isPreviewMode = true;
        },
        UPDATE_ORIGINAL_FORM_DATA(formData){
            //in case of preview mode from list, we need to keep the original template data recevived from backend to monitor changes
            this.originalFormData = JSON.parse(JSON.stringify(formData));
        },
        UPDATE_FORM_DATA(formData) {
            if(!this.isPreviewMode && !formData.created_at){
                // is either edit or create mode of template
                // Save the current state of formData
                const previousFormData = JSON.parse(JSON.stringify(this.formData));
                // Check if formData has been modified
                this.formDataHasBeenModified = !isEqual(previousFormData, formData) || !isEqual(formData, this.originalFormData);
                this.formData = JSON.parse(JSON.stringify(formData));
            }
            else{
                //is preview mode
                this.formData = JSON.parse(JSON.stringify(formData));
                this.formDataHasBeenModified = false;
            }
        },
        UPDATE_SECTION(sectionIndex, section) {
            // Save the current state of formData
            const previousFormData = JSON.parse(JSON.stringify(this.formData));
            // Check if formData has been modified
            this.formData.sections[sectionIndex] = { ...section };
            const formData = {...this.formData};
            this.formDataHasBeenModified = !isEqual(previousFormData, formData) || !isEqual(formData, this.originalFormData);

        },
        UPDATE_FIELD_IN_A_SECTION(sectionIndex, fieldIndex, field) {
            // Save the current state of formData
            const previousFormData = JSON.parse(JSON.stringify(this.formData));
            this.formData.sections[sectionIndex].fields[fieldIndex] = { ...field };
            const formData = {...this.formData};
            // Check if formData has been modified
            this.formDataHasBeenModified = !isEqual(previousFormData, formData) || !isEqual(formData, this.originalFormData);
        },
        BOUNDARY_HAS_BEEN_ADDED() {
            this.formData.boundaryAdded = true;
            this.formData.lastBoundaryRemoved = false;
        },
        LAST_BOUNDARY_HAS_BEEN_REMOVED() {
            this.formData.lastBoundaryRemoved = true;
        },
        IS_ANY_QUESTION_TYPE_BOUNDARY() {
            const hasBoundaryField = this.formData.sections.some(section => section.fields.some(field => field.question_type === 'boundary'));
            this.hasBoundaryField = true;
            return hasBoundaryField;
        },
        COUNT_OF_BOUNDARY_Q() {
            let boundaryFieldCount = 0;

            // Loop through sections and fields to count boundary questions
            this.formData.sections.forEach(section => {
                if (section.fields) {
                section.fields.forEach(field => {
                    if (field.question_type === 'boundary') {
                    boundaryFieldCount += 1;
                    }
                });
                }
            });
            return boundaryFieldCount;
        },
        COUNT_OF_POI_Q() {
            let poiFieldCount = 0;

            // Loop through sections and fields to count boundary questions
            this.formData.sections.forEach(section => {
                if (section.fields) {
                section.fields.forEach(field => {
                    if (field.question_type === 'poi') {
                        poiFieldCount += 1;
                    }
                    if (field.question_type === 'multiple_choice' || field.question_type === 'dropdown') {
                        for (let option of field.options) {
                            if (option.sub_options.length > 0) {
                                for (let suboption of option.sub_options) {
                                    if (suboption.question_type === 'poi') {
                                        poiFieldCount += 1;
                                    }
                                }
                            }
                        }
                    }});
                }
            });
            return poiFieldCount;
        },
        COUNT_IMAGES3(fieldIndex, sectionIndex) {
            let fieldFiles = this.formData.sections[sectionIndex].fields[fieldIndex].files;
            let countImages = 0;
            for (let file of fieldFiles) {
                if (file.file_type.includes('image'))
                    countImages++;
            }
            return countImages === 3;
        },
        COUNT_VIDEOS3(fieldIndex, sectionIndex) {
            let fieldFiles = this.formData.sections[sectionIndex].fields[fieldIndex].files;
            let countVideos = 0;
            for (let file of fieldFiles) {
                if (file.file_type.includes('video'))
                    countVideos++;
            }
            return countVideos === 3;
        },
        REMOVE_UNWANTED_KEYS_FROM_FORMDATA(){
            delete this.formData.boundaryAdded;
            delete this.formData.lastBoundaryRemoved;
            for(let section of this.formData.sections){
                for(let field of section.fields){
                    field = this.REMOVE_UNWANTED_KEYS_FROM_FIELDDATA(field);
                }
            }
        },
        REMOVE_UNWANTED_KEYS_FROM_FIELDDATA(field){
            delete field.additional_info?.sequence;
            delete field.additional_info?.allowed_types_toggle;

            if (field.question_type === 'multiple_choice' || field.question_type === 'dropdown' 
            || field.question_type === 'tabchoice') {
                for (let option of field.options) {
                    delete option.isNestedQRequired;
                    for (let sub_option of option.sub_options ){
                        delete sub_option?.additional_info?.allowed_types_toggle;
                    }
                }
            }
            return field;
        },
        ADD_REQUIRED_KEYS_TO_FORMDATA(){
            this.formData.boundaryAdded = false;
            this.formData.lastBoundaryRemoved = false;
            for(let section of this.formData.sections){
                for(let field of section.fields){
                    field = this.ADD_REQUIRED_KEYS_TO_FIELDDATA(field);
                }
            }
        },
        ADD_REQUIRED_KEYS_TO_FIELDDATA(field){
            if(field.question_type === 'boundary'){
                this.formData.boundaryAdded = true;
            }
            if(field.question_type === 'multiple_choice' || field.question_type === 'dropdown'
            || field.question_type === 'tabchoice'){
                for(let option of field.options){
                    if(option.sub_options.length > 0)
                        option.isNestedQRequired = true;
                    else
                        option.isNestedQRequired = false;
                    for(let sub_option of option.sub_options){
                        if(sub_option.additional_info.allowed_types.length > 0)
                            sub_option.allowed_types_toggle = true;
                        else
                            sub_option.allowed_types_toggle = false;
                    }
                    
                }
            }
            if(field.question_type === 'file_upload'){
                if(field.additional_info.allowed_types.length > 0)
                    field.additional_info.allowed_types_toggle = true;
                else
                    field.additional_info.allowed_types_toggle = false;
            }
            return field;
        },
        RESET_FORM_DATA() {
            this.formData = {
                title: '',
                description: '',
                boundaryAdded: false,
                lastBoundaryRemoved: false,
                template_type: '',
                sections: [
                    {
                        title: '',
                        description: '',
                        fields: [
                            {
                                label: '',
                                description: '',
                                question_type: '',
                                is_required: false,
                                photos_videos_required: false,
                                options: [],
                                files:[],
                                additional_info: {
                                    allowed_types_toggle: false,
                                    max_files: '1',
                                    allowed_types: [],
                                    sequence:0
                                },
                            },
                        ],
                    },
                ],
            };
            this.formDataHasBeenModified = false;
            this.newFilesUploadedFlag = true;
            this.newFilesStatusArray = [];
            this.countNewFilesToUpload = 0;
        },
    }
})

function isEqual(obj1, obj2) {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
}