
<div class="container">
  <el-row :class="hasDescription ? 'task-div' : 'noDescription'">
    <el-col :span="2">
      <div class="icon-div" @click="toggleStatus(info.id, info.status)">
        <b-icon :icon="computeStatusIcon" :style="{color: computeStatusColor}"></b-icon>
      </div>
    </el-col>
    <el-col :span="18">
      <div  :class="{ 'disabled': !canShowTaskDetails, 'info-div': canShowTaskDetails }" @click="canShowTaskDetails ? onshowTaskDetails() : null">
        <h3 :title="info.name">{{ info.name }}</h3>
        <p
          :class="
            seeMore || isMultiLines(info.description)
              ? 'description-more'
              : 'description'
          "
          style="white-space: pre-line"
        >
          {{ getTruncatedDescription(info.description)
          }}<span v-if="seeMore" class="see-more">See More</span>
        </p>
      </div>
    </el-col>
    <el-col
      :span="4"
      :style="[$props.countSize > 40 ? { 'padding-left': '4rem' } : '']"
    >
      <div :class="forLeadSummary ? 'leadSummarybodyContent' : 'bodyContent'">
        <img
          class="priority-flag"
          src="../assets/Priority.svg"
          @click="togglePriority(info.id, info.priority)"
          v-if="info.priority == 'default'"
        />
        <img
          v-else
          class="priority-flag"
          src="../assets/FlagFill.svg"
          @click="togglePriority(info.id, info.priority)"
        />
        <p class="ownerN" :style="{ backgroundColor:canShowTaskDetails ?  generateAvatarColor(info.assigned_to): generateAvatarColor(info.assignee_org) }">
          {{ canShowTaskDetails ? textInitial(info.assigned_to) : textInitial(info.assignee_org) }}</p>
      </div>
    </el-col>
  </el-row>
  <el-row>
    <el-col :span="23"><div class="border-div"></div></el-col>
  </el-row>
  <all-drawer
    :drawer="detailsDrawer"
    @save="detailsDrawer = false"
    :componentName="componentName"
    :drawerSize="500"
    @close="detailsDrawer = false"
    :tasks="info"
    :forAddTask="false"
  />

  <GlobalDrawer
    :title="'Task Details'"
    :isOpen="isTaskDrawerOpen"
    :drawer-size="750"
    :handleCloseDialog="handleTaskDrawerClose"
    class="task-drawer-dialog"
    :drawerDirection="drawerDirection"
  >
    <template v-if="isTaskDrawerOpen" v-slot:body>
      <taskDrawer
        v-if="isTaskDrawerOpen"
        :reminderData.sync="reminderDetails"
        @updateTask="updateTask"
        @deleteTask="onDeleteTask"
        :mode="'view'"
        :taskData="selectedLead"
        @allowDocumentUpload="handleAllowDocUpload"
        :documentUploaded="documentUploaded"
        @documentUploading="documentUploaded = false"
      />
    </template>
  </GlobalDrawer>
  <setReminder
    :key="setKey"
    :showCreateDrawer="showSetReminderDrawer"
    @close="showSetReminderDrawer = false"
    @save="onSave"
    :drawerSize="400"
    :page="'task'"
  />
</div>
