<template>
  <div class="drawer-container" v-loading="loading">
    <el-row>
      <el-col :span="8">
        <div class="img-container">
        
          <img :src="imgValue" alt="" />
        </div>
      </el-col>
      <el-col :span="16" class="content-container">
        <div v-for="(data, inx) in settingsData" :key="inx">
          <div v-if="data.type === 'input'" class="body-container">
            <InputView
              :itemValue="data"
              :selectedValue="data?.selectedValue || ''"
              @item-selected="selectedData"
              :widgetId="widgetId"
            />
          </div>
          <div v-if="data.type === 'select'" class="body-container">
            <SelectComponentView
              :items="data"
              :selectedValue="data.selectedValue"
              @item-selected="selectedData"
              :isGrouped.sync="isGrouped"
              :widgetType="widgetType"
              :isCustomTime.sync="isCustomTime"
              :seletedSource="seletedSource"
            />
          </div>
          <div
            v-if="data.type === 'comparison' && isComparison"
            class="body-container"
          >
            <SelectComponentView
              :items="data"
              :selectedValue="data.selectedValue"
              @item-selected="selectedData"
              :isGrouped.sync="isGrouped"
              :widgetType="widgetType"
              :isCustomTime.sync="isCustomTime"
              :seletedSource="seletedSource"
            />
          </div>
          <div
            v-if="
              data.type === 'filter' &&
              filterColumnOption.length > 0 &&
              isFilter
            "
            class="body-container"
          >
            <FilterView
              :items="data"
              :filterColumnOption="filterColumnOption"
              :otherFilterOptions="otherFilterOptions"
              :selectedFilterDetails="selectedFilterDetails"
              @filter-details="handleFilterDetails"
              :seletedSource="seletedSource"
              :isFilterEmpty.sync="isFilterEmpty"
              :leadOptions="apiData?.measure_groups?.Leads"
            />
          </div>
          <div
            v-if="data.type === 'dropdown' && !isGrouped"
            class="body-container"
          >
            <DropDownView
              :items="data"
              :selectedValue="data?.selectedValue || ''"
              @item-selected="selectedData"
            />
          </div>
          <div
            v-if="data.type === 'grouped' && isGrouped"
            class="body-container"
          >
            <GroupedTableInputs
              :groupedTableOptions="groupedTableOptions"
              @measure_details="getMeasureDetails"
              :groupedTableSelectedData="groupedTableSelectedData"
              :seletedSource="seletedSource"
            />
          </div>
          <div v-if="data.type === 'checkbox'" class="body-container">
            <CheckboxView
              :items="data"
              :selectedValue="data?.selectedValue || false"
              :isDisabled="isDisabled"
              :showTotal.sync="showTotal"
              @item-selected="selectedData"
            />
          </div>
          <div
            v-if="data.type === 'buckets' && isBucket"
            class="body-container"
          >
            <BuckertsView
              :items="data"
              :selectedValue="data?.selectedValue || false"
              :bucketsSelecteddata="bucketsSelecteddata"
              @bucket_details="getBucketDetails"
            />
          </div>

          <div
            v-if="data.type === 'timeinterval' && isTimeInterval"
            class="body-container"
          >
            <SelectComponentView
              :items="data"
              :selectedValue="data.selectedValue"
              @item-selected="handleTimeInterval"
              :isGrouped.sync="isGrouped"
              :widgetType="widgetType"
              :seletedSource="seletedSource"
            />
          </div>

          <div
            v-if="data.type === 'closed_at' && isClosedAt"
            class="body-container"
          >
            <SelectComponentView
              :items="data"
              :selectedValue="data.selectedValue"
              @item-selected="handleSelectTimeRange"
              :isGrouped.sync="isGrouped"
              :widgetType="widgetType"
              :isCustomTimeClosed.sync="isCustomTimeClosed"
              :seletedSource="seletedSource"
            />
          </div>
          <div
            v-if="data.type === 'activity_time' && isActivityTime"
            class="body-container"
          >
            <SelectComponentView
              :items="data"
              :selectedValue="data.selectedValue"
              @item-selected="handleSelectTimeRange"
              :isGrouped.sync="isGrouped"
              :widgetType="widgetType"
              :isCustomTimeActivity.sync="isCustomTimeActivity"
              :seletedSource="seletedSource"
            />
          </div>
          <div v-if="data.type === 'number'" class="body-container">
            <NumberInput
              :itemValue="data"
              :selectedValue="data?.selectedValue || ''"
              @item-selected="selectedData"
              :limitData="limitData"
            />
          </div>
          <div
            v-if="data.type === 'custom_time' && isCustomTime"
            class="body-container"
          >
            <CustomTimeView
              @custom-selecteddates="handleCustomDate"
              :customDates="additionalDetails"
              :fromLabel="'created_at_start_date'"
              :toLabel="'created_at_end_date'"
            />
          </div>
          <div
            v-if="data.type === 'custom_closed_time' && isCustomTimeClosed"
            class="body-container"
          >
            <CustomTimeView
              @custom-selecteddates="handleCustomDate"
              :customDates="additionalDetails"
              :fromLabel="'closed_at_start_date'"
              :toLabel="'closed_at_end_date'"
              :timerangeType="'closed_at'"
            />
          </div>
          <div
            v-if="data.type === 'custom_activity_time' && isCustomTimeActivity"
            class="body-container"
          >
            <CustomTimeView
              @custom-selecteddates="handleCustomDate"
              :customDates="additionalDetails"
              :fromLabel="'activity_time_start_date'"
              :toLabel="'activity_time_end_date'"
              :timerangeType="'activity_time'"
            />
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import SelectComponentView from "./selectComponentView.vue";
import API from "../../../../services/api";
import InputView from "./inputView.vue";
import FilterView from "./filterView.vue";
import dashboardSettinsdata from "./dashboardSettings.json";
import GroupedTableInputs from "./groupedTableInputs.vue";
import DropDownView from "./dropDownView.vue";
import CheckboxView from "./checkboxView.vue";
import BuckertsView from "./bucketsView.vue";
import NumberInput from "./numberInput.vue";
import KpiCard from "./assests/previewIcons/kpiCard.svg";
import BarCard from "./assests/previewIcons/barCard.svg";
import ColumnCard from "./assests/previewIcons/columnCard.svg";
import DontCard from "./assests/previewIcons/donutCard.svg";
import SimpleTable from "./assests/previewIcons/tableCard.svg";
import GroupedTable from "./assests/previewIcons/tableCard.svg";
import MapCard from "./assests/previewIcons/mapCard.svg";
import TrendLineCard from "./assests/previewIcons/lineTrendCard.svg";
import CustomTimeView from "./customTimeView.vue";
export default {
  emits: ["update:editDrawerData", "update:drawerData", "update:editDrawerData"],
  name: "createEditDrawer",
  components: {
    SelectComponentView,
    InputView,
    FilterView,
    GroupedTableInputs,
    DropDownView,
    CheckboxView,
    BuckertsView,
    NumberInput,
    CustomTimeView,
  },
  data() {
    return {
      dashboardSettingsData: [],
      chartType: "",
      xOptions: [],
      yOptions: [],
      loading: false,
      filterColumnOption: [],
      otherFilterOptions: [],
      groupedTableOptions: [],
      apiData: [],
      imgValue: "",
      KpiCard,
      GroupedTable,
      BarCard,
      ColumnCard,
      DontCard,
      MapCard,
      SimpleTable,
      TrendLineCard,
      sampleWidgetImg: {
        KPI: KpiCard,
        Bar: BarCard,
        Column: ColumnCard,
        Donut: DontCard,
        Table: SimpleTable,
        Map: MapCard,
        Trend_Line: TrendLineCard,
       
      },
      postData: {},
      isGrouped: false,
      widgetDataDetails: "",
      sourceData: "",
      measureDetails: [],
      groupedTableSelectedData: [],
      bucketDetails: [],
      isBucket: false,
      bucketsSelecteddata: [],
      selectedColumnsDetails: [],
      selectedFilterDetails: {},
      showTotal: false,
      isDisabled: false,
      limitData: "",
      widgetType: "",
      isTimeInterval: false,
      timeIntervalData: "",
      isCustomTime: false,
      isCustomTimeClosed: false,
      isCustomTimeActivity: false,
      additionalDetails: {},
      filterDetails: {},
      isClosedAt: false,
      isActivityTime: false,
      seletedSource: "",
      isComparison: true,
      isFilter: false,
      isFilterEmpty: false,
      additionalTimeRange: "",
      editReferenceData:[],
      isEdit:true,
      isPercentageTotal:false,
    };
  },
  props: {
    drawerData: {
      type: [Object,Array],
      default() {
        return []
      }
    },
    refreshData: {
      type: Boolean,
      default: false,
    },
    widgetId: {
      type: [Number, String],
    },
    isCopyDrawer: {
      type: Boolean,
      default: false,
    },
    selectedChartType: {
      type: String,
      default: "",
    },
    editDrawerData:{
      type:[Object,Array],
      default() {
        return []
      }
    }

  },
  mounted() {
    this.isEdit=false;
    this.getdashboardSettings();
    if (this.$props.widgetId) {
      this.getWidgetData(this.$props.widgetId);
    }
  },

  computed: {
    settingsData() {
      let filterdData = [];

      if (this.dashboardSettingsData.length > 0) {
        const dataFlag = this.dashboardSettingsData.find(
          (d) => d.prop === "type"
        );

        if (dataFlag.selectedValue) {
          this.widgetType = dataFlag.selectedValue;

          if (
            dataFlag.selectedValue === "Trend Line" ||
            dataFlag.selectedValue?.label === "Trend Line"
          ) {
            this.imgValue = this.sampleWidgetImg["Trend_Line"];
          }else if( dataFlag.selectedValue === "TableType - Simple" ||
            dataFlag.selectedValue === "TableType - Grouped"){
              this.imgValue = this.sampleWidgetImg["Table"];
          } else {
            this.imgValue =
              this.sampleWidgetImg[
                dataFlag.selectedValue?.label || dataFlag.selectedValue
              ];
          }

          if (dataFlag.selectedValue === "KPI") {
            filterdData = this.dashboardSettingsData.filter((d) => d.isKpi);
          } else if (dataFlag.selectedValue === "Map") {
            filterdData = this.dashboardSettingsData.filter((d) => d.isMap);
          } else if (
            dataFlag.selectedValue === "Table" ||
            dataFlag.selectedValue === "TableType - Simple" ||
            dataFlag.selectedValue === "TableType - Grouped"
          ) {
            if (this.isGrouped) {
              filterdData = this.dashboardSettingsData.filter(
                (d) => d.isTable !== false && d.isGrouped !== false
              );
            } else {
              filterdData = this.dashboardSettingsData.filter(
                (d) => d.isTable !== false && d.subType !== "grouped"
              );
            }
          } else if (dataFlag.selectedValue !== "Trend Line") {
            if (dataFlag.selectedValue === "Donut") {
              filterdData = this.dashboardSettingsData.filter(
                (d) =>
                  !d.isTrendLine &&
                  !d.isTable &&
                  d.isGraphs !== false &&
                  d.isDonut !== false
              );
            } else {
              filterdData = this.dashboardSettingsData.filter(
                (d) =>
                  !d.isTrendLine &&
                  !d.isTable &&
                  d.isGraphs !== false &&
                  d.isLegends !== true &&
                  d.isDonut !== true
              );
            }
          } else {
            filterdData = this.dashboardSettingsData.filter(
              (d) => !d.isTable && d.isGraphs !== false
            );
          }
        } else {
          filterdData = this.dashboardSettingsData.filter(
            (d) => d.prop === "name" || d.prop === "type"
          );
        }
if(this.isEdit){
  if(filterdData.length>2){
  this.loading = false;

}}else{
  this.loading = false;
}

        return filterdData;
      }

      return filterdData
    },
  },

  methods: {
    handleCustomDate(type, value, timeRangeType) {
      this.postData["additional_details"][type] = value;
      this.handlePostData();
    },
    getLegendsData() {
      const options = [
        { label: "Left", value: "left" },
        { label: "Right", value: "right" },
        { label: "Top", value: "top" },
        { label: "Bottom", value: "bottom" },
      ];

      return options;
    },
    handleFilterDetails(conditions, expression) {
      // this.handlePostData("", "", "", conditions, expression);
    
        this.filterDetails = {
        conditions: conditions,
        expression: expression,
      };
    

     
    
      this.handlePostData();
    },
    handleWidgetTypeOptions(data) {
      const iconsDataWidgets = {
        KPI: "kpi",
        Bar: "bar",
        Column: "column",
        Donut: "donut",
        Trend_Line: "trendline",
        Table_Simple: "simpletale",
        Table_Grouped: "groupedtbale",
        Map: "map",
      };

      let requiredData = [];
      data.map((d) => {
        let obj = {};

        obj.label = d;
        obj.value = d;
        if (d === "Trend Line") {
          iconsDataWidgets["Trend_Line"];
        }

        obj.icon =
          d === "Trend Line"
            ? iconsDataWidgets["Trend_Line"]
            : iconsDataWidgets[d];
        requiredData.push(obj);
      });

      const arr = JSON.parse(JSON.stringify(requiredData));
      const tableIndex = arr.findIndex((item) => item.label === "Table");

      if (tableIndex !== -1) {
        // Remove the object with label "Table"
        arr.splice(tableIndex, 1);

        // Insert two new objects after it
        arr.splice(
          tableIndex,
          0,
          {
            isSubTitle: "",
            label: "TableType - Simple",
            value: "TableType - Simple",
            icon: "simpletable",
          },
          {
            isSubTitle: "",
            label: "TableType - Grouped",
            value: "TableType - Grouped",
            icon: "groupedtable",
          }
        );
      }

      return arr;
    },
    handleWidgeSource(data) {
      const arr = [];
      data.map((d) => {
        let obj = {};
        obj.label = d;
        obj.value = d;
        arr.push(obj);
      });
      return arr;
    },
    async getdashboardSettings() {
      this.loading = true;
      try {
        const { data } = await API.CRM_DASHBOARD.GET_DASHBOARD_SETTINGS();
        // const data = dashboardSettinsdata;
        this.apiData = data;
        this.otherFilterOptions = data?.filter_operations;
        if (data) {
          this.dashboardSettingsData = [
            {
              title: "Widget Name",
              prop: "name",
              selectedValue: "",
              type: "input",
              placeholder: "Enter Widget Name",
              isKpi: true,
              isMap: true,
              isStar: true,
            },
            {
              title: "Widget Type",
              type: "select",
              prop: "type",
              selectedValue: this.$props.selectedChartType
                ? this.$props.selectedChartType
                : "",
              placeholder: "Select Widget Type",
              options: data.widget_types
                ? this.handleWidgetTypeOptions(data.widget_types)
                : [],
              isKpi: true,
              isMap: true,
              isStar: true,
            },
            {
              title: "Source",
              type: "select",
              prop: "source",
              selectedValue: "",
              placeholder: "Select Source",
              options: this.handleWidgeSource(data?.sources) || [],
              isKpi: true,
              isStar: true,
            },
            {
              title: "Measure",
              type: "select",
              prop: "measure",
              selectedValue: "",
              placeholder: "Select Measure",
              titleType: "Measure",
              options: [],
              isKpi: false,
              isTable: false,
              isStar: true,
            },

            {
              title: "Measure",
              type: "select",
              prop: "measure",
              selectedValue: "",
              placeholder: "Select Measure",
              options: [],
              isKpi: true,
              isTable: false,
              isGraphs: false,
              isStar: true,
            },
            {
              title: "Grouping",
              type: "select",
              prop: "measure_group",
              selectedValue: "",
              titleType: "Grouping",
              placeholder: "Select Grouping",
              options: [],
              isKpi: false,
              isTable: false,
              isStar: true,
            },

            {
              title: "Columns",
              type: "dropdown",
              prop: "measure_group",
              selectedValue: "",
              placeholder: "Select Columns",
              options: [],
              isKpi: false,
              isTable: true,
              isGrouped: false,
              subType: "simple",
              isStar: true,
            },

            {
              title: "",
              type: "grouped",
              prop: "measure",
              selectedValue: "",
              placeholder: "",
              options: [],
              isKpi: true,
              subType: "grouped",
            },
            {
              title: "Grouping",
              type: "select",
              prop: "measure_group",
              selectedValue: "",
              placeholder: "Select Grouping",
              options: [],
              isKpi: false,
              isTable: true,
              subType: "grouped",
              isStar: true,
            },

            {
              title: "Bucket",
              type: "buckets",
              prop: "buckets",
              selectedValue: "",
              placeholder: "Select Buckets",
              options: [],
              isKpi: false,
              subType: "simple",
              isBucket: false,
              isStar: true,
            },
            {
              title: "Time Interval",
              type: "timeinterval",
              prop: "time_interval",
              selectedValue: "",
              placeholder: "Select Time Interval",
              options: data.time_interval || [],
              // options: [
              //   { label: "By Week", value: "week" },
              //   { label: "By Month", value: "month" },
              //   { label: "By Quarter", value: "quarter" },
              // ],
              isKpi: false,
              subType: "simple",
              isStar: true,
            },
            {
              title: "Show Totals",
              type: "checkbox",
              prop: "show_total",
              selectedValue: "",
              placeholder: "",
              options: [],
              isKpi: false,
              isTable: true,
              subType: "grouped",
            },
            {
              title: "Limit",
              type: "select",
              prop: "limit",
              selectedValue: "",
              placeholder: "Select Limit",
              options: this.handleWidgeSource(data?.limit_options) || [],
              isKpi: false,
              isTable: true,
              isStar: true,
            },
            {
              title: "Legend Position",
              type: "select",
              prop: "legend_position",
              selectedValue: "",
              placeholder: "Select Legend Position",
              options: this.getLegendsData(),
              isKpi: false,
              isTable: false,
              isLegends: true,
            },
            {
              title: "Filters",
              type: "filter",
              prop: "filters",
              selectedValue: "",
              placeholder: "",
              options: [],
              isKpi: true,
              isMap: true,
            },
            {
              title: "Sort By",
              type: "select",
              prop: "measure_sort",
              selectedValue: "",
              placeholder: "Select Sort By",
              options: [],
              isKpi: false,
              isDonut: false,
              isGraphs: false,
              isGrouped:false,
            },
            {
              title: "Sort",
              type: "select",
              prop: "order",
              selectedValue: "",
              placeholder: "Select Sort",
              options: [
                { value: "ASC", label: "Ascending" },
                { value: "DESC", label: "Descending" },
              ],
              isKpi: false,
              isDonut: false,
            },
            {
              title: "Time Range - Created At",
              type: "select",
              prop: "time_range",
              selectedValue: "",
              placeholder: "Select Time Range",
              options: data?.time_range || [],
              isKpi: true,
              isMap: true,
              isStar: true,
            },
            {
              title: "Time Range",
              type: "custom_time",
              prop: "custom_time",
              selectedValue: "",
              placeholder: "Select Time Range",
              options: [],
              isKpi: true,
              isMap: true,
              isStar: true,
            },

            {
              title: "Time Range - Closed At",
              type: "closed_at",
              prop: "closed_at",
              selectedValue: "",
              placeholder: "Select Time Range",
              options: data?.time_range || [],
              isKpi: true,
              isMap: true,
              isStar: false,
            },
            {
              title: "Time Range",
              type: "custom_closed_time",
              prop: "closed_time",
              selectedValue: "",
              placeholder: "Select Time Range",
              options: [],
              isKpi: true,
              isMap: true,
              isStar: false,
            },
            {
              title: "Time Range - Activity Time",
              type: "activity_time",
              prop: "activity_time",
              selectedValue: "",
              placeholder: "Select Time Range",
              options: data?.time_range || [],
              isKpi: true,
              isMap: true,
              isStar: false,
            },
            {
              title: "Time Range",
              type: "custom_activity_time",
              prop: "custom_activity_time",
              selectedValue: "",
              placeholder: "Select Time Range",
              options: [],
              isKpi: true,
              isMap: true,
              isStar: false,
            },
            {
              title: "Comparison",
              type: "comparison",
              prop: "comparison_time_range",
              selectedValue: "None",
              placeholder: "Select Comparison",
              options: data?.comparision_time_range || [],
              isKpi: true,
              isTrendLine: true,
              isTable: false,
              isStar: true,
            },
          ];
        }
      } catch (err) {
        console.error(err);
      }
    },
    async getWidgetData(widgetId) {
      this.isEdit=true
      this.loading = true;
      try {
        const { data } = await API.CRM_DASHBOARD.GET_WIDGET_BY_ID(widgetId);
        if (data.type === "Map") {
          data.source = "Leads";
        }
      
        if(data.type==='Table'){
          if(data.additional_details?.table_type==='simple'){
data.type="TableType - Simple"
          }else{
            data.type="TableType - Grouped"
          }
        }

        this.seletedSource =
          data.type === "Map"
            ? "Leads"
            : data.additional_details.source ?? data.source;
        const source = data.source
          ? data.source
          : data.additional_details.source;
        if (source === "Leads") {
          data["closed_at"] = data.additional_details?.closed_at;
          this.isClosedAt = true;
        } else if (source === "Activities") {
          data["activity_time"] = data.additional_details?.activity_time;
          this.isActivityTime = true;
        } else {
          this.isClosedAt = false;
          this.isActivityTime = false;
        }

        if (this.$props.isCopyDrawer) {
          data.name = data.name + " (Copy)";
          let thisIsObject = {};
          thisIsObject = Object.keys(data)
            .filter((key) => key !== "result")
            .reduce((obj, key) => {
              obj[key] = data[key];
              return obj;
            }, {});
          this.postData = { ...this.postData, ...thisIsObject };
        }
        if (data?.additional_details) {
          let tempData = {
            additional_details: data.additional_details,
          };

          this.postData = { ...this.postData, ...tempData };
        }

        if (data.additional_details.time_interval) {
          this.isTimeInterval = true;
          this.timeIntervalData = data.additional_details.time_interval;
        }


        if (data.filters) {
          this.isFilter = true;

          this.selectedFilterDetails = data.filters;
        }
        if (data.additional_details?.show_total) {
          this.showTotal = data.additional_details?.show_total;
        }

        if (data.buckets.length > 0) {
          this.isBucket = true;
          this.bucketsSelecteddata = data.buckets;
          this.bucketDetails=data.buckets;
        } else {
          this.isBucket = false;
          this.bucketsSelecteddata={}
          this.postData.buckets={}
      
        }
        if (data?.additional_details) {
          const additionalData = data?.additional_details;
          if (
            additionalData?.created_at_end_date ||
            additionalData?.created_at_start_date
          ) {
            this.isCustomTime = true;
          } else {
            this.isCustomTime = false;
          }
          this.isCustomTimeClosed =
            additionalData?.closed_at_end_date ||
            additionalData?.closed_at_start_date
              ? true
              : false;
          this.isCustomTimeActivity =
            additionalData?.activity_time_start_date ||
            additionalData?.activity_time_end_date
              ? true
              : false;
          this.additionalDetails = data?.additional_details;
        }
        this.widgetDataDetails = data;
        if (data.additional_details?.table_type === "grouped") {
          this.isGrouped = true;
        }

        if (data.additional_details?.measure_details) {
          this.groupedTableSelectedData =
            data.additional_details?.measure_details;
        }
        let sourceDataValue = this.dashboardSettingsData.find(
          (d) => d.prop === "source"
        );
        if (sourceDataValue) {
          sourceDataValue["selectedValue"] =
            (data.type === "Table"||data.type === "TableType - Grouped"||data.type === "TableType - Simple")
              ? data.additional_details.source
              : data["source"];
        }
        this.getXandYList(sourceDataValue);

        if (data.additional_details) {
          this.limitData = data.additional_details.limit
            ? data.additional_details.limit
            : data.additional_details.simple_table_columns_limit;
        }

        if ( (data.type === "Table"||data.type === "TableType - Grouped"||data.type === "TableType - Simple") && this.xOptions?.length > 0) {
          data.source = data.additional_details.source;
          const tableOptions=    data.additional_details.simple_table_columns.map(metric => this.xOptions.find(item => item.metric === metric))

          // const tableOptions = this.xOptions.filter((d) =>
          //   data.additional_details.simple_table_columns.includes(d.metric)
          // );

          tableOptions.map((d) => {
            this.widgetDataDetails.measure.push(d.id);
          });

          this.widgetDataDetails.source = data.additional_details.source;
          this.widgetDataDetails.limit =
            data.additional_details.simple_table_columns_limit;
        }
        let sourceData = "";
        const widgetData = [];
        this.dashboardSettingsData.map((d) => {
          if (
            d.prop === "measure" &&
            (data.type === "Bar" ||
              data.type === "Column" ||
              data.type === "Trend Line" ||
              data.type === "KPI")
          ) {
            if (data.type === "KPI") {
              d.title = `Measure`;
            } else if (data.type === "Bar") {
              d.title = `Measure (X-Axis)`;
            } else {
              d.title = `Measure (Y-Axis)`;
            }
          } else {
            d.title = d.title;
          }

          if (
            d.prop === "measure_group" &&
            (data.type === "Bar" ||
              data.type === "Column" ||
              data.type === "Trend Line" ||
              d.prop === "Table")
          ) {
            if (d.prop === "Table") {
              d.title = `Grouping`;
            } else if (data.type === "Bar") {
              d.title = `Grouping (Y-Axis)`;
            } else {
              d.title = `Grouping (X-Axis)`;
            }
          } else {
            d.title = d.title;
          }

          if (d.prop === "measure_group" &&  (data.type === "Table"||data.type === "TableType - Grouped"||data.type === "TableType - Simple")) {
            d.selectedValue = this.widgetDataDetails.measure;
          }

          if((d.prop==='measure_sort'||d.prop==='activity_time'||d.prop==='closed_at')&&!d.selectedValue){
              d.selectedValue = "None";
            }

          if (data[d.prop]) {
            let selectedDataValue = "";
            if (d.prop === "name") {
              d.selectedValue = data[d.prop];
            }

            if (
              d.prop === "time_interval" &&
              data.additional_details?.time_interval
            ) {
              d.selectedValue = data.additional_details.time_interval;
            }

            if (d.options) {
              if (d.prop === "measure") {
                selectedDataValue = this.yOptions.find(
                  (ele) => data[d.prop][0] === ele.id
                );
                d.selectedValue = selectedDataValue;
              } else if (d.prop === "measure_group") {
                selectedDataValue = this.xOptions.find(
                  (ele) => data[d.prop] === ele.id
                );
                d.selectedValue =
                (data.type === "Table"||data.type === "TableType - Grouped"||data.type === "TableType - Simple")
                    ? this.widgetDataDetails.measure
                    : selectedDataValue;
              } else if (d.prop === "type" && data["type"] === "Table") {
                d.selectedValue =
                  data.additional_details.table_type === "simple"
                    ? "TableType - Simple"
                    : "TableType - Grouped";
              } else {
                selectedDataValue = d.options.find(
                  (ele) => data[d.prop] === ele.value || data[d.prop] === ele.id
                );

                d.selectedValue = selectedDataValue?.label || selectedDataValue;
              }
              if (d.prop === "show_total") {
                d.selectedValue = this.showTotal;
              }

              // d.selectedValue = selectedDataValue.label;
            }
            if (d.prop === "limit" && data.additional_details?.limit) {
              d.selectedValue = data.additional_details.limit;
            }
        
          }
        });
        if (data.type === "Table") {
          data.type =
            data.additional_details.table_type === "simple"
              ? "TableType - Simple"
              : "TableType - Grouped";
        }
        this.editReferenceData=data;
        this.$emit('update:editDrawerData', this.editReferenceData)
      } catch (err) {
        console.error(err);
      }
      // this.loading = false;
    },

    getXandYList(itemData) {
      if (itemData?.selectedValue) {
        switch (itemData.selectedValue) {
          case "Leads":
            this.xOptions = this.apiData?.measure_groups?.Leads;
            this.yOptions = this.apiData?.measures?.Leads;

            break;

          case "Tasks":
            this.xOptions = this.apiData?.measure_groups?.Tasks;
            this.yOptions = this.apiData?.measures?.Tasks;
            break;

          case "Activities":
            this.xOptions = this.apiData?.measure_groups?.Activities;
            this.yOptions = this.apiData?.measures?.Activities;
            break;
        }
      }
    },

    handlePostData(type, data, selectedColumn) {
   
      let sourceData = "";
      let postData = {};
      if(this.additionalDetails){
        this.postData.additional_details=this.additionalDetails;
      }

      postData.additional_details =
        this.postData && this.postData.additional_details
          ? this.postData.additional_details
          : {};
      if (this.timeIntervalData && this.isTimeInterval === true) {
        postData.additional_details.time_interval = this.timeIntervalData;
      }else{
        if(this.postData.additional_details.time_interval||this.postData.additional_details.time_interval==null){
          delete this.postData.additional_details.time_interval
        }
      }
   

      if (type === "source") {
        this.sourceData = data;
      }
      if (this.bucketDetails.length > 0) {
       
        // const bucketsLength = this.bucketDetails.filter(
        //   (bkts) => bkts.label && bkts.min && bkts.max
        // );
        // if (bucketsLength.length === this.bucketDetails.length) {
        postData.buckets =this.isBucket? this.bucketDetails:{};
        // } else {
        //   postData.buckets = [];
        // }
      }else{
        this.postData.buckets={}
       
      }

      if(this.showTotal){
        this.isPercentageTotal=this.showTotal
        this.isDisabled = false;
      }else{
        this.isPercentageTotal=false
      }


      if (this.measureDetails.length > 0 && this.isGrouped) {
        let arr = [];
        const ispercent = this.measureDetails.find(
          (fl) => fl.percentage === true
        );
        if (ispercent) {
          this.isDisabled = true;
        }
        else{
          this.isDisabled = false;
        
        }

 if (ispercent) {
          this.showTotal = true;
         
 }else if(this.isDisabled ===false&&this.showTotal){
          this.showTotal = true;
        }
        
        
        else {
          this.showTotal = false;
          
        }
        this.measureDetails.map((d) => {
          if (d.id) {
            arr.push(d.id);
          }

        });
        postData.additional_details.show_total = this.showTotal;

        postData.measure = arr;
        postData.additional_details.measure_details = this.measureDetails;
        postData.additional_details.table_type = "grouped";
      }

      if (type === "measure") {
        postData[type] = data ? (data.id ? [data.id] : data) : [];
      } else if (type === "measure_group") {
        postData[type] = data?.id;
        if((this.widgetType!=='Table'&&this.widgetType!=='TableType - Simple')||this.isGrouped){
          postData['measure_sort'] = data?.id;
        }
      } else if (type === "measure_sort") {
        postData[type] = data?.id||null;
      } else if (type !== "source") {
        postData[type] = data?.value || data;
      } else {
        postData[type] = data?.value || data;
      }

      const condition = this.filterDetails?.conditions;
      const expression = this.filterDetails?.expression;
      if (condition && expression) {
        postData.filters = {
          conditions: condition,

          expression: expression,
        };
      }
      // else {
      //   postData.filters = {};
      // }

      if (this.selectedColumnsDetails.length > 0 || selectedColumn) {
        postData.additional_details.source = this.sourceData
          ? this.sourceData?.value || this.sourceData
          : this.seletedSource;
        postData.measure = [];
        postData.additional_details.table_type = "simple";
        postData.additional_details.simple_table_columns =
          this.selectedColumnsDetails.length > 0
            ? this.selectedColumnsDetails
            : selectedColumn;
      }

      if (type === "comparison_time_range") {
        postData[type] = data?.value;
      }
      if (!this.isFilter) {
        this.postData.filter = {};
      }

      this.postData = { ...this.postData, ...postData };
      if (this.postData.limit) {
        postData.additional_details.limit = parseInt(this.postData.limit);
      }
      if(this.postData.type==='TableType - Grouped'||this.postData.type==='TableType - Grouped'){
        this.postData.type='Table'
      }

      if (this.additionalTimeRange) {
        this.postData.additional_details[this.additionalTimeRange?.name] =
          this.additionalTimeRange?.value;
      }
      this.$emit("update:drawerData", this.postData);
    },

    apiDataValueUpdate(data) {
      if (data.additional_details?.table_type) {
        const tableDetails = data.additional_details;
        let sourceDataValue = this.dashboardSettingsData.find(
          (d) => d.prop === "source"
        );
        sourceDataValue.selectedValue = tableDetails?.source;
        this.getXandYList(sourceDataValue);

        let sourceData = "";
        const widgetData = [];
        this.dashboardSettingsData.map((d) => {
          if (data[d.prop]) {
            let selectedDataValue = "";
            if (d.prop === "name") {
              d.selectedValue = data[d.prop];
            }

            if (d.options) {
              if (d.prop === "measure") {
                const selectedTableOptions = [];
                selectedDataValue = this.yOptions.find(
                  (ele) => data[d.prop][0] === ele.id
                );

                if (tableDetails?.simple_table_columns) {
                  const tableOptions = this.xOptions.filter((d) =>
                    tableDetails?.simple_table_columns.includes(d.metric)
                  );
                  tableOptions.map((d) => {
                    selectedTableOptions.push(d.id);
                  });
                }
                d.selectedValue =
                 ( data.type === "Table" ||
                  data.type === "TableType - Simple" ||
                  data.type === "TableType - Grouped")
                    ? selectedTableOptions
                    : selectedDataValue.label;

              } else if (d.prop === "measure_group") {
                selectedDataValue = this.xOptions.find(
                  (ele) => data[d.prop] === ele.id
                );
                let selectedGroupValue = this.xOptions.find(
                  (ele) => data[d.prop] === ele.id
                );
                if (d?.subType === "grouped") {
                  d.selectedValue = selectedGroupValue.label;
                } else {
                  d.selectedValue = selectedDataValue.label;
                }
              } else {
                selectedDataValue = d.options.find(
                  (ele) => data[d.prop] === ele.value || data[d.prop] === ele.id
                );

                d.selectedValue = selectedDataValue?.label || selectedDataValue;
              }

              // d.selectedValue = selectedDataValue.label;
            }
          }
          if (data.buckets.length > 0 && d.prop === "buckets") {
          }
        });
      } else {
        let sourceDataValue = this.dashboardSettingsData.find(
          (d) => d.prop === "source"
        );

        sourceDataValue.selectedValue = data["source"];
        this.getXandYList(sourceDataValue);

        this.dashboardSettingsData.map((d) => {
          if (data[d.prop]) {
            let selectedDataValue = "";
            if (d.prop === "name") {
              d.selectedValue = data[d.prop];
            }
            if (d.prop === "limit") {
              d.selectedValue = data.limit;
            }

            if (d.options) {
              if (d.prop === "measure") {
                selectedDataValue = this.yOptions.find(
                  (ele) => data[d.prop][0] === ele.id
                );

                d.selectedValue = selectedDataValue?.label || selectedDataValue;
              } else if (d.prop === "measure_group") {
                selectedDataValue = this.xOptions.find(
                  (ele) => data[d.prop] === ele.id
                );
                let selectedGroupValue = this.xOptions.find(
                  (ele) => data[d.prop] === ele.id
                );
                if (d?.subType === "grouped") {
                  d.selectedValue = selectedGroupValue.label;
                } else {
                  d.selectedValue =
                    selectedDataValue?.label || selectedDataValue;
                }
              } else {
                selectedDataValue = d.options.find(
                  (ele) => data[d.prop] === ele.value || data[d.prop] === ele.id
                );

                d.selectedValue = selectedDataValue?.label || selectedDataValue;
              }

              // d.selectedValue = selectedDataValue.label;
            }
          }
          if (d.prop === "show_total") {
            d.selectedValue = this.showTotal;
          }
        });
      }
      this.widgetDataDetails = null;
    },

    handleTimeInterval(typeData, dataValue, value, itemData) {
      this.dashboardSettingsData.map((d)=>{
        if(d.prop==='time_interval'){
          d.selectedValue=dataValue?.label||'';
        }
      })
      this.timeIntervalData = value;
      this.handlePostData();
    },
    handleSelectTimeRange(typeData, dataValue, value, itemData) {
      if (typeData === "closed_at" || typeData === "activity_time") {
        // this.isComparison = dataValue.value === 'custom' ? false : true
        this.comparision_time_range = null;
        if (dataValue.value === "custom") {
          this.postData.comparision_time_range = null;
        }else{
let additionalDetails=this.postData.additional_details;
delete additionalDetails.closed_at_end_date;
delete additionalDetails.closed_at_start_date;
delete additionalDetails.activity_time_start_date;
delete additionalDetails.activity_time_end_date;

    this.postData['additional_details']=additionalDetails;
        }

        this.additionalTimeRange = { name: typeData, value: value };
        this.postData["additional_details"][typeData] = value;
        this.handlePostData();
      }
    },

    selectedData(
      typeData,
      dataValue,
      value,
      itemData,
      index,
      isGrouped,
      selectedColumns,
      bucketsCheck
    ) {

      if (typeData === "time_range") {
        this.isComparison = dataValue.value === "custom" ? false : true;
        this.comparision_time_range = null;
        if (dataValue.value === "custom") {
          this.postData.comparision_time_range = null;
        }else{
let additionalDetails=this.postData.additional_details;
delete additionalDetails.created_at_start_date;
delete additionalDetails.created_at_end_date;

    this.postData['additional_details']=additionalDetails;
        }
      }
    

      if (selectedColumns) {
        this.selectedColumnsDetails = selectedColumns;
      }
      if (typeData === "name") {
        this.dashboardSettingsData.map((d) => {
          if (d.prop === "name") {
            d.selectedValue = value;
            this.postData.name = value;
          }
        });
      }
      if (typeData === "type") {
        if (this.postData?.type !== value) {
          this.seletedSource = "";
          this.isFilter = false;
          this.isCustomTime=false;
          this.isCustomTimeActivity=false;
          this.isCustomTimeClosed=false;
          // this.postData['filters']={};
        }
        if (value === "Map") {
          this.isClosedAt = true;
          this.seletedSource = "Leads";
          this.isFilter = true;
          this.filterColumnOption= this.apiData?.measure_groups?.Leads||[];
        } else {
          this.isClosedAt = false;
        }
        this.editReferenceData=this.postData;
        this.additionalDetails={}
        this.postData = {};
  this.$emit('update:editDrawerData',{})
        this.selectedFilterDetails = {};
        this.getMeasureDetails();

        this.bucketsSelecteddata = null;
        this.dashboardSettingsData.map((d) => {
          if (d.prop === "name") {
            this.postData.name = d.selectedValue;
          }
          if (d.prop !== "name" && d.prop !== "type") {
            d.selectedValue = "";
          }
          if (
            d.prop === "measure" &&
            (value === "Bar" ||
              value === "Column" ||
              value === "Trend Line" ||
              value === "KPI")
          ) {
            if (value === "KPI") {
              d.title = `Measure`;
            } else if (value === "Bar") {
              d.title = `Measure (X-Axis)`;
            } else {
              d.title = `Measure (Y-Axis)`;
            }
          } else {
            d.title = d.title;
          }

          if (
            d.prop === "measure_group" &&
            (value === "Bar" ||
              value === "Column" ||
              value === "Trend Line" ||
              value === "Table")
          ) {
            if (value === "Table") {
              d.title = `Grouping`;
            } else if (value === "Bar") {
              d.title = `Grouping (Y-Axis)`;
            } else {
              d.title = `Grouping (X-Axis)`;
            }
          } else {
            d.title = d.title;
          }
        });
      }
      if (typeData === "source") {
        this.editReferenceData=this.postData;
        this.postData = {};
  this.$emit('update:editDrawerData',{})

        this.seletedSource = value;
        this.isFilter = true;
        this.isCustomTime=false;
          this.isCustomTimeActivity=false;
          this.isCustomTimeClosed=false;
        // this.postData['filters']={};

        this.isClosedAt = value === "Leads" ? true : false;
        this.isCustomTime = false;
        this.isCustomTimeClosed = false;
        this.isCustomTimeActivity = false;
        this.isActivityTime = value === "Activities" ? true : false;

        this.selectedFilterDetails = {};
        this.getMeasureDetails();
        this.bucketsSelecteddata = [];
        this.dashboardSettingsData.map((d) => {
          if (d.prop === "name") {
            this.postData.name = d.selectedValue;
          }
          if (d.prop === "type") {
            this.postData.type = d.selectedValue;
          }

          if (d.prop !== "name" && d.prop !== "type" && d.prop !== "source") {
            d.selectedValue = "";
          }
        });
      }
      const mapCheck = {
        title: "Source",
        type: "select",
        prop: "source",
        selectedValue: "Leads",
        placeholder: "Select Source",
        options: [
          {
            label: "Leads",
            value: "Leads",
          },
          {
            label: "Tasks",
            value: "Tasks",
          },
          {
            label: "Activities",
            value: "Activities",
          },
        ],
        isKpi: true,
        isStar: true,
      };
      const sourceData =
        this.postData.type === "Map"
          ? mapCheck
          : this.dashboardSettingsData.find((d) => d.prop === "source");

      const chartTypeData = this.dashboardSettingsData.find(
        (d) => d.prop === "type"
      );
      let sourceOption = "";
      if (typeData === "source") {
        sourceOption = dataValue;
      }
      if (typeData === "show_total") {
        
        this.showTotal = value;
 
      }
      if (typeData) {
        this.handlePostData(typeData, dataValue, selectedColumns, sourceOption);
      }

      if (isGrouped) {
        this.isGrouped = true;
      } else if (
        chartTypeData.selectedValue === "Table" ||
        chartTypeData.selectedValue === "TableType - Simple" ||
        chartTypeData.selectedValue === "TableType - Grouped"
      ) {
        this.isGrouped;
      } else {
        this.isGrouped = false;
      }
      const updatedData = this.dashboardSettingsData.map((d) => {
        if (
          this.widgetDataDetails?.additional_details?.time_interval &&
          d.prop === "time_interval" &&
          !d.selectedDataValue
        ) {
          const timeInterval = d.options.find(
            (d) =>
              d.value ===
              this.widgetDataDetails.additional_details?.time_interval
          );
          d.selectedValue = timeInterval ? timeInterval.label : "";
        } else if (d.prop === "limit" && this.widgetDataDetails) {
          d.selectedValue = this.widgetDataDetails.additional_details.limit
            ? this.widgetDataDetails.additional_details.limit
            : this.widgetDataDetails.limit;
        } else if (d.prop === itemData.prop) {
          d.selectedValue = dataValue.label ? dataValue.label : value;
        }
      });

      const xandyValues = this.getXandYList(sourceData);

      if (this.xOptions || this.yOptions) {
        this.dashboardSettingsData.map((d) => {
    
          if (
            typeData === "measure_group" &&
           ( dataValue?.metric_type === "float"||dataValue?.metric_type === "currency") &&
            d.prop === "buckets" &&
            (this.postData.type !== "Table" || this.isGrouped)
          ) {
            this.isBucket = true;
          } else if (
            typeData === "measure_group" &&
            d.prop === "buckets"
          ) {
            this.isBucket = false;
            this.bucketsSelecteddata={}
          this.postData.buckets={}
            
          }

          if (
            typeData === "measure_group" &&
            dataValue?.metric_type === "datetime" &&
            (this.postData.type !== "Table" || this.isGrouped)
          ) {
            this.isTimeInterval = false;
            this.isTimeInterval = true;
            if(d.prop==='time_interval'){
           
              this.postData.additional_details.time_interval=null
              d.selectedValue='';
            }
            this.postData.additional_details.time_interval=null
          } else if (
            typeData === "measure_group" &&
            dataValue?.metric_type !== "datetime"
          ) {
            this.isTimeInterval = false;
            if(this.postData.additional_details.time_interval||this.postData.additional_details.time_interval==null){
              delete this.postData.additional_details.time_interval
            }
            this.timeIntervalData = "";
            this.handlePostData(
              typeData,
              dataValue,
              selectedColumns,
              sourceOption
            );
          }
          if (
            d.prop === "name" &&
            !d.selectedValue &&
            !this.postData.name &&
            typeData !== "name"
          ) {
            d.selectedValue = `Widget - ${
              chartTypeData.selectedValue?.label || chartTypeData.selectedValue
            }`;
          }

          if (d.prop === "filters" && this.xOptions.length > 0) {
            d.options = this.xOptions || [];
            const dateTimeOptions = this.xOptions.filter(
              (dt) => dt.metric_type === "datetime"
            );

            this.filterColumnOption =
              this.postData.type === "Trend Line" ||
              this.widgetDataDetails?.type === "Trend Line"
                ? dateTimeOptions
                : this.xOptions || [];
          }
          if (d.prop === "measure") {
            if (d.selectedValue) {
              if (d.selectedValue.value) {
                d.selectedValue = d.selectedValue.value;
              }
              if (d.selectedValue.label) {
                d.selectedValue = d.selectedValue.label;
              }
            }
            if (this.postData.type === "KPI") {
              d.options = this.yOptions;
            } else {
              d.options = this.yOptions;
            }
          }
          if (d.prop === "measure_group" || d.prop === "measure_sort") {
           
            if (d.selectedValue?.value) {
              d.selectedValue = d.selectedValue.value;
            }
            if (d.selectedValue?.label) {
              d.selectedValue = d.selectedValue.label;
            }
            const dateTimeOptions = this.xOptions.filter(
              (dt) => dt.metric_type === "datetime"
            );
            if (d.subType === "grouped") {
              d.options = this.xOptions;
            } else {
              d.options =
                this.postData.type === "Trend Line" ||
                this.widgetDataDetails?.type === "Trend Line"
                  ? dateTimeOptions
                  : this.xOptions;
            }
          }

          if(d.prop==='closed_at'||d.prop==='activity_time'||d.prop==='measure_sort'){
            let deepCopyArray = JSON.parse(JSON.stringify( d.options));
            const noneOption={label:'None',value:null}
            if(!d.options?.find(ot=>ot.label==='None')){
              deepCopyArray.push(noneOption);
              d.options=deepCopyArray;

            }
          }
          if (d.prop === "show_total") {
            d.selectedValue = this.showTotal;
          }
          if(d.prop==='time_interval'&&!this.isTimeInterval){
            d.selectedValue=''
          }
        });

        this.groupedTableOptions = this.yOptions || [];
      }
      if (bucketsCheck) {
        this.isBucket = true;
      }
      if (this.widgetDataDetails) {
        this.apiDataValueUpdate(this.widgetDataDetails);
      }
    },

    getMeasureDetails(data) {
      this.measureDetails = data || [];
      this.handlePostData();
    },
    getBucketDetails(data) {
      this.bucketDetails = data;
      this.handlePostData();
    },
  },
  watch: {
postData(val){
 
  for (const key in val) {
        if (this.editReferenceData.hasOwnProperty(key)) {
          this.editReferenceData[key] = val[key];
        }else{
          this.editReferenceData[key] = val[key];
        }
      }

    this.$emit('update:editDrawerData',this.editReferenceData)
  
},
    isFilterEmpty(val) {
      if (val === true) {
        this.postData.filters = {};
      }
    },
    dashboardSettingsData(val) {
      this.dashboardSettingsData = val;
    },
    isGrouped(val) {},
  },
};
</script>

<style scoped>
.drawer-container {
  padding: 1rem;
}

.body-container {
  padding-bottom: 1rem;
}

.img-container {
  padding: 1rem 1rem 1rem 0rem;
}

.content-container {
  padding-right: 1rem;
}
</style>
