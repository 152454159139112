<template>
    <div v-loading="isLoadingTemplates">
        <section :class="['right_section', isCrmUser() ? 'right_sectionCRM' : '']">
            <div class="content_section">
                <div class="filter_section">
                    <div class="title">Site Survey Form</div>
                    <div class="search-section">
                        <el-input
                        class="search-field"
                        v-model="searchText"
                        placeholder="Search templates"
                        @input="handleSearchTextChange"
                        >
                        <template v-slot:prefix>
                            <b-icon class="icon" icon="search"></b-icon>
                        </template></el-input>
                        <el-dropdown @command="createFormFunc" v-if="allowCreateForm">
                            <el-button type="primary" class="createBtn">
                                Create Form<i class="el-icon-arrow-down el-icon--right"></i>
                            </el-button>
                            <template v-slot:dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item command='self_service'>Site Survey Forms</el-dropdown-item>
                                <el-dropdown-item command="marketing" >Marketing Forms</el-dropdown-item>
                            </el-dropdown-menu>
                            </template>
                        </el-dropdown>
                    </div>
                </div>
                <div class="box-card">
                    <div class="headerContainer">
                        <p class="headings form-name">FORM NAME</p>
                        <p class="headings form-type">FORM TYPE</p>
                        <p class="headings form-actions">ACTIONS</p>
                    </div>
                    <div class="bodyContainer">
                        <div class="flexBody" v-for="template in templates" :key="template.id" @click="previewFormFunc(template.id)">
                            <p class="desc template-title">{{template.title}}</p>
                            <div class="desc template-type">{{ templateType(template.template_type) }}</div>
                            <div class="iconsCont">
                                <i class="icon copy-alt" @click="copySiteSurveyTemplate(template.id, $event)"></i>
                                <i class="icon delete-alt" v-if="!template.is_default" @click="confirmDeleteTemplate(template.id, $event)"></i>
                            </div>
                        </div>
                        <div
                            v-infinite-scroll="loadMoreTemplates"
                            infinite-scroll-disabled="busy"
                            infinite-scroll-distance="10"
                            style="text-align: center"
                            >
                            <i v-if="busy" class="el-icon-loading infiniteScrollLoader" />
                        </div>
                    </div>
                </div>
                <DeleteDialog :dialogVisible="openDeleteTemplateDialog" :footerButtonType="'primary'" :footerButtonText="'Yes'"
                    :disableCancel="true" :width="'20%'" :deleteMessage="`Do you really want to delete this template?`"
                    :infoMessage="``" @handleClick="deleteSiteSurveyTemplate(templateToDelete)" @handleClose="openDeleteTemplateDialog = false">
                </DeleteDialog>
            </div>
        </section>
    </div>
</template>
    
<script>
import { isCrmUser } from "../../../utils";
import API from '../../../services/api';
import debounce from "debounce";
import { set } from '@vue/composition-api';
import DeleteDialog from '@/pages/dashboardCRM/components/dashboardV2/deleteDialog.vue';



export default {
    emits: [ "toggle", "previewFunc"],
    components: {
        DeleteDialog,
    },

    data() {
        return {
            isLoadingTemplates: true,
            templates: [],
            nextURL: null,
            busy: false,
            searchText:'',
            openDeleteTemplateDialog: false,
            templateToDelete: null,
        };
    },
    computed: {
        allowCreateForm(){
            const user = JSON.parse(localStorage.getItem('user'))
            const role = JSON.parse(localStorage.getItem('user')).role
            if (role == "ADMIN") return true
            return false
        }
    },
    mounted() {
        this.getTemplates();
        this.debouncedSearchTemplates = debounce(this.searchTemplates, 1000);
    },

    methods: {
        isCrmUser,

        createFormFunc(templateType) {
            const copiedData = false
            this.$emit('toggle', copiedData, templateType);
        },
        templateType(templateType){
            const templates = {
                self_service: 'Site Survey',
                marketing: 'Marketing'
            }
            return templates[templateType] || ''
        },
        async getTemplates() {
            this.isLoadingTemplates = true;
            try{
                const response = await API.EDITABLE_SITE_SURVEY.GET_TEMPLATES();
                this.templates = response.data.results;
                this.nextURL = response.data.next;
                this.isLoadingTemplates = false;
            }
            catch(e){
                console.error(e);
            }
        },
        previewFormFunc(template_id) {
            this.$emit('previewFunc', template_id);
        },
        loadMoreTemplates() {
            if(this.nextURL != null) {
                this.busy = true;
                this.loadMoreTemplatesAPI();
            }else {
                this.busy = false; // Set busy to false when there's no more data
            }
        },
        async loadMoreTemplatesAPI() {
            try {
                const response = await API.EDITABLE_SITE_SURVEY.LOAD_MORE_TEMPLATES(this.nextURL);
                this.templates.push(...response.data.results);
                this.nextURL = response.data.next;
                this.busy = false;
            }
            catch(e) {
                console.error(e);
            }
            this.busy = false;
        },
        async deleteSiteSurveyTemplate(template_id){
            this.isLoadingTemplates = true;
            try{
                const response = await API.EDITABLE_SITE_SURVEY.DELETE_TEMPLATE(template_id);
                this.templates = this.templates.filter(template => template.id !== template_id);
                this.$message({
                    message: 'Template deleted successfully!',
                    type: 'success',
                    center: true,
                })
            }
            catch(error){
                this.$message({
                    showClose: true,
                        message: error.response.data.message || "There was an error. Please try again.",
                        type: "error",
                        center: true
                });
            }
            this.isLoadingTemplates = false;

        },
        async copySiteSurveyTemplate(template_id, event){
            event.stopPropagation();
            try{
                this.isLoadingTemplates = true;
                let response = await API.EDITABLE_SITE_SURVEY.FETCH_QUESTIONS(template_id);
                let copiedTemplate = {...response.data, title: `${response.data.title} (Copy)`};
                let templateType = response.data.template_type;
                copiedTemplate = this.createCopyOfAlreadyCreatedTemplate(copiedTemplate);
                //pass this to create template and set formData to this data  
                // Define a callback function
                const onFinish = () => {
                    this.isLoadingTemplates = false;
                };
                // Pass this to create template and set formData to this data, include the callback in the emission
                this.$emit('toggle', copiedTemplate, templateType, onFinish);
            }
            catch(error){
                this.$message({
                    showClose: true,
                        message: "There was an error in copying. Please try again.",
                        type: "error",
                        center: true
                });
                this.isLoadingTemplates = false;
            }
        },
        createCopyOfAlreadyCreatedTemplate(template){
            //creates a object ready for create template API
            //by removing the keys on an already created template
            delete template.created_at;
            delete template.created_by;
            delete template.is_default;
            template.sections.map(section => {
                delete section.created_at;
                delete section.created_by;
                delete section.sequence;
                delete section.id;
                section.fields.map(field =>{
                    delete field.created_at;
                    delete field.created_by;
                    delete field.sequence;
                    delete field.id;
                });
            });
            return template;
        },
        handleSearchTextChange(){
            // Call the debounced version of searchTemplates
            this.debouncedSearchTemplates(this.searchText);
        },
        async searchTemplates(queryString){
            this.busy = true;
            //make search api call
            const response = await API.EDITABLE_SITE_SURVEY.SEARCH_TEMPLATE(queryString);
            this.templates = [...response.data.results];
            this.busy = false;
            this.nextURL = response.data.next;
            //to trigger load more, else no scroll bar and no subsequent load more calls happen 
            if(this.nextURL!= null){
                this.loadMoreTemplates();
            } 
        },
        confirmDeleteTemplate(template_id, event){
            event.stopPropagation();
            this.templateToDelete = template_id;
            this.openDeleteTemplateDialog = true;
        }
    },
};
</script>
    
<style scoped>
p {
    word-break: break-word;
}

.main-controller .right_section {
    background: var(--step-50);
}

.content_section {
    padding: 24px 32px;
    min-height: calc(100vh - 100px);
}

.filter_section {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.search-section{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
}

.search-section >>> .el-input__inner{
    border-radius: 4px;
    border: 1px solid var(--shades-ccc, #CCC);
    background: var(--White, #FFF);
    display: flex;
    padding: 8px 16px;
    padding-left: 50px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    color: var(--shades-999, #999);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.content_section .title {
    font-weight: 600;
    font-size: 2rem;
}

.createBtn {
    font-size: 16px;
    font-weight: 600;
    line-height: 21.12px;
}

.box-card {
    margin-top: 20px;
    border-radius: 16px;
    box-shadow: none !important;
    border: 1px solid #ccc;
    background-color: #fff;
}

.headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid #ccc;
}

.headings {
    color: #1C3366;
    font-size: 16px;
    font-weight: 600;
}
.form-name{
    flex: 2;
}
.form-type{
    flex: 1;
    margin-right: 24px;
}
.form-actions{
    flex: 1;
    display: flex;
    justify-content: end;
}

.bodyContainer {
    margin-bottom: 16px;
    overflow: hidden;
    overflow-y: scroll;
    height: calc(100vh - 300px);
}

.flexBody {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 24px;
    padding: 16px;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
}

.flexBody:last-child {
    border-bottom: none;
}

.desc {
    font-size: 16px;
    color: #222;
}
.template-type{
    flex: 1;
}
.template-title{
    flex: 2;
}
.iconsCont {
    display: flex;
    align-items: center;
    gap: 16px;
    padding-right: 6px;
    flex: 1;
    justify-content: end;
}

.icon {
    font-size: 22px;
    cursor: pointer;
}

.search-section >>> .el-input__prefix{
   top: 10px;
   left: 12px;
}




@media (min-width: 1281px) {
    .main-controller .right_section {
        width: calc(100% - 260px);
        margin-left: auto;
    }

    .right_sectionCRM {
        width: calc(100% - 74px) !important;
    }
}

@media (max-width: 450px) {

    .filter_section {
        display: flex;
        flex-direction: column;
        align-items: flex-start !important;
        gap: 16px;
    }
    .search-section{
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: stretch;
        width: 100%;
    }
}

@media (min-width: 451px) and (max-width: 700px) {

    .search-section{
        display: flex;
        flex-direction: row;
        gap: 16px;
        justify-content: space-between;
        width: 100%;
    }
}
</style>
    