
<div id="table">
  <div class="headerContainer" style="">
    <el-table
      :data="table"
      :border="true"
      class="tableTop"
      :header-cell-style="{
        color: '#1C3366',
        paddingLeft: '16px',
        fontWeight: '600',
        fontSize: '16px',
      }"
    >
      <el-table-column
        v-for="(column , index) in checkedDropDownHeaderList"
        :key="index"
        v-bind:label="column.title"
        :show-header="false"
        :min-width="column.width"
        :resizable="false"
      >
      </el-table-column>
    </el-table>
  </div>

  <!-- dropdownTable -->

  <hr class="hrClass" />
  <div
    class="bodyContainer"
    :style="{
      cursor: 'pointer',
      width: '100%',
      maxHeight: `calc(100vh - ${'38vh'}) !important`,
      minHeight: `calc(100vh - ${'38vh'}) !important`,
      overflowY: 'scroll',
      overflowX: 'hidden',
      background: 'white',
    }"
    v-infinite-scroll="loadMoreLeadData"
    infinite-scroll-disabled="busy"
    infinite-scroll-distance="10"
    @scroll="syncScroll"
  >
    <div>
      <el-collapse
        v-for="(item , index) in leadsNames"
        :key="index"
        style="width: 100%"
        v-model="activeNames"
        @change="handleArrow(item)"
      >
        <el-collapse-item :name="item.lead_id">
          <template v-slot:title>
            <div class="leadName">
              <div>
                <i
                  class="el-icon-arrow-down"
                  v-if="item.showArrow === false"
                  style="margin-left: 16px; color: #777; font-weight: 700"
                ></i>
                <i
                  class="el-icon-arrow-up"
                  v-else
                  style="margin-left: 18px; color: #777; font-weight: 700"
                ></i>
                <span
                  style="margin-left: 12px; font-size: 14px; color: #777"
                  >{{ item.lead__name }}</span
                >
                <span
                  style="
                    font-weight: 500;
                    font-size: 11px;
                    /* background: #ffffff; */
                    border-radius: 5px;
                    color: #777;
                    padding: 3px;
                    border: 1px solid #999999;
                    margin-left: 10px;
                  "
                  >{{ item.task_count }}
                  {{ spellTask(item.task_count) }}</span
                >
              </div>
            </div>

            <span
              style="
                margin-left: auto;
                display: flex;
                align-items: center;
                color: #777;
              "
              @click.stop="openMap(item.lead__address)"
              v-if="item.lead__address !== '' && item.lead__address"
            >
              <img
                src="./assets/Group.svg"
                alt=""
                style="margin-right: 10px"
              />
              <!-- <el-tooltip
                :content="item.lead__address"
                effect="dark"
                placement="top"
              > -->
              <p
                style="
                  white-space: nowrap;
                  text-decoration: underline;
                  margin-right: 10px;
                "
              >
                {{ item.address }}
              </p>
              <!-- </el-tooltip> -->
            </span>
          </template>
          <div @mouseover="handleMouse(item)">
            <ListViewTable
              :tableData="item.tasks[0]"
              :tableColumns="checkedDropDownTableList"
              :getCellStyles="getCellStyles"
              :getHeaderStyles="getHeaderStyles"
              :weight="600"
              :size="'16px'"
              :color="'#1C3366'"
              :showHeader="false"
              :tableHeightOffset="'40vh'"
              :cellHeight="'60px'"
              :paddingLeft="'16px'"
              @updateValues="updateValues"
              @updateStatus="updateStatus"
              @updatePriority="updatePriority"
              @nudgeClicked="handleNudgeClick"
              @updateDateValues="updateDateValues"
              @loadLead="loadLead"
              :loadMore="() => loadMore"
              :handleRowClick="onRowClick"
              :showTableHeight="false"
            />
          </div>
        </el-collapse-item>
        <hr class="hrClass" />
      </el-collapse>
    </div>
    <div
      style="display: flex; align-items: center; justify-content: center"
      v-if="showGroupLoader"
    >
      <i class="el-icon-loading"></i>
    </div>
  </div>
</div>
