import axios from "axios";
export default {
    FETCH_BUNDLES(params){
        return axios.get(`api/bundles/?${params}`);
    },
    FETCH_BUNDLES_BY_ID(id){
        return axios.get(`api/bundles/${id && `${id}`}/`);
    },
    ADD_BUNDLE(data){
        return axios.post(`api/bundles/`, data);
    },
    UPDATE_BUNDLE(id, data){
        return axios.put(`api/bundles/${id}/`, data);
    }

}