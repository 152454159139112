
<div class="container" v-loading.fullscreen="isLoadingFirstTime">
  <!-- -------------------------Error----------------->
  <div class="errorMsgCont" v-if="dashData.incomplete_orders_count > 0">
    <p class="errorMsg">
      Incomplete Order Request:
      <span class="errorUnbold">{{dashData.incomplete_orders_count}} Incomplete order requests are pending in your order section.</span>
    </p>
    <el-button type="primary" class="errorBtn" @click="goToOrders">Go to Orders</el-button>
  </div>

  <!-- -------------------------Dashboard----------------->
  <h2 class="dahboardHeading">Dashboard</h2>

  <div class="dashboardContainer">
    <div class="dashboardCont dashOne"  @click="TotalNoOfProjects" v-if="!isTataOrg">
      <div class="dashFlex" >
        <h1 class="dashNo">{{ dashData.projects_count }}</h1>

        <h3 class="dashTotal">Total Number <br />of Projects</h3>
      </div>
    </div>
    <div class="dashboardCont dashTwo" @click="ActiveProject">
      <div class="dashFlex" >
        <h1 class="dashNo" >{{ dashData.active_orders_count }}</h1>
        <h3 class="dashTotal">Total Active <br />Orders</h3>
      </div>
    </div>
    <div class="dashboardCont dashThree" @click="PendingOrders">
      <div class="dashFlex"  >
        <h1 class="dashNo">{{dashData.pending_orders_count}}</h1>
        <h3 class="dashTotal">Total Pending <br />Orders</h3>
      </div>
    </div>
    <div class="dashboardCont dashFour" @click="CompleteOrders">
      <div class="dashFlex" >
        <h1 class="dashNo" >{{dashData.completed_orders_count}}</h1>
        <h3 class="dashTotal">Total Completed <br />Orders</h3>
      </div>
    </div>
  </div>

  <!-- -------------------------Order Services------------->
  <div :class="isSelfDesignShow ? 'gridContainerOne' : 'gridContainerTwo'">
    <div class="orderServicesGridContainer">
    <h2 class="osHeading">Order Services</h2>
    <div :class="isSelfDesignShow ? 'osContainerOne' : 'osContainerTwo'">
      <!-- <div class="osCont">
        <h3 class="osTotal">
          3D Roof Modeling<br />
          <p class="osCredit">200 Credits</p>
        </h3>
        <el-button type="primary" class="orderBtn" @click="payNowPopupVisible('3d')">Order Now</el-button>
      </div> -->
      <div :class="['osCont', isSelfDesignShow ? 'selfDesignShow' : '']" v-for="serviceTypes in orderServicesTypes" :key="serviceTypes.id">
        <h3 class="osTotal">
          {{serviceTypes.serviceName}}<br />
        </h3>
        <p class="osCredit">Starting From {{serviceTypes.basePrice}} Credits</p>
          <p class="osNote">
            {{serviceTypes.description}}
          </p>
        <el-button
          type="primary"
          class="orderBtn"
          :loading="serviceTypes.isLoading"
          @click="newProjectFormVisible(serviceTypes)"
          >Order Now</el-button
        >
      </div>
    </div>
    </div>
    <div  class="selfDesignContainer" v-if="isSelfDesignShow">
      <div class="orderServicesGridContainer">
        <h2 class="osHeading">Self Designs</h2>
        <div class="osContainerOne osContainerSelfDesign">
          <div class="osCont selfDesignShow">
            <h3 class="osTotal">
              {{selfDesignInfo.name}}<br />
            </h3>
            <p class="osCredit">Starting From {{selfDesignInfo.base_price}} Credits</p>
            <p class="osNote">
              {{selfDesignInfo.description}}
            </p>
            <el-button
              type="primary"
              class="orderBtn"
              @click="selfDesignPopupVisible(selfDesignInfo)"
              >Create Now
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <createNewProjectPopup
    v-if="isNewProjectFormVisible"
    :isNewProjectFormVisible.sync="isNewProjectFormVisible"
    :disableCommercial="disableCommercial"
    :request_object_id ="request_object_id"
    :requestedServiceType="requestedServiceType"
  />
  <MyTaskMobileScreenPopup
    v-if="isMyTaskMobileScreenPopupVisible"
    :isMyTaskMobileScreenPopupVisible.sync="isMyTaskMobileScreenPopupVisible"
  />

  <self-design-popup v-if="isSelfDesignPopupVisible"
    :isSelfDesignPopupVisible.sync="isSelfDesignPopupVisible"
    :buttonclickedNEW.sync="buttonclickedNEW"
    @onDialogClose="onDialogClose" />
</div>
