import * as THREE from 'three';
import { VISUAL_STATES } from "../objects/visualConstants";
import * as utils from "../utils/utils";
import AddAttachmentUtils from './AddAttachmentUtils';
import { DEFAULT_COLOR_ATTACHMENT_EDGE, HIGHLIGHT_COLOR_ATTACHMENT_EDGE, PATIO_HEIGHT, LEAST_PATIO_WIDTH, TEMP_STACK_USED_BY_EDIT_MODE, CREATED_STATE } from '../coreConstants';
import { SmartroofModel } from '../objects/model/smartroof/SmartroofModel';
import Drawface from '../objects/model/smartroof/DrawFace';
import * as jsts from 'jsts';
import PolygonModel from '../objects/model/PolygonModel';
import PenToolRoofModel from '../objects/model/smartroof/PenToolRoofModel';
import Patio from '../objects/subArray/PowerPatio';
import PowerRoofCombinerBox from '../objects/ac/PowerRoofCombinerBox';
import { useTilesStore } from '../../stores/tilesStore';
import * as notificationsAssistant from '../../componentManager/notificationsAssistant';
export default class AddAttachmentMode {
    constructor(stage) {
        this.stage = stage;

        // set default false
        this.enabled = false;

        this.meshObjectGroup = new THREE.Group();
        this.stage.sceneManager.scene.add(this.meshObjectGroup);

        this.attachmentType = null;
        this.allRequiredEdgesForPatio = [];
        this.allModelNormalEdges = [];
        this.attachments = [];

        this.validEdges = [];
        this.attachmentProperties = null;

        this.intersectedAttachments = [];
        this.irradiancePatioEdge = [];
        this.heatMapCalled = false;
        this.generalObjects = [];
    }

    checkAttachmentPlaceable(isCombinerBox = false) {
        this.validEdges = this.getModelMappedOutlineEdgesForPatio(isCombinerBox);
        // get all the valid edges to highlight them
        // add check for edge if there are no valid edge then console error
        if (this.validEdges.length === 0) {
            // we should have popup instead of console error.
            console.error('There are no edges in scene');
            return false;
        }
        return true;
    }

    // initialize add patio mode
    async initialize(attachmentType, attachmentProperties = null) {
        this.attachmentType = attachmentType;

        // enable the add attachment mode when initialized
        this.enable();

        this.stage.stateManager.startTempStack(TEMP_STACK_USED_BY_EDIT_MODE);
        this.stage.stateManager.add({
            uuid: this.uuid,
            getStateCb: () => CREATED_STATE,
            withoutContainer: true,
        });

        // deselct everything when entering addattachment mode
        this.stage.selectionControls.setSelectedObject(this.stage.ground);

        if (attachmentProperties !== null) {
            this.attachmentProperties = attachmentProperties;
        }
        /**
         * TODO: remove parent handeling for attached element
         * attached elements will never have parent they will have attechedTo
        */
        this.startingParent = this.stage.ground;

        // checking if design changed for patio edge solar access api
        this.isDesignChanged = this.checkIfDesignChanged();


        // create attachedElement
        this.createAttachment();
        // hiding the extra patio table coming in middle of scene.
        this.currentAttachedElement.hideObject(); 
        // disable all controls
        this.stage.dragControls.disable();
        this.stage.duplicateManager.disable();
        this.stage.selectionControls.disable();

        // finding the midpoint 
        this.outlineMap = new Map()
        let initIndex = 0;
        this.validEdges.forEach((ele, index) => {
            const point1 = new THREE.Vector2(ele[0].point1.x, ele[0].point1.y);
            const point2 = new THREE.Vector2(ele[0].point2.x, ele[0].point2.y);
            const midPoint =point1.clone().add(point2.clone()).multiplyScalar(0.5);
            const width = this.getAttachedElementDimension().width;
            // mapping midpoint with edge indexes.
            if ((point1.clone().distanceTo(point2) - width) > 0) {
                this.outlineMap.set(`[${midPoint.x}, ${midPoint.y}]`,initIndex++);
            }
        });

         // else update visuals for add mode
        await this.updateVisuals();
        // notification added for patio
        this.initNotification();
    }

    enable() {
        this.enabled = true;
        this.addEventListener();
    }

    disable() {
        this.enabled = false;
        this.removeEventListener();
    }

    addEventListener() {
        this.stage.rendererManager.getDomElement().addEventListener('mousemove', this.onDocumentMouseMove, false);
        this.stage.rendererManager.getDomElement().addEventListener('mousedown', this.onDocumentMouseDown, false);
        this.stage.rendererManager.getDomElement().addEventListener('touchstart', this.touchStart, false);
    }

    removeEventListener() {
        this.stage.rendererManager.getDomElement().removeEventListener('mousemove', this.onDocumentMouseMove, false);
        this.stage.rendererManager.getDomElement().removeEventListener('mousedown', this.onDocumentMouseDown, false);
        this.stage.rendererManager.getDomElement().removeEventListener('touchstart', this.touchStart, false);
    }

    onDocumentMouseMove = (event) => {
        const mousePoint = utils.getNormalizedCameraCoordinates(event.clientX, event.clientY, this.stage);

        // updateing edge color to see if its hovered or not
        this.edgeColorUpdate(mousePoint);
    }

    onDocumentMouseDown = (event) => {
        const mousePoint = utils.getNormalizedCameraCoordinates(event.clientX, event.clientY, this.stage);
        if (this.highLightedIndex !== null) {
            this.attachmentProperties = this.currentAttachedElement.getState();
            if(this.currentAttachedElement instanceof Patio) {
                this.currentAttachedElement.initPropertyIntersectParams();
                this.currentAttachedElement.initGroundModelIntersectParams();
                this.currentAttachedElement.updatePowerTableGeometry();
            }
            // setting position for the attached element when clicked
            this.moveAttachedElement(mousePoint);

            // rotate the attached element to make it parallel to attached wall
            this.rotateAttachedElement(mousePoint);

            // after placing and roatating the attached element create new attached element for next placement
            if(this.currentAttachedElement instanceof Patio){
                this.createAttachment();
            }
            else if(this.currentAttachedElement instanceof PowerRoofCombinerBox){
                this.currentAttachedElement.showObject();
                this.currentAttachedElement.placeObject();
                this.currentAttachedElement.setHeightOnRoof();
                this.currentAttachedElement.addToPowerRoof();
                // general objects will be those objects which use attachment mode, but are not saved as attachments.
                this.generalObjects.push(this.currentAttachedElement);
                this.createAttachment();
                this.autoCompleteAfterPlacing();
                this.generalObjects = [];
            }

            // update the sappane
            this.stage.eventManager.addAttachmentMode(this.currentAttachedElement);
        }
    }

    touchStart = (event) => {

        // getting pseudo mouse point for hightlighting edges
        event.clientX = event.touches[0].clientX;
        event.clientY = event.touches[0].clientY;
        const mousePoint = utils.getNormalizedCameraCoordinates(event.clientX, event.clientY, this.stage);

        // updateing edge color to see if its hovered or not
        this.edgeColorUpdate(mousePoint);

        if (this.highLightedIndex !== null) {
            // setting position for the attached element when clicked
            this.moveAttachedElement(mousePoint);
            this.attachmentProperties = this.currentAttachedElement.getState();

            // rotate the attached element to make it parallel to attached wall
            this.rotateAttachedElement(mousePoint);

            // after placing and roatating the attached element create new attached element for next placement
            this.createAttachment();

            // update the sappane
            this.stage.eventManager.addAttachmentMode(this.currentAttachedElement);
        }
    }

    enableControls() {
        // enable controls
        this.stage.dragControls.enable();
        if (this.stage.attachedElementDragControls.dragEnabled) {
            this.stage.attachedElementDragControls.disable();
        }
        this.stage.duplicateManager.enable();
        this.stage.selectionControls.enable();
    }

    async onComplete() {
        this.enableControls();

        // disable add attachment mode
        this.disable();

        // clear the meshObjectGroup and dispose geometry
        this.disposeGeometry();

        // update visual for exit add patio mode
        this.updateVisuals();
        
        // remove current patio
        this.currentAttachedElement.removeObject();
        this.stage.selectionControls.setSelectedObject(this.stage.ground);
        if(this.currentAttachedElement instanceof PowerRoofCombinerBox && this.generalObjects.length){
            useTilesStore().isCombinerBoxPresentInScene = true;
        }
        // calling the savestate of attachments.
        this.saveAttachmentStates();

        // empty attachments
        this.attachments = [];

        // empty valid edges
        this.validEdges = [];

        this.attachmentProperties = null;
        this.heatMapCalled =false;
        // to hide the color bar for patio edge heatmap.
        this.stage.eventManager.solarAccessVisibility(false);

        // source to cancelpatio edge heatmap API
        this.source.cancel('Request canceled manually');
    }

    autoCompleteAfterPlacing(){
        this.onComplete();
        this.stage.eventManager.finishAttchmentAddMode();
    }
    onCancel() {
        this.enableControls();

        // disable add attachment mode
        this.disable();

        // clear the meshObjectGroup and dispose geometry
        this.disposeGeometry();

        // update visual for exit add patio mode
        this.updateVisuals();

        if (this.currentAttachedElement) this.currentAttachedElement.removeObject();

        // removing the placed attachments
        const attachments = [...this.attachments];
        attachments.forEach(object => object.removeObject())
        this.stage.selectionControls.setSelectedObject(this.stage.ground);

        // calling the savestate of attachments.
        this.saveAttachmentStates();

        // empty attachments
        this.attachments = [];

        // empty valid edges
        this.validEdges = [];
        this.heatMapCalled =false;

        // to hide the color bar for patio edge heatmap.
        this.stage.eventManager.solarAccessVisibility(false);

        // source to cancelpatio edge heatmap API
        this.source.cancel('Request canceled manually');
    }

    // update visuals for add patio mode
    async updateVisuals() {
        if (this.enabled) {
            this.stage.ground.switchVisualState(VISUAL_STATES.MIRROR_MODE, true);

            // highlight the edges
            this.highLightEdges();
            if(this.currentAttachedElement instanceof Patio){
                // calling the irradiance map for patio edge.
                await this.patioEdgeHeatMap();
                // to show the patio edge color bar.
                this.stage.eventManager.solarAccessVisibility(true);
            }
        }
        else {
            this.stage.ground.switchVisualState(VISUAL_STATES.DEFAULT_STATES.DEFAULT, true);
        }
    }

    // create and highlight the edges 
    highLightEdges() { 
        this.patioWidth = this.getAttachedElementDimension().length * Math.cos(utils.deg2Rad(8));
        // to remove higlighted edges after deleting the model.
        if (this.validEdges.length >= 0) {
            this.disposeGeometry();
        }
        // use instance mesh for Making highlighted edges
        this.instanceEdgeInfo = this.getPositionAndLengthForInstanceMesh(this.validEdges)
        // pushing the midpoints of the placeable edges accornding to patio.
        this.mappingMidpoint = this.instanceEdgeInfo.map((ele) => ele.midpoint) 
        const count = this.instanceEdgeInfo.length;

        const edgeGeometry = new THREE.PlaneGeometry(1, this.patioWidth);
        const edgeMaterial = new THREE.MeshBasicMaterial({
            color: 0xffffff,
            transparent: true, // Enable transparency
            opacity: 0.6,      // Set the desired alpha value 
        });
        this._instancedMesh = new THREE.InstancedMesh(edgeGeometry, edgeMaterial, count);
        edgeGeometry.computeBoundingSphere(10);
        this._instancedMesh.instanceMatrix.setUsage(THREE.DynamicDrawUsage);


        // Set instance positions, scales and rotations
        for (let i = 0; i < count; i++) {
            const T = new THREE.Object3D();
            T.rotation.z = utils.deg2Rad(180 - this.instanceEdgeInfo[i].azimuth);
            T.position.set(this.instanceEdgeInfo[i].position.x, this.instanceEdgeInfo[i].position.y, this.instanceEdgeInfo[i].position.z + 2);
            T.scale.x = this.instanceEdgeInfo[i].length;
            T.updateMatrix();

            this._instancedMesh.setMatrixAt(i, T.matrix);
            // if the heatmap api is not hit then give default color for the patio edges 
            // else create heatmap color for the edges.
            if (!this.heatMapCalled) {
                this._instancedMesh.setColorAt(i, new THREE.Color(DEFAULT_COLOR_ATTACHMENT_EDGE));
                this._instancedMesh.instanceMatrix.needsUpdate = true;
            }
             // finding the outline points for placeable edge.
             this.findOutlinePointsOfPlaceablePatio(edgeGeometry);
        }

        // when the heamap called mapping the heatmap color with their particular indexes. 
        if (this.heatMapCalled) {
            for (let i = 0; i < this.mappingMidpoint.length; i++) {
                this.showPatioEdgeHeatMapColor(this.mappingMidpoint[i], i);
            }
        }

        // adding the instanced Rail Mesh to objectsGroup for each Row
        this._instancedMesh.frustumCulled = false;
        this.meshObjectGroup.add(this._instancedMesh);
    }

    // finding out the outline points of highlighted edge for patio placement
    findOutlinePointsOfPlaceablePatio(edgeGeometry) {
        this.irradiancePatioEdge = [];
        const instancedGeometry = this._instancedMesh.geometry;

        if (instancedGeometry.attributes) {
            const instanceMatrix = new THREE.Matrix4();

            // Loop through each instance
            for (let i = 0; i < this._instancedMesh.count; i++) {
                this._instancedMesh.getMatrixAt(i, instanceMatrix);

                // Calculate the transformed vertices based on the plane parameters and instance matrix
                const instanceVertices = [];

                // Plane parameters
                const widthHalf = edgeGeometry.parameters.width / 2;
                const heightHalf = edgeGeometry.parameters.height / 2;

                // Calculate vertices
                const vertices = [
                    new THREE.Vector3(-widthHalf, -heightHalf, 0),
                    new THREE.Vector3(widthHalf, -heightHalf, 0),
                    new THREE.Vector3(widthHalf, heightHalf, 0),
                    new THREE.Vector3(-widthHalf, heightHalf, 0),
                ];

                for (let j = 0; j < vertices.length; j++) {
                    const vertex = vertices[j].clone();
                    vertex.applyMatrix4(instanceMatrix);
                    instanceVertices.push(vertex);
                }
                this.irradiancePatioEdge.push(instanceVertices);
            }
        }
    }
      
    getCornersForPatioMovement(attachedObject) {
        this.stage.patioBoundingCorners = [];
        this.patioWidth = attachedObject.getDimensions().length * Math.cos(utils.deg2Rad(8));

        // Remove highlighted edges after deleting the model.
        this.validEdges = this.getModelMappedOutlineEdgesForPatio();

        // Use instance mesh for making highlighted edges
        this.instanceEdgeInfo = this.getPositionAndLengthForInstanceMesh(this.validEdges, attachedObject);
        const count = this.instanceEdgeInfo.length;

        // Set instance positions, scales, rotations, and add to the scene
        for (let i = 0; i < count; i++) {
            const T = new THREE.Object3D();
            T.rotation.z = utils.deg2Rad(180 - this.instanceEdgeInfo[i].azimuth);
            T.position.set(this.instanceEdgeInfo[i].position.x, this.instanceEdgeInfo[i].position.y, this.instanceEdgeInfo[i].position.z);
            T.scale.x = this.instanceEdgeInfo[i].length;
            T.updateMatrix();

            // Here width, height, are dimensions of the instanced mesh
            let halfWidth = this.patioWidth / 2;
            let halfLength = this.instanceEdgeInfo[i].length / 2;

            // Calculate the four corners of the instanced mesh
            let corners = [
                new THREE.Vector2(-halfWidth, -halfLength),
                new THREE.Vector2(-halfWidth, halfLength),
                new THREE.Vector2(halfWidth, halfLength),
                new THREE.Vector2(halfWidth, -halfLength),
            ];
            const angle = T.rotation.z + utils.deg2Rad(90);
            // Apply transformation to corners
            corners = corners.map(corner => {
                const rotatedX = Math.cos(angle) * corner.x - Math.sin(angle) * corner.y;
                const rotatedY = Math.sin(angle) * corner.x + Math.cos(angle) * corner.y;
                return new THREE.Vector2(this.instanceEdgeInfo[i].positionForCorners.x + rotatedX, this.instanceEdgeInfo[i].positionForCorners.y + rotatedY);
            });

            // Now the 'corners' array contains the coordinates of the four corners of the instanced mesh
            this.stage.patioBoundingCorners.push(utils.convertVectorToArray(corners))
        }
    }

    disposeGeometry() {
        this.meshObjectGroup.traverse((child) => {
            if (child.geometry) {
                child.geometry.dispose();
                child.material.dispose();
            }
        });
        this.meshObjectGroup.clear();
    }

    /**
     * sets the color of the instance (sets default color if color not specified)
     * @param {Integer} index  - index of the instance
     * @param {THREE.color} color - color to set
     */
    setColorAt(index = 0, color = new THREE.Color(HIGHLIGHT_COLOR_ATTACHMENT_EDGE)) {
        this._instancedMesh.setColorAt(index, color);
        this._instancedMesh.instanceColor.needsUpdate = true;
    }

    /**
     * set color of instancemesh to default color
     */
    setDefaultColor() {
        for (let i = 0; i < this._instancedMesh.count; i++) {
            this._instancedMesh.setColorAt(i, new THREE.Color(DEFAULT_COLOR_ATTACHMENT_EDGE));
            this._instancedMesh.instanceColor.needsUpdate = true;
        }
    }
    
    getDormersFromModels(model) {
        let tempDormers = [];
        model.getChildren().forEach((child) => {
            if (child.getChildren().length > 0) {
                child.getChildren().forEach((gchild) => {
                    if (gchild instanceof SmartroofModel) {
                        tempDormers.push(gchild);
                        this.getDormersFromModels(gchild);
                    }
                })
            }
            else return tempDormers;
        });
        return tempDormers;
    }

    convertToVertices(coordinates) {
        const vertices = [];
        for (const coord of coordinates) {
          vertices.push(new THREE.Vector3(coord.x, coord.y, coord.z));
        }
        return vertices;
    }

    getModelMappedOutlineEdgesForPatio(isCombinerBox = false) {
        // to remove the old edges .
        this.allRequiredEdgesForPatio = [];
        // Create a JSTS GeometryFactory
        this.geometryFactory = new jsts.geom.GeometryFactory();

        let groundChildren = this.stage.ground.getChildren();
        // Filter out PenToolRoofModel if it has no children
        groundChildren = groundChildren.filter(child => !(child instanceof PenToolRoofModel && child.getChildren().length === 0));
        this.models = [];
        // get all patio placeable models from the ground
        if(isCombinerBox) groundChildren = groundChildren.filter((obj) => {return ((obj instanceof SmartroofModel) && (obj.connectedPowerRoof))});
        let heightToConsider = isCombinerBox ? 1 : PATIO_HEIGHT;
        for (let i = 0, len = groundChildren.length; i < len; i++) {
            const child = groundChildren[i];
            if (child.baseHeight + child.coreHeight > heightToConsider) {
                if (child instanceof PolygonModel && !child.isObstruction) {
                    this.models.push(child);
                }
                if (child instanceof SmartroofModel || child instanceof Drawface) {
                    this.models.push(child);
                    child.getChildren().forEach((child) => {
                        if (child.getChildren().length > 0) {
                            child.getChildren().forEach((gchild) => {
                                if (gchild instanceof SmartroofModel) {
                                    if (gchild.baseHeight + gchild.coreHeight > heightToConsider) this.models.push(gchild);
                                }
                            })
                        }
                    });
                }
            }
        }

        let polygons = [];
        this.models.forEach((model) => {
            if(model instanceof PenToolRoofModel && !model.isAutomated){
                polygons.push(model.getPatioVertices());
            }
            // If roof/pentool is automated then find the outer loop vertices.
            else if (model instanceof PenToolRoofModel && model.isAutomated) {
                const automatedRoofLoopVertices = this.getOuterVerticesFromAutomatedRoof(model);
                if (automatedRoofLoopVertices.length > 0 && utils.checkClockwise(automatedRoofLoopVertices)) {
                    automatedRoofLoopVertices.reverse();
                }
                polygons.push(automatedRoofLoopVertices);
            }
            else if (utils.checkClockwise(model.get2DVertices())) {
                polygons.push(model.get2DVertices().reverse());
            }
            else {
                polygons.push(model.get2DVertices());
            }
        });
        this.models.forEach((model, index) => {
            model.idPatio = index;
        });

        this.polygonDetailsMap = new Map();
        this.models.forEach((model) => {
            this.polygonDetailsMap.set(model.idPatio, model);
            this.allModelNormalEdges.push(...model.getEdges());
        });     

        // Convert polygons to JSTS Geometry objects
        this.jstsPolygons = polygons.map(vertices => {
            if (vertices.length > 0) {
                // Ensure the first and last points are the same to form a closed ring
                vertices.push(vertices[0]);
                const coordinates = vertices.map(coord => new jsts.geom.Coordinate(coord[0], coord[1]));
                return this.geometryFactory.createPolygon(this.geometryFactory.createLinearRing(coordinates));
            }
        });

        if (!this.jstsPolygons[0]) {
            return [];
        }

        // Initialize array to store intersected polygons
        let intersectedGroups = [];
        // Separate polygons based on intersection
        for (let i = 0; i < this.jstsPolygons.length; i++) {
            const polygon = this.jstsPolygons[i];
            // Check if the polygon is already assigned to a group
            let isAssigned = false;
            // Iterate through existing groups & check if the polygon intersects with any polygon in the group
            for (let group of intersectedGroups) {
                if (group.some(otherPolygon => polygon.intersects(otherPolygon))) {
                    // Add the polygon to the existing group
                    group.push(polygon);
                    isAssigned = true;
                    break;
                }
            }
            // If the polygon is not assigned to any existing group, create a new group
            if (!isAssigned) {
                intersectedGroups.push([polygon]);
            }
        }

        // Separate intersected and non-intersected polygons
        const nonIntersectedPolygons = intersectedGroups.filter((group) => group.length === 1);
        intersectedGroups = intersectedGroups.filter((group) => group.length > 1);

        // Calculate the union of intersected polygons if any
        let outerEdges = []; // to store the outer edges with and without intersections of the models.
        let union;
        if (intersectedGroups.length > 0) {
            intersectedGroups.forEach((group) => {
                const geometryCollection = new jsts.geom.GeometryCollection(group, this.geometryFactory);
                union = jsts.operation.union.UnaryUnionOp.union(geometryCollection);
                outerEdges.push(union.getCoordinates());
            });
        }

        const allEdges = [];
        let outerEdgesVertices;
        // Function to extract edges from a polygon
        if (outerEdges && outerEdges.length > 0) {
            outerEdges.forEach((edgeArray) => {
                outerEdgesVertices = this.convertToVertices(edgeArray);
                for (let i = 0; i < outerEdgesVertices.length - 1; i++) {
                    const start = outerEdgesVertices[i + 1];
                    const end = outerEdgesVertices[i];
                    allEdges.push([start, end]);
                }
            });
        }
        if (nonIntersectedPolygons.length > 0) {
            for (const polygon of nonIntersectedPolygons) {
                const edges = this.getEdgesFromPolygon(polygon);
                allEdges.push(...edges);
            }
        }

        // now allEdges has all required edges
        // so assciate each edge with belonging model details required.
        allEdges.forEach((edge) => {
            const point1 = this.geometryFactory.createPoint(new jsts.geom.Coordinate(edge[0].x, edge[0].y));
            const point2 = this.geometryFactory.createPoint(new jsts.geom.Coordinate(edge[1].x, edge[1].y));
            const midPoint = [(edge[0].x + edge[1].x) / 2, (edge[0].y + edge[1].y) / 2];
            // Perform a point-in-polygon test for the midpoint against all original polygons
            this.models.forEach((model) => {
                const polygon = this.jstsPolygons[model.idPatio];
                if (polygon.covers(point1) || polygon.covers(point2)) {
                    // check if midpoint of the segmented edge is inside the polygon
                    const modelForCheck = this.polygonDetailsMap.get(model.idPatio);
                    const modelForCheckVertices = (modelForCheck instanceof PenToolRoofModel) ? modelForCheck.getPatioVertices() : modelForCheck.get2DVertices();
                    if (utils.checkPointOnEdgesWithReducedPrecision(modelForCheckVertices, midPoint)) {
                        // If any point of the edge belongs or contained by the polygon, associate the edge with the polygon
                        const squaredDistance = ((edge[0].x - edge[1].x) ** 2) + ((edge[0].y - edge[1].y) ** 2);
                        if (squaredDistance > (LEAST_PATIO_WIDTH ** 2)) {
                            this.allRequiredEdgesForPatio.push(this.getAzimuthFromEdge(edge, modelForCheck));
                        }
                    }
                }
            });
        });

        function filterUniqueEdges(allEdges) {
            const uniqueEdges = [];
            // Define a custom comparison function for edges
            function compareEdges(edge1, edge2) {
                return edge1[0].azimuth === edge2[0].azimuth &&
                    edge1[0].point1.equals(edge2[0].point1) &&
                    edge1[0].point2.equals(edge2[0].point2)
            }
            allEdges.forEach((edge) => {
                // Check if the current edge is unique by comparing it with previously added edges
                const isUnique = !uniqueEdges.some(uniqueEdge => compareEdges(uniqueEdge, edge));
                // If the edge is unique, add it to the list of unique edges
                if (isUnique) {
                    uniqueEdges.push(edge);
                }
            });
            return uniqueEdges;
        }
        const filteredEdges = filterUniqueEdges(this.allRequiredEdgesForPatio);
        return filteredEdges;
    }

    // for automated roofs get the outer loop of vertices 
    getOuterVerticesFromAutomatedRoof(automatedRoof) {
        let faces = [];
        automatedRoof.getChildren().forEach((roofFace) => {
            faces.push(utils.convertArrayToVector(roofFace.get3DVertices()));
        });

        // Process the array of faces to ensure clockwise order and create polygons
        let jstsPolygons = faces.map((face) => {
            let vertices = face.slice();
            if (utils.checkClockwise(vertices)) {
                vertices = vertices.reverse();  // Ensure counter-clockwise for JSTS
            }
            vertices.push(vertices[0]);  // Close the loop
            const coordinates = vertices.map(coord => new jsts.geom.Coordinate(coord.x, coord.y));
            const polygon = this.geometryFactory.createPolygon(this.geometryFactory.createLinearRing(coordinates));
            return polygon;
        });

        function findOuterBoundary(polygons) {
            const geometryFactory = new jsts.geom.GeometryFactory();

            // Merge all polygons into one
            const geometryCollection = geometryFactory.createGeometryCollection(polygons);
            const mergedGeometry = jsts.operation.union.UnaryUnionOp.union(geometryCollection);

            let boundaryCoordinates = [];
            // Check if the merged result is a GeometryCollection
            if (mergedGeometry instanceof jsts.geom.GeometryCollection) {
                // Iterate over each geometry in the collection
                for (let i = 0; i < mergedGeometry.getNumGeometries(); i++) {
                    const geometry = mergedGeometry.getGeometryN(i);
                    // If the geometry has an exterior ring, process it
                    if (geometry.getExteriorRing) {
                        const outerBoundary = geometry.getExteriorRing();
                        let coordinates = outerBoundary.getCoordinates().map(coord => ({ x: coord.x, y: coord.y }));
                        boundaryCoordinates = boundaryCoordinates.concat(coordinates);
                    }
                }
            } else if (mergedGeometry.getExteriorRing) {
                // If the merged result is a single polygon, get its exterior ring
                const outerBoundary = mergedGeometry.getExteriorRing();
                boundaryCoordinates = outerBoundary.getCoordinates().map(coord => ({ x: coord.x, y: coord.y }));
            }
            return boundaryCoordinates;
        }

        // get the bounndary coordinates
        let outerBoundaryArray;
        try {
            outerBoundaryArray = utils.convertVectorArrayTo2DArray(findOuterBoundary(jstsPolygons));
        }
        catch (error) {
            notificationsAssistant.error({
                title: 'Complex roof geometry detected.',
                message: 'Power models cannot be placed on complex roof edges.',
            });
            return [];
        }
        outerBoundaryArray = outerBoundaryArray.slice(0, -1);
        // remove collinear vertices
        outerBoundaryArray = utils.convertVectorArrayTo2DArray(utils.removeCollinearVerticesWithTolerance(utils.convertArrayToVector(outerBoundaryArray)));
        automatedRoof.boundaryPointsAutomatedRoof = outerBoundaryArray;
        return outerBoundaryArray;
    }   

    getAzimuthFromEdge(segment, model) {
        let edgesWithAzimuth = [];
        let angle = 0
        let v1 = 0;
        let v2 = 0;
        angle = utils.toDegrees(Math.atan2(
            (segment[1].y - segment[0].y), -(segment[1].x - segment[0].x),
        ));
        v1 = (segment[0]);
        v2 = (segment[1]);
        // atan2 returns between -pi and pi and we want between 0 and 360. 0 being in North
        if (angle < 0) angle += 360;
        edgesWithAzimuth.push({
            azimuth: angle.toFixed(2),
            point1: v1,
            point2: v2,
            model: model,
        });
        return edgesWithAzimuth;
    }

    getEdgesFromPolygon(polygon) {
        const edges = [];
        let edge;
        const coordinates = polygon[0]._shell._points._coordinates;
        for (let i = 0; i < coordinates.length - 1; i++) {
            const point1 = new THREE.Vector3(coordinates[i].x, coordinates[i].y);
            const point2 = new THREE.Vector3(coordinates[i + 1].x, coordinates[i + 1].y);
            edge = [point1, point2];
            edges.push(edge);
        }
        return edges;
    }
}

Object.assign(
    AddAttachmentMode.prototype,
    AddAttachmentUtils,
);