import { onObjectSelection } from "../../componentManager/sapPaneAssistant";
import { serverBus } from "../../main";
import { useMapImagesStore } from "../../stores/mapImages";
import CustomImage from "../objects/ground/CustomImage";
import API from '../../services/api';
import * as notificationsAssistant from '../../componentManager/notificationsAssistant';
import { drawingArrayToVectorArray, getHighestZ, getImageDimensions, getNormalizedCameraCoordinates } from "../utils/utils";
import OutlinePoints from "../objects/subObjects/OutlinePoints";
import { MAX_DRAWING_POINTS, UPSCALED_IMAGE_NAME } from "../coreConstants";
import * as THREE from 'three';
import LengthMeasurement from "../objects/subObjects/LengthMeasurement";
import { SCALE_GUI_LINE_COLOR, SCALE_GUI_LINE_WIDTH, SCALE_GUI_POINT_COLOR, SCALE_GUI_POINT_SIZE, THICK_BORDER_OUTLINE_POINT_IMAGE_URL } from "../objects/visualConstants";
import { getNewScale } from "../utils/customImageEditUtils";
const GROUND_SIZE_LIMITS = {
    MIN: 10,
    MAX: 1000,
    ALLOWED_DEVIATION: 0.75,
};
export default class CustomImageManager {
    constructor(stage) {
        this.stage = stage;
        this.customImages = [];
        this.currentEditingImage = null;
        this.tempImageStats = {'rotation':0, 'scale':0, 'position':{'x':0, 'y':0}, 'opacity':0, 'imgDimension':this.stage.getImageDimensions().width};
        this.mapImageStore = useMapImagesStore();
        serverBus.$emit('customImageEditFunc', this.initCustomImageEditMode.bind(this));
        serverBus.$emit('customImageViewToggleFunc', this.toggleImageVisibility.bind(this));
        serverBus.$emit('uploadCustomImage', this.addNewImage.bind(this));

        this.outlinePoints = [];
        this.objectsGroup = new THREE.Group();
        this.objectsGroup.container = this;
        this.stage.sceneManager.scene.add(this.objectsGroup);
        const pointSprite = new THREE.TextureLoader().load(THICK_BORDER_OUTLINE_POINT_IMAGE_URL);
        this.pointMaterial = new THREE.PointsMaterial({
            size: SCALE_GUI_POINT_SIZE,
            map: pointSprite,
            color: SCALE_GUI_POINT_COLOR,
        });
        this.lineMaterial = new THREE.LineBasicMaterial({
            linewidth: SCALE_GUI_LINE_WIDTH,
            linecap: 'square',
            color: SCALE_GUI_LINE_COLOR,
        });
        this.canvas = stage.rendererManager.getDomElement();
        //functions used in event listeners
        this.onClickFunc = this.onClick.bind(this);
        this.onMouseMoveFunc = this.onMouseMove.bind(this);
        this.renderer = null;
        this.textureCanvas = null;
        this.dataUrl = null;
        this.textureScene = new THREE.Scene();
        this.textureScene.background = new THREE.Color(0xffffff);
        this.textureLoader = new THREE.TextureLoader();
        this.upscaledImageMesh = null;
    }

    async addUpScaledImageToCustomImages(imageData, options) {
        imageData.position = options.customPosition;
        imageData.scale = options.customScale;
        imageData.imageOpacity = 100;
        try {
            // API to save image
            let savedImgData = (await API.FETCH_MAP.POST_MAP_IMAGE(this.saveImageJSON(imageData))).data;
            this.mapImageStore.SET_CUSTOM_IMAGES([
                ...this.mapImageStore.customImages,
                {
                    id: savedImgData.id,
                    name: savedImgData.name,
                    url: savedImgData.url,
                    rotation: 0,
                    scale: options.customScale,
                    opacity: 100,
                    is_visible: true,
                    location: options.customPosition
                }
            ]);
    
            // Ensure this section is fully processed before calling onComplete
            await this.createAndSetupCustomImage(savedImgData, options);
    
        } catch (e) {
            notificationsAssistant.error({
                title: 'Image Upload',
                message: 'Error uploading custom image',
            });
        }
    }
    
    async createAndSetupCustomImage(savedImgData, options) {
        this.currentEditingImage = new CustomImage(
            this.stage,
            savedImgData.url,
            savedImgData.id,
            true,
            true,
            false,
            false,
            options
        );

        this.tempImageStats.rotation = this.currentEditingImage.getRotation();
        this.tempImageStats.scale = this.currentEditingImage.getScale();
        this.tempImageStats.position = {
            'x': this.currentEditingImage.getPosition().clone().x,
            'y': this.currentEditingImage.getPosition().clone().y
        };
        this.tempImageStats.opacity = this.currentEditingImage.getOpacity();
        this.tempImageStats.imgDimension = this.stage.getImageDimensions().width;        

        // Await texture loading
        await this.currentEditingImage.loadTextures();
        this.currentEditingImage.name = savedImgData.name;
        this.customImages.push(this.currentEditingImage);
        this.currentEditingImage.updateOpacity(100);
        this.onComplete();
    }
    

    async addNewImage(imageData){
        const uploadImage = notificationsAssistant.loading({
            title: 'Image Upload',
            message: 'Uploading custom image',
        });
        try {
        //api to save image will be added here
        let savedImgData = (await API.FETCH_MAP.POST_MAP_IMAGE(this.saveImageJSON(imageData))).data;
        this.mapImageStore.SET_CUSTOM_IMAGES([...this.mapImageStore.customImages,{id:savedImgData.id, name:savedImgData.name, url:savedImgData.url, rotation:0, scale:1, opacity: 83, is_visible:true, location: {x:0,y:0}}]);
        this.currentEditingImage = new CustomImage(this.stage, savedImgData.url, savedImgData.id, true, true, false);
        this.currentEditingImage.name = savedImgData.name;
        this.customImages.push(this.currentEditingImage);
        this.initCustomImageEditMode(this.currentEditingImage.imageId);
        this.currentEditingImage.customTextureLoadPromise[0].then(()=>{
        notificationsAssistant.close(uploadImage);
            notificationsAssistant.success({
                title: 'Image Upload',
                message: 'Custom image uploaded successfully',
            });     
        })
        }
        catch (e) {
            notificationsAssistant.close(uploadImage);
            notificationsAssistant.error({
                title: 'Image Upload',
                message: 'Error uploading custom image',
            });
        }
    }

    loadCustomImagesOnLoad() {
        for (let ind = 0; ind < this.mapImageStore.GET_CUSTOM_IMAGES.length; ind++) {
            const imgEl = this.mapImageStore.GET_CUSTOM_IMAGES[ind];
            let isOldImage = false;
            if(imgEl.name === "oldImage" || imgEl.name === "oldImageScaled") isOldImage = true;
            let imageObj = new CustomImage(this.stage, imgEl.url, imgEl.id, true, true, true, isOldImage);
            if(imageObj.customTextureLoadPromise){
                this.stage.ground.loadingPromises.push(...imageObj.customTextureLoadPromise);
            }
            imageObj.scale = imgEl.scale;
            if(isOldImage && imgEl.name === "oldImage"){
                imageObj.updateScaleForOldImages(imageObj.scale);
            }
            imageObj.rotation = imgEl.rotation;
            imageObj.updateGeometry();
            if(imgEl.is_visible !== imageObj.isVisible)imageObj.toggleVisiblity();
            imageObj.updateOpacity(imgEl.opacity);
            imageObj.moveObject(imgEl.location.x, imgEl.location.y);
            imageObj.name = imgEl.name;
            this.customImages.push(imageObj);
        }
    }

    initCustomImageEditMode(id){
        for(let i = 0; i < this.customImages.length; i+=1){
            if(this.customImages[i].imageId===id){
                this.currentEditingImage = this.customImages[i];
                break;
            } 
        }
        this.tempImageStats.rotation = this.currentEditingImage.getRotation();
        this.tempImageStats.scale = this.currentEditingImage.getScale();
        this.tempImageStats.position = {'x':this.currentEditingImage.getPosition().clone().x, 'y':this.currentEditingImage.getPosition().clone().y};
        this.tempImageStats.opacity = this.currentEditingImage.getOpacity();
        this.tempImageStats.imgDimension = this.stage.getImageDimensions().width;
        this.stage.switchToCustomImageEditMode();
        if(!this.currentEditingImage.isVisible)this.currentEditingImage.toggleVisiblity();
        this.currentEditingImage.enableDragging();
        this.currentEditingImage.showEdges();
        this.currentEditingImage.showOutlinePoints();
        this.stage.selectionControls.disable();
        this.stage.duplicateManager.disable();
        this.stage.eventManager.setButtonStatusWhileEditingCustomImage(
            this.onComplete.bind(this),
            this.onComplete.bind(this),
            this.currentEditingImage,
        );
        onObjectSelection(this.currentEditingImage);
    }

    async onComplete(isDeleted=false) {
        if(this.tempImageStats.imgDimension !== this.stage.getImageDimensions().width){
            API.FETCH_MAP.UPDATE_MAP_DATA(this.stage.getDesignId(), {
                latitude_for_map: useMapImagesStore().latitude,
                longitude_for_map: useMapImagesStore().longitude,
                zoomLevel: useMapImagesStore().zoomLevel,
                dimensions: useMapImagesStore().dimensions,
                square: useMapImagesStore().square,
                hasOldImage: useMapImagesStore().hasOldImage,
                groundMapImageVisible: useMapImagesStore().groundMapImageVisible,
                imgDimension: this.stage.getImageDimensions().width,
            });
            useMapImagesStore().imgDimension = this.stage.getImageDimensions().width;
            let ratio = this.tempImageStats.imgDimension/this.stage.getImageDimensions().width;
            this.customImages.forEach(img => {
                img.patchImageScale(ratio);
            });
        }   
        if(!isDeleted && (this.tempImageStats.position.x !== this.currentEditingImage.getPosition().x 
        || this.tempImageStats.position.y !== this.currentEditingImage.getPosition().y 
        || this.tempImageStats.rotation !== this.currentEditingImage.getRotation() 
        || this.tempImageStats.scale !== this.currentEditingImage.getScale()
        || this.tempImageStats.opacity !== this.currentEditingImage.getOpacity()) ) {
            //patch custom image and update in store
            let postObj = this.saveImageJSON(this.currentEditingImage);
            if(postObj.name == "oldImage"){
                postObj.name = "oldImageScaled";
            }
            await API.FETCH_MAP.PATCH_MAP_IMAGE(this.currentEditingImage.imageId, postObj);
        }
        this.currentEditingImage.hideEdges();
        this.currentEditingImage.hideOutlinePoints();
        this.stage.duplicateManager.enable();
        this.stage.selectionControls.enable();
        this.stage.selectionControls.removeSelectedObject(this);
        this.stage.selectionControls.setSelectedObject(this.stage.ground);
        this.stage.dragControls.removeIfExists(this);
        this.stage.eventManager.finishCustomImageEditMode();
        this.stage.inCustomImageEditMode = false;
        this.currentEditingImage = null;
        this.updateRoofTextures();
    }

    async updateRoofTextures(){
        const groundPlaneGeometry = this.stage.ground.plane.geometry;
        if (!groundPlaneGeometry.boundingBox) {
            groundPlaneGeometry.computeBoundingBox();
        }
        const baseWidth = groundPlaneGeometry.boundingBox.max.x - groundPlaneGeometry.boundingBox.min.x;
        const baseHeight = groundPlaneGeometry.boundingBox.max.y - groundPlaneGeometry.boundingBox.min.y;

        const cartesianToUVSize = (vec2) => new THREE.Vector2(vec2.x / baseWidth, vec2.y / baseHeight);
        
        const cartesianToUVPosition = (vec2) => new THREE.Vector2((vec2.x + (baseWidth / 2)) / baseWidth, (vec2.y + (baseHeight / 2)) / baseHeight);
        
        const imageMeshes = [this.stage.ground.groundImage, ...this.customImages];

        if(this.upscaledImageMesh){
            imageMeshes.push(this.upscaledImageMesh);
        }

        
        const imageProperties = imageMeshes
            .filter(imageMesh => imageMesh.objectsGroup.visible)
            .map(imageMesh => {
                const { plane } = imageMesh;
                const { position, geometry: { parameters: size }, material: { map: texture, opacity = 1 } } = plane;
                const uvPosition = cartesianToUVPosition(position.clone());
                const scale = imageMesh.scale;
                const sizeValue = new THREE.Vector2(size.width*scale, size.height*scale);
                const uvSize = cartesianToUVSize(sizeValue);
                const rotation = plane.rotation.z;
                const offset = new THREE.Vector2(uvPosition.x, uvPosition.y);
                offset.add(new THREE.Vector2(-uvSize.x / 2, -uvSize.y / 2));
                return { texture, offset, uvSize, rotation, opacity };
            });
        
        const textureUniforms = {};
        
        imageProperties.forEach(({ texture, offset, uvSize, rotation, opacity }, i) => {
            Object.assign(textureUniforms, {
                [`texture${i}`]: { value: texture },
                [`topOffset${i}`]: { value: offset },
                [`topSize${i}`]: { value: uvSize },
                [`rotation${i}`]: { value: rotation },
                [`opacity${i}`]: { value: opacity },
            });
        });
        
        const uniforms = THREE.UniformsUtils.merge([
            THREE.ShaderLib.lambert.uniforms,
            textureUniforms,
        ]);
        

        const customVertexShader = `

        varying vec3 vViewPosition;

        #include <common>
        #include <uv_pars_vertex>
        #include <displacementmap_pars_vertex>
        #include <envmap_pars_vertex>
        #include <color_pars_vertex>
        #include <fog_pars_vertex>
        #include <normal_pars_vertex>
        #include <morphtarget_pars_vertex>
        #include <skinning_pars_vertex>
        #include <shadowmap_pars_vertex>
        #include <logdepthbuf_pars_vertex>
        #include <clipping_planes_pars_vertex>

        
        void main() {
                    
            #include <uv_vertex>
            #include <color_vertex>
            #include <morphcolor_vertex>

            #include <beginnormal_vertex>
            #include <morphnormal_vertex>
            #include <skinbase_vertex>
            #include <skinnormal_vertex>
            #include <defaultnormal_vertex>
            #include <normal_vertex>

            #include <begin_vertex>
            #include <morphtarget_vertex>
            #include <skinning_vertex>
            #include <displacementmap_vertex>
            #include <project_vertex>
            #include <logdepthbuf_vertex>
            #include <clipping_planes_vertex>
            vUv = uv;
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
            #include <logdepthbuf_vertex>

            vViewPosition = - mvPosition.xyz;

            #include <worldpos_vertex>
            #include <envmap_vertex>
            #include <shadowmap_vertex>
            #include <fog_vertex>
        }
        `

        const customFragmentShader = `
        uniform vec3 diffuse;
        uniform vec3 emissive;
        uniform float opacity;
        varying vec2 vUv;
        
        #include <common>
        #include <packing>
        #include <dithering_pars_fragment>
        #include <color_pars_fragment>
        #include <uv_pars_fragment>
        #include <map_pars_fragment>
        #include <alphamap_pars_fragment>
        #include <alphatest_pars_fragment>
        // #include <alphahash_pars_fragment>
        #include <aomap_pars_fragment>
        #include <lightmap_pars_fragment>
        #include <emissivemap_pars_fragment>
        #include <envmap_common_pars_fragment>
        #include <envmap_pars_fragment>
        #include <fog_pars_fragment>
        #include <bsdfs>
        #include <lights_pars_begin>
        #include <normal_pars_fragment>
        #include <lights_lambert_pars_fragment>
        #include <shadowmap_pars_fragment>
        #include <bumpmap_pars_fragment>
        #include <normalmap_pars_fragment>
        #include <specularmap_pars_fragment>
        #include <logdepthbuf_pars_fragment>
        #include <clipping_planes_pars_fragment>
    
        ${imageProperties.map((_, i) => `uniform sampler2D texture${i};`).join('\n')}
        ${imageProperties.map((_, i) => `uniform vec2 topOffset${i};`).join('\n')}
        ${imageProperties.map((_, i) => `uniform vec2 topSize${i};`).join('\n')}
        ${imageProperties.map((_, i) => `uniform float rotation${i};`).join('\n')}
        ${imageProperties.map((_, i) => `uniform float opacity${i};`).join('\n')}
        
        void main() {
            #include <clipping_planes_fragment>
    
            vec4 diffuseColor = vec4( diffuse, opacity );
            ReflectedLight reflectedLight = ReflectedLight( vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ) );
            vec3 totalEmissiveRadiance = emissive;
            #include <logdepthbuf_fragment>
            #include <map_fragment>
            #include <color_fragment>
            #include <alphamap_fragment>
            #include <alphatest_fragment>
            // #include <alphahash_fragment>
            #include <specularmap_fragment>
            #include <normal_fragment_begin>
            #include <normal_fragment_maps>
            #include <emissivemap_fragment>
    
            // accumulation
            #include <lights_lambert_fragment>
            #include <lights_fragment_begin>
            #include <lights_fragment_maps>
            #include <lights_fragment_end>
    
            // modulation
            #include <aomap_fragment>
    
            vec3 outgoingLight = reflectedLight.directDiffuse + reflectedLight.indirectDiffuse + totalEmissiveRadiance;
            outgoingLight = (0.5*diffuseColor.rgb) + (0.5*outgoingLight);
            #include <envmap_fragment>
            // #include <opaque_fragment>
            #include <tonemapping_fragment>
            // #include <colorspace_fragment>
            #include <fog_fragment>
            #include <premultiplied_alpha_fragment>
            #include <dithering_fragment>
    
            // custom code for texture mapping
            vec2 uv = vUv;
            vec4 baseColor = vec4(1.0, 1.0, 1.0, 1.0);                
    
            ${imageProperties.map((_, i) => `
            vec2 center${i} = topOffset${i} + (0.5 * topSize${i});
            vec2 topUv${i} = ((uv));
            topUv${i} -= center${i};                
            float cosTheta${i} = cos(rotation${i});
            float sinTheta${i} = sin(rotation${i});
            mat2 rotationMatrix${i} = mat2(cosTheta${i}, -sinTheta${i}, sinTheta${i}, cosTheta${i});
            topUv${i} = rotationMatrix${i} * topUv${i};
            topUv${i} += center${i};
            topUv${i} = (topUv${i} - topOffset${i}) / topSize${i};
    
    
            if (topUv${i}.x >= 0.0 && topUv${i}.x <= 1.0 && topUv${i}.y >= 0.0 && topUv${i}.y <= 1.0) {
                vec4 topColor${i} = texture2D(texture${i}, topUv${i});    
                baseColor = mix(baseColor, topColor${i}, topColor${i}.a*opacity${i});
            }
            `).join('\n')}
            
            gl_FragColor = vec4(outgoingLight, 1.0) * baseColor;            
        }
        `
        const material = new THREE.ShaderMaterial({
            vertexShader: customVertexShader,
            fragmentShader: customFragmentShader,
            uniforms,
            lights: true,
        });

        this.stage.ground.updateRoofTexture(material);
    }

    deleteImage(){
        API.FETCH_MAP.DELETE_MAP_IMAGE(this.currentEditingImage.imageId);
        this.currentEditingImage.deleteImage();
        let imagesInStore = [...this.mapImageStore.GET_CUSTOM_IMAGES];
        for(let i = 0; i < this.customImages.length; i+=1){
            if(this.customImages[i].imageId === this.currentEditingImage.imageId){
                this.customImages.splice(i,1);
            } 
            if(imagesInStore[i] && imagesInStore[i].id === this.currentEditingImage.imageId){
                imagesInStore.splice(i,1);
                this.mapImageStore.SET_CUSTOM_IMAGES([...imagesInStore]);
            }
        }
        this.onComplete(true);
    }

    initScalingGui(){
        // this.stage.viewManager.enableXray(this.stage.ground);
        // this.stage.viewManager.unbindXRayVision();
        this.addEventListeners();
        this.numVertices = 0;
        this.lengthMeasurement = null;
        this.scaleGUINotificationObject = this.stage.eventManager
            .setButtonStatusWhileEditingCustomImageInScaleGUIMode(this.onCancelScaleGui.bind(this));
        this.vertices = new Float32Array(MAX_DRAWING_POINTS * 3);
        this.initLines();
        this.initPoints();
        this.stage.dragControls.disable();
    }

    onCancelScaleGui(){
        this.numVertices = 0;
        // this.stage.viewManager.bindXRayVision();
        // this.stage.viewManager.disableXray(this.stage.ground);
        if (this.scaleGUINotificationObject) {
            this.stage.eventManager.closeNotificationObject(this.scaleGUINotificationObject);
            this.scaleGUINotificationObject = null;
        }
        this.removeListeners();
        this.stage.eventManager.setButtonStatusWhileEditingCustomImage(
            this.onComplete.bind(this),
            this.onComplete.bind(this),
            this.currentEditingImage,
        );
        if(!this.stage.dragControls.isEnabled()) this.stage.dragControls.enable();

        this.stage.sceneManager.scene.remove(this.objectsGroup);
        this.objectsGroup = new THREE.Group();
        this.objectsGroup.container = this;
        this.stage.sceneManager.scene.add(this.objectsGroup);

        this.vertices = new Float32Array(MAX_DRAWING_POINTS * 3);
        if (this.lengthMeasurement) {
            this.lengthMeasurement.remove();
        }
        this.lengthMeasurement = null;
    }

    initPoints() {
        // Geometry for drawing points
        const pointGeometry = new THREE.BufferGeometry();
        pointGeometry.setAttribute('position', new THREE.BufferAttribute(this.vertices, 3));

        this.pointsMesh = new THREE.Points(pointGeometry, this.pointMaterial);
        this.pointsMesh.name = 'Scale points';
        this.pointsMesh.geometry.setDrawRange(0, 0);
        this.pointsMesh.position.z = 1;
        this.pointsMesh.frustumCulled = false;

        // Add the mesh to the group to add it to the scene
        this.objectsGroup.add(this.pointsMesh);

        // initialize outline points
        this.outlinePoints = [];
        this.outlinePoints.push(new OutlinePoints(
            0,
            0,
            0,
            this,
            this.stage,
            {
                OUTLINE_POINT_COLOR: 0xffffff,
            },
        ));
    }

    initLines() {
        const lineGeometry = new THREE.BufferGeometry();
        lineGeometry.setAttribute('position', new THREE.BufferAttribute(this.vertices, 3));

        const { lineMaterial } = this;

        this.lineMesh = new THREE.Line(lineGeometry, lineMaterial);
        this.lineMesh.name = 'Scale lines';
        this.lineMesh.geometry.setDrawRange(0, 0);
        this.lineMesh.frustumCulled = false;

        // Add the mesh to the group to add it to the scene
        this.objectsGroup.add(this.lineMesh);
    }

    onMouseMove(event) {
        this.updateMousePoint(event);
        this.updateGeometry();
    }

    updateMousePoint(event) {
        const mousePoint = getNormalizedCameraCoordinates(
            event.clientX,
            event.clientY,
            this.stage,
        );
        if (this.numVertices < 2) {
            this.vertices[(this.numVertices * 3)] = mousePoint.x;
            this.vertices[(this.numVertices * 3) + 1] = mousePoint.y;
            this.vertices[(this.numVertices * 3) + 2] = getHighestZ(this.stage.ground) + 5;

            this.outlinePoints[this.numVertices].setPosition(
                this.vertices[this.numVertices * 3],
                this.vertices[(this.numVertices * 3) + 1],
                this.vertices[(this.numVertices * 3) + 2],
            );
        }
    }

    updateGeometry(){
        if (this.numVertices < 2) {
            this.updatePoints();
            this.updateLines();

            this.updateMeasurements();
        }
    }

    updatePoints() {
        this.pointsMesh.geometry.setDrawRange(0, this.numVertices + 1);
        this.pointsMesh.geometry.attributes.position.needsUpdate = true;
    }

    updateLines() {
        this.lineMesh.geometry.setDrawRange(0, this.numVertices + 1);
        this.lineMesh.geometry.attributes.position.needsUpdate = true;
    }

    updateMeasurements() {
        if (this.numVertices >= 1) {
            if (this.lengthMeasurement === null) {
                this.lengthMeasurement = new LengthMeasurement(
                    this.outlinePoints[0],
                    this.outlinePoints[1],
                    this.stage,
                    this,
                );
            }
            else {
                this.lengthMeasurement.update();
            }
            this.lengthMeasurement.show();
        }
    }

    onClick(event) {
        if (this.numVertices < 2) {
            const vertex = getNormalizedCameraCoordinates(event.clientX, event.clientY, this.stage);
            this.addPoint(vertex);
        }
    }

    addPoint(vertex) {
        this.vertices[(this.numVertices * 3)] = vertex.x;
        this.vertices[(this.numVertices * 3) + 1] = vertex.y;
        this.vertices[(this.numVertices * 3) + 2] = 1;

        this.numVertices += 1;

        // if (this.currentlyActiveControl === SCALE_GUI_CONTROL) {
            this.outlinePoints.push(new OutlinePoints(
                vertex.x,
                vertex.y,
                vertex.z,
                this,
                this.stage,
                {
                    OUTLINE_POINT_COLOR: 0xffffff,
                },
            ));
            if (this.numVertices === 2) {
                this.lengthMeasurement.update();
                this.lengthMeasurement.setTextEditable();
            }
        // }
    }

    addEventListeners() {
        this.canvas.addEventListener('mousemove', this.onMouseMoveFunc, false);
        this.canvas.addEventListener('click', this.onClickFunc, false);
    }

    removeListeners() {
        this.canvas.removeEventListener('click', this.onClickFunc, false);
        this.canvas.removeEventListener('mousemove', this.onMouseMoveFunc, false);
    }

    handleOnCancel() {
        this.onCancelScaleGui();
    }

    handleVertexPlace() {
        this.completeScalingGUI();
    }

    completeScalingGUI() {
        const newValue = this.outlinePoints[0]
            .getPosition().distanceTo(this.outlinePoints[1].getPosition());
        const vertices = drawingArrayToVectorArray(this.vertices, this.numVertices);
        const scaleOrigin =
            (new THREE.Vector2()).copy(vertices[0]).add(vertices[1]).divideScalar(2);
        const newScale = getNewScale(vertices, newValue, this.currentEditingImage.getScale());
        if(newScale<=10){
            this.currentEditingImage.updateScale(newScale);
        }
        else {
            if (this.scaleGUINotificationObject) {
                this.stage.eventManager.closeNotificationObject(this.scaleGUINotificationObject);
                this.scaleGUINotificationObject = null;
            }
            this.stage.eventManager.customImageScalingOutOfBoundError();
        }
        serverBus.$emit('customImageScaled', this.currentEditingImage.getScale());
        this.onCancelScaleGui();
    }

    handleVertexMove() {}

    toggleImageVisibility(id){
        for(let i = 0; i < this.customImages.length; i+=1){
            if(this.customImages[i].imageId===id){
                this.customImages[i].toggleVisiblity();
                break;
            } 
        }
    }

    hideAllImages(){
        for(let i = 0; i < this.customImages.length; i+=1){
            this.customImages[i].hardHide();
        }
    }

    showUpscaledImage() {
        for(let i = 0; i < this.customImages.length; i+=1){
            if(this.customImages[i].name === UPSCALED_IMAGE_NAME){
                this.customImages[i].hardShow();
            } 
        }
    }

    hideUpscaledImage() {
        for(let i = 0; i < this.customImages.length; i+=1){
            if(this.customImages[i].name === UPSCALED_IMAGE_NAME){
                this.customImages[i].hardHide();
            } 
        }
    }

    showAllImages(){
        for(let i = 0; i < this.customImages.length; i+=1){
            this.customImages[i].hardShow();
        }
    }

    hideCustomImagesOnSLDswitch(){
        for(let i = 0; i < this.customImages.length; i+=1){
            this.customImages[i].hideImage();
        }
    }

    showCustomImagesOnSLDswitch(){
        for(let i = 0; i < this.customImages.length; i+=1){
            if(this.customImages[i].isVisible) this.customImages[i].showImage();
        }
    }

    //groundSize Functions
    getGroundSizeLimits() {
        let groundSize = getImageDimensions(useMapImagesStore().latitude, useMapImagesStore().longitude, useMapImagesStore().zoomLevel, useMapImagesStore().dimensions, useMapImagesStore().dimensions).width;
        return {
            min: parseFloat(groundSize.toFixed(3)),
            max: parseFloat((Math.min(
                GROUND_SIZE_LIMITS.MAX,
                groundSize * (2 + GROUND_SIZE_LIMITS.ALLOWED_DEVIATION),
            )).toFixed(3)),
        };
    }

    saveImageJSON(obj){
        return {
            "url": obj.url,
            "rotation": obj.rotation ? obj.rotation : 0,
            "scale": obj.scale ? obj.scale : 1,
            "offset": [
                0,
                0
            ],
            "opacity" : obj.imageOpacity ? obj.imageOpacity : 83,
            "location": obj.position ? {"x":obj.position.x ,"y":obj.position.y} : {"x":0, "y":0},
            "design": this.stage.getDesignId(),
            "source": "manual_upload",
            "zoom": 0,
            "name": obj.name ? obj.name : null,
            "is_visible": obj.isVisible==null ? true : obj.isVisible,
        }
    }
}