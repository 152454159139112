<template>
  <div>
    <el-dialog 
      :visible="isOpen"
      class="delete-dialog"
      @close="dialogBoxClosingFunc"
      :show-close="false"
      :title="title"
      :close-on-click-modal="shouldOutsideClickClose"
      append-to-body
    >
      <div v-if="!hideCloseButton" class='close_button'>
        <img 
          src='../../pages/home/assets/img/close.svg' 
          alt='close' 
          @click="noClickFunc(true)"
        >
      </div>
      <div v-if="!hideIcon" class='alert'>
        <img 
          src='../../assets/img/warning-fill.svg' 
          alt='alert'
          class="warningImage"
        >
      </div>
      <p class='msg'>{{message}}</p>
      <div class='buttons-container'>
        <el-button 
          v-if="isShowCancelButton"
          @click="noClickFunc(false)">
          {{ secondaryButtonText }}
        </el-button>
        <el-button
          :loading="isloading"
          type="primary"
          @click="yesClickFunc">
          {{ primaryButtonText }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  emits: ["onClickNo", "onClickYes"],
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    isShowCancelButton: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "",
    },
    message: {
      type: String,
      default: "This is a dialog box.",
    },
    hideIcon: {
      type: Boolean,
      default: false
    },
    hideCloseButton: {
      type: Boolean,
      default: false
    },
    shouldOutsideClickClose: {
      type: Boolean,
      default: true
    },
    primaryButtonText: {
      type: String,
      default: "Yes"
    },
    secondaryButtonText: {
      type: String,
      default: "No"
    },
  },
  data(){
    return{
      isButtonClicked: false,
      isloading:false,
    }
  },
  methods: {
    dialogBoxClosingFunc() {
      if (this.shouldOutsideClickClose && !this.isButtonClicked){
        this.$emit('onClickNo', true)
      };
      this.isButtonClicked = false;
    },
    noClickFunc(isClose) {
      this.isButtonClicked = true;
      this.$emit('onClickNo', isClose);
    },
    yesClickFunc() {
      this.isButtonClicked = true;
      this.isloading = true;
      this.$emit('onClickYes', true);
    },
  },
};
</script>

<style scoped>
.delete-dialog >>> .el-dialog {
  width: 25vw;
  border-radius: 8px;
  margin-top: 30vh !important;
}
.delete-dialog >>> .el-dialog__header {
  display: none;
}
.delete-dialog .alert{
    padding: 15px 0 0px 10px;
}
.delete-dialog .alert,
.delete-dialog .msg
.delete-dialog .button-container {
  display: flex;
  justify-content: center;
}
.delete-dialog .close_button {
  display: flex;
  justify-content: flex-end;
  padding: 10px 5px 0 0;
  cursor: pointer;
}
.warningImage {
  width: 4.675rem !important;
  height: 4.194rem !important;
  margin: 1rem 9.888rem 1.369rem 8.125rem !important;
  object-fit: contain !important;
}
.msg {
  font-family: "Helvetica Neue" !important;
  font-size: 16px !important;
  font-weight: 100 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.5 !important;
  letter-spacing: normal !important;
  text-align: center !important;
  color: #222 !important;
  word-break: normal;
  margin: 0%;
  white-space: break-spaces;
  padding-top: 1rem;
}
.confirmButton {
  font-size: 18px !important;
  font-weight: bold !important;
  color: #fff !important;
  width: 9.5rem;
  height: 3rem;
  margin: 25px;
}

.buttons-container {
  display: flex;
  justify-content: center;
  padding: 24px;
}
</style>
