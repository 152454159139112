
<div>
  <el-card class="box-card cardCss desktop">
    <el-tabs v-model="activeTab">
      <el-tab-pane :label="tab.label" :name="tab.label" v-for="tab in tabs" :key="tab.value">
        <TabContainer :typeOfTariff="tab.value" id="1" @update="updateCaller()" :key="counter" :searchQuery="searchQuery"/>
      </el-tab-pane>
    </el-tabs>
  </el-card>
</div>
