<template>
    <div id="parent-container" v-loading="isPageLoading">
			<!-- Here changed id to editor2 as it was messign with the parent compoent -->
			<div v-if="editorMode" id="editor2" name="editor" class="editor jodit-document">    
			</div>
			<div v-else id="paginated-editor" class="preview">
			</div>
			<div id="paginated-editor-temp" class="preview"></div>
			<PaymentSchedulePage
				v-if="isPaymentSchedulePageVisible  && isInPreviewMode && isContractInDesignLevel && includePaymentSchedule && paymentMethodType!='loan'"
				:isInPreviewMode="isInPreviewMode"
			/>
    </div>
</template>


<script>
import {Jodit} from 'jodit/es2021/jodit.js'
import { uploadFileToBlob, fetchSasToken , isUSFlagEnabled} from "@/utils.js";
import { containerNameForContracts } from "@/constants.js";
import PaymentSchedulePage from "./paymentSchedulePage.vue";
import { v4 } from "uuid";
import { mapState, mapActions } from 'pinia';
import { useContractManagementStore } from '../../../../stores/contractManagement';
import { useUiStore } from '../../../../stores/ui';
import API from "@/services/api/";

export default{
		emits: ["exitPreview"],
		components: {
			PaymentSchedulePage
		},
		props:{
			isInPreviewMode:{
				type:Boolean,
				default:false
			},
			isContractInDesignLevel:{
				type:Boolean,
				default:true
			},
			includePaymentSchedule:{
				type:Boolean,
				default:false
			},
			isInContractListPage:{
				type:Boolean,
				default:false
			}
		},
    data(){
			return{
				editor:'',
				paginatedContent:'',
				editorMode:true,
				isPaymentSchedulePageVisible:false,
				fileLink:null,
				fileName:'',
				htmlContent:"",
				contractId:'',
				editorType:'',
				token: this.$route.params.token,
				isUSFlagEnabledFromParam : this.$route.params.isUSFlagEnabled,
				keyValuePlaceholders:{},
				isPageLoading:false,
				allAddedPlaceholders:[],
				allAddedCustomPlaceholders:[],
				allAddedNotCustomPlaceholders:[],
				keyValuePairForAddedPlaceholders:{},
				paymentMethodType: ""
			}
    },
		async created(){
			this.isPageLoading = true;
			if(!this.isInPreviewMode){
				API.SET_TOKEN_HEADER(this.token);
			}
		},
    async mounted(){
			if(!this.isInPreviewMode){
				let promise1 =  this.fetchAllPlaceholdersOptions() // no need to use await for this
				await this.fetchContractTemplate();
				let promise2 = this.fetchDocumentFromBlobStorage(); // promise2 depends on the response of fetchContractTemplate so need to use await
				await Promise.all([promise1,promise2])
			}
			if(this.isInPreviewMode && this.isContractInDesignLevel){ // to check if payment type is Loan or not...accordingly render payment schedule
				this.fetchPaymentMethodDetails();
			}
			this.initializeEditor();
			this.replacePlaceholdersBeforePagination();
			setTimeout(this.paginateContent,1000)
    },
		computed:{
			...mapState(useContractManagementStore, {
				htmlContentFromStore: "GET_HTML_CONTENT",
				CMStore: (state) => state
    		}),
			computedPageClass(){
				if(isUSFlagEnabled() || this.isUSFlagEnabledFromParam == 'true'){
					return 'pageUS';
				}
				else{
					return 'pageNonUS';
				}
			},
			pageHeightForContent(){
				if(isUSFlagEnabled()){
					return 766;   // 792px - 24px (padding top) - 1px (border top) -1px (border bottom)
				}
				else{
					return 816;
				}
			},
			pageWidthForContent(){
				if(isUSFlagEnabled()){
					return 612;  
				}
				else{
					return 595;
				}
			},

		},
    methods:{
			...mapActions(useContractManagementStore, {
				setId: "SET_ID_OF_LARGER_ELEMENTS",
				SET_KEY_VALUE_PLACEHOLDER:"SET_KEY_VALUE_PLACEHOLDER",
				UPDATE_PLACEHOLDERS_ARRAY:"UPDATE_PLACEHOLDERS_ARRAY",
				SET_KEY_VALUE_FOR_ADDED_PLACEHOLDER:"SET_KEY_VALUE_FOR_ADDED_PLACEHOLDER",
			}),
			...mapActions(useUiStore, {
				hideChatIcon:"hideChatIcon"
			}),
			isUSFlagEnabled,
			fetchSasToken,
			async fetchPaymentMethodDetails(){
				try{
					const response = await API.DESIGNS.FETCH_PRICING_ONLY(this.CMStore.selectedDesignIdForPreview);
					this.paymentMethodType = response.data.payment_method_type;
				}
				catch(e){
					console.error(e);
					this.$message({
						showClose: true,
						message: "There is some issue in fetching the payment method details",
						type: "error",
						center: true,
					});
				}
			},
			async fetchAllPlaceholdersOptions(){
				let response;
				try{
					response =  await API.CONTRACT_TEMPLATES.FETCH_ALL_PLACEHOLDERS_OPTIONS()
					this.prepareKeyValuePairPlaceholders(response.data)
				}
				catch(e){
					console.error(e)
				}
			},
			prepareKeyValuePairPlaceholders(allPlaceholdersTypes){
				this.keyValuePlaceholders = JSON.parse(JSON.stringify(this.CMStore.keyValuePlaceholders)) 
				allPlaceholdersTypes.forEach((eachPlaceholderType)=>{
					let placeholdersArray = eachPlaceholderType.placeholders
					placeholdersArray.forEach((eachPlaceholder)=>{
						this.keyValuePlaceholders[eachPlaceholder.identifier] = eachPlaceholder
					})
				})
				this.SET_KEY_VALUE_PLACEHOLDER(this.keyValuePlaceholders)
			},
			segregateCustomAndOtherPlaceholder(allAddedPlaceholders){
				this.allAddedCustomPlaceholders = allAddedPlaceholders.filter((placeholder)=>{
					return placeholder.placeholder_type =="custom_placeholders"
				})
				this.allAddedNotCustomPlaceholders = allAddedPlaceholders.filter((placeholder)=>{
					return placeholder.placeholder_type !="custom_placeholders"
				})
			},
			async fetchContractTemplate(){
				let response;
				try{
					this.contractId = this.$route.params.contractId || this.CMStore.selectedContractId
					response =  await API.CONTRACT_TEMPLATES.FETCH_CONTRACT_TEMPLATE(this.contractId)
					this.editorType = response.data.template_type
					this.fileName =  response.data.file_name
					this.allAddedPlaceholders = response.data.additional_details?.placeholders || []
					this.segregateCustomAndOtherPlaceholder(this.allAddedPlaceholders)
					this.UPDATE_PLACEHOLDERS_ARRAY(this.allAddedPlaceholders || [])
					if(this.isContractInDesignLevel){
						await this.fetchAddedPlaceholdersWithActualValues(this.allAddedPlaceholders)
					}
				}
				catch(e){
					this.$message({
						showClose: true,
						message: "There is some issue in fetching the template",
						type: "error",
						center: true,
					});
					console.error(e)
					this.$router.push({ name: "documentManagement" });
				}
			},
			async fetchAddedPlaceholdersWithActualValues(){
				// let addedPlaceholdersIdentifier = []
				let addedNotCustomPlaceholdersIdentifier = [];
      			let addedCustomPlaceholdersIdentifier = [];
				this.allAddedNotCustomPlaceholders.forEach((addedPlaceholder)=>{
					addedNotCustomPlaceholdersIdentifier.push(addedPlaceholder.placeholder_key)
				})
				this.allAddedCustomPlaceholders.forEach((addedPlaceholder)=>{
					addedCustomPlaceholdersIdentifier.push(addedPlaceholder.placeholder_key)
				})
				if(!this.allAddedPlaceholders.length)
					return
				let payload = {
					"placeholders":[...addedNotCustomPlaceholdersIdentifier],
					"design_id": this.CMStore.selectedDesignIdForPreview || this.$route.params.designId,
					"contract_id":this.contractId,
        			"custom_placeholders":[...addedCustomPlaceholdersIdentifier]
				}
				const response = await API.CONTRACT_TEMPLATES.FETCH_ADDED_PLACEHOLDERS_WITH_ACTUAL_VALUES(payload);
				this.prepareKeyValuePairForAddedPlaceholders(response.data)
			},
			prepareKeyValuePairForAddedPlaceholders(allPlaceholdersTypes){
				allPlaceholdersTypes.forEach((eachPlaceholderType)=>{
					let placeholdersArray = eachPlaceholderType.placeholders
					let placeholderCategory = eachPlaceholderType.identifier
					placeholdersArray.forEach((eachPlaceholder)=>{
						eachPlaceholder.category = placeholderCategory
						this.keyValuePairForAddedPlaceholders[eachPlaceholder?.identifier] = eachPlaceholder
					})
				})
				this.SET_KEY_VALUE_FOR_ADDED_PLACEHOLDER(this.keyValuePairForAddedPlaceholders)
			},
			async fetchDocumentFromBlobStorage(){
				let sasToken =  await fetchSasToken(containerNameForContracts)
				this.fileLink = `https://downloadstsl.blob.core.windows.net/${containerNameForContracts}/${this.fileName}?${sasToken}`
				const response = await fetch(this.fileLink);
        		this.htmlContent = await response.text();
			},
			initializeEditor(){
				// document.body.style.overflow = 'hidden';
				this.editor = Jodit.make('#editor2',{
					"autofocus": true,
					width: this.pageWidthForContent,
					buttons: [
							{
								name: 'header', // First group
								buttons: [
									'paragraph', 
									'font',
									'fontsize', 
								]
							},
							'|',
							{
								name: 'text', // Second group
								buttons: [
									'bold', 
									'italic', 
									'underline'
								]
							},
							'|',
							{
								name: 'alignment', // Third group
								buttons: [
									'left', 
									'center', 
									'right', 
									'justify'
								]
							},
							'|',
							{
								name: 'list', // Fourth group
								buttons: [
									'ul', 
									'ol'
								]
							},
							'|',
							'hr', // Horizontal line
							'table', // Table
							// 'image', // Image
							// 'print' // Print
					],
					// height: 800,
					"uploader": {
							"insertImageAsBase64URI": true,
					},
					selectionCellStyle: 'border: 1px double #1e88e5 !important;',
					useExtraClassesOptions: true,
					extraButtons: [
						{
							name: 'insertImage',
							iconURL:'src/pages/jodit/assets/imageUpload.svg',
							exec: function (editor) {
									const input = document.createElement('input');
									input.setAttribute('type', 'file');
									input.setAttribute('accept', 'image/*');
									input.click();
									let vm = this;
									// Store a reference to the Jodit editor
									let joditEditor = editor;
									input.onchange = async function () {
											const imageFile = input.files[0];
											if (!imageFile) {
													return;
											}
											if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
													return;
											}
											const uuid =  v4();
											const imageInfo = await uploadFileToBlob(input.files,uuid,containerNameBulkImport);
											const image = joditEditor.selection.j.createInside.element('img');
											image.setAttribute('src',`https://downloadstsl.blob.core.windows.net/${containerNameBulkImport}/${uuid}`);
											joditEditor.selection.insertNode(image);

									}
							}
						},
						{
							name: 'Add Page Break',
							iconURL: 'src/pages/jodit/assets/pageBreak.svg',
							exec: function (editor) {

									const divElement = document.createElement('div');
									divElement.className = 'html2pdf__page-break';
									// divElement.contentEditable = 'false'; // Set contentEditable to false
									divElement.style.border = "1px solid #000"
									divElement.style.height = "1px"
									// divElement.innerHTML = "Client Name";
									editor.s.insertHTML(divElement)
									editor.s.insertHTML('  ');

							}
						},
					]
				});
				this.editor.value = this.htmlContentFromStore || this.htmlContent;
			},
			replacePlaceholdersBeforePagination(){
				let htmlData = this.editor.value
				let parentDiv = document.createElement('div');
				parentDiv.innerHTML = htmlData;
				parentDiv.className = "modifiedContainer"
				this.replacePlaceholdersByValues(parentDiv);
				this.editor.value = parentDiv.innerHTML
			},
			paginateContent(){
				let ParentPopupDiv = document.getElementById('parent-container');
				let editorContent = ParentPopupDiv.getElementsByClassName('jodit-wysiwyg')[0];
				let pages = [];
				let currentPage = null;
				let currentHeight = 0;
				let pageNo=0;;
				let pageNoAndNodesHeightMApping = {};
				let tdElements = editorContent.querySelectorAll('td')
				tdElements.forEach(td => {
						td.style.border = '1px solid #dadada'; // Add border to the <td> element
						td.style.padding = '.4em'; // 
						td.style.minWidth = '2em'; // 
						td.style.verticalAlign = 'middle'; // 
						// td.style.fontSize = "16px";
				});
				let returnedPages=[];
				// initializing the current page
				pageNo++;
				currentPage = document.createElement('div');
				currentPage.className = this.computedPageClass;
				pages.push(currentPage);
				pageNoAndNodesHeightMApping[pageNo]=[]
				Array.from(editorContent.children).forEach((child,index) => {
						console.log("outer most iteration still going on",child,child.offsetHeight);
						let childHeight = child.offsetHeight
						let marginTop,marginBottom;
						console.log('child.style.marginTop: ', child.style.marginTop);
						// -----------------Manage Margins-----------------------------//
						if(!child.style.marginTop){
							marginTop = 0;
						}
						else if(child.style.marginTop.includes('px')){
							marginTop = Number(child.style.marginTop.replace("px",""))
						}
						else if(child.style.marginTop.includes('rem')){
							marginTop = Number(child.style.marginTop.replace("rem","")) * 16
						}
						else if(child.style.marginTop.includes('em')) {
							marginTop = Number(child.style.marginTop.replace("em","")) * 16
						}
						console.log('child.style.marginBottom: ', child.style.marginBottom);
						if(!child.style.marginBottom){
							marginBottom = 0;
						}
						else if(child.style.marginBottom.includes('px')){
							marginBottom = Number(child.style.marginBottom.replace("px",""))
						}
						else if(child.style.marginBottom.includes('rem')){
							marginBottom = Number(child.style.marginBottom.replace("rem","")) * 16
						} 
						else if(child.style.marginBottom.includes('em')){
							marginBottom = Number(child.style.marginBottom.replace("em","")) * 16
						} 
						// ----------------------------End Margins-----------------------------//
						if(childHeight>=this.pageHeightForContent && child.localName!='table' && child.localName!='ul' && child.localName!='ol' ){
							this.$message({
								showClose: true,
								message: `Your content is greater than the standard page height. Please break it into parts.`,
								type: "error",
								center: true
							})
							this.setId(child.id)
							console.log('size of this element getting more than the page height @@@@@@@@@@@@@@@@@@@@@@@@', child.id, child.localName,index);
							this.$emit('exitPreview')
							return;
						}
						//------------ its not reading client height for BR and HR nodes so defining it here--------------------//
						if(child.nodeName=="BR"){
							childHeight = 20;
						}
						else if(child.nodeName=="HR"){
							childHeight = 2;
						}
						//--------------------------------------------------------------------------------------------------------//
						let classList = Array.from(child.classList);
						let isPageBreak = false;
						if(classList.includes("html2pdf__page-break")){
								isPageBreak = true;
								child.style.display = "none"
						}
						if (!currentPage ||   childHeight > (this.pageHeightForContent - currentHeight) || isPageBreak ) {
								if(childHeight > (this.pageHeightForContent - currentHeight)){  // when child height is greater than the current available space of the page
									if(child.localName=='table'){
										let table = document.createElement('table');
										let tbody = document.createElement('tbody');
										table.style.borderCollapse = 'collapse';
										table.appendChild(tbody);
										currentPage.appendChild(table);
										console.log('pages before table break', JSON.parse(JSON.stringify(pages)) );
										let tempPage  = this.createNewPageWithTable();
										let tempTableBody = tempPage.querySelector('tbody');
										let tempContainer = document.getElementById('paginated-editor-temp');
										Array.from(child.children[0].children).forEach(async (row,i)=>{
											let rowHeight = row.offsetHeight // cannot use this row height as getting wrong
											// ------------creating a page with same classes and table and then adding rows to calculate real time cleint height------------------//
											let newRow = row.cloneNode(true)
											tempTableBody.appendChild(newRow);
											tempContainer.appendChild(tempPage)
											let newRowHeight = newRow.offsetHeight
											//------------------------------------------------------------------------------------------------------------------------------//
											rowHeight = newRowHeight; // reassigning accurate client height
											console.log('each row and its height', row,rowHeight);
											if(currentHeight + rowHeight >this.pageHeightForContent){
												currentPage = this.createNewPageWithTable();
												console.log("pages just before pushed",pages)
												pages.push(currentPage)
												tbody = currentPage.querySelector('tbody');
												currentHeight = 0;
											}
											currentHeight = currentHeight + rowHeight
											tbody.appendChild(row)
										})
										tempContainer.removeChild(tempPage)
										return; // to skip current iteration as already current child which is a big table is already taken care.
									}
									else if(child.localName=='ul' || child.localName=='ol'){
										let ulOrOl = document.createElement(child.localName)
										currentPage.appendChild(ulOrOl);
										console.log('inside ulOrOl child: ', child);
										console.log("remaining space before even iterating li",this.pageHeightForContent - (currentHeight) );
										Array.from(child.children).forEach(async (li,i)=>{
											console.log("remaining space before taking the current li is",this.pageHeightForContent - (currentHeight) );
											console.log('li: ', li,li.offsetHeight);
											let liHeight = li.offsetHeight 
											console.log('currentHeight + liHeight: ', currentHeight + liHeight);
											console.log("remaining space after taking the current li is",this.pageHeightForContent - (currentHeight + liHeight) );
											if(currentHeight + liHeight >this.pageHeightForContent){
												currentPage = this.createNewPageWithUL(child.localName);
												console.log("pages just before pushed",pages)
												pages.push(currentPage)
												ulOrOl = currentPage.querySelector(child.localName);
												currentHeight = 0;
											}
											currentHeight = currentHeight + liHeight
											ulOrOl.appendChild(li)
										})
										return; // to skip current iteration as already current child which is a big table is already taken care.
									}
									else{
										pageNo++;
										pageNoAndNodesHeightMApping[pageNo]=[]
										currentPage = document.createElement('div');
										currentPage.className = this.computedPageClass;
										pages.push(currentPage);
										currentHeight = 0;
									}
								}
								else{
									pageNo++;
									pageNoAndNodesHeightMApping[pageNo]=[]
									currentPage = document.createElement('div');
									currentPage.className = this.computedPageClass;;
									pages.push(currentPage);
									currentHeight = 0;
								}
						}
						currentPage.appendChild(child);
						pageNoAndNodesHeightMApping[pageNo].push({
								"child": child,
								"childHeight": childHeight
						})
						if(!marginTop){
							marginTop=0;
						}
						if(!marginBottom){
							marginBottom=0;
						}
						currentHeight += childHeight;
						currentHeight+= Number(marginBottom)
						currentHeight+= Number(marginTop)
						console.log("margin top and bttom is",marginTop,marginBottom);
						console.log("each occupying chld height adding to current height- child height and current occupied height ",childHeight, currentHeight);
				});
				let paginatedArray=[]
				pages.forEach(page => {
						paginatedArray.push(JSON.parse(JSON.stringify(page.innerHTML)))
				});
				this.paginatedContent = paginatedArray
				//--------------- Now as the editor's job is done so destructing the editor so that paginated content cn be rendered ----------------------------//
				this.editor.destruct();
				this.editorMode=false;
		  	setTimeout(this.renderPaginatedContent,1000);
				//---------------------------------------------------------------------------------------------------------------------------------------//
			},
			createNewPageWithTable() {
				const page = document.createElement('div');
				page.className = this.computedPageClass;
				const table = document.createElement('table');
				const tbody = document.createElement('tbody');
				table.appendChild(tbody);
				page.appendChild(table);
				return page;
			},
			createNewPageWithUL(ulOrOl){
				const page = document.createElement('div');
				page.className = this.computedPageClass;
				const ul = document.createElement(ulOrOl);
				page.appendChild(ul);
				return page;
			},
			renderPaginatedContent(){
				let parentContainer = document.getElementById('paginated-editor');
				parentContainer.innerHTML = ''; // Clear existing content
				this.paginatedContent.forEach((pageHTML, index) => {
						let page = document.createElement('div');
						page.className = this.computedPageClass;
						page.innerHTML = pageHTML;
						page.style.paddingLeft = '23px'
						page.style.paddingRight = '23px'
						page.style.paddingTop = '24px'
						page.style.paddingBottom = '0px'
						// need to remove border when open by puppeteer
						if(this.$route.name=='paginatedContentForPuppeteer')
							page.style.border = 'none'
						//---------------------------------------------------//		
						parentContainer.appendChild(page);
				});
				if(!this.isInPreviewMode){
					this.hideChatIcon()
				}
				this.isPaymentSchedulePageVisible = true;
				let parentDiv = document.getElementById('paginated-editor');
				this.isPageLoading = false;
				if(this.isInPreviewMode || this.isInContractListPage){
					this.addMarginWhilePreviewing()
				}
				this.tableMaxWidth()
				parentDiv.classList.add('reportLoaded');
			},
			tableMaxWidth(){
				let myDiv = document.getElementById('parent-container');
				// Get all table elements within that div
				let tables = myDiv.querySelectorAll('table');
				tables.forEach(function(table) {
					table.style.width = '100%';
				});
			},
			addMarginWhilePreviewing(){
				// let pageDivs = document.querySelectorAll('div.page');
				let pageDivs = document.querySelectorAll(`div.${this.computedPageClass}`);
				pageDivs.forEach(function(div) {
					div.classList.add('addMarginBetweenPages');
				});
			},
			replacePlaceholdersByValues(parentDiv) {
				// Get all <span> elements with class name "client-name" within the parent div
				let clientNameSpans = parentDiv.querySelectorAll('span.client-name');
				// Loop through each <span> element and modify it
				clientNameSpans.forEach(span => {
					span.classList.remove('client-name'); // Remove class "client-name"
					let placeholderKey = span.id.split('-')[0]; // having placeholder.id like - "placeholderKey-uuid"
					if(!this.isContractInDesignLevel){
						span.innerText =   this.CMStore.keyValuePlaceholders[placeholderKey]?.default_value +
						" " + (this.CMStore.keyValuePlaceholders[placeholderKey]?.unit || "")
					}
					else{
						if(this.CMStore.addedPlaceholdersKeyValuePair[placeholderKey].category=='docusign'){
							if(this.isInPreviewMode){
								span.innerText =   this.CMStore.addedPlaceholdersKeyValuePair[placeholderKey].name
							}
							else{
								span.innerText =   this.CMStore.addedPlaceholdersKeyValuePair[placeholderKey].default_value
								span.style.color = "white" // so that docusign can override the signature options over these places and it should look clean
							}
						}
						else{
							span.innerText =   this.CMStore.addedPlaceholdersKeyValuePair[placeholderKey]?.value +
							" " + (this.CMStore.addedPlaceholdersKeyValuePair[placeholderKey]?.unit || "")
						}
					}
					span.style.fontWeight = '800';
				});
			}
    }
}
</script>
<style scoped>
#parent-container{
    display: flex; 
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* gap: 20px; */
}
#parent-container ::v-deep ul{
 list-style: inside;
}

#parent-container ::v-deep ul,  #parent-container ::v-deep ol {   
	/* width: 100%; */
	overflow-wrap: normal; 
	/* Wrap only at spaces */
	word-wrap: normal;     
	/* Prevent breaking within words */
	white-space: pre !important;   
	/* Allow text wrapping */
}

#parent-container ::v-deep li
,#parent-container ::v-deep p
{  
	 /* display: block;  */
	 white-space: normal !important;
	 word-wrap: break-word;
	 width: auto !important;
	/* Ensure text inside li, p, wraps at spaces */
}
#parent-container ::v-deep .pageUS{
   	font-size: 16px;
    min-width: 612px;
    height: 792px;
    max-width: 612px;
	padding: 24px 24px 0px 24px !important;
	border: 1px solid #ccc;
}
#parent-container ::v-deep .pageNonUS{
   	font-size: 16px;
    min-width: 595px;
    height: 842px;
    max-width: 595px;
	padding: 24px 24px 0px 24px !important;
	border: 1px solid #ccc;
}
#parent-container ::v-deep .addMarginBetweenPages{
	margin-bottom: 20px;
}
#parent-container ::v-deep .jodit-container:not(.jodit_inline){
	border : none !important;
	margin-top: 20px !important;
}
#parent-container ::v-deep .jodit-container:not(.jodit_inline) .jodit-wysiwyg {
	padding: 24px !important;
	font-size: 16px;
}
#parent-container ::v-deep .jodit-toolbar__box:not(:empty) {
	padding: 0px 24px !important;
}

#parent-container ::v-deep .jodit-context table, #parent-container ::v-deep .jodit-wysiwyg table{
    word-break: break-all;
}

#parent-container ::v-deep table{
	word-break: break-all;
}
.preview {
	word-break: break-word;
}
</style>
