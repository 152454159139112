
<div class="editActivity">
  <GlobalDrawer
    :isOpen="showCreateDrawer"
    :title="''"
    :handleCloseDialog="closeCreateDrawer"
    :drawerSize="700"
  >
    <template #header>
      <div class="title">
          <span class="cross" @click="onClose()" style="font-weight: bolder; padding: 0.75rem 0.5rem 20px;">&#x2716;</span>
      </div>
    </template>
    <template #body>
      <div class="wholeBodyEdit">
        <div class="callerClass">
        <div class="optionsDrop">
          <el-select v-model="computedSelectedValue" placeholder="Select" :disabled="!editMode">
            <template v-slot:prefix>
              <i :class="getSelectedIconClass" class="iconClass"></i>
            </template>
            <el-option
              v-for="item in activityOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
              <i :class="item.icon"></i> {{ item.label }}
            </el-option>
          </el-select>
      </div>
      <div class="lilText">
        <span>
          {{getSelectedText}}
        </span>
        <span class="nameTo">
          <abbr :title="leadInfo?.lead_details?.name || leadInfo?.name" class="abbrTag">
            {{ leadInfo?.lead_details?.name || leadInfo?.name }}
          </abbr>
        </span> {{computedSelectedValue=="general" ? 'made a note' : ''}}
      </div>
      <div class="dateAndTime">
        <!-- <timePicker/>  -->
        <span style="color: #777;">
          at
        </span>
        <div class="timePicker">
          <!-- <el-time-picker
            :disabled="!editMode"
            v-model="pickedTime"
            :clearable="false"
            value-format="HH:mm:ss"
            format="HH:mm"
            placeholder="Arbitrary time">
          </el-time-picker> -->
          <input
            class="inputTime"
            type="time"
            :class="!editMode ? 'disableTimePicker':''"
            v-validate="'required'"
            name="time"
            v-model="pickedTime"
            :disabled="!editMode"
            placeholder="Select a time"
          />
        </div>
        <span style="color:#777;">
          on
        </span>
        <!-- <datePicker/> -->
        <el-date-picker
          :disabled="!editMode"   
          v-model="pickedDate"
          type="date"
          :clearable="false"
          :format="computedDateFormat"
          value-format="yyyy-MM-dd"
          placeholder="Select a day">
        </el-date-picker>
      </div>
    </div>
        <!-- <div class="descriptionClass"><el-input type="textarea" :rows="4" v-model="editedDesc" placeholder="Add your description"></el-input></div> -->
        <div class="card">
              <div v-if="!editMode" class="about_content">
                <div class="floating-label">
                  <p class="descriptionText">
                    {{ editedDesc }}
                  </p>
                </div>
              </div>
          <div v-if="editMode" class="about_content">
            <div class="floating-label">
              <textarea
                id="autoresizing"
                role="textbox"
                class="floating-input floating-textarea"
                name="aboutUs"
                placeholder=" "
                @keydown="autoResizeAboutUs();"
                v-model="editedDesc"
              >
              </textarea>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #pinned-footer>
      <div class="bottomCard">
              <div class="outerBtn">
                  <el-button type="primary" class="submitBtn" @click="toggleEditMode(); delayAutoResizeAboutUs();"  v-if="!editMode">Edit</el-button>
              </div>
              <div class="outerBtn">
                  <el-button type="primary" class="submitBtn" @click="toggleEditMode(); submitDetails();" v-if="editMode">Save</el-button>
              </div>
      </div>
    </template>
  </GlobalDrawer>
</div>
