
<section>
  <div class="container" id="scroll-bar" @click="selectCard()" >
    <div class="flex justify-center">
      <div v-loading="showKanbanLoading">
        <div class="min-h-screen flex overflow-x-scroll overflow-y-scroll">
          <div
            v-for="column in dataTable"
            :key="column.title"
            class="bg-gray-100 rounded-lg px-3 column-width rounded mr-4"
          >
            <div v-if="column.title !== 'addSection'">
              <div v-if="page == 'Leads'&&isSOMUsers()" class="header-container">
                <div style="display: flex;justify-content: space-between;">
                <el-tooltip
                  :content="column.title"
                  effect="dark"
                  placement="top"
                >
                  <p
                    style="
                      width: 100%;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                    class="header-label"
                  >
                    <!-- &#9679; {{ getHeaderTitle(column.title) }} -->
                    &#9679; {{ column.title }}
                  </p>
                </el-tooltip>
                <b-icon  v-if="page == 'Leads'"
                  style="margin-right: 5px"
                  :color="iconColor(column.title)"
                  :icon="iconDisplay(column.title)"
                ></b-icon>
                <p class="font-semibold font-sans tracking-wide text-sm">
                  {{
                    calculatingK(column?.count) ||
                    calculatingK(column.tasks.length)
                  }}
                  <!-- {{ calculateTasks(column?.count) }} -->
                </p>
               
              </div>
              <el-tooltip
                  content="Sum of Deal Value"
                  effect="dark"
                  placement="top-start"
                >
              <div style="display: flex;width: 100%;flex-direction: row;padding-left: 10px;">
                {{getAbrivatedDealvalue(column?.sumOfDealvalues||0)}}        
                </div>
              </el-tooltip>
              </div>
            

                <div class="Header" v-else>
                <el-tooltip
                  :content="column.title"
                  effect="dark"
                  placement="top"
                >
                  <p
                    style="
                      width: 100%;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                    class="header-label"
                  >
                    <!-- &#9679; {{ getHeaderTitle(column.title) }} -->
                    &#9679; {{ column.title }}
                  </p>
                </el-tooltip>
                <b-icon  v-if="page == 'Leads'"
                  style="margin-right: 5px"
                  :color="iconColor(column.title)"
                  :icon="iconDisplay(column.title)"
                ></b-icon>
                <p class="font-semibold font-sans tracking-wide text-sm">
                  {{
                    calculatingK(column?.count) ||
                    calculatingK(column.tasks.length)
                  }}
                  <!-- {{ calculateTasks(column?.count) }} -->
                </p>
              </div>
            
          

              <!-- Draggable component comes from vuedraggable. It provides drag & drop functionality -->
              <div
                class="crad-columns"
                id="kanban-scroll"
                :style="{
                  minHeight: `calc(100vh - ${offSetHeight}) !important`,
                  maxHeight: `calc(100vh - ${offSetHeight}) !important`,
                  overflowY: 'scroll',
                  paddingBottom: '20px',
                }"
                @scroll="handleScroll($event, column)"
                v-loading="column.loading"
              >
                <!-- Non Dragable Kanban board -->
                <div
                  v-if="
                    selectedColumnType === 'delivery_type' || page === 'Task'
                  "
                >
                  <div
                    v-for="(task, index) in column.tasks"
                    :key="task.id"
                    @click.native.stop="selectCard(task.id, task)"
                    :class="{
                      'card-selected': task.id === selectedCardId,
                    }"
                    ref="columnRef"
                  >
                    <task-card
                      :task.sync="column.tasks[index]"
                      @select-card="handleSelectCard"
                      :buttonsData="buttonsData"
                      :page="page"
                      @component-name="handleReminder"
                      @name-updated="editableName"
                      @handle-date="handleDate"
                      @handleComplete="handleComplete"
                      @handlePriority="handlePriority"
                      @leadDetails="leadDetails"
                      :taskIndex="index"
                      :selectedColumnType="column.columnType"
                      :selected="task.id === selectedCardId ? '' : ''"
                      @nudgeClicked="handleNudgeClick"
                    ></task-card>
                  </div>
                  <div
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    "
                    v-if="
                      showKanbanLoader && activeKanbanColumn === column.stage
                    "
                  >
                    <i class="el-icon-loading"></i>
                  </div>
                </div>
                <!-- <Divscroll /> -->
                <div v-else>
                  <draggable
                    :list="column.tasks"
                    :animation="200"
                    v-if="isDraggable(column)"
                    @start="onDragStart($event, column)"
                    delay="500"
                    delayOnTouchOnly="true"
                    class="ghost-card"
                    group="tasks"
                    @change="handleUpdate($event, column)"
                  >
                    <!-- Each element from here will be draggable and animated. Note :key is very important here to be unique both for draggable and animations to be smooth & consistent. -->
                    <!-- <div v-if="column.tasks.length > 0"> -->
                    <div
                      v-for="(task, index) in column.tasks"
                      :key="task.id"
                      @click.native.stop="selectCard(task.id, task)"
                      :class="{
                        'card-selected': task.id === selectedCardId,
                      }"
                      ref="columnRef"
                    >
                      <task-card
                        :task.sync="column.tasks[index]"
                        @select-card="handleSelectCard"
                        :buttonsData="buttonsData"
                        :page="page"
                        @component-name="handleReminder"
                        :selectedColumnType="column.columnType"
                        :taskIndex="index"
                        :selected="task.id === selectedCardId ? '' : ''"
                        @nudgeClicked="handleNudgeClick"
                      ></task-card>
                    </div>

                    <div
                      v-if="column.tasks.length < 3"
                      class="default-drop-zone"
                      @dragenter="handleDragEnter(column)"
                      @dragleave="handleDragLeave"
                      @drop="handleDrop"
                      @dragover.prevent
                      :style="{
                        minHeight: `calc(100vh - ${
                          14 * column.tasks.length
                        }rem - ${offSetHeight}) !important`,
                        maxHeight: `calc(100vh - ${
                          14 * column.tasks.length
                        }rem - ${offSetHeight}) !important`,
                        overflowY: 'scroll',
                      }"
                    ></div>

                    <!-- <task-card
                      v-for="task in tableDetails"
                      :key="task.id"
                      :task="task"
                      @select-card="handleSelectCard"
                    ></task-card> -->

                    <!-- </div> -->
                    <!-- </transition-group> -->
                  </draggable>
                  <!-- Non-draggable columns -->
                  <div v-else>
                    <div
                      v-for="(task, index) in column.tasks"
                      :key="task.id"
                      @click.native.stop="selectCard(task.id, task)"
                      :class="{
                        'card-selected': task.id === selectedCardId,
                      }"
                      ref="columnRef"
                    >
                      <task-card
                        :task.sync="column.tasks[index]"
                        @select-card="handleSelectCard"
                        :buttonsData="buttonsData"
                        :page="page"
                        @component-name="handleReminder"
                        :selectedColumnType="column.columnType"
                        :taskIndex="index"
                        :selected="
                          task.id === selectedCardId ? '2px solid black' : ''
                        "
                      ></task-card>
                    </div>

                    <!-- Default drop zone -->
                    <div
                      v-if="column.tasks.length < 3"
                      class="default-drop-zone"
                      @dragenter="handleDragEnter(column)"
                      @dragleave="handleDragLeave"
                      @drop="handleDrop"
                      @dragover.prevent
                      :style="{
                        minHeight: `calc(100vh - ${
                          14 * column.tasks.length
                        }rem - ${offSetHeight}) !important`,
                        maxHeight: `calc(100vh - ${
                          14 * column.tasks.length
                        }rem - ${offSetHeight}) !important`,
                        overflowY: 'scroll',
                      }"
                    ></div>
                  </div>
                </div>

                <!-- <div
                  class="create_btn"
                  @click="handleCreated(column)"
                  v-if="page == 'Leads'"
                  :style="{ marginTop: column.tasks.length > 0 ? 0 : '1rem' }"
                >
                  + Create New
                </div> -->
              </div>
            </div>
            <!-- <div v-else>
              <div
                class="create_section_btn"
                @click="handleCreateNew(column)"
              >
                + Create Section
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
