<template>
    <div>
        <div class="parentContainer">
            <el-dialog :visible="isConfirmationForDocusignPopupVisible" :close-on-click-modal="false" title="Send documents"
                @close="onDialogClose" :modal-append-to-body="isModal">
                <div class="container">
                    <div class="flexContainer">
                        <div class="flexContainerTwo">
                            <p class="font16Normal">Document for contract management</p>
                            <p class="font16Normal tag">DocuSign enabled</p>
                        </div>
                        <el-select v-model="selectedOption" placeholder="Select">
                            <el-option v-for="item in options" :key="item" :label="item"
                                :value="item">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="btnContainer">
                        <el-checkbox v-model="checked">I confirm that I have verified all project related information in
                            the contract. Arka360 is not liable for any discrepancy of data in the
                            contract.</el-checkbox>
                        <el-button type="primary" class="proceedBtn" @click="proceedBtnFunc()" :disabled="!checked">Proceed</el-button>
                    </div>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>


export default {
    emits: ["update:isConfirmationForDocusignPopupVisible", "proceedBtnFunc"],
    name: "confirmationForDocusignPopup",
    props: {
        isConfirmationForDocusignPopupVisible: {
            type: Boolean,
            default: false,
        },
        isModal:{
            type:Boolean,
            default:true,
        }
    },
    data() {
        return {
            options:["Docusign", "Arka 360"],
            checked:false,
            selectedOption: "Docusign"
        };
    },
    computed: {},
    methods: {
        onDialogClose() {
            this.$emit("update:isConfirmationForDocusignPopupVisible", false);
        },

        proceedBtnFunc() {
            this.$emit("proceedBtnFunc", {
                selectedOption: this.selectedOption
            });
            this.onDialogClose();
        }
    }
};
</script>

<style scoped>
.parentContainer>>>.el-dialog__header {
    /* background-color: #1c3366; */
    background: #fff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0 !important;
    height: 64px !important;
    padding: 24px !important;
    border-bottom: 1px solid #ccc;
}

.parentContainer>>>.el-dialog__title {
    font-family: "Helvetica Neue";
    font-size: 16px;
    font-weight: 600;
    line-height: 2.38;
    text-align: left;
    color: #222 !important;
}

.parentContainer>>>.el-dialog__close {
    color: #222222 !important;
    font-weight: 800 !important;
    font-size: 24px !important;
}

.parentContainer>>>.el-dialog__headerbtn:focus .el-dialog__close,
.parentContainer>>>.el-dialog__headerbtn:hover .el-dialog__close {
    color: #222 !important;
}

.parentContainer>>>.el-dialog {
    border-radius: 12px !important;
    height: auto;
    overflow-y: hidden;
    margin-top: 24vh !important;
    max-width: 770px;
    width: 90%;
    z-index:2000;
}

.parentContainer>>>.el-dialog__body {
    padding: 0px !important;
}

.flexContainer {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    padding: 24px;
}

.flexContainerTwo {
    display: flex;
    align-items: center;
    gap: 8px;
}

.font16Normal {
    font-size: 16px;
    color: #222;
    word-break: break-word;
}

.parentContainer>>>.el-select {
    width: 200px;
}

.parentContainer>>>.el-input__inner {
    height: 48px;
    font-size: 16px;
    color: #222;
    border-radius: 4px;
    border: 1px solid var(--Grey-Scale-999, #999);
    background: var(--Grey-Scale-White, #FFF);
}

.parentContainer>>>.el-input__inner::placeholder,
.parentContainer>>>.el-textarea__inner::placeholder {
    color: #777;
    font-size: 16px;
}

.parentContainer>>>.el-select .el-input .el-select__caret {
    font-size: 18px;
    font-weight: bold;
    color: #222;
}

.tag {
    white-space: nowrap;
    height: 30px;
    width: fit-content;
    padding: 4px 12px;
    border-radius: 50px;
    background-color: #ffc23e;
    margin-top: -12px;
}

.btnContainer {
    display: flex;
    gap: 16px;
    justify-content: space-between;
    padding: 16px 24px;
    border-top: 1px solid #ccc;
}

.parentContainer>>>.el-checkbox {
    display: flex;
    align-items: flex-start;
}

.parentContainer>>>.el-checkbox__label,
.parentContainer>>>.el-checkbox__input.is-checked+.el-checkbox__label {
    color: #222;
    font-size: 16px;
    font-weight: normal;
    white-space: normal;
    word-break: break-word;
}

.proceedBtn {
    height: 40px;
    font-size: 16px;
}

@media (max-width: 740px) {

    .parentContainer>>>.el-dialog {
        margin-top: 24vh !important;
    }

    .parentContainer>>>.el-dialog__header,
    .btnContainer {
        padding: 16px !important;
    }

    .flexContainer {
        padding: 24px 16px;
        flex-direction: column;
    }

    .parentContainer>>>.el-select {
        width: 100%;
    }
}
</style>