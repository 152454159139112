<!-- create a component called taskActivityTimeline which implements el-timeline from element ui -->
<template>
    <div class="block">
        <div class="timeline-section">
            <el-timeline>
                <el-timeline-item v-for="(activity, index) in activities" :key="index" :icon="activity.icon"
                    :type="'primary'" :size="activity.size" :hide-timestamp="true">
                    <el-row style="min-height: 80px">
                        <el-col :xs="18" :sm="22" :md="21" :lg="21">
                            <div class="dateBlock" :class="window.width < minWidth ? 'dateBlockMedia' : ''">
                                {{ getDateAndTime(activity.created_at).date }}
                            </div>
                            <div>
                                <p class="title">{{ activity.name }}</p>
                            </div>
                            <div v-if="activity.description">
                                <p class="description">{{ activity.description }}</p>
                            </div>

                            <div v-for="(doc, index) in filteredDocuments(activity)"
                                :key="index" class="docs"   :class="window.width<minWidth?'docsMedia':''">
                                <!-- col divide 6 to 18 on all screense -->
                                <p class="docText">Documents:</p>
                                <div class="allDocs">
                                    <div class="specificDoc"
                                        v-loading="doc.id == fileIdToBeDeleted && isDeleteInProgress">
                                        <p class="docName">{{ getTruncatedFileName(doc.display_name, 14) }}</p>
                                        <div class="actionIcons">
                                            <img src="../../../leadManagement/components/assets/Download.svg"
                                                class="downloadIcon" @click="downloadClick(doc)" />
                                        </div>
                                    </div>
                                </div>


                            </div>


                        </el-col>
                        <el-col :xs="6" :sm="2" :md="3" :lg="3" style="text-align: right">
                            <p class="time_text">
                                {{ getDateAndTime(activity.created_at).time }}
                            </p>
                        </el-col>
                    </el-row>
                </el-timeline-item>
            </el-timeline>
        </div>

        <!-- add note section with a text area and a limit of 200 char -->
        <el-row>
            <el-col :span="24">
                <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 4 }" :rows="4" placeholder="Add a note"
                    v-model="note" maxlength="200" show-word-limit></el-input>
            </el-col>
            <!-- update button right aligned -->
            <el-col :span="24" style="text-align: right; margin-top: 10px">
                <el-button type="primary" size="small" :disabled="note.length === 0" @click="addToActivityLog">Update
                    <!-- add ic -->
                </el-button>

            </el-col>
        </el-row>

    </div>
</template>

<script>
import API from "@/services/api/";

export default {
    name: 'taskActivityTimeline',
    data() {
        return {
            note: '',
            activities: [],
            window: {
                width: 0,
                height: 0,
            },
        };
    },
    created() {
        if (this.orderData)
            this.getTimelineData(this.orderData.id)

    },
    props: {
        orderData: {
            type: Object,
        },
    },
    methods: {
        filteredDocuments(activity) {
            return activity?.documents.length > 0 ? activity.documents : []
        },
        getTruncatedFileName(fileName, length) {
            if (fileName.length > length) {
                return "..." + fileName.substring(fileName.length - length);
            } else {
                return fileName;
            }
        },
        async downloadClick(file) {
            let fileID = file.id;
            let arrayIds = [];
            arrayIds.push(fileID);
            let postData = {
                document_ids: arrayIds,
            };
            console.log('postData', postData);
            let response = await API.DOCUMENT_INFO.DOWNLOAD_DOCUMENTS(postData);
            let url = response.data[0];
            saveAs(url, file.display_name);
        },
        getDateAndTime(val) {
            const isoString = val;
            const parsedDate = new Date(isoString);
            const day = parsedDate.getDate();
            const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            const month = monthNames[parsedDate.getMonth()];
            return {
                "date": `${day} ${month}`,
                "time": parsedDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
            }
        },
        async getTimelineData(id) {
            // fetch data from api
            try {
                let resp = await API.DESIGN_ORDERS.FETCH_DESIGN_ORDER_ACTIVITY_LOGS(id)
                let reversedArray = [...resp.data].reverse();

                this.activities = reversedArray.map((activity) => {
                    return {
                        ...activity,
                        icon: 'el-icon-more',
                    }
                })

            } catch (e) {
                console.log(e)
            }
        },
        async addToActivityLog() {
            // add to activity log
            try {
                let resp = await API.DESIGN_ORDERS.ADD_TO_DESIGN_ORDER_ACTIVITY_LOGS({
                    name: 'General notes',
                    description: this.note,
                    order: this.orderData.id,
                    activity_type: 'general'
                })
                if (resp.status === 201) {
                    this.getTimelineData(this.orderData.id)
                    this.note = ''
                }

            } catch (e) {
                console.log(e)
            }
        }
    },
};
</script>

<style scoped>
.block {
    margin: 10px;
}

.block>>>.el-timeline-item__node {
    width: 23px;
    height: 23px;
}

.block>>>.el-timeline {
    padding: 0 8px;
}

.block>>>.el-timeline-item__tail {
    position: absolute;
    left: 9px;
    height: 100%;
    border-left: 2px solid #E4E7ED;
}

.block>>>.el-timeline-item {
    padding: 8px;
}

.timeline-section {
    /* min-height: 20vh; */
    max-height: 40vh;
    overflow: scroll;
}

.dateBlock {
    width: 60px;
    height: 24px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 4px 8px;
    border-radius: 4px;
    border: solid 1px #ccc;
    font-size: 11px;
    color: #409eff;
}

.dateBlock.dateBlockMedia {
    grid-row: 1;
}

.time_text {
    font-size: 12px;
    color: #777;
    font-weight: 400;
}

.title {
    font-size: 16px;
    color: #000;
    font-weight: 600;
    margin-top: 10px;
}

.description {
    font-size: 14px;
    color: #777;
    font-weight: 400;
    margin-top: 5px;
    margin-bottom: 5px;
}
.specificDoc{
  width: 195px;
  height: 45px;
  flex-grow: 0;
  align-items: center;
  margin: 0 8px 0 0;
  /* padding: 0 0 12px 12px; */
  padding-left: 12px;
  border-radius: 4px;
  display: flex;
  background-color: #e8edf2;
  justify-content: space-between;
}
.allDocs{
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.docText{
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #222;
}
.downloadIcon{
  height: 50%;
  cursor: pointer;
  margin-top: 10px;
}

.actionIcons{
  display: flex;
  flex-basis: 35%;
  height: -webkit-fill-available;
  justify-content: flex-end;
  gap: 12px;
}
.docName{
  flex-basis: 65%;
  /* font-weight: 900; */
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #222;
}
.docs{
  display: flex;
  align-items: baseline;
  gap: 12px;
  margin-top: 2px;
}

.docs.docsMedia{
  padding-left: 0;
}
.docsMedia{
    display: flex;
    flex-direction: column;
  }
</style>