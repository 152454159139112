
<span :class="spanWidth">
    <label>
        <input
            v-validate="inputValidation"
            v-model="displayValue.text"
            :type="inputType"
            :name="name"
            :disabled="disabled"
            :placeholder="holder"
            :class="classInput"
            :data-vv-scope="errorScope !== null ? errorScope : null"
            autocomplete="off"
            @keyup.enter="enter"
            @input="onDisplayValueUpdate">
    </label>
</span>
