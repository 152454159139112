
<div id="designOrders">
  <el-dialog
    :visible="rejectState"
    :close-on-click-modal="false"
    @close="oncloseDialog"
    :show-close="true"
    :title="'Confirm Order Rejection'"
    width="60%"
  >
    <div>
      <el-row>
        <el-col :span="24">
          <span>
            {{ `Please confirm that you want to reject the order ` }}</span
          >
          <span
            ><b>{{ orderData.id }}</b>
          </span>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <span> {{ `The reason you have stated is ` }}</span
          ><span
            ><b>{{ input ? input : "" }}</b></span
          >
        </el-col>
      </el-row>
    </div>
    <template v-slot:footer>
    <span class="dialog-footer">
      <el-button @click="oncloseDialog">Cancel</el-button>
      <el-button type="primary" @click="handleconfirm">Confirm</el-button>
    </span>
    </template>
  </el-dialog>
</div>
