<template>
  <div>
    <ListTableView
      :tableData="tableData"
      :tableColumns="computedHeading"
      :getCellStyles="getCellStyles"
      :getHeaderStyles="getHeaderStyles"
      :paddingLeft="computedPadding"
      :weight="600"
      :size="'16px'"
      :color="'#1C3366'"
      @updateValues="updateValues"
      @updateStatus="updateStatus"
      @updatePriority="updatePriority"
      @updateDateValues="updateDateValues"
      :loadMore="loadMoreData"
      @loadLead="loadLead"
      @nudgeClicked="handleNudgeClick"
      :tableHeightOffset="'16rem'"
      :handleRowClick="onRowClick"
      :cellHeight="'60px'"
      :showHeaderSortable="true"
      :taskCount="taskCount"
      :isDrilldown="isDrilldown"
    />
    <div
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        border-radius: 5px;
      "
      v-if="showTableLoader"
    >
      <i class="el-icon-loading"></i>
    </div>
  </div>
</template>

<script>
import debounce from "debounce";
import ListTableView from "../../../pages/designOrders/components/ListViewTable.vue";
export default {

  emits: [
    "update",               
    "updateStatus",         
    "updatePriority",       
    "updateDate",           
    "handleRowClick",       
    "loadMoreLeadName", 
    "loadMoreTableData",
    "leadDetails"    
  ],

  components: {
    ListTableView,
  },

  props: {
    tableData: {
      type: Array,
      default() {
        return []
      }
    },
    isDrilldown: {
      type: Boolean,
    },
    taskCount: {
      type: Number,
    },
    showTableLoader: {
      type: Boolean,
      default: false,
    },
    checkedTableColumns: {
      type: Array,
      default() {
        return []
      }
    },
    checkedTableViewColumn: {
      type: Array,
      default() {
        return []
      }
    },
  },

  methods: {
    getCellStyles(row, column) {
      if (column.id === "homeOwner") {
        return {
          color: "#409eff",
          fontWeight: 400,
          fontSize: "14px",
        };
      }

      if (column.id === "dueDate" && row.status === "Completed") {
        return {
          color: "#999999",
        };
      }

      if (column.id === "priority" && row.status === "Completed") {
        return {
          color: "#999999",
        };
      }
      if (column.id === "lead_stage" && row.status === "Completed") {
        return {
          color: "#999999",
        };
      }
      if (column.id === "location" && row.status === "Completed") {
        return {
          color: "#999999",
        };
      }
      if (column.id === "location") {
        return {
          fontWeight: 400,
          fontSize: "14px",
        };
      }
    },

    getHeaderStyles() {
      const left = "left";
      return left;
    },

    updateValues: debounce(function (values) {
      this.$emit("update", values);
    }, 500),

    updateStatus(values) {
      this.$emit("updateStatus", values);
    },
    updatePriority(values) {
      this.$emit("updatePriority", values);
    },

    updateDateValues(values) {
      this.$emit("updateDate", values);
    },

    loadMoreData() {
      this.$emit("loadMoreTableData");
    },

    loadLead(id) {
      this.$emit("leadDetails", id);
    },

    handleNudgeClick(task) {
      let count = 60;
      if (task) task.nudgeTooltipText = `Available in ${count} second(s)`;

      const intervals = setInterval(() => {
        count -= 1;
        if (task) task.nudgeTooltipText = `Available in ${count} second(s)`;
      }, 1000);

      setTimeout(() => {
        clearInterval(intervals);
        task.nudgeTooltipText = "Nudge";
      }, 60000);
    },

    onRowClick(value) {
      this.$emit("handleRowClick", value);
    },
  },

  computed: {
    computedHeading() {
      if (this.isDrilldown) {
        return this.$props.checkedTableColumns;
      } else {
        return this.$props.checkedTableViewColumn;
      }
    },

    computedPadding() {
      if (this.isDrilldown) {
        return "0px";
      } else {
        return "5px";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
