import * as THREE from "three";
import * as JSTS from "jsts";
import BaseObject from "../../BaseObject";
import { SmartroofModel } from "../smartroof/SmartroofModel";
import * as JSTSConverter from "../../../utils/JSTSConverter";
import { CREATED_STATE, DELETED_STATE, PV_TILE_MOUNTING_METHODS } from "../../../coreConstants";
import { pointInPolygon } from "../../../utils/utils";
import { QuadTree, Point, Box } from "js-quadtree";
import { useTilesStore } from "../../../../stores/tilesStore";
// import arkaLogo from "../../../../assets/textures/arka_logo.jpg";
import bareDecktexture from "../../../../assets/textures/bareDeck.jpg";
import TilesGrid from "./TilesGrid";
import PowerRoofInverter from "./PowerRoofInverter";
import PowerRoofCombinerBox from "../../ac/PowerRoofCombinerBox";
import {
    PathNode,
    aStarMustVisit,
    connectPathNodes,
    mergePathNodes,
    printPath,
} from "./PathFinding";
import { createMesh } from "../../../utils/meshUtils";
import { mergedAC, mergedHomeRun } from "./annealingUtils";
import SmartroofFace from "../smartroof/SmartroofFace";
import { COLOR_MAPPINGS, VISUAL_STATES } from "../../visualConstants";
import * as notificationAssistant from "../../../../componentManager/notificationsAssistant";

export default class PowerRoof extends BaseObject {
    /**
     *
     * @param {*} stage
     * @param {SmartroofModel[]} smartRoofs
     */
    constructor(stage, smartRoofs = []) {
        super(stage);

        this.stage = stage;
        this.objectsGroup = new THREE.Group();
        this.objectsGroup.container = this;
        this.stage.sceneManager.scene.add(this.objectsGroup);
        this.cablesGroup = new THREE.Group();
        this.cablesGroup.visible = false;
        this.objectsGroup.add(this.cablesGroup);
        this.conduitGroup = new THREE.Group();
        this.conduitGroup.visible = false;
        this.objectsGroup.add(this.conduitGroup);
        this.outlineMesh = new THREE.Line();
        this.outlineMesh.visible = false;
        this.objectsGroup.add(this.outlineMesh);
        this.outlineMesh.material = new THREE.LineBasicMaterial({
            color: 0xffffff,
        });
        this.tilesEnabled = true;
        this.invertersEnabled = false;
        this.dcComputed = false;
        this.dcEnabled = false;
        this.acComputed = false;
        this.cappingsComputed = false;
        this.acEnabled = false;
        this.mergeEnabled = false;
        this.conduitComputed = false;
        this.conduitEnabled = false;
        this.acStrings = [];
        this.mergedStrings = null;
        this.mergedNodes = null;
        this.mechanicalLayoutEnabled = false;
        this.flashingEnabled = false;
        this.totalMicroInverters = 0;
        this.totalCableLength = 0;
        this.dcCapacity = 0;
        this.smartRoofs = {};
        this.cappingCount = {
            ridge: 0,
            hip: 0,
            valley: 0,
        };
        this.isTiled = false;
        this.combinerPlaced = false;
        this.homeRunType = "Exterior through Conduit";
        this.conduitType = "PVC";
        this.gridProperties = this.getDefaultGridValues();
        this.inverter = new PowerRoofInverter(this.stage, this);
        smartRoofs.forEach((roof) => this.addRoof(roof));
        this.updateMergedNodes();
        this.connectedCombinerBox = null;
        this.pathNodesMap = new Map();
        this.outlinePoints = this.getOutline();
        this.outlineMesh.geometry = new THREE.BufferGeometry().setFromPoints(
            this.outlinePoints
        );
        this.addGrid();
        this.showBareDeck();

        // Make an outline using the intersectionData

        // Make a layer for placment using the intersectionData

        // TODO: There could be a better reference.
        this.isSelected = false;

        this.stage.stateManager.add({
            uuid: this.uuid,
            getStateCb: () => CREATED_STATE,
            withoutContainer: true,
        });

        useTilesStore().isTilesSolarIrradianceFetched = false;
        useTilesStore().isPowerRoofPresentInScene = true;

        // TODO: Set this to false again when powerRoof undergoes changes.
        this.isSolarAccessUpdated = false;
    }

    getDefaultGridValues() {
        // const selectedPVTile = useTilesStore().pvTile;
        const designSettings = this.stage.getDesignSettings();
        const mountingMethod =
            designSettings.drawing_defaults.pvTile.mountingMethod;
        const selectedPVTile = useTilesStore().pvTile;
        const overlap = 0.04;
        const gridProperties = {
            id: selectedPVTile.id,
            length: selectedPVTile.characteristics.length,
            width: selectedPVTile.characteristics.width,
            power: selectedPVTile.characteristics.p_mp_ref,
            manufacturer: selectedPVTile.characteristics.manufacturer,
            model: selectedPVTile.characteristics.model,
            height: 0.025,
            overlap,
            sideSpacing: 0.008,
            relativeTilt: 3,
            eaveOffset: 0.1,
            columnOffset: 0,
            partialTileFractions: [0.626 / 1.26, 0.626 / (2*1.26)],
            terminalXOffsetFraction: 0.4,
            terminalYOffsetFraction: 0.25,
            pigTailBuffer: 0.01,
            mountingMethod: mountingMethod,
            pvTileID: selectedPVTile.id,
            mountingMethod: PV_TILE_MOUNTING_METHODS.ARKA_BLOCK_FOOTING,
        };
        return gridProperties;
    }

    getGridProperties() {
        return this.gridProperties;
    }

    getTileProperties() {
        return  useTilesStore().pvTile;
    }

    getPVTileType() {
        return {
            id: this.gridProperties.pvTileID,
            model: this.gridProperties.model,
            manufacturer: this.gridProperties.manufacturer,
        };
    }

    getMountingMethod() {
        return this.gridProperties.mountingMethod;
    }

    getRelativeTilt() {
        return this.gridProperties.relativeTilt;
    }

    getRowOverlapping() {
        return this.gridProperties.overlap;
    }

    getGrids() {
        const grids = [];
        Object.keys(this.smartRoofs).forEach((key) => {
            this.smartRoofs[key].children.forEach((child) => {
                if (child.isValidFace()) {
                    grids.push(child.tilesGrid);
                }
            });
        });
        return grids;
    }

    isMechanicalLayoutEnabled() {
        return this.mechanicalLayoutEnabled;
    }

    isFlashingEnabled() {
        return this.flashingEnabled;
    }

    /**
     * Updates the heatmap values for the PowerRoof object.
     * @returns {void}
     */
    updateHeatmapValues() {
        useTilesStore().isTilesSolarIrradianceFetched = false;
        try {
            this.heatmapPromise =
                this.stage.heatMap.getLiveHeatMapValues(false);
            this.heatmapPromise.then((data) => {
                const heatMapValues = data.heatmap_values;
                const groundPixelSize = data.heatmap_output_size;
                this.setFaceHeatmapValues(heatMapValues, groundPixelSize);
                useTilesStore().isTilesSolarIrradianceFetched = true;
            });
        } catch (e) {
            useTilesStore().isTilesSolarIrradianceFetched = false;
            console.error("Error in getting heatmap values: ", e);
        }
    }

    /**
     * Sets the heatmap values for the faces of the PowerRoof object.
     *
     * @param {Array<Array<number>>} heatMapValues - The heatmap values in pixel coordinates.
     */
    setFaceHeatmapValues(heatMapValues, groundPixelSize) {
        const groundPlaneGeometry = this.stage.ground.plane.geometry;
        if (!groundPlaneGeometry.boundingBox) {
            groundPlaneGeometry.computeBoundingBox();
        }
        const baseWidth =
            groundPlaneGeometry.boundingBox.max.x -
            groundPlaneGeometry.boundingBox.min.x;
        const baseHeight =
            groundPlaneGeometry.boundingBox.max.y -
            groundPlaneGeometry.boundingBox.min.y;

        const pixelToGround = (pixelX, pixelY) => {
            const groundX =
                (pixelY / groundPixelSize) * baseHeight - baseHeight / 2;
            const groundY =
                (-pixelX / groundPixelSize) * baseWidth + baseWidth / 2;
            return [groundX, groundY];
        };

        const heatMapGroundValues = heatMapValues.map((value) => {
            const [x, y] = pixelToGround(value[0], value[1]);
            return [x, y, value[2]];
        });

        const quadtree = new QuadTree(
            new Box(-baseWidth / 2, -baseHeight / 2, baseWidth, baseHeight)
        );
        heatMapGroundValues.forEach((value) => {
            const point = new Point(value[0], value[1], value[2]);
            quadtree.insert(point);
        });

        for (const smartRoof of Object.values(this.smartRoofs)) {
            for (const child of smartRoof.children) {
                if (child.isValidFace()) {
                    const { averageHeatMapValue, effectiveSolarArea } =
                        this.findFaceAverageHeatmap(child, quadtree);
                    child.averageHeatMapValue = averageHeatMapValue;
                    child.effectiveSolarArea = effectiveSolarArea;
                }
            }
        }
    }

    /**
     * Calculates the average heatmap value and effective solar area for a given face.
     *
     * @param {Face} face - The face for which to calculate the average heatmap value and effective solar area.
     * @param {Quadtree} quadtree - The quadtree containing the potential pixels.
     * @returns {Object} - An object containing the average heatmap value and effective solar area.
     */
    findFaceAverageHeatmap(face, quadtree) {
        let sum = 0;
        let count = 0;

        const faceBoundingBox = new THREE.Box2().setFromPoints(
            face.getVector2Vertices()
        );
        const queryBox = new Box(
            faceBoundingBox.min.x,
            faceBoundingBox.min.y,
            faceBoundingBox.max.x - faceBoundingBox.min.x,
            faceBoundingBox.max.y - faceBoundingBox.min.y
        );
        const potentialPixels = quadtree.query(queryBox);

        potentialPixels.forEach((value) => {
            if (
                face.setbackVertices.some((polygon) =>
                    pointInPolygon(
                        value,
                        polygon.map((v) => [v.x, v.y])
                    )
                )
            ) {
                sum += value.data;
                count++;
            }
        });

        const averageHeatMapValue = sum / count;
        const effectiveSolarArea = face.computeArea() * averageHeatMapValue;
        return { averageHeatMapValue, effectiveSolarArea };
    }

    async tilingForSelectiveFaces(faceIds) {
        const notificationObject =
            this.stage.eventManager.setPowerRoofTilesLoading();
        await new Promise((resolve) => setTimeout(resolve, 20));
        try {
            this.stage.stateManager.startContainer();
            this.resetTiles();
            Object.values(this.smartRoofs).forEach((roof) => {
                roof.getChildren().forEach((face) => {
                    if (faceIds.includes(face.getId())) {
                        face.fillTilesOnFace({ pv: true });
                    } else if (face.isValidFace()) {
                        face.fillTilesOnFace({ pv: false });
                    }
                });
            });
            this.isTiled = true;
            this.saveState();
            this.stage.selectionControls.setSelectedObject(this);
            this.stage.eventManager.completePowerRoofTiles(notificationObject);
        } catch (e) {
            console.error(e);
            this.stage.eventManager.errorPowerRoofTiles(notificationObject);
        } finally {
            this.stage.stateManager.stopContainer();
        }
    }

    async fillToCapacity(numberOfTiles) {
        const notificationObject =
            this.stage.eventManager.setPowerRoofTilesLoading();
        await new Promise((resolve) => setTimeout(resolve, 20));
        try {
            this.stage.stateManager.startContainer();
            this.resetTiles();
            // get flat array of all valid faces
            const faces = [];
            Object.values(this.smartRoofs).forEach((roof) => {
                roof.getChildren().forEach((face) => {
                    if (face.isValidFace()) {
                        faces.push(face);
                    }
                });
            });
            // sort faces by effectiveSolarArea
            faces.sort((a, b) => b.averageHeatMapValue - a.averageHeatMapValue);

            // fill the faces with tiles until the capacity is reached
            let totalTiles = 0;
            let i = 0;
            while (totalTiles < numberOfTiles && i < faces.length) {
                const face = faces[i];
                const remainingTiles = numberOfTiles - totalTiles;
                const tiles = face.fillTilesOnFace({
                    pv: true,
                    tileCount: remainingTiles,
                });
                totalTiles += tiles;
                i += 1;
            }
            // fill the remaining faces with nonPV tiles
            while (i < faces.length) {
                const face = faces[i];
                face.fillTilesOnFace({ pv: false });
                i += 1;
            }
            this.isTiled = true;
            this.saveState();
            this.stage.selectionControls.setSelectedObject(this);
            this.stage.eventManager.completePowerRoofTiles(notificationObject);
        } catch (e) {
            console.error(e);
            this.stage.eventManager.errorPowerRoofTiles(notificationObject);
        } finally {
            this.stage.stateManager.stopContainer();
        }
    }

    async fillAllFaces() {
        const notificationObject =
            this.stage.eventManager.setPowerRoofTilesLoading();
        await new Promise((resolve) => setTimeout(resolve, 20));
        try {
            this.stage.stateManager.startContainer();
            this.resetTiles();
            Object.values(this.smartRoofs).forEach((roof) => {
                roof.getChildren().forEach((face) => {
                    if (face.isValidFace()) {
                        face.fillTilesOnFace({ pv: true });
                    }
                });
            });
            this.isTiled = true;
            this.saveState();
            this.stage.selectionControls.setSelectedObject(this);
            this.stage.eventManager.completePowerRoofTiles(notificationObject);
        } catch (e) {
            console.error(e);
            this.stage.eventManager.errorPowerRoofTiles(notificationObject);
        } finally {
            this.stage.stateManager.stopContainer();
        }
    }

    /**
     *
     * @param {SmartroofModel} smartRoof
     */
    addRoof(smartRoof) {
        if (this.smartRoofs[smartRoof.id]) {
            console.warn("adding exising roof");
        }
        smartRoof.connectedPowerRoof = this;
        this.smartRoofs[smartRoof.id] = smartRoof;
        smartRoof.saveState();

        this.saveState();
        // update outline geom
    }

    /**
     *
     * @param {SmartroofModel} smartRoof
     */
    removeRoof(smartRoof) {
        // TODO: Consider edge cases for change of state of
        // smartroofs involved when removed/added.

        if (this.smartRoofs[smartRoof.id]) {
            // TODO: Though only testEdges were updated in updateGeometry of smartRoof
            // Consider the case for optimization.
            smartRoof.testEdges.forEach((edge) => {
                edge.measurementTextUpdate();
            });
            smartRoof.outerEdgeObjects.forEach((edge) => {
                edge.measurementTextUpdate();
            });
            smartRoof.innerEdgesObject.forEach((edge) => {
                edge.measurementTextUpdate();
            });

            smartRoof.connectedPowerRoof = null;
            delete this.smartRoofs[smartRoof.id];
        } else {
            console.warn("smartroof is not part of the powerRoof");
        }

        // update outline geom
    }

    removeObject() {
        this.removeBareDeck();
        Object.keys(this.smartRoofs).forEach((key) => {
            this.smartRoofs[key] && this.smartRoofs[key].removeObject();
        });
        this.smartRoofs = {};
        this.stage.stateManager.add({
            uuid: this.uuid,
            getStateCb: () => DELETED_STATE,
        });
        this.stage.ground.powerRoofs.splice(
            this.stage.ground.powerRoofs.indexOf(this),
            1
        );
        this.inverter?.removeObject();
        useTilesStore().isPowerRoofPresentInScene = false;
        useTilesStore().dcViewVisible = false;
        this.stage.sceneManager.scene.remove(this.objectsGroup);
    }

    removePowerroofForEditing() {
        this.deSelect();
        this.removeBareDeck();
        if (this.connectedCombinerBox) this.connectedCombinerBox.removeObject();
        Object.keys(this.smartRoofs).forEach((key) => {
            this.smartRoofs[key].children.forEach((child) => {
                if (child.isValidFace()) {
                    child.removeGrid();
                }
            });
            this.smartRoofs[key].connectedPowerRoof = null;
            this.smartRoofs[key].saveState();
            delete this.smartRoofs[key];
        });
        this.smartRoofs = {};
        this.stage.ground.powerRoofs.splice(
            this.stage.ground.powerRoofs.indexOf(this),
            1
        );
        this.inverter?.removeObject();
        useTilesStore().isPowerRoofPresentInScene = false;
        useTilesStore().dcViewVisible = false;
        this.stage.sceneManager.scene.remove(this.objectsGroup);
        this.stage.stateManager.add({
            uuid: this.uuid,
            getStateCb: () => DELETED_STATE,
        });
    }

    async updateElectricalProperties(properties) {
        const propertyNames = [
            "tilesEnabled",
            "invertersEnabled",
            "dcEnabled",
            "acEnabled",
            "mergeEnabled",
            "conduitEnabled",
            "homeRunType",
            "conduitType",
        ];

        propertyNames.forEach((propertyName) => {
            if (
                Object.prototype.hasOwnProperty.call(
                    properties,
                    propertyName
                ) &&
                properties[propertyName] !== this[propertyName]
            ) {
                if (propertyName === "mergeEnabled"
                ) {
                    if(!this.mergeEnabled &&
                    properties.mergeEnabled) {
                        this.mergeAC();
                    }else if(this.mergeEnabled && !properties.mergeEnabled) {
                        const addAC = this.acEnabled;
                        this.resetAC();
                    }
                }
                this[propertyName] = properties[propertyName];
            }
        });

        await this.updateAllStates();
        this.saveState();
        return Promise.resolve(true);
    }

    async updateGridProperties(updatedProperties) {
        let resetTilesRequired = false;
        const gridProperties = this.getGridProperties();
        if (
            Object.prototype.hasOwnProperty.call(
                updatedProperties,
                "mountingMethod"
            ) &&
            updatedProperties.mountingMethod !== gridProperties.mountingMethod
            ) {
            gridProperties.mountingMethod = updatedProperties.mountingMethod;
            resetTilesRequired = true;
        }
        if (
            Object.prototype.hasOwnProperty.call(
                updatedProperties,
                "rowOverlapping"
            ) &&
            updatedProperties.rowOverlapping !== gridProperties.overlap
            ) {
            gridProperties.overlap = this.getValidOverlap(
                updatedProperties.rowOverlapping
            );
            resetTilesRequired = true;
        }
        if (
            Object.prototype.hasOwnProperty.call(
                updatedProperties,
                "mechanicalLayoutEnabled"
            ) &&
            updatedProperties.mechanicalLayoutEnabled !==
                this.isMechanicalLayoutEnabled()
        ) {
            this.mechanicalLayoutEnabled =
                updatedProperties.mechanicalLayoutEnabled;
            if (updatedProperties.mechanicalLayoutEnabled) {
                this.showMechanicalLayout();
            } else {
                this.hideMechanicalLayout();
            }
        }
        if (
            Object.prototype.hasOwnProperty.call(
                updatedProperties,
                "flashingEnabled"
            ) &&
            updatedProperties.flashingEnabled !== this.isFlashingEnabled()
        ) {
            this.flashingEnabled = updatedProperties.flashingEnabled;
            if (updatedProperties.flashingEnabled) {
                this.showFlashing();
            } else {
                this.hideFlashing();
            }
        }
        if (resetTilesRequired) {
            this.resetTiles();
        }
        this.stage.mergeManager.mergeScene(this);
        return Promise.resolve(true);
    }

    showMechanicalLayout() {
        if(!this.cappingsComputed) {
            this.drawCappings();
        }
        Object.keys(this.smartRoofs).forEach((key) => {
            this.smartRoofs[key].children.forEach((child) => {
                if (child.isValidFace()) {
                    child.showCappings();
                }
            });
        });
    }

    hideMechanicalLayout() {
        Object.keys(this.smartRoofs).forEach((key) => {
            this.smartRoofs[key].children.forEach((child) => {
                if (child.isValidFace()) {
                    child.hideCappings();
                }
            });
        });
    }

    showFlashing() {
        Object.keys(this.smartRoofs).forEach((key) => {
            this.smartRoofs[key].children.forEach((child) => {
                if (child.isValidFace()) {
                    child.showChildrenFlashing();
                }
            });
        });
    }

    hideFlashing() {
        Object.keys(this.smartRoofs).forEach((key) => {
            this.smartRoofs[key].children.forEach((child) => {
                if (child.isValidFace()) {
                    child.hideChildrenFlashing();
                }
            });
        });
    }

    getValidOverlap(overlap) {
        if (overlap > 0.1) {
            return 0.1;
        }
        return overlap;
    }

    addMicroInverters() {
        this.stage.switchToPowerRoofInverterMenu();
        // TODO: Populate actual electrical values
        const dummyInverter = {
            stringLength: 1,
            id: 6295,
            make: "IQ7A-72-2-INT Enphase Energy",
        };
        this.inverter.stringLength = dummyInverter.stringLength;
        this.inverter.electricalProperties.id = dummyInverter.id;
    }

    showInverters() {
        this.inverter.showInverters();
    }

    groupCounts(tolerance = 0.01) {
        const allCounts = [];
        // for each tiles grid get the counts
        Object.keys(this.smartRoofs).forEach((key) => {
            this.smartRoofs[key].children.forEach((child) => {
                if (child.isValidFace()) {
                    allCounts.push(child.tilesGrid?.counts);
                }
            });
        });

        // each counts is an array of objects {length, count}
        // create a new array of counts by grouping by length
        const groupedCounts = [];
        allCounts.forEach((counts) => {
            counts.forEach((count) => {
                const index = groupedCounts.findIndex(
                    (groupedCount) =>
                        Math.abs(groupedCount.length - count.length) <=
                        tolerance
                );
                if (index === -1) {
                    groupedCounts.push({
                        length: count.length,
                        count: count.count,
                    });
                } else {
                    groupedCounts[index].count += count.count;
                }
            });
        });
        return groupedCounts;
    }

    hideInverters() {
        this.inverter.hideInverters();
    }

    showTiles() {
        Object.keys(this.smartRoofs).forEach((key) => {
            this.smartRoofs[key].children.forEach((child) => {
                if (child.isValidFace()) {
                    child.tilesGrid?.showTiles();
                }
            });
        });
    }

    hideTiles() {
        Object.keys(this.smartRoofs).forEach((key) => {
            this.smartRoofs[key].children.forEach((child) => {
                if (child.isValidFace()) {
                    child.tilesGrid?.hideTiles();
                }
            });
        });
    }

    async showDC() {
        if (this.dcComputed) {
            Object.keys(this.smartRoofs).forEach((key) => {
                this.smartRoofs[key].children.forEach((child) => {
                    if (child.isValidFace()) {
                        child.tilesGrid?.switchToDCView();
                    }
                });
            });
        } else {
            await this.addDC();
        }
        useTilesStore().dcViewVisible = true;
        this.stage.eventManager.solarAccessVisibility(false);
    }

    hideDC() {
        Object.keys(this.smartRoofs).forEach((key) => {
            this.smartRoofs[key].children.forEach((child) => {
                if (child.isValidFace()) {
                    child.tilesGrid?.hideDCView();
                }
            });
        });
        useTilesStore().dcViewVisible = false;
    }

    async addDC() {
        this.totalMicroInverters = 0;
        this.totalCableLength = 0;
        const notificationObject =
            this.stage.eventManager.setPowerRoofDCLoading();
        try {
            for (const key of Object.keys(this.smartRoofs)) {
                const smartRoof = this.smartRoofs[key];
                for (const child of smartRoof.children) {
                    if (child.isValidFace()) {
                        const pvTileGroups =
                            await child.tilesGrid?.runDCAnnealing();
                        if (pvTileGroups) {
                            const inverterInstances = pvTileGroups.map(
                                (group) => group.inverterInstance
                            );
                            const cableLength =
                                child.tilesGrid.getDCCableLength();
                            this.inverter.addInverters(inverterInstances);
                            this.totalMicroInverters +=
                                inverterInstances.length || 0;
                            this.totalCableLength += cableLength || 0;
                        }
                    }
                }
            }
            this.updateDCCapacity();
            this.dcComputed = true;
            this.dcEnabled = true;
            this.invertersEnabled = true;
            this.showInverters();
            this.stage.eventManager.completePowerRoofDC(notificationObject);
        } catch (e) {
            console.warn("Error in adding DC: ", e);
            this.stage.eventManager.errorPowerRoofDC(notificationObject);
        }
    }

    updateDCCapacity() {
        const pvTiles = this.getNumberOfTiles().pv;
        const valueInWatts = this.gridProperties.power * pvTiles;
        const valueInKilowatts = valueInWatts / 1000;
        this.dcCapacity = valueInKilowatts.toFixed(2);
    }

    resetTiles() {
        if (!this.isTiled) {
            return;
        }
        this.resetElectricals();
        Object.keys(this.smartRoofs).forEach((key) => {
            this.smartRoofs[key].children.forEach((child) => {
                if (child.isValidFace()) {
                    child.removeGrid();
                }
            });
        });
        this.isTiled = false;
        this.addGrid();
        this.stage.mergeManager.mergeScene(this);
    }

    resetElectricals() {
        this.resetConduit();
        this.resetAC();
        this.resetDC();
        if (this.connectedCombinerBox) this.connectedCombinerBox.removeObject();
        this.inverter.clearInverters();
        this.invertersEnabled = false;
    }

    resetDC() {
        if (!this.dcComputed) return;
        this.dcComputed = false;
        this.dcEnabled = false;
        this.inverter.clearInverters();
        this.invertersEnabled = false;
        for (const key of Object.keys(this.smartRoofs)) {
            const smartRoof = this.smartRoofs[key];
            for (const child of smartRoof.children) {
                if (child.isValidFace()) {
                    child.tilesGrid?.resetDC();
                }
            }
        }
        this.updateMergedNodes();
    }

    resetAC() {
        if (!this.acComputed) return;
        this.acComputed = false;
        this.acEnabled = false;
        this.tilesEnabled = true;
        this.invertersEnabled = false;
        this.inverter.resetInverters();
        this.mergedStrings = null;
        this.conduitComputed = false;
        this.conduitEnabled = false;
        for (const key of Object.keys(this.smartRoofs)) {
            const smartRoof = this.smartRoofs[key];
            for (const child of smartRoof.children) {
                if (child.isValidFace()) {
                    child.tilesGrid?.resetAC();
                }
            }
        }
        this.mergeEnabled = false;
    }

    resetConduit() {
        if (!this.conduitComputed) return;
        this.conduitComputed = false;
        this.conduitEnabled = false;
        this.conduitGroup.visible = false;
        this.conduitGroup.children = [];
    }

    async showAC() {
        if (this.acComputed) {
            Object.keys(this.smartRoofs).forEach((key) => {
                this.smartRoofs[key].children.forEach((child) => {
                    if (child.isValidFace()) {
                        child.tilesGrid?.switchToACView();
                    }
                });
            });
        } else {
            await this.addAC();
        }
        this.cablesGroup.visible = true;
    }

    async addAC() {
        this.acStrings = [];
        this.hideTiles();
        this.hideDC();
        const notificationObject =
            this.stage.eventManager.setPowerRoofACLoading();
        try {
            for (const key of Object.keys(this.smartRoofs)) {
                const smartRoof = this.smartRoofs[key];
                for (const child of smartRoof.children) {
                    if (child.isValidFace()) {
                        const acStrings =
                            await child.tilesGrid?.runACAnnealing();
                        if (acStrings) {
                            this.acStrings.push(...acStrings);
                        }
                    }
                }
            }
            this.colorInverters();
            this.acComputed = true;
            this.acEnabled = true;
            this.tilesEnabled = false;
            this.dcEnabled = false;
            this.invertersEnabled = true;
            this.showInverters();
            this.stage.eventManager.completePowerRoofAC(notificationObject);
        } catch (e) {
            console.warn("Error in adding DC: ", e);
            this.stage.eventManager.errorPowerRoofAC(notificationObject);
        }
        this.updatePathNodesMap();
    }

    hideAC() {
        Object.keys(this.smartRoofs).forEach((key) => {
            this.smartRoofs[key].children.forEach((child) => {
                if (child.isValidFace()) {
                    child.tilesGrid?.hideACView();
                }
            });
        });
        this.cablesGroup.visible = false;
    }

    mergeAC() {
        try {
            if (this.conduitComputed) {
                this.resetConduit();
            }
            this.mergedStrings = mergedAC(this, this.inverter.maxInverterPerBranch);
            this.colorInverters();
        } catch (e) {
            console.error(e);
        }
    }

    getTopNodes() {
        const allNodes = this.mergedNodes;
        const topNodes = [];
        allNodes.forEach((node) => {
            if (node.parents.size > 1) {
                topNodes.push(node);
            }
        });
        
        return topNodes;
    }

    getFaceNodes() {
        const allNodes = this.mergedNodes;
        const faceNodes = [];
        allNodes.forEach((node) => {
            if (node.parents.size > 0 && node.parents[0] instanceof SmartroofFace) {
                faceNodes.push(node);
            }
        });
        return faceNodes;
    }

    showConduit() {
        this.conduitGroup.visible = true;
        if (!this.conduitComputed) {
            this.addConduits();
        }
    }

    hideConduit() {
        this.conduitGroup.visible = false;
    }

    addConduits() {
        // iterate through the nodes of the roof and find the node which can be a junction to collect all the string end4
        try {
            let nodes = this.getTopNodes();
            if(nodes.length === 0) {
                nodes = this.getFaceNodes();
            }
            const strings = this.mergedStrings || this.acStrings;
            let availableStrings = [...strings];
            const maxStringsPerJunction = 5;
            const usedNodes = [];
            const homeRunOffset = 0.01;
            const homeRunColor = 0x00ffff;
            while (availableStrings.length > 0) {
                const costs = [];
                nodes
                    .filter((n) => !usedNodes.includes(n))
                    .forEach((node) => {
                        let cost = 0;
                        availableStrings.forEach((string) => {
                            const startNode = string.startPathNode;
                            const endNode = string.endPathNode;
                            // find the shorter one
                            const path1 = this.findPath(startNode, node);
                            const path2 = this.findPath(node, endNode);
                            if (path1.cost < path2.cost) {
                                cost += path1.cost;
                            } else {
                                cost += path2.cost;
                            }
                        });
                        costs.push({
                            node,
                            cost,
                        });
                    });
                // draw all the paths for the shortest
                costs.sort((a, b) => a.cost - b.cost);
                usedNodes.push(costs[0].node);
                const tiles = [];
                for (parent of costs[0].node.parents) {
                    if (parent.tilesGrid) {
                        const tile = parent.tilesGrid.findClosestNonPVTile(
                            costs[0].node.position
                        );
                        if (tile) {
                            tiles.push(tile);
                        }
                    }
                }
                const tileCosts = [];
                tiles.forEach((tile) => {
                    const tileNode = new PathNode(
                        tile.getGlobalPosition(),
                        "tile-node",
                        tile.gridCell.grid.smartroofFace
                    );
                    const path = this.findPath(costs[0].node, tileNode);
                    const path2 = this.findPath(costs[0].node, this.combinerNode);
                    const cost = path.cost + path2.cost;
                    tileCosts.push({
                        tile,
                        cost,
                        tileNode,
                    });
                });
                tileCosts.sort((a, b) => a.cost - b.cost);
    
                const junctionTile = tileCosts[0].tile;
                // junctionTile.onSelect();
                junctionTile.hide();
                junctionTile.isJunction = true;
    
                connectPathNodes(costs[0].node, tileCosts[0].tileNode);
                const junctionNode = tileCosts[0].tileNode;
    
                const numberOfStrings = Math.min(
                    maxStringsPerJunction,
                    availableStrings.length
                );
                const potentialStrings = [];
                for (let i = 0; i < availableStrings.length; i++) {
                    // find the cost of the string
                    const path = this.findPath(
                        junctionNode,
                        availableStrings[i].startPathNode
                    );
                    const path2 = this.findPath(
                        junctionNode,
                        availableStrings[i].endPathNode
                    );
                    if (path.cost < path2.cost) {
                        potentialStrings.push({
                            string: availableStrings[i],
                            cost: path.cost,
                        });
                    } else {
                        potentialStrings.push({
                            string: availableStrings[i],
                            cost: path2.cost,
                        });
                    }
                }
                potentialStrings.sort((a, b) => a.cost - b.cost);
                const selectedStrings = potentialStrings.slice(0, numberOfStrings);
                // remove the selected strings from available strings
                selectedStrings.forEach((s) => {
                    availableStrings = availableStrings.filter(
                        (string) => string !== s.string
                    );
                });
    
                for (let i = 0; i < selectedStrings.length; i++) {
                    const string = selectedStrings[i].string;
                    const startNode = string.startPathNode;
                    const endNode = string.endPathNode;
                    // if both share a parent then directly connect them
                    const sharedParents = Array.from(startNode.parents).filter(
                        (parent) =>
                            Array.from(junctionNode.parents).includes(parent)
                    );
                    if (
                        sharedParents.length === 1 &&
                        sharedParents[0] instanceof SmartroofFace
                    ) {
                        const d1 = startNode.position.distanceTo(
                            junctionNode.position
                        );
                        const d2 = endNode.position.distanceTo(
                            junctionNode.position
                        );
                        if (d1 < d2) {
                            this.drawPath(
                                [startNode, junctionNode],
                                homeRunColor,
                                homeRunOffset,
                                this.conduitGroup
                            );
                        } else {
                            this.drawPath(
                                [endNode, junctionNode],
                                homeRunColor,
                                homeRunOffset,
                                this.conduitGroup
                            );
                        }
                    } else {
                        // find the shorter one
                        const path1 = this.findPath(startNode, junctionNode);
                        const path2 = this.findPath(junctionNode, endNode);
                        if (path1.cost < path2.cost) {
                            // draw path1
                            this.drawPath(
                                path1.path,
                                homeRunColor,
                                homeRunOffset,
                                this.conduitGroup
                            );
                        } else {
                            // draw path2
                            this.drawPath(
                                path2.path,
                                homeRunColor,
                                homeRunOffset,
                                this.conduitGroup
                            );
                        }
                    }
                }
                const homeRunPath = this.findPath(junctionNode, this.combinerNode);
                this.addJunctionBox(junctionTile, this.conduitGroup);
                this.drawConduitPath(
                    homeRunPath.path,
                    null,
                    null,
                    this.conduitGroup
                );
            }
            this.conduitComputed = true;
            this.updatePathNodesMap();
            notificationAssistant.success({
                title: "Conduits",
                message: "Conduit added successfully.",
            });
        } catch (e) {
            console.error(e);
            this.conduitEnabled = false;
            this.conduitComputed = false;
            // clear the conduit group
            this.conduitGroup.children.forEach((child) => {
                child.geometry.dispose();
                child.material.dispose();
            });
            this.conduitGroup.clear();
            notificationAssistant.error({
                title: 'Conduits',
                message: 'Error in adding conduits. Please try again.',
            });
        }
    }

    addJunctionBox(tile, objGroup = this.objectsGroup) {
        const group = new THREE.Group();
        const width = 0.3;
        const depth = 0.05;
        const height = 0.25;
        const geometry = new THREE.BoxGeometry(height, width, depth);
        const material = new THREE.MeshBasicMaterial({
            color: 0xffffff,
            side: THREE.DoubleSide,
        });
        const mesh = new THREE.Mesh(geometry, material);
        mesh.position.copy(tile.position);
        mesh.position.z += depth / 2;
        group.add(mesh);

        // add a black circle on top of the plane
        const circleGeometry = new THREE.CircleGeometry(0.05, 32);
        const circleMaterial = new THREE.MeshBasicMaterial({
            color: 0x000000,
            side: THREE.DoubleSide,
        });
        const circleMesh = new THREE.Mesh(circleGeometry, circleMaterial);
        circleMesh.position.copy(tile.position);
        circleMesh.position.z += depth + 0.01;
        group.add(circleMesh);

        group.applyMatrix4(tile.getMatrix());
        objGroup.add(group);
    }

    drawConduitPath(
        path,
        color = 0x848484,
        offSet = 0.03,
        objGroup = this.objectsGroup
    ) {
        if (!path) return;
        const lineCurvePath = new Line3ConnectedCurve();
        for (let i = 0; i < path.length - 1; i++) {
            const node1 = path[i];
            const node2 = path[i + 1];
            const sharedParents = Array.from(node1.parents).filter((parent) =>
                Array.from(node2.parents).includes(parent)
            );

            if (
                sharedParents.length === 1 &&
                sharedParents[0] instanceof SmartroofFace
            ) {
                // Calculate Manhattan path using local coordinates and axes
                const manhattanPath = this.getManhattanPath(
                    node1,
                    node2,
                    sharedParents[0]
                );
                manhattanPath.forEach((p) => {
                    const lineCurve = new THREE.LineCurve3(p[0], p[1]);
                    lineCurvePath.add(lineCurve);
                });
                continue;
            }
            const edge = {
                nodes: [node1, node2],
                color: color,
                offSet: 0.01,
            };
            const existingEdge = node1.edges.filter(
                (e) => e.nodes[0] === node1 && e.nodes[1] === node2
            );
            if (existingEdge.length > 0) {
                edge.offSet = existingEdge.length * 0.01;
            }
            const lineCurve = new THREE.LineCurve3(
                node1.position,
                node2.position
            );
            lineCurvePath.add(lineCurve);
        }

        // end curve for the last node
        const lastNodePosition = path[path.length - 1].position;
        // get combiner box wall position
        const combinerBoxWallHeight = this.connectedCombinerBox.getPosition().z;
        const combinerBoxWallPosition = new THREE.Vector3(
            lastNodePosition.x,
            lastNodePosition.y,
            combinerBoxWallHeight
        );
        const lineCurve = new THREE.LineCurve3(
            lastNodePosition,
            combinerBoxWallPosition
        );
        lineCurvePath.add(lineCurve);
        // if there is extra distance between the last node and the combiner box wall
        // add a straight line to the combiner box wall
        const extraDistance = lastNodePosition.distanceTo(
            combinerBoxWallPosition
        );
        if (extraDistance > 0.1) {
            const lineCurve = new THREE.LineCurve3(
                combinerBoxWallPosition,
                this.connectedCombinerBox.getPosition()
            );
            lineCurvePath.add(lineCurve);
        }

        const geometry = new THREE.TubeGeometry(
            lineCurvePath,
            128,
            0.04,
            15,
            false
        );
        geometry.computeVertexNormals();

        const material = new THREE.MeshStandardMaterial({
            color,
            metalness: 0.5,
            roughness: 0.5,
        });

        const mesh = new THREE.Mesh(geometry, material);
        mesh.position.z = 0.01 + offSet;
        objGroup.add(mesh);
    }

    computeArea() {
        let totalArea = 0;
        Object.keys(this.smartRoofs).forEach((key) => {
            totalArea += this.smartRoofs[key].computeArea();
        });

        return totalArea;
    }

    getNumberOfTiles() {
        let totalTiles = {
            pv: 0,
            nonPV: {
                full: 0,
                half: 0,
                quarter: 0,
            },
            custom: 0,
            customArea: 0,
        };
        Object.keys(this.smartRoofs).forEach((key) => {
            this.smartRoofs[key].children.forEach((child) => {
                if (child.isValidFace()) {
                    const faceTiles = child.getNumberOfTiles();
                    totalTiles.pv += faceTiles.pv;
                    totalTiles.nonPV.full += faceTiles.nonPV.full;
                    totalTiles.nonPV.half += faceTiles.nonPV.half;
                    totalTiles.nonPV.quarter += faceTiles.nonPV.quarter;
                    totalTiles.custom += faceTiles.custom;
                    totalTiles.customArea += faceTiles.customArea;
                }
            });
        });
        return totalTiles;
    }

    // This is a temporary function for highlight
    // will be removed when populateIntersectionData is decided.
    getOutline() {
        const geometryFactory = new JSTS.geom.GeometryFactory();
        const polygons = Object.values(this.smartRoofs).map((roof) => {
            const vertices = roof.get2DVertices();
            const polygon = JSTSConverter.verticesToJSTSPolygon(vertices);

            return polygon;
        });
        const geometryCollection = new JSTS.geom.GeometryCollection(
            polygons,
            geometryFactory
        );
        const union = geometryCollection.union().buffer(0.1);
        const outline = union
            .getBoundary()
            .getCoordinates()
            .map((c) => new THREE.Vector2().copy(c));
        return outline;
    }

    addGrid() {
        Object.keys(this.smartRoofs).forEach((key) => {
            this.smartRoofs[key].children.forEach((child) => {
                if (child.isValidFace()) {
                    child.addGrid();
                }
            });
        });
    }

    showBareDeck() {
        this.bareDeckVisible = true;
        Object.keys(this.smartRoofs).forEach((key) => {
            this.smartRoofs[key].children.forEach((child) => {
                if (child.isValidFace()) {
                    child.showBareDeck();
                }
            });
            this.smartRoofs[key].raiseCoreEdges();
        });
    }

    hideBareDeck() {
        this.bareDeckVisible = false;
        Object.keys(this.smartRoofs).forEach((key) => {
            this.smartRoofs[key].children.forEach((child) => {
                if (child.isValidFace()) {
                    child.hideBareDeck();
                }
            });
            this.smartRoofs[key].lowerCoreEdges();
        });
    }

    removeBareDeck() {
        Object.keys(this.smartRoofs).forEach((key) => {
            this.smartRoofs[key].children.forEach((child) => {
                if (child.isValidFace()) {
                    child.removeBareDeck();
                }
            });
            this.smartRoofs[key].lowerCoreEdges();
        });
    }

    getState() {
        const powerRoofData = {
            type: PowerRoof.getObjectType(),
            smartRoofIds: [],
            tileGrids: [],
            bareDeckVisible: this.bareDeckVisible,
            connectedCombinerBox: this.connectedCombinerBox?.uuid,
            isTiled: this.isTiled,
            isDCComputed: this.dcComputed,
            isACComputed: this.acComputed,
            isConduitComputed: this.conduitComputed,
            dcEnabled: this.dcEnabled,
            acEnabled: this.acEnabled,
            mergeEnabled: this.mergeEnabled,
            tilesEnabled: this.tilesEnabled,
            invertersEnabled: this.invertersEnabled,
            conduitEnabled: this.conduitEnabled,
            homeRunType: this.homeRunType,
            conduitType: this.conduitType,
        };

        Object.keys(this.smartRoofs).forEach((key) => {
            powerRoofData.smartRoofIds.push(this.smartRoofs[key].id);
        });

        Object.keys(this.smartRoofs).forEach((key) => {
            this.smartRoofs[key].children.forEach((child) => {
                if (child.isValidFace()) {
                    powerRoofData.tileGrids.push(child.tilesGrid?.saveObject());
                }
            });
        });

        powerRoofData.nodesData = this.saveNodesGraph();

        return powerRoofData;
    }

    loadState(state, fromState) {
        if (state === CREATED_STATE || state === DELETED_STATE) {
            this.clearState();
        } else {
            const smartRoofIds = state.smartRoofIds;
            smartRoofIds.forEach((smartRoofId) => {
                const smartRoof =
                this.stage.ground.getSmartroofById(smartRoofId);
                if (smartRoof) {
                    this.addRoof(smartRoof);
                }
            });
            if (fromState === CREATED_STATE || fromState === DELETED_STATE) {
                this.stage.ground.powerRoofs.push(this);
                this.stage.sceneManager.scene.add(this.objectsGroup);
                this.bareDeckVisible = state.bareDeckVisible;
                this.showBareDeck();
                this.inverter.initInstancedMesh();
            }
            useTilesStore().isPowerRoofPresentInScene = true;
            let heatmapValuesPresent = true;
            // this is the label
            outerLoop: for (const smartRoof of Object.values(this.smartRoofs)) {
                for (const child of smartRoof.children) {
                    if (child.isValidFace() && !child.effectiveSolarArea) {
                        this.updateHeatmapValues();
                        heatmapValuesPresent = false;
                        break outerLoop; // this will break out of the outer loop
                    }
                }
            }
            if (heatmapValuesPresent) {
                useTilesStore().isTilesSolarIrradianceFetched = true;
            }

            // reset micro-inverters
            this.inverter.clearInverters();
            this.totalMicroInverters = 0;
            this.totalCableLength = 0;
            const acGrids = [];
            // load dc
            state.tileGrids.forEach((tileGridData) => {
                const smartRoof = Object.values(this.smartRoofs).find(
                    (smartRoof) => smartRoof.id === tileGridData.smartRoofId
                );
                const face = smartRoof?.children.find(
                    (child) => child.id === tileGridData.smartroofFaceId
                );
                if (face) {
                    const grid = face.tilesGrid || new TilesGrid(this.stage, face);
                    grid.loadObject(tileGridData);
                    face.tilesGrid = grid;
                    if (grid.pvTileGroups) {
                        const pvTileGroups = grid.pvTileGroups;
                        const inverterInstances = pvTileGroups.map(
                            (group) => group.inverterInstance
                        );
                        const cableLength = grid.getDCCableLength();
                        this.inverter.addInverters(inverterInstances);
                        this.totalMicroInverters += inverterInstances.length || 0;
                        this.totalCableLength += cableLength || 0;
                        acGrids.push({grid, tileGridData});
                    }
                }
            });

            this.acStrings = [];
            if(state.nodesData) {
                this.loadNodesGraph(state.nodesData);
            }else{
                this.updateMergedNodes();
            }

            acGrids.forEach(({grid, tileGridData}) => {
                const strings = grid.loadAC(tileGridData);
                if(strings) {
                    this.acStrings.push(...strings)
                }
            });
            
            this.updateDCCapacity();


            this.dcComputed = state.isDCComputed;
            this.acComputed = state.isACComputed;
            this.conduitComputed = state.isConduitComputed;

            this.dcEnabled = state.dcEnabled;
            this.acEnabled = state.acEnabled;
            this.conduitEnabled = state.conduitEnabled;
            this.tilesEnabled = state.tilesEnabled;
            this.invertersEnabled = state.invertersEnabled;
            this.homeRunType = state.homeRunType;
            this.conduitType = state.conduitType;

            this.updateAllStates();

            this.bareDeckVisible = state.bareDeckVisible;
            this.connectedCombinerBox = this.stage.getObject(
                state.connectedCombinerBox
            );
            this.isTiled = state.isTiled;
        }
        return true;
    }

    saveNodesGraph() {
        const flatNodes = this.mergedNodes[0]?.getFlatNodes();
        if (!flatNodes) {
            return null;
        }
        const roofNodesData = flatNodes.map((node) => {
            return {
                position: [node.position.x, node.position.y, node.position.z],
                parents: Array.from(node.parents).map((parent) => parent.id),
                id: node.id,
                name: node.name,
                neighbors: Array.from(node.neighbors).map((neighbor) => neighbor.id),
            };
        });
        const combinerNodeData = this.saveCombinerNode();
        const nodesData = {
            roofNodesData,
            combinerNodeData,
        };
        return nodesData;
    }

    loadNodesGraph(nodesData, loadCombiner = true) {
        this.mergedNodes = [];
        const nodes = nodesData.roofNodesData.reduce((acc, nodeData) => {
            const parents = nodeData.parents.map(parentId => this.getSmartroofFaceById(parentId)).filter(Boolean);
            if (parents.length > 0) {
                const node = new PathNode(
                    new THREE.Vector3(nodeData.position[0], nodeData.position[1], nodeData.position[2]),
                    nodeData.name
                );
                node.id = nodeData.id;
                parents.forEach(parent => node.addParent(parent));
                this.mergedNodes.push(node);
                acc.push(node);
            }
            return acc;
        }, []);
        // connect neighbors
        nodesData.roofNodesData.forEach((nodeData) => {
            const node = nodes.find((node) => node.id === nodeData.id);
            if(!node) return;
            nodeData.neighbors.forEach((neighborId) => {
                const neighbor = nodes.find((node) => node.id === neighborId);
                if (neighbor) {
                    connectPathNodes(node, neighbor);
                }
            });
        });
        const combinerNodeData = nodesData.combinerNodeData;
        if(loadCombiner && combinerNodeData) {
            const node = new PathNode(
                new THREE.Vector3(combinerNodeData.position[0], combinerNodeData.position[1], combinerNodeData.position[2]),
                combinerNodeData.name
            );
            node.id = combinerNodeData.id;
            node.addParent(this);
            this.combinerNode = node;
            combinerNodeData.neighbors.forEach((neighborId) => {
                const neighbor = nodes.find((node) => node.id === neighborId);
                if (neighbor) {
                    connectPathNodes(node, neighbor);
                }
            });
        }

        if(this.mergedNodes.length > 0) {            
            // update the junction node
            this.updatePathNodesMap();
        }else{
            this.updateMergedNodes();
        }
    }

    getSmartroofFaceById(id) {
        for (const smartroof of Object.values(this.smartRoofs)) {
            for (const face of smartroof.children) {
                if (face.id === id) {
                    return face;
                }
            }
        }
        return null;
    }

    updateInverters() {

    }

    switchVisualState(newVisualState, recursive) {
        if(newVisualState === VISUAL_STATES.DEFAULT_STATES.SOLAR_ACCESS) {
            this.dcEnabled = false;
            this.hideDC();
        }
        if (newVisualState === VISUAL_STATES.DEFAULT_STATES.SOLAR_ACCESS
            && !this.isSolarAccessUpdated) {
            this.stage.asyncManager.updateSolarAccess()
                .then((data) => {
                    this.stage.showSolarAccess();
                })
                .catch((error) => {
                    console.error(error);
                });
        }
        else {
            Object.values(this.smartRoofs).forEach((roof) => {
                roof.getChildren().forEach((face) => {
                    if(face.isValidFace()) face.tilesGrid.switchVisualState(newVisualState, recursive);
                });
            });
        }
    }

    async updateAllStates() {
        const states = [
            {
                property: "tilesEnabled",
                show: () => this.showTiles(),
                hide: () => this.hideTiles(),
            },
            {
                property: "invertersEnabled",
                show: () => this.showInverters(),
                hide: () => this.hideInverters(),
            },
            {
                property: "dcEnabled",
                show: () => this.showDC(),
                hide: () => this.hideDC(),
            },
            {
                property: "acEnabled",
                show: () => this.showAC(),
                hide: () => this.hideAC(),
            },
            {
                property: "conduitEnabled",
                show: () => this.showConduit(),
                hide: () => this.hideConduit(),
            },
        ];

        for (const state of states) {
            await this.updateState(state.property, state.show, state.hide);
        }
    }

    async updateState(stateProperty, showFunction, hideFunction) {
        if (this[stateProperty]) {
            await Promise.resolve(showFunction());
        } else {
            await Promise.resolve(hideFunction());
        }
    }

    saveObject() {
        const powerRoofData = {
            type: PowerRoof.getObjectType(),
            smartRoofIds: [],
            tileGrids: [],
            bareDeckVisible: this.bareDeckVisible,
            isTiled: this.isTiled,
        };
        Object.keys(this.smartRoofs).forEach((key) => {
            powerRoofData.smartRoofIds.push(this.smartRoofs[key].id);
        });
        Object.keys(this.smartRoofs).forEach((key) => {
            this.smartRoofs[key].children.forEach((child) => {
                if (child.isValidFace()) {
                    powerRoofData.tileGrids.push(child.tilesGrid?.saveObject());
                }
            });
        });
        powerRoofData.nodesData = this.saveNodesGraph();

        return powerRoofData;
    }

    loadObject(powerRoofData) {
        const smartRoofIds = powerRoofData.smartRoofIds;
                smartRoofIds.forEach((smartRoofId) => {
            const smartRoof = this.stage.ground.getSmartroofById(smartRoofId);
            if (smartRoof) {
                this.addRoof(smartRoof);
                smartRoof.saveState({ withoutContainer: true });
            }
        });
        let heatmapValuesPresent = true;
        // can remove this flag if needed
        let heatmapValuesFetched = false;
        let combinerBoxLoaded = false;
        const combinersToAdd = [];
        const roofs = Object.values(this.smartRoofs);
        for (
            let i = 0;
            i < roofs.length && !(combinerBoxLoaded && heatmapValuesFetched);
            i += 1
        ) {
            for (
                let j = 0;
                j < roofs[i].children.length &&
                !(combinerBoxLoaded && heatmapValuesFetched);
                j += 1
            ) {
                if (
                    roofs[i].children[j].isValidFace() &&
                    !roofs[i].children[j].effectiveSolarArea &&
                    !heatmapValuesFetched
                ) {
                    this.updateHeatmapValues();
                    heatmapValuesFetched = true;
                }
                roofs[i].children[j].children.forEach((child) => {
                    if (child instanceof PowerRoofCombinerBox) {
                        combinersToAdd.push(child);
                        combinerBoxLoaded = true;
                    }
                });
            }
        }
        if (heatmapValuesPresent) {
            useTilesStore().isTilesSolarIrradianceFetched = true;
        }

        let dcComputed = false;
        let acComputed = false;
        this.totalMicroInverters = 0;
        this.totalCableLength = 0;

        // reset micro-inverters
        this.inverter.clearInverters();
        this.totalMicroInverters = 0;
        this.totalCableLength = 0;
        const acGrids = [];
        // load dc
        powerRoofData.tileGrids.forEach((tileGridData) => {
            const smartRoof = Object.values(this.smartRoofs).find(
                (smartRoof) => smartRoof.id === tileGridData.smartRoofId
            );
            const face = smartRoof?.children.find(
                (child) => child.id === tileGridData.smartroofFaceId
            );
            if (face) {
                const grid = new TilesGrid(this.stage, face);
                grid.loadObject(tileGridData);
                face.tilesGrid = grid;
                if (!grid.pvTileGroups) {
                } else {
                    dcComputed = true;
                    const pvTileGroups = grid.pvTileGroups;
                    const inverterInstances = pvTileGroups.map(
                        (group) => group.inverterInstance
                    );
                    const cableLength = grid.getDCCableLength();
                    this.inverter.addInverters(inverterInstances);
                    this.totalMicroInverters += inverterInstances.length || 0;
                    this.totalCableLength += cableLength || 0;
                    // if(tileGridData.acStrings) {
                    //     acGrids.push({grid, tileGridData});
                    //     acComputed = true;
                    // }
                }
            }
        });
        this.dcComputed = dcComputed;
        this.updateDCCapacity();
        this.acComputed = acComputed;
        this.acStrings = [];

        this.updateMergedNodes();


        combinersToAdd.forEach((c) => {
            this.connectedCombinerBox = c;
            this.addCombinerBox(c);
            this.combinerPlaced = true;
        })

        this.updateDCCapacity();

        this.bareDeckVisible = powerRoofData.bareDeckVisible;
        this.showBareDeck();
        this.isTiled = powerRoofData.isTiled;
        this.updateAllStates();

        this.saveState({ withoutContainer: true });
    }

    static getObjectType() {
        return "powerRoof";
    }

    clearState() {
        if (this.isSelected) {
            this.deSelect();
            this.stage.selectionControls.setSelectedObject(this.stage.ground);
        }
        this.removeBareDeck();
        Object.keys(this.smartRoofs).forEach((key) => {
            // remove the entry from this.smartRoofs
            this.smartRoofs[key].children.forEach((child) => {
                if (child.isValidFace()) {
                    child.removeGrid();
                }
            });
            this.smartRoofs[key].connectedPowerRoof = null;
            delete this.smartRoofs[key];
        });
        this.smartRoofs = {};
        this.stage.ground.powerRoofs.splice(
            this.stage.ground.powerRoofs.indexOf(this),
            1
        );
        this.inverter?.removeObject();
        useTilesStore().isPowerRoofPresentInScene = false;
        useTilesStore().dcViewVisible = false;
        this.stage.sceneManager.scene.remove(this.objectsGroup);
    }

    onSelect() {
        this.isSelected = true;
        // Highlight the outline

        Object.keys(this.smartRoofs).forEach((key) => {
            this.smartRoofs[key].coreMesh.material.opacity = 0.4;
        });

        this.updateVisualsBasedOnStates();
    }

    deSelect() {
        this.isSelected = false;
        // unHighlight

        Object.keys(this.smartRoofs).forEach((key) => {
            this.smartRoofs[key].coreMesh.material.opacity = 0.25;
        });

        this.updateVisualsBasedOnStates();
    }

    // Needed?
    updateVisualsAfterLoadingAndCreation() {
        //
        console.log("updateVisualsAfterLoadingAndCreation");
    }

    // Need to make all visual changes
    // be called with this function.
    // This will be useful for render on demand.
    updateVisualsBasedOnStates() {
        // Pass the visual state changes to children.

        if (this.isSelected) {
            console.log("Placeholder for powerRoof selected visual state");
        } else {
            console.log("Placeholder for powerRoof unselected visual state");
        }
    }

    /**
     * Gets the bare deck texture. If the texture is not already loaded, it loads the texture and stores its dimensions.
     * @returns {Promise<Object>} A Promise that resolves to an object containing the texture and its dimensions.
     */
    async getBareDeckTexture() {
        if (this.bareDeckTexture) {
            return {
                texture: this.bareDeckTexture,
            };
        }
        const texture = await this.loadTexture(bareDecktexture);
        this.bareDeckTexture = texture;
        return {
            texture,
        };
    }

    /**
     * Gets the underlayment texture. If the texture is not already loaded, it loads the texture and stores its dimensions.
     * @returns {Promise<Object>} A Promise that resolves to an object containing the texture and its dimensions.
     */
    async getunderLaymentTexture() {
        // TODO:
        // if (this.bareDeckTexture) {
        //     return {
        //         texture: this.bareDeckTexture.clone(),
        //         textureDimensions: this.textureDimensions,
        //     };
        // }
        // const texture = await this.loadTexture(arkaLogo);
        // const { imageHeight, imageWidth } = this.getImageDimensions(texture);
        // this.bareDeckTexture = texture;
        // this.textureDimensions = { height: imageHeight, width: imageWidth };
        // return {
        //     texture: this.bareDeckTexture.clone(),
        //     textureDimensions: this.textureDimensions,
        // };
    }

    /**
     * Loads a texture from an image.
     * @param {string} imageUrl The URL of the image.
     * @returns {Promise<THREE.Texture>} A promise that resolves with the loaded texture.
     */
    loadTexture(imageUrl) {
        return new Promise((resolve, reject) => {
            new THREE.TextureLoader().load(
                imageUrl,
                (texture) => {
                    texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
                    texture.repeat.set(1, 1);
                    texture.anisotropy = 16;
                    resolve(texture);
                },
                undefined,
                (error) => reject(error)
            );
        });
    }

    /**
     * Gets the dimensions of an image from a texture.
     * @param {THREE.Texture} texture The texture.
     * @returns {Object} An object containing the height and width of the image.
     */
    getImageDimensions(texture) {
        const imageHeight = texture.source.data.height;
        const imageWidth = texture.source.data.width;
        return { imageHeight, imageWidth };
    }

    drawCappings() {
        // Reset Count
        this.cappingCount = {
            ridge: 0,
            hip: 0,
            valley: 0,
        };
        const blockFootDefaultData = useTilesStore().mechanicalComponents.blockFoot;
        const cappingDimensions = {
            ridge: {
                width: blockFootDefaultData.ridgeCap.details.width,
                length: blockFootDefaultData.ridgeCap.details.length,
                overlap: blockFootDefaultData.ridgeCap.details.ridge_cap_overlap,
            },
            hip: {
                width: blockFootDefaultData.hipCap.details.width,
                length: blockFootDefaultData.hipCap.details.length,
                overlap: blockFootDefaultData.hipCap.details.hip_cap_overlap,
            },
            valley: {
                width: blockFootDefaultData.valleyGutter.details.width,
                length: blockFootDefaultData.valleyGutter.details.length,
                overlap: 0.05,
            },
        }

        Object.values(this.smartRoofs).forEach((roof) => {
            roof.drawCappings(cappingDimensions);
            this.cappingCount.ridge += roof.cappingCount.ridge;
            this.cappingCount.hip += roof.cappingCount.hip;
            this.cappingCount.valley += roof.cappingCount.valley;
        });

        this.cappingCount.ridge /= 2;
        this.cappingCount.hip /= 2;
        this.cappingCount.valley /= 2;
    }

    drawEdge(
        point1,
        point2,
        color = 0x0000ff,
        offSet = 0,
        objGroup = this.objectsGroup,
        parallelOffset = 0
    ) {
        const material = new THREE.LineBasicMaterial({ color });
        const points = [];
        points.push(new THREE.Vector3(point1.x, point1.y, point1.z + offSet));
        points.push(new THREE.Vector3(point2.x, point2.y, point2.z + offSet));
        const geometry = new THREE.BufferGeometry().setFromPoints(points);
        const edge = new THREE.LineSegments(geometry, material);

        // move the edge parallel to itself by parallelOffset
        const edgeVector = new THREE.Vector3().subVectors(point2, point1);
        const edgeVectorNormalized = edgeVector.clone().normalize();
        const edgeVectorPerpendicular = new THREE.Vector3(
            -edgeVectorNormalized.y,
            edgeVectorNormalized.x,
            0
        );
        const edgeVectorPerpendicularScaled = edgeVectorPerpendicular
            .clone()
            .multiplyScalar(parallelOffset);
        edge.position.add(edgeVectorPerpendicularScaled);
        objGroup.add(edge);
    }

    drawCircle(
        point1,
        color = 0xff0000,
        offSet = 0,
        objGroup = this.objectsGroup,
        radius = 0.1
    ) {
        const material = new THREE.MeshBasicMaterial({ color });
        const geometry = new THREE.CircleGeometry(radius, 10);
        const circle = createMesh(geometry, material);
        circle.position.set(point1.x, point1.y, point1.z + offSet);
        this.objectsGroup.add(circle);
    }

    drawCircleGreen(point1, offSet = 0) {
        const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
        const geometry = new THREE.CircleGeometry(0.01, 10);
        const circle = new THREE.Mesh(geometry, material);
        circle.position.set(point1.x, point1.y - offSet, 30);
        this.objectsGroup.add(circle);
    }

    updateMergedNodes() {
        const allConnectedNodes = [];
        const outerEdges = [];
        Object.values(this.smartRoofs).forEach((smartroof) => {
            allConnectedNodes.push(...smartroof.getAllConnectedNodes());
            outerEdges.push(...smartroof.outerEdgeObjects);
        });
        this.mergeNearbyNodes(allConnectedNodes);
        this.mergedNodes = allConnectedNodes;
        this.outerEdges = outerEdges;
        // this.drawNodeGraph(this.mergedNodes[0]);
    }

    findClosestNode(point) {
        const closestNode = this.mergedNodes.reduce((prev, curr) => {
            return point.position.distanceTo(prev.position) <
                point.position.distanceTo(curr.position)
                ? prev
                : curr;
        });
        return closestNode;
    }

    findNodesOnLine(line) {
        const nodes = this.mergedNodes.filter((node) => {
            const point = new THREE.Vector3();
            line.closestPointToPoint(node.position, false, point);
            point.z = node.position.z;
            const d = point.distanceTo(node.position);
            return d < 0.1;
        });
        return nodes;
    }

    addCombinerBox = (connectedCombinerBox, updateNodes = true) => {
        this.connectedCombinerBox = connectedCombinerBox;
        if(!updateNodes) return;
        // iterate through all the node edges and find the closest edge to the combiner box
        const nodeEdges = [];
        const combinerPosition = connectedCombinerBox.getPosition();
        const combiner2DPosition = combinerPosition.clone();
        combiner2DPosition.z = 0;
        for (const node of this.mergedNodes) {
            const node2DPosition = node.position.clone();
            node2DPosition.z = 0;
            for (const neighbor of node.neighbors) {
                const neighbor2DPosition = neighbor.position.clone();
                neighbor2DPosition.z = 0;
                const line = new THREE.Line3(node2DPosition, neighbor2DPosition);
                const point = new THREE.Vector3();
                line.closestPointToPoint(combiner2DPosition, true, point);
                const distance = point.distanceTo(combiner2DPosition);
                nodeEdges.push({ node, neighbor, distance, line });
            }
        }
        nodeEdges.sort((a, b) => a.distance - b.distance);
        const closestEdge = nodeEdges[0];
        const closestLine = closestEdge.line;

        // find the closest point on the line to the combiner box
        const combinerNodePosition2D = new THREE.Vector3();
        closestLine.closestPointToPoint(combinerPosition, true, combinerNodePosition2D);

        // project the combiner node position vertically to the 3d line
        const line3D = new THREE.Line3(
            closestEdge.node.position,
            closestEdge.neighbor.position
        );
        const point3D = new THREE.Vector3();
        const combinerNodePosition3D = new THREE.Vector3(
            combinerNodePosition2D.x,
            combinerNodePosition2D.y,
            0
        );
        line3D.closestPointToPoint(combinerNodePosition3D, true, point3D);
        combinerNodePosition3D.z = point3D.z;


        const nodesOnEdge = [nodeEdges[0].node, nodeEdges[0].neighbor];
        for (let i = 1; i < nodeEdges.length; i++) {
            if (nodeEdges[i].distance < 0.1) {
                nodesOnEdge.push(nodeEdges[i].node);
                nodesOnEdge.push(nodeEdges[i].neighbor);
            }
        }
        
        // sort the nodes based on their distance to the combiner box
        const sortedNodes = nodesOnEdge.sort((a, b) => {
            return combinerNodePosition3D.distanceTo(a.position) <
                combinerNodePosition3D.distanceTo(b.position)
                ? -1
                : 1;
        });
        const combinerNode1 = sortedNodes[0];
        let combinerNode2;
        const node2d = new THREE.Vector2(
            combinerNode1.position.x,
            combinerNode1.position.y
        );
        for (let i = 1; i < sortedNodes.length; i++) {
            const node2d2 = new THREE.Vector2(
                sortedNodes[i].position.x,
                sortedNodes[i].position.y
            );

            if (node2d2.distanceTo(node2d) > 0.5) {
                combinerNode2 = sortedNodes[i];
                break;
            }
        }

        // Add a new node corresponding to the combiner box
        const combinerNode = new PathNode(
            combinerNodePosition3D,
            "combinerBox",
            this
        );
        this.combinerNode = combinerNode;

        // connect combiner node to the other nodes
        connectPathNodes(combinerNode, combinerNode1);
        connectPathNodes(combinerNode, combinerNode2);

        this.mergedNodes.push(combinerNode);
        this.updatePathNodesMap();
    };

    saveCombinerNode() {
        if(!this.combinerNode) return null;
        const combinerNodeData = {
            position: [this.combinerNode.position.x, this.combinerNode.position.y, this.combinerNode.position.z],
            id: this.combinerNode.id,
            name: this.combinerNode.name,
            neighbors: Array.from(this.combinerNode.neighbors).map((neighbor) => neighbor.id),            
        };
        return combinerNodeData;
    }

    getAllFaces() {
        const faces = [];
        for (const roof of this.roofs) {
            faces.push(...roof.getValidFaces());
        }
        return faces;
    }

    createVerticalConnections(nodes) {
        // use the x and y coordinates of the nodes, if they are close then generate a vertical edge by connecting them
        const nearbyTreshold = 0.1;
        for (let i = 0; i < nodes.length; i++) {
            for (let j = i + 1; j < nodes.length; j++) {
                if (this.get2Ddistance(nodes[i], nodes[j]) < nearbyTreshold) {
                    connectPathNodes(nodes[i], nodes[j]);
                }
            }
        }
    }

    addMergeNodes(nodes) {
        // find all edges that overlap in 2D and group them together
        // get all the different z values of all the edges
        // for all the nodes in the group, create additional nodes with the same x and y coordinates but with the missing z values

        // represent all unique 2d edges using their slope and y intercept
        const edges = {};
        for (let i = 0; i < nodes.length; i++) {
            const node1 = nodes[i];
            const neighbors = node1.neighbors;
            for (let j = 0; j < neighbors.length; j++) {
                const node2 = neighbors[j];
                if (
                    node1.position.x === node2.position.x &&
                    node1.position.y === node2.position.y
                )
                    continue;
                const slope =
                    (node2.position.y - node1.position.y) /
                    (node2.position.x - node1.position.x);
                const yIntercept = node1.position.y - slope * node1.position.x;
                // create a hash by reducing the slope and y intercept to 2 decimal places
                const hash = `${Math.round(slope * 100) / 100}_${
                    Math.round(yIntercept * 100) / 100
                }`;
                if (!edges[hash]) {
                    edges[hash] = {};
                }

                // if (Math.abs(node1.position.z - node2.position.z)) continue;
                if (!edges[hash][node1.position.z]) {
                    edges[hash][node1.position.z] = [];
                }
                edges[hash][node1.position.z].push([node1, node2]);
            }
        }

        // for each edge hash having more than one z value, create additional nodes at each z value using nodes from the other z values
        for (const hash in edges) {
            const zValues = Object.keys(edges[hash]);
            if (zValues.length > 1) {
                for (let i = 0; i < zValues.length; i++) {
                    const z1 = zValues[i];
                    const z1Nodes = edges[hash][z1];
                    const otherZValues = zValues.filter((z) => z !== z1);
                    for (let j = 0; j < otherZValues.length; j++) {
                        const z2 = otherZValues[j];
                        const z2Nodes = edges[hash][z2];
                        for (let k = 0; k < z1Nodes.length; k++) {
                            const node1 = z1Nodes[k][0];
                            const node2 = z1Nodes[k][1];
                            for (let l = 0; l < z2Nodes.length; l++) {
                                const node3 = z2Nodes[l][0];
                                const node4 = z2Nodes[l][1];
                                const zValue = node3.position.z;
                                const newNode1 = new PathNode(
                                    new THREE.Vector3(
                                        node1.position.x,
                                        node1.position.y,
                                        zValue
                                    ),
                                    "merge" + node1.name + z2,
                                    this
                                );
                                const newNode2 = new PathNode(
                                    new THREE.Vector3(
                                        node2.position.x,
                                        node2.position.y,
                                        zValue
                                    ),
                                    "merge" + node2.name + z2,
                                    this
                                );
                                // create connections between all the nodes at the same z value
                                connectPathNodes(newNode1, newNode2);
                                connectPathNodes(newNode1, node3);
                                connectPathNodes(newNode1, node4);
                                connectPathNodes(newNode2, node3);
                                connectPathNodes(newNode2, node4);
                                nodes.push(newNode1);
                                nodes.push(newNode2);
                            }
                        }
                    }
                }
            }
        }

        this.updatePathNodesMap();
    }

    updatePathNodesMap() {
        // take the any node and get the flat nodes array and update it in the map
        const flatNodes = this.mergedNodes[0].getFlatNodes();
        // this.drawNodeGraph(flatNodes[0]);
        this.pathNodesMap = new Map();
        flatNodes.forEach((node) => {
            this.pathNodesMap.set(node.id, node);
        });
    }

    getNodeById(id) {
        return this.pathNodesMap.get(id);
    }

    findPathIndex(
        startIndex = 0,
        endIndex = 4,
        compulsoryNodesIndex = [],
        debug = true
    ) {
        let mergedNodes;
        if (!this.pathGroup) {
            this.pathGroup = new THREE.Group();
            this.stage.sceneManager.scene.add(this.pathGroup);
            mergedNodes = this.mergedNodes
                ? this.mergedNodes
                : this.getMergedNodes();
            this.addMergeNodes(mergedNodes);
            this.createVerticalConnections(mergedNodes);
        } else {
            this.pathGroup.children.forEach((c) => c.geometry.dispose());
            this.pathGroup.children.forEach((c) => c.material.dispose());
            this.pathGroup.children.forEach((c) => this.pathGroup.remove(c));
            mergedNodes = this.mergedNodes;
        }
        // Perform A* algorithm
        const compulsoryNodes = compulsoryNodesIndex.map((i) => mergedNodes[i]);
        // const {path,cost} = aStar(mergedNodes[startIndex], mergedNodes[endIndex]);
        const { path, cost } = aStarMustVisit(
            mergedNodes[startIndex],
            mergedNodes[endIndex],
            compulsoryNodes
        );

        // Validate the result
        if (path !== null) {
            // printPath(path);
            for (let i = 0; i < path.length - 1; i++) {
                const node1 = path[i];
                const node2 = path[i + 1];
                this.drawEdge(
                    node1.position,
                    node2.position,
                    0xff0000,
                    0.1,
                    this.pathGroup
                );
            }
        } else {
            console.warn("No path found.");
        }

        if (debug) {
            this.drawCircle(
                mergedNodes[startIndex].position,
                0x00ff00,
                0,
                this.pathGroup
            );
            compulsoryNodes.forEach((n) =>
                this.drawCircle(n.position, 0x0000ff, 0, this.pathGroup)
            );
            this.drawCircle(
                mergedNodes[endIndex].position,
                0x00ff00,
                0,
                this.pathGroup
            );
        }
    }

    findPath(
        startNode,
        endNode,
        compulsoryNodes = [],
        avoidNodes = [],
        debug = false
    ) {
        let mergedNodes;
        if (!this.pathGroup) {
            this.pathGroup = new THREE.Group();
            mergedNodes = this.mergedNodes
                ? this.mergedNodes
                : this.getMergedNodes();
            this.addMergeNodes(mergedNodes);
            this.createVerticalConnections(mergedNodes);
        } else {
            this.pathGroup.children.forEach((c) => c.geometry.dispose());
            this.pathGroup.children.forEach((c) => c.material.dispose());
            this.pathGroup.children.forEach((c) => this.pathGroup.remove(c));
            mergedNodes = this.mergedNodes;
        }
        // Perform A* algorithm
        const { path, cost } = aStarMustVisit(
            startNode,
            endNode,
            compulsoryNodes,
            avoidNodes
        );

        // if (debug) {
        //     this.drawCircle(startNode.position, 0x00ff00, 0, this.pathGroup);
        //     compulsoryNodes.forEach((n) =>
        //         this.drawCircle(n.position, 0x0000ff, 0, this.pathGroup)
        //     );
        //     this.drawCircle(endNode.position, 0x00ff00, 0, this.pathGroup);
        // }

        return { path, cost };
    }

    drawPath(path, color = 0xff0000, offSet = 0, objGroup = this.objectsGroup) {
        if (!path) return;
        for (let i = 0; i < path.length - 1; i++) {
            const node1 = path[i];
            const node2 = path[i + 1];
            const sharedParents = Array.from(node1.parents).filter((parent) =>
                Array.from(node2.parents).includes(parent)
            );

            if (
                sharedParents.length === 1 &&
                sharedParents[0] instanceof SmartroofFace
            ) {
                // Calculate Manhattan path using local coordinates and axes
                const manhattanPath = this.getManhattanPath(
                    node1,
                    node2,
                    sharedParents[0]
                );
                manhattanPath.forEach((p) => {
                    this.drawEdge(p[0], p[1], color, offSet, objGroup);
                });
            } else {
                const edge = {
                    nodes: [node1, node2],
                    color: color,
                    offSet: 0.01,
                };
                const existingEdge = node1.edges.filter(
                    (e) => e.nodes[0] === node1 && e.nodes[1] === node2
                );
                if (existingEdge.length > 0) {
                    edge.offSet = existingEdge.length * 0.01;
                }
                this.drawEdge(
                    node1.position,
                    node2.position,
                    color,
                    offSet,
                    objGroup,
                    edge.offSet
                );
            }
        }
    }

    // This function needs to be implemented
    getManhattanPath(node1, node2, face) {
        // Calculate and return the Manhattan path
        const p1 = node1.position;
        const p2 = node2.position;

        const path = face.tilesGrid.getManhattanPathGlobal(p1, p2);

        return path;
    }

    drawNodeGraph(node, color = 0xff0000) {
        const flatNodes = node.getFlatNodes();
        if(this.nodesGroup) {
            this.nodesGroup.clear();
        }else {
            this.nodesGroup = new THREE.Group();
            this.stage.sceneManager.scene.add(this.nodesGroup);
        }
        for (let i = 0; i < flatNodes.length; i++) {
            const node1 = flatNodes[i];
            if (!node1.isValid()) continue;
            const neighbors = node1.neighbors;
            for (let j = 0; j < neighbors.length; j++) {
                const node2 = neighbors[j];
                if (!node2.isValid()) continue;
                this.drawEdge(node1.position, node2.position, color, 1 + 2 * i, this.nodesGroup);
            }
        }
    }

    mergeNearbyNodes(nodes, nearbyTreshold = 0.1) {
        for (let i = 0; i < nodes.length; i++) {
            for (let j = i + 1; j < nodes.length; j++) {
                if (
                    nodes[i].position.distanceTo(nodes[j].position) <
                    nearbyTreshold
                ) {
                    mergePathNodes(nodes[i], nodes[j]);
                    nodes[j].clean();
                    nodes.splice(j, 1);
                    j--;
                }
            }
            nodes[i].clean();
        }
    }

    get2Ddistance(node1, node2) {
        const x1 = node1.position.x;
        const y1 = node1.position.y;
        const x2 = node2.position.x;
        const y2 = node2.position.y;
        return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
    }

    colorInverters() {
        // dispose the old cables
        this.cablesGroup.children.forEach((cable) => {
            cable.geometry.dispose();
            cable.material.dispose();
        });
        this.cablesGroup.clear();
        const strings = this.mergedStrings || this.acStrings;
        if (!strings) return;
        // get a unique color for each string.
        const colors = [];
        for (let i = 0; i < strings.length; i++) {
            const color = new THREE.Color();
            let hue;
            if (i < strings.length *4 / 5) {
                // For the first half of the strings, use the spectrum from blue to violet
                hue = 0.5 + (i / (strings.length *4/5)) * (0.85 - 0.5);
            } else {
                // For the second half of the strings, use the small region around yellow
                hue = 0.17 + ((i - strings.length*4/5) / (strings.length*4/5)) * (0.2 - 0.17);
            }
            color.setHSL(hue, 1, 0.5);
            colors.push(color);
        }
        // color the inverters
        strings.forEach((string, stringIndex) => {
            string.microInverters.forEach((inverter, inverterIndex) => {
                inverter.setColor(colors[stringIndex]);
                inverter.updateText(
                    `B${stringIndex + 1}-M${inverterIndex + 1}`
                );
            });
            string.additionalPaths?.forEach((path) => {
                this.drawPath(path, colors[stringIndex], 0.01, this.cablesGroup);
            });
        });
    }

    getStringCount() {
        if (this.mergedStrings || this.acStrings) {
            return (this.mergedStrings || this.acStrings).length;
        }
        return 0;
    }

    homeRun() {
        const strings = this.mergedStrings || this.acStrings;
        this.mergedStrings = mergedHomeRun(strings, this.combinerNode, this);
        this.colorInverters();
    }

    getAestheticMaterial() {
        const material = new THREE.MeshStandardMaterial({
            color: 0x000000,
            side: THREE.DoubleSide,
            metalness: 0.8,
            roughness: 0.2,
        });
        return material;
    }

    getCustomShaderMaterial() {
        // simple vertex shader that passes on the uvs to the fragment shader
        const customVertexShader = `
        varying vec3 vViewPosition;
        varying vec3 vInstanceColor;
        
        #include <common>
        #include <uv_pars_vertex>
        #include <displacementmap_pars_vertex>
        #include <envmap_pars_vertex>
        #include <color_pars_vertex>
        #include <fog_pars_vertex>
        #include <normal_pars_vertex>
        #include <morphtarget_pars_vertex>
        #include <skinning_pars_vertex>
        #include <shadowmap_pars_vertex>
        #include <logdepthbuf_pars_vertex>
        #include <clipping_planes_pars_vertex>
        
        void main() {
         vInstanceColor = instanceColor;
         #include <uv_vertex>
         #include <color_vertex>
         #include <morphcolor_vertex>
        
         #include <beginnormal_vertex>
         #include <morphnormal_vertex>
         #include <skinbase_vertex>
         #include <skinnormal_vertex>
         #include <defaultnormal_vertex>
         #include <normal_vertex>
        
         #include <begin_vertex>
         #include <morphtarget_vertex>
         #include <skinning_vertex>
         #include <displacementmap_vertex>
         #include <project_vertex>
         #include <logdepthbuf_vertex>
         #include <clipping_planes_vertex>
         vUv = uv;

         gl_Position = projectionMatrix * viewMatrix * modelMatrix * instanceMatrix * vec4(position, 1.0);
         #include <logdepthbuf_vertex>
        
         vViewPosition = - mvPosition.xyz;
        
         #include <worldpos_vertex>
         #include <envmap_vertex>
         #include <shadowmap_vertex>
         #include <fog_vertex>
        }
        `;

        // simple fragment shader that colors the grid cells based on the uvs
        const customFragmentShader = `
        uniform vec3 diffuse;
        uniform vec3 emissive;
        uniform float opacity;
        varying vec2 vUv;
        varying vec3 vInstanceColor;


        #include <common>
        #include <packing>
        #include <dithering_pars_fragment>
        #include <color_pars_fragment>
        #include <uv_pars_fragment>
        #include <map_pars_fragment>
        #include <alphamap_pars_fragment>
        #include <alphatest_pars_fragment>
        // #include <alphahash_pars_fragment>
        #include <aomap_pars_fragment>
        #include <lightmap_pars_fragment>
        #include <emissivemap_pars_fragment>
        #include <envmap_common_pars_fragment>
        #include <envmap_pars_fragment>
        #include <fog_pars_fragment>
        #include <bsdfs>
        #include <lights_pars_begin>
        #include <normal_pars_fragment>
        #include <lights_lambert_pars_fragment>
        #include <shadowmap_pars_fragment>
        #include <bumpmap_pars_fragment>
        #include <normalmap_pars_fragment>
        #include <specularmap_pars_fragment>
        #include <logdepthbuf_pars_fragment>
        #include <clipping_planes_pars_fragment>

        void main() {
            #include <clipping_planes_fragment>
    
            vec4 diffuseColor = vec4( diffuse * vInstanceColor, opacity );
            ReflectedLight reflectedLight = ReflectedLight( vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ) );
            vec3 totalEmissiveRadiance = emissive;
            #include <logdepthbuf_fragment>
            #include <map_fragment>
            #include <color_fragment>
            #include <alphamap_fragment>
            #include <alphatest_fragment>
            // #include <alphahash_fragment>
            #include <specularmap_fragment>
            #include <normal_fragment_begin>
            #include <normal_fragment_maps>
            #include <emissivemap_fragment>
    
            // accumulation
            #include <lights_lambert_fragment>
            #include <lights_fragment_begin>
            #include <lights_fragment_maps>
            #include <lights_fragment_end>
    
            // modulation
            #include <aomap_fragment>
    
            vec3 outgoingLight = reflectedLight.directDiffuse + reflectedLight.indirectDiffuse + totalEmissiveRadiance;
            outgoingLight = (0.5*diffuseColor.rgb) + (0.5*outgoingLight);
            #include <envmap_fragment>
            // #include <opaque_fragment>
            #include <tonemapping_fragment>
            // #include <colorspace_fragment>
            #include <fog_fragment>
            #include <premultiplied_alpha_fragment>
            #include <dithering_fragment>
    
            vec2 uv = vUv;

            // add border
            float borderx = 0.025;
            float bordery = 0.05;
            if (uv.x < borderx || uv.x > 1.0 - borderx || uv.y < bordery || uv.y > 1.0 - bordery) {
                outgoingLight = vec3(1.0, 1.0, 1.0);
            }
            float borderx2 = 0.001;
            float bordery2 = 0.002;
            if (uv.x < borderx2 || uv.x > 1.0 - borderx2 || uv.y < bordery2 || uv.y > 1.0 - bordery2) {
                outgoingLight = vec3(0.0, 0.0, 0.0);
            }
            
            gl_FragColor = vec4(outgoingLight, 1.0);            
        }
        `;

        const uniforms = THREE.UniformsUtils.merge([
            THREE.ShaderLib.lambert.uniforms,
            {
                diffuse: { value: new THREE.Color(0xffffff) },
                opacity: { value: 1.0 },
            },
        ]);
        const material = new THREE.ShaderMaterial({
            vertexShader: customVertexShader,
            fragmentShader: customFragmentShader,
            side: THREE.DoubleSide,
            uniforms: uniforms,
            lights: true,
        });

        return material;
    }

    getTotalSolarAccess() {
        let totalSolarAccess = 0;

        Object.values(this.smartRoofs).forEach((roof) => {
            roof.getChildren().forEach((face) => {
                face.tilesGrid.gridCellRows.forEach((row) => {
                    row.forEach((gridCell) => {
                        if (gridCell.isPV) {
                            totalSolarAccess += gridCell.tiles[0].solarAccess;
                        }
                    });
                });
            });
        });

        return totalSolarAccess;
    }

    getAverageSolarAccess() {
        let nTiles = this.getNumberOfTiles().pv;
        if (nTiles > 0)
            return this.getTotalSolarAccess() / nTiles;
        else
            return 0;
    }

    optimiseOnSolarAccess(solarAccessThreshold) {
        const defaultColor = new THREE.Color(COLOR_MAPPINGS.TILES.DEFAULT_DC_COLOR);
        Object.values(this.smartRoofs).forEach((roof) => {
            roof.getChildren().forEach((face) => {
                face.tilesGrid.gridCellRows.forEach((row) => {
                    row.forEach((gridCell) => {
                        if (gridCell.isPV) {
                            const tile = gridCell.tiles[0];
                            if (tile.solarAccess < solarAccessThreshold) {
                                tile.convertOnOptimise = true;
                                tile.setColor(defaultColor);
                            }
                            else {
                                tile.convertOnOptimise = false;
                                tile.showSolarAccess();
                            }
                        }
                    });
                });
            });
        });
    }

    async initOptimiseOnTilesSize() {
        try {
            if (!this.isSolarAccessUpdated) {
                // await this.stage.asyncManager.updateSolarAccessForSubarray(this);
                notificationAssistant.error({
                    title: 'Optimize failed.',
                    message: 'Please refresh solar access.',
                });
                throw 'Optimize failed, need to refresh solar access';
            }
        }
        catch (error) {
            console.error('ERROR: PowerRoof: initOptimiseOnTilesSize failed', error);
            return Promise.reject(error);
        }

        const tilesArray = [];
        Object.values(this.smartRoofs).forEach((roof) => {
            roof.getChildren().forEach((face) => {
                face.tilesGrid.gridCellRows.forEach((row) => {
                    row.forEach((gridCell) => {
                        if (gridCell.isPV) tilesArray.push(gridCell.tiles[0]);
                    });
                });
            });
        });

        tilesArray.sort((a, b) => {
            if (a.solarAccess === b.solarAccess) {
                return (b.id - a.id);
            }
            return (b.solarAccess - a.solarAccess);
        });

        return tilesArray;
    }

    optimiseOnTilesSize(sortedTiles, nTiles) {
        const defaultColor = new THREE.Color(COLOR_MAPPINGS.TILES.DEFAULT_DC_COLOR);
        let i = 0;
        for (; i < nTiles; i++) {
            sortedTiles[i].convertOnOptimise = false;
            sortedTiles[i].showSolarAccess();
        }
        for (; i < sortedTiles.length; i++) {
            // TODO: DC color??
            sortedTiles[i].convertOnOptimise = true;
            sortedTiles[i].setColor(defaultColor);
        }

        return sortedTiles[nTiles -1].solarAccess.toFixed(3);
    }

    getMaxSolarAccess() {
        let maxSolarAccess = -Infinity;

        Object.values(this.smartRoofs).forEach((roof) => {
            roof.getChildren().forEach((face) => {
                face.tilesGrid.gridCellRows.forEach((row) => {
                    row.forEach((gridCell) => {
                        if (gridCell.isPV) maxSolarAccess = Math
                            .max(maxSolarAccess, gridCell.tiles[0].solarAccess);
                    });
                });
            });
        });

        return maxSolarAccess;
    }

    onCloseOptimise() {
        // Switch back from solarAccess view

        Object.values(this.smartRoofs).forEach((roof) => {
            roof.getChildren().forEach((face) => {
                face.tilesGrid.gridCellRows.forEach((row) => {
                    row.forEach((gridCell) => {
                        if (gridCell.isPV) {
                            const tile = gridCell.tiles[0];
                            // TODO: DC reset??
                            if (tile.convertOnOptimise)
                                tile.convertToNonPVTile();
                        }
                    });
                });
            });
        });

        this.stage.hideSolarAccess();
    }

    getModuleMake() {
        const gridProperties = this.getGridProperties();
        return gridProperties.manufacturer;
    }

    /**
     * Gets the DC size of a single tile.
     * @returns {number} The DC size of a single tile.
     */
    getTileDCSize() {
        const moduleSize = this.getGridProperties().power;
        return moduleSize;
    }

    /**
     * Gets the total DC size.
     * @returns {number} The total DC size.
     */
    getDCSize() {
        const moduleSize = this.getTileDCSize();
        const { pv } = this.getNumberOfTiles();

        return moduleSize * pv;
    }

    showObjectLayer() {
        this.objectsGroup.visible = true;
    }

    hideObjectLayer() {
        this.objectsGroup.visible = false;    
    }
}

class Line3ConnectedCurve extends THREE.CurvePath {
    constructor() {
        super();
    }
}
