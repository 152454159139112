<template>
    <div class="parentContainer" v-loading="isNextContractLoading">

        <el-dialog :visible="isEditContractPopupVisible" :close-on-click-modal="false" title="Edit Custom Placeholders"
            @close="onDialogClose"   :modal-append-to-body="isModal" >
            <div class="container">
                <div class="pageContainer">
                    <pureTextEditor
                        v-if="htmlContent && editorType=='text' && !isLoading"
                        :fileName="fileName"
                        :htmlContent="htmlContent"
                        :includePaymentSchedule="includePaymentSchedule"
                        :customPlaceholderMode="true"
                        :key="componentKey"
                        :showToolbar="false"
                        :readOnly="true"
				    />
                    <div class="buttonsContainer" v-if="fileLink && editorType=='pdf' && !isLoading">
                        <el-button type="primary" :disabled="pdfPageNo<=1" @click="handlePrevious"  class="nextBtn">
                            <p style="display: flex;">
									<span class="backIcon">
										<b-icon icon="arrow-left" class="searchIcon" 
											style="transform: scale(1.5);margin-right: 16px;">
                                        </b-icon>
                                    </span>
                                Previous
                            </p>
                        </el-button>
                        <p>{{pdfPageNo}}/{{ pdfTotalPages }}</p>
                        <el-button type="primary" :disabled="(pdfPageNo>=pdfTotalPages)"  @click="handleNext" class="nextBtn">
                            <p style="display: flex;">
                                Next
                                <span class="backIcon"> 
                                    <b-icon icon="arrow-right" class="searchIcon"
                                        style="transform: scale(1.5);margin-left: 16px;">
                                    </b-icon>
                                </span>
                            </p>
                        </el-button>
                    </div>
                    <pdfRenderer
                        v-if="fileLink && editorType=='pdf' && !isLoading"
                        :fileLink="fileLink"
                        :pdfPageNo="pdfPageNo"
                        :includePaymentSchedule="includePaymentSchedule"
                        :customPlaceholderMode="true"
                        :key="componentKey"
                        @totalPages="totalPages => pdfTotalPages = totalPages"
                    />
                </div>
                <el-form ref="form" :model="form" class="formContainer">
                    <p class="font16Bold">Custom Placeholders</p>
                    <div style="word-break: break-word;" v-if="!CMStore.uniqueCustomPlaceholdersArray.length">
                        No custom placeholder is being used in this template
                    </div>
                    <div class="flexRow" v-for="(customPH,index) in CMStore.uniqueCustomPlaceholdersArray" :key="index" >
                        <p class="font14Normal">{{customPH.placeholder_key || customPH.name  }}</p>
                        <el-input
                        type="textarea"
                        maxlength="2000" show-word-limit
                        v-model="customPH.value" name="title"></el-input>
                        <p class="errorMsg" v-if=false>This field is required.</p>
                    </div>
                </el-form>
            </div>
            <div class="btnContainer">
                <p class="font16Normal">Contract <span class="pageNo">{{currentContractIndex+1}}/{{ selectedContracts.length }}</span></p>
                <el-button type="primary" :loading="isNextContractLoading" @click="saveCustomPlaceholdersInDB()" class="nextBtn">Next</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useContractManagementStore } from '../../../../stores/contractManagement';
import pureTextEditor from '../../../documentManagement/components/textEditor/pureTextEditor.vue';
import pdfRenderer from '../../../documentManagement/components/textEditor/pdfRenderer.vue';
import {fetchSasToken} from '../../../../utils'
import { containerNameForContracts } from "../../../../constants";
import API from "@/services/api/";
export default {
    emits: ["update:isEditContractPopupVisible", "closeEditContractPopup"],
    name: "sendDocumentsPopup",
    components:{
        pureTextEditor,
        pdfRenderer,
    },
    props: {
        isEditContractPopupVisible: {
            type: Boolean,
            default: false,
        },
        selectedContracts: {
            type: Array,
        },
        isModal:{
            type:Boolean,
            default:true
        }
        
    },

    data() {
        return {
            form: {
                firstClientName: ''
            },
            currentContractIndex:0,
            isContractInDesignLevel: true,
            includePaymentSchedule: false,
            currentContract:{},
            additionalDetails:{},
            editorType: 'pdf',
            fileName:"",
            htmlContent:null,
            pdfPageNo:1,
            pdfTotalPages:0,
            fileLink:null,
            isLoading: true,
            componentKey:0,
            isNextContractLoading: false,
            allAddedCustomPlaceholders:[],
            allAddedNotCustomPlaceholders:[],
            keyValuePairOfAddedCustomPHWithActualValues: {}
        };
    },
    mounted(){
        console.log("selected contracts@@@@@@@@@@",this.selectedContracts);
        this.prepareCurrentContract();
    },
    computed: {
        ...mapState(useContractManagementStore, {
            CMStore: (state) => state
        }),
    },

    methods: {
        ...mapActions(useContractManagementStore, [
            "SET_HTML_CONTENT",
            "SET_FILE_LINK",
            "UPDATE_PLACEHOLDERS_ARRAY",
            "UPDATE_CUSTOM_PLACEHOLDERS_ARRAY",
            "UPDATE_UNIQUE_CUSTOM_PLACEHOLDERS_ARRAY",
            "SET_KEY_VALUE_PLACEHOLDER"
        ]),
        fetchSasToken,
        onDialogClose() {
            this.$emit("update:isEditContractPopupVisible", false);
        },
        segregateCustomAndOtherPlaceholder(allAddedPlaceholders){
            this.allAddedCustomPlaceholders = allAddedPlaceholders.filter((placeholder)=>{
                return placeholder.placeholder_type =="custom_placeholders"
            })
            this.allAddedNotCustomPlaceholders = allAddedPlaceholders.filter((placeholder)=>{
                return placeholder.placeholder_type !="custom_placeholders"
            })
        },
        async fetchAddedPlaceholdersWithActualValues(){
            let addedNotCustomPlaceholdersIdentifier = [];
            let addedCustomPlaceholdersIdentifier = [];
            this.allAddedNotCustomPlaceholders.forEach((addedPlaceholder)=>{
                addedNotCustomPlaceholdersIdentifier.push(addedPlaceholder.placeholder_key)
            })
            this.allAddedCustomPlaceholders.forEach((addedPlaceholder)=>{
                addedCustomPlaceholdersIdentifier.push(addedPlaceholder.placeholder_key)
            })
            let payload = {
                "placeholders":[...addedNotCustomPlaceholdersIdentifier],
                "design_id": this.CMStore.selectedDesignIdForPreview,
                "contract_id":this.currentContract.id,
                "custom_placeholders":[...addedCustomPlaceholdersIdentifier]
            }
            const response = await API.CONTRACT_TEMPLATES.FETCH_ADDED_PLACEHOLDERS_WITH_ACTUAL_VALUES(payload);
            let actualAddedCustomPHWithActualValues = response.data.filter((eachPlaceholderType)=>{
                return  eachPlaceholderType.identifier =='custom_placeholders'
            })[0].placeholders
            return actualAddedCustomPHWithActualValues || [];
        },
        async prepareCurrentContract(){
            try{
                this.currentContract = this.selectedContracts[this.currentContractIndex];
                this.additionalDetails =  this.currentContract.additional_details
                this.UPDATE_PLACEHOLDERS_ARRAY(this.additionalDetails?.placeholders || [])
                this.customPlaceholders = (this.additionalDetails?.placeholders|| []).filter((placeholder)=>{
                    return placeholder.placeholder_type == 'custom_placeholders'
                })
                this.UPDATE_CUSTOM_PLACEHOLDERS_ARRAY(this.customPlaceholders || [])
                let isThisKeyAlreadyAdded = {}
                let uniqueAddedCustomPH=[]
                this.customPlaceholders.forEach((placeholder)=>{
                    if(isThisKeyAlreadyAdded[placeholder.placeholder_key]){
                        return;
                    }
                    else{
                        isThisKeyAlreadyAdded[placeholder.placeholder_key] = true;
                        uniqueAddedCustomPH.push(placeholder)
                    }
                })
                this.UPDATE_UNIQUE_CUSTOM_PLACEHOLDERS_ARRAY(uniqueAddedCustomPH)
                this.segregateCustomAndOtherPlaceholder(this.additionalDetails?.placeholders || [])
                let actualAddedCustomPHWithActualValues = await this.fetchAddedPlaceholdersWithActualValues()
                actualAddedCustomPHWithActualValues.forEach((customPH)=>{
                    this.keyValuePairOfAddedCustomPHWithActualValues[customPH.name] = customPH
                })
                this.CMStore.uniqueCustomPlaceholdersArray.forEach((uniquePH)=>{
                    if(this.keyValuePairOfAddedCustomPHWithActualValues[uniquePH.placeholder_key]){
                        uniquePH.value =  this.keyValuePairOfAddedCustomPHWithActualValues[uniquePH.placeholder_key].value
                    }
                })
                this.editorType =  this.currentContract.template_type
                this.fileName =   this.currentContract.file_name
                this.fetchDocumentFromBlobStorage()
            }
            catch(e){
                this.$message({
                    showClose: true,
                    message: "There is some issue in fetching the template",
                    type: "error",
                    center: true,
                });
                console.error(e)
            }
        },
        async fetchDocumentFromBlobStorage(){
            let sasToken =  await fetchSasToken(containerNameForContracts)
            console.log('sasToken: ', sasToken);
            this.fileLink = `https://downloadstsl.blob.core.windows.net/${containerNameForContracts}/${this.fileName}?${sasToken}`
            const response = await fetch(this.fileLink);
            this.htmlContent = await response.text();
            this.SET_HTML_CONTENT(this.htmlContent)
            this.SET_FILE_LINK(this.fileLink)
            console.log('fileLink: ', this.fileLink);
            this.componentKey++
            this.isLoading = false;
            this.isNextContractLoading= false;
        },
        handleNext(){
            this.pdfPageNo++;
            if(this.pdfPageNo>this.pdfTotalPages){
                this.pdfPageNo = this.pdfTotalPages;
            }
        },
        handlePrevious(){
            this.pdfPageNo--;
            if(this.pdfPageNo<1){
                this.pdfPageNo=1;
            }
        },
        async saveCustomPlaceholdersInDB(){
            this.isNextContractLoading = true;
            let payload = {
                "design_id": this.CMStore.selectedDesignIdForPreview,
                "contract_id":this.currentContract.id,
                "custom_placeholders": this.CMStore.uniqueCustomPlaceholdersArray.map((placeholder)=>{
                    return {
                        "name": placeholder.placeholder_key || placeholder.name,
                        "value": placeholder.value,
                    }
                }),
            }
            try{
                await API.CONTRACT_TEMPLATES.SAVE_CUSTOM_PLACEHOLDERS(payload)
            }
            catch(e){
                this.$message({
                    showClose: true,
                    message: "There is some issue in saving the placeholder",
                    type: "error",
                    center: true,
                });
                this.isNextContractLoading = false;
                console.error(e)
            }
            this.loadNextContract()
        },
        loadNextContract(){
            this.isNextContractLoading = true;
            if(this.currentContractIndex +1 >=  this.selectedContracts.length){
                this.isNextContractLoading = false;
                this.$emit("closeEditContractPopup")
                return;
            }
            this.currentContractIndex++;
            this.prepareCurrentContract()
        }
    },
};
</script>

<style scoped>
.parentContainer>>>.el-dialog__header {
    /* background-color: #1c3366; */
    background-image: linear-gradient(to bottom, #e8edf2, #e9ecf2);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0 !important;
    height: 48px !important;
    padding: 16px !important;
}

.parentContainer>>>.el-dialog__title {
    font-size: 16px;
    font-weight: 600;
    line-height: 2.38;
    text-align: left;
    color: #222 !important;
}

.parentContainer>>>.el-dialog__close {
    color: #222222 !important;
    font-weight: 800 !important;
    font-size: 24px !important;
}

.parentContainer>>>.el-dialog__headerbtn:focus .el-dialog__close,
.parentContainer>>>.el-dialog__headerbtn:hover .el-dialog__close {
    color: #222 !important;
}

.parentContainer>>>.el-dialog {
    border-radius: 12px !important;
    height: auto;
    overflow-y: hidden;
    margin-top: 14vh !important;
    max-width: 1000px;
    width: 90%;
}

.parentContainer>>>.el-dialog__body {
    padding: 0px !important;
}

.container {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 24px;
    padding: 16px;
    overflow-y: scroll;
    max-height: 50vh;
}

.pageContainer {
    border-radius: 8px;
    border: 1px solid var(--Grey-Scale-CCC, #CCC);
}

.formContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: fit-content;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid var(--Grey-Scale-CCC, #CCC);
}

.errorMsg {
    margin-top: 4px;
    color: #f00;
}

.parentContainer>>>.el-form-item {
    margin-bottom: 12x;
}

.flexRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
}

.font14Normal {
    font-size: 14px;
    color: #1D1D1F;
    word-break: keep-all;
}


.font14Bold {
    font-weight: bold;
}

.font16Bold {
    font-size: 16px;
    font-weight: bold;
    color: #1D1D1F;
    word-break: keep-all;
}

.font16Normal {
    font-size: 16px;
    color: #1D1D1F;
}

.pageNo {
    color: #3584D5;
}

.parentContainer>>>.el-input {
    width: 150px;
}

.parentContainer>>>.el-input__inner {
    font-size: 16px;
    color: #222;
    border-radius: 4px;
    border: 1px solid var(--Grey-Scale-999, #999);
    background: var(--Grey-Scale-White, #FFF);
    padding: 0px 12px;
    width: 150px;
}

.parentContainer>>>.el-input__inner::placeholder,
.parentContainer>>>.el-textarea__inner::placeholder {
    color: #777;
    font-size: 16px;
}

.btnContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #ccc;
    padding: 16px;
}

.nextBtn {
    font-size: 16px;
}

.buttonsContainer{
    display: flex;
    justify-content: space-between;
    padding: 10px;
}
.customPlaceholderMode{
	pointer-events: none;
	cursor: not-allowed;
}

@media (max-width: 1100px) {

    .parentContainer>>>.el-dialog {
        margin-top: 8vh !important;
    }

    .container {
        grid-template-columns: 1fr;
        max-height: 60vh;
    }
    .flexRow {
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
    }

    .parentContainer >>> .el-input__inner,
    .parentContainer>>>.el-input {
        width: 100%;
    }

}
</style>