import axios from "axios";

export default {
    FETCH_ACCESSORIES(params) {
        return axios.get(`api/solutions-order/accessory/?${params}`)
    },
    FETCH_ACCESSORIES_SKU_DATA(params) {
        return axios.get(`api/solutions-order/accessoryskus/?${params}`)
    },
   
}