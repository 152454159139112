<template>
    <div class="fieldsCardContainer">
        <div class="flexCardContainer">
            <img v-show="!isPreviewMode" class="icon" src="../assets/drag_indicator.svg" />
            <div class="cardBorder">
                <div v-if="!isPreviewMode" class="grid-parent">
                    <div v-if="fieldData.files.length > 0">
                        <section class="photo-video-section" v-for="(file, fileIndex) in fieldData.files" :key="fileIndex">
                            <el-input :placeholder="`${file.file_type.includes('image')? 'Image': 'Video'} Label`" v-model="file.file_info" @change="prepareFieldObjectToStore('file_info', fileIndex)"></el-input>
                            <div class="photo-video-container" v-loading="!file.file_url && !file.url">
                                <img class="images" :src="file.file_url || file.url" v-if="file.file_type.includes('image')"/>
                                <video class="videos" controls :src="file.file_url || file.url" v-if="file.file_type.includes('video')">
                                    Your browser does not support HTML5 video.
                                </video>
                                <img
                                src="../../../assets/drop/x-circle-fill.svg"
                                class="crossIcon"
                                @click="deleteCurrentFile(fileIndex)"
                                />
                            </div>
                            <div class="cardFooter" style="margin-bottom: 1.5rem">
                                <div class="flexCont">
                                    <div class="iconsCont">
                                        <i class="icon copy-alt" @click="duplicateCurrentFile(fileIndex)"
                                        :class="file.file_type.includes('image') ? (countOfImages3(fieldIndex-1, sectionIndex-1) ? 'icon copy-alt disabled-icon':'icon copy-alt') :
                                        (countOfVideos3(fieldIndex-1, sectionIndex-1) ? 'icon copy-alt disabled-icon':'icon copy-alt')">
                                        <!-- Add condition to see what the current file type is and then call the appropriate count function -->
                                        </i>
                                        <i class="icon delete-alt" @click="deleteCurrentFile(fileIndex)"></i>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div class="gridCont">
                        <div class="flexColumn">
                            <el-input placeholder="Field Label" v-model="fieldData.label" @change="prepareFieldObjectToStore('label')"></el-input>
                        </div>
                        <div class="flexColumn dropdownClass">
                            <el-select v-model="fieldData.question_type" placeholder="Select" @change="prepareFieldObjectToStore('question_type')">
                                <el-option
                                    v-for="item in filteredOptions"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                >
                                    <div class="flexOptions">
                                        <div v-if="item.iconType === 'icon'">
                                            <b-icon :icon="item.icons" style="color: #777; margin-top: 12px;" font-scale="1.3"></b-icon>
                                        </div>
                                        <div v-else-if="item.iconType === 'image'">
                                            <img :src="item.icons" alt="Option Image" style="margin-top: 12px;">
                                        </div>
                                        <p class="dropdownOptions">{{ item.label }}</p>
                                    </div>
                                </el-option>
                            </el-select>
                            <b-icon v-if="selectedOptionIcon && selectedOption.iconType === 'icon'" :icon="selectedOptionIcon" style="color: #777;" font-scale="1.3" class="absoluteIcon"></b-icon>
                            <img v-if="selectedOptionIcon && selectedOption.iconType === 'image'" :src="selectedOptionIcon" class="absoluteIcon">
                        </div>
                        <div class="flexColumn">
                            <el-input placeholder="Description" v-model="fieldData.description" @change="prepareFieldObjectToStore('description')"></el-input>
                        </div>
                    </div>
                </div>
                <div class="previewCont" v-else>
                    <div class= "imageVideoContainer" v-show="fieldData.files.length>0">
                        <div class="imageContainer" v-show="photoFiles.length>0">
                            <el-col v-for="(file,index) in photoFiles" :key="index">
                                <div style="display: flex; flex-direction: column; height:100%;">
                                    <p class="heading">{{file.file_info || file.label}}</p>
                                    <img class="images" :src="file.file_url || file.url" @click="viewImage($event)"/>
                                </div>
                            </el-col>
                        </div>
                        <div class="videoContainer" v-show="videoFiles.length>0">
                            <el-col v-for="(file,index) in videoFiles" :key="index">
                                <div style="display: flex; flex-direction: column; height:100%;">
                                    <p class="heading">{{file.file_info || file.label}}</p>
                                    <video  controls class="videos" :src="file.file_url || file.url">
                                        Your browser does not support HTML5 video.
                                    </video>
                                </div>
                            </el-col>
                        </div> 
                    </div>
                    <div class="gridCont">
                        <p class="heading">{{fieldData.label}}</p>
                        <div>
                            <p class="description" v-if="!complexQTypes.includes(fieldData.question_type)">
                                <b-icon v-if="fieldData.question_type == 'date' || fieldData.question_type == 'time'
                                || fieldData.question_type == 'phone_number' || fieldData.question_type == 'email'"
                                :icon="getQuestionIcon(fieldData.question_type)"
                                class="preview-icons">
                                </b-icon>
                                {{questionType(fieldData.question_type) +
                                (fieldData.question_type === 'paragraph' || fieldData.question_type === 'short_answer' ? ' Text' : '')}}
                            </p>
                            <div class="uploadContainer" v-if="fieldData.question_type == 'file_upload'">
                                <div class="uploadCont">
                                    <b-icon icon="upload" style="color: #777;"></b-icon>
                                    <p class="description">Upload File</p>
                                </div>
                                <div class="errorCont">
                                    <div class="fileType">
                                        <p style="white-space: nowrap;" v-for="fileType in fieldData.additional_info?.allowed_types" :key="fileType">
                                            {{fileType.charAt(0).toUpperCase() + fileType.slice(1)}}
                                        </p>
                                    </div>
                                    <p class="errorMsg">The maximum file size allowed for upload is 500MB.</p>
                                </div>
                            </div>
                        </div>
                        <p class="description">{{fieldData.description}}</p>
                        <!-- for question type multiple_choice/dropdown/checkbox -->
                        <div class="optionsContainer" v-if="complexQTypesWOFileUpload.includes(fieldData.question_type)">
                            <div v-for="(option,index) in fieldData.options" :key="index">
                                <p class="options preview-options-text" v-if="fieldData.question_type =='checkbox'">{{option.option_text}}</p>
                                <p class="preview-dropdown-text" v-if="fieldData.question_type =='dropdown'">
                                    <b-icon icon="caret-down-square" style="color: #777;margin-right:16px;" font-scale= "1.25"></b-icon>
                                    {{` ${option.option_text}`}}
                                </p>
                                <p class="preview-options-text subOptions" v-if="fieldData.question_type =='multiple_choice'
                                || fieldData.question_type=='single_choice' ">{{option.option_text}}</p>
                                <p v-if="fieldData.question_type === 'tab_choice'" class="preview-options-text">{{option.option_text}}</p>
                                <div class="subOptionsContainer" v-if="option.sub_options.length > 0">
                                    <div v-for="(sub_op,index) in option.sub_options" :key="index" style="padding-left:2.25rem">
                                        <p class="heading" style="font-size: 1rem;padding:0.5rem 0;">{{sub_op.label}}</p>
                                        <p class="description" style="font-size: 1rem;" v-if="!complexQTypes.includes(sub_op.question_type)">
                                            <b-icon v-if="sub_op.question_type == 'date' ||
                                            sub_op.question_type == 'time'|| sub_op.question_type == 'phone_number'|| sub_op.question_type == 'email'"
                                            :icon="getQuestionIcon(sub_op.question_type)"
                                            style="width:1rem;height:1rem"
                                            class="preview-icons"
                                            ></b-icon>
                                            {{questionType(sub_op.question_type) +
                                            (sub_op.question_type === 'paragraph' || sub_op.question_type === 'short_answer' ? ' Text' : '')}}
                                        </p>
                                        <div class="uploadContainer" v-if="sub_op.question_type == 'file_upload'">
                                            <div class="uploadCont">
                                                <b-icon icon="upload" style="color: #777;"></b-icon>
                                                <p class="description">Upload Photos</p>
                                            </div>
                                            <div class="errorCont">
                                                <div class="fileType">
                                                    <p v-for="subOpFileType in sub_op.additional_info?.allowed_types" :key="subOpFileType">
                                                        {{subOpFileType.charAt(0).toUpperCase() + subOpFileType.slice(1)}}
                                                    </p>
                                                </div>
                                                <p class="errorMsg">The maximum file size allowed for upload is 500MB.</p>
                                            </div>
                                        </div>
                                        <!-- For nested type question multiple_choice/dropdown/checkbox/tab_choice -->
                                        <div class="optionsContainer margin-top8" v-if="complexQTypesWOFileUpload.includes(sub_op.question_type)">
                                            <div v-for="(nested_op,index) in sub_op.options" :key="index" >
                                                <p class="options preview-options-text" style="font-size:1rem" v-if="sub_op.question_type =='checkbox'">{{nested_op.option_text}}</p>
                                                <p class="preview-dropdown-text" style="font-size:1rem" v-if="sub_op.question_type =='dropdown'">
                                                    <b-icon font-scale= "1.25" style="color: #777;margin-right:16px;" icon="caret-down-square"></b-icon>{{` ${nested_op.option_text}`}}
                                                </p>
                                                <p class="preview-dropdown-text subOptions" style="font-size:1rem"
                                                v-if="sub_op.question_type =='multiple_choice' || sub_op.question_type=='single_choice'">
                                                {{nested_op.option_text}}
                                                </p>
                                                <p class="preview-dropdown-text" v-if="sub_op.question_type === 'tab_choice'">{{nested_op.option_text}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="answersFiledContainer">
                    <AnswersFields 
                    v-if="!isPreviewMode" 
                    :isEditTemplateMode="isEditTemplateMode"
                    :fieldIndex="fieldIndex" 
                    :fieldData="fieldData" 
                    :sectionIndex="sectionIndex"
                    @patchTemplate="propagatePatchDataFromField"/>
                </div>
                <div class="cardFooter" v-if="!isPreviewMode">
                    <div class="flexCont">
                        <div class="iconsCont">
                            <el-tooltip class="item" effect="dark" content="Add Image" placement="top">
                                <img src="../assets/Image.svg" alt=""
                                :class="countOfImages3(fieldIndex-1, sectionIndex-1) || (fieldData.questionType === 'poi' || fieldData.question_type === 'boundary') ? 'icons disabled-icon':'icons'"
                                @click="addPhotoField"/>
                            </el-tooltip>
                            <el-tooltip class="item" effect="dark" content="Add Video" placement="top">
                                <img src="../assets/play-btn.svg" alt=""  
                                :class="countOfVideos3(fieldIndex-1, sectionIndex-1) || (fieldData.questionType === 'poi' || fieldData.question_type === 'boundary') ? 'icons line disabled-icon ':'icons line'"
                                @click="addVideoField"/>
                            </el-tooltip>
                        </div>
                        <hr class="hrTag"/>
                        <div class="flexSwitch">
                            <p class="switchTxt">Required</p>
                            <el-switch v-model="fieldData.is_required" @change="prepareFieldObjectToStore('is_required')">
                            </el-switch>
                        </div>
                        <hr class="hrTag"/>
                        <div class="iconsCont">
                            <i :class="fieldData.question_type === 'boundary' ? 'icon copy-alt disabled-icon':'icon copy-alt'" @click="duplicateCurrentField"></i>
                            <i class="icon delete-alt" @click="deleteCurrentField" :class="{ 'disabled-icon': fieldsLength === 1 }"></i>
                        </div>
                    </div>
                <input
                type="file"
                ref="fileInputImage"  
                style="display: none;"
                @change="uploadPhotoVideo"
                accept="image/*"
                multiple
                />
                <input
                type="file"
                ref="fileInputVideo"  
                style="display: none;"
                @change="uploadPhotoVideo"
                accept="video/*"
                multiple
                />
                </div>
            </div>
        </div>
    </div>
</template>
 
<script>
import { v4 } from "uuid";
import API from '@/services/api/';
import {CONTAINER_NAME} from '../../../constants';
import { viewImage } from "@/pages/utils/utils.js";
import debounce from "debounce";
import AnswersFields from "./answersFields.vue";
import { mapState, mapActions } from 'pinia';
import { useEditableSiteSurveyStore } from "../../../stores/editableSiteSurvey";
 
 
export default {
    emits: ["duplicateCurrentFile",  "deleteCurrentFile", "deleteCurrentField", "duplicateCurrentField", "patchTemplate" ],
    components: {
        AnswersFields,
    },
    props:{
        fieldIndex:{
            type:Number,
        },
        field:{
            type: Object,
        },
        fieldsLength:{
            type: Number,
        },
        sectionIndex:{
            type:Number,
        },
        templateId: {
            type: Number,
            required: false,
        },
    },
    data() {
        return {
            fieldData: this.$props.field,
            isPhoto: false,
            maxFilesImageVideo : 3,
            isFileLoading: false,
            photoFiles: [],
            videoFiles: [],
        };
    },
 
    created() {
        [this.photoFiles, this.videoFiles] = this.separatePhotoVideoFiles(this.fieldData.files);
    },
    computed: {
        filteredOptions() {
            return this.options.filter(item => this.canRenderQType(item));
        },
        selectedOption() {
            return this.options.find(option => option.value === this.fieldData.question_type) || {};
        },
 
        selectedOptionIcon() {
            return this.selectedOption.icons || null;
        },
        ...mapState(useEditableSiteSurveyStore, {
            formData: "GET_FORM_DATA",
            options: "GET_QUESTIONS_LIST",
            complexQTypes: "GET_COMPLEX_Q_TYPES",
            isPreviewMode : "GET_PREVIEW_FLAG",
            allowFieldPatchFlag: "GET_ALLOW_FIELD_PATCH_FLAG",
        }),
        complexQTypesWOFileUpload(){
            return this.complexQTypes.filter(type => type !== 'file_upload');
        },
        isEditTemplateMode(){
            // to determine the actual edit template mode
            return this.templateId !== 0 && !this.isPreviewMode;
        }
    },
 
    methods: {
        ...mapActions(useEditableSiteSurveyStore, {
            updateFieldInASection:"UPDATE_FIELD_IN_A_SECTION",
            boundaryHasBeenAdded:"BOUNDARY_HAS_BEEN_ADDED",
            lastBoundaryHasBeenRemoved: "LAST_BOUNDARY_HAS_BEEN_REMOVED",
            countOfBoundaryQ:"COUNT_OF_BOUNDARY_Q",
            countOfPOIQ:"COUNT_OF_POI_Q",
            countOfImages3: "COUNT_IMAGES3",
            countOfVideos3: "COUNT_VIDEOS3",
            addFileToNewFilesStatusArray: "ADD_FILE_TO_NEW_FILES_STATUS_ARRAY",
            incrementCountNewFilesToUpload:"INCREMENT_COUNT_NEW_FILES_TO_UPLOAD",
            setNewFilesUploadedFlagFalse : "SET_NEW_FILES_UPLOADED_FLAG_FALSE",
        }),
        viewImage,
        separatePhotoVideoFiles(filesArray){
            // Separate photos and videos
            const photos = [];
            const videos = [];

            if(filesArray.length > 0){
                filesArray.forEach(file => {
                    if (file.file_type.startsWith('image')) {
                        photos.push(file);
                    } else if (file.file_type.startsWith('video')) {
                        videos.push(file);
                    }
                });
            }
            return [photos, videos];
        },
        openFileInput() {
            // Programmatically click the hidden file input
            const fileInput = this.isPhoto? this.$refs.fileInputImage : this.$refs.fileInputVideo;
            fileInput.click();
 
            // Create and dispatch a custom event to trigger the @change event
            const event = new Event("change", { bubbles: true, cancelable: true });
            fileInput.dispatchEvent(event);
        },
        async uploadPhotoVideo(event) {
            // Handle the selected files here
            const files = event.target.files;
            if (files.length + this.fieldData.files.filter(f=> f.file_type === files[0]?.type).length > this.maxFilesImageVideo) {
                // Display an error message or take appropriate action
                this.$message({
                    showClose: true,
                    message: "Cannot upload more than 3 photos and 3 videos each.",
                    type: "error",
                    center: true
                });
                return;
            }
            this.setNewFilesUploadedFlagFalse();
            // Loop through the array and upload each file
            for (const file of files) {
 
                //check file type
                //carry on process only for image and video
                // Call the uploadFileToBlob function for each file
                //use uuid while uploading
                //create uuid
                if (file.size > 500 * 1024 * 1024) {
                    this.$message({
                        showClose: true,
                        message: "File size should be less than 500MB. Please try again",
                        type: "error",
                        center: true
                    });
                    continue; // Skip this file
                }
                this.isFileLoading= true;
                const uuidFile =  v4();
                let fileObj = {
                    file_name:uuidFile + file.type.split('/')[1],
                    url:undefined,
                    file_type:file.type,
                    file_info: '',
                }
                this.fieldData.files.push(fileObj);
                this.incrementCountNewFilesToUpload();
                try {
                    const response = await Promise.all([
                        this.uploadFileToBlob(file, uuidFile),
                    ]);
                    fileObj.url = response[0];
                    this.addFileToNewFilesStatusArray({file_url : response[0]});
                    this.prepareFieldObjectToStore('files');
                } catch (error) {
                    console.error('Error uploading file:', error);
                } finally{
                    this.isFileLoading = false;
                }
            }
            // Clear the file input field after processing the files
            event.target.value = null; // This will clear the selected files
        },
        async uploadFileToBlob(file,refId){
            const accountName = "downloadstsl"; // same
            const fileName = refId + file.type.split('/')[1];
 
            let containerName = CONTAINER_NAME;
            const postData = {
                "container": CONTAINER_NAME, 
            }
            const response_Azure_sas = await API.AZURE_SAS_TOKEN.FETCH_AND_SET_SAS_TOKEN(postData);
            const sasToken = response_Azure_sas.data['sas_token'];
            const headers = {
                'x-ms-blob-type': 'BlockBlob',
                'Content-Type': file.type,
            };
            
            const url = `https://${accountName}.blob.core.windows.net/${containerName}/${fileName}?${sasToken}`;
            const response = await fetch(url,
                {
                    method: 'PUT',
                    headers: headers,
                    body: file,
                });
            let downloadUrl = response.url.split('?');
            return downloadUrl[0];
        },
        addPhotoField(){
            this.isPhoto = true;
            this.openFileInput();
        },
        addVideoField(){
            this.isPhoto = false;
            this.openFileInput();
        },
        duplicateCurrentFile(fileIndex){
            this.$emit("duplicateCurrentFile", [fileIndex, this.fieldIndex -1]);
        },
        deleteCurrentFile(fileIndex){
            this.$emit("deleteCurrentFile", [fileIndex, this.fieldIndex -1]);
        },
        deleteCurrentField(){
            this.$emit("deleteCurrentField", this.fieldIndex -1);
        },
        duplicateCurrentField(){
            this.$emit("duplicateCurrentField", this.fieldIndex-1);
        },
        prepareFieldObjectToStore(propertyName, fileIndex){
             if(this.isEditTemplateMode){
                const validProperties = ["label", "description", "question_type"];
                let fieldBody = {
                    [propertyName]: validProperties.includes(propertyName) ? this.fieldData[propertyName]?.trim() : this.fieldData[propertyName],
                    isFieldLevel: true,
                    id: this.fieldData.id,

                };
                this.$emit("patchTemplate",fieldBody);
            }
            else{
                this.updateFieldInASection(this.sectionIndex -1, this.fieldIndex -1, this.fieldData);  
            }
        },
        propagatePatchDataFromField(fieldBody){
            if(this.isEditTemplateMode){
                //fieldBody.template_section = this.sectionData.id;
                this.$emit("patchTemplate",fieldBody);
            }
        },
        canRenderQType(subQuestion){
            if(subQuestion.value === 'poi'){
                if(this.formData.boundaryAdded === true && this.formData.lastBoundaryRemoved === false)
                    return true;
                else
                    return false;
            }
            else return true;
        },
        questionType(question_type) {
            const currentOption = this.options.find(option => option.value === question_type);
            return currentOption ? currentOption.label : '';
        },
        getQuestionIcon(questionType) {
            const matchingOption = this.options.find(option => option.value === questionType);
            return matchingOption ? matchingOption.icons : ''; // Return the icon based on the matching option
        },
    },
    watch: {
    'fieldData.question_type': {
        handler: debounce(function(newQuestionType, oldQuestionType) {
            if(newQuestionType !== oldQuestionType  && this.allowFieldPatchFlag){
                // commented out from condition above because creating new boundary field was not setting 
                // the required flag to allow POI as an option
                // && oldQuestionType !== undefined
                if(!this.isPreviewMode){
                    if (newQuestionType === 'dropdown' || newQuestionType === 'multiple_choice' || newQuestionType === 'checkbox') {
                        this.fieldData.options = [];
                    }
                    if(newQuestionType === 'boundary'){
                        //check if other fields exist in the section
                        //give a prompt and stop the change
                        const foundOthers = this.formData.sections[this.sectionIndex -1]?.fields.find(field => field.question_type !== 'boundary');
                        if(foundOthers){
                            this.fieldData.question_type = oldQuestionType;
                            this.$message({
                                showClose: true,
                                message: "Cannot add boundary to this section. Please add a new section and try again.",
                                type: "error",
                                center: true
                            });
                        }
                        //if there is already one boundary in the form, do not allow the addition
                        if(this.countOfBoundaryQ() > 1){
                            this.fieldData.question_type = oldQuestionType;
                            this.$message({
                                showClose: true,
                                message: "Cannot add more than one boundary section in the form.",
                                type: "error",
                                center: true
                            });
                        }
                        else{
                            //set a key as true
                            this.boundaryHasBeenAdded();
                        }
                    }
                    if(oldQuestionType === 'boundary'){
                        //iterate through the template and find at least one more boundary
                        // if present leave key as true
                        // if absent, then make key false
                        if(this.countOfBoundaryQ() === 0){
                            //check if there are any dependent POI fields
                            if(this.countOfPOIQ() === 0){
                                this.lastBoundaryHasBeenRemoved();
                            }
                            else{
                                //cannot remove last boundary
                                //show error message and reset the Qtype back to boundary
                                this.$message({
                                    showClose: true,
                                    message: "Cannot remove boundary. Please remove dependent POIs and try again.",
                                    type: "error",
                                    center: true
                                });
                                this.fieldData.question_type = 'boundary';
                            }
                        }
                        //else nothing
                    }
                    this.prepareFieldObjectToStore('question_type');
                }
            }  
        }, 300), // Adjust the delay (in milliseconds) as needed
        //immediate: true, // Trigger the handler immediately on component creation
        //an attempt to stop the redundant field patches when the form first opens in edit mode
        immediate: false,
    },
    field: {
        immediate: true,
        handler(newVal) {
            this.fieldData = newVal;
            [this.photoFiles, this.videoFiles] = this.separatePhotoVideoFiles(this.fieldData.files);
        },
    },
    isPreviewMode:{
        immediate:true,
        handler(newValue){
            [this.photoFiles, this.videoFiles] = this.separatePhotoVideoFiles(this.fieldData.files);
        }
    }
  },
};
</script>
 
<style scoped>
.flexCardContainer {
    display: flex;
    gap: 8px;
    align-items: flex-start;
}
 
.cardBorder {
    width: 100%;
    border-radius: 8px;
    border: 1px solid #CCC;
    padding: 24px;
}
 
.gridCont {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
}
 
.flexCardContainer>>>.el-radio__label,
.switchTxt,
.dropdownOptions {
    font-size: 16px;
    color: #222;
}
 
.flexCardContainer>>>.el-radio__inner {
    border: 2px solid #777;
    width: 22px;
    height: 22px;
    background-color: #FFFFFF;
}
 
.flexCardContainer>>>.el-radio__input.is-checked .el-radio__inner {
    border-color: #409EFF;
    background: #fff;
}
 
.flexCardContainer>>>.el-radio__inner::after {
    background-color: #409EFF;
    width: 10px;
    height: 10px;
}
 
.dropdownClass >>> .el-input__inner {
    background-color: #fff !important;
    border: 1px solid #CCC !important;
    padding-left: 48px;
}
 
.dropdownClass {
    position: relative;
}
 
.absoluteIcon {
    position: absolute;
    top: 14px;
    left: 16px;
}
 
.icon {
    font-size: 20px;
    cursor: pointer;
}
 
.cardFooter {
    display: flex;
    justify-content: flex-end;
    /*justify-content: space-between;*/
    padding: 24px 0px 0px 0px;
    margin-top: 24px;
    border-top: 1px solid #ccc;
}
 
.flexCont,
.flexSwitch,
.iconsCont,
.flexOptions {
    display: flex;
    gap: 24px;
    align-items: center;
}
 
.flexSwitch,
.flexOptions {
    gap: 16px;
}
 
.iconsCont {
    gap: 16px;
}
 
.hrTag {
    height: 16px;
}
 
.delete-alt {
    color: #D03606;
    font-weight: bold;
}
 
.previewCont {
    row-gap: 16px;
}
 
.heading {
    font-size: 20px;
    color: #000;
}
 
.description {
    font-size: 20px;
    color: #777;
    display: flex;
    align-items: center;
}
 
.uploadCont {
    display: flex;
    align-items: center;
    gap: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 9px 16px;
}
 
.errorCont {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 16px;
    margin-top: 8px;
}
 
.fileType,
.errorMsg {
    font-size: 12px;
    word-wrap: break-word;
}
 
.fileType {
    color: #777;
    min-width: 3rem;
}
 
.errorMsg {
    color: red;
}
 
.optionsContainer,
.subOptionsContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
 
.options {
    font-size: 16px;
    color: #222;
    display: flex;
    align-items: center;
    gap: 8px;
}
 
.options::before,
.subOptions::before {
    content: "";
    background: url('');
    min-width: 18px;
    min-height: 18px;
    border: 2px solid #777;
    border-radius: 4px;
    display: block;
}
 
.subOptions::before {
    border-radius: 50%;
}
 
.subOptions {
    font-size: 14px;
    color: #222;
    margin-left: 16px;
    display: flex;
    align-items: center;
    gap: 1rem;
}
 
.preview-options-text{
    color: var(--tsl-foreground, var(--222, #222));
    font-size: 1rem;
    line-height: 160%; /* 25.6px */
}
 
.preview-dropdown-text{
    color: var(--TSL-Foreground, var(--222, #222));
    font-size: 1rem;
    line-height: 1rem; /* 100% */
    display: flex;
    align-items: center;
}
 
.preview-icons{
    color: #777;
    margin-right:1rem;
    width:1.25rem;
    height:1.25rem;
}
 
.icons {
    width: 20px;
    font-size: 20px;
    cursor: pointer;
}
 
.photo-video-section{
    width:100%;
    display: flex;
    flex-direction: column;
    gap:1.5rem;
}
 
.grid-parent{
   
}
 
.photo-video-container{
    width: 100%;
    display: flex;
    align-items: flex-start;
    position: relative;
}
 
.crossIcon {
  position: absolute;
  top: -7px;
  right: -7px;
  cursor: pointer;
}
 
.videos, .images{
    width: 100%;
    max-height: 40.812rem;
}
 
.disable-pointer-events {
  pointer-events: none;
}
 
.margin-top8{
    margin-top:0.5rem;
}

.imageVideoContainer{
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.imageContainer, .videoContainer {
    flex: 1;
    display: flex;
    gap: 16px;
    overflow-x: auto; /* Enable horizontal scrolling if items exceed container width */
}

.imageVideoContainer .heading{
    margin-bottom: 0.625rem;
}

.imageContainer img, .videoContainer video {
    flex: 1; /* Distribute available space equally among items */
    height: 100%;
}
.imageContainer img{
    cursor: pointer;
}
.disabled-icon {
    opacity: 0.5; /* or any other styling to visually disable the icon */
    pointer-events: none; /* prevent interaction with the icon */
}

@media(max-width: 767px) {

    .imageContainer, .videoContainer {
        display: flex;
        flex-flow: row wrap;
        gap: 16px;   
    }
}
</style>