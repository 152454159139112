
<div class='parent-container'>

    <div>
    <GlobalDialog dialogTitle="Preview" :onClickClose="false" :dialogVisible.sync="openContractList" 
      @handleClose="closeDialog" :width="'750px'" :height="'calc(100vh - 300px)'" :isModal="false" :isModalAppend="false">
      <template v-if="selectedContracts.length" v-slot:body>
        <div
        >
            <ChangeOrderPreview v-if="openPreview" 
    :open.sync="openPreview"
    :editorType="editorType"
    :isContractInDesignLevel="isContractInDesignLevel"
    :includePaymentSchedule="includePaymentSchedule"
  />
        </div>
  
      </template>
      <template v-else v-slot:body>
        No contract selected!
      </template>
      <template v-slot:footer>
        <div class="footer">
          <el-button plain @click="backtoCustomContract">
            Back
          </el-button>
          <el-tooltip
            class="item"
            effect="dark"
            :content=tooltipMessageForShareButton
            placement="top"
            :disabled="!(isFileBeingPrepared || !selectedContracts.length)"
          >
            <span>
              <el-button class="footer-btn" 
                :loading="isFileBeingPrepared || !selectedContracts.length"
                :disabled="isFileBeingPrepared || !selectedContracts.length" 
                type="primary" 
                @click="openMailDilaog"
              >
                Share
              </el-button>
            </span>
          </el-tooltip>

        </div>
      </template>
    </GlobalDialog>
  </div>
  <!-- <ContractPreview v-if="openPreview" 
    :open.sync="openPreview"
    :editorType="editorType"
    :isContractInDesignLevel="isContractInDesignLevel"
    :includePaymentSchedule="includePaymentSchedule"
  /> -->
  <EditTemplateEditorDialog v-if="openMailTemplate" :defaultUser="leadMailID" :subject="templateSubject"
    :content="mailContent" :title="templateTitle" :dialogLoading="mailLoading"
    :handleClearEmailData="handleClearEmailData" :fileData="fileData" :handleSendInvoice="handleSendContract"
    :isEmailTemplateForContractManagement="true"
    :showCC="true"
    @emitFinalPayload="sendContractWithoutDocusign"
    :isModalAppend="false"
    />

  <ConfirmationForDocusignPopup 
    v-if="isConfirmationForDocusignPopupVisible"
    :isConfirmationForDocusignPopupVisible.sync="isConfirmationForDocusignPopupVisible"
    @proceedBtnFunc="proceedBtnFunc"
    :isModal="false"
  />
  <ClientInformationPopup 
    v-if="isClientInformationPopupVisible" 
    :isClientInformationPopupVisible.sync = "isClientInformationPopupVisible"
    :placeholder="selectedContracts"
    @sendBtnFunc="sendBtnFunc"
    :isModal="false"
  />
  <EditContractPopup 
    v-if="isEditContractPopupVisible" 
    :isEditContractPopupVisible.sync="isEditContractPopupVisible"
    :selectedContracts="selectedContracts" 
    @closeEditContractPopup="closeEditContractPopup"
    :isModal="false"
    />
  <StatusPopup 
    v-if="isStatusPopupVisible" 
    :isStatusPopupVisible.sync="isStatusPopupVisible"
    @checkStatus="checkStatus"
    />
</div>
