//define MIME type

export default{
    "image/png":"pngFile.png",
    "image/x-dxf":"cadFile.png",
    "image/jpeg":"jpgFile.png",
    "image/jpg": "jpgFile.png",
    "pdf":"pdfFile.png",
    "application/pdf":"pdfFile.png",
    "text/plain":"txtFile.png",

    "application/vnd.openxmlformats-officedocument.presentationml.presentation":"pptFile.png",
    "application/vnd.ms-powerpoint":"pptFile.png",
    "application/vnd.ms-powerpoint.presentation.macroEnabled.12":"pptFile.png",
 
    "video/mp4": "multimedia.svg", 
    "video/avi": "multimedia.svg", 
    "video/flv": "multimedia.svg", 
    "video/3gp": "multimedia.svg", 
    "video/mov": "multimedia.svg",
    "text/csv":"docFile.png",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":"xlsxFile.png",
    "application/vnd.ms-excel": "xlsxFile.png",
    "xlsx": "xlsxFile.png",
    "defaultFile": "defaultFile.png",
    "docx": "docFile.png",
    "application/doc":"docFile.png",
    "application/ms-doc":"docFile.png",
    "application/msword":"docFile.png",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document":"docFile.png",

	// These have been added here because
	// file mimetype data is not coming for site survey documents from backend.
	// It only says "image" or "video".
    "image": "jpgFile.png",
    "video": "multimedia.svg",
};