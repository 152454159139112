
    <div class="parentContainer">
        <el-dialog :visible="isClientInformationPopupVisible" :close-on-click-modal="false" title="Send Document"
            @close="onDialogClose" :show-close="!disableCloseIcon" :modal-append-to-body="isModal">
            <div class="container">
                <el-form ref="form" :model="form" @submit.native.prevent class="formContainer">
                    <div>
                        <p class="font14Bold">
                            First Client Name <span v-if="showFirstRow" class="asterisk">*</span>
                        </p>
                        <el-input 
                            v-model="form.firstClientName"
                            v-validate="requiredValidationForFirst"
                            name="first_client_name"
                            placeholder="Enter Client Name"
                            />
                                <p
                                    v-show="errors.has('first_client_name')"
                                    class="errorMsg"
                                   >
                                   This field is required.
                                </p>
                    </div>
                    <div>
                        <p class="font14Bold">
                            First Client Email <span v-if="showFirstRow" class="asterisk">*</span>
                        </p>
                        <el-input 
                            v-model="form.firstClientEmail"
                            v-validate="requiredValidationForFirstEmail"
                            name="first_client_email"
                            placeholder="Enter Client Email"
                            />
                            <p 
                             v-show="errors.has('first_client_email')"
                            class="errorMsg"
                             >
                            Invalid email id
                            </p>
                    </div>
                    <div>
                        <p class="font14Bold">
                            Second Client Name <span v-if="showSecondRow" class="asterisk">*</span>
                        </p>
                        <el-input
                             v-model="form.secondClientName"
                            v-validate="requiredValidationForSecond"
                            name="second_client_name"
                            placeholder="Enter Client Name"
                            />
                            <p 
                                v-show="errors.has('second_client_name')"
                                class="errorMsg"
                                >
                                This field is required.
                            </p>
                    </div>
                    <div>
                        <p class="font14Bold">
                            Second Client Email <span v-if="showSecondRow" class="asterisk">*</span>
                        </p>
                        <el-input 
                            v-model="form.secondClientEmail"
                            v-validate="requiredValidationForSecondEmail"
                            name="second_client_email"
                            placeholder="Enter Client Email"
                            />
                            <p 
                            v-show="errors.has('second_client_email')"
                            class="errorMsg"
                             >
														 Invalid email id
                            </p>
                    </div> 
                    <div >
                        <p class="font14Bold">
                            Third Client Name <span v-if="showThirdRow" class="asterisk">*</span>
                        </p>
                        <el-input 
                            v-model="form.thirdClientName" 
                            v-validate="requiredValidationForThird"
                            name="third_client_name"
                            placeholder="Enter Client Name"
                            />

                            <p
                             v-show="errors.has('third_client_name')"
                            class="errorMsg">
                            This field is required.
                        </p>
                    </div>
                    <div>
                        <p class="font14Bold">
                            Third Client Email <span v-if="showThirdRow" class="asterisk">*</span>
                        </p>
                        <el-input
                            v-model="form.thirdClientEmail"
                            v-validate="requiredValidationForThirdEmail"
                            name="third_client_email"
                             placeholder="Enter Client Email"
                             />
                            <p
                            v-show="errors.has('third_client_email')"
                             class="errorMsg" 
                            >
                            Invalid email id
                        </p>
                    </div>
                    <div>
                        <p class="font14Bold">
                            Envelope Name <span class="asterisk">*</span>
                        </p>
                        <el-input
                            v-model="form.envelopeName"
                            v-validate="requiredValidation"
                             name="envelope"
                            placeholder="Enter Envelope Name" />
                            <p 
                            v-show="errors.has('envelope')"
                            class="errorMsg"
                             >
                             This field is required.
                            </p>
                    </div>
                    <div>
                        <p class="font14Bold">
                            CC to
                        </p>
                        <el-input 
                            v-model="form.cc" 
                            v-validate="emailValidation"
                            name="cc_to"
                            placeholder="Enter Email"
                            />
                            <p 
                            v-show="errors.has('cc_to')"
                            class="errorMsg" 
                            >
														Invalid email id
                        </p>
                    </div>
                </el-form>
                <div class="btnContainer">
                    <el-button
                     type="" 
                    class="addFAQBtn"
                     @click="onDialogClose()" :disabled='isCancelBtnDisabled'>
                     Cancel
                    </el-button>
                    <el-tooltip
                        class="item"
                        :disabled="!disableSendDocument"
                        effect="dark"
                        :content="tooltipContent"
                        placement="top-start"
                    >
                        <el-button 
                            :loading="isLoading"
                            type="primary" 
                            class="addFAQBtn" 
                            :disabled="disableSendDocument"
                            @click="sendBtn()"  
                        >
                            Send
                        </el-button>
                    </el-tooltip>    
                </div>
            </div>
        </el-dialog>
    </div>
