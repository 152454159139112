<template>
  <div id="dashboard">
    <GlobalPageHeader>
      <template v-slot:children>
        <div>
          <div class="heading-container">
            <div class="cardFilter">
              <popoverSelect
                :dashboardsData.sync="dashboardsData"
                :selecteddashboardData="selecteddashboardData"
                @selected-dashboard="getSelectedDashBoard"
                @update-dashboard="updateDashboard"
                @dashboardChanged="handleDashboardChange"
                :currentDashboardId="currentDashboardId"
                @load-more="loadMoreData"
                :editMode.sync="editMode"
                @get-dashboard="getdashboards"
              />
              <!-- <el-tooltip content="Edit">
                <span
                  v-if="!isMobile && selecteddashboardData?.id"
                  class="pencil"
                  @click="handleEdit"
                  >
                  <img src="./assests/pencil.svg" class="pencil-class"
                /></span>
              </el-tooltip> -->
            </div>

            <!-- <div class="heading" >
              <div style="display: flex; align-items: center">
                <el-tooltip content="Back to Dashboard">
                  <b-icon
                    :icon="'arrow-left'"
                    class="arrow-icon-left"
                    style="
                      margin-right: 5px;
                      font-size: 1rem;
                      font-weight: 800;
                      cursor: pointer;
                    "
                    @click="handleBack(selecteddashboardData)"
                  ></b-icon>
                </el-tooltip>

                <p style="color: #1c3366; font-size: 20px; font-weight: 600">
                  {{ selecteddashboardData.name }}
                  <span class="icons-content">
                    <el-tooltip content="Edit">
                      <b-icon :icon="'pencil'"></b-icon
                    ></el-tooltip>
                  </span>
                </p>
              </div>
              <div>
                <el-button
                  type="text"
                  @click="handleCreateWidget()"
                  style="
                    border: 1px solid #409eff;
                    padding-left: 1rem;
                    padding-right: 1rem;
                  "
                  >Add Widget</el-button
                >
                <el-button type="primary">Save</el-button>
              </div>
            </div> -->
          </div>
          <div class="dashboardContainer">
            <div  v-if="!isEmpty" v-loading="isDashboardData" class="fullWidth">
              <!-- edit toogle -->
              <!-- <div>
              <el-switch
                v-model="isEditMode"
                active-color="#13ce66"
                inactive-color="#ff4949"
              >
              </el-switch>
            </div> -->
              <div v-if="dashboardsData.length && !isEmpty" class="fullWidth">
                <!-- <div class="cardFilter">
                  <popoverSelect
                    :dashboardsData.sync="dashboardsData"
                    @selected-dashboard="getSelectedDashBoard"
                    @update-dashboard="updateDashboard"
                    @dashboardChanged="handleDashboardChange"
                    :currentDashboardId="currentDashboardId"
                    @load-more="loadMoreData"
                    :editMode.sync="editMode"
                  />
                  <el-tooltip content="Edit">
                    <span class="pencil" @click="handleEdit"
                      ><img src="./assests/pencil.svg" class="pencil-class"
                    /></span>
                  </el-tooltip>
                </div> -->

                <div
                  v-if="selectedDashboardLayoutData.length"
                  v-loading="isWidgetsData"
                  class="fullWidth"
                >
                  <grid-layout
                    :layout.sync="selectedDashboardLayoutData"
                    :row-height="100"
                    :is-draggable="false"
                    :is-resizable="false"
                    :is-mirrored="false"
                    :vertical-compact="true"
                    :margin="[10, 10]"
                    :use-css-transforms="false"
                    :auto-size="false"
                    :prevent-collision="false"
                    :responsive="true"
                    :cols="{ lg: 4, md: 2, sm: 2, xs: 1, xxs: 1 }"
                  >
                    <grid-item
                      v-for="item in selectedDashboardLayoutData"
                      class="grid-item"
                      :x="item.x"
                      :y="item.y"
                      :w="item.w"
                      :h="item.h"
                      :i="item.i"
                      :minW="item.minW"
                      :minH="item.minH"
                      :maxW="item.maxW"
                      :maxH="item.maxH"
                      :key="item.i"
                    >
                    <div class="graph-container"    v-if="item.type === 'KPI'">
                      <CardComponentCRM
                     
                        :id="item.i"
                        :unit="'dollar'"
                        @drilldown-kpi="handleDrilldownOpen"
                        :editMode="false"
                        @mode-open="handleOpenMode"
                        :updateCheck.sync="updateCheck"
                      ></CardComponentCRM>
                    </div>
                    <div class="graph-container"     v-else-if="item.type === 'Table'">
                      <LeadsTableCRM
                      
                        :data="[]"
                        :id="item.i"
                        :headingText="'LeadsTable'"
                        :dropDown="false"
                        :pageRedirectFlag="'dashboard'"
                        :chartSource="chartSource"
                        @handleRowClick="handleRowClick"
                        @showDrawer="showDrawer"
                        :editMode="false"
                        @mode-open="handleOpenMode"
                        :updateCheck.sync="updateCheck"
                      />
</div>
<div class="graph-container"       v-else>
                      <AllChart
                     
                        :id="item.i"
                        :type="item.type"
                        :isMap="item.type == 'Map'"
                        @click-drilldown="handleDrilldownOpen"
                        :widgetsData="selectedDashboardLayoutData"
                        :dashboardId="dashboardId"
                        :updateWidgetData.sync="updateWidgetData"
                        :updateCheck.sync="updateCheck"
                        :editMode="false"
                        @mode-open="handleOpenMode"
                      />
                      </div>
                    </grid-item>
                  </grid-layout>
                </div>
                <div v-else-if="editMode">
                  <AddNewWidgetspage @handle-create="handleCreateWidget" />
                </div>
                <div v-else>
                  <el-row
                    :gutter="20"
                    class="card-row nodata"
                    style="margin-bottom: 20px"
                  >
                    No Widgets Available
                  </el-row>
                </div>
                <GlobalDialog
                  :dialogVisible.sync="open"
                  @handleClose="closeDialog"
                  :width="'70%'"
                  :isDrilldown="open"
                  v-if="
                    chartSource === 'Tasks' ||
                    chartSource === 'Leads' ||
                    chartSource === 'Activities'
                  "
                >
                  <template v-slot:body>
                    <div class="dialog-body">
                      <LeadManagement
                        v-if="
                          chartSource === 'Leads' ||
                          chartSource === 'Activities'
                        "
                        :isDrilldown.sync="open"
                        :drillDownUrl.sync="drillDownUrl"
                        :widgetId="widgetId"
                        @csv-download="downloadCsv"
                        @handleClose="closeDialog"
                        @updateWidget="updateWidget"
                      />
                      <TaskMangment
                        :isDrilldown.sync="open"
                        :drillDownUrl.sync="drillDownUrl"
                        :widgetId="widgetId"
                        @csv-download="downloadCsv"
                        v-if="chartSource === 'Tasks'"
                        @handleClose="closeDialog"
                        @updateWidget="updateWidget"
                      />
                    </div>
                  </template>
                  <template v-slot:footer>
                    <div></div>
                  </template>
                </GlobalDialog>
              </div>
              <div
                v-else
                :style="{
                  height:
                    !dashboardsData.length > 0 && !isEmpty
                      ? 'calc(100vh - 250px)'
                      : 0,
                }"
              >
                <!-- <div>
              <NoDataOverView :infoData="'No Dashboard Data Found.'" />
            </div> -->
              </div>
            </div>
            <div v-else>
              <div>
                <NoDataOverView :infoData="'No Dashboard Data Found.'" />
              </div>
            </div>
          </div>

          <leadsDrawer
            v-if="isDrawerOpen"
            :drawer.sync="isDrawerOpen"
            :lead="selectedLead"
            :activeTab="'first'"
            :focusNote="true"
            @close="[(isDrawerOpen = false), (selectedLead = null)]"
            :widgetId="widgetId"
            :isDrilldown="isDrawerOpen"
          />
          <GlobalDrawer
            :title="'View Task'"
            :isOpen="isTaskDrawerOpen"
            :drawer-size="750"
            :handleCloseDialog="handleTaskDrawerClose"
            class="task-drawer-dialog"
          >
            <template v-if="isTaskDrawerOpen" v-slot:body>
              <taskDrawer
                v-if="isTaskDrawerOpen"
                :reminderData.sync="reminderDetails"
                @updateTask="updateTask"
                :fieldsArray="returnFieldsForCreateTask()"
                :mode="'view'"
                :taskData="selectedLead"
                :avatars="selectedAvatars"
                @loadMore="debounceCollaboratorLoadMore"
                :widgetId="widgetId"
                @allowDocumentUpload="handleAllowDocUpload"
                :documentUploaded="documentUploaded"
                @documentUploading="documentUploaded = false"
              />
            </template>
          </GlobalDrawer>

          <!-- Create Edit delete Widgets -->
          <GlobalDrawer
            :isOpen="drawerOpen"
            :handleCloseDialog="handleShowOrHideDrawer"
            :drawer-size="750"
            class="task-drawer-dialog"
          >
            <template v-if="drawerOpen" v-slot:header>
              <div class="drawerheader">
                <p>{{ drawerTitle }}</p>
                <!-- <p class="cross" @click="handleShowOrHideDrawer">
                  <b-icon :icon="'x'"></b-icon>
                </p> -->
              </div>
            </template>
            <template v-if="drawerOpen" v-slot:body>
              <div class="drawer-body">
                <CreateEditDrawer
                  :drawerData.sync="drawerData"
                  :widgetId="activeWidgetId"
                  :isCopyDrawer="isCopyDrawer"
                  :selectedChartType="selectedChartType"
                />
              </div>
            </template>
            <template v-if="drawerOpen" v-slot:pinned-footer>
              <div class="footer">
                <el-button @click="handleShowOrHideDrawer">Cancel</el-button>
                <el-button
                  :disabled="isDisabled"
                  type="primary"
                  @click="addWidget"
                  >{{ drawerBtn }}</el-button
                >
              </div>
            </template>
          </GlobalDrawer>
          <GlobalDialog
            dialogTitle="Edit Dashboard"
            :dialogVisible.sync="openDash"
            @handleClose="closeDialog"
            :width="'40%'"
          >
            <template v-slot:bodyya>
              <div class="dialogbody">
                <p class="label">Name</p>
                <el-input
                  v-model="selectedDashboard"
                  :placeholder="'Enter Dashboard Name'"
                />
              </div>
            </template>
            <template v-slot:footer>
              <div class="alignment">
                <el-button type="primary" @click="updateDashboard"
                  >Update</el-button
                >
              </div>
            </template>
          </GlobalDialog>

          <DeleteDialog
            :dialogVisible="deleteOpen"
            :footerButtonType="'primary'"
            :footerButtonText="'Yes'"
            :disableCancel="true"
            :width="'20%'"
            :deleteMessage="'Are you sure you want to delete this widget?'"
            @handleClick="handleDelete"
            @handleClose="deleteOpen = false"
          >
          </DeleteDialog>
        </div>
      </template>
    </GlobalPageHeader>
  </div>
</template>
<script>
import GlobalPageHeader from "../../../../components/ui/GlobalPageHeader/globalPageHeader.vue";
import AllChart from "./AllChart.vue";
import CardComponentCRM from "../CardComponentCRM.vue";
import popoverSelect from "../popoverSelect.vue";
import { generateColorHex } from "../../../../utils/colorGenerator";
import GlobalDialog from "../../../commonComponents/GlobalDialog.vue";
import LeadManagement from "../../../leadManagement/components/leadManagement.vue";
import TaskMangment from "../../../taskManagement/components/taskKanbanView.vue";
import API from "../../../../services/api";
import LeadsTableCRM from "../LeadsTableCrm.vue";
import GlobalDrawer from "../../../commonComponents/allDrawer/globalDrawer.vue";
import leadsDrawer from "../../../leadManagement/components/leadsDrawer.vue";
import taskDrawer from "../../../taskManagement/components/taskDrawerDynamic.vue";
import NoDataOverView from "../../../../components/ui/noDataOverView.vue";
import debounce from "debounce";
import CreateEditDrawer from "./createEditDrawer.vue";
import DeleteDialog from "./deleteDialog.vue";
import AddNewWidgetspage from "./addNewWidgetspage.vue";
import VueGridLayout from "vue-grid-layout";

const defaultKpiDimensions = {
  w: 1,
  h: 1,
  maxW: 1,
  minW: 1,
  maxH: 1,
  minH: 1,
}
const defaultGraphDimensions = {
  w: 2,
  h: 5,
  maxW: 4,
  minW: 2,
  minH: 5,
  maxH: 7,
}
const defaultTableDimensions = {
  w: 2,
  h: 5,
  maxW: 4,
  minW: 2,
  minH: 4,
  maxH: 12,
}

export default {
  components: {
    AllChart,
    GlobalPageHeader,
    popoverSelect,
    GlobalDialog,
    LeadManagement,
    TaskMangment,
    CardComponentCRM,
    LeadsTableCRM,
    GlobalDrawer,
    leadsDrawer,
    taskDrawer,
    NoDataOverView,
    CreateEditDrawer,
    DeleteDialog,
    AddNewWidgetspage,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
  },
  data() {
    return {
      isEditMode: false,
      editMode: false,
      documentUploaded: false,
      allowUpload: false,
      currentDashboardId: null,
      homeOwnerResponse: {},
      nextUrlDash: "",
      selectedAvatars: [],
      avatars: [],
      homeOwnerScroll: {},
      homeownerNames: [],
      homeownerSources: "",
      homeownerSeperator: "",
      reminderDetails: {},
      setKey: 0,
      showSetReminderDrawer: false,
      selectedLead: null,
      isTaskDrawerOpen: false,
      isDrawerOpen: false,
      kpiArr: [],
      filteredWidgets: [],
      open: false,
      staredDashboard: "",
      dashboardsData: [],
      selecteddashboardData: {},
      selectedDashboardLayoutData: [],
      widgetData: [],
      chartSource: "",
      widgetId: "",
      isDashboardData: false,
      apiResponses: {
        id: 1,
        name: "d1",
        is_starred: true,
        layout: {},
        widgets: [
          // { id: 44, type: "KPI" },
          // { id: 21, type: "KPI" },
          // { id: 30, type: "Column" },
          { id: 38, type: "Bar" },
          // { id: 40, type: "Donut" },
          // { id: 42, type: "Map" },
          // { id: 41, type: "Trend Line" },
          // { id: 34, type: "Column" },
          // { id: 49, type: "Column" },
          // { id: 75, type: "Table" },
        ],
      },
      mapData: [],
      chartData: [],
      cardData: [],
      leadsTable: [],
      drillDownUrl: "",
      updateWidgetData: "",
      updateCheck: false,
      isEmpty: false,
      csvColumnsLead: [],
      csvColumnsTask: [],

      drawerOpen: false,
      createTaskHeading: "Add Widget",
      selectedDashboard: "",
      dashboardName: "",
      deleteOpen: false,
      activeWidgetId: "",
      drawerData: {},
      refreshData: false,
      drawerTitle: "Add Widget",
      isEditDrawer: false,
      isCopyDrawer: false,
      isSaveEnabled: false,
      selectedChartType: "",
      isDisabled: false,
      drawerBtn: "Add Widget",
      isMobile: false,
      dashboardId: null,
      openDash:false,
    };
  },

  methods: {
 
    returnFieldsForCreateTask(){

    },
    handleEditDashboard() {
      this.open = true;
    },
    async updateDashboard(dashboardData,is_starred) {
      try {
        const id = dashboardData?.id;
        const patchData = {
          // name: this.selectedDashboard,
          is_starred,
        };
        const { data } = API.CRM_DASHBOARD.UPDATE_DASHBOARD_BY_ID(
          id,
          is_starred
        );
        this.dashboardName = this.selectedDashboard;
        let tempDefaultDash=dashboardData;
        tempDefaultDash.is_starred=true;
      let tempList=  this.dashboardsData.filter(d=>d.id!==id);
      tempList = tempList.map(item => {
        item.is_starred = false;
        return item;
      });
      tempList.unshift(tempDefaultDash);
      this.dashboardsData=tempList;
        this.open = false;
      } catch (err) {
        console.error(err);
        this.open = false;
        this.$message({
                    showClose: true,
                    message: "There was an unknown error updating fetching the dashboard.",
                    type: "error",
                    center: true,
                });
      }
    },

    // Widgets methods
    removeEmptyKeys(obj) {
      for (let key in obj) {
        if (obj[key] === "" || obj[key] === null || obj[key] === undefined) {
          delete obj[key];
        } else if (typeof obj[key] === "object") {
          this.removeEmptyKeys(obj[key]); // Recursively check nested objects
        }
      }
    },

    async addWidget() {
      try {
        // const dashboardId = this.$route.params.id;
        this.drawerData.dashboard = this.selecteddashboardData.id;
        let postData = this.removeEmptyKeys(this.drawerData);
        let data = "";
        if (this.isEditDrawer) {
          data = await API.CRM_DASHBOARD.UPDATE_WIDGET_BY_ID(
            this.activeWidgetId,
            this.drawerData
          );
          //throw new Error("Simulated error occurred during widget update.");
        } else {
          data = await API.CRM_DASHBOARD.CREATE_WIDGET(this.drawerData);
          this.drawerOpen = false;
        }
        if (data) {
          this.getSelectedDashBoard(this.selecteddashboardData.id);
        }
      } catch (err) {
        console.error(err);
        this.refreshData = true;
        this.$message({
                    showClose: true,
                    message: "There was an unknown error while creating the widget.",
                    type: "error",
                    center: true,
                });
      }
    },
    async editWidget() {
      try {
        // const dashboardId = this.$route.params.id;
        this.drawerData.dashboard = this.selecteddashboardData.id;
        this.drawerData.filters = {};
        const { data } = await API.CRM_DASHBOARD.UPDATE_WIDGET_BY_ID(
          this.activeWidgetId,
          this.drawerData
        );
        if (data) {
          this.refreshData = true;
          this.getSelectedDashBoard(this.selecteddashboardData.id);
        }
      } catch (err) {
        console.error(err);
        this.$message({
                    showClose: true,
                    message: "There was an unknown error while uodating the widget.",
                    type: "error",
                    center: true,
                });
      }
    },
    handleOpenDelete(value, id) {
      this.deleteOpen = true;
      this.activeWidgetId = id;
    },
    async handleDelete() {
      try {
        const dashboardId = this.$route.params.id;

        const data = await API.CRM_DASHBOARD.DELETE_WIDGET_BY_ID(
          this.activeWidgetId
        );
        if (data) {
          this.refreshData = true;
          this.getSelectedDashBoard(this.selecteddashboardData.id);
        }
      } catch (err) {
        this.$message({
                    showClose: true,
                    message: "There was an unknown error while deleting the widget.",
                    type: "error",
                    center: true,
                });
      }
    },

    handleOpenMode(value, id) {
      if (value.prop === "edit") {
        this.handleEditWidget(value, id);
        // this.$emit("handle-editWidget", value, id);
      }
      if (value.prop === "copy") {
        this.handleCopyWidget(value, id);
        // this.$emit("handle-copyWidget", value, id);
      }
      if (value.prop === "delete") {
        // this.$emit("delete-widget", value, id);
        this.handleOpenDelete(value, id);
      }
    },

    handleBack(selecteddashboardData) {
      this.handleDashboardChange(selecteddashboardData.id);
      this.getSelectedDashBoard(selecteddashboardData.id);
      this.editMode = false;
      this.isEditMode = false;
    },
    handleEdit() {
      // this.editMode = true;
      // this.isEditMode = true;
      this.$router.push({
        name: "dashboardEditMode",
        params: { id: this.currentDashboardId },
      });
    },
    handleAllowDocUpload() {
      this.allowUpload = true;
    },
    updateWidget(widgetId) {
      this.updateWidgetData = 0;
      this.updateWidgetData = widgetId;
      this.updateCheck = !this.updateCheck;
    },

    openDrawer() {
      this.drawerOpen = true;
    },
    closeDialog() {
      this.open = false;
    },
    handleShowOrHideDrawer() {
      this.drawerOpen = false;
    },
    handleCreateWidget(data) {
      this.isDisabled = true;
      this.activeWidgetId = "";

      this.selectedChartType = data;

      this.drawerTitle = "Add Widget";
      this.drawerBtn = "Add Widget";
      this.isEditDrawer = false;
      this.isCopyDrawer = false;
      this.openDrawer();
    },
    handleEditWidget(data, id) {
      this.isDisabled = false;
      this.drawerTitle = "Edit Widget";
      this.drawerBtn = "Update";
      this.isEditDrawer = true;
      this.activeWidgetId = id;
      this.activeWidgetId = id;
      this.isCopyDrawer = false;
      this.openDrawer();
    },
    handleCopyWidget(data, id) {
      this.drawerTitle = "Add Widget";
      this.isEditDrawer = false;
      this.activeWidgetId = id;
      this.isCopyDrawer = true;
      this.openDrawer();
    },

    // End of Widgets Methods
    handleDashboardChange(id) {
      this.currentDashboardId = id;
    },
    updateTask() {
      this.updateTaskData();
    },
    async updateTaskData() {
      try {
        if (this.allowUpload) {
          this.documentUploaded = true;
          this.allowUpload = false;
        }
      } catch (err) {
        console.error(err);
        this.$message({
          showClose: true,
          message: "Unable to update task",
          type: "error",
          center: true,
        });
      }
    },
    onDeleteTask(taskId) {
      this.isTaskDrawerOpen = false;
    },
    handleTaskDrawerClose() {
      this.isTaskDrawerOpen = false;
      this.selectedLead = null;
    },
    handleOnClick() {
      this.selectedLead.isTask = true;
      this.reminderDetails = this.selectedLead.reminder_details;
      //open task drawer and pass the task value
    },
    async onSave(reminderDetails) {
      this.reminderDetails = {
        reminder_sent_at: reminderDetails.reminder_sent_at,
        notes: reminderDetails.notes,
      };
      if (this.selectedLead.id) {
        this.setReminder(this.selectedLead.id);
      }
      this.showSetReminderDrawer = false;
    },
    onSetReminder() {
      this.setKey++;
      this.showSetReminderDrawer = true;
    },
    async setReminder(id) {
      let response = await API.TASKS.POST_SETREMINDER_TASK(id, reminderDetails);
      this.loadingStateButton = false;
      this.$message({
        showClose: true,
        message: "Reminder has been set successfully.",
        type: "success",
        center: true,
      });
    },
    showDrawer(type) {
      if (type === "lead") {
        this.isDrawerOpen = !!type;
      } else {
        this.isTaskDrawerOpen = !!type;
      }
    },
    handleRowClick(value) {
      this.selectedLead = value;
    },
    async filterRows(data) {
      this.kpiArr = data.filter((e) => e.type === "KPI");
      const filteredWidgetsTemp = data.filter((e) => e.type !== "KPI");
      let filteredArr = [];
      let filteredElement = {};
      let increment = 1;
      if (filteredWidgetsTemp.length === 1) {
        if (filteredWidgetsTemp[0].type === "Table") {
          filteredElement = {
            type: "single",
            data: [filteredWidgetsTemp[0]],
          };
          filteredArr.push(filteredElement);
        } else {
          filteredElement = {
            type: "single",
            data: [filteredWidgetsTemp[0]],
          };
          filteredArr.push(filteredElement);
        }
        this.filteredWidgets = filteredArr;
        return;
      }
      for (let i = 0; i < filteredWidgetsTemp.length; i += increment) {
        increment = 1;
        filteredElement = {};

        if (filteredWidgetsTemp[i + 1] === undefined) {
          if (filteredWidgetsTemp[i].type === "Table") {
            filteredElement = {
              type: "single",
              data: [filteredWidgetsTemp[i]],
            };
            filteredArr.push(filteredElement);
          } else {
            filteredElement = {
              type: "normal",
              data: [filteredWidgetsTemp[i]],
            };
            filteredArr.push(filteredElement);
          }
          this.filteredWidgets = filteredArr;
          return;
        }

        if (filteredWidgetsTemp[i].type !== "Table") {
          // checking if its a table
          if (
            filteredWidgetsTemp[i + 1] &&
            filteredWidgetsTemp[i + 1].type !== "Table"
          ) {
            // checking if the next element is a table
            filteredElement = {
              type: "normal",
              data: [filteredWidgetsTemp[i], filteredWidgetsTemp[i + 1]],
            };
            increment = 2;
            filteredArr.push(filteredElement); // the row is pushed if none of the element are table
          } else if (
            filteredWidgetsTemp[i + 1] &&
            filteredWidgetsTemp[i + 1].type === "Table"
          ) {
            // if next element is a table
            if (
              filteredWidgetsTemp[i + 2] &&
              filteredWidgetsTemp[i + 2].type === "Table"
            ) {
              //if next next element is also a table
              filteredElement = {
                type: "table",
                data: [filteredWidgetsTemp[i + 1], filteredWidgetsTemp[i + 2]], // array of 2 tables
              };
              filteredArr.push(filteredElement);
              increment = 2;
              filteredWidgetsTemp[i + 2] = filteredWidgetsTemp[i]; // setting the value of upcoming index to the value of first element
            } else if (
              filteredWidgetsTemp[i + 2] &&
              filteredWidgetsTemp[i + 2].type !== "Table"
            ) {
              // if next next element is not a table
              filteredElement = {
                type: "mix",
                data: [
                  {
                    type: "normal",
                    data: [filteredWidgetsTemp[i], filteredWidgetsTemp[i + 2]],
                  },
                  filteredWidgetsTemp[i + 1],
                ], // array with array of chart and a table
              };
              filteredArr.push(filteredElement);
              increment = 3;
            } else {
              filteredElement = {
                type: "mix",
                data: [
                  {
                    type: "normal",
                    data: [filteredWidgetsTemp[i]],
                  },
                  filteredWidgetsTemp[i + 1],
                ],
              };
              filteredArr.push(filteredElement);
              increment = 2;
            }
          }
        } else if (filteredWidgetsTemp[i].type === "Table") {
          if (
            filteredWidgetsTemp[i + 1] &&
            filteredWidgetsTemp[i + 1].type === "Table"
          ) {
            // if next element is also a table
            filteredElement = {
              type: "table",
              data: [filteredWidgetsTemp[i], filteredWidgetsTemp[i + 1]], // array of 2 tables
            };
            filteredArr.push(filteredElement);
            increment = 2;
          } else if (
            filteredWidgetsTemp[i + 1] &&
            filteredWidgetsTemp[i + 1].type !== "Table"
          ) {
            if (
              filteredWidgetsTemp[i + 2] &&
              filteredWidgetsTemp[i + 2].type === "Table"
            ) {
              filteredElement = {
                type: "table",
                data: [filteredWidgetsTemp[i + 1], filteredWidgetsTemp[i + 2]], // array of 2 tables
              };
              filteredArr.push(filteredElement);
              increment = 2;
              filteredWidgetsTemp[i + 2] = filteredWidgetsTemp[i + 1];
            } else if (
              filteredWidgetsTemp[i + 2] &&
              filteredWidgetsTemp[i + 2].type !== "Table"
            ) {
              filteredElement = {
                type: "mix",
                data: [
                  filteredWidgetsTemp[i],
                  {
                    type: "normal",
                    data: [
                      filteredWidgetsTemp[i + 1],
                      filteredWidgetsTemp[i + 2],
                    ],
                  },
                ],
              };
              filteredArr.push(filteredElement);
              increment = 3;
            } else {
              filteredElement = {
                type: "mix",
                data: [
                  filteredWidgetsTemp[i],
                  {
                    type: "normal",
                    data: [filteredWidgetsTemp[i + 1]],
                  },
                ], // array with array of chart and a table
              };
              filteredArr.push(filteredElement);
              increment = 2;
            }
          }
        }
      }
      this.filteredWidgets = [];
      this.filteredWidgets = filteredArr;
    },
    async getdashboards() {
      try {
        const res = await API.CRM_DASHBOARD.GET_DASHBOARDS();
        const dashboards = res.data.results;
        this.nextUrlDash = res.data.next;
        this.dashboardsData = dashboards ? dashboards : [];
        if (this.nextUrlDash) {
          // this.loadMoreData();
        }
        if (dashboards.length > 0) {
          const staredDash = dashboards.find((d) => d.is_starred === true);
          const activeDashboard=dashboards.find((b)=>b.id===this.$route.query.dashboardId)
const selectedDasboardId=activeDashboard?{id:this.$route.query.dashboardId}:{id:staredDash?.id||dashboards[0]?.id}
          this.staredDashboard =selectedDasboardId?selectedDasboardId: staredDash ? staredDash : dashboards[0];
          this.currentDashboardId = this.staredDashboard.id;
          this.isEmpty = false;
        } else {
          this.isEmpty = true;
        }
      } catch (err) {
        this.isEmpty = true;
        this.$message({
          showClose: true,
          message:
            "Error while fetching Dashborad Data. Please try again later.",
          type: "error",
          center: true,
        });
      }
      this.isDashboardData = false;
    },

    calculateCoordinatesForWidget(widget, layout) {
      let x = 0;
      let y = 0;

      // For an empty dashboard
      if (
        !Array.isArray(layout) ||
        layout.length === 0
      ) {
        return { x, y };
      }

      // Find the maximum item in the layout based on y and x properties
      const maxItemInLayout = layout.reduce(
        (maxItem, item) => {
          if (
            item.y + item.h > maxItem.y + maxItem.h ||
            (item.y + item.h === maxItem.y + maxItem.h && item.x > maxItem.x)
          ) {
            return item;
          }
          return maxItem;
        },
        { x: -1, y: -1, h: 0 }
      );

      //need to check if there is space to the left and if the widget can fit there before trying to add it to the right

      // Check if the new widget fits in the current row
      if (maxItemInLayout.x + maxItemInLayout.w + widget.w <= 4) {
        x = maxItemInLayout.x + maxItemInLayout.w;
        y = maxItemInLayout.y + maxItemInLayout.h;
      } else {
        // If there is not enough horizontal space, place the new widget in the next row
        x = 0;
        y = maxItemInLayout.y + maxItemInLayout.h;
      }

      return { x, y };
    },

    async generateLayoutForDashboard(widgets) {
      let tempLayout = []
      widgets.map(widget => {
        let initialObject = {
          i: widget.id,
          id: widget.id,
          type: widget.type,
        };
        if (widget.type === "KPI")
          initialObject = {
            ...initialObject,
            ...defaultKpiDimensions
          };
          else if(widget.type==='Table')
          initialObject = {
            ...initialObject,
            ...defaultTableDimensions
          };
        else
          initialObject = {
            ...initialObject,
            ...defaultGraphDimensions
          };
        let coordinates = this.calculateCoordinatesForWidget({
          w: initialObject.w,
          h: initialObject.h,
        }, tempLayout);

        tempLayout.push(
          { ...initialObject, ...coordinates },
        )

      })

      return tempLayout
    },

    async handleDashboardData(resp) {
      this.dashboardData = {};

      let tempDashboardData =  resp || {}
      if (resp.widgets.length > 0 && (!Array.isArray(resp.layout) || resp.widgets.length === 0)) {
        const newLayout = await this.generateLayoutForDashboard(resp.widgets)
        const response =  API.CRM_DASHBOARD.UPDATE_DASHBOARD_BY_ID(
          resp.id,
            { layout: newLayout }
          );        
          tempDashboardData.layout = newLayout
      }
      this.selecteddashboardData = tempDashboardData;
        this.selectedDashboardLayoutData = tempDashboardData.layout;

      // this.dashboardData = tempDashboardData;
      this.selectedDashboard = tempDashboardData
        ? tempDashboardData.name
        : "";
      this.dashboardName = tempDashboardData ? tempDashboardData.name : "";
   
    },

    async getSelectedDashBoard(id) {
      this.isWidgetsData = true;
      try {
        let { data } = await API.CRM_DASHBOARD.GET_DASHBOARD_BY_ID(id);
        // data.widgets = this.apiResponses.widgets;
        this.filteredWidgets = [];
        this.filterRows(data.widgets);
         this.$router.push("/");

        this.$router.push({
          name: "dashboardCRM",
          query: { dashboardId:id },
        });
        this.handleDashboardData(data)
        // this.selecteddashboardData = data;
        // this.selectedDashboardLayoutData = data.layout;
        this.isWidgetsData = false;
      } catch (err) {
        this.isWidgetsData = false;
        this.$message({
                    showClose: true,
                    message: "There was an unknown error while fetching the widgets.",
                    type: "error",
                    center: true,
                });
      }
    },
    async getWidgets() {
      const defaultDash = this.selecteddashboardData;
      if (defaultDash) {
        if (defaultDash.widgets?.length) {
          defaultDash.widgets.map(async (d, inx) => {
            try {
              let { data } = await API.CRM_DASHBOARD.GET_WIDGET_BY_ID(d);
              data.sequence = inx;
              this.widgetData[inx] = data;
            } catch (err) {
              console.err(err);
              this.$message({
                    showClose: true,
                    message: "There was an unknown error while fetching the widgets.",
                    type: "error",
                    center: true,
                });
            }
          });
        }
      }
    },

    getColorHex(num) {
      return generateColorHex(num);
    },
    handleDrilldownOpen(paramsKey, paramsValue, widgetData) {
      this.drillDownUrl = {
        param: paramsKey.query_param,
        value: paramsValue.data_value,
      };
      this.widgetId = widgetData.id;
      this.chartSource = widgetData.source;
      this.open = true;
    },
    async getCsvColumns() {
      try {
        const resp = await API.CRM_DASHBOARD.GET_CSV_DRILLDOWN_COLUMNS();
        this.csvColumnsLead = resp.data?.Leads || [];
        this.csvColumnsTask = resp.data?.Tasks || [];
      } catch (err) {
        console.error(err);
        this.$message({
                    showClose: true,
                    message: "There was an unknown error while fetching the CSV.",
                    type: "error",
                    center: true,
                });
      }
    },
    async downloadCsv(selectedColumns, drilldownType,paramsDetails) {
      this.downlodLoading = true;
      let qParms = "";
      let queryParams = {};

      if (this.drillDownUrl?.param) {
        queryParams.param_key = this.drillDownUrl?.param || "";
        queryParams.param_value = this.drillDownUrl?.value || "";
      }

      if (drilldownType === "Lead") {
        let metricData = [];
        metricData.push("project_lead.name");
        this.csvColumnsLead.filter((d) => {
          if (selectedColumns.includes(d.label)) {
            metricData.push(d.metric);
          } else {
            if (
              selectedColumns.includes("System Size") &&
              !metricData.includes("project_lead.estimated_system_size")
            ) {
              metricData.push("project_lead.estimated_system_size");
            }
          }
        });
        queryParams.metrics = metricData;
      }

      if (drilldownType === "Task") {
        let metricData = [];
        this.csvColumnsTask.filter((d) => {
          if (selectedColumns.includes(d.label)) {
            metricData.push(d.metric);
          } else {
            if (
              selectedColumns.includes("Assign To") &&
              !metricData.includes("assignee.first_name")
            ) {
              metricData.push("assignee.first_name");
            }
          }
        });
        queryParams.metrics = metricData;
      }
      qParms = new URLSearchParams(queryParams);

      qParms=qParms+"&"+paramsDetails

      try {
let response=''
if(drilldownType === "Lead")
       {  response = await API.CRM_DASHBOARD.EXPORT_LEAD_DATA_CSV(
          this.widgetId,
          qParms
        );}
        if(drilldownType === "Task")
       {  response = await API.CRM_DASHBOARD.EXPORT_TASK_DATA_CSV(
          this.widgetId,
          qParms
        );}

   if(response) {    const csvData = response.data;
        // Convert the CSV data to a Blob
        const blob = new Blob([csvData], { type: "text/csv;charset=utf-16;" });
        // Create a temporary URL for the Blob
        const url = URL.createObjectURL(blob);
        // Create a download link
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "");
        document.body.appendChild(link);
        // Trigger the download
        link.click();
        // Clean up
        document.body.removeChild(link);
        URL.revokeObjectURL(url);}
        this.downlodLoading = false;
      } catch (error) {
        this.downlodLoading = false;
        this.$message({
          showClose: true,
          message: "Error while exporting CSV file. Please try again later.",
          type: "error",
          center: true,
        });
      }
    },

    async loadMoreData() {
      if (this.nextUrlDash) {
        const { data } = await API.DESIGN_ORDERS.LOAD_MORE_Leads(
          this.nextUrlDash
        );

        this.nextUrlDash = data.next;

        this.dashboardsData.push(...data.results);
      }
    },
  },
  mounted() {
    // this.getWidgets();
    this.getCsvColumns();
    this.isEmpty = false;

  },
  created() {
    if (screen.width <= 768) {
      this.isMobile = true;
    }
    this.isDashboardData = true;
    this.getdashboards();
    this.debounceLoadMore = debounce(this.loadMoreData, 300);
    // this.getSelectedDashBoard();
  },

  watch: {
  
    isDrawerOpen(val){
      if(val===false){
        setTimeout(() => {
          this.updateCheck=true;

        }, 500);

      }
    },
    isTaskDrawerOpen(val){
      if(val===false){
        setTimeout(() => {
          this.updateCheck=true;

        }, 1000);

      }
    },
 
    deleteOpen(val) {
      this.deleteOpen = val;
    },
    drawerData(val) {
      if (val) {
        if (this.isEditDrawer) [(this.isDisabled = false)];
        else {
          let condt1 = false;
          let condt2 = false;
          let condt3 = false;
          if (val.type && val.name && val.measure && val.source) {
            condt1 = true;
          }
          if (val.time_range) {
            condt3 = true;
          }

          if (
            val.type !== "KPI" &&
            val.type !== "Map" &&
            val.type !== "Table"
          ) {
            if (val.measure_group) {
              condt1 = true;
            } else {
              condt1 = false;
            }
          }
          if (val.buckets && val?.buckets?.length > 0) {
            const bucketsLength = val.buckets.filter(
              (bkts) => bkts.label && bkts.min && bkts.max
            );
            if (bucketsLength.length === val.buckets.length) {
              condt1 = true;
            } else {
              condt1 = false;
            }
          } else {
            condt1 = true;
          }
          if (Object.values(val.filters).length) {
            const conditions = val.filters.conditions;

            if (Object.values(conditions).length) {
              const valuesAvailable = Object.values(conditions).filter(
                (condition) => {
                  return (
                    condition.metric && condition.operator && condition.value
                  );
                }
              );
            }
          } else {
            condt1 = true;
          }

          if (val.type === "Map") {
            if (val.name) {
              condt1 = true;
              condt3 = true;
            } else {
              condt1 = false;
            }
          }

          if (val.type === "KPI" || val.type === "Trend Line") {
            if (val.comparison_time_range) {
              condt2 = true;
            } else {
              condt2 = false;
            }
          } else {
            condt2 = true;
          }

          if (condt1 && condt2 && condt3) {
            this.isDisabled = false;
          } else {
            this.isDisabled = true;
          }
        }
      }
    },

    staredDashboard(val) {
      this.isWidgetsData = true;
      this.isWidgetsData = this.getSelectedDashBoard(val.id);
    },
    chartSource(val) {
      127;
    },
    filteredWidgets(val) {
      this.filteredWidgets = val;
    },
  },
};
</script>
<style scoped>
.dashboardContainer {
  /* margin-left: 10px; */
  /* margin-top: 10px; */
  /* padding: 10px; */
  height: calc(100vh - 200px);

  max-height: calc(100vh - 200px);
  overflow: scroll;
padding: 0.5rem;
padding-top: 0.5rem !important;

}
.chart-not-first-row {
  margin-top: 20px;
}
.graphs,
.table {
  margin-bottom: 1rem;
}
.table-row {
  display: flex;
  flex-flow: row wrap;
}
.cardFilter {
  display: flex;
  align-items: center;
  width: 390px;
  margin-bottom: 0;
  padding: 12px 16px 12px 16px;
}

.pencil >>> .pencil-class {
  height: 20px;
  margin-left: 10px;
  cursor: pointer;
}
.kpi-container > div {
  margin-top: 20px;
}
@media only screen and (min-width: 1200px) {
  .kpi-container > div {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .kpi-container > div {
    margin-top: 20px;
  }
  .container-sm {
    height: 100% !important;
  }
  .chart-not-first-row-sm {
    margin-top: 20px;
  }
}
.dialog-body {
  margin: 12px 0px;
}
div >>> .card {
  border: 1px solid var(--step-100);
  border-radius: 12px;
  background: var(--white);
}
.nodata {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 15rem);
  margin: 0.2rem !important;
  margin-top: 1rem !important;
  background-color: white;
  border-radius: 8px;
}
.heading-container {
  border-bottom: 1px solid #ccc;
}
.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 12px 16px 12px 16px;
}
.drawerheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 52px;
  border-bottom: 1px solid #ccc;
  padding: 16px;
}
.drawer-body {
  max-height: calc(100vh - 120px);
  overflow-y: scroll;
}
.footer {
  border-top: 1px solid #cccccc;
  height: 68px;
  padding: 16px;
  gap: 12px;
  display: flex;
  justify-content: end;
  align-items: center;
  background: white;
}
.cross {
  font-size: 1.5rem;
  font-weight: 600;
}
.icons-content {
  cursor: pointer;
  padding-left: 0.5rem;
}
.dialog-container {
  padding: 1rem;
}
.dialogbody >>> .el-input__inner {
  background: #e8edf2;
  padding: 14px 10px 14px 10px;
  color: black;
}
.dialogbody >>> .el-input__inner::placeholder{
 
  color: #777;
}
.alignment {
  padding: 1rem;

  display: flex;
  justify-content: center;
}
.label {
  font-size: 1rem;
  padding-bottom: 1rem;
}
.arrow-icon-left {
  margin-right: 5px;
  font-size: 1.5rem;
  font-weight: 800;
  cursor: pointer;
}
.fullWidth {
  width: 100% !important;
  height: calc(100vh - 185px);
  max-height: calc(100vh - 185px);
}
.graph-container{
  padding: 0.25rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  height: 100%
}
</style>
