<template>
    <div class="answersFiledsContainer" v-if="showContent()">
        <div class="uploadFileContainer" v-if="fieldData.question_type === 'file_upload' 
        || fieldData.question_type === 'poi' ||fieldData.question_type === 'boundary'">
            <div v-if="fieldData.question_type === 'file_upload'">
                <div class="flexCont" >
                    <p class="heading">Allow only specific file types</p>
                    <el-switch v-model="fieldData.additional_info.allowed_types_toggle" @change="fieldData.additional_info.allowed_types = []; prepareFieldObjectToStore(undefined,true);">
                    </el-switch>
                </div>
                <el-checkbox-group v-model="fieldData.additional_info.allowed_types" v-if="fieldData.additional_info.allowed_types_toggle" @change="prepareFieldObjectToStore(undefined,true)">
                    <el-checkbox v-for="item in fileTypeItems" :key="item.value" :label="item.value">
                    {{ item.label }}
                    </el-checkbox>
                </el-checkbox-group>
            </div>
            <div class="flexCont">
                <p class="heading">Maximum number of files</p>
                <el-select v-model="fieldData.additional_info.max_files" placeholder="Select" @change="prepareFieldObjectToStore(undefined, true)">
                    <el-option v-for="item in options" :key="item.value" :label="item.value" :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <p class="errorTxt">The maximum file size allowed for upload is 500MB.</p>
        </div>


        <div class="radioContainer" v-if="complexQTypesWOFileUpload.includes(fieldData.question_type)">
            <div class="radioInputsCont">
                <div class="boxContainer" v-for="(option, optionIndex) in fieldData.options" :key="optionIndex">
                    <div class="inputCrossContainer">
                        <div class="flexRadioInput">
                            <div :class="fieldData.question_type === 'multiple_choice' ? 'staticRadio' : (fieldData.question_type === 'checkbox' ? 'staticCheckbox' : '')"></div>
                            <input type="text" class="inputs" 
                            :placeholder="fieldData.question_type === 'tab_choice'? 'Tab Option Text ' + (optionIndex + 1) :'Option Text ' + (optionIndex + 1)" 
                            v-model="option.option_text" 
                            @change="prepareFieldObjectToStore('options')" />
                        </div>
                        <b-icon icon="x-lg" style="color: #777;" @click="removeOptionFunc(optionIndex)" class="icons"></b-icon>
                    </div>
                    <div :class="['flexCont',radioToggle ? 'marginLeft' : '' ]" v-if="fieldData.question_type !== 'checkbox'">
                        <p class="heading">Add sub option</p>
                        <el-switch v-model="option.isNestedQRequired"  @change="addNestedQuestion(optionIndex,0)">
                        </el-switch>
                    </div>
                    <div class="toggleOn" v-for="(nestedQ,nestedQIndex) in filteredSubOptions(option)" :key="nestedQIndex">
                        <div class="flexRadioInput">
                            <input type="text" class="inputColor" placeholder="Sub Label Text" v-model="nestedQ.label" @change="prepareFieldObjectToStore('options')" />
                            <b-icon icon="x-lg" style="color: #777;" v-if="nestedQIndex !== 0" @click="removeCurrentNestedQuestionForOption(optionIndex, nestedQIndex)" class="icons"></b-icon>
                        </div>
                        <div class="dropdownClass">
                            <el-select v-model="nestedQ.question_type" placeholder="Select" @change="resetObject(optionIndex,nestedQIndex);prepareFieldObjectToStore('options');">
                                <el-option
                                    v-for="subQuestion in filteredSubQuestionTypeItems"
                                    :key="subQuestion.value"
                                    :label="subQuestion.label"
                                    :value="subQuestion.value"
                                >
                                <div>
                                    <div class="flexOptions">
                                        <div v-if="subQuestion.iconType === 'icon'">
                                            <b-icon :icon="subQuestion.icons" style="color: #777; margin-top: 12px;" font-scale="1.3"></b-icon>
                                        </div>
                                        <div v-else-if="subQuestion.iconType === 'image'">
                                            <img :src="subQuestion.icons" alt="Option Image" class="image-icon" style="margin-top: 12px;">
                                        </div>
                                        <p class="dropdownOptions">{{ subQuestion.label }}</p>
                                    </div>
                                </div>
                                </el-option>
                            </el-select>
                            <b-icon v-if="selectedOptionIcon && selectedOption.iconType === 'icon'" :icon="selectedOptionIcon" style="color: #777;" font-scale="1.3" class="absoluteIcon"></b-icon>
                            <img v-if="selectedOptionIcon && selectedOption.iconType === 'image'" :src="selectedOptionIcon" class="image-icon absoluteIcon">
                        </div>
                        <!-- rendering options for nested question -->
                        <div class="inputCrossContainer"  v-for="(nestedQOption, nestedQOptionIndex) in filteredNestedQOptions(nestedQ)" 
                        :key="nestedQOptionIndex" 
                        >
                            <div class="flexRadioInput">
                                <div :class="nestedQ.question_type == 'multiple_choice' ?'staticRadio': 'staticCheckbox'"
                                 v-if="nestedQ.question_type != 'dropdown' && nestedQ.question_type !='tab_choice' "></div>
                                <input type="text" class="inputs" :placeholder="'Sub Option Text ' + (nestedQOptionIndex + 1)" 
                                v-model="nestedQOption.option_text" @change="prepareFieldObjectToStore('options')" />
                            </div>
                            <b-icon icon="x-lg" style="color: #777;" @click="removeAnOptionForNestedQuestion(optionIndex, nestedQIndex,nestedQOptionIndex)" class="icons"></b-icon>
                        </div>
                        <div v-if="!complexQTypesWOFileUpload.includes(nestedQ.question_type)">
                            <div class="sliderContainer" v-if="nestedQ.question_type === 'slider'">
                                <div class="sliderFields" v-for="(sliderField, sliderFieldIndex) in sliderSubDetail" :key="sliderFieldIndex">
                                    <p class="sliderFieldText">{{sliderField.label}}</p>
                                    <input class="sliderInput" v-if="sliderField.type !== 'number'" v-model="nestedQ.additional_info[sliderField.value]" @change="prepareFieldObjectToStore('options')" />
                                    <input class="sliderInput" 
                                    v-if="sliderField.type === 'number'" 
                                    :placeholder="sliderField.default" 
                                    v-model="nestedQ.additional_info[sliderField.value]" 
                                    @input="filterNonNumeric(sliderField.value,nestedQ)"
                                    @change="limitNumericValue(nestedQ.additional_info[sliderField.value], sliderField, nestedQ)"/>
                                </div>
                            </div>
                            <div class="uploadFileContainer" v-if="nestedQ.question_type === 'file_upload' 
                            || nestedQ.question_type === 'poi'">
                                <div v-if="nestedQ.question_type === 'file_upload'">
                                    <div class="flexCont" >
                                        <p class="heading">Allow only specific file types</p>
                                        <el-switch v-model="nestedQ.additional_info.allowed_types_toggle" @change="nestedQ.additional_info.allowed_types = []; prepareFieldObjectToStore('options');">
                                        </el-switch>
                                    </div>
                                    <el-checkbox-group v-model="nestedQ.additional_info.allowed_types" v-if="nestedQ.additional_info.allowed_types_toggle" @change="prepareFieldObjectToStore('options')">
                                        <el-checkbox v-for="item in fileTypeItems" :key="item.value" :label="item.value">
                                        {{ item.label }}
                                        </el-checkbox>
                                    </el-checkbox-group>
                                </div>
                                <div class="flexCont">
                                    <p class="heading">Maximum number of files</p>
                                    <el-select v-model="nestedQ.additional_info.max_files" placeholder="Select" @change="prepareFieldObjectToStore('options')">
                                        <el-option v-for="item in options" :key="item.value" :label="item.value" :value="item.value">
                                        </el-option>
                                    </el-select>
                                </div>
                                <p class="errorTxt">The maximum file size allowed for upload is 500MB.</p>
                            </div>
                        </div>
                        <div class="addContainer" v-if="complexQTypesWOFileUpload.includes(nestedQ.question_type)">
                            <p class="addOpt" @click="addAnOptionForNestedQuestion(optionIndex, nestedQIndex)">Add Sub Option</p>
                        </div>
                        <div class="addContainer" style="margin-top: 1rem; border-bottom: 1px solid #ccc;">
                            <p class="addOpt" style="margin-bottom: 1rem" @click="addNestedQuestion(optionIndex,nestedQIndex)">Add Sub Option</p>
                        </div>
                    </div>
                </div>
                <div class="addContainer">
                    <p class="addOpt" @click="addOptionFunc">{{fieldData.question_type==='tab_choice' ? `Add Tab Option`:`Add Option`}}</p>
                    <p>or</p>
                    <p class="addOther" @click="addOtherOptionFunc">add “Other”</p>
                </div>
            </div>
        </div>
        <div class="sliderContainer" v-if="fieldData.question_type === 'slider'">
            <div class="sliderFields" v-for="(sliderField, sliderFieldIndex) in sliderSubDetail" :key="sliderFieldIndex">
                <p class="sliderFieldText">{{sliderField.label}}</p>
                <input class="sliderInput" v-if="sliderField.type !== 'number'" v-model="fieldData.additional_info[sliderField.value]" @change="prepareFieldObjectToStore(undefined, true)" />
                <input class="sliderInput" 
                v-if="sliderField.type === 'number'" 
                :placeholder="sliderField.default"
                v-model="fieldData.additional_info[sliderField.value]" 
                @input="filterNonNumeric(sliderField.value)"
                @change="limitNumericValue(fieldData.additional_info[sliderField.value], sliderField)"/>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import { useEditableSiteSurveyStore } from "../../../stores/editableSiteSurvey";
export default {
	emits: ["patchTemplate"],
  components: { 
    
   },
    props:{
        fieldData:{
            type:Object,
        },
        fieldIndex:{
            type:Number,
        },
        sectionIndex:{
            type:Number,
        },
        isEditTemplateMode: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            checkList: [],
            toggleOn: false,
            radioToggle: false,
            options: [{
                value: '1',
                label: '1'
            }, {
                value: '5',
                label: '5'
            }, {
                value: '10',
                label: '10'
            }, {
                value: '20',
                label: '20'
            }],
            containers: [],
            subContainers: [],
            radio: 3,
        };
    },
    computed:{
        selectedOption() {
            return this.subQuestionTypeItems.find(subQuestion => subQuestion.value === this.value) || {};
        },

        selectedOptionIcon() {
            return this.selectedOption.icons || null;
        },
        ...mapState(useEditableSiteSurveyStore, {
            formData: "GET_FORM_DATA",
            questionTypeList: "GET_QUESTIONS_LIST",
            complexQTypes: "GET_COMPLEX_Q_TYPES",
            fileTypeItems:"GET_FILE_TYPES",
            sliderSubDetail:"GET_SLIDER_SUBDETAIL",
        }),
        complexQTypesWOFileUpload(){
            return this.complexQTypes.filter(type => type !== 'file_upload');
        },
        subQuestionTypeItems(){
            return this.questionTypeList.filter(qType => qType.value !== 'boundary' && qType.value !== 'tab_choice' );
        },
        filteredSubQuestionTypeItems() {
            return this.subQuestionTypeItems().filter(subQuestion => this.canRenderQType(subQuestion));
        }
    },
    created() {
        this.value = this.subQuestionTypeItems.length > 0 ? this.subQuestionTypeItems[0].value : '';
    },
    methods: {
        filteredSubOptions(option) {
            return option.sub_options.filter(() => option.isNestedQRequired && this.fieldData.question_type !== 'checkbox');
        },
        filteredNestedQOptions(nestedQ) {
           return nestedQ.options.filter(() => this.complexQTypesWOFileUpload.includes(nestedQ.question_type))
        },
        resetObject(optionIndex, nestedQIndex){
            //always reset the followup question's options array when the q type is changed
            this.fieldData.options[optionIndex].sub_options[nestedQIndex].options = [];
        },
        canRenderQType(subQuestion){
            if(subQuestion.value === 'poi'){
                if(this.formData.boundaryAdded === true && this.formData.lastBoundaryRemoved === false)
                    return true;
                else
                    return false;
            }
            else return true;
        },
        addOptionFunc() {
            this.fieldData.options.push({
                option_text: '',
                sub_options: [],
                isNestedQRequired: false,
            });
            this.prepareFieldObjectToStore('options');
        },
        addOtherOptionFunc(){
            this.fieldData.options.push({
                option_text: 'Other',
                sub_options: [],
            });
            this.prepareFieldObjectToStore('options');
        },
        addNestedQuestion(optionIndex, nestedQIndex){
            if(this.fieldData.options[optionIndex].isNestedQRequired){
                let newNestedQ = {
                        label:'',
                        question_type:'',
                        options:[],
                        additional_info:{
                            allowed_types: [],
                            max_files: '1',
                            allowed_types_toggle: false,
                        },
                };
                if(this.fieldData.options[optionIndex].sub_options.length === 0){
                    this.fieldData.options[optionIndex].sub_options.push(newNestedQ);
                }
                else{
                    // write code to insert after the current nested question
                    this.fieldData.options[optionIndex].sub_options.splice(nestedQIndex + 1, 0, newNestedQ);
                }
            }else{
                //nested questions toggled off
                //remove all nested Qs
                this.fieldData.options[optionIndex].sub_options = [];
            }
            this.prepareFieldObjectToStore('options');
        },
        removeCurrentNestedQuestionForOption(optionIndex,nestedQIndex){
            this.fieldData.options[optionIndex].sub_options.splice(nestedQIndex,1);
            this.prepareFieldObjectToStore('options');
        },
        removeOptionFunc(optionIndex) {
            this.fieldData.options.splice(optionIndex, 1);
            this.prepareFieldObjectToStore('options');
        },

        addAnOptionForNestedQuestion(optionIndex, nestedQIndex) {
            this.fieldData.options[optionIndex].sub_options[nestedQIndex].options.push({
                option_text: '',
                sub_options: [],
            });
            this.prepareFieldObjectToStore('options');
        },

        removeAnOptionForNestedQuestion(optionIndex, nestedQIndex, nestedQOptionIndex) {
            this.fieldData.options[optionIndex].sub_options[nestedQIndex].options.splice(nestedQOptionIndex, 1);
            this.prepareFieldObjectToStore('options');
        },
        showContent(){
            if(this.fieldData.question_type === "short_answer" || this.fieldData.question_type === "paragraph" 
            || this.fieldData.question_type === "number"|| this.fieldData.question_type === "date"
            || this.fieldData.question_type === "time" || this.fieldData.question_type === ''){
                return false;
            }
            else
                return true;
        },
        filterNonNumeric(propertyVal,nestedQ) {
            // Remove non-numeric characters using a regular expression
            if(propertyVal === 'step'){
                //allows only positive numbers
                if(!nestedQ){
                    this.fieldData.additional_info[propertyVal] = this.fieldData.additional_info[propertyVal].replace(/\D/g, '');
                    this.prepareFieldObjectToStore(undefined, true);
                }
                else{
                    nestedQ.additional_info[propertyVal] = nestedQ.additional_info[propertyVal].replace(/\D/g, '');
                    this.prepareFieldObjectToStore('options');
                }
            }
            else{
                //allows positive and negative numbers
                if(!nestedQ){
                    this.fieldData.additional_info[propertyVal] = this.fieldData.additional_info[propertyVal].replace(/[^-0-9]/g, '');
                    this.prepareFieldObjectToStore(undefined, true);
                }
                else{
                    nestedQ.additional_info[propertyVal] = nestedQ.additional_info[propertyVal].replace(/[^-0-9]/g, '');
                    this.prepareFieldObjectToStore('options');
                }
            }
        },
        limitNumericValue(value, sliderField, nestedQ) {
            // Convert the value to a number
            const numericValue = Number(value);
            let maxVal;
            let minVal;
            let sliderMaxDefault = this.sliderSubDetail.find(slider => slider.value === 'maximum_value');
            let sliderMinDefault = this.sliderSubDetail.find(slider => slider.value === 'minimum_value');
            if(!nestedQ){
                maxVal = this.fieldData.additional_info.maximum_value || sliderMaxDefault.default; 
                minVal = this.fieldData.additional_info.minimum_value || sliderMinDefault.default;
            }else{
                maxVal = nestedQ.additional_info.maximum_value || sliderMaxDefault.default;
                minVal = nestedQ.additional_info.minimum_value || sliderMinDefault.default;
            }

            // Check if the numericValue is greater than 999999
            if(sliderField.value !== 'step'){
                if (numericValue > 9999999 || numericValue < -9999999) {
                    // Set the value to 999999
                    //show an error message to the user that the value is too high 
                    this.$message({
                        message: 'value must be in the range of -9999999 to 9999999.',
                        type: 'error'
                    });
                    if(!nestedQ)
                        this.fieldData.additional_info[sliderField.value] = '';
                    else
                        nestedQ.additional_info[sliderField.value] = '';
                }
                if (sliderField.value === 'maximum_value' && (minVal || minVal === 0)
                 && numericValue <= minVal) {
                    this.$message({
                        message: 'Maximum value must be greater than minimum value.',
                        type: 'error'
                    });
                    if(!nestedQ)
                        this.fieldData.additional_info[sliderField.value] = '';
                    else
                        nestedQ.additional_info[sliderField.value] = '';
                }
                if(sliderField.value === 'minimum_value' && maxVal
                 && numericValue >= maxVal){
                    this.$message({
                        message: 'Minimum value must be less than maximum value.',
                        type: 'error'
                    });
                    if(!nestedQ)
                        this.fieldData.additional_info[sliderField.value] = '';
                    else
                        nestedQ.additional_info[sliderField.value] = '';
                }
            }
            else{
                
                if(maxVal === 0 && minVal === 0){
                    this.$message({
                        message: 'Enter minimum and maximum values first.',
                        type: 'error'
                    });
                    if(!nestedQ)
                        this.fieldData.additional_info[sliderField.value] = '';
                    else
                        nestedQ.additional_info[sliderField.value] = '';
                }
                if((maxVal || minVal ) && (numericValue > maxVal - minVal)){
                    this.$message({
                        message: 'Enter a valid step.',
                        type: 'error'
                    });
                    if(!nestedQ)
                        this.fieldData.additional_info[sliderField.value] = '';
                    else
                        nestedQ.additional_info[sliderField.value] = '';
                }
            }
        },
        ...mapActions(useEditableSiteSurveyStore, {
            updateFieldInASection:"UPDATE_FIELD_IN_A_SECTION",
        }),
        prepareFieldObjectToStore(propertyName, inAdditionalInfo = false){
            if (this.isEditTemplateMode){
                let fieldBody = {
                    isFieldLevel: true,
                    id: this.fieldData.id,
                };
                if (inAdditionalInfo === true) {
                    fieldBody = {
                        ...fieldBody, 
                        additional_info: this.fieldData.additional_info,
                    };
                } else {
                    fieldBody = {
                        ...fieldBody, 
                        [propertyName]: this.fieldData[propertyName], 
                    };
                }
                this.$emit("patchTemplate", fieldBody);
            } else {
                this.updateFieldInASection(this.sectionIndex - 1, this.fieldIndex - 1, this.fieldData);
            }
        },
    },
};
</script>
    
<style scoped>
.answersFiledsContainer {
    margin-top: 24px;
    width: 49%;
}

.uploadFileContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.flexCont {
    display: flex;
    justify-content: space-between;
    gap: 24px;
    align-items: center;
}

.heading,
.answersFiledsContainer>>>.el-checkbox__label,
.answersFiledsContainer>>>.el-checkbox__input.is-checked+.el-checkbox__label {
    font-size: 16px;
    color: #222 !important;
}

.answersFiledsContainer>>>.el-checkbox__inner,
.staticCheckbox,
.staticRadio {
    min-width: 22px;
    height: 22px;
    border: 2px solid #777;
    border-radius: 4px;
}

.staticRadio {
    border-radius: 100%;
}

.answersFiledsContainer>>>.el-checkbox__inner::after {
    border: 2px solid #FFFFFF;
    border-left: 0;
    border-top: 0;
    height: 10px;
    left: 7px;
}

.answersFiledsContainer>>>.el-select {
    width: 80px !important;
    height: 40px !important;
}

.answersFiledsContainer>>>.el-input__inner {
    height: 40px !important;
    background-color: #fff !important;
    border: 1px solid #ccc !important;
    font-size: 16px;
    color: #222;
}

.errorTxt {
    margin-top: -16px;
    color: #f00;
    font-size: 12px;
}

.radioInputsCont,
.mapInputCont,
.boxContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
}

.inputCrossContainer,
.flexRadioInput {
    display: flex;
    align-items: center;
    gap: 24px;
    width: 100%;
}

.inputCrossContainer {
    width: 100%;
}

.inputs {
    border: none;
    width: 100%;
    color: #777;
}

.inputs:hover {
    border-bottom: 1px solid #ccc;
}

.addContainer {
    display: flex;
    gap: 16px;
}

.addOpt {
    display: flex;
    gap: 18px;
    color: #777;
}

.addOpt::before {
    content: "";
    background: url('../assets/PlusCircle.svg');
    width: 20px;
    height: 20px;
    display: block;
}

.addOther {
    color: #1C3366;
}

.boxContainer {
    gap: 16px;
}

.marginLeft {
    margin-left: 50px;
}

.toggleOn {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-left: 40px;
}

.inputColor {
    height: 40px;
    padding: 10px 16px;
    color: #222;
    background-color: #E8EDF2;
    border: none;
    border-radius: 4px;
    width: 100%;
}

.addOpt,
.addOther,
.icons {
    cursor: pointer;
}

.dropdownClass >>> .el-select {
    width: 100% !important;
}

.dropdownClass >>> .el-input__inner {
    background-color: #fff !important;
    border: 1px solid #CCC !important;
    padding-left: 48px;
    height: 48px;
    background-color: #e8edf2;
    border: none;
    font-size: 16px;
    color: #222;
    width: 100% !important;
}

.dropdownClass {
    position: relative;
    width: 100%;
}

.flexOptions {
    display: flex;
    gap: 24px;
    align-items: center;
}
.absoluteIcon {
    position: absolute;
    top: 14px;
    left: 16px;
}
.dropdownOptions {
    font-size: 16px;
    color: #222;
}

.sliderContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    align-self: stretch;
}

.sliderFields{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.sliderFieldText{
    color: var(--Black, var(--222, #222));
    font-size: 1rem;
    line-height: 1.25rem; 
}

.sliderInput{
    border-radius: 4px;
    border: 1px solid var(--ccc, #CCC);
    display: flex;
    width: 80px;
    padding: 8px;
    align-items: center;
    gap: 30px;
}
</style>