
<div class="taskdetails">
    <slot name="header">
      <div class="title">
        <h3 class="task-title-text">Task Details</h3>
        <span class="cross" @click="onClose()" style="font-weight: bolder; padding: 0.75rem 0.5rem 20px;">&#x2716;</span>
      </div>
    </slot>
    <slot name="body">
      <div class="wholeBody">
        <div>
            <p class="label">Task Name <span class="astrisk"> *</span> </p>
            <p class="task_name">{{ tasks.name }}</p>
        </div>
        <div> 
            <p class="label">Owner <span class="astrisk"> *</span></p>
  
              <div class="bodyContent ownerBody" >
                <p class="ownerN">{{ textInitial(tasks.assigned_to) }}</p><span class="ownerLine">{{
                    tasks.assigned_to }}</span>
            </div>
           

        <!-- </div>
        <div>  -->
            <!-- <p class="label">Description</p> -->
            <p class="description">{{tasks.description}}</p>

        </div>
      </div>
    </slot>
    <slot name="footer">
      <div class="bottomCard">
          <!-- <el-card class="boxCard"> -->
              
              <div class="outerBtn">
                <el-button type="default" class="submitBtn1" @click="deleteTask()" :loading="loadingStateButton">Delete</el-button>
                  <el-button type="primary" class="submitBtn" @click="detailsDrawer=true" :loading="loadingStateButton">Edit</el-button>
              </div>
          <!-- </el-card> -->
      </div>
    </slot>
    <all-drawer :drawer="detailsDrawer" @save="detailsDrawer = false" :componentName="componentName" :drawerSize="500"
    @close="onClose()" :forAddTask="forAddTask" :tasks="tasks"/>
</div>
