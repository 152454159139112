
<div class="deleteModule" id="deleteModule">
  <el-dialog :visible="isDeleteProjectDocumentPopupOpen" :close-on-click-modal="false" :title="title" width="30%"
    height="25rem !important" class="delete_module" @close="closeModal" :modal-append-to-body="false">
      <div class="alert" >
        <img src="../../../assets/img/alert (1).svg" alt="alert" class="warningImage" />
      </div>
    <p class="msg">{{info}}</p>
    <p class="msg">{{info2}}</p>
    <div class="button_container">
      <el-button type="outline" @click="cancelDelete()">No</el-button>
      <el-button type="primary" @click="confirmDelete()">Yes</el-button>
    </div>
  </el-dialog>
</div>
