
<div class='deleteModule'>
    <el-dialog 
        :visible="isStatusPopupVisible"
        :close-on-click-modal="false"
        title=""
        width="30%"
        @close="closeModal"
        append-to-body
    >
        <b-icon icon="check-circle-fill" variant="success" class="successIcon"></b-icon> 
        <p class='title'>Email Sent.</p>
        <p class='description'>You have successfully shared the contract, would you like to view the status? </p>
        <div class='button_container'>
            <el-button type="" class="commonBtnClass cancelBtn"  @click="checkStatus(false)">Cancel</el-button>
            <el-button type="primary" class="commonBtnClass"  @click="checkStatus(true)">Yes</el-button>
        </div>
    </el-dialog>
</div>
