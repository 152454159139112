
<div>
  <el-drawer
    :append-to-body="true"
    title="I am the title"
    :visible="drawer"
    class="allDrawer"
    :with-header="false"
    :size="drawerSize"
    :show-close="true"
    :before-close="onClose"
  >
    <component
      :is="componentName"
      :drawerStage.sync="drawerStage"
      :propsData.sync="propsData"
      @save="onSave()"
      @close="onClose()"
      :key="taskKey"
      :leadId="leadId"
      :tasks="tasks"
      :project_id="project_id"
      :forAddTask="forAddTask"
      :isDraw="isDrawer"
      :showWarningPopUp="showWarningPopUp"
      @handleShowWarning="handleShowWarning"
    >
      <template #header> </template>
      <template #body> </template>
      <template #footer> </template>
    </component>
  </el-drawer>
  <CloseDialog
    v-if="isCloseDialogOpen"
    :isDeleteProjectDocumentPopupOpen="isCloseDialogOpen"
    @cancelDelete="isCloseDialogOpen = false"
    @confirmDelete="confirmClose"
    message="Are you sure you want to discard your changes?"
  />
</div>
