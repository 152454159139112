<template>
  <div v-loading="isLoading">
    <div
      class="tableContainer tableContainer-dash"
    >
      <div
        :class=" checkedColumnsList && checkedColumnsList.length > screenSizeCheck
              ? ' tableHeader '
              : ' tableHeader tableHeader-dash'
        "
      >
        <div
          :class="checkedColumnsList &&
                checkedColumnsList.length > screenSizeCheck
                ? 'headerTitle slnoDet slnoDet-dash '
                : 'headerTitle slnoDet slnoDet-dash fullwidthContainer'
          "
          v-if="isDrilldown"
        >
          S. No.
        </div>

        <div
          :class=" checkedColumnsList &&
                checkedColumnsList.length > screenSizeCheck
                ? ' leadDet-dash headerTitle leadDet'
                : 'fullwidthContainer leadDet-dash headerTitle leadDet'
          "
        >
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
            "
          >
            <div>Lead Details</div>
            <div
              v-if="isDrilldown"
              style="
                font-weight: 500;
                background: #e8edf2;
                color: #777;
                border-radius: 4px;
                padding: 5px;
              "
            >
              {{ leadsCount }}
            </div>
          </div>
        </div>
        <div
          v-if="checkedColumnsList.includes('Pipeline')"
          :class="checkedColumnsList &&
                checkedColumnsList.length > screenSizeCheck
                ? ' stage-dash headerTitle stage'
                : 'fullwidthContainer stage-dash headerTitle stage'
          "
        >
          Pipeline
        </div>
        <div
          v-if="checkedColumnsList.includes('Stage')"
          :class=" checkedColumnsList &&
                checkedColumnsList.length > screenSizeCheck
                ? ' stage-dash headerTitle stage'
                : 'fullwidthContainer stage-dash headerTitle stage'
          "
        >
          Stage
        </div>
        <div
          v-if="checkedColumnsList.includes('Lead Source')"
          :class=" checkedColumnsList &&
                checkedColumnsList.length > screenSizeCheck
                ? ' stage-dash headerTitle stage'
                : 'fullwidthContainer stage-dash headerTitle stage'
          "
        >
          Lead Source
        </div>
        <div
        v-if="checkedColumnsList.includes('Region') && isRelianceOrg"
          :class="checkedColumnsList &&
                checkedColumnsList.length > screenSizeCheck
                ? ' stage-dash headerTitle stage'
                : 'fullwidthContainer stage-dash headerTitle stage'
          "
        >
          Region
        </div>
        <div
          v-if="checkedColumnsList.includes('Probability')"
          :class="checkedColumnsList &&
                checkedColumnsList.length > screenSizeCheck
                ? ' stage-dash headerTitle stage'
                : 'fullwidthContainer stage-dash headerTitle stage'
          "
        >
        Probability
        </div>
        <div
          v-if="checkedColumnsList.includes('Owner')"
          :class="checkedColumnsList &&
                checkedColumnsList.length > screenSizeCheck
                ? ' owner-dash headerTitle owner'
                : 'fullwidthContainer owner-dash headerTitle owner'
          "
        >
          Owner
        </div>
        <div
          v-if="checkedColumnsList.includes('Type')"
          :class="checkedColumnsList &&
                checkedColumnsList.length > screenSizeCheck
                ? ' type-dash headerTitle type'
                : 'fullwidthContainer type-dash headerTitle type'
          "
        >
          Type
        </div>
        <div
          v-if="checkedColumnsList.includes('Created On')"
          :class="checkedColumnsList &&
                checkedColumnsList.length > screenSizeCheck
                ? ' createdOn-dash headerTitle createdOn'
                : 'fullwidthContainer createdOn-dash headerTitle createdOn'
          "
        >
          Created On
        </div>
        <div
          v-if="checkedColumnsList.includes('Target Close Date')"
          :class="checkedColumnsList &&
                checkedColumnsList.length > screenSizeCheck
                ? ' targetCloseDate-dash headerTitle targetCloseDate'
                : 'fullwidthContainer targetCloseDate-dash headerTitle targetCloseDate'
          "
        >
          Target Close Date
        </div>
        <div
          v-if="checkedColumnsList.includes('Deal Value')"
          :class=" checkedColumnsList &&
                checkedColumnsList.length > screenSizeCheck
                ? ' dealVal-dash headerTitle dealVal'
                : 'fullwidthContainer dealVal-dash headerTitle dealVal'
          "
        >
          Deal Value
        </div>
        <div
          v-if="checkedColumnsList.includes('Latest Note')"
          :class="checkedColumnsList &&
                checkedColumnsList.length > screenSizeCheck
                ? ' latestNote-dash headerTitle latestNote'
                : 'fullwidthContainer latestNote-dash headerTitle latestNote'
          "
        >
          Latest Note
        </div>
        <div
          v-if="checkedColumnsList.includes('System Size')"
          :class="checkedColumnsList &&
                checkedColumnsList.length > screenSizeCheck
                ? ' sysSize-dash headerTitle sysSize'
                : 'fullwidthContainer sysSize-dash headerTitle sysSize'
          "
        >
          System Size
        </div>
        <div
          v-if="checkedColumnsList.includes('Last Contacted')"
          :class=" checkedColumnsList &&
                checkedColumnsList.length > screenSizeCheck
                ? ' lastCont-dash headerTitle lastCont'
                : 'fullwidthContainer lastCont-dash headerTitle lastCont'
          "
        >
          Last Contacted
        </div>
        <div
          v-if="checkedColumnsList.includes('Country')"
          :class="checkedColumnsList &&
                checkedColumnsList.length > screenSizeCheck
                ? ' lastCont-dash headerTitle lastCont'
                : 'fullwidthContainer lastCont-dash headerTitle lastCont'
          "
        >
          Country
        </div>
      </div>
      <div
        :class="checkedColumnsList && checkedColumnsList.length > screenSizeCheck
              ? ' parentBodyContainer'
              : 'fullWidthBody parentBodyContainer'
        "
      >
        <div
          :class="checkedColumnsList &&
                checkedColumnsList.length > screenSizeCheck
                ? ' bodyContainer'
                : 'fullwidthContainer bodyContainer'
          "
          v-for="(lead, index) in computedAllLeadsList"
          :key="index"
          @click="
            isDrilldown ? redirectToSummary(lead) : openLeadDrawer(lead, index, $event)
          "
        >
          <div
            class="fullwidthContainer slnoDet-dash bodyContent slnobody"
            v-if="isDrilldown"
          >
            {{ index + 1 }}
            <!-- <p class="pipelineLabel">{{ lead.lead_details.stage }}</p> -->
          </div>

          <div
            :class="checkedColumnsList &&
                  checkedColumnsList.length > screenSizeCheck
                  ? ' leadDet-dash bodyContent leadDetBody'
                  : 'fullwidthContainer leadDet-dash bodyContent leadDetBody'
            "
          >
            <div class="leadFlex">
              <div class="leadDetcolumn">
                <p class="name">
                  <el-tooltip placement="top" :disabled="!showTooltip(lead?.lead_details?.name,33)">
                    <template v-slot:content>
                    <div>
                      <span
                        ><p class="lead-name">
                          {{ lead?.lead_details?.name }}
                        </p></span
                      >
                    </div>
                    </template>
                    <router-link
                      style="display:flex;align-items: center;max-width: 260px;"
                      :to="{ name: 'leadSummary', params: { leadId: lead.lead_details.id }}"
                      event
                      @click="
                        isDrilldown ? redirectToSummary(lead) : openLeadDrawer(lead, index, $event)
                      "
                    >
                      <span :class="['ownerLine']">
                        {{ lead?.lead_details?.name }}
                      </span>
                      <span v-if="lead?.is_shared" style="display: flex;align-items: center;" >
                        <img style="padding-left: 0.5rem;height:auto;" src="../../../assets/img/sharedIcon.svg" alt="">
                      </span>
                      <!-- Lead Belongs to VIP Indicator -->
                      <span v-if="(!lead?.is_shared&&lead?.lead_details?.workflow_type==='d2c_vip_external')" style="display: flex;align-items: center;" >
                        <img style="padding-left: 0.5rem;height:auto;" src="../../../assets/img/bordered-building.svg" alt="">
                      </span>
                    </router-link>
                  </el-tooltip>
                </p>
                <p
                  :class="{
                    'disabled-content': !(
                      lead?.lead_details?.owner_id == ownerId
                    ),
                  }"
                  v-if="lead?.reminder_details"
                  class="remainder"
                  @click="onSetReminder(lead, index)"
                >
                  {{ reminderTimeText(lead) }}
                </p>
                <p
                  v-else
                  class="remainder"
                  @click="onSetReminder(lead, index)"
                  :class="{
                    'disabled-content': !(
                      lead?.lead_details?.owner_id == ownerId
                    ),
                  }"
                >
                  Set Reminder
                </p>
              </div>
              <div class="leadIcons">
                <div
                  class="tooltipCommon"
                  id="addNoteBtn"
                  @click="
                    isDrilldown
                      ? redirectToSummary(lead)
                      : addNoteClick($event, lead, index)
                  "
                >
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="Add Notes"
                    placement="top-start"
                    popper-class="tooltipClass"
                  >
                    <img src="./assets/Vector (1).svg" class="vectorIcon" />
                  </el-tooltip>
                </div>
                <div class="tooltipCommon">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="View Tasks"
                    placement="top-start"
                  >
                    <img
                      src="./assets/event_list.svg"
                      @click="onClickTasks($event, lead, index)"
                      class="eventListIcon"
                    />
                  </el-tooltip>
                </div>
                <div class="tooltipCommon site-survey-button">
                  <el-tooltip class="item" effect="dark" placement="top-start">
                    <template v-slot:content>
                    <div>
                      <i
                        class="el-icon-loading"
                        v-if="isGeneratingSiteSurvey"
                      ></i>
                      <div v-else>{{ siteSurveyOptionToolTip(lead) }}</div>
                    </div>
                    </template>
                    <span>
                      <img
                        @click="onClickSiteSurvey(lead)"
                        src="./assets/assignment.svg"
                        class="assignmentIcon"
                        :class="{ disabled: !lead.address }"
                      />
                    </span>
                  </el-tooltip>
                </div>
              </div>
            </div>
          </div>
          <div
            :class="checkedColumnsList &&
                  checkedColumnsList.length > screenSizeCheck
                  ? ' stage-dash bodyContent stageBody'
                  : 'fullwidthContainer stage-dash bodyContent stageBody'
            "
            v-if="checkedColumnsList.includes('Pipeline')"
          >
            <p class="pipelineLabel">
              {{ handlePipeline(lead.lead_details.pipeline) }}
            </p>
          </div>
          <div
            :class="checkedColumnsList &&
                  checkedColumnsList.length > screenSizeCheck
                  ? ' stage-dash bodyContent stageBody'
                  : 'fullwidthContainer stage-dash bodyContent stageBody'
            "
            v-if="checkedColumnsList.includes('Stage')"
          >
            <el-select
              v-model="lead.lead_details.stage_object"
              @change="changeLeadStage(lead)"
              @focus='changeStage(lead.lead_details.stage_object)'
              placeholder="Stage"
            >
              <el-option
                v-for="stage in handleStage(lead.lead_details.pipeline)"
                :key="stage.id"
                :label="stage.name"
                :value="stage.id"
              >
                {{ stage.name }}
                <b-icon style="margin-left:10px;" :color="iconColor(stage)" :icon="showIcon(stage)"></b-icon>
              </el-option>
            </el-select>
            <!-- <p class="pipelineLabel">{{ lead.lead_details.stage }}</p> -->
          </div>
          <div
            :class="checkedColumnsList &&
                  checkedColumnsList.length > screenSizeCheck
                  ? ' stage-dash bodyContent sourceBody'
                  : 'fullwidthContainer stage-dash bodyContent sourceBody'
            "
            v-if="checkedColumnsList.includes('Lead Source')"
          >
            <div style="display: flex; align-items: center; gap: 8px">
              <p class="typeLine">{{ handleLeadSource(lead?.lead_details?.lead_source_object,lead) }}</p>
            </div>
          </div>
          <div
            :class="checkedColumnsList &&
                  checkedColumnsList.length > screenSizeCheck
                  ? ' stage-dash bodyContent sourceBody'
                  : 'fullwidthContainer stage-dash bodyContent sourceBody'
            "
             v-if="checkedColumnsList.includes('Region') && isRelianceOrg"
          >
            <div style="display: flex; align-items: center; gap: 8px">
              <p class="typeLine">{{handleRegion( lead?.lead_details?.region)}}</p>
            </div>
          </div>
          <div
            :class=" checkedColumnsList &&
                  checkedColumnsList.length > screenSizeCheck
                  ? ' stage-dash bodyContent sourceBody'
                  : 'fullwidthContainer stage-dash bodyContent sourceBody'
            "
            v-if="checkedColumnsList.includes('Probability')"
          >
            <div style="display: flex; align-items: center; gap: 8px">
              <p class="typeLine">{{ lead.lead_details.probability?lead.lead_details.probability:0}} %</p>
            </div>
          </div>
          <div
            :class=" checkedColumnsList &&
                  checkedColumnsList.length > screenSizeCheck
                  ? ' owner-dash bodyContent ownerBody'
                  : 'fullwidthContainer owner-dash bodyContent ownerBody'
            "
            v-if="checkedColumnsList.includes('Owner')"
          >
            <p
              class="ownerN"
              :style="{
                backgroundColor: generateColorFromName(
                  lead?.lead_details?.owner
                )
                  ? generateColorFromName(lead?.lead_details?.owner)
                  : '#1c3366',
              }"
              v-if="(lead?.lead_details?.owner ? lead?.lead_details?.owner : '')&&checkdifferntOrg(lead)"
            >
              {{ returnInitials(lead?.lead_details?.owner) }}
            </p>
            <p class="orgOwnerIcon"  v-else><img src="../../../assets/img/buildings.svg" alt=""></p>
            <!-- <span class="ownerLine">{{
                      lead?.lead_details?.owner? lead?.lead_details?.owner : "—" }}
                    </span> -->
            <el-tooltip placement="bottom" :hide-after="0" :disabled="!showTooltip(lead?.lead_details?.owner,14)">
              <template v-slot:content>
              <div>
                <span
                  >{{
                    lead?.lead_details?.owner ? lead?.lead_details?.owner : "—"
                  }}
                </span>
              </div>
              </template>
              <span class="ownerLine"
                >{{
                  handleOwner(lead)
                }}
              </span>
            </el-tooltip>
          </div>
          <div
            :class=" checkedColumnsList &&
                  checkedColumnsList.length > screenSizeCheck
                  ? ' type-dash bodyContent typeBody'
                  : 'fullwidthContainer type-dash bodyContent typeBody'
            "
            v-if="checkedColumnsList.includes('Type')"
          >
            <div style="display: flex; align-items: center; gap: 8px">
              <img
                :src="
                  lead?.project_type == 'residential'
                    ? residentialIcon
                    : commercialIcon
                "
              />
              <p class="typeLine">{{ lead?.project_type }}</p>
            </div>
          </div>
          <div
            :class="checkedColumnsList &&
                  checkedColumnsList.length > screenSizeCheck
                  ? ' createdOn-dash bodyContent createdOnBody'
                  : 'fullwidthContainer createdOn-dash bodyContent createdOnBody'
            "
            v-if="checkedColumnsList.includes('Created On')"
          >
            <p class="createdOnLine">
              {{
                handleFormatDate(
                  lead?.lead_details?.created_at,
                  typeConstants.shortDate,
                  isUSFlagEnabled
                )
              }}
            </p>
          </div>

          <div
            :class="checkedColumnsList &&
                  checkedColumnsList.length > screenSizeCheck
                  ? ' targetCloseDate-dash bodyContent targetCloseBody'
                  : 'fullwidthContainer targetCloseDate-dash bodyContent targetCloseBody'
            "
            v-if="checkedColumnsList.includes('Target Close Date')"
          >
            <p class="createdOnLine">
              {{
                lead?.lead_details?.target_closure_date
                  ? handleFormatDate(
                      lead?.lead_details?.target_closure_date,
                      typeConstants.shortDate,
                      isUSFlagEnabled
                    )
                  : "—"
              }}
            </p>
          </div>
          <div
            :class="checkedColumnsList &&
                  checkedColumnsList.length > screenSizeCheck
                  ? ' dealVal-dash bodyContent dealValBody'
                  : 'fullwidthContainer dealVal-dash bodyContent dealValBody'
            "
            v-if="checkedColumnsList.includes('Deal Value')"
          >
            <p class="dealValLine">
              {{ currencySymbol(lead)
              }}{{
                formatNumberWithCommas(
                  lead?.lead_details?.deal_value,
                  isIndianLocationOrAccount(lead)
                )
              }}
            </p>
          </div>
          <div
            :class="checkedColumnsList &&
                  checkedColumnsList.length > screenSizeCheck
                  ? ' latestNote-dash bodyContent latestNoteBody'
                  : 'fullwidthContainer latestNote-dash bodyContent latestNoteBody'
            "
            v-if="checkedColumnsList.includes('Latest Note')"
          >
            <el-tooltip
              v-if="lead?.lead_details?.latest_notes"
              class="item"
              effect="dark"
              :content="formatNotes(lead?.lead_details?.latest_notes)"
              placement="top"
              popper-class="lead-management-table-notes"
            >
              <p class="latestNoteLine">
                {{ lead?.lead_details?.latest_notes }}
              </p>
            </el-tooltip>
            <p v-else>—</p>
          </div>
          <div
            :class="checkedColumnsList &&
                  checkedColumnsList.length > screenSizeCheck
                  ? ' sysSize-dash bodyContent sysSizeBody'
                  : 'fullwidthContainer sysSize-dash bodyContent sysSizeBody'
            "
            v-if="checkedColumnsList.includes('System Size')"
          >
            <p class="sysSizeLine">
              {{
                formatNumberWithCommas(
                  lead?.lead_details?.estimated_system_size || 0,
                  isIndianLocationOrAccount(lead)
                )
              }}
              kWp
            </p>
          </div>
          <div
            :class=" checkedColumnsList &&
                  checkedColumnsList.length > screenSizeCheck
                  ? ' lastCont-dash bodyContent lastContBody'
                  : 'fullwidthContainer lastCont-dash bodyContent lastContBody'
            "
            v-if="checkedColumnsList.includes('Last Contacted')"
          >
            <p class="lastContLine">
              {{ lead?.lead_details?.last_contacted || "—" }}
            </p>
          </div>
          <div
            :class="checkedColumnsList &&
                  checkedColumnsList.length > screenSizeCheck
                  ? ' lastCont-dash bodyContent lastContBody'
                  : 'fullwidthContainer lastCont-dash bodyContent lastContBody'
            "
            v-if="checkedColumnsList.includes('Country')"
          >
            <p class="lastContLine">{{ lead?.country?.name || "—" }}</p>
          </div>
        </div>
        <div
          v-infinite-scroll="loadMoreLeads"
          infinite-scroll-disabled="busy"
          infinite-scroll-distance="10"
          style="text-align: center"
        >
          <i v-if="busy" class="el-icon-loading infiniteScrollLoader" />
        </div>
      </div>
    </div>
    <EmptySiteSurveyLinkPopUp
      :emptySiteSurvey.sync="emptySiteSurvey"
      :closeEmptySiteSurveyPopUp.sync="closeEmptySiteSurveyPopUp"
      :isModal="isDrilldown ? false : true"
    />
    <LeadsDrawer
      v-if="drawer"
      :drawer.sync="drawer"
      :focusNote="focusOnAddNote"
      :lead="activeLead"
      :currentRowNumber="currentRowNumber"
      @close="[(drawer = false), (activeTab = 'first')]"
      @update-owner="updateOwnerInCurrentRow"
      @update-stage="updateStageInCurrentRow"
      @update-lead-source="updateLeadSourceInCurrentRow"
      @update-region="updateRegionInCurrentRow"
      @update-probability="updateProbabilityInCurrentRow"
      :activeTab="activeTab"
      @updated-tags="updateTagsInCurrentRow"
      @deleted-lead="updateAllLeadsData"
    />
    <setReminder
      v-if="!isDrilldown"
      :key="setKey"
      :showCreateDrawer="showCreateDrawer"
      @close="showCreateDrawer = false"
      :lead="activeLead"
      @save="onSave"
      :drawerSize="400"
    />
    <DealValue v-if="dealCheck"
    :isDeleteProjectDocumentPopupOpen=true
    :title="`Deal Value Check`"
    :info="`Deal value for this lead is ${currencySymbolDialog}0.`"
    :info2="` Do you want to continue without updating it?`"
    @cancelDelete="cancelDelete"
    @confirmDelete="confirmDelete"/>
  </div>
</template>

<script>
import API from "@/services/api/";
import debounce from "debounce";
import LeadsDrawer from "./leadsDrawer.vue";
import DealValue from './dealValueCheckDialog.vue'
import setReminder from "./../../setReminder/setReminder.vue";
import {
  getLeadPipelineStages,
  generateColorFromName,
  formatNumberWithCommas,
  isUSFlagEnabled,
  isRelianceOrg,
  getUiFromStorage,
  setUiInStorage,
  returnInitials,
} from "../../../utils";
import {
  getCurrencySymbol
} from "../../../utils/numberFormat/currencyFormatter"; 
import { DateTime } from "luxon";
import currencySymbolNameMap from "@/pages/currency-symbol-name-map";
import residentialIcon from "./assets/residential.svg";
import commercialIcon from "./assets/commercial.svg";
import { formatDateTime, typeConstants } from "../../../utils/dateFormatter";
import EmptySiteSurveyLinkPopUp from "../../dashboard/components/emptySiteSurveyPopUp.vue";
import { DATABASE_URL } from "../../../constants";
import { mapState, mapActions } from "pinia";
import { useProjectStore } from "../../../stores/project";
import { useDashboardStore } from "../../../stores/dashboard";
import { useUiStore } from "../../../stores/ui";
import { useLeadStore } from "../../../stores/lead";


export default {
  emits: ["updateWidget", "update:isupdate"],
  components: {
    setReminder,
    DealValue,
    LeadsDrawer,
    EmptySiteSurveyLinkPopUp,
  },

  data() {
    return {
      dealCheck:false,
      isProceed:false,
      isRole:'',
      isReliance:JSON.parse(localStorage.getItem("organisation")) || {},
      allData:[],
      oldStageID:'',
      newStageID:'',
      currencySymbolDialog:'',
      countryCode:'',
      setKey: 0,
      startClosure: "",
      endClosure: "",
      lessDC: "",
      moreDC: "",
      ui: getUiFromStorage(),
      ownerId: JSON.parse(localStorage.getItem("user")).user_id,
      pipelineData: [],
      costLess: "",
      costMore: "",
      residentialIcon,
      commercialIcon,
      leadIndex: 0,
      showCreateDrawer: false,
      componentName: "setReminder",
      reminderDrawer: false,
      drawer: false,
      leadId: null,
      checked: true,
      isLoading: false,
      isSearchedLeadEmpty: false,
      isSearchingLeads: false,
      isGeneratingSiteSurvey: false,
      nextUrl: null,
      prevUrl: null,
      allLeadsList: [],
      busy: false,
      pipeline: getLeadPipelineStages(),
      filtersString: "",
      sortString: "",
      isSiteSurvey: false,
      closeEmptySiteSurveyPopUp: true,
      emptySiteSurvey: false,
      // pipeline: [
      //     {
      //         label: 'option1',
      //         value: 'option1',
      //     },
      //     {
      //         label: 'option2',
      //         value: 'option2',
      //     }
      // ],
      selectedStage: "option1",
      activeLead: null,
      regionsData:[],
      leadSourceData:[],
      activeTab: "first",
      currentRowNumber: 0,
      focusOnAddNote: false,
      typeConstants: typeConstants,
      leadsCount: "0 Lead",
      updateWidgets: false,
      screenSizeCheck: 2,
    };
  },
  props: {
    search: {
      type: String,
      default: "",
    },
    checkedColumnsList: {
      type: Array,
      default() {
       return  [
          "Pipeline",
          "Stage",
          "Owner",
          "Type",
          "Created On",
          "Deal Value",
          "Latest Note",
          "System Size",
          "Last Contacted",
          "Country",
        ]
      },
    },
    selectedSort: {
      type: [Array,String],
      default() {
       return  []
      },
    },
    selectedFilter: {
      type: [Array,String],
      default() {
       return  []
      },
    },
    DCvalue: {
      type: [Array,String],
      default() {
       return  []
      },
    },
    Systemcost: {
      type: [Array,String],
      default() {
       return  []
      },
    },
    expectClosure: {
      type: [Array,String],
      default() {
       return  []
      },
    },
    tablePipeline: {
      type: [Array,String],
      default() {
       return  []
      },
    },
    isDrilldown: {
      type: Boolean,
      default: false,
    },
    listQueryParams: {
      type: String,
      default: "",
    },
    updateValues: {
      type: Function,
      default: () => null,
    },
    widgetId: {
      type: Number,
      default: null,
    },
  },

  mounted() {
    this.checkScreenSize();
    window.addEventListener("resize", this.checkScreenSize);
    this.getPipeline();
    this.getRegions();
    this.getLeadSource();
    if (this.$props.isDrilldown) {
      this.fetchFilteredLeadsDrilldown(this.$props.listQueryParams);
    }
    let ui = getUiFromStorage();
    if (!this.$props.isDrilldown) {
      ui.leadManagement.filterListParams
        ? this.fetchFilteredLeads(this.$props.lis)
        : this.fetchAllLeads();
    }
  },
  created() {
    this.countryCode=JSON.parse(localStorage.getItem("organisation")).country_code
    this.isRole=JSON.parse(localStorage.getItem("user")).role
    this.searchAllLeads = debounce(this.searchAllLeads, 1000);
  },
  watch: {
    // isDrilldown: {
    //   handler(val) {
    //     let ui = getUiFromStorage();
    //     if (val === true) {
    //       this.fetchFilteredLeads(ui.leadManagement.filterListParams);
    //     }
    //   },
    // },
    updateWidgets: {
      immedete: true,
      handler(val) {
        this.$props.updateValues();
        this.$emit("updateWidget");
        // this.$emit("update:isupdate", val);
      },
    },
    listQueryParams: {
      immedete: true,
      handler(val) {
        let ui = getUiFromStorage();
        if (val) {
          this.fetchFilteredLeadsDrilldown(val);
        }
      },
    },
    search(val) {
      this.searchAllLeads(val);
    },
    selectedSort(val) {
      this.sortString = val;
      if (val) {
        // this.fetchFilteredLeads();
      }
    },
    selectedFilter(val) {
      let reqObj = "";
      val.map((k, inx) => {
        reqObj = reqObj + `${k[1]}=${k[2]}`;
        if (inx < val.length - 1) {
          reqObj += "&";
        }
      });
      this.filtersString = reqObj;
      if (val) {
        // this.fetchFilteredLeads();
      }
    },
    DCvalue(val) {
      this.lessDC = val[1] == undefined ? "" : val[1];
      this.moreDC = val[0] == undefined ? "" : val[0];
      if (val) {
        // this.fetchFilteredLeads();
      }
    },
    expectClosure(val) {
      if (val.length > 0) {
        this.startClosure = val[0].toISOString();
        this.endClosure = val[1].toISOString();
        if (val) {
          // this.fetchFilteredLeads();
        }
      } else {
        this.startClosure = "";
        this.endClosure = "";
        if (val) {
          // this.fetchFilteredLeads();
        }
      }
    },
    Systemcost(val) {
      this.costLess = val[1] == undefined ? "" : val[1];
      this.costMore = val[0] == undefined ? "" : val[0];
      if (val) {
        // this.fetchFilteredLeads();
      }
    },
    tablePipeline(val) {
      this.pipelineData = val;
      if (val) {
        // this.fetchFilteredLeads();
      }
    },
    drawer(val) {
      if (!val) {
        this.focusOnAddNote = false;
      }
    },
    updateWorkFlow(val){
      this.updateWorkflowCurrentRow(val,this.currentRowNumber)

    }
  },

  computed: {
    computedAllLeadsList() {
      return this.allLeadsList;
    },
    ...mapState(useUiStore,{
      isMobileView: state => state.isMobileView
    }),
    ...mapState(useLeadStore, {
      updateWorkFlow:(state)=>state.workflow_type,
      leadInfo:state=>state,
      }),

    isUSFlagEnabled,
    isRelianceOrg,
  },
  methods: {
    ...mapActions(useDashboardStore, {
      setDashboard: "SET_DASHBOARD_DETAILS",
    }),
    formatNotes(str) {
      if (str.length > 200) return str.slice(0, 200) + "...";
      return str;
    },
    cancelDelete() {
      this.isDeleteProjectDocumentPopupOpen = false;
      this.dealCheck=false
      let data=this.allData.find((project)=>project.id==this.newStageID.id)
      let pipe=this.pipeline.find((pipeline)=>pipeline.id==data.lead_details.pipeline)
      let stage= pipe.pipeline_stages.find((stage)=>stage.id==this.oldStageID)
      data.lead_details.stage_object=stage.id

      this.isProceed=false
    },
    handleLeadSource(id,leadDatas){
      const leadData={...leadDatas,...leadDatas?.lead_details}
      const organisationInfo= JSON.parse(localStorage.getItem("organisation")) || {};
      const user = JSON.parse(localStorage.getItem("user")) || {};
      const secondaryOrgs=organisationInfo?.secondary_org_details;
      let lead= this.leadSourceData.find((lead)=> lead.id==id)
      if(leadData?.is_shared){
       if(user?.organisation_id===leadData?.organisation_id){
        return lead ? lead.name : id;
      
       }else{
        if(organisationInfo?.aggregator){
          const result=  secondaryOrgs.find(org=>org.id===leadData?.organisation_id);
          return result?result?.name:'_';
        }else{
          return organisationInfo?.primary_org_details?.name||organisationInfo?.arka_sales_details?.name||'NA';
        }
       }


      }else{
        return lead ? lead.name : id

      }

      // if(val){
      // const lead = this.leadSourceData.find((leadSource) => leadSource.id == val);
      // return lead ? lead.name : val
      // }else {
      //   return ''
      // }
    },
    handleOwner(leadDatas){
      const leadData={...leadDatas,...leadDatas?.lead_details}
      const organisationInfo= JSON.parse(localStorage.getItem("organisation")) || {};
      const secondaryOrgs=organisationInfo?.secondary_org_details;
      const user = JSON.parse(localStorage.getItem("user")) || {};
     
      if(leadData?.is_shared){
       if(user?.organisation_id===leadData?.owner?.org_id||user?.organisation_id===leadData?.owner_org_id){
        return leadData?.lead_details?.owner ? leadData?.lead_details?.owner : "—";
      
       }
       else if(organisationInfo?.is_vip_aggregator){
        return leadData?.lead_details?.owner_org_name ? leadData?.lead_details?.owner_org_name : "—";

       }
       else{
        if(organisationInfo?.aggregator){
       const result=  secondaryOrgs.find(org=>org.id===leadData?.owner_org_id);
          return result?result?.name:'_';
        }else{
          return organisationInfo?.primary_org_details?.name||'NA';
        }
       }


      }else{
        return leadData?.lead_details?.owner ? leadData?.lead_details?.owner : "—";

      }

    
    },
    handleRegion(val){
      if(val){
      const lead = this.regionsData.find((region) => region.id == val);
      return lead ? lead.name : val
      }else {
        return ''
      }
    },
    confirmDelete(){
      this.isDeleteProjectDocumentPopupOpen = false;
      this.dealCheck=false
      this.isProceed=true
      this.changeLeadStage(this.newStageID)
    },
    async getPipeline() {
      let ui = getUiFromStorage();
      let response = await API.LEADS.GET_PIPELINES();

      this.pipeline = response.data.pipelines;
      // this.computedAllLeadsList();
    },
    showIcon(id){
      if(id.stage_type=='close'){
        if(id.close_stage_type=='won'){
          return 'hand-thumbs-up'
        }
        else if(id.close_stage_type=='lost'){
          return 'hand-thumbs-down'
        }else{
          return 'dash-square'
        }
      }
    },
    iconColor(id){
      if(id.stage_type=='close'){
        if(id.close_stage_type=='won'){
          return 'green'
        }
        else if(id.close_stage_type=='lost'){
          return 'red'
        }else{
          return 'grey'
        }
      }
    },
    redirectToSummary(data) {
      if (data.lead_details) {
        const leadId = data.lead_details.id;
        this.$router.push({ name: "leadSummary", params: { leadId } });
      }
    },
    handleStage(ID) {
      const stage = this.pipeline.find((pipeline) => pipeline.id == ID);

      return stage ? stage.pipeline_stages : {};
    },
    handlePipeline(ID) {
      const Pipeline = this.pipeline.find((pipeline) => pipeline.id == ID);
      return Pipeline ? Pipeline.name : ID;
    },
    async getRegions(){
      let response = await API.CRMSETTINGS.FETCH_ALL_REGIONS();
      this.regionsData=response.data
    },
    async getLeadSource(){
      let response = await API.CRMSETTINGS.FETCH_ALL_LEAD_SOURCE();
      this.leadSourceData=response.data
    },
    siteSurveyOptionToolTip(lead) {
      if (!lead.address) {
        return "Please Add Address";
      } else if (lead.address && lead.site_survey_token === null) {
        return "Get Site Survey Link";
      } else {
        return "View Site Survey";
      }
    },
    onClickSiteSurvey(lead) {
      let siteSurveyToken = lead.site_survey_token;
      if (siteSurveyToken) {
        this.viewSiteSurvey(lead);
      } else {
        this.generateSiteSurveyToken(lead);
      }
    },
    async viewSiteSurvey(lead) {
      const surveyData = lead.site_survey_token;
      const URL = `${DATABASE_URL}api/site-survey-details/${surveyData}/`;
      const user = JSON.parse(localStorage.getItem("user")) || {};
      const { token } = user;

      const myHeaders = new Headers();
      myHeaders.append("authorization", `Token ${token}`);
      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      await fetch(URL, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          const data = JSON.parse(result);
          if (
            data.site_survey_details.length === 0 &&
            this.closeEmptySiteSurveyPopUp
          )
            this.emptySiteSurvey = true;
        });
      if (surveyData && !this.emptySiteSurvey) {
        const routeData = this.$router.resolve({
          name: "mediaBox",
          params: { surveyId: surveyData },
        });
        window.open(routeData.href, "_blank");
      }
    },
    async generateSiteSurveyToken(lead) {
      this.isSiteSurvey = true;
      this.isGeneratingSiteSurvey = true;
      try {
        let response = await API.SITE_SURVEY_LINK.FETCH_SITE_SURVEY_LINK();
        let siteSurveyToken = response.data.token;
        await API.PROJECTS.PATCH_PROJECT(lead.id, {
          site_survey_token: this.siteSurveyToken,
        });
        lead.site_survey_token = siteSurveyToken;
      } catch (e) {
        console.error("error", e);
      }
      this.isGeneratingSiteSurvey = false;
    },
    handleFormatDate(isoDateTime, type, isUS) {
      return formatDateTime(isoDateTime, type, isUS);
    },
    async fetchFilteredLeadsDrilldown(qparams) {
      try {
        this.allLeadsList = [];
        this.allData=[];
        this.isLoading = true;
        this.isSearchingLeads = false;
        this.nextUrl = null;
        let ui = getUiFromStorage();
        const response = await API.LEADS.FETCH_LEAD_LIST(qparams);
        if (this.isSearchingLeads) {
          return;
        }
        //---------------------------------------------------------------------//
        this.assignAPIResponse(response,false);
        this.isLoading = false;
        if(this.nextUrl&&this.$props.isDrilldown){
        this.loadMoreLeads();
        
      }
      } catch (e) {
        console.error(e);
        this.$message({
          showClose: true,
          message: this.$props.isDrilldown
            ? "Error in fetching Data. Please try again later."
            : "There was an unknown error while sorting leads",
          type: "error",
          center: true,
        });
      }
    },
    async fetchFilteredLeads() {
      try {
        this.allLeadsList = [];
        this.allData=[];
        this.isLoading = true;
        this.isSearchingLeads = false;
        this.nextUrl = null;
        let ui = getUiFromStorage();
        const response = await API.LEADS.FETCH_LEAD_LIST(
          ui.leadManagement.filterListParams
        );
        if (this.isSearchingLeads) {
          return;
        }
        //---------------------------------------------------------------------//
        this.assignAPIResponse(response,false);
        this.isLoading = false;
      } catch (e) {
        console.error(e);
        this.$message({
          showClose: true,
          message: "There was an unknown error while sorting leads",
          type: "error",
          center: true,
        });
      }
    },
    async fetchSortedLeads(keypoints) {
      try {
        this.allLeadsList = [];
        this.allData=[];
        this.isLoading = true;
        this.isSearchingLeads = false;
        const response = await API.LEADS.FETCH_SORTED_LEADS(
          keypoints[2],
          keypoints[1]
        );
        if (this.isSearchingLeads) {
          return;
        }
        //---------------------------------------------------------------------//
        this.assignAPIResponse(response,false);
        this.isLoading = false;
      } catch (e) {
        console.error(e);
        this.$message({
          showClose: true,
          message: "There was an unknown error while sorting leads",
          type: "error",
          center: true,
        });
      }
    },
    onSave(remainderDetails) {
      this.allLeadsList[this.leadIndex].reminder_details = remainderDetails;

      this.showCreateDrawer = false;
    },
    onSetReminder(val, index) {
      this.setKey++;
      this.leadIndex = index;
      this.activeLead = val;
      this.showCreateDrawer = true;
    },
    async fetchAllLeads() {
      try {
        this.allLeadsList = [];
        this.allData=[];
        this.isLoading = true;
        this.isSearchingLeads = false;
        const response = await API.LEADS.FETCH_ALL_LEADS();
        //--------- we dont to use Lead API's results if we searched somehting
        if (this.isSearchingLeads) {
          return;
        }
        //---------------------------------------------------------------------//
        this.assignAPIResponse(response,false);
        this.isLoading = false;
      } catch (e) {
        console.error(e);
        this.$message({
          showClose: true,
          message:
            "There was an unknown error while fetching lead information.",
          type: "error",
          center: true,
        });
      }
    },
    assignAPIResponse(response, loadMore) {
      this.nextUrl = response.data.next;
      this.prevUrl = response.data.previous;
      let resultTableData = response.data.results;
      if (this.$props.isDrilldown && !loadMore) {
        this.allLeadsList = [];
        this.allData=[];
      }
      if(!loadMore){
        this.allLeadsList = [];
        this.allData=[];
      }
     
      this.leadsCount =
        response.data.count && response.data.count > 1
          ? `${response.data.count} Leads`
          : `${response.data?.count || 0} Lead`;
      for (let obj of resultTableData) {
        // let tempLeadObject = {};
        // let obj = resultTableData[key];
        // tempLeadObject["id"] = obj.id;
        // tempLeadObject["leadName"] = obj?.lead_details?.name || "";
        // tempLeadObject["projectType"] = obj.project_type || "";
        // tempLeadObject["owner"] = obj?.lead_details?.owner || '-';
        // tempLeadObject["deal_value"] = obj?.lead_details?.deal_value || '-';
        // tempLeadObject["last_contacted"] = obj?.lead_details?.last_contacted || '-';

        // let curDate = obj?.lead_details?.created_at.split("T")[0];
        // curDate = new Date(curDate);
        // curDate = curDate.toDateString();
        // let year = `${curDate[11]}${curDate[12]}${curDate[13]}${curDate[14]}`;
        // let month = `${curDate[4]}${curDate[5]}${curDate[6]}`;
        // let dt = `${curDate[8]}${curDate[9]}`;
        // let modifiedDate = `${dt} ${month} ${year}`;
        // tempLeadObject["date"] = modifiedDate;

        // tempLeadObject['stage'] = obj?.lead_details?.stage;

        obj.lead_details.originalStage = obj.lead_details.stage;
        this.allData.push(obj)
        this.allLeadsList.push(obj);
      }
    },
    async loadMoreLeadsHelper() {
      try {
        const response = await API.PROJECTS.LOAD_MORE_PROJECTS(this.nextUrl);
        this.assignAPIResponse(response, true);
        this.busy = false;
      } catch (error) {
        console.error();
      }
    },

    loadMoreLeads() {
      if (this.nextUrl !== null) {
        this.busy = true;
        this.loadMoreLeadsHelper();
      }
    },
    searchAllLeads(query) {
      if (query !== "") {
        this.isLoading = true;
        setTimeout(() => {
          this.searchAllLeadsHelper(query);
        }, 200);
      } else {
        // fetching all leads
        this.allLeadsList = [];
        this.allData=[];
        this.isLoading = true;
        this.fetchAllLeads();
      }
    },
    async searchAllLeadsHelper(query) {
      try {
        this.isSearchedLeadEmpty = true;
        this.isLoading = true;
        this.isSearchingLeads = true;
        const response = await API.LEADS.SEARCH_ALL_LEADS(query);
        this.allLeadsList = [];
        this.allData=[];
        this.assignAPIResponse(response,false);
        this.isLoading = false;

        if (this.allLeadsList.lenght > 0) this.isSearchedLeadEmpty = false;
      } catch (error) {
        console.error(error);
      }
    },
    // TODO: Update this once reminder is available in the response
    reminderTimeText(lead) {
      let timeString = lead?.reminder_details?.reminder_sent_at;
      if (!timeString | !lead?.reminder_details) {
        return "Unknown";
      }
      const user = JSON.parse(localStorage.getItem("user")) || {};
      const isUsUser = user.isUSFlagEnabled;

      const dateTime =
        formatDateTime(timeString, "Date Dash", isUsUser) +
        " | " +
        formatDateTime(timeString, "Short Time", isUsUser);
      return dateTime;
      // let date = DateTime.fromISO(timeString);
      // let finalString = date.toFormat("dd-LL-yyyy | h:mm a");
      // return finalString;
    },
    openLeadDrawer(lead, index, event) {
      if (this.isMobileView) {
        this.redirectToSummary(lead)
        return
      }
      let eventTarget = event.target;
      let ignoreClass = ".site-survey-button";
      let parent = eventTarget.closest(ignoreClass);

      if (parent) { return }
      if (this.showCreateDrawer) { return }

      if(this.isRole=='DESIGNER'){
        if(this.focusOnAddNote || this.activeTab =='second'){
          this.drawer = true;
          this.activeLead = lead;
          this.currentRowNumber = index;
          return
        }else{
          this.redirectToSummary(lead)
      
          this.focusOnAddNote=false
          this.activeTab='first'
        }
      }else{
        this.drawer = true;
        this.activeLead = lead;
        this.currentRowNumber = index;
      }
    },
    addNoteClick(event, lead, index) {
      event.stopPropagation();
      this.focusOnAddNote = true;
      this.openLeadDrawer(lead, index, event);
      
    },
    changeStage(val){
      this.oldStageID=val
    },
    async changeLeadStage(lead) {
      this.newStageID=lead
      let pipe=this.pipeline.find((pipeline)=>pipeline.id==lead.lead_details.pipeline)
      let stage= pipe.pipeline_stages.find((stage)=>stage.id==lead.lead_details.stage_object)
      this.currencySymbolDialog=getCurrencySymbol(lead.country?lead.country.country_code:this.countryCode)
      if(stage.close_stage_type=='won' && lead.lead_details.deal_value<=0 && this.isProceed==false){
        this.dealCheck=true
      }else{
        this.isProceed=false
        this.oldStageID=lead.lead_details.stage_object
      try {
        await API.LEADS.UPDATE_LEAD(lead.lead_details.id, lead.id, {
          stage_object: lead.lead_details.stage_object,
        });
        lead.lead_details.originalStage = lead.lead_details.stage;
        // this.$emit("updateWidget");

        if (this.$props.isDrilldown) {
          let updatewidgersData = {
            id: this.$props.widgetId,
            flag: !this.updateWidgets,
          };
          this.setDashboard(updatewidgersData);
          this.fetchFilteredLeadsDrilldown(this.$props.listQueryParams);
        }
      this.updateWidgets=!this.updateWidgets
        this.$emit("update:isupdate", !this.updateWidgets);
      } catch (err) {
        console.error(err);
        if (!this.$props.isDrilldown) {
          this.$message({
            showClose: true,
            message: "There was an error while updating the stage.",
            type: "error",
            center: true,
          });
        }
        this.oldStageID=lead.lead_details.stage_object
        lead.lead_details.stage = lead.lead_details.originalStage;
      }
    }
    },
    convertDateFormat(dateString) {
      let date = new Date(dateString);
      let curDate = date.toDateString();
      let year = `${curDate[11]}${curDate[12]}${curDate[13]}${curDate[14]}`;
      let month = `${curDate[4]}${curDate[5]}${curDate[6]}`;
      let dt = `${curDate[8]}${curDate[9]}`;
      let modifiedDate = `${dt} ${month} ${year}`;
      return modifiedDate;
    },
    isIndianLocationOrAccount(lead) {
      // If location is indian then follow indian format and if location is not added, the if country code is 91 then indian format
      if (lead?.country?.country_code == "IN") {
        return true;
      } else if (!lead.country) {
        let countryCode = JSON.parse(
          localStorage.getItem("organisation")
        ).country;
        if (countryCode == 91) return true;
      }
      return false;
    },
    currencySymbol(lead) {
      if (lead?.country?.country_code)
        return currencySymbolNameMap[lead?.country?.currency_code];
      // when no address is selected
      else {
        if (this.isUSFlagEnabled) return "$";
        else return "₹";
      }
    },
    onClickTasks(event, lead, index) {
      event.stopPropagation();
      this.activeTab = "second";
     
      this.openLeadDrawer(lead, index, event);
    },
    updateOwnerInCurrentRow(selectedOwnerObj, rowNumber) {
      if (typeof selectedOwnerObj == "object") {
        this.allLeadsList[rowNumber].lead_details.owner =
          selectedOwnerObj.first_name + " " + selectedOwnerObj.last_name;
      } else {
        this.allLeadsList[rowNumber].lead_details.owner = selectedOwnerObj;
      }
    },
    updateStageInCurrentRow(stage, rowNumber, pipeline) {
      this.allLeadsList[rowNumber].lead_details.stage = stage;
      // this.allLeadsList[rowNumber].lead_details.pipeline = pipeline;
    },
    updateLeadSourceInCurrentRow(leadSource, rowNumber) {
      const leadSourceId=leadSource
      const lead = this.leadSourceData.find((leadSource) => leadSource.id == leadSourceId);
      this.allLeadsList[rowNumber].lead_details.lead_source_object = lead.name;
    },
    updateRegionInCurrentRow(region , rowNumber){
      const regionId=region
      const regions = this.regionsData.find((region) => region.id == regionId);
      this.allLeadsList[rowNumber].lead_details.region = regions.name;
    },
    updateProbabilityInCurrentRow(probability , rowNumber){
      this.allLeadsList[rowNumber].lead_details.probability = probability;
    },
    updateTagsInCurrentRow(allTags, rowNumber) {
      this.allLeadsList[rowNumber].tags = [...allTags];
    },
    updateWorkflowCurrentRow(workflow, rowNumber) {
      this.allLeadsList[rowNumber].lead_details.workflow_type =workflow;
    },
    generateColorFromName,
    formatNumberWithCommas,
    returnInitials,
    checkScreenSize() {
      if(!this.isDrilldown){
         if (window.innerWidth >= 2200) {
        this.screenSizeCheck =11;
      }
      else if (window.innerWidth >= 2100) {
        this.screenSizeCheck = 10;
      } 
        else if (window.innerWidth >= 2000) {
        this.screenSizeCheck = 9;
      } 
      else if (window.innerWidth >= 1800) {
        this.screenSizeCheck =8;
      }
      else if (window.innerWidth >= 1700) {
        this.screenSizeCheck = 7;
      } else if (window.innerWidth >= 1550) {
        this.screenSizeCheck = 6;
      } else if (window.innerWidth >= 1500) {
        this.screenSizeCheck = 5;
      } else {
        this.screenSizeCheck = 5;
      }
      }else{
      if (window.innerWidth >= 2000) {
        this.screenSizeCheck = 7;
      } else if (window.innerWidth >= 2000) {
        this.screenSizeCheck = 5;
      } else if (window.innerWidth >= 1550) {
        this.screenSizeCheck = 4;
      } else if (window.innerWidth >= 1500) {
        this.screenSizeCheck = 3;
      } else {
        this.screenSizeCheck = 2;
      }
    }
      this.isSmallScreen = window.innerWidth <= 768;
    },
    showTooltip(value,size) {
      // Check the length of the value and decide whether to show the tooltip
      return value && value.toString().length > size;
    },
    checkdifferntOrg(lead){
      const userInfo=JSON.parse(localStorage.getItem("user")) || {};
     return lead?.lead_details?.owner_org_id===userInfo.organisation_id?true:false; 

    },
    updateAllLeadsData(leadData){
      this.allLeadsList=this.allLeadsList.filter(d=>d.id!==leadData.id);
      this.drawer=false;
    

    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkScreenSize);
  },
};
</script>

<style scoped>
.disabled {
  opacity: 0.6;
  pointer-events: none;
}

.assignmentIcon.disabled {
  filter: grayscale(100%);
}

p,
span,
h1,
h2,
h3 {
  word-break: break-word;
}
.leadscount {
  padding-left: 10px;
  font-family: "Helvetica Neue";
  color: black;
  font-size: 16px;
  font-weight: 600;
}
.tableContainer {
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 16px;
  width: fit-content;
  max-width: 100%;
  overflow: hidden;
  overflow-x: scroll;
  /* margin-top: 24px; */
}
.tableContainer-lead {
  margin-top: 24px;
}
.tableContainer-dash {
  margin-top: 5px;
  width: 100% !important;
  max-width: 100% !important;
  overflow: hidden !important;
  overflow-x: scroll !important;
}
.parentBodyContainer {
  height: calc(100vh - 340px);
  overflow: hidden;
  overflow-y: scroll;
  width: fit-content;
}

.tableHeader {
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
}

.tableHeader,
.bodyContainer {
  display: flex;
  align-items: center;
  height: 60px;
  padding-left: 8px;
  border-bottom: 1px solid #ccc;
  width: fit-content;
  transition: 0.2s;
}

.bodyContainer:hover {
  background-color: rgb(245, 245, 245);
}

.bodyContainer {
  height: auto;
  width: fit-content;
  cursor: pointer;
}

.headerTitle {
  font-size: 16px;
  font-weight: 600;
  padding-left: 16px;
  padding-right: 16px;
  color: #1c3366;
  height: 60px;
  display: flex;
  align-items: center;
}

.leadFlex,
.leadIcons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.leadIcons {
  gap: 16px;
}

/* .leadIcons *:hover {
    background: rgb(240, 240, 240);
} */

.el-tooltip__popper >>> .tooltipClass {
  padding: 4px;
}

.tooltipCommon {
  position: relative;
}

.tooltipContainer {
  width: max-content;
  background-color: #fff;
  position: absolute;
  z-index: 100;
  bottom: 30px;
  padding: 4px;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display: none;
}

.tooltipText {
  font-size: 12px;
  color: #222;
}

.tooltipCommon:hover .tooltipContainer {
  display: inherit;
}

.leadFlex {
  width: 100%;
  padding: 12px 0px;
}

.leadDetcolumn {
  display: grid;
  gap: 8px;
}

.name {
  font-size: 16px;
  font-weight: 600;
  color: #222;
  max-width: 280px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.time {
  font-size: 12px;
  font-weight: 600;
  color: #777;
  display: flex;
  gap: 8px;
}

.time::before {
  content: "";
  background: url("./assets/Vector.svg");
  width: 13.5px;
  height: 13.5px;
  display: block;
}

.remainder {
  font-size: 14px;
  font-weight: 600;
  color: #409eff;
  display: flex;
  gap: 8px;
  cursor: pointer;
  width: fit-content;
}

.remainder:hover {
  background: rgb(230, 230, 230);
}

.remainder::before {
  content: "";
  background: url("./assets/event.svg");
  width: 12.3px;
  height: 14px;
  display: block;
}

.leadDet,
.leadDetBody {
  width: 390px;
  height: 100%;
  border-right: 1px solid #ccc;
}
.leadDet-dash {
  min-width: 390px;
}
.slnobody,
.slnoDet {
  width: 80px;
  height: 100%;
  border-right: 1px solid #ccc;
}
.slnoDet-dash {
  min-width: 80px !important;
  max-width: 80px !important;
}
.stage,
.sourceBody,
.stageBody {
  width: 160px;
  height: 100%;
  border-right: 1px solid #ccc;
}

.stageBody,
.sourceBody,
.stage {
  width: 169px;
}

.stage-dash {
  min-width: 169px;
}

.owner,
.ownerBody,
.type,
.typeBody {
  width: 200px;
  height: 100%;
  border-right: 1px solid #ccc;
}
.el-select-dropdown__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.owner-dash {
  min-width: 200px;
}

.latestNote,
.latestNoteBody {
  width: 240px;
  height: 100%;
  border-right: 1px solid #ccc;
}
.latestNote-dash {
  min-width: 240px;
}

.createdOn,
.createdOnBody,
.dealVal,
.dealValBody,
.sysSize,
.sysSizeBody,
.lastCont,
.lastContBody {
  width: 175px;
  border-right: 1px solid #ccc;
  height: 100%;
}

.lastCont-dash {
  min-width: 175px !important;
}

.lastCont,
.lastContBody {
  height: 100%;
}

.typeBody,
.type,
.dealVal,
.dealValBody,
.sysSize,
.sysSizeBody,
.createdOn,
.createdOnBody {
  width: 144px;
}
.sysSize-dash {
  min-width: 144px !important;
}
.dealVal-dash {
  width: 144px;
}
.createdOn-dash {
  min-width: 144px !important;
}
.type-dash {
  min-width: 144px !important;
}
.targetCloseDate,
.targetCloseBody {
  width: 137px;
  border-right: 1px solid #ccc;
  height: 100%;
}

.targetCloseDate-dash {
  min-width: 137px !important;
}
.bodyContent {
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  min-height: 68px;
}

.ownerN {
  display: grid;
  place-items: center;
  min-width: 40px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #1c3366;
  font-size: 16px;
  color: #fff;
  margin-right: 8px;
}

.orgOwnerIcon{
  display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: white;
    font-size: 16px;
    color: #fff;
    margin-right: 8px;
    border: 1px solid #ccc;
}

.createdOnLine,
.dealValLine,
.sysSizeLine,
.lastContLine,
.typeLine {
  font-size: 14px;
  color: #222;
  text-transform: capitalize;
}
.pipelineLabel {
  font-size: 14px;
  color: #222;
  /* text-transform: capitalize; */
}

.latestNoteLine {
  font-size: 14px;
  color: #222;
}
.sysSizeLine {
  text-transform: unset;
}
.disabled-content {
  pointer-events: none;
  opacity: 0.3;
}
.ownerLine {
  font-size: 16px;
  color: #222;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.latestNoteLine {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.tableContainer >>> .el-select {
  width: 100%;
  max-width: 135px;
}

.tableContainer >>> .el-input__inner {
  border: none;
  background-color: #e8edf2;
  height: 34px;
  color: #222;
}

.tableContainer >>> .el-select .el-input .el-select__caret {
  color: #222;
  font-weight: bold;
  position: relative;
  top: -1px;
  transform: rotate(0deg);
}

.tableContainer >>> .el-select .el-input .el-select__caret.is-reverse {
  position: relative;
  top: 2px;
  transform: rotate(180deg);
}

.tableContainer >>> .el-icon-arrow-up:before {
  content: url("./assets/CaretDownFill.svg");
}

.tableContainer >>> .el-input__inner::placeholder {
  font-size: 14px;
  /* color: #222; */
}

.infiniteScrollLoader {
  font-size: 20px;
}
.fullwidthContainer {
  width: 100% !important;
  /* flex: 1 !important; */
}
.fullWidthHeader {
  width: 100% !important;
  /* flex: 1 !important; */
}
.fullWidthBody {
  width: 100%;
  /* flex: 1 !important; */
}
.tableHeader-dash {
  width: 100% !important;
}
.tableContainer-dash >>> ::-webkit-scrollbar {
  width: 5px; /* Adjust this width as needed */
}

/* Hide scrollbar track */
.tableContainer-dash >>> ::-webkit-scrollbar-track {
  background-color: transparent;
}

/* Hide scrollbar thumb */
.tableContainer-dash >>> ::-webkit-scrollbar-thumb {
  background-color: #ccc;
}
</style>
