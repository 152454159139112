<template>
  <div class="select-container">
    <p class="title">
      {{ items.title }} <span v-if="items.isStar" class="astrisk"> *</span>
    </p>
    <el-select
      v-model="selectedItem"
      :placeholder="items.placeholder"
      @change="handleSelect"
      multiple
      filterable
    >
      <el-option
        v-for="(item, indx) in optionsData"
        :key="item.id ? item.id : item.value ? item.value : item"
        :label="item?.label || item"
        :value="item.id || item"
      >
        <template v-slot:default>
          <div :class="items.options.length - 1 > indx ? 'option-lables option-lables-border' : 'option-lables'">
            <input type="checkbox" :value="item.id" v-model="selectedItem" />
            <span style="margin-left: 10px">{{ item.label || item }}</span>
          </div>
        </template>
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  emits: ["item-selected"],
  name: "selectComponentView",
  props: {
    items: {
      type: Object,
      default: () => {},
    },
    selectedValue: {
      type: String,
      default: () => "",
    },
    selected: {
      type: String,
      default: () => "",
    },
    indexOfOptions: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      selectedItem: [],
      radio: "",
      selectedData: "",
      optionsData: this.$props.items.options,
      selectedOptions: [],
      checkList: [],
      selectedCheck: [],
      bucketCheck: {},
    };
  },
  mounted() {
    // this.selectedItem = this.$props.selectedValue;
  },

  methods: {
    handleSelect(value) {
      this.selectedData = value;
      this.selectedItem = value.label ? value.label : value;
      const selectedData = this.optionsData.filter((d) => value.includes(d.id));
      this.bucketCheck = selectedData;

      if (this.bucketCheck) {
        this.bucketCheck = this.bucketCheck.find(
          (vl) => vl.metric_type === "float"
        );
      }
      this.$emit(
        "item-selected",
        this.$props.items.prop,
        value,
        value.value ? value.value : value,
        this.$props.items,
        this.$props.indexOfOptions,
        false,
        this.selectedOptions,
       false
      );
    },
  },

  watch: {
    checkList: {
      handler(val) {
        let uniqueValue = [...new Set(val)];
        // this.checkList = uniqueValue;
      },
    },
    selectedItem: {
      deep: true,
      immediate: true,
      handler(val) {
        // const filteredObjects = this.optionsData.filter((obj) =>
        //   val.includes(obj.id)
        // );
        // const selectedData = this.optionsData.filter((d) => val.includes(d.id));
if(val&&val?.length>0&&typeof(val)==='object'){
  const optionsListData=this.optionsData.length>0?this.optionsData :this.$props.items.options

    const selectedData=    val?.map(id => optionsListData.find(item => item.id === id))

        this.selectedCheck.length = 0;
        // this.selectedCheck.push(...this.checkList);
        this.selectedOptions.length = 0;
        selectedData.map((d) => {
          this.selectedCheck.push(d?.label);

      
            this.selectedOptions.push(d?.metric);
      
        });

        this.selectedCheck = [...new Set(this.selectedCheck)];
        this.checkList.length = 0;
        this.checkList.push(...this.selectedCheck);}
      },
    },
    items: {
      immediate: true,
      deep: true,
      handler(val) {
        const options = val.options;

        const isCheck = options.filter((d) => d.checked);

        if (isCheck.length === 0) {
          options.map((d) => {
            d.checked = false;
          });

          this.optionsData = options;
        }
      },
    },
    selectedValue: {
      immediate: true,
      handler(val) {
        if(typeof(val)=='object'){
          this.selectedItem = val;

        }
      },
    },
    radio: {
      immediate: true,
      handler(val) {
        if (!val) {
          this.radio = "Simple";
        }
        const isGrouped = val === "Grouped" ? true : false;
        if (this.selectedData?.value === "Table") {
          this.$emit(
            "item-selected",
            this.$props.items.prop,
            this.selectedData,
            this.selectedData.value
              ? this.selectedData.value
              : this.selectedData,
            this.$props.items,
            this.$props.indexOfOptions,
            isGrouped,
            this.selectedOptions
          );
        }
        this.radio = val;
      },
    },
    selectedData(value) {
      if (value) {
        const isGrouped = this.radio === "Grouped" ? true : false;

        // if (value.value !== "Table") {
        //   this.$emit(
        //     "item-selected",
        //     this.$props.items.prop,
        //     value,
        //     value.value ? value.value : value,
        //     this.$props.items,
        //     this.$props.indexOfOptions,
        //     false
        //   );
        // }
      }
      //   this.selectedItem = null;
    },
  },
};
</script>

<style scoped>
.select-container {
}
.title {
  padding-bottom: 0.5rem;
  font-size: 12px;
  font-weight: 600;
  color: #777;
}
.el-select {
  width: 100%;
}
.select-container >>> .el-input__inner {
  border: none;
  background: #e8edf2;
  height: 40px;
}
.select-container >>> .el-select .el-input .el-select__caret {
  color: black;
}
.select-container >>> .el-input__inner::placeholder {
  font-size: 14px;
  color: #777;
}
.el-select-dropdown__item {
  min-height: 42px !important;
  height: auto !important;
  line-height: 40px !important;
  padding: 0px 12px !important;
}
.option-lables-border {
  border-bottom: 1px solid #ccc;
}
.option-lables {
  color: black;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.icons {
  padding-right: 0.5rem;
}
.el-radio__inner {
  width: 1rem !important; /* Adjust this value to increase or decrease the size of the dot */
  height: 1rem !important; /* Adjust this value to increase or decrease the size of the dot */
}
.select-container >>> .el-radio__input.is-checked .el-radio__inner::after {
  transform: translate(-50%, -50%) scale(2.5) !important;
  background: white;
}
.astrisk {
  font-size: 12px;
  font-weight: 600;
  color: #f00;
}
</style>
