
<div class='col'>
  <div class="upload_area" @dragover="enableDragAndDrop && dragover" @drop="enableDragAndDrop && drop">
    <div :class="[(!this.$props.fromEsign) ? 'insicloseUploadde_content' : '', isBulkLeadsImport ? 'removeBorder' : '']">
     
      <div :class="['browse_button', ((this.$props.fromEsign) ? 'backGround' : '')]">
        <label :class="['select_label', isBulkLeadsImport ? 'labelLead' : '', isPipelineSelected ? 'cursorClass' : '']" v-show="!isWeatherFileUploading">
        
          <p :class="['Drag-files-to-upload', isBulkLeadsImport ? 'font20Normal' : '']">{{ uploadFileText }}</p>
          <p :class="['or', isBulkLeadsImport ? 'font20Normal': '']">or</p>
          <input
            v-show="!isWeatherFileUploading"
            class="btn btn-outline"
            type="file"
            ref="file"
            @change="onChange"
            :disabled="isWeatherFileUploading || isPipelineSelected"
            :multiple="ispdf?false:true" 
            :accept="ispdf ? 'application/pdf' : '*/*'"
            
          />
          <div :class="['browseBtnLead', isPipelineSelected ? 'cursorClass disabledBrowseBtnLead' : '']" v-if="isBulkLeadsImport">
            <p class="addContentSDLead" >Browse file</p>
          </div>
          <div class="BrowseBtn" v-else>
          <p class="addContentSD" >Browse File</p>
          </div>
        </label>
        <i class="el-icon-loading" v-show="isWeatherFileUploading"></i>
      </div>
    
    </div>
  </div>
  <p v-show="isInvalidFile" class="error-msg">* {{ errorMsg }}</p>
</div>
