
<div class="deleteModule">
  <el-dialog
    :visible="isDeleteProjectDocumentPopupOpen"
    :close-on-click-modal="false"
    title="Add User"
    width="30%"
    class="delete_module"
    @close="closeModal"
    append-to-body
  >
    <div class="close_button">
      <img
        src="../../../home/assets/img/close.svg"
        alt="close"
        @click="$emit('cancelDelete')"
      />
    </div>
    <div class="alert">
      <img
        src="../../../../assets/img/alert (1).svg"
        alt="alert"
        class="warningImage"
      />
    </div>
    <p class="msg">Are you sure you want to delete this file?</p>
    <div class="button_container">
      <el-button type="primary" @click="confirmDelete()">Yes</el-button>
    </div>
  </el-dialog>
</div>
