<template>
  <div class="tabsData">
    <div class="tariffDetailComp" v-if="activeName == 'TARIFF_DETAILS'">
      <div class="infoBlock">
        <p class="label">Utility Provider</p>
        <p class="textValue">{{ tariffDetails?.utility_provider }} </p>
      </div>
      <div class="infoBlock">
        <p class="label">Tariff Rate</p>
        <p class="textValue">{{ tariffDetails?.tariff_rate }} </p>
      </div>
      <div class="infoBlock">
        <p class="label">Billing Period</p>
        <p class="textValue">{{ tariffDetails?.billing_period }} </p>
      </div>
      <div class="infoBlock">
        <p class="label">Charge Period</p>
        <p class="textValue">{{ tariffDetails?.charge_period }} </p>
      </div>
      <div class="infoBlock">
        <p class="label">Does the Tariff have TOU rates?</p>
        <p class="boolValue">{{ yesToTrue(tariffDetails?.has_tou_rates) }}</p>
      </div>
      <div class="infoBlock">
        <p class="label">Does the Tariff has tiered rates?</p>
        <p class="boolValue">{{ yesToTrue(tariffDetails?.has_tiered_rates) }} </p>
      </div>
      <div class="infoBlock">
        <p class="label">Types of Charges</p>
        <p class="textValue">{{ tariffDetails?.charge_types }} </p>
      </div>
      <div class="infoBlock">
        <p class="label">Is the Tariff Solar Eligible?</p>
        <p class="boolValue">{{ yesToTrue(tariffDetails?.is_solar_eligible) }} </p>
      </div>
    </div>
    <div class="consumptionChargesComp" v-if="activeName == 'CONSUMPTION_BASED' || activeName == 'DEMAND_BASED'">
      <el-collapse accordion>
        <el-collapse-item :name="rateGroup.rate_group" v-for="rateGroup in chargeTypeData" :key="rateGroup">
          <template v-slot:title>
            <p class="tabHead">{{ rateGroup.rate_group }}</p>
          </template>
          <div class="table" v-if="rateGroup.rate_names[0].tier_ranges.length">
            <div class="row headerTable">
              <div class="cell">Rate Name</div>
              <div class="cell">Tier Range</div>
              <div class="cell">Rate Amount</div>
            </div>

            <!-- Render each row based on the structured data -->
            <div v-for="(row, index) in structuredData(rateGroup.rate_names)" :key="index" class="row">
              <div class="cell" data-label="Rate Name">{{ row.rateName }}</div>
              <div class="cell" data-label="Tier Range">{{ row.tierRange }}</div>
              <div class="cell" data-label="Rate Amount">{{ row.rate }}</div>
            </div>
          </div>
          <div class="eCBlock" v-else v-for="(row, index) in rateGroup.rate_names" :key="index">
            <p>{{ row.rate_name }}</p>
            <p>${{ row.rate }}</p>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <div class="fixedChargesComp" v-if="activeName == 'MINIMUM' || activeName == 'FIXED_PRICE'">
      <div class="eCBlock" v-for="rate in chargeTypeData" :key="rate">
        <p>{{ rate.rate_group_name }}</p>
        <p>${{ rate.rate }}/day</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    activeName: {
      default: "Charge",
      type: String,
    },
    chargeTypeData: {
      default: null,
    },
    tariffDetails: {
      default: {},
      type: ()=>{ }
    }
  },
  methods: {
    yesToTrue(value) {
      if(value)
       return "Yes";
      return "No";
    },
    structuredData(rateNames) {
      let result = [];

      rateNames.forEach(rate => {
        rate.tier_ranges.forEach((range, index) => {
          result.push({
            rateName: index === 0 ? rate.rate_name : '-',
            tierRange: `${range.lower_limit} - ${range.upper_limit !== null ? range.upper_limit : 'No Limit'}`,
            rate: range.rate
          });
        });
      });

      return result;
    },
    isTableData(rateGroup) {
      if (["Delivery Charges", "Generation Charges", "Ancillary Services"].includes(rateGroup)) {
        return true;
      }
      return false;
    }
  }
};
</script>

<style scoped>
.tariffDetailComp {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 12px;
}

.infoBlock {
  flex: 40%;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.label {
  font-size: 16px;
  font-weight: 400;
  line-height: 18.48px;
  text-align: left;
  color: #777;
}

.textValue {
  font-size: 16px;
  font-weight: 400;
  line-height: 18.48px;
  text-align: left;
  color: #222;
}

.boolValue {
  font-size: 16px;
  font-weight: 600;
  line-height: 18.48px;
  text-align: left;
  color: #5fcc29;
}


.table {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-collapse: collapse;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.headerTable {
  background-color: #f2f2f2;
  font-weight: bold;
}

.headerTable .cell {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: #777;
}

.cell {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

@media (max-width: 600px) {
  .row {
    flex-direction: column;
  }

  .cell {
    text-align: left;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
  }

  .cell::before {
    content: attr(data-label);
    font-weight: bold;
    margin-right: 10px;
  }
}

.eCBlock {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  padding: 0px 20px;
  font-weight: 400;
  line-height: 20px;
  text-align: right;
  color: #222;
  margin-bottom: 10px;
}

.tabHead {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: right;
}

.fixedChargesComp {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.tabsData {
  margin-bottom: 20px;
}

.tabsData>>>.el-collapse {
  flex-direction: column;
  display: flex;
  border-top: 1px solid #EBEEF5;
  border-bottom: 1px solid #EBEEF5;
  gap: 20px;
}

.tabsData>>>.el-collapse-item__header {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 10px;
}

.tabsData>>>.el-collapse-item__arrow {
  font-size: 24px;
  font-weight: 800;
}
</style>