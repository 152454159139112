
<div id="shareProject">
    <el-dialog
        :visible="shareDialogBoxVisible"
        :close-on-click-modal="false"
        title="Sharing"
        custom-class="dialog-box"
        @close="syncPermissions">
        <el-form
            label-position="top"
            style="text-align: left; padding-bottom: 20px">
            <div style="display: flex;">
                <el-form-item
                    label="Public"
                    style="margin-bottom: 0; flex-grow: 1; text-align: end; margin-right: 8px;"/>
                <el-switch
                    v-model="isPublicShared"
                   
                    style="margin: auto"/>
            </div>
            <div
                class="disclaimer"
                style="padding-top: 10px; color:#222;display: flex;justify-content: center; text-align:center;">
                <span v-if="isPublicShared"> This {{isCrmUser() ? 'lead' : 'project'}} is visible to everyone from your organization. 
                 You can turn off the toggle button above to keep it private.
                 </span> 
                <span v-else> This {{isCrmUser() ? 'lead' : 'project'}} is visible to your managers only. To
                add more people, add email id below
                </span>

            </div>
            <el-form-item
                style="margin-bottom: 10px;">
                <div
                    style="display: flex; height: 33px; line-height: 0px">
                    <infiniteScrollUsers
                        :user.sync="selectedUser"
                        :isUserListDisabled="isPublicShared"
                        style="flex-grow: 1;"/>
                    <button
                        :disabled="Object.entries(selectedUser).length === 0 &&
                        selectedUser.constructor === Object"
                        class="button-confirm"
                        style="height: 48.2px;
                                margin: 0;
                                border-radius: 0px 4px 4px 0px;
                                padding: 0 15px; backgoundColor: #409EFF"
                        @click.prevent="addUserToSharerList">
                        Add
                    </button>
                </div>
                
            </el-form-item>
            <div
                v-if="sharingMessage && projectPermissionsUsers.length === 1 && !isPublicShared"
                class="disclaimer">
                Sharing with {{ projectPermissionsUsers[0].user.first_name }}
            </div>
            <div
                v-else-if="sharingMessage && projectPermissionsUsers.length > 1 && !isPublicShared"
                class="disclaimer">
                Sharing with {{ projectPermissionsUsers[0].user.first_name }} and
                {{ projectPermissionsUsers.length - 1 }} other(s)
            </div>
            <div
                v-else-if="sharingMessage  && isPublicShared"
                class="disclaimer">
                Sharing with Everyone!
            </div>
            <div
                v-if="!personHavingAccessList && !isPublicShared"
                style="display: inline-block;">
                <a
                    v-if="projectPermissionsUsers.length !== 0"
                    class="hideOrShowList"
                    @click="showUserPermissionList()">
                    Change Permission
                </a>
            </div>

            <div
                v-if="personHavingAccessList"
                style="padding: 13px 0 10px 0; text-align: left; color:#777777"
                class="disclaimer">
                Who has access
                <a
                    class="hideOrShowList"
                    @click="hideUserPermissionList()">
                    Hide List
                </a>
            </div>      
            <div
                v-if="personHavingAccessList"
                style="height: 25vh;  padding: 5px 0 10px 0; text-align: left">
                <VuePerfectScrollbar class="scroll-area">
                    <el-row
                        v-for="(user, index) in projectPermissionsUsers"
                        :key="user.id"
                        style="text-align: left; padding-top: 1.5vh">
                        <el-col
                            :span="17"
                            style="padding: 0px 10px 0px 10px;
                            color: #303133;
                            line-height: 28px; width: auto;">
                            <div >{{ user.user.first_name }} {{ user.user.last_name }}</div>
                            <el-tooltip
            class="item"
            effect="dark"
            content="The Lead is Assigned to this user cannot remove Permission"
            placement="top"
            :disabled="selectedOwner!==user.user.id"
          
          >
                            <div @click="removePersonAccess(index)" class="removeButton " :class="{'disablebtn':selectedOwner===user.user.id }" >Remove</div>
                      </el-tooltip>
                        </el-col>
                        <el-col
                            :span="4"
                            style="text-align: right; width:auto; float: right; padding-right: 10px !important;">
                            <!-- <el-select
                                v-model="user.permission[0]"
                                class="permissions"
                                popper-class="lightDropdown">
                                <el-option
                                    v-for="(options,
                                            indexPermission) in permissionsAvailable"
                                    :key="indexPermission"
                                    :label="options.label"
                                    :value="options.name"/>
                            </el-select> -->
                            <el-tooltip
            class="item"
            effect="dark"
            content="The Lead is Assigned to this user cannot change Permission"
            placement="top"
            :disabled="selectedOwner!==user.user.id"
          
          >
                                <el-radio-group :disabled="selectedOwner===user.user.id"  v-model="user.permission" size="small">
                                    <el-radio-button label="change" >Edit</el-radio-button>
                                    <el-radio-button label="view">View</el-radio-button>
                                </el-radio-group>
                                </el-tooltip>
                            <!-- <input id="toggle-on" class="toggle toggle-left" name="toggle" value="false" type="radio" checked>
                            <label for="toggle-on" class="butn">Yes</label>
                            <input id="toggle-off" class="toggle toggle-right" name="toggle" value="true" type="radio">
                            <label for="toggle-off" class="butn">No</label> -->
                        </el-col>
                        <!-- <el-col
                            :span="3"
                            style="text-align: right;">
                            <i
                                class="el-icon-close"
                                style="cursor: pointer;
                                padding: 0px 18px 0 0;
                                font-size: 14px;
                                line-height: 26px"
                                @click="removePersonAccess(index)"/>
                        </el-col> -->
                    </el-row>
                </VuePerfectScrollbar>
            </div>

            <el-form-item
                v-if="false"
                label="Shareable Link"
                style="margin-bottom: 0px;">
                <div
                    class="disclaimer"
                    style="padding: 0">
                    Anyone with this link
                    <a
                        href="#"
                        style="text-decoration: none; color: #409eff">
                        can view
                    </a>
                </div>
                <el-row>
                    <el-col :span="21">
                        <el-input
                            :disabled="true"
                            placeholder="http://localhost:8080/projectSummary/252"
                        />
                    </el-col>
                    <el-col :span="3">
                        <button
                            class="button-confirm"
                            disabled
                            style="width: 100%">
                            Copy
                        </button>
                    </el-col>
                </el-row>
            </el-form-item>

            <el-form-item
                size="small"
                style="padding-top: 20px;display: flex; justify-content: center">
                <button
                    class="button-confirm"
                    @click.prevent="onProfileSharingSubmit"
                    :disabled = "!isUpdated"
                    >
                    Share
                </button>
            </el-form-item>
        </el-form>
    </el-dialog>
    <el-dialog
        :visible.sync="unsavedChangesDialog"
        title="Confirm"
        width="30%"
        style="padding-top: 5vh;">
        <span>
            Your changes are not being saved. Do you want to save them?
        </span>
        <template v-slot:footer>
        <span
            class="confirm-dialog"
            style="text-align: center; display: flex;justify-content: center">
            <button
                class="button-confirm"
                @click="onUnsavedChangesDialogConfirm">
                Confirm
            </button>
            <button
                class="btn btn-cancel"
                @click="onProfileSharingCancel">
                Cancel
            </button>
        </span>
        </template>
    </el-dialog>
</div>
