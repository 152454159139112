
<el-input
  v-model="searchValue"
  @input="onUpdate"
  placeholder="Search"
  class="custom-placeholder"
  style="width: 20vw; min-width: 200px"
>
  <template v-slot:prefix>
  <i style="font-size: 1.5rem">
    <div
      style="
        margin-left: 2px;
        height: 12px;
        width: 20px;
        margin-top: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
      "
    >
      <svg
        width="19"
        height="18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.663 17.275 11.188 11.8a6.02 6.02 0 0 1-1.788.938 6.477 6.477 0 0 1-2.012.312c-1.817 0-3.363-.63-4.638-1.888S.838 8.367.838 6.55c0-1.817.637-3.362 1.912-4.637C4.025.637 5.571 0 7.388 0 9.205 0 10.742.637 12 1.913c1.259 1.274 1.888 2.82 1.888 4.637 0 .7-.1 1.37-.3 2.013-.2.641-.517 1.229-.95 1.762l5.5 5.525c.2.2.296.438.287.713a1.02 1.02 0 0 1-.312.712c-.2.2-.442.3-.725.3a.988.988 0 0 1-.725-.3zM7.388 10.95c1.217 0 2.254-.43 3.112-1.287.859-.859 1.288-1.896 1.288-3.113 0-1.233-.425-2.283-1.275-3.15-.85-.867-1.892-1.3-3.125-1.3s-2.283.433-3.15 1.3-1.3 1.917-1.3 3.15c0 1.233.433 2.275 1.3 3.125.867.85 1.917 1.275 3.15 1.275z"
          fill="#CCCCCC"
        />
      </svg>
    </div>
  </i>
  </template>
</el-input>
