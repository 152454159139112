<template>
  <div :class="chartType !== 'map' ? 'card-body' : ''" style="height: 90%">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "CardBody",

  props: {
    chartType: {
      type: String,
      default: "",
    },
  },
  mounted() {
  },
};
</script>

<style scoped>
.card-body {
  padding: 20px;
  height: 50vh;
}
</style>
