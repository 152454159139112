
<div class="parentContainer" v-loading.fullscreen="isLoading">
  <el-drawer title="I am the title" :visible.sync="drawer" :with-header="false" :before-close="onClose" :size="computedDrawerSize"
    :append-to-body="true">
    <div class="loader-div" v-loading="!$props.lead" v-if="$props.lead === null"></div>
    <div v-if="$props.lead !== null" class="lead-drawer">
      <div class="headerLD">
        <el-button class="shareBtn" @click="isShareProposalPopupVisible = true">Share Proposal</el-button>
        <div class="header-left-buttons-container">
          <router-link
            :to="{
              name: 'leadSummary',
              params: { leadId: lead?.lead_details?.id },
            }"
            @click.native="onClickViewFullDetails"
          >
            <div class="fullDetContainer el-button el-button--primary">
              <p class="fullDet">View Full Details</p>
              <span style="font-size: 1.5em; display: flex; align-items: center;">
                <b-icon icon="arrow-right-short"></b-icon>
              </span>
            </div>
          </router-link>
          <span class="cross" style="cursor: pointer" @click="onClose()">&#x2716;</span>
        </div>
      </div>
      <div class="detContainer" ref="detContainer">
        <div class="lead-details">
          <p class="shrtForm" :style="{
            backgroundColor: generateColorFromName(lead?.lead_details?.name) || '#1c3366',
          }">
           {{ extractInitials(lead?.lead_details?.name) }}
          </p>
          <div class="name-and-share-container">
            <p class="name">
              <abbr :title="lead?.lead_details?.name" class="abbrTag">
                {{ lead?.lead_details?.name }}
               
              </abbr>
            </p>
            <div class="icons-container">
       
            <img src="./assets/share.svg" class="shareIcon" @click="openShareProjectPopup()" />
            <el-tooltip
                class="item"
                effect="dark"
                :content="leadDeleteTooltip"
                placement="top"
                :disabled="!disableLeadDelete"
              >
        <el-button type="text"  
        @click="deleteLeadConfirmation"
        :disabled="disableLeadDelete"
        >     
           <b-icon
      icon="trash"
      :style="{color: disableLeadDelete ? '#ccc' : '#777', marginLeft: '16px', transform: 'scale(1.5)'}"       
    ></b-icon>
  </el-button>
  
    </el-tooltip>
          </div>
          </div>
          <span class="res" style="display: flex;align-items: center;">({{ lead?.project_type }})  
           <span style="display: flex;align-items: center;" v-if="lead?.is_shared">                           
                   <img style="padding-left: 0.5rem; "    src="../../../assets/img/sharedIcon.svg" alt="">
            </span>
            <span style="display: flex;align-items: center;" v-if="(!lead?.is_shared&&lead?.lead_details?.workflow_type==='d2c_vip_external')">                           
                   <img style="padding-left: 0.5rem; "    src="../../../assets/img/bordered-building.svg" alt="">
            </span>
            </span>
          <div class="number-and-email">
            <span class="number">{{ lead?.lead_details?.phone }}</span>
            <div class="email">
              <img src="./assets/mail.svg" alt="">
              <span class="email-value">
                {{ lead?.lead_details?.email }}
              </span>
            </div>
          </div>
          <p class="address">{{ lead?.address || "Unknown Address" }}</p>
        </div>
        <div class="gridContainer">
          <div class="boxOne">
            <p class="label">Owner</p>
            <div class="flexContainer4">
              <infiniteScrollUsers :user.sync="selectedUser" :isUserListDisabled="isPublicShared" :crmMode="true"
                style="flex-grow: 1" @updatePMdata="updateOwnerInLead" />
            </div>
          </div>
          <div class="boxOne">
            <p class="label">Pipeline</p>

            <p style="min-height: 40px; display: flex; align-items: center">
              {{ handlePipeline(pipelineData) }}
            </p>
          </div>
          <div class="boxOne">
            <p class="label">Stage</p>
            <!-- <p>{{ lead.lead_details.stage }}</p> -->
            <el-select v-model="stageData" placeholder="Stages" @change="updateStageInLead">
              <el-option v-for="stage in handleStage(pipelineData)" :key="stage.id" :label="stage.name"
                :value="stage.id">
                {{ stage.name }}
                <b-icon style="margin-left: 10px" :color="iconColor(stage)" :icon="showIcon(stage)"></b-icon>
              </el-option>
            </el-select>

            <div v-if="errorStage">
              <p style="color: red">This field is required</p>
            </div>
          </div>
          <div class="boxOne">
            <p class="label">Lead Source</p>
            <!-- <el-select v-if="(!lead?.is_shared||organisationData?.secondary_org_details.length>0)" v-model="lead.lead_details.lead_source_object" placeholder="Lead Source"
              @change="updateLeadSource()">
              <el-option v-for="item in leadSourceOptions" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select> -->
            <p  style="min-height: 40px; display: flex; align-items: center">
              {{ handleLeadSource(lead.lead_details.lead_source_object,lead) }}
            </p>
          </div>
          <div v-if="isRelianceOrg" class="boxOne">
            <p class="label">Region</p>
            <el-select v-model="lead.lead_details.region" placeholder="Region" @change="updateRegion()">
              <el-option v-for="item in regionsOptions" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="boxOne">
            <p class="label">Probability</p>
            <el-input placeholder="Probability" type="number" ref="inputElement" v-model="lead.lead_details.probability"
              @change="updateProbability" :min="0" :max="100" @input="validateInput">
              <template v-slot:append><span class="suffex">%</span></template>
            </el-input>
          </div>
        </div>
        <div style="padding: 0px 24px; margin-bottom: 16px">
          <p class="label">Tags</p>
          <Tags v-if="tagsFetched" :tagsInProps="lead.tags" :lead="lead" :isUsingStore="false" :currentRowNumber="currentRowNumber"
            @updated-tags="emitUpdatedTags" />
        </div>
        <!-- <div style="padding: 0px 24px 24px 24px"> -->
        <Tabs v-if="$props.lead !== null" :lead="lead" :activeTab="activeTab" :project_id="lead.id"
          :focusAddNote="focusNote" :isDrawer="true" :allLeadList="allLeadList" :allUserList="allUserList" />
      </div>
    </div>
  </el-drawer>
  <ShareProject v-if="$props.lead !== null" :shareDialogBoxVisible.sync="shareDialogBoxVisible" :project_id="lead.id" :lead="lead" />
  <ShareProposalPopup v-if="isShareProposalPopupVisible && $props.lead !== null"
    :isShareProposalPopupVisible.sync="isShareProposalPopupVisible" :lead="lead" />
  <DealValue v-if="dealCheck" :isDeleteProjectDocumentPopupOpen="true" :title="`Deal Value Check`"
    :info="`Deal value for this lead is ${currencySymbol}0.`" :info2="` Do you want to continue without updating it?`"
    @cancelDelete="cancelDelete" @confirmDelete="confirmDelete" />

    <div style="z-index:1000">
    <DeleteDialog :dialogVisible="deleteLeadConfirmationDialog" :footerButtonType="'primary'" :footerButtonText="'Yes'"
          :disableCancel="false" :width="'400px'" :deleteMessage="`Are you sure you want to delete this lead?`" @handleClick="handleDeleteLead" @handleClose="deleteLeadConfirmationDialog=false" 
          :dialogTitle="''" :infoMessage="batteryDetailsMessage"
          >
        </DeleteDialog>

      </div>
</div>
