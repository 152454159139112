import axios from "axios";
export default {
    FETCH_SELF_DESIGN(postData,reqSelfDesign) {
        return axios.post(`api/projects/?self_design=${reqSelfDesign}`,postData);
    },
    CREATE_NEW_SELF_DESIGN(postData,reqSelfDesign) {
        return axios.post(`/api/designs/?self_design=${reqSelfDesign}`, postData);
    },
    FETCH_REVISION_REQUEST(id){
        return axios.get(`/api/features/pricing/?order_id=${id}&type=additional_revision`)
    },
};