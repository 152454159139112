<template>
  <div>
    <div class="grouped-container">
      <el-row>
        <p>Columns</p>
      </el-row>
      <div
        class="select-body"
        v-for="(groupedRows, inx) in groupedTableData"
        :key="inx"
      >
        <div v-for="(group, index) in groupedRows" :key="index">
          <div class="spacing-container">
            <div v-if="group.type === 'input'" class="body-container">
              <InputView
                :itemValue="group"
                :selectedValue="group?.selectedValue || ''"
                :indexOfOptions="inx"
                @item-selected="handleInput"
              />
            </div>
            <div class="body-container">
              <SelectComponentView
                v-if="group.type === 'select'"
                :items="group"
                :selectedValue="group?.selectedValue || ''"
                @item-selected="selectedData"
                :indexOfOptions="inx"
                :seletedSource="seletedSource"
              />
              <div style="padding-top: 10px">
                <CheckboxView
                  v-if="group.type === 'checkbox'"
                  :items="group"
                  :selectedValue="group?.selectedValue || false"
                  :indexOfOptions="inx"
                  @item-checked="handleChecking"
                />
              </div>
            </div>
          </div>
        </div>

        <div :span="1" @click="removeColumn(inx)">
          <p class="cross" v-if="groupedTableData.length > 1">
            <b-icon :icon="'x'"></b-icon>
          </p>
        </div>
      </div>
    </div>

    <div @click="handleaddGroupedColumns">
      <p
        style="
          color: #1c3366;
          font-weight: 600;
          font-size: 1rem;
          padding-top: 1rem;
          display: flex;
        "
      >
        <span
          ><b-icon
            :style="{
              color: '#1c3366',
              marginRight: '0.5rem',
            }"
            class="custom-icon-size"
            :icon="'plus-circle-fill'"
          ></b-icon></span
        >{{ `Add New Column` }}
      </p>
    </div>
  </div>
</template>

<script>
import SelectComponentView from "./selectComponentView.vue";
import InputView from "./inputView.vue";
import CheckboxView from "./checkboxView.vue";

export default {
  emits: ["measure_details"],
  name: "groupedTableInputs",
  components: { SelectComponentView, InputView, CheckboxView },
  data() {
    return {
      groupedData: [],
      selectedItem: "",
      groupedRows: [
        {
          title: "Name",
          prop: "groupedname",
          selectedValue: "",
          type: "input",
          placeholder: "Enter Column Name",
          isStar: true,
        },
        {
          title: "Measure",
          type: "select",
          prop: "measure",
          selectedValue: "",
          placeholder: "Select",
          options: this.$props.groupedTableOptions,
          isStar: true,
        },
        {
          title: "Percentage of Total",
          type: "checkbox",
          prop: "percentage_total",
          selectedValue: false,
          isStar: true,
        },
      ],
      measure_details: [{}],
      groupedRowOptions: [],
    };
  },

  props: {
    groupedTableOptions: {
      type: Array,
      default() {
        return []
      }
    },
    groupedTableSelectedData: {
      type: Array,
      default() {
        return []
      }
    },
    seletedSource: {
      type: String,
      default: 'Leads'
    }
  },

  async mounted() {
    this.groupedRowOptions = await this.$props.groupedTableOptions;
    if (this.$props.groupedTableSelectedData.length > 0) {
      // this.handleaddSelectedOptions();
    } else {
      this.handleaddGroupedColumns();
    }
  },
  computed: {
    groupedTableData() {
      return this.groupedData;
    },
  },
  methods: {
    handleInput(
      typeData,
      dataValue,
      value,
      itemData,
      index,
      isGrouped,
      selectedColumns
    ) {
      this.groupedData[index].map((d) => {
        if (d.prop === "groupedname") {
          d.selectedValue = dataValue;
        }
      });
    },
    selectedData(
      typeData,
      dataValue,
      value,
      itemData,
      index,
      isGrouped,
      selectedColumns
    ) {
      this.groupedData[index].map((d) => {
        if (d.prop === "measure") {
          d.selectedValue = dataValue.label;
          d.id = dataValue.id;
        }
        if (d.prop === "groupedname"&&!d.selectedValue) {
          d.selectedValue = dataValue.label;
        }
      });
    },
    handleaddSelectedOptions(options) {
      // Create a deep copy of groupedRows
      const selectedOptions = this.$props.groupedTableSelectedData;

      selectedOptions.map((d) => {
        const newRow = JSON.parse(JSON.stringify(this.groupedRows));

        newRow.map((val) => {
          if (val.prop === "groupedname") {
            val.selectedValue = d.label;
          }
          if (val.prop === "measure") {
            const result = options.find((op) => op.id === d.id);
            val.selectedValue = result.label;
            val.id = d.id;
          }
          if (val.prop === "percentage_total") {
            val.selectedValue = d.percentage;
          }
        });

        this.groupedData = [...this.groupedData, newRow];
      });
    },

    handleaddGroupedColumns() {
      // Create a deep copy of groupedRows
      const newRow = JSON.parse(JSON.stringify(this.groupedRows));

      // Add the deep copy to groupedData
      this.groupedData = [...this.groupedData, newRow];

      // Add indexes and sequences to the newly added rows
      this.groupedData.forEach((grp, ind) => {
        grp.forEach((d, inx) => {
          d.index = ind;
          d.sequence = inx;

          if (this.$props.groupedTableOptions) {
            d.options = this.$props?.groupedTableOptions || [];
          }
        });
      });
    },
    removeColumn(index) {
      this.groupedData = this.groupedData.filter((d, ind) => ind !== index);
    },
    handleChecking(index, value) {
      if (this.groupedData[index]) {
        this.groupedData[index].map((d) => {
          if (d.prop === "percentage_total") {
            d.selectedValue = value;
          }
        });
      }
    },
  },
  watch: {
    groupedTableSelectedData: {
      immediate: true,
      deep: true,
      handler(val) {},
    },
    groupedTableOptions: {
      immediate: true,
      handler(val) {
        this.groupedRowOptions = val;
        if (val.length > 0) {
          this.handleaddSelectedOptions(val);
        }
        this.groupedData.map((d) => {
          d.map((data) => {
            if (data.prop === "measure") {
              data.options = val;
            }
          });
        });
      },
    },
    groupedData: {
      deep: true,
      handler(val) {
        this.measure_details=[]
        val.map((data, inx) => {
          let dataSelect = { id: "", label: "", percentage: "" };
          data.map((d) => {
            if (d.prop === "measure") {
              dataSelect.id = d?.id || "";
            }
            if (d.prop === "groupedname") {
              dataSelect.label = d.selectedValue;
            }
            if (d.prop === "percentage_total") {
              dataSelect.percentage = d.selectedValue;
            }
          });

          this.measure_details[inx] = dataSelect;
        });
        if (this.measure_details.length > 0) {
          this.$emit("measure_details", this.measure_details);
        }
      },
    },
  },
};
</script>

<style scoped>
.grouped-container {
}
.content-container {
  padding: 0.5rem;
}
.custom-icon-size {
  font-size: 1.2rem;
}
.select-body {
  display: flex;
  align-items: center;
  padding: 1rem 0rem;
}
.spacing-container {
  padding-right: 0.5rem;
}
.cross {
  padding-top: 20px;
  font-size: 1.5rem;
  cursor: pointer;
}
</style>
