
<div class="">
  <Navbar @handleToggleSideBar="handleSidebar" />
  <main class="main-controller">
    <div class="backdrop"></div>
    <Sidebar :isSidebarOpen="isSidebarOpen" />
     <financials-wrapper></financials-wrapper>
  </main>
 
</div>
