
<div class="parentContainer">
    <el-dialog :visible="isAddNewStepPopupVisible" :close-on-click-modal="false" :title="title"
        @close="onDialogClose">
        <div class="container">
            <el-form ref="form" :model="form" @submit.native.prevent class="formContainer">
                <div>
                    <p class="font14Bold">Title <span class="asterisk">*</span></p>
                    <el-input v-model="form.title" name="title" placeholder="Enter Title"
                        @blur="checkTitle" maxlength="50" show-word-limit></el-input>
                    <p class="error-msg" v-if="showTitleError">This field is required.</p>
                </div>
                <div>
                    <p class="font14Bold">Description <span class="asterisk">*</span></p>
                    <el-input type="textarea" v-model="form.description" name="description"
                        placeholder="Enter Description" @blur="checkDescription" maxlength="200"  @input="limitLines" show-word-limit></el-input>
                    <p class="error-msg" v-if="showDescriptionError">This field is required.</p>
                </div>
                <div class="flexContainer">
                    <div class="width100">
                        <p class="font14Bold">
                            {{ form.unit == handleCurrencySymbol ? 'Amount charged at this step' : '% of amount charged at this step'
                            }}
                            <span class="asterisk">*</span>
                        </p>
                        <el-input v-model="form.amount" name="amount" type="number" placeholder="Enter Amount"
                            @input="checkAmount"></el-input>
                        <p class="error-msg" v-if="showAmountError">{{ amountErrorMsg }}</p>
                    </div>
                    <div class="width100">
                        <p class="font14Bold">Unit</p>
                        <el-select v-model="form.unit" placeholder="commercial" @change="handleUnitChange">
                            <el-option v-for="item in unitTypes" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
            </el-form>
            <div class="btnContainer">
                <el-button type="primary" class="addFAQBtn" @click="handleConfirm()" :loading="isLoading"
                    :disabled="!isFormValid">Save</el-button>
            </div>
        </div>
    </el-dialog>
</div>
