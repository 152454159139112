import { defineStore } from "pinia";

export const useDocumentStore = defineStore("documents", {
  state: () => {
    return {
      documentsData: "",
      signedContract:"",
    };
  },
  actions: {
    async SET_DOCUMENTS(data) {
      this.documentsData = data;
    },
    async SET_SIGNED_CONTRACTS(data){
        this.signedContract=data;
    }
  },
});
