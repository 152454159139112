import im1 from "../../assets/drop/stringing-images/Correct/1.png";
import im2 from "../../assets/drop/stringing-images/Correct/2.png";
import im3 from "../../assets/drop/stringing-images/Correct/3.png";
import im4 from "../../assets/drop/stringing-images/Correct/4.png";
import im5 from "../../assets/drop/stringing-images/Correct/5.png";
import im6 from "../../assets/drop/stringing-images/Correct/6.png";
import im7 from "../../assets/drop/stringing-images/Correct/7.png";
import im8 from "../../assets/drop/stringing-images/Correct/8.png";
import im9 from "../../assets/drop/stringing-images/Correct/9.png";
import im10 from "../../assets/drop/stringing-images/Correct/10.png";
import im11 from "../../assets/drop/stringing-images/Correct/11.png";
import im12 from "../../assets/drop/stringing-images/Correct/12.png";
import im13 from "../../assets/drop/stringing-images/Correct/13.png";
import im14 from "../../assets/drop/stringing-images/Correct/14.png";
import im15 from "../../assets/drop/stringing-images/Correct/15.png";
import im16 from "../../assets/drop/stringing-images/Correct/16.png";
import im17 from "../../assets/drop/stringing-images/Correct/17.png";
import im18 from "../../assets/drop/stringing-images/Correct/18.png";
import im19 from "../../assets/drop/stringing-images/Correct/19.png";
import im20 from "../../assets/drop/stringing-images/Correct/20.png";
import im21 from "../../assets/drop/stringing-images/Correct/21.png";
import im22 from "../../assets/drop/stringing-images/Correct/22.png";
import im23 from "../../assets/drop/stringing-images/Correct/23.png";
import im24 from "../../assets/drop/stringing-images/Correct/24.png";
import im25 from "../../assets/drop/stringing-images/Correct/25.png";
import im26 from "../../assets/drop/stringing-images/Correct/26.png";
import im27 from "../../assets/drop/stringing-images/Correct/27.png";
import im28 from "../../assets/drop/stringing-images/Correct/28.png";
import im29 from "../../assets/drop/stringing-images/Correct/29.png";
import im30 from "../../assets/drop/stringing-images/Correct/30.png";
import im31 from "../../assets/drop/stringing-images/Correct/31.png";
import im32 from "../../assets/drop/stringing-images/Correct/32.png";
import im33 from "../../assets/drop/stringing-images/Correct/33.png";
import im34 from "../../assets/drop/stringing-images/Correct/34.png";
import im35 from "../../assets/drop/stringing-images/Correct/35.png";
import im36 from "../../assets/drop/stringing-images/Correct/36.png";
import im37 from "../../assets/drop/stringing-images/Correct/37.png";
import im38 from "../../assets/drop/stringing-images/Correct/38.png";
import im39 from "../../assets/drop/stringing-images/Correct/39.png";
import im40 from "../../assets/drop/stringing-images/Correct/40.png";
import im41 from "../../assets/drop/stringing-images/Correct/41.png";
import im42 from "../../assets/drop/stringing-images/Correct/42.png";
import im43 from "../../assets/drop/stringing-images/Correct/43.png";
import im44 from "../../assets/drop/stringing-images/Correct/44.png";
import im45 from "../../assets/drop/stringing-images/Correct/45.png";
import im46 from "../../assets/drop/stringing-images/Correct/46.png";
import im47 from "../../assets/drop/stringing-images/Correct/47.png";
import im48 from "../../assets/drop/stringing-images/Correct/48.png";
import im49 from "../../assets/drop/stringing-images/Correct/49.png";
import im50 from "../../assets/drop/stringing-images/Correct/50.png";

import incorrect1 from "../../assets/drop/stringing-images/Incorrect/1.png";
import incorrect2 from "../../assets/drop/stringing-images/Incorrect/2.png";
import incorrect3 from "../../assets/drop/stringing-images/Incorrect/3.png";
import incorrect4 from "../../assets/drop/stringing-images/Incorrect/4.png";
import incorrect5 from "../../assets/drop/stringing-images/Incorrect/5.png";
import incorrect6 from "../../assets/drop/stringing-images/Incorrect/6.png";
import incorrect7 from "../../assets/drop/stringing-images/Incorrect/7.png";
import incorrect8 from "../../assets/drop/stringing-images/Incorrect/8.png";
import incorrect9 from "../../assets/drop/stringing-images/Incorrect/9.png";
import incorrect10 from "../../assets/drop/stringing-images/Incorrect/10.png";
import incorrect11 from "../../assets/drop/stringing-images/Incorrect/11.png";
import incorrect12 from "../../assets/drop/stringing-images/Incorrect/12.png";
import incorrect13 from "../../assets/drop/stringing-images/Incorrect/13.png";
import incorrect14 from "../../assets/drop/stringing-images/Incorrect/14.png";
import incorrect15 from "../../assets/drop/stringing-images/Incorrect/15.png";
import incorrect16 from "../../assets/drop/stringing-images/Incorrect/16.png";
import incorrect17 from"../../assets/drop/stringing-images/Incorrect/17.png";
import incorrect18 from "../../assets/drop/stringing-images/Incorrect/18.png";
import incorrect19 from "../../assets/drop/stringing-images/Incorrect/19.png";
import incorrect20 from "../../assets/drop/stringing-images/Incorrect/20.png";
import incorrect21 from "../../assets/drop/stringing-images/Incorrect/21.png";
import incorrect22 from "../../assets/drop/stringing-images/Incorrect/22.png";
import incorrect23 from "../../assets/drop/stringing-images/Incorrect/23.png";
import incorrect24 from "../../assets/drop/stringing-images/Incorrect/24.png";
import incorrect25 from "../../assets/drop/stringing-images/Incorrect/25.png";
import incorrect26 from "../../assets/drop/stringing-images/Incorrect/26.png";
import incorrect27 from "../../assets/drop/stringing-images/Incorrect/27.png";
import incorrect28 from "../../assets/drop/stringing-images/Incorrect/28.png";
import incorrect29 from "../../assets/drop/stringing-images/Incorrect/29.png";
import incorrect30 from "../../assets/drop/stringing-images/Incorrect/30.png";
import incorrect31 from "../../assets/drop/stringing-images/Incorrect/31.png";
import incorrect32 from "../../assets/drop/stringing-images/Incorrect/32.png";
import incorrect33 from "../../assets/drop/stringing-images/Incorrect/33.png";
import incorrect34 from "../../assets/drop/stringing-images/Incorrect/34.png";
import incorrect35 from "../../assets/drop/stringing-images/Incorrect/35.png";
import incorrect36 from "../../assets/drop/stringing-images/Incorrect/36.png";
import incorrect37 from "../../assets/drop/stringing-images/Incorrect/37.png";
import incorrect38 from "../../assets/drop/stringing-images/Incorrect/38.png";
import incorrect39 from "../../assets/drop/stringing-images/Incorrect/39.png";
import incorrect40 from "../../assets/drop/stringing-images/Incorrect/40.png";
import incorrect41 from "../../assets/drop/stringing-images/Incorrect/41.png";
import incorrect42 from "../../assets/drop/stringing-images/Incorrect/42.png";
import incorrect43 from "../../assets/drop/stringing-images/Incorrect/43.png";
import incorrect44 from "../../assets/drop/stringing-images/Incorrect/44.png";
import incorrect45 from "../../assets/drop/stringing-images/Incorrect/45.png";
import incorrect46 from "../../assets/drop/stringing-images/Incorrect/46.png";
import incorrect47 from "../../assets/drop/stringing-images/Incorrect/47.png";
import incorrect48 from "../../assets/drop/stringing-images/Incorrect/48.png";
import incorrect49 from "../../assets/drop/stringing-images/Incorrect/49.png";
import incorrect50 from "../../assets/drop/stringing-images/Incorrect/50.png";

export const arrayOfCorrectImages = [
    im1,im2,im3,im4,im5,im6,im7,im8,im9,im10,
    im11,im12,im13,im14,im15,im16,im17,im18,im19,im20,
    im21,im22,im23,im24,im25,im26,im27,im28,im29,im30,
    im31,im32,im33,im34,im35,im36,im37,im38,im39,im40,
    im41,im42,im43,im44,im45,im46,im47,im48,im49,im50,
];

export const arrayOfIncorrectImages = [
    incorrect1,incorrect2,incorrect3,incorrect4,incorrect5,incorrect6,incorrect7,incorrect8,incorrect9,incorrect10,
    incorrect11,incorrect12,incorrect13,incorrect14,incorrect15,incorrect16,incorrect17,incorrect18,incorrect19,incorrect20,
    incorrect21,incorrect22,incorrect23,incorrect24,incorrect25,incorrect26,incorrect27,incorrect28,incorrect29,incorrect30,
    incorrect31,incorrect32,incorrect33,incorrect34,incorrect35,incorrect36,incorrect37,incorrect38,incorrect39,incorrect40,
    incorrect41,incorrect42,incorrect43,incorrect44,incorrect45,incorrect46,incorrect47,incorrect48,incorrect49,incorrect50,
];

