
<div class="overflowMD">
  <table :id="id">
    <tr>
      <th v-for="(time, index) in timeColumn" :key="index" class="verticalTextTime">{{time}}</th>     
    </tr>
    <tr  :class="[id=='value1' ? 'tableTr1' : 'tableTr2']" v-for="(row, index) in actualData" :key="index">
      <th class="monthText">{{monthColumn[index]}}</th>
      <td :class="[id=='value1' ? 'tableTd1' : 'tableTd2']" v-for="(value, colIndex) in row" :key="colIndex" @blur="updateValue(index, colIndex, $event.target.innerText)">{{ value }}</td>
    </tr>
  </table>
</div>
