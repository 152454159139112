
    <div>

        <GlobalDialog :dialogVisible.sync="open" :dialogTitle="title" @handleClose="handleCloseDialog"
                   :width="'800px'"  :loading="loading" :onClickClose="false" :isModal="isModal" :isModalAppend="isModalAppend">
            <template v-slot:body >
                <el-row :align="'middle'" >
                    <el-col :span="1">
                        <div>
                            <p style="padding-top: 10px;font-size: 16px;font-weight: 400;">To : </p>
                        </div>
                    </el-col>
                    <el-col :span="23">
                        <div>
                            <el-tag :disable-transitions="false" style="margin-top: 5px; margin-left:10px">
                                {{ defaultUser }}
                            </el-tag>
                            <el-tag :key="tag" v-for="tag in additionalUsers" closable :disable-transitions="false"
                                style="margin-top: 5px;font-size: 14px;" @close="handleClose(tag)">
                                {{ tag }}
                            </el-tag>

                            <el-input class="input-new-tag" v-if="inputVisible" v-model="inputValue" ref="saveTagInput"
                                size="mini" @keyup.enter.native="handleInputConfirm" @keyup.escape.native="handleCloseInput"
                                @blur="handleInputConfirm" @input="handleChangeValue" :class="{ 'border-red': inputError }"
                                suffix-icon="el-icon-close" @click.native="handleIconClick">
                            </el-input>
                            <el-button style="margin-top: 5px;" v-else class="button-new-tag" size="small"
                                @click="showInput">+</el-button>
                        </div>
                    </el-col>
                </el-row>
                <el-row :align="'middle'">
                    <el-col>
                        <p v-if="inputError" style="color:red;padding-top: 5px;">{{ inputError }}</p>
                    </el-col>
                </el-row>
                <hr class="thick-hr" style="margin-top: 10px;" />

                <!-- CC container -->
								<div v-if="showCC">
									<el-row :align="'middle'">
											<el-col :span="1">
													<div>
															<p style="padding-top: 10px;font-size: 16px;font-weight: 400;">CC : </p>
													</div>
											</el-col>
											<el-col :span="23">
													<div>
															<el-tag :key="tag" v-for="tag in additionalUsersCC" closable :disable-transitions="false"
																	style="margin-top: 5px;font-size: 14px;" @close="handleClose(tag,'cc')">
																	{{ tag }}
															</el-tag>

															<el-input class="input-new-tag" v-if="inputVisibleCC" v-model="inputValueCC" ref="saveTagInputCC"
																	size="mini" @keyup.enter.native="handleInputConfirm('cc')" @keyup.escape.native="handleCloseInput('cc')"
																	@blur="handleInputConfirm('cc')" @input="handleChangeValue('cc')" :class="{ 'border-red': inputError }"
																	suffix-icon="el-icon-close" @click.native="handleIconClick('cc')">
															</el-input>
															<el-button style="margin-top: 5px;" v-else class="button-new-tag" size="small"
																	@click="showInput('cc')">+</el-button>
													</div>
											</el-col>
									</el-row>
									<el-row :align="'middle'">
											<el-col>
													<p v-if="inputErrorCC" style="color:red;padding-top: 5px;">{{ inputErrorCC }}</p>
											</el-col>
									</el-row>
                <hr class="thick-hr" style="margin-top: 10px;" />
            	</div>
                <div style="display: flex;align-items: center;" justify-content="flex-start">
                    <p style="margin-right: 5px;min-width: 65px;font-size: 16px; font-weight: 400;">Subject : </p>
                    <el-input v-model="localSubject" class="subject"></el-input>
                </div>
                <hr class="thick-hr" />
                <div  v-if="urlContent">
									<div style="display: flex;align-items: center;" justify-content="flex-start">
											<el-input type="textarea" :rows="10" v-model="localContent" class="subject" resize="none"
													:autosize="{ minRows: 2, maxRows: 10 }"></el-input>    
									</div> 
									<div v-html="urlContent"></div>
									<div style="display: flex;align-items: center;" justify-content="flex-start">
											<el-input type="textarea" :rows="4" v-model="closerContent" class="subject" resize="none"
													:autosize="{ minRows: 2, maxRows: 4 }"></el-input>    
									</div> 
            		</div>
                <div style="display: flex;align-items: center;" justify-content="flex-start" v-else>
                    <el-input type="textarea" :rows="10" v-model="localContent" class="subject" resize="none"
                        :autosize="{ minRows: 8, maxRows: 10 }"></el-input> 
                </div>

                <div v-if="fileData?.length>0" class="center-content" >
                 <div v-for="(file,index) in fileData" :key="index">
                    <div class="file-container center-content " @click="handleDownload(file)">
                    	<span class="pdf-icon"><img src="../../../assets/img/pdfredIcon.svg" alt=""></span> <p class="ellipsis">{{ file?.display_name}}</p>    
                		</div>
                 </div>
                </div>
               

            </template>
            <template v-slot:footer>
                <hr class="thick-hr" />
                <div :style="footerStyle">
                    <div v-if="withFileUpload">
                        <input type="file" ref="fileInput" accept=".pdf" @change="handleFileUpload" style="display: none" />

                        <el-button size="medium" class="padded-button dashed-button" plain @click="triggerFileUpload"
                            v-if="!invoiceFile">
                            <div style="display:flex; align-items: center; justify-content: center;">
                                <img src="../../leadManagement/components/assets/upload.svg" alt="" class="iconUpload"
                                    style="margin-right: 5px;">
                                Upload Invoice
                            </div>
                        </el-button>
                        <el-button size="medium" class="padded-button"
                            style="color: black; padding:15px; background-color: #E8EDF2; border-color: #D3D4D5; " plain
                            v-else>
                            <div style="display:flex; align-items: center; justify-content: center; ">
                                <p style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;max-width: 100%;min-height: 15px;">
                                    {{ invoiceFile.name }}</p>
                                <i class="el-icon-close"  @click.stop="handleFileUpload(null)"
                                    style=" transform: scale(1.5); margin-left: 10px;color: black;"></i>
                            </div>
                        </el-button>
                    </div>
                    <el-button type="primary" :loading="loading" :disabled="withFileUpload && !invoiceFile" @click="handleSendEmail">Send</el-button>
                </div>
            </template>
            
        </GlobalDialog>
    </div>
