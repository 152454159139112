
    <div :style="{width: errorMessage=='Design'? '100%' :'', height:errorMessage=='Design'? '100%' :'',display: errorMessage=='Design'? 'flex' :''}" class="container" >
      <div v-if="orderData||errorMessage">
      <div style="display: flex;margin: auto; flex-direction: column; align-items: center;" v-if="errorMessage">
  
        <h3 v-if="errorMessage == 'Design'" style="margin-bottom: 10px;">The Design API has failed.</h3>
        <div v-else>
          <h3 class="error-heading">
            An unexpected error occurred. There might be more details below.
          </h3>
          <div class="error-text">
            {{ errorText }}
          </div>
        </div>
        <el-button v-if="errorMessage == 'Design'"  @click="sendErrorEmail">Send Error Report</el-button>
      </div>
      <div v-else >
      <div class="heading" v-if="!loader">
        <div class="title-section">
<p class="title-text">{{ orderData.id }}</p>
<div>

            <el-tag
            :type="orderData.color"
              style="margin-right: 0.5rem"
              class="tag"
            >
              {{ orderData.service_type }}
            </el-tag>

        </div>
        <div
            v-if="
              orderData?.add_ons_availed &&
              orderData.add_ons_availed?.length > 0
            "
          >
            <el-tag
              style="margin-right: 0.5rem"
              v-for="(addons, inx) in orderData.add_ons_availed"
              :key="inx"
              class="tag"
            >
              {{ addons }}
            </el-tag>
          </div>
        </div>
        <div v-if="!loader"  class="btn-container">
      
       
          <div></div>
          <el-tooltip
                  class="item"
                  effect="dark"
                  :content="'Select the owner to assign the order'"
                  placement="top"
                  :disabled="disableAccept(orderData)"
                >
          <el-button type="primary"    v-if="orderData.order_status === 'under review'"
          @click="handleAccept"
            :disabled="!disableAccept(orderData)||acceptLoader"
            :loading="acceptLoader"
              class=" btn-spacing"
          >
          Accept

          </el-button>
          </el-tooltip>
     
          <rejectDialog
            v-if="orderData?.order_status !== 'rejected' && orderData?.order_status !== 'cancelled'"
            :orderData="orderData"
            :orderDetailsPopVisible.sync="propsData.orderDetailsPopVisible"
            :rejectStatus.sync="rejectStatus"
          />
        </div>
        
  
      </div>
  
      <!-- <div  > -->
        <div v-loading="loader"  class="body">
           <div class="left-body"   >
            <div v-for="(label, ind) in labelDataOptions" :key="ind" style=" padding-bottom: 16px;">
                <p class="title-lable">{{ label.label }}</p>
                <el-select
            v-if="label.inputType == 'select'&&label.prop=='order_status'"
            placeholder="Select an Owner"
            v-model="orderData['orderStatus']"
            :disabled="label.prop==='order_status' && (orderData[label.prop]==='under review'||orderData[label.prop]==='incomplete'||orderData[label.prop]==='cancelled'||orderData[label.prop]==='rejected'||orderData[label.prop]==='pending')"
            @change="onAssigneeChange($event, label.prop, orderData)"
          >
            <el-option
              v-for="(item, ind) in label.options"
              :key="ind"
              :label="item.label"
              :value="item.value"
              class="owner-dropdown-option"
            >
              <el-badge
                is-dot
                v-if="item.shifts"
                :type="getType(item.shifts)"
                class="dot-badge"
              ></el-badge>
            
              <span v-if="item.competence" :style="{ paddingLeft: '1rem' }">
                <el-tag
                  v-for="(comp, indx) in item.competence"
                  :key="indx"
                  class="tag"
                  :color="handleColorLightning(handleColor(comp), 80)"
                  :style="{
                    color: `${handleColor(comp)}`,
                    marginLeft: '0.2rem',
                  }"
                  type="red"
                  size="mini"
                >
                  {{ comp }}
                </el-tag>
              </span>
            </el-option>
            <div
            v-infinite-scroll="loadMoreOwners(label.nextUrl)"
            infinite-scroll-disabled="ownersBusy"
            infinite-scroll-distance="10"
            class="loading-icon"
            >
              <i v-if="ownersBusy" class="el-icon-loading infiniteScrollLoader"/>
            </div>
          </el-select>
                <el-select
            v-if="label.inputType == 'select'&&label.prop!=='order_status'"
            placeholder="Select an Owner"
            v-model="orderData[label.prop]"
            :disabled="label.prop==='order_status' && (orderData[label.prop]==='under review'||orderData[label.prop]==='incomplete'||orderData[label.prop]==='cancelled')"
            @change="onAssigneeChange($event, label.prop, orderData)"
          >
            <el-option
              v-for="(item, ind) in label.options"
              :key="ind"
              :label="item.label"
              :value="item.value"
              class="owner-dropdown-option"
            >
              <el-badge
                is-dot
                v-if="item.shifts"
                :type="getType(item.shifts)"
                class="dot-badge"
              ></el-badge>
              {{ item.label }}
              <span v-if="item.competence" :style="{ paddingLeft: '1rem' }">
                <el-tag
                  v-for="(comp, indx) in item.competence"
                  :key="indx"
                  class="tag"
                  :color="handleColorLightning(handleColor(comp), 80)"
                  :style="{
                    color: `${handleColor(comp)}`,
                    marginLeft: '0.2rem',
                  }"
                  type="red"
                  size="mini"
                >
                  {{ comp }}
                </el-tag>
              </span>
            </el-option>
            <div
            v-infinite-scroll="loadMoreOwners(label.nextUrl)"
            infinite-scroll-disabled="ownersBusy"
            infinite-scroll-distance="10"
            class="loading-icon"
            >
              <i v-if="ownersBusy" class="el-icon-loading infiniteScrollLoader"/>
            </div>
          </el-select>
 
                <div v-else-if="label.prop==='name'" > 
                  <div style="display: flex;align-items: center;">
                  <div style="padding-right: 8px;display: flex;" ><img src="./assets/person.svg" alt=""></div>
                  <div class="project-name">{{ orderData[label.prop] }}</div> 
                </div>
                </div>
                  <div v-else-if="label.prop==='created_at'" class="created_on"> <span style="padding-right: 8px;" >Created On</span>
                    <span >{{ orderData[label.prop] }}</span> 
                   </div>
                   <p v-else-if="label.prop=='due_date'&&orderData[label.prop]=='NA'"  class="value-details-empty" >'Accept' to see expected delivery</p>
                   <p v-else-if="label.prop=='time_remaining'&&orderData[label.prop]=='NA'"  class="value-details-empty" >'Accept' to see time remaining</p>

                   <p  class="value-details" v-else-if="label.prop!=='order_status'" >{{ orderData[label.prop] }}</p>

                
            </div>

           </div>

           <div class="right-body">
            <div style="padding-bottom:8px">
            <div v-for="(icon,inx) in buttonsData" :key="inx" class="common-style">
              <el-button :disabled="isDisabledBtn(orderData,icon)" type="text" :class="[ isDisabledBtn(orderData,icon) ? 'disabled-link':icon.exist=='desgin'?'design-btn' : 'icon-container' ]" @click.native.stop="icon.callback()" >
  

                <!-- <router-link
              :to="icon.redirect"
              target="_blank"
              :class="[ isDisabledBtn(orderData,icon) ? 'disabled-link':icon.exist==='desgin'? 'edit-design': 'icon-container', ]"
              @click.native="handleClick($event,orderData,icon)"
            > -->
            <span >
                  <b-icon style="transform: scale(1.5)" :icon="icon.icon"></b-icon> 

              </span >
                <span class="icon-text">{{ icon.tooltipContent }}</span> 
            <!-- </router-link> -->
              </el-button>

        

            </div>
          </div>
            <div style="border-top:1px solid #ccc;padding-top:8px" v-if="designData && !loader">
              <ProposalButtons :design="designData" :isProposalGenration="false" :isSalesModeDisabled="orderData.order_status==='under review'||orderData.order_status==='incomplete'" />

            </div>
           </div>

        </div>
   
    <!-- </div> -->
      <!-- <el-row class="proposal-btns" v-if="designData && !loader">
        <el-col :span="24">
          <ProposalButtons :design="designData" />
        </el-col>
      </el-row> -->
      <div style="border-top:1px solid #ccc;padding:8px" v-if="designData && !loader">
        <ProposalButtons :design="designData" :isSalesModeBtns="false" />
      </div>
  
      <div v-if="!loader" class="tabs">
        <Tabs
          :active="'1'"
          :token="token"
          :DetailsData="projectDetails"
          :orderData="orderData"
          :projectData="projectData"
          :customer="customerDetails"
          :ownerDetails="ownerDetails"
          :moduleDetails="moduleDetails"
          :pricingDetails="pricingDetails"
          :additionalNotes="additionalNotes"
          :ahjDetails="ahjDetails"
          :modelDetails="modelDetails"
          :batteryDetails="batteryDetails"
        />
      </div>
      <ChatBox v-if="ISUs && !loader" :orderData="orderData"  :drawerStatus="propsData?.drawer"/>
    </div>
  </div>
  <div v-else style="height:50vh;display: flex;justify-content: center;">
    <div v-loading="true">
  
    </div>
  </div>
  <GlobalDialog 
    :dialogTitle="`Permit Package Order Delivery and Pricing`"
      :dialogVisible="openPermitPricing"
      @handleClose="closeDialoge"
     :zeroPadding="true"
      :width="'650px'"
      :isModal="false"
    >
    <template v-slot:body>
      <div class="permit-container">
        <div class="permit-info">

          <div class="permit-item" v-for="(info,index) in permitInfoData" :key="index">
            <p class="label-permit">{{ info?.title }}</p>
            <p class="value-permit">{{info.prop=="order_count"?permitData?.['order_count']||"_" : orderData?.[info.prop]||"_" }}</p> 
          </div>
        </div>

        <div class="permit-input-container" v-for="(inputInfo,index) in pricingItems" :key="index">
    <div class="box first">
      <p class="input-label" >{{ inputInfo?.name }} <span class="required">*</span></p>
         <InputField :inputData.sync="permitData[inputInfo.price_prop]"   :countryDetails.sync="countryDetails" placeholderValue="Please Input"/>
    </div>
    <div class="box second"> 
       <p class="input-label">Delivery Time <span class="required">*</span></p>
      <InputField :inputData.sync="permitData[inputInfo.days_prop]" :prefixIcon="'Days'"  :countryDetails.sync="countryDetails" placeholderValue="0"/>
    </div>
    <div class="box third">  
      <p style="visibility: hidden" class="input-label">label <span class="required">*</span></p>
      <InputField :inputData.sync="permitData[inputInfo.hours_prop]" :prefixIcon="'Hours'"  :countryDetails.sync="countryDetails" placeholderValue="0"/>

    </div>

  </div>
      </div>

      </template>
      <template v-slot:footer>
<div class="permit-btns" >
  <el-button @click="closeDialoge">Cancle</el-button>
  <el-tooltip
                  class="item"
                  effect="dark"
                  :content="'To enable all pricing values and either (days or hours) values should be added.'"
                  placement="top"
                  :disabled="!isPermitPricingDisabled"
                >
  <el-button :disabled="isPermitPricingDisabled" type="primary" @click="handleToNexStep">Update and Continue</el-button>
  </el-tooltip>

</div>
      </template>
      </GlobalDialog>
  </div>
  