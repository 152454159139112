
    <div>
      
              <pdfPuppeteer
                :isInPreviewMode="isInPreviewMode && !isInContractListPage"
                :isInContractListPage="isInContractListPage"
                :isContractInDesignLevel="isContractInDesignLevel"
                :includePaymentSchedule="includePaymentSchedule"
                v-if="editorType=='pdf'"
              />
              <paginatedContentForPuppeteer
                v-else
                :isInPreviewMode="isInPreviewMode && !isInContractListPage"
                :isInContractListPage="isInContractListPage"
                :isContractInDesignLevel="isContractInDesignLevel"
                :includePaymentSchedule="includePaymentSchedule"
							  @previewLoaded="getData"
                @exitPreview="closeDialog"  
						  />
       
       
         
    </div>
