import { getEdges } from "../../../utils/utils";
import SelectionTree from "../../../lib/SelectionTree";
import BaseObject from "../../BaseObject";
import ThreejsText from "../../subObjects/ThreejsText";
import { TILE_VIEWS } from "./TilesGrid";
import * as THREE from "three";

export default class BaseTile extends BaseObject {
    constructor(stage, gridCell, position, length, width) {
        super(stage);
        this.stage = stage;
        this.gridCell = gridCell;
        this.position = position;
        this.length = length;
        this.width = width;
        this.instanceMesh = null;
        this.instanceIndex = null;
        this.visible = true;
        this.inverterInstance = null;
        this.updateColors();
        this.text = '';
        const globalPosition = this.gridCell.grid.toGlobal(this.position);
        this.textMesh = new ThreejsText(this.text, globalPosition, 0, this.stage, this, false, 'center', 'middle', null, true);
    }

    getVertices2D() {
        const vertices = [
            new THREE.Vector2(-this.length / 2 + this.position.x, -this.width / 2 + this.position.y),
            new THREE.Vector2(this.length / 2 + this.position.x, -this.width / 2 + this.position.y),
            new THREE.Vector2(this.length / 2 + this.position.x, this.width / 2 + this.position.y),
            new THREE.Vector2(-this.length / 2 + this.position.x, this.width / 2 + this.position.y)
        ];
        return vertices;
    }

    showText() {
        this.textMesh.showText();
    }

    hideText() {
        this.textMesh.hideObject();
    }

    updateText(newText = this.text) {
        this.text = newText;
        const globalPosition = this.gridCell.grid.toGlobal(this.position);
        this.textMesh.update(null, globalPosition);
        this.textMesh.textMesh.text = this.text;
        if(this.text && this.text !== '') {
            this.showText();
        } else {
            this.hideText();
        }
    }


    getMatrix() {
        return this.gridCell.getMatrix();
    }

    getLocalEdges(){
        let coords = [];
        coords.push([this.position.x+this.length/2, this.position.y+this.width/2]);
        coords.push([this.position.x-this.length/2, this.position.y+this.width/2]);
        coords.push([this.position.x-this.length/2, this.position.y-this.width/2]);
        coords.push([this.position.x+this.length/2, this.position.y-this.width/2]);
        return getEdges(coords);
    }

    /**
     * Hides the tile by setting its visibility to false and hiding its mesh by scaling to 0.
     */
    hide() {
        this.visible = false;
        if(this.instanceMesh) {
            this.gridCell.grid.hideTileMesh(this);
        }
    }

    /**
     * Shows the tile by setting its visibility to true and showing its mesh by scaling back.
     */
    show() {
        this.visible = true;
        this.gridCell.grid.showTileMesh(this);
    }

    /**
     * Selects the tile and highlights it.
     */
    onSelect() {
        this.isSelected = true;
        this.showObject();        
    }

    /**
     * Deselects the tile and sets to default color.
     */
    deSelect() {
        this.isSelected = false;
        this.unHighlight();
    }

    /**
     * Shows the tile by setting its highlight color.
     */
    showObject() {
        this.setHighlightColor();
    }

    /**
     * Resets the tile color to the default or DC view color based on the current view.
     */
    unHighlight() {
        if(!this.visible) {
            return;
        }
        if (this.gridCell.grid.view === TILE_VIEWS.DC_VIEW) {
            this.setDCViewColor();
        } else {
            this.setDefaultColor();
        }
    }

    /**
     * Sets the color of the tile.
     * @param {THREE.Color} color - The color to set.
     */
    setColor(color) {
        this.instanceMesh.setColorAt(this.instanceIndex, color);
        this.instanceMesh.instanceColor.needsUpdate = true;
    }

    /**
     * Sets the default color of the tile.
     * @param {Object} options - The options object.
     * @param {THREE.Color} options.defaultColor - The default color to set.
     */
    setDefaultColor({ defaultColor } = {}) {
        if (defaultColor) {
            this.defaultColor = defaultColor;
        }
        this.setColor(this.defaultColor);
        this.hideText();
    }

    /**
     * Sets the DC view color of the tile.
     * @param {Object} options - The options object.
     * @param {THREE.Color} options.dcColor - The DC view color to set.
     */
    setDCViewColor({ dcColor, apply = true } = {}) {
        if (dcColor) {
            this.dcColor = dcColor;
        }
        if(apply) {
            this.setColor(this.dcColor);
        }
        this.hideText();
    }

    /**
     * Sets the highlight color of the tile.
     * @param {Object} options - The options object.
     * @param {THREE.Color} options.highlightColor - The highlight color to set.
     */
    setHighlightColor({ highlightColor } = {}) {
        if (highlightColor) {
            this.highlightColor = highlightColor;
        }
        this.setColor(this.highlightColor);
        this.hideText();
    }

    setAestheticColor(aestheticColor) {
        if (aestheticColor) {
            this.setColor(aestheticColor);
        }
    }

    setAestheticColor(aestheticColor) {
        if (aestheticColor) {
            this.setColor(aestheticColor);
        }
    }

    /**
     * Gets the tilt of the grid cell.
     * @returns {number} The tilt of the grid cell.
     */
    getTilt() {
        return this.gridCell.grid.getTilt();
    }

    /**
     * Gets the azimuth of the grid cell.
     * @returns {number} The azimuth of the grid cell.
     */
    getAzimuth() {
        return this.gridCell.grid.getAzimuth();
    }

    /**
     * Calculates the distance to another tile.
     * @param {Object} tile - The tile to calculate the distance to.
     * @returns {number} The distance to the other tile.
     */
    distanceToTile(tile) {
        return this.position.distanceTo(tile.position);
    }

    removeObject() {
        this.gridCell.removeTile(this);
        this.saveState();
    }

    getInverterLift() {
        return this.gridCell.grid.getInverterLift();
    }

    getSelectableObjects() {
        return new SelectionTree([this.gridCell.grid.getPowerRoof(),this.gridCell.grid.smartroofFace ,this.gridCell.grid, this]);
    }

    getGlobalPosition() {
        return this.gridCell.grid.toGlobal(this.position);
    }

    getPowerRoof() {
        return this.gridCell.getPowerRoof();
    }

    saveState() {
        this.gridCell.saveState();
    }
}
