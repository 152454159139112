import i18next from 'i18next';
import englishTranslation from "./en/translation.json"
import spanishTranslation from "./es/translation.json"

i18next.init({
    lng: "en",
    debug: false,
    resources: {
        en: {
            translation: englishTranslation,
        },
        es: {
            translation: spanishTranslation,
        }
    }
});

export default i18next