
<div>
  <el-dialog 
    :visible="isOpen"
    class="delete-dialog"
    @close="dialogBoxClosingFunc"
    :show-close="false"
    :title="title"
    :close-on-click-modal="shouldOutsideClickClose"
    append-to-body
  >
    <div v-if="!hideCloseButton" class='close_button'>
      <img 
        src='../../pages/home/assets/img/close.svg' 
        alt='close' 
        @click="noClickFunc(true)"
      >
    </div>
    <div v-if="!hideIcon" class='alert'>
      <img 
        src='../../assets/img/warning-fill.svg' 
        alt='alert'
        class="warningImage"
      >
    </div>
    <p class='msg'>{{message}}</p>
    <div class='buttons-container'>
      <el-button 
        v-if="isShowCancelButton"
        @click="noClickFunc(false)">
        {{ secondaryButtonText }}
      </el-button>
      <el-button
        :loading="isloading"
        type="primary"
        @click="yesClickFunc">
        {{ primaryButtonText }}
      </el-button>
    </div>
  </el-dialog>
</div>
