
<section :class="['right_section', isCrmUser() ? 'right_sectionCRM': '']">
  <div class="content_section">
    <div class="filter_section">
      <div class="title">Manage Financials</div>
      <div class="starred_action">
        <div class="toggle-button-cover" v-if="isAdmin">
          <h5>
            Allow users to create, edit & delete financials
            <div class="hover_information">
              <i class="fas fa-info-circle"></i>
              <div class="tooltip">
                <p>
                  If you turn on the toggle, your organization will be able to
                  edit, delete, and copy the financials
                </p>
              </div>
            </div>
          </h5>
          <div class="toggle_btn">
            <el-switch
              v-model="isPermissionGiven"
              @change="changeToggle"
              :disabled="!isAdmin"
            ></el-switch>
          </div>
        </div>
      </div>
    </div>
    <div>
      <financialsContainer />
    </div>
  </div>
</section>
