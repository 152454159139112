import * as THREE from 'three';
import { Text } from 'troika-three-text';
import SelectionTree from '../../lib/SelectionTree';
import { CAMERA_UPDATED } from '../../coreConstants';
import * as utils from '../../utils/utils';
import * as utilss from "../../../components/ui/length/utils"; 
import HTMLText from './HTMLText';
import { isMetricUnit, stringifyFromMetricMeasurement } from '../../../components/ui/length/utils';
import { FOOT_INCHES_VALIDATION_REGEX } from '../../../components/ui/length/constants';
import OuterEdge from '../model/smartroof/OuterEdge';
import ArcMeasurement from './ArcMeasurement';
import RotationPoint from './RotationPoint';
import Edge from '../model/smartroof/Edge';
import Dimension from './Dimension';
import CustomTile from '../model/powerRoof/CustomTile';
import { InverterInstance } from '../model/powerRoof/PowerRoofInverter';
import BaseTile from '../model/powerRoof/BaseTile';




export default class ThreejsText {
    constructor(text, position, angle, stage, parent, editable = false, anchorX ='center',anchorY = 'middle', inputValidator = () => {}, visible = true, ignoreUpdate = false) {
        // standard norms
        this.stage = stage;
        this.canvas = stage.rendererManager.getDomElement();
        this.length = text;
        this.text = this.getValidInputInit(text);
        this.angle = angle;
        this.position = position;
        this.parent = parent;
        this.visible = visible

        if(this.stage.checkInstanceOfOuterEdge(parent)){
            this.vertex1 = parent.point1;
            this.vertex2 = parent.point2;
        }
        else if (parent instanceof ArcMeasurement){
            this.vertex1 = parent.point1;
            this.vertex2 = parent.point2;
            this.vertex3 = parent.point3;
        }
        else {
            this.vertex1 = parent.point2;
            this.vertex2 = parent.point1;
        }

        this.objectsGroup = new THREE.Group();
        this.stage.sceneManager.scene.add(this.objectsGroup);

        this.objectsGroup.position.z += 0.1;
        this.objectsGroup.container = this;

        // properties
        this.editable = editable;
        this.isSelectionEnabled = false;
        this.textMesh = new Text();
        this.textMesh.fontWeight = "bold"
        this.textMesh.outlineBlur = '20%'
        this.textMesh.anchorX = anchorX;
        this.textMesh.anchorY = anchorY;
        this.textMesh.maxWidth =1;
        this.inputValidator = inputValidator;
        this.zoomPrev = 1;
        this.isMetricUnitFlag = isMetricUnit();
        this.keydownEventListener = this._updateParent.bind(this);
        this.ignoreUpdate = ignoreUpdate;
        this.init();
    }

    init() {
        // Set properties to configure:
        this.textMesh.text = this.text;
        this.textMesh.fontSize = 0.5;
        this.textMesh.position.x = this.position.x;
        this.textMesh.position.y = this.position.y;
        this.textMesh.position.z = 200;
        // this.textMesh.fillOpacity = 0.5;
        this.textMesh.color = 0xffffff;
        this.textMesh.setRotationFromAxisAngle(new THREE.Vector3(0, 0, 1), this.angle);

        // Update the rendering:
        if(!(this.parent instanceof Dimension)) this.hideObject();
        this.textMesh.addEventListener('synccomplete',()=>{
                this.updateScale();
        })
        // this.textMesh.sync();
        this.objectsGroup.add(this.textMesh);
        this.updateScale();
    }

    moveObjectWithoutConsequences(deltaX, deltaY, deltaZ = 0) {
        this.textMesh.position.x += deltaX;
        this.textMesh.position.y += deltaY;
        this.showObject();
    }

    getValidInputInit(magnitude){
        let res = magnitude;
        if (utilss.isMetricUnit()) {
            res = parseFloat(magnitude);
            if(res < 0.001 ){
                return '0.001';
            }
        } else {
            const lengthInFeets = magnitude * 3.28084;
            const lengthInInches = ((((lengthInFeets).toFixed(4)) % 1) * 12).toFixed(2);
            const length = `${String((((lengthInFeets).toFixed(4)) - (((lengthInFeets).toFixed(4)) % 1)).toFixed(0))}'${String(lengthInInches)}"`;
            return length;
        }
        return magnitude;
    }
    getValidInput(magnitude){
        let res = magnitude;

        if (isMetricUnit()) {
            res = parseFloat(magnitude);
            if(res < 0.001 && res >= 0 ){
                return '0.001';
            }
        } else {
            if(this.parent instanceof ArcMeasurement || this.parent instanceof RotationPoint){
                return magnitude;
            }
            let feetArr = utilss.parseImperialMeasurement(magnitude);
            res = (0.3048 * feetArr[0]) + (0.0254 * feetArr[1]);
            if(res < 0.001 ){
                res = 0.0393700788;
                return utilss.stringifyImperialMeasurement(0,res);
            }
        }
        return magnitude;
    }
    convertLengthToMetric(length) {
        if (isMetricUnit()) {
            return length;
        }
        for(let i = 0; i < length.length; i++) {
            if (length[i] === "'") {
                if(i === length.length - 1) return (parseFloat(length.substring(0, i)) * 0.3048).toFixed(3);
                return (parseFloat(length.substring(0, i)) * 0.3048 + parseFloat(length.substring(i + 1, length.length - 1)) * 0.0254).toFixed(3);
            }
        }
    }

    isValidInput() {
        let magnitude = this.HTMLText.getValue();
        let setbackValidation = true;
        if (this.stage.setbackEditMode.isEnabled() &&
            !this.stage.setbackEditMode.setbackValueValidator(magnitude)) {
            setbackValidation =  false;
        }
        
        if (!this.inputValidator(magnitude) ||
            !setbackValidation) {
            // TODO: Think of some other method
            return false;
        }
        return true;
    }

    isValidToShow() {
        //if the length is less than 10 feet hide
        if (!isMetricUnit()) {
            const lengthInFeets = this.length * 3.28084;
            if (lengthInFeets < 1) {
                return false;
            }
        }
        else {
            if (parseFloat(this.length) < 0.3048) {
                return false;
            }
        }
        return true;
    }

    update(length = this.length , position = this.position, angle= this.angle, text = '') {
        if(this.stage.checkInstanceOfOuterEdge(this.parent)){
            this.vertex1 = this.parent.point1;
            this.vertex2 = this.parent.point2;
        }
        else if (parent instanceof ArcMeasurement){
            this.vertex1 = parent.point1;
            this.vertex2 = parent.point2;
            this.vertex3 = parent.point3;
        }
        else {
            this.vertex1 = this.parent.point2;
            this.vertex2 = this.parent.point1;
        }

        this.length = length;
        // this.textMesh.clipRect = [0,0,100,100]
        // this.convertLengthToMetric(text);
        // setting the properties
        // if(!(text !== '')){
            if(!isMetricUnit() && !(this.parent instanceof ArcMeasurement || this.parent instanceof RotationPoint)) {
                const lengthInFeets = this.length * 3.28084;
                const lengthInInches = ((((lengthInFeets).toFixed(4)) % 1) * 12).toFixed(2);
                const length = `${String((((lengthInFeets).toFixed(4)) - (((lengthInFeets).toFixed(4)) % 1)).toFixed(0))}'${String(lengthInInches)}"`;
                this.text = length;
            }
            else {
                this.text = parseFloat(length).toFixed(3);
            }
        // } //use later!!!
        // else {
        //     this.text = text
        // }

        // this.isValidInput(text) ? this.text = this.getValidInput(text) : this.text = this.text;

        // this.text = text;
        this.position = position;
        this.textMesh.text = this.text;
        this.textMesh.position.x = this.position.x;
        this.textMesh.position.y = this.position.y;
        if (this.angle !== angle) {
            this.angle = angle;
            this.textMesh.setRotationFromAxisAngle(new THREE.Vector3(0, 0, 1), this.angle);
        }
        if(this.HTMLText) this.HTMLText.update(this.text, this.position, this.angle, new THREE.Vector3(0,0,0),.1)
        this.updateScale(false);
        // this.showObject();
        // this.textMesh.maxWidth =1;
        // updating the text mesh
        // this.textMesh.sync();
    }

    updateScale = (updateFlag = true) => {
        const currentZoom = 1 / this.stage.getNormalisedZoom() * 10;
        
        if (this.stage.baseZoom === -1) {
            this.stage.baseZoom = currentZoom * 0.5;
        }
        
        // Calculate relative zoom factor
        const relativeZoom = currentZoom / this.stage.baseZoom;
    
        this.textMesh.scale.set(relativeZoom, relativeZoom, 1);
        // this.textMesh.sync();
    
        if (!(this.parent instanceof ArcMeasurement || this.parent instanceof BaseTile || CustomTile || InverterInstance) && updateFlag){
            const pos = this.getPerpendicularVectorToMidpoint((relativeZoom) / 4);
            this.update(this.length, pos, this.angle, this.text);
        }
    
        let compLength = (this.parent instanceof ArcMeasurement) ? this.parent.shortEdgeLength : this.length;
        if (this.ignoreUpdate) compLength = Infinity;
        if(this.parent instanceof BaseTile || this.parent instanceof CustomTile ||  this.parent instanceof InverterInstance) compLength = this.parent.length;
    
        if ((this.textMesh.geometry.boundingSphere.radius * relativeZoom * 3 > compLength) && this.visible && this.textMesh.geometry.boundingSphere.radius * relativeZoom * 3 > 0) {
            this.objectsGroup.visible = false;
        } else if ((this.textMesh.geometry.boundingSphere.radius * relativeZoom * 3 < compLength) && this.visible) {
            this.objectsGroup.visible = true;
        }
    
        this.zoomPrev = relativeZoom;
    }

    findPerpendicularPoint(angleInRadians, point, distance) {
      
        // Calculate the direction vector based on the angle
        const direction = new THREE.Vector3(
          Math.cos(angleInRadians + Math.PI / 2),
          Math.sin(angleInRadians + Math.PI / 2),
          0
        );
      
        // Scale the direction vector by the distance and add it to the original point
        const perpendicularPoint = point.clone().add(direction.multiplyScalar(distance));
        
        this.update(this.length,perpendicularPoint,this.angle);
    }

    updateScaleAfterSelection = () => {
        if(this.HTMLText) this.HTMLText._setPosition(this.position, this.HTMLText.direction, 0,this.angle);
    }

    hideObject() {
        this.objectsGroup.visible = false;
        this.stage.eventBus.removeEventListener(CAMERA_UPDATED, this.cameraUpdate);

    }

    showObject(text = '') {
        if(this.visible){
            if(this.isMetricUnitFlag !== isMetricUnit()) {
                this.isMetricUnitFlag = isMetricUnit();
            }
            this.update(this.length, this.position, this.angle,text );
            if(!this.stage.visualManager.in3D) {
                this.objectsGroup.visible = true;
                this.updateScale();
                this.stage.eventBus.addEventListener(CAMERA_UPDATED, this.cameraUpdate);
            }
        }
    }

    showText(){
        this.objectsGroup.visible = true;
        this.stage.eventBus.addEventListener(CAMERA_UPDATED, this.cameraUpdate);
    }

    setPosition(pos){
        this.positon = pos;
        this.update(this.length, this.position, this.angle);
    }
    getPosition(){
        return this.position;
    }
    getEdgeVector(point1, point2) {
        return (new THREE.Vector3(point2.x - point1.x, point2.y - point1.y, 0)).normalize();
    }

    getPerpendicularVectorToMidpoint(distance) {
        // Calculate the midpoint of the line segment
        const midpoint = new THREE.Vector3();
        midpoint.addVectors(this.vertex1.clone(), this.vertex2.clone()).multiplyScalar(0.5);
      
        // Calculate the direction vector from the midpoint to vectorB
        const directionVector = new THREE.Vector3();
        directionVector.subVectors(this.vertex2.clone(), midpoint);
      
        // Normalize the direction vector
        directionVector.normalize();
      
        // Rotate the unit vector by 90 degrees in the XY plane
        const perpendicularVector = new THREE.Vector3(-directionVector.y, directionVector.x, 0);
      
        perpendicularVector.multiplyScalar(-distance);
      
        // Add the perpendicular vector to the midpoint to get the final point
        const perpendicularPoint = new THREE.Vector3();
        perpendicularPoint.addVectors(midpoint, perpendicularVector);
      
        return perpendicularPoint;
    }

    getArcPos(distance) {
        const vertex1 = this.vertex1;
        const vertex2 = this.vertex2;
        const vertex3 = this.vertex3;
        const radius = (4 / this.stage.getNormalisedZoom());
        const radiusOffset = radius * 1;
        this.radiusMeasurement = radius + radiusOffset;
        const [startAngle, endAngle] = utils.getAngles(vertex1, vertex2, vertex3);

        // add arc points


        // set magnitude in degrees
        let diff = endAngle - startAngle;
        if (startAngle > endAngle) {
            diff = (Math.PI * 2) - Math.abs(diff);
        }

        // calculate placement point
        const direction1 = new THREE.Vector3(vertex1.x - vertex2.x, vertex1.y - vertex2.y, 0);
        const direction2 = new THREE.Vector3(vertex3.x - vertex2.x, vertex3.y - vertex2.y, 0);
        direction1.normalize();
        direction2.normalize();
        let placementUnitVector = new THREE.Vector3(
            direction1.x + direction2.x,
            direction1.y + direction2.y,
            0,
        );
        placementUnitVector.normalize();

        if (diff < Math.PI) {
            placementUnitVector.multiplyScalar(-1);
        }

        let placementPoint = vertex2.clone().addScaledVector(placementUnitVector, distance);

        const magnitude = (360 - Math.abs(utils.toDegrees(diff))).toFixed(1);
        if (magnitude === '180.0') {
            placementUnitVector = new THREE.Vector3(
                direction1.x + direction2.x,
                direction1.y + direction2.y,
                0,
            );

            // if the angles are very close to PI x and y tends to zero
            placementUnitVector.x = (placementUnitVector.x < 0.001) ? 0 : placementUnitVector.x;
            placementUnitVector.y = (placementUnitVector.y < 0.001) ? 0 : placementUnitVector.y;
            placementUnitVector.normalize();

            direction2.applyAxisAngle(new THREE.Vector3(0, 0, 1), -Math.PI / 2).normalize();
            placementPoint = vertex2.clone().addScaledVector(direction2, distance);
            placementUnitVector = direction2;
        }
        return placementPoint;
    }

    
    onDimensionChange() {
        const pointIndex1 = this.parent.index1;
        const pointIndex2 = this.parent.index2;
        const pointIndex3 = (this.parent.index2 + 1) % this.parent.parent.outlinePoints.length;
        const pointIndex4 = (this.parent.index2 + 2) % this.parent.parent.outlinePoints.length;

        const point1 = this.parent.parent.outlinePoints[pointIndex1].getPosition().clone();
        const point2 = this.parent.parent.outlinePoints[pointIndex2].getPosition().clone();
        const point3 = this.parent.parent.outlinePoints[pointIndex3].getPosition().clone();
        const point4 = this.parent.parent.outlinePoints[pointIndex4].getPosition().clone();

        const edgeVector1 = this.getEdgeVector(point1, point2);
        const edgeVector2 = this.getEdgeVector(point2, point3);
        const edgeVector3 = this.getEdgeVector(point3, point4);

        const newPoint2 = point1.add(edgeVector1.multiplyScalar(this.length));
        const newPoint3 = utils.checkLineIntersection([newPoint2,edgeVector2.add(newPoint2)],[point3,edgeVector3.add(point3)]);

        this.parent.parent.outlinePoints[pointIndex2].setPosition(newPoint2.x, newPoint2.y, point2.z);
        this.parent.parent.outlinePoints[pointIndex3].setPosition(newPoint3.x, newPoint3.y, point3.z);
        this.parent.parent.updateSmartRoof();
        this.parent.parent.placeObject();

    }
    async onComplete() {
        this.stage.stateManager.startContainer();

        // if(!this.valueChanged() && !this._shouldCompleteOnNoChange && isMetricUnit()) {
        //     return true;
        // }
        const magnitude = this.getValidInput(this.HTMLText.getValue());

        if (!this.inputValidator(magnitude) && isMetricUnit()) {
            // this.deSelect();

            // TODO: Think of some other method
            this.parent.inputError();
            throw new Error('Input validation failed.');
        }
        if(!isMetricUnit() && !this.inputValidator(this.HTMLText.getValue())){
            // this.deSelect();

            this.parent.inputError();
            throw new Error('Input validation failed.');
        }

        if (this.stage.setbackEditMode.isEnabled() &&
            !this.stage.setbackEditMode.setbackValueValidator(magnitude)) {
            this.stage.eventManager.customErrorMessage(
                'Setback value should be atleast 0.001',
                'Setback EditMode',
            );
            throw new Error('Input validation failed.');
        }

        if(!isMetricUnit()) this.length = this.convertLengthToMetric(magnitude);
        else this.length = magnitude;
        
        try{
            await this.parent.handleValueUpdate(magnitude);
        }
        catch (e) {
            console.log('e: ', e);
        }
        finally{
            if(!isMetricUnit() && !(this.parent instanceof ArcMeasurement || this.parent instanceof RotationPoint)) {
                const lengthInFeets = this.length * 3.28084;
                const lengthInInches = ((((lengthInFeets).toFixed(4)) % 1) * 12).toFixed(2);
                const length = `${String((((lengthInFeets).toFixed(4)) - (((lengthInFeets).toFixed(4)) % 1)).toFixed(0))}'${String(lengthInInches)}"`;
                this.text = length;
            }
            else {
                this.text = parseFloat(length).toFixed(3);
            }
            this.deSelect();
            if(this.parent instanceof RotationPoint) this.visible = false;

            this.stage.eventBus.addEventListener(CAMERA_UPDATED, this.cameraUpdate);
            this.stage.stateManager.stopContainer();
        }

        return true;
    }
    onCancel() {
        // this.HsetElementInnerText(this.text);
        this.parent.handleOnCancel();
    }


    onSelect({flag} = {flag: true}) {
        const parent = this.parent instanceof OuterEdge ? this.parent.parent: this.parent;
        
        if(parent instanceof ArcMeasurement ) {
            parent.update();
            this.visible = false;
        }
        if(this.parent instanceof RotationPoint || this.parent instanceof Dimension) this.visible = false;

        this.hideObject();

        this.HTMLText =  new HTMLText(this.text, this.position, this.angle, this.stage, new THREE.Vector3(0,0,0), 0, parent, this.inputValidator);
        // this.textMesh.geometry.boundingBox
        //add green dots for this.textMesh.geometry.boundingBox.min and this.textMesh.geometry.boundingBox.max
       

        this.HTMLText.element.value = this.text;
        this.HTMLText._setPosition(this.position,this.HTMLText.direction,0,this.angle);
        this.HTMLText.onSelect();
        this.stage.eventBus.addEventListener(CAMERA_UPDATED, this.cameraUpdateAfterSelection);
        if(flag) document.addEventListener('keydown', this.keydownEventListener, false);
    }

    deSelect() {
        this.visible = true;
        this.HTMLText.deSelect();
        this.HTMLText.remove();
        this.stage.textSelectionControls.selectedTextObject = undefined;
        this.stage.eventBus.removeEventListener(CAMERA_UPDATED, this.cameraUpdateAfterSelection);
        document.removeEventListener('keydown', this.keydownEventListener, false);
    }

    async _updateParent(event) {
        if(event.key === 'Enter') {
            await this.onComplete();
            // const test = this.HTMLText.getElementInnerText();
            // if(!isMetricUnit()) this.length = this.convertLengthToMetric(test);
            // else this.length = this.getValidInput(test);
            // // this.onDimensionChange();
            //  await this.parent.handleValueUpdate(this.length);
            // this.deSelect();
        }
    }

    handleTextSelection() {
        if (!this.stage.dragControls.isEditModeEnabled() &&
            !this.stage.duplicateManager.isEditModeEnabled() &&
            !this.stage.setbackEditMode.isEnabled() &&
            !this.stage.smartRoofSetbackEditMode.isEnabled()) {
            this.isTextSelected = true;
            this.stage.selectionControls.setSelectedObject(this);
        }
    }

    enableSelection = () => {
        if (!this.isSelectionEnabled) {
            // this.element.addEventListener('mousedown', this._onMouseDown);
            this.isSelectionEnabled = true;
        }
   };

   disableSelection = () => {
       if (this.isSelectionEnabled) {
        //    this.element.removeEventListener('mousedown', this._onMouseDown);
           this.isSelectionEnabled = false;
       }
   };

   valueChanged() {
        if(!isMetricUnit() && !(this.parent instanceof ArcMeasurement || this.parent instanceof RotationPoint)) {
            return this.length !== this.convertLengthToMetric(this.HTMLText.getValue());
         }  
        else {
            return parseFloat(this.length).toFixed(3) !== parseFloat(this.HTMLText.getValue()).toFixed(3);
        }
    // return this.text !== this.HTMLText.getValue();
}

    getSelectableObjects() {
        return new SelectionTree([this]);
    }

    getParentWiringZone() {
        return null;
    }
    getChildren() {
        return [];
    }

    cameraUpdate = () => {
        this.stage.addCameraUpdates(this.updateScale);
    }

    cameraUpdateAfterSelection = () => {
        this.stage.addCameraUpdates(this.updateScaleAfterSelection);
    }
    removeObject() {
        this.stage.eventBus.removeEventListener(CAMERA_UPDATED, this.cameraUpdate);
        this.objectsGroup.remove(this.textMesh);
        this.textMesh.dispose();
        this.stage.sceneManager.scene.remove(this.objectsGroup);
    }
}
