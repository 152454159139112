
<div class="addtask">
    <slot name="header">
      <div class="title">
        <h3 class="task-title-text" v-if="forAddTask">Add task</h3>
        <h3 class="task-title-text" v-else>{{ editOrDetails }}</h3>
        <span class="cross" @click="onClose()" style="font-weight: bolder; padding: 0.75rem 0.5rem 20px;">&#x2716;</span>
      </div>
    </slot>
    <slot name="body">
      <div class="wholeBody">
        <div class="forLabel"> 
            <label class="label">Task Name<span class="astrisk"> *</span></label>
            <p class="task_name" v-if="!(editMode || forAddTask)">{{ tasks.name }}</p>
            <el-input 
            v-else
            placeholder="Enter task name" 
            v-model="taskName" 
            v-validate="taskNameValidation" 
            name="taskName" 
            @input="isTaskName()"
            class="custom-input"></el-input>
            <p class="errorMsg" v-show="errors.has('taskName')">
              This field is required.
            </p>
        </div>
        <div class="boxOne">
            <p class="label">Owner<span class="astrisk"> *</span></p>
                <div class="bodyContent ownerBody" v-if="!(editMode || forAddTask)">
                    <p class="ownerN">{{ textInitial(tasks.assigned_to) }}</p><span class="ownerLine">{{
                        tasks.assigned_to }}</span>
                </div>
                <div class="flexContainer4" v-else>
                    <infiniteScrollUsers
                        :user.sync="selectedUser"
                        :crmMode="true"
                        style="flex-grow: 1;"
                        :collaborators="tasks.collaborators"
                        />
                </div>
                <p class="errorMsg" v-if="showOwnerError">
                This field is required.
              </p>
         </div>
         <div class="forLabel"> 
            <label class="label" v-if="editMode || forAddTask">Description</label>
            <p class="description" v-if="!(editMode || forAddTask)">{{description}}</p>
            <!-- <el-input 
            v-if="editMode || forAddTask"
            type="textarea" 
            class="descriptionBox"
            :rows="4" 
            placeholder="Add your description" 
            v-model="description" 
            name="description" 
            @input="isDescription($event)"></el-input> -->
            <div v-if="editMode || forAddTask" class="about_content">
              <div class="floating-label">
                <textarea
                  id="autoresizing"
                  role="textbox"
                  class="floating-input floating-textarea"
                  name="aboutUs"
                  placeholder=" "
                  @keydown="autoResizeAboutUs();"
                  v-model="description" 
                >
                </textarea>
              </div>
            </div>
            <div class="belowDescriptionBox">
              <p class="errorMsg" v-if="errors.has('description')">
                This field is required.
              </p>
              <p v-else-if="showDescriptionError" class="errorMsg">This field may not be greater than 500.</p>
            <p class="charracterCount" v-if="editMode || forAddTask">{{ descriptionLength }}/500</p>
            </div>
        </div>
         
      </div>
    </slot>
    <slot name="footer">
      <div class="bottomCard">
          <!-- <el-card class="boxCard"> -->
              
              <div class="outerBtn">
                  <el-button v-if="forAddTask" type="primary" class="submitBtn" @click="beforeCreateTask()" :loading="loadingStateButton">Create Task</el-button>
                   <el-button v-if="!(editMode || forAddTask)" type="default" class="submitBtn1" @click="deleteTask()" :loading="loadingStateButton" style="color: red;">Delete</el-button>
                   <el-button v-if="editMode && !forAddTask" type="default" class="submitBtn1" @click="toggleEditMode()" :loading="loadingStateButton">Cancel</el-button>
                  <el-button  v-if="!(editMode || forAddTask)" type="primary" class="submitBtn"  @click="toggleEditMode();" :loading="loadingStateButton">Edit</el-button>
                  <el-button v-if="!(!editMode || forAddTask)" type="primary" class="submitBtn" @click="editTask()" :loading="loadingStateButton">Update</el-button>
              </div>
          <!-- </el-card> -->
      </div>
    </slot>
    <leadPermissionPopup
      v-if="isLeadPermissionPopup"
      :isLeadPermissionPopup="isLeadPermissionPopup"
      :project_id="project_id"
      :userId="selectedUser.email"
      :userName="selectedUser.first_name"
      @cancelDelete="isLeadPermissionPopup = false"
      @close="isLeadPermissionPopup = false"
      @closeAfterShare="closeAfterShare"
    />
</div>
