import * as THREE from "three";
import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils';
import Panel from "./Panel";
import Subarray from './Subarray'
import Row from './Row'
import * as raycastingUtils from "../../utils/raycastingUtils";
import * as JSTS from "jsts";
import * as utils from '../../utils/utils';
import {
    getNearestSubarrayForTableSnapping,
} from '../../utils/subarrayUtils';
import {
    CREATED_STATE,
    DELETED_STATE,
    PANEL_ORIENTATION_PORTRAIT,
    OUT_OF_ASSOCIATED_MODEL_ERROR,
    TABLE_OUT_OF_GROUND_ERROR,
    TABLE_TILT_LOWER_THAN_PARENT_TILT_ERROR,
    COMBINATION_IS_NOT_POSSIBLE_ERROR,
    DC_CAP_REACHED_ERROR,
    SUBARRAY_RACK_STYLE_FLUSH,
    SUBARRAY_RACK_STYLE_FIXED,
    OUT_OF_POLYGON_MODEL,
    SALES_MODE,
    POWER_PATIO,
    OUT_OF_POLYGON_EDGE,
    PATIO_OBJECT,
    PARENT_WITHIN_CHILD_SO_NO_SETBACK_OUTSIDE_ERROR,
    POWER_TABLE,
    POWERMODEL_INTERSECTS_WITH_OTHER_MODELS,
    POWERMODEL_INTERSECTS_WITH_PROPERTY_LINE,
    OUT_OF_GROUND_ERROR,
    POWERMODEL_OUTSIDE_OF_EDGE,
    POWER_MODEL_CANT_BE_PALCED_ON_TILTED_SURFACE,
} from '../../coreConstants';
import { COLOR_MAPPINGS, VISUAL_STATES } from '../visualConstants';
import PolygonModel from '../model/PolygonModel';
import Ground from '../../../core/objects/ground/Ground';
import SmartroofFace from "../model/smartroof/SmartroofFace";
import Gazebo from "../../lib/PowerGazebo";
import img from '../../../assets/img/arrowGazebo.png'
import createBufferGeometry from "../../utils/meshUtils";
import Table from './Table';

function getImageUrl(path) {
    return new URL(path, import.meta.url).href
}

export default class PowerTable extends Table {
    constructor(
        stage, tableMap, { withoutContainer } = { withoutContainer: false }, newFlow = false,
    ) {
        super(stage, tableMap, { withoutContainer }, newFlow = false,);
        this.objectType = POWER_TABLE;
    }

    saveObject(isCopy = false) {

        let tableData = {
            type: Table.getObjectType(),
            subarrayUUID: this.getParent().getParent().getUUID(),
        };

        // save id and name
        tableData.id = this.id;
        tableData.name = this.name;

        // saving properties
        tableData.hidden = this.hidden;
        tableData.isMoved = this.isMoved;

        // save table map
        tableData.tableMap = this.getTableMap();

        if (isCopy) {
            tableData.uuid = this.uuid;
            // Temp fix for EW: REWORK REQUIRED
            if (this.linkedTable){
                tableData.linked = this.linked;
                let linkedTableCopy = {
                    type: Table.getObjectType(),
                    subarrayUUID: this.linkedTable.getParent().getParent().getUUID(),
                };
                linkedTableCopy.id = this.linkedTable.id;
                linkedTableCopy.name = this.linkedTable.name;
                linkedTableCopy.uuid = this.linkedTable.uuid;
                linkedTableCopy.hidden = this.linkedTable.hidden;
                linkedTableCopy.isMoved = this.linkedTable.isMoved;
                linkedTableCopy.tableMap = this.linkedTable.getTableMap();                
                tableData.linkedTable = linkedTableCopy;
            }
        }
        return tableData;
    }

    loadObject(tableData, isPaste) {
        // load id and name
        if (!isPaste) {
            console.error('ERROR: Table: loadObject: Not implemented when not pasting');
        }

        this.id = this.getSubarray().getHighestTableId() + 1;
        this.name = "PowerTable #" + this.id.toString();

        // get highest panel id in the subarray
        let highestPanelId = this.getSubarray().getHighestPanelId();

        // initialise table map
        this.hidden = false;
        this.isMoved = true;
        for (let panel of this.getChildren()) {
            panel.id = highestPanelId + 1;
            panel.name = "Panel #" + this.id.toString();
            highestPanelId += 1;
        }

        this.getSubarray().structureUpdateRequired = true;
        if (this.getSubarray() instanceof Gazebo) {
            if (this.rotationPoints)  this.rotationPoints.getSubarray().removeObject();
            this.getSubarray().createRotation();
        }

        if (isPaste) {
            if (tableData.linkedTable) {
                const { subarrayUUID } = tableData.linkedTable;
                const subarray = utils.findBaseObjectInChildren(
                    subarrayUUID,
                    this.stage.ground,
                );

                const object = new PowerTable(this.stage, tableData.linkedTable.tableMap);
                object.showIndividualMesh();
                subarray.getChildren()[0].addChild(object);
                object.loadObject(tableData.linkedTable, true);
                this.linkedTable = object;
                this.linked = true;
                object.linkedTable = this;
                object.linked = true;

            }
        }
    }

    getState() {
        const position = this.getPosition();
        const state =  {
            uuid: this.uuid,
            id: this.id,
            name: this.name,
            parent: this.getParent() ? this.getParent().uuid : null,
            hidden: this.hidden,
            linked: this.linked,
            linkedTable: this.linkedTable ? this.linkedTable.uuid : null,
            position: {
                x: position.x,
                y: position.y,
                z: position.z,
            },
        };
        return state;
    }

    loadState(state, fromState) {
        if (state === CREATED_STATE || state === DELETED_STATE) {
            this.clearState();
        } else {
            const { parent, id, name, hidden, position } = state;
            this.id = id;
            this.name = name;
            this.hidden = hidden;
            this.linked = state.linked;
            // if (state.linkedTable) this.linkedTable = this.stage.getObject(state.linkedTable);
            this.updateVisualsAfterLoadingAndCreation();

            if (parent) {
                const parentObject = this.stage.getObject(parent);
                if (!this.getParent()) {
                    parentObject.addChild(this, undefined, true);
                } else if (parentObject !== this.getParent()) {
                    this.getParent().removeChild(this);
                    parentObject.addChild(this, undefined, true);
                }
            }

            // TEMP FIX FOR EW: REWORK REQUIRED
            if (this.getSubarray() && this.getSubarray().eastWestEnabled) {
                if (!this.objectsGroup.parent) {
                    this.stage.sceneManager.scene.add(this.objectsGroup);
                }
            }
            
            this.setPosition(position);

            if (fromState === CREATED_STATE || fromState === DELETED_STATE) {
                this.stage.sceneManager.scene.add(this.objectsGroup);
            }

            if (this.getSubarray()) {
                this.getSubarray().structureUpdateRequired = true;
            }
            if (this.getSubarray() instanceof Gazebo) {
                this.hideIndividualMesh();
                if (this.getSubarray().rotationPoints) {
                    this.getSubarray().createBoundaryFromBB();
                    this.getSubarray().updateGeometry();
                    this.getSubarray().showMergedMeshes();
                    this.getSubarray().rotationPoints.hideObject();
                    // this.stage.selectionControls.setSelectedObject(this.stage.ground);
                }
            }
            if (this.getSubarray()?.objectType === POWER_PATIO) {
                if (this.stage.selectionControls.getSelectedObject() !== this) {
                    this.hidePatioSetback();
                }
            }
        }
    }

    clearState() {
        // select ground if selected
        if (this.stage.selectionControls.getSelectedObject() === this) {
            this.stage.selectionControls.setSelectedObject(this.stage.ground);
        }

        if (this.getParent()) {
            this.getParent().removeChild(this);
        }

        this.stage.sceneManager.scene.remove(this.objectsGroup);
    }

    // Geometry Manipulation

    moveObject(deltaX, deltaY, deltaZ = 0, rackMove = false) {
        if (this.stage.attachedElementDragControls.finalPos && this.getSubarray()?.attachedPatioEdge && this.stage.attachedElementDragControls.patioDrag) {
            this.tableErrorFlag = false;
            const currentTableCenter = this.getSubarray().getPosition();
            if (this.reqCorners) {
                this.tableErrorFlag = utils.checkPointInsideVertices(this.reqCorners, [currentTableCenter.x, currentTableCenter.y])
            }
            if (this.tableErrorFlag) {
                this.switchVisualState(VISUAL_STATES.DEFAULT, true);
                this.invalidPatioPlacement = false;
            }
            else {
                this.switchVisualState(VISUAL_STATES.ERROR, true);
                this.invalidPatioPlacement = true;
            }
            deltaX = this.stage.attachedElementDragControls.finalPos.x;
            deltaY = this.stage.attachedElementDragControls.finalPos.y;
            deltaZ = this.stage.attachedElementDragControls.finalPos.z;
        }
        this.objectsGroup.position.set(
            this.objectsGroup.position.x + deltaX,
            this.objectsGroup.position.y + deltaY,
            this.objectsGroup.position.z + deltaZ,
        );
        if (this.getParent() !== null && this.getParent() !== undefined) {
            if (this.getSubarray() instanceof Gazebo) {
                if (this.getSubarray().rotationPoints) {
                    // to update the bounding box of gazebo while moving and its rotation point.
                    this.getSubarray().createBoundaryFromBB();
                    this.getSubarray().updateGeometry();
                    this.getSubarray().rotationPoints.moveObjectWithoutConsequences(deltaX, deltaY, deltaZ);
                    this.getSubarray().rotationPoints.lineMesh.visible = false;
                }
            }

            if (this.getSubarray()?.objectType === POWER_PATIO) {
                const errorsWhilePlacing = this.getPlacingInformationWhilePlacing(this.getPosition()).errors;
                if (this.isSelected) {
                    if (errorsWhilePlacing.length > 0) {
                        this.outGndErr = true;
                        this.hidePatioSetback();
                    }
                    else {
                        this.updateGeometry();
                        if (this.getSubarray() && this.getSubarray().attachment && this.getSubarray().attachment.attachedTo) {
                            this.getPatiosForMovementAttachedToMovingEdge();
                            this.check_2FeetSetbackDistance();
                        }
                        if (!this.getSubarray().setbackOutsideMesh.visible) this.showPatioSetback();
                    }
                }
                else {
                    this.hidePatioSetback();
                }
            }
        }

        this.moveDimensions(deltaX, deltaY, deltaZ);

        const children = this.getChildren();
        for (let i = 0, l = children.length; i < l; i += 1) {
            children[i].moveObject();
        }

        // console.log('this.getSubarray()?.objectType: ', this.getSubarray()?.objectType);
        // if (this.stage.mode === SALES_MODE && this.stage.selectionControls.getSelectedObjects()[0] instanceof Table) {
        //     if (this.getSubarray()?.objectType !== 'Gazebo' && this.getSubarray()?.objectType !== PATIO_OBJECT) {
                // this.stage.visualManager.updateVisualsForAddTableMode(false);
        //     }
        // }

        // we dont need to show setbacks while adding patio and gazebo
        // and power table is being used by gazebo and patio
        this.stage.visualManager.updateVisualsForAddTableMode(false);

        this.saveState();
        if (!this.stage.selectionControls.isMultiSelect() && this.isSelected) {
            if(this.linked && !rackMove ) {
                this.linkedTable.moveObject(deltaX, deltaY, 0, true);
            }
        }
    }

    validatePropertyIntersection() {
        this.propertyIntersectionError = false;
        let edge1, edge2;
        const currentTableVertices = this.getPatioVerticesWhileMoving();
        if (currentTableVertices?.length > 0) this.currentMovingTableEdges = utils.getEdges(currentTableVertices);
        const allPropertyEdges = this.getSubarray().allPropertyEdges;
        if (allPropertyEdges) {
            for (let i = 0; i < allPropertyEdges.length; i++) {
                edge1 = allPropertyEdges[i];
                for (let j = 0; j < this.currentMovingTableEdges.length; j++) {
                    edge2 = this.currentMovingTableEdges[j];
                    const res = utils.checkLineIntersection(edge1, edge2);
                    if (res.onLine1 && res.onLine2) {
                        this.switchVisualState(VISUAL_STATES.ERROR, true);
                        this.propertyIntersectionError = true;
                    }
                }
            }
        }
    }

    getPatioVerticesWhileMoving() {
        let tempTableVertices = [];
        const patio = this.getSubarray();
        for (let row of patio.getChildren()) {
            if (row.get3DBoundingBoxesExcludingHiddenTables()[0]?.length > 0)
            tempTableVertices = utils.convertVectorArrayTo2DArray([...row.get3DBoundingBoxesExcludingHiddenTables()][0]);
        }
        return tempTableVertices;
    }

    setPosition(position) {
        this.objectsGroup.position.set(
            position.x,
            position.y,
            position.z,
        );
    }

    getLocalPosition(subarray) {
        if (!subarray) {
            subarray = this.getSubarray();
        }
        return subarray.globalToLocalCoordinates(new THREE
            .Vector2(this.getPosition().x, this.getPosition().y), subarray.getBoundingBox());
    }

    /**?
     * to check whether the object is being placed inside ground.
     */
    getPlacingInformationWhilePlacing(mousePoint) {
        const subarrayParentModel = this.getSubarray().getAssociatedModel();
        let position = this.getPosition();
        const offset = 0.01;
        let boundingBoxVertices = [
            [position.x - offset, position.y - offset],
            [position.x - offset, position.y + offset],
            [position.x + offset, position.y - offset],
            [position.x + offset, position.y + offset],
        ];
        if (mousePoint) {
            boundingBoxVertices = [
                [mousePoint.x - offset, mousePoint.y - offset],
                [mousePoint.x - offset, mousePoint.y + offset],
                [mousePoint.x + offset, mousePoint.y + offset],
                [mousePoint.x + offset, mousePoint.y - offset],
            ];
        }

        // Two conditions for placement = at least one vertex should be on the associated model of
        // the subarray & all vertices should be open (i.e. no vertex should have a model on top
        // of it on placement)

        const response = {};
        let parentExists = true;
        response.errors = [];
        // checking whether the position of table is outside of the map or not.
        if (!utils.checkPointInsideVertices(this.stage.ground.get2DVertices(), [position.x, position.y, position.z])) {
            response.errors.push(new Error(OUT_OF_GROUND_ERROR));
            response.parent = null;
            return response;
        }


        const allModels =
            raycastingUtils.getAllModelsBelowVertices(boundingBoxVertices, this.stage);

        let newParent;
        for (let idx = 0, len = allModels.length; idx < len; idx += 1) {
            if (!allModels[idx][0].isIgnored()) {
                [newParent] = allModels[idx];
                break;
            }
        }

        // if parent is undefined we dont have to check for inside model error.
        if (newParent) {
            if (this.getSubarray() instanceof Gazebo) {
                let outlinePoints = [];
                const tablePosition = this.getPosition();
                const tableDimension = this.getSubarray().getTableDimensions();

                outlinePoints.push([
                    tablePosition.x + (tableDimension.width / 2),
                    tablePosition.y + (tableDimension.length / 2),
                    tablePosition.z,
                ])

                outlinePoints.push([
                    tablePosition.x - (tableDimension.width / 2),
                    tablePosition.y + (tableDimension.length / 2),
                    tablePosition.z,
                ])

                outlinePoints.push([
                    tablePosition.x + (tableDimension.width / 2),
                    tablePosition.y - (tableDimension.length / 2),
                    tablePosition.z,
                ])

                outlinePoints.push([
                    tablePosition.x - (tableDimension.width / 2),
                    tablePosition.y - (tableDimension.length / 2),
                    tablePosition.z,
                ])

                let points = [];

                outlinePoints.forEach((outline) => {
                    points.push(new THREE.Vector3(outline[0], outline[1], 40))
                })

                let newPoints = [];

            for (let i = 0; i < 4; i++) {
                    newPoints.push(utils.rotationAroundPoint(
                        tablePosition.x,
                        tablePosition.y,
                        points[i].x,
                        points[i].y,
                        utils.deg2Rad(180 - this.getSubarray().getAzimuth()) + this.getChildren()[0].rotation,
                    ));
                }

                let dontPlace = false;
                for (let i = 0; i < newPoints.length; i++) {
                    if (!utils.checkPointInsideVertices(newParent.get2DVertices(), newPoints[i])) {
                        dontPlace = true;
                        break;
                    }
                }

                if (dontPlace) {
                    if(this.getSubarray().objectType === 'Gazebo'){
                        response.errors.push(new Error(OUT_OF_POLYGON_MODEL));
                    }
                    else if(this.getSubarray().objectType === 'Patio'){
                        response.errors.push(new Error(OUT_OF_POLYGON_EDGE));
                    }
                    parentExists = false;
                }
                // parentExists = false;
            }
        }
        // checking the mousepoint whether it is inside ground or outside for placing patio.
        if (mousePoint && !utils.checkPointInsideVertices(this.stage.ground.get2DVertices(), [mousePoint.x,mousePoint.y,mousePoint.z])) {
            response.errors.push(new Error(OUT_OF_POLYGON_EDGE));
        }

        if (parentExists) {
            newParent = this.clickToAdd ? newParent : this.getSubarray().getAssociatedModel();
            response.parent = newParent;
        }
        return response;
    }

    checkWhetherPatioIsOnEdge(deltaX = 0, deltaY = 0) {
        // getting the patio
        const patio = this.getSubarray();

        // start and end points of the original line i.e PatioEdge (edge of the model to which the patio is attached)
        const startPoint = new THREE.Vector3(patio.attachedPatioEdge[0].x, patio.attachedPatioEdge[0].y, 0);
        const endPoint = new THREE.Vector3(patio.attachedPatioEdge[1].x, patio.attachedPatioEdge[1].y, 0);

        // edgeDirectionVector vector of the original line
        const edgeDirectionVector = new THREE.Vector3().subVectors(endPoint, startPoint);

        // perpendicular vector to the original line
        const perpendicular = new THREE.Vector3(edgeDirectionVector.y, -edgeDirectionVector.x, 0).normalize();

        // perpendicular distance for the parallel line which is half of patio width
        const perpendicularDistance = patio.getTableDimensions().length / 2;

        // Scale the perpendicular vector by the desired perpendicularDistance
        const offset = perpendicular.clone().multiplyScalar(perpendicularDistance);

        // the endPoints of the line which is parallel to the AttachedPatioEdge and at a perpendicular distance of half of the patio table length
        const parallelLineStart = startPoint.clone().add(offset);
        const parallelLineEnd = endPoint.clone().add(offset);

        // parallel line at a perpendicular distance of half of the patio width length
        const parallelLine = [parallelLineStart, parallelLineEnd];

        // initial position
        const position = this.getPosition();
        // moved position of the table
        const movedPosition = new THREE.Vector3(position.x + deltaX, position.y + deltaY, 0);

        // checking whether the moved position is on line segment or not and storing it in a variable
        const onLineSegment = utils.checkIfPointLiesOnLineSegment(parallelLine, movedPosition);

        // For Debugging
        // const material = new THREE.LineBasicMaterial({
        //     color: 0x0000ff
        // });

        // const geometry = new THREE.BufferGeometry().setFromPoints(parallelLine);

        // this.parallelLine = new THREE.Line(geometry, material);
        // this.stage.sceneManager.scene.add(this.parallelLine);

        if (onLineSegment) {
            // if the center is on segment check for the distance between the center and the edge
            const startPointDistance = movedPosition.distanceToSquared(parallelLine[0]);
            const endPointDistance = movedPosition.distanceToSquared(parallelLine[1]);
            const checkDistance = startPointDistance < endPointDistance ? startPointDistance : endPointDistance;
            if (checkDistance < (patio.getTableDimensions().width / 2 * patio.getTableDimensions().width / 2)) {
                // console.log('patio getting outside of the segment');
                return false;
            }
            // console.log('patio is on segment');
            return true;
        } else {
            // console.log('patio getting outside of the segment');
            return false;
        }
    }

    getPlacingInformation(mousePoint) {
        const subarrayParentModel = this.getSubarray().getAssociatedModel();
        let position = this.getPosition();
        const offset = 0.01;
        let boundingBoxVertices = [
            [position.x - offset, position.y - offset],
            [position.x - offset, position.y + offset],
            [position.x + offset, position.y - offset],
            [position.x + offset, position.y + offset],
        ];
        if (mousePoint) {
            boundingBoxVertices = [
                [mousePoint.x - offset, mousePoint.y - offset],
                [mousePoint.x - offset, mousePoint.y + offset],
                [mousePoint.x + offset, mousePoint.y + offset],
                [mousePoint.x + offset, mousePoint.y - offset],
            ];
        }

        // Two conditions for placement = at least one vertex should be on the associated model of
        // the subarray & all vertices should be open (i.e. no vertex should have a model on top
        // of it on placement)

        const response = {};
        let parentExists = true;
        response.errors = [];

        const allModels =
            raycastingUtils.getAllModelsBelowVertices(boundingBoxVertices, this.stage);

        let newParent;
        for (let idx = 0, len = allModels.length; idx < len; idx += 1) {
            if (!allModels[idx][0].isIgnored()) {
                [newParent] = allModels[idx];
                break;
            }
        }

        // if parent is undefined we dont have to check for inside model error.
        if (newParent) {
            if (this.getSubarray() instanceof Gazebo) {
                let outlinePoints = [];
                const tablePosition = this.getPosition();
                const tableDimension = this.getSubarray().getTableDimensions();

                outlinePoints.push([
                    tablePosition.x + (tableDimension.width / 2),
                    tablePosition.y + (tableDimension.length / 2),
                    tablePosition.z,
                ])

                outlinePoints.push([
                    tablePosition.x - (tableDimension.width / 2),
                    tablePosition.y + (tableDimension.length / 2),
                    tablePosition.z,
                ])

                outlinePoints.push([
                    tablePosition.x + (tableDimension.width / 2),
                    tablePosition.y - (tableDimension.length / 2),
                    tablePosition.z,
                ])

                outlinePoints.push([
                    tablePosition.x - (tableDimension.width / 2),
                    tablePosition.y - (tableDimension.length / 2),
                    tablePosition.z,
                ])

                let points = [];

                outlinePoints.forEach((outline) => {
                    points.push(new THREE.Vector3(outline[0], outline[1], 40))
                })

                let newPoints = [];

            for (let i = 0; i < 4; i++) {
                    newPoints.push(utils.rotationAroundPoint(
                        tablePosition.x,
                        tablePosition.y,
                        points[i].x,
                        points[i].y,
                        utils.deg2Rad(180 - this.getSubarray().getAzimuth()) + this.getChildren()[0].rotation,
                    ));
                }

                let dontPlace = false;
                for (let i = 0; i < newPoints.length; i++) {
                    if (!utils.checkPointInsideVertices(newParent.get2DVertices(), newPoints[i])) {
                        dontPlace = true;
                        break;
                    }
                }

                if (dontPlace) {
                    if(this.getSubarray().objectType === 'Gazebo'){
                        response.errors.push(new Error(OUT_OF_POLYGON_MODEL));
                    }
                    else if(this.getSubarray().objectType === 'Patio'){
                        response.errors.push(new Error(OUT_OF_POLYGON_EDGE));
                    }
                    parentExists = false;
                }
                // parentExists = false;
            }
        }

        this.check_2FeetSetbackDistance();
        this.validatePropertyIntersection();

        if (this.getSubarray().objectType === POWER_PATIO) {
            if (this.error3FeetSetback || this.error2FeetSetback) {
                response.errors.push(new Error(POWERMODEL_INTERSECTS_WITH_OTHER_MODELS));
            }
            if (this.propertyIntersectionError) {
                response.errors.push(new Error(POWERMODEL_INTERSECTS_WITH_PROPERTY_LINE));
            }
        }

        // we cannot place the table on other model after it's placed on a model.
        // but it can be placed while gazebomode is enabled
        if (newParent !== subarrayParentModel && !this.stage.gazeboMode.enable) {
            response.errors.push(new Error(OUT_OF_ASSOCIATED_MODEL_ERROR));
            parentExists = false;
        }
        if (!raycastingUtils.areVerticesOnGround(utils
                .convertArrayToVector(this.getVerticesFrom3DBoundingBox(this.getSubarray())), this.stage)) {
            response.errors.push(new Error(TABLE_OUT_OF_GROUND_ERROR));
            parentExists = false;
        }
        if (Number.isNaN(this.getSubarray().getTiltWrtParentSurface(newParent))) {
            if (newParent.getTilt() !== this.getSubarray().getState().tilt && this.getSubarray().getState().mountType === SUBARRAY_RACK_STYLE_FLUSH) {
                parentExists = true;
            } else {
                response.errors.push(new Error(COMBINATION_IS_NOT_POSSIBLE_ERROR));
                parentExists = false;
            }
        }
        if(this.getSubarray().objectType === 'Gazebo' || this.getSubarray().objectType === 'Patio'){
            if (newParent.getTilt() > 0) {
                response.errors.push(new Error(POWER_MODEL_CANT_BE_PALCED_ON_TILTED_SURFACE));
                parentExists = false;
            }
        }

        if (parentExists) {
            newParent = this.clickToAdd ? newParent : this.getSubarray().getAssociatedModel();
            response.parent = newParent;
        }
        return response;
    }

    updateGeometry() {
        const patio = this.getSubarray();
        let setbackOutsideGeometry = createBufferGeometry();
        let setbackCommon3FeetGeometry = createBufferGeometry();
        if (patio.setbackOutside) {
            setbackOutsideGeometry = this.getSetbackOutsideGeometry(patio.setbackOutside);
            setbackCommon3FeetGeometry = this.getSetbackOutsideGeometry(patio.mergedSetbackForPatio);

            const { count } = setbackOutsideGeometry.attributes.position;

            patio.setbackVertices = this.alignVerticesForPatioWithSetback(setbackOutsideGeometry, patio.setbackOutside);
            patio.mergedSetbackVertices = this.alignVerticesForPatioWithSetback(setbackCommon3FeetGeometry, patio.mergedSetbackForPatio);

            // TODO: setting proper Z position for the setbackGeometry

            for (let i = 0; i < count; i += 1) {
                setbackOutsideGeometry.attributes.position.setZ(i, 10);
                setbackCommon3FeetGeometry.attributes.position.setZ(i, 10);
            }
        }
        patio.setbackOutsideMesh.geometry = setbackOutsideGeometry;
    }

    alignVerticesForPatioWithSetback(geometry, reqSetback) {
        const point1 = new THREE.Vector3(geometry.attributes.position.getX(0), geometry.attributes.position.getY(0), 10);
        const point2 = new THREE.Vector3(geometry.attributes.position.getX(1), geometry.attributes.position.getY(1), 10);
        const point3 = new THREE.Vector3(geometry.attributes.position.getX(2), geometry.attributes.position.getY(2), 10);
        const point4 = new THREE.Vector3(geometry.attributes.position.getX(3), geometry.attributes.position.getY(3), 10);
        const newPointA = this.shortenDistanceWithSetback(reqSetback, point1, point2);
        const newPointB = this.shortenDistanceWithSetback(reqSetback, point4, point3);

        geometry.attributes.position.setX(0, newPointA.x);
        geometry.attributes.position.setY(0, newPointA.y);
        geometry.attributes.position.setZ(0, 10);
        geometry.attributes.position.setX(3, newPointB.x);
        geometry.attributes.position.setY(3, newPointB.y);
        geometry.attributes.position.setZ(3, 10);

        return [newPointA, point2, point3, newPointB];
    }

    shortenDistanceWithSetback(setbackDistance, point1, point2) {
        const D = Math.sqrt((point1.x - point2.x) ** 2 + (point1.y - point2.y) ** 2);
        const ratio = setbackDistance / D;
        // TODO: properly get and set Z value. Setting Z as 10 for now
        const requiredPoint = new THREE.Vector3(((1 - ratio) * point1.x + (ratio * point2.x)), ((1 - ratio) * point1.y + (ratio * point2.y)), 10);
        return requiredPoint;
    }

    getSetbackOutsideGeometry(requiredSetbackWidth) {
        const patio = this.getSubarray();
        // create setbackOutside
        let setbackOutsideGeometry = new THREE.BufferGeometry();
        let setbackOutsideShapes = [];
        if (this.getParent() && patio?.objectType === POWER_PATIO) {
            let innerPolygonPoints;
            for (let row of patio.getChildren()) {
                const pointsArray = row.get3DBoundingBoxesExcludingHiddenTables();
                if (pointsArray.length > 0) innerPolygonPoints = utils.convertVectorArrayTo2DArray([...pointsArray][0]);
                else return [];
            }
            const base3DVerticesArray = [];
            for (let point of innerPolygonPoints) {
                    base3DVerticesArray.push([point[0], point[1], 10]);
            }
            const setbackOutside3DPoints = utils.getSetbackPoints(base3DVerticesArray, requiredSetbackWidth);
            const setbackOutsidePoints = utils.convertVectorArrayTo2DArray(setbackOutside3DPoints);
            const parentPolygonPoints = patio.getParent().get2DVertices();
            let setbackVertices = utils.outsideSetbackIntersectionWithParent(setbackOutsidePoints, innerPolygonPoints, parentPolygonPoints);

            if (setbackVertices instanceof Error) {
                if (setbackVertices.message === PARENT_WITHIN_CHILD_SO_NO_SETBACK_OUTSIDE_ERROR) {
                    return new THREE.BufferGeometry();
                }
            }

            if (setbackVertices[0][0].length > 0) {

                if (patio.azimuth >= 0 && patio.azimuth < 90) {
                    setbackVertices[0][0] = this.shiftBackArrayElements(setbackVertices[0][0], 0);
                }
                if (patio.azimuth >= 90 && patio.azimuth < 180) {
                    setbackVertices[0][0] = this.shiftBackArrayElements(setbackVertices[0][0], 1);
                }
                if (patio.azimuth >= 180 && patio.azimuth < 270) {
                    setbackVertices[0][0] = this.shiftBackArrayElements(setbackVertices[0][0], 2);
                }
                if (patio.azimuth >= 270 && patio.azimuth <= 360) {
                    setbackVertices[0][0] = this.shiftBackArrayElements(setbackVertices[0][0], 3);
                }
            }
            patio.setbackVertices = setbackVertices[0][0];
            for (let i = 0; i < setbackVertices[0].length; i++) {
                if (setbackVertices[0][i].length > 0) {
                    let setbackOutsideShape = new THREE.Shape(utils.convertArrayToVector(setbackVertices[0][i]));
                    let holesInShape = [];
                    for (let j = 0; j < setbackVertices[1][i].length; j++) {
                        let hole = new THREE.Path(utils.convertArrayToVector(setbackVertices[1][i][j]));
                        holesInShape.push(hole);
                    }
                    setbackOutsideShape.holes = holesInShape;
                    setbackOutsideShapes.push(setbackOutsideShape);
                }
            }

            setbackOutsideGeometry = new THREE.ShapeGeometry(setbackOutsideShapes);
            setbackOutsideGeometry.translate(0, 0, this.baseHeight);
        }
        return setbackOutsideGeometry;
    }

    shiftBackArrayElements(array, shiftCount) {
        for (let i = 0; i < shiftCount; i++) {
            array.push(array.shift());
        }
        return array;
    }

    // gets all patios from current attached edge of patio and checks for 3 feet setback intersection among them.
    getPatiosForMovementAttachedToMovingEdge() {
        const patio = this.getSubarray();
        if (patio.attachment?.attachments) {
            let patiosAttachedToModel = patio.attachment.attachments;
            const tempAttachedEdge = patio.attachedPatioEdge;
            let requiredPatiosFromCurrentEdge = [];
            for (let i in patiosAttachedToModel) {
                if ((patiosAttachedToModel[i].id !== patio.id) &&
                    (JSON.stringify(patiosAttachedToModel[i].attachedPatioEdge[0]) === JSON.stringify(tempAttachedEdge[0])) &&
                    (JSON.stringify(patiosAttachedToModel[i].attachedPatioEdge[1]) === JSON.stringify(tempAttachedEdge[1]))) {
                    patiosAttachedToModel[i].getTables()[0].updateGeometry();
                    requiredPatiosFromCurrentEdge.push(patiosAttachedToModel[i]);
                }
            }
            this.check3feetMergedSetbackIntersection(requiredPatiosFromCurrentEdge);
        }
    }

    // check 3feet merged setba ck intersection among patios.
    check3feetMergedSetbackIntersection(requiredPatios) {
        let currentPatio2ftVertices = this.getSubarray().mergedSetbackVertices;
        this.setSetbackSideEdgesForPatios(this.getSubarray());
        for (let i = 0; i < requiredPatios.length; i++) {
            this.setSetbackSideEdgesForPatios(requiredPatios[i]);
            let v1 = utils.convertVectorToArray(currentPatio2ftVertices);
            let v2 = utils.convertVectorToArray(requiredPatios[i].mergedSetbackVertices);
            if (utils.checkPolygonIntersection(v1, v2)) {
                this.switchVisualState(VISUAL_STATES.ERROR, true);
                this.error3FeetSetback = true;
            }
            else {
                this.error3FeetSetback = false;
            }
        }
    }

    setSetbackSideEdgesForPatios(patio) {
        const temp = patio.mergedSetbackVertices;
        patio.setbackSideEdges = { edge1: [temp[0], temp[1]], edge2: [temp[3], temp[2]] };
    }

    // check 2feet setback intersection of patio with models.
    check_2FeetSetbackDistance() {
        this.error2FeetSetback = false;
        let currentPatio2ftVertices = this.newGazebo ? this.newGazebo.setbackVertices : this.getSubarray().setbackVertices;
        this.getSubarray().modelsForPatio2feetIntersection.forEach((model) => {
            let v1 = utils.convertVectorToArray(currentPatio2ftVertices);
            let v2;
            if (model.objectType === POWER_PATIO) {
                v2 = this.getVerticesFrom3DBoundingBox(model);
            }
            else {
                v2 = model.get2DVertices();
            }
            if (v1 && v2 && utils.checkPolygonIntersection(v1, v2)) {
                this.switchVisualState(VISUAL_STATES.ERROR, true);
                this.error2FeetSetback = true;
            }
        });
    }

    updateWhilePlacing(placingInformation) {
        if (placingInformation.errors.length !== 0) {
            return;
        }
        // if (placingInformation.parent !== this.getSubarray().getParent()) {
        //     this.getSubarray().changeParent(placingInformation.parent);
        //     this.getSubarray().associatedModel = placingInformation.parent;
        //     this.getSubarray().createBoundaryFromParent();
        //     this.changeTableDuringCreation();
        // }
    }

    async updateWhileHovering(prevParent, parent, forGazebo = false) {
        if (this.parent == null || this.parent.parent == null) {
            return;
        }
        if (prevParent) {
            const parentSubarray = this.getSubarray();
            parentSubarray.changeParent(parent);
            const newSubarrayProperties = this.getSubarray().getState();
            if (newSubarrayProperties.mountType === SUBARRAY_RACK_STYLE_FLUSH) {
                newSubarrayProperties.azimuth = parent.getAzimuth();
                newSubarrayProperties.tilt = parent.getTilt();
            }
            if (parent instanceof PolygonModel || parent instanceof SmartroofFace ||
                parent instanceof Ground) {
                parentSubarray.changeTablePropertiesDuringCreation(newSubarrayProperties);
                if (forGazebo) {
                    this.stage.eventManager.addGazeboMode(parentSubarray);
                }
                else {
                    this.stage.eventManager.addTableMode(parentSubarray);
                }
            }
        }
    }

    getSubarrayProperties(placedSubarray, placableSubarray) {
        if (placedSubarray != null && placedSubarray != undefined) {
            return {
                name: placedSubarray.name,
                azimuth: placedSubarray.azimuth,
                moduleProperties: placedSubarray.moduleProperties,
                bifacialEnabled: placedSubarray.bifacialEnabled,
                moduleSpacingUp: placedSubarray.moduleSpacingUp,
                moduleSpacingWide: placedSubarray.moduleSpacingWide,
                mountHeight: placedSubarray.mountHeight,
                mountType: placedSubarray.mountType,
                panelOrientation: placedSubarray.panelOrientation,
                rowSpacing: placedSubarray.rowSpacing,
                rowSpacingMode: placedSubarray.rowSpacingMode,
                structureType: placedSubarray.structureType,
                tableSizeUp: placedSubarray.tableSizeUp,
                tableSizeWide: placedSubarray.tableSizeWide,
                tableSpacing: placedSubarray.tableSpacing,
                tilt: placedSubarray.tilt,
            }
        }

        return {
            name: placableSubarray.name,
            azimuth: placableSubarray.azimuth,
            moduleProperties: placableSubarray.moduleProperties,
            bifacialEnabled: placableSubarray.bifacialEnabled,
            moduleSpacingUp: placableSubarray.moduleSpacingUp,
            moduleSpacingWide: placableSubarray.moduleSpacingWide,
            mountHeight: placableSubarray.mountHeight,
            mountType: placableSubarray.mountType,
            panelOrientation: placableSubarray.panelOrientation,
            rowSpacing: placableSubarray.rowSpacing,
            rowSpacingMode: placableSubarray.rowSpacingMode,
            structureType: placableSubarray.structureType,
            tableSizeUp: placableSubarray.tableSizeUp,
            tableSizeWide: placableSubarray.tableSizeWide,
            tableSpacing: placableSubarray.tableSpacing,
            tilt: placableSubarray.tilt,
        }
    }

    // TODO: add a function to update the subarrays for removed tables.
    removeIntersectingTables() {
        const intersectingTables =
            raycastingUtils.getAllTablesBelowVertices(this.getVerticesFrom3DBoundingBox(this.getSubarray()), this.stage);
        for (let i = 0, l = intersectingTables.length; i < l; i += 1) {
            if (intersectingTables[i] !== this) {
                intersectingTables[i].removeObject();
            }
        }
    }

    placeObject(deltaX = 0, deltaY = 0, options = {}, isLinked = true) {
        const patioIsOnEdge = this.checkWhetherPatioIsOnEdge(deltaX, deltaY);
        if (!patioIsOnEdge) {
            this.stage.eventManager.customErrorMessage(POWERMODEL_OUTSIDE_OF_EDGE, 'Table')
            return Promise.reject(new Error(POWERMODEL_OUTSIDE_OF_EDGE));
        }
        // JUGAAD: panels were shaking because the subarrays had different azimuth
        // TODO: FIX for east west copypaste
        if (this.getSubarray() && this.getSubarray().rackSubarray) {
            if (this.getSubarray().objectType === 'EastWestRack' && this.getSubarray().azimuth === this.getSubarray().rackSubarray.azimuth) {
                let newAzimuth = this.getSubarray().rackSubarray.azimuth + 180;
                if (newAzimuth >= 360) newAzimuth -= 360;
                this.getSubarray().azimuth = newAzimuth;
                this.getSubarray().updateGeometry();
            }
        }
        // table is placed
        this.notPlaced = false;
        const defaultOptions = {
            objectSelected: false,
        };
        const customOptions = Object.assign(defaultOptions, options);

        // mark table as moved for solar access calculation
        this.isMoved = true;

        this.moveObject(deltaX, deltaY, 0);

        // check if each panel in the table is placable in the current position
        const errorsWhilePlacing = this.getPlacingInformation().errors;

        if (errorsWhilePlacing.length !== 0 || this.stage.getRemainingDcSize() < 0) {
            this.removeObject(
                undefined,
                undefined, { objectSelected: customOptions.objectSelected },
            );
            if (errorsWhilePlacing.length !== 0) {
                this.stage.eventManager.customErrorMessage(errorsWhilePlacing[0].message, 'Table');
            } else {
                this.stage.eventManager.dcCapSizeExceeded();
            }

            return Promise.reject(errorsWhilePlacing[0]);
        }

        // show all hidden tables
        let hiddenTablesList = this.getSubarray().getHiddenTables();
        for (let hiddenTable of hiddenTablesList) {
            hiddenTable.showTable();
        }

        this.removeIntersectingTables();

        // hide remaining hidden tables
        for (let hiddenTable of hiddenTablesList) {
            hiddenTable.hideTable();
        }
        // change parent to new row and handel the consequences
        this.getSubarray().findAndSetNewParentForTable(this);

        // place table along z (only required when the roof is tilted)
        const lowestVertex = this.getLowestVertex();
        const associatedModelZ = Math.max(
            this.getSubarray().getAssociatedModel().getZOnTopSurface(
                lowestVertex.x,
                lowestVertex.y,
            ),
            0,
        );
        let deltaZ = this.getSubarray().getMountHeight() - (lowestVertex.z - associatedModelZ);
        this.moveObject(0, 0, deltaZ);

        // update dimensions
        for (let dimension in this.dimensionObjects ) {
            this.dimensionObjects[dimension].handleAssociatedObjectPlace(this);
        }

        if (this.getSubarray() instanceof Gazebo) {
            if (this.getSubarray().rotationPoints) {
                this.getSubarray().createBoundaryFromBB();
                this.getSubarray().updateGeometry();
            }
        }

        this.getSubarray().structureUpdateRequired = true;
        this.getSubarray().updateRail();

        if (isLinked) {
            if (this.linkedTable) {
                this.linkedTable.placeObject(deltaX, deltaY, options, false)
            }
        }
        if (this.stage.mode === SALES_MODE) {
            this.stage.selectionControls.setSelectedObjects(this.stage.selectionControls.getSelectedObjects());
            this.stage.visualManager.updateVisualsForAddTableMode(false);
        }
        this.saveState();

        return Promise.resolve(true);
    }

    /**
     * removes the table from the scene and returns the intersecting tables
     * without using the actual flow of removeObject
     */
    removeIntersectingTablesFromScene() {
        const intersectingTables =
            raycastingUtils.getAllTablesBelowVertices(this.getVerticesFrom3DBoundingBox(this.getSubarray()), this.stage);
        const tablesRemoved = [];
        for (let i = 0, l = intersectingTables.length; i < l; i += 1) {
            if (intersectingTables[i] !== this) {
                if (intersectingTables[i].getSubarray().addTableFlow) {
                    this.stage.addTableMode
                        .removeIntersectedSubarray(intersectingTables[i].getSubarray());
                    intersectingTables[i].removeObject();
                } else {
                    intersectingTables[i].removePanelsMeshFromScene();
                    tablesRemoved.push(intersectingTables[i]);
                    // intersectingTables[i].getSubarray()
                    //     .mergeGeometriesForAllPanels({ excludeTables: [intersectingTables[i]] });
                }
            }
        }
        return tablesRemoved;
    }

    getIntersectedTableWithoutRemoving(shouldHideMergedMesh = false) {
        let verticesForCheck;
        if (this.getSubarray()?.objectType === POWER_PATIO) {
            verticesForCheck = this.getSubarray().mergedSetbackVertices;
        }
        else {
            verticesForCheck = this.getVerticesFrom3DBoundingBox(this.getSubarray());
        }
        const intersectingTables =
            raycastingUtils.getAllPowerTablesBelowVertices(verticesForCheck, this.stage);
        const GazeboRemoved = [];
        const subarrayToBeUpdated = [];
        for (let i = 0, l = intersectingTables.length; i < l; i += 1) {
            if (intersectingTables[i] !== this) {
                if (intersectingTables[i].getSubarray() instanceof Gazebo) {
                    GazeboRemoved.push(intersectingTables[i].getSubarray())
                    if (shouldHideMergedMesh) {
                        intersectingTables[i].getSubarray().hideMergedMeshes(); 
                        intersectingTables[i].hideIndividualMesh();
                    }
                }
                else {
                    if (!subarrayToBeUpdated.includes(intersectingTables[i].getSubarray())) {
                        subarrayToBeUpdated.push(intersectingTables[i].getSubarray());
                    }
                }
            }
        }
        subarrayToBeUpdated.forEach(subarray => {
            subarray.hideMergedMeshes();
        });
        return {
            GazeboRemoved, 
            subarrayToBeUpdated
        };
    }

    placeObjectForAddTable(deltaX = 0, deltaY = 0) {
        // mark table as moved for solar access calculation
        this.isMoved = true;

        this.moveObject(deltaX, deltaY, 0);

        // check if each panel in the table is placable in the current position
        const placingInformation = this.getPlacingInformation();
        const errorsWhilePlacing = placingInformation.errors;
        if (placingInformation.parent !== this.getSubarray().getParent() && placingInformation.parent) {
            this.getSubarray().changeParent(placingInformation.parent);
            this.getSubarray().associatedModel = placingInformation.parent;
            this.getSubarray().createBoundaryFromParent();
            this.changeTableDuringCreation();
        }
        if (errorsWhilePlacing.length !== 0 || this.stage.getRemainingDcSize() < 0) {
            this.removeObject(
                undefined,
                undefined, { objectSelected: true },
            );
            if (errorsWhilePlacing.length !== 0) {
                this.stage.eventManager.customErrorMessage(errorsWhilePlacing[0].message, 'Table');
                return errorsWhilePlacing[0];
            }
            this.stage.eventManager.dcCapSizeExceeded();
            return new Error(DC_CAP_REACHED_ERROR);
        }

        if (this.clickToAdd && placingInformation.parent) {
            this.getSubarray().changeParent(placingInformation.parent);
            this.getSubarray().associatedModel = placingInformation.parent;
        }

        // show all hidden tables
        let hiddenTablesList = this.getSubarray().getHiddenTables();
        for (let hiddenTable of hiddenTablesList) {
            hiddenTable.showTable();
        }

        // const tablesRemoved = this.removeIntersectingTablesFromScene();
        // for removing the intersecting tables while 
        // copy paste the gazebo by clicking alt
        if (this.getSubarray() instanceof Gazebo && !this.getSubarray().addTableMode){
            this.removeIntersectingTables();
        }

        // hide remaining hidden tables
        for (let hiddenTable of hiddenTablesList) {
            hiddenTable.hideTable();
        }

        this.getSubarray().removeOutlinePoints();
        // this.getSubarray().createConvexHull();

        const allSubarrayProperties = [];
        const allSubarraySiblings = this.getSubarray().getParent().getChildren();
        for (let i = 0, len = allSubarraySiblings.length; i < len; i += 1) {
            if (allSubarraySiblings[i] instanceof Subarray) {
                const property = allSubarraySiblings[i].getState();
                property.subarray = allSubarraySiblings[i];
                allSubarrayProperties.push(property);
            }
        }
        if (this.stage.snapManager.isSnapped()) {
            const snapInfo = getNearestSubarrayForTableSnapping(
                allSubarrayProperties,
                this,
                this.stage.mousePoint.clone(),
            );
            if (snapInfo.hasOwnProperty('snappingSubarray')) {
                const localTablePosition = snapInfo.snappingSubarray
                    .globalToLocalCoordinates(
                        this.getPosition(true),
                        snapInfo.snappingSubarray.getBoundingBox(),
                    );
                const rowMinY = snapInfo.snappingRowBBox.minY;
                const rowMaxY = snapInfo.snappingRowBBox.maxY;
                const tableLength = this.getSubarray().getTableDimensions(true).length *
                    Math.cos(utils.toRadian(this.getSubarray().getTilt()));
                const tableMinY = snapInfo.tableLocalPosition.y - (tableLength / 2);
                const tableMaxY = snapInfo.tableLocalPosition.y + (tableLength / 2);
                const tableWithinRows = (rowMinY <= tableMaxY && rowMinY >= tableMinY) ||
                    (rowMaxY <= tableMaxY && rowMinY >= tableMinY) ||
                    (tableMaxY <= rowMaxY && tableMaxY >= rowMinY) ||
                    (tableMinY <= rowMaxY && tableMinY >= rowMinY);
                if (tableWithinRows) {
                    const surfaceTilt = snapInfo.snappingSubarray.getParent().getTilt();
                    const subarrayTilt = snapInfo.snappingSubarray.getTilt();
                    const yAlongBBoxFactor = Math.cos(utils.deg2Rad(subarrayTilt - surfaceTilt));

                    const tableHighestY = localTablePosition.y +
                        ((this.getSubarray().getTableDimensions().length * yAlongBBoxFactor) / 2);
                    const panelLength = (
                            this.getSubarray().panelOrientation === PANEL_ORIENTATION_PORTRAIT ?
                            this.getSubarray().moduleProperties.moduleLength :
                            this.getSubarray().moduleProperties.moduleWidth
                        ) +
                        this.getSubarray().moduleSpacingUp;
                    const num = Math.round((snapInfo.snappingRowBBox.maxY - tableHighestY) /
                        (panelLength * yAlongBBoxFactor));
                    const hDiff = num * panelLength * (Math.sin(utils.deg2Rad(subarrayTilt)) -
                        (Math.tan(utils.deg2Rad(surfaceTilt)) *
                            Math.cos(utils.deg2Rad(subarrayTilt))));

                    const calculatedMountHeight =
                        parseFloat((snapInfo.snappingSubarray.mountHeight + hDiff).toFixed(3));
                    // Jugad Fix: while using addtable panels are comming on ground or inside structure.
                    if (calculatedMountHeight > 0 && !(this.getSubarray() instanceof Gazebo)) {
                        this.getSubarray().mountHeight = calculatedMountHeight;
                    }
                }
            }
        }
        // place table along z (only required when the roof is tilted)
        const lowestVertex = this.getLowestVertex();
        const associatedModelZ = Math.max(
            this.getSubarray().getAssociatedModel().getZOnTopSurface(
                lowestVertex.x,
                lowestVertex.y,
            ),
            0,
        );
        const deltaZ = this.getSubarray().getMountHeight() - (lowestVertex.z - associatedModelZ);
        this.moveObject(0, 0, deltaZ);

        // update dimensions
        for (let dimension in this.dimensionObjects) {
            this.dimensionObjects[dimension].handleAssociatedObjectPlace(this);
        }

        if (this.getSubarray().rotationPoints) {
            this.getSubarray().createBoundaryFromBB();
            this.getSubarray().updateGeometry();
        }

        this.saveState();
        return {
            isSuccess: true,
            tablesRemoved: {},
        };
    }

    placeObjectForPatio(deltaX = 0, deltaY = 0, checkPlacingInfo = true) {
        this.outGndErr = false;
        if (this.isSelected) {
            // this.updateGeometry();
            if (this.getSubarray()?.attachment && this.getSubarray().attachment.attachedTo) {
                this.getSubarray().initPropertyIntersectParams();
                this.getSubarray().initGroundModelIntersectParams();
            }
        }
        else {
            this.hidePatioSetback();
        }
        this.moveObject(deltaX, deltaY, 0);

        // TODO: handle the place object for patio and this function more clearly.
        const lowestVertex = this.getLowestVertex();
        const associatedModelZ = Math.max(
            this.getSubarray().getAssociatedModel().getZOnTopSurface(
                lowestVertex.x,
                lowestVertex.y,
            ),
            0,
        );
        const deltaZ = this.getSubarray().getMountHeight() - (lowestVertex.z - associatedModelZ);
        this.moveObject(0, 0, deltaZ);
        if (this.outGndErr) {
            this.removeObject();
            this.stage.eventManager.setPatioOutOfPolygonRemoved();
            this.stage.selectionControls.selectGround();
            this.outGndErr = false;
            return;
        }
        // remove tables when intersecting with other tables or models.
        if (!this.stage.addAttachmentMode.enabled && !(this.error3FeetSetback || this.error2FeetSetback || this.propertyIntersectionError)) this.removeIntersectingTables();

        // prevent getting placing info on patio placement in add patio mode for the newly created patio while updation(refer->addattachmentutils->moveAttachedElement())
        if (checkPlacingInfo) {
            const placingInformation = this.getPlacingInformation();
            const errorsWhilePlacing = placingInformation.errors;
            if (errorsWhilePlacing.length !== 0) {
                this.removeObject(
                    undefined,
                    undefined, { objectSelected: true },
                );
                this.stage.eventManager.customErrorMessage(errorsWhilePlacing[0].message, 'Table');
                return errorsWhilePlacing[0];
            }
        }
        if (this.invalidPatioPlacement) {
            this.removeObject();
            this.stage.eventManager.setPatioOutOfPolygonRemoved();
            this.stage.selectionControls.selectGround();
            this.invalidPatioPlacement = false;
            //juggad : patios are not selecting if one patio goes out of the limit
            if (!this.stage.selectionControls.selectionEnabled) this.stage.selectionControls.enable();
        }
        if (this.error3FeetSetback || this.error2FeetSetback) {
            this.removeObject();
            this.getSubarray().disposeSetbackGeometry();
            this.stage.eventManager.customErrorMessage(POWERMODEL_INTERSECTS_WITH_OTHER_MODELS, 'Table');
        }
        if (this.propertyIntersectionError) {
            this.removeObject();
            this.getSubarray().disposeSetbackGeometry();
            this.stage.eventManager.customErrorMessage(POWERMODEL_INTERSECTS_WITH_PROPERTY_LINE, 'Table');
        }

        // update dimensions
        for (let dimension in this.dimensionObjects) {
            this.dimensionObjects[dimension].handleAssociatedObjectPlace(this);
        }

        if (this.getSubarray()?.rotationPoints) {
            this.getSubarray().createBoundaryFromBB();
            this.getSubarray().updateGeometry();
        }
    }

    changeTableDuringCreation() {
        while (this.getChildren().length > 0) {
            this.getChildren()[0].removeObject();
        }
        const tableMap = this.getSubarray().getCustomTableMapForAddTable(
            new THREE.Vector3(), { withBBox: true },
        );
        const panelMaps = tableMap.panels !== undefined ? tableMap.panels : [];
        for (let i = 0, len = panelMaps.length; i < len; i += 1) {
            const panel = new Panel(this.stage, panelMaps[i]);
            this.addChild(panel);
            panel.switchVisualState(this.getVisualState(), true);
            panel.addToScene();
        }

        this.getSubarray().getTableDimensions(true);
        const position = this.getPosition(true);
        // TODO : change this method
        const highestZ = utils.getHighestZ(this.stage.ground) + 5;
        this.moveObject(0, 0, highestZ - position.z);

        // JUGAD: need to do this temp action because of some bug in BufferGeometry for Panel.
        this.stage.sceneManager.scene.remove(this.objectsGroup);
        this.stage.sceneManager.scene.add(this.objectsGroup);
    }

    getLowestVertex() {
        let lowestVertex = new THREE.Vector3(0, 0, Infinity);
        for (let panel of this.getChildren()) {
            let panelLeastHighVertex = panel.getLowestVertex();
            if (panelLeastHighVertex.z < lowestVertex.z) {
                lowestVertex = panelLeastHighVertex;
            }
        }
        return lowestVertex;
    }

    shouldPropagate(model) {
        if (model instanceof Panel || model instanceof Subarray || model instanceof Table ||
            model instanceof Row) {
            return true;
        }
        return false;
    }

    handleDragStart() {
        for (let childIndex = 0; childIndex < this.children.length; childIndex += 1) {
            const child = this.children[childIndex];
            if (this.shouldPropagate(child)) {
                child.handleDragStart();
            }
        }
    }

    handleDragMove(deltaX, deltaY) {
        this.moveObject(deltaX, deltaY, 0);
        if (this.getSubarray().allPropertyEdges) this.validatePropertyIntersection();
    }

    handleDragEnd(deltaX = 0, deltaY = 0) {
        for (let childIndex = 0; childIndex < this.children.length; childIndex += 1) {
            const child = this.children[childIndex];
            if (this.shouldPropagate(child)) {
                child.handleDragEnd();
            }
        }
        if (this.getSubarray() instanceof Gazebo) {
            if (this.getSubarray().objectType === 'Patio') {
                this.placeObjectForPatio();
            }
            else {
                this.placeObjectForAddTable(deltaX, deltaY, { objectSelected: true });
                this.getSubarray().saveState();
            }
        }
        else {
            this.placeObject(deltaX, deltaY, { objectSelected: true });
        }
    }

    // Visual Functions

    updateVisualsBasedOnStates() {}

    switchVisualState(newVisualState) {
        super.switchVisualState(newVisualState, true);
    }

    getColorMap() {
        const colorMapping = COLOR_MAPPINGS.TABLE;
        if (this.materialAndVisualStatesExist(colorMapping)) {
            return colorMapping[this.materialState][this.visualState];
        }
        return {};
    }

    // Helper functions

    showTable() {
        if (this.hidden) {
            this.hidden = false;
            for (let child of this.getChildren()) {
                child.showPanel();
            }
            this.saveState();
        }
    }

    hideTable() {
        if (!this.hidden) {
            this.hidden = true;
            for (let child of this.getChildren()) {
                child.hidePanel();
            }
            this.saveState();
        }
    }

    showObjectLayer() {
        for (let child of this.getChildren()) {
            child.showObjectLayer();
        }
    }

    hideObjectLayer() {
        for (let child of this.getChildren()) {
            child.hideObjectLayer();
        }
    }

    isHidden() {
        return this.hidden;
    }

    getDcSize() {
        return this.getNumberOfPanels() * this.getSubarray().getPanelSize();
    }

    getNumberOfPanels() {
        if (this.hidden) {
            return 0;
        }
        return this.getChildren().length;
    }

    getNumberOfPanelsIncludingHidden() {
        let count = 0;
        for (let i = this.getChildren().length - 1; i >= 0; i--) {
            if (this.getChildren()[i] instanceof Panel) {
                count++;
            }
        }
        return count;
    }

    getTableMap({ withSolarAccess } = { withSolarAccess: true }) {
        let tableMap = {
            id: this.id,
            panels: [],
            position: {
                x: this.getPosition().x,
                y: this.getPosition().y,
                z: this.getPosition().z,
            },
        };
        if (this.isMoved) tableMap.isMoved = this.isMoved;
        if (this.hidden) tableMap.hidden = this.hidden;

        for (let panel of this.getChildren()) {
            const panelMap = panel.getPanelMap({ withSolarAccess: withSolarAccess });
            if(panelMap.corners.length > 0) tableMap.panels.push(panelMap);
        }
        return tableMap;
    }

    isSolarAccessComputed() {
        for (let child of this.getChildren()) {
            if (!child.isSolarAccessComputed()) {
                return false;
            }
        }
        return true;
    }

    getTotalSolarAccess(isLinkedTable = false) {
        let totalSolarAccess = 0;
        if (!this.hidden) {
            for (let child of this.getChildren()) {
                totalSolarAccess += child.getSolarAccess();
            }
        }

        if (this.linkedTable && !isLinkedTable) {
            totalSolarAccess = (this.linkedTable.getTotalSolarAccess(true) + totalSolarAccess) / 2;
        }
        return totalSolarAccess;
    }

    getAverageSolarAccess() {
        let nPanels = this.getNumberOfPanels();
        if (nPanels > 0) return this.getTotalSolarAccess() / nPanels;
        else return 0;
    }

    _getAverageSolarAccessIncludingHidden() {
        let totalSolarAccess = 0;
        for (let child of this.getChildren()) {
            totalSolarAccess += child.getSolarAccess();
        }
        return totalSolarAccess / this.getChildren().length;
    }

    getSubarray() {
        if (this.getParent()) {
            return this.getParent().getParent();
        }
        return null;
    }

    get tableSize() {
        return this.getSubarray().tableSize;
    }

    get2DVertices() {
        // Cached flow
        if(this.stage.cacheTables && this.stage.cacheTablesID){
            if (this.cachedVertices && this.stage.cacheTablesID === this.cacheTablesID) {
                return this.cachedVertices;
            }
            const panels = this.getChildren();
            const coordinatePoints = [];
            for (let i = 0, l = panels.length; i < l; i += 1) {
                const panelVertices = panels[i].get2DVertices();
                for (let j = 0, len = panelVertices.length; j < len; j += 1) {
                    coordinatePoints
                        .push(new JSTS.geom.Coordinate(panelVertices[j][0], panelVertices[j][1]));
                }
            }
            const convexHullCoordinates =
                new JSTS.geom.GeometryFactory().createMultiPointFromCoords(coordinatePoints)
                .convexHull().getCoordinates();
            this.cachedVertices = utils.removeCollinearPoints(convexHullCoordinates
                    .map(coordinate => [coordinate.x, coordinate.y]).slice(0, -1));
            this.cacheTablesID = this.stage.cacheTablesID;
            return this.cachedVertices;
        }

        // Default flow
        const panels = this.getChildren();
        const coordinatePoints = [];
        for (let i = 0, l = panels.length; i < l; i += 1) {
            const panelVertices = panels[i].get2DVertices();
            for (let j = 0, len = panelVertices.length; j < len; j += 1) {
                coordinatePoints
                    .push(new JSTS.geom.Coordinate(panelVertices[j][0], panelVertices[j][1]));
            }
        }
        const convexHullCoordinates =
            new JSTS.geom.GeometryFactory().createMultiPointFromCoords(coordinatePoints)
            .convexHull().getCoordinates();
        return utils.removeCollinearPoints(convexHullCoordinates
                .map(coordinate => [coordinate.x, coordinate.y]).slice(0, -1));
    }

    // The parameter subarray mainly refers to Power models
    getVerticesFrom3DBoundingBox(subarray) {
        let vertices;
        for (const row of subarray.getChildren()) {
            if (row.get3DBoundingBoxesExcludingHiddenTables().length > 0) {
                vertices = utils.convertVectorArrayTo2DArray([...row.get3DBoundingBoxesExcludingHiddenTables()][0]);
            }
        }
        return vertices;
    }

    /**
     * Gives the nearest panel to the given point
     * @param {Vector3} point 
     */
    getNearestPanelToPoint(point) {
        const shiftedPoint = point.sub(this.getPosition());
        const panels = this.getChildren();
        let nearestDistance = Infinity;
        let nearestPanel = null;
        for (let i = 0, l = panels.length; i < l; i += 1) {
            const position = panels[i].getReleativePositionToTable();
            const currDistance = position.distanceToSquared(shiftedPoint)
            if (currDistance < nearestDistance) {
                nearestDistance = currDistance;
                nearestPanel = panels[i];
            }
        }
        if (nearestPanel === null) {
            console.error('how can the table exist if no panel???');
        }
        return nearestPanel;
    }

    getEdges() {
        let edges = [];
        for (let panel of this.getChildren()) {
            edges.push(...panel.getEdges());
        }
        return edges;
    }

    getPosition(refresh = false) {
        return this.objectsGroup.position.clone();
    }

    getId() {
        return this.id;
    }

    // Solar Access

    updateSolarAccess(solarAccessMap) {
        for (let child of this.getChildren()) {
            child.updateSolarAccess(solarAccessMap);
        }
    }

    getMaxSolarAccess() {
        return this._getAverageSolarAccessIncludingHidden();
    }

    optimiseOnSolarAccess(solarAccessThreshold) {
        if (this._getAverageSolarAccessIncludingHidden() < solarAccessThreshold && !this.hidden) {
            this.hideTable();
        } else if (this._getAverageSolarAccessIncludingHidden() >= solarAccessThreshold && this.hidden) {
            this.showTable();
        }
    }

    getDcStringsofPanels() {
        let dcStrings = [];
        for (let panel of this.getChildren()) {
            if (panel.electricalComponentConnected) {
                dcStrings.push(panel.electricalComponentConnected);
            }
        }
        return dcStrings;
    }

    // Universal Functions

    removeObject({ shouldSaveState, deleteEmptyParent } = { shouldSaveState: true, deleteEmptyParent: true },
        newFlow = true, { objectSelected } = { objectSelected: false }, {customOptions} = {customOptions: {}},
    ) {
        if (this.getSubarray()?.objectType === POWER_PATIO) {
            if (this.getSubarray().beingRotated) {
                return;
            }
            else {
                this.getSubarray().disposeSetbackGeometry();
            }
        }

        // JUGAAD: panels were shaking because the subarrays had different azimuth
        // TODO: FIX for east west copypaste
        if (this.getSubarray() && this.getSubarray().rackSubarray) {
            if (this.getSubarray().objectType === 'EastWestRack' && this.getSubarray().azimuth === this.getSubarray().rackSubarray.azimuth) {
                let newAzimuth = this.getSubarray().rackSubarray.azimuth + 180;
                if (newAzimuth >= 360) newAzimuth -= 360;
                this.getSubarray().azimuth = newAzimuth;
                this.getSubarray().updateGeometry();
            }
        }
        if(!this.parent) return;
        if (this.gazeboDirectionArrow && this.gazeboDirectionArrow.visible) {
            this.gazeboDirectionArrow.visible = false;
        }
        const parent = this.getSubarray();
        this.stage.sceneManager.scene.remove(this.objectsGroup);
        if (this.getSubarray()) {
            this.getSubarray().structureUpdateRequired = true;
            if (this.getSubarray().rotationPoints) this.getSubarray().rotationPoints.removeObject();
        }
        const i = 0;
        while (this.getChildren().length > i) {
            this.getChildren()[i].removeObject();
        }
        const row = this.getParent();
        row.removeChild(this, newFlow);

        // update the rails and attachment when the selected table is deleted.
        // this will not work when u delete subarray.
        if (objectSelected) {
            if ((!this.stage.multiSelectSubarray) || this.stage.multiSelectSubarray.length === 0)
            parent.updateRail();
        }

        this.removeDimensions();

        if (shouldSaveState) {
            this.stage.stateManager.add({
                uuid: this.uuid,
                getStateCb: () => DELETED_STATE,
            });
        }

        if (deleteEmptyParent) row.removeIfEmpty(customOptions);

        // NOTE: deSelect should be after save since it will disable drag controls and
        // stop Undo/Redo container
        if (objectSelected) {
            this.stage.selectionControls.removeSelectedObject(this);
            this.stage.dragControls.removeIfExists(this);
        }

        if(this.linked){
            this.linkedTable.linked = false;
            this.linkedTable.removeObject({ shouldSaveState, deleteEmptyParent }, newFlow, { objectSelected })
        }
    }

    onSelect({ updateSubarray } = { updateSubarray: true }, rackSelect = false) {
        this.isSelected = true;
        this.showIndividualMesh();

        // juggad fix : need to disable the drag controls while in addpatio mode.
        if (this.stage.addAttachmentMode.enabled) return;

        this.switchVisualState(VISUAL_STATES.SELECT, true);

        if (updateSubarray) {
            this.getSubarray().mergeGeometriesForAllPanels({ excludeTables: [this] });
        }

        if (this.getSubarray().objectType && this.getSubarray() instanceof Gazebo) {
            this.createDirectionArrowForGazebo();
        }
        this.addObjectsToDragControls();
        
        if (this.getSubarray().objectType === POWER_PATIO) {
            this.stage.addAttachmentMode.getCornersForPatioMovement(this.getSubarray());
            this.addObjectToAttachmentDragControl();
            const currentTableCenter = this.getPosition();
            if (this.stage.patioBoundingCorners.length > 0) {
                this.stage.patioBoundingCorners.forEach((cornerArray) => {
                    if (utils.checkPointInsideVertices(cornerArray, [currentTableCenter.x, currentTableCenter.y])) {
                        this.reqCorners = cornerArray;
                    }
                });
            }
            this.updateGeometry();
            this.showPatioSetback();

            const { isPropertyLineAdded, propertyLines } = utils.isPropertyLinePresent(this.stage);
            if (isPropertyLineAdded) {
                propertyLines.forEach((line) => {
                    line.showPropertySetback(true);
                });
            }
            if (this.getSubarray()?.attachment && this.getSubarray().attachment.attachedTo) {
                this.getSubarray().initPropertyIntersectParams();
                this.getSubarray().initGroundModelIntersectParams();
                this.check_2FeetSetbackDistance();
            }
        }
        if (this.linked && !rackSelect) {
            this.linkedTable.onSelect({ updateSubarray }, true);
        }
    }

    showPatioSetback() {
        this.getSubarray().setbackOutsideMesh.visible = true;
    }

    hidePatioSetback() {
        this.getSubarray().setbackOutsideMesh.visible = false;
    }

    createDirectionArrowForGazebo(azimuthAngle = this.getSubarray().azimuth) {
        const spriteTexture = new THREE.TextureLoader().load(img);
        this.gazeboDirectionArrow = new THREE.Sprite(new THREE.SpriteMaterial({
            map: spriteTexture,
            rotation: (-Math.PI / 2) - utils.deg2Rad(azimuthAngle),
        }));
        this.gazeboDirectionArrow.scale.set(2, 1, 3);
        this.gazeboDirectionArrow.center.set(0.5, 0.5);
        this.gazeboDirectionArrow.position.set(0, 0, 5);
        this.gazeboDirectionArrow.frustumCulled = false;
        this.objectsGroup.add(this.gazeboDirectionArrow);
    }

    deSelect({ updateSubarray } = { updateSubarray: true }, rackSelect = false) {
        if (this.stage.attachedElementDragControls.patioDrag) this.stage.attachedElementDragControls.patioDrag = false;
        if (this.getSubarray()?.objectType === POWER_PATIO) {
            this.stage.attachedElementDragControls.disable();
            this.stage.dragControls.enable();
            this.hidePatioSetback();
            const {isPropertyLineAdded, propertyLines} = utils.isPropertyLinePresent(this.stage);
            if (isPropertyLineAdded) {
                propertyLines.forEach((line) => {
                    line.hidePropertySetback();
                });
            }
        }
        this.isSelected = false;
        this.hideIndividualMesh();
        if (this.gazeboDirectionArrow?.visible) this.gazeboDirectionArrow.visible = false;
        if (this.getParent() !== null && this.getSubarray() !== null && this.getSubarray() !== undefined && this.getSubarray().rotationPoints) {
            this.getSubarray().rotationPoints.hideObject();
        }
        if (updateSubarray) {
            this.getSubarray().mergeGeometriesForAllPanels({ excludeTables: [this] });
        }
        this.switchVisualState(VISUAL_STATES.DEFAULT, true);
        if(this.linked && !rackSelect){
            this.linkedTable.deSelect({updateSubarray}, true);
        }
    }

    handleObjectsGroupAddition(objectsGroup) {
        if (this.useIndividualMesh) {
            this.objectsGroup.add(objectsGroup);
        }
    }

    handleObjectsGroupDeletion(objectsGroup) {
        this.objectsGroup.remove(objectsGroup);
    }

    removePanelsMeshFromScene() {
        for (let i = 0, l = this.getChildren().length; i < l; i += 1) {
            this.getChildren()[i].removeFromScene();
        }
    }

    addPanelMeshToScene() {
        for (let i = 0, l = this.getChildren().length; i < l; i += 1) {
            this.getChildren()[i].addToScene();
        }
    }

    addObjectsToDragControls() {
        // Check if object is already added to drag controls
        if (this.stage.dragControls.objects.indexOf(this) !== -1) {
            // remove from drag and add again
            this.stage.dragControls.removeIfExists(this);
        }
        // add to drag
        this.stage.dragControls.add(
            this,
            this.handleDragMove.bind(this),
            this.handleDragEnd.bind(this),
            this.handleDragStart.bind(this),
            this.handleDragCancel.bind(this),
        );
        if (this.getSubarray().objectType === 'Gazebo') {
            this.getSubarray().rotationPoints = this.getSubarray().createRotation();
        }
        if (this.getSubarray() !== null && this.getSubarray() !== undefined && this.getSubarray().rotationPoints) {
            this.getSubarray().rotationPoints.showObject();
            this.stage.dragControls.add(
                this.getSubarray().rotationPoints,
                this.getSubarray().rotationPoints.moveObject.bind(this.getSubarray().rotationPoints),
                this.getSubarray().rotationPoints.placeObject.bind(this.getSubarray().rotationPoints),
                this.getSubarray().rotationPoints.handleDragStart.bind(this.getSubarray().rotationPoints),
                this.getSubarray().rotationPoints.handleDragCancel.bind(this.getSubarray().rotationPoints),
            );
        }
    }

    addObjectToAttachmentDragControl() {
        this.stage.dragControls.disable();
        if (!this.stage.attachedElementDragControls.dragEnabled) this.stage.attachedElementDragControls.enable();
        this.stage.attachedElementDragControls.add(
            this,
            this.handleDragMove.bind(this),
            this.handleDragEnd.bind(this),
            this.handleDragStart.bind(this),
            this.handleDragCancel.bind(this),
        );
    }

    showIndividualMesh() {
        this.useIndividualMesh = true;
        const children = this.getChildren();
        for (let i = 0; i < children.length; i += 1) {
            children[i].addToScene();
        }
    }

    hideIndividualMesh() {
        this.useIndividualMesh = false;
        const children = this.getChildren();
        for (let i = 0; i < children.length; i++) {
            children[i].removeFromScene();
        }
    }

    handleDragCancel() {
        const children = this.getChildren();
        for (let i = 0, len = children.length; i < len; i += 1) {
            children[i].handleDragCancel();
        }
        this.switchVisualState(VISUAL_STATES.DEFAULT, true);
    }

    static getObjectType() {
        return 'Power Table';
    }
}
