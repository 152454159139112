import axios from "axios";
export default {
    FETCH_DASHBOARD_INFO(org_id) {
        if(org_id)
        return axios.get(`api/organisations/${org_id }/dashboard/`);
    },
    FETCH_ALL_SERVICES_INFO(){
        return axios.get(`api/service-templates/`);
    },
    CREATE_PROJECT(rewObj_id,postData) {
        if(rewObj_id)
        return axios.post(`api/request-expert-services/${rewObj_id}/create_project/`,postData);
    },
    UPDATE_EXPERT_SERVICE(expertServiceId, patchData) {
        if (expertServiceId) {
            return axios.patch(`api/request-expert-services/${expertServiceId}/`, patchData);
        }
    },
    FETCH_EXPERT_SERVICE(expertServiceId) {
        if (expertServiceId) {
            return axios.get(`api/request-expert-services/${expertServiceId}/`);
        }
    },
    ADITIONAL_ADD_NOTE_POPUP_ADDED(rewObj_id,postData) {
        if(rewObj_id)
        return axios.post(`api/request-expert-services/${rewObj_id}/create_and_update_design/`,postData);
    },
};