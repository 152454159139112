<template>
    <div>
        <!-- 
            Speech Bubble Credits:
            https://freefrontend.com/css-speech-bubbles/
            https://codepen.io/MarkBoots/pen/RwLPXgJ
         -->
        <div speech-bubble pbottom aright class="bubble" @click="hideAlertBubble()" ref="alertBubble">
            <div class="title">
                You can drag the chat launcher around the screen.
            </div>
        </div>
        <div
            class="intercom-icon-custom smooth"
            id="intercom-icon-custom"
            ref="intercom-icon-custom"
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 32">
                <path d="M28 32s-4.714-1.855-8.527-3.34H3.437C1.54 28.66 0 27.026 0 25.013V3.644C0 1.633 1.54 0 3.437 0h21.125c1.898 0 3.437 1.632 3.437 3.645v18.404H28V32zm-4.139-11.982a.88.88 0 00-1.292-.105c-.03.026-3.015 2.681-8.57 2.681-5.486 0-8.517-2.636-8.571-2.684a.88.88 0 00-1.29.107 1.01 1.01 0 00-.219.708.992.992 0 00.318.664c.142.128 3.537 3.15 9.762 3.15 6.226 0 9.621-3.022 9.763-3.15a.992.992 0 00.317-.664 1.01 1.01 0 00-.218-.707z"></path>
            </svg>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            mouseDownCos: {
                top: 0,
                left: 0,
            },
            offsetCos: {
                top: 0,
                left: 0,
            },
            isDragging: false,
        }
    },
    mounted() {
        let iconRef = this.$refs['intercom-icon-custom']
        iconRef.addEventListener('mousedown', (e) => {
            this.mouseDownCos.top = e.clientY
            this.mouseDownCos.left = e.clientX
            this.offsetCos.top = e.offsetY
            this.offsetCos.left = e.offsetX
            iconRef.classList.remove('smooth')
            this.isDragging = true
        })
        iconRef.addEventListener('touchstart', (e) => {
            e.preventDefault()

            let cos = e.touches[0]
            this.mouseDownCos.top = cos.clientY
            this.mouseDownCos.left = cos.clientX
            this.offsetCos.top = 24
            this.offsetCos.left = 24
            iconRef.classList.remove('smooth')
            this.isDragging = true
        })
        
        document.addEventListener('mousemove', (e) => {
            if (!this.isDragging) { return }

            let leftDiff = e.clientX - this.mouseDownCos.left
            let topDiff = e.clientY - this.mouseDownCos.top
            let iconRef = this.$refs['intercom-icon-custom']
            let newXCo = this.mouseDownCos.left + leftDiff - this.offsetCos.left
            let newYCo = this.mouseDownCos.top + topDiff - this.offsetCos.top
            iconRef.style.left = newXCo + 'px'
            iconRef.style.top = newYCo + 'px'

            this.hideAlertBubble()
        })
        document.addEventListener('touchmove', (e) => {
            if (!this.isDragging) { return }
            let cos = e.touches[0]

            let leftDiff = cos.clientX - this.mouseDownCos.left
            let topDiff = cos.clientY - this.mouseDownCos.top
            let iconRef = this.$refs['intercom-icon-custom']
            let newXCo = this.mouseDownCos.left + leftDiff - this.offsetCos.left
            let newYCo = this.mouseDownCos.top + topDiff - this.offsetCos.top
            iconRef.style.left = newXCo + 'px'
            iconRef.style.top = newYCo + 'px'

            this.hideAlertBubble()
        })

        document.addEventListener('mouseup', (e) => {
            this.isDragging = false

            let iconRef = this.$refs['intercom-icon-custom']
            iconRef.classList.add('smooth')

            let hasNotMovedMuch = Math.abs(this.mouseDownCos.top - e.clientY) < 10
                                    && Math.abs(this.mouseDownCos.left - e.clientX) < 10
            if (hasNotMovedMuch) {
                window.Intercom('show');
                return
            }
            
            this.checkForBounds()
        })
        document.addEventListener('touchend', (e) => {
            let cos = e.changedTouches[0]
            this.isDragging = false

            let iconRef = this.$refs['intercom-icon-custom']
            iconRef.classList.add('smooth')

            let hasNotMovedMuch = Math.abs(this.mouseDownCos.top - cos.clientY) < 10
                                    && Math.abs(this.mouseDownCos.left - cos.clientX) < 10
            if (hasNotMovedMuch) {
                window.Intercom('show');
                return
            }
            
            this.checkForBounds()
        })

        // Handling for when window resizes
        window.addEventListener('resize', () => {
            let iconRef = this.$refs['intercom-icon-custom']
            iconRef.classList.remove('smooth')
            let maxPossibleLeftCo = window.innerWidth - 48 - 20
            let curLeftCo = parseInt(iconRef.style.left)
            if (curLeftCo > maxPossibleLeftCo) {
                iconRef.style.left = maxPossibleLeftCo + 'px'
            }

            this.checkForBounds()
            iconRef.classList.add('smooth')
        })
    },
    
    methods: {
        checkForBounds() {
            let iconRef = this.$refs['intercom-icon-custom']
            let curLeftCo = parseInt(iconRef.style.left)
            let xPer = curLeftCo/window.innerWidth
            if (xPer < 0.5) {
                iconRef.style.left = 20 + 'px'
            } else {
                iconRef.style.left = (window.innerWidth - 48 - 20) + 'px'
            }

            let maxPossibleTopCo = window.innerHeight - 48 - 20
            let curTopCo = parseInt(iconRef.style.top)
            if (curTopCo > maxPossibleTopCo) {
                iconRef.style.top = maxPossibleTopCo + 'px'
            }

            let minPossibleTopCo = 20
            curTopCo = parseInt(iconRef.style.top)
            if (curTopCo < minPossibleTopCo) {
                iconRef.style.top = minPossibleTopCo + 'px'
            }
        },
        hideAlertBubble() {
            let alertBubble = this.$refs.alertBubble
            alertBubble.classList.add("hide")
        }
    }
}

</script>

<style scoped>
.intercom-icon-custom {
    cursor: pointer;
    position: fixed;
    z-index: 1000;
    bottom: 20px;
    right: 20px;
    width: 48px;
    height: 48px;
    background-color: black;
    border-radius: 50%;
    pointer-events: initial;
    display: flex;
    align-items: center;
    justify-content: center;
}

.intercom-icon-custom.smooth {
    transition: 0.3s;
}

.intercom-icon-custom:hover {
    transform: scale(1.1);
}

.intercom-icon-custom:active {
    transform: scale(0.99);
}

svg {
    pointer-events: none;
    width: 24px;
    height: 24px;
}

svg path {
    fill: rgb(255, 255, 255);
}

[speech-bubble], [speech-bubble] * { box-sizing: border-box }

[speech-bubble]{
  --bbColor: grey;
  --bbArrowSize: 1.5rem;
  --bbBorderRadius: 0.25rem;
  --bbPadding: 1rem;
  background: var(--bbColor);
  border-radius: var(--bbBorderRadius);
  padding: var(--bbPadding);
  position: relative;
}

[speech-bubble]::before{
  content: ''; 
  position: absolute;
  background: var(--bbColor);
}

[speech-bubble][pbottom]{ margin-bottom: var(--bbArrowSize) }
[speech-bubble][ptop]   { margin-top: var(--bbArrowSize); }
[speech-bubble][pleft]  { margin-left: var(--bbArrowSize); }
[speech-bubble][pright] { margin-right: var(--bbArrowSize); }


/* bottom and top  */
[speech-bubble][pbottom]::before,
[speech-bubble][ptop]::before{
  --width: calc(var(--bbArrowSize) / 2 * 3);
  height: var(--bbArrowSize);
  width: var(--width);
}

/* bottom */
[speech-bubble][pbottom]::before{
  top: calc(100% - 2px);
}
[speech-bubble][pbottom][aleft]::before{
  left: 1rem;
  clip-path: polygon(25% 0, 100% 0, 0% 100%)
}
[speech-bubble][pbottom][acenter]::before{
  left: calc(50% - var(--width) / 2);
  clip-path: polygon(12.5% 0, 87.5% 0, 50% 100%)
}
[speech-bubble][pbottom][aright]::before{
  right: 1rem;
  clip-path: polygon(0 0, 75% 0, 100% 100%)
}

/* top */
[speech-bubble][ptop]::before{
  bottom: calc(100% - 2px);
}
[speech-bubble][ptop][aleft]::before{
  left: var(--bbPadding);
  clip-path: polygon(0 0, 100% 100%, 25% 100%)
}
[speech-bubble][ptop][acenter]::before{
  left: calc(50% - var(--width) / 2);
  clip-path: polygon(12.5% 100%, 50% 0, 87.5% 100%)
}
[speech-bubble][ptop][aright]::before{
  right: var(--bbPadding);
  clip-path: polygon(0 100%, 100% 0, 75% 100%)
}

/* left and right  */
[speech-bubble][pleft]::before,
[speech-bubble][pright]::before{
  --height: calc(var(--bbArrowSize) / 2 * 3);
  width: var(--bbArrowSize);
  height: var(--height);
}

/* right */
[speech-bubble][pright]::before{
  left: calc(100% - 2px);
}
[speech-bubble][pright][atop]::before{
  top: var(--bbPadding);
  clip-path: polygon(100% 0, 0 100%, 0 25%)
}
[speech-bubble][pright][acenter]::before{
  top: calc(50% - var(--height) / 2);
  clip-path: polygon(0 12.5%, 100% 50%, 0 87.5%)
}
[speech-bubble][pright][abottom]::before{
  bottom: var(--bbPadding);
  clip-path: polygon(0 0, 100% 100%, 0 75%)
}

/* left */
[speech-bubble][pleft]::before{
  right: calc(100% - 2px);
}
[speech-bubble][pleft][atop]::before{
  top: var(--bbPadding);
  clip-path: polygon(0 0, 100% 25%, 100% 100%)
}
[speech-bubble][pleft][acenter]::before{
  top: calc(50% - var(--height) / 2);
  clip-path: polygon(0 50%, 100% 12.5%, 100% 87.5%);
}
[speech-bubble][pleft][abottom]::before{
  bottom: var(--bbPadding);
  clip-path: polygon(0 100%, 100% 0, 100% 75%)
}

/* flip */
[speech-bubble][pbottom][flip]::before,
[speech-bubble][ptop][flip]::before{
  transform: scaleX(-1)
}
[speech-bubble][pleft][flip]::before,
[speech-bubble][pright][flip]::before{
  transform: scaleY(-1)
}





/* for demo */
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  cursor: default
}
body{
  max-width: 60rem;
  margin-inline: auto;
  font-family: sans-serif;
  display:grid;
  grid-template-areas:
    'br bc bl'
    'rb cc lb'
    'rc cc lc'
    'rt cc lt'
    'tr tc tl';
  padding: 2rem;
  gap: 2rem;
  background: #141518;
}

.middle{
  grid-area: cc;
  align-self: center;
  justify-self: center;
  text-align:center;
  color: white;
}

[speech-bubble][pbottom][aleft]   {grid-area: bl}
[speech-bubble][pbottom][acenter] {grid-area: bc}
[speech-bubble][pbottom][aright]  {grid-area: br}

[speech-bubble][pright][atop]     {grid-area: rt}
[speech-bubble][pright][acenter]  {grid-area: rc}
[speech-bubble][pright][abottom]  {grid-area: rb}

[speech-bubble][pleft][atop]      {grid-area: lt}
[speech-bubble][pleft][acenter]   {grid-area: lc}
[speech-bubble][pleft][abottom]   {grid-area: lb}

[speech-bubble][ptop][aleft]      {grid-area: tl}
[speech-bubble][ptop][acenter]    {grid-area: tc}
[speech-bubble][ptop][aright]     {grid-area: tr}

[speech-bubble][pbottom],
[speech-bubble][ptop]{margin: 0}

[speech-bubble]{ 
  filter: drop-shadow(0px 0px 0.2rem rgba(200,200,200,0.8));
  transition: transform 0.25s ease
}

[speech-bubble] .title{
  color: black; 
}
[speech-bubble] code {
  background: white;
  margin: 0.125rem;
  box-shadow: 0px 0px 5px rgba(0,0,0,.5);
  white-space: nowrap;
  font-size: .9rem
}

.middle code{
  font-size: 1rem;
}

.bubble {
    width: 200px;
    position: fixed;
    bottom: 100px;
    right: 32px;
    transition: 0.5s;
    --bbColor: white; 
    opacity: 1;
    z-index: 1000;
}

.bubble.hide {
    pointer-events: none;
    opacity: 0;
}

</style>