<template>
    <div>
        <div class="container">
            <div v-for="(type, index) in accordions" :key="index">
                <button @click="toggleAccordion(index)"
                    :class="['accordion', { active: activeAccordions.includes(index) }]">{{
                        type
                    }}<b-icon icon="chevron-right" aria-hidden="true"
                        :class="['dropdownIcon', { dropdownActive: activeAccordions.includes(index) }]"></b-icon>
                </button>
                <div v-show="activeAccordions.includes(index)" class="panel">
                    <draggable group="people" :key="componentKey" @start="onDragStart" @end="e => onDragEnd(type, e)"
                        handle=".dragIcon">
                        <div class="flexParentCont" v-for="(step, index) in finalData[type]" :key="index" :id="index">
                            <div draggable="true" class="dragIcon"><img src="../../../assets/img/shuffledotsIcon.svg" alt=""></div>
                            <div class="listContainer">
                                <div class="flexContainer">
                                    <div class="flexBtw">
                                        <p class="font16Normal">{{ step.title }}</p>
                                        <div class="flexIcon">
                                            <el-tooltip class="item" effect="dark" content="Edit" placement="top-start">
                                                <b-icon icon="pencil" :id="index" class="editIcon" aria-hidden="true"
                                                    @click="addAndEditStep('edit', type, step)"></b-icon>
                                            </el-tooltip>
                                            <el-tooltip class="item" effect="dark" content="Delete"
                                                placement="top-start">
                                                <b-icon icon="trash" :id="index" class="delIcon" aria-hidden="true"
                                                    @click="handleDeleteClick(step)"></b-icon>
                                            </el-tooltip>
                                            <el-tooltip class="item" effect="dark" :disabled="!isSwitchDisabled(step)"
                                                content="Percentage limit has reached." placement="top-start"
                                                >
                                                <el-switch v-model="step.show_in_contract"
                                                    :disabled="isSwitchDisabled(step)"
                                                    @change="handleSwitchChange(step)"></el-switch>

                                            </el-tooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </draggable>
                    <p class="addNewStep" @click="addAndEditStep('add', type)">
                        <b-icon icon="plus" aria-hidden="true" class="plusIcon"></b-icon>Add New Step
                    </p>
                </div>
                <DeleteDialog v-if="isDeleteDialogVisible" :isDeleteProjectDocumentPopupOpen="isDeleteDialogVisible"
                    :message="`Are you sure you want to delete this step?`"
                    @cancelDelete="isDeleteDialogVisible = false" @confirmDelete="handleDeleteStep" />
            </div>
        </div>
        <AddNewStepPopup v-if="isAddNewStepPopupVisible" :isAddNewStepPopupVisible.sync="isAddNewStepPopupVisible"
            :mode="mode" :projectType="projectType" @fetch-all-payment-schedule="fetchAllPaymentSchedule"
            :editSelectedStep="editSelectedStep" :finalData="finalData"
            :sumOfPercentageValuesForResidential="sumOfPercentageValuesForResidential"
            :sumOfPercentageValuesForCommercial="sumOfPercentageValuesForCommercial"
            @updatedScheduleAndSwitches="updatedScheduleLocally" />
    </div>
</template>

<script>

import API from "@/services/api/";
import draggable from "vuedraggable";
import AddNewStepPopup from "./addNewStepPopup.vue";
import DeleteDialog from "../../project/components/projectDocuments/deleteProjectDocument.vue";


export default {
    components: {
        draggable,
        AddNewStepPopup,
        DeleteDialog,
    },

    data() {
        return {
            isAddNewStepPopupVisible: false,
            isDeleteDialogVisible: false,
            isAdmin: JSON.parse(localStorage.getItem("user")).role === "ADMIN",
            accordions: ["Residential", "Commercial"],
            activeAccordions: [],
            mode: "",
            isLoading: false,
            projectType: null,
            deleteStep: null,
            finalData: {},
            editSelectedStep: {},
            componentKey: 0,
            sumOfPercentageValuesForResidential: null,
            sumOfPercentageValuesForCommercial: null,
        };
    },

    async mounted() {
        this.fetchAllPaymentSchedule();
        this.toggleAccordion(0);
    },

    methods: {

        async fetchAllPaymentSchedule() {
            try {
                this.isLoading = true;
                let response;

                response = await API.PAYMENT_SCHEDULE.FETCH_ALL_PAYMENT_SCHEDULE();
                this.$set(this.finalData, 'Residential', response.data.filter(item => item.project_type === "residential"))
                this.$set(this.finalData, 'Commercial', response.data.filter(item => item.project_type === "commercial"))
                this.updateSumOfPercentageValues();
                this.updateSwitchDisabledState();
                this.isLoading = false;
            } catch (e) {
                this.isLoading = false;
                console.error(e);
            }
        },

        async handleSwitchChange(step) {
            // Check if the switch change is allowed
            if (this.disableSwitches(step)) {
                let patchData = {
                    "show_in_contract": step.show_in_contract,
                };
                try {
                    await API.PAYMENT_SCHEDULE.UPDATE_ORG_PAYMENT_SCHEDULE(step.id, patchData);
                    this.$message({
                        showClose: true,
                        message: "Updated Successfully.",
                        type: "success",
                        center: true
                    });
                } catch (err) {
                    this.$message({
                        showClose: true,
                        message: "Unable to update.",
                        type: "error",
                        center: true
                    });
                }
            } else {
                // Revert the switch state and show error message
                step.show_in_contract = !step.show_in_contract;
                this.$message({
                    showClose: true,
                    message: "Toggle not allowed: total percentage exceeds 100%.",
                    type: "error",
                    center: true
                });
            }
            this.updateSumOfPercentageValues();
            this.updateSwitchDisabledState();
        },

        disableSwitches(step) {
            let projectType = step.project_type === 'residential' ? 'Residential' : 'Commercial';
            let sum = projectType === 'Residential' ? this.sumOfPercentageValuesForResidential : this.sumOfPercentageValuesForCommercial;

            if (sum > 100) {
                return false;
            }
            return true;
        },

        updatedScheduleLocally(step) {
            let projectType = step.project_type == 'residential' ? 'Residential' : 'Commercial';
            this.finalData[projectType] = this.finalData[projectType].map(e => {
                if (e.id == step.id) return { ...e, ...step };
                return e;
            });

            this.disableSwitches(step);
            this.updateSumOfPercentageValues();
            this.updateSwitchDisabledState();
        },

        updateSwitchDisabledState() {
            ['Residential', 'Commercial'].forEach(projectType => {
                let sum = projectType === 'Residential' ? this.sumOfPercentageValuesForResidential : this.sumOfPercentageValuesForCommercial;

                this.finalData[projectType].forEach(item => {
                    if (!item.show_in_contract) {
                        this.$set(item, 'isDisabled', sum >= 100);
                    } else {
                        this.$set(item, 'isDisabled', false);
                    }
                });
            });
        },

        async handleDeleteStep() {
            try {
                await API.PAYMENT_SCHEDULE.DELETE_ORG_PAYMENT_SCHEDULE(this.deleteStep.id);
                await this.fetchAllPaymentSchedule()
                this.$message({
                    showClose: true,
                    message: "Deleted Successfully",
                    type: "success",
                    center: true
                });
                this.isDeleteDialogVisible = false;
            } catch (err) {
                this.$message({
                    showClose: true,
                    message: "Unable to delete",
                    type: "error",
                    center: true
                });
            }
        },

        handleSequenceUpdate(type, oldIndex, newIndex) {
            let itemId = this.finalData[type][oldIndex]?.id

            try {
                let res = API.PAYMENT_SCHEDULE.UPDATE_ORG_PAYMENT_SCHEDULE_SEQUENCE(itemId, { sequence: newIndex + 1 });
                let draggedStep = this.finalData[type].splice(oldIndex, 1);
                draggedStep[0].sequence = newIndex + 1;
                this.$set(this.finalData, type, JSON.parse(JSON.stringify(this.finalData[type])));
                this.finalData[type].splice(draggedStep[0].sequence - 1, 0, draggedStep[0]);
                this.$set(this.finalData, type, JSON.parse(JSON.stringify(this.finalData[type])));
                this.reAdjustSequence(type)
            } catch (err) {
                this.$message({
                    showClose: true,
                    message: "Unable to pefrom this action.",
                    type: "error",
                    center: true
                });
            }
            this.isLoading = false
        },
        reAdjustSequence(type) {
            let count = 1;
            for (let i = 0; i < this.finalData[type].length; i++) {
                this.finalData[type][i].sequence = count;
                this.$set(this.finalData[type], i, this.finalData[type][i])
                count++;
            }
            this.componentKey++;

            // this.$set(this.finalData, type, JSON.parse(JSON.stringify(this.finalData[type])));
        },

        toggleAccordion(index) {
            const accordionIndex = this.activeAccordions.indexOf(index);
            if (accordionIndex > -1) {
                // If the accordion is already open, close it
                this.activeAccordions.splice(accordionIndex, 1);
            } else {
                // Otherwise, open it
                this.activeAccordions.push(index);
            }
        },

        addAndEditStep(type, projectType, step) {
            if (type == 'edit') {
                this.mode = "edit";
                this.editSelectedStep = step;
            } else {
                this.mode = "add";
            }
            this.projectType = projectType.toLowerCase();
            this.isAddNewStepPopupVisible = true;
        },

        onDragStart() {
            // Set loading state to true when dragging starts
        },

        onDragEnd(type, event) {
            this.isLoading = true;
            this.handleSequenceUpdate(type, event.oldIndex, event.newIndex);
        },

        handleDeleteClick(step) {
            this.isDeleteDialogVisible = true
            this.deleteStep = step
        },

        updateSumOfPercentageValues() {
            this.sumOfPercentageValuesForResidential = this.finalData['Residential']
                .filter(item => item.type === 'percentage' && item.show_in_contract)
                .reduce((sum, item) => sum + Number(item.value), 0);

            this.sumOfPercentageValuesForCommercial = this.finalData['Commercial']
                .filter(item => item.type === 'percentage' && item.show_in_contract)
                .reduce((sum, item) => sum + Number(item.value), 0);
        },

        isSwitchDisabled(step) {
            if(step.type == 'absolute') {
                return false;
            }
            let sum = step.project_type === 'residential' ? this.sumOfPercentageValuesForResidential : this.sumOfPercentageValuesForCommercial;
            // return !step.show_in_contract && sum + step.value > 100;
            if(!step.show_in_contract) {
                if((sum + Number(step.value)) > 100) {
                    return true;
                } else {
                    return false;
                }
            }
        }

    },

    computed: {

    },

};
</script>

<style scoped>
p {
    word-break: break-word;
}

.container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 32px 0px 24px 0px;
}

.accordion {
    background-color: #fff;
    color: #222;
    cursor: pointer;
    padding: 16px;
    width: 100%;
    outline: none;
    font-size: 16px;
    font-weight: bold;
    border-radius: 8px;
    border: 1px solid var(--Color-Shades-CCC, #CCC);
    display: flex;
    justify-content: space-between;
    align-deleteStep: center;

}

.active {
    border-radius: 8px 8px 0px 0px;
    border-bottom: none;
}

.dropdownIcon {
    font-size: 24px;
    color: #777;
}

.dropdownActive {
    transform: rotate(90deg);
}

.panel {
    display: flex;
    flex-direction: column;
    border-radius: 0px 0px 8px 8px;
    border: 1px solid var(--Color-Shades-CCC, #CCC);
    border-top: none;
    background-color: white;
    overflow: hidden;
    margin-top: -16px;
    transition: max-height 0.5s ease-out;
}

.parentContainer>>>.el-checkbox__inner {
    width: 22px;
    height: 22px;
    border: 1px solid #999;
}


.parentContainer>>>.el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #1C3366;
    border: 1px solid #1C3366;
}

.parentContainer>>>.el-checkbox__inner::after {
    border: 2px solid #FFFFFF;
    border-left: 0;
    border-top: 0;
    height: 9px;
    left: 7px;
    top: 3px;
    width: 3px;
}

.listContainer {
    width: 100%;
    background: #ffffff;
}

.flexContainer {
    display: flex;
}

.font16Normal {
    line-height: 1.5;
    word-break: break-word;
    white-space: pre-wrap;
    font-size: 16px;
    color: #222;
}

.flexIcon {
    display: flex;
    gap: 16px;
    align-deleteStep: center;
}

.editIcon,
.delIcon {
    font-size: 20px;
    color: #777777;
    cursor: pointer;

}

.flexIcon>>>.el-switch__core {
    height: 24px;
    border-radius: 14px;
}

.flexIcon>>>.el-switch__core:after {
    height: 20px;
    width: 20px;
}

.flexIcon>>>.el-switch.is-checked .el-switch__core::after {
    margin-left: -21px !important;
}

.addNewStep {
    text-decoration: underline;
    width: fit-content;
    display: flex;
    color: #1C3366;
    font-size: 16px;
    margin: 8px 14px 16px 14px;
    font-weight: bold;
    cursor: pointer;
}

.plusIcon {
    font-size: 24px;
}

.flexParentCont {
    display: flex;
    gap: 16px;
    align-deleteStep: center;
    padding: 16px;
    border-bottom: 1px solid #ccc;
}

.flexParentCont:last-child {
    border-bottom: none;
}

.dragIcon {
    cursor: pointer;
}

.flexBtw {
    display: flex;
    justify-content: space-between;
    align-deleteStep: center;
    gap: 16px;
    width: 100%;
}
</style>