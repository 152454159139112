
<div class="select-container">
  <p class="title">
    {{ getTitle }} <span v-if="items.isStar" class="astrisk"> *</span>
  </p>
 
  <el-tooltip class="item" effect="dark" :content="placeholderText" placement="top-start" v-if="isDisabled">
    <el-select v-model="selectedItem" :placeholder="items.placeholder" @change="handleSelect" filterable ref="mySelect"
      :disabled="isDisabled">
      <template #prefix v-if="selectedIcon">
        <!-- Customize the prefix content here -->
        <span style="padding-top:10px"> <img :src="selectedIcon" alt="" /></span>
      </template>
      <el-option v-for="(item, indx) in items.options" :key="indx" :label="item?.label || item" :value="item || item">
        <div :class="items.options.length - 1 > indx ? 'option-lables' : ''">
          <p>
            <span v-if="item.icon" class="icons"><img :src="getIcons(item.icon)" alt="" /></span>
            <!-- <span v-if="item.isSubTitle"> {{ item?.isSubTitle }}</span> -->
            <span v-if="!item.isSubTitle"> {{ item?.label || item?.name||'' }}</span>
          </p>
        </div>
      </el-option>
    </el-select>
  </el-tooltip>
  <el-tooltip class="item" effect="dark" :content="selectedItem" placement="top-start" v-else-if="(getTitle=='Column'||getTitle=='Measure')&&selectedItem ">
  <el-select v-model="selectedItem" :placeholder="items.placeholder" @change="handleSelect" filterable
    ref="mySelect" :disabled="isDisabled">
    <template #prefix v-if="selectedIcon">
      <!-- Customize the prefix content here -->
      <span style="padding-top:10px"> <img :src="selectedIcon" alt="" /></span>
    </template>
    <el-option v-for="(item, indx) in items.options" :key="indx" :label="item?.label || item" :value="item || item">
      <div :class="items.options.length - 1 > indx ? 'option-lables' : ''">
        <p>
          <span v-if="item.icon" class="icons"><img :src="getIcons(item.icon)" alt="" /></span>
          <span v-if="!item.isSubTitle"> {{ item?.label || item?.name||'' }}</span>
          <span v-if="item?.additional_details?.units"> ({{ item?.additional_details?.units}}) </span>

        </p>
      </div>
    </el-option>
  </el-select>
</el-tooltip>
<el-select v-else v-model="selectedItem" :placeholder="items.placeholder" @change="handleSelect" filterable
    ref="mySelect" :disabled="isDisabled">
    <template #prefix v-if="selectedIcon">
      <!-- Customize the prefix content here -->
      <span style="padding-top:10px"> <img :src="selectedIcon" alt="" /></span>
    </template>
    <el-option v-for="(item, indx) in items.options" :key="indx" :label="item?.label || item" :value="item || item">
      <div :class="items.options.length - 1 > indx ? 'option-lables' : ''">
        <p>
          <span v-if="item.icon" class="icons"><img :src="getIcons(item.icon)" alt="" /></span>
          <span v-if="!item.isSubTitle"> {{ item?.label || item?.name||'' }}</span>
          <span v-if="item?.additional_details?.units"> ({{ item?.additional_details?.units}}) </span>
        </p>
      </div>
    </el-option>
  </el-select>
</div>
