
<div class="custom-date">
    <div  v-for="(customDate,index) in selectedData" :key="index">
        <div  >
    <DatePickerView
              :itemValue="customDate"
              :selectedValue="customDate?.selectedValue || ''"
              :indexOfOptions="index"
              @item-selected="selectedDate"
              :disabledPrev="disabledPrev"
            />
        </div>
        </div>
</div>
