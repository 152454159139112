<template>
    <div class="flexCardContainer">
        <div class="sectionCard">
            <div class="sectionNoContainer" v-if="isCardOpen">
                <p class="sectionNo">
                     {{"Section "+ sectionIndex }}
                </p>
            </div>
            <div :class="['cardBorderContainer', !isCardOpen ? 'padding' : '']">
                <div class="closeCard" v-if="!isCardOpen" @click="cardToggleFunc()">
                    <p class="heading">{{sectionData.title}}</p>
                    <i class="el-icon-arrow-down icons" ></i>
                </div>
                <div class="flexInputs" v-else>
                    <div class="inputsCont" v-if="!isPreviewMode">
                        <div class="flexColumn">
                            <el-input  placeholder="Section Title" v-model="sectionData.title" @change="prepareSectionObjectToStore('title')"></el-input>
                        </div>
                        <div class="flexColumn">
                            <el-input class="placeholderPadding" type="textarea" :rows="3" placeholder="Description" v-model="sectionData.description" @change="prepareSectionObjectToStore('description')"></el-input>
                        </div>
                    </div>
                    <div class="previewContainer" v-else>
                        <div style="cursor:pointer" @click="cardToggleFunc()">
                            <p class="heading">{{ sectionData.title }}</p>
                            <hr class="lineHR"/>
                        </div>
                        <p class="description">{{ filteredDescription }}
                        </p>
                    </div>
                    <i class="el-icon-arrow-up icons" @click="cardToggleFunc()"></i>
                </div>
                <div  v-if="isCardOpen">
                    <draggable class="fieldsCardCont" v-model="sectionData.fields" @change="updateFieldPosition">
                        <FieldsCard
                            v-for="(field, index) in sectionData.fields"
                            :key="index"
                            :fieldIndex="index + 1"
                            :fieldsLength="sectionData.fields.length" 
                            :field="field" 
                            :templateId="templateId"
                            :sectionIndex="sectionIndex"
                            @deleteCurrentField="deleteCurrentField" 
                            @duplicateCurrentField="duplicateCurrentField"
                            @duplicateCurrentFile="duplicateCurrentFile"
                            @deleteCurrentFile="deleteCurrentFile"
                            @patchTemplate="propagatePatchDataFromField"
                            ref="fieldsRef"
                        />
                    </draggable>
                </div>
            </div>
        </div>
        <div class="cardOptions" v-if="isCardOpen && !isPreviewMode">
            <el-tooltip class="item" effect="dark" content="Add Field" placement="left" :disabled="!(tooltipVisible && tooltipIcon === 'addField')">
                <img src="../assets/PlusCircle.svg" alt="" class="icons" @click="addField" @mouseenter="showTooltip('addField',$event)" @mouseleave="hideTooltip"/>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="Add Section" placement="left" :disabled="!(tooltipVisible && tooltipIcon === 'addSection')">
                <img src="../assets/view-stacked.svg" alt="" class="icons" @click="addSection" @mouseenter="showTooltip('addSection', $event)" @mouseleave="hideTooltip"/>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="Duplicate Section" placement="left" :disabled="!(tooltipVisible && tooltipIcon === 'duplicateSection')">
                <i class="icon copy-alt icons"  @click="duplicateSection" @mouseenter="showTooltip('duplicateSection', $event)" @mouseleave="hideTooltip"></i>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="Move Section" placement="left" :disabled="!(tooltipVisible && tooltipIcon === 'moveSection')">
                <img src="../assets/ArrowsMove.svg" :class="{ 'disabled-icon': sectionsLength === 1 }" @click="openMoveSectionCard" alt="" class="icons" @mouseenter="showTooltip('moveSection', $event)" @mouseleave="hideTooltip"/>
            </el-tooltip>
            
            <el-tooltip class="item" effect="dark" content="Delete Section" placement="left" :disabled="!(tooltipVisible && tooltipIcon === 'deleteSection')">
                <i class="icon delete-alt icons" @click="deleteCurrentSection" :class="{ 'disabled-icon': sectionsLength === 1 }" @mouseenter="showTooltip('deleteSection', $event)" @mouseleave="hideTooltip"></i>
            </el-tooltip>
        </div>
        <MoveSectionPopup 
        v-show="openMoveCard" 
        :showMoveSectionPopup="openMoveCard" 
        :isEditTemplateMode="isEditTemplateMode" 
        @sectionMoved="prepareSectionObjectToStore('sequence',  $event)"
        @close-popup="openMoveCard=false"/>    
    </div>
</template>

<script>
import FieldsCard from "./fieldsCard.vue";
import { mapState, mapActions } from 'pinia';
import MoveSectionPopup from './moveSectionPopup.vue'
import draggable from "vuedraggable";
import { useEditableSiteSurveyStore } from "../../../stores/editableSiteSurvey";

export default {
    emits: [
        "checkScrollThumb",       
        "addSection",             
        "patchTemplate",          
        "deleteCurrentSection",   
        "duplicateCurrentSection",
        "addFieldToSection",      
        "duplicateCurrentField",  
        "deleteCurrentField",     
        "duplicateCurrentFile",   
        "deleteCurrentFile",      
    ],
    components: {
        FieldsCard,
        draggable,
        MoveSectionPopup
    },
    props:{
        section:{
            type:Object,
        },
        sectionIndex:{
            type:Number,
        },
        sectionsLength:{
            type: Number
        },
        templateId: {
            type: Number,
            required: false,
        },
    },
    data() {
        return {
            isCardOpen: true,
            sectionData: this.$props.section,
            openMoveCard: false,
            tooltipVisible: false,
            tooltipIcon: '',
            currentIconElement: null,
        };
    },
    computed: {
        ...mapState(useEditableSiteSurveyStore, {
            isPreviewMode : "GET_PREVIEW_FLAG",
        }),
        filteredDescription(){
            if (!this.sectionData.description) return ''
            return this.sectionData.description.replaceAll('\\n', "");
        },
        isEditTemplateMode(){
            // to determine the actual edit template mode
            return this.templateId !== 0 && !this.isPreviewMode;
        }
    },
    created() {
        this.isEditModeFunc();
        window.addEventListener('scroll', this.handleScroll, true);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll, true);
    },
    watch: {
        section: {
            immediate: true,
            handler(newVal) {
                this.sectionData = newVal || {};
            },
        },
    },
    methods: {
        showTooltip(iconName, event) {
            this.tooltipVisible = true;
            this.tooltipIcon = iconName;
            this.currentIconElement = event.target;
        },
        hideTooltip() {
            this.tooltipVisible = false;
            this.tooltipIcon = '';
            this.currentIconElement = null;
        },
        handleScroll() {
            if (this.currentIconElement) {
                const rect = this.currentIconElement.getBoundingClientRect();
                const isCursorOverIcon =
                rect.top <= window.innerHeight &&
                rect.bottom >= 0 &&
                rect.left <= window.innerWidth &&
                rect.right >= 0;
                if (!isCursorOverIcon) {
                    this.hideTooltip();
                }
            } else {
                this.hideTooltip();
            }
        },
        isEditModeFunc() {
            if(!this.isPreviewMode) {
                this.isCardOpen = true;
            } else {
                this.isCardOpen = false;
            }
        },
        openMoveSectionCard(){
            this.openMoveCard=true
        },
        cardToggleFunc() {
            this.$emit('checkScrollThumb')
            this.isCardOpen = !this.isCardOpen;
        },
        ...mapActions(useEditableSiteSurveyStore, {
            updateSection:"UPDATE_SECTION",
            setAllowFieldPatchFlagFalse:"SET_ALLOW_FIELD_PATCH_FLAG_FALSE",
            lastBoundaryHasBeenRemoved: "LAST_BOUNDARY_HAS_BEEN_REMOVED",
            countOfPOIQ:"COUNT_OF_POI_Q",
        }),
        addSection(){
            this.$emit("addSection", this.sectionIndex-1);
        },
        updateFieldPosition(event){
            this.sectionData.fields.forEach((stage, index) => {
                stage.additional_info.sequence = index ;
            });
            if(this.isEditTemplateMode){
                // run this code only if it is editing an existing template
                this.sectionData.fields.forEach((stage, index) => {
                    stage.sequence = index ;
                });
                let fieldToPatch = event.moved.element;
                let fieldBody = {
                    'sequence': fieldToPatch.sequence,
                    isFieldLevel: true,
                    id: fieldToPatch.id,
                };
                this.setAllowFieldPatchFlagFalse();
                this.$emit("patchTemplate",fieldBody);
            }
        },
        deleteCurrentSection(){
            if(this.countOfPOIQ() !== 0 && this.sectionData.fields[0].question_type === 'boundary'){
                //cannot remove last boundary
                //show error message and reset the Qtype back to boundary
                this.$message({
                    showClose: true,
                    message: "Cannot remove this section containing boundary. Please remove the dependent POIs and try again.",
                    type: "error",
                    center: true
                });
            }
            else{
                if(this.countOfPOIQ() === 0 && this.sectionData.fields[0].question_type === 'boundary')
                    this.lastBoundaryHasBeenRemoved();
                this.$emit("deleteCurrentSection", this.sectionIndex-1);
                this.setAllowFieldPatchFlagFalse();
            }
        },
        duplicateSection(){
            //check if the section being duplicated is a boundary section
            if(this.sectionData.fields[0].question_type === 'boundary'){
                this.$message({
                    showClose: true,
                    message: "Cannot add more than one boundary section in the form.",
                    type: "error",
                    center: true
                });
            }
            else
            this.$emit("duplicateCurrentSection", [this.sectionData, this.sectionIndex -1]);
        },
        addField(){
            const foundBoundary = this.sectionData.fields.find(field => field.question_type === 'boundary');
            if(foundBoundary){
                this.$message({
                    showClose: true,
                    message: "Cannot add fields to a section with boundary. Please add a new section and try again.",
                    type: "error",
                    center: true
                });
            }
            else{
                this.$emit("addFieldToSection", this.sectionIndex-1, () => {
                    this.scrollToField(this.sectionData.fields.length - 1);
                });
                //this.scrollToField(this.sectionData.fields.length - 1);
            }
        },
        duplicateCurrentField(fieldIndex){
            this.$emit("duplicateCurrentField", [fieldIndex, this.sectionIndex - 1],() => {
                this.scrollToField(fieldIndex + 1);
            });
            //scroll to after the field we are copying
            // this.scrollToField(fieldIndex + 1);
        },
        deleteCurrentField(fieldIndex){
            this.$emit("deleteCurrentField", [fieldIndex, this.sectionIndex - 1]);
        },
        duplicateCurrentFile([fileIndex, fieldIndex]){
            this.$emit("duplicateCurrentFile", [fileIndex, fieldIndex, this.sectionIndex - 1]);
        },
        deleteCurrentFile([fileIndex, fieldIndex]){
            this.$emit("deleteCurrentFile", [fileIndex, fieldIndex, this.sectionIndex - 1]);
        },
        scrollToField(fieldIndex){
            this.$nextTick(() => {
                // Get a reference to the newly added section
                const newFieldRef = this.$refs.fieldsRef[fieldIndex];
                if (newFieldRef) {
                    newFieldRef.$el.scrollIntoView({
                    block: 'center', // Scroll to the top of the screen vertically
                    });
                }
            });
        },
        prepareSectionObjectToStore(propertyName, sectionMoved){
            if(this.isEditTemplateMode){
                let sectionBody = {
                    [propertyName]: propertyName !== 'sequence' ? this.sectionData[propertyName].trim() : sectionMoved?.sequence,
                    isSectionLevel: true,
                    id:sectionMoved?.id || this.sectionData.id,
                };
                this.$emit("patchTemplate",sectionBody);
            }
            else{
                this.updateSection(this.sectionIndex -1, this.sectionData);
            }
        },
        propagatePatchDataFromField(fieldBody){
            if(this.isEditTemplateMode){
                fieldBody.template_section = this.sectionData.id;
                this.$emit("patchTemplate",fieldBody);
            }
        }
    },
};
</script>

<style scoped>
.flexCardContainer,
.closeCard,
.flexInputs {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    align-items: flex-start;
    position: relative;
}

.closeCard {
    align-items: center;
    cursor: pointer;
}

.heading {
    color: #1C3366;
    font-size: 20px;
}

.lineHR {
    margin: 16px 0px;
}

.description {
    color: #777;
    font-size: 20px;
    white-space: break-spaces;
}

.flexInputs {
    gap: 30px;
}

.sectionCard {
    width: 100%;
}

.sectionNoContainer {
    border-radius: 8px 8px 0px 0px;
    background: #E8EDF2;
    padding: 8px 16px;
    width: fit-content;
    margin-left: 60px;
}

.sectionNo {
    font-size: 16px;
    color: #222;
}

.cardBorderContainer {
    border-radius: 8px;
    border: 1px solid #CCC;
    padding: 24px;
}

.padding {
    padding: 10px 24px;
}

.inputsCont {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
}

.cardOptions {
    padding: 16px;
    margin-top: 37px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.cardOptions {
    display: grid;
    place-items: center;
    gap: 16px;
    position: sticky;
    top: 0; /* Stick to the top of the container */
    z-index: 1; /* Ensure the toolbar is above the section content */
}

.icons {
    width: 20px;
    font-size: 20px;
    cursor: pointer;
}

.line {
    padding-bottom: 16px;
    border-bottom: 1px solid #ccc;
}

.el-icon-arrow-up,
.el-icon-arrow-down {
    font-weight: 600;
}

.fieldsCardCont {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 30px;
}

.flexCardContainer>>>.el-input__inner::placeholder {
    /*color: #222;*/
    color: #999999;
}

.placeholderPadding >>>.el-textarea__inner::placeholder{
    padding-top: 5px;
}

.icons {
    cursor: pointer;
}

.disabled-icon {
    opacity: 0.5; /* or any other styling to visually disable the icon */
    pointer-events: none; /* prevent interaction with the icon */
}

.previewContainer{
    min-width: 95%;
}
</style>