
<div class="avatars-container" style="display: relative;" @click="handleClick">
    <div class="avatar-icons">
        <el-avatar :class="$props.hover ? 'avatar-hover' : ''" :size="imgWidth"
            v-for="(avatar, index) in computedAvatars" :key="index" :style="getAvatarStyle(avatar.name, index)">
            <el-tooltip class="item" effect="dark" :content="avatar.message" placement="top" popper-class="avatar-hover-tooltip">
                <span>{{ limitNotRequired ? (returnInitials(avatar.name)) : ($props.avatars.length > $props.limit
                    ? (index === 0 ? avatar.name : returnInitials(avatar.name)) : returnInitials(avatar.name))
                }}</span>
            </el-tooltip>
        </el-avatar>
    </div>
    <el-select v-if="$props.editable" v-model="selectedValue" v-show="true" filterable placeholder="" :size="`${$props.imgWidth}`"
        popper-class="avatar-handler-dropdown"
        style="flex: 1"
        class="selection-avatars" @change="handleAdd"  ref="selection" hide-on-selected="false">
        <el-option v-for="(avatar, index) in computedSelectionAvatars" :key="index" :label="avatar.name"
            :disabled="avatar.disabled ? true : false"
            :value="avatar.id">
            <div >
            <el-checkbox :disabled="avatar.disabled ? true : false" :value="!avatar.isUnique"></el-checkbox>
                <el-avatar :size="28" :style="getSelectionAvatarStyle(avatar.name, avatar.disabled)">
                    <span>{{ returnInitials(avatar.name) }}</span>
                </el-avatar>
                <span class="cb-avatar-name" :style="handleCollaboratorsDisability()">{{ avatar.name }}</span>
            </div>
        </el-option>
        <ScrollObserver
              :showLoader="false"
              @intersect="loadMore2"
            />
    </el-select>
</div>
