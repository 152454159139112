
<div>
  <div class="table_section table_normal">
    <table class="data_table">
      <thead class="headerSticky">
        <tr>
          <th>
            <div class="data_head pName">
              <span class="title_text">Utility Provider</span>
            </div>
          </th>
          <th>
            <div class="data_head">
              <span class="title_text">Tariff Rates</span>
            </div>
          </th>
          <th>
            <div class="data_head">
              <span class="title_text">Actions</span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(tariff, index) in computedTariffData" :key="index">
          <td class="smallScr" style="width: 65%;">
            <div class="md_head">Utility Provider</div>   
            <div class="value_type pName">
                <div class="pNameData">
                  {{tariff.utility_provider_name}}
                </div>
            </div>
          </td>
          <td style="width: 25%;">
            <div class="md_head">Tariff Rates</div>
            <div class="value_type">
              <span class="nowrap">
                {{tariff.utility_rate_name}}
              </span>
            </div>
          </td>
          <td class="text-center action-delete" v-if="window.width >= 767" style="text-align: justify;">
            <div class="md_head">Actions</div>
            <div class="value_type">
              <span class="nowrap">
            <i
              class="icon copy-alt"
              style="font-size: 1.7rem; cursor: pointer;"
              @click="handleCopy(tariff)"
            />
            <i
              class="icon edit-alt"
              style="font-size: 1.7rem; cursor: pointer;"
              @click="handleEdit(tariff)"
              v-if="tariff.organisation"
            />
            <i
              class="icon delete-alt"
              style="font-size: 1.7rem; cursor: pointer;"
              @click="handleDelete(tariff.id)"
              v-if="tariff.organisation"
            />
          </span>
            </div>
          </td>
          <td v-else>
            <el-dropdown
              trigger="click"
              @command="handleCommand"
            >
              <span class="el-dropdown-link">
                <span data-v-12684b02="" class="fas fa-ellipsis-v"></span>
              </span>
              <template v-slot:dropdown>
                <el-dropdown-menu >
                  <el-dropdown-item
                    icon="icon copy-alt"
                    :command="{ name: 'copy', tariff: `${tariff}` }"
                    >Copy
                    </el-dropdown-item
                  >
                  <el-dropdown-item
                    v-if="tariff.organisation"
                    icon="icon edit-alt"
                    :command="{ name: 'edit', tariff: `${tariff}` }"
                    >Edit</el-dropdown-item
                  >
                  <el-dropdown-item
                    v-if="tariff.organisation"
                    icon="icon delete-alt"
                    :command="{ name: 'delete', id: `${tariff.id}` }"
                    >Delete</el-dropdown-item
                  >
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </td>
        </tr>
      </tbody>
    </table>
    <div
      v-infinite-scroll="loadMoreTariffs"
      infinite-scroll-disabled="busy"
      infinite-scroll-distance="10"
      style="text-align: center"
    >
      <i v-if="busy" class="el-icon-loading infiniteScrollLoader" />
    </div>
  </div>
  <CreateTariffPopup
    :createTariffPopup="createTariffPopup"
    :typeOfOperation="typeOfOperation"
    :tariffForId="tariffForId"
    :key="createCounter"
    @added="handleAdd()"
    @close="handleClose()"
  />
  <DeleteTariffPopup
    :tariffId="tariffId"
    :isDeleteTariffPopup="isDeleteTariffPopup"
    @close="handleClose()"
    @delete="handleAfterDelete()"
  />
</div>
