
<div class="setRemminder">
  <globalDrawer
    :isOpen="showCreateDrawer"
    :title="createBoxHeading"
    :handleCloseDialog="closeCreateDrawer"
    :drawerSize="drawerSize"
  >
    <!-- <template #header>
    <div class="title">
      <h4 style="font-weight: bolder; padding: 0.75rem 0.5rem 20px">
        Set Reminder
      </h4>
      <span
        class="cross"
        @click="onClose()"
        style="font-weight: bolder; padding: 0.75rem 0.5rem 20px"
        >&#x2716;</span
      >
    </div>
  </template> -->
    <template #body>
      <div class="wholeBody">
        <div class="custom-date-picker">
          <label for="" class="dateLabel">Date Picker</label>
          <el-date-picker
            class="is-image-suffix"
            v-model="pickedDate"
            :clearable="false"
            type="date"
            name="date"
            v-validate="'required'"
            :format="computedDateFormat"
            value-format="yyyy-MM-dd"
            :suffix-icon="iconSuffix"
            :picker-options="pickerOptions"
            placeholder="Select a day"
          >
            <template #suffix>
              <img
                src="./../leadManagement/components/assets/event.png"
                alt="Icon"
                class="suffix-icon"
              />
            </template>
          </el-date-picker>
          <p class="formErrors">
            <span>{{ errors.first("date") }}</span>
          </p>
          <!-- <img src="../../assets/img/calendar.svg" alt="Icon" class="suffix-icon"> -->
        </div>
        <div class="custom-date-picker">
          <label for="" class="dateLabel">Time Picker</label>
          <!-- <el-time-picker
            :clearable="false"
            v-model="pickedTime"
            v-validate="'required'"
            format="HH:mm"
            name="time"
            :suffix-icon="iconSuffix"
            value-format="HH:mm:ss"
            placeholder="Select a time">
          </el-time-picker> -->
          <input
            class="inputTime"
            type="time"
            v-validate="'required'"
            name="time"
            v-model="pickedTime"
            placeholder="Select a time"
            ref="time-picker"
          />
          <p class="formErrors">
            <span>{{ errors.first("time") }}</span>
          </p>
        </div>
      </div>
    </template>
    <template #pinned-footer>
      <div class="bottomCard">
        <el-card class="boxCard">
          <el-input
            type="textarea"
            :rows="4"
            placeholder="Add your notes here"
            v-model="notes"
            maxlength="160"
            show-word-limit
            resize="none"
          ></el-input>
          <div class="outerBtn">
            <el-button
              type="primary"
              :disabled="errors.items.length > 0"
              class="submitBtn"
              @click="submitDetails()"
              >Save</el-button
            >
          </div>
        </el-card>
      </div>
    </template>
  </globalDrawer>
</div>
