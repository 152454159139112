
<div id="parentContainer" v-loading.fullscreen="isLoading">
  <el-dialog
    :visible="isShareProposalPopupVisible"
    :close-on-click-modal="true"
    title="Share Proposal"
    @close="onDialogClose"
    append-to-body
  >
    <div :class="project.designs.length > 0 ? 'container' : 'noDesignCont'">
      <div class="backOne" v-if="project.designs.length > 0">
        <div class="table_section table_normal">
          <table class="data_table">
            <thead class="headerSticky">
              <tr>
                <th>
                  <div class="data_head">
                    <p class="headerTitle">Name</p>
                  </div>
                </th>
                <th>
                  <div class="data_head">
                    <p class="headerTitle">System Size</p>
                  </div>
                </th>
                <th>
                  <div class="data_head">
                    <p class="headerTitle">Last Modified</p>
                  </div>
                </th>
                <th>
                  <div class="data_head">
                    <p class="headerTitle">Action</p>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="tr" v-for="design in project.designs" :key="design.id">
                <td>
                  <div class="md_head">Name</div>
                  <div class="value_type">{{ design.name }}</div>
                </td>
                <td>
                  <div class="md_head">System Size</div>
                  <div class="commonVal">
                    {{
                      design.versions.summary.nameplate_dc_size
                        ? design.versions.summary.nameplate_dc_size + " kW"
                        : "—"
                    }}
                  </div>
                </td>
                <td class="orderOn">
                  <div class="md_head">Last Modified</div>
                  <div class="value_type">
                    <p class="commonVal">
                      {{ dateFormat(design.versions.modified_at) }}
                    </p>
                  </div>
                </td>
                <td>
                  <div class="md_head">Actions</div>
                  <div class="value_type pName">
                    <ProposalButtons :design="design" />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- <div v-observe-visibility="loadMoreRequests" style="text-align: center">
                          <i v-if="busy" class="el-icon-loading infiniteScrollLoader" />
                      </div> -->
        </div>
      </div>
      <p class="noDesign" v-else>No designs</p>
    </div>
  </el-dialog>
</div>
