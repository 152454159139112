import * as THREE from "three";
import { createMesh } from "../../../utils/meshUtils";
import SmartroofFace from "../smartroof/SmartroofFace";
import BaseObject from "../../BaseObject";
import { checkPolygonInsidePolygonVec } from "../../../utils/utils";
import * as utils from "../../../utils/utils";
import GridCell from "./GridCell";
import CylinderModel from "../CylinderModel";
import PolygonModel from "../PolygonModel";
import PVTile from "./PVTile";
import {
    ACAnnealer,
    DCAnnealer,
    PVString,
    getNormalizedPenalty,
    getPenaltyColor,
    updateEndNodes,
} from "./annealingUtils";
import NonPVTile from "./NonPVTile";
import * as BufferGeometryUtils from "three/examples/jsm/utils/BufferGeometryUtils";
import { Box, Point, QuadTree } from "js-quadtree";
import { InverterInstance } from "./PowerRoofInverter";
import { COLOR_MAPPINGS, VISUAL_STATES } from "../../visualConstants";

const OBSTRUCTION_BUFFER = 0.02;

export const TILE_VIEWS = {
    DESIGN_VIEW: "DESIGN",
    DC_VIEW: "DC",
    AC_VIEW: "AC",
    AESTHETIC_VIEW: "AESTHETIC",
    SOLAR_ACCESS: "SOLAR",
};

export default class TilesGrid extends BaseObject {
    /**
     *
     * @param {*} stage
     * @param {SmartroofFace} face
     * This can be any other compatible object too.
     * Need two points (first and last) for x-axis (and y-axis), a normal and the first point will be used as origin.
     */
    constructor(stage, face) {
        super(stage);

        const faceVertices = face.getVector3DVertices();
        this.origin = faceVertices[faceVertices.length - 1];
        this.smartroofFace = face;
        this.plane = face.plane;
        
        this.objectsGroup = new THREE.Group();
        this.objectsGroup.container = this;
        this.stage.sceneManager.scene.add(this.objectsGroup);
        this.getPowerRoof().objectsGroup.add(this.objectsGroup);
        // this.debugGroup = new THREE.Group();
        // this.stage.sceneManager.scene.add(this.debugGroup);
        // TODO: Should this be the first point or the last.
        this.customTilesGroup = new THREE.Group();
        this.customTilesGroup.container = this;
        this.objectsGroup.add(this.customTilesGroup);

        this.dcObjectsGroup = new THREE.Group();
        this.objectsGroup.add(this.dcObjectsGroup);

        this.acObjectsGroup = new THREE.Group();
        this.objectsGroup.add(this.acObjectsGroup);

        this.textGroup = new THREE.Group();
        this.textGroup.container = this;
        this.objectsGroup.add(this.textGroup);

        this.xAxis = new THREE.Vector3()
            .subVectors(faceVertices[0], faceVertices[faceVertices.length - 1])
            .normalize();
        this.yAxis = this.plane.normal.clone().cross(this.xAxis).normalize();
        this.zAxis = this.plane.normal.clone().normalize();
        this.matrix = new THREE.Matrix4();
        // 'this.origin.y - 0.00001' was used in the POC to mitigate edge cases for
        // the bottom-most lineDraw. But it didn't fix it completely.
        this.matrix
            .makeBasis(this.xAxis, this.yAxis, this.zAxis)
            .setPosition(this.origin.x, this.origin.y, this.origin.z);
        this.inverseMatrix = this.matrix.clone().invert();

        // Temp
        this.id = stage.getSubarrayId();
        this.name = "Subarray #" + this.id.toString();
        this.PANEL_MODEL_ID = 0;

        // Define the dimensions of the tile based on the properties provided
        // Note: The tile length is the longer horizontal edge, while the width is the shorter vertical edge.
        const gridProperties = this.getPowerRoof().getGridProperties();
        this.tileLength = gridProperties.length;
        this.tileWidth = gridProperties.width;
        this.terminalXOffsetFraction = gridProperties.terminalXOffsetFraction;
        this.terminalYOffsetFraction = gridProperties.terminalYOffsetFraction;
        this.pigTailBuffer = gridProperties.pigTailBuffer;
        this.pvDefaultColor = new THREE.Color(COLOR_MAPPINGS.TILES.PV_TILE_COLOR);
        this.pvHighlighColor = new THREE.Color(COLOR_MAPPINGS.TILES.PV_TILE_HIGHLIGHT_COLOR);
        this.nonPVDefaultColor = new THREE.Color(COLOR_MAPPINGS.TILES.NON_PV_TILE_COLOR);
        this.nonPVHighlighColor = new THREE.Color(COLOR_MAPPINGS.TILES.NON_PV_TILE_HIGHLIGHT_COLOR);
        this.partialTileDefaultColors = COLOR_MAPPINGS.TILES.PARTIAL_TILE_COLORS.map(
            (color) => new THREE.Color(color)
        );
        this.partialTileHighlightColors = COLOR_MAPPINGS.TILES.PARTIAL_TILE_HIGHLIGHT_COLORS.map(
            (color) => new THREE.Color(color)
        );
        this.defaultDCColor = new THREE.Color(COLOR_MAPPINGS.TILES.DEFAULT_DC_COLOR);

        // Define the overlap and side spacing
        this.tileOverlap = gridProperties.overlap;
        this.sideSpacing = gridProperties.sideSpacing;

        this.height = gridProperties.height;

        // The relative tilt of the tile(in degrees) from the roof face
        this.relativeTilt = gridProperties.relativeTilt;

        // The offset of the grid from the eave and ridge
        this.eaveOffset = gridProperties.eaveOffset;
        this.columnOffset = gridProperties.columnOffset;

        // The partial tile lengths in terms of percentage of the tile length (e.g. [0.5, 0.25] means 50% and 25% of the tile length)
        this.partialTileFractions = gridProperties.partialTileFractions;

        // The tile rows, starting from the eave [[GridCell, GridCell, ...], [...], ...]
        this.tileRows = [];
        this.gridCellRows = [];

        this.cellMap = {};

        this.setObjectGroupsMatrix();
        this.initGridInstancedMesh();
        this.pvEnabled = true;
        this.pvTileCount = 0;
        this.nonPVTileCount = {
            full: 0,
            half: 0,
            quarter: 0,
        }
        this.customTileCount = 0;
        this.customTileArea = 0;

        this.selectionQuadtree = null;
        this.createQuadtreeForSelection();
        this.microInverters = [];
        this.view = TILE_VIEWS.DESIGN_VIEW;
        // TODO: Not sure if this is necessary
        this.previousView = TILE_VIEWS.DESIGN_VIEW;
        this.isFilled = false;
    }

    getPowerRoof() {
        return this.smartroofFace.parent.connectedPowerRoof;
    }
    getPVTileType() {
        return this.getPowerRoof().getPVTileType();
    }
    getMountingMethod() {
        return this.getPowerRoof().getMountingMethod();
    }
    getRelativeTilt() {
        return this.getPowerRoof().getRelativeTilt();
    }
    getRowOverlapping() {
        return this.getPowerRoof().getRowOverlapping();
    }
    isMechanicalLayoutEnabled() {
        return this.getPowerRoof().isMechanicalLayoutEnabled();
    }
    isFlashingEnabled() {
        return this.getPowerRoof().isFlashingEnabled();
    }

    optimize() {
        return "";
    }

    async update(updatedProperties) {
        return this.getPowerRoof().updateGridProperties(updatedProperties);
    }

    //       Tile Length
    //  ┌─────────────────────────────┐
    //  │                             │
    //  │                             │ Tile Width
    //  │                             │
    //  │                             │
    //  └─────────────────────────────┘
    //
    //       Cell Length
    //  ┌───────────────────────────────┐
    //  │                               │
    //  │                               │ Cell Width
    //  │                               │
    //  └───────────────────────────────┘
    //

    // the full cell dimension along the vertical axis
    getCellWidth() {
        return (
            this.tileWidth * Math.cos((Math.PI * this.relativeTilt) / 180) -
            this.tileOverlap
        );
    }

    // the full cell dimension along the horizontal axis
    getCellLength() {
        return this.tileLength + this.sideSpacing;
    }

    getPigTailLength() {
        return (
            this.getCellWidth() *
            (1 - this.terminalXOffsetFraction * 0.5 + this.pigTailBuffer)
        );
    }

    getDCColor() {
        return this.defaultDCColor;
    }

    getDcSize() {
        // TODO: Populate module properties
        const moduleProperties = {
            moduleId: 24050,
            moduleLength: 1.26,
            moduleMake:
                "Arka energy  PV SOLAR TILE – 90Wp PV SOLAR TILE – 90Wp",
            moduleSize: 0.09,
            moduleWidth: 0.465,
        };

        return moduleProperties.moduleSize * this.pvTileCount;
    }

    getPVColor() {
        return this.pvDefaultColor;
    }

    getPVHighlightColor() {
        return this.pvHighlighColor;
    }

    getNonPVColor() {
        return this.nonPVDefaultColor;
    }

    getNonPVHighlightColor() {
        return this.nonPVHighlighColor;
    }

    getPartialTileColor(index) {
        return this.partialTileDefaultColors[index];
    }

    getPartialTileHighlightColor(index) {
        return this.partialTileHighlightColors[index];
    }

    getLift() {
        return (
            this.height +
            this.tileWidth * Math.sin((Math.PI * this.relativeTilt) / 180)
        );
        // return 0;
    }

    getInverterLift() {
        return this.getLift() + 0.05;
    }

    getTilt() {
        return this.relativeTilt + this.smartroofFace.tilt;
    }

    getAzimuth() {
        return this.smartroofFace.azimuth;
    }

    getYCompensation() {
        return (
            (this.tileWidth * Math.cos((Math.PI * this.relativeTilt) / 180) -
                this.getCellWidth()) /
            2
        );
    }

    /**
     *
     * @param {THREE.Vector3} vector
     * @returns {THREE.Vector3}
     */
    toLocal(vector = new THREE.Vector3()) {
        return vector.clone().applyMatrix4(this.inverseMatrix);
    }

    /**
     *
     * @param {THREE.Vector3} vector
     * @returns {THREE.Vector3}
     */
    toGlobal(vector = new THREE.Vector3()) {
        return vector.clone().applyMatrix4(this.matrix);
    }

    fillArea({ pv, tileCount } = { pv: true }) {
        this.pvEnabled = pv;
        return this.addTiles(tileCount);
    }

    getInstancedObjectFromId(instanceId) {
        for (let row of this.gridCellRows.flat()) {
            for (let tile of row.tiles) {
                if (tile.instanceIndex === instanceId) {
                    if (!tile.visible) return null;
                    return tile;
                }
            }
        }
        return null;
    }

    getLocalObstructionVertices() {
        const obstructions = this.smartroofFace
            .getChildren()
            .filter(
                (child) =>
                    child instanceof CylinderModel ||
                    child instanceof PolygonModel
            );
        const obstructionVertices = [];
        obstructions.forEach((obstruction) => {
            let globalVertices = null;
            if (obstruction instanceof CylinderModel) {
                globalVertices = obstruction.getRotated2DVertices();
                // globalVertices = obstruction.get2DVertices();
            } else if (obstruction instanceof PolygonModel) {
                globalVertices = obstruction.get2DVertices();
            }
            const localVertices = globalVertices.map((v) => {
                const localV = this.toLocal(
                    new THREE.Vector3(
                        v[0],
                        v[1],
                        this.smartroofFace.getZOnTopSurface(v[0], v[1])
                    )
                );
                localV.z = 0;
                return localV;
            });
            //  enlarge the obstruction by a small amount
            const enlargedVertices = utils.bufferPolygon(utils.convertVectorToArray(localVertices), OBSTRUCTION_BUFFER)[0].map((v) => new THREE.Vector3(v[0], v[1], 0));
            obstructionVertices.push(enlargedVertices);            
        });
        return obstructionVertices;
    }

    getManhattanPathGlobal(startGlobal, endGlobal) {
        const start = this.toLocal(startGlobal);
        const end = this.toLocal(endGlobal);
        const localSetbackVertices = this.smartroofFace.setbackVertices.map(
            (setbackShape) => {
                return setbackShape.map((vertex) => {
                    const localV = this.toLocal(vertex);
                    localV.z = 0;
                    return localV;
                });
            }
        );
        const insideVerts = localSetbackVertices.filter(
            (setbackVerts) => !utils.checkClockwiseVectors(setbackVerts)
        );
        const boundingVertices = insideVerts[0];
        const manhattanPoint = new THREE.Vector3(start.x, end.y, 0);
        const manhattanPoint2 = new THREE.Vector3(end.x, start.y, 0);
        const arrayVertices = utils.convertVectorToArray(boundingVertices);
        const inside1 = utils.checkPointInsideVertices(arrayVertices, [
            manhattanPoint.x,
            manhattanPoint.y,
        ]);
        const inside2 = utils.checkPointInsideVertices(arrayVertices, [
            manhattanPoint2.x,
            manhattanPoint2.y,
        ]);
        if (inside1) {
            const manhattanPointGlobal = this.toGlobal(manhattanPoint);
            return [
                [startGlobal, manhattanPointGlobal],
                [manhattanPointGlobal, endGlobal],
            ];
        } else if (inside2) {
            const manhattanPointGlobal = this.toGlobal(manhattanPoint2);
            return [
                [startGlobal, manhattanPointGlobal],
                [manhattanPointGlobal, endGlobal],
            ];
        }
        return [[startGlobal, endGlobal]];
    }

    getManhattanPath(start, end) {
        // if the start and end are already along the same axis, return the path
        // use a tolerance of 0.01
        if (
            Math.abs(start.x - end.x) < 0.01 ||
            Math.abs(start.y - end.y) < 0.01
        ) {
            return [[start, end]];
        }
        const localSetbackVertices = this.smartroofFace.setbackVertices.map(
            (setbackShape) => {
                return setbackShape.map((vertex) => {
                    const localV = this.toLocal(vertex);
                    localV.z = 0;
                    return localV;
                });
            }
        );
        const insideVerts = localSetbackVertices.filter(
            (setbackVerts) => !utils.checkClockwiseVectors(setbackVerts)
        );
        const boundingVertices = insideVerts[0];
        const manhattanPoint = new THREE.Vector3(start.x, end.y, 0);
        const manhattanPoint2 = new THREE.Vector3(end.x, start.y, 0);
        const arrayVertices = utils.convertVectorToArray(boundingVertices);
        const inside1 = utils.checkPointInsideVertices(arrayVertices, [
            manhattanPoint.x,
            manhattanPoint.y,
        ]);
        const inside2 = utils.checkPointInsideVertices(arrayVertices, [
            manhattanPoint2.x,
            manhattanPoint2.y,
        ]);
        if (inside1) {
            return [
                [start, manhattanPoint],
                [manhattanPoint, end],
            ];
        } else if (inside2) {
            return [
                [start, manhattanPoint2],
                [manhattanPoint2, end],
            ];
        }
        return [[start, end]];
    }

    getPolygonIntersectionPoint(polygon, line) {
        // find the intersection point between the polygon and the line
        const intersectionPoints = [];
        for (let i = 0; i < polygon.length; i++) {
            const v1 = polygon[i];
            const v2 = polygon[(i + 1) % polygon.length];
            const intersectionPoint = utils.getIntersectionPoint(
                v1,
                v2.clone().sub(v1),
                line[0],
                line[1].clone().sub(line[0])
            );
            if (intersectionPoint) {
                intersectionPoints.push(intersectionPoint);
            }
        }
        if (intersectionPoints.length === 0) {
            return null;
        }
        // find the intersection point closest to the start of the line
        let minDistance = Infinity;
        let closestIntersectionPoint = null;
        intersectionPoints.forEach((intersectionPoint) => {
            const distance = intersectionPoint.distanceTo(line[0]);
            if (distance < minDistance) {
                minDistance = distance;
                closestIntersectionPoint = intersectionPoint;
            }
        });
        return closestIntersectionPoint;
    }

    computeArea(vertices) {
        // compute the area of the custom tile
        let area = 0;
        let j = vertices.length - 1;
        for (let i = 0; i < vertices.length; i++) {
            area +=
                (vertices[j].x + vertices[i].x) *
                (vertices[j].y - vertices[i].y);
            j = i;
        }
        return Math.abs(area / 2);
    }

    handleOutsideIntersection(setbackVertices, cellVertices, finalCell) {
        const intersection = utils.doPolygonsIntersect(
            setbackVertices,
            cellVertices
        );
        if (finalCell.isFullCell && !intersection) {
            const cellInsideSetback = checkPolygonInsidePolygonVec(
                cellVertices,
                setbackVertices
            );
            if (cellInsideSetback) {
                finalCell.isFullCell = false;
            }
        }
        if (finalCell.isFullCell) {
            const setbackInsideCell = checkPolygonInsidePolygonVec(
                setbackVertices,
                cellVertices
            );
            if (setbackInsideCell) {
                finalCell.isFullCell = false;
                finalCell.subCells = this.splitCell(
                    cellVertices,
                    setbackVertices
                );
            } else if (intersection) {
                finalCell.isFullCell = false;
                const intersectionPolygons = utils.differenceOfPolygons(
                    cellVertices,
                    setbackVertices
                );
                finalCell.subCells = [
                    ...finalCell.subCells,
                    ...intersectionPolygons,
                ];
            }
        } else if (intersection) {
            const newSubCells = finalCell.subCells.flatMap((subCell) =>
                utils.differenceOfPolygons(subCell, setbackVertices)
            );
            finalCell.subCells = newSubCells;
        }
    }

    handleInsideIntersection(setbackVertices, cellVertices, finalCell) {
        const intersection = utils.doPolygonsIntersect(
            setbackVertices,
            cellVertices
        );
        if (!intersection && finalCell.isFullCell) {
            const inside = checkPolygonInsidePolygonVec(
                cellVertices,
                setbackVertices
            );
            if (!inside) {
                finalCell.isFullCell = false;
            }
        }
        if (finalCell.isFullCell && intersection) {
            finalCell.isFullCell = false;
            const intersectionPolygons = utils.intersectionOfPolygons(
                cellVertices,
                setbackVertices
            );
            finalCell.subCells = [
                ...finalCell.subCells,
                ...intersectionPolygons,
            ];
        } else if (intersection) {
            const newSubCells = finalCell.subCells.flatMap((subCell) =>
                utils.intersectionOfPolygons(subCell, setbackVertices)
            );
            finalCell.subCells = newSubCells;
        }
    }

    splitCell(cellVertices, setbackVertices) {
        const [setbackMinX, setbackMaxX] = this.getMinMaxX(setbackVertices);
        const cell1 = [
            cellVertices[0],
            new THREE.Vector3(setbackMinX, cellVertices[0].y, 0),
            new THREE.Vector3(setbackMinX, cellVertices[2].y, 0),
            cellVertices[3],
        ];
        const cell2 = [
            new THREE.Vector3(setbackMaxX, cellVertices[0].y, 0),
            cellVertices[1],
            cellVertices[2],
            new THREE.Vector3(setbackMaxX, cellVertices[3].y, 0),
        ];
        return [cell1, cell2];
    }

    getMinMaxX(setbackVertices) {
        const xs = setbackVertices.map((v) => v.x);
        return [Math.min(...xs), Math.max(...xs)];
    }

    addTiles(tileCount) {
        this.isFilled = true;
        const cellWidth = this.getCellWidth();
        const cellLength = this.getCellLength();
        const localFaceVertices = this.smartroofFace.vertices.map((v) => {
            const localV = this.toLocal(v);
            localV.z = 0;
            return localV;
        });

        const obstructionVertices = this.getLocalObstructionVertices();


        const localBBox = new THREE.Box2().setFromPoints(localFaceVertices);

        const azimuth = this.smartroofFace.azimuth;
        let columnOffset = this.columnOffset;
        if(azimuth < 0 || azimuth > 180) {
            const baseLength = localBBox.max.x - localBBox.min.x;
            const fullTiles = Math.floor((baseLength - this.columnOffset) / cellLength);
            columnOffset = baseLength - this.columnOffset- fullTiles * cellLength;
        }
        // add a buffer of x in both directions equal to the cell length
        // add a buffer of y in both directions equal to the cell width
        localBBox.min.x -= 2 * cellLength - columnOffset;
        localBBox.min.y -= 2 * cellWidth - this.eaveOffset;
        localBBox.max.x += 2 * cellLength;
        localBBox.max.y += 2 * cellWidth;
        this.localBBox = localBBox;
        this.cellRows = [];
        let start = new THREE.Vector3(localBBox.min.x, localBBox.min.y, 0);

        function createPrimitiveCell(start, cellLength, cellWidth) {
            return [
                start.clone(),
                new THREE.Vector3(start.x + cellLength, start.y, 0),
                new THREE.Vector3(start.x + cellLength, start.y + cellWidth, 0),
                new THREE.Vector3(start.x, start.y + cellWidth, 0),
            ];
        }

        while (start.y <= localBBox.max.y + cellWidth) {
            const primitiveCells = [];

            const rowStart = start.clone();
            // if row is odd, shift the start by half a cell
            if (this.cellRows.length % 2 === 1) {
                rowStart.x -= cellLength / 2;
            } else {
                rowStart.x += cellLength / 2;
            }

            while (
                start.x <=
                localBBox.max.x + (start.y === rowStart.y ? 0 : cellLength / 2)
            ) {
                primitiveCells.push(
                    createPrimitiveCell(start, cellLength, cellWidth)
                );
                start.x += cellLength;
            }

            this.cellRows.push(primitiveCells);
            start = new THREE.Vector3(
                rowStart.x - (start.y === rowStart.y ? 0 : cellLength / 2),
                rowStart.y + cellWidth,
                0
            );
        }

        const localSetbackVertices = this.smartroofFace.setbackVertices.map(
            (setbackShape) => {
                return setbackShape.map((vertex) => {
                    const localV = this.toLocal(vertex);
                    localV.z = 0;
                    return localV;
                });
            }
        );

        const insideVerts = localSetbackVertices.filter((setbackVerts) => {
            return (
                !utils.checkClockwiseVectors(setbackVerts) &&
                this.computeArea(setbackVerts) >
                    this.tileLength * this.tileWidth
            );
        });
        const outsideVerts = [
            ...localSetbackVertices.filter((setbackVerts) =>
                utils.checkClockwiseVectors(setbackVerts)
            ),
            ...obstructionVertices,
        ];

        const finalRows = [];

        insideVerts.forEach((insideLoop) => {
            const filteredOutsideVerts = [];
            // only include the outside vertices that are inside or intersect the inside loop
            outsideVerts.forEach((setbackVertices) => {
                if (utils.checkPolygonInsidePolygonVec(setbackVertices, insideLoop)) {
                    filteredOutsideVerts.push(setbackVertices);
                } else if (utils.doPolygonsIntersect(setbackVertices, insideLoop)) {
                    filteredOutsideVerts.push(setbackVertices);
                }
            });
            this.cellRows.forEach((row) => {
                const finalRow = [];
                finalRows.push(finalRow);
                row.forEach((cellVertices) => {
                    const finalCell = {
                        vertices: cellVertices,
                        isFullCell: true,
                        subCells: [],
                    };
                    filteredOutsideVerts.forEach((setbackVertices) => {
                        this.handleOutsideIntersection(
                            setbackVertices,
                            cellVertices,
                            finalCell
                        );
                    });
                    this.handleInsideIntersection(
                        insideLoop,
                        cellVertices,
                        finalCell
                    );
                    if (cellVertices.length > 0) {
                        finalRow.push(finalCell);
                    }
                });
            });
        });


        this.clearInstancedMesh();
        this.gridCellRows = [];
        this.gridCellRows = finalRows.map((row) => {
            return row.map((primitiveCell) => {
                const gridCell = new GridCell(this.stage, this);
                gridCell.autoAssignTiles(primitiveCell);
                return gridCell;
            });
        });

        // merge adjacent custom tiles
        this.gridCellRows.forEach((row) => {
            this.mergeAdjacentCustomTiles(row);
        });

        // remove empty grid cells
        this.gridCellRows = this.gridCellRows.map((row) => {
            return row.filter((gridCell) => {
                return (
                    gridCell.tiles.length > 0 ||
                    gridCell.customTiles?.length > 0
                );
            });
        });

        // remove empty rows
        this.gridCellRows = this.gridCellRows.filter((row) => {
            return row.length > 0;
        });

        // check if the first row only consists of custom tiles
        // if so, remove the first row
        if (this.gridCellRows.length > 0) {
            const firstRow = this.gridCellRows[0];
            if (firstRow.every((gridCell) => gridCell.customTiles.length > 0)) {
                this.gridCellRows.shift();
            }
        }

        // handle gridcellrows being empty
        if (this.gridCellRows.length === 0) {
            return 0;
        }

        // convert first and last row to non pv tiles
        this.gridCellRows[0].forEach((gridCell) => {
            if (gridCell.isPV) {
                gridCell.convertToNonPVTile(gridCell.tiles[0], false);
            }
        });
        this.gridCellRows[this.gridCellRows.length - 1].forEach((gridCell) => {
            if (gridCell.isPV) {
                gridCell.convertToNonPVTile(gridCell.tiles[0], false);
            }
        });

        // remove partial tiles from the ends and convert them to custom tiles
        const customTileValidWidth = this.tileLength * 0.2;

        this.gridCellRows.forEach((row) => {
            if (row.length === 0) return;

            const [firstCell, lastCell] = [row[0], row[row.length - 1]];

            [firstCell, lastCell].forEach(cell => {
                const hasTile = Boolean(cell.tiles[0]);
                const hasCustomTiles = Boolean(cell.customTiles && cell.customTiles.length > 0);
                const isNotFull = hasTile && !cell.tiles[0].isFull;

                const customTileBelowTreshold = hasCustomTiles && cell.customTiles.every(customTile => {
                    return customTile.getHorizontalSpan() < customTileValidWidth;
                });

                if (hasTile && (!hasCustomTiles || customTileBelowTreshold) && isNotFull) {
                    if(cell.tiles[0].fractionIndex === 0) {
                        cell.convertToCustomTile(cell.tiles[0], false);
                    }
                }
            });
        });

        // for each row, check if there is a buffer of non pv tiles at the start and end before the first and last pv tile
        // if not convert the first and last pv tile to non pv tile
        this.gridCellRows.forEach((row) => {
            // check if there is a buffer of non pv tile at the start
            const firstTileCell = row.find((gridCell) => {
                return gridCell.tiles.length > 0;
            });
            if (firstTileCell && firstTileCell.isPV) {
                firstTileCell.convertToNonPVTile(firstTileCell.tiles[0], false);
            }
            // check if there is a buffer of non pv tile at the end
            const lastTileCell = row.reverse().find((gridCell) => {
                return gridCell.tiles.length > 0;
            });
            if (lastTileCell && lastTileCell.isPV) {
                lastTileCell.convertToNonPVTile(lastTileCell.tiles[0], false);
            }
        });

        const limitPVCount = tileCount ? tileCount : false;
        const totalPV = this.gridCellRows.reduce((total, row) => {
            return (
                total +
                row.reduce((total, gridCell) => {
                    if (gridCell.isPV) {
                        return total + 1;
                    }
                    return total;
                }, 0)
            );
        }, 0);

        if (limitPVCount && totalPV > limitPVCount) {
            // convert the extra pv tiles to non pv tiles
            let pvTiles = totalPV - limitPVCount;
            this.gridCellRows.forEach((row) => {
                row.forEach((gridCell) => {
                    if (pvTiles === 0) {
                        return;
                    } else if (gridCell.isPV) {
                        gridCell.convertToNonPVTile(gridCell.tiles[0], false);
                        pvTiles--;
                    }
                });
                if (pvTiles === 0) {
                    return;
                }
            });
        }

        this.updateCustomTileMesh();

        this.updateTilesMesh();

        //adding tiles in quadtree
        for (let row of this.gridCellRows.flat()) {
            for (let tile of row.tiles) {
                const point = new Point(
                    tile.position.x,
                    tile.position.y,
                    tile.instanceIndex
                );
                this.selectionQuadtree.insert(point);
            }
        }
        // return number of pv tiles

        return this.pvTileCount;
    }

    updateCustomTiles() {
        // merge adjacent custom tiles
        this.gridCellRows.forEach((row) => {
            this.mergeAdjacentCustomTiles(row);
        });
        this.updateCustomTileMesh();
    }

    /**
     * Updates the custom tile mesh based on the grid cell rows.
     */
    updateCustomTileMesh() {
        // Clear old meshes
        this.customTilesGroup.children.forEach((child) => {
            child.geometry.dispose();
            child.material.dispose();
        });
        this.customTilesGroup.clear();

        const customTiles = this.gridCellRows
            .flat()
            .flatMap((gridCell) => {
                return gridCell.customTiles;
            })
            .filter(Boolean);

        if (customTiles.length === 0) {
            return;
        }

        const customTileShapes = customTiles.map((customTile) => {
            const shape = new THREE.Shape();
            shape.moveTo(customTile.vertices[0].x, customTile.vertices[0].y);
            for (let i = 1; i < customTile.vertices.length; i++) {
                shape.lineTo(
                    customTile.vertices[i].x,
                    customTile.vertices[i].y
                );
            }
            return shape;
        });
        // create a geometry for each custom tile
        const customTileGeometries = customTileShapes.map((shape) => {
            return new THREE.ShapeGeometry(shape);
        });
        // move the each tile geometry so that the top edge is aligned with x axis, rotate about the x axis by the relative tilt
        // and move the geometry back to the original position
        customTileGeometries.forEach((geometry, index) => {
            const customTile = customTiles[index];
            const yMax = customTile.vertices.reduce((max, v) => {
                return Math.max(max, v.y);
            }, -Infinity);
            geometry.translate(0, -yMax, 0);
            geometry.rotateX((-Math.PI * this.relativeTilt) / 180);
            geometry.translate(0, yMax, 0);
            geometry.computeVertexNormals();
        });
        // create a mesh for each custom tile
        const customTileMeshGeometries = customTileGeometries.map(
            (geometry) => {
                return new THREE.Mesh(
                    geometry,
                    this.getCustomTileMaterial().clone()
                ).geometry;
            }
        );

        // merge all geometries into one
        const mergedGeometry = BufferGeometryUtils.mergeGeometries(
            customTileMeshGeometries
        );

        // create a single mesh from the merged geometry
        this.customTileMergedMesh = new THREE.Mesh(
            mergedGeometry,
            this.getCustomTileMaterial().clone()
        );

        // create edges for each custom tile
        const customTileEdges = customTileGeometries.map((geometry) => {
            return new THREE.EdgesGeometry(geometry);
        });

        // merge all edge geometries into one
        const mergedEdgeGeometry =
            BufferGeometryUtils.mergeGeometries(customTileEdges);

        // create a single line from the merged edge geometry
        this.customTileMergedLine = new THREE.LineSegments(
            mergedEdgeGeometry,
            new THREE.LineBasicMaterial({ color: 0xffffff })
        );

        // add the merged mesh to the custom tiles group
        this.customTilesGroup.add(this.customTileMergedMesh);

        // add the merged line to the custom tiles group
        this.customTilesGroup.add(this.customTileMergedLine);
        // add the custom tile meshes to the custom tiles group
        // customTiles.forEach((tile, index) => {
        //     customTiles[index].code = `A${index.toString().padStart(4, "0")}`;
        // });
        // const lenghts = [];
        // customTiles.forEach((customTile) => {
        //     lenghts.push(customTile.getHorizontalSpan());
        // });

        // const threshold = 0.01; // Define your threshold here

        // // Sort the lengths array
        // const sortedLengths = [...lenghts].sort((a, b) => a - b);

        // const groups = [];
        // let currentGroup = [];

        // sortedLengths.forEach((length, i) => {
        //     if (
        //         currentGroup.length === 0 ||
        //         Math.abs(length - currentGroup[currentGroup.length - 1]) <=
        //             threshold
        //     ) {
        //         // If the current group is empty or the current length is within the threshold of the last length in the current group
        //         currentGroup.push(length);
        //     } else {
        //         // If not, add the current group to the groups array and start a new group with the current length
        //         groups.push(currentGroup);
        //         currentGroup = [length];
        //     }

        //     // If it's the last length, add the current group to the groups array
        //     if (i === sortedLengths.length - 1) {
        //         groups.push(currentGroup);
        //     }
        // });

        // // Map over the groups array to get the count for each group
        // const counts = groups.map((group) => ({
        //     length: group[0],
        //     count: group.length,
        // }));
        // this.counts = counts;

        // update counts
        this.customTileCount = customTiles.length;
        this.customTileArea = customTiles.reduce((area, customTile) => {
            return area + customTile.computeArea();
        }, 0);
    }

    switchCustomTileToAesthicView() {
        this.customTileMergedLine.visible = false;
        this.customTileMergedMesh.material = this.getCustomTileAestheticMaterial();
        this.customTileMergedMesh.material.needsUpdate = true;
    }

    switchCustomTileToDesignView() {
        this.customTileMergedLine.visible = true;
        this.customTileMergedMesh.material = this.getCustomTileMaterial();
        this.customTileMergedMesh.material.needsUpdate = true;
    }

    getCustomTileMaterial() {
        if (!this.customTileMaterial) {
            this.customTileMaterial = new THREE.MeshStandardMaterial({
                side: THREE.DoubleSide,
                color: 0x333333, // Dark grey color
            });
        }
        return this.customTileMaterial;
    }

    getCustomTileAestheticMaterial() {
        if (!this.customTileAestheticMaterial) {
            this.customTileAestheticMaterial = new THREE.MeshStandardMaterial({
                side: THREE.DoubleSide,
                color: 0x000000,
                transparent: false,
                roughness: 0.5,
                metalness: 1,
            });
        }
        return this.customTileAestheticMaterial;
    }
    mergeAdjacentCustomTiles(row) {
        // iterate over each grid cell and check if it has a custom tile
        // check the next tile to see if it has a custom tile on the same side
        // consider merging them if one of them is smaller than the cell width
        const cellWidth = this.getCellWidth();
        let currentCustomTile = null;
        for (let i = 0; i < row.length - 1; i++) {
            if (!row[i].customTiles) {
                continue;
            }
            currentCustomTile = row[i].customTiles[0];
            // check if the next cell has a custom tile
            if (row[i + 1].customTiles) {
                // check if the two custom tiles are adjacent by looking for a common vertex within a tolerance
                const nextCustomTile = row[i + 1].customTiles[0];
                // if the vertices are emtpy skip
                if (
                    currentCustomTile.vertices.length === 0 ||
                    nextCustomTile.vertices.length === 0
                ) {
                    continue;
                }
                const commonVertex = currentCustomTile.vertices.find((v) => {
                    return nextCustomTile.vertices.find((v2) => {
                        return v.distanceTo(v2) < 0.001;
                    });
                });
                const currentTileFullWidth =
                    currentCustomTile.getVerticalSpan() >= cellWidth - 0.001;
                const nextTileFullWidth =
                    nextCustomTile.getVerticalSpan() >= cellWidth - 0.001;

                // only merge if one of the tiles is smaller than the cell width

                if (
                    commonVertex &&
                    (!currentTileFullWidth || !nextTileFullWidth)
                ) {
                    // merge the two custom tiles using union of polygons
                    const mergedVertices = utils.unionOfPolygons(
                        currentCustomTile.vertices,
                        nextCustomTile.vertices
                    )[0];
                    // draw the merged vertices
                    // this.drawPolygon(mergedVertices, 0x00ff00);
                    // remove the custom tile which is smaller than the cell width
                    if (currentTileFullWidth) {
                        row[i + 1].customTiles = null;
                        // update the custom tile vertices
                        currentCustomTile.updateVertices(mergedVertices);
                        // remove the custom tile
                        nextCustomTile.removeObject();
                    } else {
                        row[i].customTiles = null;
                        // update the custom tile vertices
                        nextCustomTile.updateVertices(mergedVertices);
                        // remove the custom tile
                        currentCustomTile.removeObject();
                    }
                }
            }
        }

        // draw all the custom tiles
        // row.forEach((gridCell) => {
        //     if(gridCell.customTiles) {
        //         gridCell.customTiles.forEach((customTile) => {
        //             this.drawPolygon(customTile.vertices, 0x00ff00);
        //         });
        //     }
        // });

        row.forEach((gridCell) => {
            if (gridCell.customTiles) {
                gridCell.customTiles = gridCell.customTiles.filter(
                    (customTile) => {
                        const isValidTile =
                            customTile !== undefined &&
                            customTile !== null &&
                            customTile.vertices.length > 0;
                        //  &&
                        // // customTile.getVerticalSpan() >
                        // //     this.getCellWidth()/3 &&
                        // // customTile.getHorizontalSpan() <
                        // //     this.getCellLength() - 0.01 &&
                        // customTile.isValid();

                        if (!isValidTile && customTile.removeObject) {
                            customTile.removeObject();
                        }
                        return isValidTile;
                    }
                );
            }
        });
    }

    addTileMesh(tile) {
        if (this.view === TILE_VIEWS.DC_VIEW && tile.dcColor) {
            this.gridMesh.setColorAt(this.gridMesh.count, tile.dcColor);
        } else {
            this.gridMesh.setColorAt(this.gridMesh.count, tile.defaultColor);
        }
        this.dummyObject.position.set(
            tile.position.x,
            tile.position.y - this.getYCompensation(),
            0
        );
        if (tile.visible) {
            this.dummyObject.scale.set(tile.length, tile.width, 1);
        } else {
            this.dummyObject.scale.set(0, 0, 0);
        }
        this.dummyObject.rotation.x = (-Math.PI * this.relativeTilt) / 180;
        // add y compensation to center the tile
        this.dummyObject.updateMatrix();
        this.gridMesh.setMatrixAt(this.gridMesh.count, this.dummyObject.matrix);
        this.gridMesh.instanceMatrix.needsUpdate = true;
        this.gridMesh.instanceColor.needsUpdate = true;
        this.gridMesh.computeBoundingBox();
        this.gridMesh.computeBoundingSphere();
        tile.instanceMesh = this.gridMesh;
        tile.instanceIndex = this.gridMesh.count;
        this.gridMesh.count += 1;
    }

    updateTileMesh(tile) {
        this.dummyObject.position.set(
            tile.position.x,
            tile.position.y - this.getYCompensation(),
            0
        );
        if (tile.visible) {
            this.dummyObject.scale.set(tile.length, tile.width, 1);
        } else {
            this.dummyObject.scale.set(0, 0, 0);
        }
        this.dummyObject.rotation.x = (-Math.PI * this.relativeTilt) / 180;
        // add y compensation to center the tile
        this.dummyObject.updateMatrix();
        this.gridMesh.setMatrixAt(tile.instanceIndex, this.dummyObject.matrix);
        this.gridMesh.instanceMatrix.needsUpdate = true;
        this.gridMesh.instanceColor.needsUpdate = true;
        this.gridMesh.computeBoundingBox();
        this.gridMesh.computeBoundingSphere();
    }

    hideTileMesh(tile) {
        // scale to 0
        const mat4 = new THREE.Matrix4();
        tile.instanceMesh.getMatrixAt(tile.instanceIndex, mat4);
        mat4.decompose(
            this.dummyObject.position,
            this.dummyObject.quaternion,
            this.dummyObject.scale
        );
        this.dummyObject.scale.set(0, 0, 0);
        this.dummyObject.updateMatrix();
        tile.instanceMesh.setMatrixAt(
            tile.instanceIndex,
            this.dummyObject.matrix
        );
        tile.instanceMesh.instanceMatrix.needsUpdate = true;
    }

    showTileMesh(tile) {
        tile.visible = true;
        // scale to 1
        const mat4 = new THREE.Matrix4();
        tile.instanceMesh.getMatrixAt(tile.instanceIndex, mat4);
        mat4.decompose(
            this.dummyObject.position,
            this.dummyObject.quaternion,
            this.dummyObject.scale
        );
        this.dummyObject.scale.set(tile.length, tile.width, 1);
        this.dummyObject.updateMatrix();
        tile.instanceMesh.setMatrixAt(
            tile.instanceIndex,
            this.dummyObject.matrix
        );
        tile.instanceMesh.instanceMatrix.needsUpdate = true;
    }

    clearInstancedMesh() {
        this.pvTileCount = 0;
        this.nonPVTileCount = {
            full: 0,
            half: 0,
            quarter: 0,
        };
        this.gridMesh.count = 0;
        this.gridMesh.instanceMatrix.needsUpdate = true;
        this.gridMesh.instanceColor.needsUpdate = true;
    }

    getNumberOfTiles() {
        const numberOfTiles = {
            pv: this.pvTileCount,
            nonPV: this.nonPVTileCount,
            custom: this.customTileCount,
            customArea: this.customTileArea,
        };
        return numberOfTiles;
    }

    setObjectGroupsMatrix() {
        this.customTilesGroup.applyMatrix4(this.matrix);
        // TODO: remove the hardcoded value
        this.customTilesGroup.position.z += this.getLift()*3/4;
        this.dcObjectsGroup.applyMatrix4(this.matrix);
        this.dcObjectsGroup.position.z += this.getLift() + 0.1;
        this.acObjectsGroup.applyMatrix4(this.matrix);
        this.acObjectsGroup.position.z += this.getLift() + 0.1;
    }

    initGridInstancedMesh() {
        // create a geometry for the grid cells
        const geometry = new THREE.BoxGeometry(1, 1, 0.05);
        geometry.computeVertexNormals();
        this.shaderMaterial = this.getCustomShaderMaterial();

        // create an instanced mesh for the grid cells
        this.gridMesh = new THREE.InstancedMesh(
            geometry,
            this.shaderMaterial,
            10000
        );
        this.gridMesh.instanceMatrix.setUsage(THREE.DynamicDrawUsage);
        this.gridMesh.instanceColor = new THREE.InstancedBufferAttribute(
            new Float32Array(10000 * 3),
            3,
            1
        );
        this.gridMesh.receiveShadow = true;
        this.gridMesh.container = this;
        this.gridMesh.selectable = true;
        this.gridMesh.frustumCulled = false;
        this.gridMesh.count = 0;
        this.gridMesh.applyMatrix4(this.matrix);
        this.gridMesh.position.z += this.getLift();
        this.objectsGroup.add(this.gridMesh);
        this.dummyObject = new THREE.Object3D();
        this.yCompensation =
            (this.tileWidth * Math.cos((Math.PI * this.relativeTilt) / 180) -
                this.getCellWidth()) /
            2;
    }

    saveObject() {
        const tilesGridData = {
            type: TilesGrid.getObjectType(),
        };
        tilesGridData.id = this.id;
        tilesGridData.smartroofFaceId = this.smartroofFace.id;
        tilesGridData.smartRoofId = this.smartroofFace.parent.id;
        tilesGridData.tileLength = this.tileLength;
        tilesGridData.tileWidth = this.tileWidth;
        tilesGridData.tileOverlap = this.tileOverlap;
        tilesGridData.sideSpacing = this.sideSpacing;
        tilesGridData.height = this.height;
        tilesGridData.relativeTilt = this.relativeTilt;
        tilesGridData.eaveOffset = this.eaveOffset;
        tilesGridData.columnOffset = this.columnOffset;
        tilesGridData.partialTileFractions = this.partialTileFractions;
        tilesGridData.pvEnabled = this.pvEnabled;
        tilesGridData.gridCellRows =
            this.gridCellRows?.map((row) => {
                return row.map((gridCell) => {
                    return gridCell.saveObject();
                });
            }) ?? [];
        tilesGridData.pvTileGroups = this.pvTileGroups?.map((group) => {
            const tileIds = group.tiles.map((tile) => tile.id);
            const cabling = group.cabling.map((vertexPair) => {
                return vertexPair.map((vertex) => [
                    vertex.x,
                    vertex.y,
                    vertex.z,
                ]);
            });
            const inverterPlacementTileId =
                group.inverterInstance.placementTile.id;
            const inverterProperties = {
                placementTileId: inverterPlacementTileId,
                color: group.inverterInstance.color?.getHex(),
                text: group.inverterInstance.text,
            };
            return {
                tileIds,
                penalty: group.penalty,
                cabling,
                inverterProperties,
            };
        });
        tilesGridData.acStrings = this.acStrings?.map((acString) => {
            return {
                id: acString.id,
                dropPenalty: acString.dropPenalty,
                cableLength: acString.cableLength,
                additionalPaths: acString.additionalPaths.map((path) => {
                    return path.map((node)=> {
                        return node.id;                    
                    })
                }),
                additionalStrings: acString.additionalStrings.map((string) => {
                    return string.id;
                }),
                endPathNode: acString.endPathNode.id,
                startPathNode: acString.startPathNode.id,
                microInverters: acString.microInverters.map((inverter) => {
                    return inverter.placementTile.id;
                }),
                segments: acString.segments.map((segment) => {
                    return {
                        segmentPath: segment.segmentPath.map((vertex) => {
                            return [vertex.x, vertex.y, vertex.z];
                        }),
                        distance: segment.distance,
                        dropPenalty: segment.dropPenalty,
                        micro1PlacementTileId: segment.micro1.placementTile.id,
                        micro2PlacementTileId: segment.micro2.placementTile.id,
                    }
                }),
            }
        });
        return tilesGridData;
    }

    loadObject(tilesGridData) {
        this.id = tilesGridData.id ?? this.id;
        this.tileLength = tilesGridData.tileLength;
        this.tileWidth = tilesGridData.tileWidth;
        this.tileOverlap = tilesGridData.tileOverlap;
        this.sideSpacing = tilesGridData.sideSpacing;
        this.height = tilesGridData.height;
        this.relativeTilt = tilesGridData.relativeTilt;
        this.eaveOffset = tilesGridData.eaveOffset;
        this.columnOffset = tilesGridData.columnOffset;
        this.partialTileFractions = tilesGridData.partialTileFractions;
        this.pvEnabled = tilesGridData.pvEnabled;
        this.tilesById = {};
        this.gridCellRows =
            tilesGridData.gridCellRows?.map((row) =>
                row.map((gridCellData) => {
                    const gridCell = new GridCell(this.stage, this);
                    gridCell.loadObject(gridCellData);
                    // Add each tile to the map
                    gridCell.tiles.forEach((tile) => {
                        if (tile.id) {
                            this.tilesById[tile.id] = tile;
                        }
                    });
                    return gridCell;
                })
            ) ?? [];
        this.updateTilesMesh();

        // clear the quadtree
        this.selectionQuadtree.clear();

        //adding tiles in quadtree
        for (let row of this.gridCellRows.flat()) {
            for (let tile of row.tiles) {
                const point = new Point(
                    tile.position.x,
                    tile.position.y,
                    tile.instanceIndex
                );
                this.selectionQuadtree.insert(point);
            }
        }
        this.updateCustomTileMesh();
        if (tilesGridData.pvTileGroups) {
            this.pvTileGroups = tilesGridData.pvTileGroups.map((groupData) => {
                const groupTiles = groupData.tileIds.map((tileId) => {
                    return this.getTileById(tileId);
                });
                const group = {
                    tiles: groupTiles,
                    penalty: groupData.penalty,
                    cabling: groupData.cabling.map((vertexPair) => {
                        return vertexPair.map((vertex) => {
                            return new THREE.Vector3(
                                vertex[0],
                                vertex[1],
                                vertex[2]
                            );
                        });
                    }),
                };
                const dcColor = getPenaltyColor(
                    getNormalizedPenalty(groupData.penalty)
                );
                group.tiles.forEach((tile) => {
                    tile.setDCViewColor({ dcColor, apply: false });
                });
                const inverterInstance = new InverterInstance(
                    group,
                    groupTiles.find(
                        (tile) => tile.id === groupData.inverterProperties.placementTileId
                    )
                );
                inverterInstance.setColor(
                    new THREE.Color(groupData.inverterProperties.color)
                );
                inverterInstance.updateText(groupData.inverterProperties.text);
                group.inverterInstance = inverterInstance;
                return group;
            });
            this.drawDCCables();
        }
        this.switchToNormalView();
    }

    loadAC(tilesGridData) {
        this.acStrings = [];
        if (!tilesGridData.acStrings) {
            this.drawACCables();
            return
        }
        this.acStrings = tilesGridData.acStrings.map((acStringData) => {
            const acString = new PVString();
            acString.id = acStringData.id;
            acString.dropPenalty = acStringData.dropPenalty;
            acString.cableLength = acStringData.cableLength;
            acString.additionalPaths = acStringData.additionalPaths.map((path) => {
                return path.map((nodeId) => {
                    return this.getNodeById(nodeId);
                });
            });
            acString.additionalStrings = [];
            acString.endPathNode = this.getNodeById(acStringData.endPathNode);
            acString.startPathNode = this.getNodeById(acStringData.startPathNode);
            acString.microInverters = acStringData.microInverters.map((inverterPlacementTileId) => {
                return this.getInverterByPlacementTileId(inverterPlacementTileId);
            });
            acString.segments = acStringData.segments.map((segmentData) => {
                const segment = {};
                segment.segmentPath = segmentData.segmentPath.map((vertex) => {
                    return new THREE.Vector3(vertex[0], vertex[1], vertex[2]);
                });
                segment.distance = segmentData.distance;
                segment.dropPenalty = segmentData.dropPenalty;
                segment.micro1 = this.getInverterByPlacementTileId(segmentData.micro1PlacementTileId);
                segment.micro2 = this.getInverterByPlacementTileId(segmentData.micro2PlacementTileId);
                return segment;
            });
            acString.grid = this;
            return acString;
        });
        // updateEndNodes(this.acStrings);
        this.drawACCables();
        return this.acStrings;
    }

    getTileById(tileId) {
        return this.tilesById[tileId];
    }

    getNodeById(nodeId) {
        return this.getPowerRoof().getNodeById(nodeId);
    }

    getInverterByPlacementTileId(placementTileId) {
        const inverter = this.getPowerRoof().inverter.getInverterByPlacementTileId(placementTileId);
        return inverter;
    }

    updateTilesMesh() {
        this.clearInstancedMesh();
        const allGridCells = this.gridCellRows.flat();
        const allTiles = allGridCells.map((gridCell) => gridCell.tiles).flat();

        allTiles.forEach((tile) => {
            if (tile instanceof PVTile) {
                this.pvTileCount += 1;
            } else {
                if(tile.isFull) {
                    this.nonPVTileCount.full += 1;
                }
                else if(tile.fractionIndex === 0) {
                    this.nonPVTileCount.half += 1;
                }
                else if(tile.fractionIndex === 1) {
                    this.nonPVTileCount.quarter += 1;
                }
            }
            this.addTileMesh(tile);
        });
    }

    findClosestNonPVTile(globalPosition) {
        const localPosition = this.toLocal(globalPosition);
        // const nonPV
        const nonPVGridCells = this.getNonPVTiles();
        let minDistance = Infinity;
        let closestTile = null;
        nonPVGridCells.forEach((gridCell) => {
            gridCell.tiles
                .filter((t) => !t.isJunction)
                .forEach((tile) => {
                    const distance = tile.position.distanceTo(localPosition);
                    if (distance < minDistance) {
                        minDistance = distance;
                        closestTile = tile;
                    }
                });
        });
        return closestTile;
    }

    getNonPVTiles() {
        // get the flat array of all non pv grid cells
        const nonPVGridCells = this.gridCellRows.flat().filter((gridCell) => {
            return !gridCell.isPV && gridCell.tiles.length > 0;
        });
        return nonPVGridCells;
    }

    removeObject() {
        this.gridMesh.geometry.dispose();
        this.gridMesh.material.dispose();
        this.objectsGroup.clear();
        this.objectsGroup.removeFromParent();
        this.gridMesh = null;
        this.objectsGroup = null;
    }

    static getObjectType() {
        return "Tiles Grid";
    }

    async switchToAesthicMaterial() {
        this.aestheticMaterial = this.aestheticMaterial ? this.aestheticMaterial : await utils.getAestheticMaterial();
        this.gridMesh.material = this.aestheticMaterial;
        this.gridMesh.material.needsUpdate = true;
        this.switchCustomTileToAesthicView();
    }

    switchToShaderMaterial() {
        this.gridMesh.material = this.shaderMaterial;
        this.gridMesh.material.needsUpdate = true;
    }

    getCustomShaderMaterial() {
        return this.getPowerRoof().getCustomShaderMaterial();
    }

    createHelpers() {
        if (this.debugGroup) {
            this.debugGroup.clear();
            this.debugGroup.removeFromParent();
        }

        this.debugGroup = new THREE.Group();
        this.stage.scene.add(this.debugGroup);

        const xAxisArrow = new THREE.ArrowHelper(
            this.xAxis,
            this.origin,
            5,
            0xff0000
        );
        const yAxisArrow = new THREE.ArrowHelper(
            this.yAxis,
            this.origin,
            5,
            0x00ff00
        );
        const zAxisArrow = new THREE.ArrowHelper(
            this.zAxis,
            this.origin,
            5,
            0x0000ff
        );
        this.debugGroup.add(xAxisArrow);
        this.debugGroup.add(yAxisArrow);
        this.debugGroup.add(zAxisArrow);
    }

    drawPolygon(vertices, colorValue = 0xff0000 * Math.random()) {
        const color = new THREE.Color(colorValue);
        const material = new THREE.LineBasicMaterial({ color });
        const geometry = new THREE.BufferGeometry().setFromPoints(
            vertices.map((v) => this.toGlobal(v))
        );
        const line = new THREE.LineLoop(geometry, material);
        line.position.z += 0.01;
        this.objectsGroup.add(line);
    }

    drawVertex(vertex, colorValue = 0xff0000 * Math.random()) {
        const geometry = new THREE.SphereGeometry(0.01, 32, 32);
        const material = new THREE.MeshBasicMaterial({ color: colorValue });
        const sphere = new THREE.Mesh(geometry, material);
        sphere.position.copy(this.toGlobal(vertex));
        this.objectsGroup.add(sphere);
    }

    getState() {
        const tilesGridData = {
            type: TilesGrid.getObjectType(),
        };
        tilesGridData.smartroofFaceId = this.smartroofFace.id;
        tilesGridData.smartRoofId = this.smartroofFace.parent.id;
        tilesGridData.tileLength = this.tileLength;
        tilesGridData.tileWidth = this.tileWidth;
        tilesGridData.tileOverlap = this.tileOverlap;
        tilesGridData.sideSpacing = this.sideSpacing;
        tilesGridData.height = this.height;
        tilesGridData.relativeTilt = this.relativeTilt;
        tilesGridData.eaveOffset = this.eaveOffset;
        tilesGridData.columnOffset = this.columnOffset;
        tilesGridData.partialTileFractions = this.partialTileFractions;
        tilesGridData.pvEnabled = this.pvEnabled;
        tilesGridData.gridCellRows =
            this.gridCellRows?.map((row) => {
                return row.map((gridCell) => {
                    return gridCell.saveObject();
                });
            }) ?? [];
        return tilesGridData;
    }

    loadState(tilesGridData) {
        this.tileLength = tilesGridData.tileLength;
        this.tileWidth = tilesGridData.tileWidth;
        this.tileOverlap = tilesGridData.tileOverlap;
        this.sideSpacing = tilesGridData.sideSpacing;
        this.height = tilesGridData.height;
        this.relativeTilt = tilesGridData.relativeTilt;
        this.eaveOffset = tilesGridData.eaveOffset;
        this.columnOffset = tilesGridData.columnOffset;
        this.partialTileFractions = tilesGridData.partialTileFractions;
        this.pvEnabled = tilesGridData.pvEnabled;
        this.gridCellRows =
            tilesGridData.gridCellRows?.map((row) =>
                row.map((gridCellData) => {
                    const gridCell = new GridCell(this.stage, this);
                    gridCell.loadObject(gridCellData);
                    return gridCell;
                })
            ) ?? [];
        this.updateTilesMesh();
    }

    clearState() {}

    makeNonPVTilesGrey() {
        const grey = new THREE.Color(0x808080);
        this.gridCellRows.forEach((row) => {
            row.forEach((gridCell) => {
                gridCell.tiles.forEach((tile) => {
                    if (tile instanceof NonPVTile) {
                        tile.setDCViewColor(grey);
                    }
                });
            });
        });
    }

    makeAllTilesGrey() {
        const grey = new THREE.Color(0xb0c4de);
        this.gridCellRows.forEach((row) => {
            row.forEach((gridCell) => {
                gridCell.tiles.forEach((tile) => {
                    tile.setAestheticColor(grey);
                });
            });
        });
    }

    switchToNormalView() {
        this.view = TILE_VIEWS.DESIGN_VIEW;
        if (this.dcObjectsGroup) {
            this.dcObjectsGroup.visible = false;
        }

        this.gridCellRows?.forEach((row) => {
            row.forEach((gridCell) => {
                gridCell?.tiles?.forEach((tile) => {
                    tile?.setDefaultColor();
                });
            });
        });
    }

    /**
     * Deselects the tile and sets to default color.
     */
    deSelect() {
        console.log("deselecting");
    }

    /**
     * Shows the tile by setting its highlight color.
     */
    showObject() {
        console.log("showing");
    }

    /**
     * Selects the tile and highlights it.
     */
    onSelect() {
        console.log("selecting");
    }

    showTiles() {
        this.gridMesh.visible = true;
        this.customTilesGroup.visible = true;
    }

    hideTiles() {
        this.gridMesh.visible = false;
        this.customTilesGroup.visible = false;
    }

    switchToDCView() {
        this.view = TILE_VIEWS.DC_VIEW;
        this.dcObjectsGroup.visible = true;

        this.gridCellRows.forEach((row) => {
            row.forEach((gridCell) => {
                gridCell.tiles.forEach((tile) => {
                    tile.setDCViewColor();
                });
            });
        });
    }

    hideDCView() {
        this.view = TILE_VIEWS.DESIGN_VIEW;
        this.dcObjectsGroup.visible = false;

        this.gridCellRows.forEach((row) => {
            row.forEach((gridCell) => {
                gridCell.tiles.forEach((tile) => {
                    tile.setDefaultColor();
                });
            });
        });
    }

    switchToACView() {
        this.acObjectsGroup.visible = true;
    }

    hideACView() {
        this.acObjectsGroup.visible = false;
    }

    async switchToAestheticView() {
        this.makeAllTilesGrey();
        await this.switchToAesthicMaterial();
    }

    switchOffAestheticView() {
        this.switchToShaderMaterial();
        this.switchCustomTileToDesignView();
        if(this.view===TILE_VIEWS.DC_VIEW) this.switchToDCView();
        else this.switchToNormalView();
    }

    // Bounding box function similar to subarray
    // For panelMap debegging purposes
    bigBox(reset = false) {
        // order of vertices
        // top-left
        // bottom-left
        // bottom-right
        // top-right
        if (this.boundingBox === undefined || reset) {
            const facePoints = this.smartroofFace.getVector3DVertices();
            const coreShape = new THREE.Shape(facePoints);
            const geometry = new THREE.ShapeGeometry(coreShape);
            const { count } = geometry.attributes.position;
            for (let i = 0; i < count.length; i++) {
                const X = geometry.attributes.position.getX(i);
                const Y = geometry.attributes.position.getY(i);
                const Z = this.smartroofFace.getZOnTopSurface(X, Y);

                geometry.attributes.position.setZ(i, Z);
            }

            geometry.computeBoundingSphere();
            const subarraySphere = geometry.boundingSphere;
            subarraySphere.center.z = this.smartroofFace.getZOnTopSurface(
                subarraySphere.center.x,
                subarraySphere.center.y
            );
            // 2D directions
            let bBoxDirectionUp = new THREE.Vector3().setFromSphericalCoords(
                1,
                90 * (Math.PI / 180),
                -this.smartroofFace.azimuth * (Math.PI / 180)
            ); //  tilt's zero is from the base and azimuth is clockwise (in scene)
            // rotation requied because in 2d-View the Y-azis is upwards not outwards
            bBoxDirectionUp = utils.posResetFor2D(bBoxDirectionUp);

            let bBoxDirectionLeft = new THREE.Vector3().setFromSphericalCoords(
                1,
                90 * (Math.PI / 180),
                (-this.smartroofFace.azimuth + 90) * (Math.PI / 180)
            );
            // rotation requied because in 2d-View the Y-azis is upwards not outwards
            bBoxDirectionLeft = utils.posResetFor2D(bBoxDirectionLeft);

            // finding 3D directions using the slope of parent
            const pointUp = subarraySphere.center
                .clone()
                .addScaledVector(bBoxDirectionUp, 1);
            const pointLeft = subarraySphere.center
                .clone()
                .addScaledVector(bBoxDirectionLeft, 1);

            // getting the z-coordinates of the points along the slope of the parent polygon
            pointUp.z = this.smartroofFace.getZOnTopSurface(
                pointUp.x,
                pointUp.y
            );
            pointLeft.z = this.smartroofFace.getZOnTopSurface(
                pointLeft.x,
                pointLeft.y
            );

            // now these direction are along the slope of the parent in 3D
            bBoxDirectionUp = pointUp.sub(subarraySphere.center);
            bBoxDirectionLeft = pointLeft.sub(subarraySphere.center);

            bBoxDirectionUp.normalize();
            bBoxDirectionLeft.normalize();

            // this is also normal of the parent surface
            const bBoxNormal = new THREE.Vector3();
            bBoxNormal.crossVectors(bBoxDirectionUp, bBoxDirectionLeft);
            bBoxNormal.normalize();

            const vertices = [];
            const diagonalVector = bBoxDirectionLeft
                .clone()
                .multiplyScalar(subarraySphere.radius * Math.SQRT2);
            const verticesOrderAngle = [-45, 45, 135, -135];

            verticesOrderAngle.forEach((angle) => {
                vertices.push(
                    subarraySphere.center
                        .clone()
                        .add(
                            diagonalVector
                                .clone()
                                .applyAxisAngle(
                                    bBoxNormal,
                                    utils.deg2Rad(angle)
                                )
                        )
                );
            });
            this.boundingBox = vertices;
        }
        return this.boundingBox;
    }

    switchVisualState(newVisualState, recursive) {
        if(newVisualState === VISUAL_STATES.DEFAULT_STATES.SOLAR_ACCESS) {
            this.previousView = this.view;
            this.view = TILE_VIEWS.SOLAR_ACCESS;
        }
        else {
            this.view = this.previousView;
        }

        if (recursive) {
            // TODO: Maybe add aesthetic view?
            if (this.view === TILE_VIEWS.SOLAR_ACCESS) {
                this.gridCellRows?.forEach((row) => {
                    row.forEach((gridCell) => {
                        gridCell?.tiles?.forEach((tile) => {
                            tile?.showSolarAccess();
                        });
                    });
                });
            }
            else if (this.view === TILE_VIEWS.AC_VIEW) {
                this.switchToACView();
            }
            else if (this.view === TILE_VIEWS.DC_VIEW) {
                this.switchToDCView();
            }
            else if (this.view === TILE_VIEWS.DESIGN_VIEW) {
                this.switchToNormalView();
            }
            else {
                console.warn('Incorrect View: ', this.view);
            }
        }
    }

    // Compatibility export interface for generation
    getPanelMap() {
        const PVTiles = [];
        this.gridCellRows.forEach((row) => {
            const tileRow = [];
            row.forEach((gridCell) => {
                if (gridCell.isPV) tileRow.push(gridCell.tiles[0]);
            });
            if (tileRow.length) PVTiles.push(tileRow);
        });
        const tileProperties = this.getPowerRoof().getTileProperties();
        const subarrayMap = {
            uuid: this.getUUID(),
            id: this.id,
            name: this.name,
            // rowSpacing is irrelevant for generation calculation
            // as long as it is not bifacial
            rowSpacing: -this.tileOverlap,
            tilt: parseFloat(this.smartroofFace.getTilt() - this.relativeTilt),
            structureType: "Default Fixed Tilt",
            azimuth: this.smartroofFace.azimuth,
            // TODO: Populate length and width with moduleProperties
            moduleLength: this.tileLength,
            moduleWidth: this.tileWidth,
            landscape: true,
            mountHeight: this.height,
            frameSizeUp: 1,
            frameSizeWide: 1,
            // TODO: Populate frameSpacing
            frameSpacing: 0,
            // Module spacing doesn't contribute to anything when it is a 1x1
            moduleSpacingUp: 0,
            moduleSpacingWide: 0,
            associatedObstacle: this.smartroofFace
                ? this.smartroofFace.id
                : null,
            surfaceTilt: this.smartroofFace.getTilt(),
            // TODO: Populate this.moduleProperties
            moduleProperties: {
                moduleId: tileProperties.id,
                moduleLength: tileProperties.characteristics.length,
                moduleWidth: tileProperties.characteristics.width,
                moduleMake: tileProperties.characteristics.manufacturer,
                moduleSize: tileProperties.characteristics.p_mp_ref,
            },

            // TODO: Populate this.panelProperties
            panelProperties: tileProperties,
            rows: [],
            // TODO: Populate this.bifacialEnabled
            bifacialEnabled: false,
            panelCount: PVTiles.reduce((count, row) => count + row.length, 0),
        };

        let rowId = 0;
        for (let row of PVTiles) {
            const startTile = row[0];
            const endTile = row[row.length - 1];
            const minX = startTile.position.x - startTile.length / 2;
            const minY = startTile.position.y - startTile.width / 2;
            const maxX = endTile.position.x + endTile.length / 2;
            const maxY = endTile.position.y + endTile.width / 2;

            // // Sanity check
            // if (minX > maxX) console.warn("minX > maxX");
            // if (minY > maxY) console.warn("minY > maxY");
            {
                let prevTile;
                row.forEach((tile) => {
                    if (prevTile && tile.position.x < prevTile.position.x) {
                        // console.warn("tiles not in x-order");
                        return;
                    }
                });
            }

            const rowMap = {
                id: rowId,
                localBBox: {
                    minX,
                    maxX,
                    minY,
                    maxY,
                },
                frames: [],
            };

            let tableId = 0;
            for (const table of row) {
                const frame = table.getTableMap();
                frame.id = tableId;
                rowMap.frames.push(frame);
                tableId++;
            }
            subarrayMap.rows.push(rowMap);
            rowId++;
        }
        return subarrayMap;
    }

    getPanelId() {
        this.PANEL_MODEL_ID++;
        return this.PANEL_MODEL_ID;
    }

    updateSolarAccess(solarAccessMap) {
        this.gridCellRows.forEach((row) => {
            row.forEach((gridCell) => {
                if (gridCell.isPV) {
                    const tile = gridCell.tiles[0];
                    tile.updateSolarAccess(solarAccessMap);
                }
            });
        });
    }

    createQuadtreeForSelection() {
        const cellWidth = this.getCellWidth();
        const cellLength = this.getCellLength();
        const localFaceVertices = this.smartroofFace.vertices.map((v) => {
            const localV = this.toLocal(v);
            localV.z = 0;
            return localV;
        });

        const localBBox = new THREE.Box2().setFromPoints(localFaceVertices);
        // add a buffer of x in both directions equal to the cell length
        // add a buffer of y in both directions equal to the cell width
        localBBox.min.x -= 2 * cellLength - this.columnOffset;
        localBBox.min.y -= 2 * cellWidth - this.eaveOffset;
        localBBox.max.x += 2 * cellLength;
        localBBox.max.y += 2 * cellWidth;

        this.selectionQuadtree = new QuadTree(
            new Box(
                localBBox.min.x,
                localBBox.min.y,
                localBBox.max.x - localBBox.min.x,
                localBBox.max.y - localBBox.min.y
            )
        );
    }
    getTilesForSelectionRectangle(polygonGeometry, verticesForLasso = []) {
        let polygonVerts = [];
        if (verticesForLasso.length) {
            for (let i = 0; i < verticesForLasso.length - 1; i += 1) {
                const tempVert = verticesForLasso[i].clone();
                tempVert.z = this.smartroofFace.getZOnTopSurface(
                    tempVert.x,
                    tempVert.y
                );
                const localPoint = this.toLocal(tempVert);
                polygonVerts.push(localPoint);
            }
        } else {
            let bufferArray = polygonGeometry.attributes.position.clone();
            for (let i = 0; i < bufferArray.count; i += 1) {
                const localPoint = this.toLocal(
                    new THREE.Vector3(
                        bufferArray.getX(i),
                        bufferArray.getY(i),
                        this.smartroofFace.getZOnTopSurface(
                            bufferArray.getX(i),
                            bufferArray.getY(i)
                        )
                    )
                );
                polygonVerts.push(localPoint);
            }
            const tempVert = polygonVerts[2];
            polygonVerts[2] = polygonVerts[3];
            polygonVerts[3] = tempVert;
        }
        const rectBoundingBox = new THREE.Box2().setFromPoints(polygonVerts);
        const queryBox = new Box(
            rectBoundingBox.min.x,
            rectBoundingBox.min.y,
            rectBoundingBox.max.x - rectBoundingBox.min.x,
            rectBoundingBox.max.y - rectBoundingBox.min.y
        );
        const potentialTiles = this.selectionQuadtree
            .query(queryBox)
            .map((tile) => {
                return this.getInstancedObjectFromId(tile.data);
            });
        const finalTiles = [];
        const polygonEdges = utils.getEdges(polygonVerts);
        potentialTiles.forEach((tile) => {
            if (!tile) return;
            let intersection = false;
            const tileEdges = tile.getLocalEdges();
            for (let polyEdge of polygonEdges) {
                for (let tileEdge of tileEdges) {
                    let check = utils.checkLineIntersection(tileEdge, polyEdge);
                    if (check.onLine1 && check.onLine2) {
                        intersection = true;
                        break;
                    }
                }
                if (intersection) break;
            }
            if (!intersection) {
                if (
                    utils.pointInPolygon(
                        tileEdges[0][0],
                        polygonVerts.map((v) => [v.x, v.y])
                    )
                ) {
                    finalTiles.push(tile);
                }
            }
        });
        return finalTiles;
    }

    async runDCAnnealing() {
        this.switchToDCView();
        const localSetbackVertices = this.smartroofFace.setbackVertices.map(
            (setbackShape) => {
                return setbackShape.map((vertex) => {
                    const localV = this.toLocal(vertex);
                    localV.z = 0;
                    return localV;
                });
            }
        );

        const faceLocalVertices = localSetbackVertices.filter(
            (setbackVerts) => !utils.checkClockwiseVectors(setbackVerts)
        );

        if (!this.pvEnabled) {
            return null;
        }

        const inverter = this.getPowerRoof().inverter;
        const inverterParameters = {
            minTiles: inverter.minTiles,
            maxTiles: inverter.maxTiles,
            maxInverterPerBranch: inverter.maxInverterPerBranch,
        }
        const annealer = new DCAnnealer({
            faceLocalVertices: faceLocalVertices,
            gridCellRows: this.gridCellRows,
            tileParameters: {
                tileLength: this.getCellLength(),
                tileWidth: this.getCellWidth(),
                pigTailLength: this.getPigTailLength(),
            },
            grid: this,
            inverterParameters: inverterParameters,
        });
        // add a delay to make the intial state visible
        await new Promise((resolve) => setTimeout(resolve, 20));
        this.pvTileGroups = (await annealer.run()) || [];

        return this.pvTileGroups;
    }

    getCablePositiveMaterial() {
        if (!this.cablePositiveMaterial) {
            this.cablePositiveMaterial = new THREE.LineBasicMaterial({
                color: 0x0000ff,
                linewidth: 1,
            });
        }
        return this.cablePositiveMaterial;
    }

    getCableNegativeMaterial() {
        if (!this.cableNegativeMaterial) {
            this.cableNegativeMaterial = new THREE.LineBasicMaterial({
                color: 0x000000,
                linewidth: 1,
            });
        }
        return this.cableNegativeMaterial;
    }

    colorGroups(state = this.groupedPV) {
        state.forEach((group) => {
            const penalty = group.calculateWeightedTotalPenalty();
            const color = getPenaltyColor(getNormalizedPenalty(penalty));
            group.tiles.forEach((tile) => {
                tile.setDCViewColor({ dcColor: color });
            });
        });
    }

    drawDCCables(state = this.pvTileGroups) {
        // dispose previous cables from objectsGroup
        this.dcObjectsGroup.children.forEach((child) => {
            child.geometry.dispose();
            child.material.dispose();
        });
        this.dcObjectsGroup.clear();

        if (state.length === 0) return;

        let negativeGeometries = [];
        let positiveGeometries = [];

        state.forEach((group) => {
            for (let i = 0; i < group.cabling.length - 1; i++) {
                const cable = group.cabling[i];
                for (let j = 0; j < cable.length - 1; j += 1) {
                    const points = [
                        new THREE.Vector3(cable[j].x, cable[j].y, cable[j].z),
                        new THREE.Vector3(
                            cable[j + 1].x,
                            cable[j + 1].y,
                            cable[j + 1].z
                        ),
                    ];
                    const cableGeometry =
                        new THREE.BufferGeometry().setFromPoints(points);
                    negativeGeometries.push(cableGeometry);
                }
            }
            const returnCable = group.cabling[group.cabling.length - 1];
            const yOffset = 0.1;
            const newSegments = [
                returnCable[0],
                new THREE.Vector2(returnCable[0].x, returnCable[0].y - yOffset),
                new THREE.Vector2(returnCable[1].x, returnCable[1].y - yOffset),
                returnCable[1],
            ];
            for (let k = 0; k < newSegments.length - 1; k += 1) {
                const points = [
                    new THREE.Vector3(newSegments[k].x, newSegments[k].y, 0),
                    new THREE.Vector3(
                        newSegments[k + 1].x,
                        newSegments[k + 1].y,
                        0
                    ),
                ];
                const cableGeometry = new THREE.BufferGeometry().setFromPoints(
                    points
                );
                positiveGeometries.push(cableGeometry);
            }
        });

        // Merge geometries and create lines
        const mergedNegativeGeometry =
            BufferGeometryUtils.mergeGeometries(negativeGeometries);
        const mergedPositiveGeometry =
            BufferGeometryUtils.mergeGeometries(positiveGeometries);

        const negativeLine = new THREE.LineSegments(
            mergedNegativeGeometry,
            this.getCableNegativeMaterial()
        );
        const positiveLine = new THREE.LineSegments(
            mergedPositiveGeometry,
            this.getCablePositiveMaterial()
        );

        this.dcObjectsGroup.add(negativeLine);
        this.dcObjectsGroup.add(positiveLine);
    }

    getDCCableLength(state = this.pvTileGroups) {
        let length = 0;
        state.forEach((group) => {
            group.cabling.forEach((cable) => {
                for (let i = 0; i < cable.length - 1; i += 1) {
                    const p1 = new THREE.Vector3(
                        cable[i].x,
                        cable[i].y,
                        cable[i].z
                    );
                    const p2 = new THREE.Vector3(
                        cable[i + 1].x,
                        cable[i + 1].y,
                        cable[i + 1].z
                    );
                    length += p1.distanceTo(p2);
                }
            });
        });
        return length;
    }

    drawACCables(state = this.acStrings) {
        // dispose previous cables from objectsGroup
        this.acObjectsGroup.children.forEach((child) => {
            child.geometry.dispose();
            child.material.dispose();
        });
        this.acObjectsGroup.clear();

        if (state.length === 0) return;

        const maxDropPenalty = Math.max(
            2,
            ...state.flatMap((string) =>
                string.segments.map((segment) => segment.dropPenalty)
            )
        );
        const minDropPenalty = Math.min(
            0,
            ...state.flatMap((string) =>
                string.segments.map((segment) => segment.dropPenalty)
            )
        );

        const minValue = minDropPenalty;
        const maxValue = maxDropPenalty;
        const minColor = new THREE.Color(0x00ff00);
        const maxColor = new THREE.Color(0xff0000);

        const getBoundedColor = (value) => {
            let boundedValue = value;
            if (boundedValue < minValue) {
                boundedValue = minValue;
            } else if (boundedValue > maxValue) {
                boundedValue = maxValue;
            }
            const i = (boundedValue - minValue) / (maxValue - minValue);
            const color = new THREE.Color().lerpColors(minColor, maxColor, i);
            return color;
        };

        const geometriesByColor = new Map();

        state.forEach((string) => {
            for (let i = 0; i < string.segments.length; i++) {
                const segment = string.segments[i];
                const segmentPathPoints = segment.segmentPath.map(
                    (point) => new THREE.Vector3(point.x, point.y, point.z)
                );

                // Create pairs of vertices for each segment
                for (let j = 0; j < segmentPathPoints.length - 1; j++) {
                    const segmentGeometry = new THREE.BufferGeometry();
                    segmentGeometry.setFromPoints([
                        segmentPathPoints[j],
                        segmentPathPoints[j + 1],
                    ]);

                    const cableColor = getBoundedColor(segment.dropPenalty);
                    const colorKey = cableColor.getHexString();

                    if (!geometriesByColor.has(colorKey)) {
                        geometriesByColor.set(colorKey, []);
                    }

                    geometriesByColor.get(colorKey).push(segmentGeometry);
                }
            }
        });

        geometriesByColor.forEach((geometries, colorKey) => {
            const mergedGeometry =
                BufferGeometryUtils.mergeGeometries(geometries);
            const cableMaterial = new THREE.LineBasicMaterial({
                color: new THREE.Color(`#${colorKey}`),
                linewidth: 1,
            });
            const cableLine = new THREE.LineSegments(
                mergedGeometry,
                cableMaterial
            );
            this.acObjectsGroup.add(cableLine);
        });
    }

    colorInverters(state = this.acStrings) {
        // for each string set the same color for all inverters
        // Choose a unique color for each string using the color
        // ignore the penalty for now
        const colors = [];
        const colorCount = state.length;
        const colorStep = 1 / colorCount;
        for (let i = 0; i < colorCount; i++) {
            const color = new THREE.Color();
            color.setHSL(i * colorStep, 1, 0.5);
            colors.push(color);
        }

        state.forEach((string, index) => {
            string.microInverters.forEach((micro, microIndex) => {
                micro.setColor(colors[index]);
                micro.updateText(`S${index + 1}-M${microIndex + 1}`);
            });
        });
    }

    convertMultiTilesToNonPV(tiles) {
        const uniqueGrids = [];
        tiles.forEach((tile) => {
            if (!uniqueGrids.includes(tile.gridCell.grid))
                uniqueGrids.push(tile.gridCell.grid);
            tile.gridCell.convertToNonPVTileWhileMultiSelect(tile);
        });
        uniqueGrids.forEach((grid) => {
            grid.updateTilesMesh();
        });
        this.resetElectricals();
        this.stage.selectionControls.setSelectedObject(this.stage.ground);
        this.saveState();
    }

    convertMultiTilesToPV(tiles) {
        const uniqueGrids = [];
        tiles.forEach((tile) => {
            if (!tile.isFull) return;
            if (!uniqueGrids.includes(tile.gridCell.grid))
                uniqueGrids.push(tile.gridCell.grid);
            tile.gridCell.convertToPVTileWhileMultiSelect(tile);
        });
        uniqueGrids.forEach((grid) => {
            grid.updateTilesMesh();
        });
        this.resetElectricals();
        this.stage.selectionControls.setSelectedObject(this.stage.ground);
        this.saveState();
    }

    resetElectricals() {
        this.getPowerRoof().resetElectricals();
    }

    async runACAnnealing() {
        this.switchToACView();
        if (!this.pvTileGroups || this.pvTileGroups.length === 0) return;
        const localSetbackVertices = this.smartroofFace.setbackVertices.map(
            (setbackShape) => {
                return setbackShape.map((vertex) => {
                    const localV = this.toLocal(vertex);
                    localV.z = 0;
                    return localV;
                });
            }
        );

        const faceLocalVertices = localSetbackVertices.filter(
            (setbackVerts) => !utils.checkClockwiseVectors(setbackVerts)
        );

        if (!this.pvEnabled || !this.pvTileGroups) {
            return null;
        }

        const inverter = this.getPowerRoof().inverter;
        const inverterParameters = {
            minTiles: inverter.minTiles,
            maxTiles: inverter.maxTiles,
            maxInverterPerBranch: inverter.maxInverterPerBranch,
        }

        const annealer = new ACAnnealer({
            faceLocalVertices: faceLocalVertices,
            pvTileGroups: this.pvTileGroups,
            balance: false,
            grid: this,
            inverterParameters: inverterParameters,
        });
        // add a delay to make the intial state visible
        await new Promise((resolve) => setTimeout(resolve, 20));
        this.acStrings = (await annealer.run()) || [];

        this.acStrings.forEach((pvString) => {
            pvString.grid = this;
        });
        updateEndNodes(this.acStrings);
        return this.acStrings;
    }

    reset({ forceAddTiles = false } = { forceAddTiles: false }) {
        try {
            this.resetDC();
            this.clearInstancedMesh();
            this.gridCellRows = [];
            this.pvTileGroups = [];
            if (this.isFilled || forceAddTiles) {
                this.addTiles();
            }
        } catch (err) {
            console.error(err);
        }
    }

    resetDC() {
        this.switchToNormalView();
        this.pvTileGroups = [];
        this.dcObjectsGroup.clear();
    }

    resetAC() {
        this.switchToNormalView();
        this.acStrings = [];
        this.acObjectsGroup.clear();
    }

    saveState() {
        this.getPowerRoof().saveState();
    }
}
