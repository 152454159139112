
<div 
  class="task-card" 
  id="task-card" 
  @click="selectRow"
  :style="{ border: selected, margin: taskIndex !== 0 ? '1rem 0rem' : 0, marginBottom: 0 }"
>
  <el-row class="wrapper" v-if="page == 'Leads'">
    <el-row class="wrapper-avatar">
      <el-col :span="4" class="avatar">
        <el-avatar :icon="task.name ? '' : 'el-icon-user-solid'" :style="{
          backgroundColor: task.color ? task.color : '#1c3366',
          color: 'white',
        }" :src="''">
          <!-- {{ task.name ? task.name.match(/\b(\w)/g).join("") : "" }} -->
          {{ task.name ? extractLetters(task.name) : "" }}
        </el-avatar>
      </el-col>

      <el-col :span="(task?.is_shared||task.lead_details?.workflow_type==='d2c_vip_external')?17:19" class="user-container">
        <p class="name-container" style="
            display: flex;
            justify-content: flex-start;
            align-items: center;
          ">
          <!-- <span
            ><p
              class="overfolw-ellipsis user-name"
              style="max-width: 7.5rem !important"
            >
              {{ task.name ? task.name : "NA" }}
            </p></span
          > -->
          <el-tooltip placement="top">
            <template v-slot:content>
              <div>
                <span>
                  <p class="lead-name" style="max-width: 7.5rem !important">
                    {{ task.name ? task.name : "NA" }}
                  </p>
                </span>
              </div>
            </template>
            <span>
              <p class="overfolw-ellipsis lead-name" style="max-width: 7.5rem !important">
                {{ task.name ? task.name : "NA" }}
              </p>
            </span>
          </el-tooltip>

          <span class="project-type"> {{ `(${task.project_type})` }}</span>
        </p>

        <p class="time">{{ task.created_at }}</p>
      </el-col>
      <el-col :span="2" v-if="task?.is_shared">
        <div>
      <img  style="border-radius: 0 !important;width:auto !important;height: auto !important;" src="../../../../assets/img/sharedIcon.svg" alt="">
    </div>  
    </el-col>
    <el-col :span="2" v-if="(!task?.is_shared&&task.lead_details?.workflow_type==='d2c_vip_external')">
        <div>
      <img  style="border-radius: 0 !important;width:auto !important;height: auto !important;" src="../../../../assets/img/bordered-building.svg" alt="">
    </div>  
    </el-col>
    </el-row>
    <div class="container">
      <el-row class="leadDetcolumn">
        <el-col :span="20" :class="{ 'disabled-content': !(task?.lead_details?.owner_id == ownerId)}"  @click.native.stop="handleReminder">
          <p class="remainder overfolw-ellipsis overfolw-texts">
            {{
              task.reminder_details
              ? dateTimeFormater(task.reminder_details.reminder_sent_at)
              : "Set Reminder"
            }}
          </p>
        </el-col>
      </el-row>
      <el-row class="leadDetcolumn">
        <el-col :span="10" class="body-content">
          <p class="phone">{{ task.phone }}</p>
        </el-col>
      </el-row>
      <el-row class="leadDetcolumn">
        <el-col :span="10" class="body-content">
          <p class="email">
            <span>
              <p class="overfolw-ellipsis overfolw-texts" style="font-size: 0.85rem">
                {{ task.email }}
              </p>
            </span>
          </p>
        </el-col>
      </el-row>
      <el-row class="leadDetcolumn" v-if="isSOMUsers() && this.task.d2c_products.length > 0 ">
        <el-col :span="12" class="body-content">
          <div class="d2c-products-container">
            <span v-for="(product, index) in displayedProducts" :key="index" class="d2c-product-pill">
              {{ product }}
            </span>
            <el-tooltip v-if="remainingCount > 0" placement="bottom" effect="dark">
              <template v-slot:content>
                <div>
                  <p
                    v-for="(product, index) in remainingProducts"
                    :key="index"
                  >
                    {{ product}}
                  </p>
                </div>
              </template>
              <span class="d2c-product-pill">
              +{{ remainingCount }}
              </span>
            </el-tooltip>
          </div>
        </el-col>
      </el-row>
          
    </div>
  </el-row>
  <el-row class="wrapper" v-else-if="page == 'Task'">
    <el-row class="wrapper-avatar">
      <el-col v-if="task.status == 'new'" @click.native.stop="handleComplete" :span="2" class="avatar">
        <img style="margin: 0; width:13px;height: 16px;" src="../assets/checkCircle.svg" alt="">
      </el-col>
      <el-col v-if="task.status == 'in_progress'" @click.native.stop="handleComplete" :span="2" class="avatar">
        <img style="margin: 0; width:13px;height: 16px;" src="../assets/in_progress.svg" alt="">
      </el-col>
      <el-col v-if="task.status == 'completed'" @click.native.stop="handleComplete" :span="2" class="avatar">
        <img style="margin: 0; width:13px;height: 16px;" src="../assets/completeCheckBox.svg" alt="">
      </el-col>

      <el-col :span="19" style="margin-bottom: 0px" class="user-container">
        <el-tooltip placement="top" :content="task.name">
          <div class="ellipsisDiv" style="max-height: 37px; ">
            <p style="font-size: 14px;text-overflow: ellipsis !important; margin: 0; color: #1C3366;white-space: nowrap; overflow: hidden;" ref="taskName">
              {{ task.name ? task.name : "NA" }}
            </p>
          </div>
        </el-tooltip>
      </el-col>

    </el-row>
    <div class="container">
      <el-row style="gap:0; padding-left: 0;" class="leadDetcolumn">
        <el-col :span="20" @click.native.stop="handleClick(task.lead_details.id)">
          <p class="personBadge overfolw-ellipsis overfolw-texts">
            {{
              task.lead_details.name
            }}
          </p>
        </el-col>
      </el-row>
      <el-row style="gap:0; padding-left: 0;" class="leadDetcolumn">
        
          <el-col v-if="isToday(task.due_by) && task.status !== 'completed'" :span="10"
            style="display:flex; justify-content: space-between; width:100% ;height: 15px;" class="body-content"
            @click.native.stop="emitUpdatedDate">
            <el-tooltip content="Due Date" placement="top" style="margin-right:100px">
            <el-date-picker v-model="task.due_by" class="dueCalender" type="date" unlink-panels
              popper-class="date-picker-popper" :format="'MMM dd, yyyy'" @change="emitUpdatedDate"  :picker-options="pickerOptions">
            </el-date-picker>
          </el-tooltip>
          <el-tooltip :content="task.nudgeTooltipText" placement="top" @click.native.stop="nudgeNotification(task)">
            <img style="width:15px; height: 13px;" src="../assets/Nudge Icon.svg">
          </el-tooltip>
          </el-col>
          <el-col v-else-if="isToday(task.due_by) && task.status == 'completed'" :span="10"
            style="display:flex; justify-content: space-between; width:100% ;height: 15px;" class="body-content"
            @click.native.stop="emitUpdatedDate">
            <el-tooltip content="Due Date" placement="top" style="margin-right:100px">
            <el-date-picker v-model="task.due_by" class="calenderComplete" type="date" unlink-panels  :picker-options="pickerOptions"
              popper-class="date-picker-popper" :format="'MMM dd, yyyy'" 
              @change="emitUpdatedDate">
            </el-date-picker>
          </el-tooltip>
          <el-tooltip :content="task.nudgeTooltipText" placement="top" @click.native.stop="nudgeNotification(task)">
            <img style="width:15px; height: 13px;" src="../assets/Nudge Icon2.svg">
          </el-tooltip>
          </el-col>
          <el-col v-else :span="10" style="display:flex; justify-content: space-between; width:100% ; height: 15px;"
            class="body-content" @click.native.stop="emitUpdatedDate">
            <el-tooltip content="Due Date" placement="top" style="margin-right:100px">
            <el-date-picker v-model="task.due_by" class="calender" type="date" unlink-panels
              popper-class="date-picker-popper" :format="'MMM dd, yyyy'"  @change="emitUpdatedDate"  :picker-options="pickerOptions">
            </el-date-picker>
          </el-tooltip>
          <el-tooltip :content="task.nudgeTooltipText" placement="top" @click.native.stop="nudgeNotification(task)">
            <img style="width:15px; height: 13px;" src="./assets/bell_icon.svg">
          </el-tooltip>
          </el-col>
      </el-row>
      <el-row style="gap:0; padding-left: 0;" class="leadDetcolumn">
        <el-col v-if="task.status !== 'completed'" :span="10" class="body-content" @click.native.stop="openMap(task.lead_details.address)">
          <el-tooltip
            content="Open in Google Maps"
            placement="top"
            :disabled="!task.lead_details.address"
          >
            <p class="location" >
              <span>
                <p class="overfolw-ellipsis overfolw-texts" style="font-size: 0.85rem">
                  {{ task.lead_details.address || "Unknown Address" }}
                </p>
              </span>
            </p>
          </el-tooltip>
        </el-col>
        <el-col v-else :span="10" class="body-content"  @click.native.stop="openMap(task.lead_details.address)">
          <p class="locationComplete">
            <span>
              <p class="overfolw-ellipsis overfolw-texts" style="font-size: 0.85rem">
                {{ task.lead_details.address }}
              </p>
            </span>
          </p>
        </el-col>
      </el-row>
      <el-row style="gap:0; padding-left: 0;" class="leadDetcolumn">
        <el-col :span="10" style="width:100%; display:flex; justify-content: space-between;" class="body-content">
          <div v-if="(admin == 'ADMIN' || manager == true ) " style="width:25%">
            <AvatarHandler :avatars="task.assignees" :overlap="true" :gap="5" :editable="false" :imgWidth="30"
              :fontSize="'12px'" :hover="false" :limit="1" :font-size="'20px'" :removable="false" />
          </div>
          <div>
            <el-col @click.native.stop="handlePriority">

              <div v-if="task.status === 'completed' && task.priority === 'high'"
                style="display:flex; align-items:center; justify-content:space-between; pointer-events: none;">
                <img style="height: 32px; width:18.25px; margin: 0; ;" src="../assets/FlagDisabled.svg" alt="">
                <p style="margin-left: 5px;color:#cccccc">Urgent</p>
              </div>
              <div v-else-if="task.status === 'completed' && task.priority === 'default'"
                style="display:flex; align-items:center; justify-content:space-between; pointer-events: none;">
                <img style="height: 32px; width:18.25px; margin: 0; ;" src="../assets/default_priority.svg" alt="">
                <p style="margin-left: 5px;color:#cccccc">Default</p>
              </div>
              <div v-else-if="task.priority == 'default'"
                style="display:flex; align-items:center; justify-content:space-between">
                <img style="height: 32px; width:18.25px; margin: 0; ;" src="../assets/Priority.svg" alt="">
                <p style="margin-left: 5px;color:#777777">Default</p>
              </div>
              <div v-else style="display:flex; align-items:center; justify-content:space-between">
                <img style="height: 32px; width:18.25px;  margin: 0;" src="../assets/FlagFill.svg" alt="">
                <p style="color: red;margin-left: 5px;">Urgent</p>
              </div>
            </el-col>
          </div>
          <div v-if="task.status !== 'completed'"
            style="display:flex; align-items:center; justify-content: space-between;">
            <el-tooltip      class="item"
                effect="dark"
                content="Documents"
                placement="top-start">
            <p style="margin-left: 5px;" class="documents overfolw-ellipsis overfolw-texts">
              {{ task.documents_count }}
            </p>
          </el-tooltip>
              <el-tooltip      class="item"
                effect="dark"
                content="Comments"
                placement="top-start">
            <p style="margin-left: 5px;" class="chat overfolw-ellipsis overfolw-texts">
              {{ task.comments_count }}
            </p>
            </el-tooltip>
          </div>
          <div v-else style="display:flex; align-items:center; justify-content: space-between;">
            <el-tooltip      class="item"
                effect="dark"
                content="Documents"
                placement="top-start">
            <p style="margin-left: 5px;" class="documentsComplete overfolw-ellipsis overfolw-texts">
              {{ task.documents_count }}
            </p>
            </el-tooltip>
        
              <el-tooltip      class="item"
                effect="dark"
                content="Comments"
                placement="top-start">
            <p style="margin-left: 5px;" class="chatComplete overfolw-ellipsis overfolw-texts">
              {{ task.comments_count }}
            </p>
            </el-tooltip>
          </div>

        </el-col>
      </el-row>
    </div>
  </el-row>
  <el-row class="wrapper" v-else>
    <el-row class="spacing">
      <el-col :span="15" class="first-content">
        <p class="label">{{ task.id }}</p>
      </el-col>
      <el-tooltip content="Order type" placement="top" effect="light">
        <el-col :span="9" class="addition-section">
          <el-tag class="tag" :type="task.color" size="mini">
            {{ task.service_type }}
          </el-tag>
        </el-col>
      </el-tooltip>
    </el-row>
    <el-row class="spacing">
      <el-col :span="24" class="first-content">
        <el-tooltip class="item" effect="light" :content="isOrderPowerModel(task)" placement="top-start">
          <img
            v-if="isOrderPowerModel(task)"
            src="../../../../assets/img/gazeboIcons.svg"
            style="{width:13.5px;height:13.5px;margin-left:0;margin-right:8px;}"
          />
        </el-tooltip>
        <el-tooltip placement="bottom">
          <template v-slot:content>
            <div>
              <el-col :span=20>
                <p class="label-name">
                  <span style="color:white">{{ task.name }}</span>
                </p>
              </el-col>
            </div>
          </template>
          <p class="label-name overfolw-ellipsis" style="display: flex; align-items: center;">
            {{ task.name }}
          </p>
        </el-tooltip>
      </el-col>
    </el-row>
    <el-row class="spacing">
      <el-tooltip class="item" effect="light" :content="'Ordered on'" placement="top-start">
        <el-col :span="20" class="first-content">
          <!-- <span
          ><i class="el-icon-user icon-size" style="padding-right: 0.5rem"></i
        ></span> -->

          <el-col :span="3">
            <img src="../assets/opendoor.svg" alt="" style="
                 {
                  width: 13.5px;
                  height: 13.5px;
                  margin-left: 0;
                }
              " />
          </el-col>
          <el-col :span="20">
            <p class="label">{{ task.order_placed_at }}</p>
          </el-col>
        </el-col>
      </el-tooltip>
    </el-row>
    <el-row class="spacing" >
      <el-tooltip class="item" effect="light"  :content="getTooltipContent(task.order_status)" placement="top-start">
        <el-col :span="20" class="first-content">
          <el-col :span="3">
            <img src="../assets/warning.svg" alt="" style="
                 {
                  width: 8px;
                  height: 13px;
                  margin-left: 0;
                }
              " />
          </el-col>
          <el-col :span="20">
            <p v-if="task.order_status=='complete' ||task.order_status=='cancelled' ||task.order_status=='rejected' " class="label">
              {{ task.modified_at}}
            </p>
            <p v-else class="label" :style="{ color: task.due_date.color }">
              {{ task.due_date.label}}
            </p>
          </el-col>
        </el-col>
      </el-tooltip>

      <el-col :span="3" class="addition-section">
        <p v-if="task.due_date.color" :style="{ color: task.due_date.color }">
          &#9679;
        </p>
      </el-col>
    </el-row>
    
    <el-row class="spacing">
      <el-tooltip class="item" effect="light" :content="selectedColumnType === 'order_status'
        ? 'Delivery type'
        : 'Order type'
        " placement="top-start">
        <el-col :span="20" class="first-content">
          <el-col :span="3">
            <img src="../assets/info.svg" alt="" style="
                 {
                  width: 13.5px;
                  height: 13.5px;
                  margin-left: 0;
                }
              " />
          </el-col>
          <el-col :span="20">
            <p v-if="selectedColumnType === 'order_status'" class="label">
              {{ task?.delivery_type || "NA" }}
            </p>

            <p v-else class="label">
              {{ task?.orderStatus || "NA" }}
            </p>
          </el-col>
        </el-col>
        <el-col :span="9" class="addition-section"> </el-col>
      </el-tooltip>
    </el-row>
    <el-row class="spacing">
      <el-tooltip class="item" effect="light" :content="'Assigned to'" placement="top-start">
        <el-col :span="15" class="first-content">
          <el-col :span="3">
            <img src="../assets/person.svg" alt="" style="
                 {
                  width: 13.5px;
                  height: 13.5px;
                  margin-left: 0;
                }
              " />
          </el-col>
          <el-col :span="24">
            <div class="engineer-details" style="">
              <p class="label overfolw-ellipsis">
                {{ task.assigned_to }}
              </p>
              <div v-if="task.assigned_to !== 'NA' && task.assigned_to !== 'Unassigned'">
                <el-badge is-dot :type="task.isOnline ? 'success' : 'info'" style="margin-left: 10px"></el-badge>
                <!-- <span v-if="task.isOnline === true" style="margin-left: 10px; color: green">Online</span>
                <span v-else style="margin-left: 10px; color: grey">Offline</span> -->
              </div>
            </div>
          </el-col>
        </el-col>
      </el-tooltip>
      <el-col :span="9" class="addition-section">
        <div v-for="(icon, inx) in buttonsData" :key="inx">
          <el-tooltip class="item" effect="light" :content="icon.tooltipContent" placement="top-start">
            <i v-if="(task.design && icon.exist === 'desgin') ||
              (task.additional_info?.path && icon.exist === 'survey')
              " :key="inx" :class="icon.icon" class="icon-size" style="padding-left: 1rem; color: #777"
              @click.native.stop="icon.callback(task)">
            </i>
          </el-tooltip>
        </div>


        <!--messages  -->
        <div style="display: flex;align-items: center;padding-left: 1rem;">
          <svg xmlns="http://www.w3.org/2000/svg" width="1.2rem" height="1.2rem" fill="#777" class="bi bi-chat-left"
            viewBox="0 0 16 16">
            <path
              d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
          </svg>
          <p
            style="padding-left: 0.5rem; font-weight: 600;font-size: 1rem; color:#777 ;display: flex;align-items: flex-start">
            {{ task.unread_message_count ? task.unread_message_count : 0 }}</p>
        </div>
        <!-- <i
          class="el-icon-document-checked"
          style="font-size: 1.2rem; padding-left: 0.5rem"
        ></i> -->
      </el-col>
    </el-row>
  </el-row>
</div>
