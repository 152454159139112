<template>
  <div>
    <el-checkbox v-model="selecteddata" :disabled="isDisabled">{{
      checkboxData?.title || ""
    }}</el-checkbox>
  </div>
</template>

<script>
export default {
  emits: ["item-checked", "update:showTotal", "item-selected"],
  name: "checkboxView",
  data() {
    return {
      checkboxData: "",
      selecteddata: false,
    };
  },
  mounted() {
    this.checkboxData = this.$props.items;
    this.selecteddata = this.$props.selectedValue;
  },
  props: {
    items: {
      type: Object,
      default() {
        return {}
      },
    },
    indexOfOptions: {
      type: Number,
      default: null,
    },
    selectedValue: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    showTotal: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    selecteddata(val) {

      this.$emit("item-checked", this.$props.indexOfOptions, val);
      this.$emit('update:showTotal',val)
      this.$emit(
        "item-selected",
        this.$props.items.prop,
        val,
        val,
        this.$props.items,
        this.$props.indexOfOptions,
        false
      );
    },

    selectedValue(val) {
      this.selecteddata = val;
    },
    showTotal: {
      immediate: true,
      handler(val) {
        this.selecteddata = val;
      },
    },
  },
};
</script>

<style scoped></style>
