<template>
    <div class="input-container">
        <el-input 
        v-model="inputValue" 
        :disabled="isDisabled" 
        type="number" 
        :placeholder="placeholderValue"
         @keypress="onlyAllowNumbers"
        >
                <template v-slot:suffix v-if="prefixIcon">
                    <div style="color:#777">{{ prefixIcon }}</div>
                </template>

                
                </el-input>
    </div>
</template>

<script>
import { placeholder } from 'lodash/bind';


    export default {
        emits: ['update:inputData'],
        data(){
            return{
                inputValue:'',
            }
        },
        mounted(){
            this.inputValue=this.$props.inputData;
        },
        props:{
            isDisabled:{
                type:Boolean,
                default:false
            },
            inputData:{
                type:[String,Number],
                default:''
            },
            prefixIcon:{
                type:String,
                default:null
            },
            placeholderValue:{
                type:String,
                default:''
            },
        },
        methods:{
            onlyAllowNumbers(event) {
      // Allow only numbers, backspace, delete, tab, escape, enter, and arrow keys
      const allowedKeys = [8, 46, 9, 27, 13]; // Backspace, Delete, Tab, Escape, Enter
      const arrowKeys = event.keyCode >= 37 && event.keyCode <= 40; // Arrow keys

      if (
        (event.key < '0' || event.key > '9') && // Disallow non-number keys
        !allowedKeys.includes(event.keyCode) && // Allow special keys
        !arrowKeys // Allow arrow keys
      ) {
        event.preventDefault();
      }
    }
        },
        watch:{
            inputValue:{
                handler(val){
                    if(val){
                        const floatValue=parseFloat(val)
                        this.$emit('update:inputData',floatValue)
                    }
                }
            },
            inputData:{
                immediate:true,
                handler(val){
                    this.inputValue=val;
                }
            }
        
        }
        
    }
</script>

<style scoped>
.input-container>>>.el-input__inner {
    border: none;
  background-color: #e8edf2;
  height: 40px;
  font-size: 16px;
  color: #222;
  text-overflow: ellipsis;
}
.input-container>>>.el-input__suffix{
    display: flex;
    align-items: center;
    padding:10px 10px

}

</style>