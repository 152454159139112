// Color Lightner based on percentage
export function getLightenedColor(hexColor, percentage) {
  const cleanHexColor = hexColor.replace("#", "");

  const red = parseInt(cleanHexColor.substr(0, 2), 16);
  const green = parseInt(cleanHexColor.substr(2, 2), 16);
  const blue = parseInt(cleanHexColor.substr(4, 2), 16);

  const lightenedRed = Math.round((255 - red) * (percentage / 100)) + red;
  const lightenedGreen = Math.round((255 - green) * (percentage / 100)) + green;
  const lightenedBlue = Math.round((255 - blue) * (percentage / 100)) + blue;

  const lightenedHexColor =
    "#" +
    ((lightenedRed << 16) | (lightenedGreen << 8) | lightenedBlue)
      .toString(16)
      .padStart(6, "0");

  return lightenedHexColor;
}

//   Color based on Competence
export function handleCompetenceColor(data) {
  switch (data) {
    case "PV Design":
      return "#C13A5E";

    case "Solar Sales Proposal":
    case "Sales Proposal":
    case "Preliminary Proposal":
      return "#34691E";
    case "Permit Package":
      return "#EF6C00";
    case "Premium Order":
      return "#7C1EA4";
    default:
      return "#409eff";
  }
};

export const colorCodes = [
  "#679F38",
  "#EF6C00",
  "#016EAD",
  "#A34A00",
  "#0099A5",
  "#7D263D",
  "#C13A5E",
  "#34691E",
  "#184D93",
  "#799199",
  "#7C1EA4",
  "#00717A",
];
// generate darker colors if palette exceeds (dashboard V2)
export function generateColorHex(number) {

  const generatedColors = [];
  let nextDarkness = 0;
  let darknessLevel = 50; // You can adjust this darkness level
  for (let i = 0; i < number; i++) {
    if (i < colorCodes.length) {
      generatedColors.push(colorCodes[i]);
    } else {
      const baseColor = generatedColors[i % colorCodes.length];
      const r = parseInt(baseColor.slice(1, 3), 16);
      const g = parseInt(baseColor.slice(3, 5), 16);
      const b = parseInt(baseColor.slice(5, 7), 16);
      if (nextDarkness == 11) {
        darknessLevel = darknessLevel + 50;
        nextDarkness = 0;
      }
      const darkerR = Math.max(0, r - darknessLevel);
      const darkerG = Math.max(0, g - darknessLevel);
      const darkerB = Math.max(0, b - darknessLevel);

      const darkerHex = `#${darkerR.toString(16).padStart(2, "0")}${darkerG
        .toString(16)
        .padStart(2, "0")}${darkerB.toString(16).padStart(2, "0")}`;
      generatedColors.push(darkerHex);
      nextDarkness += 1;
    }
  }

  return generatedColors;
};

export function  handleServiceTypeColor(data) {
  switch (data.service_type) {
    case "PV Design":
      return "danger";

    case "Solar Sales Proposal":
    case "Sales Proposal":
    case "Preliminary Proposal":
      return "success";
    case "Permit Package":
      return "warning";
    default:
      return "";
  }
};
