
<div id="dropdownInfiniteScroll">

    <el-select v-model="value" placeholder="Pro Clamp" class="dropdownClass" @input="handleChange">
        <div class="formClass">
            <div class="positionR">
                <input id="myInput" type="text" placeholder="Search" class="formInput" v-model="search" autocomplete="off">
                <button class="searchIconBtn" type="submit" :class="styleSearchButton" />
            </div>
        </div>

        <p class="make">Make</p>

        <el-option 
            v-for="item in finalOptions" 
            :key="item.id" 
            :label="getLabel(item.company_name, [item.model, item.make, item.series], item.location)" 
            :value="item.id"
            class="drpdwnOptClass">
        </el-option>

        <!-- <infinite-loading
            :distance="0"
            spinner="bubbles"
            @infinite="fetchMoreUtilityRateByLSEID"
        >
            <div
                slot="no-more"
                style="color: #606266; font-size: 12px">
                No more utility rates.
            </div>
            <div
                slot="no-results"
                style="color: #606266; font-size: 12px">
                No more utility rates.
            </div>

            <div
                slot="error"
                style="color: #606266; font-size: 12px">
                Error in fetching utility rates, retry!!
            </div>
        </infinite-loading> -->
    </el-select>
</div>
