
<div class="outer-container">
  <p v-show="helpText">
    <span style="color: red">*</span> Weeks are calculated as 7 days from the
    first day of the quarter.
  </p>
  <div class="container">
    <!-- <canvas id="chart"></canvas> -->
    <canvas ref="chartCanvas" @click="handleChartClick"></canvas>
  </div>
</div>
