
<div class="input-container">
    <el-input 
    v-model="inputValue" 
    :disabled="isDisabled" 
    type="number" 
    :placeholder="placeholderValue"
     @keypress="onlyAllowNumbers"
    >
            <template v-slot:suffix v-if="prefixIcon">
                <div style="color:#777">{{ prefixIcon }}</div>
            </template>

            
            </el-input>
</div>
