<template>
	<div id="textEditorContainer" :class="[{customPlaceholderMode:customPlaceholderMode}]">
        <div id="editor" name="editor" class="editor">
        </div>
		<el-checkbox
			v-if="!customPlaceholderMode"
			class="addPSClass" 
			size="medium"
			v-model="includePaymentScheduleData"
		>
			Add project schedule page in contract
		</el-checkbox>
	</div>
		
</template>

<script>
import {Jodit} from 'jodit/es2021/jodit.js'
import 'jodit/es2021/jodit.min.css'
import { uploadFileToBlob } from "@/utils.js";
import { containerNameForEditorImages, containerNameForContracts } from "@/constants.js";
import { v4 } from "uuid";
import {mapState, mapActions} from "pinia";
import {useContractManagementStore} from '../../../../stores/contractManagement'
import API from "@/services/api/";
import imageUploadIcon from '@/pages/documentManagement/assets/imageUpload.svg';
import pageBreakIcon from '@/pages/documentManagement/assets/pageBreak.svg';

export default {
		emits: ["clearPlaceholder", "clearCustomPlaceholder"],
		components:{
		},
		props:{
			selectedProjectPlaceholder:{
				type:String,
				default:''
			},
			selectedDocusignPlaceholder:{
				type:String,
				default:''
			},
			selectedGoodleapPlaceholder:{
				type:String,
				default:''
			},
			selectedCustomPlaceholder:{
				type:String,
				default:''
			},
			saveEditorCounter:{
				type:Number,
				default:0
			},
			htmlContent:{
				type:String,
				default:''
			},
			fileName:{
				type:String,
				default:''
			},
			includePaymentSchedule:{
				type:Boolean,
				default:false
			},
			customPlaceholderMode:{
				type:Boolean,
				default:false
			},
			showToolbar:{
				type:Boolean,
				default:true
			},
			readOnly:{
				type:Boolean,
				default:false
			}
		},
		data(){
			return{
				editor:'',
				projectPlaceholders:['Client Name', "Address", "Phone Number"],
				docusignPlaceholders:['First Client Signature', "Second Client Signature", "Third Client Signature"],
				openView:false,
				paginatedContent:'',
				editorMode: true,
				contractId : this.$route.params.contractId,
				includePaymentScheduleData: this.includePaymentSchedule,
			}
		},
		mounted(){
			this.CMStore.isEditorContentChanged = false;
			this.initializeEditor('#editor')
		},
		computed:{
			...mapState(useContractManagementStore, {
				getElementId: "GET_ID_OF_LARGER_ELEMENT",
				CMStore: (state) => state
			})
		},
		methods:{
			...mapActions(useContractManagementStore, {
				setId: "SET_ID_OF_LARGER_ELEMENTS",
				setHTMLContent: "SET_HTML_CONTENT",
			}),
			handlePaste(event) {
				// Prevent the default paste action
				// Get the plain text content from the clipboard
				let pastedText = event.clipboardData.getData('text');
				let pastedHTML = event.clipboardData.getData('text/html');
				let isImgIncluded = pastedHTML.includes('img src')
				if(isImgIncluded){
					event.preventDefault();
					this.$message({
						duration:5000,
						showClose: true,
						message: "Image pasting is not allowed. Please upload via the toolbar",
						type: "error",
						center: true,
						customClass: 'message-container' // Apply the custom CSS class
					});
					return;
				}
				// Check if the pasted content contains significant line breaks, indicating it's likely from a PDF
				const isFromPDF = !pastedHTML
				const editor = this.editor;
				if (isFromPDF) {
					event.preventDefault();
					// Normalize line breaks to \n
					pastedText = pastedText.replace(/\r\n/g, '\n');
					// Split the pasted text by \n
					const textSegments = pastedText.split('\n');
					// Wrap each text segment in a <p> tag and filter out empty segments
					const formattedText = textSegments
						.filter(segment => segment.trim() !== '')  // Remove empty segments
						.map(segment => `<p>${segment.trim()}</p>`) // Wrap in <p> tags
						.join('');  // Join everything back into a single string
					// Insert the formatted text into the editor as HTML
					editor.selection.insertHTML(formattedText);
				} else {
					// Do the usual thing.
				}
			},
			initializeEditor(idOfDivContainer){
				let vm = this;
				this.editor = Jodit.make(idOfDivContainer,{
					useNativeTooltip: true,
					enableDragAndDropFileToEditor: false,
					placeholder: 'Start Typing ....',
					readonly: this.readOnly, // Disable typing
					disablePlugins: ['paste','DragAndDrop'],
					toolbar: this.showToolbar, // Disable the toolbar if showToolbar is true
					"autofocus": true,
					events: {
						paste: this.handlePaste,
						change: function () {
							vm.CMStore.isEditorContentChanged = true;
						}
        			},
					width: 612,
					toolbarAdaptive: false, // Disable adaptive toolbar
					controls: {
						paragraph: {
							list: Jodit.atom({
								p: 'Normal text',
								h1: 'Heading 1',
								h2: 'Heading 2',
								h3: 'Heading 3',
							})
						},
						
					},
					buttons: [
						{
							name: 'header', // First group
							buttons: [
								'paragraph', 
								'font',
								'fontsize', 
							]
						},
						'|',
						{
							name: 'text', // Second group
							buttons: [
								'bold', 
								'italic', 
								'underline'
							]
						},
						'|',
						{
							name: 'alignment', // Third group
							buttons: [
								'left', 
								'center', 
								'right', 
							]
						},
						'|',
						{
							name: 'list', // Fourth group
							buttons: [
								'ul', 
								'ol'
							]
						},
						'|',
						'hr', // Horizontal line
						'table', // Table
						// 'image', // Image
						// 'print' // Print
					],
					askBeforePasteFromWord: false,
					askBeforePasteHTML: false,
					// height: 800,
					"uploader": {
						url: 'none',
						// "insertImageAsBase64URI": true,
					},
					selectionCellStyle: 'border: 1px double #1e88e5 !important;',
					useExtraClassesOptions: true,
					extraButtons: [
						{
							name: 'insertImage',
							tooltip: 'Insert Image',
							iconURL:imageUploadIcon,
							exec: function (editor) {
									const input = document.createElement('input');
									input.setAttribute('type', 'file');
									input.setAttribute('accept', 'image/*');
									input.click();
									// Store a reference to the Jodit editor
									let joditEditor = editor;
									input.onchange = async function () {
										const imageFile = input.files[0];
										let fileSizeInKB = imageFile.size/1024
										if(fileSizeInKB > 3 * 1024){
											vm.$message({
												duration:5000,
												showClose: true,
												message: "cannot exceed 3MB of file size",
												type: "error",
												center: true,
												customClass: 'message-container' // Apply the custom CSS class
											});
											return
										}
										if (!imageFile) {
												return;
										}
										if (!imageFile.name.match(/\.(jpg|jpeg|png|webp)$/)) {
												return;
										}
										const uuid =  v4();
										const imageInfo = await uploadFileToBlob(input.files,uuid,containerNameForEditorImages);
										const image = joditEditor.selection.j.createInside.element('img');
										image.setAttribute('src',`https://downloadstsl.blob.core.windows.net/${containerNameForEditorImages}/${uuid}`);
										joditEditor.selection.insertNode(image);

									}
							}
						},
						{
							name: 'Add Page Break',
							tooltip: 'Add Page Break',
							iconURL: pageBreakIcon,
							exec: function (editor) {
								const divElement = document.createElement('div');
								divElement.className = 'html2pdf__page-break';
								divElement.style.border = "1px solid #000"
								divElement.style.height = "1px"
								editor.s.insertHTML(divElement)
								editor.s.insertHTML('  ');
							}
						},
					]
				});
				this.editor.value = this.htmlContent;
				this.CMStore.isEditorContentChanged = false;
			},
			handleSave(){
				this.saveDataInLS();
				let promise1 = this.updateDocumentTemplate()
				let promise2 = this.updatePlaceholdersArray()
				Promise.all([promise1,promise2]).then(()=>{
					this.CMStore.isEditorSavingInProgress = false;
					this.CMStore.isEditorContentChanged = false;
				})
			},
			updatePlaceholdersArray(){
				this.CMStore.placeholdersArray=[]
				let parentDiv = document.createElement('div');
				parentDiv.innerHTML = this.editor.value;
				let clientNameSpans = parentDiv.querySelectorAll('span.client-name');
					clientNameSpans.forEach(span => {
					let placeholderKey= span.id.split('-')[0] // already have span in the format - "placeholderKey-uuid"
					let spanJson = {
						'placeholder':  span.innerText,
						"placeholder_key": placeholderKey, 
						"id": span.id,
						"additional_details": this.CMStore.keyValuePlaceholders[placeholderKey].additional_details,
						"placeholder_type": this.CMStore.keyValuePlaceholders[placeholderKey].placeholder_type,
						"default_value" : this.CMStore.keyValuePlaceholders[placeholderKey].default_value || "", // in case of custom placehoder we have default_value
						"value" : this.CMStore.keyValuePlaceholders[placeholderKey].value || "" // in case of custom placehoder we have default_value
					};
					this.CMStore.placeholdersArray.push(spanJson)
				});
				this.updatePlaceholdersInDB();
			},
			async updatePlaceholdersInDB(){
				try{
						let patchData = {
							"additional_details":{
								"placeholders":[... this.CMStore.placeholdersArray]
							},
							"include_payment_schedule": this.includePaymentScheduleData
						}
						await API.CONTRACT_TEMPLATES.UPDATE_CONTRACT_TEMPLATE(this.contractId,patchData)
						this.$message({
							showClose: true,
							message: "Updated Successfully!",
							type: "success",
							center: true
						});
					}
					catch(e){
						console.error(e);
						this.$message({
							showClose: true,
							message: "Something failed! Can you try once again?",
							type: "error",
							center: true
						});
					}
			},
			async updateDocumentTemplate(){
				await this.uploadHTMLContent(this.editor.value)
			},
			uploadFileToBlob,
			async uploadHTMLContent(updatedContent){
				const blob = new Blob([updatedContent], { type: "text/html" });
				let files=[blob]
				const imageInfo = await uploadFileToBlob(files,this.fileName,containerNameForContracts);
			},
			insertImage(url) {
				const image = this.editor.selection.j.createInside.element('img');
				image.setAttribute('src', url);
				this.editor.selection.insertNode(image);
			},
			addIdsToHtml(htmlString) {
				const parser = new DOMParser();
				const doc = parser.parseFromString(htmlString, 'text/html');
				// Helper function to assign ID
				const assignIdToElements = (element) => {
					if (element.children) {
						Array.from(element.children).forEach((child) => {
							element.removeAttribute('id');
							if (!child.id || true) {
								let uuid = v4()
								child.id = uuid;
							}
							assignIdToElements(child);
						});
					}
				};
				assignIdToElements(doc.body);
				return doc.body.innerHTML;
    		},
			scrollToElementById(id) {
				const element = document.getElementById(id);
				if (element) {
					element.scrollIntoView({ behavior: 'smooth' });
					element.style.border = "2px solid transparent";
                	element.style.animation = "blink-border 1s infinite";
					this.$message({
						duration:10000,
						showClose: true,
						message: "Its too lengthy continuous content. Please break it down into different chunks",
						type: "error",
						center: true,
						customClass: 'message-container' // Apply the custom CSS class
					});
					this.setId('')
					setTimeout(() =>{
						element.style.border = "none";
                		element.style.animation = "none";
					}, 3000);
				} else {
					console.warn(`Element with ID ${id} not found.`);
				}
    		},
			saveDataInLS(){
				let data = this.editor.value
				this.setHTMLContent(data)
				const updatedContent = this.addIdsToHtml(data);
			},
			modifyClientNames(parentDiv) {
				// Get all <span> elements with class name "client-name" within the parent div
				let clientNameSpans = parentDiv.querySelectorAll('span.client-name');
				// Loop through each <span> element and modify it
				clientNameSpans.forEach(span => {
						if(span.textContent== 'Client Name'){
								span.classList.remove('client-name'); // Remove class "client-name"
								span.textContent = 'SanchitAgrawal'; // Change text content to something else
								span.style.fontWeight = '800';
						}
						else if(span.textContent== 'Client Sign'){
								span.classList.remove('client-name'); // Remove class "client-name"
								span.textContent = '<<Client Signature>>'; // Change text content to something else
						}
				});
			},
			insertPlaceholder(placeholderJson){ 
				let placeholderName = placeholderJson.name || placeholderJson.placeholder_key //placeholder_key in case of custom placeholder
				let placeholderIdentifier = placeholderJson.identifier || placeholderJson.placeholder_key  //placeholder_key in case of custom placeholder
				const span = document.createElement('span');
				span.className = 'client-name';
				span.contentEditable = 'false'; // Set contentEditable to false
				span.innerHTML = placeholderName;
				const uuid =  v4();
				// We are maintaining the id in the format like - "placeholder-uuid" so that, later on very easily we can split it and get the placeholderIdentifier
				// which could be used as placeholder_key inside updatePlaceholdersArray method. Have discussed with backend to not to add '-' from their end.
				span.id = `${placeholderIdentifier}-${uuid}`; 
				this.editor.s.insertHTML(span)
				this.editor.s.insertHTML('  ');
			},
			closeDialog(){
					this.openView=false;
			}
		},
		watch:{
			selectedProjectPlaceholder:{
				handler(val){
					if(val){
						this.insertPlaceholder(val)
						this.$emit("clearPlaceholder")  // so that watch can be triggered whenever the same placeholder is added
					}
				}
			},
			selectedDocusignPlaceholder:{
				handler(val){
					if(val){
						this.insertPlaceholder(val)
						this.$emit("clearPlaceholder")  // so that watch can be triggered whenever the same placeholder is added
					}
				}
			},
			selectedGoodleapPlaceholder:{
				handler(val){
					if(val){
						this.insertPlaceholder(val)
						this.$emit("clearPlaceholder")  // so that watch can be triggered whenever the same placeholder is added
					}
				}
			},
			selectedCustomPlaceholder:{
				handler(val){
					if(val){
						this.insertPlaceholder(val)
						this.$emit("clearCustomPlaceholder")  // so that watch can be triggered whenever the same placeholder is added
					}
				}
			},
			saveEditorCounter:{
				handler(val){
					if(val>0){
						this.handleSave()
					}
				}
			},
			getElementId:{
				handler(val){
					if(val){
						this.scrollToElementById(val);
					}
				}
			},
		}
}
</script>

<style scoped>
#textEditorContainer ::v-deep .page{
    border: 1px solid black;
    min-width: 612px;
    height: 792px;
    max-width: 612px;
    margin: 0 auto
}
#textEditorContainer ::v-deep .jodit-container{
    margin: 0 auto;
}
#textEditorContainer ::v-deep .jodit-container:not(.jodit_inline){
	border : none !important;
	margin-top: 20px !important;
}
#textEditorContainer ::v-deep .jodit-container:not(.jodit_inline) .jodit-wysiwyg {
	padding: 24px !important;
	font-size: 16px;
}
#textEditorContainer ::v-deep .jodit-container:not(.jodit_inline) .jodit-workplace{
	min-height: 75vh !important;
}
#textEditorContainer ::v-deep .jodit-toolbar__box:not(:empty) {
	padding: 0px 24px !important;
}

#textEditorContainer ::v-deep .client-name {
	display: inline-block !important;
	padding: 5px;
	background-color: #f0f0f0;
	border-radius: 5px; /* Add border-radius for rounded corners */
	pointer-events: none;
}

#textEditorContainer ::v-deep .page{
	margin-bottom: 24px;
	border: 1px solid #ccc;
	min-width: 612px;
	height: 792px;
	max-width: 612px;
	/* this is imp to match padding of the editor */
	padding-left: 23px;
	padding-right: 23px;
	padding-top: 24px;
	padding-bottom: 0px;
}

@keyframes blink-border {
	0%, 100% {
		border-color: transparent;
	}
	50% {
		border-color: red;
	}
}

#textEditorContainer ::v-deep .message-container {
  z-index: 99999 !important; /* Set a high z-index value */
  top: 10vh !important;
  left: 40%;
}


/* td{
	padding : .4em !important;
} */
</style>

<style scoped>
#textEditorContainer ::v-deep ul{
	list-style: inside
}

#textEditorContainer ::v-deep ul,  #textEditorContainer ::v-deep ol {   
	/* width: 100%; */
	overflow-wrap: normal; 
	/* Wrap only at spaces */
	word-wrap: normal;     
	/* Prevent breaking within words */
	white-space: pre !important;   
	/* Allow text wrapping */
}

#textEditorContainer ::v-deep li
,#textEditorContainer ::v-deep p
{  
	 /* display: block;  */
	 white-space: normal !important;
	 word-wrap: break-word;
	 width: auto !important;
	/* Ensure text inside li, p, wraps at spaces */
}

#textEditorContainer ::v-deep .jodit-add-new-line{
	display: none;
}

#textEditorContainer{
	display: flex;
    flex-direction: column;
	gap: 15px;
}	

#textEditorContainer >>> .el-checkbox__inner {
  width: 20px;
  height: 20px;
}

#textEditorContainer >>> .el-checkbox__label {
  color: #222;
  font-size: 16px;
  white-space: initial;
  padding-left: 12px;
}
#textEditorContainer >>> .el-checkbox__inner::after {
  top: 3px;
  left: 7px;
  border-width: 2px;
}
.addPSClass{
	margin: 0 auto;
	width: 612px;
	font-weight: bold;
}
.jodit-document ::v-deep table{
	word-break: break-all;
}

.leftSection{
		/* overflow-y: auto; */
    width:80%;
    height: 100%;
  
}
.editor{
    display: flex;
    height: 100%;
    width: 100%;    
}
.texteditor{
    width:100%;
    height: 100%;
    display: flex;
    justify-content: right;
    padding: 32px;
    
}
.toggle{
    width:20%;
}
.customPlaceholderMode{
	pointer-events: none;
	cursor: not-allowed;
}
</style>