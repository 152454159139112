<template>
  <div id="draw">
    <el-drawer
      :title="title"
      :visible="isOpen"
      :size="computedDrawerSize"
      :append-to-body="true"
      :show-close="!isMobileView"
      :direction="drawerDirection"
      :before-close="handleCloseDialog"
      :with-header="title ? true : false"
    >
      <template v-slot:title>
        <div v-if="isMobileView" class="title-container">
          <b-icon
            class="back-arrow"
            icon="arrow-left-short"
            @click="handleCloseDialog"
          />
          {{ title }}
        </div>
      </template>
      <div class="drawer">
        <slot name="header"></slot>
        <slot name="body"></slot>
        <slot name="footer"></slot>
        <div class="pinned-footer">
          <slot name="pinned-footer"></slot>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useMiscStore } from "../../../stores/misc";
import { useUiStore } from "../../../stores/ui";
export default {
  data() {
    return {};
  },
  props: {
    isOpen: {
      default: true,
      type: Boolean,
    },
    drawerSize: {
      type: [Number, String],
      default: 700,
    },
    title: {
      type: String,
      default: "Title",
    },
    handleCloseDialog: {
      type: Function,
      required: true,
    },
    drawerDirection: {
      type: String,
      default: "rtl",
    },
  },
  computed: {
    ...mapState(useUiStore, {
      isMobileView: state => state.isMobileView
    }),
    computedDrawerSize() {
      if (this.isMobileView) {
        return "100%";
      } else {
        return this.drawerSize;
      }
    },
  },
  mounted() {
    if (this.isOpen) {
      this.setDrawerState("globalDrawer", true);
    }
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    this.setDrawerState("globalDrawer", false);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    ...mapActions(useMiscStore, {
      setDrawerState: "SET_DRAWER_STATE",
    }),
    handleResize() {
      this.$forceUpdate();
    },
  },
  watch: {
    isOpen(isOpenDrawer) {
      if (isOpenDrawer) {
        this.setDrawerState("globalDrawer", true);
      } else {
        this.setDrawerState("globalDrawer", false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.title-container {
	display: flex;
	align-items: center;
	font-size: 16px;
	gap: 0.2em;
	font-weight: 600;
}

.back-arrow {
	font-size: 2em;
	cursor: pointer;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.drawer {
  height: 100%;
  position: relative;
  /* Other styles for the drawer */
}

.pinned-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  /* Other styles for the pinned div */
}

::v-deep .el-drawer__header {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0;
  color: #222222;
  margin-bottom: 0.5rem;
  padding: 1rem 1rem 0rem 1rem;
}

::v-deep .el-icon-close {
  font-size: 16px;
  font-weight: bolder;
}

@media (max-width: 768px) {
  ::v-deep .el-drawer__header {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0;
    color: #222222;
    padding: 0 1em 0 0.4em;
    border-bottom: 1px solid #ccc;
    height: var(--top-navbar-height);
  }
}
</style>
