
<div class="card-header" >
  <el-tooltip placement="bottom" :hide-after="0" :content="heading">
    <div class="name-content">
      {{ heading }}
    </div>
  </el-tooltip>
  <div v-if="editMode" style="cursor: pointer">
    <CommonPopover @open-options="handleOpenMode" />
  </div>
  <slot></slot>
</div>
