
<div>
  <div class="data-container">
    <div>
      <el-row>
        <div class="container-data">
          <b-icon
            class="custom-icon"
            :style="{
              marginRight: '5px',
              color: '#EF6C00',
            }"
            :icon="'exclamation-octagon-fill'"
          ></b-icon>
        </div>
      </el-row>
      <el-row>
        <p class="container-data title">{{ info }}</p>
      </el-row>
      <el-row>
        <p class="container-data inicator">{{ infoindicator }}</p>
      </el-row>
    </div>
  </div>
</div>
