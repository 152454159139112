<template>
  <div id="usersInfiniteScroll" style="width: 100%;">
    <el-select
      ref="userInfiniteScrollDropdown"
      id="userSelected"
      v-model="selectedUser"
      @change="$emit('update:user', selectedUser)"
      :disabled="isUserListDisabled"
      :remote-method="searchUsers"
      :loading="loading"
      class="projectSharing"
      style="width: 100%"
      value-key="id"
      filterable
      remote
      clearable
      reserve-keyword
      @visible-change="onVisibleChange"
      :placeholder="!crmMode ?  'Enter name or email address' : 'Select Owner'"
      :popper-class="theme"
    >
      <el-option
        v-for="currUser in computeUsers"
        :key="currUser.id"
        :label="`${currUser['first_name']} - ${currUser['email']}`"
        :value="currUser"
        :disabled="currUser.isDisabled"
      >
      <span v-if="checkdifferntOrg(currUser)" style="padding-right: 0.5rem;"> <img src="../../../assets/img/personfill.svg" alt=""> </span>
      <span v-else style="padding-right: 0.5rem;"> <img src="../../../assets/img/buildings.svg" alt=""> </span>
     
      <span>{{ currUser['first_name'] }} - {{ currUser['email'] }}</span>
      </el-option>
      <infinite-loading
        :distance="0"
        spinner="bubbles"
        @infinite="loadMoreUsers"
      >
        <template v-slot:no-more>
          <div style="color: #606266; font-size: 12px">
            No more users!!
          </div>
        </template>
        <template v-slot:error>
          <div style="color: #606266; font-size: 12px">
            Error in fetching users, retry!!
          </div>
        </template>
        <template v-slot:no-results>
          <div style="color: #606266; font-size: 12px">
            No more users!!
          </div>
        </template>
      </infinite-loading>
    </el-select>
    <b-icon icon="search" variant="dark" class="arrowIcon" v-if="showDropdownIcon" @click="openDropdown"></b-icon> 
    <!-- Confirmation Popup before assigning to Project Manager mainly for L2C2C flow and works for all users who will have the key is_project_manager as true  -->
    <SalesPacketConfirmationDialog :openDialog.sync="projectManagerConfirmation" @handleClose="handleCloseandReassigne" @handleUpdateOwner="handleOwnerUpdate" />
  </div>
</template>

<script>
import API from "@/services/api/";
import {  mapState } from "pinia";
import { useLeadStore } from "../../../stores/lead";
import {queryParamsFormater} from '../../../utils/queryParamGenerator';
import SalesPacketConfirmationDialog from "../../../pages/leadManagement/components/salesPacketConfirmationDialog.vue";

export default {
  emits: ["update:user", "update:user", "updatePMdata"],
  name: "usersScroll",
  components:{
    SalesPacketConfirmationDialog,
  },
  props: {
    user: {
      default() {
        return {};
      },
    },
    theme: {
      type: String,
      default: "lightDropdown",
    },
    isUserListDisabled:{
      type: Boolean,
      default: false,
    },
    crmMode:{
      type: Boolean,
      default: false,
    },
    collaborators: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selectedUser: {},
      usersList: [],
      loading: false,
      scrollState: "",
      apiPromise: null,
      initialUsersList:[],
      projectManagerConfirmation:false,
      assigneeProjectManagerData:null,
    };
  },

  watch: {
    user: {
      deep: true,
      handler(value) {
        this.selectedUser = JSON.parse(JSON.stringify(value));
      },
    },
    'leadInfo.workflow_type':{
      deep:true,
      handler(val){
        this.fetchAllUsers()

      }
    },
    selectedUser:{
      handler(val,oldVal){
        if(val?.is_project_manager){
          this.assigneeProjectManagerData={selectedOwner:val,oldOwner:oldVal};
          this.projectManagerConfirmation=true;
          this.$refs.userInfiniteScrollDropdown.blur();
        }
        
      }
    }
  },

  methods: {
    checkDisable(){
      if(this.$props.collaborators.length > 0){
        
      }
    },
    async fetchAllUsers() {
      try {
        let qprams={
          only_vip:null,
          only_internal_and_ip:null,
        }
 const leadWorkflow=this.leadInfo?.lead_details?.workflow_type||this.leadInfo?.workflow_type||null;
if(leadWorkflow){
  if(leadWorkflow=="d2c_arka_internal"){
    qprams.only_internal_and_ip=true
  }else{
    qprams.only_vip=true
  }
}
const qParamFormate=    queryParamsFormater(qprams);


        let promise = API.USERS.FETCH_ALL_USERS(qParamFormate);
        this.apiPromise = promise
        this.loading = true;
        promise.then(response => {
          if (this.apiPromise != promise) { return }

          this.loading = false;

          this.nextURL = response.data.next;
          this.usersList = JSON.parse(JSON.stringify(response.data.results));
          this.initialUsersList = JSON.parse(JSON.stringify(response.data.results));
          this.scrollState?.reset?.();
        })
      } catch (e) {
        this.loading = false;
        console.error(e);
      }
    },

    async loadMoreUsersHelper(url, $state) {
      try {
        let promise = API.USERS.LOAD_MORE_USERS(url);
        this.apiPromise = promise
        promise.then(response => {
          if (this.apiPromise != promise) { return }

          this.nextURL = response.data.next;
          this.prevURL = response.data.previous;
          for (let i = 0; i < response.data.results.length; i++) {
            this.usersList.push(response.data.results[i]);
          }
  
          $state.loaded();
        })

      } catch (e) {
        console.error();
      }
    },

    async searchUsersHelper(query) {
      try {
        let promise = API.USERS.SEARCH_USERS(query)
        this.apiPromise = promise
        this.loading = true;
        promise.then(response => {
          if (this.apiPromise != promise) { return }

          this.loading = false;
          
          this.usersList = [];
          this.usersList = JSON.parse(JSON.stringify(response.data.results));
          this.nextURL = response.data.next;
          this.prevURL = response.data.previous;
        })
      } catch (e) {
        this.loading = false;
        console.error(e);
      }
    },

    loadMoreUsers($state) {
      this.scrollState = $state;
      if (this.nextURL !== null) {
        this.loadMoreUsersHelper(this.nextURL, $state);
      } else {
        $state.complete();
      }
    },
    searchUsers(query) {
      if (query !== "") {
        setTimeout(() => {
          this.searchUsersHelper(query);
        }, 200);
      } else {
        this.usersList = [];
        this.fetchAllUsers();
      }
    },
    onVisibleChange(value){
      if(!value){  //after searching something, need to clear the value with the initial list of users after collapsing the dropdown
        this.usersList = JSON.parse(JSON.stringify(this.initialUsersList));
        this.searchUsers('');
      }
    },
    checkdifferntOrg(user){
      const userInfo=JSON.parse(localStorage.getItem("user")) || {};
     return user.organisation_id===userInfo.organisation_id?true:false; 
    },

    openDropdown() {
      if(!this.isUserListDisabled) {
          // Open the dropdown when the icon is clicked
          this.$refs.userInfiniteScrollDropdown.visible = true;
      } else {
        return;
      }
    },
// If selected Project Manager and did not proceed the assign back to old owner and close the dialog
    handleCloseandReassigne(){
      this.$emit("update:user",this.assigneeProjectManagerData.oldOwner);
      this.projectManagerConfirmation=false;
    },
    handleOwnerUpdate(){
this.$emit('updatePMdata',this.assigneeProjectManagerData.selectedOwner.id, this.assigneeProjectManagerData.selectedOwner, this.assigneeProjectManagerData.oldOwner);
this.projectManagerConfirmation=false;

    }
  },
  computed: {
    ...mapState(useLeadStore, {
      leadInfo: (state) => state,
    }),
    computeUsers(){
      
      if(this.$props.collaborators.length > 0){
        return (
          this.usersList.map(e => {
            if(this.$props.collaborators.find(element => element.id === e.id)){
              return {...e, isDisabled: true}
            }
            return {...e, isDisabled: false}
          })
        )
      }
      return this.usersList
    },
    showDropdownIcon() {
      if(Object.keys(this.selectedUser).length == 0) {
        return true;
      } else {
        return false;
      }
    }
  },
  mounted() {
    if(this.crmMode)
    this.selectedUser = this.user;    
    this.fetchAllUsers();
    this.checkDisable()
  },
};
</script>

<style type="text/css" scoped>
#usersInfiniteScroll {
  position: relative;
  width: 100%;
  height: 2.7rem;
  margin-bottom: 8px;
}
.projectSharing >>> .el-input__inner {
    font-size: 16px !important;
    border-radius: 4px 0px 0px 4px;
    height: 48px;
    background-color: #e8edf2 !important;
    border: none !important;
    color: #222 !important;
}

.projectSharing >>> .el-input__inner::placeholder {
  font-size: 12px !important;
}


.el-select {
  height: 100%;
}

.el-select >>> .el-input {
  height: 100%;
}


.arrowIcon {
  font-size: 16px;
  font-weight: bold;
  position: absolute;
  top: 12px;
  right: 10px;
  cursor: pointer;
}

.disabledIcon {
  cursor: not-allowed;
}

  @media (max-width: 1140px) {
    .projectSharing >>> .el-input__inner::placeholder {
  font-size: 16px !important;
}
  }

</style>
