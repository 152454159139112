<template>
  <div class="container">
    <el-row :class="hasDescription ? 'task-div' : 'noDescription'">
      <el-col :span="2">
        <div class="icon-div" @click="toggleStatus(info.id, info.status)">
          <b-icon :icon="computeStatusIcon" :style="{color: computeStatusColor}"></b-icon>
        </div>
      </el-col>
      <el-col :span="18">
        <div  :class="{ 'disabled': !canShowTaskDetails, 'info-div': canShowTaskDetails }" @click="canShowTaskDetails ? onshowTaskDetails() : null">
          <h3 :title="info.name">{{ info.name }}</h3>
          <p
            :class="
              seeMore || isMultiLines(info.description)
                ? 'description-more'
                : 'description'
            "
            style="white-space: pre-line"
          >
            {{ getTruncatedDescription(info.description)
            }}<span v-if="seeMore" class="see-more">See More</span>
          </p>
        </div>
      </el-col>
      <el-col
        :span="4"
        :style="[$props.countSize > 40 ? { 'padding-left': '4rem' } : '']"
      >
        <div :class="forLeadSummary ? 'leadSummarybodyContent' : 'bodyContent'">
          <img
            class="priority-flag"
            src="../assets/Priority.svg"
            @click="togglePriority(info.id, info.priority)"
            v-if="info.priority == 'default'"
          />
          <img
            v-else
            class="priority-flag"
            src="../assets/FlagFill.svg"
            @click="togglePriority(info.id, info.priority)"
          />
          <p class="ownerN" :style="{ backgroundColor:canShowTaskDetails ?  generateAvatarColor(info.assigned_to): generateAvatarColor(info.assignee_org) }">
            {{ canShowTaskDetails ? textInitial(info.assigned_to) : textInitial(info.assignee_org) }}</p>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="23"><div class="border-div"></div></el-col>
    </el-row>
    <all-drawer
      :drawer="detailsDrawer"
      @save="detailsDrawer = false"
      :componentName="componentName"
      :drawerSize="500"
      @close="detailsDrawer = false"
      :tasks="info"
      :forAddTask="false"
    />

    <GlobalDrawer
      :title="'Task Details'"
      :isOpen="isTaskDrawerOpen"
      :drawer-size="750"
      :handleCloseDialog="handleTaskDrawerClose"
      class="task-drawer-dialog"
      :drawerDirection="drawerDirection"
    >
      <template v-if="isTaskDrawerOpen" v-slot:body>
        <taskDrawer
          v-if="isTaskDrawerOpen"
          :reminderData.sync="reminderDetails"
          @updateTask="updateTask"
          @deleteTask="onDeleteTask"
          :mode="'view'"
          :taskData="selectedLead"
          @allowDocumentUpload="handleAllowDocUpload"
          :documentUploaded="documentUploaded"
          @documentUploading="documentUploaded = false"
        />
      </template>
    </GlobalDrawer>
    <setReminder
      :key="setKey"
      :showCreateDrawer="showSetReminderDrawer"
      @close="showSetReminderDrawer = false"
      @save="onSave"
      :drawerSize="400"
      :page="'task'"
    />
  </div>
</template>
<script>
import API from "../../../../services/api";
import debounce from "debounce";
import {
  generateAvatarColor,
  extractInitials as textInitial,
} from "../../../../utils";
import GlobalDrawer from "../../../commonComponents/allDrawer/globalDrawer.vue";
import taskDrawer from "../../../taskManagement/components/taskDrawerDynamic.vue";
import setReminder from "../../../setReminder/setReminder.vue";
import { mapState } from "pinia";
import { useUiStore } from "../../../../stores/ui"


export default {
  emits: ["onDeleteTask", "taskDrawerClosed", "individualTaskUpdated"],
  name: "Task",
  components: {
    GlobalDrawer,
    taskDrawer,
    setReminder,
  },
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
    project_id: {
      type: Number,
    },
    forLeadSummary: {
      type: Boolean,
      default: false,
    },
    countSize: {
      type: Number,
      default: 24,
      required: false,
    },
  },
  data() {
    return {
      documentUploaded: false,
      allowUpload: false,
      reminderDetails: {},
      setKey: 0,
      user_id:JSON.parse(localStorage.getItem("user")).user_id || {},
      org_id:JSON.parse(localStorage.getItem("user")).organisation_id || {},
      showSetReminderDrawer: false,
      isTaskDrawerOpen: false,
      selectedLead: null,
      collaboratorsNextURL: null,
      directNextURL: null,
      seeMore: false,
      componentName: "addOrEditTask",
      detailsDrawer: false,
      color: "",
    };
  },
  computed: {
    ...mapState(useUiStore, {
      isMobileView: state => state.isMobileView,
    }),
    drawerDirection() {
      if(this.isMobileView) {
        return 'btt';
      } else {
        return 'rtl';
      }
    },
    canShowTaskDetails() {
      return this.info.assignee_org_id == this.org_id;
    },
    computeStatusColor(){
      let colors = {
        new: '#63676f',
        in_progress: '#03A9F4',
        completed: '#20ce19',
      }
      return colors[this.info.status]
    },
    computeStatusIcon(){
      if(this.info.status == 'completed'){
        return 'check-circle-fill'
      }else if(this.info.status == 'in_progress'){
        return 'record-circle-fill'
      }else {
        return 'check-circle'
      }
    },
    hasDescription() {
      return Boolean(this.info.description);
    },
  },
  methods: {
    handleAllowDocUpload(){
      this.allowUpload = true;
    },
    handleOnClick() {
      this.selectedLead.isTask = true;
      this.reminderDetails = this.selectedLead.reminder_details;
      //open task drawer and pass the task value
    },
    async onSave(reminderDetails) {
      this.reminderDetails = {
        reminder_sent_at: reminderDetails.reminder_sent_at,
        notes: reminderDetails.notes,
      };
      if (this.selectedLead.id) {
        this.setReminder(this.selectedLead.id);
      }
      this.showSetReminderDrawer = false;
    },
    onSetReminder() {
      this.setKey++;
      this.showSetReminderDrawer = true;
    },
    async setReminder(id) {
      let response = await API.TASKS.POST_SETREMINDER_TASK(id, reminderDetails);
      this.loadingStateButton = false;
      this.$message({
        showClose: true,
        message: "Reminder has been set successfully.",
        type: "success",
        center: true,
      });
    },
    onDeleteTask(taskId) {
      this.$emit('onDeleteTask', taskId)
      this.isTaskDrawerOpen = false
    },
    handleTaskDrawerClose() {
      this.isTaskDrawerOpen = false;
      this.selectedLead = null;
      this.$emit('taskDrawerClosed')
    },
    backButtonRoute() {
      this.$router.goBackOrGoHome();
    },
    showDrawer(type) {
      if (type === "lead") {
        this.isDrawerOpen = !!type;
      } else {
        this.isTaskDrawerOpen = !!type;
      }
    },
 
    updateTask(data) {
      this.$emit('individualTaskUpdated', {...data, assignee_org_id: this.info.assignee_org_id})
    },

    async togglePriority(id, option) {
      let priority = option == "default" ? "high" : "default";
      try {
        const formData = new FormData();
        formData.append("priority", priority);
        await API.TASKS.UPDATE_TASK(id, formData);
        this.$props.info.priority = priority;
      } catch (err) {
        console.error(err);
      }
    },
    textInitial,
    async onshowTaskDetails() {
      let filteredData = {};
      this.isTaskDrawerOpen = true;
      let res = await API.TASKS.FETCH_TASK_BY_ID(this.info.id);
      filteredData = res.data;
      filteredData.type = "task";
      filteredData.isTask = true;
      this.selectedLead = filteredData;
    },
    async toggleStatus(id, status) {
      let newStatus;
      if(status == 'in_progress' || status == 'new'){
        newStatus = 'completed'
      }else{
        newStatus = 'new'
      }
      let completed = newStatus == 'completed' ? true : false
      try {
        const formData = new FormData();
        formData.append("is_completed", completed);
        formData.append("status", newStatus );

        const response = await API.LEADS.UPDATE_TASK(id, formData);
        this.$props.info.is_completed = response.data.is_completed
        this.$props.info.status = response.data.status
      } catch (e) {
        console.log(e);
        this.$message({
          showClose: true,
          message: "There was an unknown error while updating task status",
          type: "error",
          center: true,
        });
      }
    },
    getTruncatedDescription(description) {
      let linesArr = description.split("\n");
      if (linesArr.length > 1) {
        if (linesArr[0].length > Math.floor(this.$props.countSize) / 2) {
          this.seeMore = true;
          return (
            linesArr[0].substring(0, Math.floor(this.$props.countSize) - 4) +
            "..."
          );
        }
        if (linesArr.length > 2) {
          this.seeMore = true;
          return `${linesArr[0]}\n${linesArr[1].substring(
            0,
            Math.floor(this.$props.countSize) / 3
          )}...`;
        } else {
          if (linesArr[1].length < Math.ceil(this.$props.countSize / 2)) {
            return `${linesArr[0]}\n${linesArr[1]}`;
          }
        }
      } else {
        if (description.length > this.$props.countSize) {
          this.seeMore = true;
          return description.substring(0, this.$props.countSize) + "...";
        } else {
          return description;
        }
      }
    },
    isMultiLines(value) {
      if (value.split("\n")[1]) return true;
      return false;
    },
    generateAvatarColor
  },
};
</script>
<style scoped>
.container {
  padding: 0;
  margin: 0;
}
.border-div {
  height: 0px;
  border-top: 2px dashed #cccccc;
  margin-left: 1rem;
}

.container:nth-last-child(1 of .container) >>> .border-div {
  border-top: none;
}
.avatar-img {
  border-radius: 50%;
}
.description,
.description-more {
  margin-top: 5px;
  color: #909399;
  opacity: 0;
  height: 0;
  transition: opacity 0.3s ease-in-out, height 0.3s ease-in-out;
  overflow: hidden;
  word-wrap: break-word;
}
@media (max-width: 768px){
  .task-div .description, .description-more{
    opacity: 1;
  }
  .task-div .info-div {
    margin-top: 10px;
  }
  .task-div .description{
  height: 35px;
  position: relative;
  margin-bottom: -18px;
}
  .task-div .description-more{
  height: 45px;
  position: relative;
  margin-bottom: -15px;
}
}
.see-more {
  color: #409eff;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  display: inline-block;
}

.icon-div {
  position: absolute;
  top: 1.2rem;
  left: 1.1rem;
  cursor: pointer;
}
.avatar-div {
  margin: 0.7rem 0 0.3rem;
}
.info-div {
  margin-top: 18px;
  margin-left: 1rem;
  padding-left: 2rem;
  transition: 0.2s;
  margin-bottom: 15px;
}
div >>> .el-col-16 {
  padding-left: 0 !important;
  margin-left: 0;
}
.disabled {
  margin-top: 18px;
  margin-left: 1rem;
  padding-left: 2rem;
  transition: 0.2s;
  margin-bottom: 15px;
  cursor: not-allowed; /* Set cursor to not-allowed when disabled */
  opacity: 0.5; /* Optionally reduce opacity to indicate disabled state */
}
.disabled p {
  cursor: not-allowed;
  font-weight: 400;
  font-size: 14px;
}

.disabled h3 {
  font-weight: 500;
  font-size: 16px;
  cursor: not-allowed;
  user-select: none;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
.task-div {
  padding: 0 !important;
  margin: 0 !important;
}
.task-div:hover .description-more {
  opacity: 1;
  height: 45px;
  position: relative;
  margin-bottom: -15px;
}
.task-div:hover .description {
  opacity: 1;
  height: 35px;
  position: relative;
  margin-bottom: -18px;
}
.task-div:hover .info-div {
  margin-top: 10px;
}
.info-div h3 {
  cursor: pointer;
  user-select: none;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
div >>> .el-row {
  padding-bottom: 0;
}
.bodyContent {
  display: flex;
  align-items: center;
  min-height: 60px;
  margin-left: 20px;
  width: 175px;
}
.leadSummarybodyContent {
  display: flex;
  padding-left: 1.5rem;
  align-items: center;
  min-height: 50px;
  width: 175px;
}
.ownerN {
  display: grid;
  place-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}
.info-div p {
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
}

.info-div h3 {
  font-weight: 500;
  font-size: 16px;
}
.container:nth-last-child(1) >>> .border-div {
  border: 0 !important;
  margin-top: 20px;
}
.priority-flag {
  position: relative;
  left: -20px;
}
@media (max-width: 768px){
  .border-div{
     border-top: 2px solid #cccccc;
  }
}
</style>
