
    <div class="all-container">
        <el-dialog 
        :title="header"
         :visible.sync="openDrawer" 
          append-to-body 
        :close-on-click-modal="false"
          :before-close="onCloseClick"
          class="dialog">
          <div class="email-dialog-conatiner">
           <div class="top-email-format">
              <div class="main-container">
                    <div>
                        <p class="main-title">
                        Title
                        </p>
                        <el-input
                            v-model="title"
                            type="text" 
                            :style="dataTimePickupStyles"
                            placeholder="Please Enter Title"
                            maxlength="100" 
                            class="input-el"
                           /> 
                    </div>
              </div>
              <div class="main-container">
                    <div class="date-container">
                        <div class="inner-container">
                            <p class="main-title">
                                Date
                                <span class="required-class">
                                    *
                                </span>
                            </p>
                             <div>
                                 <el-date-picker
                                     v-model="date"
                                     type="date"
                                     placeholder="Select Date"
                                     style="width: 100%;"
                                     :style="dataTimePickupStyles"
                                      :picker-options="datePickerOptions"
                                         :clearable="false"
                                 />
                             </div>
                        </div>
                        <div class="inner-container">
                            <p class="main-title">
                                Start Time
                                <span class="required-class">
                                    *
                                </span>
                            </p>
                             <div>
                                <el-tooltip 
                                class="item" 
                                effect="dark" 
                                :content="!date ? 'Add Date' : 'Add Start Time'"
                                 placement="top-start"
                                 >
                                    <el-time-select
                                        v-model="startTime"
                                        placeholder="Select time"
                                        :style="dataTimePickupStyles"
                                        :picker-options="startPickerOption"
                                        :disabled="!date"
                                           :clearable="false"
                                    />
                                </el-tooltip>
                             </div>
                        </div>
                        <div class="inner-container">
                            <p class="main-title">
                                End Time
                                <span class="required-class">
                                    *
                                </span>
                            </p>
                             <div>
                                <el-tooltip 
                                class="item" 
                                effect="dark" 
                                :content="!startTime && !date ? 'Add Date and Time' : !startTime ? 'Add Start Time':'Add End Time'"
                                 placement="top-start"
                                 >
                                    <el-time-select
                                        v-model="endTime"
                                        :style="dataTimePickupStyles"
                                        placeholder="Select time"
                                        :picker-options="endPickerOption"
                                        :disabled="!startTime || !date"
                                        :clearable="false"
                                    />
                                    </el-tooltip>
                             </div>
                        </div>
                    </div>
              </div>
              <!-- Attendees---------------------------------------------------------------------------------- -->

              <div class="email-header">
                <p class="main-title">
                    Attendees 
                    <span class="required-class">
                                    *
                                </span>
                    : 
                </p>
                <div
                v-for="(item, index) in avatar"
                :key="index"
                 class="email-details-container"
                 >
                 <el-tooltip
                    class="item" 
                   effect="dark"
                    :content="item"
                     placement="top-start"
                     >
                   <p 
                   class="email-send-to"
                   :style="{ color:item == admin ? '#777' : '#409EFF;' }"
                   >
                   {{ item }}
                </p>
                   </el-tooltip>
                   <el-tooltip
                    class="item" 
                    effect="dark"
                    :content="item == admin ? 'Cannot be removed': 'Remove Email'"
                     placement="top-start"
                     >
                        <img
                         src="./assets/X.svg"
                         alt="close"
                         class="close_image_class"
                         :style="{cursor: item == admin ? 'not-allowed' : 'pointer' }"
                         @click="handleRemoveAttendees(item)"
                         >
                    </el-tooltip>
                </div>
                <div>
                    <div class="email_input_new">
                    <input 
                        v-if="showEmailInputField"
                        ref="emailInput"
                        v-model="addedAttendees"
                        name="email"
                        type="text"
                        class="input_email"
                        :style="handleEmailError()"
                        @blur="handleAddAttendees()"
                        @focus="handleRemoveError()"
                        @keydown="handleKeyEvents($event)"
                    >
                </div>
                </div>
      
                <el-tooltip
                    class="item" 
                   effect="dark"
                     :content="!showEmailInputField ? 'Add Email' : checkEmailValidation(addedAttendees) ? 'Disabled':'Remove Email'"
                     placement="top-start"
                     >
                     <div 
                     class="add-more-email"
                     :style="{ 
                        marginLeft: showEmailInputField ? '10px' : '0px' , 
                     cursor:checkEmailValidation(addedAttendees) ? 'not-allowed': 'pointer'
                     }"
                     @click="addNewEmailField()"
                     >
                     <i 
                     v-if="!showEmailInputField"
                     class="el-icon-plus"
                     />

                     <i 
                     v-else 
                     class="el-icon-close"
                     />
                     </div>
                    </el-tooltip>
            </div>

              <!-- body -->
              <div 
            class="email_body_class"
             >
                <el-input
                    v-model="description"
                    type="textarea"
                    :autosize="false"
                    show-word-limit
                    placeholder="Type details for this new meeting"
                    />
            </div>
           </div>
           <div class="bottom-email-format">
            <div class="bottom-left-container">
            </div>
            <div 
            class="bottom-right-container"
            style="margin-left: auto;"
            >
            <el-tooltip
             class="item"
             effect="dark" 
            :content="contentMeeting"
             placement="top-start"
             >
            <el-button
            type="primary"
            :disabled="handleSaveButtonDisability"
            @click="onOperationalButtonClick"
            >
            <p v-if="!loading">
                        Save
                    </p>
                    <i 
                    v-else
                    class="el-icon-loading" 
                     />
            </el-button>
        </el-tooltip>
            </div>
           </div>
          </div>
        </el-dialog>
</div>
