
<div class="inputview-container">
  <p class="title">
    {{ inputDetails.title }}
    <span v-if="inputDetails.isStar" class="astrisk"> *</span>
  </p>
  <el-tooltip :content="inputData" placement="top-start" :hide-after="0" :disabled="!showTooltip(inputData)">

  <el-input
   @input="nameValidation"
    :placeholder="inputDetails.placeholder"
    maxlength="100"
    v-model="inputData"
  ></el-input>
  </el-tooltip>
  <p v-if="errorMessage" style="color: red;">Maximun characters limit allowed is 100</p>
</div>
