<!-- Custom overlay for any component which provides the focus background -->
<template>
    <div v-if="showOverlay" class="custom-overlay"></div>
  </template>
  
  <script>
  export default {
    props: {
      showOverlay: {
        type: Boolean,
        default: false,
      },
    },
  };
  </script>
  
  <style scoped>
  .custom-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
    z-index: 500; /* Adjust the z-index as needed */
  }
  </style>
  