
<div id="parentContainer">
    <el-dialog :visible="bulkLeadImportState.isBulkLeadUploadedPopupVisible" :close-on-click-modal="false"
        title="Import Leads" @close="onDialogClose">

        <div class='container' v-if="bulkLeadImportState.isLeadsUploadSuccessfully">
            <img src="./assets/successIcon.svg" class="tickIcon" />
            <p class="congrats">
                Congrats!<br />
                <span class="content">Your leads has been successfully imported.</span>
            </p>
            <el-button type="primary commonBtnClass" class="okBtn" @click="onDialogClose()">Ok</el-button>
        </div>

        <div class='container' v-if="bulkLeadImportState.isLeadUploadedError">
            <img src="./assets/errorIconTwo.svg" />
            <p class="oops">
                Oops!<br /> <span class="contentTwo">There was some error while importing your lead.</span>
            </p>
            <div class="">
                <el-button class="overBtn commonBtnClass startOverBtn" @click="startOverBtnFunc()">Start
                    Over</el-button>
                <el-button type="primary" class="commonBtnClass tryAgainBtn" @click="tryAgainFunc()">Try
                    Again</el-button>
            </div>
        </div>

        <div class='container' v-if="bulkLeadImportState.isCancelBtnClicked">
            <img src="./assets/exclamationMark.svg" />
            <p class="content">Are you sure you want to cancel?</p>
            <div class="">
                <el-button class="overBtn commonBtnClass startOverBtn"
                    @click="bulkLeadImportState.isBulkLeadUploadedPopupVisible = false">Cancel</el-button>
                <el-button type="primary" class="commonBtnClass tryAgainBtn" @click="closeSSE">Yes</el-button>
            </div>
        </div>
    </el-dialog>
</div>
