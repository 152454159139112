
<div>
  <!-- <label for="dynamicDropdown">Select an option:</label> -->
  <el-dropdown @command="changeChart">
    <span class="el-dropdown-link">
      {{ selectedFilter }}<i class="el-icon-arrow-down el-icon--right"></i>
    </span>
    <template v-slot:dropdown>
    <el-dropdown-menu>
      <el-dropdown-item command="Weekly">This Week</el-dropdown-item>
      <el-dropdown-item command="Monthly">This Month</el-dropdown-item>
      <el-dropdown-item command="Quarterly">This Quarter</el-dropdown-item>
      <el-dropdown-item command="Yearly">This Year</el-dropdown-item>
    </el-dropdown-menu>
    </template>
  </el-dropdown>
  <!-- <select
    id="dynamicDropdown"
    v-model="selectedOption"
    @change="$emit('selectionMade', selectedOption)"
  >
    <option
      v-for="option in dataOptions"
      :value="option.value"
      :key="option.value"
    >
      {{ option.label }}
    </option>
  </select> -->
  <!-- <p>You selected: {{ selectedOption }}</p> -->
</div>
