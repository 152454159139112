<template>
  <div class="pop">
    <el-popover
      placement="bottom-end"
      width="auto"
      trigger="click"
      v-model="popVisible"
      popper-class="popover-custom"
      id="kpop"
    >
      <template v-slot:reference>
      <p style="cursor: pointer">
        <b-icon
          :style="{
            marginRight: '5px',
            color: 'black',
          }"
          :icon="'three-dots-vertical'"
        ></b-icon>
      </p>
      </template>
      <div @mouseleave="handleMouseleave" class="popover-content">
        <div v-for="(data, inx) in options" :key="inx">
          <p class="content-container" @click="handleSelect(data)">
            <span v-if="data.icon" class="icons"
              ><img :src="getIcon(data.icon)" alt=""
            /></span>
            {{ data.label }}
          </p>
        </div>
      </div>
    </el-popover>
  </div>
</template>

<script>
import EditIcon from "./assests/edit.svg";
import CopyIcon from "./assests/copy.svg";
import DeleteIcon from "./assests/deleteIcon.svg";

export default {
  emits: ["open-options"],
  data() {
    return {
      popVisible: false,
      options: [
        { label: "Edit Widget", prop: "edit", icon: "edit" },
        { label: "Copy Widget", prop: "copy", icon: "copy" },
        { label: "Delete Widget", prop: "delete", icon: "delete" },
      ],
      EditIcon,
      CopyIcon,
      DeleteIcon,
      iconsData: {
        edit: EditIcon,
        copy: CopyIcon,
        delete: DeleteIcon,
      },
    };
  },
  props: {
    isPopoverOpen: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    getIcon(icon) {
      return this.iconsData[icon];
    },
    handleMouseleave(){
      this.popVisible = false;
    },
    handleSelect(val) {
      this.popVisible = false;
      this.$emit("open-options", val);
    },
  },
  watch: {
    // isPopoverOpen: {
    //   immediate: true,
    //   handler(val) {
    //     // this.popVisible = val;
    //   },
    // },
  },
};
</script>

<style scoped>
.popover-content {
  padding: 12px;
  box-shadow: 5px 5px 12px grey;
}
.content-container {
  padding-bottom: 0.5rem;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 400;
  color: #222;
}
.icons {
  color: #222;
  padding-right: 0.5rem;
}

#kpop >>> .el-popover {
  border : none !important;
}

</style>
