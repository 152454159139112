import axios from "axios";

export default {
    FETCH_ALL_CONTRACT_TEMPLATES(param) {
        if(param){
            return axios.get(`/api/organisation/contracts/?sort_by=${param}`);
        }
        else{
            return axios.get(`/api/organisation/contracts/`);
        }
    },
    FETCH_CONTRACT_TEMPLATES_WITH_QUERY(query) {
        return axios.get(`/api/organisation/contracts/?query=${query}`)
    },
    LOAD_MORE_CONTRACT_TEMPLATES(url) {
        return axios.get(url);
    },
    CREATE_CONTRACT_TEMPLATE(postData){
        return axios.post(`/api/organisation/contracts/`,postData);
    },
    UPDATE_CONTRACT_TEMPLATE(id,patchData){
        return axios.patch(`/api/organisation/contracts/${id}/`,patchData);
    },
    FETCH_CONTRACT_TEMPLATE(id){
        return axios.get(`/api/organisation/contracts/${id}/`);
    },
    FETCH_ALL_PLACEHOLDERS_OPTIONS(){
        return axios.get(`/api/organisation/contracts/placeholders/`);
    },
    FETCH_ADDED_PLACEHOLDERS_WITH_ACTUAL_VALUES(postdata){
        return axios.post(`/api/organisation/contracts/placeholder_values/`,postdata);
    },
    DELETE_CONTRACT_TEMPLATE(id) {
        return axios.delete(`/api/organisation/contracts/${id}/`)
    }, 
    SEND_CONTRACT(postData){
        return axios.post(`/api/docusign/send_contract/`,postData);
    },
    CANCEL_CONTRACT(id,postData){
        return axios.post(`/api/contracts/${id}/cancel_contract/`,postData);
    },
    SAVE_CUSTOM_PLACEHOLDERS(postData){
        return axios.post(`/api/organisation/contracts/placeholders/`,postData);
    },
    SAVE_LATEST_PLACEHOLDERS(postData){
        return axios.post(`/api/organisation/contracts/latest_placeholders/`,postData);
    },
    FETCH_LATEST_PLACEHOLDERS(){
        return axios.get(`/api/organisation/contracts/latest_placeholders/`);
    },

  };
  