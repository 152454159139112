import * as THREE from 'three';
import OuterEdge from "../../objects/model/smartroof/OuterEdge";
import * as utils from '../../utils/utils';
import azimuthArrowLeft from "../../../components/ui/saleStudio/assets/azimuthArrowLeft.svg";
import {arrayOfAzimuthNumberImages} from '../../../components/ui/saleStudio/azimuthNumbersimages';
import primaryEdgeArrow from '../../../components/ui/saleStudio/assets/primaryEdgeArrow.png';
import { Line2 } from 'three/examples/jsm/lines/Line2';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry';
import createBufferGeometry from '../../utils/meshUtils';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial';
import { Vector3 } from 'three';


export default class SalesModeOuterEdge extends OuterEdge {
    constructor(parent, stage, outlinePoint1 = null, outlinePoint2 = null, outlinePoint1Index, outlinePoint2Index, height = null, isPitched = true, tilt = 20, smartRoofFace = null) {
        super(parent, stage, outlinePoint1 , outlinePoint2, outlinePoint1Index, outlinePoint2Index, height, isPitched, tilt, smartRoofFace);
        this.salesEdge = '';
        this.setback = 0.4572;
        this.azimuthDirectionFlag = true;
        this.clicked = false;
        this.azimuthSelection = this.makePrimaryEdgeSelectionSalesMode();
        this.azimuthSelectionNumber = this.makePrimaryEdgeNumbersSalesMode();
        this.primaryEdgeArrow = this.makePrimaryEdgeArrow();
        this.updatePrimaryEdgeArrow();
        this.primaryEdgeArrow.visible = false;
        this.objectsGroup.add(this.primaryEdgeArrow);
        this.azimuthSelection.visible = false;
        this.azimuthSelectionNumber.visible = true;
        
        this.salesModehighlightColor = 0xffa500;

        this.salesModehighlightedLine = new Line2();
        this.salesModehighlightedLine.visible = false;

        this.salesModehighlightedLine.geometry = new LineGeometry();
        this.updateFacePlane();
        this.updateWallPlane();
        this.makeOuterEdge();
        this.hideObject();
    }

      // Changing the azimuth arrow by clicking the arrow mesh
      async azimuthArrowClick() {
        this.azimuthDirectionFlag = !this.azimuthDirectionFlag;
        this.clicked = true;
   
        const angle  = 180 - this.smartRoofFace.tilt;

        await this.smartRoofFace.updateObject({ tilt: angle });
        // remove the childrens of the face when the azimuth is inverted
        const i = 0;
        while (this.smartRoofFace.getChildren().length > i) {
            this.smartRoofFace.getChildren()[i].removeObject();
        }

        await this.belongsTo.placeObject();
        
        if (!this.azimuthDirectionFlag) {
            this.belongsTo.dispalyTiltChanged = true;
            this.smartRoofFace.updateSalesAzimuth();
        }
        else {
            this.belongsTo.dispalyTiltChanged = false;
        }

        this.stage.selectionControls.setSelectedObject(this.belongsTo);
    }

    makeEdgeMesh() {
        this.primaryEdgeArrow = this.makePrimaryEdgeArrow();
        this.primaryEdgeArrow.visible = false;

        this.updateSalesModeArrow();
        this.updatePrimaryEdgeArrow();
        this.belongsTo.outerEdgesMesh.push(this.azimuthSelection);
        this.objectsGroup.add(this.azimuthSelection);
        this.objectsGroup.add(this.azimuthSelectionNumber);
        this.objectsGroup.add(this.primaryEdgeArrow);

        this.outerEdgeMesh = new THREE.Line(this.outerEdgeGeometry, this.outerEdgeMaterial);
        this.outerEdgeMesh.name = 'Outer Edge Mesh';
        this.objectsGroup.add(this.outerEdgeMesh);
        this.objectsGroup.add(this.highlightedLine);
        this.objectsGroup.add(this.salesModehighlightedLine);
        this.belongsTo.measurementTextMesh.push(this.measurementText.textMesh);
        this.belongsTo.outerEdgesMesh.push(this.outerEdgeMesh);
    }

    updateScale = () => {
        return;
    }

    updateOuterEdge() {
        this.outerEdgeGeometry.setFromPoints([this.outlinePoint1.getPosition(), this.outlinePoint2.getPosition()])
        this.point1 = this.outlinePoint1.getPosition();
        this.point1.z = this.height;
        this.point2 = this.outlinePoint2.getPosition();
        this.point2.z = this.height;
        this.outerEdgeGeometry.translate(0, 0, 5);
        this.outerEdgeGeometry.attributes.position.needsUpdate = true;
        if (this.highlightedLine) {
            const points = [];
            points.push(this.point1.x, this.point1.y, this.point1.z);
            points.push(this.point2.x, this.point2.y, this.point2.z);
            this.highlightedLine.geometry.setPositions(points);
            this.updateHighlightResolution();
        }
        if (this.salesModehighlightedLine) {
            const points = [];
            points.push(this.point1.x, this.point1.y, this.point1.z);
            points.push(this.point2.x, this.point2.y, this.point2.z);
            this.salesModehighlightedLine.geometry.setPositions(points);
        }
        
        this.updateFacePlane();
        this.updateWallPlane();
        this.measurementTextUpdate();
        this.updateSalesModeArrow();
        this.updatePrimaryEdgeArrow();
        this.primaryEdgeArrow.rotation.z += Math.PI;

        // to remove the previous smartroof setback and primary edge meshes
        if (!this.isPrimaryEdge) {
            this.smartRoofFace.hideSetback();
            this.removelightColor();
        }
        // to show only the active primary edge
        else {
            this.updateHighLightColor();
        }
    }

    updateSalesModeArrow() {
        const check = utils.checkClockwise([this.outlinePoint1.getPosition(),this.outlinePoint2.getPosition()]);
        const outlineP1 = this.outlinePoint1.getPosition();
        const outlineP2 = this.outlinePoint2.getPosition();
        let scalar = 10 / this.stage.getNormalisedZoom();
        scalar = (scalar > 0.7 ? scalar : 0.7 ) ;
        scalar = scalar > 1.2 ? 1.2 : scalar;
        const centerPos = this.getPerpendicularPoint(new THREE.Vector3(outlineP1.x,outlineP1.y,20),new THREE.Vector3(outlineP2.x,outlineP2.y,20), -scalar);
        let  direction = this.outlinePoint1.getPosition().clone().sub(this.outlinePoint2.getPosition().clone()).normalize();
        // Create a new Euler object and set its rotation to align with the direction vector
        const euler =  direction;
        this.azimuthSelection.position.set(centerPos.x ,centerPos.y ,20);
        this.azimuthSelectionNumber.position.set(centerPos.x ,centerPos.y ,20);

        // Normalize the direction vector
        direction.normalize();

        // Calculate the rotation axis
        var rotationAxis = new THREE.Vector3();
        rotationAxis.crossVectors(new THREE.Vector3(0, 1, 0), direction);
        rotationAxis.normalize();

        // Calculate the angle of rotation
        var angle = Math.acos(new THREE.Vector3(0, 1, 0).dot(direction));

        // Create a quaternion rotation
        var quaternion = new THREE.Quaternion();
        quaternion.setFromAxisAngle(rotationAxis, angle);

        // Apply the rotation to the box mesh
        this.azimuthSelection.setRotationFromQuaternion(quaternion);
    }

    updatePrimaryEdgeArrow() {
        const outlineP1 = this.outlinePoint1.getPosition();
        const outlineP2 = this.outlinePoint2.getPosition();
        const centerPos =new THREE.Vector3(outlineP1.x,outlineP1.y,20).add(new THREE.Vector3(outlineP2.x,outlineP2.y,20)).multiplyScalar(0.5);
        const crossProduct = new THREE.Vector3().crossVectors(outlineP1, outlineP2);

        // The cross product gives a vector that is perpendicular to both input vectors
        const perpendicular = crossProduct.clone().normalize();

        // Multiply the perpendicular vector by 1 and add it to the midpoint to get the final point
        const finalPoint = new THREE.Vector3().addVectors(centerPos, perpendicular.multiplyScalar(0.48))
        this.primaryEdgeArrow.position.set(finalPoint.x ,finalPoint.y ,20);  

        let  direction = this.outlinePoint1.getPosition().clone().sub(this.outlinePoint2.getPosition().clone()).normalize();
        // Create a new Euler object and set its rotation to align with the direction vector
        const euler =  direction;

        // Normalize the direction vector
        direction.normalize();

        // Calculate the rotation axis
        var rotationAxis = new THREE.Vector3();
        rotationAxis.crossVectors(new THREE.Vector3(0, 1, 0), direction);
        rotationAxis.normalize();
        rotationAxis.negate();

        // Calculate the angle of rotation
        var angle = Math.acos(new THREE.Vector3(0, 1, 0).dot(direction));

        // Create a quaternion rotation
        var quaternion = new THREE.Quaternion();
        quaternion.setFromAxisAngle(rotationAxis, Math.PI- angle);

        // Apply the rotation to the box mesh
        this.primaryEdgeArrow.setRotationFromQuaternion(quaternion);
        if (this.clicked) {
            if(!this.azimuthDirectionFlag){
                this.primaryEdgeArrow.rotation.z += Math.PI;
            }
        }
    }

    makePrimaryEdgeSelectionSalesMode() {
        const geometry = new THREE.CircleGeometry(0.3,20);
        const textureLoader = new THREE.TextureLoader();
        const texture = textureLoader.load(azimuthArrowLeft);
        // Create a new Mesh object with the geometry and a basic material
        const material = new THREE.MeshBasicMaterial({map: texture});
        material.opacity = .5
        const mesh = new THREE.Mesh(geometry, material);
        return mesh;
    }

    makePrimaryEdgeNumbersSalesMode() {
        const totalEdge = this.belongsTo.outlinePoints.length - 1;
        const geometry = new THREE.CircleGeometry(0.5, 15, .00001);
        const textureLoader = new THREE.TextureLoader();
        const texture = textureLoader.load(arrayOfAzimuthNumberImages[this.outlinePoint1Index]);
        // Create a new Mesh object with the geometry and a basic material
        const material = new THREE.MeshBasicMaterial({map: texture});
        material.opacity = .5
        const mesh = new THREE.Mesh(geometry, material);
        return mesh;
    }

    makePrimaryEdgeArrow() {
        this.objectsGroup.remove(this.primaryEdgeArrow);
        const geometry = new THREE.CircleGeometry(0.5,20, .00001);
        const textureLoader = new THREE.TextureLoader();
        const texture = textureLoader.load(primaryEdgeArrow);
        // Create a new Mesh object with the geometry and a basic material
        const material = new THREE.MeshBasicMaterial({
            color: 0xffffff,
            map: texture,
        });
        material.opacity = .5
        const mesh = new THREE.Mesh(geometry,material);
        mesh.userData.isArrow =true;
        mesh.userData.edge = this;
        return mesh;
    }

    makeEdgeGeometry() {
        const EPSILON = 5;
        this.outerEdgeGeometry = createBufferGeometry();
        const raisedPoint1 = new Vector3(this.point1.x, this.point1.y, this.point1.z + EPSILON);
        const raisedPoint2 = new Vector3(this.point2.x, this.point2.y, this.point2.z + EPSILON);
        this.outerEdgeGeometry.setFromPoints([raisedPoint1, raisedPoint2]);
        if (this.highlightedLine) {
            const points = [];
            points.push(raisedPoint1.x, raisedPoint1.y, raisedPoint1.z);
            points.push(raisedPoint2.x, raisedPoint2.y, raisedPoint2.z);
            this.highlightedLine.geometry.setPositions(points);
        }
        if (this.salesModehighlightedLine) {
            const points = [];
            points.push(raisedPoint1.x, raisedPoint1.y, raisedPoint1.z);
            points.push(raisedPoint2.x, raisedPoint2.y, raisedPoint2.z);
            this.salesModehighlightedLine.geometry.setPositions(points);
        }
    }
    makeEdgeMaterial() {
        // make blue outer edges
        this.outerEdgeMaterial = new THREE.LineBasicMaterial({
            color: 0xF0FFFF,
            linewidth: 1,
        });
        if (this.highlightedLine) { 
            this.highlightedLine.material = new LineMaterial({
            color: new THREE.Color(this.highlightColor),
            linewidth: 0.0001,
            });
            this.updateHighlightResolution();
        }
        if (this.salesModehighlightedLine) { 
            this.salesModehighlightedLine.material = new LineMaterial({
            color: new THREE.Color(this.salesModehighlightColor),
            linewidth: 0.005,
            });
        }
    }

    hideObject() {
        if (this.measurementText) this.measurementText.hideObject();
        this.objectsGroup.visible = false;
        if(this.azimuthSelection)this.azimuthSelection.visible = false;
    }

    showObject() {
        if (this.status && !this.stage.visualManager.in3D) {
            if (this.measurementText) this.measurementText.showObject();
            this.objectsGroup.visible = true;
            
        }
        // updaing the scale for sales mode numbers 
        this.updateSalesModeArrow();
        if(this.azimuthSelection)this.azimuthSelection.visible = true;
    }

    showArrows() {
        this.azimuthSelectionNumber.visible = false;
    }

    hideArrows() {
        this.azimuthSelectionNumber.visible = true;
    }
    updateHighLightColor() {
        super.updateHighLightColor();
        this.primaryEdgeArrow.visible =true;
    }
    removelightColor() {
        super.removelightColor();
        this.primaryEdgeArrow.visible =false;
    }
    getPlaneNormal() { 
            const planeNormalAngle = utils.deg2Rad(90 + this.smartRoofFace.tilt);
            const planeNormal = (this.getInwardHorizontalNormal().applyAxisAngle(this.getEdgeVector(), planeNormalAngle)).normalize();
            return planeNormal;
    }

}