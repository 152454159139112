import axios from 'axios';
import {
	STAGE_REPORT_LAMBDA_ENDPOINT,
	BASE_URL_FOR_REPORT_IMAGES
} from '../../constants';
import API from "../api";
import Pako from 'pako';

export default {

    POST_DESIGN_VERSION(postData) {
        return axios.post('/api/design-versions/', postData);
    },

    PATCH_DESIGN_VERSION_SCENE(designVersionId, [patchData, isDesignChanged]) {
		const compressedData = Pako.deflate(JSON.stringify(patchData.scene));
		return axios.patch(`/api/design-versions/${designVersionId}/scene/?clear_cache=${isDesignChanged}`, compressedData);
    },

    PATCH_STUDIO_IMAGE_ID(versionId, patchData) {
        return axios.patch(`api/design-versions/${versionId}/`, patchData);
    },
    FETCH_IMAGES_FOR_REPORT(versionId,patchData){
        return axios.post(`api/design-versions/${versionId}/stage_report/`, patchData);
    },
	async FETCH_IMAGES_FROM_LAMBDA(payload) {
		let resp = await fetch(STAGE_REPORT_LAMBDA_ENDPOINT, {
			method: "POST",
			body: JSON.stringify(payload),
		})

        if (!resp.ok) {
            throw await resp.json()
        }

		let data = await resp.json()
		return data
	},
    // This API call is different from the usual API calls (it has try catch blocks) because
    // it is emulating the layout API call that used to the exist in the backend.
	async FETCH_LAYOUT_IMAGE_FROM_LAMBDA(designVersionId) {
		let payload = {
			base_url: BASE_URL_FOR_REPORT_IMAGES,
			detailed_layout: true,
			reference_id: designVersionId,
		}

		try {
			let data = await API.DESIGN_VERSIONS.FETCH_IMAGES_FROM_LAMBDA(payload)
			if (data.detailed_layout) {
				// Cache the fetched layout image
				API.DESIGN_VERSIONS.CACHE_REPORT_IMAGES(designVersionId, {
					layoutImageUrl: data.detailed_layout
				})
				return data
			} else {
				return
			}
		} catch (err) {
			console.error(err)
			return
		}
	},
    CACHE_REPORT_IMAGES(versionId, patchData) {
        return axios.post(`api/design-versions/${versionId}/save_report_files/`, patchData);
    },
	CLEAR_CACHED_SHADOW_IMAGES(versionId, payload) {
        return axios.patch(`api/design-versions/${versionId}/clear_cached_shadow_images/`, payload);
	},
};
