
<div class="">
    <Navbar @handleToggleSideBar="handleSidebar" />
    <main class="main-controller">
        <div class="backdrop"></div>
        <Sidebar :isSidebarOpen="isSidebarOpen" />
        <section :class="['right_section', isCrmUser() ? 'right_sectionCRM' : '']">
            <div class="content_section">
                <div class="filter_section">
                    <div class="title">Payment Schedule</div>
                </div>
                <ProjectTimeline />
            </div>
        </section>
    </main>
</div>
