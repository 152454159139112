
<div class="card" @click="handleDrildown">
  <div style="width: 100%" v-if="kpiData">
    <div class="card-header">
      <div class="title">
        <p>{{ kpiData.name }}</p>

        <p v-if="editMode" style="cursor: pointer">
          <CommonPopover @open-options="handleOpenMode" />
        </p>
      </div>
    </div>
    <div class="card-content" style="margin-top: -0.8rem">
      <div class="values">
        <div class="value">
          <div
            style="
              font-family: 'Switzer';
              font-style: normal;
              font-weight: 600;
              font-size: 24px;
              line-height: 24px;
            "
          >
            <h3 v-if="metricType === 'currency'">{{ formatNumber }}</h3>
            <h3 v-else-if="metricType === 'number'">{{ formatCount }}</h3>
           
          </div>
          <div :style="greenRedText">
            <h5
              style="
                font-weight: 400;
                font-size: 14px;
                line-height: 14px;
                display: flex;
              "
              v-if="kpiPercentage||kpiPercentage===0"
            >
              <span style="display: flex; justify-content: end">
                <b-icon :style="greenRedText" :icon="trendIconClass"></b-icon
              ></span>
            
              {{ kpiPercentage&&kpiPercentage!==0? kpiPercentage.toFixed(2) : "0" }}%
              {{ comparisionText }}
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Skeleton  v-else />
</div>
