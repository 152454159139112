import { Notification } from 'element-ui';

const NOTIFICATION_DURATION = 3000;
const NOTIFICATION_OFFSET = 40;
const NOTIFICATION_CLASS = 'notificationClass'
const NOTIFICATION_WIDTH_CLASS = 'notificationWidthClass'

function _autoCloseNotification(options) {
    Notification({
        title: options.title,
        message: options.message,
        type: options.type,
        duration: NOTIFICATION_DURATION,
        offset: NOTIFICATION_OFFSET,
        customClass: NOTIFICATION_CLASS
    });
}

function _autoCloseNotificationAutoWidth(options) {
    Notification({
        title: options.title,
        message: options.message,
        type: options.type,
        duration: NOTIFICATION_DURATION,
        offset: NOTIFICATION_OFFSET,
        customClass: NOTIFICATION_WIDTH_CLASS,
        dangerouslyUseHTMLString: options.dangerouslyUseHTMLString ? options.dangerouslyUseHTMLString : false,
    });
}

function success(options) {
    options.type = 'success';
    _autoCloseNotification(options);
}

function error(options) {
    options.type = 'error';
    _autoCloseNotification(options);
}

function warning(options) {
    options.type = 'warning';
    _autoCloseNotification(options);
}

function info(options) {
    options.type = 'info';
    _autoCloseNotificationAutoWidth(options);
}

function multiLineInfo(options) {
    options.type = 'info';
    options.dangerouslyUseHTMLString = true;
    _autoCloseNotificationAutoWidth(options);
}

function noIcon(options) {
    _autoCloseNotificationAutoWidth(options);
}

function longInfo(options) {
    return (Notification({
        title: options.title,
        message: options.message,
        type: 'info',
        duration: Object.prototype.hasOwnProperty.call(options, 'duration') ? options.duration : 5000,
        offset: NOTIFICATION_OFFSET,
        showClose: true,
        customClass: NOTIFICATION_CLASS
    }));
}

function loading(options) {
    return (Notification({
        title: options.title,
        message: options.message,
        duration: 0,
        offset: NOTIFICATION_OFFSET,
        iconClass: 'el-icon-loading',
        showClose: false,
        customClass: NOTIFICATION_CLASS
    }));
}

function close(notificationObject) {
    if (typeof notificationObject.close === 'function') {
        notificationObject.close();
    }
    else {
        console.error('ERROR: notificationsAssistant: No close function in notificationObject passed.');
    }
}

function SalesModeSave(options) {
    return (Notification({
        title: options.title,
        message: options.message,
        type: options.type,
        offset: options.offset,
        duration: options.duration,
        showClose: options.showClose ?? true,
        position: 'top-right',
        iconClass: options.icon ? 'el-icon-loading' : null,
    }));
}

export { success, error, warning, info, loading, close, longInfo, SalesModeSave, noIcon, multiLineInfo };
