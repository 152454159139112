import { DateTime } from "luxon";

export default function generateTimeDifference(date, isShort){
    let date1 = DateTime.fromISO(date,{ zone: "utc" })
    let date2 = DateTime.utc({ zone: "utc" })
    let diff = (date1.diff(date2, ["years", "months", "days", "hours", "minutes", "seconds"]))
    if(diff.years!=0){
        return `${Math.floor(diff.years*-1)}${ isShort ? ' y' : ` year${checkPlural(diff.years)}`} ago`
    }else if(diff.months!=0){
        return `${Math.floor(diff.months*-1)}${ isShort ? ' mon' :  ` month${checkPlural(diff.months)}`} ago`
    }else if(diff.days!=0){
        return `${Math.floor(diff.days*-1) > 1 ? (Math.floor(diff.days*-1)+(isShort?' d ago' : ' days ago')) : 'Yesterday'}`
    }else if(diff.hours!=0){
        return `${Math.floor(diff.hours*-1)}${ isShort ? ' h' : ` hour${checkPlural(diff.hours)}`} ago`
    }else if(diff.minutes!=0){
        return `${Math.floor(diff.minutes*-1)}${ isShort ? ' m' : ` minute${checkPlural(diff.minutes)}`} ago`
    }else if(diff.seconds<-0.999){
        return `${Math.floor(diff.seconds*-1)}${ isShort ? ' s' : ` second${checkPlural(diff.seconds)}`} ago`
    }
    
    return 'Just now'
}

function checkPlural(num){
    if((num*-1) < 2){
        return ''
    }else{
        return 's'
    }
}