
<div v-loading="googleMapsState.isLoadingAddressFromMarker" class="positionR">
    <b-icon icon="search" class="searchIcon"></b-icon>
    <input
        v-model="googleMapsState.address"
        ref="autocomplete"
        class="address-field"
        :placeholder="placeholder"
        @focus="$event.target.select()"
        @input="setManualInputState"
        :disabled="!(allowAutoComplete)"
        :style="{'cursor': allowAutoComplete ? 'text' : 'not-allowed'}"
    />
</div>
