import { NOTIFICATIONS_WEBSOCKET_ENDPOINT } from "../constants";

// This notifications system uses websockets to establish a persistent connection with the notifications server.
export default class NotificationsManager {
    constructor(options) {
        this.attemptCount = 0
		this.eventListeners = {}

        this.connect()
        this.optimiseConnection()
    }

    connect() {
        if (this.attemptCount > 4) {
            console.log("Unable to establish a connection with the notifications server. Total number of attempts: 5")
            return
        }
        this.attemptCount++

        const user = JSON.parse(localStorage.getItem("user")) || {};
        const token = user.token;
        if (!token) {
            console.log("Not logged in. Connection attempt to notifications server aborted.")
            return
        }

        let connection = new WebSocket(`${NOTIFICATIONS_WEBSOCKET_ENDPOINT}?token=${token}`)
        this.connection = connection
        
        connection.addEventListener('message', event => {
            let message = JSON.parse(event.data)
			let listenerList = this.eventListeners["message"]
			if (!listenerList) { return }

			listenerList.forEach(fn => fn(message))
        })
        
        connection.addEventListener('open', event => {
            this.attemptCount = 0
            this.isOptimiserActive = true
            console.log("Successfully connected to the notifications server.")

			let listenerList = this.eventListeners["open"]
			if (!listenerList) { return }
			listenerList.forEach(fn => fn())
        })

        connection.addEventListener('close', event => {
            console.log("Connection to the notifications server closed.")

			let listenerList = this.eventListeners["close"]
			if (!listenerList) { return }
			listenerList.forEach(fn => fn())

            if (this.isConnectionClosedOnPurpose) { return }

            console.log("Reconnecting..")
            setTimeout(() => {
                this.connect()
            }, 1000);
        })

        connection.addEventListener('error', event => {
            console.log("Connection to the notifications server closed due to an error.")
        })
    }

	optimiser() {
		if (!this.isOptimiserActive) { return }

		let optimiserTimeoutSeconds = 60
		if (document.hidden) {
			// Close connection if window is inactive for more than 60s
			this.optimiserTimeout = setTimeout(() => {
				this.isConnectionClosedOnPurpose = true
				this.connection.close()
			}, optimiserTimeoutSeconds * 1000);
		} else {
			clearTimeout(this.optimiserTimeout)
			this.isConnectionClosedOnPurpose = false
			if (this.connection.readyState == this.connection.CLOSED || this.connection.readyState == this.connection.CLOSING) {
				this.connect()
			}
		}
	}

    optimiseConnection() {
		this.optimiser = this.optimiser.bind(this) // Thanks to a comment under https://stackoverflow.com/a/10444156
        document.addEventListener("visibilitychange", this.optimiser);
    }

    addEventListener(eventName, fn) {
        // this.connection.addEventListener(eventName, fn)
		this.eventListeners[eventName] ||= []
		this.eventListeners[eventName].push(fn)
    }

    removeEventListener(eventName, fn) {
        // this.connection.removeEventListener(eventName, fn)
		let listenerList = this.eventListeners[eventName]
		if (!listenerList) { return }

		let foundFnIndex = listenerList.indexOf(fn)
		if (foundFnIndex == -1) { return }

		console.log(`Removing ${eventName} listener for notifications service.`)
		listenerList.splice(foundFnIndex, 1)
	}

    closeConnection() {
        this.isConnectionClosedOnPurpose = true
        this.connection.close()
		
		// Clear all event listeners and timeouts
		clearTimeout(this.optimiserTimeout)
        document.removeEventListener("visibilitychange", this.optimiser);
		Object.keys(this.eventListeners).forEach(eventName => {
			delete this.eventListeners[eventName]
		})
    }
}