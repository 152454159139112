
<div id="usersInfiniteScroll" style="width: 100%;">
  <el-select
    ref="userInfiniteScrollDropdown"
    id="userSelected"
    v-model="selectedUser"
    @change="$emit('update:user', selectedUser)"
    :disabled="isUserListDisabled"
    :remote-method="searchUsers"
    :loading="loading"
    class="projectSharing"
    style="width: 100%"
    value-key="id"
    filterable
    remote
    clearable
    reserve-keyword
    @visible-change="onVisibleChange"
    :placeholder="!crmMode ?  'Enter name or email address' : 'Select Owner'"
    :popper-class="theme"
  >
    <el-option
      v-for="currUser in computeUsers"
      :key="currUser.id"
      :label="`${currUser['first_name']} - ${currUser['email']}`"
      :value="currUser"
      :disabled="currUser.isDisabled"
    >
    <span v-if="checkdifferntOrg(currUser)" style="padding-right: 0.5rem;"> <img src="../../../assets/img/personfill.svg" alt=""> </span>
    <span v-else style="padding-right: 0.5rem;"> <img src="../../../assets/img/buildings.svg" alt=""> </span>
   
    <span>{{ currUser['first_name'] }} - {{ currUser['email'] }}</span>
    </el-option>
    <infinite-loading
      :distance="0"
      spinner="bubbles"
      @infinite="loadMoreUsers"
    >
      <template v-slot:no-more>
        <div style="color: #606266; font-size: 12px">
          No more users!!
        </div>
      </template>
      <template v-slot:error>
        <div style="color: #606266; font-size: 12px">
          Error in fetching users, retry!!
        </div>
      </template>
      <template v-slot:no-results>
        <div style="color: #606266; font-size: 12px">
          No more users!!
        </div>
      </template>
    </infinite-loading>
  </el-select>
  <b-icon icon="search" variant="dark" class="arrowIcon" v-if="showDropdownIcon" @click="openDropdown"></b-icon> 
  <!-- Confirmation Popup before assigning to Project Manager mainly for L2C2C flow and works for all users who will have the key is_project_manager as true  -->
  <SalesPacketConfirmationDialog :openDialog.sync="projectManagerConfirmation" @handleClose="handleCloseandReassigne" @handleUpdateOwner="handleOwnerUpdate" />
</div>
