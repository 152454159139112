<template>
    <div id="moveSection">
      <el-dialog
        :visible="showMoveSectionPopup"
        :close-on-click-modal="true"
        title="Move Section"
        @close="closeMoveSectionPopUp"
      >
          <div class="section-cont">
            
            <draggable class="sectionCards" v-model="formData.sections" @end="handleSectionMove" >
              <div v-for="section in formData.sections" :key="section" class="sections">
                  <img src="../assets/drag_indicator.svg" />
                  <p >{{ section.title }}</p>
              </div>
            </draggable>
          </div>
          
      </el-dialog>
    </div>
  </template>
  <script>
  import { mapState, mapActions } from 'pinia';
  import { useEditableSiteSurveyStore } from "../../../stores/editableSiteSurvey";
  import draggable from 'vuedraggable';
  export default {
    emits: [ "close-popup", "sectionMoved"],
    name: "moveSectionPopup",
    components: {draggable,},
    props: {
      showMoveSectionPopup: {
        type: Boolean,
        default: false,
      },
      isEditTemplateMode:{
        type: Boolean,
        default: false,
      }
    },
    computed:{
        ...mapState(useEditableSiteSurveyStore, {
            formData: "GET_FORM_DATA",
        }),
    },
    methods: {
      closeMoveSectionPopUp() {
          this.$emit("close-popup")
      },
      handleSectionMove(event) {
        if(this.isEditTemplateMode){
          // run this code only if it is editing an existing template
          this.formData.sections.forEach((stage, index) => {
              stage.sequence = index ;
          });
          const newIndex = event.newIndex;
          let sectionToPatch = this.formData.sections[newIndex];
          this.$emit("sectionMoved", sectionToPatch);
        }
      },
    }
  };
  </script>
  <style scoped>
  #moveSection >>> .el-dialog__wrapper {
    max-height: 95vh !important;
    display: flex !important;
    height: 50vh;
  }
  #moveSection >>> .el-dialog__title {
      color: #16328b !important;
  }
  
  #moveSection >>> .el-dialog {
      border-radius: 12px !important;
      height: auto !important;
      position: absolute;
      top: 42%;
      left: 30%;
  }
  #moveSection >>> .el-dialog__header {
    background-image: linear-gradient(to bottom, #e8edf2, #e9ecf2);
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    display: flex;
    margin-bottom: 10px;
    justify-content: space-between;
  }
  #moveSection ::v-deep .el-dialog__header .el-dialog__headerbtn .el-dialog__close {
    color: #222222;
    font-size: larger;
    font-weight: 600;
  }
  #moveSection ::v-deep .el-icon-close::before {
    font-size: 20px;
    font-weight: 600;
    color: #222222;
  }
  .sections {
      display: flex;
      justify-content: flex-start;
      gap: 20px;
      color: #222;
      cursor: pointer;
      font-size: 16px;
      margin-bottom: 10px;
  }
  .section-cont{
      display: flex;
      flex-direction: column;
      gap: 15px;
      margin-bottom: 10px;
  }
  .btn-cont {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
  }
  .update-button {
      color: white;
      font-size: 16px;
      background: #409eff;
      display: inline-block;
      cursor: pointer;
      font-weight: 500;
      padding: 12px 20px;
      border-radius: 4px;
      border: none;
  }
  </style>