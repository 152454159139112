
<div :class="['parentCont', isMinimized ? 'parentSectionop' : 'parentSection']">
    <div class="firstSection" v-if="!isMinimized">
        <b-icon icon="dash-lg" @click="isMinimized = !isMinimized" class="minimizeIcon"></b-icon>
        <div class="progressBarContainer">
            <el-progress :percentage="bulkLeadImportState.progress" :stroke-width="15"></el-progress>
        </div>
        <p class="font20Normal">Validation ongoing.<br />
            Please check after sometime.</p>
        <el-button type="primary" @click="showLeadUploadedPopupFunc()" class="cancelBtn" >Cancel</el-button>
    </div>
    <div class="minimizeSection" v-else>
        <div class="flexHeader">
            <p class="font16Normal">Importing Leads</p>
            <b-icon icon="chevron-up" @click="isMinimized = !isMinimized" class="arrowIcon"></b-icon>
        </div>
        <div class="progressBarContainer">
            <el-progress :percentage="bulkLeadImportState.progress" :stroke-width="15"></el-progress>
        </div>
        <p class="font16Normal"><b-icon icon="file-earmark-text"></b-icon>{{ bulkLeadImportState.fileName }}</p>
    </div>
</div>
