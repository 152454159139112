
<div class="datePicker forLabel">
  <!-- <input type="date" id="birthday" name="birthday" v-model="date">
        <date-pick v-model="date" :hasInputElement="false"></date-pick> -->
  <label for="" class="labelClass"
    >{{ itemValue.title }}
    <span v-if="itemValue.isStar" class="astrisk"> *</span>
  </label>
  <el-date-picker
    v-model="pickedDate"
    type="date"
    @change="onChange"
    :picker-options="pickerOptions"
    placeholder="Select Date"
    :format="dateFormat"
  >
  </el-date-picker>
</div>
