import { defineStore } from "pinia";
import { DateTime } from "luxon";
import API from '../services/api'
export const useReviewStore = defineStore("review", {
  state: () => {
    return {
      isReviewRequired: false,
      totalLeads: 0,
      isReviewed: false,
      dismissedDate: null,
      joinDate: null,
      reviewUrl: '',
      totalLeads: 0,
      totalDesigns: 0,
      totalProjects: 0,
      dismissCount: 0,
      isManuallyInitiated: false,
    };
  },
  getters: {
    GET_REVIEW_STATUS: (state) => state.isReviewRequired,
  },
  actions: {
    async SET_REVIEW_STATUS(bool, isManual, sourceType) {
      try {
        if(bool){
          if (isManual){
            this.isManuallyInitiated = true
            const res = await API.USERS.GENERATE_REVIEW_URL()
            this.reviewUrl = res?.data?.login_url
            this.isReviewRequired = bool;
          }else{
            if (this.dismissCount >=3 ) return
            this.isManuallyInitiated = false
            if (this.isReviewed) return
            let comparisonDays = this.dismissedDate ? 10 : 30
            let date1 = DateTime.fromISO((this.dismissedDate || this.joinDate),{ zone: "utc" })
            let date2 = DateTime.utc({ zone: "utc" })
            let diffInDays = date2.diff(date1, 'days').days
            if (!this.VALIDATE_SOURCE_TYPE(sourceType)) return
            if (comparisonDays == 10){
              if (diffInDays >= comparisonDays){
                const res = await API.USERS.GENERATE_REVIEW_URL()
                this.reviewUrl = res?.data?.login_url
                this.isReviewRequired = bool;
              }
            }else{
              if (diffInDays >= comparisonDays || this.VALIDATE_USER()){
                const res = await API.USERS.GENERATE_REVIEW_URL()
                this.reviewUrl = res?.data?.login_url
                this.isReviewRequired = bool;
              }
            }
          }
        }else{
          this.isReviewRequired = bool;
        }
        return false
      } catch (err) {
        console.error(err)
        return err.message
      }
    },
    VALIDATE_USER(){
      const isCrmEnabled = JSON.parse(localStorage.getItem('organisation'))?.is_crm_enabled
      const role = JSON.parse(localStorage.getItem('user'))?.role
      if (isCrmEnabled){
        if (role == 'ADMIN') return this.totalLeads >= 10 || this.totalDesigns >= 10
        if (role == 'SALESPERSON') return this.totalLeads >= 10
      } else {
        if (role == 'ADMIN') return this.totalProjects >= 10 || this.totalDesigns >= 10
        if (role == 'SALESPERSON') return this.totalProjects >=10
      }
      if (role == 'DESIGNER') return this.totalDesigns >= 10
      return false
    },
    VALIDATE_SOURCE_TYPE(sourceType){
      const isCrmEnabled = JSON.parse(localStorage.getItem('organisation'))?.is_crm_enabled
      const role = JSON.parse(localStorage.getItem('user'))?.role
      switch(sourceType){
        case 'leads':
          if (isCrmEnabled && (role == 'ADMIN' || role == 'SALESPERSON')) return true;
          break;
        case 'designs':
          if (role == 'ADMIN' || role == 'DESIGNER') return true;
          break;
        case 'projects':
          if (!isCrmEnabled && (role == 'ADMIN' || role == 'SALESPERSON')) return true;
          break;
        default:
          return false
      }
    },
    SET_TOTAL_LEADS(num){
     this.totalLeads = num;
    },
    SET_IS_REVIEWED(bool){
      this.isReviewed = bool
    },
    SET_REVIEW_DETAILS(data){
      this.isReviewed = data.is_review_collected;
      this.dismissedDate = data.review_dismissed_on
      this.joinDate = data.date_joined
      this.totalDesigns = data.total_designs
      this.totalLeads = data.total_leads
      this.totalProjects = data.total_projects
      this.dismissCount = data.review_dismissed_count
    },
    UPDATE_DISMISS_LOCALLY(count, dismissedOn){
      this.dismissCount = count
      this.dismissedDate = dismissedOn
    },
  },
});
