<template>
    <div class="deleteModule" id="deleteModule">
      <el-dialog :visible="isDeleteProjectDocumentPopupOpen" :close-on-click-modal="false" :title="title" width="30%"
        height="25rem !important" class="delete_module" @close="closeModal" :modal-append-to-body="false">
          <div class="alert" >
            <img src="../../../assets/img/alert (1).svg" alt="alert" class="warningImage" />
          </div>
        <p class="msg">{{info}}</p>
        <p class="msg">{{info2}}</p>
        <div class="button_container">
          <el-button type="outline" @click="cancelDelete()">No</el-button>
          <el-button type="primary" @click="confirmDelete()">Yes</el-button>
        </div>
      </el-dialog>
    </div>
  </template>
    
  <script>
  export default {
    emits: ["confirmDelete", "cancelDelete", "cancelDialog"],
    props: {
      isDeleteProjectDocumentPopupOpen: {
        type: Boolean,
        default: false,
      },
      title: {
        type: String,
        default: ''
      },
      info: {
        type: String
      },
      info2: {
        type: String
      },
    },
    data() {
      return {
        
      };
    },
   
    methods: {  
      confirmDelete() {
        this.$emit('confirmDelete', true);
        this.closeModal() 
        
      },
      closeModal() {
        this.$emit('cancelDelete');
      },
      cancelDelete(){
        this.$emit('cancelDialog')
        this.closeModal() 
      }

    },
  }
  </script>
  
  <style scoped>
  
  #deleteModule>>>.el-icon-close::before {
    font-size: 20px;
    font-weight: 600;
    color: #222222;
  }
  
  #deleteModule>>>.el-dialog {
    height: '25rem !important'
  }
  
  .deleteModule .delete_module>>>.el-dialog {
    border-radius: 16px;
    margin-top: 14vh !important;
    width: 500px !important;
    height: 18rem !important;
  }
  
  .deleteModule .delete_module>>>.el-dialog__header .el-dialog__headerbtn .el-dialog__close {
    /* padding-top: 20px; */
    color: #222222;
  }
  /* .el-button--primary.is-disabled{
      border: transparent !important;
      background-color: #cccccc;
  } */
  .deleteModule .delete_module>>>.el-dialog__header {
    display: none;
    background-color: #E8EDF2;
    color: black !important;
    margin-bottom: 30px;
    padding: 10px !important;
    /* display: flex; */
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    align-items: center;
    
    justify-content: space-between;
  }
  
  .deleteModule .delete_module>>>.el-dialog__body {
    padding: 30px 20px;
    color: #606266;
    height: 150px;
    background-color: white;
    border-radius: 15px;
    font-size: 14px;
    word-break: break-all;
  }
  
  .deleteModule .delete_module>>>.el-dialog__title {
    line-height: 24px;
    font-size: 18px;
    color: #222 !important;
}
  .deleteModule .delete_module  {
    margin: 24px 0px 32px 0px;
  }
  .alert{
    padding: 24px 0px 32px 0px;
    display: flex;
    justify-content: center;
  }
  .deleteModule .delete_module ,
  .deleteModule .delete_module .msg,
  .deleteModule .delete_module .button_container {
    display: flex;
    justify-content: center;
    padding: 0px 20px !important;
  }
  
  .deleteModule .delete_module .close_button {
    display: flex;
    justify-content: flex-end;
    padding: 10px 5px 0 0;
    cursor: pointer;
  }
  .msg {
    font-family: "Helvetica Neue" !important;
    font-size: 16px !important;
    font-weight: 100 !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.5 !important;
    letter-spacing: normal !important;
    text-align: left !important;
    color: #222 !important;
    padding: 0 20px;
    word-break: normal;
    margin: 0%;
  }
  .deleteModule >>> .el-input__inner::placeholder {
    font-size: 16px;
    font-weight: 400;
    color: #222222;
  }
  .deleteModule >>>.el-select__caret {
      color: #222222;
      font-size: 24px;
      transition: transform 0.3s;
      transform: rotateZ(180deg);
      cursor: pointer;
  }
  .el-button {
    font-size: 18px;
    padding: 13px 20px;
    margin: 32px 0px 40px 0px;
    width: 100px;
  }
  
  @media (max-width: 500px) {
    .deleteModule .delete_module>>>.el-dialog {
      width: 86% !important;
    }
  }
  </style>