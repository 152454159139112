
<div class="home">
    <NavBar/>
    <main class='main-container'>
      <div class='backdrop'></div>
      <SideBar/>
      <div v-if="isUS">
         <dashboard /> 
      </div>
      <div v-else><ProjectListView/></div>
     
     
    </main>
</div>
