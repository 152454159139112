import * as THREE from 'three';
import { CAMERA_UPDATED } from '../../coreConstants';
import {
    convertImperialToMetric,
    parseImperialMeasurement,
    isMetricUnit,
} from '../../../components/ui/length/utils';
import { FOOT_INCHES_VALIDATION_REGEX } from '../../../components/ui/length/constants';
import OutlinePoints from './OutlinePoints';
import ThreejsText from './ThreejsText';
import DrawManager from '../../managers/DrawManager';
import CylinderModel from '../model/CylinderModel';
import Walkway from '../model/Walkway';
import Property from '../model/Property';
import Tree from '../model/Tree';
import CustomImageManager from '../../managers/CustomImageManager';
import Handrail from '../model/Handrail';

export default class LengthMeasurement {
    constructor(vertexObj1, vertexObj2, stage, parent, scalingFactor = 2) {
        // standard norms
        this.stage = stage;
        this.parent = parent;

        this.objectsGroup = new THREE.Group();
        this.stage.sceneManager.scene.add(this.objectsGroup);

        this.objectsGroup.position.z += 0.1;
        this.objectsGroup.container = this;

        this.vertexObj1 = vertexObj1;
        this.vertexObj2 = vertexObj2;

        this.point1 = (!(this.vertexObj1 instanceof OutlinePoints) )? this.vertexObj1.clone() : this.vertexObj1.getPosition();
        this.point2 = (!(this.vertexObj2 instanceof OutlinePoints) )? this.vertexObj2.clone() : this.vertexObj2.getPosition();

        // properties
        this.scalingFactor = scalingFactor;
        this.onSelectColor = new THREE.Color(0, 0, 1);
        this.deSelectColor = new THREE.Color(1, 0, 0);
        if(this.vertexObj2 instanceof OutlinePoints) this.movableVertex = this.vertexObj2;
        else this.movableVertex = this.vertexObj1;

        //this is only for rectangle obstruction
        this.obstructionVertex1 = null;
        this.obstructionVertex2 = null;
        // TODO: Think of some other method
        this.inputError = this.stage.eventManager.wrongLengthInputError;

        // initialising arrows
        const directionVector = new THREE.Vector3(1,1,1);
        const tempVector = new THREE.Vector3(0, 0, 0);
        this.arrowHelper1 = new THREE.ArrowHelper(
            directionVector, tempVector,
            1, this.deSelectColor,
        );
        this.arrowHelper2 = new THREE.ArrowHelper(
            directionVector, tempVector,
            1, this.deSelectColor,
        );
        this.arrowHelper1.container = this;
        this.arrowHelper2.container = this;

        // constant for arrow helper throwing error if vertices are at same position
        this.ARROW_HELPER_ERROR_CONSTANT = 0.001;

        // initialising text
        this.textObject = new ThreejsText(0, tempVector, 0, this.stage, this, true, 'center', 'middle', LengthMeasurement.isValidInput);
        // new DrawManager
        if (this.parent instanceof CylinderModel || this.parent instanceof Walkway || this.parent instanceof Property || this.parent instanceof Tree || this.parent instanceof Handrail) this.parent.measurementTextMesh.push(this.textObject.textMesh);
        else if(this.parent instanceof CustomImageManager) this.stage.ground.measurementTextMesh.push(this.textObject.textMesh);
        else if(!(this.parent instanceof DrawManager)) this.parent.parent.measurementTextMesh.push(this.textObject.textMesh);

        // add to objectsGroup
        if(this.parent instanceof CylinderModel || this.parent instanceof Tree) {
            this.objectsGroup.add(this.arrowHelper1);
            this.objectsGroup.add(this.arrowHelper2);
        }

        this.hide();

        this.update();
    }

    // input validator to be passed to text class
    // TODO: Use some library
    static isValidInput(input) {
        if (!isMetricUnit()) {
            return input.search(FOOT_INCHES_VALIDATION_REGEX) !== -1;
        }
    
        if (Number.isNaN(parseFloat(input)) || parseFloat(input) <= 0 || !LengthMeasurement.containsOnlyNumbers(input)) {
            return false;
        }
    
        if (input % 1 === 0) {
            return true;
        }
    
        const inputArr = input.split('.');
        if (inputArr.length > 2) return false;
        return inputArr[1].length <= 3;
    }
    
    static containsOnlyNumbers(input) {
        return /^([0]*(\d+(\.\d*)?|\.\d+))$/.test(input) && parseFloat(input) >= 0;
    }
    

    // add to objects group
    show(editable=true) {
        if (this.stage.viewManager.lengthVisible) {
            this.textObject.visible = true;
            this.textObject.showObject();
            this.stage.eventBus.addEventListener(CAMERA_UPDATED, this.cameraUpdate);
            this.objectsGroup.visible = true;
        }
    }

    // remove only from objects group, different from remove() which deletes the threejs objects
    hide() {
        this.textObject.visible = false;
        this.textObject.hideObject();
        this.stage.eventBus.removeEventListener(CAMERA_UPDATED, this.cameraUpdate);
        this.objectsGroup.visible = false;
    }
    updateScale(){
        const zoom = 1/this.stage.getNormalisedZoom() * 10;
        let zoomFactor = zoom;
      //scale the arrows heads according to zoom
        if (zoom > 1.3) zoomFactor = 1.3;
        const headLength = 2 / this.stage.getNormalisedZoom();
        const headWidth = 1 / this.stage.getNormalisedZoom();
        this.arrowHelper1.setLength(this.point1.distanceTo(this.point2) / 2, headLength, headWidth);
        this.arrowHelper2.setLength(this.point1.distanceTo(this.point2) / 2, headLength, headWidth);

        }

    cameraUpdate = () => {
        // this.stage.addCameraUpdates(this.update.bind(null, this.obstructionVertex1, this.obstructionVertex2));
        this.stage.addCameraUpdates(this.updateScale.bind(this));
    }

    // update
    update = (vertex1Pos, vertex2Pos) => {
        // calculate required parameter
        // get vertices vectors
        let vertex1 = (!(this.vertexObj1 instanceof OutlinePoints) )? this.vertexObj1.clone() : this.vertexObj1.getPosition();
        let vertex2 = (!(this.vertexObj2 instanceof OutlinePoints) )? this.vertexObj2.clone() : this.vertexObj2.getPosition();
        //if position is passed in parameter
        if((vertex1Pos !== undefined && vertex1Pos !== null) && (vertex2Pos !== undefined && vertex2Pos !== null)){
            vertex1 = vertex1Pos;
            this.obstructionVertex1 = vertex1Pos;
            vertex2 = vertex2Pos;
            this.obstructionVertex2 = vertex2Pos;
        }
        vertex1 = (!(vertex1 instanceof OutlinePoints) )? vertex1.clone() : vertex1.getPosition();
        vertex2 = (!(vertex2 instanceof OutlinePoints) )? vertex2.clone() : vertex2.getPosition();

        
        this.point1 = vertex1;
        this.point2 = vertex2;
        // magnitude
        const magnitude = this.point1.distanceTo(this.point2);

        // text angle
        // const textAngle = 0;
        const zoom = 1/this.stage.getNormalisedZoom() * 10;
        let zoomFactor = zoom;

        if (zoom > 1.3) zoomFactor = 1.3;

        const endPointClone = vertex1.clone();
        endPointClone.z = 0;
        const startPointClone = vertex2.clone();
        startPointClone.z = 0;
        const slope = (startPointClone.y - endPointClone.y) / (startPointClone.x - endPointClone.x);
        let textAngle = Math.atan(slope);
        const centerPos = this.getPerpendicularVectorToMidpoint(new THREE.Vector3(startPointClone.x,startPointClone.y,20),new THREE.Vector3(endPointClone.x,endPointClone.y,20), zoomFactor/4);
        const centerPosArrow = this.getPerpendicularVectorToMidpoint(new THREE.Vector3(startPointClone.x,startPointClone.y,20),new THREE.Vector3(endPointClone.x,endPointClone.y,20),0);

        // adjustments for changing camera position
        const scalingFactor = this.scalingFactor / this.stage.getNormalisedZoom();
        let headLength = 2 / this.stage.getNormalisedZoom();
        const headWidth = 1 / this.stage.getNormalisedZoom();

        // arrow direction
        const arrow1Direction = vertex1.clone().sub(vertex2).normalize();
        const arrow2Direction = arrow1Direction.clone().multiplyScalar(-1);

        // mid point of the line
        const midPoint = vertex1.clone().add(vertex2).divideScalar(2);

        // box direction from reference point
        const boxDirection = vertex2.clone().sub(midPoint).applyAxisAngle(
            new THREE.Vector3(0, 2, 1),
            Math.PI / 2,
        ).normalize();

        // reference point
        // const origin = boxDirection.clone().multiplyScalar(scalingFactor).add(midPoint);
        // origin.z  = 30;

        // update arrows
        if (magnitude > this.ARROW_HELPER_ERROR_CONSTANT) {
            if (magnitude < (this.ARROW_HELPER_ERROR_CONSTANT + (2 * headLength))) {
                headLength = (magnitude - this.ARROW_HELPER_ERROR_CONSTANT) / 2;
            }

            this.arrowHelper1.setDirection(arrow1Direction);
            this.arrowHelper1.setLength(magnitude / 2, headLength, headWidth);
            this.arrowHelper1.position.copy(centerPosArrow);
            this.arrowHelper2.setDirection(arrow2Direction);
            this.arrowHelper2.setLength(magnitude / 2, headLength, headWidth);
            this.arrowHelper2.position.copy(centerPosArrow);

            // update text
            this.textObject.update(
                    magnitude,
                    centerPos,
                    textAngle
            )
        }
        else {
            // update text
            this.textObject.update(
                    magnitude,
                    undefined,
                    undefined
            )
        }

        this.textObject.updateScale(false);

    };

    getPerpendicularVectorToMidpoint(vectorA, vectorB, distance) {
        // Calculate the midpoint of the line segment
        const midpoint = new THREE.Vector3();
        midpoint.addVectors(vectorA, vectorB).multiplyScalar(0.5);
      
        // Calculate the direction vector from the midpoint to vectorB
        const directionVector = new THREE.Vector3();
        directionVector.subVectors(vectorB, midpoint);
      
        // Normalize the direction vector
        directionVector.normalize();
      
        // Rotate the unit vector by 90 degrees in the XY plane
        const perpendicularVector = new THREE.Vector3(-directionVector.y, directionVector.x, 0);
      
        perpendicularVector.multiplyScalar(-distance);
      
        // Add the perpendicular vector to the midpoint to get the final point
        const perpendicularPoint = new THREE.Vector3();
        perpendicularPoint.addVectors(midpoint, perpendicularVector);
      
        return perpendicularPoint;
    }

    getPerpendicularPoint(vector1, vector2) {
        // Calculate the midpoint of the line
        const midpoint = vector1.add(vector2).multiplyScalar(0.5);
      
        const crossProduct = new THREE.Vector3().crossVectors(vector1, vector2);

        // The cross product gives a vector that is perpendicular to both input vectors
        const perpendicular = crossProduct.clone().normalize();
      
        // Multiply the perpendicular vector by 1 and add it to the midpoint to get the final point
        const finalPoint = new THREE.Vector3().addVectors(midpoint, perpendicular.multiplyScalar(1));
      
        return midpoint;
    }

    // remove and dispose threejs objects
    remove() {
        this.hide();
        this.textObject.removeObject();
        this.stage.sceneManager.scene.remove(this.objectsGroup);
    }

    enable() {
        // TODO: Enable arrow
        this.textObject.showObject();
    }

    disable() {
        // TODO: Disable arrow
        this.textObject.hideObject();
    }

    setMovableVertex(vertexObj) {
        if (vertexObj !== this.vertexObj1 && vertexObj !== this.vertexObj2) {
            console.error('ERROR: Length Measurement: vertexObj doesn\'t belong to this object');
        }
        this.movableVertex = vertexObj;
    }

    // update magnitude by moving the vertex to the desired location.
    async handleValueUpdate(userEnteredValue) {
        const newMagnitude = (isMetricUnit()) ? userEnteredValue :
        convertImperialToMetric(parseImperialMeasurement(userEnteredValue));

        // get vertices in order according to movable vertex
        let vertex1 = (!(this.vertexObj1 instanceof OutlinePoints) )? this.vertexObj1.clone() : this.vertexObj1.getPosition();
        let vertex2 = (!(this.vertexObj2 instanceof OutlinePoints) )? this.vertexObj2.clone() : this.vertexObj2.getPosition();
        if (this.movableVertex === this.vertexObj1) {
            vertex1 = (!(this.vertexObj2 instanceof OutlinePoints) )? this.vertexObj2.clone() : this.vertexObj2.getPosition();
            vertex2 = (!(this.vertexObj1 instanceof OutlinePoints) )? this.vertexObj1.clone() : this.vertexObj1.getPosition();
        }

        // get delta vector to move
        const magnitude = vertex1.distanceTo(vertex2);
        const delta = vertex2.clone().sub(vertex1).setLength(newMagnitude - magnitude);

        await this.movableVertex.placeObject(delta.x, delta.y, delta.z);
    }

    async handleValueUpdateSalesMode(userEnteredValue) {
        const newMagnitude = userEnteredValue;

        // get vertices in order according to movable vertex
        let vertex1 = this.vertexObj1.getPosition();
        let vertex2 = this.vertexObj2.getPosition();
        if (this.movableVertex === this.vertexObj1) {
            vertex1 = this.vertexObj2.getPosition();
            vertex2 = this.vertexObj1.getPosition();
        }

        // get delta vector to move
        const magnitude = vertex1.distanceTo(vertex2);
        const delta = vertex2.clone().sub(vertex1).setLength(newMagnitude - magnitude);

        try {
            await this.movableVertex.placeObject(delta.x, delta.y, delta.z);
            return Promise.resolve(true);
        }
        catch(error) {
            console.error('error in update radius: ', error);
            return Promise.reject(error);
        }
    }

    moveVertex(userEnteredValue){
        const newMagnitude = (isMetricUnit()) ? userEnteredValue :
            convertImperialToMetric(parseImperialMeasurement(userEnteredValue));

        // get vertices in order according to movable vertex
        let vertex1 = this.vertexObj1.getPosition();
        let vertex2 = this.vertexObj2.getPosition();
        if (this.movableVertex === this.vertexObj1) {
            vertex1 = this.vertexObj2.getPosition();
            vertex2 = this.vertexObj1.getPosition();
        }

        // get delta vector to move
        const magnitude = vertex1.distanceTo(vertex2);
        const delta = vertex2.clone().sub(vertex1).setLength(newMagnitude - magnitude);

        this.movableVertex.moveObject(delta.x, delta.y, delta.z);
        this.update(this.vertexObj1,this.vertexObj2);
    }

    setTextEditable({ shouldCreateContainer, shouldCompleteOnNoChange, flag } = { shouldCreateContainer: true, shouldCompleteOnNoChange: false,  flag:true }) {
        this.stage.textSelectionControls.setSelectedTextObject(
            this.textObject,
            { shouldCreateContainer, shouldCompleteOnNoChange, flag },
        );
    }

    handleOnCancel() {
        this.parent.handleOnCancel();
    }

    enableTextSelection() {
        this.textObject.enableSelection();
    }

    disableTextSelection() {
        this.textObject.disableSelection();
    }

    handleTextSelection() {
        this.arrowHelper1.setColor(this.onSelectColor);
        this.arrowHelper2.setColor(this.onSelectColor);
    }

    handleTextDeSelection() {
        this.arrowHelper1.setColor(this.deSelectColor);
        this.arrowHelper2.setColor(this.deSelectColor);
    }
}
