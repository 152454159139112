import axios from "axios";

export default {
  POST_DOCUMENTS(payLoad) {
    return axios.post(`/api/documents/`, payLoad);
  },
  FETCH_DOCUMENTS_FROM_INSTALLER(projectid,uploaded_by,orderId) {
    if(!orderId){
    return axios.get(`/api/documents/?project=${projectid}&uploaded_by=${uploaded_by}`);
    }else{
      return axios.get(`/api/documents/?project=${projectid}&uploaded_by=${uploaded_by}&order=${orderId}`);
    }

  },
  FETCH_DOCUMENTS_FOR_CRM(projectId) {
    return axios.get(`api/documents/?project=${projectId}`)
  },
  FETCH_DOCUMENTS_FROM_DESIGNER(projectid,uploaded_by,orderId){
    if(!orderId){
    return axios.get(`/api/documents/?project=${projectid}&uploaded_by=${uploaded_by}`);
    }else{
      return axios.get(`/api/documents/?project=${projectid}&uploaded_by=${uploaded_by}&order=${orderId}`);
    }
  },
  FETCH_DOCUMENTS_FOR_TASKS(taskId) {
    return axios.get(`api/documents/?task=${taskId}`)
  },
  DOWNLOAD_DOCUMENTS(payLoad) {
    return axios.post(`/api/documents/download/`,payLoad);
  },
  DELETE_DOCUMENTS(fileId) {
    return axios.delete(`api/documents/${fileId}/`);
  },
  SEND_CONTRACT(id,payLoad){
    return axios.post(`api/contracts/${id}/mail_to_sign/`,payLoad);
  },
  SUBMIT_CONTRACT(id,payLoad){
    return axios.patch(`api/contracts/${id}/`,payLoad);
  },
  APPROVE_CONTRACT(postData){
    return axios.post(`api/contracts/webhook/`,postData);
  },
  GET_SIGNED_CONTRACT(signedId){
    return axios.get(`api/contracts/download/?file_id=${signedId}`)

  }
};
