<template>
    <div v-loading="googleMapsState.isLoadingAddressFromMarker" class="positionR">
        <b-icon icon="search" class="searchIcon"></b-icon>
        <input
            v-model="googleMapsState.address"
            ref="autocomplete"
            class="address-field"
            :placeholder="placeholder"
            @focus="$event.target.select()"
            @input="setManualInputState"
            :disabled="!(allowAutoComplete)"
            :style="{'cursor': allowAutoComplete ? 'text' : 'not-allowed'}"
        />
    </div>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { useMiscStore } from '../../stores/misc'
import mapsLoaded from "./googleMaps.js"
import { reverseGeocodeCoordinates } from "./googleMaps"
import { getDetailsFromGoogleMapsResult, isCrmUser } from '../../utils'
import { useProjectStore } from '../../stores/project'
import { useLeadStore } from '../../stores/lead'

function convertCoordinates(input) {
    // Regular expressions to match the different coordinate formats
    const ddRegex = /^(-?\d+(\.\d+)?)°?\s*([NS])?,?\s*(-?\d+(\.\d+)?)°?\s*([EW])?$/;
    const ddmRegex = /^(\d+(\.\d+)?)°\s*(\d+(\.\d+)?)['′]\s*([NS])?,?\s*(\d+(\.\d+)?)°\s*(\d+(\.\d+)?)['′]\s*([EW])?$/;
    const dmsRegex = /^(\d+)°\s*(\d+)['′]\s*(\d+(\.\d+)?)["″]\s*([NS])?,?\s*(\d+)°\s*(\d+)['′]\s*(\d+(\.\d+)?)["″]\s*([EW])?$/;
    const ddDecimalRegex = /^(-?\d+(\.\d+)?)°?\s*([NS])?,?\s*(-?\d+(\.\d+)?)°?\s*([EW])?$/;

    let match;
    let lat, lng;

    // Check input format and extract latitude and longitude
    if ((match = input.match(ddRegex))) {
        lat = parseFloat(match[1]);
        lng = parseFloat(match[4]);
    } else if ((match = input.match(ddmRegex))) {
        lat = parseFloat(match[1]) + parseFloat(match[3]) / 60;
        lng = parseFloat(match[6]) + parseFloat(match[8]) / 60;
    } else if ((match = input.match(dmsRegex))) {
        lat = parseFloat(match[1]) + parseFloat(match[2]) / 60 + parseFloat(match[3]) / 3600;
        lng = parseFloat(match[6]) + parseFloat(match[7]) / 60 + parseFloat(match[8]) / 3600;
    } else if ((match = input.match(ddDecimalRegex))) {
        lat = parseFloat(match[1]);
        lng = parseFloat(match[4]);
    } else {
        return { error: "Invalid input format" };
    }

    // Check for North/South and East/West indicators and adjust signs
    if (match[3] === "S" || match[3] === "W") {
        lat = -lat;
    }
    if (match[6] === "S" || match[6] === "W") {
        lng = -lng;
    }

    // Return latitude and longitude as a dictionary
    return {
        lat: lat,
        lng: lng
    };
}


export default {
    emits: ["input", "validate"],
    props: {
        placeholder: {
            type: String,
            default: "",
        },
        addValidation:{
            type: Boolean,
            default: false,
        },
        isNew:{
            // using this prop to check if a new lead/project is being created, in this case, the field must be enabled
            type: Boolean,
            default: false,
        }
    },
    mounted() {
        let vm = this
        mapsLoaded.then((google) => {
            let input = this.$refs.autocomplete
            const searchBox = new google.maps.places.SearchBox(input);
            searchBox.addListener("places_changed", () => {
                // This is to make sure that manually entered address inputs are not allowed.
                let places = searchBox.getPlaces()
                for (let place of places) {
                    let coordinates = place?.geometry?.location
                    if (!coordinates) { continue }

                    let address = vm.$refs.autocomplete.value
                    let { countryCode, state, postalCode, county, city } = getDetailsFromGoogleMapsResult(place)
                    let isAddressChosenByInput = true
                    // Weird logic, but it works
                    if (!place.address_components) {
                        isAddressChosenByInput = false
                    }
                    vm.UPDATE_GOOGLE_MAPS_STATE({
                        coordinates,
                        address,
                        state,
                        countryCode,
                        isAddressChosenByInput,
                        postalCode,
                        county,
                        city,
                        isAddressManuallyEntered: false
                    })
                    vm.$emit("placesChanged")
                    break
                }
            })

            input.addEventListener("focusout", async () => {
                if (!this.googleMapsState.address) { return }
                let latLng = convertCoordinates(this.googleMapsState.address)

                if (latLng.error) { return }

                let newMapsState = await reverseGeocodeCoordinates(latLng, this.googleMapsState.isAddressChosenByInput)
                newMapsState.coordinates = new google.maps.LatLng(latLng.lat, latLng.lng)
                newMapsState.isAddressChosenByInput = true
                this.UPDATE_GOOGLE_MAPS_STATE(newMapsState)
                this.googleMapsState.isLoadingAddressFromMarker = false
                vm.$emit("placesChanged")
            })
        });
    },
    computed: {
        ...mapState(useMiscStore, {
            googleMapsState: "GET_GOOGLE_MAPS_STATE",
        }),
        ...mapState(useProjectStore, {
            projectAddressEditFlag: "GET_PROJECT_ADDRESS_EDIT_FLAG",
        }),
        ...mapState(useLeadStore, {
            leadAddressEditFlag: "GET_LEAD_ADDRESS_EDIT_FLAG",
        }),
        allowAutoComplete(){
            if(isCrmUser()){
                if(this.isNew){
                    return true;
                }
                return this.leadAddressEditFlag;
            }
            else{
                if(this.isNew)
                    return true;
                return this.projectAddressEditFlag;
            }
        }
    },
    methods: {
        ...mapActions(useMiscStore, ["UPDATE_GOOGLE_MAPS_STATE"]),
        setManualInputState() {
            this.$emit('input')
            let isAddressManuallyEntered = false
            if (this.googleMapsState.address) {
                isAddressManuallyEntered = true
            }
            this.UPDATE_GOOGLE_MAPS_STATE({ isAddressManuallyEntered })
            if (this.addValidation) {
                this.$emit('validate')
            }
        },
        getAddressFromPlaceList(places) {
        }
    }
}
</script>

<style scoped>
.searchBarWrapper input {
  height: 48px;
  background-color: #e8edf2;
  outline: 0;
  width: 100%;
  border: none;
  box-sizing: border-box;
  width: 100%;
  font-size: 16px;
  color: #222;
  border-radius: 4px;
}

.address-field {
    padding-left: 40px;
}

.createLeadSearchBarWrapper input{
    height: 48px;
  background-color: #e8edf2;
  outline: 0;
  width: 100%;
  border: none;
  box-sizing: border-box;
  width: 100%;
  font-size: 16px;
  color: #222;
  border-radius: 4px;
}

.positionR {
    position: relative;
}

.searchIcon {
    position: absolute;
    left: 16px;
    top: 16px;
    font-size: 16px;
    color: #777;
}
</style>