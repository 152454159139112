
<div v-loading="isLoadingTemplates">
    <section :class="['right_section', isCrmUser() ? 'right_sectionCRM' : '']">
        <div class="content_section">
            <div class="filter_section">
                <div class="title">Site Survey Form</div>
                <div class="search-section">
                    <el-input
                    class="search-field"
                    v-model="searchText"
                    placeholder="Search templates"
                    @input="handleSearchTextChange"
                    >
                    <template v-slot:prefix>
                        <b-icon class="icon" icon="search"></b-icon>
                    </template></el-input>
                    <el-dropdown @command="createFormFunc" v-if="allowCreateForm">
                        <el-button type="primary" class="createBtn">
                            Create Form<i class="el-icon-arrow-down el-icon--right"></i>
                        </el-button>
                        <template v-slot:dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item command='self_service'>Site Survey Forms</el-dropdown-item>
                            <el-dropdown-item command="marketing" >Marketing Forms</el-dropdown-item>
                        </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </div>
            </div>
            <div class="box-card">
                <div class="headerContainer">
                    <p class="headings form-name">FORM NAME</p>
                    <p class="headings form-type">FORM TYPE</p>
                    <p class="headings form-actions">ACTIONS</p>
                </div>
                <div class="bodyContainer">
                    <div class="flexBody" v-for="template in templates" :key="template.id" @click="previewFormFunc(template.id)">
                        <p class="desc template-title">{{template.title}}</p>
                        <div class="desc template-type">{{ templateType(template.template_type) }}</div>
                        <div class="iconsCont">
                            <i class="icon copy-alt" @click="copySiteSurveyTemplate(template.id, $event)"></i>
                            <i class="icon delete-alt" v-if="!template.is_default" @click="confirmDeleteTemplate(template.id, $event)"></i>
                        </div>
                    </div>
                    <div
                        v-infinite-scroll="loadMoreTemplates"
                        infinite-scroll-disabled="busy"
                        infinite-scroll-distance="10"
                        style="text-align: center"
                        >
                        <i v-if="busy" class="el-icon-loading infiniteScrollLoader" />
                    </div>
                </div>
            </div>
            <DeleteDialog :dialogVisible="openDeleteTemplateDialog" :footerButtonType="'primary'" :footerButtonText="'Yes'"
                :disableCancel="true" :width="'20%'" :deleteMessage="`Do you really want to delete this template?`"
                :infoMessage="``" @handleClick="deleteSiteSurveyTemplate(templateToDelete)" @handleClose="openDeleteTemplateDialog = false">
            </DeleteDialog>
        </div>
    </section>
</div>
