import axios from "axios";

export default {
  FETCH_TASK_BY_ID(id) {
    return axios.get(`api/task/${id}/`);
  },
  FETCH_TASK_LIST(options) {
    return axios.get(`api/task/?${options}`);
  },
  UPDATE_TASK_LIST(id, data) {
    return axios.patch(`api/task/${id}/`, data);
  },
  UPDATE_TASK(id, data) {
    return axios.patch(`api/task/${id}/`, data);
  },
  DELETE_TASK(id) {
    return axios.delete(`api/task/${id}/`);
  },
  FETCH_ALL_LEAD_LIST() {
    return axios.get(`api/task/lead_list/`);
  },
  SEARCH_ALL_TASK_KANBAN(query, stage) {
    return axios.get(`/api/task/?stage=${stage}&query=${query}`);
  },
  CREATE_TASK(task) {
    return axios.post("api/task/", task);
  },
  LOAD_MORE_LEADS(url) {
    return axios.get(url);
  },
  LOAD_MORE_TASKS(url) {
    return axios.get(url);
  },
  SEARCH_LEADS(query) {
    return axios.get(`/api/task/lead_list/?name=${query}`);
  },
  FETCH_INDIVIDUAL_TASK(id) {
    return axios.get(`/api/activity-log/?task_id=${id}`);
  },
  FETCH_ACTIVITY_LOG(id) {
    return axios.get(`/api/activity-log/?task_id=${id}`);
  },
  POST_ACTIVITY_COMMENT(payLoad) {
    return axios.post(`/api/activity-log/`, payLoad);
  },
  GROUP_BY_LEAD(query) {
    return axios.get(`/api/task/group_by_lead/?${query}`);
  },
  LEAD_DATA_BY_ID(id, query) {
    return axios.get(`/api/task/group_by_lead/?lead_id=${id}&${query}`);
  },
  POST_SETREMINDER_TASK(task_id, postData) {
    return axios.post(`api/task/${task_id}/send_reminder/`, postData);
  },
  POST_NUDGE_NOTIFICATION(id, postData) {
    return axios.post(`api/task/${id}/send_nudge/`, postData);
  },

  GET_MORE_GROUP_BY_LEAD(url) {
    return axios.get(url);
  },

  GET_MORE_GROUP_BY_LEAD_ITEMS(url) {
    return axios.get(url);
  },
};
