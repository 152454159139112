<template>
  <div class="outer-container">
    <p v-show="helpText">
      <span style="color: red">*</span> Weeks are calculated as 7 days from the
      first day of the quarter.
    </p>
    <div class="container">
      <!-- <canvas id="chart"></canvas> -->
      <canvas ref="chartCanvas" @click="handleChartClick"></canvas>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
export default {
  emits: ["chart-click"],
  name: "ChartJs",
  data() {
    return {
      chart: null,
      helpText: false,
    };
  },
  props: {
    type: String,
    data: {},
    options: {},
    dashboardId: {
      type: Number,
      default: null,
    },
  },
  mounted() {
    //console.log("mounted");
    this.renderChart();
  },

  methods: {
    renderChart() {
      if (this.chart) {
        this.chart.destroy();
      }
      // Create new chart instance
      const chartOptions = {
        plugins: {
          legend: {
            display: false, // Remove the legend
          },
        },
      };
      const chartCanvas = this.$refs.chartCanvas;
      const chart = new Chart(chartCanvas, {
        type: this.type,
        data: this.data,
        options: {
          ...this.options,
          maintainAspectRatio: false,
          responsive: true,
        },
      });

      // Save the chart instance if needed for later use or updates
      this.chart = chart;
    },
    updateChart() {
      // Update the chart's data
      this.chart.data = this.data;
      // Update the chart
      this.renderChart();
      if (this.$props.data.datasets[0].label === "Quater") {
        this.helpText = true;
      } else {
        this.helpText = false;
      }
    },
    handleChartClick(event) {
      console.log("event: ", this.data);
      if (!this.chart) return;

      const clickedPoints = this.chart.getElementsAtEventForMode(
        event,
        "nearest",
        {
          intersect: true,
        }
      );
      console.log("clickedPoints: ", clickedPoints);
      this.$emit("chart-click", clickedPoints);
      // if (clickedPoints.length > 0) {
      //   const datasetIndex = clickedPoints[0].datasetIndex;
      //   const dataIndex = clickedPoints[0].index;
      //   const clickedData = this.data.datasets[datasetIndex].data[dataIndex];

      //   // Now you have access to the clicked data, you can use it as needed.
      //   console.log("Clicked data:", clickedData);
      // }
    },
  },

  watch: {
    data() {
      // Update the chart when the chartData prop changes
      //console.log("watching");
      this.updateChart();
    },
    dashboardId: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal && this.chart) {
          this.chart.destroy();
        }
      },
    },
  },
};
</script>

<style scoped>
.container {
  height: 100%;
}
.outer-container {
  position: relative;
  height: 100%;
}

p {
  color: #777;
  font-size: small;
  position: absolute;
  top: -4%;
  left: 25%;
}
@media only screen and (max-width: 1500px) {
  p {
    color: #777;
    font-size: 0.6rem;
    position: absolute;
    top: -4%;
    left: 25%;
  }
}
</style>
