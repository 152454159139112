
<div>
  <el-row :gutter="20" class="card-row" style="margin-bottom: 20px">
    <div>
      <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
        <div
          class="kpi-container card-container"
          @click="handleCreate('KPI')"
        >
          <p style="padding-right: 0.5rem;color: #1c3366; font-weight: 600;display:flex">
            <span>
              <b-icon
                :style="{
                  color: '#1c3366',
                }"
                class="custom-icon"
                :icon="'plus-circle-fill'"
              ></b-icon
            ></span>
            Add KPI
          </p>
        </div>
      </el-col>
    </div>
  </el-row>

  <el-row :gutter="20" class="card-row" style="margin-bottom: 20px">
    <template>
      <el-col
        v-for="(element, index) in chartData" :key="index"
        :xs="24"
        :sm="12"
        :md="12"
        :lg="12"
        :xl="12"
      >
        <div class="graphs card-container" @click="handleCreate('Bar')">
          <p style="padding-right: 0.5rem; color: #1c3366; font-weight: 600;display:flex">
            <span>
              <b-icon
                :style="{
                  color: '#1c3366',
                }"
                class="custom-icon"
                :icon="'plus-circle-fill'"
              ></b-icon
            ></span>
            {{ element }}
          </p>
        </div>
      </el-col>
    </template>
  </el-row>
</div>
