
<div>
  <section :class="['right_section', isCrmUser() ? 'right_sectionCRM': '']">
    <div class="content_section">
      <div class="filter_section">
        <div class="title">Custom Tariff</div>
        <div class="starred_action">
          <div class="headerDetails">
            <div :class="['searchCont',openSearchBox == true ? 'openSearchBox': '']">
              <input
                type="text"
                :class="['headerDetailsSearch', openSearchBox == true ? 'openSearchBox': '']"
                placeholder="Search based on tariff provider or rate"
                v-model="searchTariff"
              />
              <i
                class="el-icon-search inpIcons"
                v-if="searchTariff == null"
                @click="openSearchBox = !openSearchBox"
              ></i>
              <i
                v-else
                @click="[(searchTariff = null), (openSearchBox = !openSearchBox)]"
                class="el-icon-close inpIcons"
              ></i>
            </div>

            <el-button
              type="primary"
              class="headerDetailsBtn"
              @click="createTariffPopup = true"
              >Create New Tariff
            </el-button>
          </div>
        </div>
      </div>
      <div>
        <CustomTariffContainer 
          :tabCounter="tabCounter"
          :searchQuery="searchQuery"
        />
      </div>
    </div>
  </section>
  <CreateTariffPopup
    :createTariffPopup="createTariffPopup"
    @close="handleClose()"
    @added="handleAdd()"
    :key="createCounter"
  />
</div>
