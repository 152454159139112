<template>
    <div class="">
        <Navbar @handleToggleSideBar="handleSidebar" />
        <main class="main-controller">
            <div class="backdrop"></div>
            <Sidebar :isSidebarOpen="isSidebarOpen" />
            <section :class="['right_section', isCrmUser() ? 'right_sectionCRM' : '']">
                <div class="content_section">
                    <div class="filter_section">
                        <div class="title">Payment Schedule</div>
                    </div>
                    <ProjectTimeline />
                </div>
            </section>
        </main>
    </div>
</template>

<script>
import Navbar from "../../components/ui/newNavbar.vue";
import Sidebar from "../../components/ui/sidebar.vue";
import ProjectTimeline from "./components/projectTimeline.vue";
import { mapState, mapActions } from 'pinia';
import API from '@/services/api';
import { v4 } from "uuid";
import { isCrmUser } from "../../utils";


export default {
    name: "customTariff",
    components: {
        Navbar,
        Sidebar,
        ProjectTimeline,
    },

    data() {
        return {
            isSidebarOpen: false,
        };
    },

    computed: {
    },
    methods: {
        isCrmUser,

        handleSidebar(isSidebarOpen) {
            this.isSidebarOpen = isSidebarOpen;
        },
    },
}
</script>

<style scoped>

.main-controller .right_section {
    background: var(--step-50);
}

.content_section {
    padding: 24px 64px;
    min-height: calc(100vh - 100px);
}

.filter_section {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.content_section .title {
    font-weight: 600;
    font-size: 24px;
    color: #000;
}

@media (min-width: 1281px) {
    .main-controller .right_section {
        width: calc(100% - 260px);
        margin-left: auto;
    }

    .right_sectionCRM {
        width: calc(100% - 74px) !important;
    }
}

@media (max-width: 450px) {

.filter_section {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    gap: 16px;
}
}

</style>