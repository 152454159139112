import axios from "axios";
export default {
  FETCH_ARKA_ORDERS(params) {
    return axios.get(`api/purchase-orders/?${params}`);
  },
  FETCH_ARKA_ORDERS_BY_LEAD(id){
    return axios.get(`api/purchase-orders/?lead_id=${id}`);
  },

  PLACE_ARKA_ORDER(data) {
    return axios.post(`api/purchase-orders/`, data);
  },

  UPDATE_ARKA_ORDER(id,patchData){
    return axios.patch(`api/purchase-orders/${id}/`,patchData)
  },

  // accessories
  FETCH_ALL_ACCESSORIES(){
    return axios.get(`api/solutions-order/accessory/`);
  },

  ACCEPT_ORDER(data){
    return axios.post(`api/purchase-orders/send_mail/`, data);
  }

}