export const SMARTROOF_FACES = "smartroofFaces";
export const SUBARRAYS = "subArrays";
export const HANDRAIL = "handrail";
export const PROPERTY = "property";
export const AC_CABLE = "acCable";
export const DC_CABLE = "dcCable";
export const SAFETY_LINE = "safetyline";
export const CONDUIT = "conduits";
export const DOUBLE_CONDUIT = "doubleConduit";
export const DOUBLE_SEPARATE_CONDUIT = "doubleSeparateConduit";
export const SINGLE_CABLE_TRAY = "singleCableTray";
export const DOUBLE_SEPARATE_CABLE_TRAY = "DoubleSeparateCableTray";
export const DOUBLE_CABLE_TRAY = "doubleCableTray";
export const ACDB = "acdb";
export const DCDB = "dcdb";
export const TREE = "trees";
export const INVERTER = "inverters";
export const EDGE_CENTRE_POINT = "edgeCentrePoints";
export const PILLAR = "pillar";
export const CYLINDER = "cylinders";
export const CROWN = "crown";
export const CROWN_EDGE = "crownEdge";
export const TRUNK = "trunk";
export const TRUNK_EDGE = "trunkEdge";
export const POLYGON = "polygons";
export const PARAPET = "parapet";
export const FLASHING = "flashing";
export const FLAT_FOLD = 'flat';
export const PITCHED_FOLD = 'outer';
export const VERTICAL_FOLD = 'vertical';
export const TEXT_BOX = 'textbox';
export const SMARTROOF = 'smartroofs';
export const DORMER = 'dormers';