<template>
  <div class="card" :style="borderStyle">
    <CardHeader
      :heading="headingText"
      :editMode="editMode"
      @mode-open="openPopOver"
    >
      <slot name="header"></slot>
    </CardHeader>

    <CardBody :chartType="chartType">
      <slot name="body"></slot>
    </CardBody>
  </div>
</template>

<script>
import CardHeader from "./CardHeader.vue";
import CardBody from "./CardBody.vue";

export default {
  emits: ["open-popover" ],
  name: "CardElement",
  components: {
    CardHeader,
    CardBody,
  },
  props: {
    headingText: {
      type: String,
      default: "",
    },
    chartType: {
      type: String,
      default: "",
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasHeader() {
      return !!this.$slots.header;
    },
    borderStyle(){
      return this.$props.editMode ? 'border: 2px solid blue;' :''
    }
  },
  methods: {
    openPopOver(value) {
      this.$emit("open-popover", value);
    },
  },
};
</script>

<style scoped>
.card {
  transition: 0.3s;
  border-radius: 5px; /* 5px rounded corners */
  width: 100%;

  height: 100%;
  /*padding: 30px;*/
}
</style>
