
<div style="height: 100% ; ">
  <CardElement :headingText="computeHeadingText" :chartType="chartType" :editMode="editMode"
    @open-popover="handleOpenPopover" v-if="chartsData.length">
    <template v-slot:body>
      <div calss="chart-container" style="height: 100%">
        <Chart v-loading="!chartsData[0]" v-if="!isMap" :type="chartType" :data="chartsData[0]"
          :options="computeOptions" @chart-click="handleDrilldownOpen" :dashboardId="dashboardId" />
        <div v-else style="height: 100%">
          <HomeMap ref="homeMapImport" :all-projects="chartsData[0]?.position.lat ? chartsData : []"
            :page-flag="'dashboard'" />
            <!-- <div v-else style="display:flex;justify-content:center;align-items:center;height:100%">No Data Available</div> -->
        </div>
      </div>
    </template>
  </CardElement>
  <CardElement :headingText="computeHeadingText" :chartType="chartType" :editMode="editMode"
    @open-popover="handleOpenPopover" v-else-if="errorData === true">
    <template v-slot:body>
      <div calss=" nodata-container" style="
          display: flex;
          justify-content: center;
          align-items: center;
          height: 50vh;
        ">
        {{ noDataValue }}
      </div>
    </template>
  </CardElement>
  <Skeleton  v-else />
</div>
