
<div class="datasheetContainer">
    <div class="childContainer">
        <div class="btnContainer">
            <p class="datasheetHeading">Datasheet</p>
            <div>
                <el-button type="primary" class="dwnldBtn" @click="downloadDatasheet">
                    <span v-show="!isDownloadRunning">Download Datasheet</span>
                    <i
                        v-show="isDownloadRunning"
                        class="el-icon-loading"/>
                </el-button>
                <el-button type="primary" class="saveBtn" @click="save" :disabled="!isSavedEnabled">
                    <span v-show="!isSaveRunning">Save</span>
                    <i
                        v-show="isSaveRunning"
                        class="el-icon-loading"/>
                </el-button>
            </div>
        </div>
        <div class="tableContainer">

            <!-- ---------------------------------------------------------header------------------------------------------ -->
            <div class="tableHeader commonGridClass">
                <el-checkbox v-model="universalCheck">Component</el-checkbox>
                <p class="headerDesc">Description</p>
                <p class="headerAct">Action</p>
            </div>

            <!-- ---------------------------------------------------------body------------------------------------------------>
            <div class="tableBodyContainer">


                <!-- ---------------------------------------------------------without dropdown------------------------------------>
                <div class="tableBody commonGridClass">
                    <el-checkbox v-model="modulesCheck" :disabled="moduleLinks.length == 0">Modules</el-checkbox>
                    <div  id="noDropContainer">
                        <div id="noDropBody" v-for="item in modules" :key="item.id">
                            <p class="bodyDesc">{{ item.make }}</p>
                            <p class="errorMsg" v-if="!item.isValidLink">Datasheet not available for this module</p>
                        </div>
                    </div>
                </div>
                <div class="tableBody commonGridClass">
                    <el-checkbox v-model="invertersCheck" :disabled="inverterLinks.length == 0">Inverters</el-checkbox>
                    <div id="noDropContainer">
                        <div id="noDropBody" v-for="item in inverters" :key="item.id">
                            <p class="bodyDesc">{{ item.make }}</p>
                            <p class="errorMsg" v-if="!item.isValidLink">Datasheet not available for this inverter</p>
                        </div>
                    </div>
                </div>
                <div 
                    class="tableBody commonGridClass"
                    v-if="optimizers.length > 0">
                    <el-checkbox v-model="invertersCheck" :disabled="inverterLinks.length == 0">Optimiser</el-checkbox>
                    <div>
                        <div v-for="item in optimizers" :key="item.id">
                            <p class="bodyDesc valueOptimizer">{{ item }}</p>
                            <p class=""></p>
                        </div>
                    </div>
                </div>

                <!-- ---------------------------------------------------------with dropdown--------------------------------------->
                
                <div
                    v-for="(item, key) in dropDownComponents" :key="key">
                    <div class="tableBody commonGridClass" :class="(addNewDropdown == true || item.selectedPDFs.length > 1 )? 'DropdownsRow' : ''" >
                        <el-checkbox v-model="item.isChecked" :class="(addNewDropdown == true || item.selectedPDFs.length > 1) ? 'conditionDropDown' : ''">{{ key }}</el-checkbox>
                        <div class="dropdownContainer">
                            <div 
                                class="moreDropdownsContainer"
                                v-for="(value, idx) in item.selectedPDFs" :key="idx">
                                    <InfiniteDropdown
                                        :options="item.options"
                                        :componentKey="key"
                                        :selectedPDF="value"
                                        :index="idx"/>
                                    <img 
                                        v-if="idx > 0"
                                        src="./assets/img/Group 278.svg" class="icon" @click="setDeleteOption(key, idx)" />
                            </div>
                        </div>
                        <p class="bodyAct" @click="addNewOption(key)" :class="(addNewDropdown == true || item.selectedPDFs.length > 1 )? 'conditionDropDown' : ''">New {{ key }}</p>
                    </div>
                </div>
                <!-- <div class="tableBody commonGridClass">
                    <el-checkbox v-model="checked">Option</el-checkbox>
                    <InfiniteDropdown />
                    <p class="bodyAct">New Structure/Attachment</p>
                </div> -->

                <!-- ---------------------------------------------------------user can add---------------------------------------->
                <div v-for="(item, key, index) in uploadComponents" :key="index" >
                    <div class="tableBody commonGridClass userRow">
                        <el-checkbox v-model="item.isChecked" v-if="editRow.isEditBtnClicked == false">{{ item.component
                        }}</el-checkbox>
                        <input type="text" v-model="item.component" v-if="editRow.isEditBtnClicked == true"
                        @focus="bindEnter"
                        @blur="unbindEnter"
                            class="editInputs" />
                        <div class=''>
                            <p class="bodyDesc" v-if="editRow.isEditBtnClicked == false">{{ item.make }}</p>
                            <input type="text" v-model="item.make" v-if="editRow.isEditBtnClicked == true"
                            @focus="bindEnter"
                        @blur="unbindEnter"
                                class="editInputs" />
                            <!-- <ul class="uploadFilesContainer">
                                <li class="uploadFiles">huiuhihuihuhi</li>
                                <li class="uploadFiles">huiuhihuihuhi</li>
                            </ul> -->
                        </div>
                        <div class="editDelContainer">
                            <img src="./assets/img/Group 294.svg" class="icon" @click="editRowText()" v-if="showEditIcon"/>
                            <img src="./assets/img/tickIcon.svg" class="icon" @click="editRowText()" v-if="showTickIcon"/>
                            <img src="./assets/img/Group 278.svg" class="icon" @click="setDeleteRowIndex(index)" />

                        </div>
                    </div>
                </div>



                <!-- ---------------------------------------------------------footer--------------------------------------------->
                <p class="footerHeading">Upload Custom Datasheet</p>
                <div class="footerInputsContainer">
                    <input type="text" placeholder="Enter Component Name" class="footerInputs" id="input-component" />
                    <input type="text" placeholder="Enter Make" class="footerInputs" id="input-make"  />
                    <div class="positionR">
                        <label for="card_index" class="labelBtn">
                            <div class="imgContainer upldImgCntnr">
                                <p class="upldImgTxt">{{ uploadFileName }}</p>
                                <img src="./assets/img/Group 1716.svg" class="uploadIcon" />
                                <input type="file" id="card_index" multiple="multiple"
                                    style="width: -webkit-fill-available;" accept="application/pdf" @input="handlePdfInput" />
                            </div>
                        </label>
                        <p class="fileType">.pdf</p>
                    </div>
                    <el-button type="primary" class="addDSBtn" @click="uploadPDF()">
                        <span v-show="!isUploadRunning">Add Datasheet</span>
                        <i
                            v-show="isUploadRunning"
                            class="el-icon-loading"/>
                    </el-button>
                </div>

            </div>
        </div>
        <el-button class="el-icon-back backBtn" @click="moveToDesignSummary" type="primary" > Back to Design Summary Page</el-button>   
    </div>
    <DeleteComponentPopup 
        :isDeleteComponentPopup="isDeleteComponentPopup"
        @confirmDelete="deleteOption()"
        @cancelDelete="isDeleteComponentPopup = false" />
    <DeleteComponentPopup 
        :isDeleteComponentPopup="isDeleteUploadComponentPopup"
        @confirmDelete="deleteRow()"
        @cancelDelete="isDeleteUploadComponentPopup = false" />

    <loading :active.sync="isLoading" 
        :can-cancel="true" 
        :on-cancel="onCancel"
        :is-full-page="fullPage"></loading>
</div>
