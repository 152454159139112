
<div id="moveSection">
  <el-dialog
    :visible="showMoveSectionPopup"
    :close-on-click-modal="true"
    title="Move Section"
    @close="closeMoveSectionPopUp"
  >
      <div class="section-cont">
        
        <draggable class="sectionCards" v-model="formData.sections" @end="handleSectionMove" >
          <div v-for="section in formData.sections" :key="section" class="sections">
              <img src="../assets/drag_indicator.svg" />
              <p >{{ section.title }}</p>
          </div>
        </draggable>
      </div>
      
  </el-dialog>
</div>
