
<span>
    <label>
        <input
            v-validate="inputValidation"
            v-model="displayValue.text"
            :name="name"
            :disabled="disabled"
            :class="classInput"
            :data-vv-scope="errorScope !== null ? errorScope : null"
            autocomplete="off"
            @input="onDisplayValueUpdate">
    </label>
</span>
