
<div id="popoverselection">
  <el-popover placement="bottom" width="auto" trigger="click" v-model="isPopoverOpen"
    popper-class="dashboard-selection">
    <div :style="{maxHeight:screenSize<1500?'400px' :'500px',overflowY: 'scroll',width: '358px',overflowX: 'hidden'}" v-infinite-scroll="loadMore">
      <div v-for="(dashboard, inx) in dashboardsData" :key="dashboard?.id||inx">
        <div class="optionscontainer">
          <div class="container-labels options-border ">
            <div class="options " @click="handleClick(dashboard)">
              <p style="padding-right: 0.5rem" @click="handleDefaultSelect(dashboard)">
                <span v-if="defaultDashboardId === dashboard.id">
                  <b-icon :style="{
                    marginRight: '5px',
                    color: 'orange',
                  }" :icon="'pin-angle-fill'"></b-icon></span>
                <span v-else><b-icon :style="{
                  marginRight: '5px',
                  color: 'black',
                }" :icon="'pin-angle'"></b-icon>
                </span>
              </p>

              <p class="labels">
                {{ dashboard.name }}
              </p>
            </div>
            <div class="icons-container " v-if="!isMobile">
              <el-tooltip content="Copy">
                <p class="icons-content" @click="copyOpen(dashboard)">
                  <b-icon icon="files"></b-icon>
                </p>
              </el-tooltip>
              <p v-if="!isMobile" class="icons-content" @click="handleEdit(dashboard)">
                <el-tooltip content="Edit"> <b-icon :icon="'pencil'"></b-icon></el-tooltip>
              </p>
              <p class="icons-content" @click="handleDeleteOpen(dashboard)" v-if="dashboardsData.length > 1">

                <el-tooltip content="Delete"> <b-icon :icon="'trash'"></b-icon></el-tooltip>
              </p>

            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- create dashboard container -->
    <div v-if="!isMobile" :class="['create-options', dashboardsData.length >= 10? 'disabled' : '',]" @click="createOpen">
      <el-tooltip v-if="dashboardsData.length >= 10" class="item" effect="dark"
        content="Maximum of 10 dashboards can be created" placement="top">
        <div class="options">
          <div class="create-container" >
            <span>
              <b-icon
                :style="{
                  color: '#1c3366',
                }"
                class="plus-icon"
                :icon="'plus-circle-fill'"
              ></b-icon>
            </span>
            <span class="createlabel">Create New Dashboard</span>
          </div>
        </div>
      </el-tooltip>
      <div v-else class="options">
        <div class="create-container" >
          <span>
            <b-icon :style="{

              color: '#1c3366',
            }" class="plus-icon" :icon="'plus-circle-fill'"></b-icon></span>
          <span class="createlabel">Create New Dashboard</span>
          </div>
      </div>
    </div>

    <template v-slot:reference>
    <div class="selection-container">
      <el-tooltip class="item" effect="dark" :content="defaultDashboard" placement="top">
        <p style="text-overflow: ellipsis;overflow: hidden;">{{ defaultDashboard }}</p>
      </el-tooltip>
      <p style="padding-left: 0.5rem">
        <i :class="isPopoverOpen ? 'fas fa-angle-up' : 'fas fa-angle-down'"></i>
      </p>
    </div>
    </template>
    <!-- <el-button slot="reference"></el-button> -->
  </el-popover>
  <GlobalDialog :dialogTitle="dialogTitle" :dialogVisible.sync="open" :onClickClose="false" @handleClose="closeDialog"
    :width="'40%'">
    <template v-slot:body>
      <div class="dialogbody">
        <p class="label">Name</p>
        <el-input @input="nameValidation" v-model="newDashboard" maxlength=100 :placeholder="'Enter Dashboard Name'" />
        <p v-if="errorMessage" style="color: red;">Maximun characters limit allowed is 100</p>
      </div>
    </template>
    <template v-slot:footer>
      <div class="alignment">
        <el-button :disabled="!newDashboard" type="primary" @click="handleCreate">Submit</el-button>
      </div>
    </template>
  </GlobalDialog>

  <DeleteDialog :dialogVisible="deleteOpen" :footerButtonType="'primary'" :footerButtonText="'Yes'"
    :disableCancel="true" :width="'20%'" :deleteMessage="'Are you sure you want to delete this dashboard?'"
    @handleClick="handleDelete" @handleClose="deleteOpen = false">
  </DeleteDialog>
</div>
