<template>
  <div class="select-container">
    <p class="title">
      {{ getTitle }} <span v-if="items.isStar" class="astrisk"> *</span>
    </p>
   
    <el-tooltip class="item" effect="dark" :content="placeholderText" placement="top-start" v-if="isDisabled">
      <el-select v-model="selectedItem" :placeholder="items.placeholder" @change="handleSelect" filterable ref="mySelect"
        :disabled="isDisabled">
        <template #prefix v-if="selectedIcon">
          <!-- Customize the prefix content here -->
          <span style="padding-top:10px"> <img :src="selectedIcon" alt="" /></span>
        </template>
        <el-option v-for="(item, indx) in items.options" :key="indx" :label="item?.label || item" :value="item || item">
          <div :class="items.options.length - 1 > indx ? 'option-lables' : ''">
            <p>
              <span v-if="item.icon" class="icons"><img :src="getIcons(item.icon)" alt="" /></span>
              <!-- <span v-if="item.isSubTitle"> {{ item?.isSubTitle }}</span> -->
              <span v-if="!item.isSubTitle"> {{ item?.label || item?.name||'' }}</span>
            </p>
          </div>
        </el-option>
      </el-select>
    </el-tooltip>
    <el-tooltip class="item" effect="dark" :content="selectedItem" placement="top-start" v-else-if="(getTitle=='Column'||getTitle=='Measure')&&selectedItem ">
    <el-select v-model="selectedItem" :placeholder="items.placeholder" @change="handleSelect" filterable
      ref="mySelect" :disabled="isDisabled">
      <template #prefix v-if="selectedIcon">
        <!-- Customize the prefix content here -->
        <span style="padding-top:10px"> <img :src="selectedIcon" alt="" /></span>
      </template>
      <el-option v-for="(item, indx) in items.options" :key="indx" :label="item?.label || item" :value="item || item">
        <div :class="items.options.length - 1 > indx ? 'option-lables' : ''">
          <p>
            <span v-if="item.icon" class="icons"><img :src="getIcons(item.icon)" alt="" /></span>
            <span v-if="!item.isSubTitle"> {{ item?.label || item?.name||'' }}</span>
            <span v-if="item?.additional_details?.units"> ({{ item?.additional_details?.units}}) </span>

          </p>
        </div>
      </el-option>
    </el-select>
  </el-tooltip>
  <el-select v-else v-model="selectedItem" :placeholder="items.placeholder" @change="handleSelect" filterable
      ref="mySelect" :disabled="isDisabled">
      <template #prefix v-if="selectedIcon">
        <!-- Customize the prefix content here -->
        <span style="padding-top:10px"> <img :src="selectedIcon" alt="" /></span>
      </template>
      <el-option v-for="(item, indx) in items.options" :key="indx" :label="item?.label || item" :value="item || item">
        <div :class="items.options.length - 1 > indx ? 'option-lables' : ''">
          <p>
            <span v-if="item.icon" class="icons"><img :src="getIcons(item.icon)" alt="" /></span>
            <span v-if="!item.isSubTitle"> {{ item?.label || item?.name||'' }}</span>
            <span v-if="item?.additional_details?.units"> ({{ item?.additional_details?.units}}) </span>
          </p>
        </div>
      </el-option>
    </el-select>
  </div>
</template>

<script>
import KpiIcon from "./assests/drawerIcons/kpi.svg";
import BarIcon from "./assests/drawerIcons/bar.svg";
import ColumnIcon from "./assests/drawerIcons/column.svg";
import DonutIcon from "./assests/drawerIcons/donut.svg";
import TrendLineIcon from "./assests/drawerIcons/lineGraph.svg";
import SimpleTable from "./assests/drawerIcons/simple_t.svg";
import GroupedTable from "./assests/drawerIcons/groupedTable.svg";
import MapIcon from "./assests/drawerIcons/map.svg";

export default {
  emits: [
    "update:isCustomTime",
    "update:isCustomTimeActivity",
    "update:isCustomTimeClosed",
    "update:isGrouped",
    "item-selected",
  ],

  name: "selectComponentView",

  props: {
    items: {
      type: Object,
      default: () => { },
    },
    selectedValue: {
      type: [String, Number],
      default: '',
    },
    selected: {
      type: String,
      default: () => "",
    },
    indexOfOptions: {
      type: Number,
      default: null,
    },
    isGrouped: {
      type: Boolean,
      default: false,
    },
    widgetType: {
      type: String,
      default: "",
    },
    isCustomTime: {
      type: Boolean,
      default: false
    },
    isCustomTimeClosed: {
      type: Boolean,
      default: false
    },
    isCustomTimeActivity: {
      type: Boolean,
      default: false
    },
    seletedSource: {
      type: String,
      default: ''
    },
    placeholderText: {
      type: String,
      default: 'Select source'
    }
  },

  data() {
    return {
      selectedItem: "",
      radio: "",
      selectedData: "",
      optionsData: [],

      KpiIcon,
      BarIcon,
      ColumnIcon,
      DonutIcon,
      TrendLineIcon,
      SimpleTable,
      GroupedTable,
      MapIcon,
      iconsData: {
        kpi: KpiIcon,
        bar: BarIcon,
        column: ColumnIcon,
        donut: DonutIcon,
        trendline: TrendLineIcon,
        simpletable: SimpleTable,
        groupedtable: GroupedTable,
        map: MapIcon,
      },
      groupedCheck: false,
      selectedIcon: '',
    };
  },
  computed: {
    getTitle() {
      let title = "";
      if (this.$props.widgetType === "Donut" && this.$props.items.titleType) {
        title = this.$props.items.titleType;
      } else {
        title = this.$props.items.title;
      }

      return title;
    },

    isDisabled() {
      if ((this.$props.items.prop !== 'type' && this.$props.items.prop !== 'source') && !this.$props.seletedSource) {
        return true
      } else
        return false
    }
  },
  mounted() {

    this.selectedItem = this.$props.selectedValue;
  },
  updated() {

  },

  methods: {
    getIcons(url) {

      return this.iconsData[url];
      // return new URL(url, import.meta.url).href;
    },

    handleSelect(value) {
      if (value?.icon) {
        this.selectedIcon = this.iconsData[value.icon]
      }
      this.selectedData = value;

      if (this.$props.items.prop === "time_range") {
        if (value.value === 'custom') {
          this.$emit('update:isCustomTime', true)
        } else {
          this.$emit('update:isCustomTime', false)
        }
      }

      if (this.$props.items.prop === "activity_time") {
        if (value.value === 'custom') {
          this.$emit('update:isCustomTimeActivity', true)
          this.$emit('update:isCustomTimeClosed', false)
        } else {
          this.$emit('update:isCustomTimeActivity', false)
        }
      }
      if (this.$props.items.prop === "closed_at") {
        if (value.value === 'custom') {
          this.$emit('update:isCustomTimeClosed', true)
          this.$emit('update:isCustomTimeActivity', false)
        } else {
          this.$emit('update:isCustomTimeClosed', false)
        }
      }


      if (this.$props.items.prop === "type") {

        if (value.label === "TableType - Grouped") {
          this.groupedCheck = true
          this.$emit("update:isGrouped", true);
        } else {
          this.groupedCheck = false
          this.$emit("update:isGrouped", false);
        }
      }
      this.selectedItem = value.label ? value.label : value;

      let tableCheck = "";
      if (
        this.$props.items.prop === "type" &&
        (value.label === "TableType - Simple" ||
          value.label === "TableType - Grouped")
      ) {
        tableCheck = JSON.parse(JSON.stringify(value));

        tableCheck.label = "Table";
        tableCheck.value = "Table";
      } else {
        tableCheck = "";
      }
      setTimeout(() => {
        if (this.$refs.mySelect) {
          this.$refs.mySelect.blur();
        }
      }, 0);




      // if (value !== "Table") {
      this.$emit(
        "item-selected",
        this.$props.items.prop,
        tableCheck ? tableCheck : value,
        tableCheck.value ? tableCheck.value : value.value ? value.value :value.label==='None'?null: value,
        this.$props.items,
        this.$props.indexOfOptions,
        this.groupedCheck
      );
      // }
    },
  },

  watch: {
    selectedValue: {
      handler(val) {
        if (this.$props.items.prop === "type") {
          const selectedType = this.$props.items?.options.find(d => d.label === val)
          this.selectedIcon = this.iconsData[selectedType.icon]


          if (
            val === "TableType - Grouped" ||
            this.selectedItem === "TableType - Grouped"
          ) {
            this.$emit("update:isGrouped", true);
          } else if (
            val === "Table" &&
            this.$props.isGrouped &&
            this.selectedItem !== "TableType - Simple"
          ) {
            this.$emit("update:isGrouped", true);
          } else {
            this.$emit("update:isGrouped", false);
          }
        }
        if (
          val === "Table" ||
          this.selectedItem === "TableType - Grouped" ||
          this.selectedItem === "TableType - Simple"
        ) {
          if (
            this.$props.isGrouped ||
            this.selectedItem === "TableType - Grouped"
          ) {
            this.selectedItem = "TableType - Grouped";
          } else {
            {
              this.selectedItem = "TableType - Simple";
            }
          }
        } else {
          this.selectedItem = val;
        }
      },
    },
    radio: {
      // immediate: true,
      handler(val) {
        // if (!val) {
        //   this.radio = "Simple";
        // } else {
        //   this.radio = val;
        // }

        if (this.selectedData?.value === "Table") {
          let isGroup = val === "Grouped" ? true : false;
          this.$emit("update:isGrouped", isGroup);

          // this.isGrouped = this.radio === "Grouped" ? true : false;
          this.$emit(
            "item-selected",
            this.$props.items.prop,
            this.selectedData,
            this.selectedData.value
              ? this.selectedData.value
              : this.selectedData,
            this.$props.items,
            this.$props.indexOfOptions,
            isGroup
          );
        }
      },
    },
    selectedData(value) {
      if (value) {
        // if (value.value !== "Table") {
        //   this.$emit(
        //     "item-selected",
        //     this.$props.items.prop,
        //     value,
        //     value.value ? value.value : value,
        //     this.$props.items,
        //     this.$props.indexOfOptions,
        //     false
        //   );
        // }
      }
      //   this.selectedItem = null;
    },
    items: {
      immediate: true,
      handler(val) {
        const arr = JSON.parse(JSON.stringify(val.options));
        const tableIndex = arr.findIndex((item) => item.label === "Table");

        if (tableIndex !== -1) {
          // Remove the object with label "Table"
          arr.splice(tableIndex, 1);

          // Insert two new objects after it
          arr.splice(
            tableIndex,
            0,
            {
              isSubTitle: "",
              label: "TableType - Simple",
              value: "TableType - Simple",
              icon: "simpletable",
            },
            {
              isSubTitle: "",
              label: "TableType - Grouped",
              value: "TableType - Grouped",
              icon: "groupedtable",
            }
          );
        }
        this.optionsData = arr;
      },
    },
    isGrouped: {
      immediate: true,
      handler(val) {
        if (val === true) {
          this.radio = "Grouped";
        } else {
          this.radio = "Simple";
        }
      },
    },
  },
};
</script>

<style scoped>
.select-container {}

.title {
  padding-bottom: 0.5rem;
  font-size: 12px;
  color: #777;
  font-weight: 600;
}

.el-select {
  width: 100%;
}

.select-container>>>.el-input__inner {
  border: none;
  background: #e8edf2;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 40px;
  color: black
}

.select-container>>>.el-select .el-input .el-select__caret {
  color: black;
  font-size: 20px;
}

.select-container>>>.el-input__inner::placeholder {
  font-size: 14px;
  color: #777;
}

.el-select-dropdown__item {
  min-height: 42px !important;
  height: auto !important;
  line-height: 40px !important;
  padding: 0px 12px !important;
  color: black;
}

.el-select-dropdown__item.selected {
  color: black !important;
  font-weight: 500;
}

.select-container>>>.el-input__prefix {
  padding-top: 10px !important
}

.option-lables {
  border-bottom: 1px solid #ccc;
  color: black;
}

.icons {
  padding-right: 0.5rem;
}

.el-radio__inner {
  width: 1rem !important;
  /* Adjust this value to increase or decrease the size of the dot */
  height: 1rem !important;
  /* Adjust this value to increase or decrease the size of the dot */
}

.select-container>>>.el-radio__input.is-checked .el-radio__inner::after {
  transform: translate(-50%, -50%) scale(2.5) !important;
  background: white;
}

.astrisk {
  font-size: 12px;
  font-weight: 600;
  color: #f00;
}
</style>
