import arrow1 from '../saleStudio/assets/arrow1.svg';
import arrow2 from '../saleStudio/assets/arrow2.svg';
import arrow3 from '../saleStudio/assets/arrow3.svg';
import arrow4 from '../saleStudio/assets/arrow4.svg';
import arrow5 from '../saleStudio/assets/arrow5.svg';
import arrow6 from '../saleStudio/assets/arrow6.svg';
import arrow7 from '../saleStudio/assets/arrow7.svg';
import arrow8 from '../saleStudio/assets/arrow8.svg';
import arrow9 from '../saleStudio/assets/arrow9.svg';
import arrow10 from '../saleStudio/assets/arrow10.svg';
import arrow11 from '../saleStudio/assets/arrow11.svg';
import arrow12 from '../saleStudio/assets/arrow12.svg';
import arrow13 from '../saleStudio/assets/Arrow13.svg';
import arrow14 from '../saleStudio/assets/Arrow14.svg';
import arrow15 from '../saleStudio/assets/Arrow15.svg';
import arrow16 from '../saleStudio/assets/Arrow16.svg';
import arrow17 from '../saleStudio/assets/Arrow17.svg';
import arrow18 from '../saleStudio/assets/Arrow18.svg';
import arrow19 from '../saleStudio/assets/Arrow19.svg';
import arrow20 from '../saleStudio/assets/Arrow20.svg';
import arrow21 from '../saleStudio/assets/Arrow21.svg';
import arrow22 from '../saleStudio/assets/Arrow22.svg';
import arrow23 from '../saleStudio/assets/Arrow23.svg';
import arrow24 from '../saleStudio/assets/Arrow24.svg';
import arrow25 from '../saleStudio/assets/Arrow25.svg';
import arrow26 from '../saleStudio/assets/Arrow26.svg';
import arrow27 from '../saleStudio/assets/Arrow27.svg';
import arrow28 from '../saleStudio/assets/Arrow28.svg';
import arrow29 from '../saleStudio/assets/Arrow29.svg';
import arrow30 from '../saleStudio/assets/Arrow30.svg';
import arrow31 from '../saleStudio/assets/Arrow31.svg';
import arrow32 from '../saleStudio/assets/Arrow32.svg';

export const arrayOfAzimuthNumberImages = [
    arrow1,arrow2,arrow3,arrow4,arrow5,arrow6,
    arrow7,arrow8,arrow9,arrow10,arrow11,arrow12,
    arrow13,arrow14,arrow15,arrow16,arrow17,arrow18,
    arrow19,arrow20,arrow21,arrow22,arrow23,arrow24,
    arrow25,arrow26,arrow27,arrow28,arrow29,arrow30,
    arrow31,arrow32,
];