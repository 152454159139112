import {
  getUserFromLocalStorage,
  getOrganisationFromLocalStorage,
  isCrmUser,
} from "../../utils"


// SOM roles check
export function isSOMUsers() {
  // const isVIP=getOrganisationFromLocalStorage().vip_for_power_gazebo;
  const isProjectManager = getUserFromLocalStorage().is_project_manager;
  const isInternalSales = getUserFromLocalStorage().role == "ARKA_INTERNAL_SALES";
  const isSalesAdmin = getUserFromLocalStorage().role == "ADMIN" && getOrganisationFromLocalStorage().is_vip_aggregator;
  const isSalesOps = getUserFromLocalStorage().role == "ARKA_SALES_OP";

  return ((isSalesOps || isSalesAdmin || isInternalSales || isProjectManager) && isCrmUser()) ? true : false;
}