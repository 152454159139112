import { defineStore } from 'pinia'
import API from '../services/api';
import { useDesignStore } from './design';

export const useBatteryStore = defineStore('battery', {
    state: () => {
        return {
            additional_savings_post_battery: 0,
            annual_generation: 0,
            battery_backup_on_storage: 0,
            battery_backup_on_storage_and_solar: 0,
            battery_backup_on_storage_and_load: 0,
            battery_detail: [],
            battery_model: "",
            battery_sizing_type: "",
            battery_tier_details: [],
            energy_offset_with_solar: 0,
            energy_offset_with_solar_and_battery: 0,
            for_self_consumption: false,
            grid_metrics: {},
            bills: {},
            has_solark_inverter: false,
            id: -1,
            minimum_battery_capacity: 0,
            project: {
                consumption_details: {
                    utility_tariff_details: {}
                },
                country_details: {
                    country_code: ""
                },
                is_consumption_added: false,
            },
            self_consumption: 0,
            self_consumption_ending_hours: [],
            self_consumption_starting_hours: [],
            system_type: "",
            total_consumption_hours: 0,
        }
    },
    getters: {
        essOnlyDesign() {
            return this.system_type == "ess_only_design"
        },
        isConsumptionAdded: state => state.project.is_consumption_added,
        countryCode: state => state.project.country_details.country_code,
    },
    actions: {
        async SET_BATTERY_DETAILS(designId) {
            this.$reset()

            let resp = await API.DESIGNS.FETCH_BATTERY_DETAILS(designId)
            let respData = resp.data

            this.additional_savings_post_battery = respData.additional_savings_post_battery || 0
            this.annual_generation = respData.annual_generation
            let annualGenerationFromDesign = useDesignStore().annual_generation
            if (annualGenerationFromDesign) {
                this.annual_generation = annualGenerationFromDesign
            }
            this.battery_backup_on_storage = respData.battery_backup_on_storage
            this.battery_backup_on_storage_and_solar = respData.battery_backup_on_storage_and_solar
            this.battery_backup_on_storage_and_load = respData.battery_backup_on_storage_and_load
            this.battery_detail = respData.battery_detail || []
            this.battery_model = respData.battery_model
            this.battery_sizing_type = respData.battery_sizing_type
            this.battery_tier_details = respData.battery_tier_details
            this.energy_offset_with_solar = respData.energy_offset_with_solar
            this.energy_offset_with_solar_and_battery = respData.energy_offset_with_solar_and_battery
            this.for_self_consumption = respData.for_self_consumption
            this.grid_metrics = respData.grid_metrics
            this.bills = respData.bills
            this.has_solark_inverter = respData.has_solark_inverter
            this.id = respData.id
            this.minimum_battery_capacity = respData.minimum_battery_capacity
            this.project = respData.project
            this.self_consumption = respData.self_consumption
            this.self_consumption_ending_hours = respData.self_consumption_ending_hours
            this.self_consumption_starting_hours = respData.self_consumption_starting_hours
            this.system_type = respData.system_type
            this.total_consumption_hours = respData.total_consumption_hours
        }
    },
})