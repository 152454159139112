export default {
    HOME_STATE: 'HOME_STATE',
    LOADING_STATE: 'LOADING_STATE',
    VIEW_3D_STATE: 'VIEW_3D_STATE',
    DRAWING_STATE: 'DRAWING_STATE',
    NO_COMPLETE_DRAWING_STATE: 'NO_COMPLETE_DRAWING_STATE',
    CANCEL_STATE: 'CANCEL_STATE',
    COMPLETE_CANCEL_STATE: 'COMPLETE_CANCEL_STATE',
    UNDO_AVAILABILITY: 'UNDO_AVAILABILITY',
    REDO_AVAILABILITY: 'REDO_AVAILABILITY',
    SAVE_BUTTON_STATUS: 'SAVE_BUTTON_STATUS',
    VIEW_SLD_STATE: 'VIEW_SLD_STATE',
    UNDO_REDO_COMPLETE_STATE: 'UNDO_REDO_COMPLETE_STATE',
    ONLY_COMPLETE_STATE: 'ONLY_COMPLETE_STATE', // TODO: Create this state...
};
