<template>
  <div>
    <el-drawer
      :append-to-body="true"
      title="I am the title"
      :visible="drawer"
      class="allDrawer"
      :with-header="false"
      :size="drawerSize"
      :show-close="true"
      :before-close="onClose"
    >
      <component
        :is="componentName"
        :drawerStage.sync="drawerStage"
        :propsData.sync="propsData"
        @save="onSave()"
        @close="onClose()"
        :key="taskKey"
        :leadId="leadId"
        :tasks="tasks"
        :project_id="project_id"
        :forAddTask="forAddTask"
        :isDraw="isDrawer"
        :showWarningPopUp="showWarningPopUp"
        @handleShowWarning="handleShowWarning"
      >
        <template #header> </template>
        <template #body> </template>
        <template #footer> </template>
      </component>
    </el-drawer>
    <CloseDialog
      v-if="isCloseDialogOpen"
      :isDeleteProjectDocumentPopupOpen="isCloseDialogOpen"
      @cancelDelete="isCloseDialogOpen = false"
      @confirmDelete="confirmClose"
      message="Are you sure you want to discard your changes?"
    />
  </div>
</template>

<script>
import addOrEditTask from '../../leadManagement/components/addOrEditTask.vue';
import showTaskDetails from '../../leadManagement/components/models/showTaskDetails.vue';
import setReminder from "./../../setReminder/setReminder.vue";
import createLead from "./../../createLead/components/index.vue";
import dialogSection from "../../designOrders/components/dialogSection.vue";
import editActivity from "../../leadManagement/components/editActivity.vue";
import CloseDialog from '../../project/components/projectDocuments/deleteProjectDocument.vue';
import { mapActions } from 'pinia';
import { useMiscStore } from '../../../stores/misc';
export default {
  emits: ["save", "close"],
  data() {
    return {
      isReminder: true,
      taskKey: 0,
      showWarningPopUp: false,
      isCloseDialogOpen: false,
    };
  },
  props: [
    "isDrawer",
    "drawer",
    "componentName",
    "drawerSize",
    "drawerStage",
    "propsData",
    "leadId",
    "tasks",
    "project_id",
    "forAddTask",
  ],
  components: {
    showTaskDetails,
    setReminder,
    createLead,
    dialogSection,
    editActivity,
    addOrEditTask,
    CloseDialog,
  },
  methods: {
    ...mapActions(useMiscStore, {
      setDrawerState: "SET_DRAWER_STATE"
    }),
    handleShowWarning(bool){
      this.showWarningPopUp = bool
    },
    confirmClose(){
      this.showWarningPopUp = false
      this.onClose()
      this.isCloseDialogOpen = false
    },
    onSave() {
      this.$emit("save", false);
    },
    onClose(done) {
      if(this.showWarningPopUp){
        this.isCloseDialogOpen = true;
        done()
      }else{
        this.$emit("close", false);
      this.$emit("save", false);
      }
   
    },
  },
  destroyed() {
    this.setDrawerState('globalDrawer', false);
  },
  watch: {
    drawerStage(newVal, oldVal) {
    },
    drawer(isDrawer) {
      if(isDrawer){
        if(this.componentName=="addOrEditTask") {
          this.taskKey++;
        }
        this.setDrawerState('globalDrawer', true);
      }
      else {
        this.setDrawerState('globalDrawer', false);
      }
      
    },
  },
};
</script>

<style lang="scss" scoped></style>
