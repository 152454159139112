<template>
    <div :class="['parentTabContainer', isWeatherOptions ? 'parentContainerForWeather' : '',
        isTabDisabled ? 'disable' : '']">
        <div :class="['tabBox', isFullWidth ? 'tabBoxFullWidth' : '']" v-if="showTabs">
            <el-radio-group v-model="selectedOption" style="margin-bottom: 30px;">
                <el-tooltip v-for="option in options" :key="option" :content="tooltipMsg"
                    placement="top" :disabled="!isTooltipEnabled(option)">
                    <el-radio-button :label="option" :disabled="isDisabled(option)" :class="{ 'disabled-tab': isDisabled(option) }"> {{ option }}
                    </el-radio-button>
                </el-tooltip>
            </el-radio-group>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { useProjectStore } from '../../../../stores/project';
import { useOrganisationStore } from '../../../../stores/organisation';
export default {
    emits: [ "selected-option"],
    components: {
    },

    props: {
        options: {
            type: Array
        },
        showTabs: {
            type: Boolean,
            default: true
        },
        isFullWidth: {
            type: Boolean,
            default: false
        },
        selectedOptionFromDB: {
            type: String || Number,
        },
        isWeatherOptions: {
            type: Boolean,
            default: false
        },
        isTabDisabled: {
            type: Boolean,
            default: false,
        },
        isPreSolarFilled: {
            type: Boolean,
            default: false,
        }
    },

    data() {
        return {
            selectedOption: this.selectedOptionFromDB || this.options[0],
            tooltipMsg:"",
        };
    },

    created() {
        this.selectedOption = this.selectedOptionFromDB || this.options[0];
    },

    computed: {
        ...mapState(useProjectStore, {
            projectStore: (state) => state,
        }),
        ...mapState(useOrganisationStore, {
            orgStore : (state) => state,
        })
    },

    methods: {
        isTooltipEnabled(option) {
            if(option == 'Post Solar' && !this.isPreSolarFilled)
            {
                this.tooltipMsg = "Please add pre-solar rates to access";
                return true;
            }
            else if(option =="Future Profile" && this.projectStore.project_type == "commercial")
            {
                this.tooltipMsg = "Available for residential projects only.";
                return true;
            }
            return false;
        },
        isDisabled(option){
            if(option == 'Post Solar' && !this.isPreSolarFilled)
                return true;
            else if(option =="Future Profile" && this.projectStore.project_type == "commercial")
                return true;
            else if(option == "Year" && (this.projectStore.isConsumptionBeingCustomized || this.projectStore.isConsumptionBeingEdited || this.orgStore.isConsumptionCustomised))
                return true;    
            
            return false;
        }   
    },

    watch: {
        selectedOption(newValue) {
            if (newValue == "Current Profile") {
                this.$emit('selected-option', "currentProfile");
            } else if (newValue == "Future Profile") {
                this.$emit('selected-option', "futureProfile");
            } else {
                this.$emit('selected-option', newValue);
            }
        },
    },
};
</script>

<style scoped>
.parentTabContainer {
    position: relative;
}
.disabled-tab {
    opacity: 0.5;
}

.disable {
    opacity: 0.5;
    pointer-events: none;
}

.tabBox {
    width: fit-content;
    padding: 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
    white-space: normal;
    word-break: auto-phrase;
}

.tabBoxFullWidth {
    width: 100%;
}

.tabBox>>>.el-radio-group {
    display: flex;
    gap: 4px;
    margin-bottom: 0px !important;
}

.tabBox>>>.el-radio-button:first-child .el-radio-button__inner {
    border-left: none;
}

.tabBox>>>.el-radio-button__inner {
    border-radius: 4px;
    border: none;
    color: #222;
    font-size: 13px;
    padding: 8px 12px;
    background-color: #F2F5F8;
}

.tabBoxFullWidth>>>.el-radio-button__inner {
    background-color: #fff;
}

.tabBox>>>.el-radio-button__orig-radio:checked+.el-radio-button__inner {
    color: #fff;
    background-color: #409EFF;
}

.tabBoxFullWidth>>>.el-radio-button {
    width: 100%;
}

.tabBoxFullWidth>>>.el-radio-button__inner {
    width: 100%;
}

.tabBox>>>.el-radio-button:first-child .el-radio-button__inner {
    border-left: none;
}


@media (max-width: 550px) {

    .parentContainerForWeather>>>.el-radio-button__inner {
        color: #222;
        font-size: 10px;
        padding: 8px 8px;
    }

}
</style>