
<div>
  <ListTableView
    :tableData="tableData"
    :tableColumns="computedHeading"
    :getCellStyles="getCellStyles"
    :getHeaderStyles="getHeaderStyles"
    :paddingLeft="computedPadding"
    :weight="600"
    :size="'16px'"
    :color="'#1C3366'"
    @updateValues="updateValues"
    @updateStatus="updateStatus"
    @updatePriority="updatePriority"
    @updateDateValues="updateDateValues"
    :loadMore="loadMoreData"
    @loadLead="loadLead"
    @nudgeClicked="handleNudgeClick"
    :tableHeightOffset="'16rem'"
    :handleRowClick="onRowClick"
    :cellHeight="'60px'"
    :showHeaderSortable="true"
    :taskCount="taskCount"
    :isDrilldown="isDrilldown"
  />
  <div
    style="
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
      border-radius: 5px;
    "
    v-if="showTableLoader"
  >
    <i class="el-icon-loading"></i>
  </div>
</div>
