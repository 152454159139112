
<GoogleMaps
  :center="geoLocation.center"
  :zoom="geoLocation.zoom"
  :mapOptions="mapOptions"
  :showCentralMarker="true"
  :address="address"
  @centerChanged="updateCenter"
  @zoomChanged="updateZoom"
  @idle="sync"
/>
