
<div class="parentContainer">
  <el-dialog
    :visible="isDeleteTariffPopup"
    :close-on-click-modal="false"
    title="Add User"
    width="600px"
    class="delete_module"
    @close="closeModal"
  >
    <div class="close_button">
      <img
        src="../../../assets/img/close.svg"
        alt="close"
        @click="$emit('close')"
      />
    </div>
    <div class="alert">
      <img
        src="../../../assets/img/alert.svg"
        alt="alert"
        class="warningImage"
      />
    </div>
    <p class="msg">Are you sure you want to delete this Tariff?</p>
    <p class="content">
      This action cannot be undone. Please note that this will not affect any
      projects that have already used this tariff. To edit the tariffs in
      existing projects, please open the project and make the necessary
      changes.
    </p>
    <div class="button_container">
      <el-button type="primary" :loading="isLoading" @click="deleteTariff()"
        >Yes</el-button
      >
    </div>
  </el-dialog>
</div>
