<template>
  <div class="card" id="chat">
    <div class="card_header" @click="toggleCollapse">
      <div class="heading-container">
        <el-row>
          <el-col :span="12" class="left-content">
            <h4 v-if="isESOrg()">
              {{
                orderData.owner_name && orderData.owner_name !== "NA"
                  ? orderData.owner_name
                  : `Installer`
              }}
            </h4>
            <h4 v-else>
              {{ `Expert Services` }}
            </h4>
          </el-col>
          <el-col :span="12" class="right-content">
            <h4>
              <i
                :class="
                  this.collapsed == false
                    ? 'fas fa-angle-down'
                    : 'fas fa-angle-up'
                "
              ></i>
            </h4>
          </el-col>
        </el-row>

        <div class="hover_information">
          <i class="fas fa-info-circle"></i>
          <div class="tooltip">
            <p>You can talk to the designer here related to this project.</p>
          </div>
        </div>
      </div>
      <img
        src="../../../../assets/img/arrows-angle-expand.png"
        class="expand"
      />
    </div>
    <div class="chatBoxContainer" v-show="!collapsed" v-loading="chatloader"   > 
      <div
        class="card_content"
        id="messageContainer"
        v-if="allChatMessages && allChatMessages?.length != 0"
        @scroll="handleScroll"
       
      >
        <div
          class="contentShow"
          id="messageContainer-content"
          v-for="(data, index) in allChatMessages"
          :key="index"
        >
          <div
            class="userMsgCont"
            v-if="!currentUser(data.user_id) && data.message_type === 'message'"
          >
            <!-- <p class="userName">{{ data.user_name }}</p> -->
            <div class="userMsgDiv">
              <div
                v-if="
                  data.documents?.length === 0 && data.message_type === 'message'
                "
                class="userMsg"
              >
                <div v-html="formattedString(data.message)"></div>

            </div>

              <div
                v-if="
                  data.documents?.length > 0 && data.message_type === 'message'
                "
                @click="isDropdown = true"
                class="userMsg"
                id="userMsgID"
              >
                <div
                  v-for="document in data.documents"
                  :key="document.id"
                  class="documentFeedMessage"
                  id="userMsgID"
                >
                <span><i class="el-icon-document"></i></span>  
               <p class="file-displayname">{{ document.display_name }}</p>   
                  <span
                    class="download-container"
                    @click="downloadDocument(document)"
                  >
                    <img src="./assests/Download.svg" class="allDwnldIcon"
                  /></span>
              
            </div>
              

                <div class="userMsg">
                  <div v-html="formattedString(data.message)"></div>
                </div>
              </div>
              <p class="userTime">
                {{ convertUTCInLocalDate(data.timestamp) }}
              </p>
            </div>
          </div>

          <div class="ourMsgCont" v-else-if="data.message_type!='event'" >
           
            <div
              v-if="
                data.documents?.length === 0 && data.message_type === 'message'
              "
              class="ourMsg"
            >
              <div v-html="formattedString(data.message)"></div>
          </div>
            <div
              v-if="
                data.documents?.length > 0 && data.message_type === 'message'
              "
              class="dropdownMenu"
            >
              <div
                @click="isDropdown = document.id"
                v-for="document in data.documents"
                :key="document.id"
                class="documentFeedMessage"
                id="userMsgID"
              >
             <span><i class="el-icon-document"></i></span>   
            <p class="file-displayname"> {{ document.display_name }} </p>   
                <span
                  class="download-container"
                  @click="downloadDocument(document)"
                >
                  <img src="./assests/Download.svg" class="allDwnldIcon"
                /></span>
              
          </div>

              <div class="ourMsg">
                <div v-html="formattedString(data.message)"></div>


              </div>

            </div>
            <p class="ourTime"  >{{ convertUTCInLocalDate(data.timestamp) }}</p>

          </div>
          <div class="eventContainer">

            <div class="eventMessages" v-if="data.message_type === 'event'">
              <div v-html="formattedString(data.message)"></div>
              <el-row>    <div class="ourEventTime">{{ convertUTCInLocalDate(data.timestamp) }}</div></el-row>
          

            </div>
          </div>
        </div>
      </div>
      <div class="noChat" v-else>
        <img src="../../../../assets/drop/chat-dots.png" class="chatIcon" />
        <p class="noChatContent">
          You can discuss doubt or share additional information regarding this
          project with the designer here.
        </p>
      </div>
      <div class="msgContainer">
        <img src="../../../../assets/img/Group 1804.png" class="emoji" />
        <img src="../../../../assets/img/Group 1805.png" class="filesIcon" />
        <div class="footer-container">
          <div class="msgInput">
           
            <el-row
              style="
                margin-left: 35px;
                background-color: #e8edf2;
                margin-right: 35px;
              "
            >
              <!-- First row of footer-content -->
              <el-col :span="12" v-for="(file, index) in fileList" :key="index">
                <div class="file-preview">
                  <span class="previewContainer">
                    <i class="el-icon-document"></i>
                    <span
                      ><p class="filename">{{ file.name }}</p></span
                    >
                    <span class="removeFile" @click="removeFile(index)"
                      >&times;</span
                    >
                  </span>
                </div>
              </el-col>
            </el-row>

            <div class="footer-content">
              <el-upload
                class="upload-demo"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :onChange="handleOnChange"
                :before-upload="handleBeforeUpload"
                action=""
                :before-remove="handleBeforeRemove"
                :on-success="handleSuccess"
                :on-progress="handleOnProgress"
                :submit="handleOnSubmitFinal"
                :on-error="handleOnError"
                :http-request="handleHttpReq"
                :on-exceed="handleOnExceed"
                :file-list="fileList"
                multiple
                :show-file-list="false"
                thumbnail-mode
                ref="uploader"
              >
                <img
                  v-if="this.$props.orderData?.project"
                  src="./assests/upload.svg"
                  class="chatIcon"
                  @click="handleFileUpload"
                />
              </el-upload>

              <!-- <input class="chat-message-input" type="text" v-model="messageVmodal" v-on:keyup.enter="chatSend" /> -->
            
                            <el-input
                            class="chat-message-input"
              type="textarea"
              :autosize="{ minRows: 1, maxRows: 4}"
              placeholder=""
              v-model="messageVmodal"
              @keydown.native="handleEnter"
              >
            </el-input>

              <el-button type="primary" class="sendBtn" @click="chatSend">
                ➤
              </el-button>
            </div>
          </div>
        </div>
        <div id="recMsgFromWS"></div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/services/api/";
import { DATABASE_URL_FOR_WEBSOCKET } from "../../../../constants";
import { Upload } from "element-ui";
import { saveAs } from "file-saver";
import { isExpertServicesOrg } from "../../../../utils";
import { alignIntercom, alignIntercomNormal } from "../../../../plugins/intercom";
import { DateTime } from 'luxon';
import {mapState,mapActions} from 'pinia';
import { useUiStore } from "../../../../stores/ui";
import debounce from "debounce";


export default {
  name: "chatBox",
  data() {
    return {
      myMessages: null,
      messageVmodal: "",
      projectId: this.$route.params.projectId,
      chatSocket: "",
      chatMessages: [],
      chatNextUrl:null,
      socket: "",
      collapsed: this.isESOrg() ? false:true,
      fileList: [],
      document_ids: [],
      isDropdown: false,
      chatloader:false,
      intervalId: null,
      isLoadDataAdded:false,
    };
  },
  components: {
    "el-upload": Upload,
  },
  computed:{
    ...mapState(useUiStore, {
      isChatBoxClosed: state => state.openChatBox
    }),
    allChatMessages(){
      return this.chatMessages
    }

  },
  mounted() {
    window.addEventListener("chatSocket-onMessage-called", (event) => {
      this.myMessages = event.detail.storage;
      this.myMessages = JSON.parse(this.myMessages);
      this.chatMessages.push(this.myMessages);
      const isUnique = (value, index, self) =>
        self.findIndex(
          (obj) =>
            obj.message === value.message && this.areDatesEqual(obj.timestamp,value.timestamp) &&obj.id===value.id
        ) === index;
       
      this.uniqueMessages(this.chatMessages)
      this.scrollToBottom();
    });
    this.intervalId = setInterval(() => {
      if(!this.collapsed){
        this.closeWebSocket();
      // Wait for a brief moment before reconnecting to ensure the socket is closed
      setTimeout(() => {
        this.connectWebsocket();
      }, 1000); // Wait for 1 second before reconnecting
      }

    }, 120000); 


  },
  props: {
    orderData: {
      type: Object,
    },
    drawerStatus:{
      type:Boolean,
      default:false
    },
    isDesignSummary:{
      type:Boolean,
      default:false
    }
  },
  created() {
 this.collapsed=this.isESOrg() ? false:this.isChatBoxClosed
    // this.connectWebsocket();
    this.getMessagesFromWebsocket();
    this.getChatMessagesFromDb();
    this.debounceLoadMore = debounce(this.loadMoreChat, 300);
  },

  
  watch: {
    drawerStatus(val){
      if(!val){
        // this.collapsed= true;
        this.closeWebSocket();
      }else{
        if(this.isESOrg()){
          this.scrollToBottom();

          // this.collapsed=false
        }
      }

    },
    orderData(newVal, oldVal) {
    
      this.getChatMessagesFromDb();
if(this.$props.isDesignSummary){
  this.collapsed = true;

}else{
  this.collapsed = false;

}

    },
    collapsed:{
      immediate:true,
      handler(val){
        if(val==false){
          alignIntercom();
          this.connectWebsocket();
          this.getChatMessagesFromDb()

        }else{
          alignIntercomNormal();
          this.closeWebSocket();
          this.scrollToBottom();

        }

      }
    },
 
    isChatBoxClosed:{
      handler(val){
if(val==false){
  this.collapsed=false
}
      }
    }
  },


  beforeDestroy() {
    this.closeWebSocket(); // Close the WebSocket connection when the component is destroyed
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  updated() {
    this.scrollToBottom();
  },
  methods: {
    ...mapActions(useUiStore, ["SET_CLOSE_CHAT_BOX"]),

    formattedString(str){
      return str.replace(/\n/g, "<br>");
    },

    formatDates(date) {
      const dateData = new Date(date);
      const localDatetime=  new Date(dateData.toLocaleString())
      const formattedDate = dateData.getTime();
      return formattedDate;
    },
    uniqueMessages(messages) {
      messages.map(cht=>cht.timeMills=this.formatDates(cht.timestamp))
      const uniqueMap = new Map();
      messages.forEach((message) => {
        const key = `${message.user_id}_${message.timeMills}_${message.message}`;
        if (!uniqueMap.has(key) || !uniqueMap.get(key).documents.length) {
            uniqueMap.set(key, message);
        }
      });
      this.chatMessages=Array.from(uniqueMap.values());
    },
    hasSpacingOrNewline(text) {
      const regex = /^\s*$/;
      return regex.test(text);
    },
    isESOrg() {
      return isExpertServicesOrg()
    },
    checkWebSocketConnection() {
  
      if (this.chatSocket && this.chatSocket.readyState === WebSocket.OPEN) {
        return true;
      } else {
        return false;
      }
    },
  
    async downloadDocument(file) {
      // Implement your logic to handle the download action for the document with the given ID
      let fileIDForDesigner = file.id;
      let arrayIds_forDesigner = [];
      arrayIds_forDesigner.push(fileIDForDesigner);
      let postData = {
        document_ids: arrayIds_forDesigner,
      };
      let response = await API.DOCUMENT_INFO.DOWNLOAD_DOCUMENTS(postData);
      let url = response.data[0];
      saveAs(url, file.display_name);
      this.isDropdown = false;
    },
    async previewDocument(id) {
      // Implement your logic to handle the preview action for the document with the given ID
      let fileIDForDesigner = id;
      let arrayIds_forDesigner = [];
      arrayIds_forDesigner.push(fileIDForDesigner);
      let postData = {
        document_ids: arrayIds_forDesigner,
      };
      let response = await API.DOCUMENT_INFO.DOWNLOAD_DOCUMENTS(postData);
      let previewUrl = response.data[0];

      if (previewUrl) {
        window.location.href = previewUrl;
      } else {
        console.error("Error retrieving preview URL.");
      }
    },

    async handleSave() {
      const formData = new FormData();
      for (let i = 0; i < this.fileList?.length; i++) {
        formData.append(
          "file[]",
          this.fileList[i].raw,
          this.fileList[i].name.replace(/[<>$:@"\/%|\?\#*!]/g, "_")
        );
      }
      if (this.$props.orderData) {
        formData.append("project", this.$props.orderData.project);
        formData.append("order", this.$props.orderData.id);
      }

      let uploaded_by = localStorage.getItem("organisation").id;
      formData.append(
        "uploaded_by",
        uploaded_by == 822 ? "designer" : "installer"
      );
      //   formData.append("file[]", data);
      //   data.map((item) => formData.append("file[]", item.raw));

      let response =
        this.fileList.length > 0
          ? await API.DOCUMENT_INFO.POST_DOCUMENTS(formData)
          : [];
      this.document_ids =
        this.fileList.length > 0 ? response.data.map((item) => item.id) : [];
      this.messageVmodal
        ? (this.messageVmodal = this.messageVmodal)
        : (this.messageVmodal = " ");
      // this.getDocuments();
    },
    removeFile(index) {
      this.fileList.splice(index, 1); // Remove the file from the files array
    },
    async handleUploadFiles() {
      await this.$props.handleUpload(this.fileList);
      this.$refs.uploader.clearFiles();
    },
    handleOnChange(file, fileList) {
      this.fileList = fileList;
    },
    handleBeforeUpload(file) {
    },
    handleHttpReq(e) {
    },

    handlePreview(data) {
   
    },

    handleRemove(data, fileList) {
      this.fileList = fileList;
    },
    handleBeforeRemove(file, fileList) {
    },
    handleOnExceed(files, fileList) {
      this.$notify({
        title: "Warning",
        message: `Only ${this.limit} file(s) can be uploaded`,
        type: "warning",
        duration: 2000,
        position: "top-right",
      });
      // this.toggleExceedDialogModeState()
    },
    handleSuccess(response, file, fileList) {
    },
    handleOnError(err, file, fileList) {
    },
    handleOnProgress(event, file, fileList) {
    },

    handleOnSubmitFinal() {
    },
    toggleCollapse() {
      this.collapsed = !this.collapsed;
      this.SET_CLOSE_CHAT_BOX(this.collapsed);
    },
    reverseDisplay(myList) {
      let result = myList.slice().reverse();
      return result;
    },

    convertUTCInLocalDate(utcDate) {
      let localDate = new Date(utcDate);
      localDate = localDate.toLocaleString();
      return localDate;
    },
    currentUser(userId) {
      const user = this.getUserIformation();
      let result = false;
      if (userId == user.user_id) {
        result = true;
      }
      return result;
    },
    async getChatMessagesFromDb() {
      if(this.$props.orderData?.id){
      this.chatloader=true;
      // alert("called")
      const response = this.orderData
        ? await API.CHAT_iNFO.FETCH_CHAT_MESSAGE(this.$props.orderData.id)
        : await API.CHAT_iNFO.FETCH_CHAT_MESSAGES(this.projectId);
      this.chatNextUrl=response.data.next;
      this.chatMessages = response.data.results;
      this.chatMessages = this.reverseDisplay(this.chatMessages);
      if (response.data?.count) {
        const patchData = {
          order_id: this.orderData.id,
          message_offset: response.data.count,
        };
        await API.DESIGN_ORDERS.UNREAD_NOTIFICATIONS_UPDATE(patchData);
      }
      if(this.chatMessages){
        this.chatloader=false;

      }
    }
    },
    handleFileUpload() {
      // this.$refs.upload.$el.querySelector('input[type="file"]').click();
      // alert("hello")
    },
    handleUploadSuccess(response) {
      // Handle the upload success event
    },

    areDatesEqual(date1,date2) {
      const luxonDate1 = DateTime.fromISO(date1);
      const luxonDate2 = DateTime.fromISO(date2);

      // Compare the dates
      return luxonDate1.equals(luxonDate2);
    },
    handleEnter(event) {
    // Check if Shift key was not pressed
    if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault(); // Prevent the default action (inserting a new line)
        this.chatSend();
      }
  },

    async chatSend() {
      const checkEmpty = this.hasSpacingOrNewline(this.messageVmodal);
      await this.handleSave();
      // this.messageVmodal==null?this.messageVmodal=" ":this.messageVmodal=this.messageVmodal;
     
      this.fileList = [];
      if (this.checkWebSocketConnection() === false) {
        await this.connectWebsocket();
        // this.chatSend();
      }
      if (this.messageVmodal || this.document_id) {
        let user = this.getUserIformation();
        const now = DateTime.now();
        const currentDateTime = DateTime.now();
        const formattedDateTime = currentDateTime.toISO({ millis: true, includeOffset: true });
        let PostObj = {};
        let refObj={};
      
        this.$props.orderData
          ? (PostObj = {
              order_id: this.$props.orderData.id,
              message: this.messageVmodal,
              user_id: user.user_id,
              event: "message",
              message_type: "message",
              document_ids: this.document_ids,
            })
          : (PostObj = {
              project_id: this.projectId,
              message: this.messageVmodal,
              user_id: user.user_id,
              document_ids: this.document_ids,
            });

            refObj={
              "message": this.messageVmodal?this.messageVmodal:'',
              "timestamp": formattedDateTime,
              "user_id": user.user_id,
              // "user_name": "Surendar K",
              "event": "message",
              "message_type": "message",
              "documents": []
          }

            if(refObj.message||refObj.documnets?.length>0){
              // this.chatMessages.push(refObj)
            }
        let jsonSt = JSON.stringify(PostObj);
        if (
          this.chatSocket &&
          this.chatSocket.readyState === WebSocket.OPEN &&
          this.messageVmodal &&
          !checkEmpty
        ) {
          this.chatSocket.send(jsonSt);
          this.getMessagesFromWebsocket();
        } else if (
          this.chatSocket &&
          this.chatSocket.readyState === WebSocket.OPEN &&
          this.document_ids?.length > 0
        ) {
          this.chatSocket.send(jsonSt);
          this.getMessagesFromWebsocket();
        }
        this.scrollToBottom();

        this.messageVmodal = "";
      }
    },
    getUserIformation() {
      const user = JSON.parse(localStorage.getItem("user")) || {};
      return user;
    },
    connectWebsocket() {
      // this.chatSocket = new WebSocket(
      //   "wss://" +
      //     DATABASE_URL_FOR_WEBSOCKET +
      //     "ws/chat/" +
      //     this.projectId +
      //     "/"
      // );
      const url = this.orderData
        ?DATABASE_URL_FOR_WEBSOCKET+"order/" +this.orderData.id + "/"
        : DATABASE_URL_FOR_WEBSOCKET + this.projectId + "/";
      if ((this.orderData?.id || this.projectId)&&!this.checkWebSocketConnection()) {
        this.chatSocket = new WebSocket(url);
      }
      this.chatSocket.onopen = function (event) {
      };

      this.chatSocket.onmessage = (event) => {
        // Handle incoming messages
        const message = JSON.parse(event.data);
  // Handle your message processing logic here
  // ...

  // Optionally, trigger a custom event
  window.dispatchEvent(new CustomEvent("chatSocket-onMessage-called", { detail: { storage: event.data } }));
      };

      this.chatSocket.onclose = () => {
        // Perform any necessary cleanup or handle connection closure
      };

      this.chatSocket.onerror = (error) => {
        console.error("WebSocket error:", error);
        // this.connectWebsocket();
        // Handle WebSocket errors
      };
    },
    getMessagesFromWebsocket() {
      // this.chatSocket.onmessage = function (event) {
      //   window.dispatchEvent(
      //     new CustomEvent("chatSocket-onMessage-called", {
      //       detail: {
      //         storage: event.data,
      //       },
      //     })
      //   );
      // };
    },
    scrollToBottom() {
      this.$nextTick(() => {
        let objDiv = document.getElementById("messageContainer");
        if (objDiv && !this.isLoadDataAdded) {
          objDiv.scrollTop = objDiv.scrollHeight;
          this.$nextTick(() => {
          });
        }
      });
    },
    closeWebSocket() {
      if (this.chatSocket) {
        this.chatSocket.close();
      }
    },
    async loadMoreChat(){
      if( this.chatNextUrl){
        this.isLoadDataAdded=true;
        const response = await API.DESIGN_ORDERS.LOAD_MORE_Leads(this.chatNextUrl);
        this.chatNextUrl=response.data.next;
        let chatData=response.data.results;
        chatData= this.reverseDisplay(chatData);
         this.chatMessages = [...chatData, ...this.chatMessages];
        // this.chatMessages = this.reverseDisplay(this.chatMessages);
        this.isLoadDataAdded=false;

      }
    },
    handleScroll(event) {
      const { scrollTop } = event.target;
      if (scrollTop === 0) {
        this.debounceLoadMore();
      }
    },
  },
};
</script>

<style scoped>
.documentFeedMessage {
  background-color: #f0f0f0;
  padding: 10px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}
.filename {
  white-space: nowrap;

  max-width: 8rem;

  overflow: hidden;

  text-overflow: ellipsis;
}
.file-displayname{
  white-space: nowrap;

max-width: 700px;

overflow: hidden;

text-overflow: ellipsis;
}
.file-preview {
  margin-left: 5px;
  margin-top: 10px;
}
.previewContainer {
  display: flex;
  margin-left: 13px;
  margin-right: 20px;
}
.docPreview {
  display: flex;
  width: 100px;
}
.removeFile {
  font-size: 25px;
  cursor: pointer;
  line-height: 12px;
}
.card {
  top: auto !important;
  bottom: 0;
  right: 0;
  z-index: 999;
  position: fixed;
  width: 450px;
  /* margin-right: 4.5rem; */
  /* border: 1px solid var(--step-100);
  border-radius: 12px;
  background: var(--white);
  */
}
.card .card_header {
  background-image: linear-gradient(to bottom, #e8edf2, #e9ecf2);
  padding: 16px 16px 16px 10px;
  border-radius: 12px 12px 0 0;
  height: 48px;
  cursor: pointer;
}
.heading-container {
  width: 100%;
}
.left-content {
  display: flex;
  justify-content: left;
}
.right-content {
  display: flex;
  justify-content: right;
}
#chatField {
  width: 100%;
}
.heading,
.msgInput {
  /* display: flex;
  align-items: center; */
  /* justify-content: right; */
}
.footer-content {
  display: flex;
  justify-content: stretch;
  align-items: end;
}

.chat-message-input {
  flex: 1;
  align-self: stretch;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border: 0;
  padding-left: 12px;
}

.card .card_header .heading h4 {
  font-size: 16px;
  font-weight: 600;
  color: var(--primary);
}

.noChat {
  padding: 10px 16px 16px 10px;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.noChatContent {
  font-size: 16px;
  font-weight: 100;
  color: #777;
  line-height: 1.5;
  margin-top: 8px;
}

.card_content {
  height: 350px;
  position: relative;
  overflow: hidden;
  overflow-y: scroll;
  padding: 10px 16px 16px 10px;
}

.contentShow {
  display: flex;
  flex-direction: column;
}

.userMsgCont {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin: 8px 0px;
}

.userName {
  font-size: 16px;
  font-weight: 500;
  color: #777;
  margin-bottom: 8px;
  word-break: break-word;
}

.userMsgDiv {
  padding: 8px 8px 4px 8px;
  border-radius: 4px;
  background-color: white;
  max-width: 80%;
}

.userMsg {
  font-size: 16px;
  color: #222;
  line-height: 1.5;
  word-break: break-word;
}

.userTime {
  font-size: 12px;
  color: #777777;
  float: right;
  margin-top: 4px;
}

.ourMsgCont {
  margin: 8px 0px 8px auto;
  float: right;
  max-width: 85%;
  background-color: #409eff;
  padding: 8px 8px 4px 8px;
  border-radius: 4px;
}

.ourMsg {
  font-size: 16px;
  color: #fff;
  word-break: break-word;
  line-height: 1.5;
}

.ourTime {
  font-size: 12px;
  color: #fff;
  float: right;
  margin-top: 4px;
}
.ourEventTime{
  font-size: 12px;
  color:  #222;
  float: right;
  margin-top: 4px;
}
.hover_information {
  display: inline-block;
  position: relative;
  display: none;
}

.fas {
  font-size: 13px;
  color: #777777;
  cursor: pointer;
}

.tooltip {
  border-radius: 8px;
  box-shadow: 0 6px 18px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px var(--light-m);
  background-color: var(--white);
  padding: 12px;
  position: absolute;
  width: 300px;
  left: -15px;
  bottom: 100%;
  visibility: hidden;
  opacity: 0;
  transition: all ease-in-out 0.35s;
  z-index: 100;
}

.tooltip p {
  margin: 0;
  line-height: 20px;
  font-size: 14px;
  color: #222;
  word-break: break-word;
}
i:hover ~ .tooltip {
  opacity: 1;
  visibility: visible;
}

.expand {
  cursor: pointer;
  display: none;
}

.msgContainer {
  padding: 16px 16px 16px 8px;
  display: grid;
  align-items: center;
  grid-template-columns: auto;
}

.emoji,
.filesIcon {
  width: 30px;
  cursor: pointer;
  display: none;
}

.sendBtn {
  height: 40px;
  padding: 10px;
  border-radius: 0px 4px 4px 0px;
  font-size: 16px;
}

.input {
  background-color: #e8edf2;
  border: none;
  color: #222;
  font-size: 18px;
  /* height: 40px; */
  min-height: 40px;
  border-radius: 0px 0px 0px 4px;
  width: calc(100% - 75px);
  padding: 5px;
}

@media (max-width: 1280px) {
  .card {
    top: 81px;
  }
}
.eventMessages {
  background-color: #e8edf2;
  text-align: center;
  border-radius: 9px;
  font-style: oblique;
  color: darkgray;
  padding: 8px;
}
.eventContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}
.menuProp {
  z-index: 10;
  border: 1px solid black;
}
.input-col {
  flex-grow: 1;
}

.button-col {
  align-self: flex-end;
}
.upload-demo {  
  margin-right: 0.5rem;
}
.download-container {
  margin-left: 1rem;
  cursor: pointer;
}
.chatBoxContainer{
  background:#f1f1f1
}
#chat>>>.el-textarea__inner{
min-height: 40px !important;
}
</style>
