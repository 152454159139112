import axios from "axios";

export default {
    GET_PARTNERS() {
        return axios.get('api/partner/')
    },

    CREATE_PARTNERS(data) {
        return axios.post('api/partner/', data)
    },

    UPDATE_PARTNERS(id, data) {
        return axios.patch(`api/partner/${id}/`, data)
    },

    DELETE_PARTNERS(id) {
        return axios.delete(`api/partner/${id}/`)
    },

    GET_MORE_PARTNERS_DATA(url) {
        return axios.get(url)
    }
}