<script>
import { defineComponent } from '@vue/composition-api'
import { useAuthStore } from '../../stores/auth'
import { mapActions, mapState } from 'pinia';
import { useIntegrationStore } from '../../stores/integration';

export default {
    name: 'LoginForSenseHawk',
    data() {
        return {
            token: null
        }
    },
    created() {
        this.token = this.$route.query.token;
        this.handleLogin();
    },
    computed: {
        ...mapState(useIntegrationStore, [
            'IS_INTEGRATION'
        ]),
    },
    methods: {
        ...mapActions(useAuthStore, [
            'LOGIN',
        ]),
        ...mapActions(useIntegrationStore, {
            checkProjectExistAndSetId: 'CHECK_PROJECT_EXIST_AND_SET_ID',
            removeProjectDataFromSessionStorage: 'REMOVE_PROJECTDATA_FROM_SESSION_STORAGE',
            routeToProjectSummary: 'ROUTE_TO_PROJECT_SUMMARY',
        }),
        async handleLogin() {
            let response = await this.LOGIN(this.token);
            if (this.IS_INTEGRATION) {
                const doesProjectExist = await this.checkProjectExistAndSetId();
                if (doesProjectExist) {
                    this.routeToProjectSummary();
                    this.removeProjectDataFromSessionStorage();
                }
                else {
                    this.$router.push({ name: 'integration', props: { isRedirectedFromLoginPage: true } });
                }
            }
            else {
                this.$router.push({ name: 'home' });
            }
        }
}
}
</script>