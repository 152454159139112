<template>
    <div :class="['parentCont', isMinimized ? 'parentSectionop' : 'parentSection']">
        <div class="firstSection" v-if="!isMinimized">
            <b-icon icon="dash-lg" @click="isMinimized = !isMinimized" class="minimizeIcon"></b-icon>
            <div class="progressBarContainer">
                <el-progress :percentage="bulkLeadImportState.progress" :stroke-width="15"></el-progress>
            </div>
            <p class="font20Normal">Validation ongoing.<br />
                Please check after sometime.</p>
            <el-button type="primary" @click="showLeadUploadedPopupFunc()" class="cancelBtn" >Cancel</el-button>
        </div>
        <div class="minimizeSection" v-else>
            <div class="flexHeader">
                <p class="font16Normal">Importing Leads</p>
                <b-icon icon="chevron-up" @click="isMinimized = !isMinimized" class="arrowIcon"></b-icon>
            </div>
            <div class="progressBarContainer">
                <el-progress :percentage="bulkLeadImportState.progress" :stroke-width="15"></el-progress>
            </div>
            <p class="font16Normal"><b-icon icon="file-earmark-text"></b-icon>{{ bulkLeadImportState.fileName }}</p>
        </div>
    </div>
</template>

<script>

import { useMiscStore } from "../../../stores/misc";
import { mapActions, mapState } from "pinia";

export default {
    name: "minimizablePopup",

    components: {

    },
    props: {

    },

    data() {
        return {
            isMinimized: false,
            percentage: 23,
        }
    },

    mounted() {
        setTimeout(() => {
            if (this.bulkLeadImportState.progress == 0 && !this.bulkLeadImportState.isBulkLeadUploadedPopupVisible) {
                this.bulkLeadImportState.eventSource.close();
                this.bulkLeadImportState.isSSEProcessing = false;
                this.bulkLeadImportState.showMinimizablePopup = false;
                this.bulkLeadImportState.isBulkLeadUploadedPopupVisible = true;
                this.bulkLeadImportState.isLeadsUploadSuccessfully = false;
                this.bulkLeadImportState.isLeadUploadedError = true;
                this.bulkLeadImportState.isCancelBtnClicked = false;
            }
        }, 20000); 
    },

    computed: {
        ...mapState(useMiscStore, {
            bulkLeadImportState: (state) => state.bulkLeadImportState,
        }),
    },

    methods: {
        showLeadUploadedPopupFunc() {
            this.bulkLeadImportState.isBulkLeadUploadedPopupVisible = true;
            this.bulkLeadImportState.isCancelBtnClicked = true;
        }
    }

}

</script>


<style scoped>
.parentSection {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.25);
    display: grid;
    place-items: center;
    z-index: 1000;
}

.firstSection {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 24px;
    width: 100%;
    max-width: 550px;
    height: 345px;
    padding: 24px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}

.minimizeIcon {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
}

.arrowIcon {
    font-size: 20px;
    cursor: pointer;
}

.font20Normal {
    font-size: 20px;
    color: #222;
    text-align: center;
}

.progressBarContainer {
    padding: 5px 8px;
    border-radius: 4px;
    background: var(--Background, #E8EDF2);
    height: 30px;
    width: 100%;
}

.parentCont>>>.el-progress-bar__outer {
    background-color: #fff !important;
    border-radius: 4px;
}

.parentCont>>>.el-progress-bar__inner {
    border-radius: 4px;
}

.parentCont>>>.el-progress__text {
    font-size: 16px !important;
    color: #000 !important;
    margin: 0px 0px 0px 13px;
}

.minimizeSection {
    position: fixed;
    bottom: 0px;
    right: 30px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    max-width: 359px;
    height: 158px;
    padding: 16px;
    background-color: #fff;
    border-radius: 8px 8px 0px 0px;
    border: 1px solid var(--Primary, #409EFF);
    background: var(--White, #FFF);
}

.flexHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cancelBtn {
    font-size: 16px;
    font-weight: bold;
    max-width: 100px;
    margin: 0px auto;
}

@media (max-width: 700px) {
    .firstSection {
        max-width: 90vw;
        padding: 16px;
        height: 320px;
    }

    .minimizeSection {
        max-width: 92vw;
        right: 16px;
        height: 140px;
        gap: 16px;
    }
}
</style>