
<div class="filter-container">
  <p class="filter-title">Filter</p>
  <div>
    <div class="select-container">
      <div
        v-for="(filterOuter, inx) in filteredData"
        :key="inx"
      >
        <div v-if="inx > 0" class="operator-container">
          <p style="width: 40px">{{ handleOperator(inx) }}</p>
          <p>
            <span class="icon-container">
              <b-icon
                @click="toggleOperator('and', inx)"
                :style="{
                  color: upIconColor[inx],
                  marginRight: '0.5rem',
                }"
                class="custom-arrow"
                :icon="'caret-up-fill'"
              ></b-icon>
              <b-icon
                @click="toggleOperator('or', inx)"
                :style="{
                  color: downIconColor[inx],
                  marginRight: '0.5rem',
                }"
                class="custom-arrow"
                :icon="'caret-down-fill'"
              ></b-icon>
            </span>
          </p>
        </div>
        <div class="select-body">
          <div v-for="(filterInner, indx) in filterOuter" :key="indx">
            <div class="spacing-container">
              <SearchSelectView
                v-if="filterInner.type === 'select'&&filterInner.ValueMetricType === 'FK'"
                :items="filterInner"
                :selectedValue="filterInner.selectedValue"
                @item-selected="selectedOptionValue"
                :indexOfOptions="inx"
                :seletedSource="filterInner.isDisabled"
                :placeholderText="filterInner.toolTip"
                :options="items.options"
              />

              <SelectComponentView
                v-if="filterInner.type === 'select'&&filterInner.ValueMetricType !== 'FK'"
                :items="filterInner"
                :selectedValue="filterInner.selectedValue"
                @item-selected="selectedOptionValue"
                :indexOfOptions="inx"
                :seletedSource="filterInner.isDisabled"
                :placeholderText="filterInner.toolTip"
              />
              <InputView
                v-if="filterInner.type === 'input'"
                :itemValue="filterInner"
                :selectedValue="filterInner?.selectedValue || ''"
                :indexOfOptions="inx"
                @item-selected="selectedOptionValue"
                :seletedSource="filterInner.isDisabled"
              />
              <DatePickerView
                v-if="filterInner.type === 'date'"
                :itemValue="filterInner"
                :selectedValue="filterInner?.selectedValue || ''"
                :indexOfOptions="inx"
                @item-selected="selectedOptionValue"
                :seletedSource="filterInner.isDisabled"
              />
              <NumberInput
                v-if="filterInner.type === 'number'"
                :itemValue="filterInner"
                :selectedValue="filterInner?.selectedValue || ''"
                :indexOfOptions="inx"
                @item-selected="selectedOptionValue"
                :seletedSource="filterInner.isDisabled"
              />

              <MenuView
                v-if="filterInner.type === 'menu'"
                :itemValue="filterInner"
                :selectedValue="filterInner?.selectedValue || ''"
                :indexOfOptions="inx"
                @item-selected=" selectedOptionValue"
                :filterColumnOption="filterColumnOption"
                :seletedSource="filterInner.isDisabled"
                :placeholderText="filterInner.toolTip"
              />
            </div>
          </div>
          <div :span="1" @click="removeFilter(inx)">
            <p class="cross" v-if="inx===filterData.length-1"><b-icon :icon="'x'"></b-icon></p>
          </div>
        </div>
      </div>
      <div v-if="filterData.length > 1">
        <p>{{ selectedExpression }}</p>
      </div>

      <div style=" padding-top: 1rem;">
        <p
          style="
            color: #1c3366;
            font-weight: 600;
            font-size: 1rem;
           
            display: flex;
            cursor: pointer;
            width:125px;
          "
          @click="handleaddFilter"
        >
          <span
            ><b-icon
              :style="{
                color: '#1c3366',
                marginRight: '0.5rem',
              }"
              class="custom-icon-size"
              :icon="'plus-circle-fill'"
            ></b-icon></span
          >{{ `Add Filter` }}
        </p>
      </div>
    </div>
  </div>
</div>
