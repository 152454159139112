
<div v-loading="isLoading">
  <div
    class="tableContainer tableContainer-dash"
  >
    <div
      :class=" checkedColumnsList && checkedColumnsList.length > screenSizeCheck
            ? ' tableHeader '
            : ' tableHeader tableHeader-dash'
      "
    >
      <div
        :class="checkedColumnsList &&
              checkedColumnsList.length > screenSizeCheck
              ? 'headerTitle slnoDet slnoDet-dash '
              : 'headerTitle slnoDet slnoDet-dash fullwidthContainer'
        "
        v-if="isDrilldown"
      >
        S. No.
      </div>

      <div
        :class=" checkedColumnsList &&
              checkedColumnsList.length > screenSizeCheck
              ? ' leadDet-dash headerTitle leadDet'
              : 'fullwidthContainer leadDet-dash headerTitle leadDet'
        "
      >
        <div
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
          "
        >
          <div>Lead Details</div>
          <div
            v-if="isDrilldown"
            style="
              font-weight: 500;
              background: #e8edf2;
              color: #777;
              border-radius: 4px;
              padding: 5px;
            "
          >
            {{ leadsCount }}
          </div>
        </div>
      </div>
      <div
        v-if="checkedColumnsList.includes('Pipeline')"
        :class="checkedColumnsList &&
              checkedColumnsList.length > screenSizeCheck
              ? ' stage-dash headerTitle stage'
              : 'fullwidthContainer stage-dash headerTitle stage'
        "
      >
        Pipeline
      </div>
      <div
        v-if="checkedColumnsList.includes('Stage')"
        :class=" checkedColumnsList &&
              checkedColumnsList.length > screenSizeCheck
              ? ' stage-dash headerTitle stage'
              : 'fullwidthContainer stage-dash headerTitle stage'
        "
      >
        Stage
      </div>
      <div
        v-if="checkedColumnsList.includes('Lead Source')"
        :class=" checkedColumnsList &&
              checkedColumnsList.length > screenSizeCheck
              ? ' stage-dash headerTitle stage'
              : 'fullwidthContainer stage-dash headerTitle stage'
        "
      >
        Lead Source
      </div>
      <div
      v-if="checkedColumnsList.includes('Region') && isRelianceOrg"
        :class="checkedColumnsList &&
              checkedColumnsList.length > screenSizeCheck
              ? ' stage-dash headerTitle stage'
              : 'fullwidthContainer stage-dash headerTitle stage'
        "
      >
        Region
      </div>
      <div
        v-if="checkedColumnsList.includes('Probability')"
        :class="checkedColumnsList &&
              checkedColumnsList.length > screenSizeCheck
              ? ' stage-dash headerTitle stage'
              : 'fullwidthContainer stage-dash headerTitle stage'
        "
      >
      Probability
      </div>
      <div
        v-if="checkedColumnsList.includes('Owner')"
        :class="checkedColumnsList &&
              checkedColumnsList.length > screenSizeCheck
              ? ' owner-dash headerTitle owner'
              : 'fullwidthContainer owner-dash headerTitle owner'
        "
      >
        Owner
      </div>
      <div
        v-if="checkedColumnsList.includes('Type')"
        :class="checkedColumnsList &&
              checkedColumnsList.length > screenSizeCheck
              ? ' type-dash headerTitle type'
              : 'fullwidthContainer type-dash headerTitle type'
        "
      >
        Type
      </div>
      <div
        v-if="checkedColumnsList.includes('Created On')"
        :class="checkedColumnsList &&
              checkedColumnsList.length > screenSizeCheck
              ? ' createdOn-dash headerTitle createdOn'
              : 'fullwidthContainer createdOn-dash headerTitle createdOn'
        "
      >
        Created On
      </div>
      <div
        v-if="checkedColumnsList.includes('Target Close Date')"
        :class="checkedColumnsList &&
              checkedColumnsList.length > screenSizeCheck
              ? ' targetCloseDate-dash headerTitle targetCloseDate'
              : 'fullwidthContainer targetCloseDate-dash headerTitle targetCloseDate'
        "
      >
        Target Close Date
      </div>
      <div
        v-if="checkedColumnsList.includes('Deal Value')"
        :class=" checkedColumnsList &&
              checkedColumnsList.length > screenSizeCheck
              ? ' dealVal-dash headerTitle dealVal'
              : 'fullwidthContainer dealVal-dash headerTitle dealVal'
        "
      >
        Deal Value
      </div>
      <div
        v-if="checkedColumnsList.includes('Latest Note')"
        :class="checkedColumnsList &&
              checkedColumnsList.length > screenSizeCheck
              ? ' latestNote-dash headerTitle latestNote'
              : 'fullwidthContainer latestNote-dash headerTitle latestNote'
        "
      >
        Latest Note
      </div>
      <div
        v-if="checkedColumnsList.includes('System Size')"
        :class="checkedColumnsList &&
              checkedColumnsList.length > screenSizeCheck
              ? ' sysSize-dash headerTitle sysSize'
              : 'fullwidthContainer sysSize-dash headerTitle sysSize'
        "
      >
        System Size
      </div>
      <div
        v-if="checkedColumnsList.includes('Last Contacted')"
        :class=" checkedColumnsList &&
              checkedColumnsList.length > screenSizeCheck
              ? ' lastCont-dash headerTitle lastCont'
              : 'fullwidthContainer lastCont-dash headerTitle lastCont'
        "
      >
        Last Contacted
      </div>
      <div
        v-if="checkedColumnsList.includes('Country')"
        :class="checkedColumnsList &&
              checkedColumnsList.length > screenSizeCheck
              ? ' lastCont-dash headerTitle lastCont'
              : 'fullwidthContainer lastCont-dash headerTitle lastCont'
        "
      >
        Country
      </div>
    </div>
    <div
      :class="checkedColumnsList && checkedColumnsList.length > screenSizeCheck
            ? ' parentBodyContainer'
            : 'fullWidthBody parentBodyContainer'
      "
    >
      <div
        :class="checkedColumnsList &&
              checkedColumnsList.length > screenSizeCheck
              ? ' bodyContainer'
              : 'fullwidthContainer bodyContainer'
        "
        v-for="(lead, index) in computedAllLeadsList"
        :key="index"
        @click="
          isDrilldown ? redirectToSummary(lead) : openLeadDrawer(lead, index, $event)
        "
      >
        <div
          class="fullwidthContainer slnoDet-dash bodyContent slnobody"
          v-if="isDrilldown"
        >
          {{ index + 1 }}
          <!-- <p class="pipelineLabel">{{ lead.lead_details.stage }}</p> -->
        </div>

        <div
          :class="checkedColumnsList &&
                checkedColumnsList.length > screenSizeCheck
                ? ' leadDet-dash bodyContent leadDetBody'
                : 'fullwidthContainer leadDet-dash bodyContent leadDetBody'
          "
        >
          <div class="leadFlex">
            <div class="leadDetcolumn">
              <p class="name">
                <el-tooltip placement="top" :disabled="!showTooltip(lead?.lead_details?.name,33)">
                  <template v-slot:content>
                  <div>
                    <span
                      ><p class="lead-name">
                        {{ lead?.lead_details?.name }}
                      </p></span
                    >
                  </div>
                  </template>
                  <router-link
                    style="display:flex;align-items: center;max-width: 260px;"
                    :to="{ name: 'leadSummary', params: { leadId: lead.lead_details.id }}"
                    event
                    @click="
                      isDrilldown ? redirectToSummary(lead) : openLeadDrawer(lead, index, $event)
                    "
                  >
                    <span :class="['ownerLine']">
                      {{ lead?.lead_details?.name }}
                    </span>
                    <span v-if="lead?.is_shared" style="display: flex;align-items: center;" >
                      <img style="padding-left: 0.5rem;height:auto;" src="../../../assets/img/sharedIcon.svg" alt="">
                    </span>
                    <!-- Lead Belongs to VIP Indicator -->
                    <span v-if="(!lead?.is_shared&&lead?.lead_details?.workflow_type==='d2c_vip_external')" style="display: flex;align-items: center;" >
                      <img style="padding-left: 0.5rem;height:auto;" src="../../../assets/img/bordered-building.svg" alt="">
                    </span>
                  </router-link>
                </el-tooltip>
              </p>
              <p
                :class="{
                  'disabled-content': !(
                    lead?.lead_details?.owner_id == ownerId
                  ),
                }"
                v-if="lead?.reminder_details"
                class="remainder"
                @click="onSetReminder(lead, index)"
              >
                {{ reminderTimeText(lead) }}
              </p>
              <p
                v-else
                class="remainder"
                @click="onSetReminder(lead, index)"
                :class="{
                  'disabled-content': !(
                    lead?.lead_details?.owner_id == ownerId
                  ),
                }"
              >
                Set Reminder
              </p>
            </div>
            <div class="leadIcons">
              <div
                class="tooltipCommon"
                id="addNoteBtn"
                @click="
                  isDrilldown
                    ? redirectToSummary(lead)
                    : addNoteClick($event, lead, index)
                "
              >
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="Add Notes"
                  placement="top-start"
                  popper-class="tooltipClass"
                >
                  <img src="./assets/Vector (1).svg" class="vectorIcon" />
                </el-tooltip>
              </div>
              <div class="tooltipCommon">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="View Tasks"
                  placement="top-start"
                >
                  <img
                    src="./assets/event_list.svg"
                    @click="onClickTasks($event, lead, index)"
                    class="eventListIcon"
                  />
                </el-tooltip>
              </div>
              <div class="tooltipCommon site-survey-button">
                <el-tooltip class="item" effect="dark" placement="top-start">
                  <template v-slot:content>
                  <div>
                    <i
                      class="el-icon-loading"
                      v-if="isGeneratingSiteSurvey"
                    ></i>
                    <div v-else>{{ siteSurveyOptionToolTip(lead) }}</div>
                  </div>
                  </template>
                  <span>
                    <img
                      @click="onClickSiteSurvey(lead)"
                      src="./assets/assignment.svg"
                      class="assignmentIcon"
                      :class="{ disabled: !lead.address }"
                    />
                  </span>
                </el-tooltip>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="checkedColumnsList &&
                checkedColumnsList.length > screenSizeCheck
                ? ' stage-dash bodyContent stageBody'
                : 'fullwidthContainer stage-dash bodyContent stageBody'
          "
          v-if="checkedColumnsList.includes('Pipeline')"
        >
          <p class="pipelineLabel">
            {{ handlePipeline(lead.lead_details.pipeline) }}
          </p>
        </div>
        <div
          :class="checkedColumnsList &&
                checkedColumnsList.length > screenSizeCheck
                ? ' stage-dash bodyContent stageBody'
                : 'fullwidthContainer stage-dash bodyContent stageBody'
          "
          v-if="checkedColumnsList.includes('Stage')"
        >
          <el-select
            v-model="lead.lead_details.stage_object"
            @change="changeLeadStage(lead)"
            @focus='changeStage(lead.lead_details.stage_object)'
            placeholder="Stage"
          >
            <el-option
              v-for="stage in handleStage(lead.lead_details.pipeline)"
              :key="stage.id"
              :label="stage.name"
              :value="stage.id"
            >
              {{ stage.name }}
              <b-icon style="margin-left:10px;" :color="iconColor(stage)" :icon="showIcon(stage)"></b-icon>
            </el-option>
          </el-select>
          <!-- <p class="pipelineLabel">{{ lead.lead_details.stage }}</p> -->
        </div>
        <div
          :class="checkedColumnsList &&
                checkedColumnsList.length > screenSizeCheck
                ? ' stage-dash bodyContent sourceBody'
                : 'fullwidthContainer stage-dash bodyContent sourceBody'
          "
          v-if="checkedColumnsList.includes('Lead Source')"
        >
          <div style="display: flex; align-items: center; gap: 8px">
            <p class="typeLine">{{ handleLeadSource(lead?.lead_details?.lead_source_object,lead) }}</p>
          </div>
        </div>
        <div
          :class="checkedColumnsList &&
                checkedColumnsList.length > screenSizeCheck
                ? ' stage-dash bodyContent sourceBody'
                : 'fullwidthContainer stage-dash bodyContent sourceBody'
          "
           v-if="checkedColumnsList.includes('Region') && isRelianceOrg"
        >
          <div style="display: flex; align-items: center; gap: 8px">
            <p class="typeLine">{{handleRegion( lead?.lead_details?.region)}}</p>
          </div>
        </div>
        <div
          :class=" checkedColumnsList &&
                checkedColumnsList.length > screenSizeCheck
                ? ' stage-dash bodyContent sourceBody'
                : 'fullwidthContainer stage-dash bodyContent sourceBody'
          "
          v-if="checkedColumnsList.includes('Probability')"
        >
          <div style="display: flex; align-items: center; gap: 8px">
            <p class="typeLine">{{ lead.lead_details.probability?lead.lead_details.probability:0}} %</p>
          </div>
        </div>
        <div
          :class=" checkedColumnsList &&
                checkedColumnsList.length > screenSizeCheck
                ? ' owner-dash bodyContent ownerBody'
                : 'fullwidthContainer owner-dash bodyContent ownerBody'
          "
          v-if="checkedColumnsList.includes('Owner')"
        >
          <p
            class="ownerN"
            :style="{
              backgroundColor: generateColorFromName(
                lead?.lead_details?.owner
              )
                ? generateColorFromName(lead?.lead_details?.owner)
                : '#1c3366',
            }"
            v-if="(lead?.lead_details?.owner ? lead?.lead_details?.owner : '')&&checkdifferntOrg(lead)"
          >
            {{ returnInitials(lead?.lead_details?.owner) }}
          </p>
          <p class="orgOwnerIcon"  v-else><img src="../../../assets/img/buildings.svg" alt=""></p>
          <!-- <span class="ownerLine">{{
                    lead?.lead_details?.owner? lead?.lead_details?.owner : "—" }}
                  </span> -->
          <el-tooltip placement="bottom" :hide-after="0" :disabled="!showTooltip(lead?.lead_details?.owner,14)">
            <template v-slot:content>
            <div>
              <span
                >{{
                  lead?.lead_details?.owner ? lead?.lead_details?.owner : "—"
                }}
              </span>
            </div>
            </template>
            <span class="ownerLine"
              >{{
                handleOwner(lead)
              }}
            </span>
          </el-tooltip>
        </div>
        <div
          :class=" checkedColumnsList &&
                checkedColumnsList.length > screenSizeCheck
                ? ' type-dash bodyContent typeBody'
                : 'fullwidthContainer type-dash bodyContent typeBody'
          "
          v-if="checkedColumnsList.includes('Type')"
        >
          <div style="display: flex; align-items: center; gap: 8px">
            <img
              :src="
                lead?.project_type == 'residential'
                  ? residentialIcon
                  : commercialIcon
              "
            />
            <p class="typeLine">{{ lead?.project_type }}</p>
          </div>
        </div>
        <div
          :class="checkedColumnsList &&
                checkedColumnsList.length > screenSizeCheck
                ? ' createdOn-dash bodyContent createdOnBody'
                : 'fullwidthContainer createdOn-dash bodyContent createdOnBody'
          "
          v-if="checkedColumnsList.includes('Created On')"
        >
          <p class="createdOnLine">
            {{
              handleFormatDate(
                lead?.lead_details?.created_at,
                typeConstants.shortDate,
                isUSFlagEnabled
              )
            }}
          </p>
        </div>

        <div
          :class="checkedColumnsList &&
                checkedColumnsList.length > screenSizeCheck
                ? ' targetCloseDate-dash bodyContent targetCloseBody'
                : 'fullwidthContainer targetCloseDate-dash bodyContent targetCloseBody'
          "
          v-if="checkedColumnsList.includes('Target Close Date')"
        >
          <p class="createdOnLine">
            {{
              lead?.lead_details?.target_closure_date
                ? handleFormatDate(
                    lead?.lead_details?.target_closure_date,
                    typeConstants.shortDate,
                    isUSFlagEnabled
                  )
                : "—"
            }}
          </p>
        </div>
        <div
          :class="checkedColumnsList &&
                checkedColumnsList.length > screenSizeCheck
                ? ' dealVal-dash bodyContent dealValBody'
                : 'fullwidthContainer dealVal-dash bodyContent dealValBody'
          "
          v-if="checkedColumnsList.includes('Deal Value')"
        >
          <p class="dealValLine">
            {{ currencySymbol(lead)
            }}{{
              formatNumberWithCommas(
                lead?.lead_details?.deal_value,
                isIndianLocationOrAccount(lead)
              )
            }}
          </p>
        </div>
        <div
          :class="checkedColumnsList &&
                checkedColumnsList.length > screenSizeCheck
                ? ' latestNote-dash bodyContent latestNoteBody'
                : 'fullwidthContainer latestNote-dash bodyContent latestNoteBody'
          "
          v-if="checkedColumnsList.includes('Latest Note')"
        >
          <el-tooltip
            v-if="lead?.lead_details?.latest_notes"
            class="item"
            effect="dark"
            :content="formatNotes(lead?.lead_details?.latest_notes)"
            placement="top"
            popper-class="lead-management-table-notes"
          >
            <p class="latestNoteLine">
              {{ lead?.lead_details?.latest_notes }}
            </p>
          </el-tooltip>
          <p v-else>—</p>
        </div>
        <div
          :class="checkedColumnsList &&
                checkedColumnsList.length > screenSizeCheck
                ? ' sysSize-dash bodyContent sysSizeBody'
                : 'fullwidthContainer sysSize-dash bodyContent sysSizeBody'
          "
          v-if="checkedColumnsList.includes('System Size')"
        >
          <p class="sysSizeLine">
            {{
              formatNumberWithCommas(
                lead?.lead_details?.estimated_system_size || 0,
                isIndianLocationOrAccount(lead)
              )
            }}
            kWp
          </p>
        </div>
        <div
          :class=" checkedColumnsList &&
                checkedColumnsList.length > screenSizeCheck
                ? ' lastCont-dash bodyContent lastContBody'
                : 'fullwidthContainer lastCont-dash bodyContent lastContBody'
          "
          v-if="checkedColumnsList.includes('Last Contacted')"
        >
          <p class="lastContLine">
            {{ lead?.lead_details?.last_contacted || "—" }}
          </p>
        </div>
        <div
          :class="checkedColumnsList &&
                checkedColumnsList.length > screenSizeCheck
                ? ' lastCont-dash bodyContent lastContBody'
                : 'fullwidthContainer lastCont-dash bodyContent lastContBody'
          "
          v-if="checkedColumnsList.includes('Country')"
        >
          <p class="lastContLine">{{ lead?.country?.name || "—" }}</p>
        </div>
      </div>
      <div
        v-infinite-scroll="loadMoreLeads"
        infinite-scroll-disabled="busy"
        infinite-scroll-distance="10"
        style="text-align: center"
      >
        <i v-if="busy" class="el-icon-loading infiniteScrollLoader" />
      </div>
    </div>
  </div>
  <EmptySiteSurveyLinkPopUp
    :emptySiteSurvey.sync="emptySiteSurvey"
    :closeEmptySiteSurveyPopUp.sync="closeEmptySiteSurveyPopUp"
    :isModal="isDrilldown ? false : true"
  />
  <LeadsDrawer
    v-if="drawer"
    :drawer.sync="drawer"
    :focusNote="focusOnAddNote"
    :lead="activeLead"
    :currentRowNumber="currentRowNumber"
    @close="[(drawer = false), (activeTab = 'first')]"
    @update-owner="updateOwnerInCurrentRow"
    @update-stage="updateStageInCurrentRow"
    @update-lead-source="updateLeadSourceInCurrentRow"
    @update-region="updateRegionInCurrentRow"
    @update-probability="updateProbabilityInCurrentRow"
    :activeTab="activeTab"
    @updated-tags="updateTagsInCurrentRow"
    @deleted-lead="updateAllLeadsData"
  />
  <setReminder
    v-if="!isDrilldown"
    :key="setKey"
    :showCreateDrawer="showCreateDrawer"
    @close="showCreateDrawer = false"
    :lead="activeLead"
    @save="onSave"
    :drawerSize="400"
  />
  <DealValue v-if="dealCheck"
  :isDeleteProjectDocumentPopupOpen=true
  :title="`Deal Value Check`"
  :info="`Deal value for this lead is ${currencySymbolDialog}0.`"
  :info2="` Do you want to continue without updating it?`"
  @cancelDelete="cancelDelete"
  @confirmDelete="confirmDelete"/>
</div>
