
<div>
    <div class="parentContainer">
        <el-dialog :visible="isConfirmationForDocusignPopupVisible" :close-on-click-modal="false" title="Send documents"
            @close="onDialogClose" :modal-append-to-body="isModal">
            <div class="container">
                <div class="flexContainer">
                    <div class="flexContainerTwo">
                        <p class="font16Normal">Document for contract management</p>
                        <p class="font16Normal tag">DocuSign enabled</p>
                    </div>
                    <el-select v-model="selectedOption" placeholder="Select">
                        <el-option v-for="item in options" :key="item" :label="item"
                            :value="item">
                        </el-option>
                    </el-select>
                </div>
                <div class="btnContainer">
                    <el-checkbox v-model="checked">I confirm that I have verified all project related information in
                        the contract. Arka360 is not liable for any discrepancy of data in the
                        contract.</el-checkbox>
                    <el-button type="primary" class="proceedBtn" @click="proceedBtnFunc()" :disabled="!checked">Proceed</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</div>
