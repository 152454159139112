<template>
    <div>
  <el-dialog
  title="Warning"
  :visible.sync="openDrawer"
  append-to-body 
  :show-close="false"
  :show-header="false"
  width="40%"
  center
  class="dialog"
  >
  <div class="body">
    <img 
        src="./assets/alert.svg"
        alt="close"
     >
    <span>
    {{ message }}
</span>
  </div>

  <template v-slot:footer>
    <span
    class="dialog-footer"
    >
    <el-button @click="onCancelClick">Cancel</el-button>
    <el-button
     type="primary"
      @click="onDeleteConfirm"
      >
      Confirm
    </el-button>
    </span>
    </template>
</el-dialog>
    </div>
</template>

<script>
    export default {
        emits: [ "confirm", "onClickClose"],
        props:{
            openDrawer: {
                type: Boolean,
                default: false
            },
            message:{
                type: String,
                default: ""
            }
        },

        methods:{
            onDeleteConfirm(){
                this.$emit("confirm")
            },

            onCancelClick(){
                this.$emit("onClickClose")
            }
        }
    }
</script>

<style scoped>

.dialog >>> .el-dialog__header{
    display: none;
}
.dialog >>> .el-dialog{
    padding:10px;
}
.dialog >>>.el-dialog__body{
    display: flex;
    align-items: center;
    justify-content: center;
}

.body{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 10px;
}
</style>
