
<el-dialog
  class="crm-notification-dialog-wrapper"
  :visible.sync="$props.isVisible"
  :modal-append-to-body="isModal"
  :show-close="false"
  @close="handleClose"
  width="30%"
  custom-class="crm-notification-dialog"
>
  <div style="height: 100%">
    <div class="header-div">
      <div class="header-notification">Notifications</div>
      <router-link class="header-more" :to="{ name: 'notifications' }"
        ><span @click="handleClose">See all</span></router-link
      >
      <div class="header-right">
        <span style="margin-right: 0.5rem">Unread Only</span>
        <el-switch
          :width="32"
          v-model="unreadOnly"
          @change="handleUnreadToggle"
        ></el-switch>
      </div>
    </div>
    <div class="tabs-div">
      <div
        @click="handleTabs('direct')"
        :class="[activeTab === 'direct' ? 'tabs-active' : 'tabs-inactive']"
      >
        Direct
      </div>
      <div
        @click="handleTabs('collab')"
        :class="[activeTab === 'collab' ? 'tabs-active' : 'tabs-inactive']"
      >
        Collaborators
      </div>
      <div class="tabs-end" @click="handleReadAll">Mark all as read</div>
    </div>
    <div
      class="notification-cards"
      v-if="notificationFetchError"
      style="color: red"
    >
      There was an error while fetching notifications.
    </div>
    <div
      v-else
      class="notification-cards"
      v-loading="!computeNotificationList"
      v-infinite-scroll="handleScroll"
      infinite-scroll-distance="10"
    >
      <NotificationCards
        :notificationsList="computeNotificationList"
        @handleClick="handleClick"
        @showDrawer="showDrawer"
        :maxWidth="'450px'"
        :assigneeWidth="'200px'"
        :isPopup="true"
        @notificationRead="handleNotificationRead"
      />
      <div class="empty-notification">
        {{ emptyNotificationsText }}
      </div>
      <div class="footer" v-if="computeShowFooter">
        <div style="position: relative">
          <span class="footer-img">
            <img
              src="../../assets/img/notifBellDeny.svg"
              alt="bell-deny"
              height="20.35px"
              width="19.76px"
            />
          </span>
          <span class="footer-left-text"
            >That's all your notifications from last week</span
          >
        </div>
        <router-link class="footer-right" :to="{ name: 'notifications' }"
          ><span @click="handleClose"
            >See older notifications</span
          ></router-link
        >
      </div>
      <div
        v-else
        v-show="isNotificationLoading || loaderErrorMessage.length > 0"
        :class="['loader-and-error']"
        v-loading="isNotificationLoading"
      >
        <span
          :class="[loaderErrorMessage.length > 0 ? 'loading-error-text' : '']"
        >
          {{ loaderErrorMessage }}
        </span>
      </div>
    </div>
  </div>
</el-dialog>
