<template>
  <div class="btnsContainerFlex">
    <div class="flexOne">
      <ReusableButton
        v-if="!isGenerationAlreadyCalculated && !essOnlyDesign"
        :loading="isLoading"
        :button="generationButtonData()"
        @click="calculateGeneration()"
      />

      <ReusableButton
        v-if="isGenerationAlreadyCalculated && !essOnlyDesign"
        :button="webProposalButtonData()"
        @click="navigateToWebProposal()"
      />

      <ReusableButton
        v-if="isGenerationAlreadyCalculated"
        :button="documentProposalButtonData()"
        @click="onClickDocumentProposal"
      />

      <ReusableButton
        v-if="essOnlyDesign"
        :button="essOnlyProposalButtonData()"
        @click="navigateToDocumentProposal()"
      />

      <ReusableButton
        v-if="isGenerationAlreadyCalculated && !essOnlyDesign"
        :button="designOverviewButtonData()"
        @click="navigateToDesignOverview()"
      />
    </div>
    <div v-if="!essOnlyDesign" class="flexTwo">
      <ReusableButton
        v-if="isSalesModeAvailable && !isMobileView"
        :loading="isStudioLoading"
        :button="generationSaleStudioButtonData()"
        @click="saleStudioButton()"
      />
      <ReusableButton
        v-if="isSalesModeAvailable"
        :loading="isReportLoading"
        :button="generationSaleReportButtonData()"
        @click="salesModeReportBtn()"
      />
    </div>

    <DialogPopup
      v-if="isHybridInverterPopupOpen" 
      :isOpen="isHybridInverterPopupOpen"
      :message="'The design currently includes a hybrid inverter, but no batteries have been added. Would you like to view the proposal without including a battery?'"
      @onClickYes="navigateToDocumentProposal"
      @onClickNo="isHybridInverterPopupOpen = false"
    />
    
  </div>
</template>

<script>
import ReusableButton from "./ReusableButton.vue";
import DialogPopup from "../../../components/ui/DialogPopup.vue";
import { useFinancialStore } from "../../../stores/financial";
import {
  isWebProposalDisabled,
  isDocProposalDisabled,
  isGenerationDisabled,
  isSalesModeReportDisabled,
  tooltipMessageForDisabledDocumentProposalButton,
  isExpertServicesOrg,
  consumptionPresent,
  batteryNotAddedToHybridInverterDesign,
} from "../../../utils";
import { mapState, mapActions } from "pinia";
import { useDesignStore } from "../../../stores/design";
import { useProjectStore } from "../../../stores/project";
import { useUiStore } from "../../../stores/ui";
import API from '../../../services/api';
import { useBatteryStore } from "../../../stores/battery";

export default {
  props: {
    design: {
      type: Object,
      required: true,
    },
  },
  components: {
    ReusableButton,
    DialogPopup,
  },
  data() {
    return {
      reusableButtonStyles: {
        fontSize: "12px",
        fontWeight: "600",
        height: "28px",
        padding: "6px 10px",
      },
      salesModeBtnStyle: {
        fontSize: "12px",
        fontWeight: "600",
        height: "28px",
        padding: "6px 10px",
        background: '#1C3366',
        border: 'none'
      },
      isLoading: false,
      isStudioLoading: false,
      isReportLoading: false,
      tooltipMessageForNoModules:
        "Please add modules and inverters to the design, and calculate generation.",
      isHybridInverterPopupOpen: false,
    };  
  },
  computed: {
    ...mapState(useProjectStore, {
      project: (state) => state
    }),
    ...mapState(useUiStore, {
      isMobileView: state => state.isMobileView
    }),
    ...mapState(useFinancialStore, {
      basicCost: (state) => state.basic_cost,
    }),
    ...mapState(useProjectStore, {
      projectPermissionObject: 'GET_PERMISISON_OBJECT',
    }),
    essOnlyDesign() {
      return this.design.system_type == 'ess_only_design'
    },
    projectData() {
      return this.design?.project || this.project;
    },
    isGenerationAlreadyCalculated() {
      let nameplateDcSize = this.dcSizeValue;
      let acSize = this.acSizeValue;
      let annualGeneration = this.annualGeneration;
      if (nameplateDcSize && acSize && annualGeneration) {
        return true;
      } else return false;
    },
    isSalesModeAvailable() {
      const user = JSON.parse(localStorage.getItem("user")) || {};
      return user.sales_mode_enabled;
    },
    //CONSUMPTION REQUIRED FOR SALES MODE V2
    isSalesModeEnabled() {
      return (
        this.consumptionPresent(this.design.project || this.project) &&
        (isExpertServicesOrg() || this.projectPermissionObject.edit_design)
      );
    },
    tooltipMessageForSalesModeStudio() {
      return this.consumptionPresent(this.design.project || this.project) ? "Contact your administrator to get access to Sales Mode." : "Please add Consumption details to the system to generate the proposal." 

    },
    isOnDesignSummary() {
      return this.$route.params.designId
    },
    acSizeValue() {
      return this.design?.versions?.summary?.ac_size
    },
    dcSizeValue() {
      return this.design?.versions?.summary?.nameplate_dc_size
    },
    annualGeneration() {
      return this.design?.annual_generation
    },
  },
  created() {
    this.autoClickCalculate()
  },
  methods: {
    ...mapActions(useDesignStore, {
      SET_DESIGN: "SET_DESIGN",
      STORE_DESIGN_VERSION: "STORE_DESIGN_VERSION",
      getAndStoreGenerationValues: "getAndStoreGenerationValues",
    }),
    async calculateGeneration() {
      try {
        this.isLoading = true;
        if (this.isOnDesignSummary) {
          await this.getAndStoreGenerationValues()
        } else {
          let resp = await API.UTILS.FETCH_GENERATION_NUMBERS(this.$props.design.id);
          let annualGeneration = resp.data.annual_generation
          this.design.annual_generation = annualGeneration
          useBatteryStore().annual_generation = annualGeneration
        }
      } catch (e) {
        console.error(e);
      }
      this.isLoading = false;
    },
    async saleStudioButton() {
      // console.log('this.$route.params.leadID: ', this.$route.params);
      // localStorage.setItem('SaleStudioConstants', JSON.stringify({
      //   leadID: this.$route.params.leadId,
      // }))
      // const routeData = this.$router.resolve({ name: 'salesStudioMode', params: { designId: this.$props.design.id } });
      // window.open(routeData.href, '_blank');
      this.isStudioLoading = true;
      try {
        let designId = this.$props.design.id;
        const response = await API.DESIGNS.FETCH_DESIGN(designId);
        await this.STORE_DESIGN_VERSION(response);
        this.$router.push({
          name: "salesStudioMode",
          params: { designId: designId },
        });
        this.isStudioLoading = false;
      } catch (e) {
        console.error(e);
        this.isStudioLoading = false;
      }
    },

    generationButtonData() {
      let user = JSON.parse(localStorage.getItem('user'))
      let isUserAllowed = user.design_studio_enabled || user.sales_mode_enabled;
      let tooltipMsg = "";
      if(!isUserAllowed) {
        tooltipMsg = "Connect with the designer to add inverter for detailed modelling";
      } else {
        if (!this.dcSizeValue){
          tooltipMsg = "Please add modules and inverters to the design to calculate generation.";
        } else if (!this.acSizeValue) {
          tooltipMsg = "Please add inverters in design studio for detailed modelling";
        }
      }
      return {
        label: "Calculate Generation",
        type: "primary",
        styleEnabled: { ...this.reusableButtonStyles },
        styleDisabled: { ...this.reusableButtonStyles },
        disableCondition: this.isGenerationDisabledForDesign(
          this.$props.design
        ),
        tooltip: tooltipMsg,
      };
    },
    generationSaleStudioButtonData() {
      return {
        label: "Open in Sales Mode",
        type : "primary",
        styleEnabled: { ...this.salesModeBtnStyle },
        styleDisabled: { ...this.reusableButtonStyles },
        disableCondition: !this.isSalesModeEnabled,
        tooltip: this.tooltipMessageForSalesModeStudio
      }
    },

    async salesModeReportBtn() {
      this.isReportLoading = true;
      let designId = this.$props.design.id || null;
      const routeData = this.$router.resolve({
        name: "salesModeReport",
        params: { designId: designId },
      });
      window.open(routeData.href, "_blank");
      this.isReportLoading = false;
    },

    generationSaleReportButtonData(){
       return {
          label: "Sales Mode Report",
          type : "primary",
          styleEnabled: { ...this.salesModeBtnStyle },
          styleDisabled: { ...this.reusableButtonStyles },
          disableCondition: this.isSalesModeReportDisabledForDesign(
            this.$props.design
          ),
          tooltip: this.tooltipMessageForSalesModeReport(this.$props.design),
       }
    },
    

    webProposalButtonData() {
      return {
        label: "Web",
        type: "primary",
        styleEnabled: { ...this.reusableButtonStyles },
        styleDisabled: { ...this.reusableButtonStyles },
        disableCondition: isWebProposalDisabled(this.$props.design),
        tooltip: this.tooltipMessageForProposal(this.$props.design),
      };
    },

    documentProposalButtonData() {
      return {
        label: "Doc",
        type: "primary",
        styleEnabled: { ...this.reusableButtonStyles },
        styleDisabled: { ...this.reusableButtonStyles },
        disableCondition: isDocProposalDisabled(this.$props.design),
        tooltip: this.tooltipMessageForProposal(this.$props.design),
      };
    },

    essOnlyProposalButtonData() {
      return {
        label: "ESS Proposal",
        type: "primary",
        styleEnabled: { ...this.reusableButtonStyles },
        styleDisabled: { ...this.reusableButtonStyles },
        disableCondition: !this.$props.design?.versions?.basic_cost || !this.consumptionPresent(this.projectData),
        tooltip: "Add consumption, pricing and battery",
      };
    },

    designOverviewButtonData() {
      return {
        label: "3D",
        type: "primary",
        styleEnabled: { ...this.reusableButtonStyles },
        styleDisabled: { ...this.reusableButtonStyles },
        disableCondition: this.is3DLinkDisabledForDesign(),
        tooltip: this.tooltipMessageForNoModules,
        //why is this returning false even though the value exists?
      };
    },
    navigateToWebProposal() {
      const designUUID = this.$props.design?.versions?.reference_id;
      const route = { name: "webProposal", params: { designUUID } };

      // Generate the absolute URL for the route
      const url = this.$router.resolve(route).href;
      // Open the URL in a new tab
      window.open(url, "_blank");
    },
    onClickDocumentProposal() {
      if (batteryNotAddedToHybridInverterDesign(this.$props.design)) {
        this.isHybridInverterPopupOpen = true
      } else {
        this.navigateToDocumentProposal()
      }
    },
    navigateToDocumentProposal() {
      const designId = this.$props.design?.id;
      const route = { name: "documentProposal", params: { designId } };
      // Generate the absolute URL for the route
      const url = this.$router.resolve(route).href;
      // Open the URL in a new tab
      window.open(url, "_blank");
      this.isHybridInverterPopupOpen = false
    },
    navigateToDesignOverview() {
      const designUUID = this.$props.design?.versions?.reference_id;
      const route = { name: "DesignOverview", params: { designUUID } };
      // Generate the absolute URL for the route
      const url = this.$router.resolve(route).href;
      // Open the URL in a new tab
      window.open(url, "_blank");
    },
    isGenerationDisabledForDesign(design) {
      return isGenerationDisabled(design);
    },
    tooltipMessageForProposal(design) {
      return tooltipMessageForDisabledDocumentProposalButton(design)
    },
    tooltipMessageForSalesModeReport(design) {
      if (this.dcSizeValue == null || this.dcSizeValue == 0 || !(this.acSizeValue >=0)) {  //here the difference is, it will work even for Ac Size 0
        return this.tooltipMessageForNoModules;
      } else if (
        !this.consumptionPresent(design.project || this.project) //PRICING IS NOT REQUIRED FOR SALES MODE REPORT. CONSUMPTION IS
      ) {
        return "Please add consumption to the project.";
      }
    },
    isSalesModeReportDisabledForDesign(design) {
      return isSalesModeReportDisabled({
        orderStatus: design?.request_expert_service?.order_status,
        nameplateDcSize: this.dcSizeValue,
        acSize: this.acSizeValue,
        reportTemplate:
          design?.versions?.setting?.report_defaults?.template_name,
        financials: design?.pricing,
        basicCost: design?.versions?.basic_cost,
        design: design,
        project: this.project

      });
    },
    is3DLinkDisabledForDesign() {
      // Disable 3D link if generation is not calculated
      if (this.annualGeneration || (this.acSizeValue && this.dcSizeValue)) {
        return false;
      }

      return true;
    },
    autoClickCalculate() {
      if (!this.isOnDesignSummary) { return }

      // Auto click calculate button
      if (
        this.acSizeValue &&
        this.dcSizeValue &&
        !this.annualGeneration
      ) {
        this.calculateGeneration()
      }
    },
    consumptionPresent,
  },
};
</script>

<style scoped>
.btnsContainerFlex {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
@media (max-width: 768px) {
  .btnsContainerFlex {
    flex-direction: row;
    gap: 24px;
  }
  .flexTwo{
    flex-direction: column;
  }
  .flexTwo > div >>> button{
    width: 100%;
    text-align: left;
    max-width: 135px;
  }
}
.flexOne,
.flexTwo {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}
</style>
