
<div class="deleteModule">
  <el-dialog
    :visible="isDeleteProjectPopupOpen"
    :close-on-click-modal="false"
    title="Add User"
    width="30%"
    class="delete_module"
    @close="closeModal"
  >
    <div class="close_button">
      <img
        src="../assets/img/close.svg"
        alt="close"
        @click="$emit('cancelDelete')"
      />
    </div>
    <div class="alert">
      <img 
        src="../../design/assets/img/alert.svg" 
        alt="alert" 
        class="warningImage"
      />
    </div>
    <p class="msg">Are you sure you want to delete this project ?</p>
    <div class="button_container">
      <el-button class="confirmbutton" type="primary" @click="$emit('confirmDelete')">Yes</el-button>
    </div>
  </el-dialog>
</div>
