
<div id="parentContainer">
  <el-dialog
    :visible="isMyTaskMobileScreenPopupVisible"
    :close-on-click-modal="false"
    style="min-width: 800px"
    title="Project 1"
    @close="onDialogClose"
  >
    <div class="container">
      <div class="innrBdyCont">
        <div class="innerBodyContainer">
          <div>
            <p class="headMD">NAME</p>
            <div class="arrowDropCont">
              <img
                src="../../../assets/drop/Group 2018.svg"
                class="innrIcnTSK"
              />
              <p class="projectNoInnr">ACT...566 Conduct Site Survey</p>
            </div>
          </div>
          <p class="createdBy">
            <span class="headMD">CREATED BY</span>John Doe
          </p>
          <p class="strtDate">
            <span class="headMD">START DATE</span>08-24-2022
          </p>
          <p class="endDate">
            <span class="headMD">END DATE</span>08-24-2022
          </p>
          <div>
            <p class="headMD">STATUS</p>
            <el-select v-model="value" placeholder="In-progress">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="innerBodyContainer">
          <div>
            <p class="headMD">PROJECT NAME</p>
            <div class="arrowDropCont">
              <img
                src="../../../assets/drop/Ellipse 182.svg"
                class="innrIcnTSK"
              />
              <p class="projectNoInnr">ACT...566 Conduct Site Survey</p>
            </div>
          </div>
          <p class="createdBy">
            <span class="headMD">CREATED BY</span>John Doe
          </p>
          <p class="strtDate">
            <span class="headMD">START DATE</span>08-24-2022
          </p>
          <p class="endDate">
            <span class="headMD">END DATE</span>08-24-2022
          </p>
          <div>
            <p class="headMD">STATUS</p>
            <el-select v-model="value" placeholder="In-progress">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="innerBodyContainer">
          <div>
            <p class="headMD">PROJECT NAME</p>
            <div class="arrowDropCont">
              <img
                src="../../../assets/drop/Group 2015.svg"
                class="innrIcnTSK"
              />
              <p class="projectNoInnr">ACT...566 Conduct Site Survey</p>
            </div>
          </div>
          <p class="createdBy">
            <span class="headMD">CREATED BY</span>John Doe
          </p>
          <p class="strtDate">
            <span class="headMD">START DATE</span>08-24-2022
          </p>
          <p class="endDate">
            <span class="headMD">END DATE</span>08-24-2022
          </p>
          <div>
            <p class="headMD">STATUS</p>
            <el-select v-model="value" placeholder="In-progress">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="innerBodyContainer">
          <div>
            <p class="headMD">PROJECT NAME</p>
            <div class="arrowDropCont">
              <img
                src="../../../assets/drop/Group 2016.svg"
                class="innrIcnTSK"
              />
              <p class="projectNoInnr">ACT...566 Conduct Site Survey</p>
            </div>
          </div>
          <p class="createdBy">
            <span class="headMD">CREATED BY</span>John Doe
          </p>
          <p class="strtDate">
            <span class="headMD">START DATE</span>08-24-2022
          </p>
          <p class="endDate">
            <span class="headMD">END DATE</span>08-24-2022
          </p>
          <div>
            <p class="headMD">STATUS</p>
            <el-select v-model="value" placeholder="In-progress">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="innerBodyContainer">
          <div>
            <p class="headMD">PROJECT NAME</p>
            <div class="arrowDropCont">
              <img
                src="../../../assets/drop/Group 2018.svg"
                class="innrIcnTSK"
              />
              <p class="projectNoInnr">ACT...566 Conduct Site Survey</p>
            </div>
          </div>
          <p class="createdBy">
            <span class="headMD">CREATED BY</span>John Doe
          </p>
          <p class="strtDate">
            <span class="headMD">START DATE</span>08-24-2022
          </p>
          <p class="endDate">
            <span class="headMD">END DATE</span>08-24-2022
          </p>
          <div>
            <p class="headMD">STATUS</p>
            <el-select v-model="value" placeholder="In-progress">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>

      </div>
    </div>
  </el-dialog>
</div>
