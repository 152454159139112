<template>
    <div class='deleteModule'>
        <el-dialog 
            :visible="isStatusPopupVisible"
            :close-on-click-modal="false"
            title=""
            width="30%"
            @close="closeModal"
            append-to-body
        >
            <b-icon icon="check-circle-fill" variant="success" class="successIcon"></b-icon> 
            <p class='title'>Email Sent.</p>
            <p class='description'>You have successfully shared the contract, would you like to view the status? </p>
            <div class='button_container'>
                <el-button type="" class="commonBtnClass cancelBtn"  @click="checkStatus(false)">Cancel</el-button>
                <el-button type="primary" class="commonBtnClass"  @click="checkStatus(true)">Yes</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    emits: ["checkStatus", "cancelDelete"],
    props: {
        isStatusPopupVisible: {
            type: Boolean,
            default: false,
        },
        message:{
            type: String,
            default: 'Are you sure you want to delete this file?',
        }
    },
    methods: {
        checkStatus(wantToCheckStatus){
          this.$emit('checkStatus',wantToCheckStatus);
        },
        closeModal() {
            this.$emit('cancelDelete');
        }
    },
}
</script>

<style scoped>

p {
    word-break: break-word;
}

.el-dialog__wrapper >>> .el-dialog{
    border-radius: 16px;
    margin-top: 24vh !important;
    max-width: 350px !important;
    width: 90%;
}
.el-dialog__wrapper >>> .el-dialog__header{
    display: none;
}

.el-dialog__wrapper >>> .el-dialog__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding: 24px !important;
    text-align: center;
}

.title {
    font-size: 16px;
    font-weight: bold;
    color: #5FCC29;
}

.description {
    font-size: 14px;
    color: #222;
}

.successIcon {
    color: #5FCC29;
    font-size: 48px;
}

.button_container {
    margin-top: 8px;
}

.commonBtnClass {
    font-size: 16px;
    font-weight: bold;
}

.cancelBtn {
    border: 1px solid var(--Grey-Scale-999, #999);
    color: #999
}


@media (max-width: 500px) {
    .el-dialog__wrapper >>> .el-dialog{
    width: 86% !important;
}
}


</style>