<template>
    <el-skeleton class="skeleton-container"  :style="{width: width,height:height}" animated>
      <template v-slot:template>
        <el-skeleton-item
          variant="rect"
          :style="{height:height}"
        />
      </template>
    </el-skeleton>
</template>
<script>
    export default {
        name: 'Skeleton',
        props: {
            height: {
                type: String,
                default: '100% !important'
            },
            width: {
                type: String,
                default: '100%'
            }
        }
    }
</script>

<style scoped>
.skeleton-container>>>.el-skeleton {
  height: 100%;

}

</style>