
<div class="deleteModule">
  <el-dialog
    :visible="isAddOrEditOrCopyPopupOpen"
    :close-on-click-modal="false"
    title="Add User"
    width="45%"
    class="delete_module"
    @close="closeModal"
  >
    <div class="container">
      <!-- -----------------header--------- -->
      <div class="Rectangle">
        <p class="rectContent">{{ typeOfOperation }} {{ typeOfTab }}</p>
        <img
          src="../../../../src/assets/img/close.svg"
          alt="close"
          @click="$emit('cancelAdd')"
          class="closeBtn"
        />
      </div>

      <!-- -----------------input Container--------- -->
      <div class="inputContainer">
        <el-form ref="form" :model="formObj">
          <!-- -----------------Input One--------- -->
          <div>
            <el-form-item
              :label="'Enter ' + typeOfTab + ' Name*'"
              class="firstInput"
            >
              <el-input v-model="formObj.name" @input="ProjectName">
              </el-input>
              <p class="validationCss" v-if="nameInvalid" >
                {{ nameField }}
              </p>
            </el-form-item>
          </div>
          <!-- -----------------other Four Inputs--------- -->
          <div class="inputs">
            <el-form-item label="Project Type*"
              ><br />
              <el-select
                v-model="formObj.project_type"
                placeholder="Select Project Type"
                @change="ProjectType"
              >
                <el-option
                  label="Residential"
                  class="projectType"
                  value="residential"
                ></el-option>
                <el-option
                  label="Commercial"
                  class="ProjectType"
                  value="commercial"
                ></el-option>
              </el-select>
              <p
                class="validationCss"
                v-if="projectTypeInvalid"
                
              >
                {{ project_typeField }}
              </p>
            </el-form-item>

            <el-form-item label="Upfront Payment*">
              <span class="inputValues"
                >{{ currencySymbolNameMap[currencyCode] }}
              </span>
              <el-input
                v-model="formObj.upfront_payment"
                type="Number"
                min="0"
                @input="Isupfront()"
              ></el-input>
              <p
                class="validationCss"
                v-if="upFrontPaymentInvalid"
                
              >
                {{ upfront_paymentField }}
              </p>
            </el-form-item>

            <el-form-item label="Rate*" v-if="typeOfTab == 'PPA'">
              <span class="inputValues"
                >{{ currencySymbolNameMap[currencyCode]
                }}<span v-if="typeOfTab == 'Lease'">/month</span
                ><span v-else>/kWh</span></span
              >
              <el-input
                v-model="formObj.ppa_rate"
                type="Number"
                min="0"
                @input="Israte()"
              ></el-input>
              <p class="validationCss" v-if="rateInvalid" >
                {{ ppa_rateField }}
              </p>
            </el-form-item>
            <el-form-item label="Monthly Payment" v-else>
              <span class="inputValues"
                >{{ currencySymbolNameMap[currencyCode]
                }}<span v-if="typeOfTab == 'Lease'">/month</span
                ><span v-else>/kWh</span></span
              >
              <el-input
                v-model="formObj.monthly_lease_payment"
                type="Number"
                min="0"
                @input="IsMonthly()"
              ></el-input>
              <p class="validationCss" v-if="monthInvalid" >
                {{ monthly_lease_paymentInvalid }}
              </p>
            </el-form-item>

            <el-form-item label="Escalation Rate*">
              <!-- <span class="rupeeIcon">{{currencySymbolNameMap[currencyCode]}}</span> -->
              <span class="inputValues">% per year</span>
              <el-input
                v-model="formObj.escalation_rate"
                type="Number"
                min="0"
                @input="Isescalation()"
              ></el-input>
              <p
                class="validationCss"
                v-if="escalationExceed"
                
              >
                {{ escalationIsInvalid }}
              </p>
              <p
                class="validationCss"
                v-if="escalationRateInvalid"
                
              >
                {{ escalation_Field }}
              </p>
            </el-form-item>
            <el-form-item label="Currency Selection*">
              <el-select
                v-model="currency"
                @change="onCountryChange"
                filterable
                remote
                reserve-keyword
              >
                <el-option
                  v-for="country in countryDetails"
                  :key="country.id"
                  :label="`${country.currency_code} ${
                    currencySymbolNameMap[country.currency_code]
                      ? `(${currencySymbolNameMap[country.currency_code]}) ${
                          country.name
                        } `
                      : ''
                  }`"
                  :value="country.id"
                />
              </el-select>
              <p class="validationCss" v-if="currInvalid" >
                {{ currField }}
              </p>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div class="bottomInfo">
        <hr />
        <p style="word-break: break-word">
          {{ getDescription() }}
        </p>
      </div>
      <!-- -----------------button--------- -->
      <div class="popupBtnContainer">
        <el-button
          v-if="typeOfOperation === 'Add' || typeOfOperation === 'Copy'"
          class="popupBtn"
          type="primary"
          @click="onSubmit"
          >{{ typeOfOperation }} {{ typeOfTab }}</el-button
        ><el-button
          v-if="typeOfOperation === 'Edit'"
          class="popupBtn"
          type="primary"
          @click="onSubmit"
          >Update {{ typeOfTab }}</el-button
        >
      </div>
    </div>

    <!-- <p class="msg">Are you sure you want to oprn this {{ toBeAdded }} ?</p> -->
  </el-dialog>
</div>
