import axios from "axios";

export default {
    FETCH_ALL_REGIONS() {
        return axios.get('/api/region/');
    },
    FETCH_SINGLE_REGIONS(id) {
        return axios.get(`/api/region/${id}/`);
    },
    UPDATE_REGIONS(id, data) {
        return axios.patch(`api/region/${id}/`, data);
    },
    CREATE_REGIONS(data) {
        return axios.post("api/region/", data);
    },
    DELETE_REGIONS(id) {
        return axios.delete(`api/region/${id}/`);
    },
    FETCH_ALL_LEAD_SOURCE(isBulkLeadImport) {
        if(isBulkLeadImport) {
            return axios.get('/api/lead-source-category/lead_source/?bulk_import=bulk_import');
            
        } else {
            return axios.get('/api/lead-source-category/lead_source/');
        }
    },
    UPDATE_LEAD_SOURCE(id, data) {
        return axios.patch(`api/lead-source-category/lead_source/${id}/`, data);
    },
    CREATE_LEAD_SOURCE(data) {
        return axios.post("api/lead-source-category/lead_source/", data);
    },
    DELETE_LEAD_SOURCE(id) {
        return axios.delete(`api/lead-source-category/lead_source/${id}/`);
    },
    FETCH_ALL_LEAD_SOURCE_CATEGORIES() {
        return axios.get('/api/lead-source-category/');
    },
    UPDATE_LEAD_SOURCE_CATEGORY(id, data) {
        return axios.patch(`api/lead-source-category/${id}/`, data);
    },
    CREATE_LEAD_SOURCE_CATEGORY(data) {
        return axios.post("api/lead-source-category/", data);
    },
    DELETE_LEAD_SOURCE_CATEGORY(id) {
        return axios.delete(`api/lead-source-category/${id}/`);
    },
}