<template>
    <div class="mappinngContainer">
        <div class="headerCont">
            <p class="heading">Stages in file</p>
            <p class="heading">Pipeline Stages </p>
        </div>
        <div class="bodyCont">
            <div class="rows" v-for="(key, index) in Object.keys(keyValuePairForStages)" :key="index">
                <div class="positionR">
                    <div class="values">
                        <p class="font16Normal">{{ key == "" ? 'Empty' : key }}</p>
                    </div>
                </div>
                <el-select v-model="keyValuePairForStages[key]" placeholder="Select Stage" @change="updateButtonStatus">
                    <el-option v-for="stage in stages" :key="stage.id" :label="stage.name" :value="stage.id">
                    </el-option>
                </el-select>
            </div>
        </div>
    </div>
</template>

<script>

import API from "@/services/api/";
import { useMiscStore } from "../../../stores/misc";
import { mapActions, mapState } from "pinia";
import { DATABASE_URL } from "@/constants.js"
import Papa from "papaparse";
import { uploadFileToBlob } from "@/utils.js";
import { v4 } from 'uuid';
import { containerNameBulkImport } from "@/constants.js";

export default {
    name: "stageMapping",
    components: {

    },

    data() {
        return {
            stages: [],
            keyValuePairForStages: {},
            fileName: ''
        }
    },

    mounted() {
        this.selectedPipeline(this.bulkLeadImportState.selectedPipeline);
        this.bulkLeadImportState.isValidateBtnDisabled = true;
        this.addingKeysAndValuesFunc();
    },

    computed: {
        ...mapState(useMiscStore, {
            bulkLeadImportState: (state) => state.bulkLeadImportState,
        }),
    },

    methods: {

        uploadFileToBlob,

        async exportCSV() {
            const csv = Papa.unparse(this.bulkLeadImportState.fileData);
            const blob = new Blob([csv], { type: 'text/csv' });
            this.uuid = v4();
            const file_name = `exportedData_${this.uuid}.csv`;
            this.fileName = file_name;
            const response = await uploadFileToBlob([blob], file_name, containerNameBulkImport);
        },

        setupSSE() {
            let userID = JSON.parse(localStorage.getItem('user')).user_id;
            this.uuid = v4();
            const file_name = this.fileName;
            let temp_file = `${file_name}`;
            let user_file = this.bulkLeadImportState.userFileName;
            // Establish a connection to the server endpoint that provides SSE
            this.bulkLeadImportState.eventSource = new EventSource(`${DATABASE_URL}lead/upload_progress/${userID}/?temp_file=${temp_file}&user_file=${user_file}`);
            
            // Handle events received from the server
            this.bulkLeadImportState.eventSource.onmessage = (event) => {
                const parts = event.data.split(':');
                const percentage = parts[1].replace('%', '');
                this.bulkLeadImportState.progress = parseInt(percentage);
                this.bulkLeadImportState.isSSEProcessing = true;
                if (percentage == 'Task completed') {
                    this.bulkLeadImportState.showMinimizablePopup = false;
                    this.bulkLeadImportState.isBulkLeadUploadedPopupVisible = true;
                    this.bulkLeadImportState.isLeadsUploadSuccessfully = true;
                    this.bulkLeadImportState.progress = 0;
                    this.bulkLeadImportState.isSSEProcessing = false;
                    this.bulkLeadImportState.eventSource.close();
                    this.bulkLeadImportState.leadsRerender++;
                    this.bulkLeadImportState.isCancelBtnClicked = false;
                }
            };

            this.bulkLeadImportState.eventSource.onerror = (error) => {
                this.bulkLeadImportState.eventSource.close();
                this.bulkLeadImportState.isSSEProcessing = false;
                this.bulkLeadImportState.showMinimizablePopup = false;
                this.bulkLeadImportState.isBulkLeadUploadedPopupVisible = true;
                this.bulkLeadImportState.isLeadsUploadSuccessfully = false;
                this.bulkLeadImportState.isLeadUploadedError = true;
                this.bulkLeadImportState.isCancelBtnClicked = false;
            };
        },

        addingKeysAndValuesFunc() {
            for (let index = 0; index < this.bulkLeadImportState.uniqueStagesArray.length; index++) {
                const uniqueValues = this.bulkLeadImportState.uniqueStagesArray[index];
                this.$set(this.keyValuePairForStages, uniqueValues, '');
                this.updateButtonStatus()
            };
        },

        async stageMappingAndValidateFunc() {
            for (let index = 0; index < this.bulkLeadImportState.fileData.length; index++) {
                const stageValue = this.bulkLeadImportState.fileData[index]['stage'];
                if (this.keyValuePairForStages.hasOwnProperty(stageValue)) {
                    this.bulkLeadImportState.fileData[index]['stage'] = this.keyValuePairForStages[stageValue];
                }
            };
            await this.exportCSV();
            this.setupSSE();
            this.bulkLeadImportState.showStageFieldsSection = false;
            this.bulkLeadImportState.showMinimizablePopup = true;
        },

        selectedPipeline(val) {
            let Stages = this.bulkLeadImportState.pipelines.filter((pipeline) => pipeline.id == val);
            this.stages = Stages[0].pipeline_stages;
        },

        updateButtonStatus() {
            let arr = Object.values(this.keyValuePairForStages);
            if (arr.includes("")) {
                this.bulkLeadImportState.isValidateBtnDisabled = true;
            } else {
                this.bulkLeadImportState.isValidateBtnDisabled = false;
            };
        }
    }

}

</script>


<style scoped>

p {
    word-break: break-word;
}

.headerCont,
.rows {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    row-gap: 16px;
    column-gap: 24px;
    padding-bottom: 8px;
    border-bottom: 1px solid #ccc;
}

.headerCont {
    margin: 24px 24px 0px 24px;
    max-width: 545px;
}

.rows {
    padding: 0px;
    border: 0px;
    grid-template-columns: 1fr 1fr 0.5fr;
}

.values {
    border-radius: 8px;
    background: var(--background-highlight, #E8EDF2);
    padding: 12px;
}

.heading,
.font16Normal {
    font-size: 16px;
    font-weight: 600;
    color: #222;
}

.font16Normal {
    font-weight: normal;
}

.bodyCont {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-height: 61vh;
    min-height: 61vh;
    overflow: hidden;
    overflow-y: scroll;
    margin-top: 16px;
    padding: 0px 24px 24px 24px;
}

.bodyCont>>>.el-input__inner {
    background: #E8EDF2;
    border-radius: 8px;
    height: 45px;
    border: none;
    font-size: 16px;
    color: #222;
}

.bodyCont>>>.el-input__inner::placeholder {
    color: #222;
}

.bodyCont>>>.el-select .el-input .el-select__caret {
    color: #222;
    font-size: 16px;
    font-weight: 600;
}

@media (max-width: 700px) {

    .headerCont {
        margin: 8px 16px 0px 16px;
    }

    .bodyCont {
        padding: 16px;
        margin-top: 0px;
        max-height: 65vh;
        min-height: 65vh;
    }

    .rows {
        grid-template-columns: 1fr 1fr;
        gap: 12px;
        align-items: flex-start;
    }

}
</style>