import BaseObject from "../../BaseObject";

export default class RoofVertex extends BaseObject{
    constructor(stage, vertex, next, previous, isFirst = false){
      super(stage);
        this.vertex = vertex;
        this.next = next;
        this.previous = previous;
        this.isFirst = isFirst;
        this.isDuplicate = false;
    }
}