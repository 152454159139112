
<div class="card">
  <div class="card_header flex_header">
    <div class="heading">
      <h4>Documents</h4>
      <!-- <div class="hover_information">
        <i class="fas fa-info-circle"></i>
        <div class="tooltip">
          <p>
            In this section, you will be able to view and download all the
            project reports and the documents you may have shared.
          </p>
        </div>
      </div> -->
    </div>
  </div>
  <div class="chatBoxContainer">
    <div v-if="resultOfFilesForDesigner.length !== 0">
      <div class="fileDownloadCont">
        <h4 class="PFRHeading">Project Files/ Reports</h4>
        <div class="flexContAll" @click="downloadAllClickForDesigner">
          <img
            src="../../../../assets/drop/Group 1808.svg"
            class="allDwnldIcon"
          />
          <p class="dwnldCont">Download All</p>
        </div>
      </div>
      <div class="gridContainer mBottom">
        <div
          class="PDcard"
          v-for="(file, i) in finalListForDesigner"
          :key="i"
        >
          <!-- <img
            :src="
              fileUploadAssets[
                `/src/assets/drop/fileUploadImages/${findFileTypeIcon(file)}`
              ]
            "
          /> -->
          <p class="contentOne">
            <abbr :title="file.display_name" class="abbrTag">{{
              file.display_name
            }}</abbr>
          </p>
          <div class="icons">
            <img
              src="../../../../assets/drop/Group 1832.png"
              class="dwnldIcon"
              target="_blank"
              @click="downloadClickForDesigner(file)"
            />
            <img
              src="../../../../assets/drop/Group 1831.png"
              class="delBtn"
              @click="deleteClickForDesigner(file.id)"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="fileDownloadCont">
      <h4 class="docHeading"></h4>
      <div class="flexContAll" @click="downloadAllClick">
        <img
          src="../../../../assets/drop/Group 1808.svg"
          class="allDwnldIcon"
        />
        <p class="dwnldCont">Download All</p>
      </div>
    </div> -->
    <div class="gridContainer">
      <div class="PDcard" v-for="(file, i) in finalList" :key="i">
        <img
          :src="
            fileUploadAssets[
              `/src/assets/drop/fileUploadImages/${findFileTypeIcon(file)}`
            ]
          "
        />
        <p class="contentOne">
          <abbr :title="file.display_name" class="abbrTag">{{
            file.display_name
          }}</abbr>
        </p>
        <div class="icons">
          <img
            src="../../../../assets/drop/Group 1832.png"
            class="dwnldIcon"
            target="_blank"
            @click="downloadClick(file)"
          />
          <img
            src="../../../../assets/drop/Group 1831.png"
            class="delBtn"
            @click="deleteClick(file.id)"
          />
        </div>
      </div>
      <div class="PDcard" @click="isShareDocumentPopupVisible = true">
        <img
          src="../../../../assets/drop/plus-circle (1).png"
          class="dwnldIcon"
        />
        <p class="addDocCon">Add Document</p>
      </div>
    </div>
  </div>
  <DeleteDocument
    v-if="isDeleteProjectDocumentPopupOpen"
    :isDeleteProjectDocumentPopupOpen="isDeleteProjectDocumentPopupOpen"
    @cancelDelete="isDeleteProjectDocumentPopupOpen = false"
    @confirmDelete="confirmDelete"
  />
  <ShareDocumentPopup
    :isShareDocumentPopupVisible.sync="isShareDocumentPopupVisible"
    :handleSave="handleSave"
    :isCRMMode="true"
    @confirmUploaded="confirmUploaded"
  />
</div>
