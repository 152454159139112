<template>
    <div class="Firstcontainer" style="display: relative;">
        <el-popover v-if="removable" :popper-class="'popover-avatarTest'" placement="top" v-model="forceClose"
            trigger="click">
            <div v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="10" class="tag-box"
                :style="{ flexDirection: $props.isColumn ? 'column' : 'row' }">
                <div class="search-bar">
                    <el-input v-model="searchTerm" placeholder="Search" @change="searchAssignee"></el-input>
                </div>
                <el-tag style="cursor: default;" v-for="avatar in selectableAvatars"
                    :key="avatar.id" :size="'medium'" @close="handleRemove(avatar.id)" :type="'info'">

                    <el-checkbox v-model="avatar.isSelected" @change="handleCheckBoxChange(avatar.id)">
                        <el-avatar :size="30" :style="getTagAvatarStyleTypeTwo(avatar.name)">
                            <span>{{ generateInitials(avatar.name) }}</span>
                        </el-avatar>
                        <span class="avatar-name">{{ avatar.name }}</span>
                    </el-checkbox>
                </el-tag>
                <template v-if="!isSelectable">
                    <el-tag style="cursor: default; max-height:200px; overflow-y:auto" v-for="avatar in selectableAvatars"
                        :key="avatar.id" :size="'medium'" @close="handleRemove(avatar.id)" closable :type="'info'">
                        <el-avatar :size="20" :style="getTagAvatarStyle(avatar.name)">
                            <span>{{ generateInitials(avatar.name) }}</span>
                        </el-avatar>
                        <span>{{ avatar.name }}</span>
                    </el-tag>
                </template>
            </div>
            <template v-slot:reference>
            <div :style="{ position: 'relative', cursor: 'pointer', width: computeWidth }" >
                <el-avatar :class="$props.hover ? 'avatar-hover' : ''" :size="imgWidth"
                    v-for="(avatar, index) in computedAvatars" :key="index" :style="getAvatarStyle(avatar.name, index)">
                    <el-tooltip class="item" effect="dark" :content="avatar.message" placement="top" popper-class="avatar-hover-tooltip">
                        <span>{{ limitNotRequired ? (generateInitials(avatar.name)) : ($props.avatars.length > $props.limit
                            ? (index === 0 ? avatar.name : generateInitials(avatar.name)) : generateInitials(avatar.name))
                        }}</span>
                    </el-tooltip>
                </el-avatar>
            </div>
            </template>
        </el-popover>
        <!-- <div :style="{position: 'relative', cursor: 'pointer', width: computeWidth}" v-else >
                    <el-avatar
                        :class="$props.hover ? 'avatar-hover' : ''"
                        :size="`${$props.imgWidth}`"
                        v-for="(avatar, index) in computedAvatars"
                        :key="index"
                        :style="getAvatarStyle(avatar.name, index)"
                        >
                        <el-tooltip class="item" effect="dark" :content="avatar.message" placement="top">
                            <span>{{ limitNotRequired ? (generateInitials(avatar.name)) : ($props.avatars.length > $props.limit ? (index === 0 ? avatar.name : generateInitials(avatar.name)) :  generateInitials(avatar.name)) }}</span>
                        </el-tooltip>
                    </el-avatar>
                </div> -->
        <div v-if="$props.editable">
            <!-- <el-button icon="el-icon-plus" size="mini"
                    class="selection-avatars"
                    :style="{left: computeLeft, 
                        // top: `${$props.imgWidth*1/4}px`,
                         position: 'absolute',
                         }"
                    @click="handleClick"
                    v-show="false"
                    >Add </el-button> -->
            <el-select v-model="selectedValue" v-show="true" filterable placeholder="" :size="`${$props.imgWidth}`"
                class="selection-avatars" :style="{
                    left: computeLeft,
                    top: `-${$props.imgWidth * 1 / 5}px`,
                }" @change="handleAdd" @visible-change="handleVisibleChange" ref="selection">
                <el-option v-for="avatar in computedSelectionAvatars" :key="avatar.id" :label="avatar.name"
                    :value="avatar.id">
                    <div>
                        <el-avatar :size="20" :style="getSelectionAvatarStyle(avatar.name)">
                            <span>{{ generateInitials(avatar.name) }}</span>
                        </el-avatar>
                        <span>{{ avatar.name }}</span>
                    </div>
                </el-option>
            </el-select>
        </div>
    </div>
</template>
<script>
import { generateAvatarColor } from '../../../utils';
export default {
    emits: ["loadMore", "searchAssignee", "handleSelectedChange", , "handleAdd", "handleRemove"],
    name: 'AvatarHandler',
    props: {
        avatars: {
            type: Array,
            default() {
                return []
            }
        },
        selectionAvatars: {
            type: Array,
            default: () => []
        },
        overlap: {
            type: Boolean,
            default: false
        },
        imgWidth: {
            type: Number,
            default: 50
        },
        limit: {
            type: Number,
            default: 3
        },
        count: {
            type: Number,
            default: 0
        },
        fontSize: {
            type: String,
            default: '20px'
        },
        gap: {
            type: Number,
            default: 10
        },
        removable: {
            type: Boolean,
            default: false
        },
        editable: {
            type: Boolean,
            default: true,
        },
        hover: {
            type: Boolean,
            default: false,
        },
        isColumn: {
            type: Boolean,
            default: false
        },
        isSelectable: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showSelection: false,
            forceClose: null,
            limitNotRequired: null,
            selectedValue: null,
            busy: false,
            addCount: '',
            searchTerm:'',
        }
    },
    computed: {
        selectableAvatars() {
            return this.isSelectable ? this.avatars : [];
        },
        computeAvatarLength() {
            return (this.$props.avatars.length < this.$props.limit ? this.$props.avatars.length : this.$props.limit)
        },
        computeLeft() {
            if (this.$props.avatars.length < this.$props.limit) {
                if (this.$props.overlap) {
                    return `${(this.$props.imgWidth * (this.$props.avatars.length + 1) - ((this.computeAvatarLength - 1) * this.$props.gap))}px`
                }
                return `${(this.$props.imgWidth * (this.$props.avatars.length + 1) + (this.computeAvatarLength * this.$props.gap))}px`
            }
            if (this.$props.overlap) {
                return `${(this.$props.imgWidth * (this.$props.limit + 1) - ((this.computeAvatarLength - 1) * this.$props.gap)) + 2}px`
            }
            return `${(this.$props.imgWidth * (this.$props.limit + 1) + (this.computeAvatarLength * this.$props.gap)) + 4}px`
        },
        computeWidth() {
            return `${this.$props.imgWidth * this.$props.limit}px`
        },
        computedSelectionAvatars() {
            return this.$props.selectionAvatars.filter(o1 => !this.$props.avatars.some(o2 => o1.id === o2.id));
        },
        computedAvatars() {
            if (this.$props.limit < this.$props.avatars.length) {
                this.addCount = `+${(this.$props.count - 1) - (this.$props.limit - 1)}`
                this.limitNotRequired = false;
                let filteredArr = this.$props.avatars.slice(0, (this.$props.limit + 1))
                let last = this.$props.avatars.slice(this.$props.limit, this.$props.avatars.length)
                let lastMessage = '';
                last.forEach((e, index) => {
                    lastMessage += e.name + `${index === last.length - 1 ? '' : ', '}`
                })
                filteredArr = filteredArr.map((e, index) => {
                    if (index === filteredArr.length - 1) {
                        return { ...e, name: `+${(this.$props.count - 1) - (this.$props.limit - 1)}`, message: lastMessage }
                    }
                    return { ...e, message: e.name }
                })
                return filteredArr.reverse()
            } else {
                this.limitNotRequired = true;
                this.$props.limit = this.$props.avatars.length - 1
                let arr = this.$props.avatars.map((e) => {
                    return { ...e, message: e.name }
                })
                return arr.reverse()
            }
        }
    },
    methods: {
        loadMore() {
            this.$emit("loadMore")
        },
        searchAssignee(val){
            this.$emit("searchAssignee",val)
        },
        handleCheckBoxChange(id) {
            this.$emit('handleSelectedChange', id)
        },
        handleVisibleChange(bool) {
            if (!bool) this.showSelection = false
        },
        handleClick() {
            this.showSelection = true
            this.$nextTick(() => {
                this.$refs.selection.focus();
            });
        },
        handleAdd(id) {
            let ele = this.$props.selectionAvatars.find(e => e.id === id)
            this.$emit('handleAdd', ele)
            //if(this.$props.selectionAvatars.filter(o1 => !this.$props.avatars.some(o2 => o1.id === o2.id)).length === 0){} 
            this.showSelection = false
            this.selectedValue = ''
        },
        handleRemove(id) {
            if (this.$props.avatars.length - 3 < this.$props.limit) this.$props.limit = this.$props.avatars.length - 1
            this.$emit('handleRemove', id)
        },
        generateInitials(name) {
            if (!name || name.trim().length === 0) {
                return "N/A"; // Return empty string for blank names
            }

            const names = name.trim().split(" ");
            const initials = names.map((n) => n.charAt(0).toUpperCase());

            if (initials.length === 1) {
                return initials[0]; // Return single initial if only one name is available
            } else {
                return initials[0] + initials[initials.length - 1]; // Return first and last initials for multiple names
            }

        },

        getAvatarStyle(value, index) {
            const backgroundColor = generateAvatarColor(value, this.addCount);
            if (this.$props.overlap) {
                return {
                    backgroundColor: backgroundColor,
                    fontSize: this.$props.fontSize,
                    position: 'absolute',
                    left: `${((this.$props.limit + 1) - (index + 1)) * this.$props.imgWidth - ((this.$props.limit + 1) - (index + 1)) * (this.$props.gap)}px`
                };
            }
            return {
                marginRight: "2.5px",
                marginLeft: "2.5px",
                backgroundColor: backgroundColor,
                fontSize: this.$props.fontSize,
                position: 'absolute',
                left: `${((this.$props.limit + 1) - (index + 1)) * (this.$props.imgWidth + this.$props.gap) - ((this.$props.limit + 1) - (index + 1))}px`
            };
        },
        getTagAvatarStyle(value, index) {
            const backgroundColor = generateAvatarColor(value, this.addCount);
            return {
                marginRight: "2.5px",
                marginLeft: "2.5px",
                backgroundColor: backgroundColor,
                fontSize: '10px',
                position: 'absolute',
                left: '5px',
                top: '3px'
            };
        },
        getTagAvatarStyleTypeTwo(value, index) {
            const backgroundColor = generateAvatarColor(value, this.addCount);
            return {
                marginRight: "2.5px",
                marginLeft: "2.5px",
                backgroundColor: backgroundColor,
                fontSize: '10px',
                position: 'relative',
                top: '2px'
            };
        },
        getSelectionAvatarStyle(value) {
            const backgroundColor = generateAvatarColor(value, this.addCount);
            return {
                marginRight: "2.5px",
                marginTop: "2.5px",
                backgroundColor: backgroundColor,
                fontSize: '10px',
                position: 'relative',
                top: '4px',
                left: '-5px'
            };
        }
    },
    watch: {
        avatars: {
            handler(value) {
                if (value.length === 0) {
                    this.forceClose = false
                }
            }
        },
        searchTerm(val){
      this.searchAssignee(val)
    },
    }
}
</script>
<style scoped>
.tag-box {
    max-width: 500px;
    display: flex;
    max-height: 300px;
    border: transparent !important;
    overflow-y: auto;
    gap: 10px;
    padding: 0.5rem;
}

.tag-box>>>.el-tag.el-tag--info {
    position: relative;
    background-color: transparent;
    border-color: transparent;
    color: #909399;
}

.avatar-hover:hover {
    border: 1px solid white;
    z-index: 1;
}

.selection-avatars>>>.el-input__inner {
    width: 100px;
    display: absolute;
    background-color: transparent;
    border: transparent;
}

.selection-avatars:hover {
    color: transparent;
    background-color: transparent;
    border: transparent;
}

.selection-avatars>>>.el-input.is-focus .el-input__inner {
    border: transparent;
    background-color: transparent;
}

.selection-avatars>>>.el-select__caret {
    color: transparent !important;
}

.el-checkbox {
    width: 180px;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;
}

.popover-avatar {
    border: transparent !important;
}

.el-popper[x-placement^=bottom] {
    margin-top: 12px;
    border: transparent !important;
}

.el-checkbox>>>.el-checkbox__label {
    padding-left: 0;
}
.search-bar ::v-deep.el-input__inner{
  background-color: #e8edf2;
  border: none;
  margin-left: 5px;
  margin-top: 3px;
  border-radius: 0;
  width: 195px;
}
.avatar-name {
    position: relative;
    top: -3px;
    left: 6px;
    color:#222;
    width: 135px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.el-checkbox>>>.el-checkbox__inner {
    display: inline-block;
    position: relative;
    border: 1px solid #777777a6;
    border-radius: 2px;
    box-sizing: border-box;
    width: 14px;
    height: 14px;
    background-color: #FFFFFF;
    z-index: 1;
    transition: border-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46), background-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46);
}
</style>