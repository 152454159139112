import axios from "axios";

export default {
    GENERATE_CONTRACT(postdata){
        return axios.post(`api/contracts/generate_contract/`,postdata )
    },
    FETCH_CONTRACTS_DETAILS(params) {
        return axios.get(`api/contracts/?${params}`)
    },
    DOWNLOAD_CONTRACT(id){
        return axios.get(`api/contracts/${id}/download/`)
    },
    GET_ALL_VIP_USERS(params){
        return axios.get(`api/users/vip_users/?${params}`)
    },
    UPDATE_CONTRACT(contractId,patchData){
        return axios.patch(`api/contracts/${contractId}/`,patchData)
    },
    GET_CONTRACT_PAYMENTS_LIST(contractId){
        return axios.get(`api/paymentlinks/?contract_id=${contractId}` );
    },
    GENERATE_CONTRACT_PAYMENT_LINK(postData) {
        return axios.post(`api/paymentlinks/generate_payment_link/`,postData );
    },
    CREATE_CONTRACT_PREVIEW(payload,params){
        return axios.post(`api/contracts/preview/?${params}`,payload );
    },
    GENERATE_SEND_CONTRACT(payload){
        return axios.post(`api/contracts/send_contract/`,payload );
    },
}