export function decodeHtml(input) {
    var doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
}

// doesnt remove em and strong tags while decoding html
export function decodeHtml2(input) {
    // Mapping of HTML entities to their corresponding characters
    const entityMap = {
        '&amp;': '&',
        '&lt;': '<',
        '&gt;': '>',
        '&nbsp;': ' ',
        // Add more entities if needed
    };

    // Function to replace entities with their corresponding characters
    const decodeEntities = (str) => {
        return str.replace(/&[a-zA-Z0-9#]+;/g, (entity) => {
            return entityMap[entity] || entity;
        });
    };

    // Preserve <strong> and <em> tags
    const preserveTags = (str) => {
        return str.replace(/&lt;(\/?strong|\/?em)&gt;/g, '<$1>');
    };

    // Decode entities and preserve specific tags
    let decodedContent = decodeEntities(input);
    decodedContent = preserveTags(decodedContent);

    return decodedContent;
}
