<!-- Rules:  
1.if want to add the different data types or even if u want to display different data it should be before the given indication down below.
2.If want toi add after data just do after the given indication and color is controlled be the getCellColor and should be passed from parent.
-->

<template>
  <div class="table-view" :id="showBorderRadius?'arkaTable':'table'" v-loading="tableData ? false : true">
    <div v-if="showSearch">
      
      <searchBar
        @search="handleInputChange"
        :style="{ width: searchHeight, margin: '1rem' }"
      ></searchBar>
    </div>
      <div class="buttonstyles" v-if="showButton">
        <el-button @click="createCRM(buttonTitle)" type="primary" >{{ buttonTitle }}</el-button>
      </div>
    <el-table
      @row-click="handleRowClick"
      ref="table"
      :show-header="showHeader"
      class="table-container"
      :data="tableData"
      :style="getTableStyles()"
      v-infinite-scroll="loadMore"
      infinite-scroll-disabled="busy"
      infinite-scroll-distance="10"
      :border="showBorder"
      :highlight-current-row="false"
      :cell-style="{
        padding: '0',
        height: cellHeight,
        paddingLeft: paddingLeft,
      }"
      :header-cell-style="{
        paddingLeft: paddingLeft,
        color: color,
        fontWeight: weight,
        fontSize: size,
      }"
    >
      <el-table-column
        v-for="column in tableColumns"
        :key="column.id"
        v-bind:prop="column.id"
        v-bind:label="column.title"
        :min-width="column.width"
        :align="column.align ? column.align : 'left'"
        :resizable="false"
      >
        <template v-slot:header v-if="showHeaderSortable">
          <span class="headerButton" v-if="column.isSortable">
            <span :style="handleTableHeader(column)"> {{ column.title }}</span>
            <i
              class="el-icon-top"
              v-if="!column.sorted"
              v-on:click="handleSort(column, 'desc')"
              style="line-height: 1.1; margin-left: 3px; margin-top: 5px"
            ></i>
            <i
              class="el-icon-bottom"
              v-else
              v-on:click="handleSort(column, 'asc')"
              style="line-height: 1.1; margin-left: 3px; margin-top: 5px"
            ></i>
            <!-- Replace with your desired icon -->
          </span>

          <span v-if="column.headerTask">
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
              "
            >
              {{ column.title }}
              <span
                style="
                  font-weight: 500;
                  background: #e8edf2;
                  color: #777;
                  /* border-radius: 4px; */
                  padding: 5px;
                "
                >{{ taskCount }} {{ spellTask(taskCount) }}</span
              >
            </div>
          </span>
        </template>
        <template scope="scope">
          <div
            :style="getCellStyles(scope.row, column)"
            :class="{
              place: true,
              'green-tick': scope.row[column.id] === '\u2713',
            }"
          >
            <span v-if="column.type === 'tag'">
              <slot :scope="{ scope, column }" name="tags"></slot>
            </span>

            <span v-if="column.type === 'shiftTags'">
              <slot :scope="{ scope, column }" name="shiftTags"></slot>
            </span>

            <span
              v-if="column.type === 'avatar'"
              style="display: flex; align-items: center"
            >
              <el-avatar
                :size="30"
                style="
                  margin-right: 1rem;
                  min-width: 30px;
                  background-color: red;
                "
                >{{ renderName(scope.row.fullName) }}</el-avatar
              >
              {{ scope.row.fullName }}
            </span>
            <span style="margin:auto;padding-top:5px;" v-if="column.type=='check'">
              <el-checkbox @click.native.stop="handleCheckBox(scope.row)" v-model="scope.row.checkBox"></el-checkbox>
            </span>
            <span :style="{ height: cellHeight }" style="font-size:large; width:100px; cursor:default;height:100%; display:flex; align-items:center;" @click.stop v-if="column.type=='actionDelete'">
              <div style="display:flex;width:100%;">
                <div style="cursor:pointer; margin-right: 30px;" @click.stop="editCRM(scope.row)">
                  <b-icon style="color:#777777;" icon="pencil"></b-icon>
                </div>
                <div style="cursor:pointer;" @click.stop="deleteCRM(scope.row)">
                  <b-icon  style="color:#777777; " icon="trash"></b-icon>
                </div>
            </div>
            </span>
            <span style="display:flex;align-items: center;" @click.stop v-if="column.type=='arkaName'">
              <div>
                <img style="width:50px;height: 50px;border-radius: 5px; margin-right: 15px;" :src="scope.row.line_items[0].product_sku_details.image" alt="">
              </div>
              <div style="color: #222;">
                <p >{{ scope.row.identifier }} <span v-if="scope.row.quantity>0" style="background-color: #e8edf2; border-radius:50%;margin-left: 5px; padding:5px 6px;">+{{ scope.row.quantity }}</span> </p>
                <p style="color:#777">{{scope.row.dateTime}}</p>
              </div>
            </span>
            <span style="display:flex;align-items: center;" @click.stop v-if="column.type=='catalogName'">
              <div  style="width:50px;height: 50px; margin-right: 15px;">
                <img  style="width:50px;height: 50px;border-radius: 5px;" :src="scope.row.image" alt="">
              </div>
              <div style="color: #222;">
                <p >{{ scope.row.name }} <span v-if="scope.row.quantity>0" style="background-color: #e8edf2; border-radius:50%;margin-left: 5px; padding:5px 6px;">+{{ scope.row.quantity }}</span> </p>
                <p style="color:#777">{{scope.row.description}}</p>
              </div>
            </span>
            <span style="display:flex;align-items: center;" @click.stop v-if="column.type=='catalogActions'">
              <div style="display:flex;width:100%;" >
                <!-- <el-tooltip content="Archive" placement="top">
                  <el-button style="border: none;display: flex;"   plain>
                    <p style="padding: 10px;font-size: 20px;"><b-icon  icon="archive"></b-icon></p>
                    
                  </el-button>
                </el-tooltip> -->

                <el-tooltip content="Edit" placement="top">
                  <el-button  style="padding:8px; display: flex; align-items: center; border-radius: 4px;border-color: #777;" @click.stop="editBundle(scope.row)">
                   <p ><b-icon style="transform:scale(1.3);color: #777;" icon="pencil"></b-icon></p> 
                  </el-button>
                </el-tooltip>
            </div>
            </span>
            <span v-if="column.type === 'paymentStatus' &&scope.row.payment">
              <el-tag v-if="scope.row?.payment?.status==='COMPLETED'"
            style="color: white;background-color: #5FCC29; border: 1px solid transparent;border-radius:50px "
                  effect="dark"
                  >
                  {{ 'Received'}}
                </el-tag>
                <el-tag v-if="scope.row.payment?.status==='PENDING'&&scope.row.linkStatus"
                  style="color: white;background-color: #FF4340; border: 1px solid transparent;border-radius:50px "
                  >
                  {{ 'Resend' }}
                 
                </el-tag>
                <el-tag v-if="scope.row.payment?.status==='PENDING'&&!scope.row.linkStatus"
                  style="color: white;background-color: #D5D920; border: 1px solid transparent;border-radius:50px "
                  >
                  {{ scope.row.payment.status.charAt(0).toUpperCase() + scope.row.payment.status.slice(1).toLowerCase() }}
                 
                </el-tag>
                <el-tag v-if="!scope.row.payment.id"
                  style="color: white;background-color: #CCC; border: 1px solid transparent;border-radius:50px "
                  >
                  {{ "Financed" }}
                 
                </el-tag>

            </span>
            <span :style="{ height: cellHeight }" style="font-size:large; width:100px; cursor:default;height:100%; display:flex; align-items:center;" @click.stop v-if="column.type=='contractAction'">
        <div style="display:flex;width:100%;gap:16px" v-if="scope.row?.is_rejected">
          <el-tooltip :content="scope.row?.mode_of_payment=='finance'?'Finance' :'Payment'" placement="top">
                  <el-button :disabled="scope.row?.mode_of_payment=='finance'"   style=" display: flex; align-items: center;  padding:8px; border-radius: 4px;" type="primary" class="white-background" plain @click.stop="payment(scope.row)">
                    <b-icon  icon="currency-dollar" class="action-icon"></b-icon>
                  </el-button>
                </el-tooltip>
          <el-tag 
   
    effect="dark"
    style="color: white;background-color: #FF4340; border: 1px solid transparent;border-radius:50px "
    >
    {{scope.row.lead_details? 'Rejected':'Deleted' }}
  </el-tag>
        </div>
        <div style="display:flex;width:100%;gap:16px" v-else-if="scope.row?.is_accepted">
          
                <el-tooltip :content="scope.row?.mode_of_payment=='finance'?'Finance' :'Payment'" placement="top">
                  <el-button :disabled="scope.row?.mode_of_payment=='finance'"   style=" display: flex; align-items: center;  padding:8px; border-radius: 4px;" type="primary" class="white-background" plain @click.stop="payment(scope.row)">
                    <b-icon  icon="currency-dollar" class="action-icon"></b-icon>
                  </el-button>
                </el-tooltip>

                <el-tag 
                style="color: white;background-color: #5FCC29; border: 1px solid transparent;border-radius:50px "
                  effect="dark">
                  {{ 'Accepted' }}
                </el-tag>
            </div>
              <div style="display:flex;width:100%;gap:16px" v-else>
         
                <el-tooltip :content="scope.row?.mode_of_payment=='finance'?'Finance' :'Payment'" placement="top">
                  <el-button :disabled="scope.row?.mode_of_payment=='finance'"  style="margin:0; display: flex; align-items: center;  padding:8px; border-radius: 4px;" type="primary" class="white-background" plain @click.stop="payment(scope.row)">
                    <b-icon  icon="currency-dollar" class="action-icon"></b-icon>
                  </el-button>
                </el-tooltip>

                <el-tooltip  content="Accept" placement="top">
                  <el-button 
                  :disabled=" scope.row.status =='Accepted' || scope.row.status =='Rejected' " 
                  :class="{ 'disabled-button':  scope.row.status === 'Accepted' || scope.row.status === 'Rejected' }"
                  style=" margin:0;  padding:8px; display: flex; align-items: center; border-radius: 4px;" class="white-background" type="success"  plain @click.stop="acceptContract(scope.row)">
                    <b-icon  icon="check2" class="action-icon"></b-icon>
                  </el-button>
                </el-tooltip>

                <el-tooltip content="Reject" placement="top">
                  <el-button style="margin:0;padding:8px; display: flex; align-items: center; border-radius: 4px;"  class="reject-button"  @click.stop="reject(scope.row)">
                    <b-icon class="action-icon" icon="x"></b-icon>
                  </el-button>
                </el-tooltip>
            </div>
            </span>
            <span @click.stop v-if="column.type=='contractStatus'">
              <el-tag v-if="!scope.row.document_details"
                  style="color: white;background-color: #FF4340; border: 1px solid transparent;border-radius: 50px;"
                  >
                  {{ "Deleted" }}
                </el-tag>
                <el-tag v-else-if="scope.row.is_accepted"
                  style="color: white;background-color: #5FCC29; border: 1px solid transparent;border-radius: 50px;"
                  >
                  {{ "Accepted" }}
                </el-tag>
                <el-tag v-else-if="scope.row.is_rejected"
                  style="color: white;background-color: #FF4340; border: 1px solid transparent;border-radius: 50px;"
                  >
                  {{ "Rejected" }}
                </el-tag>

        
         <el-tag v-else
                  style="color: white;background-color: #D5D920; border: 1px solid transparent;border-radius: 50px;"
                  >
                  {{ "Pending" }}
                </el-tag>
            </span>
            
            <span @click.stop style="color: #222;" v-if="column.type=='contract_id'">
              <p >{{ scope.row.identifier }}</p>
              <p style="color:#777">{{dateFormat(scope.row.created_at)}}</p>
            </span>
            <span @click.stop v-if="column.type=='userData'">
              <p v-if="scope.row?.created_by_details||scope.row.submitted_by_details">{{ scope.row.submitted_by_details?.first_name|| scope.row?.created_by_details?.first_name}} {{ scope.row.submitted_by_details?.last_name|| scope.row?.created_by_details?.last_name }}</p>
              <p v-else>_</p>
            </span>
            <span @click.stop v-if="column.type=='documents' ">
              <p v-if="scope.row.document_details?.display_name" @click.stop="downloadDocument(scope.row)" style="display:flex;font-weight: 500;cursor: pointer; align-items: center;">
                <img class="pdf-icon" src="../../../assets/img/pdfIcon.svg" alt="">
                <span style="color: #1C3366;"> View Details</span></p>
                <p v-else>_</p>
            </span>
            <span :style="{ height: cellHeight }" style="font-size:large; width:100px; cursor:default;height:100%; display:flex; align-items:center;" @click.stop v-if="column.type=='ordersAction'">
            
              <div style="display:flex;width:100%;" v-if="scope.row.status==='Accepted'||scope.row.status==='Rejected' || scope.row.status==='Cancelled'">
                <el-tooltip content="View" placement="top">
                  <el-button style="margin-right: 10px; display: flex; align-items: center; padding:8px; border-radius: 4px;" type="primary" class="white-background" plain @click.stop="view(scope.row)">
                    <b-icon icon="eye"></b-icon>
                  </el-button>
                </el-tooltip>
                <el-tooltip v-if="isSOandProjectmanager" content="Payment" placement="top">
                  <el-button 
                  style=" margin-right: 10px;  padding:8px; display: flex; align-items: center; border-radius: 4px;" class="white-background" type="primary"  plain @click.stop="installments(scope.row)">
                    <b-icon  icon="currency-dollar"></b-icon>
                  </el-button>
                </el-tooltip>
<span v-if="!organisationInfo?.vip_for_power_gazebo&&role !== 'ARKA_INTERNAL_SALES'">
                <el-tag v-if="scope.row.status==='Accepted'"
                style="color: white;background-color: #5FCC29; border: 1px solid transparent;border-radius:50px "
                  effect="dark">
                  {{ scope.row.status }}
                </el-tag>
                <el-tag v-if="scope.row.status==='Cancelled'"
                  style="color: white;background-color: #CCC; border: 1px solid transparent;"
                  >
                  {{ scope.row.status }}
                </el-tag>
                <el-tag v-if="scope.row.status==='Rejected'"
                style="color: white;background-color: #FF4340; border: 1px solid transparent;border-radius:50px "
                  effect="dark">
                  {{ scope.row.status }}
                </el-tag>
              </span>
              </div>
              <div style="display:flex;width:100%;" v-else>
                <el-tooltip content="View" placement="top">
                  <el-button style="  display: flex; align-items: center; padding:8px; border-radius: 4px;" type="primary" class="white-background" plain @click.stop="view(scope.row)">
                    <b-icon  icon="eye"></b-icon>
                  </el-button>
                </el-tooltip>

                <el-tooltip v-if="isSOandProjectmanager" content="Payment" placement="top">
                  <el-button 
                  style=" margin-right: 10px;  padding:8px; display: flex; align-items: center; border-radius: 4px;" class="white-background" type="primary"  plain @click.stop="installments(scope.row)">
                    <b-icon  icon="currency-dollar"></b-icon>
                  </el-button>
                </el-tooltip>
           <div v-if="isSOandProjectmanager">
          
     
      <el-tooltip v-if="column.show !=='crossIcon'&&(scope.row.status==='Invoiced'||scope.row.status==='Accepted')" content="Accept" placement="top">
                  <el-button 
                  :disabled=" scope.row.status =='Accepted' || scope.row.status =='Rejected' " 
                  :class="{ 'disabled-button':  scope.row.status === 'Accepted' || scope.row.status === 'Rejected' }"
                  style=" margin-right: 10px;  padding:8px; display: flex; align-items: center; border-radius: 4px;" class="white-background" type="success"  plain @click.stop="accept(scope.row)">
                    <b-icon  icon="check2"></b-icon>
                  </el-button>
                </el-tooltip>
                <el-tooltip v-else content="Invoice" placement="top">
                  <el-button 
                  :disabled="scope.row.status =='Invoiced' || scope.row.status =='Accepted' || scope.row.status =='Rejected' " 
                  :class="{ 'disabled-button': scope.row.status === 'Invoiced' || scope.row.status === 'Accepted' || scope.row.status === 'Rejected' }"
                  style="margin-right: 10px; padding:8px; display: flex; align-items: center; border-radius: 4px;" class="white-background disabled" type="success"  plain @click.stop="invoice(scope.row)">
                    <b-icon  icon="receipt-cutoff"></b-icon>
                  </el-button>
                </el-tooltip>
              </div>
                <el-tooltip v-if="isSOandProjectmanager"  :content="isSOandProjectmanager ?'Reject' :'Cancel'" placement="top">
                  <el-button 
                  :disabled=" scope.row.status =='Accepted' || scope.row.status =='Rejected' " 
                  :class="{ 'disabled-button':  scope.row.status === 'Accepted' || scope.row.status === 'Rejected' }"
                  style="padding:8px; display: flex; align-items: center; border-radius: 4px;" class="reject-button" @click.stop="reject(scope.row)">
                    <b-icon style="transform:scale(1.3);" icon="x"></b-icon>
                  </el-button>
                </el-tooltip>
             
              </div>
            </span>
            <span @click.stop v-if="column.type==='text'">
             <p>{{ scope.row[column.id] }}</p>
           </span>
            <span @click.stop v-if="column.type=='leadData'">
             
              <p v-if="isSOandProjectmanager&&column.isArkaOrder" >{{ scope.row?.lead_details?.name||"_" }}</p>

              <p v-else style="color: #1C3366; font-weight: 600; cursor: pointer;" @click.stop="leadData(scope.row.lead_details)">{{ scope.row?.lead_details?.name||'_' }}</p>
            </span>
            <span @click.stop v-if="column.type=='status'">
              <p :style="{color:renderColor(scope.row.status)}">{{ scope.row.status }}</p>
            </span>
            <span style="width:100%; text-align:right; color: #222;" @click.stop v-if="column.type=='amount'||column.type=='price'">
              <p>{{ scope.row[column.type] }}</p>
            </span>
            <span class="crmElipsis" v-if="column.type === 'vip'"> 
              <p style="display: contents;color: #222;" >{{scope.row[column.id]}}</p>
            </span>
            <span v-if="column.type ==='edit_actions'">
              <slot :data="{ scope, column }" name="edit_actions"></slot>
            </span>
            <span v-if="column.type === 'onlineStatus'">
              <div class="actionClass">
                <el-badge
                  is-dot
                  class="item"
                  :type="getStatus(scope.row, column)"
                  style="margin-top: 12px"
                ></el-badge>
                <span
                  v-if="scope.row.isOnline === true"
                  style="margin-left: 10px; color: green"
                  >Online</span
                >
                <span v-else style="margin-left: 10px; color: grey"
                  >Offline</span
                >
              </div>
            </span>

            <span v-if="column.type === 'dropdown'">
              <slot :scope="{ scope, column }" name="dropdown"></slot>
            </span>

            <span v-if="column.type === 'priority'">
              <div
                v-if="!scope.row.flag && scope.row.priority === 'default'"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <div>
                  <img
                    src="./assets/Priority.svg"
                    alt=""
                    @click.stop="handleFlagShow(scope.row, 'high')"
                  />
                </div>

                <span
                  style="
                    margin-left: 5px;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 16px;
                    margin-bottom: 10px;
                    color: #999999;
                    margin-top: 7px;
                  "
                  >Default</span
                >
              </div>
              <div
                v-else
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <div>
                  <img
                    src="./assets/FlagDisabled.svg"
                    alt=""
                    v-if="scope.row.status === 'Completed'"
                    @click.stop="handleFlagShow(scope.row, 'default')"
                  />
                  <img
                    src="./assets/FlagFill.svg"
                    alt=""
                    v-else
                    @click.stop="handleFlagShow(scope.row, 'default')"
                  />
                </div>
                <div>
                  <span
                    style="
                      margin-left: 5px;
                      font-weight: 500;
                      margin-bottom: 10px;
                      font-size: 15px;
                      line-height: 16px;
                      color: #999999;
                      margin-top: 7px;
                    "
                    v-if="scope.row.status === 'Completed'"
                    >Urgent</span
                  >
                  <span
                    style="
                      margin-left: 5px;
                      font-weight: 500;
                      margin-bottom: 10px;
                      font-size: 15px;
                      line-height: 16px;
                      color: red;
                      margin-top: 7px;
                    "
                    v-else
                    >Urgent</span
                  >
                </div>
              </div>
            </span>

            <span v-if="column.type === 'location'">
              <p @click.stop="openMap(scope.row[column.id])">
                {{ scope.row[column.id] }}
              </p>
            </span>
            <span class="crmElipsis" v-if="column.type === 'elipsis'"> 
              <p style="display: contents;" >{{scope.row[column.id]}}</p>
            </span>
            <span v-if="column.type === 'taskName'" style="display: flex">
              <div class="mainContainer">
                <div class="left">
                  <div style="margin-top: 5px">
                    <img
                      src="./assets/checkCircle.svg"
                      alt=""
                      @click.stop="handleCheck(scope.row, 'completed')"
                      v-if="scope.row.status === 'New'"
                    />
                    <img
                      src="./assets/In_progress.svg"
                      alt=""
                      v-else-if="scope.row.status === 'In-Progress'"
                      @click.stop="handleCheck(scope.row, 'completed')"
                    />
                    <img
                      src="./assets/completeCheckBox.svg"
                      alt=""
                      v-else-if="scope.row.status === 'Completed'"
                      @click.stop="handleCheck(scope.row, 'new')"
                    />
                  </div>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="scope.row[column.id]"
                    placement="right"
                    :visible-arrow="false"
                  >
                    <div @click.stop>
                      <el-input
                        placeholder="Please Input"
                        @input="(value) => updateEditedName(value, scope.row)"
                        v-model="scope.row.name"
                        size="small"
                        style="width: 160px"
                        class="inputFieldContainer"
                        :maxlength="50"
                      >
                      </el-input>
                    </div>
                  </el-tooltip>
                </div>
              </div>
            </span>

            <span v-if="column.type === 'homeOwner'">
              <p @click.stop="isDrilldown ? '' : handleOpenLead(scope.row)">
                {{ scope.row[column.id] }}
              </p>
            </span>

            <span v-if="column.type === 'status_dropdown'">
              <div class="status_dropdown">
                <span>
                  <el-select
                    v-model="scope.row.status"
                    :key="scope.row.id"
                    :placeholder="scope.row.status"
                    style="border: none; background-color: #e8edf2"
                    @click.native="handleIntrupt"
                  >
                    <el-option
                      v-for="item in scope.row.statusOption"
                      :key="item.id"
                      :label="item.label"
                      :value="item.value"
                      @click.native="hideDropDown(scope.row, item.value)"
                    >
                    </el-option>
                  </el-select>
                </span>
              </div>
            </span>

            <span v-if="column.type === 'dateDueBy'" @click.stop>
              <el-date-picker
                v-model="scope.row.dueBy"
                type="date"
                :format="'MMM dd, yyyy'"
                placeholder="N/A"
                :timestamp = "isoTimeList"
                class="datePicker"
                :class="placeholderClass(scope.row, column)"
                @change="handleDatePicker($event, scope.row)"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </span>

            <span
              v-if="column.id === 'assignees'"
              style="display: flex; align-items: center"
            >
              <el-avatar :size="30" :style="getAvatarColor(scope.row)">{{
                renderName(scope.row[column.id])
              }}</el-avatar>
              <el-tooltip
                class="item"
                effect="dark"
                :content="scope.row[column.id]"
                placement="top-start"
              >
                <p
                  style="
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 80px;
                    margin-left: -10px;
                  "
                >
                  {{ scope.row[column.id] }}
                </p>
              </el-tooltip>
            </span>

            <!-- before this -->
            <span
              v-else-if="!column.type"
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              {{ scope.row[column.id]||"-" }}
            </span>
            <!-- after this -->
            <span v-if="column.id === tableColumns[0].id" class="actionClass">
              <slot :scope="{ scope, column }" name="actions"> </slot>
            </span>

            <span v-if="column.type === 'taskName'">
              <div class="right">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="Documents"
                  placement="top-start"
                >
                  <p
                    class="documents"
                    style="display: flex; align-items: center; margin-left: 5px"
                  >
                    <img
                      src="./assets/paperFaded.svg"
                      alt=""
                      style="height: 16px"
                      v-if="scope.row.status === 'Completed'"
                    />

                    <img
                      src="./assets/paperclip 1.svg"
                      alt=""
                      style="height: 16px"
                      v-else
                    />
                    <span style="width: 18px" :style="getStyles(scope.row)">{{
                      scope.row.documents_count
                    }}</span>
                  </p>
                </el-tooltip>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="Comments"
                  placement="top-start"
                >
                  <p class="chat" style="display: flex; align-items: center">
                    <img
                      src="./assets/chat.svg"
                      alt=""
                      :style="getStyles(scope.row)"
                      v-if="scope.row.status === 'Completed'"
                    />
                    <img
                      src="./assets/chat-left 1.svg"
                      alt=""
                      :style="getStyles(scope.row)"
                      v-else
                    />
                    <span :style="getStyles(scope.row)" class="comment">{{
                      scope.row.comments_count
                    }}</span>
                  </p>
                </el-tooltip>
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="scope.row.nudgeTooltipText"
                  placement="top-start"
                >
                  <p
                    class="bell"
                    style="display: flex; align-items: center; margin-left: 5px"
                  >
                    <img
                      v-if="scope.row.status === 'Completed'"
                      src="./assets/bell_iconSuccess.svg"
                      alt=""
                      :style="getStyles(scope.row)"
                      @click.stop
                      disabled
                    />
                    <img
                      v-else
                      src="./assets/bell_icon.svg"
                      alt=""
                      :style="getStyles(scope.row)"
                      @click.stop="nudgeNotification(scope.row)"
                    />
                  </p>
                </el-tooltip></div
            ></span>
           
          </div>
        </template>

       
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { generateInitials } from "../../../utils/initalsGenerator";
import infiniteScroll from "vue-infinite-scroll";
import searchBar from "./header/searchBar.vue";
import { formatDateTime } from "../../../utils/dateFormatter";
import API from "@/services/api/";
import { generateAvatarColor } from "../../../utils";

function synchroniseScrolling(scrollPos) {
  let tableHeader = document.querySelector('.el-table__header-wrapper')
  let leadHeaders = document.querySelectorAll('.el-table__body-wrapper')
  let allHeaders = [
    tableHeader,
    ...leadHeaders
  ]

  allHeaders.forEach(header => {
    if (!header) { return }

    header.scrollLeft = scrollPos
  })
}

export default {
  emits: [
    "editCRM",
    "view",   
    "installments",
    "downloadDocument",
    "assign", 
    "payment",
    "accept", 
    "invoice",
    "reject", 
    "acceptContract",  
    "editBundle",  
    "leadDrawer",  
    "deleteCRM",   
    "createCRM",   
    "updatePriority",  
    "sortTableApi",
    "updateDateValues",
    "updateStatus",
    "nudgeClicked",
    "loadLead",    
    "handleSearch",
    "updateValues",
  ],
  name: "ListViewTable",
  directives: {
    infiniteScroll,
  },
  components: {
    searchBar,
  },
  props: {
    paddingLeft: {
      type: String,
      default: "0px",
    },
    cellHeight: {
      type: String,
      default: "80px",
    },
    searchHeight: {
      type: String,
      default: "100%",
    },
    tableColumns: {
      required: true,
      type: Array,
    },
    tableData: {
      required: true,
      type: Array,
    },
    handleRowClick: {
      type: Function,
    },
    getCellStyles: {
      type: Function,
      default: () => {
        return { color: "black" };
      },
    },
    getHeaderStyles: {
      type: Function,
      default: () => {
        const center = "center";
        return center;
      },
    },
    showTableHeight: {
      type: Boolean,
      default: true,
    },
    showCursor:{
      type:Boolean,
      default: false,
    },
    showBorderRadius:{
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "#909399",
    },

    weight: {
      type: Number,
      default: 500,
    },

    size: {
      type: String,
      default: "14px",
    },
    loadMore: {
      type: Function,
      default: () => {
      },
    },
    showBorder: {
      type: Boolean,
      default: true,
    },
    loadMoreLeadData: {
      type: Function,
    },
    showSearch: {
      type: Boolean,
      default: false,
    },
    showButton:{
      type: Boolean,
      default: false,
    },
    buttonTitle:{
      type: String,
    },
    showHeaderSortable: {
      type: Boolean,
      default: false,
    },
    tableHeightOffset: {
      type: String,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    taskCount: {
      type: Number,
    },
    isDrilldown: {
      type: Boolean,
      default: false,
    },
    tablePadding:{
      type:String,
      default:'0px'
    },
  isLoading:{
    type:Boolean,
    default:false
  }
  },
  data() {
    return {
      value1: "",
      sortable: false,
      tablesData: 11,
      istTimestamp:"",
      currentPage: 1,
      pageSize: 10,
      totalDataCount: 0,
      loading: false,
      busy: false,
      data: [],
      selectedRow: "",
      scrollRefData: 0,
      tableHeightRef: 0,
      isChecked: false,
      showFlag: false,
      showInput: false,
      showTitle:this.$props.buttonTitle,
      dropdownShow: false,
      selectedValue: this.tableData?.status,
      value: "",
      intruptDropdownVisibility: false,
      
      pickerOptions: {
        disabledDate: (date) => {
          const today = new Date();
          today.setHours(0, 0, 0, 0);
          return date < today;
        },
      },
      curScrollPosition: 0,
      role: JSON.parse(localStorage.getItem("user")).role,
      isProjectManager: JSON.parse(localStorage.getItem("user")).is_project_manager,
      organisationInfo : JSON.parse(localStorage.getItem('organisation')) || {},
    };
  },

  computed: {
    isoTimeList() {
      const ISTOffset = 5.5 * 60 * 60 * 1000;
    return Date.now() + ISTOffset;
    },

    scrollRefDatas() {
      this.scrollRefData;
      return this.scrollRefData;
    },
    isSOandProjectmanager(){
            return this.role=="ARKA_SALES_OP"||this.isProjectManager
        }
  },
  mounted() {
   
    this.addSynchronisedScrolling()
  },

  watch:{
    showTitle:{
      immediate: true,
      handler(value) {
      }
    },
   
  },
  methods: {
    renderColor(val){
      let data=val.toLowerCase()
switch(data){
  case 'accepted':
return '#2CC21C';
case 'invoiced':
return '#409EFF';
case 'rejected':
return '#FF0000';
case 'cancelled':
return '#CCC';
  default:
    return 'black'
}

      // if(val.toLowerCase() !=='accepted'){
      //   return 'blue'
      // }else{
      //   return "green"
      // }
    },
    editCRM(val){
      this.$emit('editCRM',val)
    },
    view(val){
      this.$emit('view',val)
    },
    installments(val){
      this.$emit('installments',val)
    },
    downloadDocument(val){
      this.$emit('downloadDocument',val)
    },
    assign(val){
      this.$emit('assign',val)
    },
    payment(val){
this.$emit('payment',val)
    },
    accept(val){
      this.$emit('accept',val)
    },
    invoice(val){
      this.$emit('invoice',val)
    },
    reject(val){
      this.$emit('reject',val)
    },
    acceptContract(val){
      this.$emit('acceptContract',val)
    },
    editBundle(val){
this.$emit('editBundle',val)
    },
    leadData(val){
      this.$emit('leadDrawer',val.id)
    },
    deleteCRM(val){
      this.$emit('deleteCRM',val)
    },
    createCRM(val){
      this.$emit('createCRM',val)
    },
    dateFormat(date) {
      const user = JSON.parse(localStorage.getItem('user')) || {};
      const isUsUser = user.isUSFlagEnabled

      const dateTime = formatDateTime(date, "Date Dash", isUsUser) + ", " + formatDateTime(date, "Short Time", isUsUser)
      return dateTime
    },

    alignItems(column){
    },
    dateFormatter(dateInput) {
      const originalDate = new Date(dateInput);

      const utcDate = new Date(
        originalDate.getUTCFullYear(),
        originalDate.getUTCMonth(),
        originalDate.getUTCDate(),
        originalDate.getUTCHours(),
        originalDate.getUTCMinutes(),
        originalDate.getUTCSeconds()
      );

      const iso8601Format = utcDate.toISOString();

      return iso8601Format;
    },

  handleTableHeader(column){
   if(column.clicks > 0 && column.clicks < 3){
    return{
      color:"black"
    }
   }
  },

    placeholderClass(row, column) {
      const date = this.dateFormatter(row.dueBy);
      const inputDate = new Date(date.split("T")[0]);
      inputDate.setHours(0, 0, 0, 0);
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      if (inputDate < today && row.status !== "Completed") {
        return "red-placeholder";
      }
      if (row.status === "Completed") {
        return "gray-placeholder";
      }
      if (row.status !== "Completed") {
        return "default-placeholder";
      }
    },

    handleFlagShow(row, value) {
      row.priority = value;

      const data = {
        id: row.id,
        priority: value,
        lead: row.lead,
      };

      this.$emit("updatePriority", data);
      row.flag = !row.flag;
    },
    handleSort(column, type) {
      this.tableColumns.map(item =>{
        if(item.id !== column.id){
          item.clicks=0
          item.sorted=false
        }
      })
     if(column.clicks == 3){
      column.clicks = 0
     }


      if(column.clicks < 3){
        column.clicks +=1
      }else{
        column.clicks = 0
      }

      if(column.clicks <= 2 && column.sorted !== 3)
      column.sorted = !column.sorted;
    this.$emit("sortTableApi", [ type, column.id,column.clicks])
    },
    loadData() {
      this.tablesData += this.tablesData + 10;
    },

    showInputData(row) {
      row.showInput = true;
    },

    handleDatePicker(e, row) {
      const data = {
        id: row.id,
        due_by: e.toISOString(),
        lead: row.lead,
      };

      this.$emit("updateDateValues", data);
      this.handleDate(row);
    },

    handleShowInput(row) {
      row.showInput = false;
    },

    getTableStyles() {
      if (this.$props.showTableHeight === true) {
        return {
          cursor: this.$props.showCursor? 'default': "pointer",
          minWidth: "100%",
          maxHeight: `calc(100vh - ${this.$props.tableHeightOffset}) !important`,
          minHeight: `calc(100vh - ${this.$props.tableHeightOffset}) !important`,
          overflowY: "scroll",
          overflowX: "auto",
          background: "white",
          borderRadius:this.$props.showBorderRadius? "0px 10px 10px 10px" :'10px',
          border:this.$props.showBorderRadius?"1px solid transparent": "1px solid #ccc",
          padding:this.$props.tablePadding
        };
      } else {
        return {
          cursor: this.$props.showCursor? 'default': "pointer",
          minWidth: "100%",
          overflowY: "scroll",
          background: "white",
          borderRadius: "10px",
        };
      }
    },

    showDropdown(row) {
      !this.intruptDropdownVisibility
        ? (row.showDropdown = true)
        : (row.showDropdown = false);
    },

    handleIntrupt() {
      this.intruptDropdownVisibility = true;
    },

    handleHide(row) {
      this.intruptDropdownVisibility
        ? (row.showDropdown = true)
        : (row.showDropdown = false);
    },

    openMap(address) {
      window.open(`http://maps.google.com/?q=${address}`);
    },
    spellTask(count) {
      if (count === 1) {
        return "Task";
      } else {
        return "Tasks";
      }
    },

    hideDropDown(row, value) {
      if (value === "completed") {
        row.status = "Completed";
      }

      if (value === "in_progress") {
        row.status = "In-Progress";
      }
      const data = {
        id: row.id,
        status: value,
        lead: row.lead,
      };

      this.$emit("updateStatus", data);
    },

    getAvatarColor(row) {
      return {
        marginRight: "1rem",
        minWidth: "30px",
        backgroundColor: generateAvatarColor(row.assigned_to, null),
      };
    },

    async nudgeNotification(task) {
      const { id } = task;
      if (task.nudgeTooltipText !== "Nudge") return;

      const currentDate = new Date();
      this.presentDate = {
        sent_at: currentDate.toISOString(),
      };

      try {
        let response = await API.TASKS.POST_NUDGE_NOTIFICATION(
          id,
          this.presentDate
        );
        this.$emit("nudgeClicked", task);

        this.$message({
          showClose: true,
          message: "Nudge Notification has been sent successfully.",
          type: "success",
          center: true,
        });
      } catch (err) {
        this.$emit(
          "nudgeClicked",
          parseInt(err.response.data.available_in.match(/\d+/g).join(""))
        );
        this.$message({
          showClose: true,
          message: `Too many request. Try again in ${err.response.data.available_in}.`,
          type: "error",
          center: true,
        });
      }
    },

    handleOpenLead(row) {
      const { id } = row.lead_details;
      this.$emit("loadLead", id);
    },

    handleCheck(row, value) {
      if (value === "completed") {
        row.status = "Completed";
      }

      if (value === "in_progress") {
        row.status = "In-Progress";
      }

      if (value === "new") {
        row.status = "New";
      }

      const data = {
        id: row.id,
        status: value,
        lead: row.lead,
      };

      this.$emit("updateStatus", data);
    },
    handleInputChange(data) {
      this.$emit("handleSearch", data);
    },

    getStyles(row) {
      if (row.status === "Completed") {
        return {
          color: " #999999",
          marginLeft: "4px",
        };
      } else {
        return {
          marginLeft: "4px",
        };
      }
    },

    handleDate(row) {
      row.showDatePicker = !row.showDatePicker;
    },

    updateEditedName(values, row) {
      const { id } = row;

      const value = {
        id: id,
        name: values,
        lead: row.lead,
      };
      this.$emit("updateValues", value);
    },

    getStatus(row, column) {
      if (row.isOnline === true && column.id === "isOnline") {
        return "success";
      }
      return "info";
    },

    renderName(name) {
      return generateInitials(name);
    },
    
    addSynchronisedScrolling() {
      let elem = this.$refs.table.$el.querySelector('.el-table__body-wrapper')
      elem.addEventListener('scroll', this.onScrollTable)
    },

    onScrollTable(e) {
      this.curScrollPosition = e.target.scrollLeft
      synchroniseScrolling(this.curScrollPosition)
    },

  },
  beforeDestroy() {
    // Remove scroll event listeners
    let elem = this.$refs.table.$el.querySelector('.el-table__body-wrapper')
    elem.removeEventListener('scroll', this.onScrollTable)
  },
};
</script>

<style scoped>


.table-view {
  width: 100%;
}

#table >>> .el-table .success-row {
  color: red;
}
#arkaTable>>> .el-table .success-row {
  color: red;
}
.color {
  background-color: red;
}

.sortable tr {
  cursor: pointer;
}

.place {
  text-align: left;
  display: flex;
  justify-content: space-between;
}

.inputFieldContainer:hover {
  border: 1px solid rgb(168, 168, 168);
  border-radius: 5px;
  background-color: white;
}
#table >>> .el-table__body tr.current-row > td.el-table__cell {
  background-color: #e8edf2;
}

#table >>> .el-table td.el-table__cell,
.el-table--border th.el-table__cell {
  border-bottom: 1px solid #ccc;
}
#table >>> .el-table--border th.el-table__cell {
  border-bottom: 1px solid #ccc;
}
#table >>> .el-table--border .el-table__cell {
  border-right: 1px solid #ccc;
}

#arkaTable >>> .el-table__body tr.current-row > td.el-table__cell {
  background-color: #e8edf2;
}

#arkaTable >>> .el-table td.el-table__cell,
.el-table--border th.el-table__cell {
  border-bottom: 1px solid #ccc;
}
#arkaTable >>> .el-table--border th.el-table__cell {
  border-bottom: 1px solid #ccc;
}
#arkaTable >>> .el-table--border .el-table__cell {
  border-right: 1px solid transparent;
}
#arkaTable>>> .el-table--border th.el-table__cell.is-right {
    text-align: right;
    padding-right:30px
}
.button {
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.green-tick {
  color: rgb(8, 210, 8) !important;
  font-weight: 800;
}

.actionClass {
  display: flex;

  align-items: center;
  justify-content: center;
}
#table >>> .el-table .el-table__header-wrapper {
  position: sticky;
  top: 0;
  z-index: 1;
}
#table >>> .el-table::before,
.el-table--group::after,
.el-table--border::after {
  background-color: transparent;
}
#arkaTable >>> .el-table .el-table__header-wrapper {
  position: sticky;
  top: 0;
  z-index: 1;
}

#arkaTable >>>.el-table__empty-block {
    height: calc(-20rem + 90vh) !important;
    width: 100%;
  
}
#table >>>.el-table__empty-block {
    height: calc(-20rem + 90vh) !important;
  
}
#arkaTable >>> .el-table::before,
.el-table--group::after,
.el-table--border::after {
  background-color: transparent;
}
.el-table .el-table__row.is-current-row {
  background-color: yellow; /* Replace with your desired color */
}
.crmElipsis{
  white-space: pre-line;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Adjust the number of lines as needed */
  -webkit-box-orient: vertical;
  max-height: 28px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.headerButton {
  display: flex;
  align-items: center;
}

.mainContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  width: 100%;
}

.left {
  display: flex;
  align-items: center;
}

.right {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.textColor {
  display: flex;
  margin-left: 10px;
  color: #1c3366;
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  justify-content: left;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
}
.pdf-icon{
  font-size:20px !important; 
  margin-right: 4px;
}
.textColor:hover {
  border: 1px solid #bebfc0;
  padding: 5px;
  border-radius: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
}
.disabled-button {
  opacity: 0.3; /* Adjust the opacity to your liking */
  cursor: not-allowed;
}
.showInputField {
  outline: 1px solid rgb(179, 179, 179);
  margin-left: 10px;
  color: #1c3366;
  font-weight: 500;
  height: 100%;
  font-size: 16px;
  display: block;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 1px;
  padding-bottom: 1px;
  border-radius: 5px;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdown {
  height: 100%;
  width: 100%;

  padding-left: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 12px;
  background-color: white;
  font-weight: 400;
  font-size: 18px;
  border-radius: 5px;
}

.dropdownItemStyle {
  font-weight: 400;
  font-size: 18px;
}

.datePicker ::v-deep.el-input__prefix {
  display: none;
}
.white-background {
    background-color: #FFF;
}
.el-button--primary.is-plain {
    color: #409EFF;
    background: #fff;
    border-color: #409EFF;
}
.el-button--primary.is-plain.is-disabled {
    color: #ccc;
    background: #fff !important;
    border-color: #ccc
}
.reject-button:hover {
    background-color: #F00;
    color: #FFF;
    border-color: #F00;
}
.reject-button {
    color: #F00;
    border-color: #F00;
}
.el-button--success.is-plain {
    color: #2CC21C;
    background: #fff;
    border-color: #2CC21C;
}
.datePicker ::v-deep.el-input__inner {
  padding: 0;

  width: 55%;
  background: none !important ;
}
.buttonstyles{
  display: flex;
  justify-content: end;
  width: 100%;
  margin: 1rem 0rem;
  padding-right: 1rem;
}
.place >>>.el-checkbox__inner {
    display: inline-block;
    position: relative;
    border: 1px solid #1C3366;
    border-radius: 2px;
    box-sizing: border-box;
    width: 24px;
    height: 24px;
    background-color: #FFFFFF;
    z-index: 1;
    transition: border-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46), background-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46);
}
.place >>>.el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #1C3366;
    border-color: #1C3366;
}
.place >>>.el-checkbox__input.is-checked .el-checkbox__inner::after {
    transform: rotate(45deg) scaleY(1.4);
    margin-left: 4px;
    margin-top: 4px;
}
.datePicker ::v-deep.el-input__inner :hover {
  background-color: #e8edf2 !important ;
  border: none;
}

#table >>> .el-select .el-input.is-focus .el-input__inner {
  background-color: #e8edf2;
  color: black;
  border: none;
}
#arkaTable >>> .el-select .el-input.is-focus .el-input__inner {
  background-color: #e8edf2;
  color: black;
  border: none;
}
#arkaTable >>>.el-table--scrollable-x .el-table__body-wrapper {
    overflow-x: auto;
    overflow: visible;
}
.el-select-dropdown__item {
  color: black;
}

.status_dropdown >>> .el-input--suffix .el-input__inner {
  background-color: #e8edf2;
  color: black;
  border: none;
}

#table >>> .el-select .el-input .el-select__caret {
  color: black;
}

#table >>> .el-table .cell {
  white-space: normal;
  word-break: break-word;
  line-height: 23px;
}
#arkaTable >>> .el-select .el-input .el-select__caret {
  color: black;
}

#arkaTable >>> .el-table .cell {
  white-space: normal;
  word-break: break-word;
  line-height: 23px;
}

.blue-placeholder ::v-deep .el-input__inner {
  color: blue;
}

.red-placeholder ::v-deep .el-input__inner {
  color: red;
  background-color: white;
  border: none;
}

.default-placeholder ::v-deep .el-input__inner {
  color: black;
  border: none;
}

.gray-placeholder ::v-deep .el-input__inner {
  color: rgb(153, 153, 153);
  border: none;
}

#table >>> .el-tooltip__popper .popper__arrow {
  display: none;
}
#table >>> .el-input--small .el-input__inner {
  font-size: 16px;
  border: none;
  color: #1c3366;
  font-weight: 500;
  background-color: transparent;
  text-overflow: ellipsis;
}

#table >>> .el-table .cell {
  white-space: normal;
  word-break: break-word;
  line-height: 23px;
}
#arkaTable >>> .el-tooltip__popper .popper__arrow {
  display: none;
}
#arkaTable >>> .el-input--small .el-input__inner {
  font-size: 16px;
  border: none;
  color: #1c3366;
  font-weight: 500;
  background-color: transparent;
  text-overflow: ellipsis;
}

#arkaTable >>> .el-table .cell {
  white-space: normal;
  word-break: break-word;
  line-height: 23px;
}
.el-tag--dark.el-tag--danger{
  background-color: red;
  padding-left: 13px;
  padding-right: 13px;
}
@media (max-width: 1600px) {
  #arkaTable >>> .el-table .el-table__header-wrapper {
  position: sticky;
  top: 0;
  z-index: 1;
  overflow: visible !important;
}
}
#table >>> .el-table .el-table__header-wrapper {
  position: sticky;
  top: 0;
  z-index: 1;
  overflow: visible !important;
}
.action-icon{
  transform: scale(1.5)
}
</style>
