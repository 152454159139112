import { defineStore } from 'pinia'
import API from '../services/api';
import {
  initializeStore,
  fetchOrganisationDetails,
  deactivateNotifications
} from "../utils";
import { useCreditsStore } from './credits';
import {isCrmUser, getOrgSettings, isGoodleapConnected} from '../utils'

function clearImpLocalStorageInfoOnLogoutOrReset(){
    localStorage.removeItem('user');
    localStorage.removeItem('organisation');
    localStorage.removeItem('allServicesInfo');
    localStorage.removeItem('all_country_details');
    localStorage.removeItem('allocated-quota-details');
    localStorage.removeItem('all-available-pricing-plans');
    localStorage.removeItem('isDocuSignIntegrated');
    localStorage.removeItem('isGoodleapConnected');
}

export const useAuthStore = defineStore('auth', {
    state: () => {
        return {
            status: '',
            token: localStorage.getItem('token') || '',
            user: {},
        }
    },
    getters: {},
    actions: {
        async LOGIN(userCredentials) {
            this.status = 'loading';
            try {
                let response = await API.USERS.LOGIN(userCredentials);
                // for already login session of the users
                if (response.data.status === 302) {
                    return {
                        'status': response.data.status,
                        'message': response.data.message,
                        'token' : response.data.token
                    }
                }
                const user = response.data;
                if (!user.is_verified) {
                    return {
                        'status': response.data.status,
                        'is_verified': false,
                        'token': response.data.token,
                        'jwt_encoded_data': response.data.jwt_encoded_data
                    }
                }
                
                user.isUSFlagEnabled = user.country == 52;
                
                localStorage.setItem('user', JSON.stringify(user));
                this.status = 'success';
                this.token = user.token;
                this.user = user;

                // Initializing services
                API.SET_TOKEN_HEADER(user.token);
                let respo =  await API.USERS.FETCH_USER(user.user_id);            
                let updatedUser = { ...user, ...respo.data };
                localStorage.setItem('user', JSON.stringify(updatedUser));

                if(updatedUser?.role=='DESIGNER' ||updatedUser?.enable_designer_view ){
                    let ui = JSON.parse(localStorage.getItem("ui")) || {};
                    ui.leadManagement = ui.leadManagement || {};
                    ui.leadManagement.isTableView = 'true';
                    localStorage.setItem("ui", JSON.stringify(ui));
                  }

                initializeStore();
                await Promise.all([
                    fetchOrganisationDetails(),
                    this.assignAllServicesInfo(),
                ])

                if(isCrmUser()) {
                    getOrgSettings();
                    isGoodleapConnected();
                }
                return {
                    'status': response.data.status, 
                    'is_verified': true,
                    'token': response.data.token,
                    'jwt_encoded_data': response.data.jwt_encoded_data
                }
            }
            catch (e) {
                this.status = 'error';
                throw e;
            }
        },
        async  assignAllServicesInfo(){
            if(!JSON.parse(localStorage.getItem('allServicesInfo'))){
                try{
                    let response = await API.DASHBOARD_INFO.FETCH_ALL_SERVICES_INFO();
                    localStorage.setItem('allServicesInfo',JSON.stringify(response.data));
                }
                catch(e){
                    this.status = 'error';
                    throw e;
                }
              }
        },
        async LOGOUT() {
            this.AUTH_LOGOUT()
            clearImpLocalStorageInfoOnLogoutOrReset();
            useCreditsStore().UPDATE_CREDIT_BALANCE({ purchased_credits: 0, promotional_credits: 0 })
            deactivateNotifications()
        },
        
        async LOGOUTSESSION() {
            this.AUTH_LOGOUT()
            if (JSON.parse(localStorage.getItem('user'))) {
                await API.USERS.LOGOUTSESSION(JSON.parse(localStorage.getItem('user')));
            }
            clearImpLocalStorageInfoOnLogoutOrReset();
        },

        RESET() {
            this.AUTH_LOGOUT()
            clearImpLocalStorageInfoOnLogoutOrReset();
        },

        async PLAN_PAYMENT_LINK(selectedPlan) {
            try {
                const response = await API.USERS.PLAN_PAYMENT_LINK(selectedPlan);
                return response.data;
            }
            catch(e) {
                throw e;
            }
        },

        AUTH_LOGOUT() {
            this.status = '';
            this.token = '';
            this.user = {};
        },
    }
})
