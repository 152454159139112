
<div class="tasksContainerhi">
  <previousProposalRequests
    v-if="isPreviousRequestsPopupVisible"
    :isPreviousRequestsPopupVisible.sync="isPreviousRequestsPopupVisible"
    :currency_code="projectInformation.country_details.currency_code"
    :project_id="project_id"
  />
  <div class="addTaskBtn" @click="handleShowOrHideDrawer">
    <b-icon icon="plus-lg" style="color: white; font-size: 16px;"></b-icon>
  </div>
  <div class="history" v-if="forLeadSummary">
    <p class="historyHead" @click="isPreviousRequestsPopupVisible = true">
      Proposal History
    </p>
  </div>
  <div class="tasks" :style="{backgroundColor: 'white'}">
    <div class="task-header">
      <h3>{{ headName }}</h3>
      <span class="taskStatus" v-if="forLeadSummary"
        >{{ totalTasks }} tasks</span
      >
      <div class="header-btn">
        <el-button
          type="text"
          icon="el-icon-plus"
          @click="handleShowOrHideDrawer"
          style="font-size: 16px; font-weight: 600"
          >Add Task</el-button
        >
      </div>
    </div>
    <div
      v-if="tasks.length"
      :class="forLeadSummary ? 'borderContainer' : 'withoutBorder'"
    >
      <Task
        v-for="task in tasks"
        :countSize="countSize"
        :forLeadSummary="forLeadSummary"
        :info="task"
        :project_id="project_id"
        :key="task.id"
        @individualTaskUpdated="handleIndividualTaskUpdated"
        @onDeleteTask="onDeleteTask"
      />
      <ScrollObserver
        :showLoader="busy"
        @intersect="loadMoreTasks"
      />
    </div>
    <div v-loading="isTaskLoading" class="no-tasks-container" v-else>
      No tasks here.
    </div>
  </div>
  <!-- <all-drawer
    :drawer="detailsDrawer"
    @save="detailsDrawer = false"
    :componentName="componentName"
    :drawerSize="500"
    @close="detailsDrawer = false"
    :project_id="project_id"
    :leadId="leadId"
    :forAddTask="forAddTask"
  /> -->

  <GlobalDrawer
    :isOpen="showCreateDrawer"
    title="Create Task"
    :handleCloseDialog="handleShowOrHideDrawer"
    :drawer-size="750"
    :drawerDirection="drawerDirection"
    class="task-drawer-dialog"
  >
    <!-- <template v-if="taskMode === 'view'" v-slot:header>
              <button>Mark Complete</button>
            </template> -->
    <template v-if="showCreateDrawer" v-slot:body>
      <TaskDrawerDynamic
        :reminderData.sync="reminderDetails"
        :mode="taskMode"
        :taskData="taskValue"
        @createTask="createTask"
        @deleteTask="onDeleteTask"
        @createTaskClicked="createTaskClicked"
        :isCreateLoading="isCreateLoading"
        @updateTask="updateTask"
        :isLeadSummary="isLeadSummary"
        :showCloseDialog="showCloseDialog"
        @setUnsavedChanges="setUnsavedChanges"
        @closeDrawer="closeDrawer"
        @handleCloseWarningDialog="showCloseDialog = false"
      />
      <!-- @openSetReminder="handleOpenSetReminder" -->
    </template>
  </GlobalDrawer>
  <setReminder
    :key="setKey"
    :showCreateDrawer="showSetReminderDrawer"
    @close="showSetReminderDrawer = false"
    @save="onSave"
    :drawerSize="400"
    :page="'task'"
  />
</div>
