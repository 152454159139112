<template>
  <div class="card-header" >
    <el-tooltip placement="bottom" :hide-after="0" :content="heading">
      <div class="name-content">
        {{ heading }}
      </div>
    </el-tooltip>
    <div v-if="editMode" style="cursor: pointer">
      <CommonPopover @open-options="handleOpenMode" />
    </div>
    <slot></slot>
  </div>
</template>

<script>
import CommonPopover from "../../dashboardV2/commonPopover.vue";

export default {
  emits: ["mode-open" ],
  name: "CardHeader",
  props: {
    heading: {
      type: String,
      default: "",
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleOpenMode(value) {
      this.$emit("mode-open", value);
    },
  },
  components: { CommonPopover },
};
</script>

<style scoped>
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /*/* font-family: "Switzer";*/
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.5rem;
  border-bottom: 1px solid #cccccc;
  border-radius: 8px 8px 0px 0px;
  color: #222222;
  padding: 16px;
  /*height: 3.875rem;*/
  height: 3.5rem;
  text-transform: capitalize;
}
h3 {
  font-style: none;
}
.name-content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
