import { defineStore } from 'pinia';

export const useDesignOrdersStore = defineStore('designOrdersStore', {
    state : () => ({
        assignedUser: {},
        designOrder:null,
    }),
    getters: {
        GET_ASSIGNED_USER: state => state.assignedUser,
        GET_DESIGN_ORDER:state=>state.designOrder,
    },
    actions:{
        SET_ASSIGNED_USER(data){
            this.assignedUser = data;
        },
        SET_DESIGN_ORDER(data){
            this.designOrder=data;
        }
    }
})