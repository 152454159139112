
  <div :style="{width: errorMessage=='Design'? '100%' :'', height:errorMessage=='Design'? '100%' :'',display: errorMessage=='Design'? 'flex' :''}" class="container" >
    <div v-if="orderData||errorMessage">
    <div style="display: flex;margin: auto; flex-direction: column; align-items: center;" v-if="errorMessage">

      <h3 v-if="errorMessage == 'Design'" style="margin-bottom: 10px;">The Design API has failed.</h3>
      <div v-else>
        <h3 class="error-heading">
          An unexpected error occurred. There might be more details below.
        </h3>
        <div class="error-text">
          {{ errorText }}
        </div>
      </div>
      <el-button v-if="errorMessage == 'Design'"  @click="sendErrorEmail">Send Error Report</el-button>
    </div>
    <div v-else >
    <div>
      <el-row class="heading">
        <el-col :span="8" class="title"> Order Details</el-col>
        <el-col v-if="!loader" :span="16" class="btn-container">
          <div
            v-if="
              orderData?.add_ons_availed &&
              orderData.add_ons_availed?.length > 0
            "
          >
            <el-tag
              style="margin-right: 0.5rem"
              v-for="(addons, inx) in orderData.add_ons_availed"
              :key="inx"
              class="tag"
            >
              {{ addons }}
            </el-tag>
          </div>
          <!-- <div>
            <el-button @click="handleWebProposal()">web</el-button>
            <el-button @click="handleWebDoc">Doc</el-button>
            <el-button @click="handle3D">3D</el-button>
          </div> -->
          <div></div>
          <el-tooltip
                  class="item"
                  effect="dark"
                  :content="'Select the owner to assign the order'"
                  placement="top"
                  :disabled="disableAccept(orderData)"
                >
          <button
            v-if="
              orderData.order_status === 'order_placed'
            "
            class="btn btn-primary btn-spacing"
            data-toggle="modal"
            data-target="#make_panel"
            size="mini"
            @click="handleAccept"
            :disabled="!disableAccept(orderData)||acceptLoader"
            v-loading="acceptLoader"
          >
            Accept
          </button>
          </el-tooltip>
          <!-- <button
            class="btn btn-danger"
            @click="resetSearch()"
            data-toggle="modal"
            data-target="#make_panel"
          >
            Reject
          </button> -->
          <rejectDialog
            v-if="orderData?.order_status !== 'rejected' && orderData?.order_status !== 'cancelled'"
            :orderData="orderData"
            :orderDetailsPopVisible.sync="propsData.orderDetailsPopVisible"
            :rejectStatus.sync="rejectStatus"
          />
        </el-col>
      </el-row>
    </div>

    <!-- <div  > -->
    <el-row v-loading="loader"  class="body">
      <el-col
        :span="12"
        v-for="(label, ind) in labelDataOptions"
        :key="ind"
        class="body-container"
      >
        <el-row class="label">
          {{ label.label  }}
        </el-row>

        <el-row class="value">
          <el-select
            v-if="label.inputType == 'select'"
            placeholder="Select an Owner"
            v-model="orderData[label.prop]"
            :disabled="label.prop==='order_status' && (orderData[label.prop]==='order_placed'||orderData[label.prop]==='incomplete'||orderData[label.prop]==='cancelled')"
            @change="onAssigneeChange($event, label.prop, orderData)"
          >
            <el-option
              v-for="(item, ind) in label.options"
              :key="ind"
              :label="item.label"
              :value="item.value"
              class="owner-dropdown-option"
            >
              <el-badge
                is-dot
                v-if="item.shifts"
                :type="getType(item.shifts)"
                class="dot-badge"
              ></el-badge>
              {{ item.label }}
              <span v-if="item.competence" :style="{ paddingLeft: '1rem' }">
                <el-tag
                  v-for="(comp, indx) in item.competence"
                  :key="indx"
                  class="tag"
                  :color="handleColorLightning(handleColor(comp), 80)"
                  :style="{
                    color: `${handleColor(comp)}`,
                    marginLeft: '0.2rem',
                  }"
                  type="red"
                  size="mini"
                >
                  {{ comp }}
                </el-tag>
              </span>
            </el-option>
            <div
            v-infinite-scroll="loadMoreOwners(label.nextUrl)"
            infinite-scroll-disabled="ownersBusy"
            infinite-scroll-distance="10"
            class="loading-icon"
            >
              <i v-if="ownersBusy" class="el-icon-loading infiniteScrollLoader"/>
            </div>
          </el-select>
          <div v-else-if="label.inputType === 'icon'">
            <el-col
              :span="12"
              v-for="(icon, inx) in filteredButtons" :key="inx"
            >
              <el-button :disabled="(orderData.order_status==='incomplete'|| orderData.order_status==='order_placed')&&icon.exist!=='survey'?true:false" type="text" class="icon-container" @click.native.stop="icon.callback()">
                <span>
                  <i
                    :key="inx"
                    :class="icon.icon"
                    class="icon-size"
                    style="padding-left: 0.5rem; "
                  ></i
                ></span>
                <span class="icon-text">{{ icon.tooltipContent }}</span>
              </el-button>
            </el-col>
            <router-link
              :to="designSummaryRoute"
              style="color: #409EFF;"
              target="_blank"
            >
              Open Design Summary
            </router-link>
          </div>
          <div v-else>{{ orderData[label.prop] }}</div>

          <el-col :span="9" class="addition-section">
            <!-- <i
              class="el-icon-document-checked"
              style="font-size: 1.2rem; padding-left: 0.5rem"
            ></i> -->
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  <!-- </div> -->
    <el-row class="proposal-btns" v-if="designData && !loader">
      <el-col :span="24">
        <ProposalButtons :design="designData" />
      </el-col>
    </el-row>

    <div v-if="!loader" class="tabs">
      <Tabs
        :active="'1'"
        :token="token"
        :DetailsData="projectDetails"
        :orderData="orderData"
        :projectData="projectData"
        :customer="customerDetails"
        :ownerDetails="ownerDetails"
        :moduleDetails="moduleDetails"
        :pricingDetails="pricingDetails"
        :additionalNotes="additionalNotes"
        :ahjDetails="ahjDetails"
        :modelDetails="modelDetails"
        :batteryDetails="batteryDetails"
      />
    </div>
    <ChatBox v-if="ISUs && !loader" :orderData="orderData"  :drawerStatus="propsData?.drawer"/>
  </div>
</div>
<div v-else style="height:50vh;display: flex;justify-content: center;">
  <div v-loading="true">

  </div>
</div>
</div>
