import i18next from "../../../../locales/i18nextInit"

export function modifyBackupTime(duration) {
    let finalText = ''
    if (isNaN(duration)) {
        finalText = '-'
    } else if (duration == -1 || duration >= 168) {
        finalText = `7+ ${i18next.t('day', { count: 7 })}`
    } else {
        let dayCount = parseInt(duration / 24)
        if (dayCount) {
            finalText += i18next.t('dayWithCount', { count: dayCount })
        }
        let hourCount = duration % 24
        if (hourCount) {
            finalText += " " + i18next.t('hourWithCount', { count: hourCount })
        }
    }
    return finalText || i18next.t('hourWithCount', { count: 0 })
}

export function showBomItemQuantity(bomItem) {
    let quantity = Math.ceil(bomItem.count ?? bomItem.quantity)
    let category = bomItem.component || bomItem.category

    if (
        ["Structure", "Racking System", "BOS"].includes(category) ||
        !bomItem.make ||
        isNaN(quantity)
    ) {
        return "—"
    }

    let unitType = bomItem.unit_type
    if (unitType == "lot") {
        quantity ||= 0
        let unit = "lots"
        if (quantity == 1) {
            unit = "lot"
        }
        return quantity + ` ${unit}`
    }

    return quantity
}

export let sunlightLoanStageList = [
    {
        title: "Stipulation",
        pending_stages: [
            "Stip Created",
        ],
        completed_stages: [
            "Stip Closed",
        ],
    },
    {
        title: "Preapproval",
        pending_stages: [
            "No Preapproval Decision",
        ],
        completed_stages: [
            "Not Preapproved",
            "Preapproved",
        ],
    },
    {
        title: "Prequal Review",
        pending_stages: [
            "Prequal Decision Pending",
        ],
        completed_stages: [
            "Prequal Approved",
            "Prequal Declined",
        ],
    },
    {
        title: "Credit Review",
        pending_stages: [
            "Credit Pending",
        ],
        completed_stages: [
            "Credit Approval",
            "Credit Expired",
            "Credit Decline",
        ],
    },
    {
        title: "Loan Agreement Signed",
        pending_stages: [
            "Loan Agreement Sent",
        ],
        completed_stages: [
            "Loan Documents Signed",
        ],
    },
    {
        title: "Loan Agreement Packet Review",
        pending_stages: [
            "M0 Approval Requested",
        ],
        completed_stages: [
            "M0 Approved",
        ],
    },
    {
        title: "Installed System Review",
        pending_stages: [
            "M1 Approval Requested",
        ],
        completed_stages: [
            "M1 Approved",
        ],
    },
    {
        title: "Permission to Operate Review",
        pending_stages: [
            "M2 Approval Requested",
        ],
        completed_stages: [
            "M2 Approved",
        ],
    },
    {
        title: "Change Order Consideration",
        pending_stages: [
            "Change Order Complete",
        ],
        completed_stages: [
            "Change Order Cancelled",
        ],
    },
]