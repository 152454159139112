<template>
  <div class="tasksContainerhi">
    <previousProposalRequests
      v-if="isPreviousRequestsPopupVisible"
      :isPreviousRequestsPopupVisible.sync="isPreviousRequestsPopupVisible"
      :currency_code="projectInformation.country_details.currency_code"
      :project_id="project_id"
    />
    <div class="addTaskBtn" @click="handleShowOrHideDrawer">
      <b-icon icon="plus-lg" style="color: white; font-size: 16px;"></b-icon>
    </div>
    <div class="history" v-if="forLeadSummary">
      <p class="historyHead" @click="isPreviousRequestsPopupVisible = true">
        Proposal History
      </p>
    </div>
    <div class="tasks" :style="{backgroundColor: 'white'}">
      <div class="task-header">
        <h3>{{ headName }}</h3>
        <span class="taskStatus" v-if="forLeadSummary"
          >{{ totalTasks }} tasks</span
        >
        <div class="header-btn">
          <el-button
            type="text"
            icon="el-icon-plus"
            @click="handleShowOrHideDrawer"
            style="font-size: 16px; font-weight: 600"
            >Add Task</el-button
          >
        </div>
      </div>
      <div
        v-if="tasks.length"
        :class="forLeadSummary ? 'borderContainer' : 'withoutBorder'"
      >
        <Task
          v-for="task in tasks"
          :countSize="countSize"
          :forLeadSummary="forLeadSummary"
          :info="task"
          :project_id="project_id"
          :key="task.id"
          @individualTaskUpdated="handleIndividualTaskUpdated"
          @onDeleteTask="onDeleteTask"
        />
        <ScrollObserver
          :showLoader="busy"
          @intersect="loadMoreTasks"
        />
      </div>
      <div v-loading="isTaskLoading" class="no-tasks-container" v-else>
        No tasks here.
      </div>
    </div>
    <!-- <all-drawer
      :drawer="detailsDrawer"
      @save="detailsDrawer = false"
      :componentName="componentName"
      :drawerSize="500"
      @close="detailsDrawer = false"
      :project_id="project_id"
      :leadId="leadId"
      :forAddTask="forAddTask"
    /> -->

    <GlobalDrawer
      :isOpen="showCreateDrawer"
      title="Create Task"
      :handleCloseDialog="handleShowOrHideDrawer"
      :drawer-size="750"
      :drawerDirection="drawerDirection"
      class="task-drawer-dialog"
    >
      <!-- <template v-if="taskMode === 'view'" v-slot:header>
                <button>Mark Complete</button>
              </template> -->
      <template v-if="showCreateDrawer" v-slot:body>
        <TaskDrawerDynamic
          :reminderData.sync="reminderDetails"
          :mode="taskMode"
          :taskData="taskValue"
          @createTask="createTask"
          @deleteTask="onDeleteTask"
          @createTaskClicked="createTaskClicked"
          :isCreateLoading="isCreateLoading"
          @updateTask="updateTask"
          :isLeadSummary="isLeadSummary"
          :showCloseDialog="showCloseDialog"
          @setUnsavedChanges="setUnsavedChanges"
          @closeDrawer="closeDrawer"
          @handleCloseWarningDialog="showCloseDialog = false"
        />
        <!-- @openSetReminder="handleOpenSetReminder" -->
      </template>
    </GlobalDrawer>
    <setReminder
      :key="setKey"
      :showCreateDrawer="showSetReminderDrawer"
      @close="showSetReminderDrawer = false"
      @save="onSave"
      :drawerSize="400"
      :page="'task'"
    />
  </div>
</template>
<script>
import previousProposalRequests from "../../webProposal/previousProposalRequests.vue";
import Task from "./models/Task.vue";
import API from "../../../services/api";
import GlobalDrawer from "../../commonComponents/allDrawer/globalDrawer.vue";
import TaskDrawerDynamic from "../../taskManagement/components/taskDrawerDynamic.vue";
import setReminder from "../../setReminder/setReminder.vue";
import { useLeadStore } from "../../../stores/lead";
import { mapActions, mapState } from "pinia";
import { useProjectStore } from "../../../stores/project";
import { DATABASE_URL_FOR_SITE_SURVEY, DATABASE_URL } from "../../../constants";
import ScrollObserver from "../../../components/ui/ScrollObserver.vue";
import { useUiStore } from "../../../stores/ui"

export default {
  name: "TaskActivity",
  props: [
    "forLeadSummary",
    "lead",
    "project_id",
    "sizeType",
    "allLeadList",
    "allUserList",
    "callApi",
    "isLeadSummary",
  ],
  components: {
    Task,
    previousProposalRequests,
    TaskDrawerDynamic,
    GlobalDrawer,
    setReminder,
    ScrollObserver,
  },
  data() {
    return {
      showCloseDialog: false,
      isUnsavedChanges: false,
      isTaskLoading: true,
      isSiteSurveyGenerated: false,
      leadsUpData: [],
      usersUpData: [],
      isCreateLoading: false,
      showSetReminderDrawer: false,
      taskMode: "create",
      countSize: 0,
      setKey: 0,
      isPreviousRequestsPopupVisible: false,
      headName: "Tasks",
      tasksStatus: "",
      detailsDrawer: false,
      showCreateDrawer: false,
      name: "",
      description: "",
      componentName: "addOrEditTask",
      forAddTask: true,
      tasks: [],
      isSiteSurveyLinkVisible: false,
      viewSiteSurveyLink: false,
      siteSurveyLinkUrl: "",
      busy: false,
      closeEmptySiteSurveyPopUp: true,
      emptySiteSurvey: false,
      paginationDict: {
        copyUrl: "",
        nextUrl: null,
        busy: false,
      },
      taskValue: {},
    };
  },
  methods: {
    ...mapActions(useLeadStore, ["FETCH_ALL_TASKS_IN_STORE", "DELETE_TASK_IN_LEAD"]),
    onSiteSurveyGenerated() {
      this.isSiteSurveyGenerated = true;
    },
    closeDrawer(){
      this.isUnsavedChanges = false
      this.showCloseDialog = false
      this.handleShowOrHideDrawer()
    },
    setUnsavedChanges(bool){
      this.isUnsavedChanges = bool
    },
    handleIndividualTaskUpdated(data){
      this.tasks = this.tasks.map(e => {
        if(e.id == data.id) return data
        return e
      })
    },
    createTaskClicked() {
      this.isCreateLoading = true;
    },
    async generateSiteSurveyLink() {
      this.isSiteSurveyLinkVisible = true;
      this.viewSiteSurveyLink = true;
    },
    async openMedia() {
      const surveyData = this.projectInformation.site_survey_token;
      const URL = `${DATABASE_URL}api/site-survey-details/${surveyData}/`;
      const user = JSON.parse(localStorage.getItem("user")) || {};
      const { token } = user;
      const myHeaders = new Headers();
      myHeaders.append("authorization", `Token ${token}`);
      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      await fetch(URL, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          const data = JSON.parse(result);
          if (
            data.site_survey_details.length === 0 &&
            this.closeEmptySiteSurveyPopUp
          )
            this.emptySiteSurvey = true;
        });
      if (surveyData && !this.emptySiteSurvey) {
        const routeData = this.$router.resolve({
          name: "mediaBox",
          params: { surveyId: surveyData },
        });
        window.open(routeData.href, "_blank");
      }
    },
    openSiteSurveyLinkInNewTab() {
      let siteSurveyObject = this.projectInformation.site_survey[this.projectInformation.site_survey.length - 1]
      const url = DATABASE_URL_FOR_SITE_SURVEY + siteSurveyObject.token + `/${siteSurveyObject.template.id}`;
      window.open(url, '_blank');
    },
    shareSiteSurveyPopUp() {
      this.isSiteSurveyLinkVisible = true;
    },
    sortTasks() {
      this.tasks.sort(function (a, b) {
        return b.time - a.time;
      });
    },
    handleShowOrHideDrawer() {
      if(this.isUnsavedChanges){
        this.showCloseDialog = true;
        return
      }
      this.taskMode = "create";
      this.showCreateDrawer = !this.showCreateDrawer;
      this.reminderDetails = {};
      //Reload the updated data
      //this.getData();
    },
    async onSave(reminderDetails) {
      this.reminderDetails = {
        reminder_sent_at: reminderDetails.reminder_sent_at,
        notes: reminderDetails.notes,
      };
      if (this.taskValue?.id) {
        this.setReminder(this.taskValue.id);
      }
      this.showSetReminderDrawer = false;
    },
    createTask() {
      this.createTaskData();
    },
    async createTaskData() {
      try {
        this.getTaskInfo();
        //this.getData();
        this.showCreateDrawer = !this.showCreateDrawer;
      } catch (err) {
        this.$message({
          showClose: true,
          message: "There was an unknown error while creating the task",
          type: "error",
          center: true,
        });
      }
      this.isCreateLoading = false;
    },
    onDeleteTask(taskId) {
      let taskInd = this.tasks.findIndex(task => task.id == taskId)
      this.getTaskInfo();
      if (taskInd != -1) {
        this.tasks.splice(taskInd, 1)
        this.DELETE_TASK_IN_LEAD(taskId);
      }
    },
    async getTaskInfo() {
      this.isTaskLoading = true;
      try {
        let response = await API.LEADS.GET_TASKS(this.leadId, this.project_id);
        this.paginationDict.nextUrl = response.data.next;
        this.tasks = response.data.results;
        this.loadMoreTasks()
        this.FETCH_ALL_TASKS_IN_STORE(response.data.results, response.data.count);
      } catch (e) {
        this.$message({
          showClose: true,
          message: "Unable to get tasks.",
          type: "error",
          center: true,
        });
      }
      this.isTaskLoading = false;
    },
    onSetReminder() {
      this.setKey++;
      this.showSetReminderDrawer = true;
    },

    async getMoreTasksData(nextURL) {
      let { data } = await API.TASKS.LOAD_MORE_TASKS(nextURL);
      return { ...data };
    },

    async loadMoreTasks() {
      if (this.busy) { return }
      if (!this.paginationDict.nextUrl) { return }

      try {
        this.busy = true
        let res = await this.getMoreTasksData(this.paginationDict.nextUrl)
        if (!this.tasks.find(e => e.id == res.results[0].id)) {
          this.tasks = [...this.tasks, ...res.results]
          this.paginationDict.nextUrl = res.next
        }
      } catch (error) {
        console.error(error)
        this.$message({
          showClose: true,
          message: "There was an error while fetching more task.",
          type: "error",
          center: true,
        });
      }
      this.busy = false
    },
  },
  computed: {
    ...mapState(useUiStore, {
      isMobileView: state => state.isMobileView,
    }),
    ...mapState(useLeadStore, {
      allTasks: (state) => state.tasks,
      leadInfo: (state) => state,
      totalTasks: (state) => state.taskCount,
    }),
    ...mapState(useProjectStore, {
      projectInformation: "GET_PROJECT_INFORMATION",
      updateSiteSurveyToken: "UPDATE_SITE_SURVEY_TOKEN",
    }),
    drawerDirection() {
      if(this.isMobileView) {
        return 'btt';
      } else {
        return 'rtl';
      }
    },
    siteSurveyLink() {
      if (this.projectInformation.site_survey_token) {
        return true;
      } else {
        return false;
      }
    },
    updateFinishedTasks() {
      let completedTasks = 0;
      this.tasks.forEach((e) => {
        if (e.is_completed) completedTasks++;
      });
      this.tasksStatus = `${completedTasks}/${this.totalTasks}`;
      return this.tasksStatus;
    },
    leadId() {
      return this.lead?.lead_details?.id || this.leadInfo.lead_id || this.leadInfo.id;
    },
  },
  mounted() {
    this.$props.sizeType === "medium"
      ? (this.countSize = 120)
      : (this.countSize = 40);
    this.siteSurveyToken = this.projectInformation.site_survey_token;
    this.siteSurveyLinkUrl = DATABASE_URL_FOR_SITE_SURVEY.concat(
      this.siteSurveyToken
    );
    this.getTaskInfo();
  },
};
</script>
<style scoped>
.taskStatus {
  text-align: center;
  margin-left: auto;
}
.history {
  display: flex;
  align-items: center;
  gap: 8px;
}
.shareIcon {
  cursor: pointer;
  margin: 0 10% 0 2%;
}
.addTskContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
}

.addTskContainer {
  margin-bottom: 16px;
  gap: 24px;
}

.flexCont1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.historyHead {
  font-size: 16px;
  font-weight: 600;
  width: fit-content;
  color: #1c3366;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: auto;
}

.historyHead::before {
  content: "";
  background: url("./assets/history.svg");
  width: 20px;
  min-width: 20px;
  height: 20px;
  display: block;
}

.taskBlue {
  font-size: 20px;
  color: #222;
}

.taskNo {
  font-size: 16px;
  color: #777;
}

.addTask {
  font-size: 16px;
  font-weight: bold;
  color: #409eff;
  display: flex;
  gap: 8px;
  align-items: center;
}

.addTask::before {
  content: "";
  background: url("./assets/PlusLg.svg");
  width: 20px;
  height: 20px;
  display: block;
}

.shrtFormSmall {
  width: 30px;
  height: 30px;
  display: grid;
  place-items: center;
  font-size: 14px;
  color: #fff;
  background-color: #1c3366;
  border-radius: 50%;
}

.tasksBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding: 16px 0px;
  border-bottom: 4px dashed #999;
}

.tasksBox:last-child {
  border-bottom: none;
}

.heading {
  font-size: 16px;
  color: #222;
  margin-bottom: 4px;
}

.desc {
  font-size: 14px;
  color: #777;
}

.tasksContainer >>> .el-checkbox {
  display: flex;
  align-items: center;
  gap: 4px;
}

.tasksContainer >>> .el-checkbox__inner {
  width: 16px !important;
  height: 16px !important;
  border-radius: 50%;
  border: 1px solid #222 !important;
}

.tasksContainer >>> .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #fff;
}

.tasksContainer >>> .el-checkbox__inner::after {
  border: 1px solid #222;
  border-left: 0;
  border-top: 0;
  height: 7px;
  left: 5px;
  top: 2px;
}

.historyHead,
.shareIcon {
  cursor: pointer;
}

.no-tasks-container {
  text-align: center;
  font-size: 1.2em;
  color: gray;
  padding: 2em;
}

.createButton {
  padding: 0.55rem 1rem;
}
.header-btn {
  margin-left: auto;
}

.tasks {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
  .taskStatus{
      margin-top: 5px;
      display: absolute;
      color: #909399;
      font-size: smaller;
  }

  .task-header {
    display: flex;
    align-items: center;
    /*/* font-family: "Switzer";*/
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 26px;
    padding: 0;
    height:auto;
    margin: 0;
    gap: 0.5rem;
    padding-top: 8px;
  }

  .borderContainer {
     padding: 0px;
     border-radius: 8px;
     border: 1px solid #ccc;
     background-color: #fff;
     overflow: auto;
     overflow-x: hidden;
  }

 .withoutBorder {
     padding: 0px 0px 0px 8px;
     border-radius: 8px;
     background-color: #fff;
     overflow: auto;
     overflow-x: hidden;
 }

 .addTaskBtn {
  display: none;
 }

.tasksContainerhi {
  background-color: #fff;
  min-width: 360px;
  padding: 24px;
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .tasksContainerhi {
    max-width: inherit;
    padding: 0px;
  }
  .history {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 16px;
    background: #E8EDF2;
    z-index: 10;
  }

  .task-header {
    display: none;
  }

  .borderContainer {
    border-radius: 8px;
    background-color: #fff;
    overflow: auto;
    overflow-x: hidden;
    padding: 16px 0px 0px 0px;
    padding-top: 0px;
    border: none;
  }

  .addTaskBtn {
    border-radius: 8px;
    width: 48px;
    height: 48px;
    background-color: #409EFF;
    display: flex !important;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 6px 0px #00000040;
    position: fixed;
    bottom: 76px;
    right: 10px;
    z-index: 1;
    cursor: pointer;
  }

  .shareIcon {
    margin: 0 2% 0 1%;
}
}
</style>
