<template>
    <div id="parentContainer">
        <el-dialog :visible="bulkLeadImportState.isBulkLeadUploadedPopupVisible" :close-on-click-modal="false"
            title="Import Leads" @close="onDialogClose">

            <div class='container' v-if="bulkLeadImportState.isLeadsUploadSuccessfully">
                <img src="./assets/successIcon.svg" class="tickIcon" />
                <p class="congrats">
                    Congrats!<br />
                    <span class="content">Your leads has been successfully imported.</span>
                </p>
                <el-button type="primary commonBtnClass" class="okBtn" @click="onDialogClose()">Ok</el-button>
            </div>

            <div class='container' v-if="bulkLeadImportState.isLeadUploadedError">
                <img src="./assets/errorIconTwo.svg" />
                <p class="oops">
                    Oops!<br /> <span class="contentTwo">There was some error while importing your lead.</span>
                </p>
                <div class="">
                    <el-button class="overBtn commonBtnClass startOverBtn" @click="startOverBtnFunc()">Start
                        Over</el-button>
                    <el-button type="primary" class="commonBtnClass tryAgainBtn" @click="tryAgainFunc()">Try
                        Again</el-button>
                </div>
            </div>

            <div class='container' v-if="bulkLeadImportState.isCancelBtnClicked">
                <img src="./assets/exclamationMark.svg" />
                <p class="content">Are you sure you want to cancel?</p>
                <div class="">
                    <el-button class="overBtn commonBtnClass startOverBtn"
                        @click="bulkLeadImportState.isBulkLeadUploadedPopupVisible = false">Cancel</el-button>
                    <el-button type="primary" class="commonBtnClass tryAgainBtn" @click="closeSSE">Yes</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>

import { mapState, mapActions } from "pinia";
import { useMiscStore } from "../../../stores/misc";


export default {
    name: "importLeadsPopup",
    components: {
    },

    props: {
    },

    data() {
        return {
        };
    },

    created() {
    },

    computed: {
        ...mapState(useMiscStore, {
            bulkLeadImportState: (state) => state.bulkLeadImportState,
        }),
    },

    methods: {
        ...mapActions(useMiscStore, ["resetBulkLeadStoreFunc"]),
        ...mapActions(useMiscStore, ["closeSSE"]),
        onDialogClose() {
            this.resetBulkLeadStoreFunc();
            this.bulkLeadImportState.isBulkLeadUploadedPopupVisible = false;
        },

        startOverBtnFunc() {
            this.resetBulkLeadStoreFunc();
            this.bulkLeadImportState.isBulkLeadUploadedPopupVisible = false;
            this.bulkLeadImportState.isImportLeadsPopupVisible = true;
        },

        tryAgainFunc() {
            this.bulkLeadImportState.isBulkLeadUploadedPopupVisible = false;
            this.bulkLeadImportState.isImportLeadsPopupVisible = true;
            this.bulkLeadImportState.showFileUploadSection = false;
            this.bulkLeadImportState.showInputsSection = true;
            this.bulkLeadImportState.showLeadFieldsSection = true;
            this.bulkLeadImportState.userFileData = this.bulkLeadImportState.userFileDataWithoutModification;
        },
    },
};
</script>

<style scoped>
.el-dialog__wrapper {
    margin-top: 16vh !important;
    overflow: hidden;
}

.el-dialog__wrapper>>>.el-dialog__header {
    display: none;
}

.el-dialog__wrapper>>>.el-dialog__title {
    font-size: 16px;
    font-weight: 600;
    line-height: 2.38;
    text-align: left;
    margin-left: 10px;
    color: #222222 !important;
}

.el-dialog__wrapper>>>.el-dialog__close {
    display: none;
}

.el-dialog__wrapper>>>.el-dialog {
    border-radius: 8px !important;
    height: auto;
    margin-top: 16vh !important;
    max-width: 550px !important;
    width: 90vw !important;
}

.el-dialog__wrapper>>>.el-dialog__body {
    padding: 0px !important;
}

.container {
    padding: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 24px;
    overflow-y: scroll;
    height: 345px;
    text-align: center;
}

.container>>>.el-progress__text {
    font-size: 36px !important;
    font-weight: 600;
    color: #409EFF !important;
}

.tickIcon {
    width: 48px;
}

.congrats {
    color: #2CC21C;
    font-size: 20px;
}

.oops {
    color: #2CC21C;
    font-size: 20px;
}

.content,
.contentTwo {
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    color: #222222;
    word-break: break-word;
}

.okBtn {
    width: 64px;
    height: 36px;
    font-size: 16px;
    padding: 0px;
}

.boldTxt {
    font-weight: 600;
}

.commonBtnClass {
    font-size: 16px;
    height: 36px;
    padding: 0px;
    padding: 0px 12px;
    font-weight: bold;
}

.startOverBtn {
    border-radius: 4px;
    border: 1px solid var(--999, #999);
    background: var(--White, #FFF);
}

.overBtn {
    border: 1px solid #999999;
    margin-right: 16px;
}

@media (max-width: 700px) {
    .container {
        padding: 24px 10px;
    }
}
</style>