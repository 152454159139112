
<div class="popup">
    <GlobalDialog 
:dialogTitle="''" 
:dialogVisible.sync="openDialog" 
:width="'495px'" 
@handleClose="$emit('handleClose')"
 :isModal="false"
>
      
            <template v-slot:body >
         <div class="content">
            <p class="icon-container">
                <b-icon
    
          class="info-icon"
          :icon="'exclamation-circle-fill'"
          :rotate="180"
        ></b-icon>
  </p>
            <p class="content-item">You are about to assign this lead to a PM. Before you continue, ensure you have the following documents:</p>
            <p class="content-item-option">1. Accepted proposal</p>
            <p class="content-item-option">2. Signed contract</p>
            <p class="content-item-option">3. Financial contract (for financial deals only)</p>
            <p class="content-item">Would you like to continue?</p>

         </div>
            </template>
            <template v-slot:footer>
                <div class="footer-container">

                    <div>
                        <el-button plain   @click="$emit('handleClose')">Cancel</el-button
          >           
          <el-button
            type="primary"
            @click="$emit('handleUpdateOwner')"
            >Continue</el-button
          >
                    </div>
                </div>
   
            </template>
            
            </GlobalDialog>

</div>
