
<div id="parentContainer" v-loading.fullscreen="isLoading">
  <el-dialog
    :close-on-click-modal="false"
    :visible="isAddCreditPopupVisible"
    title="Add Credits"
    width="856px"
    @close="onDialogClose"
  >
    <div class="container">
      <div class="leftContainer">
        <div class="addCreditContainer">
          <el-form ref="form" :model="form">
            <el-form-item label="Add Credits"
              ><br />
              <!-- The below regex pattern and onkeypress listener will limit the input to positive integers -->
              <el-input
                v-model="form.creditCount"
                pattern=" 0+\.[0-9]*[1-9][0-9]*$"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                type="number">
              </el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="serviceTax">
          <p class="note" v-if="currency_code=='INR'">
            *{{taxRate * 100}}% {{taxText}} will be included in the total payable amount.
          </p>
          <p v-else>*Price is inclusive of all the taxes</p>
        </div>
        <div class="footer">
          <p class="footerContent">
            If you are facing issues regarding payment, please contact our
            <span class="supportTeam" onclick="window.Intercom('show')">Support Team</span>
          </p>
        </div>
      </div>
      <div class="rightContainer">
        <div class="headerContainer">
          <h3 class="headings">Payment Details</h3>
          <div class="packageDetails">
            <p class="details">Credit Amount</p>
            <p class="detailsValue">{{formatNumberWithCommas(+form.creditCount, currency_code=='INR')}}</p>
          </div>
        </div>
        <div class="selectedPayments">
          <div class="packageDetails">
            <p class="detailsSmall">Amount</p>
            <p class="detailsValue">{{currencySymbolNameMap[currency_code]}}{{formatNumberWithCommas(creditAmount.toFixed(2), currency_code=='INR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}}</p>
          </div>
          <div class="packageDetails" v-if="currency_code=='INR'">
            <p class="detailsSmall">{{taxText}}</p>
            <p class="detailsValue">{{currencySymbolNameMap[currency_code]}}{{formatNumberWithCommas(taxAmount.toFixed(2), currency_code=='INR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}}</p>
          </div>
        </div>
        <div class="footerBtnContainer">
          <div class="packageDetails">
            <p class="totalPayCred">Total Payable Amount</p>
            <p class="totalPayCredVal">{{currencySymbolNameMap[currency_code]}}{{formatNumberWithCommas(totalAmount.toFixed(2), currency_code=='INR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}}</p>
          </div>
          <div class="footerMD">
            <p class="footerContent">
              If you are facing issues regarding payment, please contact our
              <span class="supportTeam" onclick="window.Intercom('show')">Support Team</span>
            </p>
          </div>
          <el-button class="footerBtn" type="primary" :disabled="isTataOrg" @click="openPaymentGateway()">{{isTataOrg ? "Credits Unavailable" : "Pay Now"}}</el-button>
        </div>
      </div>
    </div>
  </el-dialog>
  <el-dialog
    :close-on-click-modal="false"
    @close="isPaymentGatewayPopupVisible = false"
    :visible="isPaymentGatewayPopupVisible"
    v-if="isPaymentGatewayPopupVisible"
    class="payment-dialog"
    style="z-index: 10000">
    <div ref="cashfree-payment-element" v-if="paymentGateway == 'cashfree'"></div>
    <div v-else-if="paymentGateway == 'stripe'" style="padding: 1em">
      <div class="purchase-info">
        <span class="purchase-description">{{formatNumberWithCommas(form.creditCount)}} Credits</span>
        <span class="purchase-amount">{{currencySymbolNameMap[currency_code]}}{{totalAmount.toFixed(2)}}</span>
      </div>
      <div id="stripe-payment-element"></div>
      <el-button type="primary" ref="stripe-pay-button" style="width: 100%; margin-top: 2em">Pay</el-button>
    </div>
  </el-dialog>
</div>
