import axios from 'axios';

export default {

	PATCH_DESIGN_FINANCIALS(pricingId, patchData) {
        return axios.patch(`/api/financial/pricing/${pricingId}/`, patchData);
    },

    DELETE_DESIGN_FINANCIALS(pricingId) {
        return axios.delete(`/api/financial/pricing/${pricingId}/`);
    },

    POST_DESIGN_FINANCIALS(postData) {
        return axios.post(`/api/financial/pricing/`, postData);
    },

}