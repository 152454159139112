
<div class="deleteModule">
  <el-dialog
    :visible="isAddOrEditOrCopyLoanPopupOpen"
    :close-on-click-modal="false"
    :title="heading"
    width="45%"
    class="delete_module"
    @close="$emit('cancelAdd')"
  >
    <div class="container">
      <!-- -----------------input Container--------- -->
      <div class="scrollableCont">
        <div class="inputContainer">
          <el-form ref="form" :model="form">
            <!-- -----------------Input One--------- -->
            <div class="inputOne">
              <el-form-item
                :label="'Enter ' + typeOfTab + ' Name*'"
                class="firstInput"
              >
                <el-input
                  v-model="form.name"
                  @input="EnterProjectName"
                ></el-input>
                <p
                  class="validationCss"
                  v-if="projectNameInvalid"
                  
                >
                  {{ nameField }}
                </p>
              </el-form-item>
            </div>
            <div></div>

            <!-- -----------------other Four Inputs--------- -->
            <div class="inputs">
              <el-form-item label="Project Type*"
                ><br />
                <el-select
                  v-model="form.project_type"
                  placeholder="Select Project Type"
                  @change="SelectProjectType"
                >
                  <el-option
                    v-for="(project_type, index) in project_type"
                    :key="index"
                    :label="project_type.label"
                    :value="project_type.value"
                  ></el-option>
                </el-select>
                <p
                  class="validationCss"
                  v-if="projectTypeInvalid"
                  
                >
                  {{ project_typeField }}
                </p>
              </el-form-item>
              <el-form-item label="Loan Type*"
                ><br />

                <el-select
                  v-model="form.loan_type"
                  placeholder="Select Loan Type"
                  @change="SelectLoanType"
                >
                  <el-option
                    v-for="(loan_type, index) in loan_type"
                    :key="index"
                    :label="loan_type.label"
                    :value="loan_type.value"
                    :summary="loan_type.summary"
                    class="loanDropdown"
                    ><h3 class="labelDropdown">{{ loan_type.label }}</h3>
                    <p class="descDropdown">{{ loan_type.summary }}</p>
                    <hr />
                  </el-option>
                </el-select>
                <p
                  class="validationCss"
                  v-if="loanTypeInvalid"
                  
                >
                  {{ loan_typeField }}
                </p>
              </el-form-item>

              <el-form-item v-if=" form.loan_type !== 'mortgage_style' && form.loan_type !== 'mortgage_min_max_style'" label="Principal*">
                <div class="hover_information">
                  <i class="fas fa-info-circle"></i>
                  <div class="tooltip">
                    <p>
                      The percentage of system cost minus grants that will be
                      financed with the loan
                    </p>
                  </div>
                </div>

                <span class="inputValues">%</span>
                <el-input
                  v-model="form.principal_amount"
                  type="Number"
                  min="0"
                  @input="IsPrincipalAmount()"
                ></el-input>
                <p
                  class="validationCss"
                  v-if="principalAmountExceed"
                  
                >
                  {{ principalIsInvalid }}
                </p>
                <p
                  class="validationCss"
                  v-if="principalAmountInvalid"
                  
                >
                  {{ principal_amountField }}
                </p>
              </el-form-item>
              <el-form-item
                v-if="
                  form.loan_type === 'bullet' ||
                  form.loan_type === 'mortgage_style' || form.loan_type == 'mortgage_min_max_style'
                "
                label="Interest Rate *"
              >
                <div class="hover_information">
                  <i class="fas fa-info-circle"></i>
                  <div class="tooltip">
                    <p>
                      The proportion of a loan that is charged as interest to
                      the borrower, expressed as an annual percentage of the
                      loan outstanding
                    </p>
                  </div>
                </div>
                <span class="inputValues">%</span>
                <el-input
                  v-model="form.interest_rate"
                  type="Number"
                  min="0"
                  @input="IsInterestRate()"
                ></el-input>
                <p
                  class="validationCss"
                  v-if="interestRateExceed"
                  
                >
                  {{ interest_rateIsInvalid }}
                </p>
                <p
                  class="validationCss"
                  v-if="interestRateInvalid"
                  
                >
                  {{ interest_rateField }}
                </p>
              </el-form-item>

              <el-form-item
                v-if="
                  form.loan_type === 'bullet' || form.loan_type == 'mortgage_min_max_style' ||
                  form.loan_type === 'mortgage_style'
                "
                label="Duration*"
              >
                <div class="hover_information">
                  <i class="fas fa-info-circle"></i>
                  <div class="tooltip">
                    <p>The term of the loan in months</p>
                  </div>
                </div>
                <span class="inputValues">Months</span>
                <el-input
                  v-model="form.duration"
                  type="Number"
                  min="0"
                  max="600"
                  @input="IsDuration()"
                ></el-input>
                <p
                  class="validationCss"
                  v-if="durationInvalid"
                  
                >
                  {{ monthField }}
                </p>
                 <p
                  class="validationCss"
                  v-if="durationInvalidExceeds"
                  
                >
                  {{ monthFieldExceeds }}
                </p>
              </el-form-item>

              <el-form-item label="Dealer's Fees">
                <div class="hover_information">
                  <!-- <i class="fas fa-info-circle"></i>
                  <div class="tooltip">
                    <p>
                      The percentage of system cost minus grants that will be
                      financed with the loan
                    </p>
                  </div> -->
                </div>

                <span class="inputValues">%</span>
                <el-input
                  v-model="form.dealer_fee"
                  type="Number"
                  min="0"
                  @input="IsDealerFees()"
                ></el-input>
                <p
                  class="validationCss"
                  v-if="dealerAmountExceed"
                  
                >
                  {{ dealerIsInvalid }}
                </p>
                <p
                  class="validationCss"
                  v-if="dealerAmountInvalid"
                  
                >
                  {{ dealer_amountField }}
                </p>
              </el-form-item>

              </div>
                <el-form-item :class="principalType == 'percentage'?'principalPercentage' :'principalMinMax'"  v-if=" form.loan_type == 'mortgage_style' || form.loan_type == 'mortgage_min_max_style'" label="Principal*">
                  <div class="hover_information">
                    <!-- <i class="fas fa-info-circle"></i> -->
                    <!-- <div class="tooltip">
                      <p>
                        The percentage of system cost minus grants that will be
                        financed with the loan
                      </p>
                      </div> -->
                      
                      </div>
                        <div>
                          <el-radio @change="radioButtonClick" v-model="principalType" label="percentage">As percentage
                            <span style='margin-left: 8px;' >
                              <div class="hover_information">
                                <i class="fas fa-info-circle"></i>
                                  <div class="tooltip"  style="width: fit-content;">
                                    <p>
                                      The percentage of system cost minus grants that will be
                                      financed with the loan
                                    </p>
                                  </div>
                              </div>
                            </span>
                          </el-radio>
                          <el-radio @change="radioButtonClick" v-model="principalType" label="mortgage_min_max_style">As min-max value
                            <span style='margin-left: 8px;'>
                              <div class="hover_information">
                                <i class="fas fa-info-circle"></i>
                                  <div class="tooltip" style="width: fit-content;">
                                    <p>
                                      The min and max loan principal that can be financed including dealer fees
                                    </p>
                                  </div>
                              </div>
                            </span>
                          </el-radio>
                        </div>
                      <!-- <span class="inputValues">%</span> -->
                  <el-input
                    v-if="this.principalType == 'percentage'"
                    v-model="form.principal_amount"
                    type="Number"
                    min="0"
                  >
                  <template v-slot:append><span class="suffex">%</span></template>
                  </el-input>
                  <div style="display: flex; justify-content:space-between; gap:24px;" v-else>
                    <div style="margin-bottom: 16px;width: 100%;">
                      <label for="min-value">Min Value</label>
                      <el-input
                        id="min-value"
                        v-model.number="form.min_principal_amount"
                        type="number"
                        min="0"
                        @input="validateValues"
                      >
                      </el-input>
                    </div>
                    <div style="margin-bottom: 16px;width: 100%;">
                      <label for="max-value">Max Value</label>
                      <el-input
                        id="max-value"
                        v-model.number="form.max_principal_amount"
                        type="number"
                        min="0"
                        @input="validateValues"
                      >
                      </el-input>
                    </div>
                  </div>
                  <div v-if="principalMinMaxValid"  class="validationCss">{{ errorMessage }}</div>
                  <p
                    class="validationCss"
                    v-if="principalAmountExceed"
                    
                  >
                    {{ principalIsInvalid }}
                  </p>
                  <p
                    class="validationCss"
                    v-if="principalAmountInvalid"
                    
                  >
                    {{ principal_amountField }}
                  </p>
                </el-form-item>

            <!-- --------------------border container---------- -->
            <div
              v-if="form.loan_type === 'interest_only'"
              class="termOneContainer"
            >
              <hr class="interestHr" />
              <h3 class="termsHeading">Term 1: Interest Only</h3>
              <el-form ref="form" :model="form" class="termInputs">
                <el-form-item label="Interest Rate*">
                  <div class="hover_information">
                    <i class="fas fa-info-circle"></i>
                    <div class="tooltip">
                      <p>
                        The proportion of a loan that is charged as interest
                        to the borrower, expressed as an annual percentage of
                        the loan outstanding
                      </p>
                    </div>
                  </div>
                  <span class="inputValues">%</span>
                  <el-input
                    v-model="form.interest_rate_for_term_one"
                    type="Number"
                    min="0"
                    @input="IsInterest_rate_for_term_one"
                  ></el-input>
                  <p
                    class="validationCss"
                    v-if="interestRateExceedInterestOnly"
                    
                  >
                    {{ interest_rateIsInvalid }}
                  </p>
                  <p
                    class="validationCss"
                    v-if="interest_rate_for_term_oneInvalidINterestOnly"
                    
                  >
                    {{ interest_rate_for_term_oneField }}
                  </p>
                </el-form-item>

                <el-form-item label="Duration*">
                  <div class="hover_information">
                    <i class="fas fa-info-circle"></i>
                    <div class="tooltip">
                      <p>The term of the loan in months</p>
                    </div>
                  </div>
                  <span class="inputValues">Months</span>
                  <el-input
                    v-model="form.duration_for_term_one"
                    type="Number"
                    min="0"
                    max="600"
                    @input="IsDuration_for_term_one"
                  ></el-input>
                  <p
                    class="validationCss"
                    v-if="monthValidationForTermOneInvalid"
                    
                  >
                    {{ duration_for_term_oneField }}
                  </p>
                   <p
                    class="validationCss"
                    v-if="monthValidationForTermOneExceeded"
                    
                  >
                    {{ duration_for_term_oneFieldExceeds }}
                  </p>
                </el-form-item>

                <!-- --------------------border container---------------->
                <div class="borderContainer">
                  <el-form-item :label="isPaid" class="borderContainerRadio">
                    <div class="hover_information">
                      <i class="fas fa-info-circle"></i>
                      <div class="tooltip">
                        <p>
                          If this is checked, the prepayment percent of the
                          loan will be paid down in the specified month, which
                          keeps the loan payments the same. If this is not
                          checked, the prepayment will not be made, resulting
                          in higher monthly loan payments.
                        </p>
                      </div>
                    </div>
                    <br />
                    <el-radio v-model="radioValue" label="true">Yes</el-radio>
                    <el-radio v-model="radioValue" label="false">No</el-radio>
                  </el-form-item>

                  <el-form-item
                    :label="isPrePercentage"
                    class="borderContainerInputs"
                  >
                    <div class="hover_information">
                      <i class="fas fa-info-circle"></i>
                      <div class="tooltip">
                        <p>
                          The percentage of the loan that will be paid of
                          early
                        </p>
                      </div>
                    </div>
                    <span class="inputValues">%</span>
                    <el-input
                      v-model="form.pre_payment_percentage"
                      type="Number"
                      min="0"
                      :disabled="!isDisableInterest"
                      @input="IsPrePaymentPercentage()"
                    ></el-input>
                    <p
                      class="validationCss"
                      v-if="prepaymentExceed && isDisabled"
                      
                    >
                      {{ prePaymentIsInvalid }}
                    </p>
                    <p
                      class="validationCss"
                      v-if="pre_payment_percentageInvalid && isDisabled"
                      
                    >
                      {{ prepaidField }}
                    </p>
                  </el-form-item>

                  <el-form-item
                    :label="isPreMonth"
                    class="borderContainerInputs"
                  >
                    <div class="hover_information">
                      <i class="fas fa-info-circle"></i>
                      <div class="tooltip month">
                        <p>
                          The month that the repayment will be paid to the
                          lender and the loan will be remortized
                        </p>
                      </div>
                    </div>
                    <span class="inputValues">Month</span>
                    <el-input
                      v-model="form.pre_payment_month"
                      type="Number"
                      min="0"
                      :disabled="!isDisableInterest"
                      @input="IsPrePaymentMonth()"
                    ></el-input>
                    <p
                      class="validationCss"
                      v-if="pre_payment_monthInvalid && isDisabled"
                      
                    >
                      {{ pre_payment_monthField }}
                    </p>
                    <p
                      class="validationCss"
                      v-if="pre_payment_monthInvalidExceeeded && isDisabled"
                      
                    >
                      {{ pre_payment_monthFieldExceeds }}
                    </p>
                  </el-form-item>
                </div>
              </el-form>
            </div>
            <div
              v-if="form.loan_type === 'no_payment'"
              class="termOneContainer"
            >
              <hr class="nopaymentHr" />
              <h3 class="termsHeading">Term 1: No Payment</h3>
              <el-form ref="form" :model="form" class="termInputs">
                <el-form-item label="Interest Rate*">
                  <div class="hover_information">
                    <i class="fas fa-info-circle"></i>
                    <div class="tooltip">
                      <p>
                        The proportion of a loan that is charged as interest
                        to the borrower, expressed as an annual percentage of
                        the loan outstanding
                      </p>
                    </div>
                  </div>
                  <span class="inputValues">%</span>
                  <el-input
                    v-model="form.interest_rate_for_term_one"
                    type="Number"
                    min="0"
                    @input="IsInterest_rate_for_term_one_forNoPay()"
                  ></el-input>
                  <p
                    class="validationCss"
                    v-if="interestRateExceedNoPayment"
                    
                  >
                    {{ interest_rateIsInvalid }}
                  </p>
                  <p
                    class="validationCss"
                    v-if="interest_rate_for_term_oneNoPayment"
                    
                  >
                    {{ interest_rate_for_term_oneField }}
                  </p>
                </el-form-item>

                <el-form-item label="Duration*">
                  <div class="hover_information">
                    <i class="fas fa-info-circle"></i>
                    <div class="tooltip">
                      <p>The term of the loan in months</p>
                    </div>
                  </div>
                  <span class="inputValues">Months</span>
                  <el-input
                    v-model="form.duration_for_term_one"
                    type="Number"
                    min="0"
                    max="600"
                    @input="IsDuration_for_term_one_ForNoPay()"
                  ></el-input>
                  <p
                    class="validationCss"
                    v-if="monthValidationForTermOneInvalid"
                    
                  >
                    {{ duration_for_term_oneField }}
                  </p>
                  <p
                    class="validationCss"
                    v-if="monthValidationForTermOneExceeded"
                    
                  >
                    {{ duration_for_term_oneFieldExceeds }}
                  </p>
                </el-form-item>
              </el-form>
            </div>
            <div
              v-if="
                form.loan_type === '' || form.loan_type === 'mortgage_style' || form.loan_type == 'mortgage_min_max_style'
              "
              class="borderContainer"
            >
              <el-form-item :label="isPaid" class="borderContainerRadio">
                <div class="hover_information">
                  <i class="fas fa-info-circle"></i>
                  <div class="tooltip">
                    <p>
                      If this is checked, the prepayment percent of the loan
                      will be paid down in the specified month, which keeps
                      the loan payments the same. If this is not checked, the
                      prepayment will not be made, resulting in higher monthly
                      loan payments
                    </p>
                  </div>
                </div>
                <br />

                <el-radio v-model="radioValue" label="true">Yes</el-radio>
                <el-radio v-model="radioValue" label="false">No</el-radio>
                <p
                  class="validationCss"
                  v-if="prepaymentInvalid"
                  
                >
                  {{ prepaidField }}
                </p>
              </el-form-item>

              <el-form-item
                :label="isPrePercentage"
                class="borderContainerInputs"
              >
                <div class="hover_information">
                  <i class="fas fa-info-circle"></i>
                  <div class="tooltip">
                    <p>
                      The percentage of the loan that will be paid of early
                    </p>
                  </div>
                </div>
                <span class="inputValues">%</span>
                <el-input
                  v-model="form.pre_payment_percentage"
                  type="Number"
                  min="0"
                  :disabled="!isDisabled"
                  @input="IsPrePaymentPercentage()"
                ></el-input>
                <p
                  class="validationCss"
                  v-if="prepaymentExceed && isDisabled"
                  
                >
                  {{ prePaymentIsInvalid }}
                </p>
                <p
                  class="validationCss"
                  v-if="pre_payment_percentageInvalid && isDisabled"
                  
                >
                  {{ prepaidField }}
                </p>
              </el-form-item>

              <el-form-item :label="isPreMonth" class="borderContainerInputs">
                <div class="hover_information">
                  <i class="fas fa-info-circle"></i>
                  <div class="tooltip month">
                    <p>
                      The month that the repayment will be paid to the lender
                      and the loan will be remortized
                    </p>
                  </div>
                </div>
                <!-- <span class="inputValues">%</span> -->
                <el-input
                  v-model="form.pre_payment_month"
                  type="Number"
                  min="0"
                  :disabled="!isDisabled"
                  @input="IsPrePaymentMonthForNoPay()"
                ></el-input>                
                <div v-if="prePaymentMonthValid"  class="validationCss">{{ PrePaymentErrorMessage }}</div>
                <p
                  class="validationCss"
                  v-if="pre_payment_monthInvalid && isDisabled"
                  
                >
                  {{ pre_payment_monthField }}
                </p>
              </el-form-item>
            </div>
          </el-form>
        </div>
        <!------------------------termTwo--------------------------------->
        <div
          v-if="
            form.loan_type === 'interest_only' ||
            form.loan_type === 'no_payment'
          "
          class="termTwoContainer"
        >
          <hr />
          <h3 class="termsHeading">Term 2: Mortgage-Style</h3>
          <el-form ref="form" :model="form" class="termInputs">
            <el-form-item label="Interest Rate*">
              <div class="hover_information">
                <i class="fas fa-info-circle"></i>
                <div class="tooltip">
                  <p>
                    The proportion of a loan that is charged as interest to
                    the borrower, expressed as an annual percentage of the
                    loan outstanding
                  </p>
                </div>
              </div>
              <span class="inputValues">%</span>
              <el-input
                v-model="form.interest_rate_for_term_two"
                type="Number"
                min="0"
                @input="IsInterest_rate_for_term_two_ForMortagage()"
              ></el-input>
              <p
                class="validationCss"
                v-if="interestRateExceedForMortgage"
                
              >
                {{ interest_rateIsInvalid }}
              </p>
              <p
                class="validationCss"
                v-if="interest_rate_for_term_TwoInvalid"
                
              >
                {{ interest_rate_for_term_twoField }}
              </p>
            </el-form-item>

            <el-form-item label="Duration*">
              <div class="hover_information">
                <i class="fas fa-info-circle"></i>
                <div class="tooltip">
                  <p>The term of the loan in months</p>
                </div>
              </div>
              <span class="inputValues">Months</span>
              <el-input
                v-model="form.duration_for_term_two"
                type="Number"
                min="0"
                max="600"
                @input="IsDurationForTermTwo()"
              ></el-input>
              <p
                class="validationCss"
                v-if="duration_for_term_twoInvalid"
                
              >
                {{ duration_for_term_twoField }}
              </p>
                <p
                class="validationCss"
                v-if="duration_for_term_twoInvalidExceeded"
                
              >
                {{ duration_for_term_twoFieldExceeds }}
              </p>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <!-- --------------------bottom info---------- -->
      <div class="bottomInfo">
        <hr />
        <p style="word-break: break-word">
          {{ getDescription() }}
        </p>
      </div>

      <!-- -----------------button--------- -->
      <div class="popupBtnContainer">
        <el-button
          v-if="typeOfOperation === 'Add' || typeOfOperation === 'Copy'"
          class="popupBtn"
          type="primary"
          @click="addLoan"
          >{{ typeOfOperation }} {{ typeOfTab }}</el-button
        ><el-button
          v-if="typeOfOperation === 'Edit'"
          class="popupBtn"
          type="primary"
          @click="addLoan"
          >Update {{ typeOfTab }}</el-button
        >
      </div>
    </div>
  </el-dialog>
</div>
