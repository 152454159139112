
<div class="answersFiledsContainer" v-if="showContent()">
    <div class="uploadFileContainer" v-if="fieldData.question_type === 'file_upload' 
    || fieldData.question_type === 'poi' ||fieldData.question_type === 'boundary'">
        <div v-if="fieldData.question_type === 'file_upload'">
            <div class="flexCont" >
                <p class="heading">Allow only specific file types</p>
                <el-switch v-model="fieldData.additional_info.allowed_types_toggle" @change="fieldData.additional_info.allowed_types = []; prepareFieldObjectToStore(undefined,true);">
                </el-switch>
            </div>
            <el-checkbox-group v-model="fieldData.additional_info.allowed_types" v-if="fieldData.additional_info.allowed_types_toggle" @change="prepareFieldObjectToStore(undefined,true)">
                <el-checkbox v-for="item in fileTypeItems" :key="item.value" :label="item.value">
                {{ item.label }}
                </el-checkbox>
            </el-checkbox-group>
        </div>
        <div class="flexCont">
            <p class="heading">Maximum number of files</p>
            <el-select v-model="fieldData.additional_info.max_files" placeholder="Select" @change="prepareFieldObjectToStore(undefined, true)">
                <el-option v-for="item in options" :key="item.value" :label="item.value" :value="item.value">
                </el-option>
            </el-select>
        </div>
        <p class="errorTxt">The maximum file size allowed for upload is 500MB.</p>
    </div>


    <div class="radioContainer" v-if="complexQTypesWOFileUpload.includes(fieldData.question_type)">
        <div class="radioInputsCont">
            <div class="boxContainer" v-for="(option, optionIndex) in fieldData.options" :key="optionIndex">
                <div class="inputCrossContainer">
                    <div class="flexRadioInput">
                        <div :class="fieldData.question_type === 'multiple_choice' ? 'staticRadio' : (fieldData.question_type === 'checkbox' ? 'staticCheckbox' : '')"></div>
                        <input type="text" class="inputs" 
                        :placeholder="fieldData.question_type === 'tab_choice'? 'Tab Option Text ' + (optionIndex + 1) :'Option Text ' + (optionIndex + 1)" 
                        v-model="option.option_text" 
                        @change="prepareFieldObjectToStore('options')" />
                    </div>
                    <b-icon icon="x-lg" style="color: #777;" @click="removeOptionFunc(optionIndex)" class="icons"></b-icon>
                </div>
                <div :class="['flexCont',radioToggle ? 'marginLeft' : '' ]" v-if="fieldData.question_type !== 'checkbox'">
                    <p class="heading">Add sub option</p>
                    <el-switch v-model="option.isNestedQRequired"  @change="addNestedQuestion(optionIndex,0)">
                    </el-switch>
                </div>
                <div class="toggleOn" v-for="(nestedQ,nestedQIndex) in filteredSubOptions(option)" :key="nestedQIndex">
                    <div class="flexRadioInput">
                        <input type="text" class="inputColor" placeholder="Sub Label Text" v-model="nestedQ.label" @change="prepareFieldObjectToStore('options')" />
                        <b-icon icon="x-lg" style="color: #777;" v-if="nestedQIndex !== 0" @click="removeCurrentNestedQuestionForOption(optionIndex, nestedQIndex)" class="icons"></b-icon>
                    </div>
                    <div class="dropdownClass">
                        <el-select v-model="nestedQ.question_type" placeholder="Select" @change="resetObject(optionIndex,nestedQIndex);prepareFieldObjectToStore('options');">
                            <el-option
                                v-for="subQuestion in filteredSubQuestionTypeItems"
                                :key="subQuestion.value"
                                :label="subQuestion.label"
                                :value="subQuestion.value"
                            >
                            <div>
                                <div class="flexOptions">
                                    <div v-if="subQuestion.iconType === 'icon'">
                                        <b-icon :icon="subQuestion.icons" style="color: #777; margin-top: 12px;" font-scale="1.3"></b-icon>
                                    </div>
                                    <div v-else-if="subQuestion.iconType === 'image'">
                                        <img :src="subQuestion.icons" alt="Option Image" class="image-icon" style="margin-top: 12px;">
                                    </div>
                                    <p class="dropdownOptions">{{ subQuestion.label }}</p>
                                </div>
                            </div>
                            </el-option>
                        </el-select>
                        <b-icon v-if="selectedOptionIcon && selectedOption.iconType === 'icon'" :icon="selectedOptionIcon" style="color: #777;" font-scale="1.3" class="absoluteIcon"></b-icon>
                        <img v-if="selectedOptionIcon && selectedOption.iconType === 'image'" :src="selectedOptionIcon" class="image-icon absoluteIcon">
                    </div>
                    <!-- rendering options for nested question -->
                    <div class="inputCrossContainer"  v-for="(nestedQOption, nestedQOptionIndex) in filteredNestedQOptions(nestedQ)" 
                    :key="nestedQOptionIndex" 
                    >
                        <div class="flexRadioInput">
                            <div :class="nestedQ.question_type == 'multiple_choice' ?'staticRadio': 'staticCheckbox'"
                             v-if="nestedQ.question_type != 'dropdown' && nestedQ.question_type !='tab_choice' "></div>
                            <input type="text" class="inputs" :placeholder="'Sub Option Text ' + (nestedQOptionIndex + 1)" 
                            v-model="nestedQOption.option_text" @change="prepareFieldObjectToStore('options')" />
                        </div>
                        <b-icon icon="x-lg" style="color: #777;" @click="removeAnOptionForNestedQuestion(optionIndex, nestedQIndex,nestedQOptionIndex)" class="icons"></b-icon>
                    </div>
                    <div v-if="!complexQTypesWOFileUpload.includes(nestedQ.question_type)">
                        <div class="sliderContainer" v-if="nestedQ.question_type === 'slider'">
                            <div class="sliderFields" v-for="(sliderField, sliderFieldIndex) in sliderSubDetail" :key="sliderFieldIndex">
                                <p class="sliderFieldText">{{sliderField.label}}</p>
                                <input class="sliderInput" v-if="sliderField.type !== 'number'" v-model="nestedQ.additional_info[sliderField.value]" @change="prepareFieldObjectToStore('options')" />
                                <input class="sliderInput" 
                                v-if="sliderField.type === 'number'" 
                                :placeholder="sliderField.default" 
                                v-model="nestedQ.additional_info[sliderField.value]" 
                                @input="filterNonNumeric(sliderField.value,nestedQ)"
                                @change="limitNumericValue(nestedQ.additional_info[sliderField.value], sliderField, nestedQ)"/>
                            </div>
                        </div>
                        <div class="uploadFileContainer" v-if="nestedQ.question_type === 'file_upload' 
                        || nestedQ.question_type === 'poi'">
                            <div v-if="nestedQ.question_type === 'file_upload'">
                                <div class="flexCont" >
                                    <p class="heading">Allow only specific file types</p>
                                    <el-switch v-model="nestedQ.additional_info.allowed_types_toggle" @change="nestedQ.additional_info.allowed_types = []; prepareFieldObjectToStore('options');">
                                    </el-switch>
                                </div>
                                <el-checkbox-group v-model="nestedQ.additional_info.allowed_types" v-if="nestedQ.additional_info.allowed_types_toggle" @change="prepareFieldObjectToStore('options')">
                                    <el-checkbox v-for="item in fileTypeItems" :key="item.value" :label="item.value">
                                    {{ item.label }}
                                    </el-checkbox>
                                </el-checkbox-group>
                            </div>
                            <div class="flexCont">
                                <p class="heading">Maximum number of files</p>
                                <el-select v-model="nestedQ.additional_info.max_files" placeholder="Select" @change="prepareFieldObjectToStore('options')">
                                    <el-option v-for="item in options" :key="item.value" :label="item.value" :value="item.value">
                                    </el-option>
                                </el-select>
                            </div>
                            <p class="errorTxt">The maximum file size allowed for upload is 500MB.</p>
                        </div>
                    </div>
                    <div class="addContainer" v-if="complexQTypesWOFileUpload.includes(nestedQ.question_type)">
                        <p class="addOpt" @click="addAnOptionForNestedQuestion(optionIndex, nestedQIndex)">Add Sub Option</p>
                    </div>
                    <div class="addContainer" style="margin-top: 1rem; border-bottom: 1px solid #ccc;">
                        <p class="addOpt" style="margin-bottom: 1rem" @click="addNestedQuestion(optionIndex,nestedQIndex)">Add Sub Option</p>
                    </div>
                </div>
            </div>
            <div class="addContainer">
                <p class="addOpt" @click="addOptionFunc">{{fieldData.question_type==='tab_choice' ? `Add Tab Option`:`Add Option`}}</p>
                <p>or</p>
                <p class="addOther" @click="addOtherOptionFunc">add “Other”</p>
            </div>
        </div>
    </div>
    <div class="sliderContainer" v-if="fieldData.question_type === 'slider'">
        <div class="sliderFields" v-for="(sliderField, sliderFieldIndex) in sliderSubDetail" :key="sliderFieldIndex">
            <p class="sliderFieldText">{{sliderField.label}}</p>
            <input class="sliderInput" v-if="sliderField.type !== 'number'" v-model="fieldData.additional_info[sliderField.value]" @change="prepareFieldObjectToStore(undefined, true)" />
            <input class="sliderInput" 
            v-if="sliderField.type === 'number'" 
            :placeholder="sliderField.default"
            v-model="fieldData.additional_info[sliderField.value]" 
            @input="filterNonNumeric(sliderField.value)"
            @change="limitNumericValue(fieldData.additional_info[sliderField.value], sliderField)"/>
        </div>
    </div>
</div>
