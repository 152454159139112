import axios from "axios";

export default {
    GET_APPLIANCES(){
        return axios.get('/api/appliance-list/')
    },
    POST_APPLIANCES(payload){
        return axios.post(`api/appliance-list/`, payload)
    },
    UPDATE_APPLIANCES(id,payload){
        return axios.patch(`/api/appliance-list/${id}/`,payload)
    },
}