<template>
  <div class="docContainer" v-loading=isLoading>
    <div class="electBillContainer">
      <div class="flexCont">
        <el-checkbox v-model="electricityBillCheckbox"
          >Electricity Bill</el-checkbox
        >
        <p class="downloadAll" @click="downloadSelected">Download Selected</p>
      </div>
      <div class="gridCOntainer">
        <div
          class="card"
          v-for="(file, index) in computedElectricityBillFiles"
          :key="index"
        >
          <img
            :src="
              fileUploadAssets[
                `../../../assets/drop/fileUploadImages/${findFileTypeIcon(
                  file
                )}`
              ]
            "
            class="uploadIcon"
          />
          <p class="docFileName">
            <abbr :title="file.display_name" class="abbrTag">{{
              file.display_name
            }}</abbr>
          </p>
          <div class="flexIcon">
            <img
              src="./assets/Download.svg"
              class="downloadIconDoc"
              @click="downloadClick(file)"
            />
            <img
              src="./assets/delete.svg"
              class="deleteIcon"
              @click="deleteClick(file.id)"
            />
          </div>
        </div>
        <div
          class="addCard"
          @click="commonDocumentContainerFunc('electricity_bill')"
        >
          <img src="./assets/add.svg" class="addIcon" />
        </div>
      </div>
    </div>
    <div class="electBillContainer" v-show="isInternalSales || isSalesOps||isSalesAdmin">
      <div class="flexCont">
        <el-checkbox v-model="signedSolutionContractCheckbox"
          >D2C Contract</el-checkbox
        >
      </div>
      <div class="gridCOntainer">
        <div      
          v-for="(file, index) in computedSignedSolutionContractFiles"
          :key="index"
        >
        <div  class="contractCard">
          <p v-show="file.addtional_details.status =='completed'&&!file.addtional_details.is_rejected" class="acceptedTag">Accepted</p>
          <p v-show="file.addtional_details.status =='sent'" class="pendingTag">Pending</p>
          <p v-show="file.addtional_details.is_rejected" class="rejectedTag">Rejected</p>
          <img
            :src="
              fileUploadAssets[
                `../../../assets/drop/fileUploadImages/${findFileTypeIcon(
                  file
                )}`
              ]
            "
            class="uploadIcon"
          />
          <p class="docFileName">
            <abbr :title="file.display_name" class="abbrTag"
              >{{ file.display_name }}
            </abbr>
          </p>
          <div class="flexIcon" >
            <img
              src="./assets/Download.svg"
              class="downloadIconDoc"
              @click="downloadClick(file)"
            />
            <img
              v-show="(file.addtional_details.status !=='completed'||file.addtional_details.is_rejected)&&!isProjectManager && (isInternalSales||isSalesAdmin)"
              src="./assets/delete.svg"
              class="deleteIcon"
              @click="deleteClick(file.id,file)"
            />
          </div> 
        </div>
      
        </div>
        <div v-if="(isInternalSales||isSalesAdmin)&&computedSignedSolutionContractFiles.length===0"
          class=""
         
        >
          <!-- <img src="./assets/add.svg" class="addIcon" /> -->
          <el-tooltip placement="top" :disabled="!contractBtnDisabled" content="Create a Solutions proposal to enable contract.">
    <el-button
           type="primary"
            class="btn"
           
            @click="handleContract"
            :disabled="contractBtnDisabled"
            :loading="contractBtnLoading"
            >
            Send Contract
            </el-button>
            </el-tooltip>
        </div>
        <div v-else-if="computedSignedSolutionContractFiles.length===0" class="addCard site-survey-card" style="cursor: default">
          <span>No Uploaded Documents</span>
        </div>
      </div>
    </div>
    <div class="electBillContainer" v-show="(isInternalSales|| isSalesOps||isSalesAdmin)&&checkIfFinance">
      <div class="flexCont">
        <el-checkbox v-model="financialContractCheckBox"
          >Financial contract</el-checkbox
        >
      </div>
      <div class="gridCOntainer">
        <div
          class="card"
          v-for="(file, index) in computedFinancingContract"
          :key="index"
        >
        <div  class="contractCard">
          <p v-show="file.addtional_details.status =='completed'" class="acceptedTag">Accepted</p>
          <p v-show="file.addtional_details.status =='sent'" class="pendingTag">Pending</p>
          <p v-show="file.addtional_details.status =='rejected'" class="rejectedTag">Rejected</p>
          <p v-show="file.addtional_details.status =='cancelled'" class="cancelledTag">Cancelled</p>
          <img
            :src="
              fileUploadAssets[
                `../../../assets/drop/fileUploadImages/${findFileTypeIcon(
                  file
                )}`
              ]
            "
            class="uploadIcon"
          />
          <p class="docFileName">
            <abbr :title="file.display_name" class="abbrTag"
              >{{ file.display_name }}
            </abbr>
          </p>
          <div class="flexIcon" >
            <img
              src="./assets/Download.svg"
              class="downloadIconDoc"
              @click="downloadClick(file)"
            />
            <b-icon v-if="showCancelOrder(file)" style="cursor: pointer;" icon="x-lg" @click="showDialog(file)"></b-icon>
          </div> 
        </div>
        </div>
        <div
          class="addCard"
          @click="commonDocumentContainerFunc('financing_contracts')"
        >
          <img src="./assets/add.svg" class="addIcon" />
        </div>
      </div>
    </div>
    
    <div class="electBillContainer">
      <div class="flexCont">
        <el-checkbox v-model="signedContractCheckbox"
          >Signed Contract</el-checkbox
        >
      </div>
      <div class="gridCOntainer">
        <div      
          v-for="(file, index) in computedSignedContractFiles"
          :key="index"
        >
        <div  class="contractCard">
          <p v-show="file.addtional_details.status =='completed'" class="acceptedTag">Accepted</p>
          <p v-show="file.addtional_details.status =='sent'" class="pendingTag">Pending</p>
          <p v-show="file.addtional_details.status =='rejected'" class="rejectedTag">Rejected</p>
          <p v-show="file.addtional_details.status =='cancelled'" class="cancelledTag">Cancelled</p>
          <img
            :src="
              fileUploadAssets[
                `../../../assets/drop/fileUploadImages/${findFileTypeIcon(
                  file
                )}`
              ]
            "
            class="uploadIcon"
          />
          <p class="docFileName">
            <abbr :title="file.display_name" class="abbrTag"
              >{{ file.display_name }}
            </abbr>
          </p>
          <div class="flexIcon" >
            <img
              src="./assets/Download.svg"
              class="downloadIconDoc"
              @click="downloadClick(file)"
            />
            <b-icon v-if="showCancelOrder(file)" style="cursor: pointer;" icon="x-lg" @click="showDialog(file)"></b-icon>
          </div> 
        </div>
        </div>
        <div
          class="addCard"
          @click="commonDocumentContainerFunc('signed_contract')"
        >
          <img src="./assets/add.svg" class="addIcon" />
        </div>
        <div v-show="(isInternalSales || isSalesOps||isSalesAdmin)&&somContarctDesginId&&isSOMContractSigned">
        <el-button type="primary" @click="openChangeOrder"  :loading="changeOrderLoader">
          Change Order
        </el-button>

        </div>
      </div>
    </div>

    <div class="electBillContainer">
      <div class="flexCont">
        <el-checkbox v-model="financeCheckbox"
          >Finance Approval / Cash</el-checkbox
        >
      </div>
      <div class="gridCOntainer">
        <div
          class="card"
          v-for="(file, index) in computedFinanceFiles"
          :key="index"
        >
          <img
            :src="
              fileUploadAssets[
                `../../../assets/drop/fileUploadImages/${findFileTypeIcon(
                  file
                )}`
              ]
            "
            class="uploadIcon"
          />
          <p class="docFileName">
            <abbr :title="file.display_name" class="abbrTag"
              >{{ file.display_name }}
            </abbr>
          </p>
          <div class="flexIcon">
            <img src="./assets/Download.svg" class="downloadIconDoc" @click="downloadClick(file)"/>
            <img src="./assets/delete.svg" class="deleteIcon" @click="deleteClick(file.id)" />
          </div>
        </div>
        <div class="addCard" @click="commonDocumentContainerFunc('finance')">
          <img src="./assets/add.svg" class="addIcon" />
        </div>
      </div>
    </div>
    <div class="electBillContainer">
      <div class="flexCont">
        <el-checkbox v-model="siteSurveyCheckbox"
          >Site Survey</el-checkbox
        >
      </div>
      <div class="gridCOntainer">
        <div
          class="card"
          v-for="(file, index) in computedSiteSurveyFiles"
          :key="index"
        >
          <img
            :src="
              fileUploadAssets[
                `../../../assets/drop/fileUploadImages/${findFileTypeIcon(
                  file
                )}`
              ]
            "
            class="uploadIcon"
          />
          <p class="docFileName">
            <abbr :title="file.display_name" class="abbrTag"
              >{{ file.display_name }}
            </abbr>
          </p>
          <div class="flexIcon">
            <img src="./assets/Download.svg" class="downloadIconDoc" @click="downloadClick(file)"/>
          </div>
        </div>
        <div v-if="!computedSiteSurveyFiles.length" class="addCard site-survey-card" style="cursor: default">
          <span>No Uploaded Documents</span>
        </div>
        <div class="addCard" @click="commonDocumentContainerFunc('site_survey')" v-if="computedFetchOrganisationId">
          <img src="./assets/add.svg" class="addIcon" />
        </div>
      </div>
    </div>
    <div class="electBillContainer" >
      <div class="flexCont">
        <el-checkbox v-model="permitInterConnectionCheckbox"
          >Permit / Interconnection</el-checkbox
        >
      </div>
      <div class="gridCOntainer">
        <div
          class="card"
          v-for="(file, index) in computedPermitInterConnectionFiles"
          :key="index"
        >
          <img
            :src="
              fileUploadAssets[
                `../../../assets/drop/fileUploadImages/${findFileTypeIcon(
                  file
                )}`
              ]
            "
            class="uploadIcon"
          />
          <p class="docFileName">
            <abbr :title="file.display_name" class="abbrTag"
              >{{ file.display_name }}
            </abbr>
          </p>
          <div class="flexIcon">
            <img src="./assets/Download.svg" class="downloadIconDoc" @click="downloadClick(file)"/>
            <img src="./assets/delete.svg" class="deleteIcon" @click="deleteClick(file.id)" />
          </div>
        </div>
        <div class="addCard" @click="commonDocumentContainerFunc('permit_interconnection')">
          <img src="./assets/add.svg" class="addIcon" />
        </div>
      </div>
    </div>
    <div class="electBillContainer otherDoc">
      <div class="flexCont">
        <el-checkbox v-model="otherDocCheckbox">Other Documents</el-checkbox>
      </div>
      <div class="gridCOntainer">
        <div
          class="card"
          v-for="(file, index) in computedOtherFiles"
          :key="index"
        >
          <img
            :src="
              fileUploadAssets[
                `../../../assets/drop/fileUploadImages/${findFileTypeIcon(
                  file
                )}`
              ]
            "
            class="uploadIcon"
          />
          <p class="docFileName">
            <abbr :title="file.display_name" class="abbrTag"
              >{{ file.display_name }}
            </abbr>
          </p>
          <div class="flexIcon">
            <img src="./assets/Download.svg" class="downloadIconDoc" @click="downloadClick(file)"/>
            <img src="./assets/delete.svg" class="deleteIcon" @click="deleteClick(file.id)"/>
          </div>
        </div>
        <div class="addCard" @click="commonDocumentContainerFunc('other')">
          <img src="./assets/add.svg" class="addIcon" />
        </div>
      </div>
    </div>
    <div class="flexCont lastFlexCont">
      <div></div>
      <p class="downloadAll" @click="downloadAllClick">
        Download All Documents
      </p>
    </div>
    <ShareDocumentPopup
      :isShareDocumentPopupVisible.sync="isShareDocumentPopupVisible"
      :isCRMMode="isCRMMode"
      @confirmUploaded="confirmUploaded"
      :projectId="projectId"
      :documentContainer="documentContainer"
      :ispdf="isPdfType"
    />
    <DeleteProjectDocument
      v-if="isDeleteProjectDocumentPopupOpen"
      :isDeleteProjectDocumentPopupOpen="isDeleteProjectDocumentPopupOpen"
      @cancelDelete="isDeleteProjectDocumentPopupOpen = false"
      @confirmDelete="confirmDelete"
    />
    <GlobalDialog 
    :dialogTitle="'Generate Contract'" 
    :dialogVisible.sync="openDesignList" 
    :width="contractPreview?`1000px` :'800px'" 
    @handleClose="closeDesignList"
    :isModal="false"
    :zeroPadding="true"
    >
          
                <template v-slot:body >
                  <div v-if="contractPreview" style="padding:0 50px;height: calc(100vh - 250px);overflow: hidden;">
                    <iframe ref="iframe" class="custom-iframe" :srcdoc="previewContractData" style="width: 100%; height:100%; border: none;" ></iframe>
                  </div>
                  <div class="body-container" v-else>

                    <div class="left-section">
                      <div  class="select-container">
                      <p class="lable-section">Workflow <span class="required-value">*</span></p>
                      <el-select v-model="selectedWorkflow" placeholder="Select Proposal" class="select-content">
                      <el-option
                      v-for="(item,inx) in wrokflowList"
                      :key="inx"
                      :label="item.name"
                      :value="item.value">
                      </el-option>
                  </el-select>
                </div>
                      <div  class="select-container">
                      <p class="lable-section">Proposal List <span class="required-value">*</span></p>
                    <el-select v-model="selectedDesign" placeholder="Select Proposal" class="select-content">
                      <el-option
                      v-for="item in arkaProductsProposal"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                      </el-option>
                  </el-select>
                </div>
                <div  class="select-container">
                      <p class="lable-section" >Mode of payment <span class="required-value">*</span></p>
                    <el-select v-model="selectedFinance" placeholder="Select Proposal" class="select-content">
                      <el-option
                      v-for="(item,inx) in financeList"
                      :key="inx"
                      :label="item.name"
                      :value="item.value">
                      </el-option>
                  </el-select>
                </div>
                <div  class="select-container">
                  <el-checkbox v-model="includeWaiver">Include concrete waiver in contract</el-checkbox>

                </div>

                    </div>
                    <div class="right-section">
                     <p class="pricing-title">Pricing Details for Selected Proposal</p>
                     <div class="pricing-top-list">

                   
                     <div class="pricing-content">
                      <p class="pricing-label">Total System Price</p>
                      <p  class="pricing-label">{{formatNumber( pricing?.total_system_price  ,'currency',countryCode) }}</p>

                     </div>
                     <div class="pricing-content">
                      <p  class="pricing-label">Potential Incentives</p>
                      <p  class="pricing-label">- {{formatNumber(pricing?.incentives_total_cost ,'currency',countryCode) }}</p>

                     </div>
                     <div class="sublist" v-for="incentive in pricing?.incentives_data" :key="incentive.id">
                <div class="pricing-content" style="padding-left: 16px;">
                      <p  class="incentive-label">{{ incentive.name }}</p>
                      <p class="incentive-value">{{formatNumber(incentive.tot_amount_contribution ,'currency',countryCode)  }}</p>

                     </div>
              </div>
            </div>
                     <div class="pricing-content" style="border-top:1px solid #ccc">
                      <p  class="pricing-label">System Price Post Incentives</p>
                      <p  class="pricing-label">{{formatNumber(pricing?.system_price_post_incentives ,'currency',countryCode)   }}</p>

                     </div>
                     <div class="pricing-content">
                      <p style="color:#777">Pricing can be edited from the design</p>
                     </div>
                    </div>
           
                  </div>
                </template>
                <template v-slot:footer
                    >
                    <div class="contract-footer" >

   
                      <div   v-if="contractPreview">

                        <el-button
          plain
            class="btn"
            @click="backToGenerate"
           
            >
            Back
            </el-button>

                      <el-tooltip placement="top" :disabled="!handleDisableBtn" content="The total system price should be more than 1500." 
                     
                      >

                      <el-button
           type="primary"
            class="btn"
            :loading="contractBtnLoading"
            @click="generateContract(selectedWorkflow,selectedDesign,selectedFinance)"
            :disabled="handleDisableBtn"
            >
            Generate Contract
            </el-button>
            </el-tooltip>
       
          </div>

            <el-tooltip placement="top" :disabled="!handleDisableBtn" content="The total system price should be more than 1500." v-else>

<el-button
type="primary"
class="btn"
@click="goToPreviewContract(selectedWorkflow,selectedDesign,selectedFinance)"
:disabled="handleDisableBtn"
>
Next
</el-button>
</el-tooltip>
                    </div>
                </template>
                
                </GlobalDialog>
                <DialogPopup v-if="showDialogPopup" 
                  :isOpen="showDialogPopup"
                  message="Are you sure you want to cancel the contract?"
                  @onClickNo="showDialogPopup = false"
                  @onClickYes="cancelContract"
                />
       
    <ShareChangeOrder v-if="isChangeContract" :changeContract.sync="tempChangeOrderTemplate" @handleClose="handleCloseAndRefresh()" :contractDesignId="somContarctDesginId"/>

  </div>
</template>

<script>
import API from "@/services/api";
import DeleteProjectDocument from "../../project/components/projectDocuments/deleteProjectDocument.vue";
import ShareDocumentPopup from "../../dashboard/components/shareDocumentPopup.vue";
import fileType from "@/pages/utils/fileType";
import { mapActions, mapState } from "pinia";
import { useLeadStore } from "../../../stores/lead";
import { useProjectStore } from "../../../stores/project";
import {isDocProposalDisabled} from '../../../utils'
import GlobalDialog from "../../commonComponents/GlobalDialog.vue";
import { formatNumber } from "../../../utils/numberFormat/currencyFormatter";
import { fetchOrganisationId } from '../../../utils';
import { org_id_to_add_site_survey_in_doc } from "../../../constants.js";
import DialogPopup from "../../../components/ui/DialogPopup.vue";
import {useDocumentStore} from "../../../stores/documents.js"
import { useContractManagementStore } from "../../../stores/contractManagement.js"; 
import ShareChangeOrder from "../../designSummaryCRM/components/ContractsSection/shareChangeOrder.vue";
import { queryParamsFormater } from "../../../utils/queryParamGenerator.js";



const fileUploadAssets = import.meta.glob(
  "../../../../src//assets/drop//fileUploadImages/*",
  { eager: true, as: "url" }
);

export default {
  components: {
    ShareDocumentPopup,
    DeleteProjectDocument,
    GlobalDialog,
    DialogPopup,
    ShareChangeOrder,
  },

  props: {
    lead: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      uploaded_by_installer: "installer",
      electricityBillCheckbox: false,
      signedSolutionContractCheckbox:false,
      financialContractCheckBox:false,
      signedContractCheckbox: false,
      buttonLoader:false,
      otherDocCheckbox: false,
      financeCheckbox: false,
      siteSurveyCheckbox: false,
      permitInterConnectionCheckbox:false,
      showHistoryBtn: false,
      isShareDocumentPopupVisible: false,
      isDeleteProjectDocumentPopupOpen: false,
      isCRMMode: true,
      fileList: [],
      electricityBillFiles: [],
      signedSolutionContractFiles:[],
      financingContractFiles:[],
      signedContractFiles: [],
      financeFiles: [],
      permitInterConnectionFiles:[],
      role:JSON.parse(localStorage.getItem("user")).role,
      isProjectManager:JSON.parse(localStorage.getItem("user")).is_project_manager,
      siteSurveyFiles: [],
      otherFiles: [],
      fileIdToBeDeleted: "",
      fileDetail:"",
      documentContainer: null,
      fileUploadAssets,
      isPdfType:false,
      arkaProductsProposal:null,
      contractBtnDisabled:true,
      openDesignList:false,
      selectedDesign:null,
      contractBtnLoading:false,
      intervalId: null,
      financeList:[{name:'Cash',value:'cash'},{name:'Financing (PPA/Loan/Lease)',value:'finance'}],
      wrokflowList:[{name:'D2C-ARKA (internal) ', value:'d2c_arka_internal'},{name:'D2C-VIP (External) ', value:'d2c_vip_external'}],
      selectedFinance:null,
      selectedWorkflow:null,
      organisationData:  JSON.parse(localStorage.getItem("organisation")) || {},
      pricing:null,
      showDialogPopup:false,
      currentFile:'',
      isLoading: false,
      countryCode:this.projectInfo?.country_details?.country_code,
      isSalesOps:JSON.parse(localStorage.getItem("user")).role=="ARKA_SALES_OP",
      isInternalSales:JSON.parse(localStorage.getItem("user")).role=="ARKA_INTERNAL_SALES",
      isSalesAdmin:JSON.parse(localStorage.getItem("user")).role=="ADMIN"&&JSON.parse(localStorage.getItem("organisation")).is_vip_aggregator,
      solutionsContractDetails:null,
      contractPreview:false,
      contractPreviewData:null,
      isChangeContract:false,
      tempChangeOrderTemplate:null,
      isSOMContractSigned:false,
      somContarctDesginId:null,
      changeOrderLoader:false,
      includeWaiver:false,
      previewContractData:null,
    };
  },

  created() {
    this.getFiles();
  },
  mounted(){
    // Set interval to call the function every 10 seconds
    this.intervalId = setInterval(this.triggerGetFiles, 10000); // 10000 milliseconds = 10 seconds
  },
  beforeDestroy() {
    // Clear interval when the component is destroyed to prevent memory leaks
    clearInterval(this.intervalId);
  },

  computed: {
    computedFileList() {
      return this.fileList;
    },
    ...mapState(useLeadStore, {
      leadInfo: (state) => state,
    }),
    ...mapState(useProjectStore, {
      projectInfo:state=>state,
      designs: "GET_DESIGNS_DETAILS",
     
    }),
    computedFetchOrganisationId() {
      if(org_id_to_add_site_survey_in_doc == fetchOrganisationId()) {
        return true;
      } else {
        return false;
      }
    },
    computedElectricityBillFiles() {
      return this.electricityBillFiles;
    },
    computedSignedSolutionContractFiles(){
      this.solutionsContractDetails=this.signedSolutionContractFiles.find(d=>d?.addtional_details);
      if(this.solutionsContractDetails){
        const somAdditionalDetails=this.solutionsContractDetails.addtional_details
        this.isSOMContractSigned=somAdditionalDetails.status=="completed"?true:false;
        this.somContarctDesginId=somAdditionalDetails?.design

      }
      return this.signedSolutionContractFiles;
    },
    computedFinancingContract(){
      return this.financingContractFiles;
    },
    computedSignedContractFiles() {
      return this.signedContractFiles;
    },
    computedFinanceFiles() {
      return this.financeFiles;
    },
    computedSiteSurveyFiles() {
      return this.siteSurveyFiles;
    },
    computedPermitInterConnectionFiles(){
      return this.permitInterConnectionFiles;
    },
    computedOtherFiles() {
      return this.otherFiles;
    },
    projectId() {
      return this.lead?.id || this.leadInfo?.project_details?.id
    },
    handleDisableBtn(){
 return !(this.pricing?.total_system_price>1500);


    },
    checkIfFinance(){
    return this.solutionsContractDetails?.addtional_details?.workflow=='d2c_arka_internal'&&this.solutionsContractDetails?.addtional_details?.mode_of_payment=='finance'
    }

  },

  methods: {
    commonDocumentContainerFunc(val) {
      this.isShareDocumentPopupVisible = true;
      this.documentContainer = val;
      this.isPdfType=val==='d2c_contracts'?true:false
    },
    ...mapActions(useLeadStore, {
            setActivity: "SET_ACTIVITY",
            setLead: "SET_LEAD",
            upadteLead:"UPDATE_LEAD_FROM_DRAWER",
            updateWorkFlow:"UPDATE_WORKFLOW_TYPE",
        }),
    ...mapActions(useDocumentStore,{
            setSignedContractDocuments:"SET_SIGNED_CONTRACTS",
    }),
    ...mapActions(useContractManagementStore,{
            updateDesignId:"SET_SELECTED_DESIGN_ID",
    }),
    async cancelContract(){
      const payload = {
        "void_reason":"design issue"
      }
      try{
        const response =   await API.CONTRACT_TEMPLATES.CANCEL_CONTRACT(this.currentFile.addtional_details.id,payload)
        let documentIndex =   this.signedContractFiles.findIndex((document)=>{
          return document.addtional_details.id == response.data.id
        })
        this.signedContractFiles[documentIndex].addtional_details.status = "cancelled"
        this.showDialogPopup = false;
      }
      catch(e){
        console.error('e: ', e);
        this.$message({
          showClose: true,
          message: "Cannot cancel the contract due to missing DocuSign placeholder.",
          type: "error",
          center: true,
        });
      }
    },
    showDialog(file){
      this.currentFile = file;
      this.showDialogPopup=true
    },
    showCancelOrder(file){
      return (file.addtional_details.status &&  !['completed','cancelled','rejected'].includes(file.addtional_details.status))
    },
    callApiOnDocumentTabClick() {
      // Call your API here when "Documents" tab is clicked
    },
    async getFiles() {
      this.isLoading= true;
      let response = await API.DOCUMENT_INFO.FETCH_DOCUMENTS_FOR_CRM(
        this.projectId
      );
      this.fileList = response.data;
      this.electricityBillFiles = [];
      this.signedSolutionContractFiles = [];
      this.financingContractFiles=[];
      this.signedContractFiles = [];
      this.financeFiles = [];
      this.permitInterConnectionFiles=[];
      this.otherFiles = [];

      this.fileList.forEach((obj) => {
        if (obj.document_container == "electricity_bill") {
          this.electricityBillFiles.push(obj);
        }else if (obj.document_container == "d2c_contracts") {
          this.signedSolutionContractFiles.push(obj);
        } else if(obj.document_container == "financing_contracts"){
          this.financingContractFiles.push(obj)
        }
         else if (obj.document_container == "signed_contract") {
          this.signedContractFiles.push(obj);
        } else if (obj.document_container == "finance") {
          this.financeFiles.push(obj);
        } else if (obj.document_container == "site_survey") {
          this.siteSurveyFiles.push(obj);
        }else if(obj.document_container == "permit_interconnection"){
          this.permitInterConnectionFiles.push(obj)
        } else {
          this.otherFiles.push(obj);
        }
      });
      this.setSignedContractDocuments(this.signedContractFiles)
      this.isLoading= false;
    },
    buttonText(val){
        if ( val.addtional_details?.status === 'pending') {
          return 'Send';
        } 
        else {
          return 'Submit to SO';
        }
    },
    async sendContract(data){

      const postData={
        signer_name:this.leadInfo?.name||this.leadInfo?.lead_details?.name,
        signer_email:this.leadInfo?.email||this.leadInfo?.lead_details?.email
      }
      const patchData={
        is_submitted:true
      }
      this.buttonLoader=true
      try{
        if ( data.addtional_details?.status === 'pending') {
          let response = await API.DOCUMENT_INFO.SEND_CONTRACT(data.addtional_details.id,postData);
        } else {
          const postData={
            "event": "envelope-completed",
   
 
   "data": {
       "envelopeId": data?.addtional_details?.envelope_id
    
   }
          }
          // await API.DOCUMENT_INFO.APPROVE_CONTRACT(postData)
          let resp= await API.DOCUMENT_INFO.SUBMIT_CONTRACT(data.addtional_details.id,patchData)
        }
        
        this.getFiles()
        this.buttonLoader=false
      }catch(err){
        console.error(err)
      }
    },
    async downloadAllClick() {
      let arrayIds = [];
      if (this.fileList.length > 0) {
        for (var i = 0; i < this.fileList.length; i++) {
          arrayIds.push(this.fileList[i].id);
        }
      }

      let postData = {
        document_ids: arrayIds,
      };
      let response = await API.DOCUMENT_INFO.DOWNLOAD_DOCUMENTS(postData);
      if (response.data.length > 0) {
        for (let fileUrl of response.data) {
          let splitArray = fileUrl.split("?")[0].split("/");
          let fileName = splitArray[splitArray.length - 1];
          const regForFileName = /-[0-9a-zA-Z]{12}_/;
          let display_name_arr = fileName.split(regForFileName);
          let display_name;
          if (display_name_arr.length > 0) {
            display_name = display_name_arr[display_name_arr.length - 1];
          } else splitArray[splitArray.length - 1];
          saveAs(fileUrl, display_name);
        }
      }
    },

    async downloadSelected() {
      let arrayIds = [];
      let checkboxes = [
        {
          name: "electricityBillCheckbox",
          files: this.computedElectricityBillFiles,
        },
        {
          name: "signedSolutionContractCheckbox",
          files: this.computedSignedSolutionContractFiles,
        },
        {
          name:"financialContractCheckBox",
          files:this.computedFinancingContract,
        },
        {
          name: "signedContractCheckbox",
          files: this.computedSignedContractFiles,
        },
        { name: "otherDocCheckbox", files: this.computedOtherFiles },
        { name: "financeCheckbox", files: this.computedFinanceFiles },
        { name: "siteSurveyCheckbox", files: this.computedSiteSurveyFiles },
        {name:"permitInterConnectionCheckbox",files:this.computedPermitInterConnectionFiles}
      ];

      for (let checkbox of checkboxes) {
        if (this[checkbox.name] && checkbox.files.length > 0) {
          for (let file of checkbox.files) {
            arrayIds.push(file.id);
          }
        }
      }

      if (arrayIds.length > 0) {
        let postData = {
          document_ids: arrayIds,
        };

        let response = await API.DOCUMENT_INFO.DOWNLOAD_DOCUMENTS(postData);

        if (response.data.length > 0) {
          for (let fileUrl of response.data) {
            let splitArray = fileUrl.split("?")[0].split("/");
            let fileName = splitArray[splitArray.length - 1];
            const regForFileName = /-[0-9a-zA-Z]{12}_/;
            let display_name_arr = fileName.split(regForFileName);
            let display_name;
            if (display_name_arr.length > 0) {
              display_name = display_name_arr[display_name_arr.length - 1];
            } else {
              display_name = splitArray[splitArray.length - 1];
            }
            saveAs(fileUrl, display_name);
          }
        }
      } else {
        this.$message({
          showClose: true,
          message: "No checkbox selected or document uploaded.",
          type: "error",
          center: true,
        });
      }
    },

    // async downloadSelected() {
    //   let arrayIds = [];
    //   if(this.electricityBillCheckbox == true) {
    //     if (this.computedElectricityBillFiles.length > 0) {
    //       for (var i = 0; i < this.computedElectricityBillFiles.length; i++) {
    //         arrayIds.push(this.computedElectricityBillFiles[i].id);
    //       }
    //     }
    //     let postData = {
    //       document_ids: arrayIds,
    //     };
    //     let response = await API.DOCUMENT_INFO.DOWNLOAD_DOCUMENTS(postData);
    //     if (response.data.length > 0) {
    //       for (let fileUrl of response.data) {
    //         let splitArray = fileUrl.split('?')[0].split('/')
    //         let fileName = splitArray[splitArray.length - 1]
    //         const regForFileName=/-[0-9a-zA-Z]{12}_/
    //         let display_name_arr=fileName.split(regForFileName)
    //         let display_name;
    //         if(display_name_arr.length>0){
    //           display_name=display_name_arr[display_name_arr.length-1]
    //         }else splitArray[splitArray.length-1];
    //         saveAs(fileUrl, display_name)
    //       }
    //     }
    //   } else {
    //   }
    // },

    async confirmUploaded(val) {
      // this.getFiles();
      this.fileList.push(...val);
      val.forEach((obj) => {
        if (obj.document_container == "electricity_bill") {
          this.electricityBillFiles.push(obj);
        } else if (obj.document_container == "d2c_contracts") {
          this.signedSolutionContractFiles.push(obj);
        } 
        else if (obj.document_container == "financing_contracts") {
          this.financingContractFiles.push(obj);
        } 
        else if (obj.document_container == "signed_contract") {
          this.signedContractFiles.push(obj);
        } else if (obj.document_container == "finance") {
          this.financeFiles.push(obj);
        } else if (obj.document_container == "site_survey") {
          this.siteSurveyFiles.push(obj);
        } else if(obj.document_container == "permit_interconnection"){
          this.permitInterConnectionFiles.push(obj)
        }
        else {
          this.otherFiles.push(obj);
        }
      });
      this.setSignedContractDocuments(this.signedContractFiles)
      let resp = await API.LEADS.FETCH_ACTIVITY(this.projectId);
      this.setActivity(resp.data);
    },

    findFileTypeIcon(file) {
      let iconName = fileType["defaultFile"];
      if (fileType[file.file_type]) {
        iconName = fileType[file.file_type];
      } else {
        if (
          file.display_name.split(".").pop() === "dxf" ||
          file.display_name.split(".").pop() === "dwg"
        ) {
          iconName = fileType["image/x-dxf"];
        }
      }
      return iconName;
    },

    async downloadClick(file) {
      let fileID = file.id;
      let arrayIds = [];
      arrayIds.push(fileID);
      let postData = {
        document_ids: arrayIds,
      };
      let response=''
      let contractResp=''
      if(file?.addtional_details?.status==='completed'){
        contractResp=  await   API.DOCUMENT_INFO.GET_SIGNED_CONTRACT(file?.addtional_details?.signed_document_id)
      }else{
         response = await API.DOCUMENT_INFO.DOWNLOAD_DOCUMENTS(postData);

      }
      let url =contractResp.data|| response.data[0];
      saveAs(url, file.display_name);
    },

    async deleteClick(fileID,fileData) {
      this.isDeleteProjectDocumentPopupOpen = true;
      this.fileIdToBeDeleted = fileID;
      this.fileDetail=fileData
    },

    async confirmDelete(isDelete) {
      if (this.fileIdToBeDeleted) {
        if (isDelete) {
          try {
            let response = await API.DOCUMENT_INFO.DELETE_DOCUMENTS(
              this.fileIdToBeDeleted
            );
            this.fileIdToBeDeleted = "";
          if(this.fileDetail?.document_container=="d2c_contracts"){
        
            await this.upadteLead(this.leadInfo?.lead_details?.id||this.leadInfo?.id, this.leadInfo.id, { workflow_type: null, });
            this.updateWorkFlow(null)
          }
            this.isDeleteProjectDocumentPopupOpen = false;
            this.getFiles();
          } catch (error) {
            let errorMessage =
              error.response.status === 403
                ? "You don't have permission to edit this project."
                : "error";
            this.$message({
              showClose: true,
              message: errorMessage,
              type: "error",
              center: true,
            });

            this.isDeleteProjectDocumentPopupOpen = false;
          }
        }
      } else {
        if (isDelete) {
          let response = await API.DOCUMENT_INFO.DELETE_DOCUMENTS(
            this.projectId
          );
  
          this.fileIdToBeDeletedForDesigner = "";
          this.isDeleteProjectDocumentPopupOpen = false;
          this.getFiles();
        }
      }
      let resp = await API.LEADS.FETCH_ACTIVITY(this.projectId);
      this.setActivity(resp.data);
    },
    checkBtnDisabled(){
      return (this.arkaProductsProposal&&this.arkaProductsProposal.length)?false:true
    },

    handleContract(){
      this.openDesignList=true;

    },

  async  generateContract(workflow,designId,cashType){
    this.contractBtnLoading=true;
 
    try{
      const payload={workflow:workflow,
      design_id:designId,
      mode_of_payment:cashType,
      contract:this.contractPreviewData?.contract,
      liability_waiver:this.contractPreviewData?.concrete_liability_waiver,

    };
      await API.CONTRACT_DETAILS.GENERATE_SEND_CONTRACT(payload);
      this.updateWorkFlow(workflow);
      this.openDesignList=false;
this.getFiles();
this.contractBtnLoading=false;
this.contractPreviewData=null;
this.previewContractData=null;
this.contractPreview=false;

    }
    catch(err){
      this.contractBtnLoading=false;
      this.$message({
            showClose: true,
            message: "There was an unknown error while generating contract",
            type: "error",
            center: true,
          });
    }
    },
    isProposalGenerated(design){
     return isDocProposalDisabled(design)
    },
    triggerGetFiles(){
      if(this.computedSignedSolutionContractFiles?.length>0){
this.computedSignedSolutionContractFiles.map(d=>{
  if(d.addtional_details?.status==="sent"){
    this.getFiles();
  }
}
)
        }
    },
    formatNumber,
 async goToPreviewContract(workflow,designId,cashType){
try{

  const payload={
    workflow:workflow,
    design_id:designId,
    mode_of_payment:cashType,
  };
      this.contractPreview=true;
      const qparams={
        include_liability_waiver:this.includeWaiver,

      };
      const qParamFormate=    queryParamsFormater(qparams);
   const resp= await API.CONTRACT_DETAILS.CREATE_CONTRACT_PREVIEW(payload,qParamFormate);
    // Strip out <html>, <head>, and <body> tags
     let html1 =this.includeWaiver? this.extractBodyAndStyle(resp?.data?.contract, 'html1'):null;
     let   html2 =this.includeWaiver? this.extractBodyAndStyle(resp?.data?.concrete_liability_waiver, 'html2'):null;
           // Combine the extracted content into a single valid HTML document
          const combinedHtml = `
          <!DOCTYPE html>
          <html lang="en">
          <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Combined Content</title>
            <style>
              /* Global or shared styles can go here */
            </style>
          </head>
          <body>
            <div id="html1-container">${html1}</div>
            <div style="border-top:8px solid #ccc;margin-top:30px;margin-bottom:30px;"></div>
            <div id="html2-container">${html2}</div>
          </body>
          </html>
        `;
   this.contractPreviewData= resp?.data;
   this.previewContractData= this.includeWaiver?combinedHtml:resp?.data?.contract;

   this.addCustomScrollbarStylesToIframe();

} catch(err){
  this.$message({
          showClose: true,
          message: "There was an unknown error while generating the preview.",
          type: "error",
          center: true,
        });

}


    },
    extractBodyAndStyle(html, id) {
      // Use regular expressions to extract the <body> and <style> content
      const bodyMatch = html.match(/<body[^>]*>([\s\S]*?)<\/body>/i);
      const styleMatch = html.match(/<style[^>]*>([\s\S]*?)<\/style>/i);

      const bodyContent = bodyMatch ? bodyMatch[1] : '';
      let styleContent = styleMatch ? styleMatch[1] : '';

      // Prefix all style selectors with an ID to prevent CSS conflicts
      if (styleContent) {
        styleContent = styleContent.replace(/([^}]+){/g, `#${id}-container $1 {`);
      }

      return `<style>${styleContent}</style> ${bodyContent}`;
    },
    backToGenerate(){
      this.contractPreview=false;
      this.contractPreviewData=null;
      this.previewContractData=null;
    },
    addCustomScrollbarStylesToIframe() {
      const iframe = this.$refs.iframe;
   if(iframe){   
    iframe.onload = () => {
        const style = document.createElement('style');
        style.innerHTML = `
          /* Custom scrollbar styling for WebKit browsers */
          ::-webkit-scrollbar {
            width: 5px !important;
            height: 5px !important;

          }
          
          ::-webkit-scrollbar-thumb {
            background-color: lightgrey !important;
            border-radius: 2px;
          }
          
          ::-webkit-scrollbar-thumb:hover {
            background-color: green !important;
          }

          ::-webkit-scrollbar-track {
            background-color: transparent !important;
            border-radius: 10px;
          }

          /* Firefox scrollbar styling */
          body {
            scrollbar-color: black !important; /* thumb and track colors */
            scrollbar-width: thin; /* width of scrollbar */
          }
        `;
        iframe.contentDocument.head.appendChild(style);
      };}
    },
    closeDesignList(){
      this.openDesignList=false;
      this.contractPreview=false;
      this.contractPreviewData=null;
      this.previewContractData=null;
    },
   async  openChangeOrder(){
    this.changeOrderLoader=true
    this.tempChangeOrderTemplate=  await this.getChangeOrderData()
      this.isChangeContract=true;
      this.changeOrderLoader=false;
    },
    handleCloseAndRefresh(){
      this.isChangeContract=false;
      this.getFiles();
    },
   async getChangeOrderData(){
    const  response = await API.CONTRACT_TEMPLATES.FETCH_ALL_CONTRACT_TEMPLATES();
    const resp=response?.data?.results;
    const changeOrderTemplate=resp.filter(d=>d.id==34)
    return changeOrderTemplate;
    }

  },
  watch:{ 
    designs:{
      immediate:true,
      handler(val){
        this.arkaProductsProposal=null;
        if(val.length){
          // this.arkaProductsProposal=val.filter(d=>(d.request_expert_service.service_type==="Arka Products Proposal"||d.request_expert_service.service_type==='Solar Sales Proposal')&&!this.isProposalGenerated(d))
          this.arkaProductsProposal=val.filter(d=>(d.request_expert_service.service_type==="Arka Solutions Proposal")&&!this.isProposalGenerated(d))
this.selectedDesign=this.arkaProductsProposal[0]?.id;
this.selectedFinance=this.financeList[0]?.value;
this.selectedWorkflow=this.wrokflowList[0]?.value;
          if(this.arkaProductsProposal&&this.arkaProductsProposal.length>0){
        this.contractBtnDisabled=false
      }else{
        this.contractBtnDisabled=true
      }
                }else{
                  this.contractBtnDisabled=true
                }  
      }
    },
    selectedDesign:{
      immediate:true,
      async handler(val){
        if(val){
          this.contractBtnLoading=true
      const price=   await  API.DESIGNS.FETCH_COSTS(val);
      this.pricing=price?.data;
      this.contractBtnLoading=false;
        }
      }
    }
 
  }
};
</script>

<style scoped>
.docContainer {
  overflow: hidden;
  /* height: 50vh; */
  /* overflow-y: scroll; */
  padding: 0px 24px 32px 24px;
}
.site-survey-card{
  background-color: #e8edf2;
  text-align: center;
  border: none !important;
  padding: 1rem;
}
.electBillContainer {
  margin-bottom: 8px;
}

.flexCont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.lastFlexCont {
  margin-top: 16px;
}

.downloadAll {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #409eff;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}

.downloadAll::after {
  content: "";
  background: url("./assets/Downloadblue.svg");
  width: 16px;
  height: 16px;
  display: block;
}

.gridCOntainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
  padding: 0px 24px;
}

.card {
  width: 160px;
  min-height: 140px;
  display: grid;
  justify-content: center;
  place-items: center;
  gap: 2px;
  text-align: center;
  border-radius: 6px;
  padding: 8px 16px;
  background-color: #e8edf2;
  border: none;
  overflow-x: hidden;
  box-shadow: none;
}
.contractCard{
  width: 160px;
  min-height: 140px;
  display: grid;
  /* justify-content: center; */
  place-items: center;
  gap: 2px;
  text-align: center;
  border-radius: 6px;
  padding: 8px 16px;
  background-color: #e8edf2;
  border: none;
  overflow-x: hidden;
  box-shadow: none;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}
.el-button{
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}
.addCard {
  width: 160px;
  min-height: 140px;
  border: 1px dashed #777;
  border-radius: 6px;
  cursor: pointer;
  display: grid;
  place-items: center;
}

.abbrTag {
  text-decoration: none;
}

.addIcon {
  width: 36px;
  height: 36px;
}

.docFileName {
  font-size: 12px;
  color: #222;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  word-break: break-word;
}

.uploadIcon {
  max-width: 38px;
}

.flexIcon {
  display: flex;
  gap: 16px;
  align-items: center;
}
.acceptedTag{
  margin-left: auto;
  background: #2CC21C;
  color: white;
  border-radius: 3px;
  padding: 2px 5px;
  margin-top: 0px !important;
  position: relative;
  bottom: 8px;
  left: 16px;
}
.pendingTag{
  margin-left: auto;
  background: rgb(202, 214, 30);
  color: white;
  border-radius: 3px;
  padding: 2px 5px;
  margin-top: 0px !important;
  position: relative;
  bottom: 8px;
  left: 16px;
}
.rejectedTag{
  margin-left: auto;
  background: red;
  color: white;
  border-radius: 3px;
  padding: 2px 5px;
  margin-top: 0px !important;
  position: relative;
  bottom: 8px;
  left: 15px;
}
.cancelledTag{
  margin-left: auto;
  background: #999;
  color: white;
  border-radius: 3px;
  padding: 2px 5px;
  margin-top: 0px !important;
  position: relative;
  bottom: 8px;
  left: 15px;
}
.docContainer >>> .el-checkbox {
  display: flex;
  align-items: center;
  gap: 6px;
  height: 60px;
  display: flex;
  align-items: center;
  color: #222;
}

.docContainer >>> .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #222;
}

.docContainer >>> .el-checkbox__label {
  font-size: 16px;
  font-weight: 600;
  color: #222;
}

.docContainer >>> .el-checkbox__inner {
  width: 18px;
  height: 18px;
  border: 1px solid #777;
}

.docContainer >>> .el-checkbox__inner::after {
  border: 2px solid #ffffff;
  border-left: 0;
  border-top: 0;
  height: 9px;
  left: 5px;
}

.downloadIconDoc,
.deleteIcon {
  cursor: pointer;
}

.select-container>>>.el-input__inner {
  border: none;
  background: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 48px;
  color: black;
  border:1px solid #999;
}

.select-container>>>.el-select .el-input .el-select__caret {
  color: black;
  font-size: 20px;
}

.select-container>>>.el-input__inner::placeholder {
  font-size: 14px;
  color: #777;
}

.el-select-dropdown__item {
  min-height: 42px !important;
  height: auto !important;
  line-height: 40px !important;
  padding: 0px 12px !important;
  color: black;
}

.el-select-dropdown__item.selected {
  color: black !important;
  font-weight: 500;
}

.select-container>>>.el-input__prefix {
  padding-top: 10px !important
}
.body-container{
  display: flex;
  gap:16px;
  padding:0 16px;
  height: 450px;
}
.left-section{
  width:50%
}
.right-section{
  width:50%;
  border:1px solid #999;
  border-radius: 4px;
  padding:16px;
  height: fit-content;
}
.contract-footer{
  margin-top: 16px;
  padding: 16px;
  display: flex; 
  justify-content:end;
  border-top:1px solid #999;

}
.select-content{
  display:flex;
  justify-content:center;
  padding:8px 0;
}
.lable-section{
  font-size: 14px;
  color:#777;
}
.pricing-title{
  font-size: 16px;
  font-weight:600;
  color:#1C3366;

}
.pricing-content{
  padding-top: 16px;
  display: flex;
  justify-content: space-between;
}
.pricing-top-list{
  padding-bottom:16px;
}
.select-container{
  padding-bottom:16px;
}
.incentive-label{
  overflow: hidden;
    color: rgb(119, 119, 119);
    font-size: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 50%;
}
.incentive-value{
  font-size:16px;
  color:#777;
}
.pricing-label{
  font-size: 16px;
  font-weight: 500;
}
.required-value{
  color:#FF4340
}

</style>
