import { PATIO_OBJECT } from "../../coreConstants";
import { getPatios } from "../../utils/exporters";

export default class AttachObject {
    constructor(stage, object) {
        this.stage = stage;
        this.attachments = [];
        this.attachedTo = object;
        this.hidden = false;
    }

    saveObject() {
        const patiosData = [];
        const savedData = {};
        for (let i = 0; i < this.attachments.length; i++) {
            if (this.attachments[i].objectType === PATIO_OBJECT) {
                patiosData.push(this.attachments[i].id);
            }
        }
        savedData.patios = patiosData;
        return savedData;
    }

    loadObject(loadData) {
        const patios = loadData.patios.map(ele => this.getPatioByID(ele));
        patios.forEach(ele => {
            ele.setAttachment(this);
            // save the state of attachment as we are loading attachments after loading all objects
            ele.saveState({ withoutContainer: true });
        })
        this.attachments = [...patios];
    }

    saveState() {
        const attachedData = {};
        for (let i = 0; i < this.attachments.length; i++) {
            if (this.attachments[i].objectType === PATIO_OBJECT) {
                attachedData.push(this.attachments[i].uuid);
            }
        }
        return attachedData;
    }

    loadState(loadData) {
        const patios = loadData.patios.map(ele => this.stage.getObject(ele));
        this.attachments = [...patios];
    }

    moveObject(deltaX, deltaY, deltaZ = 0) {
        this.attachments.forEach(object => {
            // move the attached patio edge when the model is moved
            if (object.objectType === PATIO_OBJECT) {
                object.attachedPatioEdge[0].x += deltaX;
                object.attachedPatioEdge[1].x += deltaX;
                object.attachedPatioEdge[0].y += deltaY;
                object.attachedPatioEdge[1].y += deltaY;
                object.attachedPatioEdge[0].z += deltaZ;
                object.attachedPatioEdge[1].z += deltaZ;
            }
            object.moveObject(deltaX, deltaY, deltaZ)
        });
    }

    rotateObjectHelper(angleInRad, centroidPoint) {
        this.attachments.forEach(object => object.rotateObjectHelper(angleInRad, centroidPoint));
    }

    hideAttachments() {
        this.hidden = true;
        this.attachments.forEach(object => object.hideObject());
        // update structure if the height is being changed in 3D
        if (this.stage.visualManager.getIn3D()) {
            this.stage.createStructures();
        }
    }

    showAttachments() {
        this.hidden = false;
        this.attachments.forEach(object => object.showObject());
        // update structure if the height is being changed in 3D
        if (this.stage.visualManager.getIn3D()) {
            this.stage.createStructures();
        }
    }

    /**to remove the attachement with the model */
    removeAttachmentFromScene() {
        const attachments = [...this.attachments];
        attachments.forEach(object => object.removeObject())
        // update structure if the height is being changed in 3D
        if (this.stage.visualManager.getIn3D()) {
            this.stage.createStructures();
        }
    }

    addAttachment(object) {
        if (!this.attachments.includes(object)) {
            this.attachments.push(object);
            object.setAttachment(this);
        }
    }

    removeAttachment(object) {
        const childIndex = this.attachments.indexOf(object);
        if (childIndex > -1) {
            const removedChild = this.attachments.splice(childIndex, 1).shift();
            removedChild.attachment = null;
            return removedChild;
        }

        console.error('ERROR: BaseObject: Child cannot be removed since it is not a child of parent');
        return null;
    }

    initRotation() {
        if (this.attachments.length > 0) {
            const attachment = [...this.attachments];
            for (let i = 0, l = attachment.length; i < l; i += 1) {
                attachment[i].saveInitPanelRotation();
            }
        }
    }

    endRotation() {
        if (this.attachments.length > 0) {
            const attachment = [...this.attachments];
            for (let i = 0, l = attachment.length; i < l; i += 1) {
                attachment[i].onPatioRotationEnd();
            }
        }
    }

    getAttachments() {
        return this.attachments;
    }

    getPatioByID(id) {
        const patios = []
        getPatios(this.stage.ground, patios);
        for(let i = 0; i < patios.length; i++) {
            if (patios[i].id === id) {
                return patios[i]
            }
        }
        console.error('Patio not found with id: ', id);
        return null;
    }
}