<template>
  <div>
    <template v-if="button.disableCondition">
      <el-tooltip effect="dark" placement="top">
        <template v-slot:content>
        <div>
          {{ button.tooltip }}
        </div>
        </template>
        <div>
          <el-button
            :loading="loading"
            :type="button.type"
            :class="button.className"
            :style="button.styleDisabled"
            @click="handleButtonClick"
            :disabled="button.disableCondition"
          >
            {{ button.label }}
          </el-button>
        </div>
      </el-tooltip>
    </template>
    <template v-else>
      <el-button
        :loading="loading"
        :type="button.type"
        :class="button.className"
        :style="button.styleEnabled"
        @click="handleButtonClick"
        :disabled="button.disableCondition"
      >
        {{ button.label }}
      </el-button>
    </template>
  </div>
</template>

<script>
export default {
  emits: ["click"],
  name: "ReusableButton",
  props: {
    button: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleButtonClick() {
      this.$emit("click");
    },
  },
};
</script>
<style scoped>
.el-button.is-disabled {
  pointer-events: none;
}
</style>
