
<div class="main-container">
    <div :v-if="showlogoutconfirmbox"> 
        <vue-confirm-dialog class = "ConfirmBox"></vue-confirm-dialog>
    </div>
    <div class="login-container" v-loading="isLogin">
        <div class="same-container">
        <div class="logoContainer">
            <div class="logo">
                <img :src="logoBasedOnDomain" alt="Logo" width="200px" height="auto" class="logoLog"/></div>
            <div class="heading1">Login</div>
            <div class="heading2">Welcome back! Please enter your email and password to access your account.</div>
        </div>    
            <form class="form-container">
                <div class="input-container">
                    <div class="label" id="label1">Email</div>
                    <div class="input-block">
                        <input type="text" placeholder="Enter Email" v-model="userName" required @input="inputUserNameCheck()" @keyup.enter="login"/>
                        <div class="tooltip" id="username-tooltip"></div>
                        <div id="err-email" class="err-message-block"></div>
                    </div>
                </div>
                <div class="input-container">
                    <div class="label" id="label2">Password</div>
                    <div class="input-block">
                        <input type="password" placeholder="Enter Password" id="password" v-model="userPassword" required @input="inputPasswordCheck()" @keyup.enter="login"/>
                        <div id="show-pwd" @click="togglePassword">Show</div>
                        <div class="tooltip" id="pwd-tooltip">Warning: Caps lock is on</div>
                        <div id="err-pwd" class="err-message-block"></div>
                    </div>
                </div>
                <div class="forgot-pwd">
                    <div class="left">
                        <input type="checkbox" v-model="isLoginActive">
                        <div class="chkBxCnt">Keep me signed in</div>
                    </div>
                    <div style="cursor: pointer" @click="handleModal('open', 'reset-pwd')">Forgot Password?</div>

                </div>
                <div class="button-container">
                    <div class="button" @click="login">Login</div>
                </div>
                <div class="policy-container">
                    <a href="https://arka360.com/terms" target="_blank">Terms & Conditions</a>
                    <a href="https://arka360.com/privacy" target="_blank">Privacy Policy</a>
                </div>
            </form>
            <div class="signup">Don’t have an account? <span class="spnSU">Sign Up</span></div>
        </div>
    </div>
    <div class="modal-background hide" id="modal-background"></div>
    <div class="modal-container hide" id="modal-container">
        <div class="reset-pwd-block" id="reset-pwd">
            <div class="cancel-icon" @click="handleModal('close', 'reset-pwd')">
                <img src="../../assets/drop/group-167.png" alt="X" height="90%" width="90%" />
            </div>
            <form class="reset-form">
                <div class="text1">Reset Password</div>
                <div class="text2">
                    <p>Enter your registered email id and we'll send you a password reset link</p>
                </div>
                <div class="input-container">
                    <input type="text" placeholder="Enter Email Id" v-model="userResetEmail" />
                    <div id="err-reset-email" >hello</div>
                </div>
                <div class="button-container">
                    <div class="button" @click="passwordResetConfirm">Send Link</div>
                </div>
            </form>
        </div>
        <div class="resend-mail-block" id="resend-verification">
            <div class="cancel-icon" @click="handleModal('close', 'resend-verification')">
                <img src="../../assets/drop/group-167.png" alt="X" height="90%" width="90%" />
            </div>
            <form class="resend-form">
                <div class="text1">Verify Email Id</div>
                <div class="text2">
                    <div>Please check your registered email inbox for the verification link.</div>
                </div>
                <div class="button-container">
                    <div class="button" @click="resendVerificationLink">Resend Verification Link.</div>
                </div>
            </form>
        </div>
    </div>
    <div id="error-msg-block">username or password is incorrect</div>
</div>

