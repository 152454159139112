<template>
  <div class="inputview-container">
    <p class="title">
      {{ inputDetails.title }}
      <span v-if="inputDetails.isStar" class="astrisk"> *</span>
    </p>
    <el-tooltip :content="inputData" placement="top-start" :hide-after="0" :disabled="!showTooltip(inputData)">

    <el-input
     @input="nameValidation"
      :placeholder="inputDetails.placeholder"
      maxlength="100"
      v-model="inputData"
    ></el-input>
    </el-tooltip>
    <p v-if="errorMessage" style="color: red;">Maximun characters limit allowed is 100</p>
  </div>
</template>

<script>
export default {
  emits: ["item-selected"],
  name: "inputView",
  data() {
    return {
      inputData: "",
      errorMessage:false,
      inputDetails: "",
    };
  },
  props: {
    itemValue: {
      type: Object,
      default: () => {},
    },
    selectedValue: {
      type: String,
      default: () => "",
    },
    indexOfOptions: {
      type: Number,
      default: null,
    },
    widgetId:{
      type:[Number,String],
      default:null
    }
  },
  mounted() {
    this.inputData = this.$props.selectedValue;
    this.inputDetails = this.$props.itemValue;
  },
  methods:{
    showTooltip(value) {
      // Check the length of the value and decide whether to show the tooltip
      return  value && value.toString().length > 14;
    },
    nameValidation(){
      if(this.inputData.length==100){
        this.errorMessage=true
      }else{
        this.errorMessage=false
      }
    },
  },
  computed: {
    inputValue() {
      return this.inputData;
    },
  },

  watch: {
    selectedValue(val) {
      if(!val){
        this.inputData=''
      }
if(this.$props.widgetId){
  this.inputData =this.inputData?this.inputData: val;

}else{
  this.inputData =val?val: this.inputData;
}

    },
    inputData: {
      handler(value) {
        this.$emit(
          "item-selected",
          this.$props.itemValue.prop,
          value,
          value,
          this.$props.itemValue,
          this.$props.indexOfOptions,
          false
        );
      },
    },
  },
};
</script>

<style scoped>
.inputview-container {
}
.title {
  padding-bottom: 0.5rem;
  font-size: 12px;
  color: #777;
  font-weight: 600;
}

.inputview-container >>> .el-input__inner {
  background: #e8edf2;
  color:black;
}
.inputview-container >>> .el-input__inner::placeholder {
  font-size: 14px;
  color: #777;
}
.astrisk {
  font-size: 12px;
  font-weight: 600;
  color: #f00;
}
</style>
