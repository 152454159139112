
    <div id="parent-container" v-loading="isPageLoading">
			<!-- Here changed id to editor2 as it was messign with the parent compoent -->
			<div v-if="editorMode" id="editor2" name="editor" class="editor jodit-document">    
			</div>
			<div v-else id="paginated-editor" class="preview">
			</div>
			<div id="paginated-editor-temp" class="preview"></div>
			<PaymentSchedulePage
				v-if="isPaymentSchedulePageVisible  && isInPreviewMode && isContractInDesignLevel && includePaymentSchedule && paymentMethodType!='loan'"
				:isInPreviewMode="isInPreviewMode"
			/>
    </div>
