
<div class="card" :style="borderStyle">
  <CardHeader
    :heading="headingText"
    :editMode="editMode"
    @mode-open="openPopOver"
  >
    <slot name="header"></slot>
  </CardHeader>

  <CardBody :chartType="chartType">
    <slot name="body"></slot>
  </CardBody>
</div>
