import axios from "axios";
export default {
    FETCH_ORDER_METHOAD_TYPE(reqObj) {
        return axios.get(`/api/request-expert-services/?order_status=${reqObj}`);
    },
    LOAD_MORE_ORDER(url) {
        return axios.get(url);
    },
    PATCH_SERVICE_PAYLOAD(id,payload){
        return axios.patch(`api/request-expert-services/${id}/`,payload)
    },
    CREATE_ORDER(payload) {
        return axios.post(`api/request-expert-services/`, payload)
    },
    GET_ORDER_INFORMATION(id) {
        return axios.get(`api/request-expert-services/${id}/`);
    },
    GET_INVERTER_INFO(id) {
        return axios.get(`/api/master-data/master-inverters/${id}/`);
    },
    ADD_STAMPING(id, payload) {
        return axios.patch(`api/request-expert-services/${id}/`, payload)
    }
};