
<div class="createLead">
  <slot name="header">
    <div class="title">
      <h4
        style="
          font-weight: bolder;
          padding: 1rem 0.5rem 20px;
          user-select: none;
        "
        @click="autofillValues"
      >
        Create Lead
      </h4>
      <span
        class="cross"
        @click="onClose()"
        style="
          font-weight: bolder;
          padding: 0.75rem 0.5rem 20px;
          cursor: pointer;
        "
        >&#x2716;</span
      >
    </div>
  </slot>
  <slot name="body">
    <div class="parentBody">
      <div class="parent">
        <div class="forLabel">
          <label for="" class="labelClass"
            >Full Name<span class="astrisk"> *</span></label
          >
          <el-input
            placeholder="Enter Name"
            v-model="userDetails.leadName"
            v-validate="firstNameValidation"
            name="firstName"
            @input="isLeadName($event)"
          ></el-input>
          <p class="errorMsg" v-show="errors.has('firstName')">
            This field is required.
          </p>
        </div>
        <div class="forLabel">
          <label for="" class="labelClass"
            >Select Owner<span class="astrisk"> *</span></label
          >
          <el-select
            placeholder="Select an Owner"
            v-model="userDetails.ownerName"
            name="ownerName"
            v-validate="ownerValidation"
          >
            <el-option
              v-for="owner in owners"
              :key="owner.id"
              :label="owner.first_name + ' ' + owner.last_name"
              :value="owner.id"
            >
              <span
                v-if="checkdifferntOrg(owner)"
                style="padding-right: 0.5rem"
              >
                <img src="../../../assets/img/personfill.svg" alt="" />
              </span>
              <span v-else style="padding-right: 0.5rem">
                <img src="../../../assets/img/buildings.svg" alt="" />
              </span>
              <span> {{ owner.first_name + " " + owner.last_name }} </span>
            </el-option>
            <ScrollObserver
                :showLoader="isLoadingPaginationResults"
                @intersect="loadMoreUsers"
              />
          </el-select>
          <p class="errorMsg" v-show="errors.has('ownerName')">
            This field is required.
          </p>
        </div>
        <div class="forLabel">
          <label for="" class="labelClass"
            >Email ID<span class="astrisk"> *</span></label
          >
          <el-input
            type="email"
            placeholder="Enter Email ID"
            v-model="userDetails.leadEmailId"
            v-validate="emailValidation"
            name="email id"
            @input="isEmail()"
          ></el-input>
          <p
            class="errorMsg"
            v-show="errors.has('email id') && isEmailTooLong"
          >
            <span>Email address is too long.</span>
          </p>
          <p class="errorMsg" v-show="errors.has('email id')">
            Please enter a valid email ID.
          </p>
          <p
            class="errorMsg"
            v-if="hasInteractedWithEmail && userDetails.leadEmailId == ''"
          >
            This field is required.
          </p>
        </div>
        <div class="forLabel">
          <label for="" class="labelClass">Phone number</label>
          <el-input
            onkeypress="return ((event.charCode > 47 && 
            event.charCode < 58) || (event.charCode === 40 || event.charCode === 41 || event.charCode === 43||event.charCode === 45))"
            placeholder="Enter phone number"
            v-model="userDetails.leadPhone"
            type="text"
            v-validate="phNoValidation"
            name="mobile number"
            @input="isPhNo()"
          >
          </el-input>
          <p
            class="errorMsg"
            v-show="errors.has('mobile number') || isPhoneNoTooLong"
          >
            <span v-show="isPhoneNoTooLong">Phone Number is too long.</span>
          </p>
          <p class="errorMsg" v-show="errors.has('mobile number')">
            This field is required.
          </p>
        </div>
        <div class="forLabel">
          <label for="" class="labelClass"
            >Property Type<span class="astrisk"> *</span></label
          >
          <el-select
            placeholder="Select a property type"
            v-model="userDetails.propertyType"
            name="propertyType"
            v-validate="propertyTypeValidation"
            @change="onChangePropertyType"
          >
            <el-option
              v-for="propertyType in propertyType"
              :key="propertyType.value"
              :label="propertyType.label"
              :value="propertyType.value"
            >
            </el-option>
          </el-select>
          <p class="errorMsg" v-show="errors.has('propertyType')">
            This field is required.
          </p>
        </div>
        <div class="forLabel">
          <label for="" class="labelClass">Lead Source</label>
          <el-select
            placeholder="Select a lead source type"
            v-model="userDetails.leadSource"
            name="leadSource"
            @change="onLeadSourceChange(userDetails.leadSource)"
          >
            <el-option
              v-for="stage in leadSource"
              :key="stage.id"
              :label="stage.name"
              :value="stage.id"
            >
            </el-option>
          </el-select>
        </div>
        <div
          class="forLabel"
          v-if="selectedLeadSourceObject.name == 'Referral'"
        >
          <label for="" class="labelClass">Referral Agent <span class="astrisk"> *</span></label>

          <div class="referral_agent">
            <el-select
              v-model="userDetails.referralAgent"
              filterable
              :filter-method="filterReferralMethod"
              @visible-change="getReferralAgentData"
              name="referral"
              placeholder="Select Existing Lead"
              v-validate="referralAgentValidation"
            >
              <el-option
                v-for="item in agentsDetails"
                :key="item?.lead_details?.id"
                :label="item?.lead_details?.name"
                :value="item?.lead_details?.id"
              >
                <div style="display: flex; flex-direction: column">
                  <el-tooltip class="item" effect="dark" :content="item?.lead_details?.name" placement="top-start">
                  <span
                    style="
                      color: rgba(34, 34, 34, 1);
                      font-size: 16px;
                      font-weight: 400;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      width:250px
                    "
                    >{{ item?.lead_details?.name }}</span
                  >
                  </el-tooltip>
                  <el-tooltip class="item" effect="dark" :content="item?.lead_details?.email" placement="top-start">
                  <span
                    style="
                      color: rgba(119, 119, 119, 1);
                      font-size: 12px;
                      font-weight: 400;
                      margin-top: -15px;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      width:200px
                    "
                    >{{ item?.lead_details?.email }}</span
                  >
                </el-tooltip>
                </div>
              </el-option>
              <ScrollObserver
                :showLoader="isLoadingPaginationResults"
                @intersect="loadMoreReferralAgents"
              />
            </el-select>
            <p class="errorMsg" v-show="errors.has('referral')">
            This field is required.
          </p>
          </div>
        </div>
        <div
          class="forLabel"
          v-if="selectedLeadSourceObject.name == 'Partner'"
        >
          <label for="" class="labelClass">
            Partner Agent <span class="astrisk"> *</span>
          </label>
          <el-select
            v-model="userDetails.partnersDetail"
            filterable
            placeholder="Search Existing Partners"
            v-validate="partnerValidation"
            name="partners"
            @change="selectedPartnersCostPerLead(userDetails.partnersDetail)"
          >
            <el-option
              v-for="item in filteredPartnersDetails"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            <div style="display:flex">
              <span
                    style="
                      color: rgba(34, 34, 34, 1);
                      font-size: 16px;
                      font-weight: 400;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      width:250px
                    "
                    >{{ item.name }}
                    </span>
            </div>
 
            </el-option>
            <el-option
              v-if="partnersDetails.length === 0"
              label="Add Partners"
              disabled
              >Add Partners</el-option
            >
            <ScrollObserver
              :showLoader="isLoadingPaginationResults"
              @intersect="loadMorePartnersData"
            />
          </el-select>
          <p class="errorMsg" v-show="errors.has('partners')">
            This field is required.
          </p>
        </div>
        <div
          class="forLabel"
          v-if="selectedLeadSourceObject.name == 'Partner'"
        >
          <label for="" class="labelClass">Cost Per Lead</label>
          <el-input
            onkeypress="return ((event.charCode > 45 && 
            event.charCode < 58) || (event.charCode === 40 || event.charCode === 41 || event.charCode === 43||event.charCode === 45))"
            placeholder="Enter Cost Per Lead"
            v-model="userDetails.costPerLead"
            maxlength="5"
          >
            <template #prepend>
              {{ selectedCurrencyCode ? selectedCurrencyCode : "$" }}
            </template>
          </el-input>
        </div>
        <div
          class="forLabel"
          v-if="selectedLeadSourceObject.name == 'Partner'"
        >
          <label for="" class="labelClass">Paid to Partner</label>
          <el-radio-group v-model="userDetails.paidToPartners">
            <el-radio label="Yes">Yes</el-radio>
            <el-radio label="No">No</el-radio>
          </el-radio-group>
        </div>
        <div class="forLabel">
          <label for="" class="labelClass"
            >Pipeline<span class="astrisk"> *</span></label
          >
          <el-select
            placeholder="Select a Pipeline"
            @change="selectedPipeline"
            v-model="userDetails.pipeline"
            name="pipeline"
            v-validate="stageValidation"
            :disabled="isPipelineSelectionDisabled"
          >
            <el-option
              v-for="stage in pipeline"
              :key="stage.id"
              :label="stage.name"
              :value="stage.id"
            >
            </el-option>
          </el-select>
          <p class="errorMsg" v-show="errors.has('pipeline')">
            This field is required.
          </p>
        </div>
        <div class="forLabel">
          <label for="" class="labelClass"
            >Stage<span class="astrisk"> *</span></label
          >
          <el-select
            placeholder="Select a Stage"
            v-model="userDetails.stage"
            name="stage"
            v-validate="stageValidation"
            :disabled="disablestages"
          >
            <el-option
              v-for="stage in stages"
              :key="stage.id"
              :label="stage.name"
              :value="stage.id"
            >
              {{ stage.name }}
              <b-icon
                style="margin-left: 10px"
                :color="iconColor(stage)"
                :icon="showIcon(stage)"
              ></b-icon>
            </el-option>
          </el-select>
          <p class="errorMsg" v-show="errors.has('stage')">
            This field is required.
          </p>
        </div>
        <div class="forLabel">
          <label for="" class="labelClass"
            >Task List<span class="astrisk"> *</span></label
          >
          <el-select
            placeholder="Select a Task list"
            v-model="userDetails.task_list"
            name="task list"
            v-validate="stageValidation"
          >
            <el-option
              v-for="task in taskListOption"
              :key="task.id"
              :label="task.name"
              :value="task.id"
            >
            {{ task.name }}
              <b-icon
                style="margin-left: 10px"
                :color="iconTaskColor(task)"
                :icon="showTaskIcon(task)"
              ></b-icon>
            </el-option>
          </el-select>
          <p class="errorMsg" v-show="errors.has('task list')">
            This field is required.
          </p>
        </div>
        <div v-if="false" class="forLabel">  <!--  JIRA ticket - AT-10988 -->
          <label for="" class="labelClass">Region</label>
          <el-select
            placeholder="Select a Region"
            v-model="userDetails.region"
            name="region"
          >
            <el-option
              v-for="region in regionsOptions"
              :key="region.id"
              :label="region.name"
              :value="region.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="forLabel" v-if="false"> <!--  JIRA ticket - AT-10988 -->
          <label for="" class="labelClass">Probability</label>
          <el-input
            placeholder="Probability"
            type="number"
            v-model="userDetails.probability"
            name="probability"
            :min="0"
            :max="100"
            @input="validateInput"
          >
            <template v-slot:append><span class="suffex">%</span></template>
          </el-input>
        </div>
      </div>
      <div class="container">
        <div class="oneColumn">
          <div class="column forLabel">
            <label for="" class="labelClass">Address</label>
            <div class="createLeadSearchBarWrapper">
              <GoogleMapsAutocompleteInputVue
                :isNew="true"
                @input="showManualInputError = false"
                @placesChanged="
                  isMapDisabled = false;
                  showManualInputError = false;
                "
                :placeholder="'Enter the property address'"
              />
              <p class="errorMsg" v-show="showManualInputError">
                Please select an address from the dropdown.
              </p>
            </div>
          </div>
          <div class="column forLabel">
            <label for="" class="labelClass">Target Close Date</label>
            <DatePicker
              :label="false"
              @dateChanged="onDateChange"
              :disabledPrev="true"
            />
          </div>
          <div class="column forLabel dealValueDiv">
            <label for="" class="labelClass">Deal Value</label>
            <p class="currencyIcon">{{ currencySymbol }}</p>
            <el-input
              placeholder="Enter potential deal value"
              class="inputs-with-units"
              v-model="userDetails.dealValue"
              name="dealValue"
              type="number"
            >
            </el-input>
          </div>
          <!-- <div class="column forLabel" style="width: 173%">
            <label for="" class="labelClass">Tags</label>
            <Tags />
          </div> -->
        </div>
        <div class="big-div forLabel">
          <div class="map">
            <div class="map-container">
              <div
                class="map-disabled-overlay"
                v-if="isMapDisabled"
                style="display: flex; flex-direction: column"
              >
                <img src="./assests/location_logo.svg" />
                <p style="padding-top: 26px" class="map-disabled-text">
                  Enter an address to view the map
                </p>
              </div>
              <!-- <p class="formHeadings"> Mark Location </p> -->
              <newProjectMapSelector
                :geo-location="geoLocation"
                :place="placeForMap"
                @change="showManualInputError = false"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="column forLabel" style="width: 100%">
        <label for="" class="labelClass">Tags</label>
        <!-- <el-select multiple filterable allow-create placeholder="Add tags to help your group leads (optional)" v-model="userDetails.tags"></el-select> -->
        <Tags
          :isCreateLeadMode="true"
          :isUsingStore="false"
          @fetchTagsToSendForLead="fetchTagsToSendForLead"
        />
      </div>
    </div>
  </slot>
  <slot name="footer">
    <div class="footerContainer">
      <el-button
        type="primary"
        class="leadBtn"
        @click="confirmSubmit()"
        :loading="loadingStateButton"
        :disabled="errors.items.length > 0 || isdisabled"
        >Create</el-button
      >
    </div>
  </slot>
  <DealValue
    v-if="dealCheck"
    :isDeleteProjectDocumentPopupOpen="true"
    :title="`Deal Value Check`"
    :info="`Deal value for this lead is ${currencySymbol}0.`"
    :info2="` Do you want to continue without updating it?`"
    @cancelDelete="cancelDelete"
    @confirmDelete="confirmDelete"
  />
</div>
