<template>
  <div :style="{width: errorMessage=='Design'? '100%' :'', height:errorMessage=='Design'? '100%' :'',display: errorMessage=='Design'? 'flex' :''}" class="container" >
    <div v-if="orderData||errorMessage">
    <div style="display: flex;margin: auto; flex-direction: column; align-items: center;" v-if="errorMessage">

      <h3 v-if="errorMessage == 'Design'" style="margin-bottom: 10px;">The Design API has failed.</h3>
      <div v-else>
        <h3 class="error-heading">
          An unexpected error occurred. There might be more details below.
        </h3>
        <div class="error-text">
          {{ errorText }}
        </div>
      </div>
      <el-button v-if="errorMessage == 'Design'"  @click="sendErrorEmail">Send Error Report</el-button>
    </div>
    <div v-else >
    <div>
      <el-row class="heading">
        <el-col :span="8" class="title"> Order Details</el-col>
        <el-col v-if="!loader" :span="16" class="btn-container">
          <div
            v-if="
              orderData?.add_ons_availed &&
              orderData.add_ons_availed?.length > 0
            "
          >
            <el-tag
              style="margin-right: 0.5rem"
              v-for="(addons, inx) in orderData.add_ons_availed"
              :key="inx"
              class="tag"
            >
              {{ addons }}
            </el-tag>
          </div>
          <!-- <div>
            <el-button @click="handleWebProposal()">web</el-button>
            <el-button @click="handleWebDoc">Doc</el-button>
            <el-button @click="handle3D">3D</el-button>
          </div> -->
          <div></div>
          <el-tooltip
                  class="item"
                  effect="dark"
                  :content="'Select the owner to assign the order'"
                  placement="top"
                  :disabled="disableAccept(orderData)"
                >
          <button
            v-if="
              orderData.order_status === 'order_placed'
            "
            class="btn btn-primary btn-spacing"
            data-toggle="modal"
            data-target="#make_panel"
            size="mini"
            @click="handleAccept"
            :disabled="!disableAccept(orderData)||acceptLoader"
            v-loading="acceptLoader"
          >
            Accept
          </button>
          </el-tooltip>
          <!-- <button
            class="btn btn-danger"
            @click="resetSearch()"
            data-toggle="modal"
            data-target="#make_panel"
          >
            Reject
          </button> -->
          <rejectDialog
            v-if="orderData?.order_status !== 'rejected' && orderData?.order_status !== 'cancelled'"
            :orderData="orderData"
            :orderDetailsPopVisible.sync="propsData.orderDetailsPopVisible"
            :rejectStatus.sync="rejectStatus"
          />
        </el-col>
      </el-row>
    </div>

    <!-- <div  > -->
    <el-row v-loading="loader"  class="body">
      <el-col
        :span="12"
        v-for="(label, ind) in labelDataOptions"
        :key="ind"
        class="body-container"
      >
        <el-row class="label">
          {{ label.label  }}
        </el-row>

        <el-row class="value">
          <el-select
            v-if="label.inputType == 'select'"
            placeholder="Select an Owner"
            v-model="orderData[label.prop]"
            :disabled="label.prop==='order_status' && (orderData[label.prop]==='order_placed'||orderData[label.prop]==='incomplete'||orderData[label.prop]==='cancelled')"
            @change="onAssigneeChange($event, label.prop, orderData)"
          >
            <el-option
              v-for="(item, ind) in label.options"
              :key="ind"
              :label="item.label"
              :value="item.value"
              class="owner-dropdown-option"
            >
              <el-badge
                is-dot
                v-if="item.shifts"
                :type="getType(item.shifts)"
                class="dot-badge"
              ></el-badge>
              {{ item.label }}
              <span v-if="item.competence" :style="{ paddingLeft: '1rem' }">
                <el-tag
                  v-for="(comp, indx) in item.competence"
                  :key="indx"
                  class="tag"
                  :color="handleColorLightning(handleColor(comp), 80)"
                  :style="{
                    color: `${handleColor(comp)}`,
                    marginLeft: '0.2rem',
                  }"
                  type="red"
                  size="mini"
                >
                  {{ comp }}
                </el-tag>
              </span>
            </el-option>
            <div
            v-infinite-scroll="loadMoreOwners(label.nextUrl)"
            infinite-scroll-disabled="ownersBusy"
            infinite-scroll-distance="10"
            class="loading-icon"
            >
              <i v-if="ownersBusy" class="el-icon-loading infiniteScrollLoader"/>
            </div>
          </el-select>
          <div v-else-if="label.inputType === 'icon'">
            <el-col
              :span="12"
              v-for="(icon, inx) in filteredButtons" :key="inx"
            >
              <el-button :disabled="(orderData.order_status==='incomplete'|| orderData.order_status==='order_placed')&&icon.exist!=='survey'?true:false" type="text" class="icon-container" @click.native.stop="icon.callback()">
                <span>
                  <i
                    :key="inx"
                    :class="icon.icon"
                    class="icon-size"
                    style="padding-left: 0.5rem; "
                  ></i
                ></span>
                <span class="icon-text">{{ icon.tooltipContent }}</span>
              </el-button>
            </el-col>
            <router-link
              :to="designSummaryRoute"
              style="color: #409EFF;"
              target="_blank"
            >
              Open Design Summary
            </router-link>
          </div>
          <div v-else>{{ orderData[label.prop] }}</div>

          <el-col :span="9" class="addition-section">
            <!-- <i
              class="el-icon-document-checked"
              style="font-size: 1.2rem; padding-left: 0.5rem"
            ></i> -->
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  <!-- </div> -->
    <el-row class="proposal-btns" v-if="designData && !loader">
      <el-col :span="24">
        <ProposalButtons :design="designData" />
      </el-col>
    </el-row>

    <div v-if="!loader" class="tabs">
      <Tabs
        :active="'1'"
        :token="token"
        :DetailsData="projectDetails"
        :orderData="orderData"
        :projectData="projectData"
        :customer="customerDetails"
        :ownerDetails="ownerDetails"
        :moduleDetails="moduleDetails"
        :pricingDetails="pricingDetails"
        :additionalNotes="additionalNotes"
        :ahjDetails="ahjDetails"
        :modelDetails="modelDetails"
        :batteryDetails="batteryDetails"
      />
    </div>
    <ChatBox v-if="ISUs && !loader" :orderData="orderData"  :drawerStatus="propsData?.drawer"/>
  </div>
</div>
<div v-else style="height:50vh;display: flex;justify-content: center;">
  <div v-loading="true">

  </div>
</div>
</div>
</template>

<script>
import { DateTime } from "luxon";
import rejectDialog from "./rejectDialog.vue";
import API from "@/services/api/";
import Tabs from "./tabs/tabs.vue";
import ChatBox from "../../project/components/chatBox/chatBox.vue";
import { isShiftOngoing } from "../../../utils/userStatus";
import {
  getLightenedColor,
  handleCompetenceColor,
} from "../../../utils/colorGenerator.js";
import {
  SITE_SURVEY_LINK,
  BASE_URL_FOR_REPORT_IMAGES,
  DATABASE_URL_FOR_SITE_SURVEY
} from "../../../constants";

import { chatEvents, sendEvent } from "../../../utils";
import ProposalButtons from "../../designSummaryCRM/components/ProposalButtons.vue";
import { mapState, mapActions } from 'pinia';
import { useDesignOrdersStore } from "../../../stores/designOrdersStore";

const  orderStatusOptions= [
        // {
        //   label: "Order Placed",
        //   value: "order_placed",
        // },
        // {
        //   label: "Incomplete",
        //   value: "incomplete",
        // },
        { label: "In Process", value: "in_process" },

        { label: "Pending", value: "pending" },
        { label: "Complete", value: "complete" },

        // { label: "Reject", value: "rejected" },
        // { label: "Cancel", value: "cancelled" },
      ];


export default {
  emits: ["dialogUpdates", "conformation-dialog", "update-card-column", "update-card"],
  components: {
    rejectDialog,
    Tabs,
    ChatBox,
    ProposalButtons,
  },
  data() {
    return {
      ownersBusy: false,
      mailOrderID:this.$props.propsData.order.id,
      mailDesignID:this.$props.propsData.order.design,
      errorMessage:"",
      errorText: "",
      mailName:this.$props.propsData.order.name,
      previousOrderStatus: "",
      rejectStatus: false,
      designData: "",
      order: this.$props.propsData.order,
      users: this.handleOptions(this.$props.propsData.users),
      loader:true,
      orderData: null,
      projectData: null,
      ownerName: null,
      options: this.$props.propsData.users,
      buttonsData: [
        {
          type: "none",
          icon: "el-icon-edit-outline",
          size: "mini",
          callback: () => {
            window.open(
              `${BASE_URL_FOR_REPORT_IMAGES}studio/${this.orderData.design}`
            );
          },
          exist: "desgin",
          tooltipContent: "Edit Design",
        },
        {
          type: "none",
          icon: "el-icon-document-checked",
          size: "mini",
          callback: () => {
            const url = this.finalSiteSurveyLink;
            window.open(url);
          },
          exist: "survey",
          tooltipContent: "SiteSurvey",
        },
      ],
      labelData: [
        { label: "Order ID", prop: "id" },
        { label: "Order Type", prop: "service_type" },
        { label: "Project Name", prop: "name" },
        { label: "Created On", prop: "created_at" },

        {
          label: "Owner",
          prop: "assigned_to",
          inputType: "select",
          options: this.$props.propsData.users,
          nextUrl: this.$props.propsData.ownerNextUrl,
        },

        {
          label: "Status",
          prop: "order_status",
          inputType: "select",
          options: orderStatusOptions,
        },
        { label: "Due Date", prop: "due_date" },
        {
          label: "",
          prop: "project",
          inputType: "icon",
        },
      ],
      token: [{ label: "TOKEN", prop: "user_token" }],
      projectDetails: [
        { title: "Project Details" },
        { label: "Name", prop: "name" },
        { label: "Stage", prop: "order_status" },
        { label: "Type", prop: "service_type" },
        { label: "Revisions", prop: "revision_version" },
      ],
      customerDetails: [
        { title: "Customer Details" },
        { label: "Name", prop: "client_name" },
        { label: "Email", prop: "client_email_id" },
        { label: "Phone", prop: "client_phone" },
        { label: "Address", prop: "client_address" },
      ],
      ownerDetails: [
        { title: "Owner Details" },
        { label: "Name", prop: "owner_name" },
        { label: "Email", prop: "owner_email" },
        { label: "Phone", prop: "owner_phone" },
      ],
      moduleDetails: [
        { title: "Component Details" },
        { label: "Component Type", prop: "type" },
        // <!-- { label: "Module ID", prop: "moduleID" }, -->
        { label: "Module Data", prop: "moduleData" },
        // { label: "Inverter ID", prop: "inverterID" },
        { label: "Inverter Data", prop: "inverterData" },
        { label: "Optimization Target", prop: "optimisationTarget" },
        { label: "Price", prop: "price" },
        { label: "Component Requirements", prop: "component_requirements" },
      ],
      modelDetails: [
        { title: "Component Details" },
        { label: "Component Type", prop: "type" },
        // <!-- { label: "Module ID", prop: "moduleID" }, -->
        { label: "Quantity", prop: "quantity" },
        { label: "Name", prop: "moduleData" },
        { label: "Price", prop: "price" },
        { label: "Inverter", prop: "inverter" },
        // { label: "Inverter ID", prop: "inverterID" },
      ],
      batteryDetails: [
        { title: "Battery Details" },
        {label: "Battery Size Use Case", prop: "battery_size_use_case"},
        { label: "Battery Manufacturer", prop: "manufacturer" },
        { label: "Battery Model", prop: "model" },
        { label: "Quantity", prop: "quantity" },
      ],
      pricingDetails: [],
      ahjDetails: [
        { title: "AHJ" },
        { label: "AHJ Name", prop: "AHJName" },
        { label: "AHJ Code", prop: "AHJCode" },
        { label: "Building Code", prop: "BuildingCode" },
        { label: "Residential Code", prop: "ResidentialCode" },
        { label: "Fire Code", prop: "FireCode" },
        { label: "Electricity Code", prop: "ElectricCode" },
      ],

      additionalNotes: [
        { title: "Additional Notes" },
        { label: "Local Codes", prop: "local_code" },
        { label: "Project Requirements", prop: "Project_Requirements" },
        { label: "Email Address", prop: "email_address" },
        { label: "Phone Number", prop: "phone_number" },
      ],
      acceptLoader:false,
    };
  },
  props: {
    propsData: {
      type: Object,
    },
  },
  computed:{
    filteredButtons() {
      return this.buttonsData.filter((icon) => {
        return (
          (this.orderData.design && icon.exist === 'desgin') ||
          ((this.orderData.additional_info?.path || this.orderData.site_survey?.token) &&
            icon.exist === 'survey')
        );
      });
    },
    labelDataOptions(){
      if(this.orderData?.order_status==='incomplete'){
       return this.labelData.filter(ele=>ele.prop !='due_date');
      }else{
        return this.labelData;
      }
    },
    finalSiteSurveyLink(){
      if(this.orderData.additional_info?.path){
        return `${SITE_SURVEY_LINK}${this.orderData.additional_info?.path}/tsl` // for old orders to support backward compatibility
      }
      else if(this.orderData?.site_survey?.token){
        return `${BASE_URL_FOR_REPORT_IMAGES}siteSurveyResponsePage/${this.orderData?.site_survey?.token}/${this.orderData?.site_survey?.template_id}` // for new site survey
      }
      return "";
    },
    designSummaryRoute() {
      let leadId = this.designData?.project?.lead
      let routeObject
      if (leadId) {
        routeObject = {
          name: 'designSummary',
          params: {
            leadId: leadId,
            designId: this.designData?.id,
          },
        }
      } else {
        routeObject = {
          name: "designSummary",
          params: {
            designId: this.designData?.id
          }
        }
      }

      return routeObject
    }
  },
  created() {
    this.order = '';
    this.order = this.$props.propsData.order;
    this.getOrderDetails(this.order);
  },
  watch: {
    rejectStatus(val) {
      this.$props.propsData.handleOrderUpdate();
      this.rejectStatus = false;
    },
    propsData(newVal, oldVal) {
      if (newVal && newVal?.order?.id !== oldVal?.order.id) {   
        this.mailOrderID=newVal.order.id
        this.mailDesignID=newVal.order.design
        this.mailName=newVal.order.name
        this.errorMessage=""
        this.designData = null
        this.loader=true
        const newOrder = newVal.order;
        this.getOrderDetails(newOrder);
        this.activeName = "1";
      }
    },
  },
  methods: {
    ...mapActions(useDesignOrdersStore, {
          setAssignedUser: "SET_ASSIGNED_USER"
    }),
    async loadMoreOwners(nextUrl){
      if(nextUrl){
        this.ownersBusy = true;
        try{
          const ownersResult = await API.DESIGN_ORDERS.LOAD_MORE_Leads(nextUrl);
          let owners = ownersResult.data.results;
          owners.forEach(owner => {
            owner.label = owner.first_name + " " + owner.last_name;
            owner.value = owner.id;
          });
          this.labelData.find(label => label.prop === "assigned_to").options = this.labelData.find(label => label.prop === "assigned_to").options.concat(owners);
          this.labelData.find(label => label.prop === "assigned_to").nextUrl = ownersResult.data.next;
        } catch(err){
          console.error(err);
        }
        this.ownersBusy = false;
      }
    },
    sendErrorEmail() {
      // Compose the email
      let toEmailList = [
        "uthakur@arkaenergy.com",
        "av@arkaenergy.com",
      ]
      let ccEmailList = [
        "kdsouza@arkaenergy.com",
        "ndhandapani@arkaenergy.com",
        "rkumar@arkaenergy.com",
        "mcharan@arkaenergy.com",
        "srinivasst@arkaenergy.com",
        "asingh@arkaenergy.com",
        "cmittal@arkaenergy.com",
      ]
      const toEmail = toEmailList.join(',');
      const subject = "Design API failure notification";
      const ccEmails = ccEmailList.join(',');
      const body = `
      Hi,

      The Design API failed.

      Details:
      - Design ID: ${this.mailDesignID}
      - Order ID: ${this.mailOrderID}
      - User: ${this.mailName}

      Please check and fix the issue.`;
 
      // Create the mailto link
      const mailtoLink = `mailto:${toEmail}?cc=${encodeURIComponent(ccEmails)}&subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
 
      // Open the default email client
      window.location.href = mailtoLink;
    },
    handleModuleInverter(data) {
      let result = "";
      data.map((d, inx) => {
        result = result + d[0] + ` ${data.length - 1 > inx ? "," : ""}`;
      });
      return result;
    },
    handleIsShiftOngoing(shift) {
      return isShiftOngoing(shift);
    },
    getType(shifts) {
      return this.handleIsShiftOngoing(shifts) ? "success" : "info";
    },
    handleColor(data) {
      return handleCompetenceColor(data);
    },
    handleColorLightning(color, perc) {
      return getLightenedColor(color, perc);
    },
    ISUs() {
      this.activeName = "1";

      const user = JSON.parse(localStorage.getItem("user")) || {};
      return user.isUSFlagEnabled;
    },
    async updateOrders(id, data,prop,value) {
      try {
        const resData = await API.DESIGN_ORDERS.UPDATE_DESIGN_ORDER_METHOAD(
          id,
          data
        );
        const updatedData = resData.data;
        this.$props.propsData.handleOrderUpdate(updatedData, data);
        if (prop === "order_status" || prop === "assigned_to") {
        this.sendChatEvent(prop, value, this.orderData.id);
      } 
        this.$emit("dialogUpdates", !this.$props.dialogUpdates);
        this.getOrderDetails(this.$props.propsData.order)
      } catch {
      }
      this.acceptLoader=false;

    },
    fullNameHandler(data) {
      // const nameValue = this.users.find((u) => u.id == parseInt(data));
      const firstName = data?.first_name||"";
      const lastName = data?.last_name||"";
      const nameValue = firstName +" " +lastName;
      // const nameValue=data?.first_name||""+" "+data?.last_name||"";

      return data? nameValue || "NA":"NA";
    },
    async getDesignByID(data) {
      const designs = await API.DESIGNS.FETCH_DESIGN(data.design);
      this.designData = designs.data;
    },

    async getOrderDetails(newOrder) {
      try{
      this.errorMessage=""
      this.designData = null
      this.loader=true
      let moduleInvers = "";
      this.projectData = newOrder.project ? newOrder.project : null;
      if (!this.projectData?.client_name && this.projectData?.name){
        this.projectData.client_name = this.projectData.name;
      }

      let batteryResp
      if (newOrder?.design) {
        [ , batteryResp] = await Promise.all([
          this.getDesignByID(newOrder),
          API.DESIGNS.FETCH_BATTERY_DETAILS(newOrder.design)
        ])

        newOrder.name=this.designData?.project?.name;
        moduleInvers = this.designData.versions?.summary?.bom_data;
      }
      let response = await API.DESIGN_ORDERS.FETCH_DESIGN_ORDER_BY_ID_METHOD(
        newOrder.id
      );
      this.loader=false
      const resData = response.data;
      const project = this.projectdata;
      const engineerData = resData.assignee_details
        ?resData.assignee_details?.first_name||""+" "+resData.assignee_details?.last_name||""
        :null;

      resData.name = newOrder ? newOrder?.name || "NA" : "NA";
      resData.owner_name =
        resData?.created_by.first_name ||
        "" + resData?.created_by.last_name ||
        "" ||
        "NA";
        
      resData.owner_phone = resData?.created_by.phone || "NA";
      resData.owner_email = resData?.created_by.email || "NA";
      resData.assigned_to = this.fullNameHandler(resData.assignee_details);

      this.ownerName = resData.assigned_to;
      resData.created_at = this.handleDueDate(resData.created_at, true);
      resData.due_date = resData.due_date !== null ?resData.order_status==='pending'? this.handleDueDate(resData.due_date,false,resData?.pending_at)  : this.handleDueDate(resData.due_date) : "NA"
      resData.revision_version = resData.revision_version
        ? resData.revision_version
        : 0;
        let conventionalPVComponent;
      if(!resData?.components?.length) {
        resData.components = [
          {
            "type": resData.component_type,
            "price": "NA",
            "moduleID": resData.module_inverter_data?.moduleID || "NA",
            "inverterID": resData.module_inverter_data?.inverterID || "NA",
            "moduleData": (resData.module_inverter_data?.moduleData || "") || "NA",
            "inverterData": resData.module_inverter_data?.inverterData || "NA",
            "optimisationTarget": resData.module_inverter_data?.optimisationTarget || "NA"
          }
        ];
        resData.moduleID = resData.module_inverter_data?.moduleID || "NA";
        resData.moduleData = resData.module_inverter_data?.moduleData || "NA";
        resData.inverterID = resData.module_inverter_data?.inverterID || "NA";
        resData.inverterData = resData.module_inverter_data?.inverterData || "NA";
        resData.optimisationTarget = resData.module_inverter_data?.optimisationTarget || "NA";
      } 
      if (newOrder?.design) {
        let batteryData = batteryResp.data
        let batteryDetails = batteryData.battery_detail;
        let transformedBatteryDetails = [];
        let batterySizeUseCase;
        if (batteryData.battery_sizing_type == 'back_up_home_essentials') {
          batterySizeUseCase = 'Partial Home Backup';
        } else if (batteryData.battery_sizing_type == 'full_energy_independence') {
          batterySizeUseCase = 'Full Home Backup';
        } else {
          batterySizeUseCase = 'Self Consumption Bill savings';
        }
        if (batteryDetails) {
          batteryDetails.forEach(detail => {
            let transformedDetail = {
              battery_size_use_case: batterySizeUseCase,
              quantity: detail.quantity,
              model: detail.battery.model,
              manufacturer: detail.manufacturer.name
            };
            transformedBatteryDetails.push(transformedDetail);
          });
        }
        resData.batterySpecific = transformedBatteryDetails;
      }

      resData.components[0].component_requirements = resData?.additional_info?.component_requirements || "NA"
      // As Arka Products only available for US,so hardcoding "$"
      if(resData.components.length>0){
        resData.components.forEach(component=>{
        if(component.type!= "Conventional PV"){
          component.price = "$ " + component?.price;
        }else if(component.type== "Conventional PV"){
          component.price =component.price&&component.price!='NA'?(component?.price+" "+component?.price_unit||"") :"NA";
        }
      })
      }
    
      // Pricing
      let financialData = resData.financial_data || {}
      let pricingKeyDict = {
        "absolute_price": "",
        "price_per_kw": "/kW",
        "price_per_watt": "/W",
      }
      let price
      for (let key of Object.keys(pricingKeyDict)) {
        if (financialData[key]) {
          price = financialData[key] + pricingKeyDict[key]
        }
      }
      let maintenanceCostDict = {
        "absolute_maintenance_cost": "/kW",
        "maintenance_cost_per_kw": "/kW/year"
      }
      let maintenanceCost
      for (let key of Object.keys(maintenanceCostDict)) {
        if (maintenanceCostDict[key]) {
          maintenanceCost = `${financialData[key] ?? '-'}${maintenanceCostDict[key]}`
        }
      }
      this.pricingDetails = [
        { title: "Pricing Details" },
        { label: "Price", value: price },
        { label: "Sales Tax", value: `${financialData.tax || '-'}%` },
        { label: "Expected Life", value: `${financialData.expected_life_years || '-'} years` },
        { label: "Maintenance Cost", value: maintenanceCost },
      ]


      // resData.moduleData = moduleInvers
      //   ? this.handleModuleInverter(moduleInvers.modules)
      //   : resData.module_inverter_data?.moduleData || "NA";

      // resData.inverterID = resData.module_inverter_data?.inverterID || "NA";
      // resData.inverterData = moduleInvers
      //   ? this.handleModuleInverter(moduleInvers.inverters)
      //   : resData.module_inverter_data?.inverterData || "NA";
      // resData.optimisationTarget = moduleInvers
      //   ? this.handleModuleInverter(moduleInvers.Optimizers)
      //   : resData.module_inverter_data?.optimisationTarget || "NA";
      resData.Project_Requirements =
        resData.additional_notes?.Project_Requirements || "NA";
      resData.local_code = resData.additional_notes?.local_code || "NA";
      resData.email_address = resData.additional_notes?.email_address || "NA";
      resData.phone_number = resData.additional_notes?.phone_number || "NA";
      resData.orderStatus=resData.order_status;

      this.orderData = resData;

    } catch(err) {
      console.error(err)
      this.errorText = err.stack
      this.loader = false
      this.errorMessage = this.designData ? "Design_id" : 'Design'
    }
    },
    handleDueDate(dateTime, isNotDueDate,isPendingAt) {
      if(isNotDueDate){
        if (
          DateTime.fromISO(dateTime).startOf("day").toISO() ===
          DateTime.local().startOf("day").toISO()
        ) {
          const date = DateTime.fromISO(dateTime);
          return "Today";
        }
      }
      else if(isPendingAt){
        let pendingDate = DateTime.fromISO(isPendingAt)
        let deadlineDate = DateTime.fromISO(dateTime)
        let diffObj = deadlineDate.diff(pendingDate, ["hours", "minutes", "seconds"]).toObject()
        return `${diffObj.hours ? diffObj.hours + 'hr' : ''} ${diffObj.minutes ? Math.round(diffObj.minutes) + 'min' : ''} ${diffObj.seconds ? Math.round(diffObj.seconds) + 'sec' : ''} remaining`
      }
      
      else{
        if (
          DateTime.fromISO(dateTime).toISO() <
          DateTime.local().toISO()
        ) {
          const date = DateTime.fromISO(dateTime);
          return "Overdue";
        }
        let currentDate = DateTime.local()
        let deadlineDate = DateTime.fromISO(dateTime)
        let diffObj = deadlineDate.diff(currentDate, ["hours", "minutes", "seconds"]).toObject()
        return `${diffObj.hours ? diffObj.hours + 'hr' : ''} ${diffObj.minutes ? Math.round(diffObj.minutes) + 'min' : ''} ${diffObj.seconds ? Math.round(diffObj.seconds) + 'sec' : ''} remaining`
      }
      return DateTime.fromISO(dateTime).toFormat("dd/MM/y  hh:mm a");
    },

    onAssigneeChange(e, prop, orderData) {
      let patchData = {};
      const value = prop === "assigned_to" ? parseInt(e) : e;
      patchData[prop] = value;
      if(value==='pending'){
      this.orderData.order_status=this.orderData.orderStatus;
        let filter={order_status:value}
        this.$emit('conformation-dialog',this.orderData,filter,"Pending")
      }else{
      if (prop === "order_status" || prop === "assigned_to") {
        //run through labelData array and find the object with prop engineer_name
        let engineerNameData = this.labelData.find((label) => label.prop === "assigned_to");
        const assignee = engineerNameData.options.find((option) => option.value === value);
        this.setAssignedUser(assignee);
      } 

      if (prop === "order_status") {
        if(value!=='pending'){
          this.orderData.orderStatus=value;
          this.$emit("update-card-column", this.orderData, value);
        }
      } else {
        this.$emit("update-card", this.orderData, value);
      }
  
        this.updateOrders(this.orderData.id, patchData,prop,value);

      }
    },
    handleAccept() {
this.acceptLoader=true;

      let orderStatus = {
        order_status: "in_process",
      };
      this.updateOrders(this.orderData.id, orderStatus,'order_status','in_process');
    },
    handleOptions(user) {
      user.map((u) => {
        let first = u?.first_name || "";
        let last = u?.last_name || "";
        u.label = first + " " + last;
        u.value = u.id;
      });

      return user;
    },

    sendChatEvent(prop, value, order_id) {
      if (prop === "assigned_to") {
        sendEvent(chatEvents.ORDER_ASSIGNMENT, order_id, value);
      }

      if (prop === "order_status") {
        if (value === "in_process") {
          sendEvent(chatEvents.ORDER_ACCEPTED, order_id);
        } else if (value === "complete") {
          sendEvent(chatEvents.COMPLETED_DESIGN, order_id);
        } else if (value === "rejected") {
          sendEvent(chatEvents.ORDER_REJECTED, order_id);
        }else if (value === "pending") {
          sendEvent(chatEvents.ORDER_PENDING, order_id);
        }
        else if (value === "cancelled") {
          sendEvent(chatEvents.ORDER_CANCELLED, order_id);
        }
        
      }
    },
    handleWebProposal() {
      const designUUID = this.designData?.versions?.reference_id;
      const route = { name: "webProposal", params: { designUUID } };

      // Generate the absolute URL for the route
      const url = this.$router.resolve(route).href;
      // Open the URL in a new tab
      window.open(url, "_blank");
    },
    handleWebDoc() {
      const designId = this.designData?.id;
      const route = { name: "documentProposal", params: { designId } };
      // Generate the absolute URL for the route
      const url = this.$router.resolve(route).href;
      // Open the URL in a new tab
      window.open(url, "_blank");
    },
    handle3D() {
      const designUUID = this.designData?.versions?.reference_id;
      const route = { name: "DesignOverview", params: { designUUID } };
      // Generate the absolute URL for the route
      const url = this.$router.resolve(route).href;
      // Open the URL in a new tab
      window.open(url, "_blank");
    },
    disableAccept(order){
      return order.assigned_to&&order.assigned_to!=="NA";
      
    }
  },
};
</script>

<style scoped>
.container {
  padding: 1.5rem;
}

.heading {
  display: flex;
  align-items: center;
}

.title {
}

.btn-container {
  display: flex;
  justify-content: end;
}

.btn-spacing {
  margin-right: 0.5rem;
}

.body {
  margin: 1.5rem 0rem 1rem 0rem;
}

.body-container {
  margin-bottom: 1rem;
}

.tabs {
  padding-bottom: 40px;
}

.label {
  padding-bottom: 0.5rem;
  font-size: 0.75rem;
  color: grey;
}

.custom-collapse .el-collapse-item__wrap {
  display: flex;
  flex-direction: column-reverse;
}

.custom-collapse .el-collapse-item__wrap {
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.custom-collapse .el-collapse-item.is-active .el-collapse-item__wrap {
  max-height: 1000px;
  /* Set a value larger than the expected content height */
}

.tag {
  border-radius: 20px;
}

.icon-container {
  cursor: pointer;
}

.icon-size {
  font-size: 1.5rem;
}

.icon-text {
  /* color: rgb(64, 158, 255); */
  padding-left: 0.5rem;
}

.owner-dropdown-option {
  display: flex;
  align-items: center;
  gap: 8px;
}

.dot-badge >>> sup {
  top: unset;
}
.proposal-btns {
  margin-bottom: 0.5rem;
}

.error-heading {
  text-align: center;
}

.error-text {
  margin-top: 24px;
  /* text-align: center; */
  color: gray;
}
</style>
