
<div :class="showBorder ? 'borderSelector':'selector'" id="menu">
  <div v-for="(menu, inx) in menuItems" :key="inx" class="el-menu-demo">
    <span class="icons" v-if="!isDrillDown">
      <img
        v-if="menu.icon == 'sort'"
        src="../../assets/sort.svg"
        alt="alter"
        v-show="!isMobileView"
      />

      <img
        v-if="menu.icon == 'filter'"
        src="../../../leadManagement/components/assets/filter_alt.svg"
        v-show="!isMobileView"
        alt="alter"
      />
    </span>
    <el-menu
      :collapse="openCollapse"
      :menu-trigger="openMenu"
      :default-active="'1'"
      mode="horizontal"
      @select="menu.callback"
      text-color="#222"
      active-text-color="#222"
      :label="menu.title"
      :value="menu.value"
      :style="{ width: 'fit-content' }"

    >
      <el-submenu
        index="1-1 "
        :class="isDrillDown ? 'submenu-with-border' : ''"
        :style="getCollapseStyles(menu)"
        popper-class="lead-filter-sub-menu"
      >
        <template v-slot:title>
          {{ !isMobileView ? menu.title : '' }}
        <img v-if="menu.icon == 'sort'"
          src="../../assets/sort.svg"
          alt="alter"
          v-show="isMobileView"
        />
        <img
          v-if="menu.icon == 'filter'"
          src="../../../leadManagement/components/assets/filter_alt.svg"
          alt="alter"
          v-show="isMobileView"
        />
      </template>
        <div v-if="menu.singleLevel" class="title-container">
          <el-menu-item
            v-for="item in menu.options"
            :default-active="'order_status'"
            :key="item.value"
            :index="item.value"
            :label="item.label"
            :value="item.value"
          >
            {{ item.label }}
          </el-menu-item>
        </div>
        <div v-if="!menu.singleLevel" 
          @mouseenter="HoverEffect">
          <el-submenu
            v-for="item in menu.options"
            :key="item.value"
            :index="item.label"
            :label="item.label"
            :value="item.value"
            class="menu-sub-items"
          >
            <template v-slot:title>
              <span class="filter-sub-menu-title">
                {{ item.value }}
              </span>
            <b-icon icon="chevron-right" class="chevron-right" v-show="isMobileView"/>
            </template>
            <template
              v-if="item.type === 'checkbox' && item.value == 'Lead Source'"
            >
              <div class="checkBoxborder">
                <el-checkbox-group
                  class="checkBox"
                  v-model="selectedLeadCheckboxes"
                  @change="updateLeadCheckbox"
                >
                <div class="checkbox-container">
                  <el-checkbox
                    v-for="subItem in item.subOptions"
                    :key="subItem.value"
                    :index="subItem.label"
                    :label="subItem.label"
                    :value="subItem.value"
                  >
                  <span class="cb-label-mobile">
                    {{ subItem.value }}
                  </span>
                  </el-checkbox>
                </div>
                  <!-- <el-button @click="updateLeadCheckbox" class="updateButton"
                    :disabled="selectedLeadCheckboxes.length === 0" type="primary">Update</el-button> -->
                </el-checkbox-group>
              </div>
            </template>
            <template
              v-if="item.type === 'checkbox' && item.value == 'Region'"
            >
              <div class="checkBoxborder">
                <el-checkbox-group
                  class="checkBox"
                  v-model="selectedRegion"
                  @change="updateRegionCheckbox"
                >
                <div class="checkbox-container">
                  <el-checkbox
                    v-for="subItem in item.subOptions"
                    :key="subItem.value"
                    :index="subItem.label"
                    :label="subItem.label"
                    :value="subItem.value"
                  >
                  <span class="cb-label-mobile">
                    {{ subItem.value }}
                  </span>
                  </el-checkbox>
                </div>
                  <!-- <el-button @click="updateLeadCheckbox" class="updateButton"
                    :disabled="selectedLeadCheckboxes.length === 0" type="primary">Update</el-button> -->
                </el-checkbox-group>
              </div>
            </template>
            <template v-if="item.type === 'checkbox' && item.value == 'Tags'">
              <div class="checkBoxborder">
                <el-checkbox-group
                  class="checkBox"
                  v-model="selectedTagCheckboxes"
                  @change="updateTagCheckbox"
                >
                  <div class="checkbox-container">
                    <el-checkbox
                      v-for="subItem in item.subOptions"
                      :key="subItem.value"
                      :index="subItem.label"
                      :label="subItem.label"
                      :value="subItem.value"
                    >
                      {{ subItem.value }}
                    </el-checkbox>
                  </div>
                </el-checkbox-group>
              </div>
            </template>
            <template v-if="item.type === 'checkbox' && item.value == 'Lead Owner'">
              <div>
                <div class="search-bar">
                  <el-input
                    v-model="search"
                    placeholder="Search"
                    @change="searchUsers"
                  >
                  <template v-slot:prefix>
                      <b-icon style="margin-top:15px; margin-left:10px;font-size:16px;color:#777" icon="search"></b-icon>
                  </template>
                  </el-input>
                </div>
                <div class="checkBoxborder">
                  <el-checkbox-group
                    class="checkBox2"
                    v-model="selectedUserCheckboxes"
                    @change="updateUserCheckbox"
                  >
                    <div
                      class="checkbox-container2"
                      v-infinite-scroll="loadMore2"
                      infinite-scroll-disabled="busy"
                      infinite-scroll-distance="10"
                    >
                    <el-checkbox
                      v-for="subItem in item.subOptions"
                      :key="subItem.value"
                      :index="subItem.value"
                      :label="subItem.value"
                      :value="subItem.value"
                      >
                        <el-avatar
                        style="margin-left:10px"
                          :size="28"
                          :style="getSelectionAvatarStyle(subItem.label)"
                        >
                          <span>{{ generateInitials(subItem.label) }}</span>
                        </el-avatar>
                        <span>{{ subItem.label }}</span>
                      </el-checkbox>
                    </div>
                  </el-checkbox-group>
                </div>
              </div>
            </template>
            <template v-if="item.type === 'checkbox' && item.value == 'Products'">
              <div 
              style="max-height: 330px; overflow: scroll;"
              >
                <div class="productHeadingText">
                  <span >PowerGazebo with Inverter</span>
                </div>
                <el-checkbox-group
                  class="checkBox"
                  v-model="selectedPowerGazeboProductsWithInverter"
                  @change="handleSelectedProductsUpdated"
                  :label="'sda'"
                  >
                    <div class="checkbox-container3">
                      <el-checkbox
                        v-for="subItem in powerGazeboProductsWithInverter"
                        :key="subItem.id"
                        :label="subItem.id"
                      >
                        {{ subItem.sku_name }}
                      </el-checkbox>
                    </div>
                  </el-checkbox-group>
                  <div class="productHeadingText">
                    <span >PowerGazebo without Inverter</span>
                  </div>
                  <el-checkbox-group
                    class="checkBox"
                    v-model="selectedPowerGazeboProductsWithoutInverter"
                    @change="handleSelectedProductsUpdated"
                    >
                    <div class="checkbox-container3">
                      <el-checkbox
                        v-for="subItem in powerGazeboProductsWithoutInverter"
                        :key="subItem.id"
                        :label="subItem.id"
                      >
                        {{ subItem.sku_name }}
                      </el-checkbox>
                      </div>  
                  </el-checkbox-group>
                  <div class="productHeadingText">
                    <span >PowerPatio with Inverter</span>
                  </div>
                  <el-checkbox-group
                    class="checkBox"
                    v-model="selectedPowerPatioProductsWithInverter"
                    @change="handleSelectedProductsUpdated"
                    >
                    <div class="checkbox-container3">
                      <el-checkbox
                        v-for="subItem in powerPatioProductsWithInverter"
                        :key="subItem.id"
                        :label="subItem.id"
                      >
                        {{ subItem.sku_name }}
                      </el-checkbox>
                    </div> 
                  </el-checkbox-group>
                  <div class="productHeadingText">
                    <span >PowerPatio without Inverter</span>
                  </div>
                  <el-checkbox-group
                    class="checkBox"
                    v-model="selectedPowerPatioProductsWithoutInverter"
                    @change="handleSelectedProductsUpdated"
                    >
                    <div class="checkbox-container3">
                      <el-checkbox
                        v-for="subItem in powerPatioProductsWithoutInverter"
                        :key="subItem.id"
                        :label="subItem.id"
                      >
                        {{ subItem.sku_name }}
                      </el-checkbox>
                    </div> 
                  </el-checkbox-group>
        
              </div>
            </template>
            <template v-if="item.type === 'checkbox' && (item.value==='Accessory'||item.value==='Accessory SKU')">
              <div class="checkBoxborder">
                <el-checkbox-group
                  class="checkBox"
                  v-model="selectedAccessories"
                  @change="updateCatalogStatusbox(item)"
                >
                  <div class="checkbox-container">
                    <el-checkbox
                      v-for="subItem in item.subOptions"
                      :key="subItem.value"
                      :index="subItem.label"
                      :label="subItem.label"
                      :value="subItem.value"
                    >
                      {{ subItem.value }}
                    </el-checkbox>
                  </div>
                  <!-- <el-button @click="updateTagCheckbox" class="updateButton fixed-button"
                    :disabled="selectedTagCheckboxes.length === 0" type="primary">Update</el-button> -->
                </el-checkbox-group>
              </div>
            </template>
            <template v-if="item.type === 'checkbox' && (item.value==='Product'||item.value==='Product SKU')">
              <div class="checkBoxborder">
                <el-checkbox-group
                  class="checkBox"
                  v-model="selectedProducts"
                  @change="updateCatalogProducts(item)"
                >
                  <div class="checkbox-container">
                    <el-checkbox
                      v-for="subItem in item.subOptions"
                      :key="subItem.value"
                      :index="subItem.label"
                      :label="subItem.label"
                      :value="subItem.value"
                     
                    >
                      {{ subItem.value }}
                    </el-checkbox>
                  </div>
                  <!-- <el-button @click="updateTagCheckbox" class="updateButton fixed-button"
                    :disabled="selectedTagCheckboxes.length === 0" type="primary">Update</el-button> -->
                </el-checkbox-group>
              </div>
            </template>
            <template
              v-if="item.type === 'checkbox' && item.value == 'Status'"
            >
              <div class="checkBoxborder">
                <el-checkbox-group
                  class="checkBox"
                  v-model="selectedStatusCheckboxes"
                  @change="updateStatusCheckbox"
                >
                  <div class="checkbox-container">
                    <el-checkbox
                      v-for="subItem in item.subOptions"
                      :key="subItem.value"
                      :index="subItem.label"
                      :label="subItem.label"
                      :value="subItem.value"
                    >
                      {{ subItem.value }}
                    </el-checkbox>
                  </div>
                </el-checkbox-group>
              </div>
            </template>
            <template
              v-else-if="
                item.type === 'date' && item.label == 'Expected closure'
              "
            >
              <div class="dateContainer">
                <!-- <el-date-picker
                  v-model="selectedDate"
                  type="daterange"
                  range-separator="-"
                  @focus="clickEvent"
                  @change="updateDate"
                  start-placeholder="From Date"
                  end-placeholder="To Date"
                >
                </el-date-picker> -->
                <el-date-picker   @focus="clickEvent"
                  class="datePick"
                  v-model="selectedFromDate"
                  type="date"
                  :picker-options="datePickerOptions"
                  placeholder="After Date"
                >
                </el-date-picker>
                <el-date-picker   @focus="clickEvent"
                  class="datePick"
                  v-model="selectedToDate"
                  type="date"
                  :picker-options="datePickerOptions"
                  placeholder="Before Date"
                >
                </el-date-picker>
                <el-button @click="updateDate" :disabled="selectedFromDate==='' || selectedToDate==''" type="primary">Update</el-button>
                <!-- <button class="updateButton">Update</button> -->
              </div>
            </template>
            <template
              v-else-if="item.type === 'date' && item.value == 'Start Date'"
            >
              <div class="dateContainer" >
                <!-- <el-date-picker
                ref="datepicker"
                  v-model="selectedStartDate"
                  type="daterange"
                  range-separator="-"
                  @focus="clickEvent"
                  @change="updateStartDate"
                  start-placeholder="From Date"
                  end-placeholder="To Date"
                >
                </el-date-picker> -->
                <el-date-picker   @focus="clickEvent"
                  class="datePick"
                  v-model="selectedStartDateFrom"
                  type="date"
                  :picker-options="datePickerOptions"
                  placeholder="After Date"
                >
                </el-date-picker>
                <el-date-picker   @focus="clickEvent"
                  class="datePick"
                  v-model="selectedStartDateTo"
                  type="date"
                  :picker-options="datePickerOptions"
                  placeholder="Before Date"
                >
                </el-date-picker>
                <el-button @click="updateStartDate" :disabled="selectedStartDateFrom==='' || selectedStartDateTo==''" type="primary">Update</el-button>
              </div>
            </template>
            <template
              v-else-if="item.type === 'date' && item.value == 'Due Date'"
            >
              <div class="dateContainer">
                <!-- <el-date-picker
                  v-model="selectedDueDate"
                  type="daterange"
                  range-separator="-"
                  @focus="clickEvent"
                  @change="updateDueDate"
                  start-placeholder="From Date"
                  end-placeholder="To Date"
                >
                </el-date-picker> -->
                <el-date-picker   @focus="clickEvent"
                  class="datePick"
                  v-model="selectedDueDateFrom"
                  type="date"
                  :picker-options="datePickerOptions"
                  placeholder="After Date"
                >
                </el-date-picker>
                <el-date-picker   @focus="clickEvent"
                  class="datePick"
                  v-model="selectedDueDateTo"
                  type="date"
                  :picker-options="datePickerOptions"
                  placeholder="Before Date"
                >
                </el-date-picker>
                <el-button @click="updateDueDate" :disabled="selectedDueDateFrom==='' || selectedDueDateTo==''" type="primary">Update</el-button>
              </div>
            </template>
            <template v-else-if="item.type === 'dropdown'">
              <div class="countryDropContainer">
                <el-select
                  class="countryselect"
                  v-model="selectedOption"
                  placeholder="USA"
                >
                  <el-option
                    v-for="subItem in item.subMenu"
                    :key="subItem.value"
                    :label="subItem.label"
                    :value="subItem.value"
                  >
                  </el-option>
                </el-select>
                <el-select
                  class="countryselect"
                  v-model="selectedOption"
                  placeholder="California"
                >
                  <el-option
                    v-for="subItem in item.subMenu"
                    :key="subItem.value"
                    :label="subItem.label"
                    :value="subItem.value"
                  >
                  </el-option>
                </el-select>
                <el-select
                  class="countryselect"
                  v-model="selectedOption"
                  placeholder="90001"
                >
                  <el-option
                    v-for="subItem in item.subMenu"
                    :key="subItem.value"
                    :label="subItem.label"
                    :value="subItem.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </template>
            <template v-else-if="item.type === 'input' && item.label=='DC Capacity'">
              <div class="inputContainer">
                <div style="display:flex">
                  <label>
                    <input
                      class="menuInput"
                      v-model="moreValue"
                      type="number"
                      placeholder="More Than or Equal to"
                    />
                    <span class="labelText2"> kW</span>
                    <span class="labelText" style="margin-left:5px" > - </span>
                  </label>
                  <label>
                    <input
                      class="menuInput"
                      v-model="lessValue"
                      type="number"
                      placeholder="Less Than or Equal to"
                    />
                    <span class="labelText2"> kW</span>
                  </label>
                 
                </div>
                <el-button
                  class="updateButton"
                  @click="UpdateDCvalue"
                  :disabled="lessValue === '' || moreValue === ''"
                  type="primary"
                  >Update</el-button
                >
              </div>
            </template>
            <template v-else-if="item.type === 'input' && item.label=='probability'">
              <div class="inputContainer">
                <div style="display:flex">
                  <label>
                    <input
                      class="menuInput"
                      v-model="maxProbability"
                      type="number"
                      :min="0" :max="100" @input="validatemaxInput"
                      placeholder="More Than or Equal to"
                    />
                    <span class="labelText2"> %</span>
                    <span class="labelText" style="margin-left:5px" > - </span>
                  </label>
                  <label>
                    <input
                      class="menuInput"
                      v-model="minProbability"
                      type="number"
                      :min="0" :max="100" @input="validateminInput"
                      placeholder="Less Than or Equal to"
                    />
                    <span class="labelText2"> %</span>
                  </label>
                </div>
               
                <el-button
                  class="updateButton"
                  @click="updateProbability"
                  :disabled="minProbability === '' || maxProbability === ''"
                  type="primary"
                  >Update</el-button
                >
              </div>
            </template>
            <template v-else-if="item.type === 'collaborators'">
              <div>
                <div class="search-bar">
                  <el-input
                    v-model="search"
                    placeholder="Search"
                    @change="searchUsers"
                  >
                  <template v-slot:prefix>
                      <b-icon style="margin-top:15px; margin-left:10px;font-size:16px;color:#777" icon="search"></b-icon>
                  </template>
                  </el-input>
                </div>
                <div class="checkBoxborder">
                  <el-checkbox-group
                    class="checkBox2"
                    v-model="selectedValue"
                    @change="updateCollaborators"
                  >
                    <div
                      class="checkbox-container2"
                      v-infinite-scroll="loadMore2"
                      infinite-scroll-disabled="busy"
                      infinite-scroll-distance="10"
                    >
                      <el-checkbox
                        v-for="avatar in item.subOptions"
                        :key="avatar.id"
                        :index="avatar.id"
                        :label="avatar.id"
                        :value="avatar.name"
                      >
                        <el-avatar
                        style="margin-left:10px"
                          :size="28"
                          :style="getSelectionAvatarStyle(avatar.name)"
                        >
                          <span>{{ generateInitials(avatar.name) }}</span>
                        </el-avatar>
                        <span>{{ avatar.name }}</span>
                      </el-checkbox>
                    </div>
                  </el-checkbox-group>
                </div>
              </div>
            </template>
            <template v-else-if="item.type === 'homeowner'">
              <div>
                <div class="search-bar">
                  <el-input
                    v-model="searchTerm"
                    placeholder="Search"
                    @change="searchLeads"
                  >
                  <template v-slot:prefix>
                    <b-icon style="margin-top:15px; margin-left:10px;font-size:16px;color:#777" icon="search"></b-icon>
                  </template>
                </el-input>
                </div>
                <div class="checkBoxborder">
                  <el-checkbox-group
                    class="checkBox2"
                    v-model="homeOwnerSelect"
                    @change="updateHomeownerCheckbox"
                  >
                    <div
                      class="checkbox-container2"
                      v-infinite-scroll="loadMore"
                      infinite-scroll-disabled="busy"
                      infinite-scroll-distance="10"
                    >
                      <el-checkbox
                        v-for="subItem in item.subOptions"
                        :key="subItem.id"
                        :index="subItem.id"
                        :label="subItem.id"
                        :value="subItem.name"
                      >
                        <el-avatar
                        style="margin-left:10px;"
                          :size="28"
                          :style="getSelectionAvatarStyle(subItem.name)"
                        >
                          <span>{{ generateInitials(subItem.name) }}</span>
                        </el-avatar>
                        <span>{{ subItem.name }}</span>
                      </el-checkbox>
                    </div>
                  </el-checkbox-group>
                </div>
              </div>
            </template>
            <template v-else-if="item.type === 'radio' && item.label == 'priority'">
              <div class="radiogroup">
                <el-radio-group
                  v-model="selectedRadioGroup"
                  @change="UpdatePriorityValue"
                >
                  <div
                    v-for="subItem in item.subOptions"
                    :key="subItem.value"
                  >
                    <el-radio :label="subItem.label" style="color: #222;">
                      {{ subItem.value }}
                    </el-radio>
                  </div>
                </el-radio-group>
              </div>
            </template>
            <template v-else-if="item.type === 'radio' && item.label == 'proposal_status'">
              <div class="radiogroup">
                <el-radio-group
                  v-model="selectedProposalRadioGroup"
                  @change="updateProposalStatusRadio"
                >
                  <div
                    v-for="subItem in item.subOptions"
                    :key="subItem.value"
                  >
                    <el-radio :label="subItem.label" style="color: #222;">
                      {{ subItem.value }}
                    </el-radio>
                  </div>
                </el-radio-group>
              </div>
            </template>

            <template v-else-if="item.type === '$input'">
              <div class="inputContainer">
                <div style="display:flex">
                  <label>
                    <span class="labelText">$</span>
                    <input
                      class="menuInput"
                      v-model="moreCost"
                      type="number"
                      placeholder="More Than or Equal to"
                    />
                  </label>
                  <label>
                    <span class="labelText" style="margin-left:5px" > - </span>
                    <span class="labelText">$</span>
                    <input
                      class="menuInput"
                      v-model="lessCost"
                      type="number"
                      placeholder="Less Than or Equal to"
                    />
                  </label>
                 
                </div>
                  <el-button
                  @click="updateCost"
                  class="updateButton"
                  :disabled="lessCost === '' || moreCost === '' || lessCost === 0 || moreCost ===0"
                  type="primary"
                  >Update</el-button
                >
                
              </div>
            </template>
            <!-- <template v-else-if="!item.type">
              <el-menu-item
                v-for="subItem in item.subOptions"
                :key="subItem.value"
                :index="subItem.label"
                :label="subItem.label"
                :value="subItem.value"
                :multiple="true"
              >
                {{ subItem.value }}
              </el-menu-item>
            </template> -->
            <div
              v-if="
                item.type !== 'checkbox' &&
                item.type !== 'radio' &&
                item.type !== 'homeowner' &&
                item.type !== 'collaborators' 
              "
              class="checkbox-container"
            >
              <el-menu-item
                v-for="subItem in item.subOptions"
                :key="subItem.value"
                :index="subItem.index ? subItem.index : subItem.label"
                :label="subItem.label"
                :value="subItem.value"
                :multiple="true"
              >
                {{ subItem.value
                }}<span
                  v-if="subItem.competence"
                  :style="{ paddingLeft: '1rem' }"
                >
                  <el-tag
                    v-for="(comp, indx) in subItem.competence"
                    :key="indx"
                    class="tag"
                    :color="handleColorLightning(handleColor(comp), 80)"
                    :style="{
                      color: `${handleColor(comp)}`,
                      marginLeft: '0.2rem',
                    }"
                    type="red"
                    size="mini"
                  >
                    {{ comp }}
                  </el-tag>
                  
                </span>
              </el-menu-item>
              <el-submenu index="4" v-if="item.type === 'text' && item.value === 'Time Period'"  >
                <template v-slot:title >Custom range</template>
                <div class="dateContainer">

                  <el-date-picker   @focus="clickEvent"
                    class="datePick"
                    v-model="selectedCreatedAtFromDate"
                    type="date"
                    :picker-options="datePickerOptionsFrom"
                    placeholder="After Date"
                  >
                  </el-date-picker>
                  <el-date-picker   @focus="clickEvent"
                    class="datePick"
                    v-model="selectedCreatedAtToDate"
                    type="date"
                    :picker-options="datePickerOptionsTo"
                    placeholder="Before Date"
                  >
                  </el-date-picker>
                  <el-button @click="updateSelectedCreatedDates" :disabled="selectedCreatedAtToDate==='' || selectedCreatedAtFromDate==''" type="primary">Update</el-button>
                </div>
              </el-submenu>
            </div>
          </el-submenu>
        </div>
      </el-submenu>
    </el-menu>
  </div>
</div>
