import * as utils from "../../../components/ui/length/utils"; 
import {ENABLE_TEXT_SELECTION, DISABLE_TEXT_SELECTION} from "../../coreConstants";
import ArcMeasurement from "./ArcMeasurement";
import Dimension from "./Dimension";
import LengthMeasurement from "./LengthMeasurement";
import RotationPoint from "./RotationPoint";

export default class HTMLText {

    constructor(text, position, angle, stage, direction, distance, parent, inputValidator, isSetbackFlag = false) {

        // standard norms
        this.stage = stage;

        this.text = text;
        this.angle = angle;
        this.position = position;
        this.direction = direction;
        this.distance = distance;

        // properties
        this.parent = parent;
        this.inputValidator = inputValidator;
        this.isSelectionEnabled = false;    
        // create div element and set properties
        let div = document.createElement('input');
        div.className = 'single-line mousetrap';
        div.maxLength = 15;
        div.size = 4;
        this.element = div;
        //add inline style
        this.element.style.position = 'absolute';
        this.element.style.color = 'white';
        //width should be min 50px
        // this.element.style.minWidth = '50px';
        this.element.style.width = 'auto';
        if(!isSetbackFlag){
            this.element.style.height = '28px';
            this.element.style.fontSize = '21px';
        }
        else {
            this.element.style.height = '18px';
            this.element.style.fontSize = '13px';
        }
        this.element.style.backgroundColor = 'rgba(0, 0, 0, 0.2)';
        this.element.style.borderRadius = '3px';

        //stop zooming
        this.element.addEventListener('mousewheel', function (event) {
            event.preventDefault();
        });
      
          // Prevent zooming on gesturechange event (for touch devices)
        this.element.addEventListener('gesturechange', function (event) {
            event.preventDefault();
        });

        // dom element to add HTML text
        this.domElement = this.stage.rendererManager.getDomElement().parentElement;
        this.domElement.appendChild(this.element);

        // text box alignment and positioning
        this.update(text, position, angle, direction, distance);

        // option to check whether container should be created
        this._shouldCreateContainer = true;
        this._shouldCompleteOnNoChange = false;

        this.elementWidth = this.element.offsetWidth;
        this.elementHeight = this.element.offsetHeight;
    }

    getElementInnerText() {
        return this.element.innerHTML
            .replace(/<div>/g, '')
            .replace(/<\/div>/g,'')
            .replace(/<br>/g, '');
    }

    setElementInnerText(text) {
        this.element.value = text;
    }

    _setPosition(position, direction, distance, angle = this.angle) {

        let pi = Math.PI;
        let divWidth = this.elementWidth;
        let divHeight = this.elementHeight;
        let camera = this.stage.cameraManager.get2dCamera();
        let offset = 0;
        
        if(this.stage.mode === 'salesMode'){
           offset = document.getElementById("navBarSaleStudio").offsetHeight;
        }

        // get screen pixel coordinates of position
        let coords2d = position.clone().project(camera);
        // Calculate the new coordinates for the center of the element
        coords2d.x = (coords2d.x + 1) / 2 * this.stage.screenDimensions.width ;
        coords2d.y = -(coords2d.y - 1) / 2 * (this.stage.screenDimensions.height) + offset ;

        // Adjust for the given distance in the given direction
        coords2d.x += (direction.x * distance);
        coords2d.y -= (direction.y * distance);

        // smart adjust the reference point on the div based on given direction
        if ((-pi / 8 < angle) && (angle <= pi / 8)) {
            // "left mid"
            coords2d.y -= (divHeight /2);
            coords2d.x -= (divWidth / 2);
        } else if ((pi / 8 < angle) && (angle <= (3 * pi / 8))) {
            // "left bottom"
            coords2d.y += divHeight/2;
            coords2d.x -= divWidth / 2;
        } else if (((3 * pi / 8)) < angle && (angle <= (5 * pi / 8))) {
            // "bottom mid"
            coords2d.y += divWidth/2;    
            coords2d.x -= divHeight / 2;
        } else if (((5 * pi / 8) < angle) && (angle <= (7 * pi / 8))) {
            // "right bottom"
            coords2d.y -= divHeight;
            coords2d.x += -divWidth;
        } else if (((7 * pi / 8) < angle) || angle <= (-7 * pi / 8)) {
            // "right mid"
            coords2d.y -= divHeight / 2;
            coords2d.x += -divWidth/2;
        } else if (((-3 * pi / 8) < angle) && (angle <= (-pi / 8))) {
            // "left top"
            coords2d.y -= (divHeight) ;
            coords2d.x -= (divWidth / 2) ;
        } else if (((-5 * pi / 8) < angle) && (angle <= (-3 * pi / 8))) {
            // "top mid"
            coords2d.x += divHeight / 2;
            coords2d.y += - divWidth / 2;
        } else if (((-7 * pi / 8) < angle && angle <= (5 * pi / 8))) {
            // "right top"
            coords2d.x += -divWidth;
        }

        // update DOM element
        this.element.style.left = `${coords2d.x}px`;
        this.element.style.top = `${coords2d.y}px`;
    }

    _onMouseDown = (event) => {
        if (this.stage.smartRoofSetbackEditMode.enabled) {
            this.stage.textSelectionControls.setSelectedTextObject.bind(this.stage.textSelectionControls.onClick(event));
            this.stage.textSelectionControls.setSelectedTextObject.bind(this.onSelect());
        }
        this.stage.textSelectionControls.setSelectedTextObject(this);
    };

    _onCameraUpdate = () => {
        this._setPosition(this.position, this.direction, this.distance);
    };

    enableSelection = () => {
         if (!this.isSelectionEnabled) {
             this.element.addEventListener('mousedown', this._onMouseDown);
             this.isSelectionEnabled = true;
         }
    };

    disableSelection = () => {
        if (this.isSelectionEnabled) {
            this.element.removeEventListener('mousedown', this._onMouseDown);
            this.isSelectionEnabled = false;
        }
    };

    show(editable=true) {
        this.element.style.visibility = 'visible';
        if (editable){
            this.enableSelection();
            this.stage.eventBus.addEventListener(ENABLE_TEXT_SELECTION, this.enableSelection);
            this.stage.eventBus.addEventListener(DISABLE_TEXT_SELECTION, this.disableSelection);
        }
    }

    hide() {
        this.element.style.visibility = 'hidden';
        this.disableSelection();
        this.stage.eventBus.removeEventListener(ENABLE_TEXT_SELECTION, this.enableSelection);
        this.stage.eventBus.removeEventListener(DISABLE_TEXT_SELECTION, this.disableSelection);
    }

    // TODO: Create a move function that is not compute heavy
    update(text, position, angle, direction, distance, updateElementInnerText = true) {

        this.text = text !== undefined ? text : this.text;
        this.angle = angle !== undefined ? angle : this.angle;
        this.position = position !== undefined ? position : this.position;
        this.direction = direction !== undefined ? direction : this.direction;
        this.distance = distance !== undefined ? distance : this.distance;

        // text
        if (updateElementInnerText) {
            this.setElementInnerText(this.text);
        }

        // angle
        this.element.style.transformOrigin = '0 0';
        this.element.style.transform = `rotate(${-1 * this.angle}rad)`;

        // position
        this._setPosition(this.position, this.direction, this.distance);
        if(this.isSelected) this.element.select();
    }

    remove() {
        if(this.stage.textSelectionControls.getSelectedTextObject() === this) {
            this.stage.textSelectionControls.deSelectSelectedTextObject();
        }

        if (this.domElement.contains(this.element)) {
            this.domElement.removeChild(this.element);
        }
    }

    enable() {
        this.element.style.removeProperty('opacity');
        this.element.removeAttribute('disabled');
        this.enableSelection();
    }

    disable(){
        this.element.style.opacity = '0.4';
        this.element.setAttribute('disabled', true);
        this.disableSelection();
    }

    onSelect({ shouldCreateContainer, shouldCompleteOnNoChange } = { shouldCreateContainer: true, shouldCompleteOnNoChange: false }) {
        this.stage.dragControls.disable();
        this.isSelected = true;

        this.element.style.backgroundColor = 'rgba(0, 0, 0, 0.2)';
        this.element.style.color = 'white';
        this.element.style.zIndex = '1000';
        this.element.contentEditable = 'true';
        this.element.style.borderRadius = '3px';

        // select text object on window
        let range = document.createRange();
        range.selectNodeContents(this.element);
        let selection = window.getSelection();
        // Remove any current selections on window if already there
        selection.removeAllRanges();
        // select currently selected range
        selection.addRange(range);

        this.element.removeEventListener('mousedown', this._onMouseDown);
        this.element.addEventListener("input", this.inputValidatorFunction.bind(this));
           
        this.parent.handleTextSelection();
        const inputValue = this.element.value;
        this.element.size = (inputValue.length - 2 > 4)? inputValue.length - 2: 4;
        this.element.select();


        // store shouldCreateContainer
        this._shouldCreateContainer = shouldCreateContainer;
        this._shouldCompleteOnNoChange = shouldCompleteOnNoChange;

    }

    deSelect() {
        if (!this.stage.setbackEditMode.isEnabled()) {
            this.stage.dragControls.enable();
        }

        // if(this.parent && (this.parent instanceof Dimension || this.parent instanceof LengthMeasurement || this.parent instanceof ArcMeasurement)) this.parent.textObject.showObject();
        this.isSelected = false;

        this.setElementInnerText(this.getValue());

        this.element.style.color = 'white';
        this.element.style.zIndex = '1';
        this.element.contentEditable = 'false';
        this.element.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
        this.element.style.borderRadius = '3px';
        this.element.blur();

        this.element.addEventListener('mousedown', this._onMouseDown);
        this.element.removeEventListener('keyup', this._checkInput);

        this.parent.handleTextDeSelection();
    }

    getValue() {
        return this.element.value;
    }

 inputValidatorFunction(){
    const inputValue = this.element.value;
    if (!utils.isMetricUnit()) {      
      // Define a regular expression for validation (e.g., allowing only letters, numbers, ', ", ft, and in)
      const validPattern = /^(\d+(\.\d*)?|\.\d+|['"]|ft|in)+$/;

      if (!validPattern.test(inputValue) || inputValue.includes('..')) {
        // If the input doesn't match the pattern or contains consecutive dots, remove the invalid character(s)
        const sanitizedValue = inputValue.replace(/[^0-9.'"\sftin]/g, ''); // Remove characters that are not digits, dots, ', ", spaces, ft, or in
        const sanitizedValueWithoutConsecutiveDots = sanitizedValue.replace(/\.{2,}/g, '.'); // Replace consecutive dots with a single dot
        this.element.value = sanitizedValueWithoutConsecutiveDots;

        // Show an error message
        this.element.setCustomValidity(`Username can only contain letters, numbers, ', ", ft, in, and consecutive dots are not allowed.`);
      } else {
            this._checkInput();
            // If the input is valid, clear any previous error message
            this.element.setCustomValidity("");
      }
    } else {        
        // Define a regular expression for validation (e.g., allowing only letters and numbers)
        const validPattern = /^(\d+(\.\d*)?|\.\d+)+$/;
      
        if (!validPattern.test(inputValue) || inputValue.includes('..')) {

          // If the input doesn't match the pattern or contains consecutive dots, remove the invalid character(s)
          const sanitizedValue = inputValue.replace(/[^0-9.]/g, ''); // Remove characters that are not digits or dots
          const sanitizedValueWithoutConsecutiveDots = sanitizedValue.replace(/\.{2,}/g, '.'); // Replace consecutive dots with a single dot
          this.element.value = sanitizedValueWithoutConsecutiveDots;
      
          // Show an error message
          this.element.setCustomValidity("Username can only contain letters and numbers, and consecutive dots are not allowed.");
        } else {
            this._checkInput();
            // If the input is valid, clear any previous error message
            this.element.setCustomValidity("");
        }
    }
    this.element.size = (inputValue.length - 1> 4)? inputValue.length - 1: 4;
}

    // use only in TextSelectionControls
    isValidInput() {
        let magnitude = this.getValue();
        let setbackValidation = true;
        if (this.stage.setbackEditMode.isEnabled() &&
            !this.stage.setbackEditMode.setbackValueValidator(magnitude)) {
            setbackValidation =  false;
        }
        
        if (!this.inputValidator(magnitude) ||
            !setbackValidation) {
            // TODO: Think of some other method
            return false;
        }
        return true;
    }
    getValidInput(magnitude){
        let res = magnitude;
        if (utils.isMetricUnit()) {
            res = parseFloat(magnitude);
            if(res < 0.001 ){
                return '0.001';
            }
        } else {
            if(this.parent instanceof ArcMeasurement || this.parent instanceof RotationPoint){
                return magnitude;
            }
            let feetArr = utils.parseImperialMeasurement(magnitude);
            res = (0.3048 * feetArr[0]) + (0.0254 * feetArr[1]);
            if(res < 0.001 ){
                res = 0.0393700788;
                return utils.stringifyImperialMeasurement(0,res);
            }
            return magnitude;
        }
        return (parseFloat(res).toFixed(3))
    }


    async onComplete() {
        // if(!this.valueChanged() && !this._shouldCompleteOnNoChange && utils.isMetricUnit()) {
        //     return true;
        // }
        
        let magnitude = this.getValidInput(this.getValue());

        if (this.stage.setbackEditMode.isEnabled() &&
            !this.stage.setbackEditMode.setbackValueValidator(magnitude)) {
            this.stage.eventManager.customErrorMessage(
                'Setback value should be atleast 0.001',
                'Setback EditMode',
            );
            return false;
        }

        if (utils.isMetricUnit() && !this.inputValidator(magnitude)) {
            // TODO: Think of some other method 
            this.parent.inputError();
            return false;
        }

        if(!utils.isMetricUnit() && !this.inputValidator(magnitude)){
            // this.deSelect();
            this.parent.inputError();
            throw new Error('Input validation failed.');
        }

        try {
            if (this._shouldCreateContainer &&
                !this.stage.setbackEditMode.isEnabled()) {
                this.stage.stateManager.startContainer();
            }
            await this.parent.handleValueUpdate(magnitude);
        }
        catch(e) {
            console.error("HTML Text: onComplete failed: "+e);
        }
        finally {
            if (this._shouldCreateContainer &&
                !this.stage.setbackEditMode.isEnabled()) {
                this.stage.stateManager.stopContainer();
            }
        }

        return true;

    }

    onCancel() {
        this.setElementInnerText(this.text);
        this.parent.handleOnCancel();
    }

    valueChanged() {
        if(!utils.isMetricUnit() && !(this.parent instanceof RotationPoint)) {
            return this.text !== this.convertLengthToMetric(this.getValue());
         }  
        else {
            return parseFloat(this.text).toFixed(3) !== parseFloat(this.getValue()).toFixed(3);
        }
    }
    convertLengthToMetric(length) {
        if (utils.isMetricUnit()) {
            return length;
        }
        for(let i = 0; i < length.length; i++) {
            if (length[i] === "'") {
                if(i === length.length - 1) return (parseFloat(length.substring(0, i)) * 0.3048).toFixed(3);
                return (parseFloat(length.substring(0, i)) * 0.3048 + parseFloat(length.substring(i + 1, length.length - 1)) * 0.0254).toFixed(3);
            }
        }
    }

    focus() {
        this.element.focus();
    }

    selectText() {
        // select text object on window
        let range = document.createRange();
        range.selectNodeContents(this.element);
        let selection = window.getSelection();
        // Remove any current selections on window if already there
        selection.removeAllRanges();
        // select currently selected range
        selection.addRange(range);
    }

    _checkInput = () => {
        const magnitude = this.element.value;    
        let setbackValidation = true;
        if (this.stage.setbackEditMode.isEnabled() &&
            !this.stage.setbackEditMode.setbackValueValidator(magnitude)) {
            setbackValidation =  false;
        }
        if(!this.inputValidator(magnitude) ||
            !setbackValidation){
            this.element.style.color = "red";
        }
        else{
            this.element.style.color = "white";
        }
    };

    getPosition() {
        return this.position;
    }
}
