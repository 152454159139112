import * as THREE from "three";

// Define a class to represent the nodes
export class PathNode {
    constructor(position, name = null, parent = null, strings = null) {
        this.position = position;
        this.neighbors = [];
        this.costs = new Map();
        this.name = name;
        this.parents = new Set();
        this.addParent(parent);        
        this.id = Math.random().toString(36);
        this.edges = [];
        this.strings = strings;
    }

    addNeighbor(neighbor, cost) {
        if (this.primaryNode) {
            this.primaryNode.addNeighbor(neighbor, cost);
            return;
        }
        if (!this.neighbors.includes(neighbor)) {
            this.neighbors.push(neighbor);
            this.costs.set(neighbor, cost);
        }
    }

    removeNeighbor(neighbor) {
        const index = this.neighbors.indexOf(neighbor);
        if (index !== -1) {
            this.neighbors.splice(index, 1);
            this.costs.delete(neighbor);
        }
    }

    updateIntersectionCosts() {
        // // update the cost to all neighbors whose line segments interfere with this node
        // for (const neighbor of this.neighbors) {
        //     let cost = this.getCostTo(neighbor);
        //     const edge = [this.position, neighbor.position, this.position];
        //     for (const parent of this.parents) {
        //         const intersection  = parent.isPolygonIntersecting(edge);
        //         if (intersection) {
        //             console.log('intersection: ', intersection);
        //             cost = Infinity;
        //             break;
        //         }
        //     }
        //     this.costs.set(neighbor, cost);
        // }
    }

    getCostTo(neighbor) {
        return this.costs.get(neighbor);
    }

    setCostTo(neighbor, cost) {
        this.costs.set(neighbor, cost);
    }

    reset() {
        this.parents = new Set();
        this.costs.clear();
        this.neighbors = [];
        this.position = null;
    }

    isValid() {
        return this.position !== null;
    }

    clean() {
        // remove all invalid neighbors and costs
        for (let i = this.neighbors.length - 1; i >= 0; i--) {
            const neighbor = this.neighbors[i];
            if (!neighbor.isValid()) {
                this.removeNeighbor(neighbor);
            }
        }
    }

    // get all the nodes in the graph in a flat array
    getFlatNodes() {
        const visited = new Set();
        const queue = [this];
        const nodes = [];

        while (queue.length > 0) {
            const node = queue.shift();
            if (!node.isValid()) {
                continue;
            }
            visited.add(node);
            nodes.push(node);

            for (const neighbor of node.neighbors) {
                if (!visited.has(neighbor) && !queue.includes(neighbor)) {
                    queue.push(neighbor);
                }
            }
        }

        return nodes;
    }

    addParent(parent) { 
        if (!parent) return;
        this.parents.add(parent);
        if (!parent.nodes) {
            parent.nodes = [];
        }
        if (!parent.nodes.includes(this)) {
            parent.nodes.push(this);
        }
    }
}

// Define a function to add connections between neighboring nodes
export function connectPathNodes(nodeA, nodeB, distance) {
    if(!distance) distance = nodeA.position.distanceTo(nodeB.position);
    nodeA.addNeighbor(nodeB, distance);
    nodeB.addNeighbor(nodeA, distance);
}

export function disconnectPathNodes(nodeA, nodeB) {
    nodeA.removeNeighbor(nodeB);
    nodeB.removeNeighbor(nodeA);
}

export function mergePathNodes(nodeA, nodeB) {
    nodeB.primaryNode = nodeA;
    // Merge nodeB into nodeA
    for (const neighbor of nodeB.neighbors) {
        connectPathNodes(nodeA, neighbor);
        neighbor.removeNeighbor(nodeB);
    }
    for (const parent of nodeB.parents) {
        nodeA.addParent(parent);
    }
}

// Define a function to calculate the heuristic
function heuristic(nodeA, nodeB) {
    const dx = Math.abs(nodeA.position.x - nodeB.position.x);
    const dy = Math.abs(nodeA.position.y - nodeB.position.y);
    const dz = Math.abs(nodeA.position.z - nodeB.position.z);
    return dx + dy + dz;
}

function euclideanDistance(nodeA, nodeB) {

    return nodeA.position.distanceTo(nodeB.position);
}

export function aStar(startPathNode, goalPathNode) {
    const heuristicFunc = euclideanDistance;
    let openSet = []; // add compulsoryPathNodes to the openSet
    openSet.push(startPathNode);

    let cameFrom = new Map();

    // For node n, gScore[n] is the cost of the cheapest path from start to n currently known.
    let gScore = new Map();
    gScore.set(startPathNode, 0);

    // For node n, fScore[n] is gScore[n] plus the estimated cost from n to the goal.
    // This map will be initialized to keep track of the cost to reach each node
    let fScore = new Map();
    fScore.set(startPathNode, heuristicFunc(startPathNode, goalPathNode));

    while (openSet.length !== 0) {
        // the node in openSet which has the lowest fScore[] value
        let current = openSet.reduce((a, b) =>
            fScore.get(a) < fScore.get(b) ? a : b
        );
        if (current === goalPathNode) {
            return reconstructPath(cameFrom, current);
        }

        // remove current node from openSet
        openSet.splice(openSet.indexOf(current), 1);

        for (let neighbor of current.neighbors) {
            let tentativeGScore = gScore.get(current) + current.getCostTo(neighbor);
            // calculate tentative score for current node
            if ((neighbor !== goalPathNode  && neighbor !== startPathNode) && (neighbor.name === 'startNode' || neighbor.name === 'endNode' )) {
                // set cost to infinity if the next node is a start or end node
                tentativeGScore = Infinity;
            }

            // If this path to neighbor is better than any previous paths, record it
            if (!gScore.has(neighbor) || tentativeGScore < gScore.get(neighbor)) {
                cameFrom.set(neighbor, current);
                gScore.set(neighbor, tentativeGScore);
                fScore.set(neighbor, tentativeGScore + heuristicFunc(neighbor, goalPathNode));

                // add neighbor to openSet if it's not already there
                if (!openSet.includes(neighbor)) {
                    openSet.push(neighbor);
                }
            }
        }

        // print the lengths
        // console.log(`Open Set Length: ${openSet.length}`);
        // console.log(`Closed Set Length: ${cameFrom.size}`);
    }

    // no path found
    return null;
}


export function aStarMustVisit(startPathNode, goalPathNode, compulsoryPathNodes, avoidNodes = []) {

    // create all possible permutations of paths that start with startPathNode and end with goalPathNode and visit all compulsoryPathNodes
    const paths = [];
    const compulsoryPathNodesCopy = [...compulsoryPathNodes];
    const permutations = permute(compulsoryPathNodesCopy);
    for (const permutation of permutations) {
        permutation.unshift(startPathNode);
        permutation.push(goalPathNode);
        paths.push(permutation);
    }

    // find the permutation with the lowest cost
    let lowestCost = Infinity;
    let lowestCostPath = null;
    const pathLookup = {};
    for (const path of paths) {
        let pathCost = 0;
        const totalPath = [];
        for(let i = 0; i < path.length - 1; i++) {
            const current = path[i];
            const next = path[i + 1];

            let cost = 0;
            let subPath = [];
            // check if the path has already been calculated
            if (!current || !next) {
                // no path found
                continue;
            }
            if (pathLookup[current.id+next.id]) {
                // path has already been calculated
                const lookup = pathLookup[current.id+next.id] || pathLookup[next.id+current.id];
                cost = lookup.cost;
                subPath = lookup.path;
            }
            else {
                // path has not been calculated
                let result = aStar(current, next);

                if (result === null) {
                    // no path found
                    return {path: null, cost: Infinity};
                }
                cost = result.cost;
                subPath = result.path;
                pathLookup[current.id+next.id] = {cost, path : subPath};
                pathLookup[next.id+current.id]
            }
            pathCost += cost;
            totalPath.push(...subPath);
        }
        if (pathCost < lowestCost) {
            lowestCost = pathCost;
            lowestCostPath = totalPath;
        }        
    }
    return {path: lowestCostPath, cost: lowestCost};
}

function permute(permutation) {
    let length = permutation.length,
        result = [permutation.slice()],
        c = new Array(length).fill(0),
        i = 1,
        k,
        p;

    while (i < length) {
        if (c[i] < i) {
            k = i % 2 && c[i];
            p = permutation[i];
            permutation[i] = permutation[k];
            permutation[k] = p;
            ++c[i];
            i = 1;
            result.push(permutation.slice());
        } else {
            c[i] = 0;
            ++i;
        }
    }
    return result;
}

function reconstructPath(cameFrom, current) {
    let totalPath = [current];
    let totalCost = 0;
    while (cameFrom.has(current)) {
        current = cameFrom.get(current);
        totalCost += current.getCostTo(totalPath[0]);
        totalPath.unshift(current);        
    }
    return {path: totalPath, cost: totalCost};
}

export function printPath(path) {
    if (path !== null) {
        const nodeNames = path.map(node => getPathNodeName(node));
        console.log("Path: " + nodeNames.join(" -> "));
    } else {
        console.log("No path found.");
    }
}

function getPathNodeName(node) {
    return node.name !== null ? node.name : node.position.toArray().join(",");
}

export function printGraph(startPathNode) {
    const visited = new Set();
    const queue = [startPathNode];

    while (queue.length > 0) {
        const node = queue.shift();
        if (!node.isValid()) {
            continue;
        }
        visited.add(node);

        const nodeName = getPathNodeName(node);
        const line = `${nodeName} -- ${node.neighbors.map(neighbor => getPathNodeName(neighbor)).join(", ")}`;
        console.log(line);

        for (const neighbor of node.neighbors) {
            if (!visited.has(neighbor) && !queue.includes(neighbor)) {
                queue.push(neighbor);
            }
        }
    }
}