import { defineStore } from 'pinia';

export const useContractManagementStore = defineStore('contractManagement', {
    state: () => {
        return {
            idOfLargerElement:'',
            htmlContent:"",
            fileLink:"",
            placeholdersArray:[],
            keyValuePlaceholders:{},
            selectedDesignIdForPreview:null,
            addedPlaceholdersKeyValuePair:{},
            selectedContractId: null,
            contractWithLinkAndPlaceholders:[],
            isDocumentTabsOpenCounter:0,
            customPlaceholdersArray:[],
            uniqueCustomPlaceholdersArray:[],
            latestAddedPlaceholders:[],
            isEditorSavingInProgress: false,
            isWaitingForPreview: false,
            isEditorContentChanged: false,
            isTemplatePreviewing: false,
        }
    },
    getters: {
        GET_ID_OF_LARGER_ELEMENT: state => state.idOfLargerElement,
        GET_HTML_CONTENT: state=> state.htmlContent,
        GET_FILE_LINK: state=> state.fileLink,
        GET_PLACEHOLDERS_ARRAY: state=> state.placeholdersArray,
    },
    actions: {
        async SET_ID_OF_LARGER_ELEMENTS(payload) {
            this.idOfLargerElement = payload;
        },
        SET_HTML_CONTENT(htmlContent){
            this.htmlContent = htmlContent
        },
        SET_FILE_LINK(fileLink){
            this.fileLink = fileLink
        },
        UPDATE_PLACEHOLDERS_ARRAY(placeholdersArray){
            this.placeholdersArray = [... placeholdersArray]
        },
        UPDATE_CUSTOM_PLACEHOLDERS_ARRAY(customPlaceholdersArray){
            this.customPlaceholdersArray = [... customPlaceholdersArray]
        },
        UPDATE_UNIQUE_CUSTOM_PLACEHOLDERS_ARRAY(uniqueCustomPlaceholdersArray){
            this.uniqueCustomPlaceholdersArray = [... uniqueCustomPlaceholdersArray]
        },
        SET_KEY_VALUE_PLACEHOLDER(keyValuePlaceholders){
            this.keyValuePlaceholders = JSON.parse(JSON.stringify(keyValuePlaceholders)) 
        },
        SET_KEY_VALUE_FOR_ADDED_PLACEHOLDER(addedPlaceholdersKeyValuePair){
            this.addedPlaceholdersKeyValuePair = JSON.parse(JSON.stringify(addedPlaceholdersKeyValuePair)) 
        },
        SET_CONTRACT_WITH_LINK_AND_PLACEHOLDERS(contractWithLinkAndPlaceholders){
            this.contractWithLinkAndPlaceholders = contractWithLinkAndPlaceholders
        },
        SET_SELECTED_DESIGN_ID(designId){
            this.selectedDesignIdForPreview=designId;
        }
    }
})