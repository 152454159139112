
<div>
    <div class="container">
        <div v-for="(type, index) in accordions" :key="index">
            <button @click="toggleAccordion(index)"
                :class="['accordion', { active: activeAccordions.includes(index) }]">{{
                    type
                }}<b-icon icon="chevron-right" aria-hidden="true"
                    :class="['dropdownIcon', { dropdownActive: activeAccordions.includes(index) }]"></b-icon>
            </button>
            <div v-show="activeAccordions.includes(index)" class="panel">
                <draggable group="people" :key="componentKey" @start="onDragStart" @end="e => onDragEnd(type, e)"
                    handle=".dragIcon">
                    <div class="flexParentCont" v-for="(step, index) in finalData[type]" :key="index" :id="index">
                        <div draggable="true" class="dragIcon"><img src="../../../assets/img/shuffledotsIcon.svg" alt=""></div>
                        <div class="listContainer">
                            <div class="flexContainer">
                                <div class="flexBtw">
                                    <p class="font16Normal">{{ step.title }}</p>
                                    <div class="flexIcon">
                                        <el-tooltip class="item" effect="dark" content="Edit" placement="top-start">
                                            <b-icon icon="pencil" :id="index" class="editIcon" aria-hidden="true"
                                                @click="addAndEditStep('edit', type, step)"></b-icon>
                                        </el-tooltip>
                                        <el-tooltip class="item" effect="dark" content="Delete"
                                            placement="top-start">
                                            <b-icon icon="trash" :id="index" class="delIcon" aria-hidden="true"
                                                @click="handleDeleteClick(step)"></b-icon>
                                        </el-tooltip>
                                        <el-tooltip class="item" effect="dark" :disabled="!isSwitchDisabled(step)"
                                            content="Percentage limit has reached." placement="top-start"
                                            >
                                            <el-switch v-model="step.show_in_contract"
                                                :disabled="isSwitchDisabled(step)"
                                                @change="handleSwitchChange(step)"></el-switch>

                                        </el-tooltip>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </draggable>
                <p class="addNewStep" @click="addAndEditStep('add', type)">
                    <b-icon icon="plus" aria-hidden="true" class="plusIcon"></b-icon>Add New Step
                </p>
            </div>
            <DeleteDialog v-if="isDeleteDialogVisible" :isDeleteProjectDocumentPopupOpen="isDeleteDialogVisible"
                :message="`Are you sure you want to delete this step?`"
                @cancelDelete="isDeleteDialogVisible = false" @confirmDelete="handleDeleteStep" />
        </div>
    </div>
    <AddNewStepPopup v-if="isAddNewStepPopupVisible" :isAddNewStepPopupVisible.sync="isAddNewStepPopupVisible"
        :mode="mode" :projectType="projectType" @fetch-all-payment-schedule="fetchAllPaymentSchedule"
        :editSelectedStep="editSelectedStep" :finalData="finalData"
        :sumOfPercentageValuesForResidential="sumOfPercentageValuesForResidential"
        :sumOfPercentageValuesForCommercial="sumOfPercentageValuesForCommercial"
        @updatedScheduleAndSwitches="updatedScheduleLocally" />
</div>
