
<div
  class="tabsContainer"
  :class="activeName == 'first' ? 'first' : ''"
  v-loading="!$props.lead"
>
<div style="z-index: 9999;">
    <EmailPopup
     v-if="openEmailPopup"
      :openDrawer="openEmailPopup"
      :leadData="lead"
      :admin="admin"
       @onClickClose="handleOpenEmailPopup" 
       @addEmailToListEmailView="addEmailToListEmailView"
       />
    <MeetingPopup  
      v-if="openMeetingPopup"
        :admin="admin"
      :leadData="lead"
     :openDrawer="openMeetingPopup"
      @onClickClose="handleOpenMeetingPopup"
      @removeMeeting="removeMeetingFromTheListMeetingView" 
      @addMeeting="addMeetingToListMeetingView"
      />
  </div>
  <el-tabs  @tab-click="onTabClick" v-model="activeName">
    <el-tab-pane
      label="Activity Timeline"
      name="first"
      class="commonTabClass"
    >
      <ActivityTimeline
        :isDawer="true"
        :focusAddNote="focusAddNote"
        :lead="lead"
      />
    </el-tab-pane>
    <el-tab-pane label="Tasks" name="second" class="commonTabClass">
      <div class="task-container">
        <Tasks
          :lead="lead"
          :project_id="project_id"
          :sizeType="'medium'"
          :isLeadSummary="lead.lead_details.id"
        />
      </div>
    </el-tab-pane>
    <el-tab-pane label="Documents" name="third" class="commonTabClass">
      <Documents  ref="documentsComponent" :lead="lead" />
    </el-tab-pane>
    <!-- email---------------------------------------------------------------------------------------- -->
    <el-tab-pane label="Emails" name="fourth" class="commonTabClass" >
      <div
       v-if="!isIntegrated"
        style="margin-top: 10px;"
       >
        <IntegrationPage />
      </div>
      <div 
      v-else
       style="padding: 10px;"
      >
      <div 
      class="create-email"
       >
        <el-button
        type="primary" 
        :disabled="fetchEmailLoading || !givenPermissionForEmail"
        @click="handleOpenEmailPopup"
       >
                  Create New Email
        </el-button>
        </div>
      <div v-if="emailInfo.length > 0">
      <div 
      v-for="(item, index) in filteredEmailInfo"
      :key="index"
      style="padding: 10px;"
      >
      <EmailDropdown 
        :item="item.messages[0]" 
        :lead="lead"
        :admin="admin"
        @removeEmail="removeEmailFromTheListEmailView"
         @addEmailToListEmailView="addEmailToListEmailView"
         @updateData="updateData"
       />
    </div>
    <div v-if="fetchEmailLoading">
      <el-skeleton
        style="height: 100px;"
       :rows="3" 
       animated 
       />
    </div>
    </div>
    <div v-else>
      <div
     v-if="fetchEmailLoading"
     style="padding: 10px;"
     >
      <el-skeleton
        style="height: 100px;"
       :rows="3" 
       animated 
       />
    </div>
    <div v-else>
       <NoDataPage :permission="givenPermissionForEmail"/>
      </div>
    </div>
    </div>
    </el-tab-pane>
    <!-- meetings --------------------------------------------------------------------------------------->
     <el-tab-pane label="Meetings" name="fifth" class="commonTabClass" >
      <div
       v-if="!isIntegrated" 
       style="margin-top: 10px;"
       >
        <IntegrationPage />
      </div>
      <div 
      v-else
      style="padding: 10px;"
      >
        <div
        class="create-email"
        >
        <el-button
        type="primary" 
        :disabled="fetchMeetingLoading ||!givenPermissionForMeeting"
       @click="handleOpenMeetingPopup"
       >
                  Create New Meeting
        </el-button>
        </div>
        <div v-if="eventsInfo.length > 0">
          <div
        v-for="(item, index) in filteredEventsInfo" 
         
        :key="index"
        style="padding: 10px;"
      >
        <MeetingsDropdown
        :items="item"  
         :admin="admin"
         :leadData="lead"
        @removeMeeting="removeMeetingFromTheListMeetingView" 
        @updateNewMeeting="updateNewMeeting"
        />
      </div>  
      <div v-if="fetchMeetingLoading">
              <el-skeleton
                style="height: 100px;"
                :rows="3" 
                animated 
              />
              </div>
        </div>
      <div v-else>
        <div
     v-if="fetchMeetingLoading"
     style="padding: 10px;"
     >
      <el-skeleton
        style="height: 100px;"
       :rows="3" 
       animated 
       />
    </div>
        <NoDataPage 
        v-else
        :message="'meeting is scheduled.'"
         :permission="givenPermissionForMeeting"
         />
      </div>
</div>
    </el-tab-pane>
  </el-tabs>
</div>
