<template>
    <div class="custom-date">
        <div  v-for="(customDate,index) in selectedData" :key="index">
            <div  >
        <DatePickerView
                  :itemValue="customDate"
                  :selectedValue="customDate?.selectedValue || ''"
                  :indexOfOptions="index"
                  @item-selected="selectedDate"
                  :disabledPrev="disabledPrev"
                />
            </div>
            </div>
    </div>
</template>

<script>
import DatePickerView from './datePickerView.vue';
import {DateTime} from 'luxon';


    export default {
    emits: ["custom-selecteddates"],
    components: { DatePickerView },
  
    mounted(){
    },
  
    data(){
        return{
            itemData:[{
                title:'From',
                prop:this.$props.fromLabel,
                selectedValue:'',
                type:'from',
            },
            {
                title:'To',
                prop:this.$props.toLabel,
                selectedValue:'',
                type:"to"
            }
        ],
        startEndDates:{},
        disabledPrev:''

        }
    },
 
    computed: {
        selectedData() {
            const customSelectedData = this.$props.customDates?.additional_details
            if (customSelectedData) {
                return this.itemData.map(d => {
                    if (d.prop === customSelectedData?.additional_details[d.prop]) {
                        d.selectedValue = customSelectedData?.additional_details[d.prop]
                    }
                })
            } else {
                return this.itemData
            }
        }
    },
    props:{
        customDates:{
            type:Object,
            default() {
                return {}
            }
        },
        fromLabel:{
            type:String,
            default:''
        },  
         toLabel:{
            type:String,
            default:''
        },
        timerangeType:{
            type:String,
            default:''
        }
    },
 
    methods:{
        selectedDate(type,dataValue,value,selectedDateObj,selectedIndex){
            if(selectedDateObj.type==='from'){
this.disabledPrev=value
            }
            this.itemData.map(d=>{
                if(d.type==='from'&&selectedDateObj.type==='from'){
                    d.selectedValue=value;
                }
                if(d.type==='to'&&selectedDateObj.type==='to'){
                    d.selectedValue=value;
                }


                if(d.type==='to'&&d.selectedValue){
    const fromDate=DateTime.fromISO(this.disabledPrev);
    const toDate=DateTime.fromISO(d.selectedValue);
    d.selectedValue=fromDate>toDate?'':d.selectedValue;
}

                if(selectedDateObj.type==='to'&&this.disabledPrev){
                    d.disabledPrev=this.disabledPrev
                }
            })
            this.startEndDates[type]=value

            this.$emit('custom-selecteddates',type,value,this.$props.timerangeType)
        }
    },
    watch:{
        customDates:{
            immediate:true,
            deep:true,
            handler(val){

                if(val){

                    this.itemData.map(d=>{
                        
                if(val[d.prop]){
                    d.selectedValue=val[d.prop]
                }
              })


                }

            }
        }
    }
}
</script>

<style scoped>
.custom-date{
    display: flex;
  align-items: center;
}
</style>