<!-- Thanks to https://vueschool.io/articles/vuejs-tutorials/build-an-infinite-scroll-component-using-intersection-observer-api/ -->
<template>
  <div class="observer">
    <i
      v-if="showLoader"
      class="el-icon-loading infiniteScrollLoader"
    />
  </div>
</template>

<script>
export default {
  emits: ["intersect"],
  props: [
    'options',
    'showLoader',
  ],
  data: () => ({
    observer: null,
  }),
  mounted() {
    const options = this.options || {};
    this.observer = new IntersectionObserver(([entry]) => {
      if (entry && entry.isIntersecting) {
        this.$emit("intersect");
      }
    }, options);

    this.observer.observe(this.$el);
  },
  destroyed() {
    this.observer.disconnect();
  },
};
</script>
<style scoped>
.observer {
  width: 100%;
  min-height: 1px;
  text-align: center;
}
</style>