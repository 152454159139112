
<div class="">
  <Navbar @handleToggleSideBar="handleSidebar" />
  <main class="main-controller">
    <div class="backdrop"></div>
    <Sidebar :isSidebarOpen="isSidebarOpen" />
    <SiteSurveyForm v-if="showSiteSurveyFormComponent" @toggle="showCreateAndEditForm"  @previewFunc="previewFormFunc"/>
    <CreateAndEditSiteSurveyForm 
    v-loading="isLoadingForm"
    v-else @toggleComponent="showSiteSurveyForm" 
    :formData="formData"
    :isNewTemplate="isNewTemplate"
    :isDuplicateTemplate="isDuplicateTemplate"
    :templateId="templateId"
    @createTemplate="createTemplate"
    @addSection="addNewSection" 
    @deleteCurrentSection="deleteSection"
    @duplicateCurrentSection="duplicateSection"
    @addFieldToSection="addNewFieldToSection"
    @deleteCurrentField="deleteCurrentField"
    @duplicateCurrentField="duplicateCurrentField"
    @duplicateCurrentFile="duplicateCurrentFile"
    @deleteCurrentFile="deleteCurrentFile"
    @patchTemplate="patchTemplate"
     />
    <DeleteDialog :dialogVisible="openCreateNewTemplateDialog" :footerButtonType="'primary'" :footerButtonText="'Yes'"
        :disableCancel="true" :width="'20%'" :deleteMessage="`Do you want to create a new template?`"
        :infoMessage="`This template has already been shared.`" @handleClick="createNewTemplateFromEdit" @handleClose="cancelCreateNewTemplateFromEdit">
    </DeleteDialog>
  </main>
</div>
