import { defaultModuleId } from "../../constants"

export function getDefaultModuleProperties() {
    return {
        moduleId: defaultModuleId,
        moduleMake: 'The Solar Labs SolarLabs TSL-310-330-FX TSL-315-FX',
        moduleSize: 0.313,
        moduleLength: 2.030,
        moduleWidth: 0.98,
    }
}

export function getDefaultPanelProperties() {
    return {
        characteristics: {
            cell_number: 72,
            cell_type: 'Monocrystalline',
            length: 2.03,
            manufacturer: 'The Solar Labs',
            model: 'TSL-315-FX',
            p_mp_ref: 313,
            series: 'SolarLabs TSL-310-330-FX',
            v_max: 999,
            width: 0.98,
        },
        id: defaultModuleId,
        image: null,
        image_link: null,
        is_selected: true,
        model: 'SolarLabs TSL-310-330-FX TSL-315-FX',
    }
}