
<div class="drawer-container" v-loading="loading">
  <el-row>
    <el-col :span="8">
      <div class="img-container">
      
        <img :src="imgValue" alt="" />
      </div>
    </el-col>
    <el-col :span="16" class="content-container">
      <div v-for="(data, inx) in settingsData" :key="inx">
        <div v-if="data.type === 'input'" class="body-container">
          <InputView
            :itemValue="data"
            :selectedValue="data?.selectedValue || ''"
            @item-selected="selectedData"
            :widgetId="widgetId"
          />
        </div>
        <div v-if="data.type === 'select'" class="body-container">
          <SelectComponentView
            :items="data"
            :selectedValue="data.selectedValue"
            @item-selected="selectedData"
            :isGrouped.sync="isGrouped"
            :widgetType="widgetType"
            :isCustomTime.sync="isCustomTime"
            :seletedSource="seletedSource"
          />
        </div>
        <div
          v-if="data.type === 'comparison' && isComparison"
          class="body-container"
        >
          <SelectComponentView
            :items="data"
            :selectedValue="data.selectedValue"
            @item-selected="selectedData"
            :isGrouped.sync="isGrouped"
            :widgetType="widgetType"
            :isCustomTime.sync="isCustomTime"
            :seletedSource="seletedSource"
          />
        </div>
        <div
          v-if="
            data.type === 'filter' &&
            filterColumnOption.length > 0 &&
            isFilter
          "
          class="body-container"
        >
          <FilterView
            :items="data"
            :filterColumnOption="filterColumnOption"
            :otherFilterOptions="otherFilterOptions"
            :selectedFilterDetails="selectedFilterDetails"
            @filter-details="handleFilterDetails"
            :seletedSource="seletedSource"
            :isFilterEmpty.sync="isFilterEmpty"
            :leadOptions="apiData?.measure_groups?.Leads"
          />
        </div>
        <div
          v-if="data.type === 'dropdown' && !isGrouped"
          class="body-container"
        >
          <DropDownView
            :items="data"
            :selectedValue="data?.selectedValue || ''"
            @item-selected="selectedData"
          />
        </div>
        <div
          v-if="data.type === 'grouped' && isGrouped"
          class="body-container"
        >
          <GroupedTableInputs
            :groupedTableOptions="groupedTableOptions"
            @measure_details="getMeasureDetails"
            :groupedTableSelectedData="groupedTableSelectedData"
            :seletedSource="seletedSource"
          />
        </div>
        <div v-if="data.type === 'checkbox'" class="body-container">
          <CheckboxView
            :items="data"
            :selectedValue="data?.selectedValue || false"
            :isDisabled="isDisabled"
            :showTotal.sync="showTotal"
            @item-selected="selectedData"
          />
        </div>
        <div
          v-if="data.type === 'buckets' && isBucket"
          class="body-container"
        >
          <BuckertsView
            :items="data"
            :selectedValue="data?.selectedValue || false"
            :bucketsSelecteddata="bucketsSelecteddata"
            @bucket_details="getBucketDetails"
          />
        </div>

        <div
          v-if="data.type === 'timeinterval' && isTimeInterval"
          class="body-container"
        >
          <SelectComponentView
            :items="data"
            :selectedValue="data.selectedValue"
            @item-selected="handleTimeInterval"
            :isGrouped.sync="isGrouped"
            :widgetType="widgetType"
            :seletedSource="seletedSource"
          />
        </div>

        <div
          v-if="data.type === 'closed_at' && isClosedAt"
          class="body-container"
        >
          <SelectComponentView
            :items="data"
            :selectedValue="data.selectedValue"
            @item-selected="handleSelectTimeRange"
            :isGrouped.sync="isGrouped"
            :widgetType="widgetType"
            :isCustomTimeClosed.sync="isCustomTimeClosed"
            :seletedSource="seletedSource"
          />
        </div>
        <div
          v-if="data.type === 'activity_time' && isActivityTime"
          class="body-container"
        >
          <SelectComponentView
            :items="data"
            :selectedValue="data.selectedValue"
            @item-selected="handleSelectTimeRange"
            :isGrouped.sync="isGrouped"
            :widgetType="widgetType"
            :isCustomTimeActivity.sync="isCustomTimeActivity"
            :seletedSource="seletedSource"
          />
        </div>
        <div v-if="data.type === 'number'" class="body-container">
          <NumberInput
            :itemValue="data"
            :selectedValue="data?.selectedValue || ''"
            @item-selected="selectedData"
            :limitData="limitData"
          />
        </div>
        <div
          v-if="data.type === 'custom_time' && isCustomTime"
          class="body-container"
        >
          <CustomTimeView
            @custom-selecteddates="handleCustomDate"
            :customDates="additionalDetails"
            :fromLabel="'created_at_start_date'"
            :toLabel="'created_at_end_date'"
          />
        </div>
        <div
          v-if="data.type === 'custom_closed_time' && isCustomTimeClosed"
          class="body-container"
        >
          <CustomTimeView
            @custom-selecteddates="handleCustomDate"
            :customDates="additionalDetails"
            :fromLabel="'closed_at_start_date'"
            :toLabel="'closed_at_end_date'"
            :timerangeType="'closed_at'"
          />
        </div>
        <div
          v-if="data.type === 'custom_activity_time' && isCustomTimeActivity"
          class="body-container"
        >
          <CustomTimeView
            @custom-selecteddates="handleCustomDate"
            :customDates="additionalDetails"
            :fromLabel="'activity_time_start_date'"
            :toLabel="'activity_time_end_date'"
            :timerangeType="'activity_time'"
          />
        </div>
      </div>
    </el-col>
  </el-row>
</div>
