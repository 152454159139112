import { defineStore } from 'pinia'

function getInitialState() {
    return {
        sale_studio_mode: {
            modelType: 'drawRoof',
            showModelsBtnsActive: true,
            drawRoofBtnActive: true,
            flatRoofBtnActive: true,
            powerGazeboBtnActive: true,
            obstructionBtnActive: false,
            panelsBtnActive: false,
            showToolbar: false,
            selectedModel: null,
            showEdgeProperties: false,
            showCommonDropdown: true,
            showObstructionTypeCard: false,
            showInputFiledsCard: false,
            showDrawRoofInputsCard: false,
            showObstructionDrawInputsCard: false,
            showObstructionRectangleInputsCard: false,
            showObstructionCylinderInputsCard: false,
            showObstructionTreeInputsCard: false,
            disabledBackToLeadBtn: false,
            disabledPrevBtn: true,
            disabledNextBtn: true,
            disabledSubmitBtn: false,
            disabledSaveBtn: true,
            disabledCancelBtn: true,
            disabledUpdateBtn: false,
            showGenerationCard: false,
            showCompleteBtn: true,
            showGuidanceMsg: false,
            manageComponentActive: false,
            gazeboEnabled: true,
            toggleObt: false,
            isAIModelPresent: false,
            viewMode: '2D',
            cannotComplete: false,
            isAIinProcess: false,
            showHeatMapToggle: true,
            showSolarAccessToggle: true,
        }
    }
}


export const useSalesStudioModeStore = defineStore('salesStudioMode', {

    state: () => getInitialState(),

    getters: {
        GET_SALE_STUDIO_MODE_DATA: (state) => state.sale_studio_mode,
        GET_IS_AI_MODEL_PRESENT: (state) => state.sale_studio_mode.isAIModelPresent,
        IS_3D: (state) => state.sale_studio_mode.viewMode == '3D'
    },

    actions: {
        SET_SALE_STUDIO_MODE_DATA(val) {
            this.sale_studio_mode = { ...val };
        },
        // juggad for statemanagement : back to lead 
        SET_INITIAL_STATE() {
            const initialState = {
                modelType: 'drawRoof',
                showModelsBtnsActive: true,
                drawRoofBtnActive: true,
                flatRoofBtnActive: true,
                powerGazeboBtnActive: true,
                obstructionBtnActive: false,
                panelsBtnActive: false,
                showToolbar: false,
                showEdgeProperties: false,
                showCommonDropdown: false,
                showObstructionTypeCard: false,
                showInputFiledsCard: false,
                showDrawRoofInputsCard: false,
                showObstructionDrawInputsCard: false,
                showObstructionRectangleInputsCard: false,
                showObstructionCylinderInputsCard: false,
                showObstructionTreeInputsCard: false,
                // showObtructionsCard: false,
                disabledBackToLeadBtn: false,
                disabledPrevBtn: true,
                disabledNextBtn: true,
                disabledSubmitBtn: false,
                disabledSaveBtn: false,
                disabledCancelBtn: true,
                disabledUpdateBtn: false,
                showGenerationCard: false,
                showCompleteBtn: true,
                manageComponentActive: false,
                gazeboEnabled: true,
                toggleObt: false,
                viewMode: '2D',
            }
            this.sale_studio_mode = getInitialState().sale_studio_mode;
        },

        CANCEL_ENABLED() {
            this.sale_studio_mode.disabledCancelBtn = false;
        },

        UPDATE_BUTTON_DISABLED_STATUS(disabledStatus) {
            this.sale_studio_mode.disabledUpdateBtn = disabledStatus;
        },

        DISABLE_PREVIOUS_BUTTON() {
            this.sale_studio_mode.disabledPrevBtn = true;
        },

        ENABLE_PREVIOUS_BUTTON() {
            this.sale_studio_mode.disabledPrevBtn = false;
        },

        DISABLED_STATE() {
            this.sale_studio_mode.showCommonDropdown = false;
            this.sale_studio_mode.disabledBackToLeadBtn = true;
            this.sale_studio_mode.disabledPrevBtn = true;
            this.sale_studio_mode.disabledNextBtn = true;
            this.sale_studio_mode.disabledSubmitBtn = true;
            this.sale_studio_mode.disabledSaveBtn = true;
            this.sale_studio_mode.disabledCancelBtn = true;
            this.sale_studio_mode.disabledUpdateBtn = true;
            this.sale_studio_mode.showGenerationCard = false;
            this.sale_studio_mode.showCompleteBtn = false;
        },

        RESET_STATE() {
            this.$reset();
        },

        SET_IS_AI_MODEL_PRESENT() {
            this.sale_studio_mode.isAIModelPresent = true;
        },

        UNSET_IS_AI_MODEL_PRESENT() {
            this.sale_studio_mode.isAIModelPresent = false;
        },

        SET_VIEW_MODE(mode) {
            this.sale_studio_mode.viewMode = mode;
        },

        SET_CANNOT_COMPLETE(val) {
            this.sale_studio_mode.cannotComplete = val;
        },

        SET_IS_AI_IN_PROCESS(val) {
            this.sale_studio_mode.isAIinProcess = val;
        },

        GENERATION_CARD_VISIBILITY(val) {
            this.sale_studio_mode.showGenerationCard = val;
        },

        SET_HEATMAP_TOGGLE_VISIBILITY(val) {
            this.sale_studio_mode.showHeatMapToggle = val;
        },

        SET_SOLARACCESS_TOGGLE_VISIBILITY(val) {
            this.sale_studio_mode.showSolarAccessToggle = val;
        }
    },
});