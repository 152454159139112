export default class TouchAndMouseManager {
    constructor() {
        this.listeners = new Map();
    }
    addListener(element, eventType, callback, controlType, useCapture = false, options = {}) {
        if (!this.listeners.has(element)) {
            this.listeners.set(element, new Map());
        }
        const eventsMap = this.listeners.get(element);
        if (!eventsMap.has(eventType)) {
            eventsMap.set(eventType, []);
        }
        const callbacks = eventsMap.get(eventType);

        if (!callbacks.some((listener) => listener.callback === callback && listener.controlType === controlType)) {
            callbacks.push({ callback, controlType });
            element.addEventListener(eventType, callback, useCapture, options);
        }
    }

    removeListener(element, eventType, callback, controlType, useCapture = false, options = {}) {
        if (this.listeners.has(element)) {
            const eventsMap = this.listeners.get(element);

            if (eventsMap.has(eventType)) {
                const callbacks = eventsMap.get(eventType);
                const index = callbacks.findIndex((listener) => listener.callback === callback && listener.controlType === controlType);

                if (index !== -1) {
                    callbacks.splice(index, 1);
                    element.removeEventListener(eventType, callback, useCapture, options);
                }
            }
        }
    }

    removeAllListeners() {
        this.listeners.forEach((eventsMap, element) => {
            eventsMap.forEach((callbacks, eventType) => {
                callbacks.forEach((listener) => {
                    const { callback } = listener;
                    element.removeEventListener(eventType, callback);
                });
            });
        });

        this.listeners.clear();
    }

    handleTouch(ev) {
        switch (ev.touches.length) {
            case 1: // Single Touch
                handleSingleTouch(ev);
                break;
            case 2: // Double Touch
                handleDoubleTouch(ev);
                break;
            default:
                break;
        }
    }

    handleSingleTouch(ev) {
        console.log('handleSingleTouch');
        // Handle single touch event (e.g., point placement)
    }
    
    handleDoubleTouch(ev) {
        console.log('handleDoubleTouch: ');
        // Handle double touch event (e.g., pinch zoom)
    }
    

};
