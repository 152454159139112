<template>
  <div class="">
    <Navbar @handleToggleSideBar="handleSidebar" />
    <main class="main-controller">
      <div class="backdrop"></div>
      <Sidebar :isSidebarOpen="isSidebarOpen" />
      <SiteSurveyForm v-if="showSiteSurveyFormComponent" @toggle="showCreateAndEditForm"  @previewFunc="previewFormFunc"/>
      <CreateAndEditSiteSurveyForm 
      v-loading="isLoadingForm"
      v-else @toggleComponent="showSiteSurveyForm" 
      :formData="formData"
      :isNewTemplate="isNewTemplate"
      :isDuplicateTemplate="isDuplicateTemplate"
      :templateId="templateId"
      @createTemplate="createTemplate"
      @addSection="addNewSection" 
      @deleteCurrentSection="deleteSection"
      @duplicateCurrentSection="duplicateSection"
      @addFieldToSection="addNewFieldToSection"
      @deleteCurrentField="deleteCurrentField"
      @duplicateCurrentField="duplicateCurrentField"
      @duplicateCurrentFile="duplicateCurrentFile"
      @deleteCurrentFile="deleteCurrentFile"
      @patchTemplate="patchTemplate"
       />
      <DeleteDialog :dialogVisible="openCreateNewTemplateDialog" :footerButtonType="'primary'" :footerButtonText="'Yes'"
          :disableCancel="true" :width="'20%'" :deleteMessage="`Do you want to create a new template?`"
          :infoMessage="`This template has already been shared.`" @handleClick="createNewTemplateFromEdit" @handleClose="cancelCreateNewTemplateFromEdit">
      </DeleteDialog>
    </main>
  </div>
</template>
    
<script>
import Navbar from "../../components/ui/newNavbar.vue";
import Sidebar from "../../components/ui/sidebar.vue";
import SiteSurveyForm from "./components/siteSurveyForm.vue";
import CreateAndEditSiteSurveyForm from "./components/createAndEditSiteSurveyForm.vue";
import DeleteDialog from '@/pages/dashboardCRM/components/dashboardV2/deleteDialog.vue';
import { mapState, mapActions } from 'pinia';
import { useEditableSiteSurveyStore } from "../../stores/editableSiteSurvey";
import API from '@/services/api';
import debounce from "debounce";
import {CONTAINER_NAME} from '../../constants';
import { v4 } from "uuid";


export default {
  name: "customTariff",
  components: {
    Navbar,
    Sidebar,
    SiteSurveyForm,
    CreateAndEditSiteSurveyForm,
    DeleteDialog,
  },

  data() {
    return {
      isSidebarOpen: false,
      showSiteSurveyFormComponent: true,
      isLoadingForm: false,
      templateId: 0,
      openCreateNewTemplateDialog: false,
      currentTemplateType: null,
      filesStatus: [],
      countFiles: 0,
      isNewTemplate: false,
      isDuplicateTemplate: false,
    };
  },

  computed: {
    ...mapState(useEditableSiteSurveyStore, {
      formData: "GET_FORM_DATA",
      originalFormData: "GET_ORIGINAL_FORM_DATA",
      isPreviewMode : "GET_PREVIEW_FLAG",
      newFilesStatusArray: "GET_NEW_FILES_STATUS_ARRAY",
      countNewFilesToUpload: "GET_COUNT_NEW_FILES_TO_UPLOAD",
    }),
    isEditTemplateMode(){
      // to determine the actual edit template mode
      return this.templateId !== 0 && !this.isPreviewMode;
    }
  },
  mounted(){
    this.debouncedPatchTemplateData = debounce(this.patchTemplateData, 1000);
    this.debouncedPatchSectionData = debounce(this.patchSectionData, 1000);
    this.debouncedDeleteSectionData = debounce(this.deleteSectionInTemplate, 1000);
    this.debouncedPatchFieldData = debounce(this.patchFieldData, 1000);
    this.debouncedDeleteFieldData = debounce(this.deleteFieldData, 1000);
    this.debouncedDeleteFileFromBlob = debounce(this.deleteFileFromBlob, 1000);
  },
  updated(){
  },
  methods: {
    ...mapActions(useEditableSiteSurveyStore, {
          resetFormData: "RESET_FORM_DATA",
          updateFormData:"UPDATE_FORM_DATA",
          updateOriginalFormData:"UPDATE_ORIGINAL_FORM_DATA",
          removeUnwantedKeysFromFormData: "REMOVE_UNWANTED_KEYS_FROM_FORMDATA",
          removeUnwantedKeysFromFieldData:"REMOVE_UNWANTED_KEYS_FROM_FIELDDATA",
          addRequiredKeysToFormData : "ADD_REQUIRED_KEYS_TO_FORMDATA",
          addRequiredKeysToFieldData:"ADD_REQUIRED_KEYS_TO_FIELDDATA",
          setPreviewModeFalse : "SET_PREVIEW_MODE_FALSE",
          setPreviewModeTrue : "SET_PREVIEW_MODE_TRUE",
          setAllowFieldPatchFlagTrue : "SET_ALLOW_FIELD_PATCH_FLAG_TRUE",
          setDuplicateTemplateFilesLoadedFalse:"SET_DUPLICATE_TEMPLATE_FILES_LOADED_FALSE",
          setDuplicateTemplateFilesLoadedTrue:"SET_DUPLICATE_TEMPLATE_FILES_LOADED_TRUE",
          setNewFilesUploadedFlagTrue: "SET_NEW_FILES_UPLOADED_FLAG_TRUE",
    }),
    handleSidebar(isSidebarOpen) {
      this.isSidebarOpen = isSidebarOpen;
    },
    showSiteSurveyForm() {
      this.showSiteSurveyFormComponent = true;
    },
    countFilesPresentInFormData(formData){
      let count = 0;
      for(let section of formData.sections){
        for(let field of section.fields){
          if(field.files.length > 0){
            count += field.files.length;
          }
        }
      }
      return count;
    },
    async showCreateAndEditForm(copiedFormData, templateType, callback) {
      this.currentTemplateType = templateType
      this.setPreviewModeFalse();
      this.templateId = 0;
      if(!copiedFormData){
        this.isNewTemplate = true;
        this.resetFormData();
      }
      else{
        this.isDuplicateTemplate = true;
        this.updateFormData(copiedFormData);
        //set duplicateTemplateFilesLoaded flag to false if there are files in the copiedFormData
        this.countFiles = this.countFilesPresentInFormData(copiedFormData);
        if(this.countFiles > 0){
          this.filesStatus = [];
          this.setDuplicateTemplateFilesLoadedFalse();
        }
        //run through the formdata, for each field in every section, reupload files
        for(let section of this.formData.sections){
          for(let field of section.fields){
            if(field.files.length > 0)
              this.processFiles(field.files, true, this.formData);
          }
        }
        // Once processing is done, call the callback function
        if (typeof callback === 'function') {
          callback();
        }
      }
      this.showSiteSurveyFormComponent = false;
    },
    addNewSection(previousSectionIndex, callback){
      //create new and empty section object
      let newEmptySection = {
        title: '',
        description: '',
        fields: [
          {
              label:'',
              description:'',
              question_type: '',
              is_required: false,
              photos_videos_required: false,
              options: [],
              files:[],
              additional_info: {
                  max_files: '1' ,
                  allowed_types: [],  
                  sequence:0      
              }, 
          },
        ]
      };
      if(this.isEditTemplateMode){
        newEmptySection.fields[0].sequence = newEmptySection.fields[0].additional_info.sequence;
        delete newEmptySection.fields[0].additional_info.sequence; 
        
        //check if the section is blank, and if so assign defaults
        if(!newEmptySection.title){
          newEmptySection.title = "Untitled Section";
          newEmptySection.description = "Description";

          //check if the field is blank, and if so assign defaults
          if(!newEmptySection.fields[0].label){
            newEmptySection.fields[0].label = "Untitled Field";
            newEmptySection.fields[0].description = "Description";
            newEmptySection.fields[0].question_type = "short_answer";
          }
        }
      }
      this.addSection(newEmptySection,previousSectionIndex).then(() => {
        callback();
      });
    },
    deleteSection(sectionIndex){
        if(this.isEditTemplateMode){
          if(this.formData.is_shared){
            this.openCreateNewTemplateDialog = true;
          }
          else{
            this.debouncedDeleteSectionData(this.formData.sections[sectionIndex].id);
            this.setAllowFieldPatchFlagTrue();
          }
        }

        let sections = [...this.formData.sections.slice(0,sectionIndex), ...this.formData.sections.slice(sectionIndex+1)];
        this.formData.sections = [...sections];
    },
    duplicateSection([sectionToCopy, sectionIndex], callback) {
      let duplicateSection = JSON.parse(JSON.stringify(sectionToCopy));
      delete duplicateSection?.created_at;
      delete duplicateSection?.created_by;
      delete duplicateSection?.id;
      this.addSection(duplicateSection, sectionIndex).then(() => {
          callback();
      });
    },
    async addSection(sectionObj, indexToInsertAfter){ 
      if(this.isEditTemplateMode){
          if(this.formData.is_shared){
            this.openCreateNewTemplateDialog = true;
          }
          else{
            this.isLoadingForm = true;
            for(let field of sectionObj.fields){
              await this.processFiles(field.files);
            }
            sectionObj.template = this.templateId;
            sectionObj.sequence = indexToInsertAfter + 1;
            delete sectionObj?.created_at;
            delete sectionObj?.created_by;
            delete sectionObj?.id;
            let createdSection = await this.createSectionData(sectionObj);
            sectionObj = JSON.parse(JSON.stringify(createdSection));
            for(let field of sectionObj.fields){
              field = this.addRequiredKeysToFieldData(field);
            }
            this.isLoadingForm = false;
          }
      }
      if(!this.isEditTemplateMode){
        this.isLoadingForm = true;
        for(let field of sectionObj.fields){
          await this.processFiles(field.files);
        }
      }
      if(this.formData.sections.length -1 === indexToInsertAfter){
          this.formData.sections.push(sectionObj);
      }
      else{
          //middle insertion
          let sections = [...this.formData.sections.slice(0,indexToInsertAfter + 1), sectionObj, ...this.formData.sections.slice(indexToInsertAfter+1)];
          this.formData.sections = [...sections];
      }
      this.isLoadingForm = false;
    },
    async addNewFieldToSection(sectionIndex, callback){
        let newField = {
            label:'',
            description:'',
            question_type: '',
            is_required: false,
            photos_videos_required: false,
            options: [],
            files:[],
            additional_info: { 
              allowed_types_toggle: false,
              max_files: '1',
              allowed_types: [],
              sequence: this.formData.sections[sectionIndex].fields.length,
            },   
        };
        if(this.isEditTemplateMode){
          if(this.formData.is_shared){
            this.openCreateNewTemplateDialog = true;
          }
          else{
            newField.template_section = this.formData.sections[sectionIndex].id;
            //switch out the sequence key position
            newField.sequence = newField.additional_info.sequence;
            
            //check if the field is blank, and if so assign defaults
            if(!newField.label){
              newField.label = "Untitled Field";
              newField.description = "Description";
              newField.question_type = "short_answer";
            }

            newField = await this.createFieldData(newField);
            delete newField?.template_section;
            delete newField?.section;
            newField = this.addRequiredKeysToFieldData(newField);
          }
        }
        this.formData.sections[sectionIndex].fields.push(newField); 
        this.updateFormData(this.formData);
        callback();
    },
    deleteCurrentField([fieldIndex, currentSectionIndex]){
      if(this.isEditTemplateMode){
        if(this.formData.is_shared){
          this.openCreateNewTemplateDialog = true;
        }
        else{
          this.debouncedDeleteFieldData(this.formData.sections[currentSectionIndex].fields[fieldIndex].id);
        }
      }

      let section = this.formData.sections[currentSectionIndex];
      let fields = [...section.fields.slice(0,fieldIndex), ...section.fields.slice(fieldIndex+1)];
      section.fields = [...fields];
      let sections = [...this.formData.sections];
      sections[currentSectionIndex] = {...section};
      this.formData.sections = [...sections];
    },
    async duplicateCurrentField([fieldIndex, currentSectionIndex], callback){
        let section = this.formData.sections[currentSectionIndex];
        let duplicateField = JSON.parse(JSON.stringify(section.fields[fieldIndex]));
        delete duplicateField?.id;

        if(this.isEditTemplateMode){
          if(this.formData.is_shared){
            this.openCreateNewTemplateDialog = true;
          }
          else{
            this.isLoadingForm = true;
            duplicateField.template_section = this.formData.sections[currentSectionIndex].id;
            duplicateField.sequence = duplicateField.sequence + 1;
            delete duplicateField?.created_at;
            delete duplicateField?.created_by;
            await this.processFiles(duplicateField.files);
            duplicateField = await this.createFieldData(duplicateField);
            delete duplicateField?.template_section;
            delete duplicateField?.section;
            duplicateField = this.addRequiredKeysToFieldData(duplicateField);
          }
        }else{
          //is create template mode
          this.isLoadingForm = true;
          duplicateField.additional_info.sequence = duplicateField.additional_info.sequence + 1;
          //carry out the upload files functionality again
          await this.processFiles(duplicateField.files);
        }
        let fields = [...section.fields.slice(0,fieldIndex+1), {...duplicateField}, ...section.fields.slice(fieldIndex+1)];
        section.fields = [...fields];
        let sections = [...this.formData.sections];
        sections[currentSectionIndex] = {...section};
        this.formData.sections = [...sections];
        this.updateFormData(this.formData);
        this.isLoadingForm = false;
        callback();
    },
    async processFiles(fieldFiles, isDuplicateForm = false, copiedFormData) {
      const promises = fieldFiles.map(async (file) => {
        const uuidFile = v4();
        file.file_name = uuidFile + file.file_type.split('/')[1];
        let fileObj;
        // Store original URL values
        const originalFileURL = file.file_url;
        const originalURL = file.url;
        // Determine the source of the file object
        if (originalFileURL) {
            fileObj = await this.getFileFromFileURL(originalFileURL);
        } else if (originalURL) {
            fileObj = await this.getFileFromURL(originalURL);
        }
        if (!isDuplicateForm) {
          delete file?.file_url;
          delete file?.url;
        }
        delete file?.created_at;
        delete file?.id;
        try {
            const response = await Promise.all([
                this.uploadFileToBlob(fileObj, uuidFile),
            ]);
            if(isDuplicateForm && copiedFormData){ 
              this.filesStatus.push({file_url : response[0]});
            }else{
              file.url = response[0];
            }
        } catch (error) {
            this.$message({
                showClose: true,
                message: 'Error uploading file. Please try again.',
                type: 'error',
            });
        } 
      });

    // Wait for all promises to resolve
    if(!isDuplicateForm)
      await Promise.all(promises);
},

    async uploadFileToBlob(file,refId){
      const accountName = "downloadstsl"; // same
      const fileName = refId + file.type.split('/')[1];

      let containerName = CONTAINER_NAME;
      let containerData = {
        container: containerName,
      }
      const response_Azure_sas = await API.AZURE_SAS_TOKEN.FETCH_AND_SET_SAS_TOKEN(containerData);
      const sasToken = response_Azure_sas.data['sas_token'];
      const headers = {
          'x-ms-blob-type': 'BlockBlob',
          'Content-Type': file.type,
      };


      const url = `https://${accountName}.blob.core.windows.net/${containerName}/${fileName}?${sasToken}`;
      const response = await fetch(url,
          {
              method: 'PUT',
              headers: headers,
              body: file,
          });
      let downloadUrl = response.url.split('?');
      return downloadUrl[0];
    },
    getFileFromFileURL(url) {
      return fetch(url)
        .then(response => response.blob())
        .then(blob => {
          const fileName = url.substring(url.lastIndexOf('/') + 1);
          return new File([blob], fileName, { type: blob.type });
        });
    },
    getFileFromURL(url) {
      return fetch(url)
        .then(response => {
          const contentType = response.headers.get('content-type');
          const fileExtension = contentType.split('/')[1]; // Extract the file extension from the Content-Type
          return { data: response.blob(), extension: fileExtension };
        })
        .then(({ data, extension }) =>
          data.then(blob => {
            let fileName = url.substring(url.lastIndexOf('/') + 1);
            if (!fileName.includes('.')) {
              fileName += `.${extension}`; // Append the derived file extension
            }
            return new File([blob], fileName, { type: blob.type });
          })
        );
    },
    async duplicateCurrentFile([fileIndex, fieldIndex, currentSectionIndex]){
      let section = this.formData.sections[currentSectionIndex];
      let field = JSON.parse(JSON.stringify(section.fields[fieldIndex]));
      let originalFile = field.files[fileIndex];
      let filesArray = [{...originalFile}];
      if(this.isEditTemplateMode){
        if(this.formData.is_shared){
          this.openCreateNewTemplateDialog = true;
        }
        else{
          this.isLoading = true;
          await this.processFiles(filesArray);
          originalFile = filesArray[0];
          let fieldBody = {
            id: this.formData.sections[currentSectionIndex].fields[fieldIndex].id,
            template_section : this.formData.sections[currentSectionIndex].id,
            files: filesArray,
          };
          this.debouncedPatchFieldData(fieldBody);
        }
      }
      else{
        this.isLoading = true;
        await this.processFiles(filesArray);
        originalFile = filesArray[0];
      }

      let modifiedFiles = [...field.files.slice(0, fileIndex), {...originalFile}, ...field.files.slice(fileIndex)];
      section.fields[fieldIndex].files = [...modifiedFiles];
      let sections = [...this.formData.sections];
      sections[currentSectionIndex] = {...section};
      this.formData.sections = [...sections];
      this.isLoading = false;
    },
    deleteCurrentFile([fileIndex, fieldIndex, currentSectionIndex]){
      if(this.isEditTemplateMode){
        if(this.formData.is_shared){
          this.openCreateNewTemplateDialog = true;
        }
        else{
          let fieldBody = {
            id: this.formData.sections[currentSectionIndex].fields[fieldIndex].id,
            deleted_files: [this.formData.sections[currentSectionIndex].fields[fieldIndex].files[fileIndex].file_name],
          };
          this.debouncedPatchFieldData(fieldBody);
        }
      }
      else{
        let fileObj = {
          "file":this.formData.sections[currentSectionIndex].fields[fieldIndex].files[fileIndex].file_name,
        };
        this.debouncedDeleteFileFromBlob(fileObj);
      }
      let section = this.formData.sections[currentSectionIndex];
      let field = JSON.parse(JSON.stringify(section.fields[fieldIndex]));
      let modifiedFiles = [...field.files.slice(0, fileIndex),...field.files.slice(fileIndex+1)];
      section.fields[fieldIndex].files = [...modifiedFiles];
      let sections = [...this.formData.sections];
      sections[currentSectionIndex] = {...section};
      this.formData.sections = [...sections];
    },
    existingTemplateHasNewSectionOrField(){
      for(let section of this.formData.sections){
        if(!section.hasOwnProperty("id")){
          return true;
        }
        for(let field of section.fields){
          if(!field.hasOwnProperty("id")){
            return true;
          }
        } 
      }
      return false;
    },
    async createTemplate(isFromEdit){
      if (this.currentTemplateType) this.formData.template_type = this.currentTemplateType
      if(isFromEdit){
        let ifNewAddition = this.existingTemplateHasNewSectionOrField();
        if(ifNewAddition){
          this.updateFormData(this.originalFormData);
        }
        this.createCopyOfAlreadyCreatedTemplate(this.formData);
        //allow the creation with duplicate title since it will be deleted
        this.formData.allow_duplicate_title = true;
      }
      this.removeUnwantedKeysFromFormData();
      try{
        const createTemplateResponse = await API.EDITABLE_SITE_SURVEY_API.CREATE_TEMPLATE(this.formData);
        this.currentTemplateType = null;
        this.setPreviewModeTrue();
        this.updateFormData(this.formData);
        if(isFromEdit){
          //created template after trying to edit an existing template
          //delete old template

          try{
            const response = await API.EDITABLE_SITE_SURVEY.DELETE_TEMPLATE(this.templateId);
            //after deleting old template, set templateID to new template
            this.templateId = createTemplateResponse.data.template.id;
            this.previewFormFunc(this.templateId);
            this.$message({
              showClose: true,
              message: "New template created successfully.",
              type: "success",
              center: true
            });
            }
          catch(error){
            this.$message({
              showClose: true,
              message: "There was an error. Please try again.",
              type: "error",
              center: true
            });
          }
        }
        else{
          this.templateId = createTemplateResponse.data.template.id;
          this.previewFormFunc(this.templateId);
        }
      }
      catch(e){
        let errMessage = ''
        let sections = e.response.data?.sections || []
        if (e.response?.data?.title == 'This field may not be blank.' || e.response?.data?.description == 'This field may not be blank.') {
          errMessage = 'Please fill out the required fields before saving the form.'
        } else if (e.response.data?.title == 'Ensure this field has no more than 255 characters.') {
          errMessage = 'Title field cannot contain more than 255 characters.'
        } else if (e.response.data?.description == 'Ensure this field has no more than 1000 characters.') {
          errMessage = 'Description field cannot contain more than 1000 characters.'
        } else if (this.checkSectionTitleLength(sections || [])) {
          errMessage = 'Section title cannot contain more than 500 characters.'
        } else if (this.checkSectionDescriptionLength(sections || [])) {
          errMessage = 'Section Description cannot contain more than 1000 characters.'
        }
        if (e.response.status >= 400 && e.response.status < 500){
          this.$message({
              showClose: true,
              message: e.response.data.message || errMessage || "Please fill out the required fields before saving the form.",
              type: "error",
              center: true
          });
        } 
        else{
          this.$message({
              showClose: true,
              message: "Could not create template. Try again.",
              type: "error",
              center: true
          });
        }
        this.addRequiredKeysToFormData();
        this.setPreviewModeFalse();
      }
    },
    checkSectionTitleLength(sectionArr){
      let isValid = sectionArr.filter(e => e.title == 'Ensure this field has no more than 500 characters.')
      return isValid.length
    },
    checkSectionDescriptionLength(sectionArr){
      let isValid = sectionArr.filter(e => e.description == 'Ensure this field has no more than 1000 characters.')
      return isValid.length
    },
    async deleteSectionInTemplate(sectionId){
      try{
        const deleteTemplateResponse = await API.EDITABLE_SITE_SURVEY_API.DELETE_SECTION(sectionId);
        this.updateFormData(this.formData);
        this.$message({
          showClose: true,
          message: "Updated Successfully",
          type: "success",
          center: true,
        });
      }
      catch(e){
        this.$message({
            showClose: true,
            message: "Could not delete section. Try again.",
            type: "error",
            center: true
        });
        // revert formData back to original
        this.updateFormData(this.originalFormData);
      }
    },
    async deleteFieldData(fieldId){
      try{
        const deleteTemplateResponse = await API.EDITABLE_SITE_SURVEY_API.DELETE_FIELD(fieldId);
        this.updateFormData(this.formData);
        this.$message({
          showClose: true,
          message: "Updated Successfully",
          type: "success",
          center: true,
        });
      }
      catch(e){
        this.$message({
            showClose: true,
            message: "Could not delete field. Try again.",
            type: "error",
            center: true
        });
        // revert formData back to original
        this.updateFormData(this.originalFormData);
      }
    },
    async deleteFileFromBlob(fileObj){
      try{
        const deleteFileResponse = await API.EDITABLE_SITE_SURVEY_API.DELETE_FILE_FROM_BLOB(fileObj);
      }
      catch(e){
        console.error(e);
        this.$message({
          message: 'Could not delete file. Try again.',
          type: 'error',
        });
        // revert formData back to original
        this.updateFormData(this.originalFormData);
      }
    },
    async createFieldData(fieldBody){
      try{
        fieldBody = this.removeUnwantedKeysFromFieldData(fieldBody);
        const createFieldResponse = await API.EDITABLE_SITE_SURVEY_API.CREATE_FIELD(fieldBody);
        this.$message({
          showClose: true,
          message: "Updated Successfully",
          type: "success",
          center: true,
        });
        return createFieldResponse.data.field;
      }
      catch(e){
        this.$message({
            showClose: true,
            message: "Could not create field. Try again.",
            type: "error",
            center: true
        });
        // revert formData back to original
        this.updateFormData(this.originalFormData);
      }
    },
    async createSectionData(sectionBody){
      try{
        for(let field of sectionBody.fields){
          delete field?.created_at;
          delete field?.created_by;
          delete field?.id;
          if(field.additional_info.hasOwnProperty("sequence")){
            field.sequence = field.additional_info.sequence;
            delete field.additional_info.sequence;
          }
          field = this.removeUnwantedKeysFromFieldData(field);
        }
        const createSectionResponse = await API.EDITABLE_SITE_SURVEY_API.CREATE_SECTION(sectionBody);
        this.$message({
          showClose: true,
          message: "Updated Successfully",
          type: "success",
          center: true,
        });
        return createSectionResponse.data;
      }
      catch(e){
        this.$message({
            showClose: true,
            message: "Could not create section. Try again.",
            type: "error",
            center: true
        });
        // revert formData back to original
        this.updateFormData(this.originalFormData);
      }
    },
    patchTemplate(patchBody){
      if(this.formData.is_shared){
          //show popup
          this.openCreateNewTemplateDialog = true;
      }
      else{
          //allow edit
          //check and determine if template patch or section patch
          try {
            if(patchBody.isTemplateLevel)
              this.debouncedPatchTemplateData(patchBody);
            else if(patchBody.isSectionLevel)
              this.debouncedPatchSectionData(patchBody);
            else if(patchBody.isFieldLevel)
              this.debouncedPatchFieldData(patchBody);
          } catch (err) {
            console.error(err)
          }
      }
    },
    cancelCreateNewTemplateFromEdit(){
      this.updateFormData(this.originalFormData);
      this.openCreateNewTemplateDialog = false;
      this.setPreviewModeTrue();
    },
    createNewTemplateFromEdit(){
      //this.createCopyOfAlreadyCreatedTemplate(this.formData);
      const isFromEdit = true;
      this.createTemplate(isFromEdit);
    },
    async patchTemplateData(templateBody){
      try{
        delete templateBody.isTemplateLevel;
        const patchTemplateResponse = await API.EDITABLE_SITE_SURVEY_API.PATCH_TEMPLATE(this.templateId,templateBody);
        this.updateFormData(this.formData);
        this.$message({
          showClose: true,
          message: "Updated Successfully",
          type: "success",
          center: true,
        });
      }
      catch(e){
        this.$message({
            showClose: true,
            message: "Could not save template. Try again.",
            type: "error",
            center: true
        });
        this.updateFormData(this.originalFormData);
      
      }
    },
    async patchSectionData(sectionBody){
      try{
          let sectionId = sectionBody.id;
          delete sectionBody.id;
          delete sectionBody.isSectionLevel;
          const patchTemplateResponse = await API.EDITABLE_SITE_SURVEY_API.PATCH_SECTION(sectionId,sectionBody);
          this.updateFormData(this.formData);
          this.$message({
            showClose: true,
            message: "Updated Successfully",
            type: "success",
            center: true,
          });
      }
      catch(e){
          this.$message({
            showClose: true,
            message: "Could not save template. Try again.",
            type: "error",
            center: true
          });
          // revert formData back to original
          this.updateFormData(this.originalFormData);
           
      }
    },
    async patchFieldData(fieldPatchBody){
      try{
          let fieldBody = JSON.parse(JSON.stringify(fieldPatchBody));
          let fieldId = fieldBody.id;
          delete fieldBody.id;
          delete fieldBody.isFieldLevel;
          const patchTemplateResponse = await API.EDITABLE_SITE_SURVEY_API.PATCH_FIELD(fieldId,fieldBody);
          if(fieldBody.hasOwnProperty("files")){
           fieldPatchBody.files = JSON.parse(JSON.stringify(patchTemplateResponse.data.files));
           this.formData.sections.filter(section => section.id === fieldPatchBody.template_section).map(section => {
             section.fields.filter(field => field.id === fieldPatchBody.id).map(field => {
               field.files = fieldPatchBody.files;
             });
           });
          }
          if(fieldBody.hasOwnProperty("sequence")){
            this.setAllowFieldPatchFlagTrue();
          }
          this.updateFormData(this.formData);
          this.$message({
            showClose: true,
            message: "Updated Successfully",
            type: "success",
            center: true,
          });
      }
      catch(e){
          this.$message({
            showClose: true,
            message: "Could not save template. Try again.",
            type: "error",
            center: true
          });
          // revert formData back to original
          this.updateFormData(this.originalFormData);
      }
    },
    async previewFormFunc(templateId) {
      this.showSiteSurveyFormComponent = false;
      this.setPreviewModeTrue();
      //get the template data for this templateId
      this.templateId = templateId;
      this.isLoadingForm= true;
      const response = await API.EDITABLE_SITE_SURVEY_API.FETCH_QUESTIONS(templateId);
      this.updateOriginalFormData(response.data);
      this.updateFormData(response.data);
      this.isLoadingForm = false;
    },
    createCopyOfAlreadyCreatedTemplate(template){
      //creates a object ready for create template API
      //by removing the keys on an already created template
      delete template?.created_at;
      delete template?.created_by;
      delete template?.is_default;
      template.sections.map(section => {
          delete section?.created_at;
          delete section?.created_by;
          delete section?.sequence;
          delete section?.id;
          section.fields.map(field =>{
              delete field?.created_at;
              delete field?.created_by;
              delete field?.sequence;
              delete field?.id;
          });
      });
      return template;
    },
  },
  watch:{
    filesStatus:{
      handler: function(){
        //check if all the file objects have file_url 
        let allFilesUploaded = false;
        if(this.filesStatus.length === this.countFiles){
          allFilesUploaded = this.filesStatus.every(file => file.file_url);
        }
        if(allFilesUploaded){
          //set the doneloadingflag to true
          this.setDuplicateTemplateFilesLoadedTrue();
        }
      },
      deep: true
    },
    newFilesStatusArray:{
      handler: function(){
        if(this.newFilesStatusArray.length === this.countNewFilesToUpload){
          let allFilesUploaded = this.newFilesStatusArray.every(file => file.file_url);
          if(allFilesUploaded){
            //set the doneloadingflag to true
            this.setNewFilesUploadedFlagTrue();
          }
        }
      }
    }
  }
};
</script>
    
<style scoped></style>