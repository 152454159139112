
<div class="timePicker">
    <div class="fullTimePicker forLabel">
        <!-- <div v-if="!isTimePicker" @click="isTimePicker=true" class="addTimerText">
            <div>
                <i class="fas fa-clock" style="padding-right: 10px;"></i>
                <span>Add a time</span>
            </div>
        </div> -->
        <!-- <div class="timePicker" v-else>
            <div class="innerTimePicker">
                <input type="text" class="timeInput1"/>
                <span class="px1">:</span>
                <input type="text" class="timeInput2"/>
            </div>
            <div class="gapper">
                <div class="flex align-center pl1 amPm">
                    <span class="text-purple-hover mr1 cursor-pointer amTime" :class="isAM ? 'selctedPeriod' : ''" @click="isAM=true">AM</span>
                    <span class="text-purple-hover mr1 text-purple text-heavy pmTime" :class="!isAM ? 'selctedPeriod' : ''" @click="isAM=false">PM</span>
                </div>
                <span class="cross" @click="isTimePicker=false">&#x2716;</span>
            </div>
        </div> -->


        <!-- Revamped Time picker------------------------------------------------------------ -->

        <label for="" class="labelClass">Time Picker</label>
        <el-time-picker
          v-model="timePicked"
          @change="onChange"
          placeholder="Arbitrary time">
        </el-time-picker>


    </div>
</div>
