
<div>
  <el-card class="box-card cardCss desktop">
    <el-tabs v-model="activeTab">
          <el-tab-pane :label="tab" :name="tab" v-for="tab in tabs" :key="tab">
            <tabContainer :typeOfTab="tab" id="1"></tabContainer>
          </el-tab-pane>
    </el-tabs>
  </el-card>
  </div>
