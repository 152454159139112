import axios from 'axios';

export default {
    RESET_CONSUMPTION_DETAIlS(consumptionId, query, patchData) {
        return axios.post(`api/project-consumption-details/${consumptionId}/reset/?reset_data=${query}`, patchData)
    },
    PATCH_CONSUMPTION_DETAILS(consumptionId, patchData) {
        return axios.patch(`/api/project-consumption-details/${consumptionId}/`, patchData);
    },

    GET_CONSUMPTION_DETAILS_GENABILITY(consumptionId) {
        return axios.get(`/api/project-consumption-details/${consumptionId}/genability/`);
    },

    GET_CONSUMPTION_DETAILS_GENABILITY_ACCOUNT_SETUP(consumptionId) {
        return axios.get(`api/project-consumption-details/${consumptionId}/genability/account_setup/`);
    },

    UPDATE_CONSUMPTION_BILL_ON_CHANGING_UTILITY_RATE(consumptionId){
        return axios.get(`api/project-consumption-details/${consumptionId}/genability/analysis/`);
    },

    UTILITY_REQUEST_ACCESS(consumptionId,postData){
        return axios.post(`api/project-consumption-details/${consumptionId}/send_mail/`,postData);
    },

    FETCH_PROJECT_CONSUMPTION_DETAILS(consumptionId){
        return axios.get(`/api/project-consumption-details/${consumptionId}/`);
    },
    FETCH_UTILITY_METERS(consumptionId){
        return axios.get(`/api/project-consumption-details/${consumptionId}/utility_access/`);
    },

    FETCH_PROJECT_CONSUMPTION_CALCULATION(id){
        return axios.get(`/api/projects/${id}/project_consumption_calculation/`);
    },

    PATCH_PROJECT_CONSUMPTION_CALCULATION(id,postData){
        return axios.patch(`/api/projects/${id}/project_consumption_calculation/`,postData);
    },

    FETCH_UTILITY_PROVIDER(id, selectedTariffSource){
        if(id && selectedTariffSource)
        return axios.get(`/api/projects/${id}/utility_provider/?source=${selectedTariffSource}`);
    },
    SEARCH_UTILITY_PROVIDER(id, selectedTariffSource,query){
        if(id && selectedTariffSource)
        return axios.get(`/api/projects/${id}/utility_provider/?source=${selectedTariffSource}&query=${query}`);
    },
    FETCH_UTILITY_RATE_BY_LSEID(projectId, lseId, source,preOrPostSolar){
        if(lseId){   //we dont want to call it for empty lseid
            if(source=='genability'){
                if(preOrPostSolar=='postSolar'){
                    return axios.get(`/api/projects/${projectId}/tariffs/?lseId=${lseId}&source=${source}&post_solar=true`);
                }
                else{
                    return axios.get(`/api/projects/${projectId}/tariffs/?lseId=${lseId}&source=${source}`);
                }
            }
            else if(source='default'){
                return axios.get(`/api/projects/${projectId}/tariffs/?provider=${lseId}&source=${source}`);
            }
        }
    },
    FETCH_MORE_UTILITY_RATE_BY_LSEID(url){
        return axios.get(url);
    },
    LOAD_MORE_UTILITY_PROVIDERS(url){
        return axios.get(url);
    },
    FETCH_UTILITY_RATE_BY_SEARCH(projectId, lseId, query,source){
        if(source=='genability')
        return axios.get(`/api/projects/${projectId}/tariffs/?lseId=${lseId}&search=${query}`);  
        else if(source='default'){
            return axios.get(`/api/projects/${projectId}/tariffs/?provider=${lseId}&source=${source}&query=${query}`)
        }
    },
    UPDATE_TARRIF_DETAILS(id,patchData){
        return axios.patch(`/api/projects/${id}/tariffs/`,patchData);
    },
    FETCH_ALL_CONSUMPTION_PROFILE(projectId,orgId){
        return axios.get(`/api/master-data/consumptionProfile/?project_id=${projectId}&organisation=${orgId}`)
    },
    FETCH_ORG_CONSUMPTION_PROFILE(orgId) {
        return axios.get(`/api/master-data/consumptionProfile/?organisation=${orgId}`)
    },
    POST_ORG_CONSUMPTION_PROFILE(postdata) {
        return axios.post(`/api/master-data/consumptionProfile/`,postdata)
    },
    FETCH_ORG_CONSUMPTION_PROFILE(orgId) {
        return axios.get(`/api/master-data/consumptionProfile/?organisation=${orgId}`)
    },
    POST_ORG_CONSUMPTION_PROFILE(postdata) {
        return axios.post(`/api/master-data/consumptionProfile/`,postdata)
    },
    LOAD_MORE_PROFILES(url){
        return axios.get(url);
    },
    ADD_CONSUMPTION_PROFILE(postData){
        return axios.post(`/api/master-data/consumptionProfile/`,postData)
    },
    UPDATE_CONSUMPTION_PROFILE(patchData,profileId){
        return axios.patch(`/api/master-data/consumptionProfile/${profileId}/`,patchData)
    },
    DELETE_CONSUMPTION_PROFILE(profileId) {
        return axios.delete(`/api/master-data/consumptionProfile/${profileId}/`)
    },
    FETCH_SPECIFIC_PROFILE(profileId){
        return axios.get(`/api/master-data/consumptionProfile/${profileId}/`)
    },
    ADD_APPLIANCES_FOR_CONSUMPTION(postData){
        return axios.post(`/api/project-consumption-appliances/`,postData)
    },
    UPDATE_APPLIANCES_FOR_CONSUMPTION(postData, applianceId){
        return axios.patch(`/api/project-consumption-appliances/${applianceId}/`,postData)
    },
    DELETE_APPLIANCE(applianceId){
        return axios.delete(`/api/project-consumption-appliances/${applianceId}/`)
    },
    UPLOAD_GREEN_BUTTON_FILE(postData, projectId){
        return axios.patch(`api/projects/${projectId}/project_consumption_calculation/`,postData)
    },
    DELETE_FILE(projectId,postData, fileType) {
        return axios.post(`/api/project-consumption-details/${projectId}/reset/?reset_data=${fileType}`, postData)
    },
    UPLOAD_SPREADSHEET_DATA(postData, projectId) {
        return axios.patch(`api/projects/${projectId}/project_consumption_calculation/`,postData)
    },
};
