
<section class="right_section" v-loading.fullscreen.lock="isShareLoading || isLoadingFullscreen">
  <div :v-if="showlogoutconfirmbox">
    <vue-confirm-dialog class="ConfirmBox"></vue-confirm-dialog>
  </div>
  <div class="content_section">
    <div class="filter_section">
      <div class="title">
        <div
          v-if="active"
          class="title projectTitle"
          style="margin-left: -1.5%"
        >
          {{projectText}}s
        </div>
      </div>
      <div class="filter_area" @click="toggleSidebarStatus">
        <div class="search_field" >
          <span
            class="icon search"
            :class="!isSearchEnabled ? 'moveRight': ''"
            @click="isSearchEnabled = !isSearchEnabled"
            v-if="search.length == 0"
          ></span>
          <span class="crossIcon" v-if="search.length > 0" @click="resetInput">
            <img src="https://cdn.zeplin.io/5fe06da7bcd15d47b191f058/assets/3b7d2dcf-3845-4086-baf4-d160366c22c9.svg" />
          </span>
          <input
            v-model="search"
            suffix-icon="el-icon-search"
            class="input_field"
            :class="{ isSearchEnabled: isSearchEnabled }"
            type="search"
            :placeholder="`Search ${projectText}s by Name or Created by`"
          />
        </div>
        <div class="view_type">
          <a @click="toggleViewType" class="tab_list" :class="{ active }">
            <span class="icon list-icon"></span>
            <span class="list_text">List</span>
          </a>
          <a
            @click="toggleViewType"
            class="tab_list"
            :class="{ active: !active }"
          >
            <span class="icon location"></span>
            <span class="list_text">Map</span>
          </a>
        </div>
        <div class="project_btn">
          <a class="btn btn-primary" @click="isNewProjectFormVisible = true"
            >Create New {{projectText}}</a
          >
        </div>
      </div>
    </div>
    <div class="tab_content">
      <div
        v-if="active"
        class="table_section"
        :style="{
          height: `${
            window.width > 767 ? window.height - 250 : window.height - 150
          }px`,
        }"
      >
        <!-- remove hidden attr for show content -->
        <div class="table_scroll">
          <table>
            <thead style="top: 0px; position: sticky">
              <tr>
                <th>{{projectText}}s ({{ total_project }})</th>
                <th>Designs</th>
                <th>Created On</th>
                <th>Client Name</th>
                <th>Created By</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody v-if="allProjectsList.length == 0">
              <tr class="empty-list" v-if="isLoading" v-loading="isLoading">
                <td colspan="6">
                  <div class="create_list">
                    <!-- <figure>
                      <a @click="isNewProjectFormVisible = true">
                        <img src="../assets/img/add-list.svg" alt="Create" />
                      </a>
                    </figure>
                    <div class="info_content">
                      <p>
                        You have not created any project. To create your first
                        project click “Create New Project” button above
                      </p>
                    </div> -->
                  </div>
                </td>
              </tr>

              <tr class="empty-list" v-if="!isLoading">
                <td colspan="6">
                  <div class="create_list">
                    <figure>
                      <a @click="isNewProjectFormVisible = true">
                        <img src="../assets/img/add-list.svg" alt="Create" />
                      </a>
                    </figure>
                    <div class="info_content">
                      <p v-if="isSearchedProjectEmpty">
                        Your searched {{projectText}}s are not Found. To create your
                        {{projectText}} click “Create New {{projectText}}" button above
                      </p>
                      <p v-else>
                        You have not created any {{projectText}}. To create your first
                        {{projectText}} click “Create New {{projectText}}” button above
                      </p>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>

            <tbody v-if="allProjectsList.length != 0" v-loading="isLoading">
              <tr v-for="project in allProjectsList" :key="project.id">
                <td class="address_td">
                  <router-link
                    :to="'/projectSummary/' + project.id"
                    @click.native="isLoadingFullscreen = true"
                  >
                    <div class="project_info">
                      <figure>
                        <img :src="project.staticImageUrl" alt />
                      </figure>
                      <div class="project_content">
                        <div style="display:flex; gap:1.5rem; alignItems: center;">
                          <h5 v-html="$globalMethods.htmlDecode(project.projectName)"></h5>
                          <el-tooltip placement="top">
                              <template v-slot:content>
                                <div>Project ID</div>
                              </template>
                              <el-tag class="tags">
                                {{ project.id }}
                              </el-tag>
                          </el-tooltip>
                        </div>
                        <em>{{ project.projectType }}</em>
                        <p>{{ project.clientAddress }}</p>
                      </div>
                    </div>
                  </router-link>
                </td>
                <!-- <td> {{project.projectName}} </td> -->
                <td class="title_td grow_td title_td_count">
                  <router-link :to="'/projectSummary/' + project.id">
                    <div class="md_head" style="color: var(--primary)">
                      Design Count
                    </div>
                    <div class="value_type">
                      {{ project.number_of_designs }}
                    </div>
                  </router-link>
                </td>
                <td class="title_td">
                  <router-link :to="'/projectSummary/' + project.id">
                    <div class="md_head" style="color: var(--primary)">
                      Created On
                    </div>
                    <span class="date">{{ project.date }}</span>
                  </router-link>
                </td>
                <td class="title_td">
                  <router-link :to="'/projectSummary/' + project.id">
                    <div class="md_head" style="color: var(--primary)">
                      Client
                    </div>
                    <div class="value_type client_name">
                      <span v-html="$globalMethods.htmlDecode(project.clientName)"></span> 
                    </div>
                  </router-link>
                </td>

                <td class="title_td">
                  <router-link :to="'/projectSummary/' + project.id">
                    <div class="md_head" style="color: var(--primary)">
                      Created By
                    </div>
                    <div class="value_type creator_name">
                      {{
                        project.createdBy.first_name +
                        " " +
                        project.createdBy.last_name
                      }}
                    </div>
                  </router-link>
                </td>

                <td
                  class="md_dot"
                  :class="{ active: project.isActionListActive }"
                  @click="toggleActionList(project)"
                >
                  <div class="dot">
                    <span class="fas fa-ellipsis-v"></span>
                  </div>
                  <div class="action_btn">
                    <ul class="action_list">
                      <li
                        v-if="isSharingAllowed"
                        data-toggle="modal"
                        data-target="#share_project"
                        @click="share_project(project)"
                        :disabled="project.permission === 'view_project'"
                      >
                        <el-tooltip
                          effect="dark"
                          placement="top-start"
                          :content="`Share ${projectText}`"
                        >
                          <button class="btn">
                            <span class="fas fa-share-alt"></span>
                          </button>
                        </el-tooltip>
                        <div class="act_text">Share</div>
                      </li>
                      <li
                        data-toggle="modal"
                        data-target="#delete_project"
                        @click="deleteProject(project)"
                        :disabled="project.permission === 'view_project'"
                      >
                        <el-tooltip
                          effect="dark"
                          placement="top-start"
                          :content="`Delete ${projectText}`"
                        >
                          <button class="btn">
                            <span class="fas fa-trash-alt"></span>
                          </button>
                        </el-tooltip>
                        <div class="act_text">Delete</div>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div
            v-infinite-scroll="loadMoreProjects"
            infinite-scroll-disabled="busy"
            infinite-scroll-distance="10"
            style="text-align: center"
          >
            <i v-if="busy" class="el-icon-loading infiniteScrollLoader" />
          </div>
        </div>
      </div>
      <div v-else class="tab_content">
        <div class="map_area" v-loading="isLoading">
          <!-- <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14019.673228798889!2d77.3344533!3d28.5421751!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xbdac94bd5b5e046c!2sThe%20Solar%20Labs!5e0!3m2!1sen!2sin!4v1618738317069!5m2!1sen!2sin"
            width="100%"
            style="border:0;"
            allowfullscreen
            loading="lazy"
          ></iframe>-->
          <HomeMap
            ref="homeMapImport"
            @deleteProject="deleteProject"
            @shareProject="share_project"
            :all-projects="allProjectsMapInfo"
          />
        </div>
      </div>
    </div>
  </div>
  <shareProject
    v-if="shareDialogBoxVisible"
    :shareDialogBoxVisible.sync="shareDialogBoxVisible"
    :project_id="project_id"
  />
  <newProjectDialog
    v-if="isNewProjectFormVisible"
    :is-new-project-form-visible.sync="isNewProjectFormVisible"
  />
  <DeleteProject
     v-if="isDeleteProjectPopupOpen"
    :isDeleteProjectPopupOpen="isDeleteProjectPopupOpen"
    :projectIdTobeDeleted="projectIdTobeDeleted"
    @confirmDelete="actualDelete()"
    @cancelDelete="isDeleteProjectPopupOpen = false"
  />
</section>
