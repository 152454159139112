import axios from 'axios';

export default {

    FETCH_SITE_SURVEY_LINK(selectedOption, projectId) {
		let postData = {
			"project": projectId,
			"template":selectedOption.id,
		}
		return axios.post(`/api/site-survey/generate_link/`,postData);
    },
	FETCH_SITE_SURVEY_FOR_ORDER(postData){
		return axios.post(`/api/site-survey/generate_link/`,postData);
	},

    SHARE_SITE_SURVEY_LINK(postData) {
        return axios.post(`api/site-survey/send_link/`, postData);
    },

	PATCH_SITE_SURVEY_LINK(payload, token) {
		axios.patch(`/api/site-survey/${token}/`,payload)
	},
	LOAD_MORE_TEMPLATES(url) {
		return axios.get(url);
	} 

}