<template>
  <div class="datePicker forLabel">
    <!-- <input type="date" id="birthday" name="birthday" v-model="date">
          <date-pick v-model="date" :hasInputElement="false"></date-pick> -->
    <label for="" class="labelClass"
      >{{ itemValue.title }}
      <span v-if="itemValue.isStar" class="astrisk"> *</span>
    </label>
    <el-date-picker
      v-model="pickedDate"
      type="date"
      @change="onChange"
      :picker-options="pickerOptions"
      placeholder="Select Date"
      :format="dateFormat"
    >
    </el-date-picker>
  </div>
</template>

<script>
import "vue-date-pick/dist/vueDatePick.css";
import {DateTime} from 'luxon';
import {isUSFlagEnabled} from '../../../../utils'

export default {
  emits: ["item-selected" ],
  name: "setReminder",
  props: {
    itemValue: {
      type: Object,
      default() {
        return {}
      },
    },
    selectedValue: {
      type: String,
      default: () => "",
    },
    indexOfOptions: {
      type: Number,
      default: null,
    },
    disabledPrev: {
      type: String,
      default: "",
    },
  },
  components: {
  },
  data() {
    return {
      pickedDate: this.$props.selectedValue,
      //pickerOptions: {},
      disableDate:''
    };
  },
  mounted() {
    this.formattedPickedDate()
  },
  computed: {
    pickerOptions() {
      if (this.$props.disabledPrev&&this.$props.itemValue.type==='to') {
        const disabledDate = DateTime.fromISO(this.$props.disabledPrev, { zone: 'utc' });

        return {
          disabledDate: (date) => date < disabledDate,
        };
      } else {
        return {};
      }
    },

    systemLocalTime() {
  if (this.pickedDate) {
    // Convert the selected date to Luxon DateTime object in the 'Asia/Kolkata' time zone
    const inputDate = DateTime.fromJSDate(this.pickedDate, { zone: 'Asia/Kolkata' });

    // Convert to UTC
    const utcDate = inputDate.toUTC();

    // Add one day to account for the time zone difference
    // const adjustedDate = utcDate.plus({ days: 1 });

    // Format the result in ISO string format
    return utcDate.toISO();
  }

  return null;
},

    dateFormat() {
      const user = JSON.parse(localStorage.getItem("user")) || {};
      return isUSFlagEnabled ?'MM-dd-yyyy' : 'dd-MM-yyyy' ;
    },
  },

  methods: {
    formattedPickedDate() {
      // Convert the ISO date to Luxon DateTime and format it in a way the date picker expects
      if (this.pickedDate) {
        
        const localTimeoutpust =  DateTime.fromISO(this.pickedDate, { zone: "local" });
      
        const dateObj = DateTime.fromISO(this.pickedDate, { zone: 'utc' });
        this.pickedDate= localTimeoutpust.toFormat('MM-dd-yyyy');
      }else{
        this.pickedDate=''
      }
     
    },
    formattedDisableDate(date) {
      // Convert the ISO date to Luxon DateTime and format it in a way the date picker expects
      
      if (date) {
      
        const dateObj = DateTime.fromISO(date, { zone: 'utc' });
        return dateObj.toFormat('yyyy-MM-dd');
      }
     
    },
    onChange() {
      const localTime = this.systemLocalTime;
   
// this.formattedPickedDate()
      this.$emit(
        "item-selected",
        this.$props.itemValue.prop,
        localTime,
        localTime,
        this.$props.itemValue,
        this.$props.indexOfOptions,
        false
      );
    },

  },
  
  watch:{
    selectedValue:{
      immediate:true,
      handler(val){
        if(!val){
      this.pickedDate=''
    }
      }

    },

itemValue:{
  immediate:true,
  handler(val){
    if(!val.selectedValue){
      this.pickedDate=''
    }
  }
},
pickedDate:{
  immediate:true,
  handler(val){
    // this.formattedPickedDate()
  }
}
}
};
</script>

<style lang="scss" scoped>
.setRemminder .el-drawer.rtl {
  min-width: 355px !important;
}

.bottomCard {
  position: absolute;
  bottom: 0px;
  width: -webkit-fill-available;
}

.px1 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.amPm {
  display: flex;
  padding-left: 0.5rem;
  align-items: center;
}

.addTimerText {
  cursor: pointer;
  align-items: center;
  padding: 0.5rem;
  display: flex;
}

.fullTimePicker {
  padding: 0.5rem;
}

.amTime,
.pmTime {
  cursor: pointer;
  margin-right: 0.5rem;
}

.wholeBody {
  margin-left: 1rem;
  margin-right: 1rem;
  min-width: 300px;
}

.timeInput1,
.timeInput2 {
  width: auto !important;
  color: #4c5773;
  font-size: 1.12em;
  padding: 0.75rem 0.75rem;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  transition: border 0.3s linear;
  height: 36px;
}

.outerBtn {
  padding: 20px 0px 20px 20px;
  margin-bottom: 20px;
}

.submitBtn {
  position: absolute;
  /* left: 75%; */
  right: 20px;
}

#birthday {
  font-size: 16px;
  display: block;
  width: 100%;
  box-sizing: border-box;
  padding: 15px;
  padding-right: 40px;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 4px;
  background: transparent;
  border: 1px solid #e0e0e0;
  box-shadow: 0 0.1em 0.3em rgba(0, 0, 0, 0.05);
  outline: 0;
  margin-bottom: 10px;
  font-weight: 900;
}

.datePicker {
  padding: 0.5rem;
}

.forLabel {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.labelClass {
  font-size: 12px;
  color: #777;
  font-weight: 600;
}

.timePicker {
  align-items: center;
  padding: 0.5rem;
  display: flex;
}

.gapper {
  display: flex;
  width: -webkit-fill-available;
  justify-content: space-between;
}

.cross {
  cursor: pointer;
}

.selctedPeriod {
  color: #7172ad;
  font-weight: 900;
}

.innerTimePicker {
  width: 125px;
  display: grid;
  grid-template-columns: 40% 20% 40%;
}
</style>

<style scoped>
.datePicker >>> .el-input {
  width: 100%;
}

.datePicker >>> .el-input__inner {
  height: 40px !important;
  background-color: #e8edf2;
  width: 100% !important;
  max-width: none !important;
  border-radius: 4px;
  margin-right: 0px;
}
.astrisk {
  font-size: 12px;
  font-weight: 600;
  color: #f00;
}
</style>
