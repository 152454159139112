import axios from 'axios';

export default {

    GET_PATIOS() {
        return axios.get('/api/master-data/power-patios/');
    },

    GET_GAZEBOS() {
        return axios.get("/api/master-data/power-gazebos/");
    },

    GET_ARKA_SOLUTION_MODELS() {
        return axios.get("/api/master-data/product-skus/");
    },

    PATCH_DESIGN_POWER_MODELS(data) {
        return axios.patch(`/api/designs/${data.design}/power_models/`, data);
    },

    FETCH_DESIGN_POWER_MODELS(designId) {
        return axios.get(`/api/designs/${designId}/power_models/`, {design: designId});
    },

    GET_PATIO_EDGE_HEATMAP(designId,data, source) {
        return axios.post(`/api/designs/${designId}/get_patio_heat_map/`, data, {
            cancelToken: source.token
        } )

    },

}