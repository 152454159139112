
	<div id="pdfEditorContainer" :class="[{customPlaceholderMode:customPlaceholderMode}]">
    <div id="parent-container">
			<DetailsComponent v-if="showDetailsCont" 
				:styleValues="detailsComponentStyle"
				:activeSpanFontSize = "activeSpanFontSize"
				@fontSize="increaseFont"
                @deletePH="deletePlaceholder"
			/>
		<div id="pdfContainer">
		</div>
  </div>
	<el-checkbox v-if="!customPlaceholderMode"
	 	class="addPSClass"
		size="medium"
		v-model="includePaymentScheduleData"
	>
		Add project schedule page in contract
	</el-checkbox>
	</div>
