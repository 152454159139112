import {
    CREATED_STATE,
    CUSTOM_INVERTER_PGPP,
    DEFAULT_PATIO_INVERTER_TYPE,
    MINIMUM_POINTS_FOR_PROPERTY,
    POWER_PATIO
} from "../../coreConstants";
import * as THREE from 'three';
import Gazebo from "../../lib/PowerGazebo";
import Row from "./Row";
import * as utils from '../../utils/utils';
import { DELETED_STATE, SALES_MODE } from '../../coreConstants';
import OutlinePoints from "../subObjects/OutlinePoints";
import {
    defaultPatioModuleId,
    gazeboInverterIQ7PLUSId,
    gazeboInverterIQ8PLUSId
} from "../../../constants";
import { COLOR_MAPPINGS, MATERIAL_STATES, VISUAL_STATES } from "../visualConstants";
import createBufferGeometry, { createMesh } from "../../utils/meshUtils";
import { useStudioStore } from "../../../stores/studio";

export default class Patio extends Gazebo {
    constructor(stage) {
        super(stage);
        this.objectType = POWER_PATIO;
        this.tilt = 8;
        this.attachment = null;
        this.moduleSpacingUp = 0.01;
        this.moduleSpacingWide = 0.01;
        this.mountHeight = 2.616;
        this.moveAttached = false;
        this.inverterTypeId = useStudioStore().GET_IS_CUSTOM_PGPP_ALLOWED ? null : gazeboInverterIQ8PLUSId;
        this.inverterType = useStudioStore().GET_IS_CUSTOM_PGPP_ALLOWED ? CUSTOM_INVERTER_PGPP : DEFAULT_PATIO_INVERTER_TYPE;
        const defaultValues = this.stage.getDesignSettings().drawing_defaults.polygonModel;
        this.setbackOutside = 0.6096; // 2feet setback for patio
        this.mergedSetbackForPatio = 0.4570; // 3feet setback for patio (1.5 + 1.5)
        // setback material
        this.setbackMaterial2D = new THREE.MeshBasicMaterial({
            color: COLOR_MAPPINGS
                .POLYGON[MATERIAL_STATES.SOLID][VISUAL_STATES.DEFAULT_STATES.DEFAULT]
                .SETBACK_COLOR,
            transparent: true,
            opacity: 0.6,
            side: THREE.DoubleSide,
        });
        this.setbackOutsideMesh = createMesh(createBufferGeometry(), this.setbackMaterial2D);
        this.objectsGroup.add(this.setbackOutsideMesh);
        this.setbackVertices = null;
        this.mergedSetbackVertices = null;
        this.setbackSideEdges = null;
        this.isPowerTable = true;
        this.modelsForPatio2feetIntersection = [];
    }

    getPowerProperties(tileLayout = '4x8') {
        const defaultValues = (this.inverterType===CUSTOM_INVERTER_PGPP) ? this.stage.getAllPowerModel().patios.without_Inverter : this.stage.getAllPowerModel().patios.with_Inverter;
        return defaultValues.find(obj => obj.tile_layout === tileLayout);
    }

    createRotation() {
        return null;
    }

    /**
     * saving the patio object
     * @returns patio data
     */
    saveObject() {
        const patioData = super.saveObject();
        patioData.type = Patio.getObjectType();
        patioData.moveAttached = this.moveAttached;

        return patioData;
    }

    /**
     * 
     * @param {*patio properties} patioData 
     * @param {*parent model} parentModel 
     * @param {*Boolean} isPaste 
     */
    loadObject(patioData, parentModel, isPaste = false){
        super.loadObject(patioData, parentModel, isPaste = false);
        this.moveAttached = patioData.moveAttached;
        if (this.inverterTypeId === gazeboInverterIQ7PLUSId) this.inverterTypeId = gazeboInverterIQ8PLUSId;
        if (this.panelProperties.id !== defaultPatioModuleId) {
            this.panelProperties = this.powerProperties.panel;
        }
        this.updatePowerTableGeometry();
        this.getTables()[0]?.hidePatioSetback();
    }
    /**
     * create new Patio model
     * @param {*patio properties} properties 
     * @returns new patio model
     */
    createNewModel(properties) {
        const newPatio = new Patio(this.stage);
        if (this.attachment) this.attachment.addAttachment(newPatio);
        if (!this.getParent()) this.parent = this.stage.ground;
        this.getParent().addChild(newPatio); // in plcae of this handle attached object
        newPatio.associatedModel = this.getParent();
        newPatio.addTableFlow = true;
        if (properties !== null) {
            newPatio.changePropertiesDuringCreation(properties);
            newPatio.createBoundaryFromParent();
        }

        const rowMap = {
            id: 0,
            frames: [],
        };
        const row = new Row(this.stage, rowMap, { withoutContainer: false }, true, this.isPowerTable);
        newPatio.addChild(row);
        row.saveState({ withoutContainer: false });
        return newPatio;
    }

    removeObject({ shouldSaveState } = {shouldSaveState: true}) {
        let i = 0;
        while (this.getChildren().length > i) {
            this.getChildren()[i].removeObject({shouldSaveState: shouldSaveState, deleteEmptyParent: false});
        }

        if (shouldSaveState) {
            this.stage.stateManager.add({
                uuid: this.uuid,
                getStateCb: () => DELETED_STATE,
            });
        }

        this.stage.sceneManager.scene.remove(this.objectsGroup);

        if(this.getParent() !== null) {
            this.getParent().removeChild(this);
        }

        if (this.attachment) {
            this.attachment.removeAttachment(this);
        }

        this.modelsForPatio2feetIntersection = [];

        if (!this.stage.dragControls.dragEnabled) {
            this.stage.dragControls.enable()
        }

        // Remove outline points
        for (let i = this.outlinePoints.length - 1; i >= 0; i--) {
            this.outlinePoints[i].removeObject();
            this.outlinePoints.splice(i,1);
        }

        // remove from quadtree
        this.stage.quadTreeManager.removeObject(this);

        // jugaad: to select ground if subarray is removed
        // if (this.stage.mode == 'app') this.stage.selectionControls.setSelectedObject(this.stage.ground)
        if (this.stage.mode === SALES_MODE && this.objectType !== Gazebo.getObjectType() && !this.stage.preventGroundSelectionSalesMode) {
            this.stage.selectionControls.setSelectedObject(this.stage.ground);
        }
    }

    onSelect() {
        //console.log('Patio selected');
    }

    disposeSetbackGeometry() {
        this.setbackOutsideMesh.geometry.dispose();
    }

    async updateObject(properties, dontRemoveIntersect = false) {
        await super.updateObject(properties, dontRemoveIntersect);
        // to exist addpatiomode in salesmode.
        if (this.stage.mode == 'salesMode' && this.stage.addAttachmentMode.enabled){
            this.newGazebo.stage.selectionControls.setSelectedObject(this.newGazebo.stage.ground);
        }
        return this.newGazebo;
    }

    changePropertiesDuringCreation(properties) {
        super.changePropertiesDuringCreation(properties);
        if (this.stage.addAttachmentMode.enabled) {
            this.stage.addAttachmentMode.disposeGeometry();
            this.stage.addAttachmentMode.highLightEdges();
        }
    }

    saveInitPanelRotation() {
        super.saveInitPanelRotation();
        this.beingRotated = true;
        this.oldPosition = new THREE.Vector2(this.getTables()[0].getPosition().x, this.getTables()[0].getPosition().y);
        this.hideMergedMeshes();
        if (this.attachedPatioEdge) {
            this.offsetPoint1 = null;
            this.offsetPoint2 = null;
            this.patioEdgePoint1 = this.attachedPatioEdge[0];
            this.patioEdgePoint2 = this.attachedPatioEdge[1];
        }

        this.attachedPatioEdgeRotated = [Object.assign({}, this.attachedPatioEdge[0]), Object.assign({}, this.attachedPatioEdge[1])];
        this.getTables()[0].hidePatioSetback();
    }

    getState() {
        let stateData = {
            type: Patio.getObjectType(),
            uuid: this.uuid,
            attachedTo: this.attachment ? this.attachment.attachedTo.uuid : null,
            id: this.id,
            name: this.name,
            addTableFlow: this.addTableFlow,
            powerProperties: this.powerProperties,
            moduleProperties: {
                moduleId: this.moduleProperties.moduleId,
                moduleMake: this.moduleProperties.moduleMake,
                moduleSize: this.moduleProperties.moduleSize,
                moduleLength: this.moduleProperties.moduleLength,
                moduleWidth: this.moduleProperties.moduleWidth,
            },
            panelProperties: this.panelProperties,
            powerProperties: this.powerProperties,
            mountType: this.mountType,
            rowSpacing: this.rowSpacing,
            rowSpacingMode: this.rowSpacingMode,
            tilt: this.tilt,
            structureType: this.structureType,
            inverterType: this.inverterType,
            inverterTypeId: this.inverterTypeId,
            azimuth: this.azimuth,
            panelOrientation: this.panelOrientation,
            mountHeight: this.mountHeight,
            tableSizeUp: this.tableSizeUp,
            moveAttached: this.moveAttached,
            tableSizeWide: this.tableSizeWide,
            tableSpacing: this.tableSpacing,
            moduleSpacingUp: this.moduleSpacingUp,
            moduleSpacingWide: this.moduleSpacingWide,
            hidden: this.hidden,
            outlinePoints: this.outlinePoints.map(outlinePoint => [
                outlinePoint.getPosition().x,
                outlinePoint.getPosition().y,
                outlinePoint.getPosition().z,
            ]),
            parent: this.getParent() ? this.getParent().uuid : null,
            boundingBox: this.getBoundingBox(),
            inverterLerpPosition: this.inverterLerpPosition,
            attachedPatioEdge: this.attachedPatioEdge,
            mergedSetbackVertices: this.mergedSetbackVertices,
            setbackVertices: this.setbackVertices,
        };
        if(this.hasNewId) stateData.hasNewId = this.hasNewId;

        return stateData;
    }

    loadState(state, fromState) {
        if (state === CREATED_STATE || state === DELETED_STATE) {
            this.clearState();
        }
        else {
            // load id and name
            this.id = state.id;
            this.name = state.name;
            this.addTableFlow = state.addTableFlow;
            this.powerProperties = state.powerProperties;
            // load subarray properties
            this.moduleProperties = {
                moduleId: state.moduleProperties.moduleId,
                moduleMake: state.moduleProperties.moduleMake,
                moduleSize: state.moduleProperties.moduleSize,
                moduleLength: state.moduleProperties.moduleLength,
                moduleWidth: state.moduleProperties.moduleWidth,
            };
            this.inverterType = state.inverterType;
            this.inverterTypeId = state.inverterTypeId;
            this.panelProperties = state.panelProperties;
            this.powerProperties = state.powerProperties;
            this.mountType = state.mountType;
            this.rowSpacing = state.rowSpacing;
            this.structureType = state.structureType; // TBC what is load state
            this.rowSpacingMode = state.rowSpacingMode;
            this.tilt = state.tilt;
            this.azimuth = state.azimuth;
            this.panelOrientation = state.panelOrientation;
            this.mountHeight = state.mountHeight;
            this.tableSizeUp = state.tableSizeUp;
            this.tableSizeWide = state.tableSizeWide;
            this.tableSpacing = state.tableSpacing;
            this.moduleSpacingUp = state.moduleSpacingUp;
            this.moduleSpacingWide = state.moduleSpacingWide;
            this.boundingBox = state.boundingBox;
            this.inverterLerpPosition = state.inverterLerpPosition;
            this.hidden = state.hidden;
            this.attachedPatioEdge = state.attachedPatioEdge;
            this.setbackVertices = state.setbackVertices;
            this.mergedSetbackVertices = state.mergedSetbackVertices;
            if(state.hasOwnProperty('hasNewId')) this.hasNewId = state.hasNewId;
            
            this.updateVisualsAfterLoadingAndCreation();

            // update parent
            const parentObject = this.stage.getObject(state.parent);
            if (parentObject) {
                this.changeParent(parentObject);
            }
            else {
                this.changeParent(this.stage.ground);
            }



            let attachedToObject = null;
            if (state.attachedTo) attachedToObject = this.stage.getObject(state.attachedTo)
            if (attachedToObject) {
                attachedToObject.attachedObjects.addAttachment(this)
            }
            this.associatedModel = this.getParent() ? this.getParent() : this.stage.ground;
            if (fromState === CREATED_STATE || fromState === DELETED_STATE) {
                // add objectsGroup to scene
                this.stage.sceneManager.scene.add(this.objectsGroup);

                if (state.outlinePoints.length === 0 && state.outlinePoints.length < 4) {
                    this.outlinePoints = state.boundingBox.map(outlinePoint => new OutlinePoints(
                        outlinePoint.x,
                        outlinePoint.y,
                        outlinePoint.z,
                        this,
                        this.stage,
                    ));
                }
                else {
                    // create outline pints
                    this.outlinePoints = state.outlinePoints.map(outlinePoint => new OutlinePoints(
                        outlinePoint[0],
                        outlinePoint[1],
                        outlinePoint[2],
                        this,
                        this.stage,
                    ));
                }
            }
            else {
                // update outline points
                // if (this.outlinePoints.length !== state.outlinePoints.length) {
                //     console.error('GazeboModel: loadState: outlinePoints length don\'t match');
                //     // return null;
                // }
                const vertices = state.outlinePoints;
                for (let i = 0, len = vertices.length; i < len; i += 1) {
                    this.outlinePoints.push(new OutlinePoints(
                        vertices[i][0],
                        vertices[i][1],
                        0,
                        this,
                        this.stage,
                    ));
                }
            }
            if (this.rotationPoints) this.rotationPoints.hideObject();
            // update geometry of subarray
            this.updateGeometry();
            this.hideIndividualPanelMeshes()
            this.showMergedMeshes();
        }
    }

    rotateObjectHelper(angleInRad, centroidPoint) {
        this.rotationStarted = true;
        this.notSelfRotate = true;
        this.newPosition = utils.rotationAroundPoint(
            centroidPoint.x,
            centroidPoint.y,
            this.oldPosition.x,
            this.oldPosition.y,
            angleInRad,
        );
        // this.getChildren()[0].getChildren()[0].moveObject(this.newPosition[0] - position.x, this.newPosition[1] - position.y, position.z);
        this.oldPosition.x += this.newPosition[0] - this.oldPosition.x;
        this.oldPosition.y += this.newPosition[1] - this.oldPosition.y;
        this.tablePositionAfterRotation = new THREE.Vector3(this.oldPosition.x, this.oldPosition.y, this.getTables()[0].getPosition().z);
        this.updatePropertiesWhileRotation(angleInRad, centroidPoint);
        this.rotateAttachedEdge(angleInRad, centroidPoint)

    }

    rotateAttachedEdge(angleInRad, centroidPoint) {
        // rotate the attachedPatioEdge with the patio.
        if (this.attachedPatioEdgeRotated) {
            this.patioEdgePoint1 = this.attachedPatioEdgeRotated[0];
            this.patioEdgePoint2 = this.attachedPatioEdgeRotated[1];

            // get the rotation offset while rotation is applied
            this.offsetPoint1 = utils.rotationAroundPoint(
                centroidPoint.x,
                centroidPoint.y,
                this.patioEdgePoint1.x,
                this.patioEdgePoint1.y,
                angleInRad,
            );
            this.offsetPoint2 = utils.rotationAroundPoint(
                centroidPoint.x,
                centroidPoint.y,
                this.patioEdgePoint2.x,
                this.patioEdgePoint2.y,
                angleInRad,
            );

            // adding the offset while rotation to translate coordinates of the patio edge 
            this.attachedPatioEdgeRotated[0].x += (this.offsetPoint1[0] - this.patioEdgePoint1.x);
            this.attachedPatioEdgeRotated[0].y += (this.offsetPoint1[1] - this.patioEdgePoint1.y);
            this.attachedPatioEdgeRotated[1].x += (this.offsetPoint2[0] - this.patioEdgePoint2.x);
            this.attachedPatioEdgeRotated[1].y += (this.offsetPoint2[1] - this.patioEdgePoint2.y);
        }
    }

    updatePatioAttachedEdge(updateProperties) {
        updateProperties.attachedPatioEdge = this.attachedPatioEdgeRotated;
    }

    updatePropertiesWhileRotation(angleInRad, centroidPoint) {
        if (this.getTables()[0].gazeboDirectionArrow !== null && this.getTables()[0].gazeboDirectionArrow !== undefined) {
            this.getTables()[0].gazeboDirectionArrow.visible = false;
        }
        this.getTables()[0].getChildren().forEach((panel) => {
            panel.gazeboRotation(angleInRad);
        });
        for (let i = 0; i < this.panelCopyGroup.children.length; i++) {
            this.rotateMeshAroundItsCenter(this.panelCopyGroup.children[i], angleInRad, centroidPoint);
        }
    }

    onPatioRotationEnd() {
        this.beingRotated = false;
        super.onGazeboRotationEnd();
    }

    getDimensions() {
        return this.getTableDimensions(true);
    }

    showMesh() {
        this.showIndividualPanelMeshes();
    }

    getPosition() {
        return this.getTables()[0].getPosition();
    }

    moveObject(deltaX, deltaY, deltaZ = 0) {
        super.moveObject(deltaX, deltaY, deltaZ)
    }

    placeObject(deltaX, deltaY,  checkPlacingInfo = true) {
     // checking whether the model is being placed outside the ground.   
        if (checkPlacingInfo) {
            const placingInformation = this.getTables()[0].getPlacingInformationWhilePlacing();
            const errorsWhilePlacing = placingInformation.errors;
            if (errorsWhilePlacing.length !== 0) {
                this.removeObject(
                    undefined,
                    undefined, { objectSelected: true },
                );
                this.stage.eventManager.customErrorMessage(errorsWhilePlacing[0].message, 'Table');
                return errorsWhilePlacing[0];
            }
        }
        this.initPropertyIntersectParams();
        this.initGroundModelIntersectParams();
        this.updatePowerTableGeometry();
        this.getTables()[0]?.placeObjectForPatio(deltaX, deltaY, checkPlacingInfo);
        this.stage.addAttachmentMode.getCornersForPatioMovement(this);
    }

    hideObject() {
        if (!this.hidden) this.hideMergedMeshes();
        this.hidden = true;
    }

    showObject() {
        if (this.hidden) this.showMergedMeshes();
        this.hidden = false;
    }
    
    /**
     * saving the attachments in the scene.
     */
    saveStateAttachments() {
        this.saveState();
        if (this.getChildren()[0]) {
            this.getChildren()[0].saveState();
            this.getChildren()[0].getChildren()[0].saveState();
            // save states of the panels undo was not working
            this.getChildren()[0].getChildren()[0].getChildren().forEach((patio) => {
                patio.saveState();
            })
        }
    }

    /**
     * creating new patio after undoing(delete) patio
     * @param {properties of patio} properties 
     * @returns new patio
     */
    async updateObjectAndReturn(properties, dontRemoveIntersect = false) {
        const intersectingGazebosRemoved = []
        this.updateObject(properties, dontRemoveIntersect);
        if (this.newGazebo.getTables()?.length > 0) intersectingGazebosRemoved.push(this.newGazebo.getTables()[0].getIntersectedTableWithoutRemoving(true));
        return {
            currentAttachment: this.newGazebo ? this.newGazebo : null,
            intersectedAttachments: intersectingGazebosRemoved,
        };
    }

    updatePowerTableGeometry() {
        if (this.getTables().length > 0) this.getTables()[0].updateGeometry();
    }

    setAttachment(attachment) {
        this.attachment = attachment;
    }

    initPropertyIntersectParams() {
        this.allPropertyEdges = [];
        const { propertyLines } = utils.isPropertyLinePresent(this.stage);
        if (propertyLines.length > 0) {
            propertyLines.forEach((line) => {
                if (line.get2DVertices().length > MINIMUM_POINTS_FOR_PROPERTY) {
                    line.updateGeometry();
                    this.allPropertyEdges.push(...line.setbackEdgesForPatioInterection);
                }
            });
        }
    }

    initGroundModelIntersectParams() {
        this.modelsForPatio2feetIntersection = utils.getAllModelsFor2FeetPatioIntersection(this.stage, this);
    }

    static getObjectType() {
        return 'Patio';
    }
} 