
<div>
  <el-popover class="popover" placement="bottom" v-model="visible">
    <div class="container">
      <el-radio v-model="radio" label="1">Proposal Details missing</el-radio>
      <el-radio v-model="radio" label="2">other</el-radio>
      <el-input
        v-if="radio === '2'"
        placeholder="Please input"
        v-model="input"
      ></el-input>
      <div style="text-align: right; margin: 0; padding-top: 10px">
        <!-- <el-button size="mini" type="text" @click="visible = false"
          >cancel</el-button
        > -->
        <el-button
          type="primary"
          size="mini"
          @click="handleReject"
          :disabled="isRejectButtonDisabled()"
          >Reject</el-button
        >
        <RejectDialogConform
          :rejectState.sync="rejectState"
          :input="dispalyInput"
          :orderData="orderData"
          @reject-state="handleRejectStatus"
          @reject-confirm="handleRejectConfirm"
        />
      </div>
    </div>

    <template v-slot:reference>
    <el-button type="danger" class="btn btn-danger"
      >Reject</el-button
    >
    </template>
  </el-popover>
</div>
