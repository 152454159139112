<template>
  <el-dialog
    class="crm-notification-dialog-wrapper"
    :visible.sync="$props.isVisible"
    :modal-append-to-body="isModal"
    :show-close="false"
    @close="handleClose"
    width="30%"
    custom-class="crm-notification-dialog"
  >
    <div style="height: 100%">
      <div class="header-div">
        <div class="header-notification">Notifications</div>
        <router-link class="header-more" :to="{ name: 'notifications' }"
          ><span @click="handleClose">See all</span></router-link
        >
        <div class="header-right">
          <span style="margin-right: 0.5rem">Unread Only</span>
          <el-switch
            :width="32"
            v-model="unreadOnly"
            @change="handleUnreadToggle"
          ></el-switch>
        </div>
      </div>
      <div class="tabs-div">
        <div
          @click="handleTabs('direct')"
          :class="[activeTab === 'direct' ? 'tabs-active' : 'tabs-inactive']"
        >
          Direct
        </div>
        <div
          @click="handleTabs('collab')"
          :class="[activeTab === 'collab' ? 'tabs-active' : 'tabs-inactive']"
        >
          Collaborators
        </div>
        <div class="tabs-end" @click="handleReadAll">Mark all as read</div>
      </div>
      <div
        class="notification-cards"
        v-if="notificationFetchError"
        style="color: red"
      >
        There was an error while fetching notifications.
      </div>
      <div
        v-else
        class="notification-cards"
        v-loading="!computeNotificationList"
        v-infinite-scroll="handleScroll"
        infinite-scroll-distance="10"
      >
        <NotificationCards
          :notificationsList="computeNotificationList"
          @handleClick="handleClick"
          @showDrawer="showDrawer"
          :maxWidth="'450px'"
          :assigneeWidth="'200px'"
          :isPopup="true"
          @notificationRead="handleNotificationRead"
        />
        <div class="empty-notification">
          {{ emptyNotificationsText }}
        </div>
        <div class="footer" v-if="computeShowFooter">
          <div style="position: relative">
            <span class="footer-img">
              <img
                src="../../assets/img/notifBellDeny.svg"
                alt="bell-deny"
                height="20.35px"
                width="19.76px"
              />
            </span>
            <span class="footer-left-text"
              >That's all your notifications from last week</span
            >
          </div>
          <router-link class="footer-right" :to="{ name: 'notifications' }"
            ><span @click="handleClose"
              >See older notifications</span
            ></router-link
          >
        </div>
        <div
          v-else
          v-show="isNotificationLoading || loaderErrorMessage.length > 0"
          :class="['loader-and-error']"
          v-loading="isNotificationLoading"
        >
          <span
            :class="[loaderErrorMessage.length > 0 ? 'loading-error-text' : '']"
          >
            {{ loaderErrorMessage }}
          </span>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import API from "../../services/api";
import NotificationCards from "./NotificationCards.vue";
export default {

  emits: [
    "handleUnreadToggle",
    "loadMoreNotifications",
    "openNotification",
    "handleReadAll",
    "closeNotification",
    "switchTabs",
    "showDrawer"
  ],

  name: "NotificationPopUp",
  components: {
    NotificationCards,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    notificationsList: {
      type: Array,
      default: () => [],
    },
    isModal: {
      type: Boolean,
      default: true,
    },
    isNextPage: {
      type: Object,
      default: () => {},
    },
    notificationFetchError: {
      type: Boolean,
      default: false,
    },
    disableScroll: {
      type: Boolean,
      default: true,
    },
    loaderErrorMessage: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      debounceChecker: false,
      isNotificationLoading: false,
      scrollDebounce: null,
      emptyNotificationsText: null,
      activeTab: "direct",
      unreadOnly: false,
      unreadNotifications: [],
      showFooter: true,
    };
  },
  computed: {
    computeShowFooter() {
      if (this.activeTab == "collab") {
        return this.$props.isNextPage.collab;
      } else {
        return this.$props.isNextPage.direct;
      }
    },
    handleChange() {
      if (this.unreadOnly) {
        return this.checkLength(this.unreadOnly);
      } else {
        return this.checkLength(this.unreadOnly);
      }
    },
    computeNotificationList() {
      let filteredArr = [...this.$props.notificationsList];
      return this.activeTab == "direct" ? filteredArr[0] : filteredArr[1];
    },
  },
  methods: {
    async handleNotificationRead(notif,queryParams) {
      await API.NOTIFICATIONS.MARK_INDIVIDUAL_READ(notif.id,queryParams);
    },
    handleUnreadToggle() {
      this.notificationsList = [];
      this.$emit("handleUnreadToggle", this.unreadOnly);
    },
    handleScroll() {
      if (this.disableScroll) return;
      this.isNotificationLoading = true;
      if (this.scrollDebounce) {
        clearTimeout(this.scrollDebounce);
      }

      this.scrollDebounce = setTimeout(() => {
        this.$emit("loadMoreNotifications", this.activeTab);
        this.scrollDebounce = null;
        this.isNotificationLoading = false;
      }, 500);
    },
    handleClick(value) {
      this.$emit("openNotification", value);
    },
    handleReadAll() {
      this.$emit("handleReadAll");
    },
    handleClose() {
      this.$emit("closeNotification");
    },
    handleTabs(tab) {
      this.activeTab = tab;
      this.$emit("switchTabs", this.activeTab);
    },
    showDrawer(bool) {
      this.$emit("showDrawer", bool);
    },
    checkLength(bool) {
      if (bool) {
        let filteredArr = this.$props.notificationsList.filter(
          (e) => e.isRead === false
        );
        if (filteredArr.length > 15) {
          return true;
        } else {
          return false;
        }
      } else {
        if (this.$props.notificationsList.length > 15) {
          return true;
        } else {
          return false;
        }
      }
    },
  },
  watch: {
    activeTab: {
      immediate: true,
      handler(val){
        if (val == "direct") {
          this.notificationsList[0]?.length == 0
            ? (this.emptyNotificationsText = "No notifications to show.")
            : (this.emptyNotificationsText = "");
        } else if (this.activeTab == "collab") {
          this.notificationsList[1]?.length == 0
            ? (this.emptyNotificationsText = "No notifications to show.")
            : (this.emptyNotificationsText = "");
        }
      }
    }
  },
};
</script>
<style scoped>
.header-div {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 15px;
  justify-content: flex-start;
  align-items: center;
}
.header-notification {
  color: #222222;
  font-size: 16px;
  font-weight: 600;
}
.header-more {
  font-size: 14px;
  font-weight: 500;
  color: #409eff;
  cursor: pointer;
}
.header-right {
  margin-left: auto;
}
.tabs-div {
  margin: 1rem 0 0.5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #cccccc;
  cursor: pointer;
}
.tabs-end {
  margin-left: auto;
  font-size: 14px;
  font-weight: 500;
  color: #555555;
}
.tabs-inactive {
  padding: 4px 8px;
  font-size: 16px;
  font-weight: 400;
  color: #777777;
  border-bottom: 2px solid transparent;
}
.tabs-active {
  padding: 4px 8px;
  font-size: 16px;
  font-weight: 600;
  color: #1c3366;
  border-bottom: 2px solid #1c3366;
}
.notification-cards {
  height: 70vh;
  overflow: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  /* -ms-overflow-style: none; */
  display: flex;
  flex-direction: column;
  padding-top: 2px;
}
/* .notification-cards::-webkit-scrollbar{
display: none;
width: 0 !important 
} */
.footer {
  width: 100%;
  bottom: 0;
  cursor: default;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  padding: 0 10px;
  margin-top: auto;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
}
.footer-right {
  margin-left: auto;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.4000000059604645px;
  text-align: center;
  color: #409eff;
  margin-top: 7px;
  cursor: pointer;
}
.footer-left-text {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: left;
  color: #555555;
  margin-left: 26px;
}
.footer-img {
  position: absolute;
  top: -2px;
}
.empty-notification {
  margin-left: 0.5rem;
}
.notification-cards >>> .main-card-div {
  margin-bottom: 0.6rem;
  padding-bottom: 0.4rem;
}
.loader-and-error {
  height: 50px;
  width: 100%;
  margin-top: 1rem;
  color: red;
}
.loading-error-text {
  position: relative;
  top: -35px;
  left: 25%;
}
</style>
