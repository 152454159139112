import { Loader } from '@googlemaps/js-api-loader'
import { GOOGLE_API_KEY_TILES } from "../../constants";
import { getDetailsFromGoogleMapsResult } from '../../utils'

const loader = new Loader({
    apiKey: GOOGLE_API_KEY_TILES,
    version: "weekly",
    libraries: ["places"]
});

let mapsLoaded = loader.load()

export default mapsLoaded

export async function reverseGeocodeCoordinates(latLng, isAddressChosenByInput) {
    let google = await mapsLoaded
    let geocoder = new google.maps.Geocoder();

    let resp = await geocoder.geocode({ latLng })
    let responses = resp.results

    if (!(responses && responses.length)) { return }

    let address = responses[0].formatted_address
    let isAddressManuallyEntered = false
    let { countryCode, state, postalCode, county, city } = getDetailsFromGoogleMapsResult(responses[0], responses);
    let newMapsState = {
        state,
        countryCode,
        postalCode,
        county,
        city,
        isAddressManuallyEntered,
    }
    if (!isAddressChosenByInput) {
        newMapsState.address = address
    } else {
        newMapsState.isAddressChosenByInput = false
    }  

    return newMapsState
}