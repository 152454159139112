<template>
    <div :style="{width: errorMessage=='Design'? '100%' :'', height:errorMessage=='Design'? '100%' :'',display: errorMessage=='Design'? 'flex' :''}" class="container" >
      <div v-if="orderData||errorMessage">
      <div style="display: flex;margin: auto; flex-direction: column; align-items: center;" v-if="errorMessage">
  
        <h3 v-if="errorMessage == 'Design'" style="margin-bottom: 10px;">The Design API has failed.</h3>
        <div v-else>
          <h3 class="error-heading">
            An unexpected error occurred. There might be more details below.
          </h3>
          <div class="error-text">
            {{ errorText }}
          </div>
        </div>
        <el-button v-if="errorMessage == 'Design'"  @click="sendErrorEmail">Send Error Report</el-button>
      </div>
      <div v-else >
      <div class="heading" v-if="!loader">
        <div class="title-section">
<p class="title-text">{{ orderData.id }}</p>
<div>

            <el-tag
            :type="orderData.color"
              style="margin-right: 0.5rem"
              class="tag"
            >
              {{ orderData.service_type }}
            </el-tag>

        </div>
        <div
            v-if="
              orderData?.add_ons_availed &&
              orderData.add_ons_availed?.length > 0
            "
          >
            <el-tag
              style="margin-right: 0.5rem"
              v-for="(addons, inx) in orderData.add_ons_availed"
              :key="inx"
              class="tag"
            >
              {{ addons }}
            </el-tag>
          </div>
        </div>
        <div v-if="!loader"  class="btn-container">
      
       
          <div></div>
          <el-tooltip
                  class="item"
                  effect="dark"
                  :content="'Select the owner to assign the order'"
                  placement="top"
                  :disabled="disableAccept(orderData)"
                >
          <el-button type="primary"    v-if="orderData.order_status === 'under review'"
          @click="handleAccept"
            :disabled="!disableAccept(orderData)||acceptLoader"
            :loading="acceptLoader"
              class=" btn-spacing"
          >
          Accept

          </el-button>
          </el-tooltip>
     
          <rejectDialog
            v-if="orderData?.order_status !== 'rejected' && orderData?.order_status !== 'cancelled'"
            :orderData="orderData"
            :orderDetailsPopVisible.sync="propsData.orderDetailsPopVisible"
            :rejectStatus.sync="rejectStatus"
          />
        </div>
        
  
      </div>
  
      <!-- <div  > -->
        <div v-loading="loader"  class="body">
           <div class="left-body"   >
            <div v-for="(label, ind) in labelDataOptions" :key="ind" style=" padding-bottom: 16px;">
                <p class="title-lable">{{ label.label }}</p>
                <el-select
            v-if="label.inputType == 'select'&&label.prop=='order_status'"
            placeholder="Select an Owner"
            v-model="orderData['orderStatus']"
            :disabled="label.prop==='order_status' && (orderData[label.prop]==='under review'||orderData[label.prop]==='incomplete'||orderData[label.prop]==='cancelled'||orderData[label.prop]==='rejected'||orderData[label.prop]==='pending')"
            @change="onAssigneeChange($event, label.prop, orderData)"
          >
            <el-option
              v-for="(item, ind) in label.options"
              :key="ind"
              :label="item.label"
              :value="item.value"
              class="owner-dropdown-option"
            >
              <el-badge
                is-dot
                v-if="item.shifts"
                :type="getType(item.shifts)"
                class="dot-badge"
              ></el-badge>
            
              <span v-if="item.competence" :style="{ paddingLeft: '1rem' }">
                <el-tag
                  v-for="(comp, indx) in item.competence"
                  :key="indx"
                  class="tag"
                  :color="handleColorLightning(handleColor(comp), 80)"
                  :style="{
                    color: `${handleColor(comp)}`,
                    marginLeft: '0.2rem',
                  }"
                  type="red"
                  size="mini"
                >
                  {{ comp }}
                </el-tag>
              </span>
            </el-option>
            <div
            v-infinite-scroll="loadMoreOwners(label.nextUrl)"
            infinite-scroll-disabled="ownersBusy"
            infinite-scroll-distance="10"
            class="loading-icon"
            >
              <i v-if="ownersBusy" class="el-icon-loading infiniteScrollLoader"/>
            </div>
          </el-select>
                <el-select
            v-if="label.inputType == 'select'&&label.prop!=='order_status'"
            placeholder="Select an Owner"
            v-model="orderData[label.prop]"
            :disabled="label.prop==='order_status' && (orderData[label.prop]==='under review'||orderData[label.prop]==='incomplete'||orderData[label.prop]==='cancelled')"
            @change="onAssigneeChange($event, label.prop, orderData)"
          >
            <el-option
              v-for="(item, ind) in label.options"
              :key="ind"
              :label="item.label"
              :value="item.value"
              class="owner-dropdown-option"
            >
              <el-badge
                is-dot
                v-if="item.shifts"
                :type="getType(item.shifts)"
                class="dot-badge"
              ></el-badge>
              {{ item.label }}
              <span v-if="item.competence" :style="{ paddingLeft: '1rem' }">
                <el-tag
                  v-for="(comp, indx) in item.competence"
                  :key="indx"
                  class="tag"
                  :color="handleColorLightning(handleColor(comp), 80)"
                  :style="{
                    color: `${handleColor(comp)}`,
                    marginLeft: '0.2rem',
                  }"
                  type="red"
                  size="mini"
                >
                  {{ comp }}
                </el-tag>
              </span>
            </el-option>
            <div
            v-infinite-scroll="loadMoreOwners(label.nextUrl)"
            infinite-scroll-disabled="ownersBusy"
            infinite-scroll-distance="10"
            class="loading-icon"
            >
              <i v-if="ownersBusy" class="el-icon-loading infiniteScrollLoader"/>
            </div>
          </el-select>
 
                <div v-else-if="label.prop==='name'" > 
                  <div style="display: flex;align-items: center;">
                  <div style="padding-right: 8px;display: flex;" ><img src="./assets/person.svg" alt=""></div>
                  <div class="project-name">{{ orderData[label.prop] }}</div> 
                </div>
                </div>
                  <div v-else-if="label.prop==='created_at'" class="created_on"> <span style="padding-right: 8px;" >Created On</span>
                    <span >{{ orderData[label.prop] }}</span> 
                   </div>
                   <p v-else-if="label.prop=='due_date'&&orderData[label.prop]=='NA'"  class="value-details-empty" >'Accept' to see expected delivery</p>
                   <p v-else-if="label.prop=='time_remaining'&&orderData[label.prop]=='NA'"  class="value-details-empty" >'Accept' to see time remaining</p>

                   <p  class="value-details" v-else-if="label.prop!=='order_status'" >{{ orderData[label.prop] }}</p>

                
            </div>

           </div>

           <div class="right-body">
            <div style="padding-bottom:8px">
            <div v-for="(icon,inx) in buttonsData" :key="inx" class="common-style">
              <el-button :disabled="isDisabledBtn(orderData,icon)" type="text" :class="[ isDisabledBtn(orderData,icon) ? 'disabled-link':icon.exist=='desgin'?'design-btn' : 'icon-container' ]" @click.native.stop="icon.callback()" >
  

                <!-- <router-link
              :to="icon.redirect"
              target="_blank"
              :class="[ isDisabledBtn(orderData,icon) ? 'disabled-link':icon.exist==='desgin'? 'edit-design': 'icon-container', ]"
              @click.native="handleClick($event,orderData,icon)"
            > -->
            <span >
                  <b-icon style="transform: scale(1.5)" :icon="icon.icon"></b-icon> 

              </span >
                <span class="icon-text">{{ icon.tooltipContent }}</span> 
            <!-- </router-link> -->
              </el-button>

        

            </div>
          </div>
            <div style="border-top:1px solid #ccc;padding-top:8px" v-if="designData && !loader">
              <ProposalButtons :design="designData" :isProposalGenration="false" :isSalesModeDisabled="orderData.order_status==='under review'||orderData.order_status==='incomplete'" />

            </div>
           </div>

        </div>
   
    <!-- </div> -->
      <!-- <el-row class="proposal-btns" v-if="designData && !loader">
        <el-col :span="24">
          <ProposalButtons :design="designData" />
        </el-col>
      </el-row> -->
      <div style="border-top:1px solid #ccc;padding:8px" v-if="designData && !loader">
        <ProposalButtons :design="designData" :isSalesModeBtns="false" />
      </div>
  
      <div v-if="!loader" class="tabs">
        <Tabs
          :active="'1'"
          :token="token"
          :DetailsData="projectDetails"
          :orderData="orderData"
          :projectData="projectData"
          :customer="customerDetails"
          :ownerDetails="ownerDetails"
          :moduleDetails="moduleDetails"
          :pricingDetails="pricingDetails"
          :additionalNotes="additionalNotes"
          :ahjDetails="ahjDetails"
          :modelDetails="modelDetails"
          :batteryDetails="batteryDetails"
        />
      </div>
      <ChatBox v-if="ISUs && !loader" :orderData="orderData"  :drawerStatus="propsData?.drawer"/>
    </div>
  </div>
  <div v-else style="height:50vh;display: flex;justify-content: center;">
    <div v-loading="true">
  
    </div>
  </div>
  <GlobalDialog 
    :dialogTitle="`Permit Package Order Delivery and Pricing`"
      :dialogVisible="openPermitPricing"
      @handleClose="closeDialoge"
     :zeroPadding="true"
      :width="'650px'"
      :isModal="false"
    >
    <template v-slot:body>
      <div class="permit-container">
        <div class="permit-info">

          <div class="permit-item" v-for="(info,index) in permitInfoData" :key="index">
            <p class="label-permit">{{ info?.title }}</p>
            <p class="value-permit">{{info.prop=="order_count"?permitData?.['order_count']||"_" : orderData?.[info.prop]||"_" }}</p> 
          </div>
        </div>

        <div class="permit-input-container" v-for="(inputInfo,index) in pricingItems" :key="index">
    <div class="box first">
      <p class="input-label" >{{ inputInfo?.name }} <span class="required">*</span></p>
         <InputField :inputData.sync="permitData[inputInfo.price_prop]"   :countryDetails.sync="countryDetails" placeholderValue="Please Input"/>
    </div>
    <div class="box second"> 
       <p class="input-label">Delivery Time <span class="required">*</span></p>
      <InputField :inputData.sync="permitData[inputInfo.days_prop]" :prefixIcon="'Days'"  :countryDetails.sync="countryDetails" placeholderValue="0"/>
    </div>
    <div class="box third">  
      <p style="visibility: hidden" class="input-label">label <span class="required">*</span></p>
      <InputField :inputData.sync="permitData[inputInfo.hours_prop]" :prefixIcon="'Hours'"  :countryDetails.sync="countryDetails" placeholderValue="0"/>

    </div>

  </div>
      </div>

      </template>
      <template v-slot:footer>
<div class="permit-btns" >
  <el-button @click="closeDialoge">Cancle</el-button>
  <el-tooltip
                  class="item"
                  effect="dark"
                  :content="'To enable all pricing values and either (days or hours) values should be added.'"
                  placement="top"
                  :disabled="!isPermitPricingDisabled"
                >
  <el-button :disabled="isPermitPricingDisabled" type="primary" @click="handleToNexStep">Update and Continue</el-button>
  </el-tooltip>

</div>
      </template>
      </GlobalDialog>
  </div>
  </template>
  
  <script>
  import { DateTime } from "luxon";
  import rejectDialog from "./rejectDialog.vue";
  import API from "@/services/api/";
  import Tabs from "./tabs/tabs.vue";
  import ChatBox from "../../project/components/chatBox/chatBox.vue";
  import { isShiftOngoing } from "../../../utils/userStatus";
  import {
    getLightenedColor,
    handleCompetenceColor,
    handleServiceTypeColor,
  } from "../../../utils/colorGenerator.js";
  import {
    SITE_SURVEY_LINK,
    BASE_URL_FOR_REPORT_IMAGES,
    DATABASE_URL_FOR_SITE_SURVEY
  } from "../../../constants";
  
  import { chatEvents, sendEvent } from "../../../utils";
  import ProposalButtons from "./proposalBtns.vue";
  import { mapState, mapActions } from 'pinia';
  import { useDesignOrdersStore } from "../../../stores/designOrdersStore";
  import GlobalDialog from '../../commonComponents/GlobalDialog.vue';
import InputField from "./inputField.vue";
// import DatePickerField from "./datePickerField.vue";
import {getCurrencySymbol} from "../../../utils/numberFormat/currencyFormatter.js"
  
  const  orderStatusOptions= [
          // {
          //   label: "Order Placed",
          //   value: "order_placed",
          // },
          // {
          //   label: "Incomplete",
          //   value: "incomplete",
          // },
          { label: "Active", value: "in_process" },
  
          // { label: "Payment Pending", value: "pending" },
          { label: "Complete", value: "complete" },
  
          // { label: "Reject", value: "rejected" },
          // { label: "Cancel", value: "cancelled" },
        ];
  
  
  export default {
    emits: ["dialogUpdates", "conformation-dialog", "update-card-column", "update-card"],
    components: {
      rejectDialog,
      Tabs,
      ChatBox,
      ProposalButtons,
      GlobalDialog,
      InputField,
      // DatePickerField,
    },
    data() {
      return {
        ownersBusy: false,
        mailOrderID:this.$props.propsData.order.id,
        mailDesignID:this.$props.propsData.order.design,
        errorMessage:"",
        errorText: "",
        mailName:this.$props.propsData.order.name,
        previousOrderStatus: "",
        rejectStatus: false,
        designData: "",
        order: this.$props.propsData.order,
        users: this.handleOptions(this.$props.propsData.users),
        loader:true,
        orderData: null,
        projectData: null,
        ownerName: null,
        options: this.$props.propsData.users,
        buttonsData: [
          {
            type: "none",
            icon: "pencil-square",
            exist: "desgin",
            tooltipContent: "Edit Design",
            callback: () => {
            window.open(
              `${BASE_URL_FOR_REPORT_IMAGES}studio/${this.orderData.design}`
            );
          },
          },
          {
            type: "none",
            icon: "text-paragraph",
            exist: "desgin_summary",
            tooltipContent: "Design Summary",
            callback: () => {
              let leadId = this.designData?.project?.lead
        let routeObject
        // if (leadId) {
        //   window.open(
        //       `${BASE_URL_FOR_REPORT_IMAGES}leadSummary/${leadId}/design/${this.designData?.id}`
        //     );
       
        // } else {
          window.open(
              `${BASE_URL_FOR_REPORT_IMAGES}designSummary/${this.designData?.id}`
            );
            // }
               },
          },
          {
            type: "none",
            icon: "clipboard",

            callback: () => {
              const url = this.finalSiteSurveyLink;
              window.open(url);
            },
            exist: "survey",
            tooltipContent: "View Site Survey",
          },
        ],
        labelData: [
        { label: "", prop: "name" },
        { label: "", prop: "created_at" },
          {
            label: "Owner",
            prop: "assigned_to",
            inputType: "select",
            options: this.$props.propsData.users,
            nextUrl: this.$props.propsData.ownerNextUrl,
          },
  
          {
            label: "Status",
            prop: "order_status",
            inputType: "select",
            options: orderStatusOptions,
          },
        //  { label: "Due Date", prop: "due_date" },
        { label: "Expected Delivery", prop: "due_date" },

          { label: "Time Remaining", prop: "time_remaining" },
      
        ],
        token: [{ label: "TOKEN", prop: "user_token" }],
        projectDetails: [
          { title: "Project Details" },
          { label: "Name", prop: "name" },
          { label: "Stage", prop: "orderStatus" },
          { label: "Type", prop: "service_type" },
          { label: "Revisions", prop: "revision_version" },
        ],
        customerDetails: [
          { title: "Customer Details" },
          { label: "Name", prop: "client_name" },
          { label: "Email", prop: "client_email_id" },
          { label: "Phone", prop: "client_phone" },
          { label: "Address", prop: "client_address" },
        ],
        ownerDetails: [
          { title: "Owner Details" },
          { label: "Name", prop: "owner_name" },
          { label: "Email", prop: "owner_email" },
          { label: "Phone", prop: "owner_phone" },
        ],
        moduleDetails: [
          { title: "Component Details" },
          { label: "Component Type", prop: "type" },
          // <!-- { label: "Module ID", prop: "moduleID" }, -->
          { label: "Module Data", prop: "moduleData" },
          // { label: "Inverter ID", prop: "inverterID" },
          { label: "Inverter Data", prop: "inverterData" },
          { label: "Optimization Target", prop: "optimisationTarget" },
          { label: "Price", prop: "price" },
          { label: "Component Requirements", prop: "component_requirements" },
        ],
        modelDetails: [
          { title: "Component Details" },
          { label: "Component Type", prop: "type" },
          // <!-- { label: "Module ID", prop: "moduleID" }, -->
          { label: "Quantity", prop: "quantity" },
          { label: "Name", prop: "moduleName" },
          { label: "Price", prop: "price" },
          { label: "Inverter", prop: "inverter" },
          // { label: "Inverter ID", prop: "inverterID" },
        ],
        batteryDetails: [
          { title: "Battery Details" },
          { label: "Battery Manufacturer", prop: "manufacturer" },
          { label: "Battery Model", prop: "model" },
          { label: "Quantity", prop: "quantity" },
        ],
        pricingDetails: [],
        ahjDetails: [
          { title: "AHJ" },
          { label: "AHJ Name", prop: "AHJName" },
          { label: "AHJ Code", prop: "AHJCode" },
          { label: "Building Code", prop: "BuildingCode" },
          { label: "Residential Code", prop: "ResidentialCode" },
          { label: "Fire Code", prop: "FireCode" },
          { label: "Electricity Code", prop: "ElectricCode" },
        ],
  
        additionalNotes: [
          { title: "Additional Notes" },
          { label: "Local Codes", prop: "local_code" },
          { label: "Project Requirements", prop: "Project_Requirements" },
          { label: "Email Address", prop: "email_address" },
          { label: "Phone Number", prop: "phone_number" },
        ],
        acceptLoader:false,
        openPermitPricing:false,
    
        permitInfoData:[
          {
            title:"Selected System Size Range",
            prop:"estimated_system_size"
          },
          {
            title:"Selected System Type",
            prop:"system_type"
          },
          {
            title:"Order No.",
            prop:"order_count"
          }
        ],
        pricingItems: [
        {
          name: "Permit Package Pricing",
          price: 0,
          deliveryDays: 0,
          deliveryHours: 0,
          days_prop:"permit_days",
          hours_prop:"permit_hours",
          price_prop:"permit_pricing"
        },
        {
          name: "Structural Stamp Pricing",
          price: "",
          deliveryDays: 0,
          deliveryHours: 0,
          days_prop:"structural_days",
          hours_prop:"structural_hours",
          price_prop:"structural_pricing"
        },
        {
          name: "Electrical Stamp Pricing",
          price: 0,
          deliveryDays: 0,
          deliveryHours: 0,
          days_prop:"electrical_days",
          hours_prop:"electrical_hours",
          price_prop:"electrical_pricing"
        },
      ],
      permitData:{
          permit_pricing:0,
          structural_pricing:0,
          electrical_pricing:0,
         permit_days:0,
         permit_hours:0,
         structural_pricing:0,
         structural_hours:0,
         structural_days:0,
         electrical_days:0,
         electrical_hours:0,
         electrical_pricing:0
        },
    
      };
    },
    props: {
      propsData: {
        type: Object,
      },
    },
    computed:{
      labelDataOptions(){
        if(this.orderData?.order_status==='incomplete'){
         return this.labelData.filter(ele=>ele.prop !='due_date');
        }else{
          return this.labelData;
        }
      },
      finalSiteSurveyLink(){
        if(this.orderData.additional_info?.path){
          return `${SITE_SURVEY_LINK}${this.orderData.additional_info?.path}/tsl` // for old orders to support backward compatibility
        }
        else if(this.orderData?.site_survey?.token){
          return `${BASE_URL_FOR_REPORT_IMAGES}siteSurveyResponsePage/${this.orderData?.site_survey?.token}/${this.orderData?.site_survey?.template_id}` // for new site survey
        }
        return "";
      },
   
      designSummaryRoute() {
        let leadId = this.designData?.project?.lead
        let routeObject
        if (leadId) {
          routeObject = {
            name: 'designSummary',
            params: {
              leadId: leadId,
              designId: this.designData?.id,
            },
          }
        } else {
          routeObject = {
            name: "designSummary",
            params: {
              designId: this.designData?.id
            }
          }
        }
  
        return routeObject
      },
      countryDetails(){
        return this.designData?.project?.country_details;
      },
      getCurrencySymbolFromProject(){
      let currency='$'
      if(this.designData?.project?.country_details?.country_code){
         currency=getCurrencySymbol(this.designData?.project?.country_details?.country_code);
      }
     
return currency;
    },
    isPermitPricingDisabled(){
      const { 
        permit_pricing, structural_pricing, electrical_pricing, 
        permit_days, permit_hours, 
        structural_days, structural_hours, 
        electrical_days, electrical_hours 
      } = this.permitData;

      // Check if all pricing fields have a value
      const pricingValid = permit_pricing > 0 && structural_pricing > 0 && electrical_pricing > 0;

      // Check if at least one of _days or _hours is present for each category
      const permitTimeValid = permit_days > 0 || permit_hours > 0;
      const structuralTimeValid = structural_days > 0 || structural_hours > 0;
      const electricalTimeValid = electrical_days > 0 || electrical_hours > 0;
      // Return false only if all conditions are met
      return !(pricingValid && permitTimeValid && structuralTimeValid && electricalTimeValid);
    },
    },
    created() {
      this.order = '';
      this.order = this.$props.propsData.order;
      this.getOrderDetails(this.order);
    },
    watch: {
      rejectStatus(val) {
        const orderStatus={
          order_status:"rejected"
        };
        this.updateOrders(this.orderData.id, orderStatus,'order_status', "rejected");
        this.rejectStatus = false;
      },
      propsData(newVal, oldVal) {
        if (newVal && newVal?.order?.id !== oldVal?.order.id) {   
          this.mailOrderID=newVal.order.id
          this.mailDesignID=newVal.order.design
          this.mailName=newVal.order.name
          this.errorMessage=""
          this.designData = null
          this.loader=true
          const newOrder = newVal.order;
          this.getOrderDetails(newOrder);
          this.activeName = "1";
        }
      },
      permitData:{
        deep:true,
        handler(val){

        }
      }
    },
    methods: {
      ...mapActions(useDesignOrdersStore, {
            setAssignedUser: "SET_ASSIGNED_USER",
            setDesignOrder:"SET_DESIGN_ORDER",
      }),
      async loadMoreOwners(nextUrl){
        if(nextUrl){
          this.ownersBusy = true;
          try{
            const ownersResult = await API.DESIGN_ORDERS.LOAD_MORE_Leads(nextUrl);
            let owners = ownersResult.data.results;
            owners.forEach(owner => {
              owner.label = owner.first_name + " " + owner.last_name;
              owner.value = owner.id;
            });
            this.labelData.find(label => label.prop === "assigned_to").options = this.labelData.find(label => label.prop === "assigned_to").options.concat(owners);
            this.labelData.find(label => label.prop === "assigned_to").nextUrl = ownersResult.data.next;
          } catch(err){
            console.error(err);
          }
          this.ownersBusy = false;
        }
      },
      sendErrorEmail() {
        // Compose the email
        let toEmailList = [
          "uthakur@arkaenergy.com",
          "av@arkaenergy.com",
        ]
        let ccEmailList = [
          "kdsouza@arkaenergy.com",
          "ndhandapani@arkaenergy.com",
          "rkumar@arkaenergy.com",
          "mcharan@arkaenergy.com",
          "srinivasst@arkaenergy.com",
          "asingh@arkaenergy.com",
          "cmittal@arkaenergy.com",
        ]
        const toEmail = toEmailList.join(',');
        const subject = "Design API failure notification";
        const ccEmails = ccEmailList.join(',');
        const body = `
        Hi,
  
        The Design API failed.
  
        Details:
        - Design ID: ${this.mailDesignID}
        - Order ID: ${this.mailOrderID}
        - User: ${this.mailName}
  
        Please check and fix the issue.`;
   
        // Create the mailto link
        const mailtoLink = `mailto:${toEmail}?cc=${encodeURIComponent(ccEmails)}&subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
   
        // Open the default email client
        window.location.href = mailtoLink;
      },
      handleModuleInverter(data) {
        let result = "";
        data.map((d, inx) => {
          result = result + d[0] + ` ${data.length - 1 > inx ? "," : ""}`;
        });
        return result;
      },
      handleIsShiftOngoing(shift) {
        return isShiftOngoing(shift);
      },
      getType(shifts) {
        return this.handleIsShiftOngoing(shifts) ? "success" : "info";
      },
      handleColor(data) {
        return handleCompetenceColor(data);
      },
      handleColorLightning(color, perc) {
        return getLightenedColor(color, perc);
      },
      ISUs() {
        this.activeName = "1";
  
        const user = JSON.parse(localStorage.getItem("user")) || {};
        return user.isUSFlagEnabled;
      },
      async updateOrders(id, data,prop,value) {
      try {
        const resData = await API.DESIGN_ORDERS.UPDATE_DESIGN_ORDER_METHOAD(
          id,
          data
        );
        const updatedData = resData.data;
        this.$props.propsData.handleOrderUpdate(updatedData, data);
        if (prop === "order_status" || prop === "assigned_to") {
        this.sendChatEvent(prop, value, this.orderData.id);
      } 
        this.$emit("dialogUpdates", !this.$props.dialogUpdates);
        this.getOrderDetails(this.$props.propsData.order);
        this.acceptLoader=false;
        this.loader=true; 
        this.openPermitPricing=false;

      } catch {
        this.acceptLoader=false;
        this.loader=false; 
        this.openPermitPricing=false;
      }
    

    },
      fullNameHandler(data) {
        // const nameValue = this.users.find((u) => u.id == parseInt(data));
        const firstName = data?.first_name||"";
        const lastName = data?.last_name||"";
        const nameValue = firstName +" " +lastName;
        // const nameValue=data?.first_name||""+" "+data?.last_name||"";
  
        return data? nameValue || "":"";
      },
      async getDesignByID(data) {
        const designs = await API.DESIGNS.FETCH_DESIGN(data.design);
        this.designData = designs.data;
      },
  
      async getOrderDetails(newOrder) {
        try{
        this.errorMessage=""
        this.designData = null
        this.loader=true
        let moduleInvers = "";
        this.projectData = newOrder.project ? newOrder.project : null;
        if (!this.projectData?.client_name && this.projectData?.name){
          this.projectData.client_name = this.projectData.name;
        }
        if (newOrder?.design) {
          await this.getDesignByID(newOrder);
          newOrder.name=this.designData?.project?.name;
          moduleInvers = this.designData.versions?.summary?.bom_data;
        }
        let response = await API.DESIGN_ORDERS.FETCH_DESIGN_ORDER_BY_ID_METHOD(
          newOrder.id
        );
        this.loader=false
        const resData = response.data;
        const project = this.projectdata;
        const engineerData = resData.assignee_details
          ?resData.assignee_details?.first_name||""+" "+resData.assignee_details?.last_name||""
          :null;
  
        resData.name = newOrder ? newOrder?.name || "NA" : "NA";
        resData.owner_name =
          resData?.created_by.first_name ||
          "" + resData?.created_by.last_name ||
          "" ||
          "NA";
          
        resData.owner_phone = resData?.created_by.phone || "NA";
        resData.owner_email = resData?.created_by.email || "NA";
        resData.assigned_to = this.fullNameHandler(resData.assignee_details);
  
        this.ownerName = resData.assigned_to;
        resData.time_remaining = resData.due_date !== null ?resData.order_status==='pending'? this.handleDueDate(resData.due_date,false,resData?.pending_at)  : this.handleDueDate(resData.due_date,false) : "NA"

        resData.created_at = this.handleDueDate(resData.created_at, true);
        resData.due_date = resData.due_date !== null ? this.handleDueDate(resData.due_date,true) : "NA"
        resData.revision_version = resData.revision_version
          ? resData.revision_version
          : 0;
          let conventionalPVComponent;
        if(!resData?.components?.length) {
          resData.components = [
            {
              "type": resData.component_type,
              "price": "NA",
              "moduleID": resData.module_inverter_data?.moduleID || "NA",
              "inverterID": resData.module_inverter_data?.inverterID || "NA",
              "moduleData": (resData.module_inverter_data?.moduleData || "") || "NA",
              "inverterData": resData.module_inverter_data?.inverterData || "NA",
              "optimisationTarget": resData.module_inverter_data?.optimisationTarget || "NA"
            }
          ];
          resData.moduleID = resData.module_inverter_data?.moduleID || "NA";
          resData.moduleData = resData.module_inverter_data?.moduleData || "NA";
          resData.inverterID = resData.module_inverter_data?.inverterID || "NA";
          resData.inverterData = resData.module_inverter_data?.inverterData || "NA";
          resData.optimisationTarget = resData.module_inverter_data?.optimisationTarget || "NA";
        } else {
          resData.components = resData.components;
          let transformedBatteryDetails = [];
          conventionalPVComponent = resData.components.find(component => component.type === "Conventional PV");
          if (conventionalPVComponent) {
            let batteryDetails = conventionalPVComponent.battery_detail;
            if (batteryDetails) {
              batteryDetails.forEach(detail => {
                let transformedDetail = {
                  quantity: detail.quantity,
                  model: detail.batteryData.model,
                  manufacturer: detail.manufacturer
                };
                transformedBatteryDetails.push(transformedDetail);
              });
              resData.batterySpecific = transformedBatteryDetails
            }
          }
        }
  
        resData.components[0].component_requirements = resData?.additional_info?.component_requirements || "NA"
        resData.components[0].price = conventionalPVComponent?.price ? (conventionalPVComponent?.price + " " + conventionalPVComponent?.price_unit) : "NA";
        // As Arka Products only available for US,so hardcoding "$"
        resData.components.forEach(component=>{
          if(component.type!= "Conventional PV"){
            component.price = "$ " + component?.price;
            component.inverter=component.isInverter==false?(`Custom >> ${component.inverter}`) :component.inverter;
            component.moduleName=component?.moduleName||component?.moduleData;
          }
        })
        // Pricing
        let financialData = resData.financial_data || {}
        let pricingKeyDict = {
          "absolute_price": "",
          "price_per_kw": "/kW",
          "price_per_watt": "/W",
        }
        let price
        for (let key of Object.keys(pricingKeyDict)) {
          if (financialData[key]) {
            price = financialData[key] + pricingKeyDict[key]
          }
        }
        let maintenanceCostDict = {
          "absolute_maintenance_cost": "/kW",
          "maintenance_cost_per_kw": "/kW/year"
        }
        let maintenanceCost
        for (let key of Object.keys(maintenanceCostDict)) {
          if (maintenanceCostDict[key]) {
            maintenanceCost = `${financialData[key] ?? '-'}${maintenanceCostDict[key]}`
          }
        }
        this.pricingDetails = [
          { title: "Pricing Details" },
          { label: "Price", value: price },
          { label: "Sales Tax", value: `${financialData.tax || '-'}%` },
          { label: "Expected Life", value: `${financialData.expected_life_years || '-'} years` },
          { label: "Maintenance Cost", value: maintenanceCost },
        ]
  
  
        // resData.moduleData = moduleInvers
        //   ? this.handleModuleInverter(moduleInvers.modules)
        //   : resData.module_inverter_data?.moduleData || "NA";
  
        // resData.inverterID = resData.module_inverter_data?.inverterID || "NA";
        // resData.inverterData = moduleInvers
        //   ? this.handleModuleInverter(moduleInvers.inverters)
        //   : resData.module_inverter_data?.inverterData || "NA";
        // resData.optimisationTarget = moduleInvers
        //   ? this.handleModuleInverter(moduleInvers.Optimizers)
        //   : resData.module_inverter_data?.optimisationTarget || "NA";
        resData.Project_Requirements =
          resData.additional_notes?.Project_Requirements || "NA";
        resData.local_code = resData.additional_notes?.local_code || "NA";
        resData.email_address = resData.additional_notes?.email_address || "NA";
        resData.phone_number = resData.additional_notes?.phone_number || "NA";
        resData.color=handleServiceTypeColor(resData);
        resData.orderStatus=this.getHeaderTitle(resData.order_status);
        resData.designStatus=resData.order_status;
        this.orderData = resData;
        this.setDesignOrder(resData)  
      } catch(err) {
        console.error(err)
        this.errorText = err.stack
        this.loader = false
        this.errorMessage = this.designData ? "Design_id" : 'Design'
      }
      },
      handleDueDate(dateTime, isNotDueDate,isPendingAt) {
        if(isNotDueDate){
          if (
            DateTime.fromISO(dateTime).startOf("day").toISO() ===
            DateTime.local().startOf("day").toISO()
          ) {
            const date = DateTime.fromISO(dateTime);
            return "Today";
          }
        }
        else if(isPendingAt){
          let pendingDate = DateTime.fromISO(isPendingAt)
          let deadlineDate = DateTime.fromISO(dateTime)
          let diffObj = deadlineDate.diff(pendingDate, ["days","hours", "minutes", "seconds"]).toObject()
          return `${diffObj.days ? diffObj.days + 'd' : ''} ${diffObj.hours ? diffObj.hours + 'hr' : ''} ${diffObj.minutes ? Math.round(diffObj.minutes) + 'min' : ''} remaining`
        }
        
        else{
          if (
            DateTime.fromISO(dateTime).toISO() <
            DateTime.local().toISO()
          ) {
            const date = DateTime.fromISO(dateTime);
            return "Overdue";
          }
          let currentDate = DateTime.local()
          let deadlineDate = DateTime.fromISO(dateTime)
          let diffObj = deadlineDate.diff(currentDate, ["days","hours", "minutes", "seconds"]).toObject()
          return `${diffObj.days ? diffObj.days + 'd' : ''} ${diffObj.hours ? diffObj.hours + 'hr' : ''} ${diffObj.minutes ? Math.round(diffObj.minutes) + 'min' : ''} remaining`
        }
        return DateTime.fromISO(dateTime).toFormat("MMM dd, yyyy 'at' hh:mm a");
      },
  
      onAssigneeChange(e, prop, orderData) {
        try{
        let patchData = {};
        const value = prop === "assigned_to" ? parseInt(e) : e;
        patchData[prop] = value;
        if(value==='pending'){
          let filter={order_status:value}
          this.$emit('conformation-dialog',this.orderData,filter,"Pending")
        }else{
          this.loader=true;    
        if (prop === "order_status" || prop === "assigned_to") {
          //run through labelData array and find the object with prop engineer_name
          let engineerNameData = this.labelData.find((label) => label.prop === "assigned_to");
          const assignee = engineerNameData.options.find((option) => option.value === value);
          this.setAssignedUser(assignee);
        } 
  
        if (prop === "order_status") {
          this.$emit("update-card-column", this.orderData, value);
        } else {
          this.$emit("update-card", this.orderData, value);
        }
    
          this.updateOrders(this.orderData.id, patchData,prop,value);
  
        }
      }catch(error){
        this.loader=false;
      }
      },
    async  handleAccept() {
        if(['Permit Package', 'Hybrid Inverter Permit'].includes(this.orderData?.service_type)){
          this.permitData={
          permit_pricing:0,
          structural_pricing:0,
          electrical_pricing:0,
         permit_days:0,
         permit_hours:0,
         structural_pricing:0,
         structural_hours:0,
         structural_days:0,
         electrical_days:0,
         electrical_hours:0,
         electrical_pricing:0
        };
       await this.getOrderPricing();

        }
else {  this.acceptLoader=true;
  this.loader=true;
  
        let orderStatus = {
          order_status: "pending",
        };
        this.updateOrders(this.orderData.id, orderStatus,'order_status', "pending");}
      },
      handleToNexStep(){
    let orderStatus = {
          order_status: "pending",
          "additional_input": {
        "permit_package": {
            "pricing": {
                "base": this.permitData?.permit_pricing,
                "structural_stamp": this.permitData?.structural_pricing,
                "electrical_stamp": this.permitData?.electrical_pricing
            },
            "delivery_time": {
                "base": {
                    "days": this.permitData?.permit_days,
                    "hours": this.permitData?.permit_hours
                },
                "structural_stamp": {
                    "days": this.permitData?.structural_days,
                    "hours": this.permitData?.structural_hours
                },
                "electrical_stamp": {
                    "days": this.permitData?.electrical_days,
                    "hours": this.permitData?.electrical_hours
                }
            }
        }
    }
        };
 
        this.acceptLoader=true;
        this.updateOrders(this.orderData.id, orderStatus,'order_status',"pending");
      
   },
      handleOptions(user) {
        user.map((u) => {
          let first = u?.first_name || "";
          let last = u?.last_name || "";
          u.label = first + " " + last;
          u.value = u.id;
        });
  
        return user;
      },
  
      sendChatEvent(prop, value, order_id) {
        if (prop === "assigned_to") {
          sendEvent(chatEvents.ORDER_ASSIGNMENT, order_id, value);
        }
  
        if (prop === "order_status") {
          if (value === "in_process") {
            sendEvent(chatEvents.ORDER_ACCEPTED, order_id);
          } else if (value === "complete") {
            sendEvent(chatEvents.COMPLETED_DESIGN, order_id);
          } else if (value === "rejected") {
            sendEvent(chatEvents.ORDER_REJECTED, order_id);
          }else if (value === "pending") {
            sendEvent(chatEvents.ORDER_PENDING, order_id);
          }
          else if (value === "cancelled") {
            sendEvent(chatEvents.ORDER_CANCELLED, order_id);
          }
          
        }
      },
      handleWebProposal() {
        const designUUID = this.designData?.versions?.reference_id;
        const route = { name: "webProposal", params: { designUUID } };
  
        // Generate the absolute URL for the route
        const url = this.$router.resolve(route).href;
        // Open the URL in a new tab
        window.open(url, "_blank");
      },
      handleWebDoc() {
        const designId = this.designData?.id;
        const route = { name: "documentProposal", params: { designId } };
        // Generate the absolute URL for the route
        const url = this.$router.resolve(route).href;
        // Open the URL in a new tab
        window.open(url, "_blank");
      },
      handle3D() {
        const designUUID = this.designData?.versions?.reference_id;
        const route = { name: "DesignOverview", params: { designUUID } };
        // Generate the absolute URL for the route
        const url = this.$router.resolve(route).href;
        // Open the URL in a new tab
        window.open(url, "_blank");
      },
      disableAccept(order){
        return order.assigned_to&&order.assigned_to!=="NA";
        
      },
      isDisabledBtn(orderData,icon){
        if(icon.exist==='survey'){
          return !this.finalSiteSurveyLink;
        }

     return (orderData.order_status==='incomplete'|| orderData.order_status==='under review')&&icon.exist!=='survey'&&icon.exist!=='desgin_summary'?true:false;
      },
      handleClick(event,orderData,icon){
        if(this.isDisabledBtn(orderData,icon)){
          event.preventDefault();
        }
  
      },
      getHeaderTitle(value) {
      switch (value) {
        case "incomplete":
          return "Incomplete";
        case "in_process":
          return "Active";
        case "pending":
          return "Payment Pending";
        case "complete":
          return "Completed";
        case "under review":
          return "Under Review";
        case "rejected":
          return "Rejected";
        case "cancelled":
          return "Cancelled";

        case "Incomplete":
          return "incomplete";
        case "Active":
          return "in_process";
        case "Payment Pending":
          return "pending";
        case "Completed":
          return "complete";
        case "Under Review":
          return "under review";
        case "Rejected":
          return "rejected";
        case "Cancelled":
          return "cancelled";
        case "Base":
          return "Base";
        case "Expedited":
          return "Expedited";
        case "Super Expedited":
          return "Super Expedited";

        default:
          return "";
      }
    },
    closeDialoge(){
      this.openPermitPricing=false;
      this.permitData={
          permit_pricing:0,
          structural_pricing:0,
          electrical_pricing:0,
         permit_days:0,
         permit_hours:0,
         structural_pricing:0,
         structural_hours:0,
         structural_days:0,
         electrical_days:0,
         electrical_hours:0,
         electrical_pricing:0,
         order_count:null,
        }

    },
   async getOrderPricing(){
      const response = await API.PROJECTS.FETCH_ALL_FEATURES(this.orderData?.id);
      const orderPricing=response?.data;
     const permitPricingData=orderPricing?.available_features.find(d=>['Permit Package', 'Hybrid Inverter Permit'].includes(d?.service_type))
     const addOnsData=orderPricing?.add_ons;
     this.permitData.order_count=orderPricing?.order_count||null;
     this.permitData.permit_pricing=permitPricingData?.credits||0;
     const permitDateTime=permitPricingData?.additional_info?.delivery_time;
     this.permitData.permit_days=permitDateTime?.days||0;
     this.permitData.permit_hours=permitDateTime?.hours||0;
if(addOnsData.length>0){
  const structuralData=addOnsData.find(d=>d.name=="PE Electrical");
  const structuralDateTime=structuralData?.additional_info?.delivery_time;
  const electricalData=addOnsData.find(d=>d.name=="PE Structural");
  const electricalDateTime=electricalData?.additional_info?.delivery_time;

  this.permitData.electrical_pricing=electricalData?.credits||0;
  this.permitData.electrical_days=electricalDateTime?.days||0;
  this.permitData.electrical_hours=electricalDateTime?.hours||0;
  this.permitData.structural_pricing=structuralData?.credits||0;
  this.permitData.structural_days=structuralDateTime?.days||0;
}else{
  this.permitData.electrical_pricing=0;
  this.permitData.electrical_days=0;
  this.permitData.electrical_hours=0;
  this.permitData.structural_pricing=0;
  this.permitData.structural_days=0;
}
  this.openPermitPricing=true;
    }
 
    },
  };
  </script>
  
  <style scoped>
  .container {
    padding: 1.5rem;
  }
  
  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .title-section {
display: flex;
justify-content: space-between;
align-items: center;
}
  
  .btn-container {
    display: flex;
    justify-content: end;
    align-items: center;
  }
  .title-text{
    font-size:28px;
    font-weight: 600;
    padding-right: 1rem;
  }

  .btn-spacing {
    margin-right: 0.5rem;
  }
  
  .body {
    margin: 1.5rem 0rem 1rem 0rem;
    display: flex;
  }
  .left-body{
    flex:0 0 70%;
    display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 items per row */
  gap: 10px;
  border-right: 2px solid #ccc;
  padding-top: 8px
  }
  .right-body{
    flex:1;
    padding: 0px 16px;

  }
  
  .body-container {
    margin-bottom: 1rem;
  }
  
  .tabs {
    padding-bottom: 40px;
  }
  
  .label {
    padding-bottom: 0.5rem;
    font-size: 0.75rem;
    color: grey;
  }
  
  .custom-collapse .el-collapse-item__wrap {
    display: flex;
    flex-direction: column-reverse;
  }
  
  .custom-collapse .el-collapse-item__wrap {
    overflow: hidden;
    transition: max-height 0.3s ease;
  }
  
  .custom-collapse .el-collapse-item.is-active .el-collapse-item__wrap {
    max-height: 1000px;
    /* Set a value larger than the expected content height */
  }
  
  .tag {
    border-radius: 20px;
  }
  
  .icon-container {
    cursor: pointer;
    color:#1C3366
  }
  .design-btn{
    cursor: pointer;
    color:#409EFF
  }
  .edit-design{
    cursor: pointer;
    color:#409EFF;
  }
  
  .icon-size {
    font-size: 1.5rem;
  }
  
  .icon-text {
    /* color: rgb(64, 158, 255); */
    padding-left: 0.5rem;
    font-size: 16px;
    font-weight: 600;
    padding:8px 18px;
  }
  
  .owner-dropdown-option {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .dot-badge >>> sup {
    top: unset;
  }
  .proposal-btns {
    padding:16px 8px;
  }
  
  .error-heading {
    text-align: center;
  }
  
  .error-text {
    margin-top: 24px;
    /* text-align: center; */
    color: gray;
  }
  .list-value{
    display: flex;
    align-items: center;
  }
  .disabled-link {
  color: #ccc !important; 
    cursor:not-allowed ; 

}
.common-style{
  padding: 8px 8px ;
}

.title-lable{
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 10px;
  color:#777;
}
.container>>>.el-input__inner{
  color:black;
  font-size: 16px;
  height: 48px;
  border:1px solid #ccc;
}
.value-details{
  padding-top:16px;
  font-size: 16px;
}
.value-details-empty{
  padding-top:16px;
  font-size: 16px;
  color:#ccc;
  font-weight: 400;
}
.project-name{
  word-break: break-word;
  text-wrap: wrap;
  font-size: 16px;
}
.created_on{
  font-size: 14px;
  color:#999;
  font-weight: 600;
  padding-top:4px;
}
.permit-container{
  height: 100%;
  padding: 0 24px 24px 24px;
}
.permit-info{
  display: flex;
  flex-wrap: wrap;
  gap:16px;
  padding-bottom: 24px;
}
.stamping{
  display: flex;
  justify-content: space-between;
  gap:16px
}
.stamp-item{
  width: 50%;
}
.permit-item{
  flex: 0 0 48%; 
  box-sizing: border-box; 
  padding-bottom: 10px; 
}
.permit-btns{
  padding:0 24px 24px 24px;
  display: flex;
  justify-content: end;
}

.custom-update-btn:hover {
  color: #fff; /* Example text color on hover */
}
.value-details-empty{
      padding-top: 16px;
    font-size: 16px;
    color: #ccc;
    font-weight: 400;
}
.label-permit{
  color:#777;
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 5px;
}
.value-permit{
  color: #222222;
  font-size: 16px;
  font-weight: 400;

}

.permit-input-container {
  display: flex;
  justify-content: space-between; 
  width: 100%; 
  gap:16px;
}

.box {
  text-align: start;
  padding-bottom: 24px;
}

.first {
  width: 50%; 
}

.second, .third {
  width: 25%; 
}
.input-label{
  padding-bottom: 5px;
}
.required{
  color: #FF4340;
  font-size: 14px;
  font-weight: 600;
}
  </style>
  