
<div class="select-container">
  <p class="title">
    {{ getTitle }} <span v-if="items.isStar" class="astrisk"> *</span>
  </p>
 
  <el-tooltip :disabled="tooltipDisabled" class="item" effect="dark" :content="placeholderText"  placement="top-start" v-if="isDisabled">
    <el-select v-model="selectedItem" :placeholder="items.placeholder" @change="handleSelect" filterable ref="mySelect"
      :disabled="isDisabled" :filter-method="debounceSearchOption" @visible-change="handleVisibleChange"   :loading="loading"  :loading-text="''" loading-icon="el-icon-loading" >
      <template #prefix v-if="selectedIcon">
        <!-- Customize the prefix content here -->
        <span style="padding-top:10px"> <img :src="selectedIcon" alt="" /></span>
      </template>
      <el-option v-for="(item, indx) in uniqueItems" :key="indx" :label="item?.label || item" :value="item || item">
        <div :class="uniqueItems.length - 1 > indx ? 'option-lables' : ''"  v-infinite-scroll="debounceLoadMore" style="overflow: hidden; white-space: nowrap;max-width: 300px;text-overflow: ellipsis; padding-right: 20px; ">
          <p style="overflow: hidden; white-space: nowrap;max-width: 200px;text-overflow: ellipsis; padding-right: 20px; ">
            <!-- <span v-if="item.isSubTitle"> {{ item?.isSubTitle }}</span> -->
            <span v-if="!item.isSubTitle"> {{ item?.label || item?.name||'' }}</span>
          </p>
        </div>
      </el-option>
    </el-select>
  </el-tooltip>
  <el-tooltip :disabled="tooltipDisabled" class="item" effect="dark" :content="selectedItem=='NA'? 'Please select a value to update':selectedItem" placement="top-start" v-else-if="selectedItem " >
  <el-select  v-model="selectedItem" :placeholder="items.placeholder" @change="handleSelect" filterable
    ref="mySelect" :disabled="isDisabled" :filter-method="debounceSearchOption"  @visible-change="handleVisibleChange"  :loading="loading"  :loading-text="''" loading-icon="el-icon-loading"  >
    <template #prefix v-if="selectedIcon">
      <!-- Customize the prefix content here -->
      <span style="padding-top:10px"> <img :src="selectedIcon" alt="" /></span>
    </template>
    <el-option v-for="(item, indx) in uniqueItems" :key="indx" :label="item?.label || item" :value="item || item">
      <div :class="uniqueItems.length - 1 > indx ? 'option-lables' : ''"  v-infinite-scroll="debounceLoadMore" style="overflow: hidden; white-space: nowrap;max-width: 300px;text-overflow: ellipsis; padding-right: 20px; ">
        <p style="overflow: hidden; white-space: nowrap;max-width: 200px;text-overflow: ellipsis; padding-right: 20px; ">
          <span v-if="!item.isSubTitle"> {{ item?.label || item?.name||'' }}</span>
        </p>
      </div>
    </el-option>
  </el-select>
</el-tooltip>

<el-select v-else v-model="selectedItem" :placeholder="items.placeholder" @change="handleSelect" filterable
    ref="mySelect" :disabled="isDisabled" :filter-method="debounceSearchOption"   @visible-change="handleVisibleChange" :loading="loading"  :loading-text="' '" :loading-icon="'el-icon-loading'" >
    <template #prefix v-if="selectedIcon">
      <!-- Customize the prefix content here -->
      <span style="padding-top:10px"> <img :src="selectedIcon" alt="" /></span>
    </template>
    <div v-loading="loading">
    <el-option v-for="(item, indx) in filteredUniqueItems" :key="indx" :label="item?.label || item" :value="item || item" >
      <div :class="uniqueItems.length - 1 > indx ? 'option-lables' : ''"  v-infinite-scroll="handleLoadmore"  infinite-scroll-distance="10" style="max-width: 200px;overflow: hidden; white-space: nowrap;max-width: 300px;text-overflow: ellipsis; padding-right: 20px; " >
        <p style="width:200px;overflow: hidden; white-space: nowrap;max-width: 200px;text-overflow: ellipsis; padding-right: 20px; ">
          <span v-if="!item?.isSubTitle"> {{ item?.label || item?.name||'' }}</span>
        </p>
      </div>
    </el-option>
    <el-option v-if="!uniqueItems" :key="-1" :label="'No options available'" :value="null"></el-option>
  </div>
  </el-select>
</div>
