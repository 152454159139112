
<div class="tagsParentContainer">
  <el-tag
    class="tagsContainer"
    v-for="tag in tags"
    :key="tag.name"
    closable
    @close="removeTag(tag.id)"
  >
    {{ tag.name }}
  </el-tag>
 
  <el-button v-if="!showInput" :class="tags.length == 0 ? 'fixButton0' : 'fixButton' "  icon="el-icon-plus" @click="showInput = true; showInputDropdown(); paddingUL();">
    <span v-if="tags.length == 0" class="addTag">Add Tag</span>
  </el-button>
  <!-- :filter-method="searchAndFilter" -->
  <div v-else>
    <!-- {{ searchedInput }} -->
    <el-select
          placement="top-start"
          filterable
          multiple
          :filter-method="searchAndFilter"
          v-model="inputValue"
          placeholder=""
          size="small"
          @change="addTag(inputValue)"
          @visible-change="onVisibleChange"
          v-enter="selectTagOnEnter"
          class="select"
        > 
          <el-option
            v-for="tag in suggestions"
            :key="tag.id"
            class="option"
            :label="tag.name"
            :value="tag"
          >
            <span>{{ tag.name }}</span>
            <img src="./assets/deleteIcon.svg" class="delete-icon" @click.stop="tagToDeleteId = tag.id; deleteDialogVisible = true" id="delete"/>
        </el-option>
       
        <template
          v-if="searchedInput.trim() && suggestions.length==0"
        >
          <div class="createTagContainer"  @click="selectTagOnEnter()">
            <p class="createTagLabel">Create tag for ‘{{searchedInput}}’</p>
          </div>
        </template>
        <div v-else-if="searchedInput.trim() && suggestions.length>0" class="createTagContainer createTagContainerSticky createTagContainerBorder" 
           @click="selectTagOnEnter()">
          <p class="createTagLabel">Create tag for ‘{{searchedInput}}’</p>
        </div>
    </el-select>
  </div>
  <!--17/04/24 : Nirmal approved. alternative method v-html-->
  <DialogPopup
          :isOpen="deleteDialogVisible"
          :message="`The tag will be deleted from the list and all the associated leads.\nAre you sure you want to continue?`"
          @onClickYes="deleteTagFromOrg(tagToDeleteId)"
          @onClickNo="deleteDialogVisible = false"
      />
</div>
