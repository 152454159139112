<template>
    <div>

        <GlobalDialog :dialogVisible.sync="open" :dialogTitle="title" @handleClose="handleCloseDialog"
                   :width="'800px'"  :loading="loading" :onClickClose="false" :isModal="isModal" :isModalAppend="isModalAppend">
            <template v-slot:body >
                <el-row :align="'middle'" >
                    <el-col :span="1">
                        <div>
                            <p style="padding-top: 10px;font-size: 16px;font-weight: 400;">To : </p>
                        </div>
                    </el-col>
                    <el-col :span="23">
                        <div>
                            <el-tag :disable-transitions="false" style="margin-top: 5px; margin-left:10px">
                                {{ defaultUser }}
                            </el-tag>
                            <el-tag :key="tag" v-for="tag in additionalUsers" closable :disable-transitions="false"
                                style="margin-top: 5px;font-size: 14px;" @close="handleClose(tag)">
                                {{ tag }}
                            </el-tag>

                            <el-input class="input-new-tag" v-if="inputVisible" v-model="inputValue" ref="saveTagInput"
                                size="mini" @keyup.enter.native="handleInputConfirm" @keyup.escape.native="handleCloseInput"
                                @blur="handleInputConfirm" @input="handleChangeValue" :class="{ 'border-red': inputError }"
                                suffix-icon="el-icon-close" @click.native="handleIconClick">
                            </el-input>
                            <el-button style="margin-top: 5px;" v-else class="button-new-tag" size="small"
                                @click="showInput">+</el-button>
                        </div>
                    </el-col>
                </el-row>
                <el-row :align="'middle'">
                    <el-col>
                        <p v-if="inputError" style="color:red;padding-top: 5px;">{{ inputError }}</p>
                    </el-col>
                </el-row>
                <hr class="thick-hr" style="margin-top: 10px;" />

                <!-- CC container -->
								<div v-if="showCC">
									<el-row :align="'middle'">
											<el-col :span="1">
													<div>
															<p style="padding-top: 10px;font-size: 16px;font-weight: 400;">CC : </p>
													</div>
											</el-col>
											<el-col :span="23">
													<div>
															<el-tag :key="tag" v-for="tag in additionalUsersCC" closable :disable-transitions="false"
																	style="margin-top: 5px;font-size: 14px;" @close="handleClose(tag,'cc')">
																	{{ tag }}
															</el-tag>

															<el-input class="input-new-tag" v-if="inputVisibleCC" v-model="inputValueCC" ref="saveTagInputCC"
																	size="mini" @keyup.enter.native="handleInputConfirm('cc')" @keyup.escape.native="handleCloseInput('cc')"
																	@blur="handleInputConfirm('cc')" @input="handleChangeValue('cc')" :class="{ 'border-red': inputError }"
																	suffix-icon="el-icon-close" @click.native="handleIconClick('cc')">
															</el-input>
															<el-button style="margin-top: 5px;" v-else class="button-new-tag" size="small"
																	@click="showInput('cc')">+</el-button>
													</div>
											</el-col>
									</el-row>
									<el-row :align="'middle'">
											<el-col>
													<p v-if="inputErrorCC" style="color:red;padding-top: 5px;">{{ inputErrorCC }}</p>
											</el-col>
									</el-row>
                <hr class="thick-hr" style="margin-top: 10px;" />
            	</div>
                <div style="display: flex;align-items: center;" justify-content="flex-start">
                    <p style="margin-right: 5px;min-width: 65px;font-size: 16px; font-weight: 400;">Subject : </p>
                    <el-input v-model="localSubject" class="subject"></el-input>
                </div>
                <hr class="thick-hr" />
                <div  v-if="urlContent">
									<div style="display: flex;align-items: center;" justify-content="flex-start">
											<el-input type="textarea" :rows="10" v-model="localContent" class="subject" resize="none"
													:autosize="{ minRows: 2, maxRows: 10 }"></el-input>    
									</div> 
									<div v-html="urlContent"></div>
									<div style="display: flex;align-items: center;" justify-content="flex-start">
											<el-input type="textarea" :rows="4" v-model="closerContent" class="subject" resize="none"
													:autosize="{ minRows: 2, maxRows: 4 }"></el-input>    
									</div> 
            		</div>
                <div style="display: flex;align-items: center;" justify-content="flex-start" v-else>
                    <el-input type="textarea" :rows="10" v-model="localContent" class="subject" resize="none"
                        :autosize="{ minRows: 8, maxRows: 10 }"></el-input> 
                </div>

                <div v-if="fileData?.length>0" class="center-content" >
                 <div v-for="(file,index) in fileData" :key="index">
                    <div class="file-container center-content " @click="handleDownload(file)">
                    	<span class="pdf-icon"><img src="../../../assets/img/pdfredIcon.svg" alt=""></span> <p class="ellipsis">{{ file?.display_name}}</p>    
                		</div>
                 </div>
                </div>
               

            </template>
            <template v-slot:footer>
                <hr class="thick-hr" />
                <div :style="footerStyle">
                    <div v-if="withFileUpload">
                        <input type="file" ref="fileInput" accept=".pdf" @change="handleFileUpload" style="display: none" />

                        <el-button size="medium" class="padded-button dashed-button" plain @click="triggerFileUpload"
                            v-if="!invoiceFile">
                            <div style="display:flex; align-items: center; justify-content: center;">
                                <img src="../../leadManagement/components/assets/upload.svg" alt="" class="iconUpload"
                                    style="margin-right: 5px;">
                                Upload Invoice
                            </div>
                        </el-button>
                        <el-button size="medium" class="padded-button"
                            style="color: black; padding:15px; background-color: #E8EDF2; border-color: #D3D4D5; " plain
                            v-else>
                            <div style="display:flex; align-items: center; justify-content: center; ">
                                <p style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;max-width: 100%;min-height: 15px;">
                                    {{ invoiceFile.name }}</p>
                                <i class="el-icon-close"  @click.stop="handleFileUpload(null)"
                                    style=" transform: scale(1.5); margin-left: 10px;color: black;"></i>
                            </div>
                        </el-button>
                    </div>
                    <el-button type="primary" :loading="loading" :disabled="withFileUpload && !invoiceFile" @click="handleSendEmail">Send</el-button>
                </div>
            </template>
            
        </GlobalDialog>
    </div>
</template>

<script>
import GlobalDialog from '../../commonComponents/GlobalDialog.vue';
import { checkEmailValidation } from '../../../core/utils/utils.js';
import { mapState, mapActions } from "pinia";
import { useContractManagementStore } from '../../../stores/contractManagement';
import API from "@/services/api/";
export default {
    emits: ["emitFinalPayload", "send-payments"],
    data() {
        return {
            // from parent
            open: true,
            additionalUsers: [],
            additionalUsersCC:[],
            inputVisible: false,
            inputError: null,
            inputValue: '',
            inputVisibleCC: false,
            inputErrorCC: null,
            inputValueCC: '',
            invoiceFile: null,
            localSubject: '',
            localContent: '',
            loading: false,
            localCloser:'',
            organisationData:JSON.parse(localStorage.getItem("organisation"))||{},
        };
    },
  
    props: {
        defaultUser: {
            type: String,
            required: true
        },
        subject: {
            type: String,
            default: ''
        },
        content: {
            type: String,
            default: ''
        },
        handleSendInvoice: {
            type: Function,
         
        },
        handleClearEmailData: {
            type: Function,
            required: true
        },
        withFileUpload: {
            type: Boolean,
            default: false
        },
        title:{
            type:String,
            default:'Share Invoice'
        },
        urlContent:{
            type:String,
            default:''
        },
        closerContent:{
            type:String,
            default:'',
        },
        dialogLoading:{
            type:Boolean,
            default:false
        },
        fileData:{
            type:Array,
            default:null,
        },
        showCC:{
            type:Boolean,
            default:false
        },
        isEmailTemplateForContractManagement:{
            type:Boolean,
            default:false
        },
        isModal:{
            type:Boolean,
            default:true,
        },
        isModalAppend:{
            type:Boolean,
            default:true,
        }
     
    },
    components: { GlobalDialog, },

    methods: {
        triggerFileUpload() {
            this.$refs.fileInput.value = null; // Clear the input value
            this.$refs.fileInput.click();
        },
        handleFileUpload(event) {
            if (event === null){
                this.invoiceFile = null;
            }

            else{
                const file = event.target.files[0];
                if (file?.type=='application/pdf' && file?.name.length <60) {
                    this.invoiceFile = event.target.files[0];
                }else {
                    if( file?.name.length >= 60){
                    this.$message({
                    showClose: true,
                    dangerouslyUseHTMLString: true,
                    message: "File name should not exceed more than 60 characters",
                    type: "error",
                    center: true
                    });
                    }else{
                        this.$message({
                        showClose: true,
                        dangerouslyUseHTMLString: true,
                        message: "Please select PDF file to proceed",
                        type: "error",
                        center: true
                        });
                    }
                }
            }
                
        },

        handleChangeValue(isCC) {
            if(isCC==='cc'){
                this.inputErrorCC = null;
            }else{
                this.inputError = null;
            }
        },
        handleCloseInput(isCC) {
            if(isCC==='cc'){
                this.inputVisibleCC = false;
                this.inputValueCC = '';
                this.inputErrorCC = null;
            }
           else{ this.inputVisible = false;
            this.inputValue = '';
            this.inputError = null;}
        },
   
        handleIconClick(isCC) {
            if(isCC==='cc'){
                this.handleCloseInput(isCC)
            }else{
                this.handleCloseInput()

            }
        },
        handleCloseDialog() {
            this.open = false
            this.$props.handleClearEmailData();
        },
        handleClose(tag,isCC) {
            if(isCC==='cc'){
                this.additionalUsersCC.splice(this.additionalUsers.indexOf(tag), 1);
            }else{
                this.additionalUsers.splice(this.additionalUsers.indexOf(tag), 1);
            }
        },
      
        showInput(isCC) {
            if(isCC==='cc'){
                this.inputVisibleCC = true;
            this.$nextTick(_ => {
                this.$refs.saveTagInputCC.$refs.input.focus();
            });
            }else{
                this.inputVisible = true;
            this.$nextTick(_ => {
                this.$refs.saveTagInput.$refs.input.focus();
            });
            }
        
        },
        handleInputConfirm(isCC) {
            let inputValue =isCC==='cc'?this.inputValueCC: this.inputValue;
            if (inputValue) {
                if (checkEmailValidation(inputValue)) {
                    if (this.additionalUsers.indexOf(inputValue) > -1 || this.defaultUser == inputValue||this.additionalUsersCC.indexOf(inputValue) > -1) {
                       if(isCC==='cc'){
                        this.inputErrorCC = 'Email already exists';
                       }else{
                        this.inputError = 'Email already exists';

                       }
                        return;
                    }
                    if(isCC==='cc'){
                        this.additionalUsersCC.push(inputValue);
                        this.inputVisibleCC = false;
                        this.inputValueCC = '';
                    }

                   else {this.additionalUsers.push(inputValue);
                    this.inputVisible = false;
                    this.inputValue = '';}
                } else {
                    if(isCC==='cc'){
                        this.inputErrorCC = 'Enter a valid email'
                    }else{
                        this.inputError = 'Enter a valid email';
                    }
                   
                }
            }
        },
       	async handleSendEmail(){
					if(this.isEmailTemplateForContractManagement){ // when used for sharing contracts in contract management
						let finalUsers = [... this.additionalUsers]
						finalUsers.push(this.defaultUser)
            this.loading = true;
						let payload = {
							"via": "email",
							"body": this.localContent,
							"subject": this.localSubject,
							"recipients": finalUsers,
							"cc_to": this.additionalUsersCC,
							"attachments" : this.fileData.map((file)=>{
								let json = {
									"file_url": file.file_url,
									"display_name": file.display_name
								}
								return json
							}),
							"design_id": this.CMStore.selectedDesignIdForPreview,
						}
						this.$emit("emitFinalPayload",payload)
						return;
					}
					if(this.$props.urlContent){
							let bodyContent=`${this.localContent}\n${this.$props.urlContent}\n\n${this.closerContent}`;
							this.$emit('send-payments',this.additionalUsers, this.localSubject, bodyContent, this.closerContent);

					}else{
							this.$props.handleSendInvoice(this.additionalUsers, this.localSubject, this.localContent, this.invoiceFile,this.additionalUsersCC);
					}
        },
        handleDownload(file){
            saveAs(file.file_url, file.display_name);
        }
    },
    computed: {
        // footer style with this padding:5px 0px 10px 0px;display: flex;justify-content: space-between;
        footerStyle() {
            return "padding:5px 0px 10px 0px;display: flex;justify-content: " + (this.withFileUpload ? "space-between;" : "flex-end;");
        },
				...mapState(useContractManagementStore, {
					CMStore: (state) => state
    		}),
    },
    //in created, set the localSubject and localContent from props
    created() {
        this.localSubject = this.$props.subject;
        this.localContent = this.$props.content;
    },
    watch:{
        dialogLoading:{
            immediate:true,
            handler(newVal){
                this.loading = newVal;
            }
        }
    },

}
</script>

<style scoped>
.el-tag+.el-tag {
    margin-left: 10px;
}

.border-red>>>.el-input__inner {
    border: 1px solid red;
}

.thick-hr {
    border: 0;
    height: 1px;
    margin-top: 5px;
    margin-bottom: 5px;
    background-color: rgb(204, 204, 204);
}

.button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
}

.input-new-tag {
    width: 120px;
    margin-left: 10px;
    vertical-align: bottom;
    margin-top: 5px;
}

.input-new-tag>>>.el-input__inner {
    height: 32px;
}
.el-button.is-plain:hover, .el-button.is-plain:focus {
    background: #FFFFFF;
    border-color: #409EFF;
    color: #777;
}
.subject>>>.el-input__inner {
    border: none !important;
    padding: 0px !important;
    color: #222;
    font-size: 16px;
    font-weight: 400;
}

.subject>>>.el-textarea__inner {
    border: none !important;
    padding: 5px 0px 5px 0px !important;
    color: #222;
    font-size: 16px;
    font-weight: 400;
}

.input-new-tag>>>.el-input__suffix-inner {
    color: #222;
    cursor: pointer;
}

.first-tag>>>.el-tag {
    margin-left: 310px !important;
}

.dashed-button {
    border: 1px black dashed
}

.padded-button {
    width: 100%;
    padding: 10px;
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px; /* Adjust this value according to your needs */
}
.center-content {
  display: flex;
  align-items: center;
  cursor: pointer;
	flex-wrap: wrap;
	gap: 10px

}

.file-container{
    border:1px solid #ccc;
    padding:8px 16px;
    border-radius: 4px;
    margin-right: 16px;
}
.pdf-icon {
  margin-right: 6px;
  display: flex;
  align-items: center;
}
.org-icon{
    width:80px;
    height: 50px; 
    display: flex;
}
</style>