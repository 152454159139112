import BaseObject from "../../BaseObject";
import ThreejsText from "../../subObjects/ThreejsText";
import { TILE_VIEWS } from "./TilesGrid";
import * as THREE from "three";

export default class CustomTile extends BaseObject {
    constructor(stage, gridCell, vertices) {
        super(stage);
        this.stage = stage;
        this.gridCell = gridCell;
        this.vertices = vertices;
        this.length = this.getHorizontalSpan();
        this.width = this.getVerticalSpan();
        this.visible = true;
        this.objectsGroup = new THREE.Group();
        this.objectsGroup.name = "CustomTile";
        this.objectsGroup.container = this;
        this.tileMesh = null;
        // this.text = '';
        // const globalPosition = this.gridCell.grid.toGlobal(this.getCentroid());
        // this.textMesh = new ThreejsText(this.text, globalPosition, 0, this.stage, this, false, 'center', 'middle', null, true);
        // this.textMesh.showText();
    }

    getCentroid() {
        // get the centroid of the custom tile
        let centroid = new THREE.Vector3(0, 0, 0);
        for (let i = 0; i < this.vertices.length; i++) {
            centroid.x += this.vertices[i].x;
            centroid.y += this.vertices[i].y;
            centroid.z += this.vertices[i].z;
        }
        centroid.x /= this.vertices.length;
        centroid.y /= this.vertices.length;
        centroid.z /= this.vertices.length;
        return centroid;
    }

    showText() {
        this.textMesh.showText();
    }

    hideText() {
        this.textMesh.hideObject();
    }

    updateText(newText = this.text) {
        // this.text = newText;
        // const globalPosition = this.gridCell.grid.toGlobal(this.position);
        // this.textMesh.update(null, globalPosition);
        // this.textMesh.textMesh.text = this.text;
        // if(this.text && this.text !== '') {
        //     this.showText();
        // } else {
        //     this.hideText();
        // }
    }

    getVerticalSpan() {
        if(this.vertices.length < 2) return 0;
        // find the difference between the highest and lowest vertex y values
        let min = this.vertices[0].y;
        let max = this.vertices[0].y;
        for (let i = 1; i < this.vertices.length; i++) {
            let y = this.vertices[i].y;
            if (y < min) {
                min = y;
            } else if (y > max) {
                max = y;
            }
        }
        return max - min;
    }

    getHorizontalSpan() {
        if(this.vertices.length < 2) return 0;
        // find the difference between the highest and lowest vertex x values
        let min = this.vertices[0].x;
        let max = this.vertices[0].x;
        for (let i = 1; i < this.vertices.length; i++) {
            let x = this.vertices[i].x;
            if (x < min) {
                min = x;
            } else if (x > max) {
                max = x;
            }
        }
        return max - min;
    }

    isValid(tolerance = 0.0001) {
        // if tile is rectangle return false
        // check that all angles are not 90 degrees
        let angles = [];
        for (let i = 0; i < this.vertices.length; i++) {
            let j = i + 1;
            if (j === this.vertices.length) {
                j = 0;
            }
            let k = j + 1;
            if (k === this.vertices.length) {
                k = 0;
            }
            let angle = this.getAngle(this.vertices[i], this.vertices[j], this.vertices[k]);
            angles.push(angle);
        }
        // if all angles are 90 degrees return false
        let isRectangle = true;
        for (let i = 0; i < angles.length; i++) {
            if (Math.abs(angles[i] - 90) > tolerance) {
                isRectangle = false;
                break;                
            }
        }
        // if (isRectangle) {
        //     return false;
        // }else {
            return true;
        // }

    }

    updateVertices(vertices) {
        this.vertices = vertices;
        this.length = this.getHorizontalSpan();
        this.width = this.getVerticalSpan();
    }

    getAngle(p1, p2, p3) {
        // get the angle between three points
        let v1 = new THREE.Vector2(p2.x - p1.x, p2.y - p1.y);
        let v2 = new THREE.Vector2(p3.x - p2.x, p3.y - p2.y);
        let angle = v1.angleTo(v2) * 180 / Math.PI;
        return angle;
    }

    
    onSelect() {
        this.tileMesh.material.color = new THREE.Color(0x00ffff);
        this.tileMesh.material.needsUpdate = true;
    }

    showObject() {
        this.tileMesh.material.color = new THREE.Color(0x00ffff);
        this.tileMesh.material.needsUpdate = true;
    }

    hideObject() {
        this.tileMesh.material.color = new THREE.Color(0x333333);
    }

    deSelect() {
        this.tileMesh.material.color = new THREE.Color(0x333333);
        this.tileMesh.material.needsUpdate = true;
    }

    computeArea() {
        // compute the area of the custom tile
        let area = 0;
        let j = this.vertices.length - 1;
        for (let i = 0; i < this.vertices.length; i++) {
            area += (this.vertices[j].x + this.vertices[i].x) * (this.vertices[j].y - this.vertices[i].y);
            j = i;
        }
        return Math.abs(area / 2);
    }

    getCode() {
        return this.code;
    }

    getArea() {
        return this.computeArea();
    }

    removeObject() {
        this.objectsGroup.remove(this.tileMesh);
        // this.textMesh.removeObject();
    }

    saveObject() {
        // vertices, gridCellVertices
        const customTileData = {
            vertices: this.vertices.map(v => [v.x, v.y, v.z]),
        };
        return customTileData;
    }

    loadObject(customTileData) {
        // vertices, gridCellVertices
        const vertices = customTileData.vertices.map(v => new THREE.Vector3(v[0], v[1], v[2]));
        this.updateVertices(vertices);
        this.updateText();
    }

}
