<template>
  <div class="menu-container">
    <p class="title">
      {{ itemValue.title }}
      <span class="astrisk"> *</span>
    </p>
    <el-menu  menu-trigger="click" mode="horizontal" background-color="white"
      text-color="black" active-text-color="#222" @select="handleMenuSelect" :unique-opened="true">
      <el-submenu index="2" class="custom-submenu" :disabled="!seletedSource">
        <template v-slot:title>
          <el-tooltip class="item" effect="dark" :content="selectedMenu=='NA'?'Please select a value to update' :selectedMenu" placement="top">
            <div style="width: 80px;text-overflow: ellipsis; overflow: hidden; white-space: nowrap;" :style="{ color: selectedMenu === 'Select Value' ? '#777' : '#222',fontSize: selectedMenu === 'Select Value' ? '12px' : '14px' }">
              {{ selectedMenu }}
            </div>
          </el-tooltip>
        </template>

        <el-submenu v-for="(options, index) in optionsData" :key="index" :index="options.id" class="custom-submenu-child">
          <template v-slot:title>
            <div
              style="overflow: hidden; white-space: nowrap;max-width: 300px;text-overflow: ellipsis; padding-right: 20px; ">
              {{ options?.label || options?.name }}
            </div>
          </template>
          <template>

            <div style="max-height: 300px; overflow: scroll;">
              <div style="height: 30px;display: flex;align-items: center;font-weight: 400; font-size: 14px;" v-for="(subOptions, ind) in options.stageOptions" :key="ind">
                <el-menu-item :index="subOptions.id">{{ subOptions.name }}</el-menu-item>
              </div>
            </div>
          </template>
        </el-submenu>
      </el-submenu>
    </el-menu>


  </div>
</template>
  
<script>
import API from '../../../../services/api';

export default {
  emits: ["item-selected"],
  name: "MyMenu",
  data() {
    return {
      selectedMenu: 'Select Value',
      optionsData: null,
      stageOptions: null,
      stageData:null
    };
  },
  props: {
    itemValue: {
      type: Object,
      default() {
        return {}
       },
    },
    selectedValue: {
      type: [String, Number],
      default: "",
    },
    indexOfOptions: {
      type: Number,
      default: null,
    },
    filterColumnOption: {
      type: Array,
      default() {
        return []
       },
    },
    seletedSource: {
      type: Object,
      default() {
        return {}
       },
    },
  

  },
  created() {
    this.getStageOptions(this.$props.itemValue?.options)
  },
  mounted() {

  },
  methods: {
    getStageOptions(pipline) {
      this.optionsData = []
      this.stageOptions = []
      const stageDetails = this.$props.filterColumnOption.find(
        (data) => data.metric === "stage_object__id" || data.metric === "lead__stage_object__id"
      );

      if (pipline) {

        pipline.map(async pl => {
          let queryParams = ''
          let tempObj = ''
          tempObj = pl
          queryParams = {
            related_model_id: stageDetails.related_model_id,

            pipeline_id: pl.id,
          };



          if (queryParams) {
            let params = new URLSearchParams(queryParams).toString();

            try {
              const { data } =
                await API.CRM_DASHBOARD.GET_WIDGET_FILTER_VALUE_OPTIONS(params);
              tempObj.stageOptions = data.results ? data.results : data;
              let apidata = data.results ? data.results : data
              apidata = apidata.map(dt => {
                dt.label = dt.id
              })

              this.stageOptions.push(...apidata)
              this.optionsData.push(tempObj)
            } catch (err) {
              console.error(err);
            }


          }
        })


      }
    },
    handleMenuSelect($event, index) {
      const piplineData = this.optionsData.find(fd => fd.id === index[1]);
      const stageData = piplineData?.stageOptions.find(stag => stag.id === index[2])
      this.selectedMenu = `${stageData.name} - ${piplineData.label}`;
      this.$emit('item-selected', this.$props.itemValue.prop, stageData, stageData.id, "", this.$props.indexOfOptions, false, [], piplineData)
    },
    isDisabled() {
      if ((this.$props.items.prop !== 'type' && this.$props.items.prop !== 'source') && !this.$props.seletedSource) {
        return true
      } else
        return false
    }
  },
  watch: {
    optionsData: {
      immediate: true,
      handler(newVal) {
        if (newVal && newVal.length > 0 && this.$props.itemValue?.selectedValue) {

          const piplineData = newVal.find(fd => fd.id === parseInt(this.$props.itemValue.additional_conditions.value));
          let stagesData = ''
          if (piplineData) {

            stagesData = piplineData?.stageOptions.find(stag => stag.id === parseInt(this.$props.itemValue.selectedValue)) || '';
            this.stageData=stagesData;
        

          }else{
            this.selectedMenu='NA'
          }
          if (piplineData) {
            if(!stagesData?.name){
              this.$emit('item-selected', this.$props.itemValue.prop, '', '', "", this.$props.indexOfOptions, false, [], piplineData)

            }
            this.selectedMenu = `${stagesData?.name||'NA'} - ${piplineData?.label || piplineData?.name||'NA'}`;

          }

        }
      }
    }
  }
};
</script>
  
<style scoped>
.title {
  padding-bottom: 0.5rem;
  font-size: 12px;
  color: #777;
  font-weight: 600;
}

.menu-container::v-deep .el-submenu__icon-arrow {
  position: absolute;
  top: 50%;
  right: 20px;
  margin-top: -7px;
  transition: transform 0.3s;
  font-size: 17px;
}
.menu-container::v-deep.el-menu--horizontal {
    border-right: none;
    max-height: 400px;
    overflow: scroll;
}
.menu-container>>>.el-submenu__title {
  background-color: #e8edf2 !important;
  display: flex;
  align-items: center;
  height: 40px !important;
  color: black !important;
}

.astrisk {
  font-size: 12px;
  font-weight: 600;
  color: #f00;
}

.el-menu--horizontal>>>.el-submenu.is-active .el-submenu__title {
  color: black !important;
  font-weight: 500;
}

.custom-submenu {
  background-color: #e8edf2 !important;
  display: flex;
  align-items: center;
  height: 40px !important;
  color: black !important;
}

.el-menu--horizontal>>>.el-submenu .el-submenu__icon-arrow {
  position: static;
  font-size: 20px;
  color: #222;
  vertical-align: middle;
  margin-left: 8px;
  margin-top: -3px;
}

.el-menu--horizontal>>>.el-menu--popup {
  z-index: 100;
  color: red;
  background-color: red;
  min-width: 120px;
  border: none;
  padding: 5px 0;
  border-radius: 2px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.menu-container>>>.el-submenu.is-active .el-submenu__title {
  border-bottom: transparent;
  color: #222;
  height: 40px;
  line-height: 40px;
}
</style>
  