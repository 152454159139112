<template>
  <div>
    <div class="data-container">
      <div>
        <el-row>
          <div class="container-data">
            <b-icon
              class="custom-icon"
              :style="{
                marginRight: '5px',
                color: '#EF6C00',
              }"
              :icon="'exclamation-octagon-fill'"
            ></b-icon>
          </div>
        </el-row>
        <el-row>
          <p class="container-data title">{{ info }}</p>
        </el-row>
        <el-row>
          <p class="container-data inicator">{{ infoindicator }}</p>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      info: "No Data Available",
      infoindicator: "Please contact our support team to resolve this issue.",
    };
  },
  mounted() {
    this.info = this.$props.infoData;
  },
  props: {
    infoData: {
      type: String,
      default: "No Data Available",
    },
  },
};
</script>

<style scoped>
.data-container {
  background: white;
  height: calc(100vh - 250px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container-data {
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom-icon {
  font-size: 5rem; /* Adjust this value to your desired font size */
  color: #ef6c00;
  margin-right: 5px;
}
.title {
  font-size: 1.2rem !important;
  font-weight: bold;
  padding-top: 1rem;
}
.inicator {
  font-size: 1.2rem;
  font-weight: bold;
}
</style>
