import * as THREE from "three";
import BaseTile from "./BaseTile";

export default class NonPVTile extends BaseTile {
    constructor(stage, gridCell, position, length, width, fractionIndex) {
        super(stage, gridCell, position, length, width);
        this.fractionIndex = fractionIndex;
        this.isFull = true;
        this.updateColors();
        this.isJunction = false;
    }

    updateColors() {
        if (this.fractionIndex !== null && this.fractionIndex !== undefined) {
            this.defaultColor = this.gridCell.grid.getPartialTileColor(
                this.fractionIndex
            );
            this.highlightColor =
                this.gridCell.grid.getPartialTileHighlightColor(
                    this.fractionIndex
                );
        } else {
            this.defaultColor = this.gridCell.grid.getNonPVColor();
            this.highlightColor = this.gridCell.grid.getNonPVHighlightColor();
        }
        this.dcColor = this.gridCell.grid.getDCColor()
    }

    convertToPVTileEnabled() {
        return this.gridCell.isFull;
    }

    convertToPVTile(resetDC = true) {
        this.gridCell.convertToPVTile(this, resetDC);
    }

    saveObject() {
        const nonPVTileData = {
            type: NonPVTile.getObjectType(),
            position: [this.position.x, this.position.y, this.position.z],
            length: this.length,
            width: this.width,
            isFull: this.isFull,
            subCell: this.subCell ? {
                centroid: [
                    (this.subCell[0].x + this.subCell[2].x) / 2,
                    (this.subCell[0].y + this.subCell[2].y) / 2,
                    (this.subCell[0].z + this.subCell[2].z) / 2,
                ],
                length: Math.abs(this.subCell[0].x - this.subCell[2].x),
                width: Math.abs(this.subCell[0].y - this.subCell[2].y),
            } : null,
            fractionIndex: this.fractionIndex,
        };
        return nonPVTileData;
    }

    loadObject(nonPVTileData) {
        this.position = new THREE.Vector3(...nonPVTileData.position);
        this.length = nonPVTileData.length;
        this.width = nonPVTileData.width;
        this.isFull = nonPVTileData.isFull;
        if (nonPVTileData.subCell) {
            const { centroid, length, width } = nonPVTileData.subCell;
            this.subCell = [
                new THREE.Vector3(centroid[0] - length / 2, centroid[1] - width / 2, centroid[2]),
                new THREE.Vector3(centroid[0] + length / 2, centroid[1] - width / 2, centroid[2]),
                new THREE.Vector3(centroid[0] + length / 2, centroid[1] + width / 2, centroid[2]),
                new THREE.Vector3(centroid[0] - length / 2, centroid[1] + width / 2, centroid[2]),
            ];
        } else {
            this.subCell = null;
        }
        this.fractionIndex = nonPVTileData.fractionIndex;
        this.updateColors();
    }

    showSolarAccess({ dcColor, apply = true } = {}) {
        if (dcColor) {
            this.dcColor = dcColor;
        }
        if(apply) {
            this.setColor(this.dcColor);
        }
    }

    static getObjectType() {
        return "Non-PV Tile";
    }
}
