// import Drawface from "./DrawFace";
import * as topBarAssistant from "../../../../componentManager/topBarAssistant";
import * as sapPaneAssistant from "../../../../componentManager/sapPaneAssistant";
import * as CONSTANTS from "../../../../componentManager/componentManagerConstants"
import { store } from "../../../../store";
import { serverBus } from "../../../../main";
import * as THREE from 'three';
import { checkClockwise, getUnionOfTwoPolygons } from "../../../utils/utils";
import RoofVertex from "./RoofVertex";
import FaceVertex from "./FaceVertex";
import PenToolRoofModel from "./PenToolRoofModel";
import * as utils from '../../../utils/utils';
import * as raycastingUtils from '../../../utils/raycastingUtils';
import { COLOR_MAPPINGS, MATERIAL_STATES } from "../../visualConstants";
import BaseObject from "../../BaseObject";
import { MINIMUM_NUMBER_OF_POINTS } from "../../subObjects/TextBox";
import { COMPLEX_GEOMETRY_ERROR, DRAWING_POINT_INSIDE_PATIO_SETBACK_AREA_ERROR, LAST_EDGE_INTERSECTION_ERROR, LESS_VERTICES_THAN_NEEDED_FOR_DRAWING_ERROR, LESS_VERTICES_THAN_NEEDED_FOR_PLACING_ERROR, OUT_OF_GROUND_ERROR, VERTEX_EQUIVALENT_ERROR, VERTEX_OVER_EDGE_ERROR } from "../../../coreConstants";
import * as turf from '@turf/turf';

// Jugaad fix ciculcar dependency
let PenToolRoofFace;
import('./PenToolRoofFace').then(module => {
  PenToolRoofFace = module.default;
}).catch(error => {
  console.error("Error loading module: " + error);
});

export default class PenTool extends BaseObject {
    constructor(stage){
        super(stage);
        this.stage = stage;
        // this.penToolRoofModel = null;
        this.penToolRoofModels = [];
        this.penToolRoofModel = new PenToolRoofModel(this.stage);
        this.penToolRoofModel.parent = this;
        this.penToolRoofModel.isDummy = true;
        this.penToolRoofModels.push(this.penToolRoofModel);
        this.children.push(this.penToolRoofModel);
        this.objectsGroup = new THREE.Group();
        this.objectsGroup.container = this;
        this.stage.sceneManager.scene.add(this.objectsGroup);
        this.drawFaces = [];
        this.pointsGraph= [];
        this.loopsArray = [];
        this.completeLoop = [];
        this.connectedGraph = [];
        this.pointsMap = new Map();
        this.roofVertices = [];
        this.roofFaces = [];
        this.drawnVertices = [];
        this.firstAndLastPointsOnLoop = false;
        this.firstSplitEdge = [];
        this.lastSplitEdge = [];
        this.firstPoint = null;
        this.secondPoint = null;
        this.currentPentoolRoofModel = null;
        this.nextPentoolRoofModel = null;
        this.mergeRoofModelsFlag = false;
        serverBus.$on('drawPenToolComplete', () => {
            setTimeout(() => {
                this.startDraw();
            }, 100);
        });

    }

    startDraw(){
        // serverBus.$emit('completeButtonToggleFlag')
        store.commit(CONSTANTS.SIDEBAR_BUTTON_STATUS_ALL_DISABLED_STATE);
        store.commit(CONSTANTS.ALL_MAP_BUTTONS_DISABLED_STATE);
        store.commit(CONSTANTS.TOPBAR_BUTTON_STATUS_DRAWING_STATE); 
        if(this.penToolRoofModels.length>0) this.penToolRoofModel.splitEdge();
        this.currentPentoolRoofModel = null;
        this.newPenTool();
    }

    newPenTool(){
      this.initDrawingMode();
        topBarAssistant.setCompleteAction(this.stage.drawManager.onComplete.bind(this.stage.drawManager), this);
        topBarAssistant.setCancelAction(this.stage.drawManager.onCancel.bind(this.stage.drawManager));
        sapPaneAssistant.onObjectCreation(this);
    }

    getRoofModel(vertex){
      if(this.penToolRoofModels.length === 0) return null;
      let selectedModel = null;
      this.penToolRoofModels.forEach(model => {
            if(model.isPointOnModel(vertex)){
                selectedModel = model;
            }
        });
      return selectedModel;
    }

    updatepointsGraph(vertices){
        this.pointsGraph = [];
        const drawnVertices = this.drawnVertices;
        this.drawFaces.forEach(face => {
            this.pointsGraph.push(face.oldVertices);
        });

        if(this.penToolRoofModel.roofFaces.length === 1){
            this.completeLoop = utils.isClockwise(this.penToolRoofModel.roofFaces[0].editedVertices) ? this.penToolRoofModel.roofFaces[0].editedVertices : this.penToolRoofModel.roofFaces[0].editedVertices.reverse();
            this.penToolRoofModel.completeLoop = this.completeLoop;
            const lastVertex = new RoofVertex(this.stage, this.completeLoop[this.completeLoop.length - 1], null, null, false);
            this.penToolRoofModel.startingVertex = new RoofVertex(this.stage, this.completeLoop[0], null, lastVertex, true);
            lastVertex.next = this.penToolRoofModel.startingVertex;
            let tempStartingVertex = this.penToolRoofModel.startingVertex;
            for(let j = 1; j< this.completeLoop.length-1; j++){
              tempStartingVertex.next = new RoofVertex(this.stage, this.completeLoop[j], null, tempStartingVertex);
              tempStartingVertex = tempStartingVertex.next;
            }
            tempStartingVertex.next = lastVertex;
            lastVertex.previous = tempStartingVertex;
        }       
        else{
          if(drawnVertices.length === 2){
            let firstVertexIndex = -1;
            let secondVertexIndex = -1;
            let tempArray = [...drawnVertices];
            tempArray.push(this.centerOfPolygon(this.completeLoop));
            if(!utils.isClockwise(tempArray)){
              drawnVertices.reverse();
            }
            for(let i = 0; i < this.penToolRoofModel.completeLoop.length; i++){
              if(this.isPointOnPoint(drawnVertices[0], this.penToolRoofModel.completeLoop[i])){
                firstVertexIndex = i;
              }
              if(this.isPointOnPoint(drawnVertices[1], this.penToolRoofModel.completeLoop[i])){
                secondVertexIndex = i;
              }
            }

            if(firstVertexIndex !== -1 && secondVertexIndex !== -1){
              this.completeLoop = [];
              let i = secondVertexIndex;
              while(i !== (firstVertexIndex + 1)%this.penToolRoofModel.completeLoop.length){
                this.completeLoop.push(this.penToolRoofModel.completeLoop[i]);
                i = (i+1)%this.penToolRoofModel.completeLoop.length;
              }
            }
          }
          else if(!this.firstAndLastPointsOnLoop){
            const completeLoopClone = [...this.penToolRoofModel.completeLoop];
            const verticesClone = [...vertices];
            //add the first vertex to the end of the loop
            verticesClone.push(verticesClone[0]);
            completeLoopClone.push(completeLoopClone[0]);
            // const union = this.mergePolygons(completeLoopClone, verticesClone);
            //complete later
          }
          else this.completeLoop = this.mergeLoops(this.penToolRoofModel.completeLoop, drawnVertices)
          this.penToolRoofModel.completeLoop = this.completeLoop;
        }
        this.penToolRoofModel.completeLoopFaceVertices = [];
        for(let i = 0; i < this.completeLoop.length; i++){  
          const faceVertex = this.pointsMap.get(`${this.completeLoop[i].x.toFixed(4)},${this.completeLoop[i].y.toFixed(4)}`);
          this.penToolRoofModel.completeLoopFaceVertices.push(faceVertex);
        }
        this.penToolRoofModel.perimeterPoints = this.completeLoop;


    }

    mergePolygons (polygon1, polygon2) {
      if(polygon1.length === 0) return polygon2;
      if(polygon2.length === 0) return polygon1;
      const basePoly = polygon1;
      const subPoly = polygon2;
      try {
        // Convert Vector3 arrays to GeoJSON Polygons
        const baseGeoJSON = this.vector3ArrayToGeoJSON(basePoly);
        const subGeoJSON = this.vector3ArrayToGeoJSON(subPoly);

        // Ensure both polygons are valid
        if (!turf.booleanValid(baseGeoJSON) || !turf.booleanValid(subGeoJSON)) {
            console.warn("Invalid input polygons. Returning original base polygon.");
            return basePoly;
        }

        // Create a FeatureCollection from the two polygons
        const featureCollection = turf.featureCollection([baseGeoJSON, subGeoJSON]);

        // Perform the difference operation
        const difference = turf.difference(featureCollection);

        if (!difference) {
            console.warn("Difference operation resulted in null. Returning original base polygon.");
            return basePoly;
        }

        // Convert the result back to Vector3 array
        const result = this.geoJSONToVector3Array(difference);
        return result;
    } catch (error) {
        console.warn("Error subtracting polygons:", error);
        console.warn("Returning original base polygon as fallback");
        return basePoly;
    }
  };

    mergeLoops(loop, drawnVertices) {
        const loop1 = (utils.isClockwise(loop)) ? loop : loop.reverse();
        const loop2 = (utils.isClockwise(drawnVertices)) ? drawnVertices : drawnVertices.reverse();
        this.loopsArray.push(loop1);
        let tempStartingVertex = this.penToolRoofModel.startingVertex;
        let tempGraphStartingVertex = new RoofVertex(this.stage, loop2[0], null, null, true);
        let tempVertex1 = new RoofVertex(this.stage, null, tempGraphStartingVertex, null, false);
        tempGraphStartingVertex.previous = tempVertex1;

        let stop = true;
        let flag = true;
        for(let j = 1; j < loop2.length; j++){
          tempStartingVertex = this.penToolRoofModel.startingVertex;
          stop = true;
          if(!flag) break;
          tempGraphStartingVertex.next = new RoofVertex(this.stage, loop2[j], null, tempGraphStartingVertex);
          tempGraphStartingVertex = tempGraphStartingVertex.next;
          if(loop2[0].x === loop2[j].x && loop2[0].y === loop2[j].y) tempGraphStartingVertex.isDuplicate = true;
          while(stop){
            if(this.isPointOnPoint(loop2[j], tempStartingVertex.vertex)) {
              stop = false;
              flag = false;
              tempGraphStartingVertex.next = tempStartingVertex.next;
              tempGraphStartingVertex.previous = tempGraphStartingVertex.previous;
              break;
            }
            else if(this.isPointOnPoint(loop2[j], tempStartingVertex.next.vertex)) {
              stop = false;
              flag = false;
              tempGraphStartingVertex.next = tempStartingVertex.next.next;
              tempGraphStartingVertex.previous = tempGraphStartingVertex.previous;
              break;
            }

            else if(this.isPointOnLine(loop2[j], tempStartingVertex.vertex, tempStartingVertex.next.vertex)) {
              stop = false;
              flag = false;
              tempGraphStartingVertex.next = tempStartingVertex.next;
              tempGraphStartingVertex.previous = tempGraphStartingVertex;
              break;
            }
            tempStartingVertex = tempStartingVertex.next;
            if(tempStartingVertex.isFirst){
              stop = false;
            }
          }
        }
          
        while(true){
          if(this.isPointOnPoint(loop2[0], tempGraphStartingVertex.vertex) && !tempGraphStartingVertex.isDuplicate) {
            tempVertex1 = tempVertex1.next;
            tempVertex1.previous = tempGraphStartingVertex.previous;
            tempGraphStartingVertex.previous.next = tempVertex1;

            break;
          }
          else if(this.isPointOnPoint(loop2[0], tempGraphStartingVertex.next.vertex) && !tempGraphStartingVertex.next.isDuplicate) {
            tempVertex1 = tempVertex1.next;
            tempVertex1.previous = tempGraphStartingVertex;
            tempGraphStartingVertex.next = tempVertex1;
            break;
          }
          else if(this.isPointOnLine(loop2[0], tempGraphStartingVertex.vertex, tempGraphStartingVertex.next.vertex) && !tempGraphStartingVertex.next.isDuplicate && !tempGraphStartingVertex.isDuplicate) {
            tempVertex1.vertex = tempGraphStartingVertex.vertex;
            tempVertex1.previous = tempGraphStartingVertex.previous;
            tempGraphStartingVertex.previous.next = tempVertex1;
            break;
          }
          tempGraphStartingVertex = tempGraphStartingVertex.next;
        }
        
        let startVertexValue = tempVertex1.vertex;
        let tempVertex2 = tempVertex1;
        // First pass: set all isFirst to false and remember the last vertex with isFirst as true
        while(tempVertex2){
          if(tempVertex2.isFirst){
            tempVertex2.isFirst = false;
          }
          tempVertex2 = tempVertex2.next;
          if(tempVertex2.vertex === startVertexValue){
            break;
          }
        }
        tempVertex2.isFirst = true;
        // tempVertex2 = tempVertex2.next;
        const mergedVector3Array = [];
        tempGraphStartingVertex = tempVertex2;
        this.penToolRoofModel.startingVertex = tempVertex2;
        while(true){
          mergedVector3Array.push(new THREE.Vector3(tempGraphStartingVertex.vertex.x, tempGraphStartingVertex.vertex.y, 5));
          tempGraphStartingVertex = tempGraphStartingVertex.next;
          if(tempGraphStartingVertex.isFirst){
            break;
          }
        }
  
        return mergedVector3Array;
        
      }

      randomPointInPolygon(polygon) {
        // Calculate the bounding box of the polygon
        let minX = Infinity, minY = Infinity, minZ = Infinity;
        let maxX = -Infinity, maxY = -Infinity, maxZ = -Infinity;
        for (let point of polygon) {
            minX = Math.min(minX, point.x);
            minY = Math.min(minY, point.y);
            minZ = Math.min(minZ, point.z);
            maxX = Math.max(maxX, point.x);
            maxY = Math.max(maxY, point.y);
            maxZ = Math.max(maxZ, point.z);
        }
    
        // Generate a random point within the bounding box
        let point = new THREE.Vector2(
            minX + Math.random() * (maxX - minX),
            minY + Math.random() * (maxY - minY)
        );
    
        return point;
    }
    centerOfPolygon(polygon) {
      let sumX = 0, sumY = 0, sumZ = 0;
      let numPoints = polygon.length;
  
      for (let point of polygon) {
          sumX += point.x;
          sumY += point.y;
          sumZ += point.z;
      }
  
      let centerX = sumX / numPoints;
      let centerY = sumY / numPoints;
      let centerZ = sumZ / numPoints;
  
      let center = new THREE.Vector3(centerX, centerY, centerZ);
  
      return center;
  }

    isPointOnPoint(point1, point2, tolerance = 0.01) {
      return Math.abs(point1.x - point2.x) <= tolerance && Math.abs(point1.y - point2.y) <= tolerance;
    }
      
    getDrawFaces(){
        return this.drawFaces;
    }
    
    initDrawingMode() {
      // Initialize drawing by providing event handlers and mesh materials
      this.stage.drawManager.initialize(
          this,
          this.onComplete.bind(this),
          this.onCancel.bind(this),
      );
  }
  getAzimuth(){
    return 180;
  }
  async onComplete(geometry) {
    const vertices = [];
    for (let i = 0; i < geometry.noOfVertices; i += 1) {
        vertices.push(new THREE.Vector3(
            geometry.attributes.position.array[(i * 3)],
            geometry.attributes.position.array[(i * 3) + 1],
            geometry.attributes.position.array[(i * 3) + 2],
        ));
    }

    if(vertices.length === 0) return;
    try{
      vertices.forEach(vertex => {
        vertex.z = this.penToolRoofModel.coreHeight;
      });
      const roofVertices = [];
      const setbackVertices = !checkClockwise(vertices.map(vertex => [vertex.x, vertex.y])) ? vertices : vertices.reverse();
      const roofFace = new PenToolRoofFace(this.stage, this.penToolRoofModel, vertices, roofVertices, [], 0, this.penToolRoofModel.coreHeight, [[]], null, null,setbackVertices);
      roofFace.drawnVertices = this.drawnVertices;
      if(this.completeLoop.length === 0) this.completeLoop = vertices;
      vertices.forEach(vertex => {
        //if the vertex not in pointsMap, add it to pointsMap
        if(!this.pointsMap.has(`${vertex.x.toFixed(4)},${vertex.y.toFixed(4)}`) || this.penToolRoofModel.isNew){
          if(this.penToolRoofModel.isNew && this.pointsMap.has(`${vertex.x.toFixed(4)},${vertex.y.toFixed(4)}`)){
            const roofVertex = new FaceVertex(this.stage, roofFace, vertex,[],false,this)
            this.pointsMap.set(`${vertex.x.toFixed(4)+0.0001},${vertex.y.toFixed(4)+0.0001}`, roofVertex);
            roofVertex.zValueMap.set(roofFace.uuid, roofFace.coreHeight)
            roofVertices.push(roofVertex);
            this.penToolRoofModel.faceVertices.push(roofVertex);
          }
          else{
            const roofVertex = new FaceVertex(this.stage, roofFace, vertex,[],false,this)
            this.pointsMap.set(`${vertex.x.toFixed(4)},${vertex.y.toFixed(4)}`, roofVertex);
            roofVertex.zValueMap.set(roofFace.uuid, roofFace.coreHeight)
            roofVertices.push(roofVertex);
            this.penToolRoofModel.faceVertices.push(roofVertex);
          }
        }
        else{
          const roofVertex = this.pointsMap.get(`${vertex.x.toFixed(4)},${vertex.y.toFixed(4)}`);
          roofVertex.zValueMap.set(roofFace.uuid, roofFace.coreHeight)
          roofVertices.push(roofVertex);
          // this.penToolRoofModel.faceVertices.push(roofVertex);//    <--  look at this later
        }
      });
      this.penToolRoofModel.isNew = false;
      roofFace.roofFaceVertices = roofVertices;
      roofFace.createEdges();
      this.penToolRoofModel.roofFaces.push(roofFace);
      this.penToolRoofModel.children.push(roofFace);
      this.penToolRoofModel.updateGeometry();
      this.roofFaces.push(roofFace);
      this.updatepointsGraph(vertices);
      await this.penToolRoofModel.placeObject();
      this.penToolRoofModel.getAllSmartroofIntersections(false, [], false);
      this.penToolRoofModel.currentPentoolRoofModel = null;
      this.penToolRoofModel.nextPentoolRoofModel = null;
      this.firstAndLastPointsOnLoop = false;
      serverBus.$emit('drawPenToolComplete');
      // serverBus.$emit('completeButtonToggleFalseFlag');
      return Promise.resolve(true);

    }
    catch(e){
      console.log('e: ', e);
      return Promise.reject(e);
    }
  }

  async generateAutomatedRoofFace(vertices) {
    if (vertices.length === 0) return;
    this.removeCollinearVertices(vertices);
  
    try {
      this.setVerticesHeight(vertices);
      const roofVertices = [];
      const setbackVertices = this.getSetbackVerticesAuto(vertices);
      const roofFace = this.createRoofFace(vertices, roofVertices, setbackVertices);
  
      this.updateCompleteLoop(vertices);
      await this.processVertices(vertices, roofFace, roofVertices);
  
      this.finalizeFaceCreation(roofFace, roofVertices);
      await this.updateModelAndStage(vertices);
  
      return true;
    } catch (error) {
      console.error('Error in generateAutomatedRoofFace:', error);
      throw error;
    }
  }

  removeCollinearVertices(vertices, tolerance = 0.5) {
    //change tolerance later
    if (!vertices || vertices.length < 3) {
        return;
    }
    // Remove collinear points
    let collinearFlag = true;
    while (collinearFlag) {
        collinearFlag = false;
        for (let i = 0; i < vertices.length; i += 1) {
            const vertex = vertices[i];
            const vertexNext = vertices[(i + 1) % vertices.length];
            const vertexPrev =
                vertices[(i - 1 + vertices.length) % vertices.length];
            if (
                utils.checkCollinear(
                    vertex,
                    vertexNext,
                    vertexPrev,
                    tolerance
                )
            ) {
                vertices.splice(i, 1);
                i -= 1;
            }
        }
    }
  }
  
  setVerticesHeight(vertices) {
    vertices.forEach(vertex => {
      vertex.z = this.penToolRoofModel.coreHeight;
    });
  }
  
  getSetbackVerticesAuto(vertices) {
    return !checkClockwise(vertices.map(vertex => [vertex.x, vertex.y])) 
      ? vertices 
      : vertices.reverse();
  }
  
  createRoofFace(vertices, roofVertices, setbackVertices) {
    const roofFace = new PenToolRoofFace(
      this.stage,
      this.penToolRoofModel,
      vertices,
      roofVertices,
      [],
      0,
      this.penToolRoofModel.coreHeight,
      [[]],
      null,
      null,
      setbackVertices
    );
    roofFace.drawnVertices = this.drawnVertices;
    return roofFace;
  }
  
  updateCompleteLoop(vertices) {
    if (this.completeLoop.length === 0) {
      this.completeLoop = vertices;
    }
  }
  
  async processVertices(vertices, roofFace, roofVertices) {
    for (const vertex of vertices) {
      const key = `${vertex.x.toFixed(4)},${vertex.y.toFixed(4)}`;
      
      if (!this.pointsMap.has(key) || this.penToolRoofModel.isNew) {
        await this.handleNewVertex(vertex, roofFace, roofVertices, key);
      } else {
        await this.handleExistingVertex(vertex, roofFace, roofVertices, key);
      }
    }
    this.penToolRoofModel.isNew = false;
  }
  
  async handleNewVertex(vertex, roofFace, roofVertices, key) {
    const roofVertex = new FaceVertex(this.stage, roofFace, vertex, [], false, this);
    roofVertex.zValueMap.set(roofFace.uuid, roofFace.coreHeight);
    
    if (this.penToolRoofModel.isNew && this.pointsMap.has(key)) {
      const newKey = `${(vertex.x + 0.0001).toFixed(4)},${(vertex.y + 0.0001).toFixed(4)}`;
      this.pointsMap.set(newKey, roofVertex);
    } else {
      this.pointsMap.set(key, roofVertex);
    }
    
    roofVertices.push(roofVertex);
    this.penToolRoofModel.faceVertices.push(roofVertex);
  }
  
  async handleExistingVertex(vertex, roofFace, roofVertices, key) {
    const roofVertex = this.pointsMap.get(key);
    roofVertex.zValueMap.set(roofFace.uuid, roofFace.coreHeight);
    roofVertices.push(roofVertex);
  }
  
  finalizeFaceCreation(roofFace, roofVertices) {
    roofFace.roofFaceVertices = roofVertices;
    roofFace.createEdges();
    this.penToolRoofModel.roofFaces.push(roofFace);
    this.penToolRoofModel.children.push(roofFace);
    this.penToolRoofModel.updateGeometry();
    this.roofFaces.push(roofFace);
  }
  
  async updateModelAndStage(vertices) {
    this.updatepointsGraph(vertices);
    await this.penToolRoofModel.placeObject();
    this.penToolRoofModel.getAllSmartroofIntersections(false, [], false);
    this.penToolRoofModel.currentPentoolRoofModel = null;
    this.penToolRoofModel.nextPentoolRoofModel = null;
    this.firstAndLastPointsOnLoop = false;
  }

  onCancel() {
    // serverBus.$emit('completeButtonToggleFalseFlag');
    // //remove all the roofModels
    // this.penToolRoofModels.forEach(model => {
    //   model.removeObject();
    // });
    // // Remove from scene
    // this.stage.sceneManager.scene.remove(this.objectsGroup);
  }

  getRoofVertexFromPointsMap(vertex){
    return this.pointsMap.get(`${vertex.x.toFixed(4)},${vertex.y.toFixed(4)}`);
  }
  getColorMap() {
    const colorMapping = COLOR_MAPPINGS.POLYGON;
    if (this.materialAndVisualStatesExist(colorMapping)) {
        return colorMapping[this.materialState][this.visualState];
    }
    return {};
  }
  
  updateVisualsBasedOnStates() {
  }

  showObject() {
  }

  onSelect(){
    this.isSelected = true;
    // this.penToolRoofModel.roofFaces.forEach(face => {
    //   face.onSelect();
    // });
    this.addObjectsToDragControls();
  }

  addObjectsToDragControls() {
    this.stage.dragControls.removeAll();
    const outlinePoints = this.pointsMap.values();
    if (!this.stage.selectionControls.isMultiSelect()) {
        for (let i = 0, len = outlinePoints.length; i < len; i += 1) {
            const v = outlinePoints[i];
            this.stage.dragControls.add(
                v,
                v.moveObject.bind(v),
                v.placeObject.bind(v),
                v.handleDragStart.bind(v),
                v.handleDragCancel.bind(v),
            );
        }
    }
  }

  deSelect(){
  }
  get2DVertices(){
    let vertices = [];
    for (let point of this.pointsMap.values()) {
        vertices.push(new THREE.Vector2(point.vertex.x, point.vertex.y));
    }
    return vertices;
  }
  getSnapVertices(){
    let vertices = [];
    for (let point of this.pointsMap.values()) {
        vertices.push([point.vertex.x, point.vertex.y]);
    }
    return vertices;
  }

  getEdges() {
    const edges = [];

    this.children.forEach((child) => {
        edges.push(...child.getEdges());
    });

    return edges;   
  }

  getPlacingInformation(vertices) {
    const response = {};
    let numberOfPoints;

    // Getting vertices
    let vertices2DArray;
    if (vertices === null || vertices === undefined) {
        vertices2DArray = this.get2DVertices();
        numberOfPoints = vertices2DArray.length;
    }
    else {
        vertices2DArray = vertices;
        numberOfPoints = vertices2DArray.length - 1;
    }
    let polygonExists = true;
    response.errors = [];
    // This is the error that is displayed to the user
    response.pointUnplaceableError = null;

    const vertices2DVectorArray = utils.convertArrayToVector(vertices2DArray);
    if(numberOfPoints === 0){
      this.stage.drawManager.autoCompleteFlag = true;
      return response;
    }
    if (!raycastingUtils.areVerticesOnGround(vertices2DVectorArray, this.stage)) {
        const error = new Error(OUT_OF_GROUND_ERROR);
        response.errors.push(error);
        response.pointUnplaceableError = error;
    }
    if (vertices2DVectorArray.length > 0) {
        vertices2DArray.forEach(vertex => {
            if (this.stage.ground.patiosForModelConstraint.length > 0) this.stage.ground.patiosForModelConstraint.forEach((patio) => {
                if (utils.checkPointInsideVertices(utils.convertVectorToArray(patio.setbackVertices), vertex)) {
                    const error = new Error(DRAWING_POINT_INSIDE_PATIO_SETBACK_AREA_ERROR);
                    response.errors.push(error);
                    response.pointUnplaceableError = error;
                }
            });
        });
    }
    if (numberOfPoints <= MINIMUM_NUMBER_OF_POINTS) {
        const error = new Error(LESS_VERTICES_THAN_NEEDED_FOR_PLACING_ERROR);
        response.cannotCompleteError = error;
        response.errors.push(error);
    }
    if (numberOfPoints + 1 < MINIMUM_NUMBER_OF_POINTS) {
        const error = new Error(LESS_VERTICES_THAN_NEEDED_FOR_DRAWING_ERROR);
        response.errors.push(error);
        response.cannotCompleteError = error;
        polygonExists = false;
    }
    if (utils.checkLastEdgeIntersectionWithEdges(vertices2DVectorArray)) {
        const error = new Error(LAST_EDGE_INTERSECTION_ERROR);
        response.errors.push(error);
        polygonExists = false;
        response.pointUnplaceableError = error;
    }
    if (vertices2DArray.slice(0, numberOfPoints).length > MINIMUM_NUMBER_OF_POINTS &&
        utils.checkComplexGeometry(vertices2DArray.slice(0, numberOfPoints))) {
        const error = new Error(COMPLEX_GEOMETRY_ERROR);
        response.errors.push(error);
        response.cannotCompleteError = error;
    }
    if (utils.checkVertexEquivalency(vertices2DArray)) {
        const error = new Error(VERTEX_EQUIVALENT_ERROR);
        response.errors.push(error);
        response.pointUnplaceableError = error;
    }
    if (utils.checkIfLastVertexOnEdges(vertices2DArray)) {
        const error = new Error(VERTEX_OVER_EDGE_ERROR);
        response.errors.push(error);
        response.pointUnplaceableError = error;
    }
    // if (this.isInvalidRoof()) {
    //     const error = new Error(COMPLEX_GEOMETRY_ERROR);
    //     response.errors.push(error);
    //     response.cannotCompleteError = error;
    // }

    return response;
}
  updateWhilePlacing() {
  }
  handleVertexMove() {
    try {
      this.updatePointsMap();
      this.children.forEach((child) => {

        child.updateGeometry();
        child.getAllSmartroofIntersections();
      });
    
    }
    catch (error) {
        console.error('ERROR: DORMER: handleVertexMove: ', error);
    }
  }
  handleVertexDragStart() {
  }

  async handleVertexPlace(vertex) {
    const roofVertex = this.getRoofVertexFromPointsMap(vertex.vertex);
    if (roofVertex){
      for (const key of roofVertex.zValueMap.keys()) {
          this.penToolRoofModel.roofFaces.forEach(face => {
              if (face.uuid === key) {
                  const z = face.getZOnPlane(vertex.vertex.x, vertex.vertex.y);
                  roofVertex.zValueMap.set(key, z);
              }
            });
      }
    }
    // this.penToolRoofModel.roofFaces.forEach(face => {
    //   for(let i = 0; i < face.roofFaceVertices.length; i++){
    //     if(this.isPointOnLine(vertex.vertex, face.roofFaceVertices[i].vertex, face.roofFaceVertices[(i+1)%face.roofFaceVertices.length].vertex)){
    //       this.firstSplitEdge = [face.roofFaceVertices[(i+1)%face.roofFaceVertices.length].vertex,face.roofFaceVertices[i].vertex];
    //       this.firstPoint = vertex.vertex;
    //       this.penToolRoofModel.splitEdge();
    //       break;
    //     }
    //   }
    // });
    this.penToolRoofModel.updateSubarray();
    this.penToolRoofModel.handleVertexPlace(vertex);
    await this.penToolRoofModel.placeObject();
    this.penToolRoofModel.getAllSmartroofIntersections();
    this.penToolRoofModel.onSelect();
     // update SAP pane
     this.stage.eventManager.setObjectsSelected(this);
     // to update the mesh in the scene
     this.stage.mergeManager.mergeScene(this);
  }
  isPointOnLine(point, linePoint1, linePoint2, buffer = 0.01) {
    const x = point.x;
    const y = point.y;

    // Check if the points are not coincident
    if (linePoint1.x === linePoint2.x && linePoint1.y === linePoint2.y) {
      return false;
    }

    // Calculate the slope (m)
    const slope = (linePoint2.y - linePoint1.y) / (linePoint2.x - linePoint1.x);

    // Calculate the y-intercept (b) using one of the points
    const yIntercept = linePoint1.y - slope * linePoint1.x;

    // Calculate the distance from the point to the line
    const distance = Math.abs(slope * x - y + yIntercept) / Math.sqrt(slope * slope + 1);

    // Check if the given point lies on the line with a buffer
    const isOnLine = distance <= buffer;

    // Check if the point is between linePoint1 and linePoint2
    const isBetween = (x >= Math.min(linePoint1.x, linePoint2.x) && x <= Math.max(linePoint1.x, linePoint2.x)) &&
              (y >= Math.min(linePoint1.y, linePoint2.y) && y <= Math.max(linePoint1.y, linePoint2.y));

    return isOnLine && isBetween;
  }

  updatePointsMap(){
    // traverse through the pointsMap and update the keys
    const newPointsMap = new Map();
    this.pointsMap.forEach((value, key) => {
        const newKey = `${value.vertex.x.toFixed(4)},${value.vertex.y.toFixed(4)}`;
        newPointsMap.set(newKey, value);
    });
    this.pointsMap = newPointsMap;
  }
    getZOnTopSurface(x, y) {
      // should return 0
      if (this.penToolRoofModel.faceVertices.length === 0) {
          console.error('ERROR: Smartroof Model: has outline points null');
      }
      const v1 = this.penToolRoofModel.faceVertices[0].getPosition();
      let v2 = this.penToolRoofModel.faceVertices[1].getPosition();
      const v3 = this.penToolRoofModel.faceVertices[2].getPosition();

      v1.addScaledVector(v2, -1);
      v3.addScaledVector(v2, -1);
      v1.cross(v3);
      v2 = this.penToolRoofModel.faceVertices[1].getPosition();
      const d = -1 * ((v1.x * v2.x) + (v1.y * v2.y) + (v1.z * v2.z));
      return -1 * ((d / v1.z) + ((v1.y * y) / v1.z) + ((v1.x * x) / v1.z));
  }

}

