import * as THREE from "three";
import BaseTile from "./BaseTile";

export default class PVTile extends BaseTile {
    constructor(stage, gridCell, position, length, width) {
        super(stage, gridCell, position, length, width);
        this.updateTerminals();
        this.updateColors();
        // This is for panelMap
        // Should've ideally been assigned by the grid directly during creation
        this.id = gridCell.grid.getPanelId();
        this.convertOnOptimise = false;
    }

    updateTerminals() {
        if (!this.position) {
            return;
        }
        const terminalXOffsetFraction =
            this.gridCell.grid.terminalXOffsetFraction;
        const terminalYOffsetFraction =
            this.gridCell.grid.terminalYOffsetFraction;
        this.positiveTerminal = new THREE.Vector2(
            this.position.x + terminalXOffsetFraction * this.length,
            this.position.y + terminalYOffsetFraction * this.width
        );
        this.negativeTerminal = new THREE.Vector2(
            this.position.x - terminalXOffsetFraction * this.length,
            this.position.y + terminalYOffsetFraction * this.width
        );
    }

    updateColors() {
        this.defaultColor = this.gridCell.grid.getPVColor();
        this.highlightColor = this.gridCell.grid.getPVHighlightColor();
        this.dcColor = this.gridCell.grid.getDCColor()
    }

    saveObject() {
        const pvTileData = {
            type: PVTile.getObjectType(),
            position: [this.position.x, this.position.y, this.position.z],
            length: this.length,
            width: this.width,
            id: this.id,
        };
        return pvTileData;
    }

    convertToNonPVTile(resetDC = true) {
        // if(this.textMesh && this.textMesh.objectsGroup.visible)
            this.hideText();

        this.gridCell.convertToNonPVTile(this, resetDC);
    }

    loadObject(pvTileData) {
        this.position = new THREE.Vector3(...pvTileData.position);
        this.length = pvTileData.length;
        this.width = pvTileData.width;
        this.positiveTerminal = new THREE.Vector2(
            this.position.x +
                this.gridCell.grid.terminalXOffsetFraction * this.length,
            this.position.y +
                this.gridCell.grid.terminalYOffsetFraction * this.width
        );
        this.negativeTerminal = new THREE.Vector2(
            this.position.x -
                this.gridCell.grid.terminalXOffsetFraction * this.length,
            this.position.y +
                this.gridCell.grid.terminalYOffsetFraction * this.width
        );
        this.id = pvTileData.id ?? this.id;
        this.updateColors();
        this.updateTerminals();
        this.updateText();
    }

    static getObjectType() {
        return "PV Tile";
    }

    distanceToTile(tile) {
        return this.position.distanceTo(tile.position);
    }

    // Compatibility export interface for solar access/shadow map
    getTableMap({ withSolarAccess } = { withSolarAccess: true }) {
        const center = new THREE.Vector3(
            this.position.x,
            this.position.y,
        );
        // const instanceMatrix = new THREE.Matrix4();
        // this.instanceMesh.getMatrixAt(this.instanceIndex, instanceMatrix);
        const tilt = this.gridCell.grid.relativeTilt;
        const rotationMatrix = new THREE.Matrix4().makeRotationX((-Math.PI * tilt) / 180);

        // center.y -= this.position.y - (this.width / 2);
        // center
        //     .applyMatrix4(rotationMatrix)
        // center.y += this.position.y - (this.width / 2);
        center
            .applyMatrix4(this.gridCell.grid.matrix);

        const corners = [
            new THREE.Vector3(
                this.position.x + (this.length / 2),
                this.position.y + (this.width / 2),
            ),
            new THREE.Vector3(
                this.position.x + (this.length / 2),
                -this.width,
            ),
            new THREE.Vector3(
                this.position.x - (this.length / 2),
                -this.width,
            ),
            new THREE.Vector3(
                this.position.x - (this.length / 2),
                this.position.y + (this.width / 2),
            ),
        ];


        // Rotating bottom corners as if bottom corners lie on X-axis
        // Taking the bottom corners back to original local positions,
        // Transforming to global
        // corners[1]
        //     .applyMatrix4(rotationMatrix);
        corners[1].y += this.position.y + (this.width / 2);
        corners[1].z += this.gridCell.grid.height;
        corners[1]
            .applyMatrix4(this.gridCell.grid.matrix);

        // corners[2]
        //     .applyMatrix4(rotationMatrix);
        corners[2].y += this.position.y + (this.width / 2);
        corners[2].z += this.gridCell.grid.height;
        corners[2]
            .applyMatrix4(this.gridCell.grid.matrix);

        // Top corners are in regular local co-ordinates
        // No rotation needed
        // Apply global transform
        corners[0].z += this.gridCell.grid.height;
        corners[0]
            .applyMatrix4(this.gridCell.grid.matrix);
        corners[3].z += this.gridCell.grid.height;
        corners[3]
            .applyMatrix4(this.gridCell.grid.matrix);

        const tableMap = {
            // The actual table id is set in its parent
            id: 0,
            panels: [],
            position: {
                x: center.x,
                y: center.y,
                z: center.z,
            },
        };

        tableMap.isMoved = false;
        tableMap.hidden = false;

        const panelMap = {
            id: this.id,
            solarAccess: withSolarAccess ? this.solarAccess : 0,
            corners: corners.map(v => [v.x, v.y, v.z]),
        };
        if(panelMap.corners.length > 0) tableMap.panels.push(panelMap);
        return tableMap;
    }

    updateSolarAccess(solarAccessMap) {
        if (solarAccessMap.hasOwnProperty(this.id.toString())) {
            this.solarAccess = solarAccessMap[this.id.toString()];
            this.updateText(`${(100 * this.solarAccess.toFixed(2))}%`);
            const solarAccessColor = this.stage.solarAccessColorMap(this.solarAccess.toFixed(2)).hex();
            const color = new THREE.Color(solarAccessColor);
            this.setColor(color);
        } else {
            console.error('ERROR: Tiles: Solar access value not available');
        }
    }

    showSolarAccess() {
        if (this.solarAccess) {
            this.updateText(`${(100 * this.solarAccess.toFixed(2))}%`);
            const solarAccessColor = this.stage.solarAccessColorMap(this.solarAccess.toFixed(2)).hex();
            const color = new THREE.Color(solarAccessColor);
            this.setColor(color);
        } else {
            console.error('ERROR: Tiles: Solar access value not available');
        }
    }
}
