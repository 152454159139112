
<div class="tabsData">
  <div class="tariffDetailComp" v-if="activeName == 'TARIFF_DETAILS'">
    <div class="infoBlock">
      <p class="label">Utility Provider</p>
      <p class="textValue">{{ tariffDetails?.utility_provider }} </p>
    </div>
    <div class="infoBlock">
      <p class="label">Tariff Rate</p>
      <p class="textValue">{{ tariffDetails?.tariff_rate }} </p>
    </div>
    <div class="infoBlock">
      <p class="label">Billing Period</p>
      <p class="textValue">{{ tariffDetails?.billing_period }} </p>
    </div>
    <div class="infoBlock">
      <p class="label">Charge Period</p>
      <p class="textValue">{{ tariffDetails?.charge_period }} </p>
    </div>
    <div class="infoBlock">
      <p class="label">Does the Tariff have TOU rates?</p>
      <p class="boolValue">{{ yesToTrue(tariffDetails?.has_tou_rates) }}</p>
    </div>
    <div class="infoBlock">
      <p class="label">Does the Tariff has tiered rates?</p>
      <p class="boolValue">{{ yesToTrue(tariffDetails?.has_tiered_rates) }} </p>
    </div>
    <div class="infoBlock">
      <p class="label">Types of Charges</p>
      <p class="textValue">{{ tariffDetails?.charge_types }} </p>
    </div>
    <div class="infoBlock">
      <p class="label">Is the Tariff Solar Eligible?</p>
      <p class="boolValue">{{ yesToTrue(tariffDetails?.is_solar_eligible) }} </p>
    </div>
  </div>
  <div class="consumptionChargesComp" v-if="activeName == 'CONSUMPTION_BASED' || activeName == 'DEMAND_BASED'">
    <el-collapse accordion>
      <el-collapse-item :name="rateGroup.rate_group" v-for="rateGroup in chargeTypeData" :key="rateGroup">
        <template v-slot:title>
          <p class="tabHead">{{ rateGroup.rate_group }}</p>
        </template>
        <div class="table" v-if="rateGroup.rate_names[0].tier_ranges.length">
          <div class="row headerTable">
            <div class="cell">Rate Name</div>
            <div class="cell">Tier Range</div>
            <div class="cell">Rate Amount</div>
          </div>

          <!-- Render each row based on the structured data -->
          <div v-for="(row, index) in structuredData(rateGroup.rate_names)" :key="index" class="row">
            <div class="cell" data-label="Rate Name">{{ row.rateName }}</div>
            <div class="cell" data-label="Tier Range">{{ row.tierRange }}</div>
            <div class="cell" data-label="Rate Amount">{{ row.rate }}</div>
          </div>
        </div>
        <div class="eCBlock" v-else v-for="(row, index) in rateGroup.rate_names" :key="index">
          <p>{{ row.rate_name }}</p>
          <p>${{ row.rate }}</p>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
  <div class="fixedChargesComp" v-if="activeName == 'MINIMUM' || activeName == 'FIXED_PRICE'">
    <div class="eCBlock" v-for="rate in chargeTypeData" :key="rate">
      <p>{{ rate.rate_group_name }}</p>
      <p>${{ rate.rate }}/day</p>
    </div>
  </div>
</div>
