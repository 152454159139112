
<div class="home">
    <NavBar/>
    <main class='main-container'>
      <div class='backdrop'></div>
      <SideBar/>
      
      <ProjectListView/>
     
     
    </main>
</div>
