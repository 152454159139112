
<el-dialog
  custom-class="reviewer-dialog"
  :visible.sync="isReviewDialogVisible"
  width="800px"
  height="600px"
  title="Review us on G2"
  @close="handleClose">
  <iframe class="reviewer-iframe" :src="reviewUrl" title="Review us on G2" @load="handleLoad"></iframe>
</el-dialog>
