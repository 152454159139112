import * as THREE from 'three';
import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils';
import * as makerjs from 'makerjs';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import axios from 'axios';
import Ground from '../objects/ground/Ground';
import SmartroofFace from '../objects/model/smartroof/SmartroofFace';
import PolygonModel from "../objects/model/PolygonModel";
import CylinderModel from "../objects/model/CylinderModel";
import Subarray from "../objects/subArray/Subarray";
import Walkway from "../objects/model/Walkway";
import SafetyLine from "../objects/model/SafetyLine";
import Handrail from "../objects/model/Handrail";
import STLExporter from '../lib/STLExporter';
import ColladaExporter from '../lib/ColladaExporter';
import {
    CONVERTER_URL,
    GAZEBO_MICROINVERTER_1Q7,
    GAZEBO_MICROINVERTER_1Q8,
    GAZEBO_OBJECT,
    PANEL_ORIENTATION_PORTRAIT,
    RAFTER_ORIENTATION_PARALLEL,
    RAFTER_ORIENTATION_PERPENDICULAR,
    SUBARRAY_OBJECT,
    HYBRID_INVERTER_TYPE,
    PATIO_HEIGHT,
    EDIT_SETBACK_OUTSIDE,
    CUSTOM_INVERTER_PGPP,
} from '../coreConstants';
import Tree from '../objects/model/Tree';
import Inverter from '../objects/ac/Inverter';
import DCDB from '../objects/ac/DCDB';
import ACDB from '../objects/ac/ACDB';
import { SmartroofModel } from '../objects/model/smartroof/SmartroofModel';
import Dormer from '../objects/model/smartroof/Dormer';
import Conduit from '../objects/ac/conduits/Conduit';
import DoubleConduit from '../objects/ac/conduits/DoubleConduit';
import SingleCableTray from '../objects/ac/cableTrays/SingleCableTray';
import DoubleCableTray from '../objects/ac/cableTrays/DoubleCableTray';
import DoubleSeparateCableTray from '../objects/ac/cableTrays/DoubleSeparateCableTray';
import AcCable from '../objects/model/cable/AcCable';
import DcCable from '../objects/model/cable/DcCable';
import DoubleSeparateConduit from '../objects/ac/conduits/DoubleSeparateConduit';
import CombinerBox from '../objects/ac/CombinerBox';
import { EDIT_SETBACK_INSIDE } from '../coreConstants';
import { getSetBack, convertArrayToVector,getObstructionPointsChimney,getObstructionPointsAC, newBuffer, convertVectorToArray, getMeasurementText,getEdgeAngleWrtXaxis,getDimensionPositionFromEdge, generateSetbackGeometry, convertVectorArrayTo2DArray, outsideSetbackIntersectionWithParent, getSetbackPoints} from './utils';
import Property from '../objects/model/Property';
import TextBox from '../objects/subObjects/TextBox';
import Gazebo from '../lib/PowerGazebo';
import { forEach } from 'lodash';
import RectangleObstruction from '../objects/model/Rectangle';
import Drawface from '../objects/model/smartroof/DrawFace';
import Patio from '../objects/subArray/PowerPatio';
import { gazeboInverterIQ7PLUSId } from '../../constants';
import PenTool from '../objects/model/smartroof/PenTool';
import PenToolRoofModel from '../objects/model/smartroof/PenToolRoofModel';
import { combineSubarrayMap, isSimilarSubarrayMap } from './subarrayUtils';
import PowerRoofCombinerBox from '../objects/ac/PowerRoofCombinerBox';
import SalesModeDrawFace from '../salesModeStudio/salesModelib/salesModeDrawFace';

// TODO: replace the ds with this function where ever
// getModles is used.
export function getAllModelType() {
    return {
        polygons: [],
        smartroofs: [],
        smartroofFaces: [],
        dormers: [],
        subArrays: [],
        cylinders: [],
        walkways: [],
        safetyline: [],
        trees: [],
        inverters: [],
        combinerBox: [],
        powerRoofCombinerBox: [],
        acdb: [],
        property: [],
        handrail: [],
        acCable: [],
        dcCable: [],
        dcdb: [],
        conduits: [],
        doubleConduit: [],
        doubleSeparateConduit: [],
        singleCableTray: [],
        doubleCableTray: [],
        DoubleSeparateCableTray: [],
        textbox: [],
    };
}

export function getModels(object, result) {
    const children = object.getChildren();
    for (let i = 0, len = children.length; i < len; i += 1) {
        if (children[i] instanceof PolygonModel) {
            result.polygons.push(children[i]);
            getModels(children[i], result);
        } else if (children[i] instanceof PenToolRoofModel) {
            result.smartroofs.push(children[i]);
            getModels(children[i], result);
        } else if (children[i] instanceof SmartroofModel) {
            result.smartroofs.push(children[i]);
            getModels(children[i], result);
        } else if (children[i] instanceof Dormer) {
            result.dormers.push(children[i]);
            getModels(children[i], result);
        } else if (children[i] instanceof SmartroofFace) {
            result.smartroofFaces.push(children[i]);
            getModels(children[i], result);
        } else if (children[i] instanceof CylinderModel) {
            result.cylinders.push(children[i]);
            getModels(children[i], result);
        } else if (children[i] instanceof Walkway) {
            if (children[i] instanceof SafetyLine) {
                result.safetyline.push(children[i]);
            } else {
                result.walkways.push(children[i]);
            }
        } else if (children[i] instanceof Handrail) {
            result.handrail.push(children[i]);
        } else if (children[i] instanceof Property) {
            result.property.push(children[i]);    
        } else if (children[i] instanceof Tree) {
            result.trees.push(children[i]);
        } else if (children[i] instanceof Inverter) {
            result.inverters.push(children[i]);
        } else if (children[i] instanceof CombinerBox) {
            result.combinerBox.push(children[i]);
        } else if (children[i] instanceof PowerRoofCombinerBox) {
            result.powerRoofCombinerBox.push(children[i]);
        } else if (children[i] instanceof ACDB) {
            result.acdb.push(children[i]);
        } else if (children[i] instanceof AcCable) {
            result.acCable.push(children[i]);
        } else if (children[i] instanceof DcCable) {
            result.dcCable.push(children[i]);
        } else if (children[i] instanceof DCDB) {
            if (result.dcdb === undefined) {
                result.dcdb = [];
            }
            result.dcdb.push(children[i]);
        } else if (children[i] instanceof Subarray) {
            result.subArrays.push(children[i]);
        } else if (children[i] instanceof Conduit) {
            if (children[i] instanceof DoubleConduit) {
                result.doubleConduit.push(children[i]);
            } else if (children[i] instanceof DoubleSeparateConduit) {
                result.doubleSeparateConduit.push(children[i]);
            } else if (children[i] instanceof SingleCableTray) {
                result.singleCableTray.push(children[i]);
            } else if (children[i] instanceof DoubleCableTray) {
                result.doubleCableTray.push(children[i]);
            } else if (children[i] instanceof DoubleSeparateCableTray) {
                result.DoubleSeparateCableTray.push(children[i]);
            } else {
                result.conduits.push(children[i]);
            }
        } else if (children[i] instanceof TextBox) {
            result.textbox.push(children[i]);
        }
    }
}

export function getAllDoubleSeperteConduits(stage) {
    const result = getAllModelType();
    getModels(stage.ground, result);

    return result.doubleSeparateConduit;
}

/**
 * returns all the conduits in the scene
 * TODO: change name to all inverters
 */
export function getAllConduits(stage) {
    const result = getAllModelType();
    getModels(stage.ground, result);

    return result.conduits;
}

/**
 * returns all the inveters in the scene
 * TODO: change name to all inverters
 */
export function getInverters(stage) {
    let result = getAllModelType();
    getModels(stage.ground, result);

    return result.inverters;
}

export function getCombinerBox(stage) {
    let result = getAllModelType();
    getModels(stage.ground, result);
    return result.combinerBox;
}

export function getAllDCDBs(stage) {
    let result = getAllModelType();
    getModels(stage.ground, result);

    return result.dcdb;
}

export function getAllAcCables(stage) {
    let result = getAllModelType();
    getModels(stage.ground, result);

    return result.acCable;
}

export function getAllDcCables(stage) {
    let result = getAllModelType();
    getModels(stage.ground, result);

    return result.dcCable;
}

export function patioMapExporter(stage) {
    let patioEdgeMap = {
        placeablePatios: [],
    }

    for(let patioPlaceableEdge of stage.addAttachmentMode.irradiancePatioEdge) {
        let patioEdges = {
            id: stage.addAttachmentMode.irradiancePatioEdge.indexOf(patioPlaceableEdge),
            edges: []
        };
        for (let i = 0; i<patioPlaceableEdge.length - 1;i++){
            patioEdges.edges.push({
                points: [
                    [patioPlaceableEdge[i].x, patioPlaceableEdge[i].y, PATIO_HEIGHT],
                    [patioPlaceableEdge[i + 1].x, patioPlaceableEdge[i + 1].y, PATIO_HEIGHT],
                ],
            });
        }
        patioEdges.edges.push({
            points: [
                [patioPlaceableEdge[0].x, patioPlaceableEdge[0].y, PATIO_HEIGHT],
                [patioPlaceableEdge[patioPlaceableEdge.length - 1].x, patioPlaceableEdge[patioPlaceableEdge.length - 1].y, PATIO_HEIGHT],
            ],
        });
        patioEdges.placable = true;
        patioEdgeMap.placeablePatios.push(patioEdges);
    }
    return patioEdgeMap;
}

// TODO: Remove approximateCylinder option later
export function roofMapExporter(
    stage, { approximateCylinder } = { approximateCylinder: false }, { approximateTree } = { approximateTree: false },
) {
    const result = getAllModelType();
    getModels(stage.ground, result);

    let roofMap = {
        cylinders: [],
        polygons: [],
        walkways: [],
        safetyline: [],
        handrail: [],
        acCable: [],
        dcStrings: [],
        strings: [],
        dcCable: [],
        conduit: [],
        cableTray: [],
        microInverters: [],
        optimizers: [],
        rafters: [],
        attachments: [],
        rails: [],
        obstructions: [],
        setbacks: [],
        setbacksFrontend: [],
        setbacksOutside: [],
        property: [],
        parapets: [],
        extras: [],
        ground: [],
    };

    //for ground
    let ground= {
        height:0,
        width:0,
        vertices: [],
    }
    
    ground.height = stage.getImageDimensions().height;
    ground.width = stage.getImageDimensions().width;
    ground.vertices = stage.ground.get2DVertices();
    roofMap.ground.push(ground);

    //for polygons
    for (let polygonModel of result.polygons) {
        let polygon = {
            id: polygonModel.id,
            ignored: polygonModel.ignored,
            placable: polygonModel.placable,
            obstruction: polygonModel.obstruction,
            edges: []
        };
        let vertices = polygonModel.get3DVertices();
        let nVertices = vertices.length;
        for (let i = 0; i < nVertices - 1; i++) {
            polygon.edges.push({
                heightParapet: polygonModel.parapetHeight,
                thicknessParapet: polygonModel.parapetThickness,
                setbackInside: polygonModel.setbackInside,
                setbackOutside: polygonModel.setbackOutside,
                points: [
                    vertices[i],
                    vertices[i + 1]
                ],
            });
        }
        polygon.edges.push({
            heightParapet: polygonModel.parapetHeight,
            thicknessParapet: polygonModel.parapetThickness,
            setbackInside: polygonModel.setbackInside,
            setbackOutside: polygonModel.setbackOutside,
            points: [
                vertices[nVertices - 1],
                vertices[0]
            ],
        });
        if (polygonModel.parent instanceof SmartroofFace || (polygonModel.obstruction !== 'None')) {
            roofMap.obstructions.push(polygon);
        } else {
            roofMap.polygons.push(polygon);
        }
        /* Creating a polygon with diagonals */
        if (polygonModel instanceof PolygonModel && polygonModel.get2DVertices().length > 3) {
            let diagonals = getObstructionPointsAC(polygonModel.get2DVertices());
            let vertices = convertArrayToVector(polygonModel.get2DVertices());
            if (polygonModel.obstruction === 'AC Unit') {
                let obs = diagonals;
                obs.forEach(element => {
                    let line = {
                        edges: [],
                    };
                    line.edges.push({
                        points: [
                            element[0],
                            element[1]
                        ],
                    });
                    roofMap.extras.push(line);
                });
            } else if (polygonModel.obstruction === 'Chimney') {
                /* Creating a polygon with a circle in the center. along with cropped diagonals */
                let dist1 = vertices[0].distanceToSquared(vertices[1]);
                let dist2 = vertices[1].distanceToSquared(vertices[2]);
                let dist = dist1 < dist2 ? dist1 : dist2;
                let obstructionChimney = getObstructionPointsChimney(diagonals[0], diagonals[1], Math.sqrt(dist.toFixed(1)) / 4, vertices);
                let polygon = {
                    id: polygonModel.id,
                    ignored: polygonModel.ignored,
                    placable: polygonModel.placable,
                    obstruction: polygonModel.obstruction,
                    edges: []
                };
                for (let i = 0; i < obstructionChimney.circlePoints.length - 1; i++) {
                    polygon.edges.push({
                        points: [
                            [obstructionChimney.circlePoints[i].x, obstructionChimney.circlePoints[i].y],
                            [obstructionChimney.circlePoints[i + 1].x, obstructionChimney.circlePoints[i + 1].y]
                        ],
                    });
                }
                polygon.edges.push({
                    points: [
                        [obstructionChimney.circlePoints[obstructionChimney.circlePoints.length - 1].x, obstructionChimney.circlePoints[obstructionChimney.circlePoints.length - 1].y],
                        [obstructionChimney.circlePoints[0].x, obstructionChimney.circlePoints[0].y]
                    ],
                });
                roofMap.extras.push(polygon);
                obstructionChimney.croppedDiagonals.forEach(element => {
                    let line = {
                        edges: [],
                    };
                    line.edges.push({
                        points: [
                            [element[0].x, element[0].y],
                            [element[1].x, element[1].y]
                        ],
                    });
                    roofMap.extras.push(line);
                });
            }
        }

        /* Parapet for each polygon model */
        // chech if polygon has parapet
        if (polygonModel.isParapetPresent()) {
            let parapet = {
                edges: []
            };
            // get vertices of parapet
            let parapetVert = polygonModel.getParapet2DVertices();
            let nParaVert = parapetVert.length;
            if (nParaVert > 0) {
                for (let i = 0; i < parapetVert.length - 1; i++) {
                    parapet.edges.push({
                        points: [
                            parapetVert[i],
                            parapetVert[i + 1]
                        ],
                    });
                }
                parapet.edges.push({
                    points: [
                        parapetVert[nParaVert - 1],
                        parapetVert[0]
                    ],
                });
                roofMap.parapets.push(parapet);
            }
        }

        if (!polygonModel.isObstruction) {
            const outerVertices = polygonModel.get3DVertices().map(arr => new THREE.Vector3(...arr));
            const setbackValues = [...polygonModel.setbackInside];
            const setbacks = getSetbackForFaceFrontend(outerVertices, setbackValues);
            for (let i = 0; i < setbacks.length; i++) {
                roofMap.setbacksFrontend.push(setbacks[i]);
            }
        }

        // outer Setbacks
        if (polygonModel.setbackOutside[0] > 0.001) {
            const outerVertices = polygonModel.get3DVertices().map(arr => new THREE.Vector3(...arr));
            const setbackValues = [...(polygonModel.setbackOutside)];
            const setbackPolygon = generateSetbackGeometry(
                setbackValues,
                polygonModel.getEdges(),
                polygonModel.get2DVertices(),
                EDIT_SETBACK_OUTSIDE,
            );
            const innerPolygonPoints = polygonModel.get2DVertices();
            const setbackOutsidePoints =
                convertVectorArrayTo2DArray(setbackPolygon);
            const parentPolygonPoints = polygonModel.parent.get2DVertices();
            const setbackVertices = outsideSetbackIntersectionWithParent(
                setbackOutsidePoints,
                innerPolygonPoints,
                parentPolygonPoints,
            );

            if (!(setbackVertices instanceof Error)) {
                const setbacks = getOutsideSetbacks(setbackVertices);
                roofMap.setbacksOutside.push(setbacks);
            }

        }

    }
    
    //for dormer
    // for (let dormerModel of result.dormers) {
    //     for(let faceChild of dormerModel.getChildren()) {
    //         let faceobj = {
    //             id: faceChild.id,
    //             ignored: faceChild.ignored,
    //             placable: faceChild.placable,
    //             edges: []
    //         };
    //         let vertices = faceChild.get3DVertices();
    //         let nVertices = vertices.length;
    //         for (let i = 0; i < nVertices - 1; i++) {
    //             faceobj.edges.push({
    //                 heightParapet: 0,
    //                 thicknessParapet: 0,
    //                 setbackInside: 0,
    //                 setbackOutside: faceChild.setbackOutside,
    //                 points: [
    //                     vertices[i],
    //                     vertices[i + 1]
    //                 ],
    //             });
    //         }
    //         faceobj.edges.push({
    //             heightParapet: 0,
    //             thicknessParapet: 0,
    //             setbackInside: 0,
    //             setbackOutside: faceChild.setbackOutside,
    //             points: [
    //                 vertices[nVertices - 1],
    //                 vertices[0]
    //             ],
    //         });
    //         roofMap.polygons.push(faceobj);
    //     }
    // }

    //for smartroofFaces
    for (let smartroofFaceModel of result.smartroofFaces) {
        if (smartroofFaceModel.isValidFace()) {
            // if (!(smartroofFaceModel.parent instanceof Dormer)) {
            let smartroofFace = {
                id: smartroofFaceModel.id,
                ignored: smartroofFaceModel.ignored,
                placable: smartroofFaceModel.placable,
                edges: []
            };
            let verticesArray = smartroofFaceModel.setbackVertices;
            // let verticesArray = getOrderedEdges(smartroofFaceModel.getIntersectingEdges());
            verticesArray.forEach(vertices => {
                smartroofFace = {
                    id: smartroofFaceModel.id,
                    ignored: smartroofFaceModel.ignored,
                    placable: smartroofFaceModel.placable,
                    edges: []
                };
                let nVertices = vertices.length;
                for (let i = 0; i < nVertices; i++) {
                    const next = (i + 1) % nVertices;
                    smartroofFace.edges.push({
                        heightParapet: 0,
                        thicknessParapet: 0,
                        setbackInside: smartroofFaceModel.setbackInside,
                        setbackOutside: 0,
                        points: [
                            [vertices[i].x, vertices[i].y, vertices[i].z],
                            [vertices[next].x, vertices[next].y, vertices[next].z],
                        ],
                    });
                }

                roofMap.polygons.push(smartroofFace);
            });
            if (smartroofFaceModel.rafterEnabled) {
                const rafterLinesVertices = smartroofFaceModel.getRafterLineVertices();
                rafterLinesVertices.forEach(rafterLine => {
                    if (rafterLine.length > 0) {
                        let rafter = {
                            edges: [],
                        }
                        rafter.edges.push({
                            points: [
                                ...rafterLine
                            ]
                        })
                        roofMap.rafters.push(rafter);
                    }
                });
                const railLinesVertices = smartroofFaceModel.getRailLineVertices();
                railLinesVertices.forEach(railLine => {
                    if (railLine.length > 0) {
                        let rail = {
                            edges: [],
                        }
                        rail.edges.push({
                            points: [
                                ...railLine
                            ]
                        })
                        roofMap.rails.push(rail);
                    }
                });
                const attachmentPoints = smartroofFaceModel.getAttachmentPoints();
                attachmentPoints.forEach((point) => {
                    if (point.length > 0) {
                        let attachment = {
                            edges: [],
                        }
                        attachment.edges.push({
                            center: point[0],
                            points: [
                                ...point.slice(1)
                            ],
                        })
                        roofMap.attachments.push(attachment);
                    }
                })
            }
        }
    }
    // SETBACK 
    // faces with panels
    for (let smartroofFaceModel of result.smartroofFaces) {
        if (smartroofFaceModel.isValidFace()) {
            for (let i = 0; i < smartroofFaceModel.setbackVertices.length; i++) {
                let faceVertices = [...smartroofFaceModel.setbackVertices[i]];
                if (!smartroofFaceModel.setbackEdges[i]) {
                    continue;
                }
                let faceSetbackValue = [...smartroofFaceModel.setbackInside[i]];
                let setbacks = getSetbackForFace(faceVertices, faceSetbackValue);
                for (let i = 0; i < setbacks.length; i++) {
                    roofMap.setbacks.push(setbacks[i]);
                }
                setbacks = getSetbackForFaceFrontend(faceVertices, faceSetbackValue);
                for (let i = 0; i < setbacks.length; i++) {
                    roofMap.setbacksFrontend.push(setbacks[i]);
                }
            }
        }
    }
    //for cylinderModel
    //export cylinder as a polygon
    for (let cylinderModel of result.cylinders) {
        let cylinder = {
            id: cylinderModel.id,
            ignored: cylinderModel.ignored,
            placable: cylinderModel.placable,
            obstruction: cylinderModel.obstruction,
            edges: []
        };
        let vertices = cylinderModel.get3DVertices(undefined, { approximate: approximateCylinder });
        let nVertices = vertices.length;
        for (let i = 0; i < nVertices - 1; i++) {
            cylinder.edges.push({
                heightParapet: cylinderModel.parapetHeight,
                thicknessParapet: cylinderModel.parapetThickness,
                setbackInside: cylinderModel.setbackInside,
                setbackOutside: cylinderModel.setbackOutside,
                points: [
                    vertices[i],
                    vertices[i + 1]
                ],
            });
        }

        cylinder.edges.push({
            heightParapet: cylinderModel.parapetHeight,
            thicknessParapet: cylinderModel.parapetThickness,
            setbackInside: cylinderModel.setbackInside,
            setbackOutside: cylinderModel.setbackOutside,
            points: [
                vertices[nVertices - 1],
                vertices[0]
            ],
        });
        if (cylinderModel.parent instanceof Ground) {
            roofMap.polygons.push(cylinder);
        } else {
            roofMap.obstructions.push(cylinder);
        }

        if (cylinderModel.setbackOutside && cylinderModel.getParent() && cylinderModel.setbackOutside[0] > 0.001) {
            let innerPolygonPoints = cylinderModel.get2DVertices();
            let base3DVerticesArray = [];
            for (let point of innerPolygonPoints) {
                if (cylinderModel.getParent() instanceof PolygonModel || cylinderModel.getParent() instanceof CylinderModel) {
                    base3DVerticesArray.push([point[0], point[1], cylinderModel.getParent().getZOnTopSurface(point[0], point[1])]);
                } else {
                    base3DVerticesArray.push([point[0], point[1], 0]);
                }
            }
            let setbackOutside3DPoints = getSetbackPoints(base3DVerticesArray, cylinderModel.setbackOutside);
            let setbackOutsidePoints = convertVectorArrayTo2DArray(setbackOutside3DPoints);
            let parentPolygonPoints = cylinderModel.parent.get2DVertices();
            let setbackVertices = outsideSetbackIntersectionWithParent(setbackOutsidePoints, innerPolygonPoints, parentPolygonPoints);
            if (!(setbackVertices instanceof Error)) {
                const setbacks = getOutsideSetbacks(setbackVertices);
                roofMap.setbacksOutside.push(setbacks);
            }
        }
    }

    // for trees
    // export tree as a polygon
    for (let i = 0, len = result.trees.length; i < len; i += 1) {
        const tree = {
            id: result.trees[i].id,
            ignored: result.trees[i].ignored,
            placable: result.trees[i].placable,
            edges: [],
        };
        const vertices =
            result.trees[i].get3DVertices(
                undefined, { approximate: approximateTree }, { roofMapExport: true },
            );
        const nVertices = vertices.length;
        for (let j = 0; j < nVertices - 1; j += 1) {
            tree.edges.push({
                heightParapet: 0,
                thicknessParapet: 0,
                setbackInside: 0,
                setbackOutside: 0,
                points: [
                    vertices[j],
                    vertices[j + 1],
                ],
            });
        }
        tree.edges.push({
            heightParapet: 0,
            thicknessParapet: 0,
            setbackInside: 0,
            setbackOutside: 0,
            points: [
                vertices[nVertices - 1],
                vertices[0],
            ],
        });
        roofMap.obstructions.push(tree);
    }

    // for inverters
    // export inverter as a 
    if (stage.showInverterIn3D) {
        for (let i = 0, len = result.inverters.length; i < len; i += 1) {
            const inverter = {
                id: result.inverters[i].id,
                ignored: result.inverters[i].ignored,
                placable: result.inverters[i].placable,
                edges: [],
            };
            const vertices =
                result.inverters[i].get3DVertices();
            const nVertices = vertices.length;
            for (let j = 0; j < nVertices - 1; j += 1) {
                inverter.edges.push({
                    heightParapet: 0,
                    thicknessParapet: 0,
                    setbackInside: 0,
                    setbackOutside: 0,
                    points: [
                        vertices[j],
                        vertices[j + 1],
                    ],
                });
            }
            inverter.edges.push({
                heightParapet: 0,
                thicknessParapet: 0,
                setbackInside: 0,
                setbackOutside: 0,
                points: [
                    vertices[nVertices - 1],
                    vertices[0],
                ],
            });
            roofMap.polygons.push(inverter);
            roofMap.dcStrings.push(...result.inverters[i].getStringsCoordinates());
            roofMap.optimizers.push(...result.inverters[i].getOptimizersCoordinates());
        }
    }

    // for microinverters
    // ElectricalString.js cad export
    const microinverters = stage.ground.microInverters;
    for (let i = 0, l = microinverters.length; i < l; i += 1) {
        roofMap.microInverters.push(...microinverters[i].getMicroinvertersCoordinates());
        roofMap.dcStrings.push(...microinverters[i].getStringsCoordinates());
    }
    // for acdbs
    // export acdb as a polygon
    for (let i = 0, len = result.acdb.length; i < len; i += 1) {
        const acdb = {
            id: result.acdb[i].id,
            ignored: result.acdb[i].ignored,
            placable: result.acdb[i].placable,
            edges: [],
        };
        const vertices =
            result.acdb[i].get3DVertices();
        const nVertices = vertices.length;
        for (let j = 0; j < nVertices - 1; j += 1) {
            acdb.edges.push({
                heightParapet: 0,
                thicknessParapet: 0,
                setbackInside: 0,
                setbackOutside: 0,
                points: [
                    vertices[j],
                    vertices[j + 1],
                ],
            });
        }
        acdb.edges.push({
            heightParapet: 0,
            thicknessParapet: 0,
            setbackInside: 0,
            setbackOutside: 0,
            points: [
                vertices[nVertices - 1],
                vertices[0],
            ],
        });
        roofMap.polygons.push(acdb);
    }

    // for walkways
    for (const walkwayModel of result.walkways) {
        const walkway = {
            id: walkwayModel.id,
            associatedObstacle: walkwayModel.getParent().getId(),
            edges: [],
            length: walkwayModel.computeLength(),
        };
        const vertices = walkwayModel.get3DVertices();
        let nVertices = vertices.length;
        for (let i = 0; i < nVertices - 1; i++) {
            walkway.edges.push({
                points: [
                    vertices[i],
                    vertices[i + 1]
                ],
            });
        }
        walkway.edges.push({
            points: [
                vertices[nVertices - 1],
                vertices[0]
            ],
        });
        roofMap.walkways.push(walkway);
    }

    // for safetyline
    for (let safetyLineModel of result.safetyline) {
        const safetyLine = {
            id: safetyLineModel.id,
            associatedObstacle: safetyLineModel.getParent().getId(),
            edges: [],
            length: safetyLineModel.computeLength(),
        };
        const vertices = safetyLineModel.get3DVertices();
        let nVertices = vertices.length;
        for (let i = 0; i < nVertices; i++) {
            safetyLine.edges.push({
                points: [
                    vertices[i],
                    vertices[i + 1]
                ],
            });
        }
        roofMap.safetyline.push(safetyLine);
    }

    // for Handrail
    for (let handrailModel of result.handrail) {
        const handRail = {
            id: handrailModel.id,
            associatedObstacle: handrailModel.getParent().getId(),
            edges: [],
            length: handrailModel.getLength(),
        };
        const vertices = handrailModel.get3DVertices();
        let nVertices = vertices.length;
        for (let i = 0; i < nVertices; i++) {
            handRail.edges.push({
                points: [
                    vertices[i],
                    vertices[i + 1]
                ],
            });
        }
        roofMap.handrail.push(handRail);
    }

    // for propertyline
    for (let propertyModel of result.property) {
        const propertyline = {
            id: propertyModel.id,
            associatedObstacle: propertyModel.getParent().getId(),
            edges: [],
            length: propertyModel.getLength(),
        };
        const vertices = propertyModel.get2DVertices();
        const measurements = getMeasurementText(propertyModel);
        const edgeAnlesWrtXaxis = getEdgeAngleWrtXaxis(propertyModel);
        const dimensionPos = getDimensionPositionFromEdge(propertyModel);
        let nVertices = vertices.length;
        for (let i = 0; i < nVertices - 1; i++) {
            propertyline.edges.push({
                points: [
                    vertices[i],
                    vertices[i + 1]
                ],
                dimension: [
                    measurements[i],
                ],
                angleWrtXaxis: [
                    edgeAnlesWrtXaxis[i],
                ],
                dimensionPosition: [
                    dimensionPos[i],
                ],
            });
        }
        propertyline.edges.push({
            points:[
                vertices[nVertices - 1],
                vertices[0]
            ],
            dimension: [
                measurements[nVertices - 1],
            ],
            angleWrtXaxis: [
                edgeAnlesWrtXaxis[nVertices - 1],
            ],
            dimensionPosition: [
                dimensionPos[nVertices - 1],
            ],
        });
        roofMap.property.push(propertyline);
    }

    // for Ac Cable
    for (const acCableModel of result.acCable) {
        const accable = {
            id: acCableModel.id,
            associatedObstacle: acCableModel.getParent().getId(),
            edges: [],
            length: acCableModel.getLength(),
            cableSizeAWG: acCableModel.cableSizeAWG,
            cableSizeMM: acCableModel.cableSizeMM,
            cores: acCableModel.cores,
            materialType: acCableModel.materialType,
            wiring_unit: acCableModel.stage.designSettings.wiring_unit,
            distance_unit: acCableModel.stage.designSettings.distance_unit,
        };
        const vertices = acCableModel.get3DVertices();
        let nVertices = vertices.length;
        for (let i = 0; i < nVertices - 1; i++) {
            accable.edges.push({
                points: [
                    vertices[i],
                    vertices[i + 1]
                ],
            });
        }
        accable.edges.push({
            points: [
                vertices[nVertices - 1],
                vertices[0]
            ],
        });
        roofMap.acCable.push(accable);
    }

    // for Dc Cable
    for (const dcCableModel of result.dcCable) {
        const dccable = {
            id: dcCableModel.id,
            associatedObstacle: dcCableModel.getParent().getId(),
            edges: [],
            length: dcCableModel.getLength(),
            cableSizeAWG: dcCableModel.cableSizeAWG,
            cableSizeMM: dcCableModel.cableSizeMM,
            cores: dcCableModel.cores,
            materialType: dcCableModel.materialType,
            wiring_unit: dcCableModel.stage.designSettings.wiring_unit,
            distance_unit: dcCableModel.stage.designSettings.distance_unit,
        };
        const vertices = dcCableModel.get3DVertices();
        let nVertices = vertices.length;
        for (let i = 0; i < nVertices - 1; i++) {
            dccable.edges.push({
                points: [
                    vertices[i],
                    vertices[i + 1]
                ],
            });
        }
        dccable.edges.push({
            points: [
                vertices[nVertices - 1],
                vertices[0]
            ],
        });
        roofMap.dcCable.push(dccable);
    }

    // for Conduit
    for (const conduitt of result.conduits) {
        const conduitObj = {
            id: conduitt.id,
            associatedObstacle: conduitt.getParent().getId(),
            edges: [],
            length: 0,
            cableSizeAWG: conduitt.cableSizeAWG,
            cableSizeMM: conduitt.cableSizeMM,
            cores: conduitt.cores,
            materialType: conduitt.materialType,
            wiring_unit: conduitt.stage.designSettings.wiring_unit,
            distance_unit: conduitt.stage.designSettings.distance_unit,
        };
        const vertices = conduitt.get3DVertices();
        let nVertices = vertices.length;
        for (let i = 0; i < nVertices - 1; i++) {
            conduitObj.edges.push({
                points: [
                    vertices[i],
                    vertices[i + 1]
                ],
            });
        }
        conduitObj.edges.push({
            points: [
                vertices[nVertices - 1],
                vertices[0]
            ],
        });
        roofMap.conduit.push(conduitObj);
    }

    for (const conduitt of result.doubleConduit) {
        const conduitObj = {
            id: conduitt.id,
            associatedObstacle: conduitt.getParent().getId(),
            edges: [],
            length: 0,
            cableSizeAWG: conduitt.cableSizeAWG,
            cableSizeMM: conduitt.cableSizeMM,
            cores: conduitt.cores,
            materialType: conduitt.materialType,
            wiring_unit: conduitt.stage.designSettings.wiring_unit,
            distance_unit: conduitt.stage.designSettings.distance_unit,
        };
        const vertices = conduitt.get3DVertices();
        let nVertices = vertices.length;
        for (let i = 0; i < nVertices - 1; i++) {
            conduitObj.edges.push({
                points: [
                    vertices[i],
                    vertices[i + 1]
                ],
            });
        }
        conduitObj.edges.push({
            points: [
                vertices[nVertices - 1],
                vertices[0]
            ],
        });
        roofMap.conduit.push(conduitObj);
    }

    for (const conduitt of result.doubleSeparateConduit) {
        const conduitObj = {
            id: conduitt.id,
            associatedObstacle: conduitt.getParent().getId(),
            edges: [],
            length: 0,
            cableSizeAWG: conduitt.cableSizeAWG,
            cableSizeMM: conduitt.cableSizeMM,
            cores: conduitt.cores,
            materialType: conduitt.materialType,
            wiring_unit: conduitt.stage.designSettings.wiring_unit,
            distance_unit: conduitt.stage.designSettings.distance_unit,
        };
        const vertices = conduitt.get3DVertices();
        let nVertices = vertices.length;
        for (let i = 0; i < nVertices - 1; i++) {
            conduitObj.edges.push({
                points: [
                    vertices[i],
                    vertices[i + 1]
                ],
            });
        }
        conduitObj.edges.push({
            points: [
                vertices[nVertices - 1],
                vertices[0]
            ],
        });
        roofMap.conduit.push(conduitObj);
    }

    // for cable tray
    for (const cable of result.singleCableTray) {
        const cableObj = {
            id: cable.id,
            associatedObstacle: cable.getParent().getId(),
            edges: [],
            length: 0,
            cableSizeAWG: cable.cableSizeAWG,
            cableSizeMM: cable.cableSizeMM,
            cores: cable.cores,
            materialType: cable.materialType,
            wiring_unit: cable.stage.designSettings.wiring_unit,
            distance_unit: cable.stage.designSettings.distance_unit,
        };
        const vertices = cable.get3DVertices();
        let nVertices = vertices.length;
        for (let i = 0; i < nVertices - 1; i++) {
            cableObj.edges.push({
                points: [
                    vertices[i],
                    vertices[i + 1]
                ],
            });
        }
        cableObj.edges.push({
            points: [
                vertices[nVertices - 1],
                vertices[0]
            ],
        });
        roofMap.cableTray.push(cableObj);
    }

    for (const cable of result.doubleCableTray) {
        const cableObj = {
            id: cable.id,
            associatedObstacle: cable.getParent().getId(),
            edges: [],
            length: 0,
            cableSizeAWG: cable.cableSizeAWG,
            cableSizeMM: cable.cableSizeMM,
            cores: cable.cores,
            materialType: cable.materialType,
            wiring_unit: cable.stage.designSettings.wiring_unit,
            distance_unit: cable.stage.designSettings.distance_unit,
        };
        const vertices = cable.get3DVertices();
        let nVertices = vertices.length;
        for (let i = 0; i < nVertices - 1; i++) {
            cableObj.edges.push({
                points: [
                    vertices[i],
                    vertices[i + 1]
                ],
            });
        }
        cableObj.edges.push({
            points: [
                vertices[nVertices - 1],
                vertices[0]
            ],
        });
        roofMap.cableTray.push(cableObj);
    }

    for (const cable of result.DoubleSeparateCableTray) {
        const cableObj = {
            id: cable.id,
            associatedObstacle: cable.getParent().getId(),
            edges: [],
            length: 0,
            cableSizeAWG: cable.cableSizeAWG,
            cableSizeMM: cable.cableSizeMM,
            cores: cable.cores,
            materialType: cable.materialType,
            wiring_unit: cable.stage.designSettings.wiring_unit,
            distance_unit: cable.stage.designSettings.distance_unit,
        };
        const vertices = cable.get3DVertices();
        let nVertices = vertices.length;
        for (let i = 0; i < nVertices - 1; i++) {
            cableObj.edges.push({
                points: [
                    vertices[i],
                    vertices[i + 1]
                ],
            });
        }
        cableObj.edges.push({
            points: [
                vertices[nVertices - 1],
                vertices[0]
            ],
        });
        roofMap.cableTray.push(cableObj);
    }
    return roofMap;
}

/**
 * This function returns all the subarrays on a
 * model, traversing recursively on child models 
 * @param {*object instance of model}  
 * @param {*result the array in which subarray needs to be pushed} 
 */
export function getSubarrays(object, result) {
    for (let child of object.getChildren()) {
        if (child instanceof PolygonModel || child instanceof CylinderModel || child instanceof SmartroofModel ||
            child instanceof SmartroofFace || child instanceof Dormer) {
            getSubarrays(child, result);
        } else if (child instanceof Subarray) {
            result.push(child);
        }
    }
}

export function getRectangleObstruction(object, result) {
    const allModels = getAllModelType();
    getModels(object, allModels);
    allModels.polygons.forEach(ele => {
        if (ele instanceof RectangleObstruction && ele.obstructionType === RectangleObstruction.getObjectType()) {
            result.push(ele);
        }
    })
}

export function getPolygonObstruction(object, result) {
    const allModels = getAllModelType();
    getModels(object, allModels);
    allModels.polygons.forEach(ele => {
        if (ele.isObstruction && !(ele instanceof RectangleObstruction)) {
            result.push(ele);
        }
    })
}

export function getFlatRoofs(object, result) {
    const allModels = getAllModelType();
    getModels(object, allModels);
    allModels.polygons.forEach(ele => {
        if (ele.isObstruction == false) {
            result.push(ele);
        }
    })
}

export function getCylinderObstruction(object, result) {
    const allModels = getAllModelType();
    getModels(object, allModels);
    allModels.cylinders.forEach(ele => {
        if (ele instanceof CylinderModel) {
            result.push(ele);
        }
    })
}

export function getTreeObstructions(object, result) {
    for (let child of object.getChildren()) {
        if (child instanceof SmartroofFace || child instanceof SmartroofModel || child instanceof PolygonModel || child instanceof CylinderModel) {
            getTreeObstructions(child, result);
        } else if (child instanceof Tree) {
            result.push(child);
        }
    }
}

export function getGazebos(object, result) {
    for (let child of object.getChildren()) {
        if (child instanceof PolygonModel || child instanceof CylinderModel || child instanceof SmartroofModel ||
            child instanceof SmartroofFace || child instanceof Dormer) {
            getGazebos(child, result);
        } else if (child instanceof Gazebo && child.objectType === Gazebo.getObjectType()) {
            result.push(child);
        }
    }
}

export function getPatios(object, result) {
    for (let child of object.getChildren()) {
        if (child instanceof Patio) {
            result.push(child);
        }
    }
}

export function getPatiosExcludingModel(object, model = null) {
    const result = [];
    for (let child of object.getChildren()) {
        if (child instanceof Patio) {
            if (model) {
                if (child.attachment && child.attachment.attachedTo.uuid !== model.uuid) result.push(child);
            }
            else {
                result.push(child);
            }
        }
    }
    return result;
}

export function getPatiosExcludingCommonAttachedEdge(object, patio) {
    const result = [];
    const attachedEdge = patio.attachedPatioEdge;
    for (let child of object.getChildren()) {
        let tempAttachedEdge = child.attachedPatioEdge;
        if (child instanceof Patio && attachedEdge && tempAttachedEdge) {
            if ((child.id !== patio.id) &&
                (JSON.stringify(tempAttachedEdge[0]) !== JSON.stringify(attachedEdge[0])) &&
                (JSON.stringify(tempAttachedEdge[1]) !== JSON.stringify(attachedEdge[1]))) {
                result.push(child);
            }
        }
    }
    return result;
}

export function getOptimizerQuantity(stage) {
    const inverters = getInverters(stage);
    let optimizerCount = 0;

    for (let j = 0; j < inverters.length; j++) {
        if (inverters[j].optimizerStatus) {
            if(inverters[j].sldData) {
               optimizerCount += inverters[j].sldData.optimizerCount;
            }
            else {
                for (let i = 0; i < inverters[j].mppts.length; i++) {
                    let count = 0;
                    for (let k = 0; k < inverters[j].mppts[i].strings.length; k++) {
                        count = inverters[j].mppts[i].strings[k].linkedPanels.length;
                        optimizerCount += Math.ceil(count / inverters[j].optimizerStringLength);
                    }
                }
            }
        }
    }
    return optimizerCount;
}
export function isBifacialEnabled(panelMap){
    let index = panelMap.findIndex((item)=>{ return item.bifacialEnabled==true})
    if(index>=0)
        return true;
    else
        return false;
}
export function panelMapExporter(stage) {
    let result = [];
    getSubarrays(stage.ground, result);

    let panelMapArray = [];
    for (let subarray of result) {
        const panelMap = subarray.getSubarrayMap();
        if(panelMap && panelMap.azimuth){
            panelMap.azimuth = parseFloat(panelMap.azimuth);
        }
        if(subarray instanceof Gazebo){
            Object.assign(panelMap, {typeOf: GAZEBO_OBJECT});
        }
        else {
            Object.assign(panelMap, {typeOf: SUBARRAY_OBJECT});
        }
        // TODO: Figure out why empty rows are occuring in the first place.
        if(panelMap.rows.length > 0) panelMapArray.push(panelMap);
    }

    const tileFaces = [];
    if (stage.ground.powerRoofs.length > 0) Object.values(stage.ground.powerRoofs[0]
        .smartRoofs).forEach((smartRoof) => tileFaces.push(...smartRoof.children));
    const grids = [];
    tileFaces.forEach((face) => {
        if(face.isValidFace() && face.tilesGrid && face.tilesGrid.getPowerRoof()) {
            grids.push(face.tilesGrid);
        }
    });

    const tileMapArray = [];
    for (let grid of grids) {
        const tileMap = grid.getPanelMap();
        if(tileMap && tileMap.azimuth){
            tileMap.azimuth = parseFloat(tileMap.azimuth);
        }
        Object.assign(tileMap, {typeOf: SUBARRAY_OBJECT});
        if(tileMap.rows.length > 0) tileMapArray.push(tileMap);
    }
    panelMapArray.push(...tileMapArray);

    return panelMapArray;
}
// new panel map exporter for generation optimization
export function panelMapExporterNew(stage) {
    const panelMapArray = panelMapExporter(stage);

    const common2DSubarray = [];
    while (panelMapArray.length > 0) {
        const similarArray = [];
        const indexes = [];
        let count = 0;
        similarArray.push(panelMapArray[0]);
        panelMapArray.splice(0, 1);
        for (let i = 0; i < panelMapArray.length; i++) {
            if (isSimilarSubarrayMap(similarArray[0], panelMapArray[i])
                && panelMapArray[i] !== undefined) {
                similarArray.push(panelMapArray[i]);
                indexes.push(i);
                count++;
            }
        }
        if (indexes.length > 0) {
            for (let j = count - 1; j >= 0; j--) {
                panelMapArray.splice(indexes[j], 1);
            }
        }
        common2DSubarray.push(similarArray);
    }
    const panelMapExporterNew = [];
    for (let i =0; i< common2DSubarray.length; i++) {
        const combinedMap = combineSubarrayMap(common2DSubarray[i]);
        panelMapExporterNew.push(combinedMap);
    }
    return panelMapExporterNew;
}

export function powerMapExporter(stage, type = 'Gazebo') {

    let result = [];
    type === 'Gazebo' ? getGazebos(stage.ground,result) : getPatios(stage.ground,result);

    const structure = {}
    let map = {
        // gazebo_components: {},
        details: [],
        total_size: type === 'Gazebo' ? stage.getDcSizeForGazebos() : stage.getDcSizeForPatios(),
        system_name: undefined,
    };
    const arr = []
    for (let ele of result){
        arr.push(ele.getGazeboType());
        // structure[ele.getGazeboType().components] = (structure[ele.getGazeboType().components] || 0) + 1;
    }
    const details = [];
    arr.forEach(ele => {
        const id = ele.id;
        let index = 0;
        for(index = 0; index<details.length; index+=1){
            if(details[index].id === id){
                if(ele.hasOwnProperty('has_new_id')){
                    if (details[index] && details[index].has_new_id) {
                        details[index].count += 1;
                        details[index].inverter.push(...ele.inverter);
                        break;
                    }
                }
                else{
                    if (details[index] && !details[index].has_new_id) {
                        details[index].count += 1;
                        details[index].inverter.push(...ele.inverter);
                        break;
                    }
                }
            }
        }
        if(index===details.length){
            details.push({...ele, 'count':1});
        }
    })
    map.details = Object.values(details)
    map.details.forEach((obj) => {
        const arr = obj["inverter"];
        const elementCounts = new Map();

        // Count occurrences of elements in the 'arr' property
        arr.forEach((element) => {
            elementCounts.set(element.id, (elementCounts.get(element.id) || 0) + 1);
        });
        let allUniqueInvertersInSceneString = 'Custom - ';
        if(elementCounts.get(null)){
            const uniqueInvSet = new Set();
            const stringInvs = getInverters(stage);
            if(stringInvs.length){
                for(let ind = 0; ind < stringInvs.length; ind+=1){
                    if(!(uniqueInvSet.has(stringInvs[ind].electricalProperties.id))){
                        allUniqueInvertersInSceneString += stringInvs[ind]?.electricalProperties?.Make?.toString() 
                        + ' '+ stringInvs[ind]?.electricalProperties?.Manufacturer?.toString() + ', ';
                        uniqueInvSet.add(stringInvs[ind].electricalProperties.id);
                    }
                }
            }
            const microInvs = stage.ground.microInverters;
            if(microInvs.length){
                for(let ind = 0; ind < microInvs.length; ind+=1){
                    allUniqueInvertersInSceneString += microInvs[ind]?.electricalProperties?.Make?.toString()
                    + ' '+ microInvs[ind]?.electricalProperties?.Manufacturer?.toString() + ', ';
                }
            }
            allUniqueInvertersInSceneString = allUniqueInvertersInSceneString.substring(0, allUniqueInvertersInSceneString.length-2);
        }
        // Update 'arr' and 'count' properties in the current object
        const inverters = Array.from(elementCounts.keys());
        const inverterCounts = Array.from(elementCounts.values());

        const inverterMap = [];
        for (let i = 0; i < inverters.length; i++) {
            inverterMap.push({
                id: inverters[i],
                make: ((inverters[i] === gazeboInverterIQ7PLUSId) ? GAZEBO_MICROINVERTER_1Q7 : (inverters[i] === null) ? allUniqueInvertersInSceneString : GAZEBO_MICROINVERTER_1Q8),
                count: inverterCounts[i],
            })
        }

        obj['inverter'] = inverterMap;
    });

    const structureType = structure;
    const gazeboTotal = map.total_size;
    if (Object.keys(structureType).length > 0) {
        const defaultKey = Object.keys(structureType)[0]; 

        let values = defaultKey.split(" ");
        let l_name = values[1] ? defaultKey.substr(defaultKey.indexOf(' ') + 1) : '';
        map.system_name = Object.keys(structureType).length > 1 ? `PowerGazebo™ ${gazeboTotal} kWp`:`PowerGazebo™ ${gazeboTotal}kWp ${l_name} System`;
    }

    return map; 
}

export function getRailLengthFromAllSmartRoofs(stage) {
    const allSmartroofs = stage.ground.getChildren().filter((child) => stage.getObject(child.uuid) instanceof SmartroofModel);
    const rows =[];
    const columns =[];
    let rowWiseProps =[];
    allSmartroofs.forEach(model => {
        model.getChildren().forEach((child) => {
            let subarray = child.getChildren().filter((child) => stage.getObject(child.uuid) instanceof Subarray);
            for (let i = 0; i < subarray.length; i++) {
                if (child.rafterOrientation === RAFTER_ORIENTATION_PERPENDICULAR) {
                    subarray[i].getChildren().forEach((row) => {
                        row.endClamps = 4;
                        row.midClamps = (row.getNumberOfPanels() * 2) - 2;
                        row.panelOrientation = subarray[i].panelOrientation;
                        row.numberOfPanels = row.getNumberOfPanels();
                        rows.push(row);
                    });
                }
                else if (child.rafterOrientation === RAFTER_ORIENTATION_PARALLEL) {
                    let tempPropsColumns = child.createColumns();
                    tempPropsColumns.forEach((column) => {
                        column.endClamps = 4;
                        column.midClamps = (column.length * 2) - 2;
                        column.panelOrientation = column[0].getSubarray().panelOrientation;
                        column.numberOfPanels = column.length;
                        columns.push(column);
                    });
                }
            }
        });
    });
    // calculate rail length for rows and columns of panels
    const rowRailLength = calculateRailLength(rows, 'Row');
    const columnRailLength = calculateRailLength(columns, 'Column');
    const totalLength = rowRailLength + columnRailLength;
    rowWiseProps = getClampProps(rows, columns);
    const props = {
        totalRailLength: totalLength,
        rowWiseProps: rowWiseProps,
    };
    return props;
}

export function calculateRailLength(rowOrColumn, isRowOrColumn) {
    let totalRailLength = 0;
    if (isRowOrColumn === 'Row') {
        rowOrColumn.forEach(row => {
            let moduleLength;
            if(row.panelOrientation === PANEL_ORIENTATION_PORTRAIT) 
                moduleLength = row.getSubarray().moduleProperties.moduleWidth;
            else 
                moduleLength = row.getSubarray().moduleProperties.moduleLength;
            row.railLength = (row.getNumberOfPanels() * moduleLength * 2) + (row.midClamps * 0.0416) + (row.endClamps * 0.125);
            totalRailLength += row.railLength;
        });
    }
    else if (isRowOrColumn === 'Column') {
        rowOrColumn.forEach(column => {
            let moduleLength;
            if(column.panelOrientation === PANEL_ORIENTATION_PORTRAIT)
                moduleLength = column[0].getSubarray().moduleProperties.moduleLength;
            else 
                moduleLength = column[0].getSubarray().moduleProperties.moduleWidth;
            column.railLength = (column.length * moduleLength * 2) + (column.midClamps * 0.0416) + (column.endClamps * 0.125);
            totalRailLength += column.railLength;
        });
    }
    return totalRailLength;
}

export function getClampProps(rows, columns) {
    let temp = [];
    rows.forEach((row) => {
        let tempProps = {
            midClamps: row.midClamps,
            endClamps: row.endClamps,
            railLength: row.railLength,
            numberOfPanels: row.numberOfPanels,
        };
        temp.push(tempProps);
    });
    columns.forEach((column) => {
        let tempProps = {
            midClamps: column.midClamps,
            endClamps: column.endClamps,
            railLength: column.railLength,
            numberOfPanels: column.numberOfPanels,
        };
        temp.push(tempProps);
    });
    return temp;
}

// returns the array of inverters electrical
// components
export function inverterElectricalMapExporter(stage, panelMap) {
    const result = getAllModelType();
    getModels(stage.ground, result);

    let isStringing = false;

    const string = [];
    const hybrid = [];

    for (let inverter of result.inverters) {
        if (inverter.mppts.length > 0) {
            let electricalMap = inverter.getElectricalMap();
            if (electricalMap.electricalProperties.inverter_type == HYBRID_INVERTER_TYPE) {
                hybrid.push(electricalMap);
            } else {
                string.push(electricalMap);
            }
        }
    }

    const allPanelsArray = [];

    if (panelMap === undefined || panelMap === null) {
        panelMap = panelMapExporter(stage);
    }

    if (panelMap !== undefined) {
        for (let subarray of panelMap) {
            for (let row of subarray.rows) {
                for (let table of row.frames) {
                    if (!table.hidden) {
                        for (let panel of table.panels) {
                            allPanelsArray.push({
                                subarrayId: subarray.id,
                                panelId: panel.id,
                            });
                        }
                    }
                }
            }
        }
    }

    for (let inverter of string) {
        for (let mppt of inverter.mppts) {
            for (let string of mppt.strings) {
                for (let linkedPanelId of string.linkedPanelIds) {
                    const ppanels = allPanelsArray;
                    for (let i = 0, l = ppanels.length; i < l; i += 1) {
                        if (linkedPanelId.subarrayId === ppanels[i].subarrayId &&
                            linkedPanelId.panelId === ppanels[i].panelId) {
                            linkedPanelId.shadowMapId = i + 1;
                            isStringing = true;
                        }
                    }
                }
            }
        }
    }

    const micro = [];
    let lastShadowMapId = 0;
    for (let i = 0, l = stage.ground.microInverters.length; i < l; i += 1) {
        const panels = [];
        let allPanelsAreForPG = true;
        for (let j = 0, len = stage.ground.microInverters[i].panels.length; j < len; j += 1) {
            const panel = {
                subarrayId: stage.ground.microInverters[i].panels[j].getSubarray().getId(),
                panelId: stage.ground.microInverters[i].panels[j].getId(),
            }
            for (let i = 0, l = allPanelsArray.length; i < l; i += 1) {
                if (panel.subarrayId === allPanelsArray[i].subarrayId &&
                    panel.panelId === allPanelsArray[i].panelId) {
                    panel.shadowMapId = i + 1;
                    lastShadowMapId = i + 1;
                }
            }
            panels.push(panel);
            if(!(stage.ground.microInverters[i].panels[j].getSubarray() instanceof Gazebo && stage.ground.microInverters[i].panels[j].getSubarray().inverterType!==CUSTOM_INVERTER_PGPP)) allPanelsAreForPG = false;
        }
        let typeOf = SUBARRAY_OBJECT;
        if(allPanelsAreForPG) typeOf = GAZEBO_OBJECT;
        micro.push({
            panels,
            stringLength: stage.ground.microInverters[i].stringLength,
            microInverterCount: Math.ceil(panels.length / stage.ground.microInverters[i].stringLength),
            inverterDatabaseId: stage.ground.microInverters[i].electricalProperties.id,
            make: stage.ground.microInverters[i].getInverterMake().concat(" ",stage.ground.microInverters[i].getInverterManufacturer()),
            typeOf: typeOf,
        });
    }

    const dummyInverter = {
        stringLength: 1,
        id: 6295,
        make: "IQ7A-72-2-INT Enphase Energy",
    };
    const tileFaces = [];
    if (stage.ground.powerRoofs.length > 0) Object.values(stage.ground.powerRoofs[0]
        .smartRoofs).forEach((smartRoof) => tileFaces.push(...smartRoof.children));
    const grids = [];
    tileFaces.forEach((face) => {
        if(face.isValidFace() && face.tilesGrid && face.tilesGrid.pvEnabled) {
            grids.push(face.tilesGrid);
        }
    });

    const tiles = [];
    for (let grid of grids) {
        grid.gridCellRows
            .forEach((row) => {
                row.forEach((gridCell) => {
                    if(gridCell.isPV) {
                        const tile = {
                            subarrayId: grid.id,
                            panelId: gridCell.tiles[0].id,
                            shadowMapId: lastShadowMapId + 1,
                        };
                        lastShadowMapId++;
                        tiles.push(tile);
                    }
                });
            });
    }
    if (tiles.length) {
        micro.push({
            panels: tiles,
            stringLength: dummyInverter.stringLength,
            microInverterCount: tiles.length / dummyInverter.stringLength,
            inverterDatabaseId: dummyInverter.id,
            make: dummyInverter.make,
            typeOf: SUBARRAY_OBJECT,
        });
    }

    const gazebos = [];
    const gazeboMicros = [];
    getGazebos(stage.ground, gazebos);
    for (let i = 0, l = gazebos.length; i < l; i += 1) {
        if(gazebos[i].inverterType !== CUSTOM_INVERTER_PGPP) gazeboMicros.push({...gazebos[i].getInverterData(),typeOf: GAZEBO_OBJECT});
    }

    for (let i = 0, l = gazeboMicros.length; i < l; i += 1) {
        for (let j = 0, l = gazeboMicros[i].panels.length; j < l; j += 1) {
            gazeboMicros[i].panels[j].shadowMapId = ++lastShadowMapId;
        }
    }

    micro.push(...gazeboMicros);

    const patios = [];
    const patioMicros = [];
    getPatios(stage.ground, patios);
    for (let i = 0, l = patios.length; i < l; i += 1) {
        if(patios[i].inverterType !== CUSTOM_INVERTER_PGPP) patioMicros.push({...patios[i].getInverterData(),typeOf: GAZEBO_OBJECT});
    }

    for (let i = 0, l = patioMicros.length; i < l; i += 1) {
        for (let j = 0, l = patioMicros[i].panels.length; j < l; j += 1) {
            patioMicros[i].panels[j].shadowMapId = ++lastShadowMapId;
        }
    }

    micro.push(...patioMicros);

    const central = [];

    // IMPORTANT: If making any changes here, changes need to be made in the backend generation as well.
    return {
        micro,
        string,
        hybrid,
        central,
        isStringing,
    };
}

/* ####### SETBACK FOR CAD #########
    -> Setback for the model is stored in three arrays  
    - edges , outerEdges
    - zeroSetback
    -> Edges and outer edges store the faces without any zero setback 
    where outer setback is the associated edge with each setback
    -> Zerosetback has all the faces with zero setback, each closed polygon for setback is pushed seperately
    
    PS: This is being done as Zero Setbacks are being handled in the backend in a different manner.
*/
export function getSetbackForFace(faceVertices, faceSetbackValue) {
    let result = []
    // make object structure
    let setback = {
        edges: [],
        outerEdges: [],
        zeroSetback: [],
    };
    let edgeSetbackInsideValues = [...faceSetbackValue];
    let completeGeom = true;

    for (let i = 0; i < edgeSetbackInsideValues.length; i++) {
        if (edgeSetbackInsideValues[i] <= 0.001) {
            completeGeom = false;
            edgeSetbackInsideValues[i] = -0.001;
        }
    }

    // check if array exists
    if (!completeGeom) {
        // push all faces having zerosetback 
        let setbackPolygon = getSetBack(
            edgeSetbackInsideValues,
            faceVertices,
            EDIT_SETBACK_INSIDE,
        );
        for (let j = 0; j < setbackPolygon.length; j++) {
            setback = {
                edges: [],
                outerEdges: [],
                zeroSetback: [],
            };
            // push all points of the closed polygon
            for (let i = 0; i < setbackPolygon[j].length - 1; i++) {
                setback.zeroSetback.push({
                    points: [
                        [setbackPolygon[j][i].x, setbackPolygon[j][i].y],
                        [setbackPolygon[j][i + 1].x, setbackPolygon[j][i + 1].y]
                    ],
                });
            }
            if (!(setback.edges.length === 0 && setback.outerEdges.length === 0 && setback.zeroSetback.length === 0)) {
                result.push(setback);
            }
        }
    }
    // push all setback for normal faces i.e. non zero setback
    else {
        let indsideSetbackPolygon = newBuffer(
            edgeSetbackInsideValues,
            convertVectorToArray(faceVertices),
            EDIT_SETBACK_INSIDE,
        );
        for (let i = 0; i < indsideSetbackPolygon.length; i++) {
            let insideHole = [];
            for (let j = 0; j < indsideSetbackPolygon[i].length - 1; j++) {
                //this creates the inner boundary
                insideHole.push({
                    points: [
                        [indsideSetbackPolygon[i][j].x, indsideSetbackPolygon[i][j].y],
                        [indsideSetbackPolygon[i][j + 1].x, indsideSetbackPolygon[i][j + 1].y]
                    ],
                });
            }
            let n = indsideSetbackPolygon[i].length - 1;
            insideHole.push({
                points: [
                    [indsideSetbackPolygon[i][n].x, indsideSetbackPolygon[i][n].y],
                    [indsideSetbackPolygon[i][0].x, indsideSetbackPolygon[i][0].y]
                ],
            });
            setback.edges.push({hole : insideHole});
        }
        for (let i = 0; i < faceVertices.length - 1; i++) {
            // this creates the outer boundary
            setback.outerEdges.push({
                points: [
                    [faceVertices[i].x, faceVertices[i].y],
                    [faceVertices[i + 1].x, faceVertices[i + 1].y]
                ],
            });
        }
        let n = faceVertices.length - 1;
        setback.outerEdges.push({
            points: [
                [faceVertices[n].x, faceVertices[n].y],
                [faceVertices[0].x, faceVertices[0].y]
            ],
        });

        // push first point to last
        // setback.outerEdges.push(setback.outerEdges.shift());
        if(!(setback.edges.length === 0 && setback.outerEdges.length === 0 && setback.zeroSetback.length === 0)){
            result.push(setback);
        }
    }
    return result;
}

export function getSetbackForFaceFrontend(faceVertices, faceSetbackValue) {
    const result = [];
    // make object structure
    let setback = {
        edges: [],
        outerEdges: [],
        zeroSetback: [],
    };
    const edgeSetbackInsideValues = [...faceSetbackValue];

    for (let i = 0; i < edgeSetbackInsideValues.length; i++) {
        if (edgeSetbackInsideValues[i].toFixed(3) <= 0.001) {
            edgeSetbackInsideValues[i] = -0.001;
        }
    }
    // push all faces having zerosetback
    const setbackPolygon = getSetBack(
        edgeSetbackInsideValues,
        faceVertices,
        EDIT_SETBACK_INSIDE,
        true,
    );
    for (let j = 0; j < setbackPolygon.length; j++) {
        setback = {
            edges: [],
            outerEdges: [],
            zeroSetback: [],
        };
        // push all points of the closed polygon
        const outerPoints = setbackPolygon[j].shell;
        for (let i = 0; i < outerPoints.length - 1; i++) {
            setback.outerEdges.push({
                points: [
                    [outerPoints[i].x, outerPoints[i].y],
                    [outerPoints[i + 1].x, outerPoints[i + 1].y],
                ],
            });
        }

        const indsideSetbackPolygon = setbackPolygon[j].holes;
        for (let i = 0; i < indsideSetbackPolygon.length; i++) {
            const insideHole = [];
            for (let k = 0; k < indsideSetbackPolygon[i].length - 1; k++) {
                // this creates the inner boundary
                insideHole.push({
                    points: [
                        [indsideSetbackPolygon[i][k].x, indsideSetbackPolygon[i][k].y],
                        [indsideSetbackPolygon[i][k + 1].x, indsideSetbackPolygon[i][k + 1].y],
                    ],
                });
            }
            const n = indsideSetbackPolygon[i].length - 1;
            insideHole.push({
                points: [
                    [indsideSetbackPolygon[i][n].x, indsideSetbackPolygon[i][n].y],
                    [indsideSetbackPolygon[i][0].x, indsideSetbackPolygon[i][0].y],
                ],
            });
            setback.edges.push({ hole: insideHole });
        }

        if (!(
            setback.edges.length === 0 &&
            setback.outerEdges.length === 0 &&
            setback.zeroSetback.length === 0
        )) {
            result.push(setback);
        }
    }

    return result;
}

export function getOutsideSetbacks(setbackVertices) {
    const setbacks = {
        edges: [],
        outerEdges: [],
        zeroSetback: [],
    };

    if (!Array.isArray(setbackVertices) || setbackVertices.length < 2 || 
        !Array.isArray(setbackVertices[0]) || !Array.isArray(setbackVertices[1])) {
        return setbacks;
    }

    // Process outer edges
    setbackVertices[0].forEach((polygon, i) => {
        if (Array.isArray(polygon) && polygon.length > 0) {
            const outsidePolygon = [];
            for (let j = 0; j < polygon.length; j++) {
                const currentPoint = polygon[j];
                const nextPoint = polygon[(j + 1) % polygon.length];
                if (Array.isArray(currentPoint) && Array.isArray(nextPoint) &&
                    currentPoint.length >= 2 && nextPoint.length >= 2) {
                    outsidePolygon.push({
                        points: [currentPoint, nextPoint],
                    });
                }
            }
            if (outsidePolygon.length > 0) {
                setbacks.edges.push({outside: outsidePolygon});
            }
        }
    });

    // Process inner edges
    setbackVertices[1].forEach((innerPolygons, i) => {
        if (Array.isArray(innerPolygons) && innerPolygons.length > 0) {
            const firstInnerPolygon = innerPolygons[0];
            if (Array.isArray(firstInnerPolygon) && firstInnerPolygon.length > 0) {
                const insidePolygon = [];
                for (let j = 0; j < firstInnerPolygon.length; j++) {
                    const currentPoint = firstInnerPolygon[j];
                    const nextPoint = firstInnerPolygon[(j + 1) % firstInnerPolygon.length];
                    if (Array.isArray(currentPoint) && Array.isArray(nextPoint) &&
                        currentPoint.length >= 2 && nextPoint.length >= 2) {
                        insidePolygon.push({
                            points: [currentPoint, nextPoint],
                        });
                    }
                }
                if (insidePolygon.length > 0) {
                    setbacks.outerEdges.push({inside: insidePolygon});
                }
            }
        }
    });

    return setbacks;
}

export function getTimeStamp() {
    const time = new Date();
    const date = time.getDate();
    const month = time.getMonth() + 1;
    const year = time.getFullYear();
    const hrs = time.getHours();
    const mins = time.getMinutes();

    return `{${date < 10 ? '0' : ''}${date}-${month < 10 ? '0' : ''}${month}-${year} ${hrs < 10 ? '0' : ''}${hrs}-${mins < 10 ? '0' : ''}${mins}}`;
}

export async function DXFExport(SLDModel, designName) {
    const timeStamp = getTimeStamp();
    const dxfString = makerjs.exporter.toDXF(SLDModel, { fontSize: 2 });
    const blobData = new Blob([dxfString], { type: 'text/plain' });
    try {
        saveAs(blobData, `${designName}_sld_${timeStamp}.dxf`);
        return Promise.resolve(true);
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function STLExport(stage) {
    const exporter = new STLExporter();

    const objects = stage.ground.exportAsSTL();

    const rootZip = new JSZip();
    const rootName = stage.eventManager.getProjectDesignName();
    const designFolder = rootZip.folder(rootName);

    // TODO: Remove this backward compatibility after 6 months on 01/01/2020
    const objectNames = [];
    let counter = 0;
    for (let i = 0; i < objects.length; i += 1) {
        if (objectNames.includes(objects[i].name)) {
            objects[i].name += String(counter += 1);
        }
        objectNames.push(objects[i].name);
        designFolder.file(`${objects[i].name}.stl`, new Blob([exporter.parse(objects[i].mesh)], { type: 'text/plain' }));
    }

    try {
        const content = await rootZip.generateAsync({ type: 'blob' });
        saveAs(content, `${rootName}.zip`);
        return Promise.resolve(true);
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function ColladaExport(stage) {
    const exporter = new ColladaExporter();
    const designName = stage.eventManager.getProjectDesignName();
    const designs = [];

    const object = stage.ground.exportAsCollada();

    const singleGeometry = BufferGeometryUtils
        .mergeGeometries(object.subarray.map((child) => {
            child.updateMatrix();
            return child.geometry;
        }));

    const subArraysMesh = new THREE.Mesh(
        singleGeometry,
        new THREE.MeshLambertMaterial({
            color: 0x0062A3,
            transparent: false,
        }),
    );
    subArraysMesh.name = 'Subarrays';

    // to maintain directions
    // TODO: remove after fixing orbit controls and coordinate system.
    object.model.rotateOnWorldAxis(new THREE.Vector3(0, 0, 1), Math.PI);
    subArraysMesh.rotateOnWorldAxis(new THREE.Vector3(0, 0, 1), Math.PI);

    designs.push(object.model);
    designs.push(subArraysMesh);

    const files = [];
    for (let i = 0; i < designs.length; i += 1) {
        const blobData = new Blob([exporter.parse(designs[i]).data], { type: 'text/plain' });
        const file = new File([blobData], `${designName}_${designs[i].name}.dae`);
        files.push(file);
    }

    try {
        let form = new FormData();
        form.append('file', files[0]);
        const converterResponseModel = await axios.post(
            CONVERTER_URL,
            form, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
        );

        form = new FormData();
        form.append('file', files[1]);

        const converterResponseSubarray = await axios.post(
            CONVERTER_URL,
            form, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
        )
        fetch(converterResponseSubarray.data.file).then((url) => {
            return url.blob()}).then((blob) =>{
            saveAs(blob, `${designName}_${designs[1].name}.3ds`);
        });
        fetch(converterResponseModel.data.file).then((url) => {
            return url.blob()}).then((blob) =>{
            saveAs(blob, `${designName}_${designs[0].name}.3ds`);
        });

        // saveAs(converterResponseModel.data.file, `${designName}_${designs[0].name}.3ds`);
        // saveAs(converterResponseSubarray.data.file, `${designName}_${designs[1].name}.3ds`);

        return Promise.resolve(true);
    } catch (error) {
        console.error('ERROR: Exporters: PVsyst export unsuccessful.', error);
        return Promise.reject(error);
    }
}