import axios from 'axios';

export default {

    FETCH_ORGANISATION(organisationId) {
        return axios.get(`/api/organisations/${organisationId}/`);
    },

    FETCH_PROPOSAL_TEMPLATES() {
        return axios.get(`/api/proposal-templates/`);
    },

    PATCH_PROPOSAL_TEMPLATES(templateId, patchData) {
        return axios.patch(`/api/proposal-templates/${templateId}/`, patchData);
    },
    
    async PATCH_ORGANISATION_SETTINGS(organisationId, patchData) {
        let resp = await axios.patch(`/api/organisations/${organisationId}/`, patchData);
        if (resp.data) {
            // Update local storage whenever organisation data is updated.
            localStorage.setItem('organisation', JSON.stringify(resp.data));
        }
        return resp;
    },

    FETCH_ALLOCATED_QUOTA() {
        return axios.get('/api/quota/allocated-quota/');
    },
    FETCH_USED_QUOTA() {
        return axios.get('/api/quota/usage/');
    },
    POST_PLAN_UPGRADATION_REQUEST() {
        // change here when request changed to post
        return axios.get('/api/quota/upgrade-plan/');
    },
    FETCH_ALL_AVAILABLE_PRICING_PLANS() {
        return axios.get('/api/quota/plan/');
    },
    FETCH_EXPERT_SERVICE_PERMISSION(organisationId) {
        return axios.get(`api/organisations/${organisationId}/`);
    },
    CREATE_TAGS(postData){
        return axios.post("/api/tags/", postData);
    },
    GET_TAGS() {
        return axios.get(`/api/tags/`);
    },
    DELETE_TAG(tagId) { 
        return axios.delete(`/api/tags/${tagId}/`);
    },
    GET_SITE_SURVEY_TEMPLATE() {
        return axios.get(`api/template/`)
    },
    GET_PROPOSAL_MEDIA() {
        return axios.get(`api/organisations/proposal_media/`)
    },
    ADD_PROPOSAL_MEDIA(payload) {
        return axios.post(`api/organisations/proposal_media/`, payload)
    },
    DELETE_PROPOSAL_MEDIA(mediaId) {
        return axios.delete(`api/organisations/proposal_media/${mediaId}/`)
    }
};
