import axios from 'axios';

export default {
    
    FETCH_ALL_PV_TILES() {
        return axios.get('/api/master-data/pv-tiles/');
    },

    FETCH_MECHANICAL_COMPONENTS(mountingType=null, componentType=null) {
        if(mountingType && componentType){
            return axios.get(`/api/master-data/mechanical-components/?mounting=${mountingType}&component=${componentType}`);
        }
        else if(mountingType){
            return axios.get(`/api/master-data/mechanical-components/?mounting=${mountingType}`);
        }
        else if(componentType){
            return axios.get(`/api/master-data/mechanical-components/?component=${componentType}`);
        }
        else {
            return axios.get(`/api/master-data/mechanical-components/`);
        }
    },

    GET_MECHANICAL_COMPONENT_DETAIL(id) {
        return axios.get(`/api/master-data/mechanical-components/${id}/`);
    },

    GET_PV_TILE_DETAILS(id) {
        return axios.get(`/api/master-data/pv-tiles/${id}/`);
    }
};