import axios from "axios";

export default {
  FETCH_CRM_DASHBOARD_DATA(orgaisationId) {
    return axios.get(`/api/organisations/${orgaisationId}/dashboard/`);
  },
  //--------------- Dashboard CRM V2 APIS----------------------

  // dahboard
  GET_DASHBOARDS() {
    return axios.get(`api/dashboard/`);
  },
  CREATE_DASHBOARD(postData) {
    return axios.post(`api/dashboard/`, postData);
  },

  GET_DASHBOARD_BY_ID(id) {
    return axios.get(`api/dashboard/${id}/`);
  },
  UPDATE_DASHBOARD_BY_ID(id, patchData) {
    return axios.patch(`api/dashboard/${id}/`, patchData);
  },
  DELETE_DASHBOARD_BY_ID(id) {
    return axios.delete(`api/dashboard/${id}/`);
  },
  COPY_DASHBOARD(id,postData) {
    return axios.post(`api/dashboard/${id}/duplicate/`,postData);
  },

  // widgets
  GET_WIDGET_BY_ID(id) {
    return axios.get(`api/widget/${id}/`);
  },

  GET_DASHBOARD_SETTINGS() {
    return axios.get(`api/dashboard/dashboard_settings/`);
  },
  CREATE_WIDGET(postData) {
    return axios.post(`api/widget/`, postData);
  },
  UPDATE_WIDGET_BY_ID(id, patchData) {
    return axios.patch(`api/widget/${id}/`, patchData);
  },

  DELETE_WIDGET_BY_ID(id) {
    return axios.delete(`api/widget/${id}/`);
  },

  GET_WIDGET_FILTER_VALUE_OPTIONS(queryParams) {
    return axios.get(`api/dashboard/search/?${queryParams}`);
  },

  // export to csv
  GET_CSV_DRILLDOWN_COLUMNS() {
    return axios.get(`/api/widget/drill_down_columns/`);
  },

  EXPORT_LEAD_DATA_CSV(id, queryParams) {
    return axios.get(
      queryParams
        ? `api/crm-lead/export_csv/?${queryParams}`
        : `api/crm-lead/export_csv/`
    );
  },
  EXPORT_TASK_DATA_CSV(id, queryParams) {
    return axios.get(
      queryParams
        ? `api/task/export_csv/?${queryParams}`
        : `api/task/export_csv/`
    );
  },
};
