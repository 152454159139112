
<div id="parentContainer" v-loading.fullscreen="isLoading">
    <el-dialog
            :visible="isPreviousRequestsPopupVisible"
            :close-on-click-modal="true"
            title="Previous Proposal Requests"
            @close="onDialogClose"
            append-to-body
          >
          <div class="container">
            <div class="backOne">
              <span v-if="!requestsData.length">
                No proposal requests
              </span>
            <div v-else class="table_section table_normal">
            <table class="data_table">
              <thead class="headerSticky">
                <tr>
                  <th>
                    <div class="data_head">
                      <span class="title_text">Proposal Request</span>
                    </div>
                  </th>
                  <th>
                    <div class="data_head">
                      <span class="title_text">Base System Price</span>
                    </div>
                  </th>
                  <th>
                    <div class="data_head">
                      <span class="title_text">Date</span>
                    </div>
                  </th>
                  <th>
                    <div class="data_head">
                      <span class="title_text">Signed/Revision Requested by</span>
                    </div>
                  </th>
                  <th>
                    <div class="data_head">
                      <span class="title_text">Action</span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody v-for="(requests, index) in requestsData" :key="index">
                <tr :class="[(requests.client_name) ? 'tr' : 'incompleteCursor']" >
                  <td class="smallScr">
                    <div class="md_head">Proposal Request</div>
                    <div class="value_type pName">
                      <div class="pNameCont">
                        
                        <div class="pNameData">
                          <h3 class="pNameHeading proposalTitle">{{ requests.design_name }}</h3>
                          <h4 v-if="requests.nameplate_dc_size" class="pNameAddress">{{requests.nameplate_dc_size}} kW</h4> <!--condition should be v-if="!essOnlyDesign" but we dont have that info right now -->
                          <h4 v-if="requests.state=='ACCEPTED'" style="color: #2cc21c" class="pNameAddress">Completed</h4>
                          <h4 v-if="requests.state=='REJECTED'" style="color: #ff0404" class="pNameAddress">Revision Requested</h4>
                          <h4 v-if="requests.state=='PENDING'" style="color: #becb0a" class="pNameAddress">Pending</h4>
                        </div>
                      </div>
                    </div>
                  </td>
                  
                  <td>
                    <div class="md_head">Price</div>
                    <div v-if="requests.total_cost==null" class="value_type ">-</div>
                    <div v-else class="value_type " style="white-space: nowrap">{{currencySymbolNameMap[currency_code] + formatPrice(requests.total_cost)}}</div>
                  </td>
                  <td class="orderOn">
                    <div class="md_head">Date</div>
                    <div class="value_type">
                      <span class="orderDate" style="white-space: nowrap">{{getFormattedDate(requests.created_at)}}</span>
                    </div>
                  </td>
                  <td>
                    <div class="md_head">Signed/Revision Requested by</div>
                    <div class="value_type pName">
                      <div class="pNameCont">
                        
                        <div class="pNameData">
                          <h3 v-if="!(requests.state=='ACCEPTED')">-</h3>
                          <h3 v-else class="pNameHeading">{{requests.client_name}}</h3>
                          <p v-if="requests.state=='ACCEPTED'" class="pNameType">{{getFormattedDate(requests.modified_at)}} | {{getFormattedTime(requests.modified_at)}}</p>
                        </div>
                      </div>
                    </div>
                  </td>
                  
        
                  <td>
                    <div class="md_head">Action</div>
                    <el-tooltip
                      :disabled="loadingState && !(referenceID==requests.reference_id)"
                      effect="dark"
                      placement="top-end"
                      :content="downloadTooltipContent"
                      :popper-class="loadingState ? 'proposal-request-download-tooltip' : ''"

                    >
                    <span>
                      <el-button :disabled="loadingState && !(referenceID==requests.reference_id)" 
                      :loading="loadingState && referenceID==requests.reference_id" @click="downloadFrontendPDF(requests)" style="border: none;">
                        <img v-if="!loadingState || !(referenceID==requests.reference_id)" 
                        src="./img/Group_2096.png" class="Group-2096">
                      </el-button >
                    </span>
                    </el-tooltip>
                  </td>
                </tr>
                <tr v-if="(requests.state=='REJECTED' && requests.reject_reason)">
                    <div class="data_head">
                      <span class="title_text">Requested Revision</span>
                    </div>
                    <div class="md_head">Requested Revision</div>
                    <div class="value_type pName">
                      <div class="pNameCont">
                        
                        <div class="pNameData">
                          <h3 v-if="!(requests.state=='REJECTED')">-</h3>
                          <h3 v-else class="pNameHeading rejectReason" v-html="requests.reject_reason"></h3>
                          
                        </div>
                      </div>
                    </div>
                </tr>
                
              </tbody>
      
            </table>
            <div v-observe-visibility="loadMoreRequests" style="text-align: center">
              <i v-if="busy" class="el-icon-loading infiniteScrollLoader" />
            </div>
          </div>
        </div>
      </div>
    </el-dialog>

</div>
