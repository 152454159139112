
    <div class="main-container">
        <el-dialog 
        :title="popupHeader"
        :visible.sync="openDrawer" 
        width="60%"
        append-to-body 
        :before-close="onCloseClick"
        :close-on-click-modal="false"
          class="dialog">
          <div class="email-dialog-conatiner">
           <div class="top-email-format">
            <!-- to -------------------------------------------------------------------------------------------->
            <div class="email-header">
                <p>
                    To   
                    <span class="required-class">
                                    *
                                </span>
                                 :
                </p>
                <div class="email-details">
                    <div v-if="fetchingData">
                        <i 
                    class="el-icon-loading" 
                     />
                    </div>
                    <div
                    v-for="(item, index) in toEmail"
                    v-else
                    :key="index"
                     class="email-details-container"
                    style="display: flex"
                    >
                    <el-tooltip
                    class="item" 
                   effect="dark"
                    :content="item"
                     placement="top-start"
                     >
                   <p class="email-send-to">{{item}}</p>
                   </el-tooltip>
                   <el-tooltip
                    class="item" 
                   effect="dark"
                    content="Remove Email"
                     placement="top-start"
                     >
                        <img
                         src="./assets/X.svg"
                         alt="close"
                         class="close_image_class"
                         @click="removeEmail(item, 'to')"
                         >
                    </el-tooltip>

                </div>
                <div>
                    <div class="email_input_new">
                    <input 
                        v-if="showEmailInputField"
                        ref="emailInput"
                        v-model="extraMail"
                        name="email"
                        type="text"
                        class="input_email"
                        :style="handleEmailError('to')"
                        :autofocus="true"
                        @focus="removeError('to')"
                        @blur="addEmail('to')"
                        @keydown="handleKeyEvents($event, 'to')"
                    >
                </div>
                </div>
      
                <el-tooltip
                    class="item" 
                   effect="dark"
                    :content="!showEmailInputField ? 'Add Email' : checkEmailValidation(extraMail) ? 'Disabled':'Remove Email'"
                     placement="top-start"
                     >
                     <div 
                     class="add-more-email"
                    :style="{ marginLeft: showEmailInputField ? '10px' : '0px' , cursor:checkEmailValidation(extraMail) ? 'not-allowed': 'pointer'}"
                     @click="addNewEmailField('to')"
                     >
                     <i 
                     v-if="!showEmailInputField"
                     class="el-icon-plus"
                     />

                     <i 
                     v-else 
                     class="el-icon-close"
                     />
                     </div>
                    </el-tooltip>

                    <div 
                        class="extra_mail_container"
                        > 
                            <p
                            v-if="!isCcVisible"
                            class="extra_mail_text"
                            @click="showExtraFields('cc')"
                            >
                            Cc
                            </p>
                            <p
                            v-if="!isBccVisible"
                            class="extra_mail_text"
                            @click="showExtraFields('bcc')"
                            >
                            Bcc
                            </p>
                       </div>
                    </div> 
            </div>
            <!-- cc----------------------------------------------------------------------------------- -->
            <div
            v-if="isCcVisible"
             class="email-header" 
            >
                <p>Cc : </p>
                <div
                v-for="(item, index) in CC"
                :key="index"
                 class="email-details-container"
                 >
                 <el-tooltip
                    class="item" 
                   effect="dark"
                    :content="item"
                     placement="top-start"
                     >
                   <p class="email-send-to">{{item}}</p>
                   </el-tooltip>
                   <el-tooltip
                    class="item" 
                   effect="dark"
                    content="Remove Email"
                     placement="top-start"
                     >
                        <img
                         src="./assets/X.svg"
                         alt="close"
                         class="close_image_class"
                         @click="removeEmail(item, 'cc')"
                         >
                    </el-tooltip>
                </div>
                <div>
                    <div class="email_input_new">
                    <input 
                        v-if="showCcInputField"
                        ref="ccInput"
                        v-model="extraCC"
                        name="email"
                        type="text"
                        class="input_email"
                        :style="handleEmailError('cc')"
                        @focus="removeError('cc')"
                        @blur="addEmail('cc')"
                        @keydown="handleKeyEvents($event,'cc')"
                    >
                </div>
                </div>
      
                <el-tooltip
                    class="item" 
                   effect="dark"
                    :content="!showCcInputField ? 'Add Email' : checkEmailValidation(extraCC) ? 'Disabled':'Remove Email'"
                     placement="top-start"
                     >
                     <div 
                     class="add-more-email"
                     :style="{ 
                        marginLeft: showCcInputField ? '10px' : '0px' , 
                     cursor:checkEmailValidation(extraCC) ? 'not-allowed': 'pointer'
                     }"
                     @click="addNewEmailField('cc')"
                     >
                     <i 
                     v-if="!showCcInputField"
                     class="el-icon-plus"
                     />

                     <i 
                     v-else 
                     class="el-icon-close"
                     />
                     </div>
                    </el-tooltip>
            </div>
            <!-- bcc----------------------------------------------------------------------------------- -->
            <div 
            v-if="isBccVisible"
            class="email-header"
            >
                <p>Bcc : </p>
                <div
                v-for="(item, index) in BCC"
                :key="index"
                 class="email-details-container"
                 >
                 <el-tooltip
                    class="item" 
                   effect="dark"
                    :content="item"
                     placement="top-start"
                     >
                   <p class="email-send-to">{{item}}</p>
                   </el-tooltip>
                   <el-tooltip
                    class="item" 
                   effect="dark"
                    content="Remove Email"
                     placement="top-start"
                     >
                        <img
                         src="./assets/X.svg"
                         alt="close"
                         class="close_image_class"
                         @click="removeEmail(item, 'bcc')"
                         >
                    </el-tooltip>
                </div>
                <div>
                    <div class="email_input_new">
                    <input 
                        v-if="showBccInputField"
                        ref="bccInput"
                        v-model="extraBCC"
                        name="email"
                        type="text"
                        class="input_email"
                        :style="handleEmailError('bcc')"
                        @focus="removeError('bcc')"
                        @blur="addEmail('bcc')"
                        @keydown="handleKeyEvents($event,'bcc')"
                    >
                </div>
                </div>
      
                <el-tooltip
                    class="item" 
                   effect="dark"
                     :content="!showBccInputField ? 'Add Email' : checkEmailValidation(extraBCC) ? 'Disabled':'Remove Email'"
                     placement="top-start"
                     >
                     <div 
                     class="add-more-email"
                     :style="{ 
                        marginLeft: showBccInputField ? '10px' : '0px' , 
                     cursor:checkEmailValidation(extraBCC) ? 'not-allowed': 'pointer'
                     }"
                     @click="addNewEmailField('bcc')"
                     >
                     <i 
                     v-if="!showBccInputField"
                     class="el-icon-plus"
                     />

                     <i 
                     v-else 
                     class="el-icon-close"
                     />
                     </div>
                    </el-tooltip>
            </div>
            <!-- subject----------------------------------------------------------------------------------- -->
            <div 
            class="email-header">
                <p style="width: 55px;">
                    Subject : 
                </p>
                <div class="input-group">
                       <input 
                       v-model="subject"
                       type="text" 
                       maxlength="100"
                       class="input-info"
                       >
                </div>
            </div>
            <!-- body------------------------------------------------------------------------------------------------------------- -->
            <div 
            class="email_body_class"
             >
                <el-input
                    v-model="body"
                    type="textarea"
                    maxlength="3000"
                    :autosize="false"
                    placeholder="Please input email description"
                    class="text_area"
                    show-word-limit
                    />
            </div>
           </div>
           <div 
           class="bottom-email-format" 
           >
            <div class="footer">
                <el-tooltip
             class="item"
             effect="dark" 
            :content=" toEmail.length>0 ? 'Save': 'Fill all the mandatory fields'"
             placement="top-start"
             >
                <el-button
                 type="primary"
                 :disabled="operationalButtonDisabilityCheck"
                 @click="operationalButton"
                 >
                    <p v-if="!loading">
                        Send
                    </p>
                    <i 
                    v-else
                    class="el-icon-loading" 
                     />
                </el-button>
                </el-tooltip>
            </div>
           </div>
          </div>
        </el-dialog>
</div>
