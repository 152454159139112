
<div class="tabsContainer">
  <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="Details" name="1">
      <div class="sections">
        <!-- <DetailsView :DetailsData="token" :orderData="orderData" /> -->
        <DetailsView :DetailsData="DetailsData" :orderData="orderData" />
        <DetailsView :DetailsData="customer" :orderData="projectData" />
        <DetailsView :DetailsData="ownerDetails" :orderData="orderData" />
        <DetailsView  v-for="(order_data, index) in orderData.components" v-bind:orderData="order_data" :key="index" :showTitle="index" :DetailsData="getDetailsData(order_data)"/>
        <DetailsView  v-for="(battery, index) in orderData.batterySpecific" v-bind:orderData="battery" :key="index" :showTitle="index" :DetailsData="batteryDetails"/>
        <DetailsView :DetailsData="ahjDetails" :orderData="projectData" />
        <DetailsView :DetailsData="additionalNotes" :orderData="orderData" />
      </div>
    </el-tab-pane>
    <el-tab-pane label="Documents" name="2" v-if="orderData.project">
      <Documents :orderData="orderData" />
    </el-tab-pane>
    <el-tab-pane label="Additional Notes" name="3" v-if="orderData.project">
      <TaskActivityTimeline :orderData="orderData"/>
    </el-tab-pane>
    <!-- <el-tab-pane label="Component Details" name="3">
      <DetailsView :DetailsData="moduleDetails" :orderData="orderData" />
    </el-tab-pane>
    <el-tab-pane label="AHJ" name="4" v-if="projectData">
      <DetailsView :DetailsData="ahjDetails" :orderData="projectData" />
    </el-tab-pane>
    <el-tab-pane label="Additional Notes" name="5" v-if="projectData">
      <DetailsView :DetailsData="additionalNotes" :orderData="orderData" />
    </el-tab-pane> -->
  </el-tabs>
</div>
