
<div :class="!isDrilldown ? 'container' : 'container-dash'">
  <div class="navContainer" @click="handleCardSelect">
    <div class="gridContainer">
        <div class="firstContainer" v-show="!showSearchInput">
          <MenuView
            :menuItems="menuData"
            :clearAll.sync="clearAll"
            :tagsClear.sync="tagsClear"
            :usersClear.sync="usersClear"
            :regionClear.sync="regionClear"
            :leadSourceClear.sync="leadSourceClear"
            :productListClear.sync="productListClear"
            :probabilityClear.sync="probabilityClear"
            :dateClear.sync="dateClear"
            :DCvalueClear.sync="DCvalueClear"
            :costClear.sync="costClear"
            :proposalStatusClear.sync="proposalStatusClear"
            @ExpectedClosure="ExpectedClosure"
            @DCValue="DCValue"
            @probabilityValue="probabilityValue"
            @systemCost="systemCost"
            @leadCheckbox="leadCheckbox"
            @regionCheckbox="regionCheckbox"
            @tagCheckbox="tagCheckbox"
            @userCheckbox='userCheckbox'
            @proposalStatusRadio="proposalStatusRadio"
            @searchUsers="searchUsers"
            @loadMore2="debounceUsersLoadMore"
            @selectedProductsUpdated="selectedProductsUpdated"
            @customCreatedDateUpdate="customCreatedDateUpdate"
            :isDrillDown.sync="isDrilldown"
          />
          <div class="pipelineMenu" v-if="isTableOptClicked && !isDrilldown">
            <template>
              <el-menu
                :default-active="activeIndex"
                class="el-menu-demo"
                mode="horizontal"
              >
                <el-submenu index="2">
                  <template v-slot:title>Pipeline</template>
                  <el-menu-item
                    v-for="option in pipelineDetails"
                    :key="option.id"
                  >
                    <el-checkbox
                      @change="tablePipelines(option.id)"
                      v-model="tablePipeline"
                      :label="option.id"
                      class="pipeline-cb-mobile"
                      >{{ option.name }}</el-checkbox
                    >
                  </el-menu-item>
                </el-submenu>
              </el-menu>
            </template>
          </div>
 
          <div class="pipeline" v-if="!isTableOptClicked && !isDrilldown">
            <el-menu
              :default-active="activeIndex"
              class="el-menu-demo"
              mode="horizontal"
              @select="handlePipeline"
            >
              <el-submenu index="1">
                <template v-slot:title >
                  <span class="pipeline-select-mobile" :style="{textOverflow: 'ellipsis', width: pipelineFieldWidth}">
                    {{
                      pipelineData.name
                    }}
                  </span>
                </template>
                <el-menu-item
                v-for="pipeline in pipelineDetails"
                  :key="pipeline.id"
                  :index="pipeline.id"
                >
                  <b-icon
                    @click="IconCheck(pipeline.id)"
                    :style="{
                      marginRight: '5px',
                      color: getIconColor(pipeline.id),
                    }"
                    :icon="getIcon(pipeline.id)"
                  ></b-icon>
                  <span class="pipeline-span-mobile">{{ pipeline.name }}</span></el-menu-item
                >
              </el-submenu>
            </el-menu>
          </div>
          <div class="relativeCont" v-show="!isMobileView">
            <p class="customize" v-if="isTableOptClicked || isDrilldown">
              Customize Table
            </p>
            <div class="customizeDropdown" v-if="showCustomizeTable">
              <p class="drpDwnHeading">Select columns to view</p>
              <div class="drpdwnFlex" id="checkColor">
                <el-checkbox-group v-model="checkedColumnsList">
                  <el-checkbox
                    v-for="(column, index) in allColumnsList"
                    :key="index"
                    :label="column"
                  >
                    {{ column }}
                  </el-checkbox>
                </el-checkbox-group>
              </div>
              <div class="applyBtnContainer">
                <el-button
                  type="primary"
                  class="applyBtn"
                  @click="applySelectedColumns()"
                  >Apply</el-button
                >
              </div>
            </div>
          </div>
        </div>
        <div :class="!isDrilldown ? 'secContainer' : 'secContainer-dash'" v-show="!showSearchInput">
          <div class="searchInpCont" v-if="!isDrilldown" @click="onClickSearch">
            <img src="./assets/search.svg" class="searchIcon" />
            <input
              type="text"
              class="searchInput"
              placeholder="Search"
              v-model="search"
            />
          </div>
          <div class="tableTypeCont" v-if="!isDrilldown">
            <el-tooltip
              class="item"
              effect="dark"
              content="Kanban View"
              placement="top"
              :hide-after="isMobileView ? 5000 : 0"
            >
              <div
                :class="['tableOpt', !isTableOptClicked ? 'activeClass' : '']"
                @click="setLeadListingView(false)"
              >
                <b-icon 
                  icon="kanban"
                  class="typeIcon"
                />
              </div>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="List View"
              placement="top"
              :hide-after="isMobileView ? 5000 : 0"
            >
              <div
                :class="['cardOpt', isTableOptClicked ? 'activeClass' : '', !isMobileView && 'list-view-icon']"
                @click="setLeadListingView(true)"
              >
                <b-icon 
                  icon="list-ul"
                  class="typeIcon"
                />
              </div>
            </el-tooltip>
          </div>
          <!-- <el-button
            v-show="!isMobileView"
            v-if="!isDrilldown"
            type="primary"
            class="createLeadBtn"
            @click="handleCreate"
            >Create Lead</el-button
          > -->

          <div class="flexBtnContainer">
            <el-button 
            type="primary" 
            class="createLeadBtn"
            v-show="!isMobileView"
            v-if="!isDrilldown"
            @click="handleCreate">Create Lead</el-button>
            <el-tooltip class="item" effect="dark" content="Bulk lead is in progress." placement="top-end" :disabled="!bulkLeadImportState.isSSEProcessing">
              <button :class="['dropdownBtn',bulkLeadImportState.showMinimizablePopup == false ? '' : 'disabledDropdownBtn' ]" @click="showDropdownOption = !showDropdownOption" :disabled="bulkLeadImportState.isSSEProcessing == true ? true: false"  v-if="isAdmin && !isDrilldown">
                <img src="../components/assets/dropdownBtnArrow.svg" />
                <div class="dropdownBtnOption" v-if="showDropdownOption" @click="importBulkLeadFunc()">
                  <p>Import Lead</p>
                </div>
            </button>
            </el-tooltip>
          </div>

          <div v-show="isMobileView" class="create-container-mobile" v-if="!isDrilldown" @click="addLeadBtnClicked = true">
            <b-icon icon="plus-lg" class="create-icon-mobile"></b-icon>
          </div>
          <el-button
            v-if="isDrilldown"
            type="primary"
            class="createLeadBtn"
            @click="downloadCsv"
            v-loading="downlodLoading"
            >Export CSV</el-button
          >
          <div
            v-if="isDrilldown"
            style="padding-left: 1rem; font-size: 1rem; cursor: pointer"
            @click="handleClose"
          >
            <img src="../../../assets/img/close.svg" alt="" />
          </div>
        </div>
      </div>
      <!--  Active Filters section  -->
      <div class="activeFilters" v-show="!showSearchInput">
        <div>
          <div class="tagsContainer" v-if="selectedSort.length > 0">
            <div>
              <img src="./assets/sort.svg" alt="alter" />
            </div>
            <div>
              <el-tag
                class="tag"
                size="medium"
                style="margin-right: 15px"
                closable
                @close="handleRemoveSort()"
              >
                {{ handleDisplaySort(selectedSort) }}
              </el-tag>
            </div>
          </div>
        </div>
        <div
          class="tagsContainer"
          v-if="
            selectedFilter.length > 0 ||
            expectClosure ||
            systemsCost ||
            dcValue ||
            probability ||
            (tags.value && tags.value.length>0) ||
            (users.value && users.value.length>0)||
            (leadSources.value && leadSources.value.length>0) ||
            (region.value && region.value.length>0) ||
            selectedProducts.length > 0  || proposalStatusValue.value
          "
        >
          <div>
            <img src="./assets/filter_alt.svg" alt="alter" />
          </div>
          <!-- <p class="actFil">Active Filters</p> -->
          <div>
            <el-tag
              v-for="(item,index) in selectedFilter"
              :key="index"
              class="tag"
              size="medium"
              closable
              :disable-transitions="false"
              @close="handleRemoveFilter(item)"
            >
              {{ handleDisplayFilters(item) }} : {{ item[2] }}
            </el-tag>

            <el-tag
              v-if="expectedClosure"
              class="tag"
              size="medium"
              closable
              :disable-transitions="false"
              @close="handleRemovestartClosure()"
            >
              {{ expectedClosure.label }} : {{ formattedDate }}
            </el-tag>

            <el-tag
              v-if="proposalStatus"
              class="tag"
              size="medium"
              closable
              :disable-transitions="false"
              @close="handleRemoveProposalStatus()"
            >
              {{ proposalStatusValue.label }} : {{ proposalStatusValue.value }}
            </el-tag>


            <el-tag
              v-if="dcValue?.value"
              class="tag"
              size="medium"
              closable
              :disable-transitions="false"
              @close="handleDCremove()"
            >
              {{ dcValue.label }} :
              {{ `${dcValue.value[1]}kW - ${dcValue.value[0]}kW` }}
            </el-tag>
            <el-tag
              v-if="probability?.value"
              class="tag"
              size="medium"
              closable
              :disable-transitions="false"
              @close="handleProbabilityRemove()"
            >
              {{ probability.label }} :
              {{ `${probability.value[1]}% - ${probability.value[0]}%` }}
            </el-tag>

            <el-tag
              v-if="systemsCost?.value"
              class="tag"
              size="medium"
              closable
              :disable-transitions="false"
              @close="handleCostremove()"
            >
              {{ systemsCost.label }} :
              {{
                `${isUSFlagEnabled ? "$" : "₹"}${systemsCost.value[1]} - ${
                  isUSFlagEnabled ? "$" : "₹"
                }${systemsCost.value[0]}`
              }}
            </el-tag>
             <el-tooltip v-if="leadSources.value && leadSources.value.length>0" placement="bottom" effect="dark">
              <template v-slot:content>
              <div>
                <p
                  v-for="(leadSource, index) in leadSources.value"
                  :key="index"
                >
                  {{ displayLeadSource(leadSource)}}
                </p>
              </div>
              </template>
              <el-tag class="tag" closable @close="handleLeadremove()">
                {{ leadSources.label }}  <span v-if="leadSources.value.length==1" >:  {{ displayLeadSource(leadSources.value[0]) }}</span>
                <span v-else class="tag-value">{{ leadSources.value.length }}</span>
              </el-tag>
            </el-tooltip>
             <el-tooltip v-if="selectedProducts.length>0" placement="bottom" effect="dark">
              <template v-slot:content>
              <div>
                <p
                  v-for="(product, index) in selectedProducts"
                  :key="index"
                >
                  {{ product.name }}
                </p>
              </div>
              </template>
              <el-tag class="tag" closable @close="handleRemoveProductFilters()">
                {{ 'Products' }}  <span v-if="selectedProducts.length==1" >:  {{ selectedProducts[0].name }}</span>
                <span v-else class="tag-value">{{ selectedProducts.length }}</span>
              </el-tag>
            </el-tooltip>
            <el-tooltip v-if="region.value && region.value.length>0" placement="bottom" effect="dark">
              <template v-slot:content>
              <div>
                <p
                  v-for="(regions,index) in region.value"
                  :key="index"
                >
                  {{ displayRegion(regions)}}
                </p>
              </div>
              </template>
              <el-tag class="tag" closable @close="handleRegionRemove()">
                {{ region.label }}  <span v-if="region.value.length==1" >:  {{ displayRegion(region.value[0]) }}</span>
                <span v-else class="tag-value">{{ region.value.length }}</span>
              </el-tag>
            </el-tooltip>

            <el-tooltip  v-if="tags.value && tags.value.length>0" placement="bottom" effect="dark">
              <template v-slot:content>
                <div> <p class="filterTag" v-for="(tag, index) in tags.value" :key="index">{{ tag }}</p></div>
              </template>
              <el-tag
                class="tag"
                closable
                :disable-transitions="false"
                @close="handleTagremove()"
              >
                {{ tags.label }} <span v-if="tags.value.length==1" >:  {{ tags.value[0] }}</span>  <span v-else class="tag-value">{{ tags.value.length }}</span>
              </el-tag>
            </el-tooltip>
            <el-tooltip  v-if="users.value && users.value.length>0" placement="bottom" effect="dark">
              <template v-slot:content>
              <div> <p class="filterTag" v-for="(user, index) in users.value" :key="index">{{ displayOwner(user) }}</p></div>
              </template>
              <el-tag
                class="tag"
                closable
                :disable-transitions="false"
                @close="handleUserRemove()"
              >
                {{ users.label }} <span v-if="users.value.length==1" >:  {{ displayOwner(users.value[0])  }}</span>  <span v-else class="tag-value">{{ users.value.length }}</span>
              </el-tag>
            </el-tooltip>
          </div>
        </div>
        <div
          class="tagsContainer"
          v-if="tablePipeline.length > 0 && (isTableOptClicked || isDrilldown)"
        >
          <!-- <div> -->
            <el-tooltip  v-if="filterPipeline" placement="bottom" effect="dark">
              <template v-slot:content>
              <div> <p class="filterTag" v-for="(pipeline, index) in filterPipeline" :key="index">{{ pipeline }}</p></div>
              </template>
                <el-tag
                  class="tag"
                  style="margin-right: 15px"
                  closable
                  @close="handleRemovePipeline()"
                >
                  Pipeline <span v-if="filterPipeline.length ==1" >: {{ filterPipeline[0] }}</span> <span v-else class="tag-value">{{ filterPipeline.length }}</span>
                </el-tag>
            </el-tooltip>
          <!-- </div> -->
        </div>
        <p
          class="clrAll"
          v-if="
            selectedFilter.length > 0 ||
            expectClosure ||
            systemsCost ||
            dcValue ||
            probability ||
            (tags.value && tags.value.length>0) ||
            (users.value && users.value.length>0)||
            (leadSources.value && leadSources.value.length>0) ||
            (region.value && region.value.length>0) ||
            selectedSort.length > 0 ||
            selectedProducts.length > 0 ||
            proposalStatusValue.value

          "
          @click="handleClear"
        >
          Clear all
        </p>
      </div>
      <div v-show="showSearchInput" class="search-container">
        <input
          ref="searchInput"
          type="text"
          placeholder="Search"
          v-model="search"
          class="searchInput-active searchInput"
        />
        <b-icon icon="x-lg" class="searchInput-close-btn" @click="showSearchInput = false"></b-icon>
      </div>
    </div>
    <div v-if="!isDrilldown">
      <div v-if="!isTableOptClicked">
        <div v-if="emptyPipline">
          <NoDataOverView :infoData="'No lead pipeline found.'" />
        </div>
        <div
          v-loading="kanbanDataValue.length > 0 ? false : true"
          :style="{
            height: 'calc(100vh - 250px)',
            marginTop: kanbanDataValue.length > 0 ? 0 : '1.5rem',
          }"
          v-else
        >
          <KanbanView
            :isLoading="isKanbanLoading"
            :dataTable="kanbanDataValue"
            @handleChange="handleChange"
            :buttonsData="buttonsData"
            :handleOrderClick="handleOnClick"
            :order.sync="order"
            :page="'Leads'"
            :getHeaderTitle="() => 'void'"
            :handleCreate="handleCreate"
            :columnType="'stage'"
            :drawer.sync="drawer"
            :drawerStage.sync="drawerStage"
            :componentName.sync="componentName"
            :drawerSize.sync="drawerSize"
            :leadDrawer="leadDrawer"
            :loadMoreData="debounceLoadMore"
            :offSetHeight="'20.1rem'"
            :showCreateDrawer.sync="showCreateDrawer"
            @updateReminder="handleReminder"
            :selectedCard="selectedCard"
            @handleColumnNumber="handleColumnNumber"
            :key="leadsRerender"
          />
        </div>
      </div>
      <LeadManagementTable
        v-else
        :search="search"
        :checkedColumnsList="checkedColumnsListToSend"
        :selectedSort.sync="selectedSort"
        :selectedFilter.sync="selectedFilter"
        :DCvalue.sync="DCvalue"
        :Systemcost.sync="Systemcost"
        :expectClosure.sync="expectClosure"
        :leadSeperator.sync="leadSeperator"
        :tagSeperator.sync="tagSeperator"
        :tablePipeline.sync="tablePipeline"
        :clearFlag.sync="clearFlag"
        :isDrilldown.sync="isDrilldown"
        :listQueryParams.sync="listQueryParams"
        :key="leadsRerender"
      />
    </div>
    <div v-if="isDrilldown && listQueryParams">
      <LeadManagementTable
        :search="search"
        :checkedColumnsList="checkedColumnsListToSend"
        :selectedSort.sync="selectedSort"
        :selectedFilter.sync="selectedFilter"
        :DCvalue.sync="DCvalue"
        :Systemcost.sync="Systemcost"
        :expectClosure.sync="expectClosure"
        :leadSeperator.sync="leadSeperator"
        :tagSeperator.sync="tagSeperator"
        :tablePipeline.sync="tablePipeline"
        :clearFlag.sync="clearFlag"
        :isDrilldown.sync="isDrilldown"
        :listQueryParams.sync="listQueryParams"
        @updateWidget="updateWidget"
        :isupdate.sync="isupdate"
        :updateValues="updateValues"
        :widgetId="widgetId"
        :key="leadsRerender"
      />
    </div>

    <div class="bottomPopupOverlay" v-if="addLeadBtnClicked">
      <div class="positionR">
        <b-icon icon="x-lg" class="closeIcon" @click="addLeadBtnClicked = false"></b-icon>
        <div class="bottomPopupForLeadOptions">
        <button class="importLeadBtnMD" @click="handleCreate" v-if="!isDrilldown">Create Lead</button>
        <el-tooltip class="item" effect="dark" content="Bulk lead is in progress." placement="top-end" :disabled="!bulkLeadImportState.isSSEProcessing">
          <button class="importLeadBtnMD" @click="importBulkLeadFunc(), addLeadBtnClicked = false" :disabled="bulkLeadImportState.isSSEProcessing == true ? true: false"  v-if="isAdmin && !isDrilldown">Import Lead</button>
        </el-tooltip>
      </div>
      </div>
    </div>

    <all-drawer
      :componentName="componentName"
      :key="createKey"
      :drawer.sync="drawer"
      :drawerStage.sync="drawerStage"
      :drawerSize="isMobileView ? '100%' : drawerSize"
      :propsData="{
        leadData: order,
        activePipelineId: pipelineIdForCreateLeadDrawer
      }"
      @save="drawer = false"
      @close="drawer = false"
    ></all-drawer>

    <setReminder
      v-if="!isDrilldown"
      :key="setKey"
      :showCreateDrawer="showCreateDrawer"
      @close="showCreateDrawer = false"
      :lead="order"
      @save="onSave"
    />

    <LeadsDrawer
      v-if="leadDrawer"
      :drawer.sync="leadDrawer"
      :lead="leadValue"
      @update-kanban="handleUpdateKanban"
      :currentRowNumber="currentRowNumber"
      @close="[(leadDrawer = false), (activeTab = 'first')]"
      @update-owner="updateOwnerInCurrentRow"
      @update-stage="updateStageInCurrentRow"
      @update-lead-source="updateLeadSourceInCurrentRow"
      @update-region="updateRegionInCurrentRow"
      @update-probability="updateProbabilityInCurrentRow"
      :activeTab="'first'"
      @updated-tags="updateTagsInCurrentRow"
      @deleted-lead="updateAllLeadsData"
      @update-dealvalue="getSumOfDealValueByStages"
    />
    <DealValue
      v-if="dealCheck"
      :isDeleteProjectDocumentPopupOpen="true"
      :title="`Deal Value Check`"
      :info="`Deal value for this lead is ${currencySymbol}0.`"
      :info2="` Do you want to continue without updating it?`"
      @cancelDelete="cancelDelete"
      @confirmDelete="confirmDelete"
    />
    <ImportLeadsPopup
      v-if="bulkLeadImportState.isImportLeadsPopupVisible" />

    <MinimizablePopup v-if="bulkLeadImportState.showMinimizablePopup" />
  </div>
