
  <div class="btnsContainerFlex">
    <div class="flexOne"  v-if="isProposalGenration">
      <div class="propsal-btn"  v-if="!isGenerationAlreadyCalculated && !essOnlyDesign">
          <span> <img v-if="isGenerationDisabledForDesign(design)" style="display: flex;"  src="./assets/fanDisabled.svg" alt="">
              <img v-else src="./assets/fan.svg" alt=""> 
              
          </span> 
      <ReusableButton 
        :loading="isLoading"
        :button="generationButtonData()"
        @click="calculateGeneration()"
      />
</div>
<div class="proposal-btn-container"> 

<div class="propsal-btn"  v-if="isGenerationAlreadyCalculated && !essOnlyDesign">
  <span> <b-icon style="transform: scale(1.5)" :class="[isWebProposalDisabledForDesign(design)?'disabled-btn' :'enabled-btn']" :icon="'link45deg'"></b-icon> </span> 
      <ReusableButton
       
        :button="webProposalButtonData()"
        @click="navigateToWebProposal()"
      />
</div>
<div class="propsal-btn"    v-if="isGenerationAlreadyCalculated">
  <span> <b-icon style="transform: scale(1.5)" :class="[isDocumentProposalDisabledForDesign(design)?'disabled-btn' :'enabled-btn']" :icon="'files'"></b-icon> </span> 
      <ReusableButton
     
        :button="documentProposalButtonData()"
        @click="navigateToDocumentProposal()"
      />
</div>
<div class="propsal-btn"  v-if="essOnlyDesign">
  <span> <b-icon style="transform: scale(1.5)" :class="[essOnlyProposalButtonData?.disableCondition?'disabled-btn' :'enabled-btn']" :icon="'badge3d'"></b-icon> </span> 
      <ReusableButton
       
        :button="essOnlyProposalButtonData()"
        @click="navigateToDocumentProposal()"
      />
</div>
<div class="propsal-btn"   v-if="isGenerationAlreadyCalculated && !essOnlyDesign">
  <span> <b-icon style="transform: scale(1.5)" :class="[is3DLinkDisabledForDesign(design)?'disabled-btn' :'enabled-btn']" :icon="'badge3d'"></b-icon> </span> 
      <ReusableButton
      
        :button="designOverviewButtonData()"
        @click="navigateToDesignOverview()"
      />
      </div>
    </div>

    </div>
    <div v-if="!essOnlyDesign&&isSalesModeBtns" class="flexTwo">
      <div class="sales-mode-btn"   v-if="isSalesModeAvailable && !isMobileView">
    <span> <b-icon style="transform: scale(1.5);" :class="[!isSalesModeEnabled||isSalesModeDisabled?'disabled-btn' :'enabled-salesmode-btn']" :icon="'bounding-box'"></b-icon> </span>     
          <ReusableButton
      
        :loading="isStudioLoading"
        :button="generationSaleStudioButtonData()"
        @click="saleStudioButton()"
      />
      </div>
  <div>
      <div class="sales-mode-btn" v-if="isSalesModeAvailable">
          <span> <b-icon style="transform: scale(1.5)" :class="[isSalesModeReportDisabledForDesign(design)?'disabled-btn' :'enabled-btn']" :icon="'clipboard-data'"></b-icon> </span>     
      <ReusableButton
        
        :loading="isReportLoading"
        :button="generationSaleReportButtonData()"
        @click="salesModeReportBtn()"
      />
      </div>
  </div>
    </div>

    
  </div>
