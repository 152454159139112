
<div>
  <div class="desktop">
    <div class="headerDetails">
      <el-input
        :placeholder="searchPlaceholder"
        v-model="searchText"
        class="headerDetailsSearch"
      ></el-input>
      <i
            class="el-icon-search"
            v-if="searchText===''"
          ></i>
           <i
            v-else
            @click="searchText=''"
            class="el-icon-close"
          ></i>
      <el-button
        type="primary"
        class="headerDetailsBtn"
        @click="isAddPopupOpenClick()"
        v-if="is_give_permission"
        >Add {{ typeOfTab }}</el-button
      >
    </div>
    <div class="bodyDetails">
      <el-table
        :data="loanData"
        style="width: 100%"
        v-el-table-infinite-scroll="loadMoreFinancial"
      >
        <el-table-column prop="name" label="Name" min-width="60%">
          <template v-slot="scope">
          <div class="text-right">
            <h3 class="heading">{{ scope.row.name }}</h3>
            <p class="description">
              {{ scope.row.description }}
            </p>
          </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="projecttype"
          label="Project Type"
          align="left"
          min-width="10%"
        >
        </el-table-column>
        <el-table-column
          min-width="11%"
          header-align="left"
          label="Actions"
          align="left"
          v-if="is_give_permission"
        >
          <template v-slot="scope">
          <div class="text-right flexIcon">
            <el-tooltip
              class="item"
              effect="dark"
              content="Copy"
              placement="top-start"
            >
              <i
                class="icon copy-alt"
                @click="handleCopy(scope.row)"
              />
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="Edit"
              placement="top-start"
            >
              <i
                class="icon edit-alt"
                @click="handleEdit(scope.row)"
                :style="[
                  scope.row.organisation == null
                    ? { cursor: 'not-allowed', opacity: '0.6' }
                    : { cursor: 'pointer', opacity: '1' },
                ]"
              />
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="Delete"
              placement="top-start"
            >
              <i
                class="icon delete-alt"
                @click="handleDelete(scope.row)"
                :style="[
                  scope.row.organisation == null
                    ? { cursor: 'not-allowed', opacity: '0.6' }
                    : { cursor: 'pointer', opacity: '1' },
                ]"
              />
            </el-tooltip>
          </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <addOrEditOrCopyDialogBoxForPpaAndLease
      v-if="typeOfTab != 'Loan'"
      :isAddOrEditOrCopyPopupOpen="isAddOrEditOrCopyPopupOpen"
      v-on:confirmOperation="confirmOperation"
      @cancelAdd="isAddOrEditOrCopyPopupOpen = false"
      :editOrCopyObj="editOrCopyObj"
      :typeOfTab="typeOfTab"
      :typeOfOperation="typeOfOperation"
      :key="counter"
    ></addOrEditOrCopyDialogBoxForPpaAndLease>

    <addOrEditOrCopyDialogBoxForLoan
      v-else
      :isAddOrEditOrCopyLoanPopupOpen="isAddOrEditOrCopyPopupOpen"
      v-on:addLoan="confirmOperation"
      @cancelAdd="isAddOrEditOrCopyPopupOpen = false"
      :editOrCopyObj="editOrCopyObj"
      :typeOfTab="typeOfTab"
      :typeOfOperation="typeOfOperation"
      :key="counter"
    ></addOrEditOrCopyDialogBoxForLoan>
  </div>
  <div class="mobile">
    <div class="searchContainer">
      
      <!-- <span
        v-if="searchText.length > 0"
        class="fa fa-times"
        @click="eraseSearchTerm"
      ></span> -->

      <el-input
        :placeholder="searchPlaceholder"
        v-model="searchText"
        class="mobheaderDetailsSearch"
      ></el-input>
       <i
            class="el-icon-search"
            v-if="searchText===''"
          ></i>
           <i
            v-else
            @click="searchText=''"
            class="el-icon-close"
          ></i>
      <el-button
        type="primary"
        class=""
        @click="isAddPopupOpenClickMob()"
        v-if="is_give_permission"
        >Add {{ typeOfTab }}</el-button
      >
    </div>
    <div
      style="overflow: auto"
      :style="{ height: `${window.height - 300}px` }"
    >
      <table>
        <tbody>
          <tr width="100%" v-for="(loan, index) in loanData" :key="index">
            <td>
              <el-card class="box-card">
                <td data-v-12684b02="" class="md_dot active" v-if="is_give_permission">
                  <div
                    data-v-12684b02=""
                    class="dot"
                    @click="menuClick(index)"
                  >
                  </div>
                   <el-dropdown
              trigger="click"
              
              @command="handleCommand"
            >
              <span class="el-dropdown-link">
                <span data-v-12684b02="" class="fas fa-ellipsis-v" ></span>
              </span>
              <template v-slot:dropdown>
              <el-dropdown-menu>
                <el-dropdown-item
                  icon="icon copy-alt"
                  :command="{ name: 'copy', id: loan }"
                 >Copy</el-dropdown-item
                >
                <el-dropdown-item
                  icon="icon edit-alt"
                  :command="{ name: 'edit', id: loan }"
                  >Edit</el-dropdown-item
                >
                <el-dropdown-item
                  icon="icon delete-alt"
                  :command="{ name: 'delete', id: loan }"
                  >Delete</el-dropdown-item
                >
              </el-dropdown-menu>
              </template>
            </el-dropdown>
                </td>
                <div class="mobtitleDes">
                  <h2 class="mobtitle">{{ loan.name }}</h2>
                  <p class="mobdescription">
                    {{ loan.description }}
                  </p>
                </div>
                <div class="mobType">
                  <h2 class="projectType">PROJECT TYPE</h2>
                  <p class="mobTypeDes">{{ loan.projecttype }}</p>
                </div>
              </el-card>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        v-observe-visibility="loadMoreFinancialMobile"
        style="text-align: center"
      >
        <i v-if="busy" class="el-icon-loading infiniteScrollLoader" />
      </div>
    </div>
  </div>
  <addOrEditOrCopyDialogBoxForPpaAndLease
      v-if="typeOfTab != 'Loan'"
      :isAddOrEditOrCopyPopupOpen="isAddOrEditOrCopyPopupOpenMob"
      v-on:confirmOperation="confirmOperationMob"
      @cancelAdd="isAddOrEditOrCopyPopupOpenMob = false"
      :editOrCopyObj="editOrCopyObjMob"
      :typeOfTab="typeOfTab"
      :typeOfOperation="typeOfOperation"
      :key="counter"
    ></addOrEditOrCopyDialogBoxForPpaAndLease>

    <addOrEditOrCopyDialogBoxForLoan
      v-else
      :isAddOrEditOrCopyLoanPopupOpen="isAddOrEditOrCopyPopupOpenMob"
      v-on:addLoan="confirmOperationMob"
      @cancelAdd="isAddOrEditOrCopyPopupOpenMob = false"
      :editOrCopyObj="editOrCopyObjMob"
      :typeOfTab="typeOfTab"
      :typeOfOperation="typeOfOperation"
      :key="counter"
    ></addOrEditOrCopyDialogBoxForLoan>
  <deleteDialogbox
     v-if="isDeletePopupOpen"
    :isDeletePopupOpen="isDeletePopupOpen"
    @confirmDelete="actualDelete()"
    @cancelDelete="isDeletePopupOpen= false"
    :toBeDeleted="typeOfTab"
  />
</div>
