import { defineStore } from "pinia";
import { alignIntercom, alignIntercomNormal } from "../plugins/intercom";

function getDefaultGoogleMapsState() {
  return {
    address: "",
    coordinates: {},
    isLoadingAddressFromMarker: false,
    isAddressManuallyEntered: false,
    state: "",
    countryCode: JSON.parse(localStorage.getItem("organisation"))
      ?.country_code,
    isAddressChosenByInput: false,
    postalCode: null,
    county: null,
    city: null,
  }
}

function getBulkLeadImportState() {
    return {
      userFileDataWithoutModification: '',
      userFileData: '',
      userFileName: '',
      isImportLeadsPopupVisible: false,
      showFileUploadSection: true,
      showInputsSection: false,
      selectedPipeline: '',
      fileName: '',
      fileType: null,
      fileData: [],
      pipelines: [],
      selectedHeaderValues: {
        fullName: '',
        assignTo: '',
        emailID: '',
        phoneNo: '',
        propertyType: '',
        stage: '',
        leadSource: '',
        address: '',
        closeDate: '',
        dealValue: '',
        tags: '',
        headerKeys: [],
      },
      defaultFiledsValues: {
        defaultAssignTo: '',
        owners: [],
        defaultPropertyType: 'residential',
        propertyTypeTypes: [
          {
            label: "Residential",
            value: "residential",
          },
          {
            label: "Commercial",
            value: "commercial",
          },
        ],
        defaultLeadSource: '',
        leadSourceTypes: [],
        defaultCloseDate: '',
        defaultDealValue: '',
        defaultTags: [],
      },
      uniqueStagesArray: [],
      isValidateBtnDisabled: true,
      showLeadFieldsSection: true,
      showStageFieldsSection: false,
      showMinimizablePopup: false,
      eventSource: null,
      progress: 0,
      isSSEProcessing: false,
      isBulkLeadUploadedPopupVisible: false,
      isLeadUploadedError: false,
      isCancelBtnClicked: false,
      isLeadsUploadSuccessfully: false,
      leadsRerender:0, // used to rerender leads in lead management page
    }
}

export const useMiscStore = defineStore("misc", {
  state: () => {
    return {
      mountSLDsummary: 0,
      wireSizeDataForSLD: {},
      showWiresizeComponent: false,
      workingDays: null,
      googleMapsState: getDefaultGoogleMapsState(),
      bulkLeadImportState: getBulkLeadImportState(),
      leadDrawerOpen: {
        isGlobalDrawerOpen: false,
        isAllDrawerOpen: false,
        isleadsDrawerOpen: false,
      },
      notificationsManager: null,
      notificationState: {
        unreadNotifications: false,
        notificationList: [],
        isLive: false,
        tempMessage: null,
      },
    };
  },
  getters: {
    GET_WORKING_DAYS: (state) => state.workingDays,
    GET_GOOGLE_MAPS_STATE: (state) => state.googleMapsState,
    GET_BULK_LEAD_IMPORT_DATA: (state) => state.bulkLeadImportState,
  },
  actions: {
    closeSSE() {
      if (this.bulkLeadImportState.eventSource) {
        this.bulkLeadImportState.eventSource.close();
        this.bulkLeadImportState.showMinimizablePopup = false;
        this.bulkLeadImportState.isBulkLeadUploadedPopupVisible = true;
        this.bulkLeadImportState.isCancelBtnClicked = true;
        this.bulkLeadImportState.isSSEProcessing = false;
        this.bulkLeadImportState.progress = 0;
        this.bulkLeadImportState.isBulkLeadUploadedPopupVisible = false;
        this.resetBulkLeadStoreFunc();
      }
    },
    SHOW_WIRESIZE_COMPONENT(val) {
      this.showWiresizeComponent = val;
    },
    RENDER_SLD_SUMMARY(val) {
      this.mountSLDsummary++;;
    },
    resetBulkLeadStoreFunc() {
      this.bulkLeadImportState.userFileDataWithoutModification = '';
      this.bulkLeadImportState.userFileData = '';
      this.bulkLeadImportState.isImportLeadsPopupVisible = false;
      this.bulkLeadImportState.showFileUploadSection = true;
      this.bulkLeadImportState.showInputsSection = false;
      this.bulkLeadImportState.selectedPipeline = '';
      this.bulkLeadImportState.fileName = '';
      this.bulkLeadImportState.fileData = [];
      this.bulkLeadImportState.pipelines = [];
      this.bulkLeadImportState.selectedHeaderValues = {
          fullName: '',
          assignTo: '',
          emailID: '',
          phoneNo: '',
          propertyType: '',
          stage: '',
          leadSource: '',
          address: '',
          closeDate: '',
          dealValue: '',
          tags: '',
          headerKeys: [],
      };
      this.bulkLeadImportState.defaultFiledsValues = {
        defaultAssignTo: '',
        owners: [],
        defaultPropertyType: 'residential',
        propertyTypeTypes: [
          {
            label: "Residential",
            value: "residential",
          },
          {
            label: "Commercial",
            value: "commercial",
          },
        ],
        defaultLeadSource: '',
        leadSourceTypes: [],
        defaultCloseDate: '',
        defaultDealValue: '',
        defaultTags: [],
      };
      this.bulkLeadImportState.uniqueStagesArray = [];
      this.bulkLeadImportState.isValidateBtnDisabled = true;
      this.bulkLeadImportState.showLeadFieldsSection = true;
      this.bulkLeadImportState.showStageFieldsSection = false;
      this.bulkLeadImportState.showMinimizablePopup = false;
      this.bulkLeadImportState.eventSource = null;
      this.bulkLeadImportState.progress = 0;
      this.bulkLeadImportState.isBulkLeadUploadedPopupVisible = false;
      this.bulkLeadImportState.isLeadsUploadSuccessfully = false;
      this.bulkLeadImportState.isCancelBtnClicked = false;
    },
    SET_NOTIFICATION_STATE(bool) {
      this.notificationState.unreadNotifications = bool;
    },
    UPDATE_GOOGLE_MAPS_STATE(data) {
      Object.keys(data).forEach((key) => {
        this.googleMapsState[key] = data[key];
      });
    },
    WIRESIZE_DATA_FOR_SLD(val) {
      this.wireSizeDataForSLD = val;
    },
    INITIALIZE_COUNTRY_CODE(countryCode) {
      this.googleMapsState.countryCode = countryCode;
    },
    SET_WORKING_DAYS(noOfWorkingDays) {
      this.workingDays = noOfWorkingDays;
    },
    SET_DRAWER_STATE(drawer, isOpen) {
      if (drawer == "globalDrawer") {
        this.leadDrawerOpen.isGlobalDrawerOpen = isOpen;
      } else if (drawer == "allDrawer") {
        this.leadDrawerOpen.isAllDrawerOpen = isOpen;
      } else {
        this.leadDrawerOpen.isleadsDrawerOpen = isOpen;
      }

      if (
        !this.leadDrawerOpen.isGlobalDrawerOpen &&
        !this.leadDrawerOpen.isAllDrawerOpen &&
        !this.leadDrawerOpen.isleadsDrawerOpen
      ) {
        alignIntercomNormal();
      } else {
        alignIntercom();
      }
    },
    resetGoogleMapsState() {
      this.googleMapsState = getDefaultGoogleMapsState()
    },
  },
});
