
<div class="block">
    <div class="timeline-section">
        <el-timeline>
            <el-timeline-item v-for="(activity, index) in activities" :key="index" :icon="activity.icon"
                :type="'primary'" :size="activity.size" :hide-timestamp="true">
                <el-row style="min-height: 80px">
                    <el-col :xs="18" :sm="22" :md="21" :lg="21">
                        <div class="dateBlock" :class="window.width < minWidth ? 'dateBlockMedia' : ''">
                            {{ getDateAndTime(activity.created_at).date }}
                        </div>
                        <div>
                            <p class="title">{{ activity.name }}</p>
                        </div>
                        <div v-if="activity.description">
                            <p class="description">{{ activity.description }}</p>
                        </div>

                        <div v-for="(doc, index) in filteredDocuments(activity)"
                            :key="index" class="docs"   :class="window.width<minWidth?'docsMedia':''">
                            <!-- col divide 6 to 18 on all screense -->
                            <p class="docText">Documents:</p>
                            <div class="allDocs">
                                <div class="specificDoc"
                                    v-loading="doc.id == fileIdToBeDeleted && isDeleteInProgress">
                                    <p class="docName">{{ getTruncatedFileName(doc.display_name, 14) }}</p>
                                    <div class="actionIcons">
                                        <img src="../../../leadManagement/components/assets/Download.svg"
                                            class="downloadIcon" @click="downloadClick(doc)" />
                                    </div>
                                </div>
                            </div>


                        </div>


                    </el-col>
                    <el-col :xs="6" :sm="2" :md="3" :lg="3" style="text-align: right">
                        <p class="time_text">
                            {{ getDateAndTime(activity.created_at).time }}
                        </p>
                    </el-col>
                </el-row>
            </el-timeline-item>
        </el-timeline>
    </div>

    <!-- add note section with a text area and a limit of 200 char -->
    <el-row>
        <el-col :span="24">
            <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 4 }" :rows="4" placeholder="Add a note"
                v-model="note" maxlength="200" show-word-limit></el-input>
        </el-col>
        <!-- update button right aligned -->
        <el-col :span="24" style="text-align: right; margin-top: 10px">
            <el-button type="primary" size="small" :disabled="note.length === 0" @click="addToActivityLog">Update
                <!-- add ic -->
            </el-button>

        </el-col>
    </el-row>

</div>
