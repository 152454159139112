
<div class="parentContainer">
  <el-dialog
    :visible="createTariffPopup"
    :close-on-click-modal="false"
    :title="dynamicHeading"
    width="1200px"
    class="delete_module"
    :class="showGenabilityRate? 'widthAdjust': ''"
    append-to-body
    @close="closeModal"
  >
    <div class="container genability" v-if="showGenabilityRate">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="Tariff Details" name="TARIFF_DETAILS">
        </el-tab-pane>
        <el-tab-pane :label="tab.name" :name="tab.type" v-for="tab in computedTabs" :key="tab.name">
        </el-tab-pane>
      </el-tabs>
      <tariffChargeDetails :activeName="activeName" :key="activeName" :chargeTypeData="chargeTypeData" :tariffDetails="tariffForId?.tariff_details"/>
      <div class="scheduleGenability" v-if="showTariffTable">
        <div class="tabPosition">
          <GenericTabs           
            :options="schedules" 
            :isFullWidth="false"
            :selectedOptionFromDB="selectedOptionFromDB"
            @selected-option="handleSelectedOption"/>
        </div>
        <TariffTable :key="counter1" :id="tableSp1" @forTableOne="handleTableOneData" :weekdaySchedule="scheduleData" :periodsAvailable="periodsAvailable" :disableTable="true"/>
        <div class="colorCodeRepresentations">
          <div v-for="colorCodeRepresentation in colorCodeRepresentations" :key="colorCodeRepresentation" class="colorCodes">
            <div :style="{backgroundColor: colorCodeRepresentation['colorCode']}" class="blockSize"></div>
            <p>{{ colorCodeRepresentation['represents'] }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="container" v-else>
      <el-form class="childContainer">
        <div class="nameAndProvider">
          <div class="positionR">
            <el-form-item label="Utility Provider*"
              ><br />
              <el-select v-model="finalUtilityRateProvider" placeholder="Select Utility Provider" filterable  clearable remote :loading="isLoading2" @change="addingUtilityRateProvider()" @clear="onClear" :remote-method="searchPostUtilityProvider" name="utilityProvider" v-validate="providerNameValidation">
                <el-option
                    v-for="(item,index) in filteredUtilityRateProviders"
                    :key="index"
                    :label="item.name"
                    :value="item.name">
                </el-option>
                <el-option
                  v-if="searchProviderQuery"
                  value="processing..."
                  >
                    <div class="addUtilityRateName">
                      <img
                        src="../../../assets/drop/Group2258.svg"
                        class="addTierIcon"
                      />
                      <p class="addTier">Add New Utility Provider</p><span>({{searchProviderQuery}})</span>
                    </div>
                </el-option>
                <infinite-loading
                  :distance="0"
                  spinner="bubbles"
                  @infinite="loadMoreUtilityProviders"
                >
                  <template v-slot:no-more>
                    <div></div>
                  </template>
                  <template v-slot:no-results>
                    <span></span>
                  </template>
                </infinite-loading>
              </el-select>
              <p
                class="providerErrorMsg"
                style="color: red"
                v-show="errors.has('utilityProvider')"
              >
              <!-- {{ errors.first('utilityProvider') }} --> This field is required.
              </p>
            </el-form-item>
          </div>
          <el-form-item label="Utility Rate*"
            ><br />
            <!-- <el-select v-model="finalUtilityRateName" filterable placeholder="Utility Rate" @change="addingUtilityRateName()" :filter-method="filterMethod" :loading="loading" :disabled="!isUtilityProviderSelected">
              <el-option  
                v-for="item in filteredUtilityRateNames"
                :key="item.id"
                :label="item.name"
                :value="item.name">
              </el-option>
              <el-option
              v-if="!filteredUtilityRateNames.length"
              value="processing..."
              >
                <div class="addUtilityRateName">
                  <img
                    src="../../../assets/drop/Group2258.svg"
                    class="addTierIcon"
                  />
                  <p class="addTier">Add New Utility Rate Name</p>
                </div>
            </el-option>
            </el-select> -->
            <el-input v-model="finalUtilityRateName" name="utilityRate" v-validate="rateNameValidation" @input="isUtilityRate()"></el-input>
            <p
                class="providerErrorMsg"
                style="color: red"
                v-show="errors.has('utilityRate')"
              >
                This field is required.
            </p>
          </el-form-item>
        </div>
        <div class="leftAndRight">
          <div class="leftCont">
            <label for="" class="labelCont"
              >Electricity Rates
              <div class="hover_information">
                <i class="fas fa-info-circle"></i>
                <div class="tooltip">
                  <p>
                    The electricity tariff sets the price or rate at which energy is sold based on defined values of varying time periods.
                  </p>
                </div>
              </div>
            </label>
            <div class="leftBorderCont">
              <div class="headerBrdr">
                <p class="headerVal">Period</p>
                <p class="headerVal">Tier</p>
                <p class="headerVal">Limits (kWh)</p>
                <p class="headerVal">Rate/Unit</p>
              </div>
              <div class="containerBorder">
                <div v-for="(period, index) in electricityRates" :key="index" class="periodBorder">
                  <div class="valBrdr">
                    <div class="ValuesCont">
                      <p class="period" :style="{backgroundColor : periodColors[index]}">{{index+1}}</p>
                      <p class="sNo">1</p>
                      <div class="inputDiv">
                        <p class="inputIcon" v-if="!(period.length==1)">kWh</p>
                        <el-input v-model="period[0].units" v-if="!(period.length==1)" type="Number"></el-input>
                      </div>
                      <div class="inputDiv1">
                        <p class="inputIcon2">{{currencySymbolComputed}}</p>
                        <el-input v-model="period[0].rate" type="Number" v-validate="firstRateValidation" @input="isFirstRate(index, 0)" :name="'rate_' + index"></el-input>
                        <p
                          class="formErrors"
                          style="color: red"
                          v-show="errors.has('rate_' + index)"
                        >
                          <!-- {{ errors.first('price') }} --> This field must be above 0.
                        </p>
                      <div class="forMD"></div>
                      </div>
                    </div>
                  </div>
                  <div class="valBrdrAdd" v-for="(tier, ind) in period.slice(1)" :key="ind">
                    <div class="ValuesCont">
                      <p class="mdPeriod"></p>
                      <p class="sNo">{{ind+2}}</p>
                      <div class="inputDiv">
                        <p class="inputIcon" v-if="!(ind === period.length - 2)">kWh</p>
                        <el-input v-model="tier['units']" v-if="!(ind === period.length - 2)" type="Number" @input="unitsInput"></el-input>
                        <p class="formErrors" style="color: red" v-if="parseFloat(tier['units']) <= parseFloat(electricityRates[index][ind]['units']) && ind+1<electricityRates[index].length-1">The unit must be greater than the previous unit.</p>
                      </div>
                      <div class="inputDiv2">
                        <p class="inputIcon2">{{currencySymbolComputed}}</p>
                        <div>
                        <el-input v-model="tier['rate']" v-validate="rateValidation" @input="isRate(index, ind)" :name="'rate_' + index + '_' + ind" type="Number"></el-input>
                        <p
                          class="formErrors"
                          style="color: red"
                          v-show="errors.has('rate_' + index + '_' + ind)"
                        >
                          <!-- {{ errors.first('price') }} --> This field must be above 0.
                        </p>
                      </div>
                        <img src="../../../assets/drop/Group 2291.svg" class="removeInpIcon" @click="deleteTier(index, ind)"/>
                      </div>
                    </div>
                  </div>
                  <div class="addTierContainer">
                    <div class="deleteContainer" @click="onDeletePeriod"  v-if="index === electricityRates.length - 1 && electricityRates.length>1" :class="{ disabled: isDeletePeriodDisabled }">
                      <img
                      src="../../../assets/drop/Group 2301.svg"
                      class="deletePeriodIcon"
                      />
                      <p class="delPeriod">Delete Period</p>
                    </div>
                    <div class="addTierCont" @click="addingTier(index)">
                      <img
                        src="../../../assets/drop/Group2258.svg"
                        class="addTierIcon"
                      />
                      <p class="addTier">Add Tier</p>
                    </div>
                  </div>
                </div>
                <div class="addWHover">
                <div class="addNewPerContainer"  @click="addingNewPeriod()" :class="{ disabled: isAddPeriodDisabled }">
                  <img
                    src="../../../assets/drop/Group2258.svg"
                    class="addNewPerIcon"
                  />
                  <p class="addNewPer">Add New Period</p>
                </div>
                <div class="selectCurency">
                  <div class="textCurrency">Change Currency</div>
                  <el-select
                    v-model="currency"
                    filterable
                    remote
                    reserve-keyword
                    @change="onCountryChange"
                  >
                    <el-option
                      v-for="country in countryDetails"
                      :key="country.id"
                      :label="
                        `${country.currency_code} ${
                          currencySymbolNameMap[country.currency_code]
                            ? `(${currencySymbolNameMap[country.currency_code]}) ${
                                country.name
                              } `
                            : ''
                        }`
                      "
                      :value="country.id"
                    />
                  </el-select>
                </div>
                <div class="hover_information" v-if="isAddPeriodDisabled">
                  <i class="fas fa-info-circle"></i>
                  <div class="tooltip">
                    <p>
                      Maximum Limit Reached: You have already defined the maximum number of periods allowed, which is 9. To add additional periods, you will need to remove one or more existing periods.
                    </p>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
          <div class="rightCont">
            <label for="" class="labelCont"
              >Schedule
              <div class="hover_information">
                <i class="fas fa-info-circle"></i>
                <div class="tooltip">
                  <p>
                    Modify your TOU Rate Schedule: Select time slots and assign periods to set up your TOU rate schedule. Click on the desired time slots and use the number keys on your keyboard. The chart displays a full year of data, organized by month and time.
                  </p>
                </div>
              </div>
            </label>
            <div class="borderCont">
              <p class="Weekday">
                Weekday
              </p>
              <TariffTable :key="counter1" :id="tableSp1" @forTableOne="handleTableOneData" :weekdaySchedule="weekdaySchedule" :periodsAvailable="periodsAvailable"/>
            </div>
            <div class="borderCont">
              <p class="Weekday">
                Weekend
              </p>
              <TariffTable :key="counter2" :id="tableSp2"  @forTableTwo="handleTableTwoData" :weekendSchedule="weekendSchedule" :periodsAvailable="periodsAvailable"/>
            </div>
          </div>
        </div>
      </el-form>
    </div>
    <div class="footer" v-if="!(isConsumption && selectedTariffSource=='genability')">
      <el-button v-if="!(isConsumption && selectedTariffSource=='genability')" type="primary" class="footerBtn" @click="addTariff" :disabled= "errors.items.length > 0 || hasLimitErrors">{{dynamicButtonText}}</el-button>
    </div>
  </el-dialog>
</div>
