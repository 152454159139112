
<div id="container">
  <Navbar @handleToggleSideBar="handleSidebar" />
  <Sidebar :isSidebarOpen="isSidebarOpen" />
  <section
    :class="['right_section', isCrmUser() ? 'containerCRM' : '']"
    v-loading.fullscreen.lock="isShareLoading"
  >
    <div class="content_section">
      <!-- <PageHeading
          :active="active"
          @toggle-view="toggleView"
          :title="title"
          :togglebtn1="togglebtn1"
          :togglebtn2="togglebtn2"
        /> -->
      <slot name="children"></slot>
    </div>
  </section>
</div>
