
<div class='deleteModule'>
    <el-dialog 
        :visible="emptySiteSurvey"
        :close-on-click-modal="false"
        title="Add User"
        width="30%"
        class="delete_module"
        @close="closeModal"
    >
        <!-- <div class='close_button'>
            <img 
                src='../../home/assets/img/close.svg' 
                alt='close' 
                @click="$emit('update:emptySiteSurvey', false)"
            >
        </div> -->
        <div class='alert'>
            <img 
                src='../../home/assets/img/info-circle.svg' 
                alt='alert'
                class="warningImage"
            >
        </div>
        <p class='msg'>No media files have been submitted yet.</p>
        <div class='button_container'>
            <el-button class='confirmButton' type="primary" @click="closeModal">
                Close
            </el-button>
        </div>
    </el-dialog>
</div>
