
<div class="select-container">
  <p class="title">
    {{ items.title }} <span v-if="items.isStar" class="astrisk"> *</span>
  </p>
  <el-select
    v-model="selectedItem"
    :placeholder="items.placeholder"
    @change="handleSelect"
    multiple
    filterable
  >
    <el-option
      v-for="(item, indx) in optionsData"
      :key="item.id ? item.id : item.value ? item.value : item"
      :label="item?.label || item"
      :value="item.id || item"
    >
      <template v-slot:default>
        <div :class="items.options.length - 1 > indx ? 'option-lables option-lables-border' : 'option-lables'">
          <input type="checkbox" :value="item.id" v-model="selectedItem" />
          <span style="margin-left: 10px">{{ item.label || item }}</span>
        </div>
      </template>
    </el-option>
  </el-select>
</div>
