import API from '../services/api';

const handleGenerateQueryParams=(params)=> {
    return Object.entries(params)
      .filter(([key, value]) => {
        if (Array.isArray(value)) {
          return value.length > 0;
        }
        return value !== null && value !== "";
      })
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          const encodedValues = value.map((item) => encodeURIComponent(item));
          return `${key}=${encodedValues.join(",")}`;
        }
        return `${key}=${encodeURIComponent(value)}`;
      })
      .join("&");
  };


const getQueryString=(pageNumber, type, timeRange, duetimeRange) =>{
    let queryString = "";
    let params;
    if (type === "orders") {
      const { start_time, end_time } = timeRange;
      const { due_start_time, due_end_time } = duetimeRange;


      params = {
        start_time,
        end_time,
        due_start_time,
        due_end_time,
        page: pageNumber,
      
      };
 
    }
    if (type === "users") {
      params = {
        page: pageNumber,
      };
    }
    queryString = handleGenerateQueryParams(params);
    return queryString;
  };
  const resovleRequests= async(
    requestCallback,
    pagesPerRequest = 5,
    recordsPerPage = 10,
    type,
    timeRange,
    duetimeRange
  ) =>{
    const orders = [];

    let queryString = getQueryString(1, type, timeRange, duetimeRange);

    const res = await requestCallback(queryString);
    // this.nextUrl = res.data.next;
    const nextUrl = res.data.next;
    orders.push(...res.data.results);
    const pages = Math.ceil(res.data.count / recordsPerPage);

    const count = Math.ceil(pages / pagesPerRequest);


    return [orders, nextUrl];
  };

export const UsersList= async ()=>{
    try{
        const [users, nextUrl] = await resovleRequests(
            API.DESIGN_ORDERS.FETCH_DESIGN_ORDER_USERS.bind(API.DESIGN_ORDERS),
            10,
            10,
            "users",
            "",
            ""
          );
          return [users,nextUrl];
    }catch(error){
        console.error('Error while fetching users list', error);
        return [];
    }
}