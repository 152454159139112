export default {
    HOME_STATE: 'HOME_STATE',
    ALL_BUTTONS_DISABLED_STATE: 'ALL_BUTTONS_DISABLED_STATE',
    LASSO_ENABLED: 'LASSO_ENABLED',
    VIEW_3D_STATE: 'VIEW_3D_STATE',
    HEAT_MAP_VISIBILITY: 'HEAT_MAP_VISIBILITY',
    HEAT_MAP_BUTTON_STATUS: 'HEAT_MAP_BUTTON_STATUS',
    SOLAR_ACCESS_VISIBILITY: 'SOLAR_ACCESS_VISIBILITY',
    VIEW_SLD_STATE: 'VIEW_SLD_STATE',
    UPDATE_UPLOAD_DIALOG_IMAGES: 'UPDATE_UPLOAD_DIALOG_IMAGES',
    DISABLE_VIEW: 'DISABLE_VIEW',
    ENABLE_VIEW: 'ENABLE_VIEW',
};
