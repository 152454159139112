
<div class="deleteModule">
  <el-dialog
    :visible="isLeadPermissionPopup"
    :close-on-click-modal="false"
    title="Add User"
    width="30%"
    @close="closeModal"
    append-to-body
  >
    <div class="crossIcon">
      <span @click="onClose()">&#x2716;</span>
    </div>
    <div class="containerTask">
      <p class="msg">
        The lead is not shared with {{ userName }}.
        Do you want to share the lead with this user?
      </p>
      <div class="button_container" v-if="showBtns">
        <el-button class="commonBtn noBtn" @click="$emit('cancelDelete')"
          >No</el-button
        >
        <el-button
          class="commonBtn yesBtn"
          type="primary"
          @click="showBtns = false"
          >Yes</el-button
        >
      </div>
      <el-select
        v-model="value"
        placeholder="Select Permission Type"
        v-if="!showBtns"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <div class="button_container shareBtnCont" v-if="value != null">
        <el-button
          class="commonBtn shareBtn"
          type="primary"
          @click="onShareTask()"
          >Share</el-button
        >
      </div>
    </div>
  </el-dialog>
</div>
