
<div class="inputview-container">
  <p class="title">
    {{ inputDetails.title }}
    <span v-if="inputDetails.isStar" class="astrisk"> *</span>
  </p>
  <el-input
    :placeholder="inputDetails.placeholder"
    v-model="inputData"
  ></el-input>
</div>
