
  <div class="docContainer" v-loading=isLoading>
    <div class="electBillContainer">
      <div class="flexCont">
        <el-checkbox v-model="electricityBillCheckbox"
          >Electricity Bill</el-checkbox
        >
        <p class="downloadAll" @click="downloadSelected">Download Selected</p>
      </div>
      <div class="gridCOntainer">
        <div
          class="card"
          v-for="(file, index) in computedElectricityBillFiles"
          :key="index"
        >
          <img
            :src="
              fileUploadAssets[
                `../../../assets/drop/fileUploadImages/${findFileTypeIcon(
                  file
                )}`
              ]
            "
            class="uploadIcon"
          />
          <p class="docFileName">
            <abbr :title="file.display_name" class="abbrTag">{{
              file.display_name
            }}</abbr>
          </p>
          <div class="flexIcon">
            <img
              src="./assets/Download.svg"
              class="downloadIconDoc"
              @click="downloadClick(file)"
            />
            <img
              src="./assets/delete.svg"
              class="deleteIcon"
              @click="deleteClick(file.id)"
            />
          </div>
        </div>
        <div
          class="addCard"
          @click="commonDocumentContainerFunc('electricity_bill')"
        >
          <img src="./assets/add.svg" class="addIcon" />
        </div>
      </div>
    </div>
    <div class="electBillContainer" v-show="isInternalSales || isSalesOps||isSalesAdmin">
      <div class="flexCont">
        <el-checkbox v-model="signedSolutionContractCheckbox"
          >D2C Contract</el-checkbox
        >
      </div>
      <div class="gridCOntainer">
        <div      
          v-for="(file, index) in computedSignedSolutionContractFiles"
          :key="index"
        >
        <div  class="contractCard">
          <p v-show="file.addtional_details.status =='completed'&&!file.addtional_details.is_rejected" class="acceptedTag">Accepted</p>
          <p v-show="file.addtional_details.status =='sent'" class="pendingTag">Pending</p>
          <p v-show="file.addtional_details.is_rejected" class="rejectedTag">Rejected</p>
          <img
            :src="
              fileUploadAssets[
                `../../../assets/drop/fileUploadImages/${findFileTypeIcon(
                  file
                )}`
              ]
            "
            class="uploadIcon"
          />
          <p class="docFileName">
            <abbr :title="file.display_name" class="abbrTag"
              >{{ file.display_name }}
            </abbr>
          </p>
          <div class="flexIcon" >
            <img
              src="./assets/Download.svg"
              class="downloadIconDoc"
              @click="downloadClick(file)"
            />
            <img
              v-show="(file.addtional_details.status !=='completed'||file.addtional_details.is_rejected)&&!isProjectManager && (isInternalSales||isSalesAdmin)"
              src="./assets/delete.svg"
              class="deleteIcon"
              @click="deleteClick(file.id,file)"
            />
          </div> 
        </div>
      
        </div>
        <div v-if="(isInternalSales||isSalesAdmin)&&computedSignedSolutionContractFiles.length===0"
          class=""
         
        >
          <!-- <img src="./assets/add.svg" class="addIcon" /> -->
          <el-tooltip placement="top" :disabled="!contractBtnDisabled" content="Create a Solutions proposal to enable contract.">
    <el-button
           type="primary"
            class="btn"
           
            @click="handleContract"
            :disabled="contractBtnDisabled"
            :loading="contractBtnLoading"
            >
            Send Contract
            </el-button>
            </el-tooltip>
        </div>
        <div v-else-if="computedSignedSolutionContractFiles.length===0" class="addCard site-survey-card" style="cursor: default">
          <span>No Uploaded Documents</span>
        </div>
      </div>
    </div>
    <div class="electBillContainer" v-show="(isInternalSales|| isSalesOps||isSalesAdmin)&&checkIfFinance">
      <div class="flexCont">
        <el-checkbox v-model="financialContractCheckBox"
          >Financial contract</el-checkbox
        >
      </div>
      <div class="gridCOntainer">
        <div
          class="card"
          v-for="(file, index) in computedFinancingContract"
          :key="index"
        >
        <div  class="contractCard">
          <p v-show="file.addtional_details.status =='completed'" class="acceptedTag">Accepted</p>
          <p v-show="file.addtional_details.status =='sent'" class="pendingTag">Pending</p>
          <p v-show="file.addtional_details.status =='rejected'" class="rejectedTag">Rejected</p>
          <p v-show="file.addtional_details.status =='cancelled'" class="cancelledTag">Cancelled</p>
          <img
            :src="
              fileUploadAssets[
                `../../../assets/drop/fileUploadImages/${findFileTypeIcon(
                  file
                )}`
              ]
            "
            class="uploadIcon"
          />
          <p class="docFileName">
            <abbr :title="file.display_name" class="abbrTag"
              >{{ file.display_name }}
            </abbr>
          </p>
          <div class="flexIcon" >
            <img
              src="./assets/Download.svg"
              class="downloadIconDoc"
              @click="downloadClick(file)"
            />
            <b-icon v-if="showCancelOrder(file)" style="cursor: pointer;" icon="x-lg" @click="showDialog(file)"></b-icon>
          </div> 
        </div>
        </div>
        <div
          class="addCard"
          @click="commonDocumentContainerFunc('financing_contracts')"
        >
          <img src="./assets/add.svg" class="addIcon" />
        </div>
      </div>
    </div>
    
    <div class="electBillContainer">
      <div class="flexCont">
        <el-checkbox v-model="signedContractCheckbox"
          >Signed Contract</el-checkbox
        >
      </div>
      <div class="gridCOntainer">
        <div      
          v-for="(file, index) in computedSignedContractFiles"
          :key="index"
        >
        <div  class="contractCard">
          <p v-show="file.addtional_details.status =='completed'" class="acceptedTag">Accepted</p>
          <p v-show="file.addtional_details.status =='sent'" class="pendingTag">Pending</p>
          <p v-show="file.addtional_details.status =='rejected'" class="rejectedTag">Rejected</p>
          <p v-show="file.addtional_details.status =='cancelled'" class="cancelledTag">Cancelled</p>
          <img
            :src="
              fileUploadAssets[
                `../../../assets/drop/fileUploadImages/${findFileTypeIcon(
                  file
                )}`
              ]
            "
            class="uploadIcon"
          />
          <p class="docFileName">
            <abbr :title="file.display_name" class="abbrTag"
              >{{ file.display_name }}
            </abbr>
          </p>
          <div class="flexIcon" >
            <img
              src="./assets/Download.svg"
              class="downloadIconDoc"
              @click="downloadClick(file)"
            />
            <b-icon v-if="showCancelOrder(file)" style="cursor: pointer;" icon="x-lg" @click="showDialog(file)"></b-icon>
          </div> 
        </div>
        </div>
        <div
          class="addCard"
          @click="commonDocumentContainerFunc('signed_contract')"
        >
          <img src="./assets/add.svg" class="addIcon" />
        </div>
        <div v-show="(isInternalSales || isSalesOps||isSalesAdmin)&&somContarctDesginId&&isSOMContractSigned">
        <el-button type="primary" @click="openChangeOrder"  :loading="changeOrderLoader">
          Change Order
        </el-button>

        </div>
      </div>
    </div>

    <div class="electBillContainer">
      <div class="flexCont">
        <el-checkbox v-model="financeCheckbox"
          >Finance Approval / Cash</el-checkbox
        >
      </div>
      <div class="gridCOntainer">
        <div
          class="card"
          v-for="(file, index) in computedFinanceFiles"
          :key="index"
        >
          <img
            :src="
              fileUploadAssets[
                `../../../assets/drop/fileUploadImages/${findFileTypeIcon(
                  file
                )}`
              ]
            "
            class="uploadIcon"
          />
          <p class="docFileName">
            <abbr :title="file.display_name" class="abbrTag"
              >{{ file.display_name }}
            </abbr>
          </p>
          <div class="flexIcon">
            <img src="./assets/Download.svg" class="downloadIconDoc" @click="downloadClick(file)"/>
            <img src="./assets/delete.svg" class="deleteIcon" @click="deleteClick(file.id)" />
          </div>
        </div>
        <div class="addCard" @click="commonDocumentContainerFunc('finance')">
          <img src="./assets/add.svg" class="addIcon" />
        </div>
      </div>
    </div>
    <div class="electBillContainer">
      <div class="flexCont">
        <el-checkbox v-model="siteSurveyCheckbox"
          >Site Survey</el-checkbox
        >
      </div>
      <div class="gridCOntainer">
        <div
          class="card"
          v-for="(file, index) in computedSiteSurveyFiles"
          :key="index"
        >
          <img
            :src="
              fileUploadAssets[
                `../../../assets/drop/fileUploadImages/${findFileTypeIcon(
                  file
                )}`
              ]
            "
            class="uploadIcon"
          />
          <p class="docFileName">
            <abbr :title="file.display_name" class="abbrTag"
              >{{ file.display_name }}
            </abbr>
          </p>
          <div class="flexIcon">
            <img src="./assets/Download.svg" class="downloadIconDoc" @click="downloadClick(file)"/>
          </div>
        </div>
        <div v-if="!computedSiteSurveyFiles.length" class="addCard site-survey-card" style="cursor: default">
          <span>No Uploaded Documents</span>
        </div>
        <div class="addCard" @click="commonDocumentContainerFunc('site_survey')" v-if="computedFetchOrganisationId">
          <img src="./assets/add.svg" class="addIcon" />
        </div>
      </div>
    </div>
    <div class="electBillContainer" >
      <div class="flexCont">
        <el-checkbox v-model="permitInterConnectionCheckbox"
          >Permit / Interconnection</el-checkbox
        >
      </div>
      <div class="gridCOntainer">
        <div
          class="card"
          v-for="(file, index) in computedPermitInterConnectionFiles"
          :key="index"
        >
          <img
            :src="
              fileUploadAssets[
                `../../../assets/drop/fileUploadImages/${findFileTypeIcon(
                  file
                )}`
              ]
            "
            class="uploadIcon"
          />
          <p class="docFileName">
            <abbr :title="file.display_name" class="abbrTag"
              >{{ file.display_name }}
            </abbr>
          </p>
          <div class="flexIcon">
            <img src="./assets/Download.svg" class="downloadIconDoc" @click="downloadClick(file)"/>
            <img src="./assets/delete.svg" class="deleteIcon" @click="deleteClick(file.id)" />
          </div>
        </div>
        <div class="addCard" @click="commonDocumentContainerFunc('permit_interconnection')">
          <img src="./assets/add.svg" class="addIcon" />
        </div>
      </div>
    </div>
    <div class="electBillContainer otherDoc">
      <div class="flexCont">
        <el-checkbox v-model="otherDocCheckbox">Other Documents</el-checkbox>
      </div>
      <div class="gridCOntainer">
        <div
          class="card"
          v-for="(file, index) in computedOtherFiles"
          :key="index"
        >
          <img
            :src="
              fileUploadAssets[
                `../../../assets/drop/fileUploadImages/${findFileTypeIcon(
                  file
                )}`
              ]
            "
            class="uploadIcon"
          />
          <p class="docFileName">
            <abbr :title="file.display_name" class="abbrTag"
              >{{ file.display_name }}
            </abbr>
          </p>
          <div class="flexIcon">
            <img src="./assets/Download.svg" class="downloadIconDoc" @click="downloadClick(file)"/>
            <img src="./assets/delete.svg" class="deleteIcon" @click="deleteClick(file.id)"/>
          </div>
        </div>
        <div class="addCard" @click="commonDocumentContainerFunc('other')">
          <img src="./assets/add.svg" class="addIcon" />
        </div>
      </div>
    </div>
    <div class="flexCont lastFlexCont">
      <div></div>
      <p class="downloadAll" @click="downloadAllClick">
        Download All Documents
      </p>
    </div>
    <ShareDocumentPopup
      :isShareDocumentPopupVisible.sync="isShareDocumentPopupVisible"
      :isCRMMode="isCRMMode"
      @confirmUploaded="confirmUploaded"
      :projectId="projectId"
      :documentContainer="documentContainer"
      :ispdf="isPdfType"
    />
    <DeleteProjectDocument
      v-if="isDeleteProjectDocumentPopupOpen"
      :isDeleteProjectDocumentPopupOpen="isDeleteProjectDocumentPopupOpen"
      @cancelDelete="isDeleteProjectDocumentPopupOpen = false"
      @confirmDelete="confirmDelete"
    />
    <GlobalDialog 
    :dialogTitle="'Generate Contract'" 
    :dialogVisible.sync="openDesignList" 
    :width="contractPreview?`1000px` :'800px'" 
    @handleClose="closeDesignList"
    :isModal="false"
    :zeroPadding="true"
    >
          
                <template v-slot:body >
                  <div v-if="contractPreview" style="padding:0 50px;height: calc(100vh - 250px);overflow: hidden;">
                    <iframe ref="iframe" class="custom-iframe" :srcdoc="previewContractData" style="width: 100%; height:100%; border: none;" ></iframe>
                  </div>
                  <div class="body-container" v-else>

                    <div class="left-section">
                      <div  class="select-container">
                      <p class="lable-section">Workflow <span class="required-value">*</span></p>
                      <el-select v-model="selectedWorkflow" placeholder="Select Proposal" class="select-content">
                      <el-option
                      v-for="(item,inx) in wrokflowList"
                      :key="inx"
                      :label="item.name"
                      :value="item.value">
                      </el-option>
                  </el-select>
                </div>
                      <div  class="select-container">
                      <p class="lable-section">Proposal List <span class="required-value">*</span></p>
                    <el-select v-model="selectedDesign" placeholder="Select Proposal" class="select-content">
                      <el-option
                      v-for="item in arkaProductsProposal"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                      </el-option>
                  </el-select>
                </div>
                <div  class="select-container">
                      <p class="lable-section" >Mode of payment <span class="required-value">*</span></p>
                    <el-select v-model="selectedFinance" placeholder="Select Proposal" class="select-content">
                      <el-option
                      v-for="(item,inx) in financeList"
                      :key="inx"
                      :label="item.name"
                      :value="item.value">
                      </el-option>
                  </el-select>
                </div>
                <div  class="select-container">
                  <el-checkbox v-model="includeWaiver">Include concrete waiver in contract</el-checkbox>

                </div>

                    </div>
                    <div class="right-section">
                     <p class="pricing-title">Pricing Details for Selected Proposal</p>
                     <div class="pricing-top-list">

                   
                     <div class="pricing-content">
                      <p class="pricing-label">Total System Price</p>
                      <p  class="pricing-label">{{formatNumber( pricing?.total_system_price  ,'currency',countryCode) }}</p>

                     </div>
                     <div class="pricing-content">
                      <p  class="pricing-label">Potential Incentives</p>
                      <p  class="pricing-label">- {{formatNumber(pricing?.incentives_total_cost ,'currency',countryCode) }}</p>

                     </div>
                     <div class="sublist" v-for="incentive in pricing?.incentives_data" :key="incentive.id">
                <div class="pricing-content" style="padding-left: 16px;">
                      <p  class="incentive-label">{{ incentive.name }}</p>
                      <p class="incentive-value">{{formatNumber(incentive.tot_amount_contribution ,'currency',countryCode)  }}</p>

                     </div>
              </div>
            </div>
                     <div class="pricing-content" style="border-top:1px solid #ccc">
                      <p  class="pricing-label">System Price Post Incentives</p>
                      <p  class="pricing-label">{{formatNumber(pricing?.system_price_post_incentives ,'currency',countryCode)   }}</p>

                     </div>
                     <div class="pricing-content">
                      <p style="color:#777">Pricing can be edited from the design</p>
                     </div>
                    </div>
           
                  </div>
                </template>
                <template v-slot:footer
                    >
                    <div class="contract-footer" >

   
                      <div   v-if="contractPreview">

                        <el-button
          plain
            class="btn"
            @click="backToGenerate"
           
            >
            Back
            </el-button>

                      <el-tooltip placement="top" :disabled="!handleDisableBtn" content="The total system price should be more than 1500." 
                     
                      >

                      <el-button
           type="primary"
            class="btn"
            :loading="contractBtnLoading"
            @click="generateContract(selectedWorkflow,selectedDesign,selectedFinance)"
            :disabled="handleDisableBtn"
            >
            Generate Contract
            </el-button>
            </el-tooltip>
       
          </div>

            <el-tooltip placement="top" :disabled="!handleDisableBtn" content="The total system price should be more than 1500." v-else>

<el-button
type="primary"
class="btn"
@click="goToPreviewContract(selectedWorkflow,selectedDesign,selectedFinance)"
:disabled="handleDisableBtn"
>
Next
</el-button>
</el-tooltip>
                    </div>
                </template>
                
                </GlobalDialog>
                <DialogPopup v-if="showDialogPopup" 
                  :isOpen="showDialogPopup"
                  message="Are you sure you want to cancel the contract?"
                  @onClickNo="showDialogPopup = false"
                  @onClickYes="cancelContract"
                />
       
    <ShareChangeOrder v-if="isChangeContract" :changeContract.sync="tempChangeOrderTemplate" @handleClose="handleCloseAndRefresh()" :contractDesignId="somContarctDesginId"/>

  </div>
