<template>
  <div id="popoverselection">
    <el-popover placement="bottom" width="auto" trigger="click" v-model="isPopoverOpen"
      popper-class="dashboard-selection">
      <div :style="{maxHeight:screenSize<1500?'400px' :'500px',overflowY: 'scroll',width: '358px',overflowX: 'hidden'}" v-infinite-scroll="loadMore">
        <div v-for="(dashboard, inx) in dashboardsData" :key="dashboard?.id||inx">
          <div class="optionscontainer">
            <div class="container-labels options-border ">
              <div class="options " @click="handleClick(dashboard)">
                <p style="padding-right: 0.5rem" @click="handleDefaultSelect(dashboard)">
                  <span v-if="defaultDashboardId === dashboard.id">
                    <b-icon :style="{
                      marginRight: '5px',
                      color: 'orange',
                    }" :icon="'pin-angle-fill'"></b-icon></span>
                  <span v-else><b-icon :style="{
                    marginRight: '5px',
                    color: 'black',
                  }" :icon="'pin-angle'"></b-icon>
                  </span>
                </p>

                <p class="labels">
                  {{ dashboard.name }}
                </p>
              </div>
              <div class="icons-container " v-if="!isMobile">
                <el-tooltip content="Copy">
                  <p class="icons-content" @click="copyOpen(dashboard)">
                    <b-icon icon="files"></b-icon>
                  </p>
                </el-tooltip>
                <p v-if="!isMobile" class="icons-content" @click="handleEdit(dashboard)">
                  <el-tooltip content="Edit"> <b-icon :icon="'pencil'"></b-icon></el-tooltip>
                </p>
                <p class="icons-content" @click="handleDeleteOpen(dashboard)" v-if="dashboardsData.length > 1">

                  <el-tooltip content="Delete"> <b-icon :icon="'trash'"></b-icon></el-tooltip>
                </p>

              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- create dashboard container -->
      <div v-if="!isMobile" :class="['create-options', dashboardsData.length >= 10? 'disabled' : '',]" @click="createOpen">
        <el-tooltip v-if="dashboardsData.length >= 10" class="item" effect="dark"
          content="Maximum of 10 dashboards can be created" placement="top">
          <div class="options">
            <div class="create-container" >
              <span>
                <b-icon
                  :style="{
                    color: '#1c3366',
                  }"
                  class="plus-icon"
                  :icon="'plus-circle-fill'"
                ></b-icon>
              </span>
              <span class="createlabel">Create New Dashboard</span>
            </div>
          </div>
        </el-tooltip>
        <div v-else class="options">
          <div class="create-container" >
            <span>
              <b-icon :style="{

                color: '#1c3366',
              }" class="plus-icon" :icon="'plus-circle-fill'"></b-icon></span>
            <span class="createlabel">Create New Dashboard</span>
            </div>
        </div>
      </div>

      <template v-slot:reference>
      <div class="selection-container">
        <el-tooltip class="item" effect="dark" :content="defaultDashboard" placement="top">
          <p style="text-overflow: ellipsis;overflow: hidden;">{{ defaultDashboard }}</p>
        </el-tooltip>
        <p style="padding-left: 0.5rem">
          <i :class="isPopoverOpen ? 'fas fa-angle-up' : 'fas fa-angle-down'"></i>
        </p>
      </div>
      </template>
      <!-- <el-button slot="reference"></el-button> -->
    </el-popover>
    <GlobalDialog :dialogTitle="dialogTitle" :dialogVisible.sync="open" :onClickClose="false" @handleClose="closeDialog"
      :width="'40%'">
      <template v-slot:body>
        <div class="dialogbody">
          <p class="label">Name</p>
          <el-input @input="nameValidation" v-model="newDashboard" maxlength=100 :placeholder="'Enter Dashboard Name'" />
          <p v-if="errorMessage" style="color: red;">Maximun characters limit allowed is 100</p>
        </div>
      </template>
      <template v-slot:footer>
        <div class="alignment">
          <el-button :disabled="!newDashboard" type="primary" @click="handleCreate">Submit</el-button>
        </div>
      </template>
    </GlobalDialog>

    <DeleteDialog :dialogVisible="deleteOpen" :footerButtonType="'primary'" :footerButtonText="'Yes'"
      :disableCancel="true" :width="'20%'" :deleteMessage="'Are you sure you want to delete this dashboard?'"
      @handleClick="handleDelete" @handleClose="deleteOpen = false">
    </DeleteDialog>
  </div>
</template>
<script>
import API from "../../../services/api";
import GlobalDialog from "../../commonComponents/GlobalDialog.vue";
import DeleteDialog from "./dashboardV2/deleteDialog.vue";


export default {
  emits: [ "load-more", "dashboardChanged", "selected-dashboard", "update-dashboard", "get-dashboard"],
  name: "popoveSelect",
  components: { GlobalDialog, DeleteDialog },
  data() {
    return {
      errorMessage: false,
      isMobile: false,
      defaultDashboard: "Default DashBoard",
      dashboardData: [],
      open: false,
      isPopoverOpen: false,
      defaultDashboardId: "",
      newDashboard: "",
      deleteOpen: false,
      activeDashboard: '',
      dialogTitle: 'Create Dashboard',
      copyData: '',
      screenSize:window.innerWidth,
    };
  },
  props: {
    dashboardsData: {
      type: Array,
      default() {
        return []
      }
    },
    currentDashboardId: {
      type: Number,
      default: null,
    },
    selecteddashboardData: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  created() {

    if(this.screenSize<1280){
        this.isMobile = true
      }else{
        this.isMobile = false
      }
    if (screen.width <1280) {
      this.isMobile = true
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);

    this.dashboardData = this.$props.dashboardsData;
    const defaultDash = this.$props.dashboardsData.length
      ? this.$props.dashboardsData.find((d) => d.is_starred === true)
      : {};
    this.defaultDashboardId =
      defaultDash?.id || this.$props.dashboardsData[0]?.id;
    this.defaultDashboard =
      defaultDash?.name || this.$props.dashboardsData[0]?.name;
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize); // Remove event listener when component is destroyed
  },
  methods: {
    handleResize() {
      this.screenSize = window.innerWidth; // Update screenWidth when the window is resized
      if(this.screenSize<1280){
        this.isMobile = true
      }else{
        this.isMobile = false
      }
    },
    nameValidation() {
      if (this.newDashboard.length == 100) {
        this.errorMessage = true
      } else {
        this.errorMessage = false
      }
    },
    loadMore() {
      this.$emit('load-more')
    },
    handleClick(data) {
      this.isPopoverOpen = false;
      if (this.$props.currentDashboardId !== data.id) {
        this.$emit("dashboardChanged", data.id);
        this.defaultDashboard = data.name;
        this.$emit("selected-dashboard", data.id);
      }
    },
    createOpen() {
      if (this.$props.dashboardsData.length >= 10) return
      this.dialogTitle = 'Create Dashboard'
      this.open = true;
    },
    copyOpen(value) {
      this.copyData = value
      this.newDashboard = `${value.name}(Copy)`
      this.dialogTitle = 'Copy Dashboard'
      this.open = true;

    },
    async handleCreate() {
      try {
        const postData = {
          name: this.newDashboard
        }
        let data = ''

        if (this.copyData) {
          data = await API.CRM_DASHBOARD.COPY_DASHBOARD(this.copyData.id, postData)

        } else {
          data = await API.CRM_DASHBOARD.CREATE_DASHBOARD(postData)

        }
        this.$message({
                    showClose: true,
                    message: "Dashboard created successfully.",
                    type: "success",
                    center: true,
                });
        if (data) {
          const dashboardId = data?.data?.id
          this.$router.push({
            name: "dashboardEditMode",
            params: { id: dashboardId },
          });
        }
      } catch (error) {
        if (error.response) {
          const statusCode = error.response.status;
          if (statusCode === 400) {
            if (this.newDashboard !== "") {
              this.$message({
                showClose: true,
                dangerouslyUseHTMLString: true,
                message: `Dashboard with name "${this.newDashboard}" already exists`,
                type: "error",
              });
            } else {
              this.$message({
                showClose: true,
                dangerouslyUseHTMLString: true,
                message: `This field may not be blank.`,
                type: "error",
              });
            }
          } else {
            this.$message({
              showClose: true,
              dangerouslyUseHTMLString: true,
              message: "There was an error while creating the dashboard name.",
              type: "error",
            });
          }
        } else {
          this.$message({
            showClose: true,
            dangerouslyUseHTMLString: true,
            message: "An error occurred while making the API request.",
            type: "error",
          });
        }
      }


    },
    closeDialog() {
      this.open = false;
    },
    handleborder(index) {
      const bordeCondition = index < this.$props.dashboardsData.length - 1;
      return bordeCondition;
    },
    handleDefaultSelect(data) {
      this.isPopoverOpen = false;
      if (this.$props.currentDashboardId !== data.id) {
        this.$emit("dashboardChanged", data.id);
        this.$emit("selected-dashboard", data.id);
      }
      this.defaultDashboardId = data.id;
      this.defaultDashboard = data.name;
      this.$emit("update-dashboard", data, { is_starred: true });
    },
    handleEdit(dashboard) {
      // this.handleClick(dashboard)
      // this.$emit('update:editMode',true)

      this.$router.push({
        name: "dashboardEditMode",
        params: { id: dashboard.id },
      });
    },
    handleDeleteOpen(dashboard) {
      this.activeDashboard = dashboard.id
      this.deleteOpen = true
    },
    async handleDelete() {
      try {

        const { data } = await API.CRM_DASHBOARD.DELETE_DASHBOARD_BY_ID(this.activeDashboard)
        this.$emit('get-dashboard')
      } catch (err) {
        console.error(err)
      }

    }

  },
  watch: {
    screenSize:{
      immediate:true,
      handler(val){
      }
    },
    selecteddashboardData(val) {
      this.defaultDashboard =
        val?.name || this.$props.dashboardsData[0].name;
      // this.defaultDashboardId =
      //  val?.id || this.$props.dashboardsData[0].id;
    },
    dashboardsData(val) {

      const defaultDash = val.length
        ? val.find((d) => d.is_starred === true)
        : {};
      this.defaultDashboard =
        defaultDash?.name || this.$props.dashboardsData[0].name;
      this.defaultDashboardId =
        defaultDash?.id || this.$props.dashboardsData[0].id;
    },
    isPopoverOpen(val) {

      if (!val) {
        // Popover is closed, reset the flag
        this.isPopoverOpen = false;
      }
    },
  },
};
</script>

<style scoped>
.selection-container {
  background: #fff;
  border: 1px solid #ccc;
  padding: 1rem;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 200px;
  width: auto;
}

.optionscontainer {
  padding-left: 1rem;
  padding-right: 1rem;

}

.container-labels {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.optionscontainer :hover {
  cursor: pointer;
}

.options {
  padding: 1rem 0 1rem 0;

  font-size: 14px;
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%
}

.disabled {
  opacity: 0.3;
  /* pointer-events: none;  */
  cursor: not-allowed !important;
}

.icons-container {
  padding: 1rem;

  font-size: 14px;
  display: flex;
  justify-content: end;
  align-items: center;
}

.options-border {
  border-bottom: 1px solid #777;
}

.create-options {

  padding-left: 1rem;
  color: #1c3366;
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  cursor: pointer;
}

#popoverselection>>>#el-popover {
  border: 1px solid red !important;

}

#popoverselection {
  position: relative;
  /* Ensure it's a positioned element */
  width: inherit;
}

#el-popover {
  max-height: 500px !important;
  /* Set the maximum height */
  overflow-y: auto !important;
  /* Enable vertical scrolling when content overflows */
}

.createlabel {
  font-size: 14px;
  padding-left: 10px;
  display: flex
}

.dialogbody {
  padding: 0.5rem;
}

.dialogbody>>>.el-input__inner {
  background: #e8edf2;
  padding: 14px 10px 14px 10px;
  color: black
}

.dialogbody>>>.el-input__inner::placeholder {

  color: #777;
}

.label {
  font-size: 1rem;
  padding-bottom: .3rem;
}

.dialog-container {
  padding: 1rem;
}

.alignment {
  padding: 1rem;

  display: flex;
  justify-content: center;
}

.labels {
  color: black;
  text-wrap: nowrap;
  width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icons-content {
  padding-left: 0.5rem;
}

#popoverselection>>>.el-popper {

  max-height: 100px !important;
  overflow-y: scroll !important;
  top:166px !important
}

.plus-icon {
transform: scale(1.2);
  display:flex;
  
}
.create-container{
  padding-right: 0.5rem;
  padding-top: 5px;
  display: flex;
  align-items: center;
}
</style>
