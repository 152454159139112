import axios from "axios";
export default {
    FETCH_ARKA_PRODUCTS(url) {
        if (url) {
            return axios.get(url);
        }else{
            return axios.get(`api/solutions-order/products/`);
        }
    },
    GET_ARKA_PRODUCTS(params) {
       
      
            return axios.get(`api/solutions-order/products/?${params}`);
       
    },
    FETCH_ARKA_PRODUCTS_SKUS(params){
        return axios.get(`api/solutions-order/productskus/?${params}`)
    }

}
