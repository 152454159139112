<template>
  <main class="main-controller" :class="{ expended: isSidebarVisible }">
    <div
      :class="['backdrop', isCrmUser() ? 'backdropCRM' : '']"
      @click="toggleSidebarStatus"
    ></div>
    <aside class="aside_setion" :class="isCrmUser() ? 'aside_setionCRM' : ''">
      <nav class="navbar" :class="navbarClassFunc()">
        <ul class="nav_list">
          <el-tooltip
            class="item"
            effect="dark"
            content="Dashboard"
            placement="right"
            :disabled="!isCrmUser() || isSidebarVisible"
          >
            <li
              v-if="!isTataOrg()"
              :class="{
                activeProject:
                  $router.currentRoute.path === '/' && active === false,
              }"
              @click="toggleSidebarStatus"
            >
              <router-link :to="{ name: 'home' }">
                <div class="nav_icon">
                  <span class="icon" v-if="$router.currentRoute.path === '/'"
                    ><img src="../../assets/drop/Group 292.svg"
                  /></span>
                  <span class="icon" v-else
                    ><img src="../../assets/drop/Group 1938.svg"
                  /></span>
                </div>
                <span
                  class="nav_text"
                  :class="!isSidebarVisible && isCrmUser() ? 'hideLabel' : ''"
                  >Dashboard</span
                >
              </router-link>
            </li>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            :content="somTitle()"
            placement="right"
            :disabled="!isCrmUser() || isSidebarVisible"
          >
            <li
              v-if="isSOMPageRequired()"
              :class="{activeProject:
                  ($router.currentRoute.path === '/arkaorders') &&
                  active === false,}"
              @click="collapseSidebar">
              <router-link :to="{ name: 'arkaorders' }">
                <div class="nav_icon">
                  <span
                    class="icon"
                    v-if="$router.currentRoute.path === '/arkaorders'"
                    ><img src="../../assets/img/arkaOrderSelected.svg"
                  /></span>
                  <span class="icon" v-else
                    ><img src="../../assets/img/arkaOrder.svg"
                  /></span>
                </div>
                <span
                  class="nav_text"
                  :class="!isSidebarVisible && isCrmUser() ? 'hideLabel' : ''"
                  >{{somTitle()}}</span
                >
              </router-link>
            </li>
          </el-tooltip>
          <!-- Catalog side Bar icon do not remove this commented code -->
          <!-- <el-tooltip
            class="item"
            effect="dark"
            content="Catalog"
            placement="right"
            :disabled="!isCrmUser() || isSidebarVisible"
          >
            <li
              v-if="isCrmUser() && ( role =='ARKA_SALES_OP'||role =='ARKA_INTERNAL_SALES' || isVIP)"
              :class="{
                activeProject:
                  $router.currentRoute.path === '/catalog' &&
                  active === false,
              }"
              @click="collapseSidebar"
            >
              <router-link :to="{ name: 'catalog' }">
                <div class="nav_icon">
                  <span
                    class="icon"
                    v-if="$router.currentRoute.path === '/catalog'"
                    ><img src="../../assets/img/catalogSelecetd.svg"
                  /></span>
                  <span class="icon" v-else
                    ><img src="../../assets/img/catalog.svg"
                  /></span>
                </div>
                <span
                  class="nav_text"
                  :class="!isSidebarVisible && isCrmUser() ? 'hideLabel' : ''"
                  >Catalog</span
                >
              </router-link>
            </li>
          </el-tooltip> -->
          
          <el-tooltip
            class="item"
            effect="dark"
            content="Tasks"
            placement="right"
            :disabled="!isCrmUser() || isSidebarVisible"
          >
          </el-tooltip>

          <el-tooltip
            class="item"
            effect="dark"
            content="Leads"
            placement="right"
            :disabled="!isCrmUser() || isSidebarVisible"
          >
            <li
              v-if="isCrmUser()"
              :class="{
                activeProject:
                  $router.currentRoute.path === '/leadmanagement' &&
                  active === false,
              }"
              @click="collapseSidebar"
            >
              <router-link :to="{ name: 'leadManagement' }">
                <div class="nav_icon">
                  <span
                    class="icon"
                    v-if="$router.currentRoute.path === '/leadManagement'"
                    ><img src="../../assets/drop/Group 1939.svg"
                  /></span>
                  <span class="icon" v-else
                    ><img src="../../assets/drop/leadManagementIcon.svg"
                  /></span>
                </div>
                <span
                  class="nav_text"
                  :class="!isSidebarVisible && isCrmUser() ? 'hideLabel' : ''"
                  >Leads</span
                >
              </router-link>
            </li>
          </el-tooltip>

          <el-tooltip
            class="item"
            effect="dark"
            content="Tasks"
            placement="right"
            :disabled="!isCrmUser() || isSidebarVisible"
          >
            <li
              v-if="isCrmUser()"
              :class="{
                activeProject:
                  $router.currentRoute.path === '/taskmanagement' &&
                  active === false,
              }"
              @click="collapseSidebar"
            >
              <router-link :to="{ name: 'taskManagement' }">
                <div class="nav_icon">
                  <span
                    class="icon"
                    v-if="$router.currentRoute.path === '/taskManagement'"
                    ><img src="../../assets/drop/task-sidebar-icon.svg"
                  /></span>
                  <span class="icon" v-else
                    ><img src="../../assets/drop/task-sidebar-icon.svg"
                  /></span>
                </div>
                <span
                  class="nav_text"
                  :class="!isSidebarVisible && isCrmUser() ? 'hideLabel' : ''"
                  >Tasks</span
                >
              </router-link>
            </li>
          </el-tooltip>

          <el-tooltip
            class="item"
            effect="dark"
            content="Orders"
            placement="right"
            :disabled="!isCrmUser() || isSidebarVisible"
          >
            <li
              v-if="!isESOrg && !isTataOrg()"
              :class="{
                activeProject:
                  $router.currentRoute.path === '/orders' && active === false,
              }"
              @click="collapseSidebar"
            >
              <router-link :to="{ name: 'orders' }">
                <div class="nav_icon">
                  <span
                    class="icon"
                    v-if="$router.currentRoute.path === '/orders'"
                    ><img src="../../assets/drop/Group 1653.svg"
                  /></span>
                  <span class="icon" v-else
                    ><img src="../../assets/drop/Group 1794.svg"
                  /></span>
                </div>
                <span
                  class="nav_text"
                  :class="!isSidebarVisible && isCrmUser() ? 'hideLabel' : ''"
                  >Orders</span
                >
              </router-link>
            </li>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="Design Orders"
            placement="right"
            :disabled="!isCrmUser() || isSidebarVisible"
          >
          <li
            v-if="isESOrg"
            :class="{
              activeProject:
                $router.currentRoute.path === '/designOrders' &&
                active === false,
            }"
            @click="collapseSidebar"
          >
            <router-link :to="{ name: 'designOrders' }">
              <div class="nav_icon">
                <span
                  class="icon"
                  v-if="$router.currentRoute.path === '/designOrders'"
                  ><img src="../../assets/drop/Group 1653.svg"
                /></span>
                <span class="icon" v-else
                  ><img src="../../assets/drop/Group 1794.svg"
                /></span>
              </div>
              <span
                class="nav_text"
                :class="!isSidebarVisible && isCrmUser() ? 'hideLabel' : ''"
                >Design Orders</span
              >
            </router-link>
          </li>
          </el-tooltip>

          <el-tooltip
            class="item"
            effect="dark"
            content="Shift Management"
            placement="right"
            :disabled="!isCrmUser() || isSidebarVisible"
          >
          <li
            v-if="isESOrg && isESOrgAdmin"
            :class="{
              activeProject:
                $router.currentRoute.path === '/esUsers' && active === false,
            }"
            @click="collapseSidebar"
          >
            <router-link :to="{ name: 'esUsers' }">
              <div class="nav_icon">
                <span
                  class="icon"
                  v-if="$router.currentRoute.path === '/esUsers'"
                  ><img src="../../assets/drop/Group 1653.svg"
                /></span>
                <span class="icon" v-else
                  ><img src="../../assets/drop/Group 1794.svg"
                /></span>
              </div>
              <span
                class="nav_text"
                :class="!isSidebarVisible && isCrmUser() ? 'hideLabel' : ''"
                >Shift Management</span
              >
            </router-link>
          </li>
          </el-tooltip>

          <el-tooltip
            class="item"
            effect="dark"
            :content="projectText+'s'"
            placement="right"
            :disabled="!isCrmUser() || isSidebarVisible"
          >
          <li
            v-if="!isCrmUser() && !isESOrg"
            :class="{
              activeProject:
                $router.currentRoute.path === '/projects' && active === false,
            }"
            @click="collapseSidebar"
          >
            <router-link :to="{ name: 'projectListViewHome' }">
              <div class="nav_icon">
                <span
                  class="icon project"
                  v-if="$router.currentRoute.path === '/projects'"
                ></span>
                <span class="icon" v-else
                  ><img src="../../assets/drop/Group 1654.svg"
                /></span>
              </div>
              <span class="nav_text" :class="!isSidebarVisible && isCrmUser() ? 'hideLabel' : ''">{{ projectText }}s</span>
            </router-link>
          </li>
          </el-tooltip>

          <el-tooltip
            class="item"
            effect="dark"
            content="Project Management"
            placement="right"
            :disabled="!isCrmUser() || isSidebarVisible"
          >
          </el-tooltip>

          <el-tooltip
            class="item"
            effect="dark"
            content="Role Management"
            placement="right"
            :disabled="!isCrmUser() || isSidebarVisible"
          >
          </el-tooltip>

          <li
            class="sub_list"
            :class="{ active }"
            id="sublistOne"
            @click="toggleClass()"
          >
            <a href="#">
              <div class="nav_icon">
                <span class="icon" v-if="active"
                  ><img src="../../assets/drop/unnamed.png"
                /></span>
                <span class="icon" v-else
                  ><img src="../../assets/drop/Group 1874.svg"
                /></span>
              </div>
              <span
                class="nav_text"
                :class="!isSidebarVisible && isCrmUser() ? 'hideLabel' : ''"
                >Organization Settings</span
              >
            </a>
            <ul
              :class="isCrmUser() && !isSidebarVisible ? 'ulOrgCRM' : 'sub_nav'"
              v-if="active"
            >
              <li
                :class="{
                  activeProject:
                    $router.currentRoute.path === '/organisationSummary',
                }"
                @click="collapseSidebar"
              >
                <router-link
                  :to="{ name: 'organisationSummary' }"
                  :class="{
                    activeProject:
                      $router.currentRoute.path === '/organisationSummary',
                  }"
                >
                  - Organization Profile
                </router-link>
              </li>
              <li
              v-if="!isIndia && isCrmUser()"
                :class="{
                  activeProject:
                    $router.currentRoute.path === '/userManagement2',
                }"
                @click="collapseSidebar"
              >
                <router-link
                v-if="!isIndia && isCrmUser()"
                  :to="{ name: 'userManagement2' }"
                  :class="{
                    activeProject:
                      $router.currentRoute.path === '/userManagement2',
                  }"
                >
                  - User Management
                </router-link>
              </li>
              <li
                v-if="isAdmin && !isVipPowerGazebo"
                :class="{
                  activeProject: $router.currentRoute.path === '/admin',
                }"
                @click="collapseSidebar"
              >
                <router-link
                  v-if="isAdmin"
                  :to="{ name: 'admin' }"
                  :class="{
                    activeProject: $router.currentRoute.path === '/admin',
                  }"
                >
                  - Manage Component
                </router-link>
              </li>
              <li
                v-if="isAdmin"
                :class="{
                  activeProject:
                    $router.currentRoute.path === '/organisationDefaults',
                }"
                @click="collapseSidebar"
              >
                <router-link
                  v-if="isAdmin"
                  :to="{ name: 'organisationDefaults' }"
                  :class="{
                    activeProject:
                      $router.currentRoute.path === '/organisationDefaults',
                  }"
                >
                  - Design Defaults
                </router-link>
              </li>
              <li
                v-if="isCrmUser()"
                :class="{
                  activeProject:
                    $router.currentRoute.path === '/documentManagement',
                }"
                @click="collapseSidebar"
              >
                <router-link
                  v-if="isCrmUser()&&!isSOMUsers()"
                  :to="{ name: 'documentManagement' }"
                  :class="{
                    activeProject:
                      $router.currentRoute.path === '/documentManagement',
                  }"
                >
                  - Manage Documents
                </router-link>
              </li>

              <li
                v-if="isCrmUser()&&!isSOMUsers()"
                :class="{
                  activeProject: $router.currentRoute.path === '/projectTimeline',
                }"
                @click="toggleSidebarStatus"
              >
                <router-link
                  :to="{ name: 'projectTimeline' }"
                  :class="{
                    activeProject: $router.currentRoute.path === '/projectTimeline',
                  }"
                >
                  - Payment Schedule
                </router-link>
              </li>

              <li
                :class="{
                  activeProject: $router.currentRoute.path === '/customTariff',
                }"
                @click="collapseSidebar"
              >
                <router-link
                  :to="{ name: 'customTariff' }"
                  :class="[
                    'tariffClass',
                    {
                      activeProject:
                        $router.currentRoute.path === '/customTariff',
                    },
                  ]"
                >
                  - Tariff Rate
                </router-link>
              </li>
              <li
                :class="{
                  activeProject:
                    $router.currentRoute.path === '/addersAndDiscounts',
                }"
                @click="collapseSidebar"
              >
                <router-link
                  :to="{ name: 'addersAndDiscounts' }"
                  :class="{
                    activeProject:
                      $router.currentRoute.path === '/addersAndDiscounts',
                  }"
                >
                  - Adders and Discounts
                </router-link>
              </li>
              <li v-if="isAdmin && isCrmUser()"
                :class="{
                  activeProject:
                    $router.currentRoute.path === '/pipelines',
                }"
                @click="collapseSidebar"
              >
                <router-link
                  v-if="isAdmin"
                  :to="{ name: 'pipelines' }"
                  :class="{
                    activeProject:
                      $router.currentRoute.path === '/pipelines',
                  }"
                >
                  - Pipelines
                </router-link>
              </li>
              <li v-if="isAdmin && isCrmUser()"
                :class="{
                  activeProject:
                    $router.currentRoute.path === '/custom-task-list',
                }"
                @click="collapseSidebar"
              >
                <router-link
                  v-if="isAdmin"
                  :to="{ name: 'custom-task-list' }"
                  :class="{
                    activeProject:
                      $router.currentRoute.path === '/custom-task-list',
                  }"
                >
                  - Custom Task List
                </router-link>
              </li>
              <li
                :class="{
                  activeProject: $router.currentRoute.path === '/incentives',
                }"
                @click="collapseSidebar"
              >
                <router-link
                  :to="{ name: 'incentives' }"
                  :class="{
                    activeProject: $router.currentRoute.path === '/incentives',
                  }"
                >
                  - Manage Incentives
                </router-link>
              </li>
              <li
                :class="{
                  activeProject: $router.currentRoute.path === '/financials',
                }"
                @click="collapseSidebar"
              >
                <router-link
                  :to="{ name: 'financials' }"
                  :class="{
                    activeProject: $router.currentRoute.path === '/financials',
                  }"
                >
                  - Manage Financials
                </router-link>
              </li>
              <li
                :class="{
                  activeProject: $router.currentRoute.path === '/dailyConsumptionProfile',
                }"
                @click="collapseSidebar"
              >
                <router-link
                  :to="{ name: 'dailyConsumptionProfile' }"
                  :class="{
                    activeProject: $router.currentRoute.path === '/dailyConsumptionProfile',
                  }"
                >
                <div>
                  -<span>Daily Consumption</span>
                  <br/>
                  <span style="margin-left:6px">Profile Library</span>
                </div>
                  
                </router-link>
              </li>
              <li
                :class="{
                  activeProject: $router.currentRoute.path === '/credits',
                }"
                @click="collapseSidebar"
              >
                <router-link
                  :to="{ name: 'credits' }"
                  :class="{
                    activeProject: $router.currentRoute.path === '/credits',
                  }"
                >
                  - Credits
                </router-link>
              </li>
              <!-- For now the integrations page is only visible to CRM users.
              But this might change in future, for example if Nearmap is added as an external integration. -->
              <li
                v-if="isCrmUser()"
                :class="{
                  activeProject:
                    $router.currentRoute.path === '/integrations',
                }"
                @click="toggleSidebarStatus"
              >
                <router-link
                  :to="{ name: 'integrations' }"
                  :class="{
                    activeProject:
                      $router.currentRoute.path === '/integrations',
                  }"
                >
                  - External Integrations
                </router-link>
              </li>
              <li
                v-if="isCrmAvailable"
                :class="{
                  activeProject:
                    $router.currentRoute.path === '/crmsettings',
                }"
                @click="collapseSidebar"
              >
                <router-link
                  v-if="isCrmAvailable"
                  :to="{ name: 'crmsettings' }"
                  :class="{
                    activeProject:
                      $router.currentRoute.path === '/crmsettings',
                  }"
                >
                  - CRM Settings
                </router-link>
              </li>
              <li
                :class="{
                  activeProject: $router.currentRoute.path === '/siteSurveyForm',
                }"
                @click="toggleSidebarStatus"
              >
                <router-link
                  :to="{ name: 'siteSurveyForm' }"
                  :class="{
                    activeProject: $router.currentRoute.path === '/siteSurveyForm',
                  }"
                >
                  - Site Survey Form
                </router-link>
              </li>
              <!-- <li>
                          <router-link 
                            :to="{name: 'wireSizeCalculator'}" 
                            v-show="hasAccess"
                            :class="{activeProject: $router.currentRoute.path=== '/wireSizeCalculator'}"
                            class="disabled-for-now"
                          >
                            - Wire Size Calculator
                          </router-link>
                        </li> -->
            </ul>
          </li>
          <li
            class="sub_list"
            :class="{ active: activeHelpOptions }"
            id="sublist"
            @click="(activeHelpOptions = !activeHelpOptions), (active = false)"
          >
            <a href="#">
              <div class="nav_icon">
                <span class="icon help" v-if="activeHelpOptions"></span>
                <span class="icon" v-else
                  ><img src="../../assets/drop/Group 289.svg"
                /></span>
              </div>
              <span
                class="nav_text"
                :class="!isSidebarVisible && isCrmUser() ? 'hideLabel' : ''"
                >Help</span
              >
            </a>
            <ul
              :class="isCrmUser() && !isSidebarVisible ? 'ulOrgCRM' : 'sub_nav'"
              v-if="activeHelpOptions"
            >
              <li @click="collapseSidebar" v-if="isHelpDocEnabled">
                <a href="https://help.arka360.com/" target="_blank">
                  - Help Docs
                </a>
              </li>
              <li @click="collapseSidebar">
                <a @click="shortcutsDialogVisible = !shortcutsDialogVisible">
                  - Shortcuts
                </a>
              </li>
              <li
                v-if="isHelpDocEnabled"
                @click="
                  collapseSidebar();
                  toggleChatIcon();
                "
              >
                <a href="javascript:void(0)">
                  - {{ chatIconStatus }} Chat Icon
                </a>
              </li>
              <li
                v-if="!isReviewEnabled"
                @click="
                  collapseSidebar();
                  handleReviewClick();
                "
              >
                <a>
                  - Review us on G2
                </a>
              </li>
            </ul>
          </li>

          <el-tooltip
            class="item"
            effect="dark"
            content="Support Tickets"
            placement="right"
            :disabled="!isCrmUser() || isSidebarVisible"
          >
          </el-tooltip>

          <el-tooltip
            class="item"
            effect="dark"
            content="Logout"
            placement="right"
            :disabled="!isCrmUser() || isSidebarVisible"
          >
            <li
              v-if="!(isLogoutDisabled)"
              @click="
                collapseSidebar();
                logoutUser();
              "
            >
              <a>
                <div class="nav_icon">
                  <img
                    class="Group-1544"
                    src="../../assets/iconFonts/leftSideBar/Group-1544@3x.png"
                    alt=""
                  />
                  <!-- <span class="icon help"></span> -->
                </div>
                <span
                  class="nav_text"
                  :class="!isSidebarVisible && isCrmUser() ? 'hideLabel' : ''"
                  >Logout</span
                >
              </a>
            </li>
          </el-tooltip>
        </ul>
      </nav>

      <div
        @click="
          collapseSidebar();
          goToUserProfile();
        "
        class="profile_details"
      >
        <el-tooltip
          class="item"
          effect="dark"
          placement="right"
          :disabled="!isCrmUser() || isSidebarVisible"
        >
          <template v-slot:content>
            <div>{{ userName }}<br/>{{ userEmail }}</div>
          </template>
          <span class="profile_icon">
            <el-avatar v-if="userLogo"  :src="userLogo" style="background-color: white" class="avatar"></el-avatar>
            <el-avatar v-else :style="{
              backgroundColor: generateColorFromName(avtarUserName),
              color: 'white',
            }">
              {{ avtarUserName ? returnInitials(avtarUserName) : "" }}
            </el-avatar>
          </span>
        </el-tooltip>
        <div
          class="pro_text"
          :class="!isSidebarVisible && isCrmUser() ? 'hideLabel' : ''"
        >
          <h5>{{ userName }}</h5>
          <h6>{{ userEmail }}</h6>
        </div>
      </div>
    </aside>
    <el-dialog
      :visible.sync="shortcutsDialogVisible"
      append-to-body
      :close-on-click-modal="false"
      title="Shortcuts"
      width="30%"
      height="66%"
    >
      <div class="data-summary">
        <p v-if="isVipPowerGazebo">
          PowerGazebo™
          <span class="output"> G </span>
        </p>
        <p v-if="isVipPowerGazebo">
          PowerPatio™
          <span class="output"> Shift + G </span>
        </p>
        <p>
          Flat Roof
          <span class="output"> P </span>
        </p>
        <p>
          Pitched Roof
          <span class="output"> R </span>
        </p>
        <p>
          Draw Face
          <span class="output"> Shift + F </span>
        </p>
        <p>
          Pen Tool
          <span class="output"> Shift + A </span>
        </p>
        <p>
          Polygon Obstruction
          <span class="output"> Shift + P </span>
        </p>
        <p>
          Rectangle Obstruction
          <span class="output"> Shift + R </span>
        </p>
        <p>
          Cylinder Obstruction
          <span class="output"> C </span>
        </p>
        <p>
          Walkway
          <span class="output"> W </span>
        </p>
        <p>
          Safety Line
          <span class="output"> E </span>
        </p>
        <p>
          Handrail
          <span class="output"> H </span>
        </p>
        <p>
          Property Line
          <span class="output"> N </span>
        </p>
        <p>
          Tree
          <span class="output"> T </span>
        </p>
        <p>
          Dimension Tool
          <span class="output"> D </span>
        </p>
        <p>
          Lasso Tool
          <span class="output"> L </span>
        </p>
        <p v-if="!isVipPowerGazebo">
          Subarray
          <span class="output"> S </span>
        </p>
        <p v-if="isUSFlagEnabled && !isVipPowerGazebo">
          PowerGazebo™
          <span class="output"> G </span>
        </p>
        <p v-if="isUSFlagEnabled && !isVipPowerGazebo">
          PowerPatio™
          <span class="output"> Shift + G </span>
        </p>
        <p>
          Text Box
          <span class="output"> B </span>
        </p>
        <p v-if="!isVipPowerGazebo">
          Fill face the selected Model
          <span class="output"> F </span>
        </p>
        <p v-if="!isVipPowerGazebo">
          Optimize the selected Subarray
          <span class="output"> O </span>
        </p>
        <p v-if="!isVipPowerGazebo">
          Inverter Menu
          <span class="output"> I </span>
        </p>
        <p>
          Mirror Image
          <span class="output"> M </span>
        </p>
        <p>
          Cancel drawing/placing
          <span class="output"> Esc </span>
        </p>
        <p>
          Discard properties changes
          <span class="output"> Esc </span>
        </p>
        <p>
          Complete drawing/placing
          <span class="output"> Enter </span>
        </p>
        <p>
          Accept change
          <span class="output"> Enter </span>
        </p>
        <p>
          Delete selected object
          <span class="output"> Del </span>
        </p>
        <p>
          Undo
          <span class="output"> Ctrl / Cmd + Z </span>
        </p>
        <p>
          Redo
          <span class="output"> Ctrl / Cmd + Shift + Z </span>
        </p>
        <p>
          Save
          <span class="output"> Ctrl / Cmd + S </span>
        </p>
        <p>
          Quick Look
          <span class="output"> Q </span>
        </p>
        <p>
          X-Ray mode
          <span class="output"> Press and Hold 'X' </span>
        </p>
        <p>
          Zoom In
          <span class="output"> Ctrl / Cmd + Alt + Plus(+) </span>
        </p>
        <p>
          Zoom Out
          <span class="output"> Ctrl / Cmd + Alt + Minus(-) </span>
        </p>
        <p>
          Default Zoom
          <span class="output"> Ctrl / Cmd + Alt + Zero(0) </span>
        </p>
        <p>
          2D mode
          <span class="output"> Double Press '2' </span>
        </p>
        <p>
          3D mode
          <span class="output"> Double Press '3' </span>
        </p>
        <p>
          SLD mode
          <span class="output"> Double Press '4' </span>
        </p>
        <p v-if="!isVipPowerGazebo">
          Add Table Mode
          <span class="output"> A </span>
        </p>
        <p v-if="!isVipPowerGazebo">
          Delete Table Mode
          <span class="output"> Shift + D </span>
        </p>
      </div>
    </el-dialog>
  </main>
</template>

<script>
import API from "@/services/api/";
import {
  isHelpDocEnabled,
  isLogoutDisabled,
} from "../../constants";
import { mapState, mapActions } from "pinia";
import { useAuthStore } from "../../stores/auth";
import { useRootStore } from "../../stores/root";
import { useReviewStore } from "../../stores/review"
import {
  isCrmUser,
  isRelianceOrg,
  isExpertServicesOrg,
  isAdmin,
  isUserOfRole,
  generateColorFromName,
  returnInitials,
  getUserFromLocalStorage,
  getOrganisationFromLocalStorage,
  isVIP,
  isProjectManager,
  isInternalSales,
  isSalesAdmin,
  isSalesOps,
  
} from "../../utils";
import { useUiStore } from "../../stores/ui";
import { isTataOrg } from '../../utils.js';
import {isSOMUsers} from "../../pages/utils/l2c2cRoles.js";

function listnerForToggle(e) {
  if (
    document.getElementsByClassName("humburger")[0]?.contains(e.target) ||
    document.getElementsByClassName("humburgerCRM")[0]?.contains(e.target) ||
    document.getElementsByClassName("aside_setion")[0]?.contains(e.target) ||
    document.getElementsByClassName("aside_setionCRM")[0]?.contains(e.target)
  ) {
  } else {
    if (this.isSidebarVisible) {
      useRootStore().toggleSidebar();
    }
  }
}

export default {
 
  data() {
    return {
      isHelpDocEnabled,
      isLogoutDisabled,
      country: null,
      active: false,
      hasAccess: true,
      activeProject: false,
      userEmail: "",
      avtarUserName:'',
      userName: "",
      userLogo:"",
      role: getUserFromLocalStorage().role,
      avtarColor:'',
      activeHelpOptions: false,
      shortcutsDialogVisible: false,
      isVipPowerGazebo: false,
      projectText: "Project",
    };
  },
  created() {
    this.setIntercomToggleButtonStatus()
    this.defineOrganisationSettings();
  },
  async mounted() {
    this.isVipPowerGazebo = await this.setGazeboStatus();
    this.fetchUserProfile();
    window.addEventListener("user-logo", (event) => {
      this.userLogo=event.detail.image;
      this.avtarColor=event.detail?.avatar_color?event.detail?.avatar_color:this.avtarColor
      this.userEmail=event.detail.email;
      this.userName = event.detail.firstName + " " + event.detail.lastName;
      this.avtarUserName= event.detail.firstName + " " + event.detail.lastName;
    });
    let exceptClasses = [".humburgerCRM", ".aside_setionCRM"];

    let vm = this;
    window.addEventListener("click", listnerForToggle.bind(vm));

    window.addEventListener(
      "click",
      function (e) {
        let toggle = document.getElementById("sublistOne");
        if (!toggle) {
          return;
        }

        if (toggle?.contains(e.target)) {
        } else {
          this.active = false;
        }
      }.bind(vm)
    );
    this.navbarClassFunc();
  },

  destroyed() {
    let vm = this;
    window.removeEventListener("click", listnerForToggle.bind(vm));
  },

  computed: {
    ...mapState(useReviewStore, {
      isReviewed: state => state.isReviewed
    }),
    ...mapState(useUiStore, {
      chatIconStatus: "intercomIconStatus",
    }),
    isReviewEnabled(){
      return this.isReviewed
    },
    isAdmin,
    isESOrg() {
      return isExpertServicesOrg()
    },
    isReliance(){
      if (isRelianceOrg()) {
        return true;
      }
      return false;
    },
    isESOrgAdmin() {
      if (isExpertServicesOrg() && isAdmin()) {
        return true;
      }
      return false;
    },
    isUSFlagEnabled() {
      const user = JSON.parse(localStorage.getItem("user")) || {};
      return user.isUSFlagEnabled;
    },
    isIndia(){
      return [91].includes(this.country);
    },
    isSidebarVisible() {
      return useRootStore().sidebarStatus;
    },

    isCrmAvailable(){
      if (isCrmUser() && isAdmin()) {
        return true
      }

      return false
    }
  },
  methods: {
    ...mapActions(useAuthStore, ["LOGOUT"]),
    ...mapActions(useReviewStore, ["SET_REVIEW_DETAILS", "SET_REVIEW_STATUS"]),
    ...mapActions(useUiStore, [
      "toggleChatIcon",
      "setIntercomToggleButtonStatus",
    ]),

    isTataOrg,
    isSOMUsers,
    async handleReviewClick(){
       let err = await this.SET_REVIEW_STATUS(true, true)
       if (err) {
        this.$message({
          showClose: true,
          message: "Error Occurred!! Try after sometime.",
          type: "error",
          center: true,
        });
       }
    },
    navbarClassFunc() {
      if (isCrmUser()) {
        if (this.isSidebarVisible) {
          return "navbarOverflow";
        } else {
          return;
        }
      } else {
        return "navbarOverflow";
      }
    },
    goToUserProfile() {
      this.$router.push({ name: "profile" });
    },
    async setGazeboStatus() {
      const user = JSON.parse(localStorage.getItem("user")) || {};
      let organisationId = user.organisation_id;
      let responseData = getOrganisationFromLocalStorage();
      if (
        !(
          Object.keys(responseData).length &&
          responseData.hasOwnProperty("vip_for_power_gazebo")
        )
      ) {
        responseData = (
          await API.ORGANISATION.FETCH_ORGANISATION(organisationId)
        ).data;
      }
      return Promise.resolve(responseData.vip_for_power_gazebo);
    },
    async fetchUserProfile() {
      const user = JSON.parse(localStorage.getItem("user")) || {};
      const organisation = getOrganisationFromLocalStorage();
      const user_id = user.user_id;
      this.country = organisation.country;
      try {
        const response = await API.USERS.FETCH_USER(user_id);
        this.SET_REVIEW_DETAILS(response.data)
        let resultUserProfile = response.data;
        this.userLogo=resultUserProfile.image
        this.avtarColor=resultUserProfile.avatar_color
        this.userName =
          resultUserProfile.first_name + " " + resultUserProfile.last_name;
        this.avtarUserName =
          resultUserProfile.first_name + " " + resultUserProfile.last_name;  
        this.userEmail = resultUserProfile.email;
        if (this.userName.length > 18) {
          this.userName = this.userName.substring(0, 17) + "...";
        }
        if (this.userEmail.length > 26) {
          this.userEmail = this.userEmail.substring(0, 25) + "...";
        }
      } catch (e) {
        console.error(e)
      }
    },
    toggleSidebarStatus() {
      useRootStore().toggleSidebar();
    },
    collapseSidebar() {
      useRootStore().collapseSidebar();
    },
    defineOrganisationSettings() {
      if (this.$router.currentRoute.path === "/") {
        this.active = false;
      }
      if (
        this.$router.currentRoute.path === "/organisationSummary" ||
        this.$router.currentRoute.path === "/admin" ||
        this.$router.currentRoute.path === "/organisationDefaults" ||
        this.$router.currentRoute.path === "/incentives" ||
        this.$router.currentRoute.path === "/financials" ||
        this.$router.currentRoute.path === "/customTariff"
      ) {
        if (!this.isCrmUser()) this.active = true;
      }
    },

    toggleClass() {
      if (this.$router.currentRoute.path === "/") {
        this.active = !this.active;
        this.activeHelpOptions = false;
        this.activeProject = !this.activeProject;
      } else {
        this.activeHelpOptions = false;
        this.active = !this.active;
      }
      // this.activeProject = !this.activeProject;
    },
    async logoutUser() {
      try {
        await this.LOGOUT();
        this.$router.push({ name: "login" });
      } catch (e) {
        console.error(e);
      }
    },
    isSOMPageRequired(){
return isCrmUser()&&( isSalesOps()||isVIP()||isProjectManager()||isSalesAdmin()||isInternalSales());
    },
    somTitle(){
return isVIP()?'Solution Orders':isProjectManager()?'Orders and Payments' :(isSalesAdmin()||isInternalSales())?"Leads Contract" :'Orders and Payments';
    },
    isCrmUser,
    isUserOfRole,
    generateColorFromName,
    returnInitials,
    isVIP,
    isProjectManager,
    isInternalSales,
    isSalesAdmin,
    isSalesOps,
  },
};
</script>
<style type="text/css" scoped>
@import url(../../assets/ico-font/ico-font.css);


.tariffClass {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}
* {
  box-sizing: border-box;
  margin: 0;
  font-family: var(--font);
}
a {
  cursor: pointer;
  text-decoration: none;
}
a:focus {
  outline: none;
  background-color: transparent;
}
a:active,
a:hover {
  outline: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}
ul {
  padding: 0;
  list-style: none;
}
.aside_setion {
  width: 260px;
  height: 100%;
  left: 0;
  top: 100px;
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 431;
  background: var(--white);
  margin-top: 1px;
}
.el-avatar >>> img {
    display: block;
    height: inherit;
    /* vertical-align: middle; */
}
.el-avatar {
    display: inline-block;
    box-sizing: border-box;
    text-align: center;
    overflow: hidden;
    color: #fff;
    background: #466cbe;
    width: 45px;
    height: 45px;
    line-height: 45px;
    font-size: 14px;
}
.aside_setionCRM {
  width: 74px;
  transition: all ease-in-out 0.4s;
  top: 100px;
}

.main-controller.expended .aside_setionCRM {
  width: 260px;
  box-shadow: 0 40px 40px 0 var(--medium);
}

@media (max-width: 1280px) {
  .aside_setion {
    top: 100px;
  }
}

@media (max-width: 1280px) {
  .aside_setion {
    transform: translateX(-100%);
    transition: all ease-in-out 0.4s;
    top: 100px;
  }
}

.main-controller.expended .backdropCRM {
  opacity: 1;
  visibility: visible;
  z-index: 21;
}

@media (max-width: 1280px) {
  .main-controller.expended .backdrop {
    opacity: 1;
    visibility: visible;
    z-index: 21;
  }
  .main-controller.expended .aside_setion {
    transform: translateX(0);
    box-shadow: 0 40px 40px 0 var(--medium);
  }
}
.main-controller .right_section {
  background: var(--step-50);
}
@media (min-width: 1281px) {
  .main-controller .right_section {
    width: calc(100% - 260px);
    margin-left: auto;
  }
}
.main-controller .backdrop {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: all ease-in-out 0.35s;
  visibility: hidden;
  z-index: -21;
}
.navbar {
  flex-grow: 1;
  max-height: calc(100vh - 185px);
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow: inherit;
}

.navbarOverflow {
  overflow-y: scroll !important;
}
.navbar::-webkit-scrollbar {
  width: 0 !important;
}
@media (max-width: 767px) {
  .navbar {
    max-height: calc(100vh - 162px);
  }

  .aside_setion {
    top: 56px;
  }
}
.navbar .nav_list {
  height: 100%;
  border-right: 1px solid var(--step-100);
}
.navbar .nav_list li {
  position: relative;
  border-bottom: 1px solid var(--step-100);
}
.navbar .nav_list li:before {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  left: 0;
  background: var(--step-50);
  transition: all ease-in-out 0.4s;
  display: block;
  top: 0;
  z-index: 0;
}
.navbar .nav_list li > a {
  display: flex;
  width: 100%;
  padding: 16px 24px;
  position: relative;
  z-index: 1;
  font-size: var(--f16);
  color: var(--step-250);
  font-weight: normal;
  align-items: center;
}
.navbar .nav_list li > a .nav_icon {
  min-width: 24px;
  max-width: 24px;
  height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  color: inherit;
}
.navbar .nav_list li > a .nav_icon span {
  font-size: 22px;
  color: inherit;
}
.nav_text {
  font: inherit;
  transition: all ease-in-out 0.4s;
  animation: fadeIn 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.navbar .nav_list li:hover:before,
.navbar .nav_list li.active:before {
  width: calc(100% + 1px);
}
.navbar .nav_list li:hover a,
.navbar .nav_list li.active a {
  color: var(--primary);
}
.navbar .nav_list li.activeProject a {
  color: var(--primary);
}
.navbar .nav_list li.activeProject {
  background-color: #e8edf2;
  width: calc(100% + 1px);
}

.navbar .nav_list li.active a {
  font-weight: 500;
}

.sub_list {
  position: relative;
}
.ulOrgCRM {
  position: absolute;
  top: 0px;
  left: 74px;
  background-color: #fff;
  max-height: calc(100vh - 25.5rem) !important;
  overflow-y: auto;
  overflow-x: hidden;
  width: 240px;
  border: 1px solid #ccc;
}

.navbar .nav_list li.sub_list:before:hover {
  width: calc(100% + 0px);
}
.navbar .nav_list li.sub_list > a {
  position: relative;
  transition: all ease-in-out 0.3s;
}
.navbar .nav_list li.sub_list > a:after {
  content: "";
  width: 7px;
  height: 7px;
  border-right: 2px solid var(--primary);
  border-bottom: 2px solid var(--primary);
  transform: rotate(-45deg);
  position: absolute;
  right: 14px;
  top: calc(50% - 5px);
  transition: all ease-in-out 0.3s;
}
.navbar .nav_list li.sub_list .sub_nav {
  padding-left: 65px;
  position: relative;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transform: scaleY(0);
  transform-origin: top center;
  transition: all ease-in-out 0.2s;
}
.navbar .nav_list li.sub_list .sub_nav li {
  border-top: 1px solid var(--step-100);
  border-bottom: 0;
}
.navbar .nav_list li.sub_list .sub_nav li a {
  padding: 16px 24px 16px 0;
  position: relative;
  z-index: 1;
  font-size: var(--f16);
  color: var(--step-250);
  font-weight: normal;
  display: block;
}
.navbar .nav_list li.sub_list .sub_nav li:hover:before,
.navbar .nav_list li.sub_list .sub_nav li.active:before {
  width: calc(100% + 1px);
}

.navbar .nav_list .sub_list .sub_nav li:hover a {
  color: var(--primary);
}

.navbar .nav_list .sub_list .sub_nav a.activeProject {
  color: var(--primary);
}

.navbar .nav_list .sub_list .sub_nav li.activeProject {
  width: calc(100% + 1px);
}

.navbar .nav_list li.sub_list.active:before {
  width: calc(100% + 0px);
}
.navbar .nav_list li.sub_list.active a:after {
  transform: rotate(45deg);
}
.navbar .nav_list li.sub_list.active .sub_nav {
  opacity: 1;
  visibility: visible;
  transform: scaleY(1);
}

.Group-1544 {
  width: 24px;
  height: 24px;
}

@media (max-width: 1280px) {
  .profile_details {
    margin-bottom: 24% !important;
  }
}

.profile_details {
  margin-bottom: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 16px 32px 16px 24px;
  border-top: 1px solid var(--step-100);
  position: relative;
  cursor: pointer;
  border-right: 1px solid var(--step-100);
}
.profile_details:after {
  content: none !important  ;
  width: 7px;
  height: 7px;
  border-right: 2px solid var(--primary);
  border-bottom: 2px solid var(--primary);
  transform: rotate(-45deg);
  position: absolute;
  right: 14px;
  top: calc(50% - 3px);
  transition: all ease-in-out 0.3s;
}
.profile_details:hover:after {
  right: 12px;
}
.profile_details .profile_icon {
  min-width: 48px;
  max-width: 48px;
  margin-right: 0px;
}
.profile_details .pro_text {
  flex-grow: 1;
  max-width: calc(100% - 64px);
}
.profile_details .pro_text h5 {
  font-size: var(--f14);
  font-weight: 500;
  color: var(--step-250);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.profile_details .pro_text h6 {
  font-size: var(--f12);
  font-weight: 500;
  color: var(--step-200);
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 2px;
}

.disabled-for-now {
  pointer-events: none;
  cursor: not-allowed;
}
.data-summary {
  padding-bottom: 10px;
}
.data-summary p {
  color: black;
  text-align: left;
  font-size: 16px;
  font-weight: 100;
  overflow: hidden;
  text-overflow: ellipsis !important;
  padding-top: 2%;
  border-bottom: 1px solid lightgray;
  padding-bottom: 2%;
}
.output {
  font-size: 16px;
  color: black;
  box-sizing: content-box;
  display: block;
  float: right;
  white-space: nowrap;
  text-overflow: ellipsis !important;
}
.el-dialog__wrapper >>> .el-dialog {
  border-radius: 16px;
  min-width: 450px;
}
.el-dialog__wrapper >>> .el-dialog__header {
  background-image: linear-gradient(to bottom, #e8edf2, #e9ecf2);
  display: flex;
  justify-content: space-between;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  margin-bottom: 0px;
}
.el-dialog__wrapper >>> .el-dialog__title {
  color: black !important;
}

.el-dialog__wrapper >>> .el-dialog__body {
  max-height: 75vh;
  overflow: hidden;
  overflow-y: scroll;
  padding: 10px !important;
}

.hideLabel {
  display: none;
  transition: all ease-in-out 0.4s;
}

@media only screen and (max-width: 500px) {
  .el-dialog__wrapper >>> .el-dialog {
    border-radius: 16px;
    min-width: 90vw;
  }
}
</style>
