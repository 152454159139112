import { defineStore } from "pinia";

export const useDashboardStore = defineStore("dashboard", {
  state: () => {
    return {
      dashboardupdate: "",
    };
  },
  getters: {
    GET_DASHBOARD_UPDATE_DETAILS: (state) => state,
  },
  actions: {
    async SET_DASHBOARD_DETAILS(data) {
      console.log(data);
      this.dashboardupdate = data;
    },
  },
});
