/* eslint-disable max-len */
/* eslint-disable prefer-destructuring */
import * as THREE from 'three';
import * as utils from '../../../utils/utils';
import { Vector3 } from 'three';
import SmartroofFace from './SmartroofFace';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry';
import { Line2 } from 'three/examples/jsm/lines/Line2';
import Edge from './Edge';
import createBufferGeometry from '../../../utils/meshUtils';
import img from '../../../../assets/img/arrow.png';
import azimuthArrowLeft from '../../../../components/ui/saleStudio/assets/azimuthArrowLeft.svg';
import primaryEdgeArrow from '../../../../components/ui/saleStudio/assets/primaryEdgeArrow.png';
import {arrayOfAzimuthNumberImages} from '../../../../components/ui/saleStudio/azimuthNumbersimages';
import { CAMERA_UPDATED } from '../../../coreConstants';
import { convertImperialToMetric, isMetricUnit, parseImperialMeasurement } from '../../../../components/ui/length/utils';

const ARROW_TRANSPARENCY = 0.8;
const ARROW_HIGHLIGHT_SCALE = 1.2;

export default class OuterEdge extends Edge {
    constructor(parent, stage, outlinePoint1 = null, outlinePoint2 = null, outlinePoint1Index, outlinePoint2Index, height = null, isPitched = true, tilt = 20, smartRoofFace = null) {
        super(parent, stage, outlinePoint1, outlinePoint2, true, outlinePoint1Index, outlinePoint2Index, !parent.isTemplate && !parent.isTurret);
        // this.belongsTo = parent;
        // this.parent = parent;
        // this.stage = stage;
        this.isPitched = isPitched;
        this.tilt = tilt;
        this.defaultTilt = this.parent.defaultTilt;
        this.height = height;
        // if (this.stage.mode == 'salesMode'){
        //     this.salesEdge = '';
        //     this.setback = 0.4572;
        // }

        // azimuth direction - shilpa
        // if (this.stage.mode == 'salesMode'){
        //     this.salesEdge = '';
        //     this.setback = 0.4572;
            this.azimuthDirectionFlag = true;
        // }
        // this.outlinePoint1 = outlinePoint1;
        // this.outlinePoint2 = outlinePoint2;
        this.outlinePoint1Index = outlinePoint1Index;
        this.outlinePoint2Index = outlinePoint2Index;
        // this.point1 = this.outlinePoint1.getPosition();
        // this.point2 = this.outlinePoint2.getPosition();
        this.defaultColor = 0xF0FFFF;
        // Neon sky blue color for highlighted edges
        this.highlightColor = 0x7DF9FF;

        if (!smartRoofFace) {
            this.smartRoofFace = new SmartroofFace(stage, [], [], this.getValidTilt(tilt), height, [[]], null, this);
        }
        else {
            this.smartRoofFace = smartRoofFace;
            smartRoofFace.outerEdge = this;
        }
        this.id = this.smartRoofFace.id;
        this.wallId = `${this.id}wall`;
        this.objectsGroup = new THREE.Group();
        this.objectsGroup.container = this;
        this.stage.sceneManager.scene.add(this.objectsGroup);
        this.highlightedLine = new Line2();
     
        this.highlightedLine.geometry = new LineGeometry();
        this.objectsGroup.name = 'Outer Edge';
        this.otherObject = [];
        this.shrinkPaths = [];
        this.children= [];
      
        this.coupledEdges = [];
        this.isPrimaryEdge = false;
    }

    loadFace(face) {
        this.smartRoofFace = face;
        this.id = this.smartRoofFace.id;
        this.wallId = `${this.id}wall`;
        this.updateFacePlane();
        this.updateWallPlane();
        this.makeOuterEdge();
    }

    makeOuterEdge() {
        this.makeEdgeGeometry();
        this.makeEdgeMaterial();
        this.makeEdgeMesh();
    }
    hideObject() {
        if (this.measurementText) this.measurementText.hideObject();
        this.objectsGroup.visible = false;
        if(this.azimuthSelection)this.azimuthSelection.visible = false;
        this.stage.eventBus.removeEventListener(CAMERA_UPDATED, this.cameraUpdate);

    }

    showObject() {
        if (this.status && !this.stage.visualManager.in3D) {
            if(this.belongsTo?.connectedPowerRoof) {
                if (this.measurementText) {
                    this.measurementText.showObject();
                    this.measurementText.editable = false;
                }
            } else {
                if (this.measurementText && this.stage.viewManager.lengthVisible) this.measurementText.showObject();
            }
            this.objectsGroup.visible = true;
        }
        if(this.azimuthSelection){
            this.azimuthSelection.visible = true;
            this.updateScale();
        }
        this.stage.eventBus.addEventListener(CAMERA_UPDATED, this.cameraUpdate);

    }
    cameraUpdate = () => {
        this.stage.addCameraUpdates(this.updateScale);
    }

    // updateScale = () => {
    //     const zoom = 1/this.stage.getNormalisedZoom() * 10;
    //     let zoomFactor = Math.min(zoom, 1.3);
    //     this.azimuthSelection.scale.set(zoomFactor, zoomFactor, 1); 

    //     const textHeightScaled = 0.45 * this.measurementText.textMesh.scale.x;
    //     const arrowHeightScaled = 1.1 * this.azimuthSelection.scale.x;
    //     const offsetMagnitude = arrowHeightScaled / 2 + textHeightScaled;

    //     const outlineP1 = this.outlinePoint1.getPosition();
    //     const outlineP2 = this.outlinePoint2.getPosition();
    //     const centerPos = new THREE.Vector3(outlineP1.x, outlineP1.y, 20).add(new THREE.Vector3(outlineP2.x, outlineP2.y, 20)).multiplyScalar(0.5);
        
    //     const normalDirection = this.getInwardHorizontalNormal();
    //     const newPosition = centerPos.add(normalDirection.multiplyScalar(-offsetMagnitude));

    //     if(!this.isPrimaryEdge) {
    //         this.azimuthSelection.position.set(newPosition.x ,newPosition.y ,20);
    //     }
    //     else {
    //         const centroid = this.parent.outlinePoints.reduce((acc, cur) => acc.add(cur.getPosition()), new THREE.Vector3()).divideScalar(this.parent.outlinePoints.length);
    //         this.azimuthSelection.position.set(centroid.x ,centroid.y ,20);
    //     }

    //     const boundingSphereExists = this.azimuthSelection.geometry.boundingSphere !== null;
    //     const boundingSphereRadius = boundingSphereExists ? this.azimuthSelection.geometry.boundingSphere.radius * zoom * 3 : 0;

    //     if(boundingSphereExists) {
    //         this.azimuthSelection.visible = boundingSphereRadius < this.measurementText.length;
    //     }
    // }


    makeEdgeGeometry() {
        const EPSILON = 5;
        this.outerEdgeGeometry = createBufferGeometry();
        const raisedPoint1 = new Vector3(this.point1.x, this.point1.y, this.point1.z + EPSILON);
        const raisedPoint2 = new Vector3(this.point2.x, this.point2.y, this.point2.z + EPSILON);
        // this.outerEdgeGeometry.vertices.push(raisedPoint1);
        // this.outerEdgeGeometry.vertices.push(raisedPoint2);
        this.outerEdgeGeometry.setFromPoints([raisedPoint1, raisedPoint2]);
        if (this.highlightedLine) {
            const points = [];
            points.push(raisedPoint1.x, raisedPoint1.y, raisedPoint1.z);
            points.push(raisedPoint2.x, raisedPoint2.y, raisedPoint2.z);
            this.highlightedLine.geometry.setPositions(points);
        }
    }

    makeEdgeMaterial() {
        // make blue outer edges
        this.outerEdgeMaterial = new THREE.LineBasicMaterial({
            color: 0xF0FFFF,
            linewidth: 1,
        });
        if (this.highlightedLine) { 
            this.highlightedLine.material = new LineMaterial({
            color: new THREE.Color(this.highlightColor),
            linewidth: 0.0001,
            });
            this.updateHighlightResolution();
        }
    }


    getPerpendicularPoint(vector1, vector2, scalar = -0.7) {
        // Calculate the midpoint of the line
        const midpoint = vector1.add(vector2).multiplyScalar(0.5);
      
        const crossProduct = new THREE.Vector3().crossVectors(vector1, vector2);

        // The cross product gives a vector that is perpendicular to both input vectors
        const perpendicular = crossProduct.clone().normalize();
      
        // Multiply the perpendicular vector by 1 and add it to the midpoint to get the final point
        const finalPoint = new THREE.Vector3().addVectors(midpoint, perpendicular.multiplyScalar(scalar));
      
        return finalPoint;
    }
      

    // makeAzimuthSelection() {
    //     const geometry = new THREE.PlaneGeometry(1, 1);
    //     const textureLoader = new THREE.TextureLoader();
    //     const texture = textureLoader.load(img);
    //     // Create a new Mesh object with the geometry and a basic material
    //     const material = new THREE.MeshStandardMaterial({map: texture, transparent: true, opacity: ARROW_TRANSPARENCY});
    //     const mesh = new THREE.Mesh(geometry, material);
    //     return mesh;
    // }
    // makePrimaryEdgeSelectionSalesMode() {
    //     const geometry = new THREE.CircleGeometry(0.3,20);
    //     const textureLoader = new THREE.TextureLoader();
    //     const texture = textureLoader.load(azimuthArrowLeft);
    //     // Create a new Mesh object with the geometry and a basic material
    //     const material = new THREE.MeshBasicMaterial({map: texture});
    //     material.opacity = .5
    //     const mesh = new THREE.Mesh(geometry, material);
    //     return mesh;
    // }
    // makePrimaryEdgeNumbersSalesMode() {
    //     const totalEdge = this.belongsTo.outlinePoints.length - 1;
    //     const geometry = new THREE.CircleGeometry(0.5, 15, .00001);
    //     const textureLoader = new THREE.TextureLoader();
    //     const texture = textureLoader.load(arrayOfAzimuthNumberImages[this.outlinePoint1Index]);
    //     // Create a new Mesh object with the geometry and a basic material
    //     const material = new THREE.MeshBasicMaterial({map: texture});
    //     material.opacity = .5
    //     const mesh = new THREE.Mesh(geometry, material);
    //     return mesh;

    // }
    // makePrimaryEdgeArrow() {
    //     const geometry = new THREE.CircleGeometry(0.35,20, .00001);
    //     const textureLoader = new THREE.TextureLoader();
    //     const texture = textureLoader.load(primaryEdgeArrow);
    //     // cover(texture, 0.5)
    //     // Create a new Mesh object with the geometry and a basic material
    //     const material = new THREE.MeshBasicMaterial({color: 0xffffff,
    //         map: texture,
    //     });
    //     material.opacity = .5
    //     const mesh = new THREE.Mesh(geometry,material);
    //     return mesh;
    // }
    // showArrows() {
    //     this.azimuthSelectionNumber.visible = false;
    //     this.azimuthSelection.visible = true;
    // }
    // hideArrows() {
    //     this.azimuthSelectionNumber.visible = true;
    //     this.azimuthSelection.visible = false;
    // }

    // getPerpendicularPoint(vector1, vector2) {
    //     // Calculate the midpoint of the line
    //     const midpoint = vector1.add(vector2).multiplyScalar(0.5);
      
    //     const crossProduct = new THREE.Vector3().crossVectors(vector1, vector2);

    //     // The cross product gives a vector that is perpendicular to both input vectors
    //     const perpendicular = crossProduct.clone().normalize();
    //     console.log('perpendicular: ', perpendicular);
      
    //     // Multiply the perpendicular vector by 1 and add it to the midpoint to get the final point
    //     const finalPoint = new THREE.Vector3().addVectors(midpoint, perpendicular.multiplyScalar(-2));
      
    //     return finalPoint;
    // }
    // updatePrimaryEdgeArrow() {
    //     const outlineP1 = this.outlinePoint1.getPosition();
    //     const outlineP2 = this.outlinePoint2.getPosition();
    //     const centerPos =new THREE.Vector3(outlineP1.x,outlineP1.y,20).add(new THREE.Vector3(outlineP2.x,outlineP2.y,20)).multiplyScalar(0.5);
    //     const crossProduct = new THREE.Vector3().crossVectors(outlineP1, outlineP2);

    //     // The cross product gives a vector that is perpendicular to both input vectors
    //     const perpendicular = crossProduct.clone().normalize();
      
    //     // Multiply the perpendicular vector by 1 and add it to the midpoint to get the final point
    //     const finalPoint = new THREE.Vector3().addVectors(centerPos, perpendicular.multiplyScalar(0.48))

    //     this.primaryEdgeArrow.position.set(finalPoint.x ,finalPoint.y ,20);  

    // //     let  direction = this.outlinePoint1.getPosition().clone().sub(this.outlinePoint2.getPosition().clone()).normalize();
    // //     // Create a new Euler object and set its rotation to align with the direction vector
    // //     const euler =  direction;

    //     // this.azimuthSelection.position.set(centerPos.x ,centerPos.y ,20);
    //     // this.primaryEdgeArrow.position.set(centerPos.x ,centerPos.y ,20);

    // //     // Normalize the direction vector
    // //     direction.normalize();

    // //     // Calculate the rotation axis
    // //     var rotationAxis = new THREE.Vector3();
    // //     rotationAxis.crossVectors(new THREE.Vector3(0, 1, 0), direction);
    // //     rotationAxis.normalize();
    // //     rotationAxis.negate();

    // //     // Calculate the angle of rotation
    // //     var angle = Math.acos(new THREE.Vector3(0, 1, 0).dot(direction));

    // //     // Create a quaternion rotation
    // //     var quaternion = new THREE.Quaternion();
    // //     quaternion.setFromAxisAngle(rotationAxis, Math.PI- angle);

    //     // Apply the rotation to the box mesh
    //     this.primaryEdgeArrow.setRotationFromQuaternion(quaternion);
    // }
    // updateAzimuthSelectionArrow() {
    //     const outlineP1 = this.outlinePoint1.getPosition();
    //     const outlineP2 = this.outlinePoint2.getPosition();
    //     const centerPos = this.getPerpendicularPoint(new THREE.Vector3(outlineP1.x,outlineP1.y,20),new THREE.Vector3(outlineP2.x,outlineP2.y,20));

    //     if(!this.isPrimaryEdge) {
    //         this.azimuthSelection.position.set(centerPos.x ,centerPos.y ,20);
    //         this.azimuthSelection.scale.set(1,1,1);
    //     }
    //     else {
    //         const parentvertices = [];
    //         this.parent.outlinePoints.forEach(outlinePoint => {
    //             parentvertices.push(outlinePoint.getPosition())
    //         })
    //         const centroid = this.findCentroid(parentvertices);
    //         this.azimuthSelection.position.set(centroid.x ,centroid.y ,20);
    //     }

    //     const rotationAxis = new THREE.Vector3(0,0,1);
    //     const rotationAngle = utils.deg2Rad(this.getArrowAngle());

    //     // Create a quaternion rotation
    //     const quaternion = new THREE.Quaternion();
    //     quaternion.setFromAxisAngle(rotationAxis, rotationAngle);
    //     // Apply the rotation to the box mesh
    //     this.azimuthSelection.setRotationFromQuaternion(quaternion);
    //     if (this.clicked) {
    //         if(!this.azimuthDirectionFlag){
    //             this.azimuthSelection.rotation.z += Math.PI;
    //         }
    //     }

    // }
    // updateSalesModeArrow() {
    //     const check = utils.checkClockwise([this.outlinePoint1.getPosition(),this.outlinePoint2.getPosition()]);
    //     const outlineP1 = this.outlinePoint1.getPosition();
    //     const outlineP2 = this.outlinePoint2.getPosition();
    //     const centerPos = this.getPerpendicularPoint(new THREE.Vector3(outlineP1.x,outlineP1.y,20),new THREE.Vector3(outlineP2.x,outlineP2.y,20));
    //     let  direction = this.outlinePoint1.getPosition().clone().sub(this.outlinePoint2.getPosition().clone()).normalize();
    //     // Create a new Euler object and set its rotation to align with the direction vector
    //     const euler =  direction;
    //     this.azimuthSelection.position.set(centerPos.x ,centerPos.y ,20);
    //     this.azimuthSelectionNumber.position.set(centerPos.x ,centerPos.y ,20);
    //     // this.primaryEdgeArrow.position.set(centerPos.x ,centerPos.y ,20);
    //     // // else {
    //     // const parentvertices = [];
    //     // this.parent.outlinePoints.forEach(outlinePoint => {
    //     //     parentvertices.push(outlinePoint.getPosition())
    //     // })
    //     // const centroid = this.findCentroid(parentvertices);
    //     // this.azimuthSelection.position.set(centroid.x ,centroid.y ,20);
    //     // }
    //     // this.azimuthSelection.rotation.set(0,0,utils.deg2Rad(90));
    //     // this.azimuthSelection.rotateX(utils.deg2Rad(90))
    //     // Normalize the direction vector
    //     direction.normalize();

    //     // Calculate the rotation axis
    //     var rotationAxis = new THREE.Vector3();
    //     rotationAxis.crossVectors(new THREE.Vector3(0, 1, 0), direction);
    //     rotationAxis.normalize();

    //     // Calculate the angle of rotation
    //     var angle = Math.acos(new THREE.Vector3(0, 1, 0).dot(direction));

    //     // Create a quaternion rotation
    //     var quaternion = new THREE.Quaternion();
    //     quaternion.setFromAxisAngle(rotationAxis, angle);

    //     // Apply the rotation to the box mesh
    //     // this.azimuthSelectionNumber.setRotationFromQuaternion(quaternion);
    //     this.azimuthSelection.setRotationFromQuaternion(quaternion);
    // }

    // getArrowAngle() {
    //     const azimuth = this.getAzimuth();
    //     const normalizedAzimuth = (azimuth + 360) % 360;
    //     // Flip the angle around the vertical axis
    //     let flippedAngle = 360 - normalizedAzimuth;
    //     // Normalized the flipped angle
    //     flippedAngle = (flippedAngle + 360) % 360;
    //     return flippedAngle;
    // };
    

    loadDeleteState(){
        this.measurementText = this.makeMeasurementText();
        this.makeEdgeMesh()
        this.stage.sceneManager.scene.add(this.objectsGroup);

    }
      

    makeEdgeMesh() {
        if(this.parent.objectType() === 'DrawFace'){
            this.updateAzimuthSelectionArrow();
            this.belongsTo.outerEdgesMesh.push(this.azimuthSelection);
            this.objectsGroup.add(this.azimuthSelection);
        }

        this.outerEdgeMesh = new THREE.Line(this.outerEdgeGeometry, this.outerEdgeMaterial);
        this.outerEdgeMesh.name = 'Outer Edge Mesh';
        this.objectsGroup.add(this.outerEdgeMesh);
        this.objectsGroup.add(this.highlightedLine);
        // this.objectsGroup.add(this.salesModehighlightedLine);
        if (this.highlightedLine) this.highlightedLine.visible = false;
        this.belongsTo.measurementTextMesh.push(this.measurementText.textMesh);
        this.belongsTo.outerEdgesMesh.push(this.outerEdgeMesh);
    }
    
    handleValueUpdate(userEnteredValue) {
        const length = (isMetricUnit()) ? userEnteredValue :
            convertImperialToMetric(parseImperialMeasurement(userEnteredValue));
        this.stage.stateManager.startContainer();
        const pointIndex1 = this.outlinePoint1Index;
        const pointIndex2 = this.outlinePoint2Index;
        const pointIndex3 = (this.index2 + 1) % this.parent.outlinePoints.length;
        const pointIndex4 = (this.index2 + 2) % this.parent.outlinePoints.length;

        const point1 = this.parent.outlinePoints[pointIndex1].getPosition().clone();
        const point2 = this.parent.outlinePoints[pointIndex2].getPosition().clone();
        const point3 = this.parent.outlinePoints[pointIndex3].getPosition().clone();
        const point4 = this.parent.outlinePoints[pointIndex4].getPosition().clone();

        const edgeVector1 = this.getEdgeVectorDimensionUpdate(point1, point2);
        const edgeVector2 = this.getEdgeVectorDimensionUpdate(point2, point3);
        const edgeVector3 = this.getEdgeVectorDimensionUpdate(point3, point4);

        const newPoint2 = point1.add(edgeVector1.multiplyScalar(length));
        const newPoint3 = utils.checkLineIntersection([newPoint2,edgeVector2.add(newPoint2)],[point3,edgeVector3.add(point3)]);

        this.parent.outlinePoints[pointIndex2].setPosition(newPoint2.x, newPoint2.y, point2.z);
        this.parent.outlinePoints[pointIndex3].setPosition(newPoint3.x, newPoint3.y, point3.z);
        this.parent.updateSmartRoof();
        this.parent.placeObject();

        this.stage.stateManager.stopContainer();
    }

    handleTextSelection() {
        if (!this.stage.dragControls.isEditModeEnabled() &&
            !this.stage.duplicateManager.isEditModeEnabled() &&
            !this.stage.setbackEditMode.isEnabled() &&
            !this.stage.smartRoofSetbackEditMode.isEnabled()) {
            this.isTextSelected = true;
            this.stage.selectionControls.setSelectedObject(this);
        }
    }

    updateOuterEdge() {
        // this.outerEdgeGeometry.vertices[0].copy(this.outlinePoint1.getPosition());
        // this.outerEdgeGeometry.vertices[1].copy(this.outlinePoint2.getPosition());
        this.outerEdgeGeometry.setFromPoints([this.outlinePoint1.getPosition(), this.outlinePoint2.getPosition()])
        this.point1 = this.outlinePoint1.getPosition();
        this.point1.z = this.height;
        this.point2 = this.outlinePoint2.getPosition();
        this.point2.z = this.height;
        this.outerEdgeGeometry.translate(0, 0, 5);
        this.outerEdgeGeometry.attributes.position.needsUpdate = true;
        if (this.highlightedLine) {
            const points = [];
            points.push(this.point1.x, this.point1.y, this.point1.z);
            points.push(this.point2.x, this.point2.y, this.point2.z);
            this.highlightedLine.geometry.setPositions(points);
            this.updateHighlightResolution();
        }
    
        this.updateFacePlane();
        this.updateWallPlane();
        this.measurementTextUpdate();
            this.updateAzimuthSelectionArrow();
            this.updateScale();
    }

    highlightOnHover() {
        if (!this.isSelected) {
            this.highlightLine();
            if (this.parent.objectType() === 'DrawFace' && !this.isPrimaryEdge) {
                const scaleX = this.azimuthSelection.scale.x * 1.2;
                const scaleY = this.azimuthSelection.scale.y * 1.2;
                const scaleZ = this.azimuthSelection.scale.z * 1.2;
                this.azimuthSelection.scale.set(scaleX,scaleY,scaleZ);
                this.azimuthSelection.material.opacity = 1;
            }
        }
    }
    clicklightColor() {
        if (!this.isSelected) {
            this.updateHighLightColor();
        }
    }
    removelightColor() {
        this.salesModehighlightedLine.visible = false;
        // this.primaryEdgeArrow.visible =false;
    }

    highlightLine() {
        if (this.highlightedLine) {
            if (this.highlightedLine.material) this.highlightedLine.material.linewidth = 5;
            this.updateHighlightResolution();
            this.highlightedLine.visible = true;
        }
    }

    updateHighlightResolution() {
        if (this.highlightedLine && this.highlightedLine.material) {
            const resolution = new THREE.Vector2();
            this.stage.rendererManager.renderer.getSize(resolution);
            this.highlightedLine.material.resolution.set(resolution.x, resolution.y);
            // this.updateHighLightColor()
        }
    }
    updateHighLightColor() {
        if (this.salesModehighlightedLine && this.salesModehighlightedLine.material) {
            const resolution = new THREE.Vector2();
            this.stage.rendererManager.renderer.getSize(resolution);
            this.highlightedLine.material.resolution.set(resolution.x, resolution.y);
            this.salesModehighlightedLine.visible = true;
            // this.highlightedLine.material.color.setHex(0xff0000);
            // this.objectsGroup.add(this.highlightedLine)
        }
    }

    unHighlight() {
        if (!this.isSelected) {
            if (this.highlightedLine) {
                this.highlightedLine.visible = false;
                if (this.highlightedLine.material) this.highlightedLine.material.linewidth = 0.0001;
            }
            if (this.parent.objectType() === 'DrawFace') {
                this.azimuthSelection.material.opacity = ARROW_TRANSPARENCY;
                this.azimuthSelection.scale.set(1, 1, 1);
                this.updateScale();
            }
        }
    }

    updateColor(color) {
        this.outerEdgeMesh.material.color.setHex(color);
    }

    changeHeight(deltaHeight) {
        this.height += deltaHeight;
        if (this.height < 0) {
            this.height = 0;
        }
        this.smartRoofFace.coreHeight = this.height;
        this.updateOuterEdge();
        for (let i = 0; i < this.coupledEdges.length; i++) {
            const coupledEdge = this.coupledEdges[i];
            coupledEdge.height += deltaHeight;
            if (coupledEdge.height < 0) {
                coupledEdge.height = 0;
            }
            coupledEdge.smartRoofFace.coreHeight = coupledEdge.height;
            coupledEdge.updateOuterEdge();
        }
    }

    getValidTilt(tilt) {
        const validTilt = utils.isNumber(parseFloat(tilt)) ? parseFloat(tilt) : this.defaultTilt;
        return validTilt;
    }

    moveObject(dx = 0, dy = 0, touch) { 
        this.moveObjectWithContraints(touch);
        // if (this.belongsTo.isTemplate) {
        // }
        // else {
        //     const EPSILON = 0.00001;
        //     this.point1 = this.outlinePoint1.getPosition();
        //     this.point2 = this.outlinePoint2.getPosition();
        //     const line = new THREE.Line3(new Vector3(this.point1.x, this.point1.y, 0), new Vector3(this.point2.x, this.point2.y, 0));
        //     const mousePoint = new THREE.Vector3(this.stage.mousePoint.x, this.stage.mousePoint.y, 0);
        //     const closestPointToPoint = new Vector3();
        //     line.closestPointToPoint(mousePoint, false, closestPointToPoint);
        //     const distance = closestPointToPoint.distanceTo(mousePoint);
        //     let dx = 0;
        //     let dy = 0;
        //     if (distance > EPSILON) {
        //         dx = mousePoint.x - closestPointToPoint.x;
        //         dy = mousePoint.y - closestPointToPoint.y;
        //         const dz = 0;
        //         this.outlinePoint1.moveObjectWithoutConsequences(dx, dy, dz);
        //         this.outlinePoint2.moveObjectWithoutConsequences(dx, dy, dz);
        //     }
        //     this.updateOuterEdge();
        //     this.belongsTo.handleOuterEdgeMove(this, dx, dy);
        // }
    }

    moveObjectWithContraints(touchPoint) {
        const EPSILON = 0.00001;
        this.point1 = this.outlinePoint1.getPosition();
        this.point2 = this.outlinePoint2.getPosition();
        const line = new THREE.Line3(new Vector3(this.point1.x, this.point1.y, 0), new Vector3(this.point2.x, this.point2.y, 0));
        const mousePoint = (touchPoint) ? touchPoint : new THREE.Vector3(this.stage.mousePoint.x, this.stage.mousePoint.y, 0);
        const closestPointToPoint = new Vector3();
        line.closestPointToPoint(mousePoint, false, closestPointToPoint);
        const distance = closestPointToPoint.distanceTo(mousePoint);
        let dx = 0;
        let dy = 0;
        if (distance > EPSILON) {
            dx = mousePoint.x - closestPointToPoint.x;
            dy = mousePoint.y - closestPointToPoint.y;
            const dz = 0;
            this.outlinePoint1.moveObjectWithoutConsequences(dx, dy, dz);
            this.outlinePoint2.moveObjectWithoutConsequences(dx, dy, dz);
            if (this.mirrorEdge) {
                this.mirrorEdge.outlinePoint1.moveObjectWithoutConsequences(-dx, -dy, dz);
                this.mirrorEdge.outlinePoint2.moveObjectWithoutConsequences(-dx, -dy, dz);
                this.mirrorEdge.updateOuterEdge();
            }
        }
        this.updateOuterEdge();
        this.belongsTo.handleOuterEdgeMove(this, dx, dy);
    }

    moveObjectWithoutConsequences(deltaX, deltaY, deltaZ = 0) {
        this.height += deltaZ;
        this.updateOuterEdge();
    }

    getPosition() {
        if (this.objectsGroup.children[0].geometry != null) {
            // noinspection JSValidateTypes
            return [this.point1, this.point2];
        }
        return null;
    }

    async placeObject() {
        try {
            await this.belongsTo.handleOuterEdgeDragEnd(this);
            return Promise.resolve(true);
        }
        catch (error) {
            console.error('ERROR: OutlinePoints: placeObject failed', error);
            return Promise.reject(error);
        }
    }

    handleDragStart() {
        this.belongsTo.handleVertexDragStart(this);
    }

    getParent() {
        return this.belongsTo;
    }
    findCentroid(vectors) {
        if (vectors.length === 0) {
          return null; // Return null if the array is empty
        }
      
        let sumX = 0;
        let sumY = 0;
        let sumZ = 0;
      
        for (let i = 0; i < vectors.length; i++) {
          sumX += vectors[i].x;
          sumY += vectors[i].y;
          sumZ += vectors[i].z;
        }
      
        const centroidX = sumX / vectors.length;
        const centroidY = sumY / vectors.length;
        const centroidZ = sumZ / vectors.length;
      
        return new THREE.Vector3(centroidX, centroidY, 20);
    }
    getChildren() {
        return this.children;
    }

    onSelect() {
        this.isSelected = true;
        if(this.stage.viewManager.lengthVisible)this.measurementText.showObject();
        if(this.parent.objectType() === 'DrawFace' && !this.isPrimaryEdge) {
            this.stage.stateManager.startContainer();
            const centroid = this.findCentroid(this.parent.oldVertices);
            this.azimuthSelection.position.set(centroid.x ,centroid.y ,20);
            this.isPrimaryEdge = true;
            this.parent.makePrimaryEdge(this);
            this.parent.saveState();
            this.updateScale();
            this.stage.stateManager.stopContainer();
        }
        else {
            this.highlightLine();
            this.outerEdgeMesh.visible = true;
        }
    }
    onSelectEdge() {
        this.isSelected = true;
        if(this.stage.viewManager.lengthVisible)this.measurementText.showObject();
        if(this.parent.objectType() === 'DrawFace' && !this.isPrimaryEdge) {
            this.stage.stateManager.startContainer();
            const centroid = this.findCentroid(this.parent.oldVertices);
            this.azimuthSelection.position.set(centroid.x ,centroid.y ,20);
            this.isPrimaryEdge = true;
            this.parent.makePrimaryEdge(this);
            this.parent.saveState();
            this.stage.stateManager.stopContainer();
            this.stage.selectionControls.setSelectedObject(this.parent);
        }
        else {
            this.highlightLine();
            this.outerEdgeMesh.visible = true;
        }
    }

    deSelect() {
        this.parent.deSelect();
    }
    onDeselect() {
        this.isSelected = false;
        if (this.highlightedLine) this.highlightedLine.visible = false;
        this.measurementText.hideObject();
        this.outerEdgeMesh.visible = false;
    }

    getSaveObjectArray() {
        // Convert all the data and return it as an array
        const saveObject = {
            id: this.id,
            outlinePoint1Index: this.outlinePoint1Index,
            outlinePoint2Index: this.outlinePoint2Index,
            height: this.height,
            salesEdge: this.salesEdge,
            setback: this.setback,
            belongsTo: this.belongsTo.id,
            tilt: this.getValidTilt(this.tilt),
            isPitched: this.isPitched,
        };
        return saveObject;
    }

    loadObject(saveObjectArray) {
        // load the object from an array of data
        this.point1 = new Vector3(saveObjectArray[0][0], saveObjectArray[0][1], saveObjectArray[0][2]);
        this.point2 = new Vector3(saveObjectArray[1][0], saveObjectArray[1][1], saveObjectArray[1][2]);
        this.isPitched = saveObjectArray[2];
        this.tilt = this.getValidTilt(saveObjectArray[3]);
        if (saveObjectArray[4] != null) {
            this.smartRoofFace = this.stage.sceneManager.scene.getObjectById(saveObjectArray[4]);
        }
        else {
            this.smartRoofFace = null;
        }
        this.makeOuterEdge();
    }

    getInwardHorizontalNormal() {
        return (new Vector3(this.point1.y - this.point2.y, this.point2.x - this.point1.x, 0)).normalize();
    }

    getAzimuth() {
        let edge = [];
        edge = [this.point2, this.point1];


        // getting normal for each pair
        let azimuth = 180;
        let angle = utils.toDegrees(Math.atan2((edge[1].y - edge[0].y), -(edge[1].x - edge[0].x)));
        // atan2 returns between -pi and pi and we want between 0 and 360. 0 being in North
        angle += 180;
        azimuth = parseFloat(angle.toFixed(2));
        if (azimuth > 359.99) {
            azimuth = 0;
        }

        return azimuth;
    }

    getEdgeVector() {
        return (new Vector3(this.point2.x - this.point1.x, this.point2.y - this.point1.y, 0)).normalize();
    }
    getEdgeVectorDimensionUpdate(point1, point2) {
        return (new THREE.Vector3(point2.x - point1.x, point2.y - point1.y, 0)).normalize();
    }

    getMidPoint() {
        return new Vector3((this.point1.x + this.point2.x) / 2, (this.point1.y + this.point2.y) / 2, (this.point1.z + this.point2.z) / 2);
    }

    getLength() {
        return this.point1.distanceTo(this.point2);
    }

    getFacePlane() {
        const plane = new THREE.Plane().setFromNormalAndCoplanarPoint(this.getPlaneNormal(), this.point1);
        return plane
    }

    // for updating and setting the new plane direction while clicking the arrow
    salesModeUpdateFacePlane() {
        this.facePlane = this.getFacePlane();
        // setting the offset for translating plane
        if (!this.azimuthDirectionFlag) {
            
            const offSet = this.findOffsetForSalesModeAzimuthArrow();
            this.point1.z += offSet;
            const facePlaneCopy = this.facePlane.clone()
            facePlaneCopy.normal.z += offSet;
            const plane = new THREE.Plane().setFromNormalAndCoplanarPoint(facePlaneCopy.normal, this.point1);
            this.facePlane = plane;
            this.smartRoofFace.plane = this.facePlane;
        }
        else {

            this.smartRoofFace.plane = this.facePlane;
        }
    }
    
    findOffsetForSalesModeAzimuthArrow() {
        const outlinePoints = this.smartRoofFace.get3DVertices();
        let offSetArray = [];
        for (let i = 0; i< outlinePoints.length; i++) {
            offSetArray.push(Math.abs(outlinePoints[0][2] - outlinePoints[i][2]));
        }
        offSetArray.sort().reverse();
        return offSetArray[0];

    }

    updateFacePlane() {
        this.salesModeUpdateFacePlane()
        
    }

    getWallPlane() {
        const verticalPlane = new THREE.Plane().setFromCoplanarPoints(
            new THREE.Vector3(
                this.point1.x,
                this.point1.y,
                0,
            ),
            new THREE.Vector3(
                this.point2.x,
                this.point2.y,
                0,
            ),
            new THREE.Vector3(
                this.point1.x,
                this.point1.y,
                1,
            ),
        );
        return verticalPlane;
    }

    updateWallPlane() {
        this.wallPlane = this.getWallPlane();
        this.smartRoofFace.outerPlane = this.wallPlane;
    }

    removeObject() {
        this.outerEdgeMesh.geometry.dispose();
        this.highlightedLine.geometry.dispose();
        if (this.smartRoofFace) this.smartRoofFace.disposeFaceMesh();
        if (this.measurementText) this.measurementText.removeObject();
        this.stage.sceneManager.scene.remove(this.objectsGroup);
    }


}
