
<div class="menu-container">
  <p class="title">
    {{ itemValue.title }}
    <span class="astrisk"> *</span>
  </p>
  <el-menu  menu-trigger="click" mode="horizontal" background-color="white"
    text-color="black" active-text-color="#222" @select="handleMenuSelect" :unique-opened="true">
    <el-submenu index="2" class="custom-submenu" :disabled="!seletedSource">
      <template v-slot:title>
        <el-tooltip class="item" effect="dark" :content="selectedMenu=='NA'?'Please select a value to update' :selectedMenu" placement="top">
          <div style="width: 80px;text-overflow: ellipsis; overflow: hidden; white-space: nowrap;" :style="{ color: selectedMenu === 'Select Value' ? '#777' : '#222',fontSize: selectedMenu === 'Select Value' ? '12px' : '14px' }">
            {{ selectedMenu }}
          </div>
        </el-tooltip>
      </template>

      <el-submenu v-for="(options, index) in optionsData" :key="index" :index="options.id" class="custom-submenu-child">
        <template v-slot:title>
          <div
            style="overflow: hidden; white-space: nowrap;max-width: 300px;text-overflow: ellipsis; padding-right: 20px; ">
            {{ options?.label || options?.name }}
          </div>
        </template>
        <template>

          <div style="max-height: 300px; overflow: scroll;">
            <div style="height: 30px;display: flex;align-items: center;font-weight: 400; font-size: 14px;" v-for="(subOptions, ind) in options.stageOptions" :key="ind">
              <el-menu-item :index="subOptions.id">{{ subOptions.name }}</el-menu-item>
            </div>
          </div>
        </template>
      </el-submenu>
    </el-submenu>
  </el-menu>


</div>
