<template>
    <div class="mappinngContainer">
        <p v-show="false">{{ isAllFieldsSelected }}</p>
        <div class="headerCont">
            <p class="heading">Fields in CRM</p>
            <p class="heading">Fields in file</p>
            <p class="heading">Default values
                <el-tooltip placement="top-end"
                    content="These values will be applied to fields that are missing in your lead information.">
                    <b-icon icon="exclamation-circle-fill" style="color: #777; cursor: pointer;"></b-icon>
                </el-tooltip>
            </p>
        </div>
        <div class="bodyCont">
            <div class="rows">
                <p class="label">Full Name<span class="asterisk"> *</span></p>
                <div>
                    <el-select v-model="bulkLeadImportState.selectedHeaderValues.fullName"
                    @change="validateDuplicateFields()"
                        placeholder="Select field to import">
                        <el-option v-for="item in bulkLeadImportState.selectedHeaderValues.headerKeys" :key="item.value"
                            :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <p class="errorMsg" v-if="allValidationSection.showNameError">{{ allValidationSection.nameErrorMsg
                        }}
                    </p>
                    <p class="errorMsg" v-if="headerValueAndCountMapping[bulkLeadImportState.selectedHeaderValues.fullName]>1">Duplicate value.</p>
                </div>
                <div class="emptyDiv"></div>
            </div>
            <div class="rows">
                <p class="label">Assignee<span class="asterisk"> *</span></p>
                <div>
                    <el-select v-model="bulkLeadImportState.selectedHeaderValues.assignTo"
                    @change="validateDuplicateFields()"
                        placeholder="Select field to import">
                        <el-option v-for="item in bulkLeadImportState.selectedHeaderValues.headerKeys" :key="item.value"
                            :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <p class="errorMsg" v-if="allValidationSection.showAssignToError">{{
                        allValidationSection.assignToErrorMsg }}</p>
                    <p class="errorMsg" v-if="headerValueAndCountMapping[bulkLeadImportState.selectedHeaderValues.assignTo]>1">Duplicate value.</p>
                </div>
                <el-select placeholder="Select an Owner"
                    @change="validateDuplicateFields()"
                    v-model="bulkLeadImportState.defaultFiledsValues.defaultAssignTo" v-infinite-scroll="loadMoreUsers"
                    infinite-scroll-disabled="busy" infinite-scroll-distance="10">
                    <el-option v-for="owner in bulkLeadImportState.defaultFiledsValues.owners" :key="owner.id"
                        :label="owner.first_name + ' ' + owner.last_name" :value="owner.email">
                    </el-option>
                </el-select>
                <p class="errorMsg" v-if="headerValueAndCountMapping[bulkLeadImportState.selectedHeaderValues.defaultAssignTo]>1">Duplicate value.</p>
            </div>
            <div class="rows">
                <p class="label">Email ID<span class="asterisk"> *</span></p>
                <div>
                    <el-select v-model="bulkLeadImportState.selectedHeaderValues.emailID"
                        @change="validateDuplicateFields()"
                        placeholder="Select field to import">
                        <el-option v-for="item in bulkLeadImportState.selectedHeaderValues.headerKeys" :key="item.value"
                            :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <p class="errorMsg" v-if="allValidationSection.showEmailError">{{
                        allValidationSection.emailIDErrorMsg
                    }}</p>
                    <p class="errorMsg" v-if="headerValueAndCountMapping[bulkLeadImportState.selectedHeaderValues.emailID]>1">Duplicate value.</p>
                </div>
                <div class="emptyDiv"></div>
            </div>
            <div class="rows">
                <p class="label">Phone Number<span class="asterisk"> *</span></p>
                <div>
                    <el-select v-model="bulkLeadImportState.selectedHeaderValues.phoneNo"
                        @change="validateDuplicateFields()"
                        placeholder="Select field to import">
                        <el-option v-for="item in bulkLeadImportState.selectedHeaderValues.headerKeys" :key="item.value"
                            :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <p class="errorMsg" v-if="allValidationSection.showPhoneNoError">{{
                        allValidationSection.phoneNoErrorMsg
                    }}</p>
                    <p class="errorMsg" v-if="headerValueAndCountMapping[bulkLeadImportState.selectedHeaderValues.phoneNo]>1">Duplicate value.</p>
                </div>
                <div class="emptyDiv"></div>
            </div>
            <div class="rows">
                <p class="label">Property Type<span class="asterisk"> *</span></p>
                <div>
                    <el-select v-model="bulkLeadImportState.selectedHeaderValues.propertyType"
                        @change="validateDuplicateFields()"
                        placeholder="Select field to import">
                        <el-option v-for="item in bulkLeadImportState.selectedHeaderValues.headerKeys" :key="item.value"
                            :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <p class="errorMsg" v-if="allValidationSection.showPropertyTypeError">{{
                        allValidationSection.propertyTypeErrorMsg }}</p>
                    <p class="errorMsg" v-if="headerValueAndCountMapping[bulkLeadImportState.selectedHeaderValues.propertyType]>1">Duplicate value.</p>
                </div>
                <div>
                    <el-select placeholder="Select a property type"
                        v-model="bulkLeadImportState.defaultFiledsValues.defaultPropertyType" name="propertyType">
                        <el-option v-for="propertyType in bulkLeadImportState.defaultFiledsValues.propertyTypeTypes"
                            :key="propertyType.value" :label="propertyType.label" :value="propertyType.value">
                        </el-option>
                    </el-select>
                    <p class="errorMsg" v-if="headerValueAndCountMapping[bulkLeadImportState.selectedHeaderValues.defaultPropertyType]>1">Duplicate value.</p>
                </div>
            </div>
            <div class="rows">
                <p class="label">Stage<span class="asterisk"> *</span></p>
                <div>
                    <el-select v-model="bulkLeadImportState.selectedHeaderValues.stage"
                        @change="validateDuplicateFields()"
                        placeholder="Select field to import">
                        <el-option v-for="item in bulkLeadImportState.selectedHeaderValues.headerKeys" :key="item.value"
                            :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <p class="errorMsg" v-if="headerValueAndCountMapping[bulkLeadImportState.selectedHeaderValues.stage]>1">Duplicate value.</p>
                </div>
                <div class="emptyDiv"></div>
            </div>
            <div class="rows">
                <p class="label">Lead Source<span class="asterisk"> *</span></p>
                <div>
                    <el-select v-model="bulkLeadImportState.selectedHeaderValues.leadSource"
                        @change="validateDuplicateFields()"
                        placeholder="Select field to import">
                        <el-option v-for="item in bulkLeadImportState.selectedHeaderValues.headerKeys" :key="item.value"
                            :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <p class="errorMsg" v-if="allValidationSection.showLeadSourceError">
                        {{ allValidationSection.leadSourceErrorMsg }}</p>
                    <p class="errorMsg" v-if="headerValueAndCountMapping[bulkLeadImportState.selectedHeaderValues.leadSource]>1">Duplicate value.</p>
                </div>
                <el-select placeholder="Select a lead source type"
                    v-model="bulkLeadImportState.defaultFiledsValues.defaultLeadSource">
                    <el-option v-for="stage in bulkLeadImportState.defaultFiledsValues.leadSourceTypes" :key="stage.id"
                        :label="stage.name" :value="stage.name">
                    </el-option>
                </el-select>
                <p class="errorMsg" v-if="headerValueAndCountMapping[bulkLeadImportState.selectedHeaderValues.defaultLeadSource]>1">Duplicate value.</p>
            </div>
            <div class="rows">
                <p class="label">Address</p>
                <div>
                    <el-select v-model="bulkLeadImportState.selectedHeaderValues.address"
                    @change="validateDuplicateFields()"
                    placeholder="Select field to import">
                    <el-option v-for="item in bulkLeadImportState.selectedHeaderValues.headerKeys" :key="item.value"
                        :label="item.label" :value="item.value">
                    </el-option>
                    </el-select>
                    <p class="errorMsg" v-if="headerValueAndCountMapping[bulkLeadImportState.selectedHeaderValues.address]>1">Duplicate value.</p>
                </div>
                <div class="emptyDiv"></div>
            </div>
            <div class="rows">
                <p class="label">Target Close Date</p>
                <div>
                    <el-select v-model="bulkLeadImportState.selectedHeaderValues.closeDate"
                        @change="validateDuplicateFields()"
                        placeholder="Select field to import">
                        <el-option v-for="item in bulkLeadImportState.selectedHeaderValues.headerKeys" :key="item.value"
                            :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <p class="errorMsg" v-if="allValidationSection.showDateFormatError">
                        {{ allValidationSection.dateFormatErrorMsg }}</p>
                    <p class="errorMsg" v-if="headerValueAndCountMapping[bulkLeadImportState.selectedHeaderValues.closeDate]>1">Duplicate value.</p>
                </div>
                <DatePicker :label="false" @dateChanged="onDateChange" :disabledPrev="true" placeholder="" />
            </div>
            <div class="rows">
                <p class="label">Deal Value</p>
                <div>
                    <el-select v-model="bulkLeadImportState.selectedHeaderValues.dealValue"
                        @change="validateDuplicateFields()"
                        placeholder="Select field to import">
                        <el-option v-for="item in bulkLeadImportState.selectedHeaderValues.headerKeys" :key="item.value"
                            :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <p class="errorMsg" v-if="allValidationSection.showDealValueError">{{
                        allValidationSection.dealValueErrorMsg }}</p>
                    <p class="errorMsg" v-if="headerValueAndCountMapping[bulkLeadImportState.selectedHeaderValues.dealValue]>1">Duplicate value.</p>
                </div>
                <div class="positionR">
                    <el-input placeholder="" v-model="bulkLeadImportState.defaultFiledsValues.defaultDealValue"
                        type="number">
                    </el-input>
                    <p class="currencySymbolIcon">{{ currencySymbol }}</p>
                </div>
            </div>
            <div class="rows">
                <p class="label">Tags</p>
                <div>
                    <el-select v-model="bulkLeadImportState.selectedHeaderValues.tags"
                    @change="validateDuplicateFields()" placeholder="Select field to import">
                    <el-option v-for="item in bulkLeadImportState.selectedHeaderValues.headerKeys" :key="item.value"
                        :label="item.label" :value="item.value">
                    </el-option>
                    </el-select>
                    <p class="errorMsg" v-if="headerValueAndCountMapping[bulkLeadImportState.selectedHeaderValues.tags]>1">Duplicate value.</p>
                </div>
                <Tags :isCreateLeadMode="true" :isUsingStore="false" @fetchTagsToSendForLead="fetchTagsToSendForLead" />
            </div>
        </div>
    </div>
</template>


<script>

import API from "@/services/api/";
import Tags from './tags.vue';
import DatePicker from "../../setReminder/components/datePicker.vue";
import currencySymbolNameMap from "@/pages/currency-symbol-name-map";
import { useMiscStore } from "../../../stores/misc";
import { mapState } from "pinia";


export default {
    name: "leadFiledsMapping",
    components: {
        Tags,
        DatePicker
    },
    props: {
        isAllFileSelected: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            nextUsersUrl: null,
            isUserInResponse: false,
            createdJSON: null,
            requiredFields: ['fullName', 'assignTo', 'emailID', 'phoneNo', 'propertyType', 'stage', 'leadSource', 'address', 'closeDate', 'dealValue', 'tags'],
            allValidationSection: {
                nameErrorMsg: null,
                assignToErrorMsg: null,
                emailIDErrorMsg: null,
                phoneNoErrorMsg: null,
                propertyTypeErrorMsg: null,
                leadSourceErrorMsg: null,
                dealValueErrorMsg: null,
                dateFormatErrorMsg: null,
                showNameError: false,
                showAssignToError: false,
                showEmailError: false,
                showPhoneNoError: false,
                showPropertyTypeError: false,
                showLeadSourceError: false,
                showDealValueError: false,
                showDateFormatError: false,
                isAllValidationValid: true,
            },
            ownersInOrgID: null,
            leadSourcesInOrgID: null,
            propFileData: null,
            headerValueAndCountMapping:{}
        };
    },

    mounted() {
        this.bulkLeadImportState.selectedHeaderValues.headerKeys = Object.keys(this.bulkLeadImportState.userFileData[0]).map(key => ({
            value: key,
            label: key
        }));
        this.propFileData = this.bulkLeadImportState.userFileData;
        this.fetchAllUsers();
        this.getLeadSource();
    },

    computed: {

        ...mapState(useMiscStore, {
            bulkLeadImportState: (state) => state.bulkLeadImportState,
        }),

        currentUserObj() {
            return JSON.parse(localStorage.getItem('user'));
        },

        currencySymbol() {
            return currencySymbolNameMap[JSON.parse(
                localStorage.getItem("organisation")
            ).currency_code];
        },

        isAllFieldsSelected() {
            let isValid = true;
            let vm = this;
            this.requiredFields.forEach(field => {
                isValid = vm.bulkLeadImportState.selectedHeaderValues[field] && isValid;
            });
            if (isValid) {
                this.bulkLeadImportState.isValidateBtnDisabled = false;
                return true;
            } else {
                this.bulkLeadImportState.isValidateBtnDisabled = true;
                return false;
            };
        },
    },

    methods: {

        async validationFromBackend() {
            let res;
            this.bulkLeadImportState.isLoading = true;
            try {
                const postData = {
                    owner_in_file: this.commonFunctionToGetArray('owner'),
                    lead_source_file: this.commonFunctionToGetArray('lead_source_object'),
                    pipeline: this.bulkLeadImportState.selectedPipeline,
                }
                res = await API.LEADS.POST_BULK_LEAD_VALIDATE(postData);
                this.bulkLeadImportState.isLoading = false;
                if (res.data.success == true) {
                    this.ownersInOrgID = res.data.owners;
                    this.leadSourcesInOrgID = res.data.leads;
                    return true;
                } else {
                    return false;
                };
            } catch (e) {
                this.bulkLeadImportState.isValidateBtnDisabled = true;
                const ownerError = e.response.data.errors.find(error => error.owner_error_msg)?.owner_error_msg;
                const leadSourceError = e.response.data.errors.find(error => error.lead_source_error_msg)?.lead_source_error_msg;
                if (ownerError) {
                    this.allValidationSection.showAssignToError = true;
                    this.allValidationSection.assignToErrorMsg = ownerError;
                };
                if (leadSourceError) {
                    this.allValidationSection.showLeadSourceError = true;
                    this.allValidationSection.leadSourceErrorMsg = leadSourceError;
                };
                this.bulkLeadImportState.isLoading = false;
                return false;
            };
        },
        initializeMappingCounter(){
            for(let i=0;i<this.requiredFields.length;i++){
                if(this.bulkLeadImportState.selectedHeaderValues[this.requiredFields[i]])
                    this.headerValueAndCountMapping[this.bulkLeadImportState.selectedHeaderValues[this.requiredFields[i]]]=0;
            }
        },
        validateDuplicateFields(){
            this.initializeMappingCounter();
            for(let i=0;i<this.requiredFields.length;i++){
                if(this.bulkLeadImportState.selectedHeaderValues[this.requiredFields[i]])
                    this.headerValueAndCountMapping[this.bulkLeadImportState.selectedHeaderValues[this.requiredFields[i]]]++;
            }
        },
        async fieldsMappingAndValidateFunc() {
            if (this.isAllFieldsSelected) {
                this.createdJSON = {};

                for (let index = 0; index < this.requiredFields.length; index++) {
                    const fieldValue = this.bulkLeadImportState.selectedHeaderValues[this.requiredFields[index]];
                    const fieldLabel = this.getFieldLabel(this.requiredFields[index]);
                    // Check if the field value is unique before adding it to the createdJSON object
                    if (!(fieldValue in this.createdJSON)) {
                        this.createdJSON[fieldValue] = fieldLabel;
                    } else {
                        // Handle duplicate values (you can add a suffix or do something else)
                        this.bulkLeadImportState.isValidateBtnDisabled = true;
                        this.createdJSON = {};
                        this.$message.error('Oops, same options selection detected.');
                        return;
                    }
                };
                this.replaceHeaderKeysFunc();
                this.valitationFunc();
                const validationPassed = await this.validationFromBackend();
                if (!validationPassed || !this.allValidationSection.isAllValidationValid) {
                    this.bulkLeadImportState.isValidateBtnDisabled = true;
                    this.bulkLeadImportState.userFileData = this.propFileData;
                    return;
                }
                this.replaceOwnerEmailsWithIDFunc();
                this.replaceLeadSourcesWithIDFunc();
                this.bulkLeadImportState.fileData = this.bulkLeadImportState.userFileData;
                this.addPipelineKeyInUserData();
                this.commonFunctionToGetArray('stage');
                this.bulkLeadImportState.showLeadFieldsSection = false;
                this.bulkLeadImportState.showStageFieldsSection = true;
            };
        },

        replaceHeaderKeysFunc() {
            // Create a copy of fileData
            let newFileData = this.bulkLeadImportState.userFileData.map(obj => {
                // Create a new object to store the updated key-value pairs
                const updatedObj = {};
                Object.entries(obj).forEach(([key, value]) => {
                    // Find the corresponding key in createdJSON
                    const newKey = this.createdJSON[key];
                    // If the corresponding key exists, add it to the updated object
                    if (newKey) {
                        updatedObj[newKey] = value;
                    };
                });
                return updatedObj;
            });
            this.bulkLeadImportState.userFileData = newFileData;
        },

        replaceOwnerEmailsWithIDFunc() {
            // Flatten ownersInOrgID array into a single object
            const ownersMap = this.ownersInOrgID.reduce((acc, obj) => {
                const [email, id] = Object.entries(obj)[0]; // Get the first (and only) entry in the object
                acc[email] = id; // Assign the ID to the email key
                return acc;
            }, {});

            for (let index = 0; index < this.bulkLeadImportState.userFileData.length; index++) {
                const ownerValue = this.bulkLeadImportState.userFileData[index]['owner'];
                if (ownersMap.hasOwnProperty(ownerValue)) {
                    this.bulkLeadImportState.userFileData[index]['owner'] = ownersMap[ownerValue];
                };
            };
        },

        replaceLeadSourcesWithIDFunc() {
            const leadSourcesMap = this.leadSourcesInOrgID.reduce((acc, obj) => {
                const [leads, id] = Object.entries(obj)[0];
                acc[leads] = id;
                return acc;
            }, {});

            for (let index = 0; index < this.bulkLeadImportState.userFileData.length; index++) {
                const leadSourcesValue = String(this.bulkLeadImportState.userFileData[index]['lead_source_object']).toLowerCase();
                if (leadSourcesMap.hasOwnProperty(leadSourcesValue)) {
                    this.bulkLeadImportState.userFileData[index]['lead_source_object'] = leadSourcesMap[leadSourcesValue];
                };
            };
        },

        commonFunctionToGetArray(val) {
            let uniqueArr = new Set();
            for (let index = 0; index < this.bulkLeadImportState.userFileData.length; index++) {
                let value = String(this.bulkLeadImportState.userFileData[index][val]);
                if (value == undefined) {
                    value = '';
                };
                uniqueArr.add(value.trim());
            };
            let uniqueArray = Array.from(uniqueArr);
            if (uniqueArray.includes('')) {
                uniqueArray = uniqueArray.filter(value => value !== "");
                if (val == 'stage') {
                    this.bulkLeadImportState.uniqueStagesArray = uniqueArray.concat([""]);
                } else if (val == 'lead_source_object') {
                    uniqueArray = uniqueArray.map(str => String(str).toLowerCase());
                    uniqueArray = [...new Set(uniqueArray)];
                    uniqueArray = uniqueArray.filter(item => item !== 'undefined');
                    return uniqueArray;
                } else {
                    return uniqueArray;
                };
            } else {
                if (val == 'stage') {
                    this.bulkLeadImportState.uniqueStagesArray = uniqueArray;
                } else if (val == 'lead_source_object') {
                    uniqueArray = uniqueArray.map(str => String(str).toLowerCase());
                    uniqueArray = [...new Set(uniqueArray)];
                    uniqueArray = uniqueArray.filter(item => item !== 'undefined');
                    return uniqueArray;
                } else {
                    uniqueArray = uniqueArray.filter(item => item !== 'undefined');
                    return uniqueArray;
                };
            };
        },

        valitationFunc() {
            for (let index = 0; index < this.bulkLeadImportState.userFileData.length; index++) {

                if (!this.bulkLeadImportState.userFileData[index]['name']) {
                    this.allValidationSection.showNameError = true;
                    this.allValidationSection.nameErrorMsg = 'Some fields are empty';
                    this.allValidationSection.isAllValidationValid = false;
                };

                if (!this.bulkLeadImportState.userFileData[index]['owner']) {
                    if (this.bulkLeadImportState.defaultFiledsValues.defaultAssignTo) {
                        this.bulkLeadImportState.userFileData[index]['owner'] = this.bulkLeadImportState.defaultFiledsValues.defaultAssignTo;
                    };
                };

                if (!this.bulkLeadImportState.userFileData[index]['email']) {
                    this.allValidationSection.showEmailError = true;
                    this.allValidationSection.emailIDErrorMsg = 'Some fields are empty';
                    this.allValidationSection.isAllValidationValid = false;
                } else {
                    if (!this.isValidEmail(this.bulkLeadImportState.userFileData[index]['email'])) {
                        this.allValidationSection.emailIDErrorMsg = "Invalid email ID";
                        this.allValidationSection.showEmailError = true;
                        this.allValidationSection.isAllValidationValid = false;
                    };
                };

                if (!this.bulkLeadImportState.userFileData[index]['phone']) {
                    this.allValidationSection.showPhoneNoError = true;
                    this.allValidationSection.phoneNoErrorMsg = 'Some fields are empty';
                    this.allValidationSection.isAllValidationValid = false;
                } else {
                    if (!this.isValidPhoneNo(this.bulkLeadImportState.userFileData[index]['phone'])) {
                        this.allValidationSection.showPhoneNoError = true;
                        this.allValidationSection.phoneNoErrorMsg = 'Invalid phone number';
                        this.allValidationSection.isAllValidationValid = false;
                    };
                };

                if (!this.bulkLeadImportState.userFileData[index]['project_type']) {
                    if (this.bulkLeadImportState.defaultFiledsValues.defaultPropertyType) {
                        this.bulkLeadImportState.userFileData[index]['project_type'] = this.bulkLeadImportState.defaultFiledsValues.defaultPropertyType;
                    };
                } else {
                    this.bulkLeadImportState.userFileData[index]['project_type'] = String(this.bulkLeadImportState.userFileData[index]['project_type']).toLowerCase();
                    if (this.bulkLeadImportState.userFileData[index]['project_type'] == 'residential' || this.bulkLeadImportState.userFileData[index]['project_type'] == 'commercial') {
                    } else {
                        this.allValidationSection.propertyTypeErrorMsg = "Invalid property type";
                        this.allValidationSection.showPropertyTypeError = true;
                        this.allValidationSection.isAllValidationValid = false;
                    };
                };

                if (!this.bulkLeadImportState.userFileData[index]['lead_source_object']) {
                    if (this.bulkLeadImportState.defaultFiledsValues.defaultLeadSource) {
                        this.bulkLeadImportState.userFileData[index]['lead_source_object'] = this.bulkLeadImportState.defaultFiledsValues.defaultLeadSource;
                    };
                };
                if (!this.bulkLeadImportState.userFileData[index]['target_closure_date']) {
                    if (this.bulkLeadImportState.defaultFiledsValues.defaultCloseDate) {
                        this.bulkLeadImportState.userFileData[index]['target_closure_date'] = this.bulkLeadImportState.defaultFiledsValues.defaultCloseDate;
                    } else {
                        this.bulkLeadImportState.userFileData[index]['target_closure_date'] = '';
                    }
                } else {
                    let formattedDate = this.bulkLeadImportState.userFileData[index]['target_closure_date'];
                    if (this.bulkLeadImportState.fileType = 'xlsx') {
                        if (typeof (formattedDate) == 'string') {
                        } else {
                            // Adjust serial number to account for Excel's base date (December 30, 1899)
                            const excelBaseDate = new Date(1899, 11, 30); // December is month 11
                            const millisecondsPerDay = 24 * 60 * 60 * 1000;
                            const millisecondsOffset = formattedDate * millisecondsPerDay;
                            const date = new Date(excelBaseDate.getTime() + millisecondsOffset);

                            const day = String(date.getDate()).padStart(2, '0');
                            const month = String(date.getMonth() + 1).padStart(2, '0');
                            const year = date.getFullYear();
                            formattedDate = `${day}-${month}-${year}`;
                            this.bulkLeadImportState.userFileData[index]['target_closure_date'] = formattedDate;
                        }
                    };
                    if (!this.isValidDateFormat(formattedDate)) {
                        this.allValidationSection.showDateFormatError = true;
                        this.allValidationSection.dateFormatErrorMsg = "Date format should be: DD-MM-YYYY";
                        this.allValidationSection.isAllValidationValid = false;
                    };
                };
                if (!this.bulkLeadImportState.userFileData[index]['deal_value']) {
                    if (this.bulkLeadImportState.defaultFiledsValues.defaultDealValue) {
                        this.bulkLeadImportState.userFileData[index]['deal_value'] = this.bulkLeadImportState.defaultFiledsValues.defaultDealValue;
                    } else {
                        this.bulkLeadImportState.userFileData[index]['deal_value'] = '';
                    }
                } else {
                    let dealValueString = String(this.bulkLeadImportState.userFileData[index]['deal_value']);
                    dealValueString = dealValueString.replace(/,/g, '');
                    this.bulkLeadImportState.userFileData[index]['deal_value'] = dealValueString;
                    if ((this.bulkLeadImportState.userFileData[index]['deal_value']).split('.').length > 2) {
                        this.allValidationSection.dealValueErrorMsg = "Invalid deal value";
                        this.allValidationSection.showDealValueError = true;
                        this.allValidationSection.isAllValidationValid = false;
                    } else {
                        let numericValue = Number(this.bulkLeadImportState.userFileData[index]['deal_value']);
                        if (isNaN(numericValue)) {
                            this.allValidationSection.dealValueErrorMsg = "Invalid deal value";
                            this.allValidationSection.showDealValueError = true;
                            this.allValidationSection.isAllValidationValid = false;
                        }
                    }
                };
                if (!this.bulkLeadImportState.userFileData[index]['tags']) {
                    this.bulkLeadImportState.userFileData[index]['tags'] = this.bulkLeadImportState.defaultFiledsValues.defaultTags;
                } else {
                    if (typeof (this.bulkLeadImportState.userFileData[index]['tags']) == 'string') {
                        let spaceRemovedFromTags = this.bulkLeadImportState.userFileData[index]['tags'].trim(' ', '');
                        let tagsArray = spaceRemovedFromTags.split(',');
                        this.bulkLeadImportState.userFileData[index]['tags'] = tagsArray;
                    };
                };
            };
        },

        isValidEmail(email) {
            // Regular expression for more comprehensive email validation
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

            if (!emailRegex.test(email)) {
                return false;
            } else {
                return true;
            }
        },

        isValidPhoneNo(phoneNo) {
            const phoneRegex = /^[\d\s()+-]*$/;
            return phoneRegex.test(phoneNo);
        },

        isValidDateFormat(dateString) {
            const dateFormatRegex = /^\d{2}-\d{2}-\d{4}$/;
            if (!dateFormatRegex.test(dateString)) {
                return false; // Incorrect format
            };
            const [day, month, year] = dateString.split('-').map(Number);
            if (month < 1 || month > 12) {
                return false;
            };
            if (day < 1 || day > 31) {
                return false; // Invalid day
            };
            const maxDaysInMonth = new Date(year, month, 0).getDate();
            if (day > maxDaysInMonth) {
                return false; // Invalid day for the month
            };
            const dateObject = new Date(year, month - 1, day);
            return !isNaN(dateObject.getTime()); // Returns true if it's a valid date, otherwise false
        },

        addPipelineKeyInUserData() {
            this.bulkLeadImportState.userFileData.forEach(obj => {
                obj.pipeline = this.bulkLeadImportState.selectedPipeline;
            });
        },

        getAdminID() {
            let adminID = JSON.parse(localStorage.getItem('user')).user_id;
            let owners = this.bulkLeadImportState.defaultFiledsValues.owners;
            if (this.bulkLeadImportState.defaultFiledsValues.defaultAssignTo == '') {
                for (let index = 0; index < owners.length; index++) {
                    if (owners[index].id == adminID) {
                        this.bulkLeadImportState.defaultFiledsValues.defaultAssignTo = owners[index].email;
                    };
                };
            };
        },

        getFieldLabel(field) {
            const fieldLabels = {
                fullName: 'name',
                assignTo: 'owner',
                emailID: 'email',
                phoneNo: 'phone',
                propertyType: 'project_type',
                stage: 'stage',
                leadSource: 'lead_source_object',
                address: 'address',
                closeDate: 'target_closure_date',
                dealValue: 'deal_value',
                tags: 'tags'
            };
            return fieldLabels[field] || field;
        },

        loadMoreUsers() {
            if (!this.nextUsersUrl) return
            debounce(this.getNextUsers(), 500);
        },

        async getNextUsers() {
            let res = await API.USERS.LOAD_MORE_USERS(this.nextUsersUrl);
            if (!this.isUserInResponse) this.checkUserExistOnResponses(res.data.results)
            this.nextUsersUrl = res.data.next || null;
            if (this.bulkLeadImportState.defaultFiledsValues.owners[this.bulkLeadImportState.defaultFiledsValues.owners.length - 1].id == res.data.results[res.data.results.length - 1].id) return
            this.bulkLeadImportState.defaultFiledsValues.owners = [...this.bulkLeadImportState.defaultFiledsValues.owners, ...res.data.results]
        },

        async fetchAllUsers() {
            let response;
            try {
                response = await API.USERS.FETCH_ALL_USERS();
                if (response.data.results.find(e => e.id == this.currentUserObj.user_id)) {
                    this.isUserInResponse = true
                } else {
                    let owner = {
                        email: this.currentUserObj.email,
                        first_name: this.currentUserObj.first_name,
                        last_name: this.currentUserObj.last_name,
                        id: this.currentUserObj.user_id,
                    }
                    this.bulkLeadImportState.defaultFiledsValues.owners.push(owner)
                }
                for (let i = 0; i < response.data.results.length; i++) {
                    this.bulkLeadImportState.defaultFiledsValues.owners.push(response.data.results[i]);
                };
                this.nextUsersUrl = response.data.next || null;
                this.getAdminID();
            } catch (e) {
                this.$message({
                    showClose: true,
                    message:
                        "There was an error while fetching the list of users from your organization.",
                    type: "error",
                    center: true,
                });
            };
        },

        async getLeadSource() {
            let response = await API.CRMSETTINGS.FETCH_ALL_LEAD_SOURCE(true);
            this.bulkLeadImportState.defaultFiledsValues.leadSourceTypes = response.data;
            if (this.bulkLeadImportState.defaultFiledsValues.defaultLeadSource == '') {
                this.bulkLeadImportState.defaultFiledsValues.defaultLeadSource = this.bulkLeadImportState.defaultFiledsValues.leadSourceTypes[0].name;
            }
        },

        onDateChange(val) {
            if (val) {
                let dateStr = val;
                let date = new Date(dateStr);
                let day = String(date.getDate()).padStart(2, "0");
                let month = String(date.getMonth() + 1).padStart(2, "0");
                let year = String(date.getFullYear()).slice(0);
                const formattedDate = `${year}-${month}-${day}`;
                this.bulkLeadImportState.defaultFiledsValues.defaultCloseDate = formattedDate;
            } else {
                this.bulkLeadImportState.defaultFiledsValues.defaultCloseDate = val;
            };
        },

        fetchTagsToSendForLead(finalTagsToSend) {
            let tags = finalTagsToSend.map((item) => item.name);
            this.bulkLeadImportState.defaultFiledsValues.defaultTags = [...tags];
        },
    }
};
</script>

<style scoped>

p {
    word-break: break-word;
}

.headerCont,
.rows {
    display: grid;
    grid-template-columns: 0.8fr 1.1fr 1.1fr;
    align-items: flex-start;
    row-gap: 16px;
    column-gap: 24px;
    padding-bottom: 8px;
    border-bottom: 1px solid #ccc;
}

.headerCont {
    margin: 24px 24px 0px 24px
}

.rows {
    padding: 0px;
    border: 0px;
}

.heading {
    font-size: 16px;
    font-weight: 600;
    color: #222;
    display: flex;
    align-items: center;
    gap: 8px;
}

.bodyCont {
    display: flex;
    flex-direction: column;
    gap: 16px;
    min-height: 61vh;
    max-height: 61vh;
    overflow: hidden;
    overflow-y: scroll;
    margin-top: 16px;
    padding: 0px 24px 24px 24px;
}

.label {
    font-size: 16px;
    color: #222;
    margin-top: 12px;
}

.asterisk,
.errorMsg {
    color: #FF0000;
}

.errorMsg {
    margin-top: 4px;
}

.emptyDiv {
    width: 100%;
    height: 45px;
    background: #F7F7F7;
    border-radius: 8px;
}

.positionR {
    position: relative;
}

.calendarIcon {
    position: absolute;
    top: 12px;
    right: 16px;
}

.dollarIcon {
    position: absolute;
    top: 12px;
    left: 16px;
}

.currencySymbolIcon {
    position: absolute;
    top: 12px;
    left: 16px;
    font-size: 16px;
}

.bodyCont>>>.el-input__inner {
    background: #E8EDF2;
    border-radius: 8px;
    height: 45px;
    border: none;
    font-size: 16px;
    color: #222;
}

.bodyCont>>>.el-input__inner::placeholder {
    color: #222;
}

.bodyCont>>>.el-select .el-input .el-select__caret {
    color: #222;
    font-size: 16px;
    font-weight: 600;
}

.positionR>>>.el-input__inner {
    padding-left: 32px;
}

.datePicker {
    padding: 0px;
}

@media (max-width: 700px) {
    .headerCont {
        display: none;
    }

    .rows {
        grid-template-columns: 1fr;
        padding-bottom: 16px;
        border-bottom: 1px solid #ccc;
    }

    .rows:last-child {
        border-bottom: none;
        padding-bottom: 0px;
    }

    .label {
        margin-top: 0px;
    }

    .bodyCont>>>.el-select {
        width: 100%;
    }

    .bodyCont {
        margin-top: 0px;
        padding: 16px;
        min-height: 70vh;
        max-height: 70vh;
    }
}
</style>