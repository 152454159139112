import OutlinePoints from "../../subObjects/OutlinePoints";
import * as THREE from "three";
export default class FaceVertex extends OutlinePoints{
        constructor(stage, object, vertex,connectedVertices, isFirst = false, parent = null){
        super(vertex.x, vertex.y, 150, parent, stage, null) 
        this.vertex = vertex;
        this.defaultPointColor = 0xffffff;
        this.connectedVertices = connectedVertices;
        this.neighbors = [];
        this.count = 0;
        this.zValueMap = new Map();
    }

    moveObject(deltaX, deltaY, deltaZ = 0) {
        let dx = deltaX,
            dy = deltaY,
            dz = deltaZ;
        this.vertex.x += dx;
        this.vertex.y += dy;
        this.vertex.z += dz;
        if (this.movementRestrictionVector !== null) {
            const deltaVector = new THREE.Vector3(deltaX, deltaY, 0);
            deltaVector.projectOnVector(this.movementRestrictionVector);
            dx = deltaVector.x;
            dy = deltaVector.y;
        }
        const prevValue = this.getPosition();
        // stupidly move the points
        this.vertexMesh.geometry.translate(dx, dy, dz);

        // call on resize function for model
        this.belongsTo.handleVertexMove(this, dx, dy, dz, prevValue);
    }

    moveObjectWithoutConsequences(deltaX, deltaY, deltaZ = 0) {
        let dx = deltaX,
        dy = deltaY,
        dz = deltaZ;
        this.vertex.x += dx;
        this.vertex.y += dy;
        this.vertex.z += dz;

        this.vertexMesh.geometry.translate(deltaX, deltaY, deltaZ);
    }


    setPosition(x, y, z) {
        const { geometry } = this.vertexMesh;
        this.vertex.x = x;
        this.vertex.y = y;
        this.vertex.z = z;

        if (x !== undefined) {
            geometry.attributes.position.array[0] = x;
        }

        if (y !== undefined) {
            geometry.attributes.position.array[1] = y;
        }

        if (z !== undefined) {
            geometry.attributes.position.array[2] = z + this.raise;
        }

        geometry.attributes.position.needsUpdate = true;
    }
    getPosition() {
        return new THREE.Vector3(
            this.vertex.x,
            this.vertex.y,
            this.vertex.z
        );
    }
}