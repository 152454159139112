import axios from "axios";

export default {
    GET_ALL_TASKLIST() {
        return axios.get(`api/tasklist/`);
    },
    ADD_TASKS(id, postData) {
        return axios.post(`api/tasklist/${id}/task/`, postData);
    },
    UPDATE_TASKS(id,stageID,postData) {
        return axios.patch(`api/tasklist/${id}/task/${stageID}/`, postData);
    },
    UPDATE_TASK_LIST_NAME(id, postData) {
        return axios.patch(`api/tasklist/${id}/`, postData);
    },
    DELETE_TASK_LIST(id) {
        return axios.delete(`api/tasklist/${id}/`);
    },
    DELETE_TASK(id,stageID) {
        return axios.delete(`api/tasklist/${id}/task/${stageID}/`);
    },
    CREATE_TASK_LIST(postData) {
        return axios.post(`api/tasklist/`, postData);
    },
};