<template>
    <div class="avatars-container" style="display: relative;" @click="handleClick">
        <div class="avatar-icons">
            <el-avatar :class="$props.hover ? 'avatar-hover' : ''" :size="imgWidth"
                v-for="(avatar, index) in computedAvatars" :key="index" :style="getAvatarStyle(avatar.name, index)">
                <el-tooltip class="item" effect="dark" :content="avatar.message" placement="top" popper-class="avatar-hover-tooltip">
                    <span>{{ limitNotRequired ? (returnInitials(avatar.name)) : ($props.avatars.length > $props.limit
                        ? (index === 0 ? avatar.name : returnInitials(avatar.name)) : returnInitials(avatar.name))
                    }}</span>
                </el-tooltip>
            </el-avatar>
        </div>
        <el-select v-if="$props.editable" v-model="selectedValue" v-show="true" filterable placeholder="" :size="`${$props.imgWidth}`"
            popper-class="avatar-handler-dropdown"
            style="flex: 1"
            class="selection-avatars" @change="handleAdd"  ref="selection" hide-on-selected="false">
            <el-option v-for="(avatar, index) in computedSelectionAvatars" :key="index" :label="avatar.name"
                :disabled="avatar.disabled ? true : false"
                :value="avatar.id">
                <div >
                <el-checkbox :disabled="avatar.disabled ? true : false" :value="!avatar.isUnique"></el-checkbox>
                    <el-avatar :size="28" :style="getSelectionAvatarStyle(avatar.name, avatar.disabled)">
                        <span>{{ returnInitials(avatar.name) }}</span>
                    </el-avatar>
                    <span class="cb-avatar-name" :style="handleCollaboratorsDisability()">{{ avatar.name }}</span>
                </div>
            </el-option>
            <ScrollObserver
                  :showLoader="false"
                  @intersect="loadMore2"
                />
        </el-select>
    </div>
</template>
<script>
import {
    generateAvatarColor,
    returnInitials,
} from '../../../utils';
import { useUiStore } from '../../../stores/ui';
import { mapState } from 'pinia';
import ScrollObserver from '../../../components/ui/ScrollObserver.vue';
export default {
    emits: ["loadMore", "handleSelectedChange", "handleRemove", "handleAdd"],
    name: 'AvatarHandler',
    components: {
        ScrollObserver
    },
    props: {
        avatars: {
            type: Array,
            default() {
                return []
            }
        },
        selectionAvatars: {
            type: Array,
            default: () => []
        },
        overlap: {
            type: Boolean,
            default: false
        },
        imgWidth: {
            type: Number,
            default: 50
        },
        limit: {
            type: Number,
            default: 3
        },
        fontSize: {
            type: String,
            default: '20px'
        },
        gap: {
            type: Number,
            default: 10
        },
        removable: {
            type: Boolean,
            default: false
        },
        editable: {
            type: Boolean,
            default: true,
        },
        hover: {
            type: Boolean,
            default: false,
        },
        isColumn: {
            type: Boolean,
            default: false
        },
        isSelectable: {
            type: Boolean,
            default: false
        },
        mode: {
            type: String,
            default:""
        }
    },
    data() {
        return {
            prevId: null,
            showSelection: false,
            forceClose: null,
            limitNotRequired: null,
            addCount: '',
            selectedValue: null,
            busy: false,
        }
    },
    computed: {
        ...mapState(useUiStore,{
            isMobileView: state => state.isMobileView,
            currentScreenWidth: state => state.currentScreenWidth
        }),
        computedSelectionAvatars() {
            // return this.$props.selectionAvatars.filter(o1 => !this.$props.avatars.some(o2 => o1.id === o2.id));
            return this.$props.selectionAvatars.map(o1 => {
                        const isUnique = !this.$props.avatars.some(o2 => o1.id === o2.id);
                        return { ...o1, isUnique };
                    });
        },
        computedAvatars() {
            if (this.$props.limit < this.$props.avatars.length) {
                this.limitNotRequired = false;
                this.addCount = `+${(this.$props.avatars.length - 1) - (this.$props.limit - 1)}`
                let filteredArr = this.$props.avatars.slice(0, (this.$props.limit + 1))
                let last = this.$props.avatars.slice(this.$props.limit, this.$props.avatars.length)
                let lastMessage = '';
                last.forEach((e, index) => {
                    lastMessage += e.name + `${index === last.length - 1 ? '' : ', '}`
                })
                filteredArr = filteredArr.map((e, index) => {
                    if (index === filteredArr.length - 1) {
                        return { ...e, name: `+${(this.$props.avatars.length - 1) - (this.$props.limit - 1)}`, message: lastMessage }
                    }
                    return { ...e, message: e.name }
                })
                return filteredArr.reverse()
            } else {
                this.limitNotRequired = true;
                this.$props.limit = this.$props.avatars.length - 1
                let arr = this.$props.avatars.map((e) => {
                    return { ...e, message: e.name }
                })
                return arr.reverse()
            }
        }
    },
    methods: {
        loadMore2() {
            this.$emit('loadMore')
        },
        handleCheckBoxChange(id) {
            this.$emit('handleSelectedChange', id)
        },
        handleClick() {
            this.showSelection = true
            this.$nextTick(() => {
                this.$refs.selection.focus();
            });
        },
        handleAdd(id) {
            
            this.$nextTick(() => {
                this.$refs.selection.focus();
                this.$refs.selection.visible = true;
            });
            if(id === this.prevId){
                this.selectedValue = ''
                return
            }
            this.prevId = id;
            setTimeout(() => {
                this.prevId = null
            }, 500)
            if(this.$props.avatars.find(e => e.id === id)){
                if (this.$props.avatars.length - 3 < this.$props.limit) this.$props.limit = this.$props.avatars.length - 1
                this.$emit('handleRemove', id)
            }else{
                let ele = this.$props.selectionAvatars.find(e => e.id === id)
                    if(this.$props.avatars.length<10){
                        this.$emit('handleAdd', ele)

                    }else{
                    
                        this.$message({
          showClose: true,
          message: 'You can add only 10 Collaborators',
          type: "error",
          center: true,
        });
                    }

           
                // this.showSelection = false
            }
                this.selectedValue = ''
            // this.$emit('handleAdd', ele)
            //if(this.$props.selectionAvatars.filter(o1 => !this.$props.avatars.some(o2 => o1.id === o2.id)).length === 0){} 
            
        },
        handleRemove(id) {
            if (this.$props.avatars.length - 3 < this.$props.limit) this.$props.limit = this.$props.avatars.length - 1
            this.$emit('handleRemove', id)
        },
        handleCollaboratorsDisability(){
            if(this.$props.avatars.length > 9){
                return{
                    color:"#C0C4CC"
                }
            }else{
                return 
            }
        },

        getAvatarStyle(value, index) {
            const backgroundColor = generateAvatarColor(value, this.addCount);
            if (this.$props.overlap) {
                return {
                    backgroundColor: backgroundColor,
                    fontSize: this.$props.fontSize,
                    left: `${((this.$props.limit + 1) - (index + 1)) * this.$props.imgWidth - ((this.$props.limit + 1) - (index + 1)) * (this.$props.gap)}px`
                };
            }
            return {
                marginRight: "2.5px",
                marginLeft: "2.5px",
                backgroundColor: backgroundColor,
                fontSize: this.$props.fontSize,
                left: `${((this.$props.limit + 1) - (index + 1)) * (this.$props.imgWidth + this.$props.gap) - ((this.$props.limit + 1) - (index + 1))}px`
            };
        },
        getTagAvatarStyle(value, index) {
            const backgroundColor = generateAvatarColor(value, this.addCount);
            return {
                marginRight: "2.5px",
                marginLeft: "2.5px",
                backgroundColor: backgroundColor,
                fontSize: '10px',
                position: 'absolute',
                left: '5px',
                top: '3px'
            };
        },
        getTagAvatarStyleTypeTwo(value, index) {
            const backgroundColor = generateAvatarColor(value, this.addCount);
            return {
                marginRight: "2.5px",
                marginLeft: "2.5px",
                backgroundColor: backgroundColor,
                fontSize: '10px',
                position: 'relative',
                top: '2px'
            };
        },
        getSelectionAvatarStyle(value, isDisabled) {
            const backgroundColor = generateAvatarColor(value, this.addCount);
            return {
                marginRight: "2.5px",
                marginTop: "2.5px",
                backgroundColor: backgroundColor,
                fontSize: '14px',
                position: 'relative',
                top: '0px',
                left: '-5px',
                opacity: isDisabled ? '0.2': '1'
            };
        },
        returnInitials,
    },
    mounted(){
        console.log(this.avatars);
        console.log(this.selectionAvatars)
        if(this.$props.avatars.length && this.$props.mode === 'view'){
            this.$refs.selection.currentPlaceholder = 'Select'
        }else if(this.$props.mode == 'create'){
            this.$refs.selection.currentPlaceholder = 'Select'
        }
    },
    watch: {
        avatars: {
            handler(value) {
                if (value.length === 0) {
                    this.forceClose = false
                }
            }
        }
    }
}
</script>
<style scoped>
.avatars-container {
    display: flex;
    gap: 6px;
}

.avatar-icons {
    position: 'relative';
    cursor: 'pointer';
    display: flex;
    align-items: center;
}

.tag-box {
    max-width: 500px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 0.5rem;
}

.tag-box>>>.el-tag.el-tag--info {
    position: relative;
}

.avatar-hover:hover {
    border: 1px solid white;
    z-index: 1;
}

.selection-avatars>>>.el-input__inner {
    /* width: 100px; */
    display: absolute;
    background-color: transparent;
    border: transparent;
}

.selection-avatars:hover {
    color: transparent;
    background-color: transparent;
    border: transparent;
}

.selection-avatars>>>.el-input.is-focus .el-input__inner {
    border: transparent;
    background-color: transparent;
}

.el-checkbox {
    width: 180px;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;
}

.el-checkbox>>>.el-checkbox__label {
    padding-left: 0;
}

.avatar-name {
    position: relative;
    top: 2px;
    left: 4px;
    width: 135px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.el-checkbox{
    display: inline-block;
    width: 30px;
}

.el-select-dropdown__item{
  padding-left: 16px;
}
div >>> .el-checkbox__inner{
    width: 18px !important;
    height: 18px;
    border-color: #777777;
    border-radius: 2px;
}
div >>> .el-checkbox__inner::after{
    margin: 2px;
}
div >>> .el-checkbox__input{
    top: -9px;
}
div >>> .el-popper[x-placement^="bottom"]{
    margin-top: 6px !important;
    padding-bottom: 6px !important;
}
.cb-avatar-name{
    position: relative;
    top: -9px;
}
@media (max-width: 768px){
    .selection-avatars>>>.el-select__caret {
        margin-right: 6px !important;
    }
}
</style>