import * as THREE from "three";
import * as utils from "../../../utils/utils.js";
import PVTile from "./PVTile.js";
import NonPVTile from "./NonPVTile.js";
import { TILE_VIEWS } from "./TilesGrid.js";
import CustomTile from "./CustomTile.js";

export default class GridCell {
    constructor(stage, grid) {
        this.stage = stage;
        this.grid = grid;
        this.isFullCell = true;
        // PV or Non-PV Tiles associated with this cell
        this.tiles = [];
    }

    autoAssignTiles(primitiveCell, pvAllowed = true) {
        this.isFullCell = primitiveCell.isFullCell;
        if (!primitiveCell.isFullCell) {
            this.isPV = false;
            const subCells = primitiveCell.subCells;
            const customTiles = [];
            subCells.forEach((subCell) => {
                const rectRegions = this.lineSweepAlgorithm(
                    primitiveCell.vertices,
                    subCell
                );
                // draw the subcell
                // this.drawPolygon(subCell, 0x00ffff);
                rectRegions.forEach((rectangle) => {
                    const rectangleLength = rectangle[1].x - rectangle[0].x;
                    const rectangleWidth = rectangle[2].y - rectangle[1].y;
                    const partialTileLengths =
                        this.grid.partialTileFractions.map((fraction) => {
                            return fraction * this.grid.tileLength;
                        });
                    // find the largest partial tile length that is less than the rectangle length
                    let partialTileLength = 0;
                    for (let i = 0; i < partialTileLengths.length; i += 1) {
                        if (
                            partialTileLengths[i] < rectangleLength &&
                            partialTileLengths[i] > partialTileLength
                        ) {
                            partialTileLength = partialTileLengths[i];
                        }
                    }
                    if (partialTileLength === 0) {
                        return;
                    }
                    const fractionIndex =
                        partialTileLengths.indexOf(partialTileLength);
                    const partialTileWidth = this.grid.tileWidth;
                    let position = new THREE.Vector3(
                        rectangle[0].x + partialTileLength / 2,
                        rectangle[0].y + rectangleWidth / 2,
                        0
                    );
                    const gapLength = rectangleLength - partialTileLength;
                    let togglePosition = new THREE.Vector3(
                        position.x + gapLength,
                        position.y,
                        0
                    );
                    const d1 = Math.abs(
                        primitiveCell.vertices[0].x - rectangle[0].x
                    );
                    const d2 = Math.abs(
                        primitiveCell.vertices[1].x - rectangle[1].x
                    );
                    if (d1 > d2) {
                        // swap position and togglePosition
                        const temp = position;
                        position = togglePosition;
                        togglePosition = temp;
                    }

                    const partialTile = new NonPVTile(
                        this.stage,
                        this,
                        position,
                        partialTileLength,
                        partialTileWidth
                    );

                    partialTile.fractionIndex = fractionIndex;
                    partialTile.isFull = false;
                    partialTile.subCell = rectangle;
                    partialTile.togglePosition = togglePosition;
                    partialTile.updateColors();
                    this.tiles.push(partialTile);


                });
                // subtract all the partial tiles from the subcell
                let customTile = subCell;
                for(let i = 0; i < this.tiles.length; i += 1) {
                    const buffer = 0.1; // Define your buffer size here
                    const xBuffer = 0;
                    const tileVertices = [
                        new THREE.Vector3(
                            this.tiles[i].position.x - this.tiles[i].length / 2 - xBuffer,
                            this.tiles[i].position.y - this.tiles[i].width / 2 - buffer,
                            0
                        ),
                        new THREE.Vector3(
                            this.tiles[i].position.x + this.tiles[i].length / 2 + xBuffer,
                            this.tiles[i].position.y - this.tiles[i].width / 2 - buffer,
                            0
                        ),
                        new THREE.Vector3(
                            this.tiles[i].position.x + this.tiles[i].length / 2 + xBuffer,
                            this.tiles[i].position.y + this.tiles[i].width / 2 + buffer,
                            0
                        ),
                        new THREE.Vector3(
                            this.tiles[i].position.x - this.tiles[i].length / 2 - xBuffer,
                            this.tiles[i].position.y + this.tiles[i].width / 2 + buffer,
                            0
                        ),
                    ];
                    try {
                        customTile = utils.differenceOfPolygons(customTile, tileVertices);
                    } catch (error) {
                        console.error(error);
                        continue;
                    }
                }
                if(this.tiles.length > 0) {
                    customTile.forEach((tile) => {
                        const customTileObject = new CustomTile(this.stage, this, tile);
                        customTiles.push(customTileObject);
                        // this.drawPolygon([tile], 0xff00ff);
                    });
                }else{
                    if(customTile.length >= 3) {
                        const customTileObject = new CustomTile(this.stage, this, customTile);
                        customTiles.push(customTileObject);
                        // this.drawPolygon(customTile, 0xff00ff);
                    }
                }                
            });

            if(customTiles.length > 0) {
                this.customTiles = customTiles;
            }
        } else {
            const position = primitiveCell.vertices
                .reduce((acc, vertex) => {
                    return acc.add(vertex);
                }, new THREE.Vector3(0, 0, 0))
                .divideScalar(primitiveCell.vertices.length);
            const length = this.grid.tileLength;
            const width = this.grid.tileWidth;
            if (this.grid.pvEnabled && pvAllowed) {
                this.isPV = true;
                const tile = new PVTile(
                    this.stage,
                    this,
                    position,
                    length,
                    width
                );
                this.tiles.push(tile);
            } else {
                this.isPV = false;
                const tile = new NonPVTile(
                    this.stage,
                    this,
                    position,
                    length,
                    width,
                );
                this.tiles.push(tile);
            }
        }
    }

    // create a new non pv tile using the same parameters and switch the tile
    convertToNonPVTile(pvTile, resetDC = true) {
        pvTile.hide();
        const nonPVTile = new NonPVTile(
            this.stage,
            this,
            pvTile.position,
            pvTile.length,
            pvTile.width
        );
        this.tiles.splice(this.tiles.indexOf(pvTile), 1, nonPVTile);
        this.isPV = false;
        // update the instanced mesh
        this.grid.updateTilesMesh();
        if(resetDC) {
            this.grid.getPowerRoof().resetElectricals();
        }
        if (pvTile.isSelected) {
            pvTile.deSelect();
        }
        this.saveState();
    }

    // create a new pv tile using the same parameters and switch the tile
    convertToPVTile(nonPVTile, resetDC = true) {
        nonPVTile.hide();
        const pvTile = new PVTile(
            this.stage,
            this,
            nonPVTile.position,
            nonPVTile.length,
            nonPVTile.width
        );
        this.tiles.splice(this.tiles.indexOf(nonPVTile), 1, pvTile);
        this.isPV = true;
        // update the instanced mesh
        this.grid.updateTilesMesh();
        if(resetDC) {
            this.grid.getPowerRoof().resetElectricals();
        }
        if (nonPVTile.isSelected) {
            nonPVTile.deSelect();
        }
        this.saveState();
    }

    convertToCustomTile(nonPVTile, resetDC = true) {
        nonPVTile.hide();
        const customTile = new CustomTile(
            this.stage,
            this,
            [
                new THREE.Vector3(
                    nonPVTile.position.x - nonPVTile.length / 2,
                    nonPVTile.position.y - nonPVTile.width / 2,
                    0
                ),
                new THREE.Vector3(
                    nonPVTile.position.x + nonPVTile.length / 2,
                    nonPVTile.position.y - nonPVTile.width / 2,
                    0
                ),
                new THREE.Vector3(
                    nonPVTile.position.x + nonPVTile.length / 2,
                    nonPVTile.position.y + nonPVTile.width / 2,
                    0
                ),
                new THREE.Vector3(
                    nonPVTile.position.x - nonPVTile.length / 2,
                    nonPVTile.position.y + nonPVTile.width / 2,
                    0
                ),
            ]
        );
        this.tiles.splice(this.tiles.indexOf(nonPVTile), 1);
        if (!this.customTiles) {
            this.customTiles = [];
        }
        this.customTiles.push(customTile);
        this.isPV = false;
        // update the instanced mesh
        this.grid.updateTilesMesh();
        if(resetDC) {
            this.grid.getPowerRoof().resetElectricals();
        }
        if (nonPVTile.isSelected) {
            nonPVTile.deSelect();
        }
        this.saveState();
    }

    convertToNonPVTileWhileMultiSelect(pvTile) {
        pvTile.hide();
        const nonPVTile = new NonPVTile(
            this.stage,
            this,
            pvTile.position,
            pvTile.length,
            pvTile.width
        );
        this.tiles.splice(this.tiles.indexOf(pvTile), 1, nonPVTile);
        this.isPV = false;
    }

    convertToPVTileWhileMultiSelect(nonPVTile) {
        nonPVTile.hide();
        const pvTile = new PVTile(
            this.stage,
            this,
            nonPVTile.position,
            nonPVTile.length,
            nonPVTile.width
        );
        this.tiles.splice(this.tiles.indexOf(nonPVTile), 1, pvTile);
        this.isPV = true;
    }
    removeTile(tile) {
        tile.hide();
        this.tiles.splice(this.tiles.indexOf(tile), 1);
        this.isPV = false;
        // update the instanced mesh
        this.grid.updateTileMesh(tile);
        if(tile instanceof PVTile) {
            this.grid.getPowerRoof().resetElectricals();
        }
    }

    saveObject() {
        const gridCellData = {
            type: GridCell.getObjectType(),
        };
        gridCellData.isFullCell = this.isFullCell;
        gridCellData.isPV = this.isPV;
        gridCellData.tiles = this.tiles.map((tile) => {
            return tile.saveObject();
        });
        gridCellData.customTiles = this.customTiles ? this.customTiles.map((tile) => {
            return tile.saveObject();
        }) : [];
        return gridCellData;
    }

    loadObject(gridCellData) {
        this.isFullCell = gridCellData.isFullCell;
        this.isPV = gridCellData.isPV;
        this.tiles = gridCellData.tiles.map((tileData) => {
            if (tileData.type === PVTile.getObjectType()) {
                const tile = new PVTile(this.stage, this);
                tile.loadObject(tileData);
                return tile;
            } else if (tileData.type === NonPVTile.getObjectType()) {
                const tile = new NonPVTile(this.stage, this);
                tile.loadObject(tileData);
                return tile;
            }
        });
        this.customTiles = gridCellData.customTiles?.map((tileData) => {
            const tile = new CustomTile(this.stage, this,[]);
            tile.loadObject(tileData);
            return tile;
        });
    }

    static getObjectType() {
        return "gridCell";
    }

    lineSweepAlgorithm(rectangleVertices, polygonVertices) {
        const EPSILON = 0.0001;
        const rectVerts = rectangleVertices;

        // the rectangle vertices define the area of the sweep
        const startY = rectVerts[0].y;
        const endY = rectVerts[2].y;
        let sortedVertices = [...polygonVertices].sort((a, b) => {
            return a.x - b.x;
        });
        // if the polygon vertices max y is less than endY then return empty array
        // if the polygon vertices min y is greater than startY then return empty array
        const polygonMaxY = Math.max(
            ...polygonVertices.map((vertex) => {
                return vertex.y;
            })
        );
        const polygonMinY = Math.min(
            ...polygonVertices.map((vertex) => {
                return vertex.y;
            })
        );
        polygonVertices.forEach((vertex) => {
            vertex.z = 0;
        });

        if (polygonMaxY < endY - EPSILON || polygonMinY > startY + EPSILON) {
            return [];
        }

        let topStart = false;
        let topStartVertex = null;
        let bottomStart = false;
        let bottomStartVertex = null;
        let topEndVertex = null;
        let bottomEndVertex = null;

        let remainingVertices = [...sortedVertices];

        let bufferVertices = [];
        let currentBuffer = [];

        for (let i = 0; i < remainingVertices.length; i += 1) {
            // is end vertex
            const isTopVertex =
                Math.abs(remainingVertices[i].y - endY) < EPSILON;
            const isBottomVertex =
                Math.abs(remainingVertices[i].y - startY) < EPSILON;
            if (isTopVertex && !topStart) {
                topStart = true;
                topStartVertex = remainingVertices[i];
                if (bottomStart) {
                    currentBuffer.push(topStartVertex);
                }
            } else if (isTopVertex && topStart) {
                topStart = false;
                topEndVertex = remainingVertices[i];
                if (bottomStart) {
                    currentBuffer.push(topEndVertex);
                    currentBuffer.sort((a, b) => {
                        return a.x - b.x;
                    });
                    bufferVertices.push(currentBuffer);
                    currentBuffer = [];
                }
            }
            if (isBottomVertex && !bottomStart) {
                bottomStart = true;
                bottomStartVertex = remainingVertices[i];
                if (topStart) {
                    currentBuffer.push(bottomStartVertex);
                }
            } else if (isBottomVertex && bottomStart) {
                bottomStart = false;
                bottomEndVertex = remainingVertices[i];
                if (topStart) {
                    currentBuffer.push(bottomEndVertex);
                    currentBuffer.sort((a, b) => {
                        return a.x - b.x;
                    });
                    bufferVertices.push(currentBuffer);
                    currentBuffer = [];
                }
            }
            if (!isTopVertex && !isBottomVertex && topStart && bottomStart) {
                currentBuffer.push(remainingVertices[i]);
            }
        }

        const tileThreshold = 0.1;

        const rectRegions = [];
        for (let i = 0; i < bufferVertices.length; i += 1) {
            for (let j = 0; j < bufferVertices[i].length - 1; j += 1) {
                let start = bufferVertices[i][j];
                const nudgedX = start.x;
                const lineStart = new THREE.Vector3(nudgedX, startY, 0);
                const lineEnd = new THREE.Vector3(nudgedX, endY, 0);
                const intersections = [];
                for (let k = 0; k < polygonVertices.length; k += 1) {
                    const intersection = this.lineLineIntersection(
                        lineStart,
                        lineEnd,
                        polygonVertices[k],
                        polygonVertices[(k + 1) % polygonVertices.length]
                    );

                    // ignore intersections whose y value is very close to the start or end y value or the start.y
                    if (
                        intersection &&
                        Math.abs(intersection.y - startY) > EPSILON &&
                        Math.abs(intersection.y - endY) > EPSILON &&
                        Math.abs(intersection.y - start.y) > EPSILON
                    ) {
                        intersections.push(intersection);
                    }
                }
                if (intersections.length === 0) {
                    if (
                        Math.abs(start.x - bufferVertices[i][j + 1].x) >
                        tileThreshold
                    ) {
                        rectRegions.push([start, bufferVertices[i][j + 1]]);
                        break;
                    }
                }
            }
        }
        const finalRectangles = rectRegions.map((rectRegion) => {
            rectRegion.sort((a, b) => {
                return a.x - b.x;
            });
            const v1 = new THREE.Vector3(rectRegion[0].x, startY, 0);
            const v2 = new THREE.Vector3(rectRegion[1].x, startY, 0);
            const v3 = new THREE.Vector3(rectRegion[1].x, endY, 0);
            const v4 = new THREE.Vector3(rectRegion[0].x, endY, 0);
            const rectangle = [v1, v2, v3, v4];
            return rectangle;
        });

        return finalRectangles;
    }

    lineLineIntersection(line1Start, line1End, line2Start, line2End) {
        const x1 = line1Start.x;
        const y1 = line1Start.y;
        const x2 = line1End.x;
        const y2 = line1End.y;
        const x3 = line2Start.x;
        const y3 = line2Start.y;
        const x4 = line2End.x;
        const y4 = line2End.y;
        const denominator = (x1 - x2) * (y3 - y4) - (y1 - y2) * (x3 - x4);

        // Check if the denominator is zero, which would cause division by zero
        if (denominator === 0) {
            return null;
        }

        const x =
            ((x1 * y2 - y1 * x2) * (x3 - x4) -
                (x1 - x2) * (x3 * y4 - y3 * x4)) /
            denominator;
        const y =
            ((x1 * y2 - y1 * x2) * (y3 - y4) -
                (y1 - y2) * (x3 * y4 - y3 * x4)) /
            denominator;

        // Check if x or y is NaN or Infinity
        if (!isFinite(x) || !isFinite(y)) {
            return null;
        }

        if (
            x < Math.min(x1, x2) ||
            x > Math.max(x1, x2) ||
            x < Math.min(x3, x4) ||
            x > Math.max(x3, x4)
        ) {
            return null;
        }

        return new THREE.Vector3(x, y, 0);
    }

    drawPolygon(vertices, colorValue) {
        this.grid.drawPolygon(vertices, colorValue);
    }

    drawVertex(vertex, colorValue = 0xff0000) {
        this.grid.drawVertex(vertex, colorValue);
    }

    toGlobal(vector = new THREE.Vector3()) {
        return this.grid.toGlobal(vector);
    }

    arrayToGlobal(array = []) {
        return array.map((vector) => {
            return this.toGlobal(vector);
        });
    }

    getMatrix() {
        return this.grid.matrix;
    }

    saveState() {
        this.grid.saveState();
    }

    getPowerRoof() {
        return this.grid.getPowerRoof();
    }
}
