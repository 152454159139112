import * as THREE from "three";
import ThreejsText from "../../subObjects/ThreejsText";
const INVERTER_WIDTH = 0.2;
const INVERTER_HEIGHT = 0.2;

const INVERTER_COLOR = 0x0000ff;
export default class PowerRoofInverter {
    constructor(stage, powerRoof) {
        this.stage = stage;
        this.powerRoof = powerRoof;
        this.objectsGroup = new THREE.Group();
        this.powerRoof.objectsGroup.add(this.objectsGroup);
        // this.stage.sceneManager.scene.add(this.objectsGroup);
        this.numberOfMicroInverters = 0;
        this.inverterInstances = [];
        this.setDefaultInverterValues();
        this.defaultColor = new THREE.Color(INVERTER_COLOR);
        this.initInstancedMesh();
        this.electricalProperties = {
            Size: 0.29,
        };
    }

    setDefaultInverterValues() {
        this.maxInverterPerBranch = 13;
        this.minTiles = 3;
        this.maxTiles = 4;
    }

    initInstancedMesh() {
        // create a geometry for the Inverters
        const geometry = new THREE.PlaneGeometry(
            INVERTER_WIDTH,
            INVERTER_HEIGHT
        );
        geometry.computeVertexNormals();
        // const material = this.getDefaultMaterial();
        const material = this.getCustomShaderMaterial();

        // create an instanced mesh for the grid cells
        this.inverterMesh = new THREE.InstancedMesh(geometry, material, 1000);
        this.inverterMesh.instanceMatrix.setUsage(THREE.DynamicDrawUsage);
        this.inverterMesh.instanceColor = new THREE.InstancedBufferAttribute(
            new Float32Array(1000 * 3),
            3,
            1
        );
        this.inverterMesh.container = this;
        this.inverterMesh.frustumCulled = false;
        this.inverterMesh.count = 0;
        if(!this.objectsGroup) {
            this.objectsGroup = new THREE.Group();
            this.powerRoof.objectsGroup.add(this.objectsGroup);
        }
        this.objectsGroup.add(this.inverterMesh);
        this.dummyObject = new THREE.Object3D();
        this.inverterMesh.selectable = false;
    }

    removeObject() {
        this.inverterInstances.forEach((inverterInstance) => {
            inverterInstance.removeObject();
        });
        this.objectsGroup?.parent?.remove(this.objectsGroup);
        this.objectsGroup = null;
        this.inverterMesh = null;
    }

    getDefaultColor() {
        return this.defaultColor;
    }

    getLift() {
        return 0.2;
    }

    /**
     * Adds a new inverter to the power roof.
     * @param {InverterInstance} inverterInstance
     */
    addInverter(inverterInstance) {
        this.inverterInstances.push(inverterInstance);
        // clear the dummy object transformations
        this.dummyObject.position.set(0, 0, 0);
        this.dummyObject.rotation.set(0, 0, 0);
        this.dummyObject.scale.set(1, 1, 1);
        // apply the matrix of the inverterInstance
        this.inverterMesh.setColorAt(
            this.inverterMesh.count,
            inverterInstance.color
        );
        this.dummyObject.position.set(
            inverterInstance.position.x,
            inverterInstance.position.y,
            inverterInstance.getLift()
        );
        if (inverterInstance.visible) {
            this.dummyObject.scale.set(1, 1, 1);
        } else {
            this.dummyObject.scale.set(0, 0, 0);
        }
        this.dummyObject.updateMatrix();
        this.dummyObject.applyMatrix4(inverterInstance.getMatrix());
        this.inverterMesh.setMatrixAt(
            this.inverterMesh.count,
            this.dummyObject.matrix
        );
        this.inverterMesh.instanceMatrix.needsUpdate = true;
        this.inverterMesh.instanceColor.needsUpdate = true;
        this.inverterMesh.computeBoundingBox();
        this.inverterMesh.computeBoundingSphere();
        inverterInstance.instanceMesh = this.inverterMesh;
        inverterInstance.instanceIndex = this.inverterMesh.count;
        this.inverterMesh.count += 1;
    }

    addInverters(inverterInstances) {
        inverterInstances.forEach((inverterInstance) => {
            if(!inverterInstance.defaultColor) {
                inverterInstance.defaultColor = this.getDefaultColor();
            }
            if (!inverterInstance.color) {
                inverterInstance.color = this.getDefaultColor();
            }
            this.addInverter(inverterInstance);
        });
    }

    showInverters() {
        this.inverterMesh.visible = true;
        this.inverterInstances.forEach((inverterInstance) => {
            inverterInstance.showObject();
        });
    }

    hideInverters() {
        if (this.inverterMesh) {
            this.inverterMesh.visible = false;
        }
        if (this.inverterInstances) {
            this.inverterInstances.forEach((inverterInstance) => {
                inverterInstance.hideObject();
            });
        }
    }

    clearInverters() {
        this.inverterInstances.forEach((inverterInstance) => {
            inverterInstance.instanceMesh = null;
            inverterInstance.instanceIndex = null;
            inverterInstance.removeObject();
        });
        this.inverterInstances = [];
        if(this.inverterMesh) {
            this.inverterMesh.count = 0;
        }
    }

    resetInverters() {
        this.inverterInstances.forEach((inverterInstance) => {
            inverterInstance.reset();
        });
    }

    getDefaultMaterial() {
        return new THREE.MeshBasicMaterial({
            color: INVERTER_COLOR,
            side: THREE.DoubleSide,
            transparent: true,
            opacity: 0.8,
            vertexColors: true,
        });
    }

    //TODO: Add electricalProperties
    getAcSize() {
        if (
            this.electricalProperties !== undefined &&
            this.electricalProperties.Size
        ) {
            return this.inverterInstances.length*this.electricalProperties.Size * 1000;
        }
        return 1;
    }

    getInverterByPlacementTileId(placementTileId) {
        return this.inverterInstances.find(
            (inverterInstance) => inverterInstance.placementTile.id === placementTileId
        );
    }

    getCustomShaderMaterial() {
        // simple vertex shader that passes on the uvs to the fragment shader
        const customVertexShader = `
        varying vec3 vViewPosition;
        varying vec3 vInstanceColor;
        
        #include <common>
        #include <uv_pars_vertex>
        #include <displacementmap_pars_vertex>
        #include <envmap_pars_vertex>
        #include <color_pars_vertex>
        #include <fog_pars_vertex>
        #include <normal_pars_vertex>
        #include <morphtarget_pars_vertex>
        #include <skinning_pars_vertex>
        #include <shadowmap_pars_vertex>
        #include <logdepthbuf_pars_vertex>
        #include <clipping_planes_pars_vertex>
        
        void main() {
         vInstanceColor = instanceColor;
         #include <uv_vertex>
         #include <color_vertex>
         #include <morphcolor_vertex>
        
         #include <beginnormal_vertex>
         #include <morphnormal_vertex>
         #include <skinbase_vertex>
         #include <skinnormal_vertex>
         #include <defaultnormal_vertex>
         #include <normal_vertex>
        
         #include <begin_vertex>
         #include <morphtarget_vertex>
         #include <skinning_vertex>
         #include <displacementmap_vertex>
         #include <project_vertex>
         #include <logdepthbuf_vertex>
         #include <clipping_planes_vertex>
         vUv = uv;

         gl_Position = projectionMatrix * viewMatrix * modelMatrix * instanceMatrix * vec4(position, 1.0);
         #include <logdepthbuf_vertex>
        
         vViewPosition = - mvPosition.xyz;
        
         #include <worldpos_vertex>
         #include <envmap_vertex>
         #include <shadowmap_vertex>
         #include <fog_vertex>
        }
        `;

        // simple fragment shader that colors the grid cells based on the uvs
        const customFragmentShader = `
        uniform vec3 diffuse;
        uniform vec3 emissive;
        uniform float opacity;
        varying vec2 vUv;
        varying vec3 vInstanceColor;


        #include <common>
        #include <packing>
        #include <dithering_pars_fragment>
        #include <color_pars_fragment>
        #include <uv_pars_fragment>
        #include <map_pars_fragment>
        #include <alphamap_pars_fragment>
        #include <alphatest_pars_fragment>
        // #include <alphahash_pars_fragment>
        #include <aomap_pars_fragment>
        #include <lightmap_pars_fragment>
        #include <emissivemap_pars_fragment>
        #include <envmap_common_pars_fragment>
        #include <envmap_pars_fragment>
        #include <fog_pars_fragment>
        #include <bsdfs>
        #include <lights_pars_begin>
        #include <normal_pars_fragment>
        #include <lights_lambert_pars_fragment>
        #include <shadowmap_pars_fragment>
        #include <bumpmap_pars_fragment>
        #include <normalmap_pars_fragment>
        #include <specularmap_pars_fragment>
        #include <logdepthbuf_pars_fragment>
        #include <clipping_planes_pars_fragment>

        void main() {
            #include <clipping_planes_fragment>
    
            vec4 diffuseColor = vec4( diffuse * vInstanceColor, opacity );
            ReflectedLight reflectedLight = ReflectedLight( vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ) );
            vec3 totalEmissiveRadiance = emissive;
            #include <logdepthbuf_fragment>
            #include <map_fragment>
            #include <color_fragment>
            #include <alphamap_fragment>
            #include <alphatest_fragment>
            // #include <alphahash_fragment>
            #include <specularmap_fragment>
            #include <normal_fragment_begin>
            #include <normal_fragment_maps>
            #include <emissivemap_fragment>
    
            // accumulation
            #include <lights_lambert_fragment>
            #include <lights_fragment_begin>
            #include <lights_fragment_maps>
            #include <lights_fragment_end>
    
            // modulation
            #include <aomap_fragment>
    
            vec3 outgoingLight = reflectedLight.directDiffuse + reflectedLight.indirectDiffuse + totalEmissiveRadiance;
            outgoingLight = (0.5*diffuseColor.rgb) + (0.5*outgoingLight);
            #include <envmap_fragment>
            // #include <opaque_fragment>
            #include <tonemapping_fragment>
            // #include <colorspace_fragment>
            #include <fog_fragment>
            #include <premultiplied_alpha_fragment>
            #include <dithering_fragment>
    
            vec2 uv = vUv;
            gl_FragColor = vec4(outgoingLight, 1.0);            
        }
        `;

        const uniforms = THREE.UniformsUtils.merge([
            THREE.ShaderLib.lambert.uniforms,
            {
                diffuse: { value: new THREE.Color(0xffffff) },
                opacity: { value: 1.0 },
            },
        ]);
        const material = new THREE.ShaderMaterial({
            vertexShader: customVertexShader,
            fragmentShader: customFragmentShader,
            side: THREE.DoubleSide,
            uniforms: uniforms,
            lights: true,
        });

        return material;
    }
}

export class InverterInstance {
    constructor(pvTilesGroup, placementTile) {
        this.pvTilesGroup = pvTilesGroup;
        this.placementTile = placementTile;
        this.position = placementTile.position.clone();
        this.defaultColor = null;
        this.instanceMesh = null;
        this.instanceIndex = null;
        this.visible = true;
        this.length = INVERTER_WIDTH * 7;
        // this.text = 'B1-1';
        this.text = "";
        const globalPosition = this.getWorldPosition();
        this.textMesh = new ThreejsText(
            this.text,
            globalPosition,
            0,
            placementTile.stage,
            this,
            false,
            "center",
            "middle",
            null,
            true
        );
        this.textMesh.showText();
    }

    showText() {
        this.textMesh.showText();
    }

    hideText() {
        this.textMesh.hideText();
    }

    showObject() {
        this.visible = true;
        this.textMesh.showText();
        this.textMesh.updateScale();
    }

    hideObject() {
        this.visible = false;
        this.textMesh.hideObject();
    }

    removeObject() {
        this.textMesh.removeObject();
    }

    updateText(newText) {
        this.text = newText;
        this.textMesh.textMesh.text = this.text;
        this.textMesh.updateScale();
    }

    setColor(color = this.color) {
        this.color = color;
        if (this.instanceMesh) {
            this.instanceMesh.setColorAt(this.instanceIndex, color);
            this.instanceMesh.instanceColor.needsUpdate = true;
        }
    }

    setPlacementTile(placementTile) {
        this.placementTile = placementTile;
        this.position = placementTile.position;
    }

    getMatrix() {
        return this.placementTile.getMatrix();
    }

    getWorldPosition() {
        const localPosition = this.placementTile.position;
        const matrix = this.getMatrix();
        const worldPosition = localPosition.clone().applyMatrix4(matrix);
        return worldPosition;
    }

    getLift() {
        return this.placementTile.getInverterLift();
    }

    saveObject() {
        return {
            position: [this.position.x, this.position.y, this.position.z],
        };
    }

    reset() {
        this.textMesh.removeObject();
        this.setColor(this.defaultColor);
    }
}
