import { defineStore } from 'pinia'
import API from '../services/api';

function getInitialState() {
    return {
        mechanicalComponents: {
            blockFoot: {
                footing: {
                    "name": "Arka Block Foot",
                    "width": 0.14,
                    "height": 0.065,
                    "length": 0.45,
                    "weight": 2
                },
                hipCap: {
                    "name": "Arka Block Foot Hip",
                    "width": 0.27,
                    "height": 0.082,
                    "length": 0.8,
                    "weight": 2,
                    "hip_cap_overlap": 0.05
                },
                ridgeCap: {
                    "name": "Arka Block Foot Ridge Cap",
                    "width": 0.27,
                    "height": 0.082,
                    "length": 0.8,
                    "weight": 2,
                    "ridge_cap_overlap": 0.05,
                    "clearance_from_first_row": 0.19
                },
                ridgeJunction: {
                    "name": "Arka Junction Cap",
                    "width": 0.27,
                    "height": 0.082,
                    "weight": 2
                },
                valleyGutter: {
                    "name": "Arka Valley Gutter",
                    "width": 0.3,
                    "height": 0.051,
                    "length": 2.4,
                    "weight": 2
                },
            }
        },
        pvTile: {
            id: 2,
            characteristics: {
                length: 1.26,
                width: 0.465,
                p_mp_ref: 90,
                manufacturer: "Arka energy",
                series: "PV SOLAR TILE – 90Wp",
                model: "PV SOLAR TILE – 90Wp",
                cell_number: 13,
                cell_type: "PERC",
                v_max: 600,
                pdf_link:
                    "https://s3.ap-south-1.amazonaws.com/componentpdf/Tile_DS__90_020_PV_TILE__R01.pdf",
            },
        },
        isTilesSolarIrradianceFetched: false,
        isPowerRoofPresentInScene: false,
        isCombinerBoxPresentInScene: false,
        dcViewVisible: false,
        tilesAestheticMaterial: null,
        cappingsAestheticMaterial: null,
    };
}

export const useTilesStore = defineStore('tilesStore', {
    state: () => getInitialState(),
    getters: {
    },
    actions: {
        async LOAD_MECHANICAL_COMPONENTS(versionData) {
            const blockFootData = versionData.blockFoot;
            if(blockFootData?.footing?.id){
                this.mechanicalComponents.blockFoot.footing = (await API.POWER_ROOF.GET_MECHANICAL_COMPONENT_DETAIL(blockFootData.footing.id)).data;
            }
            if(blockFootData?.hipCap?.id){
                this.mechanicalComponents.blockFoot.hipCap = (await API.POWER_ROOF.GET_MECHANICAL_COMPONENT_DETAIL(blockFootData.hipCap.id)).data;
            }
            if(blockFootData?.ridgeCap?.id){
                this.mechanicalComponents.blockFoot.ridgeCap = (await API.POWER_ROOF.GET_MECHANICAL_COMPONENT_DETAIL(blockFootData.ridgeCap.id)).data;
            }
            if(blockFootData?.ridgeJunction?.id){
                this.mechanicalComponents.blockFoot.ridgeJunction = (await API.POWER_ROOF.GET_MECHANICAL_COMPONENT_DETAIL(blockFootData.ridgeJunction.id)).data;
            }
            if(blockFootData?.valleyGutter?.id){
                this.mechanicalComponents.blockFoot.valleyGutter = (await API.POWER_ROOF.GET_MECHANICAL_COMPONENT_DETAIL(blockFootData.valleyGutter.id)).data;
            }
        },

        async LOAD_PV_TILE_INFO(id) {
            this.pvTile = (await API.POWER_ROOF.GET_PV_TILE_DETAILS(id)).data;
        }
    },
});