import * as THREE from "three";
import { InverterInstance } from "./PowerRoofInverter";
import { PathNode, connectPathNodes } from "./PathFinding";

const defaultDCAnnealingParameters = {
    initialTemperature: 100000,
    coolingFactor: 0.9995,
    stoppingTemperature: 0.001,
    maxIterations: 100000,
    rowPenaltyMultiplier: 2.2,
    columnPenaltyMultiplier: 2,
    cablePenaltyMultiplier: 5,
};

const defaultACAnnealingParameters = {
    initialTemperature: 10000,
    coolingFactor: 0.995,
    stoppingTemperature: 0.01,
    maxIterations: 1000,
};

const defaultInverterParameters = {
    minTiles: 3,
    maxTiles: 4,
    maxInverterPerBranch: 13,
};

export function getPenaltyColor(penalty) {
    penalty *= 2;
    let red = penalty > 1 ? 1 : penalty;
    let green = penalty > 1 ? 2 - penalty : 1;
    return new THREE.Color(`rgb(${Math.round(red * 255)}, ${Math.round(green * 255)}, 0)`);
}

export function getNormalizedPenalty(penalty) {
    // assume 7 is 0 penalty and 12 is 1 penalty
    const minPenalty = 5.5;
    const maxPenalty = 11;
    const penaltyRange = maxPenalty - minPenalty;
    const boundedPenalty = Math.max(minPenalty, Math.min(maxPenalty, penalty));
    const normalizedPenalty = (boundedPenalty - minPenalty) / penaltyRange;
    return normalizedPenalty;
}

export class PVTileGroup {
    constructor(annealer, tiles, cabling = []) {
        this.annealer = annealer;
        this.tileLength = annealer.tileLength;
        this.tileWidth = annealer.tileWidth;
        this.pigTailLength = annealer.pigTailLength;
        this.tiles = tiles;
        this.cabling = cabling;
        this.normalizedCablePenalty = null;
        this.rowPenalty = null;
        this.columnPenalty = null;
        this.totalPenalty = null;
        this.penaltySegments = [];
        this.inverterInstance = null;

        // calculate row return cable length which is the sum of tile widths minus one pigtail length
        this.rowReturnCableLength =
            tiles.length * this.tileLength - this.pigTailLength;
        this.sortedTiles = tiles.toSorted((a, b) => {
            if (a.position.x === b.position.x) {
                return a.position.y - b.position.y;
            }
            return a.position.x - b.position.x;
        });
        this.position = this.getTilesCentroid();
        this.configurationID = this.getTilesRelativeConfigurationID();
        this.calculateWeightedTotalPenalty();
    }

    getTilesCentroid() {
        const centroid = new THREE.Vector2();
        this.tiles.forEach((tile) => {
            centroid.add(tile.position);
        });
        centroid.divideScalar(this.tiles.length);
        return centroid;
    }

    getTilesRelativeConfigurationID() {
        let configurationID = "";
        const sortedTiles = this.sortedTiles;
        for (let i = 0; i < sortedTiles.length; i++) {
            configurationID += `${sortedTiles[i].instanceIndex}`;
        }
        return configurationID;
    }

    /**
     * Calculates the actual cable length and the penalty cable length.
     *
     *
     * @returns {number} The normalized cable penalty.
     */
    calculateCablePenalty() {
        if (this.normalizedCablePenalty) {
            return this.normalizedCablePenalty;
        }

        const configurationID = this.configurationID;

        if (this.annealer.lookup[configurationID]) {
            this.normalizedCablePenalty =
                this.annealer.lookup[configurationID].normalizedCablePenalty;
            this.actualCableLength =
                this.annealer.lookup[configurationID].actualCableLength;
            this.penaltyCableSegments =
                this.annealer.lookup[configurationID].penaltyCableSegments;
            this.actualCableSegments =
                this.annealer.lookup[configurationID].actualCableSegments;
            this.minLengthPermutation =
                this.annealer.lookup[configurationID].minLengthPermutation;

            // Update cabling
            this.cabling = [];
            for (let i = 0; i < this.minLengthPermutation.length - 1; i++) {
                this.cabling.push([
                    this.minLengthPermutation[i].positiveTerminal,
                    this.minLengthPermutation[i + 1].negativeTerminal,
                ]);
            }
            // add the row return cable
            this.cabling.push([
                this.minLengthPermutation[this.minLengthPermutation.length - 1]
                    .positiveTerminal,
                this.minLengthPermutation[0].negativeTerminal,
            ]);

            return this.normalizedCablePenalty;
        }

        let permutations = [];

        const permute = (arr, m = []) => {
            if (arr.length === 0) {
                permutations.push(m);
            } else {
                for (let i = 0; i < arr.length; i++) {
                    const curr = arr.slice();
                    const next = curr.splice(i, 1);
                    permute(curr.slice(), m.concat(next));
                }
            }
        };

        permute(this.tiles);

        const sortedTiles = this.sortedTiles;
        // thumb rule: the first tile in the permutation should be at the extreme left or right
        const firstSortedTile = sortedTiles[0];
        const lastSortedTile = sortedTiles[sortedTiles.length - 1];

        permutations = permutations.filter((permutation) => {
            const firstTile = permutation[0];
            return (
                firstTile === firstSortedTile || firstTile === lastSortedTile
            );
        });

        const pigtailLength = this.pigTailLength;

        let penaltyCableSegments = [];
        let actualCableSegments = [];
        let leastPenaltyLength = Infinity;
        let leastActualLength = Infinity;
        let minCableLengthPermutation = [];

        permutations.forEach((permutation) => {
            const currentSegments = [];
            let currentPenaltySegments = [];
            for (let i = 0; i < permutation.length - 1; i++) {
                const segment = permutation[i].positiveTerminal.distanceTo(
                    permutation[(i + 1) % permutation.length].negativeTerminal
                );
                currentSegments.push(segment);
                const segmentUnderPigTail = segment - 0.01 < pigtailLength;
                if (!segmentUnderPigTail) {
                    currentPenaltySegments.push(segment);
                }
            }

            const rowReturnSegment = permutation[
                permutation.length - 1
            ].positiveTerminal.distanceTo(permutation[0].negativeTerminal);
            currentSegments.push(rowReturnSegment);

            // not sure if row return segment should be penalized always
            currentPenaltySegments.push(this.rowReturnCableLength);
            // currentPenaltySegments.push(rowReturnSegment);

            if (rowReturnSegment > this.rowReturnCableLength) {
                const extraLength =
                    rowReturnSegment - this.rowReturnCableLength;
                currentPenaltySegments.push(extraLength * 2);
            }

            const currentPenaltyLength = currentPenaltySegments.reduce(
                (a, b) => a + b,
                0
            );
            const currentActualLength = currentSegments.reduce(
                (a, b) => a + b,
                0
            );

            if (currentPenaltyLength < leastPenaltyLength) {
                leastPenaltyLength = currentPenaltyLength;
                leastActualLength = currentActualLength;
                minCableLengthPermutation = permutation;
                penaltyCableSegments = currentPenaltySegments;
                actualCableSegments = currentSegments;
            }
        });

        this.cabling = [];
        for (let i = 0; i < minCableLengthPermutation.length - 1; i++) {
            this.cabling.push([
                minCableLengthPermutation[i].positiveTerminal,
                minCableLengthPermutation[i + 1].negativeTerminal,
            ]);
        }
        // add the row return cable
        this.cabling.push([
            minCableLengthPermutation[minCableLengthPermutation.length - 1]
                .positiveTerminal,
            minCableLengthPermutation[0].negativeTerminal,
        ]);
        this.normalizedCablePenalty = leastPenaltyLength / this.tiles.length;
        this.actualCableLength = leastActualLength;
        this.penaltyCableSegments = penaltyCableSegments;
        this.actualCableSegments = actualCableSegments;
        this.minLengthPermutation = minCableLengthPermutation;

        this.annealer.lookup[configurationID] = {
            normalizedCablePenalty: this.normalizedCablePenalty,
            actualCableLength: this.actualCableLength,
            penaltyCableSegments: this.penaltyCableSegments,
            actualCableSegments: this.actualCableSegments,
            minLengthPermutation: this.minLengthPermutation,
        };

        return this.normalizedCablePenalty;
    }

    /**
     * Calculates the row penalty for the tiles in the group.
     * The row penalty is the sum of distances between pairs of tiles that are not in the same row.
     * @returns {number} The calculated row penalty.
     */
    calulateRowPenalty() {
        const rowBuffer = 0.02;
        if (this.rowPenalty !== null && this.rowPenalty !== undefined) {
            return this.rowPenalty;
        }
        let rowPenalty = 0;
        // for each pair of tiles in the group, if the tiles are not in the same row, add the distance between them to the row penalty
        const sortedTiles = this.minLengthPermutation;
        const rowHeight = this.tileWidth;
        this.rowPenalties = [];
        for (let i = 0; i < sortedTiles.length - 1; i++) {
            for (let j = i + 1; j < sortedTiles.length; j++) {
                const distance = Math.abs(
                    sortedTiles[i].position.y - sortedTiles[j].position.y
                );
                if (distance > 0) {
                    this.rowPenalties.push(distance);
                    rowPenalty += distance;
                    if (distance > rowHeight + rowBuffer) {
                        rowPenalty += distance * (distance / rowHeight);
                    }
                }
            }
        }

        this.rowPenalty = rowPenalty;
        return rowPenalty;
    }

    /**
     * Calculates the column penalty for tiles in the group.
     * The column penalty represents the total distance between adjacent tiles
     * that exceeds the tile width plus a buffer value.
     * @returns {number} The calculated column penalty.
     */
    calulateColumnPenalty() {
        const columnBuffer = 0.02;
        if (this.columnPenalty !== null && this.columnPenalty !== undefined) {
            return this.columnPenalty;
        }
        let columnPenalty = 0;
        const tiles = this.minLengthPermutation;
        this.columnPenalties = [];
        for (let i = 0; i < tiles.length - 1; i++) {
            const distance = Math.abs(
                tiles[i].position.x - tiles[i + 1].position.x
            );
            if (distance > 0) {
                this.columnPenalties.push(distance);
                if (distance > this.tileLength + columnBuffer) {
                    columnPenalty += distance;
                }
            }
        }
        this.columnPenalty = columnPenalty;
        return columnPenalty;
    }

    /**
     * Calculates the weighted total penalty based on cable length penalty, row penalty, and column penalty.
     * If the total penalty has already been calculated, it returns the cached value.
     *
     * @returns {number} The weighted total penalty.
     */
    calculateWeightedTotalPenalty() {
        if (this.totalPenalty !== null && this.totalPenalty !== undefined) {
            return this.totalPenalty;
        }
        const cableWeight =
            this.annealer.annealingParameters.cablePenaltyMultiplier;
        const rowWeight =
            this.annealer.annealingParameters.rowPenaltyMultiplier;
        const columnWeight =
            this.annealer.annealingParameters.columnPenaltyMultiplier;
        this.calculateCablePenalty();
        this.calulateRowPenalty();
        this.calulateColumnPenalty();

        // Merge updateTotal logic here
        this.totalPenalty =
            this.normalizedCablePenalty * cableWeight +
            this.rowPenalty * rowWeight +
            this.columnPenalty * columnWeight;

        return this.totalPenalty;
    }

    reset() {
        this.normalizedCablePenalty = null;
        this.rowPenalty = null;
        this.columnPenalty = null;
        this.totalPenalty = null;
    }
}

export class PVString {
    constructor(microInverters = []) {
        this.microInverters = microInverters;
        this.cabling = [];
        this.cableLength = null;
        this.id = Math.random().toString(36).substring(7);
    }   
}

export class DCAnnealer {
    constructor({
        faceLocalVertices,
        gridCellRows,
        tileParameters,
        annealingParameters = defaultDCAnnealingParameters,
        inverterParameters = defaultInverterParameters,
        grid,
    }) {
        this.faceLocalVertices = faceLocalVertices;
        this.gridCellRows = gridCellRows;
        this.tileLength = tileParameters.tileLength;
        this.tileWidth = tileParameters.tileWidth;
        this.pigTailLength = tileParameters.pigTailLength;
        this.annealingParameters = annealingParameters;
        this.inverterParameters = inverterParameters;
        this.grid = grid;
        this.bestSolution = null;
        this.bestScore = null;
        this.currentSolution = null;
        this.currentScore = null;
        this.currentTemperature = null;
        this.iterations = 0;
        this.pvRows = null;
        this.groupedPV = null;
        this.lookup = new Map();
        this.numberOfPVGroups = 0;
        this.initialize();
    }

    initialize() {
        let tileCount = this.countAndReturnPvTiles();

        this.validGroupingSize = this.findValidGrouping(tileCount);

        if (!this.validGroupingSize) {
            console.warn("No valid grouping size found");
            return;
        }

        // group them into valid groupings
        this.groupedPV = this.getDefaultGrouping();
        this.numberOfPVGroups = this.groupedPV.length;
        this.penalty = this.computeTotalPenalty();
        this.colorGroups();
        this.drawCables();
        this.bestSolution = this.groupedPV;
        this.bestScore = this.penalty;
        this.currentSolution = this.groupedPV;
        this.currentScore = this.penalty;
    }

    getDefaultGrouping() {
        const pvRows = [...this.pvRows];
        // run a snake pattern to group the PV tiles using the valid grouping size
        const groupedPV = [];
        let right = true;
        let currentRow = 0;
        let currentCol = 0;
        // start bottom left
        const groups = this.validGroupingSize.groups.sort(
            (a, b) => b.groupSize - a.groupSize
        );
        for (let i = 0; i < groups.length; i++) {
            const { groupSize, count } = groups[i];
            for (let j = 0; j < count; j++) {
                const group = [];
                for (let k = 0; k < groupSize; k++) {
                    if (right) {
                        group.push(pvRows[currentRow][currentCol]);
                        currentCol++;
                        if (currentCol >= pvRows[currentRow].length) {
                            // break out of the loop if we are at the end of the row
                            if (currentRow === pvRows.length - 1) {
                                break;
                            }
                            currentCol = pvRows[currentRow + 1].length - 1; // Modify the next column to be the last element in the next row
                            currentRow++;
                            right = false;
                        }
                    } else {
                        group.push(pvRows[currentRow][currentCol]);
                        currentCol--;
                        if (currentCol < 0) {
                            currentCol = 0; // Modify the next column to be 0
                            currentRow++;
                            right = true;
                        }
                    }
                }
                const pvTileGroup = new PVTileGroup(this, group);
                groupedPV.push(pvTileGroup);
            }
        }
        return groupedPV;
    }

    countAndReturnPvTiles() {
        let tileCount = 0;
        this.pvRows = this.gridCellRows
            .map((row) =>
                row
                    .filter((cell) => cell.isPV && cell.tiles.length > 0)
                    .map((cell) => {
                        tileCount += cell.tiles.length;
                        return cell.tiles[0];
                    })
            )
            .filter((row) => row.length > 0);
        return tileCount;
    }

    findValidGrouping(tileCount) {
        let sums;
        let validGrouping = null;
        while (tileCount > 0) {
            sums = this.findSums(tileCount, this.inverterParameters);
            if (sums.length) {
                validGrouping = sums[0];
                break;
            }
            const lastTile = this.pvRows[this.pvRows.length - 1].pop();
            // if the last row is empty, remove it
            if (this.pvRows[this.pvRows.length - 1].length === 0) {
                this.pvRows.pop();
            }
            lastTile.convertToNonPVTile(false);
            tileCount--;
        }
        return validGrouping;
    }

    /**
     * Finds all possible combinations of groups and counts that sum up to a target value.
     *
     * @param {number} target - The target value to sum up to.
     * @param {Object} options - The options for finding sums.
     * @param {number} options.minTiles - The minimum number of tiles in a group.
     * @param {number} options.maxTiles - The maximum number of tiles in a group.
     * @returns {Array} - An array of objects representing the possible combinations.
     * Each object has the following properties:
     *   - groups: An array of objects representing each groupSize and its count.
     *   - totalGroups: The total number of groups in the combination.
     */
    findSums(target, { minTiles: min, maxTiles: max }) {
        let result = [];

        function helper(path, start, remainder) {
            if (remainder === 0) {
                result.push(path);
            }

            for (let i = start; i <= max; i++) {
                for (let count = 1; count * i <= remainder; count++) {
                    let newPath = [...path];
                    newPath[i - min] = count;
                    helper(newPath, i + 1, remainder - i * count);
                }
            }
        }

        helper(new Array(max - min + 1).fill(0), min, target);
        result.sort((a, b) => {
            return a.reduce((x, y) => x + y, 0) - b.reduce((x, y) => x + y, 0);
        });
        // format the result
        const formattedResult = [];
        for (let i = 0; i < result.length; i++) {
            formattedResult.push({
                groups: result[i].map((item, index) => {
                    return {
                        groupSize: index + min,
                        count: item,
                    };
                }),
                totalGroups: result[i].reduce((x, y) => x + y, 0),
            });
        }
        return formattedResult;
    }

    computeTotalPenalty(state = this.groupedPV) {
        let totalPenalty = 0;
        state.forEach((group) => {
            totalPenalty += group.calculateWeightedTotalPenalty();
        });
        return totalPenalty;
    }

    colorGroups(state = this.groupedPV) {
        state.forEach((group) => {
            const penalty = group.calculateWeightedTotalPenalty();
            const color = getPenaltyColor(getNormalizedPenalty(penalty));
            group.tiles.forEach((tile) => {
                tile.setDCViewColor({ dcColor: color });
            });
        });
    }

    drawCables(state = this.groupedPV) {
        this.grid.drawDCCables(state);
    }

    async run() {
        if (!this.currentSolution) {
            return false;
        }

        let newResult = {
            state: null,
            objective: Infinity,
        };
        let lastResult = {
            state: this.currentSolution,
            objective: this.currentScore,
        };

        let annealingParams = this.annealingParameters;

        const maxMetaIterations = 5;
        let metaIterations = 0;
        // Keep annealing until the objective stops improving
        do {
            newResult = await this.dcAnnealing(lastResult, annealingParams);
            lastResult = newResult;
            if (newResult.objective < lastResult.objective) {
                metaIterations = 0;
            }
            metaIterations++;
        } while (
            newResult.objective <= lastResult.objective &&
            metaIterations < maxMetaIterations
        );

        this.currentSolution = newResult.state;
        this.currentScore = newResult.objective;
        this.groupedPV = newResult.state;
        this.colorGroups();
        this.drawCables();
        await new Promise((resolve) => setTimeout(resolve, 20));

        this.assignMicroInverters(this.groupedPV, this.faceLocalVertices);

        const pvTileGroups = this.groupedPV.map((group) => {
            return {
                tiles: group.tiles,
                inverterInstance: group.inverterInstance,
                cabling: group.cabling,
                penalty: group.totalPenalty,
                cableLength: group.actualCableLength,
            };
        });

        return pvTileGroups;
    }

    assignMicroInverters(pvTileGroups, faceLocalVertices) {
        const inverterInstances = [];

        const xValues = faceLocalVertices.flat().map((vertex) => vertex.x);
        const minX = Math.min(...xValues);
        const maxX = Math.max(...xValues);

        // for each group in the state, find the tile which is closest to either minX or maxX
        pvTileGroups.forEach((group) => {
            const tiles = group.tiles;
            let closestTile = null;
            let closestDistance = Infinity;
            for (let i = 0; i < tiles.length; i++) {
                const tile = tiles[i];
                const distanceToMinX = Math.abs(tile.position.x - minX);
                const distanceToMaxX = Math.abs(tile.position.x - maxX);
                const distance = Math.min(distanceToMinX, distanceToMaxX);
                if (distance < closestDistance) {
                    closestTile = tile;
                    closestDistance = distance;
                }
            }
            if (!closestTile) {
                console.warn("closestTile is null");
                closestTile = tiles[0];
            }

            const microInverterInstance = new InverterInstance(
                group,
                closestTile
            );
            closestTile.inverterInstance = microInverterInstance;
            group.inverterInstance = microInverterInstance;
            inverterInstances.push(microInverterInstance);
        });

        return inverterInstances;
    }

    async dcAnnealing(lastResult, annealingParams) {
        const {
            initialTemperature,
            coolingFactor,
            stoppingTemperature,
            maxIterations,
        } = annealingParams;

        const { state: lastState, objective: lastObjective } = lastResult;

        const initialState = [...lastState];
        const initialObjective = lastObjective;

        let currentState = [...lastState];
        let currentObjective = lastObjective;
        let currentTemperature = initialTemperature;

        let bestState = [...lastState];
        let bestObjective = lastObjective;
        let iterations = 0;
        const maxIterationsWithoutImprovement = 200;
        let iterationsWithoutImprovement = 0;
        let penaltyHistory = [];
        let fullPenaltyHistory = [];
        let deltaBestObjective = 0;

        while (
            currentTemperature > stoppingTemperature &&
            iterations < maxIterations
        ) {
            if (
                iterationsWithoutImprovement >
                    maxIterationsWithoutImprovement ||
                deltaBestObjective > 150
            ) {
                // reset to initial state
                // currentState = [...bestState];
                // currentObjective = bestObjective;
                currentState = [...initialState];
                currentObjective = initialObjective;
            }

            const neighbor = await this.generateNeighbor(
                currentState,
                bestState,
                currentObjective,
                bestObjective
            );

            if (!neighbor) {
                console.warn("neighbor is null");
                break;
            }

            const newObjective = this.computeTotalPenalty(neighbor);

            const deltaObjective = newObjective - currentObjective;

            const shouldAccept =
                deltaObjective < 0 ||
                Math.random() < Math.exp(-deltaObjective / currentTemperature);

            if (shouldAccept) {
                currentState = neighbor;
                currentObjective = newObjective;

                // this.colorGroups(currentState);
                // await new Promise(resolve => setTimeout(resolve, 0));

                if (currentObjective < bestObjective) {
                    bestState = neighbor;
                    bestObjective = currentObjective;
                    iterationsWithoutImprovement = 0;
                    this.colorGroups(currentState);
                    this.drawCables(currentState);
                    await new Promise((resolve) => setTimeout(resolve, 20));
                } else {
                    iterationsWithoutImprovement++;
                }
            } else {
                iterationsWithoutImprovement++;
            }

            deltaBestObjective = currentObjective - bestObjective;
            currentTemperature *= coolingFactor;
            iterations++;
            fullPenaltyHistory.push(currentObjective);
            penaltyHistory.push(bestObjective);
        }

        // console.log("iterations: ", iterations);
        // console.log('fullPenaltyHistory: ', fullPenaltyHistory);
        // console.log('penaltyHistory: ', penaltyHistory);
        // console.log("bestState: ", bestState);
        // console.log("bestObjective: ", bestObjective);
        return {
            state: bestState,
            objective: bestObjective,
        };
    }

    async generateNeighbor(currentState) {
        const minTiles = this.inverterParameters.minTiles;
        const maxTiles = this.inverterParameters.maxTiles;

        if (minTiles === maxTiles) {
            return this.generateRandomSwap(currentState);
        }
        return Math.random() < 0.5
            ? this.generateRandomSwap(currentState)
            : this.generateRandomMove(currentState, minTiles, maxTiles);
    }

    generateRandomSwap(currentState) {
        // sort the groups by their total objective
        const sortedGroups = [...currentState].sort(
            (a, b) => b.totalPenalty - a.totalPenalty
        );
        const group1Index = Math.floor(
            Math.random() * Math.floor(sortedGroups.length / 2)
        );
        const group1 = sortedGroups[group1Index];
        const remainingGroups = currentState.filter((g) => g !== group1);
        // sort the remaining groups by their distance to the group 1
        const sortedRemainingGroups = [...remainingGroups].sort(
            (a, b) =>
                -b.position.distanceTo(group1.position) +
                a.position.distanceTo(group1.position)
        );
        // generate an index in the sorted remaining groups such that it is more likely to pick the group with lower index
        const group2Index = Math.floor(
            Math.random() * Math.floor(sortedRemainingGroups.length / 2)
        );

        if (group2Index === -1) return currentState;

        const group2 = sortedRemainingGroups[group2Index];

        if (!group1 || !group2 || group1 === group2) {
            return currentState;
        }

        const maxSwap = Math.min(group1.tiles.length, group2.tiles.length);
        const numTilesSwap = Math.floor(Math.random() * maxSwap) + 1;

        const tiles1 = this.randomElements(group1.tiles, numTilesSwap);
        const tiles2 = this.randomElements(group2.tiles, numTilesSwap);

        return this.swapTiles(currentState, group1, group2, tiles1, tiles2);
    }

    randomElements(arr, n) {
        const uniqueArr = [...new Set(arr)];

        if (n > uniqueArr.length) {
            return uniqueArr;
        }

        const result = [];
        while (result.length < n) {
            const element =
                uniqueArr[Math.floor(Math.random() * uniqueArr.length)];
            if (!result.includes(element)) {
                result.push(element);
            }
        }
        return result;
    }

    swapTiles(currentState, group1, group2, tiles1, tiles2) {
        const newGroup1 = new PVTileGroup(
            this,
            group1.tiles.filter((t) => !tiles1.includes(t)).concat(tiles2),
            []
        );
        const newGroup2 = new PVTileGroup(
            this,
            group2.tiles.filter((t) => !tiles2.includes(t)).concat(tiles1),
            []
        );
        return currentState
            .filter((g) => g !== group1 && g !== group2)
            .concat([newGroup1, newGroup2]);
    }

    generateRandomMove(currentState, minTiles, maxTiles) {
        const group1Candidates = currentState.filter(
            (g) => g.tiles.length > minTiles
        );
        const totalObjectives = group1Candidates.map((g) => g.totalPenalty);
        const totalObjectivesSum = totalObjectives.reduce((a, b) => a + b, 0);
        const totalObjectivesProbabilities = totalObjectives.map(
            (v) => v / totalObjectivesSum
        );

        function weightedRandom(prob) {
            let sum = 0;
            const r = Math.random();
            for (const [i, p] of prob.entries()) {
                sum += p;
                if (r <= sum) return i;
            }
            return -1;
        }

        const group1Index = weightedRandom(totalObjectivesProbabilities);
        if (group1Index === -1) return currentState;
        const group1 = group1Candidates[group1Index];

        const remainingGroups = currentState.filter(
            (g) => g !== group1 && g.tiles.length < maxTiles
        );
        const group2Index = weightedRandom(
            remainingGroups.map(
                (g) => 1 / g.position.distanceTo(group1.position)
            )
        );
        if (group2Index === -1) return currentState;
        const group2 = remainingGroups[group2Index];

        return this.moveTile(currentState, group1, group2);
    }

    moveTile(currentState, group1, group2) {
        const tile = this.randomElements(group1.tiles, 1)[0];
        const newGroup1 = new PVTileGroup(
            this,
            group1.tiles.filter((t) => t !== tile),
            []
        );
        const newGroup2 = new PVTileGroup(
            this,
            group2.tiles.concat([tile]),
            []
        );
        return currentState
            .filter((g) => g !== group1 && g !== group2)
            .concat([newGroup1, newGroup2]);
    }
}

export class ACAnnealer {
    constructor({
        faceLocalVertices,
        pvTileGroups,
        balance = true,
        annealingParameters = defaultACAnnealingParameters,
        inverterParameters = defaultInverterParameters,
        grid,
    }) {
        this.faceLocalVertices = faceLocalVertices;
        this.pvTileGroups = pvTileGroups;
        this.balance = balance;
        this.annealingParameters = annealingParameters;
        this.inverterParameters = inverterParameters;
        this.grid = grid;
        this.bestSolution = null;
        this.bestScore = null;
        this.currentSolution = null;
        this.currentScore = null;
        this.currentTemperature = null;
        this.iterations = 0;
        this.strings = [];
        this.lookup = new Map();
        this.numberOfPVStrings = 0;
        this.initialize();
    }

    initialize() {
        // sort the dc state by the x position of the microinverters
        const sortedPvTileGroups = [...this.pvTileGroups].sort(
            (a, b) =>
                a.inverterInstance.position.x - b.inverterInstance.position.x
        );
        let lastString = new PVString();
        this.strings.push(lastString);
        this.maxMicroPerString = this.inverterParameters.maxInverterPerBranch;
        if (this.balance) {
            const totalMicros = this.pvTileGroups.length;
            // find the number of strings
            const totalStrings = Math.ceil(
                totalMicros / this.maxMicroPerString
            );
            // find the number of microinverters per string
            const microsPerString = Math.ceil(totalMicros / totalStrings);
            // create the strings
            sortedPvTileGroups.forEach((group) => {
                if (lastString.microInverters.length === microsPerString) {
                    lastString = new PVString();
                    this.strings.push(lastString);
                }
                lastString.microInverters.push(group.inverterInstance);
            });
        } else {
            sortedPvTileGroups.forEach((group) => {
                if (
                    lastString.microInverters.length ===
                    this.inverterParameters.maxInverterPerBranch
                ) {
                    lastString = new PVString();
                    this.strings.push(lastString);
                }
                lastString.microInverters.push(group.inverterInstance);
            });
        }

        this.penalty = this.calculateObjectiveAC(this.strings);
        // this.drawCables(this.strings);
        this.colorInverters(this.strings);

        this.bestSolution = this.strings;
        this.bestScore = this.penalty;
        this.currentSolution = this.strings;
        this.currentScore = this.penalty;
    }

    async run() {
        if (!this.currentSolution) {
            return false;
        }

        let newResult = {
            state: null,
            objective: Infinity,
        };
        let lastResult = {
            state: this.currentSolution,
            objective: this.currentScore,
        };

        let annealingParams = this.annealingParameters;

        let maxMetaIterations = 5;
        let metaIterations = 0;
        // Keep annealing until the objective stops improving
        do {
            newResult = await this.acAnnealing(lastResult, annealingParams);
            lastResult = newResult;
            if (newResult.objective < lastResult.objective) {
                maxMetaIterations = 0;
            }
            metaIterations++;
        } while (
            newResult.objective <= lastResult.objective &&
            metaIterations < maxMetaIterations
        );

        this.currentSolution = newResult.state;
        this.currentScore = newResult.objective;
        this.strings = newResult.state;
        this.colorInverters();
        this.drawCables();
        await new Promise((resolve) => setTimeout(resolve, 20));

        const pvStrings = this.strings;

        return pvStrings;
    }

    async acAnnealing(lastResult, annealingParams) {
        const {
            initialTemperature,
            coolingFactor,
            stoppingTemperature,
            maxIterations,
        } = annealingParams;

        const { state: lastState, objective: lastObjective } = lastResult;

        const initialState = [...lastState];
        const initialObjective = lastObjective;

        let currentState = [...lastState];
        let currentObjective = lastObjective;
        let currentTemperature = initialTemperature;

        let bestState = [...lastState];
        let bestObjective = lastObjective;
        let iterations = 0;
        const maxIterationsWithoutImprovement = 200;
        let iterationsWithoutImprovement = 0;
        let penaltyHistory = [];
        let fullPenaltyHistory = [];
        let deltaBestObjective = 0;

        while (
            currentTemperature > stoppingTemperature &&
            iterations < maxIterations
        ) {
            if (
                iterationsWithoutImprovement >
                    maxIterationsWithoutImprovement ||
                deltaBestObjective > 150
            ) {
                // reset to initial state
                // currentState = [...bestState];
                // currentObjective = bestObjective;
                currentState = [...initialState];
                currentObjective = initialObjective;
            }

            const neighbor = await this.generateNeighborAC(
                currentState,
                bestState,
                currentObjective,
                bestObjective
            );

            if (!neighbor) {
                console.warn("neighbor is null");
                break;
            }

            const newObjective = this.calculateObjectiveAC(neighbor);

            const deltaObjective = newObjective - currentObjective;

            const shouldAccept =
                deltaObjective < 0 ||
                Math.random() < Math.exp(-deltaObjective / currentTemperature);

            if (shouldAccept) {
                currentState = neighbor;
                currentObjective = newObjective;

                // this.colorInverters(currentState);
                // await new Promise(resolve => setTimeout(resolve, 0));

                if (currentObjective < bestObjective) {
                    bestState = neighbor;
                    bestObjective = currentObjective;
                    iterationsWithoutImprovement = 0;
                    this.colorInverters(currentState);
                    await new Promise((resolve) => setTimeout(resolve, 20));
                } else {
                    iterationsWithoutImprovement++;
                }
            } else {
                iterationsWithoutImprovement++;
            }

            deltaBestObjective = currentObjective - bestObjective;
            currentTemperature *= coolingFactor;
            iterations++;
            fullPenaltyHistory.push(currentObjective);
            penaltyHistory.push(bestObjective);
        }

        // console.log("iterations: ", iterations);
        // console.log('fullPenaltyHistory: ', fullPenaltyHistory);
        // console.log('penaltyHistory: ', penaltyHistory);
        // console.log("bestState: ", bestState);
        // console.log("bestObjective: ", bestObjective);
        return {
            state: bestState,
            objective: bestObjective,
        };
    }

    generateNeighborAC(currentState) {
        const maxCount = 100;
        let count = 0;

        if (currentState.length < 2) {
            return currentState;
        }

        while (count < maxCount) {
            count++;
            if (!this.balance && Math.random() < 0.5) {
                const string1 = this.getRandomElement(currentState);
                const string2 = this.getRandomElement(
                    currentState.filter((s) => s !== string1)
                );

                if (
                    !string1 ||
                    !string2 ||
                    string2.microInverters.length >= this.maxMicroPerString
                ) {
                    continue;
                }

                const micro = this.getRandomElement(string1.microInverters);
                const micros = string1.microInverters.filter(
                    (t) => t !== micro
                );
                const newStrings = [
                    new PVString(micros),
                    new PVString(string2.microInverters.concat([micro])),
                ];

                currentState = currentState
                    .filter((g) => g !== string1 && g !== string2)
                    .concat(newStrings);
            } else {
                const string1 = this.getRandomElement(currentState);
                const string2 = this.getRandomElement(
                    currentState.filter((s) => s !== string1)
                );

                if (!string1 || !string2) {
                    continue;
                }

                const newStrings = this.swapRandomMicros(string1, string2);
                currentState = currentState
                    .filter((g) => g !== string1 && g !== string2)
                    .concat(newStrings);
            }
        }
        return currentState;
    }

    getRandomElement(arr) {
        return arr[Math.floor(Math.random() * arr.length)];
    }

    swapRandomMicros(string1, string2) {
        const micros1 = [...string1.microInverters];
        const micros2 = [...string2.microInverters];

        // const swapCount = Math.min(1, micros1.length, micros2.length);
        const swapCount = 1;

        for (let i = 0; i < swapCount; i++) {
            const micro1 = this.getRandomElement(micros1);
            const micro2 = this.getRandomElement(micros2);

            micros1[micros1.indexOf(micro1)] = micro2;
            micros2[micros2.indexOf(micro2)] = micro1;
        }

        return [new PVString(micros1), new PVString(micros2)];
    }

    calculateObjectiveAC(state) {
        const dropLength = 2.3;
        // const dropLength = 2.0;

        let totalLength = 0;
        let totalDropPenalty = 0;

        state.forEach((string) => {
            totalLength += this.calculateShortestPath(string);
            totalDropPenalty += this.calculateDropPenalty(string, dropLength);
        });

        const lengthWeight = 0.1;
        const dropPenaltyWeight = dropLength;
        const weightedSum =
            lengthWeight * totalLength + dropPenaltyWeight * totalDropPenalty;
        return weightedSum;
    }

    calculateShortestPath(string) {
        if (string.cableLength) {
            return string.cableLength;
        }

        // Calculate the total length of the AC cabling
        let totalLength = 0;

        // Use the 2-opt heuristic to find an efficient route
        string.microInverters = this.twoOpt(string.microInverters);
        string.segments = [];

        for (let i = 0; i < string.microInverters.length - 1; i++) {
            const micro1 = string.microInverters[i];
            const micro2 = string.microInverters[i + 1];
            const { segments: manHattanSegments, length: segmentDistance } =
                this.manhattanSegmentsBetween(micro1, micro2);
            totalLength += segmentDistance;
            string.segments.push({
                segmentPath: manHattanSegments,
                distance: segmentDistance,
                micro1: micro1,
                micro2: micro2,
            });
        }

        string.cableLength = totalLength;
        return totalLength;
    }

    twoOpt(route) {
        let bestRoute = route;
        let bestDistance = this.totalDistance(route);

        while (true) {
            for (let i = 0; i < route.length - 1; i++) {
                for (let k = i + 1; k < route.length; k++) {
                    let newRoute = this.twoOptSwap(route, i, k);
                    let newDistance = this.totalDistance(newRoute);
                    if (newDistance < bestDistance) {
                        bestRoute = newRoute;
                        bestDistance = newDistance;
                        break;
                    }
                }
            }
            if (bestDistance === this.totalDistance(route)) {
                break;
            } else {
                route = bestRoute;
            }
        }
        return bestRoute;
    }

    totalDistance(route) {
        let total = 0;
        for (let i = 0; i < route.length - 1; i++) {
            total += this.manHattanDistance(route[i], route[i + 1]);
        }
        return total;
    }

    manHattanDistance(micro1, micro2) {
        return (
            Math.abs(micro1.position.x - micro2.position.x) +
            Math.abs(micro1.position.y - micro2.position.y)
        );
    }

    manhattanSegmentsBetween(micro1, micro2, draw = false) {
        const segments = [];
        let totalLength = 0;

        // go horizontally first then vertically
        const horizontalSegmentStart = new THREE.Vector3(
            micro1.position.x,
            micro1.position.y,
            0
        );
        segments.push(horizontalSegmentStart);
        let horizontalSegmentEnd = null;
        if (micro1.position.x !== micro2.position.x) {
            horizontalSegmentEnd = new THREE.Vector3(
                micro2.position.x,
                micro1.position.y,
                0
            );
            segments.push(horizontalSegmentEnd);
            totalLength += horizontalSegmentEnd.distanceTo(
                horizontalSegmentStart
            );
        }

        // only add vertical segment if necessary
        if (micro1.position.y !== micro2.position.y) {
            const verticalSegmentEnd = new THREE.Vector3(
                micro2.position.x,
                micro2.position.y,
                0
            );
            segments.push(verticalSegmentEnd);
            totalLength += verticalSegmentEnd.distanceTo(
                horizontalSegmentEnd || horizontalSegmentStart
            );
        }

        return {
            segments: segments,
            length: totalLength,
        };
    }

    twoOptSwap(route, i, k) {
        let newRoute = route.slice(0, i);
        let reverseSegment = route.slice(i, k + 1).reverse();
        newRoute = newRoute.concat(reverseSegment, route.slice(k + 1));
        return newRoute;
    }

    euclideanDistance(micro1, micro2) {
        if (micro1.face === micro2.face) {
            return Math.sqrt(
                Math.pow(micro1.position.x - micro2.position.x, 2) +
                    Math.pow(micro1.position.y - micro2.position.y, 2)
            );
        } else {
            const commonEdge = micro1.face.adjacentFaces.find(
                (f) => f.adjacentFace == micro2.face
            );
            if (commonEdge) {
                const start = commonEdge.edge.point1;
                const end = commonEdge.edge.point2;
                const edge2D = new THREE.Line3(start, end);
                // find the closest point on edge to micro1
                const closestPoint1 = new THREE.Vector3();
                edge2D.closestPointToPoint(
                    micro1.position,
                    false,
                    closestPoint1
                );
                // find the closest point on edge to micro2
                const closestPoint2 = new THREE.Vector3();
                edge2D.closestPointToPoint(
                    micro2.position,
                    false,
                    closestPoint2
                );
                //  get the distance from micro1 to closestPoint1 and micro2 to closestPoint2 and closestPoint1 to closestPoint2
                const d1 = start.distanceTo(closestPoint1);
                const d2 = end.distanceTo(closestPoint1);
                const d3 = start.distanceTo(closestPoint2);
                return d1 + d2 + d3;
            } else {
                return 100000;
            }
        }
    }

    calculateDropPenalty(string, dropLength) {
        if (string.dropPenalty) {
            return string.dropPenalty;
        }
        let totalPenalty = 0;
        // for each segment in the string check if segment length is greater than dropLength
        // if it is then add a penalty based on how many extra dropLengths are present
        string.segments.forEach((segment) => {
            if (segment.distance > dropLength) {
                const dropPenalty = Math.floor(segment.distance / dropLength);
                totalPenalty += dropPenalty;
                segment.dropPenalty = dropPenalty;
            } else {
                segment.dropPenalty = 0;
            }
        });

        string.dropPenalty = totalPenalty;
        return totalPenalty;
    }

    drawCables(state = this.strings) {
        this.grid.drawACCables(state);
    }

    colorInverters(state = this.strings) {
        this.grid.colorInverters(state);
    }
}

export function mergedAC(mergedRoof, maxInverterPerBranch = 13) {
    const strings = mergedRoof.acStrings;
    const nonMaxStrings = [];
    const maxStrings = [];
    strings.forEach((string) => {
        if (string.microInverters.length < maxInverterPerBranch) {
            nonMaxStrings.push(string);
        } else {
            maxStrings.push(string);
        }
    });
    // connect the non-max strings to reach the max string length
    const mergedStrings = annealMergedAC(nonMaxStrings, mergedRoof, maxInverterPerBranch);

    const finalStrings = [...mergedStrings, ...maxStrings];
    // const finalStrings = [...mergedStrings];

    return finalStrings;
}

export function updateEndNodes(strings) {
    strings.forEach((string) => {
        string.additionalPaths = [];
        string.additionalStrings = [];
        const startNode = string.microInverters[0];
        const endNode = string.microInverters[string.microInverters.length - 1];
        const startPathNode = new PathNode(
            startNode.position.clone().applyMatrix4(string.grid.matrix),
            "startNode",
            string.grid.smartroofFace,
            [string]
        );
        const endPathNode = new PathNode(
            endNode.position.clone().applyMatrix4(string.grid.matrix),
            "endNode",
            string.grid.smartroofFace,
            [string]
        );
        string.startPathNode = startPathNode;
        string.endPathNode = endPathNode;
        const faceNodes = string.grid.smartroofFace.nodes;
        // connect start & end nodes to all the face nodes
        faceNodes.forEach((node) => {
            connectPathNodes(startPathNode, node);
            connectPathNodes(endPathNode, node);
        });
        startPathNode.updateIntersectionCosts();
        endPathNode.updateIntersectionCosts();
    });
}

export function annealMergedAC(strings, mergedRoof, maxInverterPerBranch) {
    // group strings based on the shortest path possible between them considering all the combinations of start and end nodes
    let currentString = strings[0];
    const pendingStrings = [...strings.slice(1)];
    pendingStrings.sort(
        (a, b) => a.microInverters.length - b.microInverters.length
    );
    const mergedStrings = [];
    while (pendingStrings.length > 0) {
        const shortestPath = findShortestPath(
            currentString,
            pendingStrings,
            mergedRoof,
            maxInverterPerBranch
        );
        if (shortestPath) {
            mergedStrings.push(shortestPath);
        } else {
            currentString = pendingStrings[0];
            pendingStrings.splice(pendingStrings.indexOf(currentString), 1);
        }
    }
    const finalStrings = strings.filter((string) => !string.consumed);
    return finalStrings;
}

function findShortestPath(currentString, pendingStrings, mergedRoof, maxInverterPerBranch) {
    const maxLength = maxInverterPerBranch;
    let shortestPath = null;
    let shortestDistance = Infinity;
    let additionalString = null;
    let nodeConsumed = null;
    let additionalConsumed = null;
    const delta = maxLength - currentString.microInverters.length;
    pendingStrings.forEach((string) => {
        if (string.microInverters.length > delta) {
            return;
        }
        let subDistance = Infinity;
        let subPath = null;
        let subString = null;
        let subNodeConsumed = null;
        let subAdditionalConsumed = null;
        const startNode = string.startPathNode;
        const endNode = string.endPathNode;
        const currentStartNode = currentString.startPathNode;
        const currentEndNode = currentString.endPathNode;
        // find which combination of start and end nodes has the shortest path
        // find path returns {path,cost}
        const distances = [];
        let d1 = { cost: Infinity };
        if (!startNode.consumed && !currentStartNode.consumed) {
            d1 = mergedRoof.findPath(startNode, currentStartNode);
            distances.push(d1);
        }
        let d2 = { cost: Infinity };
        if (!startNode.consumed && !currentEndNode.consumed) {
            d2 = mergedRoof.findPath(startNode, currentEndNode);
            distances.push(d2);
        }
        let d3 = { cost: Infinity };
        if (!endNode.consumed && !currentStartNode.consumed) {
            d3 = mergedRoof.findPath(endNode, currentStartNode);
            distances.push(d3);
        }
        let d4 = { cost: Infinity };
        if (!endNode.consumed && !currentEndNode.consumed) {
            d4 = mergedRoof.findPath(endNode, currentEndNode);
            distances.push(d4);
        }

        const minDistance = Math.min(...distances.map((d) => d.cost));
        if (minDistance === Infinity) {
            return;
        }
        switch (minDistance) {
            case d1.cost:
                subPath = d1.path;
                subDistance = d1.cost;
                subString = string;
                subAdditionalConsumed = subString.startPathNode;
                subNodeConsumed = currentStartNode;
                break;
            case d2.cost:
                subPath = d2.path;
                subDistance = d2.cost;
                subString = string;
                subAdditionalConsumed = subString.startPathNode;
                subNodeConsumed = currentEndNode;
                break;
            case d3.cost:
                subPath = d3.path;
                subDistance = d3.cost;
                subString = string;
                subAdditionalConsumed = subString.endPathNode;
                subNodeConsumed = currentStartNode;
                break;
            case d4.cost:
                subPath = d4.path;
                subDistance = d4.cost;
                subString = string;
                subAdditionalConsumed = subString.endPathNode;
                subNodeConsumed = currentEndNode;
                break;
            default:
                break;
        }

        if (subDistance < shortestDistance) {
            shortestDistance = subDistance;
            shortestPath = subPath;
            additionalString = subString;
            nodeConsumed = subNodeConsumed;
            additionalConsumed = subAdditionalConsumed;
        }
    });

    // update the current string with the shortest path and add the inverters
    if (shortestPath) {
        currentString.microInverters = currentString.microInverters.concat(
            additionalString.microInverters
        );
        currentString.cabling = currentString.cabling.concat(
            additionalString.cabling
        );
        additionalString.consumed = true;
        currentString.additionalPaths.push(shortestPath);
        currentString.additionalStrings.push(additionalString);
        // redefine the start and end nodes using the consumed node and null check
        if (currentString.startPathNode === nodeConsumed) {
            if (additionalString.startPathNode !== additionalConsumed) {
                currentString.startPathNode = additionalString.startPathNode;
            }
            if (additionalString.endPathNode !== additionalConsumed) {
                currentString.startPathNode = additionalString.endPathNode;
            }
            currentString.endPathNode.consumed = true;
        } else if (currentString.endPathNode === nodeConsumed) {
            if (additionalString.startPathNode !== additionalConsumed) {
                currentString.endPathNode = additionalString.startPathNode;
            }
            if (additionalString.endPathNode !== additionalConsumed) {
                currentString.endPathNode = additionalString.endPathNode;
            }
            currentString.startPathNode.consumed = true;
        }
        pendingStrings.splice(pendingStrings.indexOf(additionalString), 1);
    }

    return shortestPath;
}

export function mergedHomeRun(strings, junctionNode, mergedRoof) {
    // for each string find the shortest path to the junction node from either end
    strings.forEach((string) => {
        const start = string.startPathNode;
        const end = string.endPathNode;
        const startPath = mergedRoof.findPath(start, junctionNode);
        const endPath = mergedRoof.findPath(end, junctionNode);
        if (!string.additionalPaths) {
            string.additionalPaths = [];
        }
        if (startPath.cost < endPath.cost) {
            string.additionalPaths.push(startPath.path);
        } else {
            string.additionalPaths.push(endPath.path);
        }
    });

    return strings;
}
