
<!-- <div id="homeMap">
        <div id="mapWrapper">
            <GmapMap
                ref="gmap"
                :center="center"
                :zoom="zoom"
                :min-zoom="minZoom"
                :disable-default-ui="true"
                style="width: 100%;height:100%;"
                @click="infoWindowCloser">
                <gmap-info-window
                    :options="infoOptions"
                    :position="infoWindowPos"
                    :opened="infoWinOpen"
                    @closeclick="infoWinOpen=false">
                    <div class="infoWindowWrapper">

                        <p style="font-weight: 400;margin-bottom: 0px;color: grey;"> Project Name </p>
                        <p style="font-size: 16px;font-weight: 400;margin-top: 5px;"> {{ infoContent.projectName }} </p>
                        <div class="allPagesIcons">
                            <button
                                class="el-icon-edit"
                                style="right: -13px"
                                @click="leadToProject"/>
                            <button
                               @click="deleteProject"
                               class="el-icon-delete"/>
                            <button
                               @click="shareProject" 
                               class="el-icon-share"/>
                        </div>

                    </div>

                </gmap-info-window>

                <GmapMarker
                    v-for="(m,i) in allProjects"
                    :key="i"
                    :position="m.position"
                    :clickable="true"
                    @click="toggleInfoWindow(m,i)"/>
            </GmapMap>

        </div>
    </div> -->
<div id="homeMap">
  <GoogleMaps
  :key="reloadMapKey"
    :center="center"
    :zoom="zoom"
    :mapOptions="mapOptions"
    :allProjects="allProjects"
    :infoWindowContent="this.$refs['info-window-content']"
    v-if="isMounted"
    @changeActiveProject="changeActiveProject"
  />
  <div style="display: none">
    <div ref="info-window-content" class="infoWindowWrapper">
      <p style="font-weight: 400; margin-bottom: 0px; color: grey">
        {{ pageFlag === "dashboard" ? "Lead Name" : "Project Name" }}
      </p>
      <p style="font-size: 16px; font-weight: 400; margin-top: 5px">
        {{ activeProject?.info?.projectName }}
      </p>
      <div class="allPagesIcons">
        <button
          class="el-icon-edit"
          style="right: -13px"
          @click="leadToProject"
        />
        <button
          v-if="pageFlag !== 'dashboard'"
          @click="deleteProject"
          class="el-icon-delete"
        />
        <button
          v-if="pageFlag !== 'dashboard'"
          @click="shareProject"
          class="el-icon-share"
        />
      </div>
    </div>
  </div>
</div>
