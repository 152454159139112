<template>
    <div id="parent-container">
			<div id="pdfContainer2">
			</div>
			<PaymentSchedulePage
				v-if="isPaymentSchedulePageVisible  && isInPreviewMode && isContractInDesignLevel && includePaymentSchedule && paymentMethodType!='loan'"
				:isInPreviewMode="isInPreviewMode"
			/>
  </div>
</template>


<script>
import { uploadFileToBlob, fetchSasToken } from "@/utils.js";
import { containerNameForContracts } from "@/constants.js";
import { v4 } from "uuid";
import { PDFDocument } from 'pdf-lib'
import * as pdfjs from 'pdfjs-dist/build/pdf.min.js'
import 'pdfjs-dist/build/pdf.worker.min.js'
import axios from "axios";
import { saveAs } from 'file-saver';
import { mapState, mapActions } from 'pinia';
import { useContractManagementStore } from '../../../../stores/contractManagement';
import API from "@/services/api/";
import { useUiStore } from '../../../../stores/ui';
import PaymentSchedulePage from "./paymentSchedulePage.vue";

export default {
		components: {
			PaymentSchedulePage
		},
    data(){
        return{
            pdf: null,
            pdfSrc: null,
            pdfContainer: null,
            allPDFCanvas:[],
            currentCanvasIndex:0,
            pos1:0,
            pos2:0,
            pos3:0,
            pos4:0,
            elmnt:'',
            allAddedSpansArray:[],
            activeSpanClassName:null,
            activeSpanId:null,
			token: this.$route.params.token,
			contractId:'',
			keyValuePlaceholders:{},
			allAddedPlaceholders:[],
			keyValuePairForAddedPlaceholders:{},
			isPaymentSchedulePageVisible:false,
			allAddedCustomPlaceholders:[],
			allAddedNotCustomPlaceholders:[],
			paymentMethodType: ""
        }
    },
		props:{
			isInPreviewMode:{
				type:Boolean,
				default:false
			},
			isContractInDesignLevel:{
				type:Boolean,
				default:true
			},
			includePaymentSchedule:{
				type:Boolean,
				default:false
			},
			isInContractListPage:{
				type:Boolean,
				default:false
			}
		},
		async created(){
			if(!this.isInPreviewMode){
				API.SET_TOKEN_HEADER(this.token);
			}
		},
    computed:{
			...mapState(useContractManagementStore, {
				fileLink: "GET_FILE_LINK",
				CMStore: (state) => state
    	}),
    },
    async mounted(){
			if(!this.isInPreviewMode){
				let promise1 =  this.fetchAllPlaceholdersOptions() // no need to use await for this
				let promise2 = await this.fetchContractTemplate();
				let promise3 = this.fetchDocumentFromBlobStorage(); // promise2 depends on the response of fetchContractTemplate so need to use await
				await Promise.all([promise1,promise2,promise3])
			}
			if(this.isInPreviewMode && this.isContractInDesignLevel){ // to check if payment type is Loan or not...accordingly render payment schedule
				this.fetchPaymentMethodDetails();
			}
			if(this.isContractInDesignLevel){
				this.CMStore.placeholdersArray.forEach((eachPlaceholder)=>{
					this.CMStore.addedPlaceholdersKeyValuePair[eachPlaceholder.placeholder_key].page_no = eachPlaceholder?.page_no
					this.CMStore.addedPlaceholdersKeyValuePair[eachPlaceholder.placeholder_key].x_position = eachPlaceholder.x_position
					this.CMStore.addedPlaceholdersKeyValuePair[eachPlaceholder.placeholder_key].y_position = eachPlaceholder.y_position
				})
			}
			let pdfUrl = this.fileLink
			pdfjs.getDocument(pdfUrl).promise.then((pdf) => {
					this.pdf = pdf;
					this.renderPages();
			});
			// this.mergeWithFinancialPDF();
    },
    methods:{
			...mapActions(useContractManagementStore,[
				"SET_KEY_VALUE_PLACEHOLDER",
				"SET_FILE_LINK",
				"UPDATE_PLACEHOLDERS_ARRAY",
				"SET_KEY_VALUE_FOR_ADDED_PLACEHOLDER"
			]),
			...mapActions(useUiStore, {
				hideChatIcon:"hideChatIcon"
			}),
			fetchSasToken,
			async fetchPaymentMethodDetails(){
				try{
					const response = await API.DESIGNS.FETCH_PRICING_ONLY(this.CMStore.selectedDesignIdForPreview);
					this.paymentMethodType = response.data.payment_method_type;
				}
				catch(e){
					console.error(e);
					this.$message({
						showClose: true,
						message: "There is some issue in fetching the payment method details",
						type: "error",
						center: true,
					});
				}
			},
			renderPDF(file) {
					const fileReader = new FileReader();
					fileReader.onload = (event) => {
							const typedArray = new Uint8Array(event.target.result);
							pdfjs.getDocument(typedArray).promise.then((pdf) => {
							this.pdf = pdf;
							this.renderPages();
							});
					};
					fileReader.readAsArrayBuffer(file);
			},
			async  renderPages() {
					this.pdfContainer = document.getElementById('pdfContainer2');
					for (let i = 1; i <= this.pdf.numPages; i++) {
							let canvasParent = document.createElement('div');
							canvasParent.classList.add('canvasParent');
							canvasParent.style.position = "relative"
							canvasParent.style.border = "1px solid #ccc"
							const page = await this.pdf.getPage(i);
							const viewport = page.getViewport({ scale: 1 });
							const canvas = document.createElement('canvas');
							const context = canvas.getContext('2d');
							canvas.height = viewport.height;
							canvas.width = viewport.width;
							const renderContext = {
									canvasContext: context,
									viewport: viewport
							};
							await page.render(renderContext).promise;
							this.allPDFCanvas.push(canvas)
							canvasParent.appendChild(canvas)
							this.appendPlaceholdersInEachPage(canvasParent,i)
							this.pdfContainer.appendChild(canvasParent);
					}
					this.isPaymentSchedulePageVisible = true;
					this.hideChatIcon()
					if(this.isInPreviewMode || this.isInContractListPage){
						this.addMarginWhilePreviewing()
					}
					this.pdfContainer.classList.add('reportLoaded');
			},
			addMarginWhilePreviewing(){
				let pageDivs = document.querySelectorAll('div.canvasParent');
				pageDivs.forEach(function(div) {
					div.classList.add('addMarginBetweenPages');
				});
			},
			appendPlaceholdersInEachPage(canvasParent,pageNo){
					let spansOfCurrentPage= this.CMStore.placeholdersArray.filter(placeholder=>{
						return placeholder.page_no == pageNo
					})
					spansOfCurrentPage.forEach((placeholder)=>{
						let span = document.createElement('span')
						canvasParent.appendChild(span)
						let placeholderKey = placeholder.id.split('-')[0]; // having placeholder.id like - "placeholderKey-uuid"
						if(!this.isContractInDesignLevel){
							span.innerText =   this.CMStore.keyValuePlaceholders[placeholderKey]?.default_value +
								" " + (this.CMStore.keyValuePlaceholders[placeholderKey]?.unit || "")
						}
						else{
							if(this.CMStore.addedPlaceholdersKeyValuePair[placeholderKey].category=='docusign'){
								if(this.isInPreviewMode){
									span.innerText =   this.CMStore.addedPlaceholdersKeyValuePair[placeholderKey].name
								}
								else{
									// span.innerText =   this.CMStore.addedPlaceholdersKeyValuePair[placeholderKey].default_value
									span.innerText =   ""
									span.style.color = "white" // so that docusign can override the signature options over these places and it should look clean
								}
							}
							else{
								span.innerText =   this.CMStore.addedPlaceholdersKeyValuePair[placeholderKey]?.value +
									" " + (this.CMStore.addedPlaceholdersKeyValuePair[placeholderKey]?.unit || "")
							}
						}
						span.style.position = "absolute";
						span.style.fontWeight = '600'
						span.style.left = placeholder.x_position;
						span.style.top =  placeholder.y_position;
						span.id = placeholder.id
						span.style.fontSize = placeholder.font_size
					})
			},
			async fetchAllPlaceholdersOptions(){
				let response;
				try{
					response =  await API.CONTRACT_TEMPLATES.FETCH_ALL_PLACEHOLDERS_OPTIONS()
					this.prepareKeyValuePairPlaceholders(response.data)
				}
				catch(e){

				}
			},
			prepareKeyValuePairPlaceholders(allPlaceholdersTypes){
				this.keyValuePlaceholders = JSON.parse(JSON.stringify(this.CMStore.keyValuePlaceholders))
				allPlaceholdersTypes.forEach((eachPlaceholderType)=>{
					let placeholdersArray = eachPlaceholderType.placeholders
					placeholdersArray.forEach((eachPlaceholder)=>{
						this.keyValuePlaceholders[eachPlaceholder.identifier] = eachPlaceholder
					})
				})
				this.SET_KEY_VALUE_PLACEHOLDER(this.keyValuePlaceholders)
			},
			segregateCustomAndOtherPlaceholder(allAddedPlaceholders){
				this.allAddedCustomPlaceholders = allAddedPlaceholders.filter((placeholder)=>{
					return placeholder.placeholder_type =="custom_placeholders"
				})
				this.allAddedNotCustomPlaceholders = allAddedPlaceholders.filter((placeholder)=>{
					return placeholder.placeholder_type !="custom_placeholders"
				})
			},
			async fetchContractTemplate(){
				let response;
				try{
					this.contractId = this.$route.params.contractId || this.CMStore.selectedContractId
					response =  await API.CONTRACT_TEMPLATES.FETCH_CONTRACT_TEMPLATE(this.contractId)
					this.allAddedPlaceholders = response.data.additional_details?.placeholders || []
					this.segregateCustomAndOtherPlaceholder(this.allAddedPlaceholders)
					this.UPDATE_PLACEHOLDERS_ARRAY(response.data.additional_details?.placeholders || [])
					this.editorType = response.data.template_type
					this.fileName =  response.data.file_name
					if(this.isContractInDesignLevel){
						await this.fetchAddedPlaceholdersWithActualValues(this.allAddedPlaceholders)
					}
				}
				catch(e){
					this.$message({
						showClose: true,
						message: "There is some issue in fetching the template",
						type: "error",
						center: true,
					});
					console.error(e)
					this.$router.push({ name: "documentManagement" });
				}
			},

			async fetchAddedPlaceholdersWithActualValues(){
				let addedNotCustomPlaceholdersIdentifier = [];
				let addedCustomPlaceholdersIdentifier = [];
				this.allAddedNotCustomPlaceholders.forEach((addedPlaceholder)=>{
						addedNotCustomPlaceholdersIdentifier.push(addedPlaceholder.placeholder_key)
				})
				this.allAddedCustomPlaceholders.forEach((addedPlaceholder)=>{
					addedCustomPlaceholdersIdentifier.push(addedPlaceholder.placeholder_key)
				})
				let payload = {
					"placeholders":[...addedNotCustomPlaceholdersIdentifier],
					"design_id": this.CMStore.selectedDesignIdForPreview || this.$route.params.designId,
					"contract_id":this.contractId,
					"custom_placeholders":[...addedCustomPlaceholdersIdentifier]
				}
				const response = await API.CONTRACT_TEMPLATES.FETCH_ADDED_PLACEHOLDERS_WITH_ACTUAL_VALUES(payload);
				this.prepareKeyValuePairForAddedPlaceholders(response.data)
			},
			prepareKeyValuePairForAddedPlaceholders(allPlaceholdersTypes){
				allPlaceholdersTypes.forEach((eachPlaceholderType)=>{
					let placeholdersArray = eachPlaceholderType.placeholders
					let placeholderCategory = eachPlaceholderType.identifier
					placeholdersArray.forEach((eachPlaceholder)=>{
						eachPlaceholder.category = placeholderCategory
						this.keyValuePairForAddedPlaceholders[eachPlaceholder?.identifier] = eachPlaceholder
					})
				})
				this.SET_KEY_VALUE_FOR_ADDED_PLACEHOLDER(this.keyValuePairForAddedPlaceholders)
			},
			async fetchDocumentFromBlobStorage(){
				let sasToken =  await fetchSasToken(containerNameForContracts)
				let fileLink = `https://downloadstsl.blob.core.windows.net/${containerNameForContracts}/${this.fileName}?${sasToken}`
				this.SET_FILE_LINK(fileLink)
			},
    }
}
</script>


<style scoped>
	#parent-container{
		display: flex; 
		justify-content: center;
		align-items: center;
		flex-direction: column;
		gap: 20px;
		padding-bottom: 20px;
	}
	.draggable-span {
		position: absolute;
		border: 1px solid black;
		background-color: lightgrey;
		cursor: pointer;
		user-select: none;
		padding: 5px;	
	}
	#parent-container ::v-deep .addMarginBetweenPages{
		margin-bottom: 20px;
	}
	#parent-container ::v-deep #pdfContainer2 {
		position: relative;
		background-color: #fff;
	}
	#parent-container ::v-deep #pdfCanvas {
		border: 1px solid black;
	}
</style>
