<template>
  <div :class="[zeroPadding?'noPadding dialog-global' :'dialog-global']">
    <CustomOverLay v-if="!isModal||!isModalAppend" :showOverlay="dialogVisible" />
    <el-dialog 
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      :width="width"
      :before-close="handleClose"
      :lock-scroll="true"
      :close-on-click-modal="onClickClose"
      :class="isDrilldown || !dialogTitle ? 'flag-dialog' : ''"
      :modal-append-to-body="isModalAppend"
      :modal="isModal"
    >
    <div :style="dialogBodyStyle"  v-loading="loading">
      <slot name="body" >
        <span>This is a message</span>
      </slot>
    </div>
    <template v-slot:footer>
      <slot name="footer">
        <span class="dialog-footer">
          <el-button @click="handleClose" v-if="!disableCancel"
            >Cancel</el-button
          >
          <el-button
            :type="footerButtonType"
            @click="handleClick"
            :disabled="disableButton"
            >{{ footerButtonText }}</el-button
          >
        </span>
      </slot>
    </template>
    </el-dialog>
  </div>
</template>

<script>
import CustomOverLay from './customOverLay.vue';

export default {
  emits: ["handleClose", "handleClick"],
  name: "GlobalDialog",
  components:{CustomOverLay},
  props: {
    dialogTitle: {
      type: String,
      default: "",
    },
    height:{
      type:String,
      default:'auto'

    },
    onClickClose: {
      type: Boolean,
      default: true,
    },
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    footerButtonText: {
      type: String,
      default: "Confirm",
      required: false,
    },
    width: {
      type: String,
      default: "30%",
      required: false,
    },
    footerButtonType: {
      type: String,
      default: "primary", // Success Info Warning Danger
      required: false,
    },
    disableCancel: {
      type: Boolean,
      default: false,
      required: false,
    },
    disableButton: {
      type: Boolean,
      default: false,
      required: false,
    },
    isDrilldown: {
      type: Boolean,
      default: false,
    },
    loading:{
      type:Boolean,
      default:false
    },
    isModal:{
      type:Boolean,
      default:true
    },
    zeroPadding:{
      type:Boolean,
      default:false
    },
    isModalAppend:{
      type:Boolean,
      default:true
    }
  },
  methods: {
    handleClose() {
      this.$emit("handleClose");
    },
    handleClick() {
      this.$emit("handleClick");
      this.$emit("handleClose",'click');
    },
  },
  computed: {
    dialogBodyStyle() {
      if (this.height) {
        return {
          height: this.height,
          margin: 'auto',
          // overflowY: 'scroll',
          // overflowX: 'hidden'
        };
      }
      return {}
    },
  },
};
</script>
<style scoped>
.dialog-global ::v-deep .el-dialog__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.dialog-global ::v-deep .el-dialog {
  border-radius: 8px;
}
.dialog-global ::v-deep .el-dialog__header {
  background-color: #e8edf2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px 8px 0 0;
  padding:10px !important
}
.dialog-global ::v-deep .el-dialog__body {
    /* padding: 30px 20px; */
    color: #222;
    /* font-size: 14px;
    word-break: break-all; */
}
.flag-dialog ::v-deep .el-dialog__header {
  display: none;
}
.dialog-global ::v-deep .el-dialog__title {
  color: #222222 !important;
  font-size: 1rem;
  font-weight: 600;
}
.dialog-global ::v-deep .el-dialog__headerbtn .el-dialog__close{
font-size: 1.5rem;
font-weight: 600;
color:black
}
.dialog-global ::v-deep .el-dialog__headerbtn .el-dialog__close:hover {
  color: #000 !important; /* Change the color on hover */
}
.noPadding>>>.el-dialog__body{
padding:0 !important;
}
</style>
