
<div class="btnsContainerFlex">
  <div class="flexOne">
    <ReusableButton
      v-if="!isGenerationAlreadyCalculated && !essOnlyDesign"
      :loading="isLoading"
      :button="generationButtonData()"
      @click="calculateGeneration()"
    />

    <ReusableButton
      v-if="isGenerationAlreadyCalculated && !essOnlyDesign"
      :button="webProposalButtonData()"
      @click="navigateToWebProposal()"
    />

    <ReusableButton
      v-if="isGenerationAlreadyCalculated"
      :button="documentProposalButtonData()"
      @click="onClickDocumentProposal"
    />

    <ReusableButton
      v-if="essOnlyDesign"
      :button="essOnlyProposalButtonData()"
      @click="navigateToDocumentProposal()"
    />

    <ReusableButton
      v-if="isGenerationAlreadyCalculated && !essOnlyDesign"
      :button="designOverviewButtonData()"
      @click="navigateToDesignOverview()"
    />
  </div>
  <div v-if="!essOnlyDesign" class="flexTwo">
    <ReusableButton
      v-if="isSalesModeAvailable && !isMobileView"
      :loading="isStudioLoading"
      :button="generationSaleStudioButtonData()"
      @click="saleStudioButton()"
    />
    <ReusableButton
      v-if="isSalesModeAvailable"
      :loading="isReportLoading"
      :button="generationSaleReportButtonData()"
      @click="salesModeReportBtn()"
    />
  </div>

  <DialogPopup
    v-if="isHybridInverterPopupOpen" 
    :isOpen="isHybridInverterPopupOpen"
    :message="'The design currently includes a hybrid inverter, but no batteries have been added. Would you like to view the proposal without including a battery?'"
    @onClickYes="navigateToDocumentProposal"
    @onClickNo="isHybridInverterPopupOpen = false"
  />
  
</div>
