<template>
  <div>
    <div class="grouped-container">
      <el-row>
        <p>Buckets</p>
      </el-row>
      <div
        v-for="(groupedRows, inx) in groupedTableData"
        :key="inx"
        class="select-body"
      >
        <div v-for="(group, index) in groupedRows" :key="index">
          <div class="spacing-container">
            <div class="body-container">
              <InputView
                v-if="group.type === 'input'"
                :itemValue="group"
                :selectedValue="group?.selectedValue || ''"
                :indexOfOptions="inx"
                @item-selected="handleInput"
              />
              <NumberInput
                v-if="group.type === 'number'"
                :itemValue="group"
                :selectedValue="group?.selectedValue || ''"
                :indexOfOptions="inx"
                @item-selected="handleInput"
              />
            </div>
          </div>
        </div>
        <div :span="1" @click="removeColumn(inx)">
          <p class="cross" v-if="groupedTableData.length > 1">
            <b-icon :icon="'x'"></b-icon>
          </p>
        </div>
      </div>
    </div>

    <div @click="handleaddGroupedColumns">
      <p
        style="
          color: #1c3366;
          font-weight: 600;
          font-size: 1rem;
          padding-top: 1rem;
          display: flex;
        "
      >
        <span
          ><b-icon
            :style="{
              color: '#1c3366',
              marginRight: '0.5rem',
            }"
            class="custom-icon-size"
            :icon="'plus-circle-fill'"
          ></b-icon></span
        >{{ `Add New Bucket` }}
      </p>
    </div>
  </div>
</template>

<script>
import InputView from "./inputView.vue";
import NumberInput from "./numberInput.vue";

export default {
  emits: ["bucket_details"],
  name: "groupedTableInputs",
  components: {InputView, NumberInput },
  data() {
    return {
      groupedData: [],
      selectedItem: "",
      groupedRows: [
        {
          title: "Bucket Name",
          prop: "label",
          selectedValue: "",
          type: "input",
          placeholder: "Enter Name",
          isStar: true,
        },
        {
          title: "Min. Value",
          type: "number",
          prop: "min",
          selectedValue: "",
          placeholder: "Enter Number",
          isStar: true,
        },
        {
          title: "Max. Value",
          type: "number",
          prop: "max",
          selectedValue: "",
          placeholder: "Enter Number",
          isStar: true,
        },
      ],
      measure_details: [{}],
      groupedRowOptions: [],
    };
  },

  props: {
    groupedTableOptions: {
      type: Array,
      default() {
        return []
      }
    },
    groupedTableSelectedData: {
      type: Array,
      default() {
        return []
      }
    },
    bucketsSelecteddata: {
      type: Array,
      default() {
        return []
      }
    },
  },

  async mounted() {
    this.groupedRowOptions = await this.$props.groupedTableOptions;
    if (this.$props.bucketsSelecteddata.length > 0) {
      // this.handleaddSelectedOptions();
    } else {
      this.handleaddGroupedColumns();
    }
  },
  computed: {
    groupedTableData() {
      return this.groupedData;
    },
  },
  methods: {
    handleInput(
      typeData,
      dataValue,
      value,
      itemData,
      index,
      isGrouped,
      selectedColumns
    ) {
      this.groupedData[index].map((d) => {
        if (typeData === d.prop) {
          d.selectedValue =itemData?.type==='number'?dataValue==0?0 : parseFloat(dataValue):dataValue;
        }
      });
    },
    selectedData(
      typeData,
      dataValue,
      value,
      itemData,
      index,
      isGrouped,
      selectedColumns
    ) {
      this.groupedData[index].map((d) => {
        d.selectedValue = dataValue.label;
      });
    },
    handleaddSelectedOptions(options) {
      // Create a deep copy of groupedRows
      const selectedOptions = this.$props.bucketsSelecteddata;

      selectedOptions.map((d) => {
        const newRow = JSON.parse(JSON.stringify(this.groupedRows));

        newRow.map((val) => {
          if (val.type === "number") {
            val.selectedValue = parseInt(d[val.prop]);
          } else {
            val.selectedValue = d[val.prop];
          }
        });

        this.groupedData = [...this.groupedData, newRow];
      });
    },

    handleaddGroupedColumns() {
      // Create a deep copy of groupedRows
      const newRow = JSON.parse(JSON.stringify(this.groupedRows));

      // Add the deep copy to groupedData
      this.groupedData = [...this.groupedData, newRow];

      // Add indexes and sequences to the newly added rows
      this.groupedData.forEach((grp, ind) => {
        grp.forEach((d, inx) => {
          d.index = ind;
          d.sequence = inx;

          if (this.$props.groupedTableOptions) {
            d.options = this.$props?.groupedTableOptions || [];
          }
        });
      });
    },
    removeColumn(index) {
      this.groupedData = this.groupedData.filter((d, ind) => ind !== index);
    },
  },
  watch: {
    bucketsSelecteddata: {
      immediate: true,
      deep: true,
      handler(val) {
        this.handleaddSelectedOptions(val);
      },
    },

    groupedData: {
      deep: true,
      handler(val) {
        val.map((data, inx) => {
          let dataSelect = {};
          data.map((d) => {
            dataSelect[d.prop] = d.selectedValue;
          });

          this.measure_details[inx] = dataSelect;
        });

        if (this.measure_details.length > 0) {
          this.$emit("bucket_details", this.measure_details);
        }
      },
    },
  },
};
</script>

<style scoped>
.grouped-container {
}
.content-container {
  padding: 0.5rem;
}
.custom-icon-size {
  font-size: 1.2rem;
}
.select-body {
  display: flex;
  align-items: center;
  padding: 1rem 0rem;
}
.spacing-container {
  padding-right: 0.5rem;
}
.cross {
  padding-top: 29px;
  font-size: 1.5rem;
  cursor: pointer;
}
.astrisk {
  font-size: 12px;
  font-weight: 600;
  color: #f00;
}
</style>
