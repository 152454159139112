
<div>
  <div class="grouped-container">
    <el-row>
      <p>Buckets</p>
    </el-row>
    <div
      v-for="(groupedRows, inx) in groupedTableData"
      :key="inx"
      class="select-body"
    >
      <div v-for="(group, index) in groupedRows" :key="index">
        <div class="spacing-container">
          <div class="body-container">
            <InputView
              v-if="group.type === 'input'"
              :itemValue="group"
              :selectedValue="group?.selectedValue || ''"
              :indexOfOptions="inx"
              @item-selected="handleInput"
            />
            <NumberInput
              v-if="group.type === 'number'"
              :itemValue="group"
              :selectedValue="group?.selectedValue || ''"
              :indexOfOptions="inx"
              @item-selected="handleInput"
            />
          </div>
        </div>
      </div>
      <div :span="1" @click="removeColumn(inx)">
        <p class="cross" v-if="groupedTableData.length > 1">
          <b-icon :icon="'x'"></b-icon>
        </p>
      </div>
    </div>
  </div>

  <div @click="handleaddGroupedColumns">
    <p
      style="
        color: #1c3366;
        font-weight: 600;
        font-size: 1rem;
        padding-top: 1rem;
        display: flex;
      "
    >
      <span
        ><b-icon
          :style="{
            color: '#1c3366',
            marginRight: '0.5rem',
          }"
          class="custom-icon-size"
          :icon="'plus-circle-fill'"
        ></b-icon></span
      >{{ `Add New Bucket` }}
    </p>
  </div>
</div>
