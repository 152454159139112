import Inverter from "./Inverter";
export default class HybridInverter extends Inverter {
    constructor(stage, inverterSpecifications = {}) {
        super(stage, inverterSpecifications);
        this.name = `Hybrid Inverter #${this.id.toString()}`;
    }
    static getObjectType() {
        return 'Hybrid Inverter';
    }

    saveObject(isCopy = false) {
        return super.saveObject(isCopy, HybridInverter.getObjectType())
    }
}