import axios from "axios";
export default {
    POST_OPTIMIZER(payLoad) {
        return axios.post(`/api/master-data/optimizers/get_optimizer_list/`, payLoad);
      },

    GET_MODULES_OPTIMIZER_ELIGIBILITY(designId, payload) {
      return axios.post(`/api/designs/${designId}/get_module_optimizer_eligibilty/`, payload);
    },

    GET_ALL_MASTER_OPTIMIZERS() {
      return axios.get('/api/master-data/optimizers/');
    },

    GET_MASTER_OPTIMIZERS_WITH_QUERY(fieldName, sortType, query) {
      let param = {};
      if(fieldName) {
        param.fieldName = fieldName;
        param.sortType = sortType;
      }
      if(query) {
        param = {...param, ...query};
      }

      return axios.get(`/api/master-data/optimizers/`, {params: param});
    },

    LOAD_MORE_OPTIMIZERS(url) {
      return axios.get(url);
    },
};