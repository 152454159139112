<template>
  <div :class="!isDrilldown ? 'container' : 'container-dash'">
    <div class="navContainer" @click="handleCardSelect">
      <div class="gridContainer">
          <div class="firstContainer" v-show="!showSearchInput">
            <MenuView
              :menuItems="menuData"
              :clearAll.sync="clearAll"
              :tagsClear.sync="tagsClear"
              :usersClear.sync="usersClear"
              :regionClear.sync="regionClear"
              :leadSourceClear.sync="leadSourceClear"
              :productListClear.sync="productListClear"
              :probabilityClear.sync="probabilityClear"
              :dateClear.sync="dateClear"
              :DCvalueClear.sync="DCvalueClear"
              :costClear.sync="costClear"
              :proposalStatusClear.sync="proposalStatusClear"
              @ExpectedClosure="ExpectedClosure"
              @DCValue="DCValue"
              @probabilityValue="probabilityValue"
              @systemCost="systemCost"
              @leadCheckbox="leadCheckbox"
              @regionCheckbox="regionCheckbox"
              @tagCheckbox="tagCheckbox"
              @userCheckbox='userCheckbox'
              @proposalStatusRadio="proposalStatusRadio"
              @searchUsers="searchUsers"
              @loadMore2="debounceUsersLoadMore"
              @selectedProductsUpdated="selectedProductsUpdated"
              @customCreatedDateUpdate="customCreatedDateUpdate"
              :isDrillDown.sync="isDrilldown"
            />
            <div class="pipelineMenu" v-if="isTableOptClicked && !isDrilldown">
              <template>
                <el-menu
                  :default-active="activeIndex"
                  class="el-menu-demo"
                  mode="horizontal"
                >
                  <el-submenu index="2">
                    <template v-slot:title>Pipeline</template>
                    <el-menu-item
                      v-for="option in pipelineDetails"
                      :key="option.id"
                    >
                      <el-checkbox
                        @change="tablePipelines(option.id)"
                        v-model="tablePipeline"
                        :label="option.id"
                        class="pipeline-cb-mobile"
                        >{{ option.name }}</el-checkbox
                      >
                    </el-menu-item>
                  </el-submenu>
                </el-menu>
              </template>
            </div>
   
            <div class="pipeline" v-if="!isTableOptClicked && !isDrilldown">
              <el-menu
                :default-active="activeIndex"
                class="el-menu-demo"
                mode="horizontal"
                @select="handlePipeline"
              >
                <el-submenu index="1">
                  <template v-slot:title >
                    <span class="pipeline-select-mobile" :style="{textOverflow: 'ellipsis', width: pipelineFieldWidth}">
                      {{
                        pipelineData.name
                      }}
                    </span>
                  </template>
                  <el-menu-item
                  v-for="pipeline in pipelineDetails"
                    :key="pipeline.id"
                    :index="pipeline.id"
                  >
                    <b-icon
                      @click="IconCheck(pipeline.id)"
                      :style="{
                        marginRight: '5px',
                        color: getIconColor(pipeline.id),
                      }"
                      :icon="getIcon(pipeline.id)"
                    ></b-icon>
                    <span class="pipeline-span-mobile">{{ pipeline.name }}</span></el-menu-item
                  >
                </el-submenu>
              </el-menu>
            </div>
            <div class="relativeCont" v-show="!isMobileView">
              <p class="customize" v-if="isTableOptClicked || isDrilldown">
                Customize Table
              </p>
              <div class="customizeDropdown" v-if="showCustomizeTable">
                <p class="drpDwnHeading">Select columns to view</p>
                <div class="drpdwnFlex" id="checkColor">
                  <el-checkbox-group v-model="checkedColumnsList">
                    <el-checkbox
                      v-for="(column, index) in allColumnsList"
                      :key="index"
                      :label="column"
                    >
                      {{ column }}
                    </el-checkbox>
                  </el-checkbox-group>
                </div>
                <div class="applyBtnContainer">
                  <el-button
                    type="primary"
                    class="applyBtn"
                    @click="applySelectedColumns()"
                    >Apply</el-button
                  >
                </div>
              </div>
            </div>
          </div>
          <div :class="!isDrilldown ? 'secContainer' : 'secContainer-dash'" v-show="!showSearchInput">
            <div class="searchInpCont" v-if="!isDrilldown" @click="onClickSearch">
              <img src="./assets/search.svg" class="searchIcon" />
              <input
                type="text"
                class="searchInput"
                placeholder="Search"
                v-model="search"
              />
            </div>
            <div class="tableTypeCont" v-if="!isDrilldown">
              <el-tooltip
                class="item"
                effect="dark"
                content="Kanban View"
                placement="top"
                :hide-after="isMobileView ? 5000 : 0"
              >
                <div
                  :class="['tableOpt', !isTableOptClicked ? 'activeClass' : '']"
                  @click="setLeadListingView(false)"
                >
                  <b-icon 
                    icon="kanban"
                    class="typeIcon"
                  />
                </div>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="dark"
                content="List View"
                placement="top"
                :hide-after="isMobileView ? 5000 : 0"
              >
                <div
                  :class="['cardOpt', isTableOptClicked ? 'activeClass' : '', !isMobileView && 'list-view-icon']"
                  @click="setLeadListingView(true)"
                >
                  <b-icon 
                    icon="list-ul"
                    class="typeIcon"
                  />
                </div>
              </el-tooltip>
            </div>
            <!-- <el-button
              v-show="!isMobileView"
              v-if="!isDrilldown"
              type="primary"
              class="createLeadBtn"
              @click="handleCreate"
              >Create Lead</el-button
            > -->

            <div class="flexBtnContainer">
              <el-button 
              type="primary" 
              class="createLeadBtn"
              v-show="!isMobileView"
              v-if="!isDrilldown"
              @click="handleCreate">Create Lead</el-button>
              <el-tooltip class="item" effect="dark" content="Bulk lead is in progress." placement="top-end" :disabled="!bulkLeadImportState.isSSEProcessing">
                <button :class="['dropdownBtn',bulkLeadImportState.showMinimizablePopup == false ? '' : 'disabledDropdownBtn' ]" @click="showDropdownOption = !showDropdownOption" :disabled="bulkLeadImportState.isSSEProcessing == true ? true: false"  v-if="isAdmin && !isDrilldown">
                  <img src="../components/assets/dropdownBtnArrow.svg" />
                  <div class="dropdownBtnOption" v-if="showDropdownOption" @click="importBulkLeadFunc()">
                    <p>Import Lead</p>
                  </div>
              </button>
              </el-tooltip>
            </div>

            <div v-show="isMobileView" class="create-container-mobile" v-if="!isDrilldown" @click="addLeadBtnClicked = true">
              <b-icon icon="plus-lg" class="create-icon-mobile"></b-icon>
            </div>
            <el-button
              v-if="isDrilldown"
              type="primary"
              class="createLeadBtn"
              @click="downloadCsv"
              v-loading="downlodLoading"
              >Export CSV</el-button
            >
            <div
              v-if="isDrilldown"
              style="padding-left: 1rem; font-size: 1rem; cursor: pointer"
              @click="handleClose"
            >
              <img src="../../../assets/img/close.svg" alt="" />
            </div>
          </div>
        </div>
        <!--  Active Filters section  -->
        <div class="activeFilters" v-show="!showSearchInput">
          <div>
            <div class="tagsContainer" v-if="selectedSort.length > 0">
              <div>
                <img src="./assets/sort.svg" alt="alter" />
              </div>
              <div>
                <el-tag
                  class="tag"
                  size="medium"
                  style="margin-right: 15px"
                  closable
                  @close="handleRemoveSort()"
                >
                  {{ handleDisplaySort(selectedSort) }}
                </el-tag>
              </div>
            </div>
          </div>
          <div
            class="tagsContainer"
            v-if="
              selectedFilter.length > 0 ||
              expectClosure ||
              systemsCost ||
              dcValue ||
              probability ||
              (tags.value && tags.value.length>0) ||
              (users.value && users.value.length>0)||
              (leadSources.value && leadSources.value.length>0) ||
              (region.value && region.value.length>0) ||
              selectedProducts.length > 0  || proposalStatusValue.value
            "
          >
            <div>
              <img src="./assets/filter_alt.svg" alt="alter" />
            </div>
            <!-- <p class="actFil">Active Filters</p> -->
            <div>
              <el-tag
                v-for="(item,index) in selectedFilter"
                :key="index"
                class="tag"
                size="medium"
                closable
                :disable-transitions="false"
                @close="handleRemoveFilter(item)"
              >
                {{ handleDisplayFilters(item) }} : {{ item[2] }}
              </el-tag>
  
              <el-tag
                v-if="expectedClosure"
                class="tag"
                size="medium"
                closable
                :disable-transitions="false"
                @close="handleRemovestartClosure()"
              >
                {{ expectedClosure.label }} : {{ formattedDate }}
              </el-tag>

              <el-tag
                v-if="proposalStatus"
                class="tag"
                size="medium"
                closable
                :disable-transitions="false"
                @close="handleRemoveProposalStatus()"
              >
                {{ proposalStatusValue.label }} : {{ proposalStatusValue.value }}
              </el-tag>

  
              <el-tag
                v-if="dcValue?.value"
                class="tag"
                size="medium"
                closable
                :disable-transitions="false"
                @close="handleDCremove()"
              >
                {{ dcValue.label }} :
                {{ `${dcValue.value[1]}kW - ${dcValue.value[0]}kW` }}
              </el-tag>
              <el-tag
                v-if="probability?.value"
                class="tag"
                size="medium"
                closable
                :disable-transitions="false"
                @close="handleProbabilityRemove()"
              >
                {{ probability.label }} :
                {{ `${probability.value[1]}% - ${probability.value[0]}%` }}
              </el-tag>
  
              <el-tag
                v-if="systemsCost?.value"
                class="tag"
                size="medium"
                closable
                :disable-transitions="false"
                @close="handleCostremove()"
              >
                {{ systemsCost.label }} :
                {{
                  `${isUSFlagEnabled ? "$" : "₹"}${systemsCost.value[1]} - ${
                    isUSFlagEnabled ? "$" : "₹"
                  }${systemsCost.value[0]}`
                }}
              </el-tag>
               <el-tooltip v-if="leadSources.value && leadSources.value.length>0" placement="bottom" effect="dark">
                <template v-slot:content>
                <div>
                  <p
                    v-for="(leadSource, index) in leadSources.value"
                    :key="index"
                  >
                    {{ displayLeadSource(leadSource)}}
                  </p>
                </div>
                </template>
                <el-tag class="tag" closable @close="handleLeadremove()">
                  {{ leadSources.label }}  <span v-if="leadSources.value.length==1" >:  {{ displayLeadSource(leadSources.value[0]) }}</span>
                  <span v-else class="tag-value">{{ leadSources.value.length }}</span>
                </el-tag>
              </el-tooltip>
               <el-tooltip v-if="selectedProducts.length>0" placement="bottom" effect="dark">
                <template v-slot:content>
                <div>
                  <p
                    v-for="(product, index) in selectedProducts"
                    :key="index"
                  >
                    {{ product.name }}
                  </p>
                </div>
                </template>
                <el-tag class="tag" closable @close="handleRemoveProductFilters()">
                  {{ 'Products' }}  <span v-if="selectedProducts.length==1" >:  {{ selectedProducts[0].name }}</span>
                  <span v-else class="tag-value">{{ selectedProducts.length }}</span>
                </el-tag>
              </el-tooltip>
              <el-tooltip v-if="region.value && region.value.length>0" placement="bottom" effect="dark">
                <template v-slot:content>
                <div>
                  <p
                    v-for="(regions,index) in region.value"
                    :key="index"
                  >
                    {{ displayRegion(regions)}}
                  </p>
                </div>
                </template>
                <el-tag class="tag" closable @close="handleRegionRemove()">
                  {{ region.label }}  <span v-if="region.value.length==1" >:  {{ displayRegion(region.value[0]) }}</span>
                  <span v-else class="tag-value">{{ region.value.length }}</span>
                </el-tag>
              </el-tooltip>
  
              <el-tooltip  v-if="tags.value && tags.value.length>0" placement="bottom" effect="dark">
                <template v-slot:content>
                  <div> <p class="filterTag" v-for="(tag, index) in tags.value" :key="index">{{ tag }}</p></div>
                </template>
                <el-tag
                  class="tag"
                  closable
                  :disable-transitions="false"
                  @close="handleTagremove()"
                >
                  {{ tags.label }} <span v-if="tags.value.length==1" >:  {{ tags.value[0] }}</span>  <span v-else class="tag-value">{{ tags.value.length }}</span>
                </el-tag>
              </el-tooltip>
              <el-tooltip  v-if="users.value && users.value.length>0" placement="bottom" effect="dark">
                <template v-slot:content>
                <div> <p class="filterTag" v-for="(user, index) in users.value" :key="index">{{ displayOwner(user) }}</p></div>
                </template>
                <el-tag
                  class="tag"
                  closable
                  :disable-transitions="false"
                  @close="handleUserRemove()"
                >
                  {{ users.label }} <span v-if="users.value.length==1" >:  {{ displayOwner(users.value[0])  }}</span>  <span v-else class="tag-value">{{ users.value.length }}</span>
                </el-tag>
              </el-tooltip>
            </div>
          </div>
          <div
            class="tagsContainer"
            v-if="tablePipeline.length > 0 && (isTableOptClicked || isDrilldown)"
          >
            <!-- <div> -->
              <el-tooltip  v-if="filterPipeline" placement="bottom" effect="dark">
                <template v-slot:content>
                <div> <p class="filterTag" v-for="(pipeline, index) in filterPipeline" :key="index">{{ pipeline }}</p></div>
                </template>
                  <el-tag
                    class="tag"
                    style="margin-right: 15px"
                    closable
                    @close="handleRemovePipeline()"
                  >
                    Pipeline <span v-if="filterPipeline.length ==1" >: {{ filterPipeline[0] }}</span> <span v-else class="tag-value">{{ filterPipeline.length }}</span>
                  </el-tag>
              </el-tooltip>
            <!-- </div> -->
          </div>
          <p
            class="clrAll"
            v-if="
              selectedFilter.length > 0 ||
              expectClosure ||
              systemsCost ||
              dcValue ||
              probability ||
              (tags.value && tags.value.length>0) ||
              (users.value && users.value.length>0)||
              (leadSources.value && leadSources.value.length>0) ||
              (region.value && region.value.length>0) ||
              selectedSort.length > 0 ||
              selectedProducts.length > 0 ||
              proposalStatusValue.value

            "
            @click="handleClear"
          >
            Clear all
          </p>
        </div>
        <div v-show="showSearchInput" class="search-container">
          <input
            ref="searchInput"
            type="text"
            placeholder="Search"
            v-model="search"
            class="searchInput-active searchInput"
          />
          <b-icon icon="x-lg" class="searchInput-close-btn" @click="showSearchInput = false"></b-icon>
        </div>
      </div>
      <div v-if="!isDrilldown">
        <div v-if="!isTableOptClicked">
          <div v-if="emptyPipline">
            <NoDataOverView :infoData="'No lead pipeline found.'" />
          </div>
          <div
            v-loading="kanbanDataValue.length > 0 ? false : true"
            :style="{
              height: 'calc(100vh - 250px)',
              marginTop: kanbanDataValue.length > 0 ? 0 : '1.5rem',
            }"
            v-else
          >
            <KanbanView
              :isLoading="isKanbanLoading"
              :dataTable="kanbanDataValue"
              @handleChange="handleChange"
              :buttonsData="buttonsData"
              :handleOrderClick="handleOnClick"
              :order.sync="order"
              :page="'Leads'"
              :getHeaderTitle="() => 'void'"
              :handleCreate="handleCreate"
              :columnType="'stage'"
              :drawer.sync="drawer"
              :drawerStage.sync="drawerStage"
              :componentName.sync="componentName"
              :drawerSize.sync="drawerSize"
              :leadDrawer="leadDrawer"
              :loadMoreData="debounceLoadMore"
              :offSetHeight="'20.1rem'"
              :showCreateDrawer.sync="showCreateDrawer"
              @updateReminder="handleReminder"
              :selectedCard="selectedCard"
              @handleColumnNumber="handleColumnNumber"
              :key="leadsRerender"
            />
          </div>
        </div>
        <LeadManagementTable
          v-else
          :search="search"
          :checkedColumnsList="checkedColumnsListToSend"
          :selectedSort.sync="selectedSort"
          :selectedFilter.sync="selectedFilter"
          :DCvalue.sync="DCvalue"
          :Systemcost.sync="Systemcost"
          :expectClosure.sync="expectClosure"
          :leadSeperator.sync="leadSeperator"
          :tagSeperator.sync="tagSeperator"
          :tablePipeline.sync="tablePipeline"
          :clearFlag.sync="clearFlag"
          :isDrilldown.sync="isDrilldown"
          :listQueryParams.sync="listQueryParams"
          :key="leadsRerender"
        />
      </div>
      <div v-if="isDrilldown && listQueryParams">
        <LeadManagementTable
          :search="search"
          :checkedColumnsList="checkedColumnsListToSend"
          :selectedSort.sync="selectedSort"
          :selectedFilter.sync="selectedFilter"
          :DCvalue.sync="DCvalue"
          :Systemcost.sync="Systemcost"
          :expectClosure.sync="expectClosure"
          :leadSeperator.sync="leadSeperator"
          :tagSeperator.sync="tagSeperator"
          :tablePipeline.sync="tablePipeline"
          :clearFlag.sync="clearFlag"
          :isDrilldown.sync="isDrilldown"
          :listQueryParams.sync="listQueryParams"
          @updateWidget="updateWidget"
          :isupdate.sync="isupdate"
          :updateValues="updateValues"
          :widgetId="widgetId"
          :key="leadsRerender"
        />
      </div>

      <div class="bottomPopupOverlay" v-if="addLeadBtnClicked">
        <div class="positionR">
          <b-icon icon="x-lg" class="closeIcon" @click="addLeadBtnClicked = false"></b-icon>
          <div class="bottomPopupForLeadOptions">
          <button class="importLeadBtnMD" @click="handleCreate" v-if="!isDrilldown">Create Lead</button>
          <el-tooltip class="item" effect="dark" content="Bulk lead is in progress." placement="top-end" :disabled="!bulkLeadImportState.isSSEProcessing">
            <button class="importLeadBtnMD" @click="importBulkLeadFunc(), addLeadBtnClicked = false" :disabled="bulkLeadImportState.isSSEProcessing == true ? true: false"  v-if="isAdmin && !isDrilldown">Import Lead</button>
          </el-tooltip>
        </div>
        </div>
      </div>
  
      <all-drawer
        :componentName="componentName"
        :key="createKey"
        :drawer.sync="drawer"
        :drawerStage.sync="drawerStage"
        :drawerSize="isMobileView ? '100%' : drawerSize"
        :propsData="{
          leadData: order,
          activePipelineId: pipelineIdForCreateLeadDrawer
        }"
        @save="drawer = false"
        @close="drawer = false"
      ></all-drawer>
  
      <setReminder
        v-if="!isDrilldown"
        :key="setKey"
        :showCreateDrawer="showCreateDrawer"
        @close="showCreateDrawer = false"
        :lead="order"
        @save="onSave"
      />
  
      <LeadsDrawer
        v-if="leadDrawer"
        :drawer.sync="leadDrawer"
        :lead="leadValue"
        @update-kanban="handleUpdateKanban"
        :currentRowNumber="currentRowNumber"
        @close="[(leadDrawer = false), (activeTab = 'first')]"
        @update-owner="updateOwnerInCurrentRow"
        @update-stage="updateStageInCurrentRow"
        @update-lead-source="updateLeadSourceInCurrentRow"
        @update-region="updateRegionInCurrentRow"
        @update-probability="updateProbabilityInCurrentRow"
        :activeTab="'first'"
        @updated-tags="updateTagsInCurrentRow"
        @deleted-lead="updateAllLeadsData"
        @update-dealvalue="getSumOfDealValueByStages"
      />
      <DealValue
        v-if="dealCheck"
        :isDeleteProjectDocumentPopupOpen="true"
        :title="`Deal Value Check`"
        :info="`Deal value for this lead is ${currencySymbol}0.`"
        :info2="` Do you want to continue without updating it?`"
        @cancelDelete="cancelDelete"
        @confirmDelete="confirmDelete"
      />
      <ImportLeadsPopup
        v-if="bulkLeadImportState.isImportLeadsPopupVisible" />

      <MinimizablePopup v-if="bulkLeadImportState.showMinimizablePopup" />
    </div>
  </template>
  
  <script>
  import KanbanView from "../../../components/ui/kanban/components/kanbanView.vue";
  import API from "@/services/api/";
  import LeadManagementTable from "./leadManagementTable.vue";
  import { DateTime } from "luxon";
  import setReminder from "./../../setReminder/setReminder.vue";
  import LeadsDrawer from "./leadsDrawer.vue";
  import MenuView from "../../designOrders/components/header/menuView.vue";
  import DealValue from "./dealValueCheckDialog.vue";
  import {
    isRelianceOrg,
    generateColorFromName,
    getUiFromStorage,
    setUiInStorage,
    isUSFlagEnabled,
  } from "../../../utils";
  import { getCurrencySymbol } from "../../../utils/numberFormat/currencyFormatter";
  import debounce from "debounce";
  import { formatDateTime, typeConstants } from "../../../utils/dateFormatter";
  import NoDataOverView from "../../../components/ui/noDataOverView.vue";
  import { useUiStore } from "../../../stores/ui";
  import ImportLeadsPopup from "./importLeadsPopup.vue";
  import MinimizablePopup from './minimizablePopup.vue';
  import { useMiscStore } from "../../../stores/misc";
  import { useReviewStore } from "../../../stores/review";
  import { mapState, mapActions } from "pinia";
  import {isAdmin} from "../../../utils";
  import { useLeadStore } from "../../../stores/lead";
  import { isSOMUsers } from "../../utils/l2c2cRoles";
  import { queryParamsFormater } from "../../../utils/queryParamGenerator";
  
  export default {
    emits: ["filterView", "updateWidget", "handleClose", "csv-download"],
    components: {
      LeadManagementTable,
      KanbanView,
      setReminder,
      DealValue,
      LeadsDrawer,
      MenuView,
      NoDataOverView,
      ImportLeadsPopup,
      MinimizablePopup,
    },
    props: {
      isDrilldown: {
        type: Boolean,
        default: false,
      },
      drillDownUrl: {
        type: Object,
        default: () => {},
      },
      widgetId: {
        type: Number,
        default: null,
      },
    },
  
    data() {
      return {
        leadCount: 0,
        showSearchInput: false,
        iconPipeline: [],
        dealCheck: false,
        dragValue: [],
        dragColumn: [],
        isRole:'',
        previousValue: {},
        currencySymbol: "",
        countryCode: "",
        productListClear:false,
        leadSourceClear:false,
        regionClear:false,
        proposalStatusClear: false,
        tagsClear:false,
        usersClear:false,
        DCvalueClear:false,
        dateClear:false,
        costClear:false,
        probabilityClear:false,
        allLeadSources: [],
        allRegions: [],
        isProceed: false,
        pipelineDetails: [],
        pipelineData: { name: "", id: 0 },
        selectedCard: false,
        currentRowNumber: 0,
        setKey: 0,
        iconColors: {},
        createKey: 0,
        clearFlag: false,
        clearAll: false,
        ui: getUiFromStorage(),
        startClosure: "",
        endClosure: "",
        expectClosure: "",
        expectedClosure: "",
        leadSources: "",
        region: "",
        tags: "",
        lessDC: "",
        filterPipeline: "",
        moreDC: "",
        maxProbability: "",
        minProbability: "",
        DCvalue: "",
        ProbabilityValue: "",
        dcValue: "",
        probability: "",
        Systemcost: "",
        leadSeperator: "",
        regionSeperator: "",
        tagSeperator: "",
        users:'',
        usersSeperator:'',
        costLess: "",
        costMore: "",
        systemsCost: "",
        selectedSort: [],
        selectedFilter: [],
        filtersString: "",
        sortStrings: "",
        isUSFlagEnabled: isUSFlagEnabled,
        allTags: [],
        allUsers:[],
        allUserData:[],
        drawerStage: "",
        leadSourceData: [],
        regionsData: [],
        leadCheckBoxNames: [],
        regionCheckBoxNames: [],
        drawerSize: 700,
        leadDrawer: false,
        showCreateDrawer: false,
        leadData: "",
        leadValue: {},
        order: {},
        drawer: false,
        isKanbanLoading: true,
        isSearchedLeadEmpty: false,
        isSearchingLeads: false,
        searchVal: "",
        componentName: "createLead",
        value: "",
        selectedCheckboxes: [],
        selectedOption: "",
        selectedDate: "",
        datePickerOptions: {},
        activeIndex: "1",
        downlodLoading: false,
        emptyPipline: false,
  
        pipelines: [],
        options: [
          {
            value: "Option1",
            label: "Option1",
          },
          {
            value: "Option2",
            label: "Option2",
          },
        ],
        tablePipeline: [],
        buttonsData: [],
        isTableOptClicked: false,
        showCustomizeTable: false,
        search: "",
        checkedColumnsList: [],
        typeConstants: typeConstants,
        allColumnsList: [
          "Pipeline",
          "Stage",
          "Lead Source",
          "Owner",
          "Type",
          ...( isRelianceOrg() ? ["Region"] : []),
          "Probability",
          "Created On",
          "Target Close Date",
          "Deal Value",
          "Latest Note",
          "System Size",
          "Last Contacted",
          "Country",
        ],
        checkedColumnsListToSend: [
          "Pipeline",
          "Stage",
          "Lead Source",
          "Owner",
          "Type",
          ...( isRelianceOrg()? ["Region"] : []),
          "Probability",
          "Created On",
          "Target Close Date",
          "Deal Value",
          "Latest Note",
          "System Size",
          "Last Contacted",
          "Country",
        ],
        designerViewList: [
        "Stage",
        "Lead Source",
        "Owner",
        "Type",
        "Created On",
        "System Size",
        "Region",
        
      ],
        containerHeight: "2rem",
        kanBanColumns: [
          {
            title: "lead",
            tasks: [],
          },
          {
            title: "appointment",
            tasks: [],
          },
  
          {
            title: "proposal",
            tasks: [],
          },
          {
            title: "negotiation",
            tasks: [],
          },
          {
            title: "won",
            tasks: [],
          },
          {
            title: "lost",
            tasks: [],
          },
        ],
        kanbanData: [],
        drillDownStatus: false,
        listQueryParams: "",
        isupdate: false,
        pipelineIdForCreateLeadDrawer: null,
        nextUsersUrl: "",
        showDropdownOption: false,
        importLeadOptions: [
          {
            value: "Import Lead",
            label: "Import Lead",
          },
        ],
        addLeadBtnClicked: false,
        selectedProducts: [],
        proposalStatus: "", 
        proposalStatusValue: "",
        queryStringsFilters:""
      };
    },
  
    watch: {
      leadsRerender:{
        handler(val,prev){
          if(val>prev)
            this.getLeadsdata();
        }
      },
      leadCount: {
        immediate: true,
        handler(newVal, oldVal){
          this.SET_TOTAL_LEADS(newVal)
        }
      },
      isupdate: {
        immediate: true,
        deep: true,
        handler(val) {},
      },
      isDrilldown(val) {},
      selectedFilter(val) {
        let ui = getUiFromStorage();
  
        let reqObj = "";
        val.map((k, inx) => {
          reqObj = reqObj + `${k[1]}=${k[2]}`;
          if (inx < val.length - 1) {
            reqObj += "&";
          }
        });
        this.filtersString = reqObj;
        // ui.leadManagement.selectedFilter = val;
        // ui.leadManagement.filterStrings = reqObj;
  
        // setUiInStorage(ui);
  
        this.getLeadsdata();
      },
      drillDownUrl(val) {
        this.getLeadsdata();
      },
      selectedSort(val) {
        this.sortStrings = val;
  
        let ui = getUiFromStorage();
        // ui.leadManagement.selectedSort = val;
        // setUiInStorage(ui);
        this.getLeadsdata();
      },
      order(val) {
        // val.id = val.project_id;
        this.leadValue = val;
      },
      isTableOptClicked(val) {
        this.getLeadsdata();
      },
      search(val) {
        this.searchAllLeads(val);
        // this.searchVal = val;
  
        // if (!this.isTableOptClicked) {
        //   this.getKanbanData();
        // }
      },
      updateWorkFlow(val){
        this.updateWorkFlowType(val,'',this.leadInfo)

      },
 
    },
    async mounted() {
      this.getPipeline();
      this.getAllTags();
      this.getAllUsers();
      this.getAllUserData();
      this.getAllLeadSources();
      this.getAllRegions();
      
      // const lead = await API.LEADS.FETCH_LEAD_LIST();
      // const data = lead.data.results;
      // // const data = [
      // //   {
      // //     id: 65556,
      // //     weather: null,
      // //     project_type: "residential",
      // //     lead_details: {
      // //       id: 345,
      // //       stage: "Appointment",
      // //       owner: "CRM 2",
      // //       deal_value: 2000,
      // //       estimated_system_size: 0,
      // //       last_contacted: null,
      // //       lead_source: null,
      // //       target_closure_date: "12-03-2023",
      // //       name: "bewjk",
      // //       email: "a@i.com",
      // //       phone: "169900",
      // //       created_at: "2023-05-12T10:13:08.059251Z",
      // //     },
      // //     country: null,
      // //     tags: [],
      // //   },
      // //   {
      // //     id: 65555,
      // //     weather: null,
      // //     project_type: "residential",
      // //     lead_details: {
      // //       id: 344,
      // //       stage: "Lead",
      // //       owner: "CRM2",
      // //       deal_value: 2000,
      // //       estimated_system_size: 0,
      // //       last_contacted: null,
      // //       lead_source: null,
      // //       target_closure_date: "12-03-2023",
      // //       name: "g",
      // //       email: "a@i.com",
      // //       phone: "169900",
      // //       created_at: "2023-05-12T10:12:30.550894Z",
      // //     },
      // //     country: null,
      // //     tags: [],
      // //   },
      // // ];
      // let datacont = [];
      // data.map((d) => {
      //   d.lead_details.created_at = this.formateDateTime(
      //     d.lead_details.created_at
      //   );
      //   d.lead_details.tags = d.tags;
      //   d.lead_details.country = d.country;
      //   d.lead_details.project_type = d.project_type;
      //   d.lead_details.weather = d.weather;
      //   d.lead_details.project_id = d.id;
      //   datacont.push(d.lead_details);
      // });
      // this.kanbanData = [];
      let ui = getUiFromStorage();
      ui.leadManagement.tableSelectedColumns ||= [...this.allColumnsList];
      ui.leadManagement.drillDownSelectedColumns ||= [...this.allColumnsList];
      ui.leadManagement.designerViewTable||=[...this.designerViewList]
      setUiInStorage(ui);
      this.isTableOptClicked = ui.leadManagement?.isTableView || false;
      this.getLeadsdata();
  
      // this.selectedFilter = ui.leadManagement?.selectedFilter || "";
      // this.leadSources = ui.leadManagement?.leadSource || "";
  
      // this.dcValue = ui.leadManagement?.DCvalue || "";
      // this.expectClosure = ui.leadManagement?.ExpectedClosure || "";
      // this.Systemcost = ui.leadManagement?.systemCost || "";
      // this.tags = ui.leadManagement?.tags || "";
  
      if(this.$props.isDrilldown){
      this.checkedColumnsList= [...ui.leadManagement.drillDownSelectedColumns]
      this.checkedColumnsListToSend= [...ui.leadManagement.drillDownSelectedColumns]
    }else{
      if(this.isRole=='DESIGNER' ){
        this.checkedColumnsList= [...ui.leadManagement.designerViewTable]
        this.checkedColumnsListToSend= [...ui.leadManagement.designerViewTable]
      }else{
      this.checkedColumnsList= [...ui.leadManagement.tableSelectedColumns]
      this.checkedColumnsListToSend= [...ui.leadManagement.tableSelectedColumns]
      }
    }
      const vm = this;
      window.addEventListener(
        "click",
        function (e) {
          let customizeTableButton = document.querySelector(".relativeCont");
          if (!customizeTableButton) {
            return;
          }
  
          if (customizeTableButton?.contains(e.target)) {
            this.showCustomizeTable = true;
          } else {
            this.showCustomizeTable = false;
          }
        }.bind(vm)
      );
    },
    created() {
      this.countryCode = JSON.parse(
        localStorage.getItem("organisation")
      ).country_code;
      this.isRole=JSON.parse(localStorage.getItem("user")).role
      this.debounceLoadMore = debounce(this.loadMoreData, 300);
      this.debounceUsersLoadMore = debounce(
      this.loadMoreUsers,
      300
    );
    },
    computed: {
      ...mapState(useMiscStore, {
        bulkLeadImportState: (state) => state.bulkLeadImportState,
      }),
      ...mapState(useUiStore, {
        isMobileView: state => state.isMobileView,
        currentScreenWidth: state => state.currentScreenWidth
      }),
      ...mapState(useLeadStore, {
      updateWorkFlow:(state)=>state.workflow_type,
      leadInfo:state=>state,
      }),
      isAdmin() {
        return (JSON.parse(localStorage.getItem("user")).role === "ADMIN" );
      },
      leadsRerender(){
        return this.bulkLeadImportState.leadsRerender
      },
      isRelianceOrg,
      isSOMUsers,
      pipelineFieldWidth(){
        if(this.isMobileView) return `${this.currentScreenWidth - 300}px`
        return 'auto'
      },
      formattedDate() {
        const months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
  
        const date = new Date(this.startClosure);
        const day = date.getDate();
        const monthIndex = date.getMonth();
        const year = date.getFullYear();
        const month = months[monthIndex];
  
        const enddate = new Date(this.endClosure);
        const endday = enddate.getDate();
        const endmonthIndex = enddate.getMonth();
        const endyear = date.getFullYear();
        const endmonth = months[endmonthIndex];
  
        return `${day} ${month} ${year} - ${endday} ${endmonth} ${endyear}`;
      },
      menuData() {
        const menuItems = [
          {
            title: "Sort",
            icon: "sort",
            callback: (key, keypoints) => {
              this.selectedSort = keypoints;
            },
            options: [
              {
                value: "Amount",
                label: "deal_value",
                subOptions: [
                  {
                    value: "Ascending",
                    label: "asc",
                    index: 1,
                  },
                  {
                    value: "Descending",
                    label: "desc",
                    index: 2,
                  },
                ],
              },
              {
                value: "Sales Stage",
                label: "stage",
                subOptions: [
                  {
                    value: "Oldest To Newest",
                    label: "asc",
                    index: 3,
                  },
                  {
                    value: "Newest To Oldest",
                    label: "desc",
                    index: 4,
                  },
                ],
              },
              {
                value: "Expected Closure Date",
                label: "target_closure_date",
                subOptions: [
                  {
                    value: "Nearest First",
                    label: "asc",
                    index: 5,
                  },
                  {
                    value: "Farthest First",
                    label: "desc",
                    index: 6,
                  },
                ],
              },
              {
                value: "Created Date",
                label: "created_at",
                subOptions: [
                  {
                    value: "Oldest To Newest",
                    label: "asc",
                    index: 7,
                  },
                  {
                    value: "Newest To Oldest",
                    label: "desc",
                    index: 8,
                  },
                ],
              },
              {
                value: "Last modified",
                label: "modified_at",
                subOptions: [
                  {
                    value: "Oldest To Newest",
                    label: "asc",
                    index: 9,
                  },
                  {
                    value: "Newest To Oldest",
                    label: "desc",
                    index: 10,
                  },
                ],
              },
              {
                value: "Deal Owner",
                label: "owner",
                subOptions: [
                  {
                    value: "Ascending",
                    label: "asc",
                    index: 11,
                  },
                  {
                    value: "Descending",
                    label: "desc",
                    index: 12,
                  },
                ],
              },
              {
                value: "Location",
                label: "country",
                subOptions: [
                  {
                    value: "Ascending",
                    label: "asc",
                    index: 13,
                  },
                  {
                    value: "Descending",
                    label: "desc",
                    index: 14,
                  },
                ],
              },
              {
                value: "System Size",
                label: "estimated_system_size",
                subOptions: [
                  {
                    value: "Ascending",
                    label: "asc",
                    index: 15,
                  },
                  {
                    value: "Descending",
                    label: "desc",
                    index: 16,
                  },
                ],
              },
              {
                value: "Probability",
                label: "probability",
                subOptions: [
                  {
                    value: "Ascending",
                    label: "asc",
                    index: 19,
                  },
                  {
                    value: "Descending",
                    label: "desc",
                    index: 20,
                  },
                ],
              },
              {
                value: "Client Names",
                label: "name",
                subOptions: [
                  {
                    value: "Ascending",
                    label: "asc",
                    index: 17,
                  },
                  {
                    value: "Descending",
                    label: "desc",
                    index: 18,
                  },
                ],
              },
            ],
          },
          {
            title: "Filter",
            icon: "filter",
            // callback: (key, keypoints) => {
            //   this.selectedFilter = keypoints;
            // },
  
            callback: (key, keypoints) => {
              const isExist = this.selectedFilter.find(
                (e) => e[1] === keypoints[1]
              );
              if (isExist) {
                if (keypoints[1] === "Created at" && keypoints[2] === "Custom")
                  this.show = !this.show;
                if (keypoints[1] === "Due Date" && keypoints[2] === "Custom")
                  this.dueshow = !this.dueshow;
                this.selectedFilter = this.selectedFilter.filter((e) => {
                  if (e[1] !== keypoints[1]) return e;
                });
                this.selectedFilter.push(keypoints);
              } else {
                // this.ExpectedClosure()
                this.selectedFilter.push(keypoints);
              }
  
              this.$emit("filterView", this.selectedFilter);
            },
            options: [
              {
                value: "Time Period",
                label: "time_period",
                type: "text",
                subOptions: [
                  {
                    value: "This Week",
                    label: "This Week",
                    index: "This Week",
                  },
                  {
                    value: "This Month",
                    label: "This Month",
                    index: "This Month",
                  },
                  {
                    value: "This Quarter",
                    label: "This Quarter",
                    index: "This Quarter",
                  },
                ],
              },
              {
                value: "Lead Source",
                label: "lead_source",
                type: "checkbox",
                subOptions: this.allLeadSources,
              },
              ...(isRelianceOrg()
            ? [
                {
                  value: "Region",
                  label: "region",
                  type: "checkbox",
                  subOptions: this.allRegions,
                },
              ]
            : []),
              ...(isSOMUsers()
              ? [{
                value:"Products",
                label:"products",
                type:"checkbox",  
              },] : []),
              {
                value: "Tags",
                label: "tags",
                type: "checkbox",
                // subOptions: [
                //   {
                //     value: "Rooftop",
                //     label: "Rooftop",
                //   },
                //   {
                //     value: "Ground-mount",
                //     label: "Ground-mount",
                //   },
                //   {
                //     value: "Storage",
                //     label: "Storage",
                //   },
                //   {
                //     value: "Re-roofing Required",
                //     label: "Re-roofing Required",
                //   },
                //   {
                //     value: "Gazebo",
                //     label: "Gazebo",
                //   },
                //   {
                //     value: "Special",
                //     label: "Special",
                //   },
                // ],
                subOptions: this.allTags,
              },
              {
                value: "Lead Owner",
                label: "owner",
                type: "checkbox",
                subOptions: this.allUsers,
              },
              {
                value: "Expected Closure",
                label: "Expected closure",
                type: "date",
              },
              // {
              //   label: "Location",
              //   value: "Location",
              //   type: "dropdown",
              //   subOptions: [
              //     { label: "USA", value: "USA" },
              //     { label: "California", value: "California" },
              //     { label: "90001", value: "9001" },
              //   ],
              // },
              {
                value: "Property Type",
                label: "property_type",
                type: "text",
                subOptions: [
                  {
                    value: "Residential",
                    label: "residential",
                    index: "residential",
                  },
                  {
                    value: "Commercial",
                    label: "commercial",
                    index: "commercial",
                  },
                ],
              },
              {
                value: "Probability",
                label: "probability",
                type: "input",
              },
              {
                value: "DC Capacity",
                label: "DC Capacity",
                type: "input",
              },
              {
                value: "System Cost",
                label: "System Cost",
                type: "$input",
              },
              ...(isSOMUsers() ? [{
                value: "Proposal Status",
                label: "proposal_status",
                type: "radio",
                subOptions: [
                  {
                    value: "Accepted",
                    label: "ACCEPTED",
                    index: "accepted",
                  },
                  {
                    value: "Pending",
                    label: "PENDING",
                    index: "pending",
                  },
                ], },] : []),
            ],
            expected_closure: "",
          },
        ];
        let menu = [];
        if (this.isDrilldown) {
          menu = menuItems.filter((d) => d.title === "Sort");
          menu.map((d) => {
            if (d.title === "Sort") {
              d.title = "Sort by";
              d.isDrillDown = true;
            }
          });
        }
        return menu.length ? menu : menuItems;
      },
      computedShowCustomizeTable() {
        return this.showCustomizeTable;
      },
      kanbanDataValue() {
        const kanbanSortedData = this.kanbanData.sort(
          (a, b) => a.sequence - b.sequence
        );
        let key = "sequence";
        const arrayUniqueByKey = [
          ...new Map(kanbanSortedData.map((item) => [item[key], item])).values(),
        ];
        return arrayUniqueByKey;
      },
    },
    methods: {
      ...mapActions(useReviewStore, ["SET_TOTAL_LEADS"]),
      customCreatedDateUpdate(val) {
        const timePeriodIndex = this.selectedFilter.findIndex((item) => item[1] === 'time_period');
        if (timePeriodIndex > -1) {
          this.selectedFilter.splice(timePeriodIndex, 1);
        }
          this.selectedFilter.push(['1-1', 'time_period', 
          `${val[0].getDate()} ${val[0].toLocaleString('default', { month: 'short' })} ${val[0].getFullYear()} - ${val[1].getDate()} ${val[1].toLocaleString('default', { month: 'short' })} ${val[1].getFullYear()}`
          ,'Custom',...val]);
        
      },
      async importBulkLeadFunc() {
          this.bulkLeadImportState.isImportLeadsPopupVisible = true;
      },
      displayLeadSource(val){
        let lead=this.leadSourceData.find((lead)=> lead.id==val)
        return lead ? lead.name : val
      },
      displayRegion(val){
        let region=this.regionsData.find((region)=> region.id==val)
        return region ? region.name : val
      },
      displayOwner(val){
        let user=this.allUserData.find((user)=> user.id==val)
        return user ? user.first_name +" "+ user.last_name : val
      },
      cancelDelete() {
        this.isDeleteProjectDocumentPopupOpen = false;
        this.dealCheck = false;
        let data = this.iconPipeline.find(
          (pipeline) => pipeline.id == this.dragValue.lead_details.pipeline
        );
        let stage = data.pipeline_stages.find(
          (stage) => stage.id == this.dragValue.lead_details.stage_object
        );
        this.dragValue.lead_details.stage = stage.name;
        const filteredData = this.dragColumn.map((item) => ({
          ...item,
          tasks: item.tasks.filter((detail) => detail.id == this.dragValue.id),
        }));
        let tasks = filteredData.find((task) => task.tasks.length > 0);
        this.updateStageInCurrentRow(stage.name, 0, this.dragValue, tasks.title);
        this.isProceed = false;
      },
      confirmDelete() {
        this.isDeleteProjectDocumentPopupOpen = false;
        this.dealCheck = false;
        this.isProceed = true;
        this.handleChange(this.dragValue, this.dragColumn);
      },
      updateValues() {},
      updateWidget() {
        this.$emit("updateWidget", this.$props.widgetId);
      },
      handleClose() {
        this.$emit("handleClose");
      },
      tablePipelines(val) {
        const filteredPipelineDetails = this.pipelineDetails.filter((item) =>
          this.tablePipeline.includes(item.id)
        );
        const filteredPipelineNames = filteredPipelineDetails.map(
          (item) => item.name
        );
        let data = filteredPipelineNames ? filteredPipelineNames : "";
        this.filterPipeline = data;
        this.getLeadsdata();
      },
      async downloadCsv() {
        this.$emit("csv-download", this.checkedColumnsListToSend, "Lead",  this.listQueryParams);
      },
      async IconCheck(id) {
        this.iconColors = id;
        // let data={default_pipeline:val}
        let response = await API.PIPELINES.DEFAULT_PIPELINE(id);
      },
      getIconColor(optionId) {
        return this.iconColors === optionId ? "orange" : "black";
      },
      getIcon(optionId) {
        return this.iconColors === optionId ? "pin-angle-fill" : "pin-angle";
      },
      handlePipeline(key, keypath) {
        this.pipelineData.id =''
        this.pipelineData.id = key;
        this.getLeadsdata();
      },
      getLeadsdata() {
        if (this.isTableOptClicked === false && this.isDrilldown === false) {
          this.getKanbanData();
        }
        this.getListData();
      },
      handleColumnNumber(column) {
        const newData = this.loadMoreData(column);
      },
      handleCardSelect() {
        this.selectedCard = !this.selectedCard;
      },
      handleUpdateKanban(lead, type, owner) {
        // this.kanbanDataValue.map((d) => {
        //   if (d.title === lead.stage) {
        //     d.tasks.map((t) => {
        //       if (t.id === lead.id) {
        //         t.lead_details.owner = ` ${owner?.first_name || ""} ${
        //           owner?.last_name || ""
        //         }`;
        //         t.owner = ` ${owner?.first_name || ""} ${owner?.last_name || ""}`;
        //       }
        //     });
        //   }
        // });
        // this.kanbanData = this.kanbanDataValue;
        // const columnData = this.kanbanDataValue
        //   ? this.kanbanDataValue.length > 0
        //   : this.kanbanData;
        // const oldValue = this.kanbanDataValue.find((k) => k.title === lead.stage);
        // const oldLead = oldValue.tasks.find((l) => {
        //   if (l.id === lead.id) {
        //     l.owner = owner?.first_name || "" + owner?.last_name || "";
        //   }
        // });
      },
      handleReminder() {
        this.setKey++;
      },
      handleDateFormate(isoDate, type, isUS) {
        return formatDateTime(isoDate, type, isUS);
      },
      async loadMoreUsers(currentAssignee, assigneeField, collaborators) {
      if (this.nextUsersUrl) {
        const response = await API.DESIGN_ORDERS.LOAD_MORE_Leads(
          this.nextUsersUrl
        );
        response.data.results.forEach((item) => {
          item.value = `${item.id}`;
          item.label = `${item.first_name} ${item.last_name}`;
          delete item.competence;
          delete item.shifts;
          delete item.email;
        });

        const allUsers = response.data.results;
        let isAlreadyExist = allUsers.find((e) => e.id === currentAssignee);
        if (isAlreadyExist) assigneeField.options.shift();

        this.allUsers.push(...allUsers);
        this.nextUsersUrl = response.data.next;
      }
    },
      async searchUsers(query) {
      let response = await API.USERS.SEARCH_USERS(query);
      response.data.results.forEach((item) => {
        item.value = `${item.id}`;
        item.label = `${item.first_name} ${item.last_name}`;
    
      });
      this.allUsers = response?.data?.results;
      this.nextUsersUrl = response?.data?.next;
    },
      async getAllUsers(){
        try {
          const response = await API.USERS.FETCH_ALL_USERS();
          const userData = response.data.results;
          userData?.map((t) => {
            t.label = t.first_name + " "+ t. last_name;
            t.value = t.id;
          });
          this.allUsers = userData ? userData : [];
          this.nextUsersUrl = response.data.next;
        } catch (e) {
          this.$message({
            showClose: true,
            message: "There was an unknown error while fetching the users",
            type: "error",
            center: true,
          });
        }
      },
      async getAllUserData(){
        const param= 'view=tree&only_internal=true'
        let response=  await API.USER_MANAGEMENT.FETCH_USERS_TREE(param)
        response.data.forEach((item) => {
            item.value = `${item.id}`;
            item.label = `${item.first_name} ${item.last_name}`;
          
          });
        this.allUserData= response.data
      },
      async getAllTags() {
        try {
          const response = await API.ORGANISATION.GET_TAGS();
          const tagsData = response.data;
          tagsData?.map((t) => {
            t.label = t.name;
            t.value = t.name;
          });
          this.allTags = tagsData ? tagsData : [];
  
          // this.suggestions = response.data;
          // this.totalSuggestions = response.data;
        } catch (e) {
          this.$message({
            showClose: true,
            message: "There was an unknown error while fetching the tags",
            type: "error",
            center: true,
          });
        }
      },
      async getAllLeadSources() {
        try {
          const response = await API.CRMSETTINGS.FETCH_ALL_LEAD_SOURCE();
          this.leadSourceData = response.data;
          const tagsData = response.data;
          tagsData?.map((t) => {
            t.label = t.id;
            t.value = t.name;
          });
          this.allLeadSources = tagsData ? tagsData : [];
        } catch (e) {
          this.$message({
            showClose: true,
            message: "There was an unknown error while fetching the lead source",
            type: "error",
            center: true,
          });
        }
      },
      async getAllRegions() {
        try {
          const response = await API.CRMSETTINGS.FETCH_ALL_REGIONS();
          this.regionsData = response.data;
          const tagsData = response.data;
          tagsData?.map((t) => {
            t.label = t.id;
            t.value = t.name;
          });
          this.allRegions = tagsData ? tagsData : [];
        } catch (e) {
          this.$message({
            showClose: true,
            message: "There was an unknown error while fetching the regions",
            type: "error",
            center: true,
          });
        }
      },
      handleClear() {
        this.clearAll = true;
        this.tablePipeline = [];
        this.selectedFilter = [];
        this.selectedSort = "";
        this.sortStrings = "";
        this.startClosure = "";
        this.endClosure = "";
        this.lessDC = "";
        this.costLess = "";
        this.DCvalue = "";
        this.ProbabilityValue = "";
        this.Systemcost = "";
        this.systemsCost = "";
        this.dcValue = "";
        this.probability = "";
        this.expectClosure = "";
        this.proposalStatus = "";
        this.proposalStatusValue = "";
        this.costMore = "";
        this.moreDC = "";
        this.maxProbability = "";
        this.minProbability = "";
        this.leadSeperator = "";
        this.regionSeperator = "";
        this.tagSeperator = "";
        this.users=""
        this.usersSeperator='',
        this.expectedClosure = "";
        // this.selectedSort = "";
        this.leadSources = "";
        this.region = "";
        this.tags = "";
        let ui = getUiFromStorage();
        ui.leadManagement.DCvalue = "";
        ui.leadManagement.tags = "";
        ui.leadManagement.ExpectedClosure = "";
        ui.leadManagement.leadSource = "";
        ui.leadManagement.systemCost = "";
        ui.leadManagement.selectedSort = "";
        ui.leadManagement.selectedFilter = "";
        this.selectedProducts = [];
        setUiInStorage(ui);
      },
      handleRemoveFilter(item) {
        this.removed = item;
        this.selectedFilter = this.selectedFilter.filter((e) => {
          if (e !== item) return e;
        });
      },
      handleRemoveSort() {
        this.selectedSort = "";
        this.sortStrings = "";
        let ui = getUiFromStorage();
        ui.leadManagement.selectedSort = "";
        setUiInStorage(ui);
        this.getLeadsdata();
      },
      handleRemovestartClosure() {
        this.expectClosure = "";
        this.startClosure = "";
        this.endClosure = "";
        this.expectedClosure = "";
  
        let ui = getUiFromStorage();
        this.dateClear=true
        ui.leadManagement.ExpectedClosure = "";
        setUiInStorage(ui);
        this.getLeadsdata();
      },
      handleRemoveProposalStatus(){
        this.proposalStatus = "";
        this.proposalStatusValue = "";
        this.proposalStatusClear=true;
        this.getLeadsdata();
      },

      handleDCremove() {
        this.DCvalue = "";
        this.dcValue = "";
        this.lessDC = "";
        this.moreDC = "";
        this.DCvalueClear=true
        let ui = getUiFromStorage();
        ui.leadManagement.DCvalue = "";
        setUiInStorage(ui);
        this.getLeadsdata();
      },
      handleProbabilityRemove() {
        this.ProbabilityValue = "";
        this.maxProbability = "";
        this.probability = "";
        this.minProbability = "";
        this.probabilityClear=true
        this.getLeadsdata();
      },
      handleCostremove() {
        this.Systemcost = "";
        this.systemsCost = "";
        this.costLess = "";
        this.costMore = "";
        this.costClear=true
        let ui = getUiFromStorage();
  
        ui.leadManagement.systemCost = "";
  
        setUiInStorage(ui);
        this.getLeadsdata();
      },
      handleLeadremove() {
        this.leadSeperator = "";
        this.leadSources = "";
        let ui = getUiFromStorage();
  
        ui.leadManagement.leadSource = "";
        this.leadSourceClear=true
        setUiInStorage(ui);
        this.getLeadsdata();
      },
      handleRemoveProductFilters(){
        this.productListClear=true
      },
      handleRegionRemove() {
        this.regionSeperator = "";
        this.region = "";
        this.regionClear=true
        this.getLeadsdata();
      },
      handleTagremove() {
        this.tagSeperator = "";
        this.tags = "";
        let ui = getUiFromStorage();
        ui.leadManagement.tags = "";
        this.tagsClear=true
        setUiInStorage(ui);
        this.getLeadsdata();
      },
      handleUserRemove(){
        this.users = "";
        this.usersSeperator = "";
        this.usersClear=true
        this.getLeadsdata();
      },
      handleDisplayFilters(data) {
        const FilterData = this.menuData.find((d) => d.title === "Filter");
  
        if (FilterData) {
          const FilterOptions = FilterData.options;
          const options = FilterOptions.find((f) => f.label === data[1]);
          // const optionValue = options.subOptions.find(
          //   (so) => so.label === data[2]
          // );
          return options.value;
        }
      },
      handleDisplaySort(data) {
        const SortData = this.menuData.find((d) => d.title == "Sort");
        const DrillSortData = this.menuData.find((d) => d.title == "Sort by");
  
        if (SortData || DrillSortData) {
          const SortOptions = SortData ? SortData.options : DrillSortData.options;
          const options = SortOptions.find((f) => f.label === data[1]);
          const subOptions = options?.subOptions.find(
            (op) => op.index === data[2]
          );
  
          return `${options.value} : ${subOptions.value}`;
        }
      },
      handleRemovePipeline() {
        this.tablePipeline = [];
        this.getLeadsdata();
      },
      handleSort(data) {
        const SortData = this.menuData.find((d) => d.title === "Sort");
  
        if (SortData) {
          const SortOptions = SortData.options;
          let Sortsub = SortOptions[0].subOptions;
          const options = Sortsub.find((f) => f.index === data);
          return options.value;
        }
      },
      proposalStatusRadio(value){
        this.proposalStatus = value;
        let valueForTagsDisplay = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
        let data = value ? {label: "Proposal Status", value: valueForTagsDisplay } : "";
        this.proposalStatusValue = data ;
        this.getLeadsdata();
      },

      ExpectedClosure(value) {
        this.expectClosure = value;
        this.startClosure = value[0].toISOString();
        this.endClosure = value[1].toISOString();
        let ui = getUiFromStorage();
        let data = value
          ? {
              label: "Expected Closure",
              value: value,
            }
          : "";
        this.expectedClosure = data;
        // ui.leadManagement.ExpectedClosure = data;
        // setUiInStorage(ui);
        // this.selectedFilter.push([
        //   "Expected Closure",
        //   this.startClosure,
        //   this.endClosure,
        // ]);
        this.getLeadsdata();
      },
      DCValue(value) {
        this.DCvalue = value;
        this.lessDC = value[1];
        this.moreDC = value[0];
        let ui = getUiFromStorage();
        let data = value ? { label: "DC Capacity", value: value } : "";
        this.dcValue = data;
        // ui.leadManagement.DCvalue = data;
        // setUiInStorage(ui);
        this.getLeadsdata();
      },
      probabilityValue(value) {
        this.ProbabilityValue = value;
        this.maxProbability = value[0];
        this.minProbability = value[1];
        let data = value ? { label: "Probability", value: value } : "";
        this.probability = data;
        this.getLeadsdata();
      },
      systemCost(value) {
        this.Systemcost = value;
        this.costLess = value[1];
        this.costMore = value[0];
        let ui = getUiFromStorage();
  
        let data = value ? { label: "System Cost", value: value } : "";
        this.systemsCost = data;
        // ui.leadManagement.systemCost = data;
        setUiInStorage(ui);
        this.getLeadsdata();
      },
      leadCheckbox(value) {
        this.leadSeperator = value.join(" ,");
        value.forEach((childId) => {
          let leadCheckBox = this.leadSourceData.find((e) => e.id === childId);
          if (leadCheckBox.id) {
            this.leadCheckBoxNames.push(leadCheckBox.name);
          }
        });
        let ui = getUiFromStorage();
        let data = value ? { label: "Lead Source", value: value } : "";
        this.leadCheckBoxNames = [];
        this.leadSources = data;
        this.getLeadsdata();
      },
      selectedProductsUpdated(value){
        this.selectedProducts = value;
        // add to filter ui count
        this.getLeadsdata();
      },
      regionCheckbox(value) {
        this.regionSeperator = value.join(" ,");
        value.forEach((childId) => {
          let leadCheckBox = this.regionsData.find((e) => e.id === childId);
          if (leadCheckBox.id) {
            this.regionCheckBoxNames.push(leadCheckBox.name);
          }
        });
        let data = value
          ? { label: "Region", value: this.regionCheckBoxNames}
          : "";
        this.regionCheckBoxNames = [];
        this.region = data;
        this.getLeadsdata();
      },
      tagCheckbox(value) {
        this.tagSeperator = value.join(" ,");
        let ui = getUiFromStorage();
        let data = value ? { label: "Tags", value: value } : "";
        this.tags = data;
        // ui.leadManagement.tags = data;
        // setUiInStorage(ui);
        this.getLeadsdata();
      },
      userCheckbox(value){
        value.forEach((childId) => {
          let userCheckbox = this.allUsers.find((e) => e.id === childId);
        
        });
        this.usersSeperator = value.join(",");
        let ui = getUiFromStorage();
        let data = value ? { label: "Lead Owner", value: value } : "";
        this.users = data;
        this.getLeadsdata();
      },

      onSave(remainderDetails) {
        this.showCreateDrawer = false;
        this.getLeadsdata();
      },
  
      updateOwnerInCurrentRow(owner, row, lead) {
        this.leadValue = lead;
        const stageData=lead?.lead_details?.stage_object
        this.kanbanDataValue.map((d) => {
          if (d.stageId === stageData) {
            d.tasks.map((t) => {
             
              if (t.id === lead.id) {
                t.lead_details.owner = ` ${owner?.first_name || ""} ${
                  owner?.last_name || ""
                }`;
                t.owner = ` ${owner?.first_name || ""} ${owner?.last_name || ""}`;
                t.lead_details.owner_org_id=owner?.organisation_id||null;
                t.owner_org_id=owner?.organisation_id||null;
              }
            });
          }
        });
        this.kanbanData = this.kanbanDataValue;
      },
  
      updateStageInCurrentRow(stage, row, lead, oldStage) {
      
        if (stage !== oldStage) {
          const oldData = this.kanbanDataValue.find((d) => d.title === oldStage);
          const oldLead = oldData.tasks.find((l) => l.id === lead.id);
          this.kanbanDataValue.map((data) => {
            // if (data.title === lead.stage) {
            //   data.tasks = data.tasks.filter((t) => t.id !== lead.id);
            // }
            if (data.title == oldStage) {
              data.count -= 1;
            }
            if (data.title === stage) {
              oldLead.stage = stage;
              data.count += 1;
              data.tasks.push(oldLead);
              data.tasks = data.tasks.sort((a, b) => b.lead_id - a.lead_id);
            } else {
              data.tasks = data.tasks.filter((t) => t.id !== lead.id);
            }
          });
          this.kanbanData = this.kanbanDataValue;
        }
      },
      updateLeadSourceInCurrentRow(leadSource, row, lead) {
        this.kanbanDataValue.map((d) => {
          if (d.title === lead.stage) {
            d.tasks.map((t) => {
              if (t.id === lead.id) {
                t.lead_details.lead_source_object = leadSource;
                t.lead_source_object = leadSource;
              }
            });
          }
        });
        this.kanbanData = this.kanbanDataValue;
      },
      updateRegionInCurrentRow(region, row, lead) {
        this.kanbanDataValue.map((d) => {
          if (d.title === lead.stage) {
            d.tasks.map((t) => {
              if (t.id === lead.id) {
                t.lead_details.region = region;
                t.region = region;
              }
            });
          }
        });
        this.kanbanData = this.kanbanDataValue;
      },
      updateProbabilityInCurrentRow(Probability, row, lead) {
        this.kanbanDataValue.map((d) => {
          if (d.title === lead.stage) {
            d.tasks.map((t) => {
              if (t.id === lead.id) {
                t.lead_details.Probability = Probability;
                t.Probability = Probability;
              }
            });
          }
        });
        this.kanbanData = this.kanbanDataValue;
      },
      updateTagsInCurrentRow() {},
      updateWorkFlowType(workflow, row, lead) {
        this.kanbanDataValue.map((d) => {
          if (d.stageId === lead?.lead_details?.stage_object) {
            d.tasks.forEach((t) => {
              if (t.lead_id === lead?.lead_details?.id) {
                t.lead_details.workflow_type = workflow||null;
                t.workflow_type = workflow||null;
              }
            });
          }
        });
        this.kanbanData = this.kanbanDataValue;
      },
  
      formateToISO(date) {
        const originalDate = DateTime.fromISO(date);
  
        // Format the date to ISO format
        const formattedDate = originalDate.toISO();
      },
  
      formateDateTime(dateTime) {
        if (
          DateTime.fromISO(dateTime).startOf("day").toISO() ===
          DateTime.local().startOf("day").toISO()
        ) {
          const date = DateTime.fromISO(dateTime);
          const time = date.toLocaleString(DateTime.TIME_SIMPLE);
          return `Today at ${time}`;
        }
  
        if (
          DateTime.fromISO(dateTime).startOf("day").toISO() ===
          DateTime.local().startOf("day").minus({ days: 1 }).toISO()
        ) {
          const date = DateTime.fromISO(dateTime);
          const time = date.toLocaleString(DateTime.TIME_SIMPLE);
          return `Yesterday at ${time}`;
        }
  
        return DateTime.fromISO(dateTime).toFormat("dd/MM/y  hh:mm a");
      },
      dataFormater(data) {
        let datacont = [];
        data.map((d) => {
          d.created_at = this.formateDateTime(d.lead_details.created_at);
          d.color = generateColorFromName(d.lead_details.name);
          d.lead_id = d.lead_details.id;
          d.project_id = d.id;
          d.name = d.lead_details.name;
          d.deal_value = d.email = d.lead_details.email;
          d.phone = d.lead_details.phone;
          d.estimated_system_size = d.lead_details.estimated_system_size;
          d.probability = d.lead_details.probability;
          d.lead_source_object = d.lead_details.lead_source_object;
          d.last_contacted = d.lead_details.last_contacted;
          d.latest_notes = d.lead_details.latest_notes;
          d.owner = d.lead_details.owner;
          d.stage = d.lead_details.stage;
          d.target_closure_date = d.lead_details.target_closure_date;
          d.d2c_products = d.lead_details.d2c_products;
          // d.lead_details.lead_details = d.lead_details;
  
          // d.lead_details.created_at = this.formateDateTime(
          //   d.lead_details.created_at
          // );
          // d.lead_details.color = generateColorFromName(d.lead_details.name);
  
          // d.lead_details.tags = d.tags;
          // d.lead_details.country = d.country;
          // d.lead_details.project_type = d.project_type;
          // d.lead_details.lead_id = d.lead_details.id;
          // d.lead_details.id = d.id;
          // d.lead_details.project_id = d.id;
          // d.lead_details.lead_details.id = d.lead_details.id;
  
          // d.lead_details.activity_logs = d.activity_logs;
          // d.lead_details.address = d.address;
          // d.lead_details.country = d.country;
          // d.lead_details.project_type = d.project_type;
          // d.lead_details.reminder_details = d.reminder_details;
  
          // d.lead_details.zoom = d.zoom;
          datacont.push(d);
        });
        return datacont;
      },
  
      async getPipeline() {
        let response = await API.LEADS.GET_PIPELINES();
        this.iconPipeline = response.data.pipelines;
        if (response.data.pipelines.length) {
          let sortData = response.data.pipelines.sort(
            (a, b) => new Date(a.created_at) - new Date(b.created_at)
          );
          let details = response.data.pipelines.filter(
            (stage) => stage.id == response.data.default_pipeline
          );
  
          const defaultData =
            details.length > 0
              ? response.data.default_pipeline
              : response.data.pipelines[0].id;
          this.iconColors = defaultData;
          this.IconCheck(defaultData);
          this.pipelineDetails = sortData;
          let data = this.pipelineDetails.filter(
            (pipeline) => pipeline.id == defaultData
          );
          this.pipelineData.name = data[0].name;
          this.pipelineData.id = defaultData;
          this.getLeadsdata();
        } else {
          this.emptyPipline = true;
        }

      },
      async getListData() {
        let ui = getUiFromStorage();
        this.kanbanData = [];
  
        let FilterPipeline = this.pipelineDetails.filter(
          (pipeline) => pipeline.id == this.pipelineData.id
        );
  
        this.pipelineData.name =
          FilterPipeline.length > 0 ? FilterPipeline[0].name : "";
        ui.leadManagement.pipeline = this.pipelineDetails;
        ui.leadManagement.selectedPipeline = FilterPipeline;
        let paramsData = {
          order_by: this.sortStrings
            ? this.sortStrings[2] % 2 == 0
              ? "desc"
              : "asc"
            : "",
          sort_by: this.sortStrings ? this.sortStrings[1] : "",
          expected_closure: this.startClosure,
          expected_closure_below: this.endClosure,
          dc_capacity_above: this.lessDC,
          dc_capacity_below: this.moreDC,
          probability_above: this.minProbability,
          probability_below: this.maxProbability,
          system_cost_below: this.costMore,
          system_cost_above: this.costLess,
          tags: this.tagSeperator,
          owner_id_list:this.usersSeperator,
          lead_source_object: this.leadSeperator,
          region: this.regionSeperator,
          pipeline_ids: this.tablePipeline,
        };
        if(this.selectedProducts.length>0){
          paramsData={
            ...paramsData,
            d2c_products: JSON.stringify(this.selectedProducts),
          }
        }
        if (this.selectedFilter && this.selectedFilter.length > 0) {
          this.selectedFilter.map((d) => {
            if(d.length === 6 && d[1] === 'time_period' && d[3] === 'Custom'){
              paramsData[d[1]] = d[3];
              paramsData[d[1] + "_start_date"] = d[4].toISOString();
              
              const endDate = new Date(d[5]);
              endDate.setHours(23, 59, 59, 999);
              paramsData[d[1] + "_end_date"] = endDate.toISOString();
            }else{
            paramsData[d[1]] = d ? d[2] : "";
            }
          });
        }
  
        if (this.$props.drillDownUrl?.param) {
          paramsData[this.$props.drillDownUrl.param] = this.selectedFilter[0]
            ? this.selectedFilter[0][2]
            : this.$props.drillDownUrl.value;
        }
        if (this.$props.widgetId) {
          paramsData.widget = this.$props.widgetId;
        }
        if (this.searchVal) {
          paramsData.query = this.searchVal;
        }
        if(this.proposalStatus){
          paramsData.d2c_proposal_status = this.proposalStatus
        }
  
        ui.leadManagement.filterListParams = new URLSearchParams(
          paramsData
        ).toString();
        this.listQueryParams = "";
        this.listQueryParams = new URLSearchParams(paramsData).toString();
  
        setUiInStorage(ui);
      },

      async getKanbanData() {
        let ui = getUiFromStorage();

        let FilterPipeline = this.pipelineDetails.find(
          (pipeline) => pipeline.id == this.pipelineData.id
        );
        this.pipelineData.name = FilterPipeline?.name;
        ui.leadManagement.pipeline = this.pipelineDetails;
        ui.leadManagement.selectedPipeline = FilterPipeline
          ? FilterPipeline
          : [];
        let FilterStages = FilterPipeline
          ? FilterPipeline.pipeline_stages
          : [];
  
        let stages = FilterStages.map((stage) => stage.id);
  
        let kanbanColumns = FilterStages;
  
        // let sortData = ui.leadManagement.selectedSort;
  
        // let leadsSource = ui.leadManagement.leadSource?.value || "";
        // let dcless = ui.leadManagement.DCvalue
        //   ? ui.leadManagement.DCvalue.value[0]
        //   : "";
        // let dcmore = ui.leadManagement.DCvalue
        //   ? ui.leadManagement.DCvalue?.value[1]
        //   : "";
  
        // let expectedCloseure = ui.leadManagement.ExpectedClosure
        //   ? ui.leadManagement.ExpectedClosure.value[0]
        //   : "";
        // let expectedCloseureend = ui.leadManagement.ExpectedClosure
        //   ? ui.leadManagement.ExpectedClosure.value[1]
        //   : "";
  
        // let systemCostless = ui.leadManagement.systemCost
        //   ? ui.leadManagement.systemCost.value[0]
        //   : "";
        // let systemCostmore = ui.leadManagement.systemCost
        //   ? ui.leadManagement.systemCost.value[1]
        //   : "";
  
        // let tags = ui.leadManagement.tags ? ui.leadManagement.tags.value : "";
        // let selectedFilters = ui.leadManagement.selectedFilter
        //   ? ui.leadManagement.selectedFilter
        //   : "";
        // let filterString = ui.leadManagement.filterStrings;
  
        this.kanbanData = [];
        let reqObj = "";
  
        kanbanColumns.map(async (dt, index) => {
          
          // reqObj = `stage=${dt}&view=kanban&order_by=${
          //   sortData ? sortData[2] : ""
          // }&sort_by=${
          //   sortData ? sortData[1] : ""
          // }&expected_closure=${expectedCloseure}&expected_closure_before=${expectedCloseureend}&dc_capacity_above=${dcmore}&dc_capacity_below=${dcless}&system_cost_below=${systemCostless}&system_cost_above=${systemCostmore}&tags=${tags}&lead_source=${leadsSource}`;
  
          // let listParams = `order_by=${sortData ? sortData[2] : ""}&sort_by=${
          //   sortData ? sortData[1] : ""
          // }&expected_closure=${expectedCloseure}&expected_closure_before=${expectedCloseureend}&dc_capacity_above=${dcmore}&dc_capacity_below=${dcless}&system_cost_below=${systemCostless}&system_cost_above=${systemCostmore}&tags=${tags}&lead_source=${leadsSource}`;
  
          let paramsData = {
            order_by: this.sortStrings
              ? this.sortStrings[2] % 2 == 0
                ? "desc"
                : "asc"
              : "",
            sort_by: this.sortStrings ? this.sortStrings[1] : "",
            expected_closure: this.startClosure,
            expected_closure_below: this.endClosure,
            dc_capacity_above: this.lessDC,
            dc_capacity_below: this.moreDC,
            probability_above: this.minProbability,
            probability_below: this.maxProbability,
            system_cost_below: this.costMore,
            system_cost_above: this.costLess,
            tags: this.tagSeperator,
            owner_id_list:this.usersSeperator,
            lead_source_object: this.leadSeperator,
            region: this.regionSeperator,
            pipeline_id: this.pipelineData?.id || "",
            d2c_proposal_status: this.proposalStatus,
          };
          if(this.selectedProducts.length>0){
            paramsData={
              ...paramsData,
              d2c_products: JSON.stringify(this.selectedProducts),
            }
          }
          
  
          // reqObj = `stage=${dt}&view=kanban&order_by=${
          //   this.sortStrings
          //     ? this.sortStrings[2] % 2 == 0
          //       ? "desc"
          //       : "asc"
          //     : ""
          // }&sort_by=${
          //   this.sortStrings ? this.sortStrings[1] : ""
          // }&expected_closure=${this.startClosure}&expected_closure_below=${
          //   this.endClosure
          // }&dc_capacity_above=${this.lessDC}&dc_capacity_below=${
          //   this.moreDC
          // }&system_cost_below=${this.costMore}&system_cost_above=${
          //   this.costLess
          // }&tags=${this.tagSeperator}&lead_source=${this.leadSeperator}${
          //   Environment ? "&pipeline_name=default" : ""
          // }`;
  
          let listParams = `order_by=${
            this.sortStrings
              ? this.sortStrings[2] % 2 == 0
                ? "desc"
                : "asc"
              : ""
          }&sort_by=${
            this.sortStrings ? this.sortStrings[1] : ""
          }&expected_closure=${this.startClosure}&expected_closure_below=${
            this.endClosure
          }&dc_capacity_above=${this.lessDC}&dc_capacity_below=${
            this.moreDC
          }&probability_above=${this.minProbability}&probability_below=${
            this.maxProbability
          }&system_cost_below=${this.costMore}&system_cost_above=${
            this.costLess
          }&tags=${this.tagSeperator}$owner_id_list=${this.usersSeperator}&lead_source_object=${
            this.leadSeperator
          }&region=${this.regionSeperator}&d2c_proposal_status=${this.proposalStatus}`;
  
          // if (selectedFilters) {
  
          //   // reqObj += `&${selectedFilters}`;
          //   // listParams += `&${selectedFilters}`;
          // }
          // if (this.filtersString) {
  
          // reqObj += `&${this.filtersString}`;
          // listParams += `&${this.filtersString}`;
          // }
  
          if (this.selectedFilter && this.selectedFilter.length > 0) {
            this.selectedFilter.map((d) => {
              if(d.length === 6 && d[1] === 'time_period' && d[3] === 'Custom'){
                paramsData[d[1]] = d[3];
                paramsData[d[1] + "_start_date"] = d[4].toISOString();
                const endDate = new Date(d[5]);
                endDate.setHours(23, 59, 59, 999);
                paramsData[d[1] + "_end_date"] = endDate.toISOString();
              }else{
              paramsData[d[1]] = d ? d[2] : "";
              }
            });
          }
  
          if (this.searchVal) {
            paramsData.query = this.searchVal;
            // reqObj += `&query=${this.searchVal}`;
            // listParams += `&query=${this.searchVal}`;
          }
          const kanbanParams = { ...paramsData };
          this.queryStringsFilters=queryParamsFormater(kanbanParams);
          kanbanParams.stage_object_id = dt.id;
          kanbanParams.view = "kanban";
          ui.leadManagement.filterKanbanParams = new URLSearchParams(
            kanbanParams
          ).toString();
          // ui.leadManagement.filterListParams = new URLSearchParams(
          //   paramsData
          // ).toString();
  
          setUiInStorage(ui);
          const qParamFormate=    queryParamsFormater(kanbanParams);
          let result = await API.LEADS.FETCH_LEAD_LIST(
            qParamFormate
          );
          this.leadCount += result.data.count || 0;
          let dataValue = result?.data.results;
          let count = result?.data.count || 0;
  
          let newData = {
            sequence: index,
            count: count,
            title: dt.name,
            stageId:dt.id,
            tasks: dataValue.length > 0 ? this.dataFormater(dataValue) : [],
            next: result ? result.data.next : "",
            previous: result ? result.data.previous : "",
            sumOfDealvalues:0,
          };
  
          this.kanbanData.push(newData);
          if(kanbanColumns.length-1==index){
            this.getSumOfDealValueByStages();

          }
          
        });
    

        this.isKanbanLoading = false;

      },
  
      applySelectedColumns() {
        let ui = getUiFromStorage();
        if (this.$props.isDrilldown) {
          ui.leadManagement.drillDownSelectedColumns = [
            ...this.checkedColumnsList,
          ];
        } else {
          ui.leadManagement.designerViewTable = [...this.checkedColumnsList];
          ui.leadManagement.tableSelectedColumns = [...this.checkedColumnsList];
        }
        setUiInStorage(ui);
        this.checkedColumnsListToSend = [...this.checkedColumnsList];
        this.showCustomizeTable = false;
      },
  
      async handleChange(value, columns, previousColumn) {
        this.currencySymbol = getCurrencySymbol(
          value.country ? value.country.country_code : this.countryCode
        );
        this.previousValue = previousColumn;
        this.dragValue = value;
        this.dragColumn = columns;
        if (!value) return;
        let leadData = value;
        let id = leadData.id;

        let i = 0,
          newStatus;
        while (i < columns.length) {
          let status = columns[i].tasks.find((e) => e.id === id);
          if (status) {
            newStatus = columns[i].title;
            let pipelineData = JSON.parse(localStorage.getItem("ui"))
              .leadManagement.selectedPipeline[0];
            let stageID = pipelineData?.pipeline_stages.filter(
              (stage) => stage.name == newStatus
            );
            if (
              stageID[0].close_stage_type == "won" &&
              value.lead_details.deal_value <= 0 &&
              this.isProceed == false
            ) {
              this.dealCheck = true;
            } else {
              this.order = value;
              this.order.stage = newStatus;
              this.order.lead_details.stage = newStatus;
              this.order.lead_details.stage_object = stageID[0].id;
  
              const postData = {
                stage_object: stageID[0].id,
                pipeline: pipelineData.id,
              };
              try {
                let res = await API.LEADS.UPDATE_LEAD(
                  leadData.lead_id,
                  leadData.project_id,
                  postData
                );
                this.getSumOfDealValueByStages();
                return res;
              } catch (err) {}
            }
          }
          i++;
        }
      },
      handleCreate(data) {
        this.addLeadBtnClicked = false
        if (data.title) {
          this.drawerStage = data.title;
        } else {
          this.drawerStage = "";
        }

        // Setting the default selected pipeline in the drawer
        if (this.isTableOptClicked) {
          this.pipelineIdForCreateLeadDrawer = this.pipelineDetails[0].id
        } else {
          this.pipelineIdForCreateLeadDrawer = this.pipelineData.id
        }

        this.createKey++;
        this.componentName = "createLead";
        this.drawerSize = 700;
        this.drawer = true;
      },
      handleOnClick(data) {
        this.leadData = data;
        this.leadDrawer = true;
      },
      async loadMoreData(col) {
        const response = await API.DESIGN_ORDERS.LOAD_MORE_Leads(col.next);
        this.kanbanData.map((dt) => {
          if (dt.title === col.title) {
            dt.tasks.push(...this.dataFormater(response.data.results));
            dt.next = response.data.next;
          }
        });
      },
      searchAllLeads(query) {
        if (query !== ""&&query) {
          this.isLoading = true;
          setTimeout(() => {
            this.searchAllLeadsHelper(query);
          }, 200);
        } else {
          // fetching all leads
          this.allLeadsList = [];
          this.isLoading = true;
          this.searchVal = "";
          this.getLeadsdata();
        }
      },
      async searchAllLeadsHelper(query) {
        try {
          this.isSearchedLeadEmpty = true;
          // this.isLoading = true;
          this.isSearchingLeads = true;
          this.searchVal = query;
          this.getLeadsdata();
          const response = await API.LEADS.SEARCH_ALL_LEADS_KANBAN(query, "Lead");
          // this.allLeadsList = [];
          // this.assignAPIResponse(response);
          // this.isLoading = false;
  
          // if (this.allLeadsList.lenght > 0) this.isSearchedLeadEmpty = false;
        } catch (error) {
          console.error(error);
        }
      },
      setUiOptions() {
        let ui = getUiFromStorage();
        this.isTableOptClicked = ui?.leadManagement?.isTableView == true;
      },
      setLeadListingView(isTable) {
        this.isTableOptClicked = isTable;
        let ui = getUiFromStorage();
        ui.leadManagement.isTableView = isTable;
        setUiInStorage(ui);
      },
      onClickSearch() {
        this.showSearchInput = this.isMobileView;
        setTimeout(() => {
          this.$refs.searchInput.focus()
        }, 200);
      },
      updateAllLeadsData(leadData){
        this.kanbanDataValue.map((d) => {
          
          if (d.stageId === leadData.lead_details.stage_object) {
         d.tasks=d.tasks.filter(ts=>ts.id!==leadData.id)
          }
        });

        this.kanbanData = this.kanbanDataValue;
        this.leadDrawer=false
        

      },
      async getSumOfDealValueByStages(){
if(this.pipelineData?.id>0&&this.kanbanData&&!this.isTableOptClicked&&isSOMUsers())    {
  let result = await API.LEADS.GET_SUM_OF_DEAL_BY_STAGE(this.pipelineData?.id,this.queryStringsFilters);
  const sumOfDealvalues=result?.data;
  this.kanbanData.forEach(d=>{
    const stageData=sumOfDealvalues.find(sd=>sd.stage_object==d.stageId);
    if(stageData){
      d.sumOfDealvalues=stageData.total_deal_value;
    }else{
      d.sumOfDealvalues=0;
    }
  })
}
  },
    },
  };
  </script>
  
  <style scoped>
  .checkBox {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 2%;
  }
  
  .checkBox .el-checkbox {
    padding: 5%;
  }
  
  .updateButton {
    background-color: #1c191c2e;
    border: none;
    color: white;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    float: right;
    margin-right: 12px;
  }
  
  .labelText {
    margin-right: 10px;
  }
  
  .el-menu-item {
    color: #222 !important;
  }
  .container-dash {
    padding: 12px;
  }
  @media (min-width: 1281px) {
    .container {
      width: calc(100% - 74px);
      margin-left: auto;
      padding: 24px 30px;
      height: calc(100vh - 101px);
      background-color: #e8edf2;
    }
  }
  
  @media (max-width: 1280px) {
    .container {
      padding: 24px;
      background-color: #e8edf2;
      height: 94vh;
      overflow: hidden;
      overflow-y: scroll;
    }
  }
  
  @media (max-width: 768px) {
    .container {
      padding: 12px;
      background-color: #e8edf2;
      height: 94vh;
      overflow: hidden;
      overflow-y: scroll;
    }
    .create-container-mobile{
      position: fixed;
      bottom: 20px;
      background-color: #409EFF;
      width: 48px;
      height: 48px;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      z-index: 1;
    }
    .create-icon-mobile{
      color: white;
      font-size: 16px;
    }
    .tableOpt {
      border-right: none;
      border-radius: 2px 0px 0px 2px !important;
    }
    .cardOpt{
      background-color: white;
      border: none !important;
      border-radius: 0  !important;
      border-right: 1px solid #CCCCCC !important;
    }
    .search-container{
      position: relative;
      padding-left: 8px;
    }
    .gridContainer{
      gap: 12px !important;
    }
    .pipeline >>> .el-submenu__title{
      padding: 0 24px 0 8px;
    }
    .pipeline >>> .el-menu--horizontal > .el-submenu .el-submenu__icon-arrow{
      position: relative !important;
      right: -10px !important;
      top: 0;
    }
    .pipeline-select-mobile{
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .firstContainer{
      gap: 6px !important;
    }
    .secContainer{
      gap: 0 !important;
      grid-template-columns: auto auto !important;
    }
    .searchInpCont{
      grid-column: 2 / 3 !important;
      grid-row: 1 / 2;
      max-width: min-content !important;
    }
    .searchInpCont, .searchInput{
      height: 40px !important;
    }
    .tableTypeCont{
      grid-column: 1 / 2 !important;
    }
    .searchInput{
      width: 0% !important;
      border-radius: 0px 2px 2px 0px !important;
    }
    .searchIcon, .searchInput{
      cursor: pointer;
    }
    .searchInput-active{
      width: 100% !important;
      padding-left: 12px !important;
      padding-right: 40px !important;
      margin-bottom: 12px;
    }
    .searchInput-close-btn{
      position: absolute;
      right: 10px;
      top: 14px;
      cursor: pointer;
    }
    .pipeline-cb-mobile >>> .el-checkbox__label, .pipeline-span-mobile {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 200px !important;
    }

    .dropdownBtn {
      display: none !important;
    }
  }
  </style>
  
  <style scoped>
  .cardOpt .typeIcon, .tableOpt{
    font-size: 20px;
  }
  .activeClass{
    color: white !important;
    background-color: #1C3366 !important;
  }
  .gridContainer {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
  }
  
  .firstContainer,
  .secContainer {
    display: grid;
    grid-template-columns: auto auto auto;
    align-items: center;
    gap: 24px;
  }
  
  .commonPtag {
    font-size: 16px;
    font-weight: 600;
    color: #222;
    margin-left: -8px;
  }
  
  .filterCont {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  
  .relativeCont {
    position: relative;
  }
  
  .customize {
    font-size: 16px;
    font-weight: 600;
    color: #409eff;
    cursor: pointer;
  }
  
  .customizeDropdown {
    position: absolute;
    top: 24px;
    width: 300px;
    background-color: #fff;
    border-radius: 8px;
    padding: 16px;
    z-index: 1;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  }
  
  .drpDwnHeading {
    font-size: 16px;
    font-weight: 600;
    color: #1c3366;
  }
  
  .drpdwnFlex >>> .el-checkbox-group {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;
    overflow: hidden;
    overflow-y: scroll;
    height: 250px;
  }
  
  .applyBtnContainer {
    text-align: center;
    margin-top: 8px;
  }
  
  .searchInpCont {
    position: relative;
    max-width: 320px;
  }
  
  .searchInput {
    width: 100%;
    font-size: 18px;
    padding-left: 36px;
    height: 42px;
    border-radius: 4px;
    border: none;
  }
  
  .pipeline >>> .el-select .el-input.is-focus .el-input__inner {
    border-color: transparent;
  }
  
  .pipeline >>> .el-input {
    font-weight: 500;
    font-size: 16px;
    border: none;
  }
  
  .pipeline >>> .el-input.el-input--suffix .el-input__inner {
    padding-right: 30px;
    border: none;
    color: #222;
  }
  
  .pipeline >>> .el-select .el-input.is-focus .el-input__inner :hover {
    border-color: transparent;
  }
  
  .pipeline >>> .el-select .el-input .el-select__caret {
    color: #222222 !important;
    font-weight: 600;
    position: relative;
    font-size: 18px !important;
  }
  
  .el-select-dropdown__item.selected {
    color: #222222 !important;
    font-weight: normal !important;
  }
  
  .el-select-dropdown__item {
    font-size: 14px;
    padding: 0 20px;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #222222 !important;
    height: 34px;
    line-height: 34px;
    box-sizing: border-box;
    cursor: pointer;
  }
  
  .searchIcon {
    position: absolute;
    top: 12px;
    left: 8px;
  }
  
  .checkbox-dropdown {
    padding: 10px;
  }
  .tag-value{
      background-color: white;
      margin-top: 4px;
      color: black;
      border-radius: 50%;
      display: inline-block;
      line-height: 18px;
      width: 18px;
      font-size: small;
      margin-left: 2px;
  }
  .filterTag{
    padding: 2px 0px;
  }
  .buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
  
  .tableTypeCont {
    display: flex;
    align-items: center;
  }
  
  .tableOpt,
  .cardOpt {
    display: grid;
    place-items: center;
    height: 40px;
    width: 40px;
    cursor: pointer;
    border-style: solid;
    border-color: #999999;
  }
  .cardOpt{
    border-width: 1px 1px 1px 0px;
    border-radius: 0px 4px 4px 0px;
  }
  
  .tableOpt {
    border-width: 1px 0px 1px 1px;
    border-radius: 4px 0px 0px 4px;
  }
  
  .createLeadBtn {
    width: 123px;
    height: 40px;
    font-size: 16px;
    font-weight: 600;
    padding: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  
  .el-menu--horizontal >>> .el-submenu .el-submenu__title {
    height: 40px;
    line-height: 40px;
    border-bottom: 2px solid transparent;
    color: #222 !important;
    font-size: 16px;
    font-weight: 600;
    /* width: 120px; */
  }
  
  .pipelineMenu >>> .el-submenu__icon-arrow {
    position: absolute;
    top: 50%;
    right: 20px;
    color: #222222;
    margin-top: -7px;
    transition: transform 0.3s;
    font-size: 18px;
    font-weight: bold;
  }
  .pipeline >>> .el-submenu__icon-arrow {
    position: absolute;
    top: 50%;
    right: 20px;
    color: #222222;
    margin-top: -7px;
    transition: transform 0.3s;
    font-size: 18px;
    font-weight: bold;
  }
  .el-checkbox >>> .el-checkbox__label {
    display: inline-block;
    padding-left: 10px;
    color: #222222;
    font-weight: 500;
    line-height: 19px;
    font-size: 16px;
  }
  
  .el-menu {
    /* min-width: 120px; */
    color: #222 !important;
  }
  
  .el-menu--horizontal >>> .el-menu--popup {
    z-index: 100;
    min-width: 120px;
    border: none;
    padding: 5px 0;
    border-radius: 2px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  }
  
  .el-menu--horizontal >>> .el-submenu.is-active .el-submenu__title {
    border-bottom: transparent;
    color: #222;
    height: 40px;
    line-height: 40px;
    font-weight: 600;
  }
  
  .el-menu--horizontal .el-menu .el-menu-item {
    /* background-color: red; */
    float: none;
    height: 36px;
    /* width: 30px; */
    line-height: 36px;
    padding: 0 10px;
    color: #222 !important;
  }
  
  .el-checkbox >>> .el-checkbox__inner {
    display: inline-block;
    position: relative;
    border: 1px solid #222;
    border-radius: 2px;
    box-sizing: border-box;
    width: 14px;
    height: 14px;
    background-color: #ffffff;
    z-index: 1;
    transition: border-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46),
      background-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46);
  }
  
  .relativeCont >>> .el-checkbox {
    display: flex;
    align-items: center;
    gap: 6px;
    display: flex;
    align-items: center;
    font-size: 16px;
  }
  
  .relativeCont >>> .el-checkbox__label {
    font-size: 16px;
    color: #222;
  }
  
  .relativeCont >>> .el-checkbox__inner {
    width: 18px;
    height: 18px;
    border: 1px solid #777;
  }
  
  .filterCont >>> .el-select {
    width: 100%;
    max-width: 150px;
  }
  
  .filterCont >>> .el-input__inner {
    border: none;
  }
  
  .filterCont >>> .el-input__inner::placeholder {
    font-size: 16px;
    font-weight: 600;
    color: #222;
  }
  
  .filterCont >>> .el-select .el-input .el-select__caret {
    color: #222;
    font-weight: bold;
    position: relative;
    font-size: 18px;
  }
  
  .filterCont >>> .thirdSelect {
    width: 100%;
    max-width: 190px;
  }
  
  .navContainer >>> .el-checkbox__inner::after {
    border: 2px solid #ffffff;
    border-left: 0;
    border-top: 0;
    height: 8px;
    left: 5px;
  }
  
  .tagsContainer {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .activeFilters {
    display: flex;
    margin: 15px auto;
  }
  
  .actFil,
  .clrAll {
    font-size: 16px;
    color: #777;
    padding-right: 8px;
    margin: auto 0;
  }
  
  .clrAll {
    text-align: center;
    padding-left: 8px;
    cursor: pointer;
  }
  
  .tags {
    padding: 4px 12px;
    border-radius: 50px;
    background-color: #1c3366;
    color: #fff;
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .crossIcon {
    cursor: pointer;
  }
  
  .el-tag {
    color: #ffffff;
    background-color: #1c3366;
    height: 35px;
    font-size: 16px;
    font-weight: 400;
    border-radius: 50px;
    text-align: center;
    position: relative;
    padding-right: 33px;
  }
  .el-tag--medium {
    line-height: 30px;
  }
  .el-tag >>> .el-icon-close {
    position: absolute;
    right: 13px;
    top: 8.5px;
    color: #ffffff;
    font-size: 16px;
  }
  
  .el-tag >>> .el-icon-close::before {
    color: #ffffff;
    font-size: 16px;
  }

  .flexBtnContainer {
  display: flex;
  align-items: center;
  gap: 1px;
}

.dropdownBtn {
  position: relative;
  display: grid;
  place-items: center;
  width: 40px;
  height: 40px;
  background-color: #409eff;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border: none;
  cursor: pointer;
}

.dropdownBtn:hover {
  background-color: #66b1ff;
}

.disabledDropdownBtn {
  background-color: #a0cfff;
  cursor: not-allowed;
}

.dropdownBtnOption {
  position: absolute;
  top: 44px;
  right: 0px;
  width: 164px;
  height: 40px;
  background-color: #fff;
  border-radius: 4px;
  display: grid;
  place-items: center;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
}

.bottomPopupOverlay {
  position: fixed;
  bottom: 0px;
  right: 0px;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
}

.positionR {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.closeIcon {
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 1;
  cursor: pointer;
}

.bottomPopupForLeadOptions {
  position: relative;
  padding: 32px 16px 24px 16px;
  display: grid;
  gap: 20px;
  border-radius: 16px 16px 0px 0px;
  background-color: #fff;
}

.font16Bold,
.importLeadBtnMD {
  font-size: 16px;
  font-weight: bold;
  color: #222;
}

.importLeadBtnMD {
  border: none;
  background-color: #fff;
  text-align: left;
  padding: 0px;
}

  </style>
  
  <style>
  ::-webkit-scrollbar {
    width: 5px;
    border-radius: 4px;
    height: 6px;
  }
  .secContainer-dash {
    display: flex;
    justify-content: end;
    align-items: center;
  }
  </style>
  