
	<div id="textEditorContainer" :class="[{customPlaceholderMode:customPlaceholderMode}]">
        <div id="editor" name="editor" class="editor">
        </div>
		<el-checkbox
			v-if="!customPlaceholderMode"
			class="addPSClass" 
			size="medium"
			v-model="includePaymentScheduleData"
		>
			Add project schedule page in contract
		</el-checkbox>
	</div>
		
