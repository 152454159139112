import axios from "axios";

export default {
    GET_ALL_PIPELINES() {
        return axios.get(`api/pipeline/`);
    },
    GET_ACTIVE_STAGES(id) {
        return axios.get(`api/pipeline/${id}/active_stages/`);
    },
    ADD_STAGES(id, postData) {
        return axios.post(`api/pipeline/${id}/stage/`, postData);
    },
    UPDATE_PIPELINE_NAME(id, postData) {
        return axios.patch(`api/pipeline/${id}/`, postData);
    },
    CREATE_PIPELINE(postData) {
        return axios.post(`api/pipeline/`, postData);
    },
    DEFAULT_PIPELINE(id){
        return axios.post(`api/pipeline/${id}/default_pipeline/`);
    },
    UPDATE_STAGES(id,stageID,postData) {
        return axios.patch(`api/pipeline/${id}/stage/${stageID}/`, postData);
    },
    DELETE_STAGE(id,stageID,postData) {
        return axios.delete(`api/pipeline/${id}/stage/${stageID}/`, {data:postData});
    },
    DELETE_PIPELINE(id,postData) {
        return axios.delete(`api/pipeline/${id}/`, {data:postData});
    },

};