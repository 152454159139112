import PowerRoof from "../objects/model/powerRoof/PowerRoof";
import { SmartroofModel } from "../objects/model/smartroof/SmartroofModel";
import * as raycastingUtils from '../utils/raycastingUtils';
import { checkPolygonInsidePolygon, checkPolygonIntersection } from "../utils/utils";
import * as notificationsAssistant from '../../componentManager/notificationsAssistant';


export default class PowerRoofCreationMode {
    constructor(stage) {
        this.stage = stage;
        this.canvas = stage.rendererManager.getDomElement();
        this.mainSmartroof = null;
        this.mouseMovesIndex = null;
        this.highlightedRoof = null;
        this.selectedRoofs = [];
        this.onClickFunc = this.onClick.bind(this);
    }

    initialize(mainRoof) {
        //just a fallback
        if (mainRoof.connectedPowerRoof) {
            this.stage.stateManager.stopContainer();
            return;
        }

        this.mainSmartroof = mainRoof;
        this.highlightedRoof = null;

        //visuals
        this.mainSmartroof.deSelect();
        this.mainSmartroof.onSelectWhilePoweRoofCreation();
        this.stage.visualManager.updateVisualsForPowerRoofCreationMode(true);
        this.setButtonStatus();

        //event handlers
        this.stage.eventHandler.addEvent(this.onMouseMove.bind(this));
        this.mouseMovesIndex = this.stage.eventHandler.getIndex();
        this.canvas.addEventListener("click", this.onClickFunc);

        //Disable managers
        this.stage.dragControls.disable();
        this.stage.duplicateManager.disable();
        this.stage.selectionControls.disable();
    }

    async onComplete() {
        //Create a new object of powerroof class with all the smartroofs
        const newPowerRoof = new PowerRoof(this.stage, [
            ...this.selectedRoofs,
            this.mainSmartroof,
        ]);
        newPowerRoof.updateHeatmapValues();
        this.stage.ground.powerRoofs.push(newPowerRoof);
        this.selectedRoofs.forEach((roof) => {
            roof.connectedPowerRoof = newPowerRoof;
            roof.saveState();
        });
        this.mainSmartroof.connectedPowerRoof = newPowerRoof;
        this.mainSmartroof.saveState();
        //select new powerRoof after creation
        this.stage.selectionControls.setSelectedObject(newPowerRoof);
        newPowerRoof.saveState();
        this.stage.stateManager.stopContainer();
        await this.exitPowerRoofCreationMode(true);
        return Promise.resolve(true);
    }

    async exitPowerRoofCreationMode(afterCompletion = false) {
        //reset visuals
        this.stage.visualManager.updateVisualsForPowerRoofCreationMode(false);
        this.highlightedRoof
            ? this.highlightedRoof.unHighlightForPowerRoof()
            : null;
        this.highlightedRoof = null;
        this.selectedRoofs.forEach((roof) => {
            roof.deSelectWhilePowerRoofCreation();
        });
        this.mainSmartroof
            ? this.mainSmartroof.deSelectWhilePowerRoofCreation()
            : null;
        if (!afterCompletion) this.mainSmartroof.onSelect();
        this.mainSmartroof = null;
        this.selectedRoofs = [];

        //event handlers
        this.stage.eventHandler.removeEvent(this.mouseMovesIndex);
        this.canvas.removeEventListener("click", this.onClickFunc);

        //enable managers
        this.stage.dragControls.enable();
        this.stage.duplicateManager.enable();
        this.stage.selectionControls.enable();

        this.stage.stateManager.stopContainer();
        return Promise.resolve(true);
    }

    onClick(event) {
        const objectMeshes = this.stage.mergeManager.getAllMeshesInScene();
        let topObject = raycastingUtils.getTopObjectOnClick(
            event,
            this.stage,
            objectMeshes
        );
        if (
            topObject instanceof SmartroofModel &&
            topObject !== this.mainSmartroof &&
            topObject.connectedPowerRoof == null
        ) {
            if (!this.selectedRoofs.includes(topObject)) {
                if (!this.isValidRoof(topObject)) {
                    notificationsAssistant.warning({
                        title: 'Invalid Selection',
                        message: 'Only inter-connected roofs can be selected for power roof creation.',
                    });
                    return;
                }
                this.selectedRoofs.push(topObject);
                topObject.onSelectWhilePoweRoofCreation();
            } else {
                this.selectedRoofs.splice(
                    this.selectedRoofs.indexOf(topObject),
                    1
                );
                topObject.deSelectWhilePowerRoofCreation();
            }
        }
    }

    isValidRoof(roof) {
        const roofVertices = roof.get2DVertices();
        const intersects = [this.mainSmartroof,...this.selectedRoofs].some((selectedRoof) => {
            const selectedRoofVertices = selectedRoof.get2DVertices();
            const intersection = checkPolygonIntersection(
                selectedRoofVertices,
                roofVertices
            );
            const inside1 = checkPolygonInsidePolygon(
                roofVertices,
                selectedRoofVertices
            );
            const inside2 = checkPolygonInsidePolygon(
                selectedRoofVertices,
                roofVertices
            );
            return intersection || inside1 || inside2;
        });
        return intersects;
    } 

    onMouseMove = (event) => {
        setTimeout(this.handleHover.bind(this, event), 20);
    };

    setButtonStatus() {
        this.stage.eventManager.setButtonStatusWhilePowerRoofCreation(
            this.onComplete.bind(this),
            this.exitPowerRoofCreationMode.bind(this),
            this
        );
    }

    handleHover() {
        const mousePoint = this.stage.mousePoint;
        const objectMeshes = this.stage.ground
            .getChildren()
            .filter((obj) => obj instanceof SmartroofModel)
            .map((roofs) => roofs.mesh);
        const smartroofs = raycastingUtils.getAllObjectsBelowPoint(
            mousePoint,
            this.stage,
            null,
            objectMeshes
        );
        let topObject = smartroofs.length > 0 ? smartroofs[0][0] : null;
        if (topObject === null) {
            this.unHighlightForPowerRoof();
            return null;
        }
        if (
            topObject instanceof SmartroofModel &&
            topObject !== this.mainSmartroof &&
            topObject.connectedPowerRoof == null
        ) {
            if (!this.selectedRoofs.includes(topObject)) {
                if (topObject !== this.highlightedRoof) {
                    this.unHighlightForPowerRoof();
                    topObject.highlightOnHoverForPowerRoof();
                    this.highlightedRoof = topObject;
                }
            } else {
                this.unHighlightForPowerRoof();
            }
        } else {
            this.unHighlightForPowerRoof();
        }

        return null;
    }
    unHighlightForPowerRoof() {
        if (this.highlightedRoof) {
            this.highlightedRoof.unHighlightForPowerRoof();
            if (this.selectedRoofs.includes(this.highlightedRoof))
                this.highlightedRoof.onSelectWhilePoweRoofCreation();
            this.highlightedRoof = null;
        }
    }
}
