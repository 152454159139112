
<section :class="['right_section', isCrmUser() ? 'right_sectionCRM' : '', isDragEnabled ? 'disable-text-selection' : '']">
    <div class="content_section">
        <div class="filter_section">
            <p class="backBtn" @click="handleBack()">{{backAction}}</p>
            <div class="flexCont">
                <div class="btnFlexCont" v-show="!isEditTemplateMode && !isPreviewMode">
                    <el-button class="previewBtn btn" @click="handleCancel">Cancel</el-button>
                    <el-tooltip :disabled="enableSave">
                        <template v-slot:content>
                            <div>Please wait for the template files to load</div>
                        </template>
                        <el-button class="saveBtn btn" type="primary" :disabled="!enableSave" @click="createTemplate">Save</el-button>
                    </el-tooltip>
                </div>
                <el-button class="previewBtn btn" @click="openLinkToPreview" v-if="isPreviewMode || isEditTemplateMode">Preview</el-button>
                <el-button :class="[formData.is_default || !canUserEdit() ? 'hidden-icon' : '', 'saveBtn btn']" type="primary" @click="openEditMode" v-if="isPreviewMode">Edit Form</el-button>
            </div>
        </div>
        <div class="box-card">
            <div class="custom-scroll" 
                :style="{height: `${scrollThumbHeight}px`, 
                top: `${scrollTopValue}px`}"
                @mouseup="endDrag"
                @mousemove="handleDrag"
                @mouseleave="isDragEnabled = false"
                v-if="showCustomScroll"
            >
                <div @mousedown="startDrag" class="custom-scroll-thumb">
                </div>
            </div>
            <div class="bodyContainer" @scroll="handleScroll" ref="scrollRef">
                <div class="flexContTwo" v-if="!isPreviewMode">
                    <div class="inputsCont">
                        <div class="flexColumn">
                            <label for="" class="labelClass">Form Title</label>
                            <el-input placeholder="Form Title" v-model="formData.title" @change="prepareFormObjectToStore('title')"></el-input>
                        </div>
                        <div class="flexColumn">
                            <label for="" class="labelClass">Form Description</label>
                            <el-input class="placeholderPadding" type="textarea" :rows="3" placeholder="Form Description" v-model="formData.description" @change="prepareFormObjectToStore('description')"></el-input>
                        </div>
                    </div>
                </div>
                <div class="previewContainer" v-else>
                    <div class="flexContPV">
                        <p class="heading">{{formData.title}}</p>
                    </div>
                    <hr class="lineHR"/>
                    <p class="description">{{filteredDescription}}
                    </p>
                </div>
                <div class="sectionCards">
                        <div v-for="(section, index) in formData.sections" :key="index"  style="width: 100%">
                            <SectionCard 
                            :sectionIndex="index +1" 
                            :sectionsLength="formData.sections.length"
                            :section="section"
                            :templateId="templateId"
                            @addSection="addSection" 
                            @deleteCurrentSection="deleteCurrentSection"
                            @duplicateCurrentSection="duplicateCurrentSection"
                            @addFieldToSection="addFieldToSection"
                            @deleteCurrentField="deleteCurrentField"
                            @duplicateCurrentField="duplicateCurrentField"
                            @duplicateCurrentFile="duplicateCurrentFile"
                            @deleteCurrentFile="deleteCurrentFile"
                            @patchTemplate="propagatePatchDataFromDescendants"
                            @checkScrollThumb="checkScrollThumb"
                            ref="sectionsRef" 
                            />   
                        </div>
                </div>
            </div>
        </div>
    </div>
    <DeleteDialog :dialogVisible="openCancelChangesDialog" :footerButtonType="'primary'" :footerButtonText="'Yes'"
        :disableCancel="true" :width="'20%'" :deleteMessage="`Do you really want to leave? `"
        :infoMessage="` You have unsaved changes!`" @handleClick="showSiteSurveyFormFunc" @handleClose="openCancelChangesDialog = false">
    </DeleteDialog>
</section>
