
  <div class="table-view" :id="showBorderRadius?'arkaTable':'table'" v-loading="tableData ? false : true">
    <div v-if="showSearch">
      
      <searchBar
        @search="handleInputChange"
        :style="{ width: searchHeight, margin: '1rem' }"
      ></searchBar>
    </div>
      <div class="buttonstyles" v-if="showButton">
        <el-button @click="createCRM(buttonTitle)" type="primary" >{{ buttonTitle }}</el-button>
      </div>
    <el-table
      @row-click="handleRowClick"
      ref="table"
      :show-header="showHeader"
      class="table-container"
      :data="tableData"
      :style="getTableStyles()"
      v-infinite-scroll="loadMore"
      infinite-scroll-disabled="busy"
      infinite-scroll-distance="10"
      :border="showBorder"
      :highlight-current-row="false"
      :cell-style="{
        padding: '0',
        height: cellHeight,
        paddingLeft: paddingLeft,
      }"
      :header-cell-style="{
        paddingLeft: paddingLeft,
        color: color,
        fontWeight: weight,
        fontSize: size,
      }"
    >
      <el-table-column
        v-for="column in tableColumns"
        :key="column.id"
        v-bind:prop="column.id"
        v-bind:label="column.title"
        :min-width="column.width"
        :align="column.align ? column.align : 'left'"
        :resizable="false"
      >
        <template v-slot:header v-if="showHeaderSortable">
          <span class="headerButton" v-if="column.isSortable">
            <span :style="handleTableHeader(column)"> {{ column.title }}</span>
            <i
              class="el-icon-top"
              v-if="!column.sorted"
              v-on:click="handleSort(column, 'desc')"
              style="line-height: 1.1; margin-left: 3px; margin-top: 5px"
            ></i>
            <i
              class="el-icon-bottom"
              v-else
              v-on:click="handleSort(column, 'asc')"
              style="line-height: 1.1; margin-left: 3px; margin-top: 5px"
            ></i>
            <!-- Replace with your desired icon -->
          </span>

          <span v-if="column.headerTask">
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-between;
              "
            >
              {{ column.title }}
              <span
                style="
                  font-weight: 500;
                  background: #e8edf2;
                  color: #777;
                  /* border-radius: 4px; */
                  padding: 5px;
                "
                >{{ taskCount }} {{ spellTask(taskCount) }}</span
              >
            </div>
          </span>
        </template>
        <template scope="scope">
          <div
            :style="getCellStyles(scope.row, column)"
            :class="{
              place: true,
              'green-tick': scope.row[column.id] === '\u2713',
            }"
          >
            <span v-if="column.type === 'tag'">
              <slot :scope="{ scope, column }" name="tags"></slot>
            </span>

            <span v-if="column.type === 'shiftTags'">
              <slot :scope="{ scope, column }" name="shiftTags"></slot>
            </span>

            <span
              v-if="column.type === 'avatar'"
              style="display: flex; align-items: center"
            >
              <el-avatar
                :size="30"
                style="
                  margin-right: 1rem;
                  min-width: 30px;
                  background-color: red;
                "
                >{{ renderName(scope.row.fullName) }}</el-avatar
              >
              {{ scope.row.fullName }}
            </span>
            <span style="margin:auto;padding-top:5px;" v-if="column.type=='check'">
              <el-checkbox @click.native.stop="handleCheckBox(scope.row)" v-model="scope.row.checkBox"></el-checkbox>
            </span>
            <span :style="{ height: cellHeight }" style="font-size:large; width:100px; cursor:default;height:100%; display:flex; align-items:center;" @click.stop v-if="column.type=='actionDelete'">
              <div style="display:flex;width:100%;">
                <div style="cursor:pointer; margin-right: 30px;" @click.stop="editCRM(scope.row)">
                  <b-icon style="color:#777777;" icon="pencil"></b-icon>
                </div>
                <div style="cursor:pointer;" @click.stop="deleteCRM(scope.row)">
                  <b-icon  style="color:#777777; " icon="trash"></b-icon>
                </div>
            </div>
            </span>
            <span style="display:flex;align-items: center;" @click.stop v-if="column.type=='arkaName'">
              <div>
                <img style="width:50px;height: 50px;border-radius: 5px; margin-right: 15px;" :src="scope.row.line_items[0].product_sku_details.image" alt="">
              </div>
              <div style="color: #222;">
                <p >{{ scope.row.identifier }} <span v-if="scope.row.quantity>0" style="background-color: #e8edf2; border-radius:50%;margin-left: 5px; padding:5px 6px;">+{{ scope.row.quantity }}</span> </p>
                <p style="color:#777">{{scope.row.dateTime}}</p>
              </div>
            </span>
            <span style="display:flex;align-items: center;" @click.stop v-if="column.type=='catalogName'">
              <div  style="width:50px;height: 50px; margin-right: 15px;">
                <img  style="width:50px;height: 50px;border-radius: 5px;" :src="scope.row.image" alt="">
              </div>
              <div style="color: #222;">
                <p >{{ scope.row.name }} <span v-if="scope.row.quantity>0" style="background-color: #e8edf2; border-radius:50%;margin-left: 5px; padding:5px 6px;">+{{ scope.row.quantity }}</span> </p>
                <p style="color:#777">{{scope.row.description}}</p>
              </div>
            </span>
            <span style="display:flex;align-items: center;" @click.stop v-if="column.type=='catalogActions'">
              <div style="display:flex;width:100%;" >
                <!-- <el-tooltip content="Archive" placement="top">
                  <el-button style="border: none;display: flex;"   plain>
                    <p style="padding: 10px;font-size: 20px;"><b-icon  icon="archive"></b-icon></p>
                    
                  </el-button>
                </el-tooltip> -->

                <el-tooltip content="Edit" placement="top">
                  <el-button  style="padding:8px; display: flex; align-items: center; border-radius: 4px;border-color: #777;" @click.stop="editBundle(scope.row)">
                   <p ><b-icon style="transform:scale(1.3);color: #777;" icon="pencil"></b-icon></p> 
                  </el-button>
                </el-tooltip>
            </div>
            </span>
            <span v-if="column.type === 'paymentStatus' &&scope.row.payment">
              <el-tag v-if="scope.row?.payment?.status==='COMPLETED'"
            style="color: white;background-color: #5FCC29; border: 1px solid transparent;border-radius:50px "
                  effect="dark"
                  >
                  {{ 'Received'}}
                </el-tag>
                <el-tag v-if="scope.row.payment?.status==='PENDING'&&scope.row.linkStatus"
                  style="color: white;background-color: #FF4340; border: 1px solid transparent;border-radius:50px "
                  >
                  {{ 'Resend' }}
                 
                </el-tag>
                <el-tag v-if="scope.row.payment?.status==='PENDING'&&!scope.row.linkStatus"
                  style="color: white;background-color: #D5D920; border: 1px solid transparent;border-radius:50px "
                  >
                  {{ scope.row.payment.status.charAt(0).toUpperCase() + scope.row.payment.status.slice(1).toLowerCase() }}
                 
                </el-tag>
                <el-tag v-if="!scope.row.payment.id"
                  style="color: white;background-color: #CCC; border: 1px solid transparent;border-radius:50px "
                  >
                  {{ "Financed" }}
                 
                </el-tag>

            </span>
            <span :style="{ height: cellHeight }" style="font-size:large; width:100px; cursor:default;height:100%; display:flex; align-items:center;" @click.stop v-if="column.type=='contractAction'">
        <div style="display:flex;width:100%;gap:16px" v-if="scope.row?.is_rejected">
          <el-tooltip :content="scope.row?.mode_of_payment=='finance'?'Finance' :'Payment'" placement="top">
                  <el-button :disabled="scope.row?.mode_of_payment=='finance'"   style=" display: flex; align-items: center;  padding:8px; border-radius: 4px;" type="primary" class="white-background" plain @click.stop="payment(scope.row)">
                    <b-icon  icon="currency-dollar" class="action-icon"></b-icon>
                  </el-button>
                </el-tooltip>
          <el-tag 
   
    effect="dark"
    style="color: white;background-color: #FF4340; border: 1px solid transparent;border-radius:50px "
    >
    {{scope.row.lead_details? 'Rejected':'Deleted' }}
  </el-tag>
        </div>
        <div style="display:flex;width:100%;gap:16px" v-else-if="scope.row?.is_accepted">
          
                <el-tooltip :content="scope.row?.mode_of_payment=='finance'?'Finance' :'Payment'" placement="top">
                  <el-button :disabled="scope.row?.mode_of_payment=='finance'"   style=" display: flex; align-items: center;  padding:8px; border-radius: 4px;" type="primary" class="white-background" plain @click.stop="payment(scope.row)">
                    <b-icon  icon="currency-dollar" class="action-icon"></b-icon>
                  </el-button>
                </el-tooltip>

                <el-tag 
                style="color: white;background-color: #5FCC29; border: 1px solid transparent;border-radius:50px "
                  effect="dark">
                  {{ 'Accepted' }}
                </el-tag>
            </div>
              <div style="display:flex;width:100%;gap:16px" v-else>
         
                <el-tooltip :content="scope.row?.mode_of_payment=='finance'?'Finance' :'Payment'" placement="top">
                  <el-button :disabled="scope.row?.mode_of_payment=='finance'"  style="margin:0; display: flex; align-items: center;  padding:8px; border-radius: 4px;" type="primary" class="white-background" plain @click.stop="payment(scope.row)">
                    <b-icon  icon="currency-dollar" class="action-icon"></b-icon>
                  </el-button>
                </el-tooltip>

                <el-tooltip  content="Accept" placement="top">
                  <el-button 
                  :disabled=" scope.row.status =='Accepted' || scope.row.status =='Rejected' " 
                  :class="{ 'disabled-button':  scope.row.status === 'Accepted' || scope.row.status === 'Rejected' }"
                  style=" margin:0;  padding:8px; display: flex; align-items: center; border-radius: 4px;" class="white-background" type="success"  plain @click.stop="acceptContract(scope.row)">
                    <b-icon  icon="check2" class="action-icon"></b-icon>
                  </el-button>
                </el-tooltip>

                <el-tooltip content="Reject" placement="top">
                  <el-button style="margin:0;padding:8px; display: flex; align-items: center; border-radius: 4px;"  class="reject-button"  @click.stop="reject(scope.row)">
                    <b-icon class="action-icon" icon="x"></b-icon>
                  </el-button>
                </el-tooltip>
            </div>
            </span>
            <span @click.stop v-if="column.type=='contractStatus'">
              <el-tag v-if="!scope.row.document_details"
                  style="color: white;background-color: #FF4340; border: 1px solid transparent;border-radius: 50px;"
                  >
                  {{ "Deleted" }}
                </el-tag>
                <el-tag v-else-if="scope.row.is_accepted"
                  style="color: white;background-color: #5FCC29; border: 1px solid transparent;border-radius: 50px;"
                  >
                  {{ "Accepted" }}
                </el-tag>
                <el-tag v-else-if="scope.row.is_rejected"
                  style="color: white;background-color: #FF4340; border: 1px solid transparent;border-radius: 50px;"
                  >
                  {{ "Rejected" }}
                </el-tag>

        
         <el-tag v-else
                  style="color: white;background-color: #D5D920; border: 1px solid transparent;border-radius: 50px;"
                  >
                  {{ "Pending" }}
                </el-tag>
            </span>
            
            <span @click.stop style="color: #222;" v-if="column.type=='contract_id'">
              <p >{{ scope.row.identifier }}</p>
              <p style="color:#777">{{dateFormat(scope.row.created_at)}}</p>
            </span>
            <span @click.stop v-if="column.type=='userData'">
              <p v-if="scope.row?.created_by_details||scope.row.submitted_by_details">{{ scope.row.submitted_by_details?.first_name|| scope.row?.created_by_details?.first_name}} {{ scope.row.submitted_by_details?.last_name|| scope.row?.created_by_details?.last_name }}</p>
              <p v-else>_</p>
            </span>
            <span @click.stop v-if="column.type=='documents' ">
              <p v-if="scope.row.document_details?.display_name" @click.stop="downloadDocument(scope.row)" style="display:flex;font-weight: 500;cursor: pointer; align-items: center;">
                <img class="pdf-icon" src="../../../assets/img/pdfIcon.svg" alt="">
                <span style="color: #1C3366;"> View Details</span></p>
                <p v-else>_</p>
            </span>
            <span :style="{ height: cellHeight }" style="font-size:large; width:100px; cursor:default;height:100%; display:flex; align-items:center;" @click.stop v-if="column.type=='ordersAction'">
            
              <div style="display:flex;width:100%;" v-if="scope.row.status==='Accepted'||scope.row.status==='Rejected' || scope.row.status==='Cancelled'">
                <el-tooltip content="View" placement="top">
                  <el-button style="margin-right: 10px; display: flex; align-items: center; padding:8px; border-radius: 4px;" type="primary" class="white-background" plain @click.stop="view(scope.row)">
                    <b-icon icon="eye"></b-icon>
                  </el-button>
                </el-tooltip>
                <el-tooltip v-if="isSOandProjectmanager" content="Payment" placement="top">
                  <el-button 
                  style=" margin-right: 10px;  padding:8px; display: flex; align-items: center; border-radius: 4px;" class="white-background" type="primary"  plain @click.stop="installments(scope.row)">
                    <b-icon  icon="currency-dollar"></b-icon>
                  </el-button>
                </el-tooltip>
<span v-if="!organisationInfo?.vip_for_power_gazebo&&role !== 'ARKA_INTERNAL_SALES'">
                <el-tag v-if="scope.row.status==='Accepted'"
                style="color: white;background-color: #5FCC29; border: 1px solid transparent;border-radius:50px "
                  effect="dark">
                  {{ scope.row.status }}
                </el-tag>
                <el-tag v-if="scope.row.status==='Cancelled'"
                  style="color: white;background-color: #CCC; border: 1px solid transparent;"
                  >
                  {{ scope.row.status }}
                </el-tag>
                <el-tag v-if="scope.row.status==='Rejected'"
                style="color: white;background-color: #FF4340; border: 1px solid transparent;border-radius:50px "
                  effect="dark">
                  {{ scope.row.status }}
                </el-tag>
              </span>
              </div>
              <div style="display:flex;width:100%;" v-else>
                <el-tooltip content="View" placement="top">
                  <el-button style="  display: flex; align-items: center; padding:8px; border-radius: 4px;" type="primary" class="white-background" plain @click.stop="view(scope.row)">
                    <b-icon  icon="eye"></b-icon>
                  </el-button>
                </el-tooltip>

                <el-tooltip v-if="isSOandProjectmanager" content="Payment" placement="top">
                  <el-button 
                  style=" margin-right: 10px;  padding:8px; display: flex; align-items: center; border-radius: 4px;" class="white-background" type="primary"  plain @click.stop="installments(scope.row)">
                    <b-icon  icon="currency-dollar"></b-icon>
                  </el-button>
                </el-tooltip>
           <div v-if="isSOandProjectmanager">
          
     
      <el-tooltip v-if="column.show !=='crossIcon'&&(scope.row.status==='Invoiced'||scope.row.status==='Accepted')" content="Accept" placement="top">
                  <el-button 
                  :disabled=" scope.row.status =='Accepted' || scope.row.status =='Rejected' " 
                  :class="{ 'disabled-button':  scope.row.status === 'Accepted' || scope.row.status === 'Rejected' }"
                  style=" margin-right: 10px;  padding:8px; display: flex; align-items: center; border-radius: 4px;" class="white-background" type="success"  plain @click.stop="accept(scope.row)">
                    <b-icon  icon="check2"></b-icon>
                  </el-button>
                </el-tooltip>
                <el-tooltip v-else content="Invoice" placement="top">
                  <el-button 
                  :disabled="scope.row.status =='Invoiced' || scope.row.status =='Accepted' || scope.row.status =='Rejected' " 
                  :class="{ 'disabled-button': scope.row.status === 'Invoiced' || scope.row.status === 'Accepted' || scope.row.status === 'Rejected' }"
                  style="margin-right: 10px; padding:8px; display: flex; align-items: center; border-radius: 4px;" class="white-background disabled" type="success"  plain @click.stop="invoice(scope.row)">
                    <b-icon  icon="receipt-cutoff"></b-icon>
                  </el-button>
                </el-tooltip>
              </div>
                <el-tooltip v-if="isSOandProjectmanager"  :content="isSOandProjectmanager ?'Reject' :'Cancel'" placement="top">
                  <el-button 
                  :disabled=" scope.row.status =='Accepted' || scope.row.status =='Rejected' " 
                  :class="{ 'disabled-button':  scope.row.status === 'Accepted' || scope.row.status === 'Rejected' }"
                  style="padding:8px; display: flex; align-items: center; border-radius: 4px;" class="reject-button" @click.stop="reject(scope.row)">
                    <b-icon style="transform:scale(1.3);" icon="x"></b-icon>
                  </el-button>
                </el-tooltip>
             
              </div>
            </span>
            <span @click.stop v-if="column.type==='text'">
             <p>{{ scope.row[column.id] }}</p>
           </span>
            <span @click.stop v-if="column.type=='leadData'">
             
              <p v-if="isSOandProjectmanager&&column.isArkaOrder" >{{ scope.row?.lead_details?.name||"_" }}</p>

              <p v-else style="color: #1C3366; font-weight: 600; cursor: pointer;" @click.stop="leadData(scope.row.lead_details)">{{ scope.row?.lead_details?.name||'_' }}</p>
            </span>
            <span @click.stop v-if="column.type=='status'">
              <p :style="{color:renderColor(scope.row.status)}">{{ scope.row.status }}</p>
            </span>
            <span style="width:100%; text-align:right; color: #222;" @click.stop v-if="column.type=='amount'||column.type=='price'">
              <p>{{ scope.row[column.type] }}</p>
            </span>
            <span class="crmElipsis" v-if="column.type === 'vip'"> 
              <p style="display: contents;color: #222;" >{{scope.row[column.id]}}</p>
            </span>
            <span v-if="column.type ==='edit_actions'">
              <slot :data="{ scope, column }" name="edit_actions"></slot>
            </span>
            <span v-if="column.type === 'onlineStatus'">
              <div class="actionClass">
                <el-badge
                  is-dot
                  class="item"
                  :type="getStatus(scope.row, column)"
                  style="margin-top: 12px"
                ></el-badge>
                <span
                  v-if="scope.row.isOnline === true"
                  style="margin-left: 10px; color: green"
                  >Online</span
                >
                <span v-else style="margin-left: 10px; color: grey"
                  >Offline</span
                >
              </div>
            </span>

            <span v-if="column.type === 'dropdown'">
              <slot :scope="{ scope, column }" name="dropdown"></slot>
            </span>

            <span v-if="column.type === 'priority'">
              <div
                v-if="!scope.row.flag && scope.row.priority === 'default'"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <div>
                  <img
                    src="./assets/Priority.svg"
                    alt=""
                    @click.stop="handleFlagShow(scope.row, 'high')"
                  />
                </div>

                <span
                  style="
                    margin-left: 5px;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 16px;
                    margin-bottom: 10px;
                    color: #999999;
                    margin-top: 7px;
                  "
                  >Default</span
                >
              </div>
              <div
                v-else
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <div>
                  <img
                    src="./assets/FlagDisabled.svg"
                    alt=""
                    v-if="scope.row.status === 'Completed'"
                    @click.stop="handleFlagShow(scope.row, 'default')"
                  />
                  <img
                    src="./assets/FlagFill.svg"
                    alt=""
                    v-else
                    @click.stop="handleFlagShow(scope.row, 'default')"
                  />
                </div>
                <div>
                  <span
                    style="
                      margin-left: 5px;
                      font-weight: 500;
                      margin-bottom: 10px;
                      font-size: 15px;
                      line-height: 16px;
                      color: #999999;
                      margin-top: 7px;
                    "
                    v-if="scope.row.status === 'Completed'"
                    >Urgent</span
                  >
                  <span
                    style="
                      margin-left: 5px;
                      font-weight: 500;
                      margin-bottom: 10px;
                      font-size: 15px;
                      line-height: 16px;
                      color: red;
                      margin-top: 7px;
                    "
                    v-else
                    >Urgent</span
                  >
                </div>
              </div>
            </span>

            <span v-if="column.type === 'location'">
              <p @click.stop="openMap(scope.row[column.id])">
                {{ scope.row[column.id] }}
              </p>
            </span>
            <span class="crmElipsis" v-if="column.type === 'elipsis'"> 
              <p style="display: contents;" >{{scope.row[column.id]}}</p>
            </span>
            <span v-if="column.type === 'taskName'" style="display: flex">
              <div class="mainContainer">
                <div class="left">
                  <div style="margin-top: 5px">
                    <img
                      src="./assets/checkCircle.svg"
                      alt=""
                      @click.stop="handleCheck(scope.row, 'completed')"
                      v-if="scope.row.status === 'New'"
                    />
                    <img
                      src="./assets/In_progress.svg"
                      alt=""
                      v-else-if="scope.row.status === 'In-Progress'"
                      @click.stop="handleCheck(scope.row, 'completed')"
                    />
                    <img
                      src="./assets/completeCheckBox.svg"
                      alt=""
                      v-else-if="scope.row.status === 'Completed'"
                      @click.stop="handleCheck(scope.row, 'new')"
                    />
                  </div>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="scope.row[column.id]"
                    placement="right"
                    :visible-arrow="false"
                  >
                    <div @click.stop>
                      <el-input
                        placeholder="Please Input"
                        @input="(value) => updateEditedName(value, scope.row)"
                        v-model="scope.row.name"
                        size="small"
                        style="width: 160px"
                        class="inputFieldContainer"
                        :maxlength="50"
                      >
                      </el-input>
                    </div>
                  </el-tooltip>
                </div>
              </div>
            </span>

            <span v-if="column.type === 'homeOwner'">
              <p @click.stop="isDrilldown ? '' : handleOpenLead(scope.row)">
                {{ scope.row[column.id] }}
              </p>
            </span>

            <span v-if="column.type === 'status_dropdown'">
              <div class="status_dropdown">
                <span>
                  <el-select
                    v-model="scope.row.status"
                    :key="scope.row.id"
                    :placeholder="scope.row.status"
                    style="border: none; background-color: #e8edf2"
                    @click.native="handleIntrupt"
                  >
                    <el-option
                      v-for="item in scope.row.statusOption"
                      :key="item.id"
                      :label="item.label"
                      :value="item.value"
                      @click.native="hideDropDown(scope.row, item.value)"
                    >
                    </el-option>
                  </el-select>
                </span>
              </div>
            </span>

            <span v-if="column.type === 'dateDueBy'" @click.stop>
              <el-date-picker
                v-model="scope.row.dueBy"
                type="date"
                :format="'MMM dd, yyyy'"
                placeholder="N/A"
                :timestamp = "isoTimeList"
                class="datePicker"
                :class="placeholderClass(scope.row, column)"
                @change="handleDatePicker($event, scope.row)"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </span>

            <span
              v-if="column.id === 'assignees'"
              style="display: flex; align-items: center"
            >
              <el-avatar :size="30" :style="getAvatarColor(scope.row)">{{
                renderName(scope.row[column.id])
              }}</el-avatar>
              <el-tooltip
                class="item"
                effect="dark"
                :content="scope.row[column.id]"
                placement="top-start"
              >
                <p
                  style="
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 80px;
                    margin-left: -10px;
                  "
                >
                  {{ scope.row[column.id] }}
                </p>
              </el-tooltip>
            </span>

            <!-- before this -->
            <span
              v-else-if="!column.type"
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              {{ scope.row[column.id]||"-" }}
            </span>
            <!-- after this -->
            <span v-if="column.id === tableColumns[0].id" class="actionClass">
              <slot :scope="{ scope, column }" name="actions"> </slot>
            </span>

            <span v-if="column.type === 'taskName'">
              <div class="right">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="Documents"
                  placement="top-start"
                >
                  <p
                    class="documents"
                    style="display: flex; align-items: center; margin-left: 5px"
                  >
                    <img
                      src="./assets/paperFaded.svg"
                      alt=""
                      style="height: 16px"
                      v-if="scope.row.status === 'Completed'"
                    />

                    <img
                      src="./assets/paperclip 1.svg"
                      alt=""
                      style="height: 16px"
                      v-else
                    />
                    <span style="width: 18px" :style="getStyles(scope.row)">{{
                      scope.row.documents_count
                    }}</span>
                  </p>
                </el-tooltip>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="Comments"
                  placement="top-start"
                >
                  <p class="chat" style="display: flex; align-items: center">
                    <img
                      src="./assets/chat.svg"
                      alt=""
                      :style="getStyles(scope.row)"
                      v-if="scope.row.status === 'Completed'"
                    />
                    <img
                      src="./assets/chat-left 1.svg"
                      alt=""
                      :style="getStyles(scope.row)"
                      v-else
                    />
                    <span :style="getStyles(scope.row)" class="comment">{{
                      scope.row.comments_count
                    }}</span>
                  </p>
                </el-tooltip>
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="scope.row.nudgeTooltipText"
                  placement="top-start"
                >
                  <p
                    class="bell"
                    style="display: flex; align-items: center; margin-left: 5px"
                  >
                    <img
                      v-if="scope.row.status === 'Completed'"
                      src="./assets/bell_iconSuccess.svg"
                      alt=""
                      :style="getStyles(scope.row)"
                      @click.stop
                      disabled
                    />
                    <img
                      v-else
                      src="./assets/bell_icon.svg"
                      alt=""
                      :style="getStyles(scope.row)"
                      @click.stop="nudgeNotification(scope.row)"
                    />
                  </p>
                </el-tooltip></div
            ></span>
           
          </div>
        </template>

       
      </el-table-column>
    </el-table>
  </div>
