
    <div class="parentContainer" v-loading="isNextContractLoading">

        <el-dialog :visible="isEditContractPopupVisible" :close-on-click-modal="false" title="Edit Custom Placeholders"
            @close="onDialogClose"   :modal-append-to-body="isModal" >
            <div class="container">
                <div class="pageContainer">
                    <pureTextEditor
                        v-if="htmlContent && editorType=='text' && !isLoading"
                        :fileName="fileName"
                        :htmlContent="htmlContent"
                        :includePaymentSchedule="includePaymentSchedule"
                        :customPlaceholderMode="true"
                        :key="componentKey"
                        :showToolbar="false"
                        :readOnly="true"
				    />
                    <div class="buttonsContainer" v-if="fileLink && editorType=='pdf' && !isLoading">
                        <el-button type="primary" :disabled="pdfPageNo<=1" @click="handlePrevious"  class="nextBtn">
                            <p style="display: flex;">
									<span class="backIcon">
										<b-icon icon="arrow-left" class="searchIcon" 
											style="transform: scale(1.5);margin-right: 16px;">
                                        </b-icon>
                                    </span>
                                Previous
                            </p>
                        </el-button>
                        <p>{{pdfPageNo}}/{{ pdfTotalPages }}</p>
                        <el-button type="primary" :disabled="(pdfPageNo>=pdfTotalPages)"  @click="handleNext" class="nextBtn">
                            <p style="display: flex;">
                                Next
                                <span class="backIcon"> 
                                    <b-icon icon="arrow-right" class="searchIcon"
                                        style="transform: scale(1.5);margin-left: 16px;">
                                    </b-icon>
                                </span>
                            </p>
                        </el-button>
                    </div>
                    <pdfRenderer
                        v-if="fileLink && editorType=='pdf' && !isLoading"
                        :fileLink="fileLink"
                        :pdfPageNo="pdfPageNo"
                        :includePaymentSchedule="includePaymentSchedule"
                        :customPlaceholderMode="true"
                        :key="componentKey"
                        @totalPages="totalPages => pdfTotalPages = totalPages"
                    />
                </div>
                <el-form ref="form" :model="form" class="formContainer">
                    <p class="font16Bold">Custom Placeholders</p>
                    <div style="word-break: break-word;" v-if="!CMStore.uniqueCustomPlaceholdersArray.length">
                        No custom placeholder is being used in this template
                    </div>
                    <div class="flexRow" v-for="(customPH,index) in CMStore.uniqueCustomPlaceholdersArray" :key="index" >
                        <p class="font14Normal">{{customPH.placeholder_key || customPH.name  }}</p>
                        <el-input
                        type="textarea"
                        maxlength="2000" show-word-limit
                        v-model="customPH.value" name="title"></el-input>
                        <p class="errorMsg" v-if=false>This field is required.</p>
                    </div>
                </el-form>
            </div>
            <div class="btnContainer">
                <p class="font16Normal">Contract <span class="pageNo">{{currentContractIndex+1}}/{{ selectedContracts.length }}</span></p>
                <el-button type="primary" :loading="isNextContractLoading" @click="saveCustomPlaceholdersInDB()" class="nextBtn">Next</el-button>
            </div>
        </el-dialog>
    </div>
