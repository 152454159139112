
<div id="newProject">
  <el-dialog
    :visible="isNewProjectFormVisible"
    :close-on-click-modal="false"
    :show-close="!isIntegration"
    style="min-width: 800px;"
    title="Create a new project"
    width="90%"
    @open="assignIntegratedProjectData"
    @close="onNewProjectDialogClose"
  >
      <!-- <h3 class="containerHeading">Project Information*</h3> -->
      <div class="new-project-container">
        <div class="fields-container">
          <el-form
            :model="projectData"
            size="mini"
            label-position="left"
          >
            <!-- <p class="formHeadings">Project Details</p> -->


            <div class="flexContainer">
              <p class="label">Enter Location <span class="asterisk">*</span></p>
              <div class="fullWidth">
                <div class="searchBarWrapper">
                  <GoogleMapsAutocompleteInputVue
                    @input="showManualInputError = false"
                    @placesChanged="showManualInputError = false"
                    placeholder="Enter Location"
                  />
                </div>
                <p class="formErrors" v-show="showManualInputError">
                  Please select an address from the dropdown.
                </p>
              </div>
            </div>

            <div class="flexContainer">
              <p class="label">Project Name <span class="asterisk">*</span></p>
              <div class="fullWidth">
                <el-input
                  v-validate="projectNameValidation"
                  v-model="projectData.projectName"
                  :disabled="isIntegration"
                  name="Project Name"
                  placeholder="Enter Project Name"
                />
                <p class="formErrors">
                  {{ errors.first("Project Name") }}
                </p>
              </div>
            </div>

            <!-- <p class="formHeadings">Client Details</p> -->

            <div class="flexContainer">
              <p class="label">Client Name <span class="asterisk">*</span></p>
              <div class="fullWidth">
                <el-input
                  v-validate="nameValidation"
                  v-model="projectData.clientName"
                  name="Name"
                  placeholder="Enter Client name"
                />
                <p class="formErrors">
                  {{ errors.first("Name") }}
                </p>
              </div>
            </div>

            <el-form-item label="Client Number">
              <vue-tel-input
                v-model="projectData.clientContactNumber"
                :key="counter"
                :defaultCountry="defaultCountryCode" 
                :dropdownOptions="{showFlags:true,showDialCodeInSelection:true,showDialCodeInList:true}" 
                :inputOptions="{maxlength:15}"
                :autoFormat="false"
                :mode="international"
                :validCharactersOnly="true"
                @country-changed="setCountryCode"
                @validate="validateClientNumber"
                @open="onDropdownOpen(true)"
                @close="onDropdownOpen(false)"
                name="Client Number"
                placeholder="Enter a phone number"
                >
                <template v-slot:arrow-icon>
                  <span>{{ open ? '▲' : '▼' }}</span> 
                </template>
              </vue-tel-input>
              <!-- <el-input
                type="number"
                v-model="projectData.client_phone"
                :disabled="isIntegration"
                name="Client Number"
              /> -->
            </el-form-item>

            <el-form-item label="Client Email"
              >
              <el-input
                v-validate.immediate="emailValidation"
                @input="handleEmail"
                v-model="projectData.client_email"
                name="Email"
                placeholder="Enter Email id"
              />
              <p class="formErrors">
                {{ errors.first("Email") }}
              </p>
            </el-form-item>

            <el-form-item label="Address" >
              <el-input
                placeholder="Address to show in proposal"
                v-model="projectData.clientAddress"
              />
            </el-form-item>

            <!-- <el-form-item label="Contractor License"
              v-if="flagForUS">
              <el-input
                v-model="projectData.contractor_license"
                :disabled="isIntegration"
              />
            </el-form-item> -->
            <el-form-item label="Project Type">
              <div class="group_radio">
                <label>
                  <input
                    type="radio"
                    name="radio"
                    value="residential"
                    v-model="projectType"
                  />
                  <div class="box">
                    <img src="../../../pages/consumptionProfile/assests/Group 1684.svg" class="commercialSvg" />
                    <span>Residential</span>
                  </div>
                </label>
                <label>
                  <input
                    type="radio"
                    name="radio"
                    value="commercial"
                    v-model="projectType"
                  />
                  <div class="box">
                    <img src="../../../pages/consumptionProfile/assests/Group 1685.svg" class="commercialSvg" />
                    <span>Commercial</span>
                  </div>
                </label>
              </div>
            </el-form-item>

            <el-form-item
              v-if="PLANS_HAVING_SMALL_AND_MEDIUM.includes(userTier)"
              v-show="false"
              label="Project Size"
            >
              <el-tooltip
                :disabled="availableProjectSizes.small"
                :content="TOOLTIP_CONTENT_QUOTA_EXHAUSTED"
                effect="light"
                placement="bottom"
              >
                <el-radio
                  v-model="quotaType"
                  :disabled="!availableProjectSizes.small"
                  :label="QUOTA_TYPE.SMALL"
                >
                  Small (&#60; {{ QUOTA_TYPES_DC_CAP_SIZE.SMALL }} kW)
                </el-radio>
              </el-tooltip>
              <el-tooltip
                :content="getMediumTooltipContent"
                effect="light"
                placement="bottom"
              >
                <el-radio
                  v-model="quotaType"
                  :disabled="!availableProjectSizes.medium"
                  :label="QUOTA_TYPE.MEDIUM"
                >
                  Medium (&#60; {{ QUOTA_TYPES_DC_CAP_SIZE.MEDIUM }} kW)
                </el-radio>
              </el-tooltip>
            </el-form-item>
          </el-form>

        </div>

        <div class="map-container">
          <newProjectMapSelector
            :geo-location="geoLocation"
            :place="placeForMap"
            @change="showManualInputError = false"
          />
        </div>
      </div>
      <template v-slot:footer>
      <div class="footer">
        <!-- <p class="footerStep">Step 1<span class="unBold">/{{totalSteps}}</span></p> -->
        <div class="notesBtn">
          <!-- <el-button class="backBtn">Back</el-button> -->
          <el-button type="primary"
            :disabled="errors.items.length > 0 || isProjectGettingCreated"
            @click="confirmOnClickAction()"
            class="submitBtn">
            <span v-show="!isProjectGettingCreated">Create Project</span>
            <i v-show="isProjectGettingCreated" class="el-icon-loading" />
          </el-button>
        </div>
      </div>
      </template>
  </el-dialog>
</div>
