<template>
    <div id="parentContainer">
        <el-dialog :visible="bulkLeadImportState.isImportLeadsPopupVisible" :close-on-click-modal="false" :title="computedTitle"
            @close="onDialogClose">

            <div class='container' v-if="bulkLeadImportState.showFileUploadSection">
                <div class="rows" v-if="!fileUploaded">
                    <p class="label">Pipeline
                        <el-tooltip placement="top">
                            <template v-slot:content>
                            <div>All the leads will be moved to the particular<br />pipeline that you
                                select
                                from the dropdown</div>
                            </template>
                            <b-icon icon="exclamation-circle-fill" style="color: #777; cursor: pointer;"></b-icon>
                        </el-tooltip>
                    </p>
                    <div>
                        <el-select placeholder="Select a Pipeline" v-model="selectedPipeline" name="pipeline">
                            <el-option v-for="stage in pipeline" :key="stage.id" :label="stage.name" :value="stage.id">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <el-tooltip placement="top" :disabled="selectedPipeline ? true : false"
                    content="Please select a Pipeline to proceed.">
                    <div :class="['uploadContainer', selectedPipeline ? '' : 'cursorClass']" v-if="!fileUploaded" @dragover="dragover" @drop="drop">
                        <fileUpload @openFiles="openFiles" :isBulkLeadsImport="isBulkLeadsImport"
                            :isPipelineSelected="selectedPipeline ? false : true"
                            uploadFileText="Drag & drop your file here"></fileUpload>
                        <p class="font20Normal downloadFile" @click='downloadSampleFile()' ><b-icon icon="download" aria-hidden="true"></b-icon>
                            Download
                            sample file</p>
                        <p class="font20Normal">Only <b>CSV</b> & <b>XLSX</b> files are supported</p>
                    </div>
                </el-tooltip>
                <div class="uploadSuccess" v-if="showValidFileSection">
                    <img src="./assets/successIcon.svg" />
                    <p class="success">Your file has been successfully uploaded</p>
                    <img :src="fileType" />
                    <p class="fileName" :title="bulkLeadImportState.fileName">{{ bulkLeadImportState.fileName }}</p>
                </div>
                <div class="uploadSuccess" v-if="showInvalidFileSection">
                    <img src="./assets/errorIcon.svg" />
                    <p class="success">Error while uploading the file!
                        <br />Please <span class="blueTxt" @click="tryAgainFunc()">Try Again</span>
                    </p>
                </div>
            </div>
            <div class="footerContainer" v-if="bulkLeadImportState.showFileUploadSection">
                <el-button class="cancelBtn commonBtnClass" @click="onDialogClose">Cancel</el-button>
                <el-button type="primary " class="nextBtn commonBtnClass" @click="nextBtnFunc()"
                    :disabled="disabledNextBtnFunc">Next</el-button>
            </div>
            <div v-if="bulkLeadImportState.showInputsSection">
                <LeadFieldsMapping ref="bulkLead"
                    v-if="bulkLeadImportState.showLeadFieldsSection" />
                <StageMapping ref="stageValues" v-if="bulkLeadImportState.showStageFieldsSection" />
            </div>
            <div class="footerContainer" v-show="bulkLeadImportState.showInputsSection">
                <el-button class="cancelBtn commonBtnClass" @click="previousBtnFunc()">Previous</el-button>
                <el-tooltip placement="top" :disabled="!bulkLeadImportState.isValidateBtnDisabled">
                    <template v-slot:content>
                        <div>Please select all the mandatory fields to proceed.</div>
                    </template>
                    <el-button type="primary " class="nextBtn commonBtnClass" @click="refFeildsMappingAndValdateFunc"
                        :disabled="bulkLeadImportState.isValidateBtnDisabled" v-loading="isLoading">Validate</el-button>
                </el-tooltip>
            </div>
        </el-dialog>
    </div>
</template>


<script>

import API from "@/services/api/";
import LeadFieldsMapping from './leadFieldsMapping.vue';
import StageMapping from './stageMapping.vue';
import fileUpload from "./../../../components/ui/fileUpload.vue";
import XLSXFileIcon from './assets/xlsxFileIcon.svg';
import CSVFileIcon from './assets/csvFileIcon.svg';
import { useMiscStore } from "../../../stores/misc";
import { mapState, mapActions } from "pinia";
import Papa from "papaparse";
import * as XLSX from 'xlsx';
import { uploadFileToBlob } from "@/utils.js";
import { containerNameBulkImport } from "@/constants.js";
import { saveAs } from "file-saver";


export default {
    name: "importLeadsPopup",
    components: {
        LeadFieldsMapping,
        StageMapping,
        fileUpload,
    },

    data() {
        return {
            pipeline: [],
            fileList: [],
            fileType: null,
            fileUploaded: false,
            validFile: false,
            xlsxFileIcon: XLSXFileIcon,
            csvFileIcon: CSVFileIcon,
            isBulkLeadsImport: true,
            showValidFileSection: false,
            showInvalidFileSection: false,
            selectedPipeline: null,
            isLoading: false,
        };
    },

    async created() {
        this.getPipeline();
    },

    computed: {
        ...mapState(useMiscStore, {
            bulkLeadImportState: (state) => state.bulkLeadImportState,
        }),

        computedTitle() {
            if (this.bulkLeadImportState.showLeadFieldsSection && !this.bulkLeadImportState.showFileUploadSection) {
                return 'Fields Mapping';
            } else if (this.bulkLeadImportState.showStageFieldsSection) {
                return 'Stage Mapping';
            } else {
                return 'Import Leads';
            }
        },

        disabledNextBtnFunc() {
            if (this.fileUploaded && this.selectedPipeline) {
                return false;
            } else {
                return true;
            }
        },
    },

    methods: {
        dragover(event) {
            event.preventDefault();
        },
        drop(event) {
            event.preventDefault();
            this.fileList = event.dataTransfer.files;
            this.openFiles(this.fileList);
        },
        ...mapActions(useMiscStore, ["resetBulkLeadStoreFunc"]),
        uploadFileToBlob,
        async fileTypeValidation(fileList) {
            const file = fileList[0];
            this.bulkLeadImportState.fileName = file.name;
            const fileExtension = this.bulkLeadImportState.fileName.split(".").pop().toLowerCase();
            if (fileExtension === "csv" || fileExtension === "xlsx") {
                this.fileUploaded = true;
                this.showValidFileSection = true;
                if (fileExtension == 'csv') {
                    this.fileType = this.csvFileIcon;
                } else {
                    this.fileType = this.xlsxFileIcon;
                }
                this.disabledNextBtnFunc;
                if (fileExtension === 'csv') {
                    this.processCSVData(file);
                } else if (fileExtension === 'xlsx') {
                    this.processXLSXData(file);
                }

                let userID = JSON.parse(localStorage.getItem('user')).user_id;
                const now = new Date();
                const formattedDateTime = `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')}|${now.getHours().toString().padStart(2, '0')}:${now.getMinutes().toString().padStart(2, '0')}:${now.getSeconds().toString().padStart(2, '0')}`;
                let file_name = `${file.name}_${userID}_${formattedDateTime}`;
                this.bulkLeadImportState.userFileName = file_name;
                const response = await Promise.all([
                    uploadFileToBlob([file], file_name, containerNameBulkImport),
                ]);
            } else {
                this.fileUploaded = true;
                this.showInvalidFileSection = true;
                this.disabledNextBtnFunc;
            }
        },
        processCSVData(file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const fileData = reader.result;
                const parsedData = Papa.parse(fileData, { header: true, skipEmptyLines: true, }).data;
                this.bulkLeadImportState.fileType = 'csv';
                this.bulkLeadImportState.userFileData = parsedData;
                this.bulkLeadImportState.userFileDataWithoutModification = parsedData;
            }
            reader.readAsText(file);
        },

        processXLSXData(file) {
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const data = e.target.result;
                    const workbook = XLSX.read(data, { type: 'binary' });

                    // Assume there is only one sheet in the Excel file
                    const sheetName = workbook.SheetNames[0];
                    const sheet = workbook.Sheets[sheetName];

                    // Convert sheet data to an array of objects
                    let jsonSheet = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                    // it will clear the empty rows 
                    jsonSheet = jsonSheet.filter(row => row.some(cell => cell !== undefined && cell !== null && cell !== ''));
                    // Extract headers from the first row
                    const headers = jsonSheet[0];

                    // Skip the first row and convert the rest of the sheet data to an array of objects using headers
                    const jsonData = jsonSheet.slice(1).map((row) => {
                        const obj = {};
                        headers.forEach((header, index) => {
                            obj[header] = row[index];
                        });
                        return obj;
                    });

                    // Set the fileData with the converted JSON data
                    this.bulkLeadImportState.userFileData = jsonData;
                    this.bulkLeadImportState.fileType = 'xlsx';
                    this.bulkLeadImportState.userFileDataWithoutModification = jsonData;
                };

                reader.readAsBinaryString(file);
            }
        },
        
        openFiles(fileList) {
            this.fileTypeValidation(fileList);
            this.fileList = fileList;
        },

        downloadSampleFile() {
            let url = 'https://downloadstsl.blob.core.windows.net/bulk-import/sample file 1.csv';
            saveAs(url,'samplefile.csv');
        },

        async getPipeline() {
            let response = await API.LEADS.GET_PIPELINES();
            let sortData = response.data.pipelines.sort(
                (a, b) => new Date(a.created_at) - new Date(b.created_at)
            );
            this.pipeline = sortData;
            this.bulkLeadImportState.pipelines = this.pipeline;
        },

        tryAgainFunc() {
            this.fileUploaded = false;
            this.showInvalidFileSection = false;
        },

        previousBtnFunc() {
            if (this.bulkLeadImportState.showLeadFieldsSection) {
                this.fileUploaded = false;
                this.bulkLeadImportState.showInputsSection = false;
                this.bulkLeadImportState.showFileUploadSection = true;
                this.showValidFileSection = false;
                this.bulkLeadImportState.selectedHeaderValues = {
                    fullName: '',
                    assignTo: '',
                    emailID: '',
                    phoneNo: '',
                    propertyType: '',
                    stage: '',
                    leadSource: '',
                    address: '',
                    closeDate: '',
                    dealValue: '',
                    tags: '',
                    headerKeys: [],
                };
                this.bulkLeadImportState.defaultFiledsValues = {
                    defaultAssignTo: '',
                    owners: [],
                    defaultPropertyType: 'residential',
                    propertyTypeTypes: [
                    {
                        label: "Residential",
                        value: "residential",
                    },
                    {
                        label: "Commercial",
                        value: "commercial",
                    },
                    ],
                    defaultLeadSource: '',
                    leadSourceTypes: [],
                    defaultCloseDate: '',
                    defaultDealValue: '',
                    defaultTags: [],
                };
            } else if (this.bulkLeadImportState.showStageFieldsSection) {
                this.bulkLeadImportState.showLeadFieldsSection = true;
                this.bulkLeadImportState.showStageFieldsSection = false;
                this.bulkLeadImportState.userFileData = this.bulkLeadImportState.userFileDataWithoutModification;
            }
        },

        nextBtnFunc() {
            this.bulkLeadImportState.showFileUploadSection = false;
            this.bulkLeadImportState.showInputsSection = true;
            this.bulkLeadImportState.selectedPipeline = this.selectedPipeline;
            this.bulkLeadImportState.showLeadFieldsSection = true;
        },

        onDialogClose() {
            this.resetBulkLeadStoreFunc();
        },

        refFeildsMappingAndValdateFunc() {
            if (this.bulkLeadImportState.showLeadFieldsSection) {
                this.$refs.bulkLead.fieldsMappingAndValidateFunc();
            } else if (this.bulkLeadImportState.showStageFieldsSection) {
                this.$refs.stageValues.stageMappingAndValidateFunc();
                this.bulkLeadImportState.isImportLeadsPopupVisible = false;
            }
        },
    },
};
</script>
<style scoped>

p {
    word-break: break-word;
}

.el-dialog__wrapper {
    margin-top: 5vh !important;
    overflow: hidden;
}

.el-dialog__wrapper>>>.el-dialog__header {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0 !important;
    height: 48px !important;
    background-color: #fff;
}

.el-dialog__wrapper>>>.el-dialog__title {
    font-size: 16px;
    font-weight: 600;
    line-height: 2.38;
    text-align: left;
    margin-left: 10px;
    color: #222222 !important;
}

.el-dialog__wrapper>>>.el-dialog__close {
    color: #222;
    font-weight: bold;
}

.el-dialog__wrapper>>>.el-dialog__headerbtn:focus .el-dialog__close,
.el-dialog__wrapper>>> .el-dialog__headerbtn:hover .el-dialog__close {
    color: #222 !important;
}

.el-dialog__wrapper>>>.el-dialog {
    border-radius: 8px !important;
    height: auto;
    margin-top: 0vh !important;
    max-width: 750px !important;
    width: 90vw !important;

}

.el-dialog__wrapper>>>.el-dialog__body {
    padding: 0px !important;
}

.container {
    padding: 24px;
    min-height: 64vh;
    max-height: 64vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    overflow-y: scroll;
}

.uploadContainer {
    display: grid;
    place-items: center;
    gap: 24px;
    padding: 48px 72px 60px 72px;
    border-radius: 8px;
    border: 1px dashed var(--CCC, #CCC);
    background: var(--Background, #E8EDF2);
}

.cursorClass {
    cursor: not-allowed;
}

.font20Normal {
    font-size: 20px;
    color: #222222;
}

.downloadFile {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #1C3366;
    font-size: 18px;
    cursor: pointer;
}

.uploadFileCont,
.uploadSuccess {
    display: grid;
    place-items: center;
    gap: 24px;
    text-align: center;
}

.uploadFileCont {
    border: 1px dashed #ccc;
    padding: 64px;
    border-radius: 8px;
    background: #E8EDF2;
}

.dragAndDrop {
    font-size: 20px;
    color: #777;
}

.browseBtn {
    width: 135px;
    height: 42px;
    font-size: 18px;
    padding: 0px;
}

.fileType,
.success,
.fileName {
    font-size: 20px;
    color: #222222;
    font-weight: 500;
}

.fileName {
    margin-top: -16px;
}

.boldTxt,
.fileName {
    font-weight: 600;
}

.blueTxt {
    color: #409EFF;
    text-decoration: underline;
    line-height: 1.5;
    cursor: pointer;
}

.footerContainer {
    display: flex;
    justify-content: flex-end;
    padding: 24px;
    border-top: 1px solid #ccc;
}

.commonBtnClass {
    height: 36px;
    font-size: 16px;
    padding: 0px 16px;
}

.cancelBtn {
    border: 1px solid #999;
}

.rows {
    width: 472px;
}

.label {
    color: #222;
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;
}

.container>>>.el-select {
    width: 100%;
}

.container>>>.el-input__inner {
    background: #E8EDF2;
    border-radius: 8px;
    height: 45px;
    border: none;
    font-size: 16px;
    color: #222;
}

.container>>>.el-input__inner::placeholder {
    color: #222;
}

.container>>>.el-select .el-input .el-select__caret {
    color: #222;
    font-size: 16px;
    font-weight: 600;
}

.el-dialog__wrapper>>>.el-button--primary.is-disabled {
    border-radius: 4px;
    background: var(--CCC, #CCC);
    border: none;
}

@media (max-width: 700px) {

    .container {
        padding: 16px;
        max-height: 70vh;
        min-height: 70vh;
    }

    .rows,
    .uploadContainer {
        width: 100%;
    }

    .uploadContainer {
        padding: 24px 8px 40px 8px;
        gap: 16px;
    }

    .font20Normal {
        font-size: 16px !important;
    }

    .footerContainer {
        padding: 16px;
    }
}
</style>
