
<div class="Firstcontainer" style="display: relative;">
    <el-popover v-if="removable" :popper-class="'popover-avatarTest'" placement="top" v-model="forceClose"
        trigger="click">
        <div v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="10" class="tag-box"
            :style="{ flexDirection: $props.isColumn ? 'column' : 'row' }">
            <div class="search-bar">
                <el-input v-model="searchTerm" placeholder="Search" @change="searchAssignee"></el-input>
            </div>
            <el-tag style="cursor: default;" v-for="avatar in selectableAvatars"
                :key="avatar.id" :size="'medium'" @close="handleRemove(avatar.id)" :type="'info'">

                <el-checkbox v-model="avatar.isSelected" @change="handleCheckBoxChange(avatar.id)">
                    <el-avatar :size="30" :style="getTagAvatarStyleTypeTwo(avatar.name)">
                        <span>{{ generateInitials(avatar.name) }}</span>
                    </el-avatar>
                    <span class="avatar-name">{{ avatar.name }}</span>
                </el-checkbox>
            </el-tag>
            <template v-if="!isSelectable">
                <el-tag style="cursor: default; max-height:200px; overflow-y:auto" v-for="avatar in selectableAvatars"
                    :key="avatar.id" :size="'medium'" @close="handleRemove(avatar.id)" closable :type="'info'">
                    <el-avatar :size="20" :style="getTagAvatarStyle(avatar.name)">
                        <span>{{ generateInitials(avatar.name) }}</span>
                    </el-avatar>
                    <span>{{ avatar.name }}</span>
                </el-tag>
            </template>
        </div>
        <template v-slot:reference>
        <div :style="{ position: 'relative', cursor: 'pointer', width: computeWidth }" >
            <el-avatar :class="$props.hover ? 'avatar-hover' : ''" :size="imgWidth"
                v-for="(avatar, index) in computedAvatars" :key="index" :style="getAvatarStyle(avatar.name, index)">
                <el-tooltip class="item" effect="dark" :content="avatar.message" placement="top" popper-class="avatar-hover-tooltip">
                    <span>{{ limitNotRequired ? (generateInitials(avatar.name)) : ($props.avatars.length > $props.limit
                        ? (index === 0 ? avatar.name : generateInitials(avatar.name)) : generateInitials(avatar.name))
                    }}</span>
                </el-tooltip>
            </el-avatar>
        </div>
        </template>
    </el-popover>
    <!-- <div :style="{position: 'relative', cursor: 'pointer', width: computeWidth}" v-else >
                <el-avatar
                    :class="$props.hover ? 'avatar-hover' : ''"
                    :size="`${$props.imgWidth}`"
                    v-for="(avatar, index) in computedAvatars"
                    :key="index"
                    :style="getAvatarStyle(avatar.name, index)"
                    >
                    <el-tooltip class="item" effect="dark" :content="avatar.message" placement="top">
                        <span>{{ limitNotRequired ? (generateInitials(avatar.name)) : ($props.avatars.length > $props.limit ? (index === 0 ? avatar.name : generateInitials(avatar.name)) :  generateInitials(avatar.name)) }}</span>
                    </el-tooltip>
                </el-avatar>
            </div> -->
    <div v-if="$props.editable">
        <!-- <el-button icon="el-icon-plus" size="mini"
                class="selection-avatars"
                :style="{left: computeLeft, 
                    // top: `${$props.imgWidth*1/4}px`,
                     position: 'absolute',
                     }"
                @click="handleClick"
                v-show="false"
                >Add </el-button> -->
        <el-select v-model="selectedValue" v-show="true" filterable placeholder="" :size="`${$props.imgWidth}`"
            class="selection-avatars" :style="{
                left: computeLeft,
                top: `-${$props.imgWidth * 1 / 5}px`,
            }" @change="handleAdd" @visible-change="handleVisibleChange" ref="selection">
            <el-option v-for="avatar in computedSelectionAvatars" :key="avatar.id" :label="avatar.name"
                :value="avatar.id">
                <div>
                    <el-avatar :size="20" :style="getSelectionAvatarStyle(avatar.name)">
                        <span>{{ generateInitials(avatar.name) }}</span>
                    </el-avatar>
                    <span>{{ avatar.name }}</span>
                </div>
            </el-option>
        </el-select>
    </div>
</div>
