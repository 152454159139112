
<div class="container">
  <header class="header">
    <div class="header_left_section">
      <div
        :class="[
          { open: isSidebarVisible },
          'humburger',
          isCrmUser() ? 'humburgerCRM' : '',
        ]"
        v-if="showHamburger"
        @click="toggleSidebar"
      >
        <div class="icon-left"></div>
        <div class="icon-right"></div>
      </div>
      <div>
        <div
          class="backBtn"
          @click="backButtonRoute()"
          v-if="isNotificationPage"
        >
          <img
            src="../../pages/leadManagement/components/assets/backIcon.svg"
          />
          <span>Back</span>
        </div>
        <router-link
          v-if="!isNotificationPage"
          :class="['logo', isCrmUser() ? 'logoCRM' : '']"
          :to="{ name: 'home' }"
        >
          <img
            :src="logoBasedOnDomain"
            alt="Panelicon"
            :class="isCrmUser() ? 'logoForCRM' : 'imgLogo'"
          />
          <span
            class="app-version-text"
            :class="{ 'app-version-text-show': VITE_APP_ENVIRONMENT == 'dev' }"
            >{{ APP_VERSION }}</span
          >
        </router-link>
      </div>
    </div>
    <div class="header_right_section">
      <router-link
        :to="{ name: 'credits' }"
        class="credit_button_wrapper"
        v-if="isCreditsVisible"
      >
        <div class="credit_button_text">
          <div>Credit Balance:</div>
          <div class="credit_balance" v-loading="isFetchingCreditBalance">{{ formatNumber(totalCredits, 'decimal', countryCode )  }}</div>
        </div>
        <div class="arrow_icon">
          <img
            src="../../assets/img/Group 2019.svg"
            alt="view tranaction history"
          />
        </div>
      </router-link>
      <component
        :is="isMobileView ? 'router-link' : 'div'"
        class="notification-bell"
        :class="{ inactive: !isNotificationsLive }"
        :to="{ name: 'notifications' }"
        v-if="isNotificationsAvailable"
      >
      <img
          src="../../assets/img/bell-Icon-task.svg"
          alt="bell-icon"
          @click.self="handleClick"
        />
        <img
          src="../../assets/img/bell-notification.svg"
          alt="unRead"
          class="unread-notification"
          @click.self="handleClick"
          v-show="unreadNotifications"
        />
      </component>
      <div
        class="sub_logo"
        @click="uploadLogo()"
        v-loading="isUpdatingImage"
      >
        <router-link
          v-if="organisation_logo"
          :to="{ name: 'organisationSummary' }"
          event
          class="logo-link"
        >
          <img
            v-if="organisation_logo"
            :src="organisation_logo"
            alt="Solar"
          />
        </router-link>
        <div class="upload-image" v-else>
          <p style="width: max-content;">
            Upload Logo
          </p>
        </div>
      </div>
    </div>
  </header>
  <NotificationPopUp
    @showDrawer="showDrawer"
    @openNotification="handleOpenDrawer"
    :isModal="false"
    :notificationsList="notificationsList"
    :isVisible="isNotificationOpen"
    :isNextPage="computeIsNextPage"
    @closeNotification="closeNotification"
    @loadMoreNotifications="loadMoreNotifications"
    @handleReadAll="handleReadAll"
    @handleUnreadToggle="handleUnreadToggle"
    :notificationFetchError="notificationFetchError"
    :disableScroll="disableScroll"
    :loaderErrorMessage="loaderErrorMessage"
    @switchTabs="onSwitchTabs"
  />
  <leadsDrawer
    v-if="isDrawerOpen"
    :drawer.sync="isDrawerOpen"
    :lead="selectedLead"
    :activeTab="'first'"
    :focusNote="false"
    @close="[(isDrawerOpen = false), (selectedLead = null)]"
  />
  <GlobalDrawer
    :title="'View Task'"
    :isOpen="isTaskDrawerOpen"
    :drawer-size="750"
    :handleCloseDialog="handleTaskDrawerClose"
    class="task-drawer-dialog"
  >
    <template v-if="isTaskDrawerOpen" v-slot:body>
      <taskDrawer
        v-if="isTaskDrawerOpen"
        :reminderData.sync="reminderDetails"
        @updateTask="updateTask"
        @deleteTask="onDeleteTask"
        :mode="'view'"
        :taskData="selectedLead"
        @allowDocumentUpload="handleAllowDocUpload"
        :documentUploaded="documentUploaded"
        @documentUploading="documentUploaded = false"
      />
    </template>
  </GlobalDrawer>
  <setReminder
    :key="setKey"
    :showCreateDrawer="showSetReminderDrawer"
    @close="showSetReminderDrawer = false"
    @save="onSave"
    :drawerSize="400"
    :page="'task'"
  />

  <GlobalDrawer
        :isOpen="isDesignOrderDrawerOpen&&selectedLead?true:false"
        :title="''"
        :handleCloseDialog="handleCloseDesignOrderDrawer"
      >
        <template v-slot:body>
          
          <DesignOrdersDrawer
            :propsData="{
              order: selectedLead,
              users: selectedLead?.users||[],
              orderDetailsPopVisible: true,
              orderStatusOptions: [],
              handleOrderUpdate: handleOrderUpdate,
              drawer: isDesignOrderDrawerOpen,
            }"
            @save="drawer = false"
            :drawerSize="700"
            @close="drawer = false"
        
          />
        </template>
      </GlobalDrawer>
  <input
  type="file"
  :style="{
    color: !isAdmin ? '#999' : '#222',
    cursor: !isAdmin
      ? 'not-allowed'
      : 'pointer',
      display:'none',
  }"
  :disabled="!isAdmin"
  accept=".jpeg,.jpg,.png,"
  @change="uploadFile($event)"
  ref="fileInput"
  name="image"
  id="fileInput"
  />
</div>
