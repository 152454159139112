
<div class="details-component" :style="styleValues">
    <div class="font-cont">
        <p class="heading">Font Size</p>
        <div class="btn-cont">
            <b-icon icon="trash" class="fontButton" @click="$emit('deletePH')"></b-icon>
            <b-icon icon="dash-circle" class="fontButton" @click="decreaseFontSizeValue"></b-icon>
            <p>{{fontSizeValue}}</p>
            <b-icon icon="plus-circle" class="fontButton" @click="increaseFontSizeValue"></b-icon>
        </div>
    </div>
    <p class="para">The space occupied by the actual value of the placeholder might differ.</p>
</div>
