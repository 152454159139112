
<div id="parentContainer">
    <el-dialog :visible="bulkLeadImportState.isImportLeadsPopupVisible" :close-on-click-modal="false" :title="computedTitle"
        @close="onDialogClose">

        <div class='container' v-if="bulkLeadImportState.showFileUploadSection">
            <div class="rows" v-if="!fileUploaded">
                <p class="label">Pipeline
                    <el-tooltip placement="top">
                        <template v-slot:content>
                        <div>All the leads will be moved to the particular<br />pipeline that you
                            select
                            from the dropdown</div>
                        </template>
                        <b-icon icon="exclamation-circle-fill" style="color: #777; cursor: pointer;"></b-icon>
                    </el-tooltip>
                </p>
                <div>
                    <el-select placeholder="Select a Pipeline" v-model="selectedPipeline" name="pipeline">
                        <el-option v-for="stage in pipeline" :key="stage.id" :label="stage.name" :value="stage.id">
                        </el-option>
                    </el-select>
                </div>
            </div>
            <el-tooltip placement="top" :disabled="selectedPipeline ? true : false"
                content="Please select a Pipeline to proceed.">
                <div :class="['uploadContainer', selectedPipeline ? '' : 'cursorClass']" v-if="!fileUploaded" @dragover="dragover" @drop="drop">
                    <fileUpload @openFiles="openFiles" :isBulkLeadsImport="isBulkLeadsImport"
                        :isPipelineSelected="selectedPipeline ? false : true"
                        uploadFileText="Drag & drop your file here"></fileUpload>
                    <p class="font20Normal downloadFile" @click='downloadSampleFile()' ><b-icon icon="download" aria-hidden="true"></b-icon>
                        Download
                        sample file</p>
                    <p class="font20Normal">Only <b>CSV</b> & <b>XLSX</b> files are supported</p>
                </div>
            </el-tooltip>
            <div class="uploadSuccess" v-if="showValidFileSection">
                <img src="./assets/successIcon.svg" />
                <p class="success">Your file has been successfully uploaded</p>
                <img :src="fileType" />
                <p class="fileName" :title="bulkLeadImportState.fileName">{{ bulkLeadImportState.fileName }}</p>
            </div>
            <div class="uploadSuccess" v-if="showInvalidFileSection">
                <img src="./assets/errorIcon.svg" />
                <p class="success">Error while uploading the file!
                    <br />Please <span class="blueTxt" @click="tryAgainFunc()">Try Again</span>
                </p>
            </div>
        </div>
        <div class="footerContainer" v-if="bulkLeadImportState.showFileUploadSection">
            <el-button class="cancelBtn commonBtnClass" @click="onDialogClose">Cancel</el-button>
            <el-button type="primary " class="nextBtn commonBtnClass" @click="nextBtnFunc()"
                :disabled="disabledNextBtnFunc">Next</el-button>
        </div>
        <div v-if="bulkLeadImportState.showInputsSection">
            <LeadFieldsMapping ref="bulkLead"
                v-if="bulkLeadImportState.showLeadFieldsSection" />
            <StageMapping ref="stageValues" v-if="bulkLeadImportState.showStageFieldsSection" />
        </div>
        <div class="footerContainer" v-show="bulkLeadImportState.showInputsSection">
            <el-button class="cancelBtn commonBtnClass" @click="previousBtnFunc()">Previous</el-button>
            <el-tooltip placement="top" :disabled="!bulkLeadImportState.isValidateBtnDisabled">
                <template v-slot:content>
                    <div>Please select all the mandatory fields to proceed.</div>
                </template>
                <el-button type="primary " class="nextBtn commonBtnClass" @click="refFeildsMappingAndValdateFunc"
                    :disabled="bulkLeadImportState.isValidateBtnDisabled" v-loading="isLoading">Validate</el-button>
            </el-tooltip>
        </div>
    </el-dialog>
</div>
