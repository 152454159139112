<template>
    <div>
      
              <pdfPuppeteer
                :isInPreviewMode="isInPreviewMode && !isInContractListPage"
                :isInContractListPage="isInContractListPage"
                :isContractInDesignLevel="isContractInDesignLevel"
                :includePaymentSchedule="includePaymentSchedule"
                v-if="editorType=='pdf'"
              />
              <paginatedContentForPuppeteer
                v-else
                :isInPreviewMode="isInPreviewMode && !isInContractListPage"
                :isInContractListPage="isInContractListPage"
                :isContractInDesignLevel="isContractInDesignLevel"
                :includePaymentSchedule="includePaymentSchedule"
							  @previewLoaded="getData"
                @exitPreview="closeDialog"  
						  />
       
       
         
    </div>
</template>

<script>
import paginatedContentForPuppeteer from './textEditor/paginatedContentForPuppeteer.vue';
import pdfPuppeteer from './textEditor/pdfPuppeteer.vue';
import { mapState, mapActions } from "pinia";
import { useContractManagementStore } from '../../../stores/contractManagement';
    export default {
      emits: ["update:open"],
        components:{ paginatedContentForPuppeteer, pdfPuppeteer},
        data(){
            return{
              isInPreviewMode:true,
            }
        
        },
        props:{
          open:{
            type:Boolean,
            default:true
          },
          isContractInDesignLevel:{
            type:Boolean,
            default:false
          },
          isInContractListPage:{
            type:Boolean,
            default:false
          },
          includePaymentSchedule:{
            type:Boolean,
            default:false
          },
          editorType:{
            default: "pdf"
          },
        },
        methods:{
            closeDialog(){
                this.CMStore.isTemplatePreviewing = false;
                this.$emit('update:open',false);
            },
            getData(){
              
            }
        },
        computed: {
          ...mapState(useContractManagementStore, {
              CMStore: (state) => state
          })
        },
      
    }
</script>

<style scoped>

</style>