
<div id="parentContainer">
  <el-dialog
    :visible="isGenericPayNowPopupVisible"
    :close-on-click-modal="false"
    title="Order Details"
    width="976px"
    @close="onDialogClose"
  >
    <div class="container">
      <div class="leftContainer">
        <div class="headerContainer">
          <h3 class="headings">Permit Package with Battery</h3>
          <p class="headContent">
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
            erat, sed diam voluptua.
          </p>
        </div>
        <div class="drpDownContainer">
          <el-select v-model="value" placeholder="Select">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="checkBoxContainer">
          <el-checkbox v-model="checked"
            >PE Electrical (119 Credits) - 2 Working Days
            <div class="hover_information">
              <i class="fas fa-info-circle"></i>
              <div class="tooltip">
                <p>
                  The percentage of system cost minus grants that will be
                  financed with the loan
                </p>
              </div>
            </div>
          </el-checkbox>
          <el-checkbox v-model="checked"
            >PE Structural (119 Credits) - 2 Working Days
            <div class="hover_information">
              <i class="fas fa-info-circle"></i>
              <div class="tooltip">
                <p>
                  The percentage of system cost minus grants that will be
                  financed with the loan
                </p>
              </div>
            </div>
          </el-checkbox>
          <el-checkbox v-model="checked"
            >Nearmap Hi-Res Imagery (5 Credits)
            <div class="hover_information">
              <i class="fas fa-info-circle"></i>
              <div class="tooltip">
                <p>
                  The percentage of system cost minus grants that will be
                  financed with the loan
                </p>
              </div>
            </div>
          </el-checkbox>
          <el-checkbox v-model="checked"
            >Nearmap 3D Data (5 Credits)
            <div class="hover_information">
              <i class="fas fa-info-circle"></i>
              <div class="tooltip">
                <p>
                  The percentage of system cost minus grants that will be
                  financed with the loan
                </p>
              </div>
            </div>
          </el-checkbox>
        </div>
        <div class="footer">
          <p class="note">
            <span class="boldTxt">Note:</span>
            Our design team will only create the design in the software.
          </p>
          <el-checkbox v-model="checked">
            <p class="agreeContent">
              I agree that after ordering this service, we will fill up the
              consumption, incentives and financial data in the order and
              download the report.
            </p>
          </el-checkbox>
        </div>
      </div>
      <div class="rightContainer">
        <div class="headerContainer">
          <h3 class="headings">Payment Details</h3>
          <div class="packageDetails">
            <p class="details">Permit Package</p>
            <p class="detailsValue">119 Credits</p>
          </div>
        </div>
        <div class="selectedPayments">
          <div class="packageDetails">
            <p class="detailsSmall">PE Electrical</p>
            <p class="detailsValue">10 Credits</p>
          </div>
          <div class="packageDetails">
            <p class="detailsSmall">PE Structural</p>
            <p class="detailsValue">10 Credits</p>
          </div>
          <div class="packageDetails">
            <p class="detailsSmall">Nearmap Hi-Res Imagery</p>
            <p class="detailsValue">5 Credits</p>
          </div>
          <div class="packageDetails">
            <p class="detailsSmall">Nearmap 3D Data</p>
            <p class="detailsValue">5 Credits</p>
          </div>
        </div>
        <div class="estDeleiveryTimeContainerOne">
          <div class="packageDetails">
            <p class="details">Estimated Delivery Time</p>
            <p class="detailsValue">3 Working Day</p>
          </div>
        </div>
        <div class="estDeleiveryTimeContainerTwo">
          <h3 class="creditBal">Credit Balance</h3>
          <div class="packageDetails">
            <div class="crdtBalChkBoxContainer">
              <el-checkbox v-model="checked"
                >Promotional Credit Balance
                <div class="hover_information">
                  <i class="fas fa-info-circle"></i>
                  <div class="tooltip">
                    <p>
                      Use your bonus credits for sales proposals, revision requests, self-design creation, and some upgrades on a design level.
                    </p>
                  </div>
                </div>
              </el-checkbox>
            </div>
            <p class="details">0</p>
          </div>
        </div>
        <div class="purCredBalContainer">
          <div class="packageDetails">
            <p class="detailsSmall">Purchased Credit Balance</p>
            <p class="detailsValue">0</p>
          </div>
        </div>
        <div class="footerBtnContainer">
          <div class="packageDetails">
            <p class="totalPayCred">Total Payable Credits:</p>
            <p class="totalPayCredVal">179</p>
          </div>
          <el-button class="footerBtn" type="primary"
            >Add 179 Credits</el-button
          >
        </div>
      </div>
    </div>
  </el-dialog>
</div>
