import axios from 'axios'

export default{
    GET_TEMPLATES() {
        return axios.get('api/template/');
    },
    LOAD_MORE_TEMPLATES(url) {
        return axios.get(url);
    },
    FETCH_QUESTIONS(templateId) {
        // Also works as a get template by id call
        // return axios.get(`/api/template/${templateId}/`);
        return axios.get(`/api/template/${templateId}/`)
        .then(response => {
                const reorderedSections = reorderSectionsAndFields(response.data.sections, response.data.template_type);
                return { ...response, data: { ...response.data, sections: reorderedSections } };
        });
    },
    FETCH_ANSWERS(referenceId) {
        return axios.get(`api/answers/?site_survey=${referenceId}`);
    },
    DELETE_RESPONSE(referenceId) {
        return axios.delete(`api/answers/${referenceId}/`);
    },
    CREATE_TEMPLATE_FOR_ORDER(postData){
        return axios.post(`api/template/get_or_create_template/`,postData);
    },
    CREATE_TEMPLATE(createTemplateData) {
        return axios.post(`api/template/`, createTemplateData);
    },
    DELETE_TEMPLATE(templateId) {
        return axios.delete(`/api/template/${templateId}/`);
    },
    SEARCH_TEMPLATE(searchText) {
        return axios.get(`/api/template/?`, {
            params: {
                name: searchText,
            },
        });  
    },
    PATCH_TEMPLATE(templateId,templateBody){
        return axios.patch(`/api/template/${templateId}/`,templateBody);
    },
    CREATE_SECTION(sectionBody){
        return axios.post(`/api/template-section/`,sectionBody);
    },
    PATCH_SECTION(sectionId, sectionBody){
        return axios.patch(`/api/template-section/${sectionId}/`,sectionBody);
    },
    DELETE_SECTION(sectionId){
        return axios.delete(`/api/template-section/${sectionId}/`);
    },
    CREATE_FIELD(fieldBody){
        return axios.post(`/api/template-section-question/`, fieldBody);
    },
    PATCH_FIELD(fieldId, fieldBody){
        return axios.patch(`/api/template-section-question/${fieldId}/`, fieldBody);
    },
    DELETE_FIELD(fieldId){
        return axios.delete(`/api/template-section-question/${fieldId}/`);
    },
    DELETE_FILE_FROM_BLOB(fileObj){
        return axios.post(`api/answers/delete-from-container/`,fileObj);
    }
}

function reorderSectionsAndFields(sections, templateType) {
    // Reorder sections based on sequence key
    // we dont want to use any sequence for expert_service type of template just in case of sections
    if(templateType!='expert_service')
        sections.sort((a, b) => a.sequence - b.sequence);

    // Loop through each section and reorder fields
    for (const section of sections) {
      if (section.fields) {
        // Reorder fields based on sequence key
        section.fields.sort((a, b) => a.sequence - b.sequence);
      }
    }
    console.log("sections", sections);
    return sections;
}