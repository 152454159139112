
<div class="flexCardContainer">
    <div class="sectionCard">
        <div class="sectionNoContainer" v-if="isCardOpen">
            <p class="sectionNo">
                 {{"Section "+ sectionIndex }}
            </p>
        </div>
        <div :class="['cardBorderContainer', !isCardOpen ? 'padding' : '']">
            <div class="closeCard" v-if="!isCardOpen" @click="cardToggleFunc()">
                <p class="heading">{{sectionData.title}}</p>
                <i class="el-icon-arrow-down icons" ></i>
            </div>
            <div class="flexInputs" v-else>
                <div class="inputsCont" v-if="!isPreviewMode">
                    <div class="flexColumn">
                        <el-input  placeholder="Section Title" v-model="sectionData.title" @change="prepareSectionObjectToStore('title')"></el-input>
                    </div>
                    <div class="flexColumn">
                        <el-input class="placeholderPadding" type="textarea" :rows="3" placeholder="Description" v-model="sectionData.description" @change="prepareSectionObjectToStore('description')"></el-input>
                    </div>
                </div>
                <div class="previewContainer" v-else>
                    <div style="cursor:pointer" @click="cardToggleFunc()">
                        <p class="heading">{{ sectionData.title }}</p>
                        <hr class="lineHR"/>
                    </div>
                    <p class="description">{{ filteredDescription }}
                    </p>
                </div>
                <i class="el-icon-arrow-up icons" @click="cardToggleFunc()"></i>
            </div>
            <div  v-if="isCardOpen">
                <draggable class="fieldsCardCont" v-model="sectionData.fields" @change="updateFieldPosition">
                    <FieldsCard
                        v-for="(field, index) in sectionData.fields"
                        :key="index"
                        :fieldIndex="index + 1"
                        :fieldsLength="sectionData.fields.length" 
                        :field="field" 
                        :templateId="templateId"
                        :sectionIndex="sectionIndex"
                        @deleteCurrentField="deleteCurrentField" 
                        @duplicateCurrentField="duplicateCurrentField"
                        @duplicateCurrentFile="duplicateCurrentFile"
                        @deleteCurrentFile="deleteCurrentFile"
                        @patchTemplate="propagatePatchDataFromField"
                        ref="fieldsRef"
                    />
                </draggable>
            </div>
        </div>
    </div>
    <div class="cardOptions" v-if="isCardOpen && !isPreviewMode">
        <el-tooltip class="item" effect="dark" content="Add Field" placement="left" :disabled="!(tooltipVisible && tooltipIcon === 'addField')">
            <img src="../assets/PlusCircle.svg" alt="" class="icons" @click="addField" @mouseenter="showTooltip('addField',$event)" @mouseleave="hideTooltip"/>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="Add Section" placement="left" :disabled="!(tooltipVisible && tooltipIcon === 'addSection')">
            <img src="../assets/view-stacked.svg" alt="" class="icons" @click="addSection" @mouseenter="showTooltip('addSection', $event)" @mouseleave="hideTooltip"/>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="Duplicate Section" placement="left" :disabled="!(tooltipVisible && tooltipIcon === 'duplicateSection')">
            <i class="icon copy-alt icons"  @click="duplicateSection" @mouseenter="showTooltip('duplicateSection', $event)" @mouseleave="hideTooltip"></i>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="Move Section" placement="left" :disabled="!(tooltipVisible && tooltipIcon === 'moveSection')">
            <img src="../assets/ArrowsMove.svg" :class="{ 'disabled-icon': sectionsLength === 1 }" @click="openMoveSectionCard" alt="" class="icons" @mouseenter="showTooltip('moveSection', $event)" @mouseleave="hideTooltip"/>
        </el-tooltip>
        
        <el-tooltip class="item" effect="dark" content="Delete Section" placement="left" :disabled="!(tooltipVisible && tooltipIcon === 'deleteSection')">
            <i class="icon delete-alt icons" @click="deleteCurrentSection" :class="{ 'disabled-icon': sectionsLength === 1 }" @mouseenter="showTooltip('deleteSection', $event)" @mouseleave="hideTooltip"></i>
        </el-tooltip>
    </div>
    <MoveSectionPopup 
    v-show="openMoveCard" 
    :showMoveSectionPopup="openMoveCard" 
    :isEditTemplateMode="isEditTemplateMode" 
    @sectionMoved="prepareSectionObjectToStore('sequence',  $event)"
    @close-popup="openMoveCard=false"/>    
</div>
