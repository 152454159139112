 function generateQueryParams(params) {

    const queryParams = Object.entries(params)
    .filter(([key, value]) => {
      if (Array.isArray(value)) {
        return value.length > 0;
      }
      return value !== null && value !== '';
    })
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        const encodedValues = value.map(item => encodeURIComponent(item));
        return `${key}=${encodedValues.join(',')}`;
      }
      return `${key}=${encodeURIComponent(value)}`;
    })
    .join('&');
    return queryParams
}

const queryParamsFormater=(data)=>{
  const filteredObj = {};

for (const [key, value] of Object.entries(data)) {
if (key && value) {
filteredObj[key] = value;
}
}
const queryParams=new URLSearchParams(
filteredObj
).toString();
return queryParams

}

export {
  generateQueryParams,
  queryParamsFormater
}

