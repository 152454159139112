import OutlinePoints from "../../objects/subObjects/OutlinePoints";
import { SALES_MODE_POINT_SIZE } from "../../objects/visualConstants";


export default class SalesModeOutlinePoints extends OutlinePoints {
    constructor(x, y, z, object, stage, colorMap = null) {
        super(x, y, z, object, stage, colorMap);
        let pointSize = SALES_MODE_POINT_SIZE;
    }

    highlightOnHover() {
        this.updateColor(this.highlightColor);
        this.vertexMesh.material.size = 14 ;
    }

    unHighlight() {
        this.updateColor(this.defaultPointColor);
        this.vertexMesh.material.size = SALES_MODE_POINT_SIZE ;
    }
}