import axios from 'axios';
import {
    DATABASE_URL,
    useBetaForBifacialGeneration,
} from '../../constants';
import API from "@/services/api/";

export default {

    async FETCH_GENERATION_NUMBERS(designId) {
        let bifacialResponse, isBifacialEnabled;
        if (useBetaForBifacialGeneration) {
            bifacialResponse = await API.DESIGNS.FETCH_BIFACIAL_INFO(designId);
            isBifacialEnabled = bifacialResponse.data.is_bifacial_enabled;
        }
        if (useBetaForBifacialGeneration && isBifacialEnabled) {
            return axios.get(`https://betaapi.thesolarlabs.com/api/designs/${designId}/generation_engine/`);  
        }
        return axios.get(DATABASE_URL + 'api/designs/' + designId + '/generation_engine/');
    },
    FETCH_PROBABILITY(designId) {
        return axios.get(DATABASE_URL + 'api/designs/' + designId + '/probabilty_distribution/');
    },

};
