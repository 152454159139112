<template>
    <div class="popup">
        <GlobalDialog 
    :dialogTitle="''" 
    :dialogVisible.sync="openDialog" 
    :width="'495px'" 
    @handleClose="$emit('handleClose')"
     :isModal="false"
    >
          
                <template v-slot:body >
             <div class="content">
                <p class="icon-container">
                    <b-icon
        
              class="info-icon"
              :icon="'exclamation-circle-fill'"
              :rotate="180"
            ></b-icon>
      </p>
                <p class="content-item">You are about to assign this lead to a PM. Before you continue, ensure you have the following documents:</p>
                <p class="content-item-option">1. Accepted proposal</p>
                <p class="content-item-option">2. Signed contract</p>
                <p class="content-item-option">3. Financial contract (for financial deals only)</p>
                <p class="content-item">Would you like to continue?</p>

             </div>
                </template>
                <template v-slot:footer>
                    <div class="footer-container">

                        <div>
                            <el-button plain   @click="$emit('handleClose')">Cancel</el-button
              >           
              <el-button
                type="primary"
                @click="$emit('handleUpdateOwner')"
                >Continue</el-button
              >
                        </div>
                    </div>
       
                </template>
                
                </GlobalDialog>

    </div>
</template>

<script>
import GlobalDialog from '../../commonComponents/GlobalDialog.vue'; 
 
    export default {
        emits: ["handleClose", "handleUpdateOwner"],
        data(){
            return{
                
            }
        },
        moumted(){
            this.open=this.$props.openDialog
        },
        components:{
            GlobalDialog
        },
        props:{
            openDialog:{
                type:Boolean,
                default:false
            }
        }
        
    }
</script>

<style scoped>
.footer-container{
    display: flex;
    justify-content: center;
    gap: 16px;
    padding:16px 0px;
}
.content{
    font-size: 14px;
    font-weight: 500;
    padding: 16px 16px
}
.content-item{
    padding-bottom: 16px;
}
.content-item-option{
    padding: 0 0 10px 16px;
}
.info-icon{
    font-size: 4rem;
    color:#FF8F40;
}
.icon-container{
    display: flex;
    justify-content: center;
    padding-bottom: 16px
}
.el-button.is-plain {
    color: #409EFF;
    background: #fff;
    border-color: #409EFF;
}

</style>