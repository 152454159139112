
<el-timeline style="width: 100%">
  <el-timeline-item
    v-for="(task, index) in computeTaskList"
    :key="index"
    :icon="task.icon"
    :type="task.type"
    :color="task.color"
    :size="task.size"
    :timestamp="task.timestamp"
  >
    <div class="avatar">
      <el-avatar :style="getAvatarStyle(task[0].createdby.name)">
        <span class="avatar-initials">{{
          generateInitials(task[0].createdby.name)
        }}</span>
      </el-avatar>
    </div>
    <h3 class="activity-header">{{ task[0].createdby.name }}</h3>
    <p v-for="(taskItem, index) in task" :key="index" class="activities">
      {{ taskItem.name }}
      <span class="activity-date" :title="new Date(taskItem.activitylog_created)">{{
        generateActivityTime(taskItem.activitylog_created)
      }}</span>
    </p>
  </el-timeline-item>
</el-timeline>
