import axios from 'axios';

export default {

    GET_SOLUTIONS_PAYMENTS(orderId) {
        return axios.get(`api/paymentlinks/?order_id=${orderId}` );
    },
    GENERATE_SOLUTIONS_PAYMENT_LINK(postData) {
        return axios.post(`api/paymentlinks/generate_payment_link/`,postData );
    },
    SEND_SOLUTIONS_PAYMENT_LINK(paymentId,postData) {
        return axios.post(`api/paymentlinks/${paymentId}/send_payment_link/`,postData );
    }

    
}