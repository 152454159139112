<template>
  <el-timeline style="width: 100%">
    <el-timeline-item
      v-for="(task, index) in computeTaskList"
      :key="index"
      :icon="task.icon"
      :type="task.type"
      :color="task.color"
      :size="task.size"
      :timestamp="task.timestamp"
    >
      <div class="avatar">
        <el-avatar :style="getAvatarStyle(task[0].createdby.name)">
          <span class="avatar-initials">{{
            generateInitials(task[0].createdby.name)
          }}</span>
        </el-avatar>
      </div>
      <h3 class="activity-header">{{ task[0].createdby.name }}</h3>
      <p v-for="(taskItem, index) in task" :key="index" class="activities">
        {{ taskItem.name }}
        <span class="activity-date" :title="new Date(taskItem.activitylog_created)">{{
          generateActivityTime(taskItem.activitylog_created)
        }}</span>
      </p>
    </el-timeline-item>
  </el-timeline>
</template>
<script>
import { generateAvatarColor } from "../../../../utils";
import generateTimeDifference from "../../../../utils/timeDifferenceGenerator";
export default {
  name: "TaskActivityTimeline",
  props: {
    taskList: {
      type: Array,
      default() {
        return []
      }
    },
  },
  data() {
    return {};
  },
  methods: {
    getAvatarStyle(value) {
      const backgroundColor = generateAvatarColor(value);
      return {
        backgroundColor: backgroundColor,
        fontSize: "20px",
        height: "30px",
        width: "30px",
        fontSize: "14px",
        fontWeight: "400",
        paddingBottom: "10px",
      };
    },
    generateInitials(name) {
      if (!name || name.trim().length === 0) {
        return "N/A"; // Return empty string for blank names
      }
      const names = name.trim().split(" ");
      const initials = names.map((n) => n.charAt(0).toUpperCase());
      if (initials.length === 1) {
        return initials[0]; // Return single initial if only one name is available
      } else {
        return initials[0] + initials[initials.length - 1]; // Return first and last initials for multiple names
      }
    },
    generateActivityTime(activityDate) {
      return generateTimeDifference(activityDate, false);
    },
  },
  computed: {
    computeTaskList() {
      let filteredArr = [];
      let filteredElement = [];
      let prevId = null;
      this.$props.taskList.forEach((e, index) => {
        if (index === 0) {
          prevId = this.$props.taskList[index].createdby.id;
        } else {
          prevId = this.$props.taskList[index - 1].createdby.id;
        }
        if (prevId == e.createdby.id) {
          filteredElement.push(e);
        } else {
          filteredArr.push([...filteredElement]);
          filteredElement = [];
        }
        if (prevId !== e.createdby.id) {
          filteredElement.push(e);
        }
        if (index === this.$props.taskList.length - 1) {
          filteredArr.push([...filteredElement]);
          filteredElement = [];
          prevId = null;
        }
      });
      return filteredArr;
    },
  },
  created() {
    console.log(this.taskList);
  },
};
</script>
<style scoped>
.avatar {
  position: absolute;
  left: -10px;
  top: -5px;
}
.avatar-initials {
  position: relative;
  top: -5px;
}
.activity-header {
  font-size: 14px;
  font-weight: 600;
  color: #222222;
  margin-bottom: 6px;
}
.activities {
  position: relative;
  font-size: 14px;
  font-weight: 400;
  margin: 4px 4px 4px 6px;
  white-space: pre-line;
  word-break: break-word;
  cursor: default;
}
.activity-date,
.header-activity-date {
  color: #777777;
  font-size: 14px;
  font-weight: 400;
  margin-left: 5px;
}
.header-activity-date {
  font-size: 16px;
  font-weight: 400;
  position: relative;
  top: 2px;
}
.activity-header,
.avatar {
  cursor: default;
}
</style>
