import * as THREE from "three";
import * as utils from "../../../../core/utils/utils";

const LEFT = true;
const RIGHT = false;

export const CAPPING_TYPES = {
    RIDGE: "RIDGE",
    HIP: "HIP",
    VALLEY: "VALLEY",
}



export default class Capping {

    static geometry = (() => {
        const plane = new THREE.PlaneGeometry(1, 1);
        utils.computeUVs(plane);
        return plane;
    })();
    static materialOptions = {
        color: 0x000000,
        side: THREE.DoubleSide,
        roughness:0,
    };
    static edges = new THREE.EdgesGeometry(Capping.geometry);
    static outlineMaterialOptions = {
        color: 0xffffff,
    }

    /**
     * 
     * @param {THREE.Vector3[]} polygon - Acyclic with the bottom/primary edge missing
     * @param {THREE.Vector2[]} setbackVertices - Will be used for intersection stuff
     * @param {THREE.Vector3} normal
     */
    constructor(polygon, cappingDimensions, normal, setbackVertices = []) {
        this.polygon = polygon;
        this.setbackVertices = setbackVertices;
        this.cappingDimensions = cappingDimensions;
        this.normal = normal;
    }

    // TODO: Make a version which considers intersections with other cappings.
    /**
     * `start` and `end` need to be 3D points as point sorting is done with z-values.
     * `direction` to know where to extend the capping.
     * 
     * `LEFT|true` for anti-clockwise and `RIGHT|false` for clockwise
     * for the case of a typical polygon.
     */
    getCappingForLine({
        start = new THREE.Vector3(),
        end = new THREE.Vector3(),
        direction = LEFT,
        tilt = (Math.PI/180) * 1,
        objectsGroup = new THREE.Group(),
        type = CAPPING_TYPES.RIDGE,
        instancedMesh = new THREE.InstancedMesh(),
        outlineMaterial = new THREE.LineBasicMaterial(),
        indexOffset = 0,
    }) {
        let overlap = 0.01;
        let capWidth;
        let capLength;

        if (type === CAPPING_TYPES.HIP) {
            capWidth = this.cappingDimensions.hip.width;
            capLength = this.cappingDimensions.hip.length;
            overlap = this.cappingDimensions.hip.overlap;
        }else if(type === CAPPING_TYPES.RIDGE){
            capWidth = this.cappingDimensions.ridge.width;
            capLength = this.cappingDimensions.ridge.length;
            overlap = this.cappingDimensions.ridge.overlap;
        }else if(type === CAPPING_TYPES.VALLEY){
            capWidth = this.cappingDimensions.valley.width;
            capLength = this.cappingDimensions.valley.length;
            overlap = this.cappingDimensions.valley.overlap;
        }
        
        // This flag sets true when the segmentation starts from the end Vector.
        let isFlipped = false;
        if (start.z === end.z) {
            if (start.y === end.y) {
                isFlipped = (start.x - end.x) > 0 ? true : false;
            } else {
                isFlipped = (start.y - end.y) > 0 ? true : false;
            }
        } else {
            isFlipped = (start.z - end.z) > 0 ? true : false;
        }

        const dirVector = new THREE.Vector3().subVectors(end, start);
        const length = dirVector.length();
        dirVector.normalize();

        const count = Math.ceil((length - (capLength * 0.3)) / (capLength - overlap));
        const fullCapCount = Math.floor((length - overlap) / (capLength - overlap));

        objectsGroup.add(instancedMesh);

        const bufferVector = dirVector.clone();
        if (direction === LEFT) {
            bufferVector.applyAxisAngle(this.normal, Math.PI / 2);
        }
        else {
            bufferVector.applyAxisAngle(this.normal, -Math.PI / 2);
        }
        if (isFlipped) dirVector.negate();
        bufferVector.normalize();

        const flatDir = dirVector.clone();
        flatDir.z = 0;
        flatDir.normalize();

        const flatBuffer = flatDir.clone();
        if (direction === LEFT) {
            flatBuffer.applyAxisAngle(new THREE.Vector3(0, 0, 1), Math.PI / 2);
        }
        else {
            flatBuffer.applyAxisAngle(new THREE.Vector3(0, 0, 1), -Math.PI / 2);
        }


        const matrix = new THREE.Matrix4();
        const origin = isFlipped ? end.clone() : start.clone();
        matrix.makeBasis(bufferVector, dirVector, this.normal);
        matrix.setPosition(origin);
        const faceTr = new THREE.Vector3();
        const faceQu = new THREE.Quaternion();
        const faceEu = new THREE.Euler();
        matrix.decompose(faceTr, faceQu, new THREE.Vector3());
        faceEu.setFromQuaternion(faceQu, 'YZX');

        const flatMat = new THREE.Matrix4();
        flatMat.makeBasis(flatBuffer, flatDir, new THREE.Vector3(0, 0, 1));
        flatMat.setPosition(origin);
        const invFlat = flatMat.clone().invert();

        let index = 0;
        for (let k = capLength; k < length; k += (capLength - overlap)) {
            const transform = this.makeTransformationMatrix(
                capWidth, capLength,
                type === CAPPING_TYPES.VALLEY,
                matrix, flatMat, invFlat, faceEu,
                tilt, dirVector, k,
            );
            instancedMesh.setMatrixAt(indexOffset + index, transform);

            index++;
        }


        const dummyMatrix = new THREE.Matrix4();
        // Calculate the remaining length after the full caps have been added
        let remainingLength = length - (capLength - overlap) * index;

        if (remainingLength > (capLength * 0.3)) {
            const transform = this.makeTransformationMatrix(
                capWidth, remainingLength,
                type === CAPPING_TYPES.VALLEY,
                matrix, flatMat, invFlat, faceEu,
                tilt, dirVector, length,
            );
            instancedMesh.setMatrixAt(indexOffset + index, transform);

            index++;
        }

        // Create a LineSegments object for each instance
        for (let i = 0; i < index; i++) {
            const line = new THREE.LineSegments(Capping.edges, outlineMaterial);
            instancedMesh.getMatrixAt(indexOffset + i, dummyMatrix)
            line.applyMatrix4(dummyMatrix);
            line.matrixAutoUpdate = false;
            objectsGroup.add(line);
        }

        if (count !== index)
            console.warn('There is a mismatch in count');

        return {fullCapCount, count};
    }

    // TODO: Modify this function later to be as generic as needed.
    /**
     * Gives out the matrix that is needed to put a capping on a face along the edge.
     * Useful for instanceMatrices.
     * @param {Number} capWidth 
     * @param {Number} segmentLength - Either capLength or remaining length at end
     * @param {Boolean} isValley 
     * @param {THREE.Matrix4} matrix 
     * @param {THREE.Matrix4} flatMat 
     * @param {THREE.Matrix4} invFlat - Inverse of flatMat
     * @param {THREE.Euler} faceEu - Eulers for the face
     * @param {Number} tilt - In radians
     * @param {THREE.Vector3} dirVector 
     * @param {Number} offset - Denote where the latter end of of tile should lie
     * @returns {THREE.Matrix4}
     */
    makeTransformationMatrix(capWidth, segmentLength, isValley,
        matrix, flatMat, invFlat, faceEu, tilt, dirVector, offset) {
        const dummyObject = new THREE.Object3D();

        dummyObject
            .applyMatrix4(new THREE.Matrix4()
            .makeScale(capWidth, segmentLength, 1));

        if (isValley)
            dummyObject.translateY(-capWidth);


        dummyObject.translateX(capWidth / 2);
        dummyObject.translateY(segmentLength / 2);
        dummyObject.updateMatrix();
        dummyObject.applyMatrix4(matrix);
        dummyObject.updateMatrix();

        dummyObject.applyMatrix4(invFlat);
        dummyObject.updateMatrix();
        // Aligning the top edge with the x-axis
        dummyObject.position.y -= (segmentLength * Math.cos(faceEu.x));
        dummyObject.position.z -= (segmentLength * Math.sin(faceEu.x));
        dummyObject.updateMatrix();
        dummyObject.rotateOnWorldAxis(new THREE.Vector3(1, 0, 0), -tilt);
        dummyObject.updateMatrix();
        // Bringing it back up
        dummyObject.position.z += (segmentLength * Math.sin(faceEu.x));
        dummyObject.position.y += (segmentLength * Math.cos(faceEu.x));
        dummyObject.updateMatrix();
        dummyObject.applyMatrix4(flatMat);
        dummyObject.updateMatrix();
        dummyObject.position.addScaledVector(dirVector, offset - segmentLength);
        dummyObject.updateMatrix();

        dummyObject.position.z += (segmentLength * Math.abs(Math.sin(tilt)));
        dummyObject.position.z +=0.08
        dummyObject.updateMatrix();

        return dummyObject.matrix;
    }
};