<template>
    <div class="parentContainer">
        <el-dialog :visible="isClientInformationPopupVisible" :close-on-click-modal="false" title="Send Document"
            @close="onDialogClose" :show-close="!disableCloseIcon" :modal-append-to-body="isModal">
            <div class="container">
                <el-form ref="form" :model="form" @submit.native.prevent class="formContainer">
                    <div>
                        <p class="font14Bold">
                            First Client Name <span v-if="showFirstRow" class="asterisk">*</span>
                        </p>
                        <el-input 
                            v-model="form.firstClientName"
                            v-validate="requiredValidationForFirst"
                            name="first_client_name"
                            placeholder="Enter Client Name"
                            />
                                <p
                                    v-show="errors.has('first_client_name')"
                                    class="errorMsg"
                                   >
                                   This field is required.
                                </p>
                    </div>
                    <div>
                        <p class="font14Bold">
                            First Client Email <span v-if="showFirstRow" class="asterisk">*</span>
                        </p>
                        <el-input 
                            v-model="form.firstClientEmail"
                            v-validate="requiredValidationForFirstEmail"
                            name="first_client_email"
                            placeholder="Enter Client Email"
                            />
                            <p 
                             v-show="errors.has('first_client_email')"
                            class="errorMsg"
                             >
                            Invalid email id
                            </p>
                    </div>
                    <div>
                        <p class="font14Bold">
                            Second Client Name <span v-if="showSecondRow" class="asterisk">*</span>
                        </p>
                        <el-input
                             v-model="form.secondClientName"
                            v-validate="requiredValidationForSecond"
                            name="second_client_name"
                            placeholder="Enter Client Name"
                            />
                            <p 
                                v-show="errors.has('second_client_name')"
                                class="errorMsg"
                                >
                                This field is required.
                            </p>
                    </div>
                    <div>
                        <p class="font14Bold">
                            Second Client Email <span v-if="showSecondRow" class="asterisk">*</span>
                        </p>
                        <el-input 
                            v-model="form.secondClientEmail"
                            v-validate="requiredValidationForSecondEmail"
                            name="second_client_email"
                            placeholder="Enter Client Email"
                            />
                            <p 
                            v-show="errors.has('second_client_email')"
                            class="errorMsg"
                             >
														 Invalid email id
                            </p>
                    </div> 
                    <div >
                        <p class="font14Bold">
                            Third Client Name <span v-if="showThirdRow" class="asterisk">*</span>
                        </p>
                        <el-input 
                            v-model="form.thirdClientName" 
                            v-validate="requiredValidationForThird"
                            name="third_client_name"
                            placeholder="Enter Client Name"
                            />

                            <p
                             v-show="errors.has('third_client_name')"
                            class="errorMsg">
                            This field is required.
                        </p>
                    </div>
                    <div>
                        <p class="font14Bold">
                            Third Client Email <span v-if="showThirdRow" class="asterisk">*</span>
                        </p>
                        <el-input
                            v-model="form.thirdClientEmail"
                            v-validate="requiredValidationForThirdEmail"
                            name="third_client_email"
                             placeholder="Enter Client Email"
                             />
                            <p
                            v-show="errors.has('third_client_email')"
                             class="errorMsg" 
                            >
                            Invalid email id
                        </p>
                    </div>
                    <div>
                        <p class="font14Bold">
                            Envelope Name <span class="asterisk">*</span>
                        </p>
                        <el-input
                            v-model="form.envelopeName"
                            v-validate="requiredValidation"
                             name="envelope"
                            placeholder="Enter Envelope Name" />
                            <p 
                            v-show="errors.has('envelope')"
                            class="errorMsg"
                             >
                             This field is required.
                            </p>
                    </div>
                    <div>
                        <p class="font14Bold">
                            CC to
                        </p>
                        <el-input 
                            v-model="form.cc" 
                            v-validate="emailValidation"
                            name="cc_to"
                            placeholder="Enter Email"
                            />
                            <p 
                            v-show="errors.has('cc_to')"
                            class="errorMsg" 
                            >
														Invalid email id
                        </p>
                    </div>
                </el-form>
                <div class="btnContainer">
                    <el-button
                     type="" 
                    class="addFAQBtn"
                     @click="onDialogClose()" :disabled='isCancelBtnDisabled'>
                     Cancel
                    </el-button>
                    <el-tooltip
                        class="item"
                        :disabled="!disableSendDocument"
                        effect="dark"
                        :content="tooltipContent"
                        placement="top-start"
                    >
                        <el-button 
                            :loading="isLoading"
                            type="primary" 
                            class="addFAQBtn" 
                            :disabled="disableSendDocument"
                            @click="sendBtn()"  
                        >
                            Send
                        </el-button>
                    </el-tooltip>    
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useContractManagementStore } from '../../../../stores/contractManagement';
import API from "@/services/api/";
export default {
    emits: ["update:isClientInformationPopupVisible", "sendBtnFunc"],
    name: "sendDocumentsPopup",
    props: {
        isClientInformationPopupVisible: {
            type: Boolean,
            default: false,
        },
        placeholder:{
            type:Array,
            default: ()=>{[]}
        },
        isModal:{
            type:Boolean,
            default:true,
        }
    },

    data() {
			return {
				form: {
					firstClientName: '',
					firstClientEmail: '',
					secondClientName: '',
					secondClientEmail: '',
					thirdClientName: '',
					thirdClientEmail: '',
					cc:"",
					envelopeName:"",
				},
				isLoading:false,
                isCancelBtnDisabled: false,
                disableCloseIcon: false,
				requiredValidation: {
					required: true,
				},
				emailValidation:{
					required: true,
					email:true
				},
				showFirstRow:false,
				showSecondRow:false,
				showThirdRow:false,	
                tooltipContent:"Please fill all the * marked fields"		
			};
    },

    computed: {
        ...mapState(useContractManagementStore, {
            CMStore: (state) => state,
    	}),
        requiredValidationForFirst() {
            return {
                required: this.showFirstRow,
            }
        },
        requiredValidationForFirstEmail() {
            return {
                required: this.showFirstRow,
                email:true
            }
        },
        requiredValidationForSecond() {
            return {
                required: this.showSecondRow,
            }
        },
        requiredValidationForSecondEmail() {
            return {
                required: this.showSecondRow,
                email:true
            }
        },
        requiredValidationForThird() {
            return {
                required: this.showThirdRow,
            }
        },
        requiredValidationForThirdEmail() {
            return {
                required: this.showThirdRow,
                email:true
            }
        },
        isNoDocusignPHAdded(){
            return !(this.showFirstRow || this.showSecondRow || this.showThirdRow)
        },
        disableSendDocument(){
            // if showFirstRow, go on with the validation, but if user entered some first client email anyway, then also trigger forst row validation...same for the rest clients
					if(this.showFirstRow || this.form.firstClientEmail){ 
						if(this.form.firstClientName == ""
							|| this.form.firstClientEmail == "" || this.errors.has('first_client_email')){
                                this.tooltipContent = "Please fill all the * marked fields"
								return true;
							}
					}
					if(this.showSecondRow || this.form.secondClientEmail){
						if(this.form.secondClientName == ""
						|| this.form.secondClientEmail == "" || this.errors.has('second_client_email')){
                            this.tooltipContent = "Please fill all the * marked fields"
							return true;
						}
					}
					if(this.showThirdRow || this.form.thirdClientEmail){
						if(this.form.thirdClientName == ""
						|| this.form.thirdClientEmail == "" || this.errors.has('third_client_email')){
                            this.tooltipContent = "Please fill all the * marked fields"
							return true;
						}
					}
                    // If no docusign PH added, then it is imp for the customer to atleast add 1 client's info
                    if(this.isNoDocusignPHAdded){
                        if((this.form.firstClientName && this.form.firstClientEmail && !this.errors.has('first_client_email')) || 
                        (this.form.secondClientName && this.form.secondClientEmail && !this.errors.has('second_client_email')) || 
                        (this.form.thirdClientName && this.form.thirdClientEmail && !this.errors.has('third_client_email'))){
                            // Do nothing
                        }
                        else{
                            this.tooltipContent = "Please fill at least 1 client name and email"
                            return true
                        }
                    }
					if(this.form.envelopeName == "" || this.errors.has('cc_to')){
                        this.tooltipContent = "Please fill all the * marked fields"
						return true;
					}
					return false
        }
    },

    mounted(){
    this.checkFormRowVisibility()
    },

    methods: {
        onDialogClose() {
					this.$emit("update:isClientInformationPopupVisible", false);
        },

       async sendBtn() {
					this.isLoading=true;
                    this.isCancelBtnDisabled = true;
                    this.disableCloseIcon = true; 
					let payload = {
						"via": "docusign",
						"recipients": {
								"first_customer_name": this.form.firstClientName,
								"first_customer_email": this.form.firstClientEmail,
								"second_customer_name": this.form.secondClientName,
								"second_customer_email": this.form.secondClientEmail,
								"third_customer_name": this.form.thirdClientName,
								"third_customer_email": this.form.thirdClientEmail,
								"cc_to":this.form.cc,
								"envelope_name":this.form.envelopeName,
							},
							"design_id": this.CMStore.selectedDesignIdForPreview,
							"contracts": this.CMStore.contractWithLinkAndPlaceholders,
						}
						try{
								await	API.CONTRACT_TEMPLATES.SEND_CONTRACT(payload);
								this.isLoading = false;
								this.$emit("sendBtnFunc", true);
                                this.isCancelBtnDisabled = false;
                                this.disableCloseIcon = false; 
						}
						catch(err){
							this.$message({
								showClose: true,
								message: err.response.data.error,
								type: "error",
								center: true,
							});
							this.isLoading = false;
                            this.isCancelBtnDisabled = false;
                            this.disableCloseIcon = false; 
						}
						
        },
        checkFormRowVisibility(){
            const placeholder = this.$props.placeholder;
            if(placeholder.length === 0){
                return
            }

            placeholder.forEach((data) =>{
                data.additional_details.placeholders.map((item) =>{
                    if(item.additional_details.recipient_id == "3"){
                        if(this.showThirdRow){
                            return
                        }else{
                            this.showThirdRow = true
                        }
                    }
                    if(item.additional_details.recipient_id == "2"){
                        if(this.showSecondRow){
                            return
                        }else{
                            this.showSecondRow = true
                        }
                    }
                    if(item.additional_details.recipient_id == "1"){
                        if(this.showFirstRow){
                            return
                        }else{
                            this.showFirstRow = true
                        }
                    }
                })
            })
        }

    },
};
</script>

<style scoped>
.parentContainer>>>.el-dialog__header {
    /* background-color: #1c3366; */
    background-image: linear-gradient(to bottom, #e8edf2, #e9ecf2);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0 !important;
    height: 48px !important;
    padding: 24px !important;
}

.parentContainer>>>.el-dialog__title {
    width: 157px;
    font-family: "Helvetica Neue";
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.38;
    letter-spacing: normal;
    text-align: left;
    color: #222 !important;
}

.parentContainer>>>.el-dialog__close {
    color: #222222 !important;
    font-weight: 800 !important;
    font-size: 24px !important;
}

.parentContainer>>>.el-dialog__headerbtn:focus .el-dialog__close,
.parentContainer>>>.el-dialog__headerbtn:hover .el-dialog__close {
    color: #222 !important;
}

.parentContainer>>>.el-dialog {
    border-radius: 12px !important;
    height: auto;
    overflow-y: hidden;
    margin-top: 14vh !important;
    max-width: 770px;
    width: 90%;
}

.parentContainer>>>.el-dialog__body {
    padding: 0px !important;
}

.formContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    padding: 24px;
}

.errorMsg {
    margin-top: 4px;
    color: #f00;
}

.ccMsg {
    margin-top: 4px;
    color: #777;
}

.flexContainer {
    display: flex;
    gap: 16px;
}

.parentContainer>>>.el-form-item {
    margin-bottom: 12x;
}


.font14Bold {
    font-size: 14px;
    font-weight: bold;
    color: #777;
    margin-bottom: 6px;
}

.asterisk {
    color: red;
}

.parentContainer>>>.el-select {
    width: 100%;
}

.parentContainer>>>.el-input__inner {
    height: 48px;
    font-size: 16px;
    color: #222;
    border-radius: 4px;
    border: 1px solid var(--Grey-Scale-999, #999);
    background: var(--Grey-Scale-White, #FFF);
}

.parentContainer>>>.el-input__inner::placeholder,
.parentContainer>>>.el-textarea__inner::placeholder {
    color: #777;
    font-size: 16px;
}

.parentContainer>>>.el-textarea__inner {
    font-size: 16px;
    color: #222;
    height: 176px;
    border: none;
    border-radius: 4px;
    border: 1px solid var(--Grey-Scale-999, #999);
    background: var(--Grey-Scale-White, #FFF);
}

.parentContainer>>>.el-select .el-input .el-select__caret {
    font-size: 18px;
    font-weight: bold;
    color: #222;
}

.width100 {
    position: relative;
    width: 100%;
}

.btnContainer {
    text-align: end;
    padding: 16px;
    border-top: 1px solid #ccc;
}

.addFAQBtn {
    font-size: 16px;
}

@media (max-width: 520px) {

    .parentContainer>>>.el-dialog__wrapper {
        overflow: hidden;
    }

    .parentContainer>>>.el-dialog {
        margin-top: 6vh !important;
    }

    .parentContainer>>>.el-dialog__header {
        padding: 16px !important;
    }

    .formContainer {
        max-height: 70vh;
        overflow-y: scroll;
        padding: 16px;
    }

    .flexContainer {
        flex-direction: column;
    }

    .parentContainer>>>.el-textarea__inner {
        height: 38vh;
    }
}
</style>