<!-- DetailsComponent.vue -->
<template>
    <div class="details-component" :style="styleValues">
        <div class="font-cont">
            <p class="heading">Font Size</p>
            <div class="btn-cont">
                <b-icon icon="trash" class="fontButton" @click="$emit('deletePH')"></b-icon>
                <b-icon icon="dash-circle" class="fontButton" @click="decreaseFontSizeValue"></b-icon>
                <p>{{fontSizeValue}}</p>
                <b-icon icon="plus-circle" class="fontButton" @click="increaseFontSizeValue"></b-icon>
            </div>
        </div>
        <p class="para">The space occupied by the actual value of the placeholder might differ.</p>
    </div>
  </template>
  
  <script>
  export default {
    emits: ["deletePH", "fontSize"],
    name: 'DetailsComponent',
    props: {
        styleValues :{
            type: Object,
            default:() => {},
        },
        activeSpanFontSize:{
            type:  Number,
            default: 14,
        }
    },
    data() {
        return {
            fontSizeValue: 12,
        }
    },
    mounted() {
        this.fontSizeValue = this.activeSpanFontSize
    },
    methods: {
        increaseFontSizeValue() {
            this.fontSizeValue = this.fontSizeValue + 1;
            this.$emit('fontSize',this.fontSizeValue)
        },
        decreaseFontSizeValue() {
            this.fontSizeValue = this.fontSizeValue - 1;
            this.$emit('fontSize',this.fontSizeValue)
        },
    },
  };
  </script>
  
  <style scoped>
.fontButton{
    height: 20px;
    width: 20px;
    cursor: pointer;
}
.heading{
    font-size: 16px;
    font-weight: 600;
    color: #1D1D1F;
}
.details-component {
    background-color: white;
    padding: 5px;
    z-index: 9999;
    border-radius: 4PX;
    border: 1px solid #ccc;
    display: flex;
    flex-direction: column;
    padding: 16px 16px 16px 16px;
    gap: 25px;
}
.font-cont{
    display: flex;
    justify-content: space-between;  
}
.btn-cont{
    display: flex;
    gap: 16px;
    color: #000;
    font-size: 18px;
    align-items: center;
}
.para{
    color: #777;
}
  </style>
  