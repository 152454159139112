import { defineStore } from 'pinia'
import API from '../services/api';
import { getProjectImageUrl } from '../utils';

export const useLeadStore = defineStore('lead', {
    state: () => {
        return {
            activity_logs: [],
            address: "",
            created_at: "",
            deal_value: 0,
            email: "",
            estimated_system_size: 0,
            id: -1,
            is_deal_value_auto_updated: false,
            lead_source_object: null,
            lead_details: {last_contacted: null, latest_notes: null},
            name: "",
            owner: -1,
            phone: "",
            stage_object:0,
            project_details: null,
            stage: "",
            tags: [],
            target_closure_date: "",
            taskCount:0,
            tasks: [],
            workflow_type:null,
            leadAddressEditFlag: true,
            address_update_count: 0,
            allow_address_update: true,
            designOrderTariffFlag: false,
            leadPageLoaderGenability: false,
        }
    },
    getters: {
        GET_LEAD_PAGE_LOADER_GENABILITY: (state) => state.leadPageLoaderGenability,
        GET_DESIGN_ORDER_TARIFF_FLAG: (state) => state.designOrderTariffFlag,
        GET_LEAD_ADDRESS_EDIT_FLAG: (state) => state.leadAddressEditFlag,
        GET_LEAD_ADDRESS_UPDATE_COUNT: (state) => state.address_update_count,
        GET_ACTIVITY_DETAILS: state => state.activity_logs,
        GET_PROJECT_IMAGE_URL: (state) => {
            if (state?.project_details?.latitude && state?.project_details?.longitude) {
                return getProjectImageUrl(state.project_details.latitude, state.project_details.longitude, state.project_details.zoom);
            }
            return '';
        },
    },
    actions: {
        SET_LEAD_PAGE_LOADER_GENABILITY(boolVal){
            this.leadPageLoaderGenability = boolVal;
        },
        SET_DESIGN_ORDER_TARIFF_FLAG(){
            this.designOrderTariffFlag = !this.allow_address_update;
        },
        SET_LEAD_ADDRESS_EDIT_FLAG() {
            this.leadAddressEditFlag = this.allow_address_update ? this.address_update_count < 3 : false;
        },
        SET_LEAD_ID(payload){
            this.id = payload.lead_id;
        },
        async SET_ACTIVITY(payload){
            this.activity_logs = payload;
        },
        async SET_LEAD_FROM_DATA(leadData){
            this.updateLeadInStore(leadData);
        },
        async SET_LEAD(leadId, projectId) {
            let resp = await API.LEADS.FETCH_LEAD(leadId, projectId)
            this.updateLeadInStore(resp.data)
        },
        async UPDATE_LEAD(patchData,notificationFunction) {
            try {
                const result = await API.LEADS.UPDATE_LEAD(this.id, this.project_details.id, patchData)
                this.updateLeadInStore(result.data)
                return result.data
            } catch(e) {
                console.error(e);
                let errorMessage = 'There was an error while updating the lead information.';
                if (e.response?.status === 412) {
                    errorMessage = e.response.data.detail
                }
                else if(e.response?.status === 400){
                    errorMessage = e.response.data.detail;
                    // make another get lead call to update flags for address edit
                    await this.SET_LEAD(this.id, this.project_details.id);
                }
                if (notificationFunction) {
                    notificationFunction('error', errorMessage);
                }
            }
        },
        FETCH_ALL_TASKS_IN_STORE(allTasks,totalTasks){
            this.tasks = [... allTasks];
            this.taskCount=totalTasks;
        },
        ADD_TASK_IN_LEAD(task){
            this.tasks.push(task);
            this.taskCount=this.taskCount+1;
        },
        EDIT_TASK_IN_LEAD(taskId,data){
            const task = this.$state.tasks.find(task => task.id === taskId);
            if (task) {
                Object.assign(task, data);
            }

        },
        DELETE_TASK_IN_LEAD(taskId){
            const index = this.tasks.findIndex(task => task.id === taskId);
            if (index !== -1) {
                this.tasks.splice(index, 1);
                this.taskCount=this.taskCount-1;
            }
        },
        async UPDATE_LEAD_FROM_DRAWER(leadId,projectId,patchData,notificationFunction){
            // try{
                const result = await API.LEADS.UPDATE_LEAD(leadId,projectId, patchData)
                this.updateLeadInStore(result.data);
                // return 1;
            // }
            // catch(e){
            //     // notificationFunction('error','Error in updating information');
            //     return 0;
            // }
        },
        updateLeadInStore(data) {
            for (let key of Object.keys(data)) {
                this[key] = data[key]
            }
            this.allow_address_update = this.project_details?.allow_address_update;
            this.address_update_count = this.project_details?.address_update_count;
            this.SET_DESIGN_ORDER_TARIFF_FLAG();
            this.SET_LEAD_ADDRESS_EDIT_FLAG();
        },
        UPDATE_LATEST_NOTE(newNote) {
            this.lead_details.latest_notes = newNote;
            this.lead_details.last_contacted="0 minutes ago";
        },
        UPDATE_WORKFLOW_TYPE(data){
            this.workflow_type=data;

        }
    }
})