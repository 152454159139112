import { defineStore } from "pinia";
import { getUiFromStorage, setUiInStorage } from "../utils";
import router from "../router";

function returnDialogPopupProps() {
  return {
    visible: false,
    onClickYes: () => {},
    onClickNo: () => {},
    message: "",
  }
}

function getIntercomIconContainer() {
  let intercomIconClasses = [
    ".intercom-lightweight-app",
    ".intercom-namespace",
  ]

  let intercomIcon
  for (let curClass of intercomIconClasses) {
    intercomIcon = document.querySelector(curClass)
    if (intercomIcon) { break }
  }

  return intercomIcon
}

export const useUiStore = defineStore("ui", {
  state: () => {
    return {
      isMobileView: false,
      currentScreenWidth: 0,
      activeLeadSummaryTab: "leadInfo",
      activeLeadSummaryDesignSection: "designList", // Options: ["designList", "designSummary"]
      dialogPopup: returnDialogPopupProps(),
      openChatBox:true,
      isCreatingNewLead: false,
      intercomIconStatus: "Hide"
    };
  },
  getters: {
  },
  actions: {
    UPDATE_SCREEN_WIDTH(num) {
      this.currentScreenWidth = num;
      num <= 768 ? this.isMobileView = true : this.isMobileView = false
    },
    SET_ACTIVE_LEAD_SUMMARY_TAB(tabTitle) {
      // If the user clicks on the Design Tab again while on Design Summary,
      // go back to to the Design Tab's listing view.
      if (tabTitle != 'design') {
        router.replace({
          name: "leadSummary",
          params: {
            leadId: router.currentRoute.params.leadId
          }
        });
      }
			this.activeLeadSummaryTab = tabTitle
			let ui = getUiFromStorage()
			ui.leadSummary.activeLeadSummaryTab = tabTitle
			setUiInStorage(ui)
    },
    SET_CLOSE_CHAT_BOX(isOpen) {
      this.openChatBox=isOpen;
    },
    SET_ACTIVE_DESIGN_SECTION(sectionName) {
      this.activeLeadSummaryDesignSection = sectionName
    },

    resetDialogPopup() {
      this.dialogPopup = returnDialogPopupProps()
    },
    
    toggleChatIcon() {
      let intercomIcon = getIntercomIconContainer()
      if (!intercomIcon) { return }

      if (this.intercomIconStatus === "Hide") {
        this.intercomIconStatus = "Show";
        intercomIcon.style.display = "none";
      } else {
        this.intercomIconStatus = "Hide";
        intercomIcon.style.display = "block";
      }
    },
    hideChatIcon(){
      let intercomIcon = getIntercomIconContainer()
      this.intercomIconStatus = "Show";
      if (!intercomIcon) { return }
      intercomIcon.style.display = "none";
    },
    setIntercomToggleButtonStatus() {
      let intercomIcon = getIntercomIconContainer()
      if (!intercomIcon) { return }

      if (intercomIcon.style.display == "none") {
        this.intercomIconStatus = "Show";
      } else {
        this.intercomIconStatus = "Hide";
      }
    },
  },
});
