// import { BlobServiceClient, ContainerClient } from "@azure/storage-blob";
// import { containerName } from "./services/api/index.js";
// import { sasToken } from "./services/api/index.js";
// import { storageAccountName } from "./services/api/index.js";

export async function addImagesLoadedFlag(imgUrls) {
    let images = document.querySelectorAll('img')
    if (!images.length) {
        window.allImagesLoaded = true
        return
    }
    let promises = []
    images.forEach(image => {
        if (!imgUrls.includes(image.src)) { return }

        if (image.complete) { return }

        let prom = new Promise(resolve => image.onload = resolve)
        promises.push(prom)
    })
    await Promise.all(promises)
    window.allImagesLoaded = true
    return true
}
export function convertedWithComaskWh(value){
    if (typeof value === 'string'){
        return parseFloat(value.replace(/,/g, '')).toLocaleString("en-US")
    }
    else{
        return parseFloat(value).toLocaleString("en-US")
    }
}

export function formatDateForReport(date) {
    let dateNew = date.toDateString();
    let dateArray = dateNew.split(' ');
    let dateArray2 = dateArray.slice(1, 4);
    [dateArray2[0], dateArray2[1]] = [dateArray2[1], dateArray2[0]];
    dateArray2[1] = dateArray2[1] + ','
    let finalDate = dateArray2.join(' ');

    let dateString = finalDate + ' | ' + date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
    return dateString
}

// Thanks to https://stackoverflow.com/a/13899011
export function convertTimeTo12HourFormat(time) {
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
        time = time.slice(1);
        time[5] = +time[0] < 12 ? ' AM' : ' PM';
        time[0] = +time[0] % 12 || 12;
    }
    return time.join('');
}

export function getServiceSpecificInfo(serviceName) {
    //-----------------For Testing-------------------------------//
    // serviceName = 'Solar Sales Proposal';
    // serviceName = 'PV Design';
    // serviceName = 'Permit Package';
    // serviceName = 'Full Construction Drawing'
    // serviceName = 'Preliminary Proposal';
    //-----------------For Testing-------------------------------//
    let storedData = JSON.parse(localStorage.getItem("allServicesInfo"));
    storedData = storedData.service_templates; // now its an array of json of different services
    for (let i = 0; i < storedData.length; i++) {
        if (serviceName == storedData[i].template_constant[0].name)
            return storedData[i];
    }
}

export function isItIndianExpertService() {
    let storedData = JSON.parse(localStorage.getItem("allServicesInfo"));
    storedData = storedData.service_templates; // now its an array of json of different services
    if (storedData && storedData.length > 0) {
        if (storedData[0]['available_info'][0] == 'project_info')
            return true;
        else
            return false;
    }
    return false;
}

export function viewImage(event) {
    const element = event.target;
    const src = element.src;

    const modal = document.createElement("div");
    const close = document.createElement("span");

    close.innerText = '+'
    close.style.position = 'fixed'
    close.style.fontSize = '65px';
    close.style.fontWeight = '400';
    close.style.display = 'inline-block';
    close.style.transform = 'rotate(45deg)';
    close.style.top = '0px',
    close.style.right = '30px',
    close.style.cursor = 'pointer'
    close.style.zIndex = '90000',
    close.style.color = '#fff';


    modal.style.background = 'RGBA(0,0,0,0.8) url(' + src + ') no-repeat center',
    modal.style.backgroundSize = 'contain',
    modal.style.width = '100%',
    modal.style.height = '100%',
    modal.style.position = 'fixed',
    modal.style.zIndex = '10000',
    modal.style.top = '0',
    modal.style.left = '0',

    close.addEventListener('click', removeModal);

    document.body.appendChild(modal);
    document.body.appendChild(close);

    function removeModal() {
        modal.remove();
        close.remove();
    }
}

