
<div id="parent-container" :class="isPuppeteer ? 'parentPuppeteer' : ''">
    <div :class="[computedPageClass, isPuppeteer ? 'marginRemove' : '']" id="financial-containerDummy"
        style="visibility: hidden;">
        <div class="headerContainerPS">
            <p class="font14Bold">Sr No.</p>
            <p class="font14Bold">Event</p>
            <p class="font14Bold">Description</p>
            <p class="font14Bold">Portion</p>
            <p class="font14Bold leftAlign">Amount</p>
        </div>
        <div class="bodyContainer" id="bodyIDDummy">
            <div class="rowsDummy" v-for="(detail, index) in tableData" :key="index">
                <p class="font14Normal">{{ index + 1 }}</p>
                <p class="font14Normal">{{ detail.title }}</p>
                <p class="font14Normal lineClamp">{{ detail.description }}</p>
                <p class="font14Normal" v-if="detail.type == 'absolute'">{{ formatNumber(detail.value, 'currency', countryCode) }}</p>
                <p class="font14Normal" v-else>{{ detail.value }}%</p>
                <p class="font14Normal leftAlign">{{ formatNumber(detail.amount, 'currency', countryCode)  }}</p>
            </div>
        </div>
    </div>
    <div :class="[computedPageClass, isPuppeteer ? 'marginRemove' : '']" id="financial-container">
        <div class="headerContainerPS">
            <p class="font14Bold">Sr No.</p>
            <p class="font14Bold">Event</p>
            <p class="font14Bold">Description</p>
            <p class="font14Bold">Portion</p>
            <p class="font14Bold leftAlign">Amount</p>
        </div>
        <div class="bodyContainer" id="bodyID">
        </div>
    </div>
</div>
