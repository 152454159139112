import { defineStore } from 'pinia';
import API from '../services/api';
import { useDesignStore } from './design';

export let supportedThirdPartyFinanciers = [
    "GoodLeap",
    "Sunlight",   
]

export const useFinancialStore = defineStore('financial', {
    state: () => {
        return {
                costDetails: {
                    
                },
                paymentDetails:{},
                pricingDetails: [],
                total_cost: 0,
                basic_cost: 0,
                adders_total_cost: 0,
                adders_total_cost_for_incentive: 0,
                discounts_total_cost: 0,
                discounts_total_cost_for_incentive: 0,
                total_cost_for_incentives: 0,
                incentives_total_cost: 0,
                adders_cost_for_homeowner_false: 0,
                discounts_cost_for_homeowner_false: 0,
                isOnPricingAndFinancials: false,
                isFinancialLoading: false,
                isCalculatorLoading: false,
            }
    },
    getters: {
        GET_COST_DETAILS: state => state.costDetails,
        GET_PRICING_DETAILS: state => state.pricingDetails,
        financier: state => state.paymentDetails.financier,
        isGoodleapFinanced: (state) => state.financier == "GoodLeap",
        isSunlightFinanced: (state) => state.financier == "Sunlight",

        isFinancingOfTypeLoan() {
            // This property tells us if the financing applied for the design is a loan of any type (Arka or Third Party Financier)
            let paymentMethodType = this.pricingDetails[0]?.payment_method_type
            if (paymentMethodType == "loan" || this.isThirdPartyFinancier) {
                return true
            }
            return false
        },
        isThirdPartyFinancier() {
            return useDesignStore().isThirdPartyFinancier
        },
        financierDetails() {
            return this.paymentDetails[this.financier]
        },
        loanTermDetails() {
            return this.financierDetails?.loan_term_details
        },

        thirdPartyloanTermPeriod() {
            // Goodleap
            if (this.isGoodleapFinanced) {
                return "years"
            }
        
            // Sunlight
            return "months"
        },
    },
    actions: {
        async SET_ALL_COSTS(designId) {
            let designIdString = designId?.toString();
            let response = await API.DESIGNS.FETCH_COSTS(designIdString);
            this.costDetails = response.data;
            this.adders_total_cost = response.data.adders_total_cost;
            this.discounts_total_cost = response.data.discounts_total_cost;
            this.incentives_total_cost = response.data.incentives_total_cost;
            this.total_cost = response.data.total_cost;
            this.basic_cost = response.data.basic_cost;
            return response.data;
        },
        async SET_ALL_FINANCIALS(designId) {
            let designIdString = designId?.toString();
            let response = await API.DESIGNS.FETCH_FINANCIALS(designIdString);
            this.pricingDetails = response.data.pricing;
            return response.data.pricing;
        },

        SET_PAYMENT_DETAILS(response) {
            this.paymentDetails = response;
        },


        async UPDATE_DESIGN_FINANCIAL_DETAILS(pricingObjId, payload) {
            try {
                let resp = await API.DESIGN_FINANCIAL_DETAILS
                    .PATCH_DESIGN_FINANCIALS(pricingObjId, payload);
                return resp;
            }
            catch (e) {
                throw e;
            }
        },
        async UPDATE_ADVANCED_PRICING(designId, payload) {
            let designIdString = designId?.toString();
            try {
                let resp = await API.DESIGNS.PATCH_ADVANCED_PRICING(designIdString, payload);
                return resp;
            }
            catch (e) {
                throw e;
            }
        },
        UPDATE_PRICING_STATUS(value) {
            this.isOnPricingAndFinancials= value;
        },
        UPDATE_FINANCIAL_LOADING_STATUS(value) {
            this.isFinancialLoading= value;
        },
        UPDATE_CALCULATE_LOADER_STATUS(value) {
            this.isCalculatorLoading= value;
        },
    }
})