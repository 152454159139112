
<div class="fieldsCardContainer">
    <div class="flexCardContainer">
        <img v-show="!isPreviewMode" class="icon" src="../assets/drag_indicator.svg" />
        <div class="cardBorder">
            <div v-if="!isPreviewMode" class="grid-parent">
                <div v-if="fieldData.files.length > 0">
                    <section class="photo-video-section" v-for="(file, fileIndex) in fieldData.files" :key="fileIndex">
                        <el-input :placeholder="`${file.file_type.includes('image')? 'Image': 'Video'} Label`" v-model="file.file_info" @change="prepareFieldObjectToStore('file_info', fileIndex)"></el-input>
                        <div class="photo-video-container" v-loading="!file.file_url && !file.url">
                            <img class="images" :src="file.file_url || file.url" v-if="file.file_type.includes('image')"/>
                            <video class="videos" controls :src="file.file_url || file.url" v-if="file.file_type.includes('video')">
                                Your browser does not support HTML5 video.
                            </video>
                            <img
                            src="../../../assets/drop/x-circle-fill.svg"
                            class="crossIcon"
                            @click="deleteCurrentFile(fileIndex)"
                            />
                        </div>
                        <div class="cardFooter" style="margin-bottom: 1.5rem">
                            <div class="flexCont">
                                <div class="iconsCont">
                                    <i class="icon copy-alt" @click="duplicateCurrentFile(fileIndex)"
                                    :class="file.file_type.includes('image') ? (countOfImages3(fieldIndex-1, sectionIndex-1) ? 'icon copy-alt disabled-icon':'icon copy-alt') :
                                    (countOfVideos3(fieldIndex-1, sectionIndex-1) ? 'icon copy-alt disabled-icon':'icon copy-alt')">
                                    <!-- Add condition to see what the current file type is and then call the appropriate count function -->
                                    </i>
                                    <i class="icon delete-alt" @click="deleteCurrentFile(fileIndex)"></i>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div class="gridCont">
                    <div class="flexColumn">
                        <el-input placeholder="Field Label" v-model="fieldData.label" @change="prepareFieldObjectToStore('label')"></el-input>
                    </div>
                    <div class="flexColumn dropdownClass">
                        <el-select v-model="fieldData.question_type" placeholder="Select" @change="prepareFieldObjectToStore('question_type')">
                            <el-option
                                v-for="item in filteredOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                                <div class="flexOptions">
                                    <div v-if="item.iconType === 'icon'">
                                        <b-icon :icon="item.icons" style="color: #777; margin-top: 12px;" font-scale="1.3"></b-icon>
                                    </div>
                                    <div v-else-if="item.iconType === 'image'">
                                        <img :src="item.icons" alt="Option Image" style="margin-top: 12px;">
                                    </div>
                                    <p class="dropdownOptions">{{ item.label }}</p>
                                </div>
                            </el-option>
                        </el-select>
                        <b-icon v-if="selectedOptionIcon && selectedOption.iconType === 'icon'" :icon="selectedOptionIcon" style="color: #777;" font-scale="1.3" class="absoluteIcon"></b-icon>
                        <img v-if="selectedOptionIcon && selectedOption.iconType === 'image'" :src="selectedOptionIcon" class="absoluteIcon">
                    </div>
                    <div class="flexColumn">
                        <el-input placeholder="Description" v-model="fieldData.description" @change="prepareFieldObjectToStore('description')"></el-input>
                    </div>
                </div>
            </div>
            <div class="previewCont" v-else>
                <div class= "imageVideoContainer" v-show="fieldData.files.length>0">
                    <div class="imageContainer" v-show="photoFiles.length>0">
                        <el-col v-for="(file,index) in photoFiles" :key="index">
                            <div style="display: flex; flex-direction: column; height:100%;">
                                <p class="heading">{{file.file_info || file.label}}</p>
                                <img class="images" :src="file.file_url || file.url" @click="viewImage($event)"/>
                            </div>
                        </el-col>
                    </div>
                    <div class="videoContainer" v-show="videoFiles.length>0">
                        <el-col v-for="(file,index) in videoFiles" :key="index">
                            <div style="display: flex; flex-direction: column; height:100%;">
                                <p class="heading">{{file.file_info || file.label}}</p>
                                <video  controls class="videos" :src="file.file_url || file.url">
                                    Your browser does not support HTML5 video.
                                </video>
                            </div>
                        </el-col>
                    </div> 
                </div>
                <div class="gridCont">
                    <p class="heading">{{fieldData.label}}</p>
                    <div>
                        <p class="description" v-if="!complexQTypes.includes(fieldData.question_type)">
                            <b-icon v-if="fieldData.question_type == 'date' || fieldData.question_type == 'time'
                            || fieldData.question_type == 'phone_number' || fieldData.question_type == 'email'"
                            :icon="getQuestionIcon(fieldData.question_type)"
                            class="preview-icons">
                            </b-icon>
                            {{questionType(fieldData.question_type) +
                            (fieldData.question_type === 'paragraph' || fieldData.question_type === 'short_answer' ? ' Text' : '')}}
                        </p>
                        <div class="uploadContainer" v-if="fieldData.question_type == 'file_upload'">
                            <div class="uploadCont">
                                <b-icon icon="upload" style="color: #777;"></b-icon>
                                <p class="description">Upload File</p>
                            </div>
                            <div class="errorCont">
                                <div class="fileType">
                                    <p style="white-space: nowrap;" v-for="fileType in fieldData.additional_info?.allowed_types" :key="fileType">
                                        {{fileType.charAt(0).toUpperCase() + fileType.slice(1)}}
                                    </p>
                                </div>
                                <p class="errorMsg">The maximum file size allowed for upload is 500MB.</p>
                            </div>
                        </div>
                    </div>
                    <p class="description">{{fieldData.description}}</p>
                    <!-- for question type multiple_choice/dropdown/checkbox -->
                    <div class="optionsContainer" v-if="complexQTypesWOFileUpload.includes(fieldData.question_type)">
                        <div v-for="(option,index) in fieldData.options" :key="index">
                            <p class="options preview-options-text" v-if="fieldData.question_type =='checkbox'">{{option.option_text}}</p>
                            <p class="preview-dropdown-text" v-if="fieldData.question_type =='dropdown'">
                                <b-icon icon="caret-down-square" style="color: #777;margin-right:16px;" font-scale= "1.25"></b-icon>
                                {{` ${option.option_text}`}}
                            </p>
                            <p class="preview-options-text subOptions" v-if="fieldData.question_type =='multiple_choice'
                            || fieldData.question_type=='single_choice' ">{{option.option_text}}</p>
                            <p v-if="fieldData.question_type === 'tab_choice'" class="preview-options-text">{{option.option_text}}</p>
                            <div class="subOptionsContainer" v-if="option.sub_options.length > 0">
                                <div v-for="(sub_op,index) in option.sub_options" :key="index" style="padding-left:2.25rem">
                                    <p class="heading" style="font-size: 1rem;padding:0.5rem 0;">{{sub_op.label}}</p>
                                    <p class="description" style="font-size: 1rem;" v-if="!complexQTypes.includes(sub_op.question_type)">
                                        <b-icon v-if="sub_op.question_type == 'date' ||
                                        sub_op.question_type == 'time'|| sub_op.question_type == 'phone_number'|| sub_op.question_type == 'email'"
                                        :icon="getQuestionIcon(sub_op.question_type)"
                                        style="width:1rem;height:1rem"
                                        class="preview-icons"
                                        ></b-icon>
                                        {{questionType(sub_op.question_type) +
                                        (sub_op.question_type === 'paragraph' || sub_op.question_type === 'short_answer' ? ' Text' : '')}}
                                    </p>
                                    <div class="uploadContainer" v-if="sub_op.question_type == 'file_upload'">
                                        <div class="uploadCont">
                                            <b-icon icon="upload" style="color: #777;"></b-icon>
                                            <p class="description">Upload Photos</p>
                                        </div>
                                        <div class="errorCont">
                                            <div class="fileType">
                                                <p v-for="subOpFileType in sub_op.additional_info?.allowed_types" :key="subOpFileType">
                                                    {{subOpFileType.charAt(0).toUpperCase() + subOpFileType.slice(1)}}
                                                </p>
                                            </div>
                                            <p class="errorMsg">The maximum file size allowed for upload is 500MB.</p>
                                        </div>
                                    </div>
                                    <!-- For nested type question multiple_choice/dropdown/checkbox/tab_choice -->
                                    <div class="optionsContainer margin-top8" v-if="complexQTypesWOFileUpload.includes(sub_op.question_type)">
                                        <div v-for="(nested_op,index) in sub_op.options" :key="index" >
                                            <p class="options preview-options-text" style="font-size:1rem" v-if="sub_op.question_type =='checkbox'">{{nested_op.option_text}}</p>
                                            <p class="preview-dropdown-text" style="font-size:1rem" v-if="sub_op.question_type =='dropdown'">
                                                <b-icon font-scale= "1.25" style="color: #777;margin-right:16px;" icon="caret-down-square"></b-icon>{{` ${nested_op.option_text}`}}
                                            </p>
                                            <p class="preview-dropdown-text subOptions" style="font-size:1rem"
                                            v-if="sub_op.question_type =='multiple_choice' || sub_op.question_type=='single_choice'">
                                            {{nested_op.option_text}}
                                            </p>
                                            <p class="preview-dropdown-text" v-if="sub_op.question_type === 'tab_choice'">{{nested_op.option_text}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="answersFiledContainer">
                <AnswersFields 
                v-if="!isPreviewMode" 
                :isEditTemplateMode="isEditTemplateMode"
                :fieldIndex="fieldIndex" 
                :fieldData="fieldData" 
                :sectionIndex="sectionIndex"
                @patchTemplate="propagatePatchDataFromField"/>
            </div>
            <div class="cardFooter" v-if="!isPreviewMode">
                <div class="flexCont">
                    <div class="iconsCont">
                        <el-tooltip class="item" effect="dark" content="Add Image" placement="top">
                            <img src="../assets/Image.svg" alt=""
                            :class="countOfImages3(fieldIndex-1, sectionIndex-1) || (fieldData.questionType === 'poi' || fieldData.question_type === 'boundary') ? 'icons disabled-icon':'icons'"
                            @click="addPhotoField"/>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="Add Video" placement="top">
                            <img src="../assets/play-btn.svg" alt=""  
                            :class="countOfVideos3(fieldIndex-1, sectionIndex-1) || (fieldData.questionType === 'poi' || fieldData.question_type === 'boundary') ? 'icons line disabled-icon ':'icons line'"
                            @click="addVideoField"/>
                        </el-tooltip>
                    </div>
                    <hr class="hrTag"/>
                    <div class="flexSwitch">
                        <p class="switchTxt">Required</p>
                        <el-switch v-model="fieldData.is_required" @change="prepareFieldObjectToStore('is_required')">
                        </el-switch>
                    </div>
                    <hr class="hrTag"/>
                    <div class="iconsCont">
                        <i :class="fieldData.question_type === 'boundary' ? 'icon copy-alt disabled-icon':'icon copy-alt'" @click="duplicateCurrentField"></i>
                        <i class="icon delete-alt" @click="deleteCurrentField" :class="{ 'disabled-icon': fieldsLength === 1 }"></i>
                    </div>
                </div>
            <input
            type="file"
            ref="fileInputImage"  
            style="display: none;"
            @change="uploadPhotoVideo"
            accept="image/*"
            multiple
            />
            <input
            type="file"
            ref="fileInputVideo"  
            style="display: none;"
            @change="uploadPhotoVideo"
            accept="video/*"
            multiple
            />
            </div>
        </div>
    </div>
</div>
