
<main class="main-controller" :class="{ expended: isSidebarVisible }">
  <div
    :class="['backdrop', isCrmUser() ? 'backdropCRM' : '']"
    @click="toggleSidebarStatus"
  ></div>
  <aside class="aside_setion" :class="isCrmUser() ? 'aside_setionCRM' : ''">
    <nav class="navbar" :class="navbarClassFunc()">
      <ul class="nav_list">
        <el-tooltip
          class="item"
          effect="dark"
          content="Dashboard"
          placement="right"
          :disabled="!isCrmUser() || isSidebarVisible"
        >
          <li
            v-if="!isTataOrg()"
            :class="{
              activeProject:
                $router.currentRoute.path === '/' && active === false,
            }"
            @click="toggleSidebarStatus"
          >
            <router-link :to="{ name: 'home' }">
              <div class="nav_icon">
                <span class="icon" v-if="$router.currentRoute.path === '/'"
                  ><img src="../../assets/drop/Group 292.svg"
                /></span>
                <span class="icon" v-else
                  ><img src="../../assets/drop/Group 1938.svg"
                /></span>
              </div>
              <span
                class="nav_text"
                :class="!isSidebarVisible && isCrmUser() ? 'hideLabel' : ''"
                >Dashboard</span
              >
            </router-link>
          </li>
        </el-tooltip>
        <el-tooltip
          class="item"
          effect="dark"
          :content="somTitle()"
          placement="right"
          :disabled="!isCrmUser() || isSidebarVisible"
        >
          <li
            v-if="isSOMPageRequired()"
            :class="{activeProject:
                ($router.currentRoute.path === '/arkaorders') &&
                active === false,}"
            @click="collapseSidebar">
            <router-link :to="{ name: 'arkaorders' }">
              <div class="nav_icon">
                <span
                  class="icon"
                  v-if="$router.currentRoute.path === '/arkaorders'"
                  ><img src="../../assets/img/arkaOrderSelected.svg"
                /></span>
                <span class="icon" v-else
                  ><img src="../../assets/img/arkaOrder.svg"
                /></span>
              </div>
              <span
                class="nav_text"
                :class="!isSidebarVisible && isCrmUser() ? 'hideLabel' : ''"
                >{{somTitle()}}</span
              >
            </router-link>
          </li>
        </el-tooltip>
        <!-- Catalog side Bar icon do not remove this commented code -->
        <!-- <el-tooltip
          class="item"
          effect="dark"
          content="Catalog"
          placement="right"
          :disabled="!isCrmUser() || isSidebarVisible"
        >
          <li
            v-if="isCrmUser() && ( role =='ARKA_SALES_OP'||role =='ARKA_INTERNAL_SALES' || isVIP)"
            :class="{
              activeProject:
                $router.currentRoute.path === '/catalog' &&
                active === false,
            }"
            @click="collapseSidebar"
          >
            <router-link :to="{ name: 'catalog' }">
              <div class="nav_icon">
                <span
                  class="icon"
                  v-if="$router.currentRoute.path === '/catalog'"
                  ><img src="../../assets/img/catalogSelecetd.svg"
                /></span>
                <span class="icon" v-else
                  ><img src="../../assets/img/catalog.svg"
                /></span>
              </div>
              <span
                class="nav_text"
                :class="!isSidebarVisible && isCrmUser() ? 'hideLabel' : ''"
                >Catalog</span
              >
            </router-link>
          </li>
        </el-tooltip> -->
        
        <el-tooltip
          class="item"
          effect="dark"
          content="Tasks"
          placement="right"
          :disabled="!isCrmUser() || isSidebarVisible"
        >
        </el-tooltip>

        <el-tooltip
          class="item"
          effect="dark"
          content="Leads"
          placement="right"
          :disabled="!isCrmUser() || isSidebarVisible"
        >
          <li
            v-if="isCrmUser()"
            :class="{
              activeProject:
                $router.currentRoute.path === '/leadmanagement' &&
                active === false,
            }"
            @click="collapseSidebar"
          >
            <router-link :to="{ name: 'leadManagement' }">
              <div class="nav_icon">
                <span
                  class="icon"
                  v-if="$router.currentRoute.path === '/leadManagement'"
                  ><img src="../../assets/drop/Group 1939.svg"
                /></span>
                <span class="icon" v-else
                  ><img src="../../assets/drop/leadManagementIcon.svg"
                /></span>
              </div>
              <span
                class="nav_text"
                :class="!isSidebarVisible && isCrmUser() ? 'hideLabel' : ''"
                >Leads</span
              >
            </router-link>
          </li>
        </el-tooltip>

        <el-tooltip
          class="item"
          effect="dark"
          content="Tasks"
          placement="right"
          :disabled="!isCrmUser() || isSidebarVisible"
        >
          <li
            v-if="isCrmUser()"
            :class="{
              activeProject:
                $router.currentRoute.path === '/taskmanagement' &&
                active === false,
            }"
            @click="collapseSidebar"
          >
            <router-link :to="{ name: 'taskManagement' }">
              <div class="nav_icon">
                <span
                  class="icon"
                  v-if="$router.currentRoute.path === '/taskManagement'"
                  ><img src="../../assets/drop/task-sidebar-icon.svg"
                /></span>
                <span class="icon" v-else
                  ><img src="../../assets/drop/task-sidebar-icon.svg"
                /></span>
              </div>
              <span
                class="nav_text"
                :class="!isSidebarVisible && isCrmUser() ? 'hideLabel' : ''"
                >Tasks</span
              >
            </router-link>
          </li>
        </el-tooltip>

        <el-tooltip
          class="item"
          effect="dark"
          content="Orders"
          placement="right"
          :disabled="!isCrmUser() || isSidebarVisible"
        >
          <li
            v-if="!isESOrg && !isTataOrg()"
            :class="{
              activeProject:
                $router.currentRoute.path === '/orders' && active === false,
            }"
            @click="collapseSidebar"
          >
            <router-link :to="{ name: 'orders' }">
              <div class="nav_icon">
                <span
                  class="icon"
                  v-if="$router.currentRoute.path === '/orders'"
                  ><img src="../../assets/drop/Group 1653.svg"
                /></span>
                <span class="icon" v-else
                  ><img src="../../assets/drop/Group 1794.svg"
                /></span>
              </div>
              <span
                class="nav_text"
                :class="!isSidebarVisible && isCrmUser() ? 'hideLabel' : ''"
                >Orders</span
              >
            </router-link>
          </li>
        </el-tooltip>
        <el-tooltip
          class="item"
          effect="dark"
          content="Design Orders"
          placement="right"
          :disabled="!isCrmUser() || isSidebarVisible"
        >
        <li
          v-if="isESOrg"
          :class="{
            activeProject:
              $router.currentRoute.path === '/designOrders' &&
              active === false,
          }"
          @click="collapseSidebar"
        >
          <router-link :to="{ name: 'designOrders' }">
            <div class="nav_icon">
              <span
                class="icon"
                v-if="$router.currentRoute.path === '/designOrders'"
                ><img src="../../assets/drop/Group 1653.svg"
              /></span>
              <span class="icon" v-else
                ><img src="../../assets/drop/Group 1794.svg"
              /></span>
            </div>
            <span
              class="nav_text"
              :class="!isSidebarVisible && isCrmUser() ? 'hideLabel' : ''"
              >Design Orders</span
            >
          </router-link>
        </li>
        </el-tooltip>

        <el-tooltip
          class="item"
          effect="dark"
          content="Shift Management"
          placement="right"
          :disabled="!isCrmUser() || isSidebarVisible"
        >
        <li
          v-if="isESOrg && isESOrgAdmin"
          :class="{
            activeProject:
              $router.currentRoute.path === '/esUsers' && active === false,
          }"
          @click="collapseSidebar"
        >
          <router-link :to="{ name: 'esUsers' }">
            <div class="nav_icon">
              <span
                class="icon"
                v-if="$router.currentRoute.path === '/esUsers'"
                ><img src="../../assets/drop/Group 1653.svg"
              /></span>
              <span class="icon" v-else
                ><img src="../../assets/drop/Group 1794.svg"
              /></span>
            </div>
            <span
              class="nav_text"
              :class="!isSidebarVisible && isCrmUser() ? 'hideLabel' : ''"
              >Shift Management</span
            >
          </router-link>
        </li>
        </el-tooltip>

        <el-tooltip
          class="item"
          effect="dark"
          :content="projectText+'s'"
          placement="right"
          :disabled="!isCrmUser() || isSidebarVisible"
        >
        <li
          v-if="!isCrmUser() && !isESOrg"
          :class="{
            activeProject:
              $router.currentRoute.path === '/projects' && active === false,
          }"
          @click="collapseSidebar"
        >
          <router-link :to="{ name: 'projectListViewHome' }">
            <div class="nav_icon">
              <span
                class="icon project"
                v-if="$router.currentRoute.path === '/projects'"
              ></span>
              <span class="icon" v-else
                ><img src="../../assets/drop/Group 1654.svg"
              /></span>
            </div>
            <span class="nav_text" :class="!isSidebarVisible && isCrmUser() ? 'hideLabel' : ''">{{ projectText }}s</span>
          </router-link>
        </li>
        </el-tooltip>

        <el-tooltip
          class="item"
          effect="dark"
          content="Project Management"
          placement="right"
          :disabled="!isCrmUser() || isSidebarVisible"
        >
        </el-tooltip>

        <el-tooltip
          class="item"
          effect="dark"
          content="Role Management"
          placement="right"
          :disabled="!isCrmUser() || isSidebarVisible"
        >
        </el-tooltip>

        <li
          class="sub_list"
          :class="{ active }"
          id="sublistOne"
          @click="toggleClass()"
        >
          <a href="#">
            <div class="nav_icon">
              <span class="icon" v-if="active"
                ><img src="../../assets/drop/unnamed.png"
              /></span>
              <span class="icon" v-else
                ><img src="../../assets/drop/Group 1874.svg"
              /></span>
            </div>
            <span
              class="nav_text"
              :class="!isSidebarVisible && isCrmUser() ? 'hideLabel' : ''"
              >Organization Settings</span
            >
          </a>
          <ul
            :class="isCrmUser() && !isSidebarVisible ? 'ulOrgCRM' : 'sub_nav'"
            v-if="active"
          >
            <li
              :class="{
                activeProject:
                  $router.currentRoute.path === '/organisationSummary',
              }"
              @click="collapseSidebar"
            >
              <router-link
                :to="{ name: 'organisationSummary' }"
                :class="{
                  activeProject:
                    $router.currentRoute.path === '/organisationSummary',
                }"
              >
                - Organization Profile
              </router-link>
            </li>
            <li
            v-if="!isIndia && isCrmUser()"
              :class="{
                activeProject:
                  $router.currentRoute.path === '/userManagement2',
              }"
              @click="collapseSidebar"
            >
              <router-link
              v-if="!isIndia && isCrmUser()"
                :to="{ name: 'userManagement2' }"
                :class="{
                  activeProject:
                    $router.currentRoute.path === '/userManagement2',
                }"
              >
                - User Management
              </router-link>
            </li>
            <li
              v-if="isAdmin && !isVipPowerGazebo"
              :class="{
                activeProject: $router.currentRoute.path === '/admin',
              }"
              @click="collapseSidebar"
            >
              <router-link
                v-if="isAdmin"
                :to="{ name: 'admin' }"
                :class="{
                  activeProject: $router.currentRoute.path === '/admin',
                }"
              >
                - Manage Component
              </router-link>
            </li>
            <li
              v-if="isAdmin"
              :class="{
                activeProject:
                  $router.currentRoute.path === '/organisationDefaults',
              }"
              @click="collapseSidebar"
            >
              <router-link
                v-if="isAdmin"
                :to="{ name: 'organisationDefaults' }"
                :class="{
                  activeProject:
                    $router.currentRoute.path === '/organisationDefaults',
                }"
              >
                - Design Defaults
              </router-link>
            </li>
            <li
              v-if="isCrmUser()"
              :class="{
                activeProject:
                  $router.currentRoute.path === '/documentManagement',
              }"
              @click="collapseSidebar"
            >
              <router-link
                v-if="isCrmUser()&&!isSOMUsers()"
                :to="{ name: 'documentManagement' }"
                :class="{
                  activeProject:
                    $router.currentRoute.path === '/documentManagement',
                }"
              >
                - Manage Documents
              </router-link>
            </li>

            <li
              v-if="isCrmUser()&&!isSOMUsers()"
              :class="{
                activeProject: $router.currentRoute.path === '/projectTimeline',
              }"
              @click="toggleSidebarStatus"
            >
              <router-link
                :to="{ name: 'projectTimeline' }"
                :class="{
                  activeProject: $router.currentRoute.path === '/projectTimeline',
                }"
              >
                - Payment Schedule
              </router-link>
            </li>

            <li
              :class="{
                activeProject: $router.currentRoute.path === '/customTariff',
              }"
              @click="collapseSidebar"
            >
              <router-link
                :to="{ name: 'customTariff' }"
                :class="[
                  'tariffClass',
                  {
                    activeProject:
                      $router.currentRoute.path === '/customTariff',
                  },
                ]"
              >
                - Tariff Rate
              </router-link>
            </li>
            <li
              :class="{
                activeProject:
                  $router.currentRoute.path === '/addersAndDiscounts',
              }"
              @click="collapseSidebar"
            >
              <router-link
                :to="{ name: 'addersAndDiscounts' }"
                :class="{
                  activeProject:
                    $router.currentRoute.path === '/addersAndDiscounts',
                }"
              >
                - Adders and Discounts
              </router-link>
            </li>
            <li v-if="isAdmin && isCrmUser()"
              :class="{
                activeProject:
                  $router.currentRoute.path === '/pipelines',
              }"
              @click="collapseSidebar"
            >
              <router-link
                v-if="isAdmin"
                :to="{ name: 'pipelines' }"
                :class="{
                  activeProject:
                    $router.currentRoute.path === '/pipelines',
                }"
              >
                - Pipelines
              </router-link>
            </li>
            <li v-if="isAdmin && isCrmUser()"
              :class="{
                activeProject:
                  $router.currentRoute.path === '/custom-task-list',
              }"
              @click="collapseSidebar"
            >
              <router-link
                v-if="isAdmin"
                :to="{ name: 'custom-task-list' }"
                :class="{
                  activeProject:
                    $router.currentRoute.path === '/custom-task-list',
                }"
              >
                - Custom Task List
              </router-link>
            </li>
            <li
              :class="{
                activeProject: $router.currentRoute.path === '/incentives',
              }"
              @click="collapseSidebar"
            >
              <router-link
                :to="{ name: 'incentives' }"
                :class="{
                  activeProject: $router.currentRoute.path === '/incentives',
                }"
              >
                - Manage Incentives
              </router-link>
            </li>
            <li
              :class="{
                activeProject: $router.currentRoute.path === '/financials',
              }"
              @click="collapseSidebar"
            >
              <router-link
                :to="{ name: 'financials' }"
                :class="{
                  activeProject: $router.currentRoute.path === '/financials',
                }"
              >
                - Manage Financials
              </router-link>
            </li>
            <li
              :class="{
                activeProject: $router.currentRoute.path === '/dailyConsumptionProfile',
              }"
              @click="collapseSidebar"
            >
              <router-link
                :to="{ name: 'dailyConsumptionProfile' }"
                :class="{
                  activeProject: $router.currentRoute.path === '/dailyConsumptionProfile',
                }"
              >
              <div>
                -<span>Daily Consumption</span>
                <br/>
                <span style="margin-left:6px">Profile Library</span>
              </div>
                
              </router-link>
            </li>
            <li
              :class="{
                activeProject: $router.currentRoute.path === '/credits',
              }"
              @click="collapseSidebar"
            >
              <router-link
                :to="{ name: 'credits' }"
                :class="{
                  activeProject: $router.currentRoute.path === '/credits',
                }"
              >
                - Credits
              </router-link>
            </li>
            <!-- For now the integrations page is only visible to CRM users.
            But this might change in future, for example if Nearmap is added as an external integration. -->
            <li
              v-if="isCrmUser()"
              :class="{
                activeProject:
                  $router.currentRoute.path === '/integrations',
              }"
              @click="toggleSidebarStatus"
            >
              <router-link
                :to="{ name: 'integrations' }"
                :class="{
                  activeProject:
                    $router.currentRoute.path === '/integrations',
                }"
              >
                - External Integrations
              </router-link>
            </li>
            <li
              v-if="isCrmAvailable"
              :class="{
                activeProject:
                  $router.currentRoute.path === '/crmsettings',
              }"
              @click="collapseSidebar"
            >
              <router-link
                v-if="isCrmAvailable"
                :to="{ name: 'crmsettings' }"
                :class="{
                  activeProject:
                    $router.currentRoute.path === '/crmsettings',
                }"
              >
                - CRM Settings
              </router-link>
            </li>
            <li
              :class="{
                activeProject: $router.currentRoute.path === '/siteSurveyForm',
              }"
              @click="toggleSidebarStatus"
            >
              <router-link
                :to="{ name: 'siteSurveyForm' }"
                :class="{
                  activeProject: $router.currentRoute.path === '/siteSurveyForm',
                }"
              >
                - Site Survey Form
              </router-link>
            </li>
            <!-- <li>
                        <router-link 
                          :to="{name: 'wireSizeCalculator'}" 
                          v-show="hasAccess"
                          :class="{activeProject: $router.currentRoute.path=== '/wireSizeCalculator'}"
                          class="disabled-for-now"
                        >
                          - Wire Size Calculator
                        </router-link>
                      </li> -->
          </ul>
        </li>
        <li
          class="sub_list"
          :class="{ active: activeHelpOptions }"
          id="sublist"
          @click="(activeHelpOptions = !activeHelpOptions), (active = false)"
        >
          <a href="#">
            <div class="nav_icon">
              <span class="icon help" v-if="activeHelpOptions"></span>
              <span class="icon" v-else
                ><img src="../../assets/drop/Group 289.svg"
              /></span>
            </div>
            <span
              class="nav_text"
              :class="!isSidebarVisible && isCrmUser() ? 'hideLabel' : ''"
              >Help</span
            >
          </a>
          <ul
            :class="isCrmUser() && !isSidebarVisible ? 'ulOrgCRM' : 'sub_nav'"
            v-if="activeHelpOptions"
          >
            <li @click="collapseSidebar" v-if="isHelpDocEnabled">
              <a href="https://help.arka360.com/" target="_blank">
                - Help Docs
              </a>
            </li>
            <li @click="collapseSidebar">
              <a @click="shortcutsDialogVisible = !shortcutsDialogVisible">
                - Shortcuts
              </a>
            </li>
            <li
              v-if="isHelpDocEnabled"
              @click="
                collapseSidebar();
                toggleChatIcon();
              "
            >
              <a href="javascript:void(0)">
                - {{ chatIconStatus }} Chat Icon
              </a>
            </li>
            <li
              v-if="!isReviewEnabled"
              @click="
                collapseSidebar();
                handleReviewClick();
              "
            >
              <a>
                - Review us on G2
              </a>
            </li>
          </ul>
        </li>

        <el-tooltip
          class="item"
          effect="dark"
          content="Support Tickets"
          placement="right"
          :disabled="!isCrmUser() || isSidebarVisible"
        >
        </el-tooltip>

        <el-tooltip
          class="item"
          effect="dark"
          content="Logout"
          placement="right"
          :disabled="!isCrmUser() || isSidebarVisible"
        >
          <li
            v-if="!(isLogoutDisabled)"
            @click="
              collapseSidebar();
              logoutUser();
            "
          >
            <a>
              <div class="nav_icon">
                <img
                  class="Group-1544"
                  src="../../assets/iconFonts/leftSideBar/Group-1544@3x.png"
                  alt=""
                />
                <!-- <span class="icon help"></span> -->
              </div>
              <span
                class="nav_text"
                :class="!isSidebarVisible && isCrmUser() ? 'hideLabel' : ''"
                >Logout</span
              >
            </a>
          </li>
        </el-tooltip>
      </ul>
    </nav>

    <div
      @click="
        collapseSidebar();
        goToUserProfile();
      "
      class="profile_details"
    >
      <el-tooltip
        class="item"
        effect="dark"
        placement="right"
        :disabled="!isCrmUser() || isSidebarVisible"
      >
        <template v-slot:content>
          <div>{{ userName }}<br/>{{ userEmail }}</div>
        </template>
        <span class="profile_icon">
          <el-avatar v-if="userLogo"  :src="userLogo" style="background-color: white" class="avatar"></el-avatar>
          <el-avatar v-else :style="{
            backgroundColor: generateColorFromName(avtarUserName),
            color: 'white',
          }">
            {{ avtarUserName ? returnInitials(avtarUserName) : "" }}
          </el-avatar>
        </span>
      </el-tooltip>
      <div
        class="pro_text"
        :class="!isSidebarVisible && isCrmUser() ? 'hideLabel' : ''"
      >
        <h5>{{ userName }}</h5>
        <h6>{{ userEmail }}</h6>
      </div>
    </div>
  </aside>
  <el-dialog
    :visible.sync="shortcutsDialogVisible"
    append-to-body
    :close-on-click-modal="false"
    title="Shortcuts"
    width="30%"
    height="66%"
  >
    <div class="data-summary">
      <p v-if="isVipPowerGazebo">
        PowerGazebo™
        <span class="output"> G </span>
      </p>
      <p v-if="isVipPowerGazebo">
        PowerPatio™
        <span class="output"> Shift + G </span>
      </p>
      <p>
        Flat Roof
        <span class="output"> P </span>
      </p>
      <p>
        Pitched Roof
        <span class="output"> R </span>
      </p>
      <p>
        Draw Face
        <span class="output"> Shift + F </span>
      </p>
      <p>
        Pen Tool
        <span class="output"> Shift + A </span>
      </p>
      <p>
        Polygon Obstruction
        <span class="output"> Shift + P </span>
      </p>
      <p>
        Rectangle Obstruction
        <span class="output"> Shift + R </span>
      </p>
      <p>
        Cylinder Obstruction
        <span class="output"> C </span>
      </p>
      <p>
        Walkway
        <span class="output"> W </span>
      </p>
      <p>
        Safety Line
        <span class="output"> E </span>
      </p>
      <p>
        Handrail
        <span class="output"> H </span>
      </p>
      <p>
        Property Line
        <span class="output"> N </span>
      </p>
      <p>
        Tree
        <span class="output"> T </span>
      </p>
      <p>
        Dimension Tool
        <span class="output"> D </span>
      </p>
      <p>
        Lasso Tool
        <span class="output"> L </span>
      </p>
      <p v-if="!isVipPowerGazebo">
        Subarray
        <span class="output"> S </span>
      </p>
      <p v-if="isUSFlagEnabled && !isVipPowerGazebo">
        PowerGazebo™
        <span class="output"> G </span>
      </p>
      <p v-if="isUSFlagEnabled && !isVipPowerGazebo">
        PowerPatio™
        <span class="output"> Shift + G </span>
      </p>
      <p>
        Text Box
        <span class="output"> B </span>
      </p>
      <p v-if="!isVipPowerGazebo">
        Fill face the selected Model
        <span class="output"> F </span>
      </p>
      <p v-if="!isVipPowerGazebo">
        Optimize the selected Subarray
        <span class="output"> O </span>
      </p>
      <p v-if="!isVipPowerGazebo">
        Inverter Menu
        <span class="output"> I </span>
      </p>
      <p>
        Mirror Image
        <span class="output"> M </span>
      </p>
      <p>
        Cancel drawing/placing
        <span class="output"> Esc </span>
      </p>
      <p>
        Discard properties changes
        <span class="output"> Esc </span>
      </p>
      <p>
        Complete drawing/placing
        <span class="output"> Enter </span>
      </p>
      <p>
        Accept change
        <span class="output"> Enter </span>
      </p>
      <p>
        Delete selected object
        <span class="output"> Del </span>
      </p>
      <p>
        Undo
        <span class="output"> Ctrl / Cmd + Z </span>
      </p>
      <p>
        Redo
        <span class="output"> Ctrl / Cmd + Shift + Z </span>
      </p>
      <p>
        Save
        <span class="output"> Ctrl / Cmd + S </span>
      </p>
      <p>
        Quick Look
        <span class="output"> Q </span>
      </p>
      <p>
        X-Ray mode
        <span class="output"> Press and Hold 'X' </span>
      </p>
      <p>
        Zoom In
        <span class="output"> Ctrl / Cmd + Alt + Plus(+) </span>
      </p>
      <p>
        Zoom Out
        <span class="output"> Ctrl / Cmd + Alt + Minus(-) </span>
      </p>
      <p>
        Default Zoom
        <span class="output"> Ctrl / Cmd + Alt + Zero(0) </span>
      </p>
      <p>
        2D mode
        <span class="output"> Double Press '2' </span>
      </p>
      <p>
        3D mode
        <span class="output"> Double Press '3' </span>
      </p>
      <p>
        SLD mode
        <span class="output"> Double Press '4' </span>
      </p>
      <p v-if="!isVipPowerGazebo">
        Add Table Mode
        <span class="output"> A </span>
      </p>
      <p v-if="!isVipPowerGazebo">
        Delete Table Mode
        <span class="output"> Shift + D </span>
      </p>
    </div>
  </el-dialog>
</main>
