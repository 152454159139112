
  <div
    :class="isDrilldown&&selectedSort.length > 0 ? 'container-dash-sort' :isDrilldown? 'container-dash': 'container'"
  >
    <div class="navContainer" @click="handleCardSelect" >
      <div class="gridContainer">
        <div class="firstContainer">
          <MenuView
            :status.sync="status"
            :homeOwn.sync="homeOwn"
            :prior.sync="prior"
            :colloabo.sync="colloabo"
            :dueDateClear.sync="dueDateClear"
            :startDateClear.sync="startDateClear"
            :clearall.sync="clearall"
            :menuItems="menuData"
            @statusCheckbox="statusCheckbox"
            @start-Date="startDateRange"
            @due-Date="dueDateRange"
            @loadMore="debounceHomeownerLoadMore"
            @loadMore2="debounceCollaboratorLoadMore"
            @collaboratorCheckbox="collaboratorCheckbox"
            @homeownerCheckbox="homeownerCheckbox"
            @priorityCheck="priorityCheck"
            @searchLeads="searchLeads"
            @searchUsers="searchUsers"
            :isDrillDown="isDrilldown"
          />
         
          <div
            class="relativeContCustom"
            v-if="isDrilldown || isTableOptClicked"
          >
            <p class="customize" v-if="isTableOptClicked || isDrilldown">
              Customize Table
            </p>
            <div :class="customClassNames" v-if="showCustomizeTable">
              <p class="drpDwnHeading">Select columns to view</p>
              <div class="drpdwnFlex" id="checkColor">
                <el-checkbox-group v-model="checkedColumnsList">
                  <el-checkbox
                    v-for="(column, index) in customColumnsListData"
                    :key="index"
                    :label="column.id"
                  >
                    {{ column.title }}
                  </el-checkbox>
                </el-checkbox-group>
              </div>
              <div class="applyBtnContainer" @click="applySelectedColumns()">
                <el-button type="primary" class="applyBtn">Apply</el-button>
              </div>
            </div>
          </div>
          <div
            style="margin-bottom: 35px"
            v-if="
              (admin == 'ADMIN' || manager == true) &&
              !isDrilldown &&
              selectedTaskValue !== 'my'
            "
          >
            <AvatarHandler
              v-if="avatars.length > 0 && (admin == 'ADMIN' || manager == true )"
              :avatars="avatars"
              :overlap="true"
              :limit="3"
              :imgWidth="35"
              :removable="true"
              :editable="false"
              :fontSize="'14px'"
              :hover="true"
              :count="count"
              :gap="5"
              :isColumn="true"
              :isSelectable="true"
              @loadMore="debounceCollaboratorLoadMore"
              @searchAssignee="searchAssignee"
              @handleSelectedChange="handleAvatarSelectedChange"
            />
          </div>
        </div>

        <div class="secContainer">
          <div
            style="display: flex; align-items: center; margin: 0 10px"
            v-if="isTableOptClicked && !isDrilldown"
          >
            <span style="font-weight: 400; font-size: 16px">
              Group by lead
            </span>
            <el-switch
              v-model="groupByLead"
              style="margin-left: 5px; width: 30px"
              @change="handleGroupByLead"
            >
            </el-switch>
          </div>
          <div class="searchInpCont" v-if="!isDrilldown">
            <img src="./assets/search.svg" class="searchIcon" />
            <input
              type="text"
              class="searchInput"
              placeholder="Search"
              :style="{cursor : !scrollStart ?'not-allowed':''}"
              v-model="search"
              @input="updateGroup()"
              :disabled="!scrollStart "
            />
          </div>
          <div class="tableTypeCont">
            <el-tooltip
              class="item"
              effect="dark"
              content="Board View"
              placement="top"
            >
              <div
                v-if="!isDrilldown"
                :class="['tableOpt', !isTableOptClicked ? 'activeClass' : '']"
                @click="setLeadListingView(false)"
              >
                <b-icon 
                  icon="kanban"
                  class="typeIcon"
                />
              </div>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="List View"
              placement="top"
            >
              <div
                v-if="!isDrilldown"
                :class="['cardOpt', isTableOptClicked ? 'activeClass' : '']"
                @click="setLeadListingView(true)"
              >
                <b-icon 
                  icon="list-ul"
                  class="typeIcon"
                />
              </div>
            </el-tooltip>
            <el-button
              v-if="!isDrilldown"
              type="primary"
              class="createLeadBtn"
              @click="handleShowOrHideDrawer"
              >Create Task</el-button
            >
            <el-button
              v-if="isDrilldown"
              type="primary"
              class="createLeadBtn"
              @click="downloadCsv"
              v-loading="downlodLoading"
              >Export CSV</el-button
            >
            <div
              v-if="isDrilldown"
              style="padding-left: 1rem; font-size: 1rem; cursor: pointer"
              @click="handleClose"
            >
              <img src="../../../assets/img/close.svg" alt="" />
            </div>
            <GlobalDrawer
              :isOpen="showCreateDrawer"
              :title="createTaskHeading"
              :handleCloseDialog="handleShowOrHideDrawer"
              :drawer-size="750"
              class="task-drawer-dialog"
            >
              <!-- <template v-if="taskMode === 'view'" v-slot:header>
                <button>Mark Complete</button>
              </template> -->
              <template v-if="showCreateDrawer" v-slot:body>
                <TaskDrawerDynamic
                  :addingCollab.sync="addingCollab"
                  :deletingCollab.sync="deletingCollab"
                  :reminderData.sync="reminderDetails"
                  :mode="taskMode"
                  :taskData="taskValue"
                  @createTask="createTask"
                  @createTaskClicked="createTaskClicked"
                  :isCreateLoading="isCreateLoading"
                  @updateTask="updateTask"
                  :activityTimeline="activityTimeline"
                  @deleteTask="deleteTask"
                  @allowDocumentUpload="handleAllowDocUpload"
                  :documentUploaded="documentUploaded"
                  @documentUploading="documentUploaded = false"
                  :showCloseDialog="showCloseDialog"
                  @setUnsavedChanges="setUnsavedChanges"
                  @closeDrawer="closeDrawer"
                  @handleCloseWarningDialog="showCloseDialog = false"
                />
                <!-- @openSetReminder="handleOpenSetReminder" -->
              </template>
            </GlobalDrawer>
            <!-- <GlobalDrawer
              :isOpen="showSetReminderDrawer"
              :title="setReminderHeading"
              :handleCloseDialog="handleHideSetReminder"
              :drawer-size="360"
            >
              <template v-if="showCreateDrawer" v-slot:header>
                 <SetReminderDrawer :reminderInfo="returnSetReminderData()" />
              </template>
            </GlobalDrawer> -->
            <setReminder
              :key="setKey"
              :showCreateDrawer="showSetReminderDrawer"
              @close="showSetReminderDrawer = false"
              @save="onSave"
              :drawerSize="400"
              :page="'task'"
              :lead="taskValue"
            />
          </div>
        </div>
      </div>
      <div class="activeFilters">
        <div>
          <div class="tagsContainer" v-if="selectedSort.length > 0">
            <div>
              <img src="./assets/sort.svg" alt="alter" />
            </div>
            <div>
              <el-tag
                class="tag"
                size="medium"
                style="margin-right: 15px"
                closable
                @close="handleRemoveSort()"
              >
                {{ handleDisplaySort(selectedSort) }}
              </el-tag>
            </div>
          </div>
        </div>
        <div
          class="tagsContainer"
          v-if="
            selectedFilter.length > 0 ||
            (statusSources.value && statusSources.value.length>0) ||
            (collaboratorSources.value && collaboratorSources.value.length>0) ||
            (homeownerSources.value && homeownerSources.value.length>0) ||
            startDateClosure ||
            dueDateClosure ||
            priorityValue
          "
        >
          <div>
            <img src="./assets/filter_alt.svg" alt="alter" />
          </div>
          <div>
            <el-tag
              v-for="item in selectedFilter"
              :key="item"
              class="tag"
              size="medium"
              closable
              :disable-transitions="false"
              @close="handleRemoveFilter(item)"
            >
              {{ handleDisplayFilters(item) }} : {{ item[2] }}
            </el-tag>
            <el-tooltip v-if="statusSources.value  && statusSources.value.length > 0" placement="bottom" effect="dark">
              <template v-slot:content>
                <div> <p class="filterTag" v-for="statusSource in statusSources.value" :key="statusSource.value">{{ statusSource }}</p></div>
              </template>
            <el-tag
              class="tag"
              size="medium"
              closable
              @close="handleStatusremove()"
            >
              {{ statusSources.label }} <span v-if="statusSources.value.length==1">: {{ statusSources.value[0] }}</span> <span v-else class="tag-value">{{ statusSources.value.length }}</span>
            </el-tag>
          </el-tooltip>
            <el-tooltip v-if="collaboratorSources.value  && collaboratorSources.value.length>0" placement="bottom" effect="dark">
              <template v-slot:content>
                <div> <p class="filterTag" v-for="collaboratorSource in collaboratorSources.value" :key="collaboratorSource.value">{{ collaboratorSource }}</p></div>
              </template>
            <el-tag
              class="tag"
              closable
              @close="handleCollaboratorsremove()"
            >
              {{ collaboratorSources.label }} <span v-if="collaboratorSources.value.length==1">: {{ collaboratorSources.value[0] }}</span> <span v-else class="tag-value">{{ collaboratorSources.value.length }}</span>
            </el-tag>
          </el-tooltip>
          <el-tooltip v-if="homeownerSources.value && homeownerSources.value.length>0 " placement="bottom" effect="dark">
              <template v-slot:content>
<div > <p class="filterTag" v-for="homeownerSource in homeownerSources.value" :key="homeownerSource.value">{{ homeownerSource }}</p></div>
</template>
            <el-tag
              class="tag"
              closable
              @close="handleHomeownerremove()"
            >
              {{ homeownerSources.label }} <span v-if="homeownerSources.value.length==1">: {{ homeownerSources.value[0] }}</span>  <span v-else class="tag-value">{{ homeownerSources.value.length }}</span>
            </el-tag>
          </el-tooltip>
            <el-tag
              v-if="startDateClosure"
              class="tag"
              size="medium"
              closable
              :disable-transitions="false"
              @close="handleRemovestartClosure()"
            >
              {{ startDateClosure.label }} :
              {{ formattedDate(startFrom, startTo) }}
            </el-tag>
            <el-tag
              v-if="dueDateClosure"
              class="tag"
              size="medium"
              closable
              :disable-transitions="false"
              @close="handleRemoveEndClosure()"
            >
              {{ dueDateClosure.label }} : {{ formattedDate(dueFrom, dueTo) }}
            </el-tag>
            <el-tag
              v-if="priorityValue"
              class="tag"
              size="medium"
              closable
              :disable-transitions="false"
              @close="handleRemovePriority()"
            >
              {{ priorityValue.label }} : {{ priorityValue.value }}
            </el-tag>
          </div>
        </div>
        <p
          class="clrAll"
          v-if="
            selectedFilter.length > 0 ||
            (statusSources.value && statusSources.value.length>0) ||
            startDateClosure ||
            (collaboratorSources.value && collaboratorSources.value.length>0) ||
            (homeownerSources.value && homeownerSources.value.length>0) ||
            dueDateClosure ||
            priorityValue ||
            selectedSort.length > 0
          "
          @click="handleClear"
        >
          Clear all
        </p>
      </div>
    </div>
    <div style="height: 60vh; margin-top: 10px">
      <KanbanView
        v-if="!isTableOptClicked && !isDrilldown"
        :kanBanColumns="kanbanColumnsData"
        :dataTable="kanbanDataValue"
        :selectedCard="selectedCard"
        :isLoading="isKanbanLoading"
        :page="'Task'"
        @updateDate="updateDate"
        :loadMoreData="debounceLoadMore"
        @handleColumnNumber="handleColumnNumber"
        :handleOrderClick="handleOnClick"
        :offSetHeight="'20.3rem'"
        @handlePriority="handlePriority"
        @updateStatus="updateStatus"
        @leadDetails="leadDetails"
        @editableName="editableName"
        :showKanbanLoader="showKanbanLoader"
        :showKanbanLoading="showKanbanLoading"
        :selectedTask="true"
        :activeKanbanColumn="activeKanbanColumn"
      />

      <div v-else>
        <TableView
          v-loading="dataLoading"
          :tableData="tableColumnData"
          @update="editableName"
          @updateStatus="updateTableStatus"
          @updatePriority="handleTablePriority"
          @updateDate="updateTableDate"
          @loadMoreTableData="loadMoreTableData"
          @handleRowClick="handleOnClick"
          @leadDetails="leadDetails"
          v-if="!groupByLead"
          :isDrilldown="isDrilldown"
          :taskCount="taskCount"
          :showHeaderSortable="isDrilldown"
          :showTableLoader="showTableLoader"
          :checkedTableColumns="checkedTableColumns"
          :checkedTableViewColumn="checkedTableViewColumn"
        />
        <DropDownTable
          :leadsNames="leadsNames"
          @update="editableName"
          @updateStatus="updateTableStatus"
          @updatePriority="handleTablePriority"
          @updateDate="updateTableDate"
          @handleRowClick="handleOnClick"
          @getLeadsData="getLeadsData"
          @leadDetails="leadDetails"
          :activeNames="activeNames"
          @loadMoreLeadName="loadMoreTaskNames"
          :selectedRowId.sync="selectedRowId"
          :isDrilldown="isDrilldown"
          :selectedSort="selectedSort"
          :selectedTaskValue="selectedTaskValue"
          :selectedFilter="selectedFilter"
          :scrollStart="scrollStart"
          :showGroupLoader="showGroupLoader"
          :checkedDropDownTableList="checkedDropDownTableList"
          :checkedDropDownHeaderList="checkedDropDownHeaderList"
          v-else
        />
      </div>
      <LeadsDrawer
        v-if="showLeadQuickViewPane"
        :drawer="showLeadQuickViewPane"
        :lead="lead"
        :activeTab="'first'"
        @close="[(showLeadQuickViewPane = false), (lead = null)]"
      />
      <!-- Commenting duplicate create task and set reminder -->
      <!-- <GlobalDrawer
        :isOpen="showCreateDrawer"
        :title="createTaskHeading"
        :handleCloseDialog="handleShowOrHideDrawer"
        :drawer-size="550"
      >
        <template v-if="showCreateDrawer" v-slot:header>
          <TaskDrawerDynamic
            @createTask="createTask"
            @openSetReminder="handleOpenSetReminder"
          />
        </template>
      </GlobalDrawer>
      <GlobalDrawer
        :isOpen="showSetReminderDrawer"
        :title="setReminderHeading"
        :handleCloseDialog="handleHideSetReminder"
        :drawer-size="360"
      >
        <template v-if="showCreateDrawer" v-slot:header>
          <SetReminderDrawer :reminderInfo="returnSetReminderData()" />
        </template>
      </GlobalDrawer> -->
    </div>
  </div>
