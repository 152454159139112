<template>
    <div class="email-container-card">
        <MeetingPopup 
         v-if="openMeetingPopup"
         :openDrawer="openMeetingPopup" 
         :flag="popupFlag"
         :meetingData="items"
            :admin="admin"
         :header="headerData"
         :leadData="leadData"
         :tabType="tab"
         @onClickClose="handleMeetingPopupVisibility"
          @updateMeeting="updateMeeting"
          @removeMeeting="removeNonAttendeeMeeting"
          />

          <GlobalDelete
            v-if="openDeleteDialogPopup"
             @confirm="confirmMeetingDelete"
              @onClickClose="openDeleteDialog"
              :message="'Do you want to cancel this meeting?'"
              :openDrawer="openDeleteDialogPopup"
              />


          <div v-if="loading">
            <el-skeleton
             :rows="3" 
             animated
              />
          </div>
     <div 
     v-else
     class="head-container"
      >
            <div
             class="icon-container" 
             @click="handleEmailVisibility"
             >
                <i 
                v-if="!isVisible" 
                class="el-icon-arrow-right"
                 />
                <i 
                v-else
                 class="el-icon-arrow-down"
                  />
            </div>
            <div class="heading-container">
                <p class="email-description-content">
                    Meeting - {{ items.subject }}
                </p>
            </div>
             <div class="action-container">
                <p 
                v-if="items.htmlLink"
                @click="copyToClipboard(items.htmlLink)"
                 >
                    View
                </p>
                <p 
                 v-if="items.hangoutLink"
                 @click="copyToClipboard(items.hangoutLink)"
                 >
                    Join
                </p>
                <p  
                v-if="items.organized_by == admin"
                @click="handleMeetingPopupVisibility"
                >
                    Edit
                </p>
                <p @click="openDeleteDialog">
                    Cancel
                </p>
            </div>
        </div>
        <div 
        v-if="!loading"
        class="email-info-container"
         >
            <div>
                <div
                style="padding-left: 20px;"
                >
                    <p class="person-info">Organised by {{items.organized_by}}</p>
                    <p class="person-info">{{items.time}}</p>
                </div>
                <div
                     v-if="isVisible"
                    class="email-content-details"
                >
                    <div
                    v-if="items.description"
                     class="meeting-content"
                       >
                       <p class="meeting-description">
                        Meeting Description
                       </p>
                       <div
                        v-html="htmlContent" 
                       />
                    </div>
    
                    <div class="meetings-info">
                        <div>
                            <p class="info-header">Attendees</p>
                            <p class="info-description"> {{items.attending_members.length}} Attendees</p>
                        </div>
                        <div>
                            <p class="info-header">Duration</p>
                            <p class="info-description">{{items.duration}}</p>
                        </div>
                    </div>
                </div>
                <div
                 v-else
                 class="email-content-details"
                 >
                        <p
                         class="restrictedClass">
                          {{items.description}}
                        </p>
                </div>
            </div>
        </div>
    </div>
    </template>
    
    <script>
    import MeetingPopup from './meetingPopup.vue';
    import  GlobalDelete from "./globalDelete.vue";
    import API from "@/services/api/";
    export default {
        emits: ["removeMeeting", "updateNewMeeting", "removeMeeting"],
        components:{
            MeetingPopup,
            GlobalDelete,
        },

        props:{
            items:{
            type:Object,
            default:() => {},
        },
          admin:{
            type: String,
            default: null,
          },
          leadData:{
            type: Object,
            default:() => {},
          },
          tab:{
            type: String,
            default: null,
          }
        },

        data(){
            return{
                isVisible: false,
                openMeetingPopup:false,
                popupFlag:"edit",
                headerData:"Update Meeting",
                loading:false,
                deleteLeadConfirmationDialog:false,
                openDeleteDialogPopup:false,
            }
    },

    computed:{
        htmlContent(){
            return this.items.description.replace(/\n/g, '<br>');
        }
    },


    methods:{
        handleEmailVisibility(){
            this.isVisible = !this.isVisible;
        },

        handleMeetingPopupVisibility(){
            this.openMeetingPopup = !this.openMeetingPopup;
            this.handleEmailVisibility()
        },
        async confirmMeetingDelete(){
            this.openDeleteDialogPopup = false;
            this.loading = true;
            try{
                const data = await API.EXTERNAL_INTEGRATION.CANCEL_EVENT(this.items.id)
                this.$emit("removeMeeting", this.items.id)

                this.$message({
                showClose: true,
                message: "Meeting cancelled successfully",
                type: "success",
                center: true,
            });

            }catch(e){
                this.$message({
                showClose: true,
                message: "Error in canceling the meeting",
                type: "error",
                center: true,
            });
                console.log(e)
            }
            this.loading = false;
   
        },

        openDeletePopup(){
            this.deleteLeadConfirmationDialog = true;
        },

        copyToClipboard (url){
            window.open(url);
        },

        updateMeeting(data){
            this.$emit("updateNewMeeting", data)
        },
        openDeleteDialog(){
            this.openDeleteDialogPopup = !this.openDeleteDialogPopup
        },
        removeNonAttendeeMeeting(){
              this.$emit("removeMeeting", this.items.id)
        }
    },
    }
    </script>
    
    <style  scoped>
    .email-container-card{
        padding: 10px;
        border: 1px solid #CCCCCC;
        border-radius: 8px;
    }
    
    .head-container{
        display: flex;
        gap: 10px;
    }
    
    .icon-container{
        cursor: pointer;
    }
    
    .heading-container{
        font-size: 14px;
        font-weight: 600;
        width: 300px;
    }

    .email-description-content{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    .action-container{
        display: flex;
        gap: 10px;
        font-size: 14px;
        font-weight: 600;
        margin-left: auto;
        color:  #1C3366;
        cursor: pointer;
    }
    
    .email-info-container{
        display: flex;
        gap: 10px;
        margin-top: 10px;
    }

    .meeting-content{
        padding-bottom: 10px;
        border-bottom: 1px solid #ccc;
    }

    .meeting-description{
        font-size: 12px;
        font-weight: 400;
        color: #777777;
    }
    
    .profile-picture-container ::v-deep .el-avatar{
     background-color: #1C3366;
    }
    
    .person-name{
        font-size: 14px;
        font-weight: 600;
    }
    
    .person-info{
        color: #777777;
        font-size: 12px;
        font-weight: 400;
    }
    
    .email-content-details{
        margin-top: 10px;
        font-size: 14px;
        padding-left: 20px;
        font-weight: 400;
        line-height: 22px;
    }
    
    .logo-image{
        margin-top: 10px;
    }
    .meetings-info{
        margin-top: 5px;
        display: flex;
        gap: 40px;
    }

    .info-card-container{
        display: flex;
        flex-direction: column;
   
       
    }

    .info-header{
        font-size: 12px;
        font-weight: 400;
        color: #777777;
    }

    .info-description{
        font-weight: 400;
        font-size: 14px;

    }

    .restrictedClass{
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; 
    }

    </style>