<template>
    <div class="parentContainer">
        <el-dialog :visible="isAddNewStepPopupVisible" :close-on-click-modal="false" :title="title"
            @close="onDialogClose">
            <div class="container">
                <el-form ref="form" :model="form" @submit.native.prevent class="formContainer">
                    <div>
                        <p class="font14Bold">Title <span class="asterisk">*</span></p>
                        <el-input v-model="form.title" name="title" placeholder="Enter Title"
                            @blur="checkTitle" maxlength="50" show-word-limit></el-input>
                        <p class="error-msg" v-if="showTitleError">This field is required.</p>
                    </div>
                    <div>
                        <p class="font14Bold">Description <span class="asterisk">*</span></p>
                        <el-input type="textarea" v-model="form.description" name="description"
                            placeholder="Enter Description" @blur="checkDescription" maxlength="200"  @input="limitLines" show-word-limit></el-input>
                        <p class="error-msg" v-if="showDescriptionError">This field is required.</p>
                    </div>
                    <div class="flexContainer">
                        <div class="width100">
                            <p class="font14Bold">
                                {{ form.unit == handleCurrencySymbol ? 'Amount charged at this step' : '% of amount charged at this step'
                                }}
                                <span class="asterisk">*</span>
                            </p>
                            <el-input v-model="form.amount" name="amount" type="number" placeholder="Enter Amount"
                                @input="checkAmount"></el-input>
                            <p class="error-msg" v-if="showAmountError">{{ amountErrorMsg }}</p>
                        </div>
                        <div class="width100">
                            <p class="font14Bold">Unit</p>
                            <el-select v-model="form.unit" placeholder="commercial" @change="handleUnitChange">
                                <el-option v-for="item in unitTypes" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </el-form>
                <div class="btnContainer">
                    <el-button type="primary" class="addFAQBtn" @click="handleConfirm()" :loading="isLoading"
                        :disabled="!isFormValid">Save</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import API from "@/services/api/";
import {
  getCurrencySymbol
} from "../../../utils/numberFormat/currencyFormatter";

export default {
    emits: ["update:isAddNewStepPopupVisible", "fetch-all-payment-schedule", "updatedScheduleAndSwitches"],
    name: "addNewStepPopup",

    mounted() {
        this.unitCurrencyType();
        this.editStep();
    },

    props: {
        isAddNewStepPopupVisible: {
            type: Boolean,
            default: false,
        },
        mode: {
            type: String,
            default: "",
        },
        isToggle: {
            type: Boolean,
            default: false,
        },
        projectType: {
            type: String,
            default: "",
        },
        editSelectedStep: {
            type: Object,
        },
        sumOfPercentageValuesForCommercial: {
            type: Number
        },
        sumOfPercentageValuesForResidential: {
            type: Number
        }
    },

    data() {
        return {
            form: {
                title: null,
                description: null,
                amount: null,
                unit: null,
            },
            unitTypes: [],
            touchedFields: {
                title: false,
                description: false,
            },
            isLoading: false,
            showAmountError: false,
            amountErrorMsg: null,
            showInContract: true,
        };
    },

    computed: {

        title() {
            return this.mode === 'add' ? 'Add New Step' : 'Edit Step';
        },
        successMessage() {
            return this.mode === 'add' ? 'New step added.' : 'Step details updated.'
        },
        failMessage() {
            return this.mode === 'add' ? 'Failed to add new step.' : 'Failed to update details.'
        },

        isFormValid() {
            return (
                this.form.title &&
                this.form.description &&
                this.form.amount !== null &&
                this.form.amount !== ''
            );
        },

        showTitleError() {
            return this.touchedFields.title && !this.form.title;
        },

        showDescriptionError() {
            return this.touchedFields.description && !this.form.description;
        },

        handleCurrencySymbol() {
            return this.countryCode ? getCurrencySymbol(this.countryCode) : "";
        },

        countryCode() {
            let org = JSON.parse(localStorage.getItem("organisation")) || {};
            return org.country_code;
        },
    },

    methods: {
        onDialogClose() {
            this.$emit("update:isAddNewStepPopupVisible", false);
        },
        async addNewStep() {
            this.isLoading = true;
            let data = {
                "title": this.form.title,
                "description": this.form.description,
                "value": this.form.amount,
                "type": this.form.unit == this.handleCurrencySymbol ? 'absolute' : 'percentage',
                "project_type": this.projectType,
                "show_in_contract": this.showInContract,
            };
            try {
                await API.PAYMENT_SCHEDULE.POST_ORG_PAYMENT_SCHEDULE(data);
                this.onDialogClose();
                this.$emit('fetch-all-payment-schedule');
                this.$message({
                    showClose: true,
                    message: "Created Successfully.",
                    type: "success",
                    center: true
                });
            } catch (err) {
                console.error(err)
                this.$message({
                    showClose: true,
                    message: "Unable to create",
                    type: "error",
                    center: true
                });
            } finally {
                this.isLoading = false;
            }
        },

        async updateStep() {
            let patchData = {
                "title": this.form.title,
                "description": this.form.description,
                "value": this.form.amount,
                "type": this.form.unit == this.handleCurrencySymbol ? 'absolute' : 'percentage',
                "show_in_contract": this.showInContract ? this.editSelectedStep.show_in_contract : false,
            }
            try {
                await API.PAYMENT_SCHEDULE.UPDATE_ORG_PAYMENT_SCHEDULE(this.editSelectedStep.id, patchData);
                this.$emit('updatedScheduleAndSwitches', { id: this.editSelectedStep.id, title: patchData.title, description: patchData.description, value: patchData.value, type: patchData.type, show_in_contract: patchData.show_in_contract, project_type: this.editSelectedStep.project_type });
                this.onDialogClose();
                this.$message({
                    showClose: true,
                    message: "Updated Successfully.",
                    type: "success",
                    center: true
                });
            } catch (err) {
                console.error(err)
                this.$message({
                    showClose: true,
                    message: "Unable to update.",
                    type: "error",
                    center: true
                });
            }
        },

        checkTitle() {
            this.touchedFields.title = true;
        },

        checkDescription() {
            this.touchedFields.description = true;
        },

        limitLines() {
            const lines = this.form.description.split('\n'); // Split the input into lines
            const maxLines = 10;

            if (lines.length > maxLines) {
                // Trim the input to only include the first 10 lines
                this.form.description = lines.slice(0, maxLines).join('\n');
            }
        },

        handleUnitChange(newValue) {
            this.checkAmount()
            if (newValue !== this.handleCurrencySymbol) {
                this.form.amount = '';
            }
        },

        checkAmount() {
           // Replace any character that is not a number or a decimal point
            this.form.amount = this.form.amount.replace(/[^0-9.]/g, '');

            // Prevent multiple decimal points
            this.form.amount = this.form.amount.replace(/(\..*?)\..*/g, '$1');

            // Limit the number of digits after the decimal point to two
            this.form.amount = this.form.amount.replace(/^(\d*\.\d{0,2}).*$/, '$1');

            let sumOfPercentageValues;
            // Determine sumOfPercentageValues based on projectType
            sumOfPercentageValues = (this.projectType === 'residential') ? this.sumOfPercentageValuesForResidential : this.sumOfPercentageValuesForCommercial;
            // Check if form.amount is empty
            if (!this.form.amount) {
                this.showAmountError = true;
                this.amountErrorMsg = 'This field is required.';
                return;  // Exit early if amount is empty
            }

            // Calculate remaining percentage if the unit is '% of system price'
            if (this.form.unit === '% of system price' && this.form.amount) {
                let remainingPercentage;
                // Determine remainingPercentage based on mode and show_in_contract status
                if (this.mode === 'add') {
                    remainingPercentage = 100 - sumOfPercentageValues;
                } else if(this.editSelectedStep.show_in_contract) {
                    remainingPercentage = (100 - sumOfPercentageValues) + this.editSelectedStep.value;
                } else {
                    remainingPercentage = 100 - sumOfPercentageValues;
                };
                // Validate the form.amount against the remaining percentage
                if (this.form.unit === '% of system price' && (Number(this.form.amount) > remainingPercentage) ) {
                    this.showAmountError = true;
                    this.amountErrorMsg = `% of amount should not be more than ${remainingPercentage}.\n This step will be disabled upon creation.`;
                    this.showInContract = false;
                } else {
                    this.showAmountError = false;
                    this.showInContract = true;
                }
            } else {
                this.showInContract = true;
                this.showAmountError = false;
            }
        },


        editStep() {
            if (this.mode == 'edit') {
                this.form.title = this.editSelectedStep.title;
                this.form.description = this.editSelectedStep.description;
                this.form.amount = Number(this.editSelectedStep.value);
                this.form.unit = this.editSelectedStep.type == 'absolute' ? handleCurrencySymbol : '% of system price';
            }
        },

        handleConfirm() {
            if (this.mode == 'add') {
                this.addNewStep();
            } else {
                this.updateStep();
            }
        },

        unitCurrencyType() {
            this.unitTypes = [
                {
                    value: this.handleCurrencySymbol,
                    label: this.handleCurrencySymbol
                },
                {
                    value: '% of system price',
                    label: '% of system price'
                }
            ];
            this.form.unit = this.handleCurrencySymbol;
        }
    },
};
</script>

<style scoped>
.parentContainer>>>.el-dialog__header {
    /* background-color: #1c3366; */
    background-image: linear-gradient(to bottom, #e8edf2, #e9ecf2);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0 !important;
    height: 48px !important;
    padding: 24px !important;
}

.parentContainer>>>.el-dialog__title {
    width: 157px;
    font-family: "Helvetica Neue";
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.38;
    letter-spacing: normal;
    text-align: left;
    color: #222 !important;
}

.parentContainer>>>.el-dialog__close {
    color: #222222 !important;
    font-weight: 800 !important;
    font-size: 24px !important;
}

.parentContainer>>>.el-dialog__headerbtn:focus .el-dialog__close,
.parentContainer>>>.el-dialog__headerbtn:hover .el-dialog__close {
    color: #222 !important;
}

.parentContainer>>>.el-dialog {
    border-radius: 12px !important;
    height: auto;
    overflow-y: hidden;
    margin-top: 14vh !important;
    max-width: 770px;
    width: 90%;
}

.parentContainer>>>.el-dialog__body {
    padding: 0px !important;
}

.formContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px 24px 24px 24px;
}

.flexContainer {
    display: flex;
    gap: 16px;
}

.parentContainer>>>.el-form-item {
    margin-bottom: 12x;
}


.font14Bold {
    font-size: 14px;
    font-weight: bold;
    color: #777;
    margin-bottom: 6px;
}

.asterisk {
    color: red;
}

.error-msg {
    word-break: break-word;
    margin-top: 4px;
}

.parentContainer>>>.el-select {
    width: 100%;
}

.parentContainer>>>.el-input__inner {
    height: 48px;
    font-size: 16px;
    color: #222;
    border-radius: 4px;
    border: 1px solid var(--Grey-Scale-999, #999);
    background: var(--Grey-Scale-White, #FFF);
}

.parentContainer>>>.el-input__inner::placeholder,
.parentContainer>>>.el-textarea__inner::placeholder {
    color: #777;
    font-size: 16px;
}

.parentContainer>>>.el-textarea__inner {
    font-size: 16px;
    color: #222;
    height: 176px;
    border: none;
    border-radius: 4px;
    border: 1px solid var(--Grey-Scale-999, #999);
    background: var(--Grey-Scale-White, #FFF);
}

.parentContainer>>>.el-select .el-input .el-select__caret {
    font-size: 18px;
    font-weight: bold;
    color: #222;
}

.width100 {
    position: relative;
    width: 100%;
}

.btnContainer {
    text-align: end;
    padding: 16px;
    border-top: 1px solid #ccc;
}

.addFAQBtn {
    font-size: 16px;
}

@media (max-width: 520px) {

    .parentContainer>>>.el-dialog__wrapper {
        overflow: hidden;
    }

    .parentContainer>>>.el-dialog {
        margin-top: 6vh !important;
    }

    .parentContainer>>>.el-dialog__header {
        padding: 16px !important;
    }

    .formContainer {
        max-height: 70vh;
        overflow-y: scroll;
        padding: 16px;
    }

    .flexContainer {
        flex-direction: column;
    }

    .parentContainer>>>.el-textarea__inner {
        height: 38vh;
    }
}
</style>