import axios from "axios";

export default {
    FETCH_TEAMS(){
        return axios.get('/api/user-teams/')
    },
    FETCH_USERS_TREE(params){
        return axios.get(`/api/users/?${params}`)
    },
    LOAD_MORE_DATA(url){
        return axios.get(url)
    },
    POST_TEAM(data){
        return axios.post('/api/user-teams/', data)
    },
    FETCH_TEAM_NAMES(){
        return axios.get('/api/user-teams/names/')
    },
    UPDATE_TEAM(id, patchData){
        return axios.patch(`/api/user-teams/${id}/`, patchData)
    },
    DELETE_TEAM(id){
        return axios.delete(`/api/user-teams/${id}/`)
    },
    FETCH_USERS_ROLES(){
        return axios.get(`/api/user-roles/`);
    },
    FETCH_USER_ROLE_TREE(structure){
        return axios.get(`/api/user-roles/?view=${structure}`);
    },
    SEARCH_TEAM(query){
        return axios.get(`api/user-teams/?`, {
            params: {
                query
            }
        })
    },
    GET_USER_ROLES() {
        return axios.get('api/user-roles/');
    },
    SEARCH_TEAMS(searchText){
        return axios.get(`/api/user-teams/?`, {
            params: {
                query: searchText,
            },
        }); 
    },
    FETCH_USERS(){
        return axios.get('/api/users/',{
            params:{
                only_internal:"true"
            }
        });
    },
    SEARCH_USERS(searchText, byName = false){
        return axios.get(`/api/users/?`, {
            params: {
                only_internal: "true",
                [byName ? "name" : "query"]: searchText,
            },
        });
    },
    CREATE_USERS(userData){
        return axios.post('/api/users/create/', userData);
    },
    FETCH_SUPER_ADMIN_USER(){
        return axios.get(`/api/users/?`, {
            params: {
                only_internal:"true",
                superadmin: "True",
            },
        });
    }, 
    PATCH_USER(userId, userPatchData){
        return axios.patch(`/api/users/${userId}/`, userPatchData);
    },
    DELETE_USER(userId){
        return axios.delete(`/api/users/${userId}/`);
    },
    FETCH_ROLES_PRICING(){
        return axios.get(`api/roles/pricing/`);
    },
    RESET_PASSWORD_FOR_USER(userEmailObj){
        return axios.post(`/api/user/reset-password/`, userEmailObj);
    },
    CHECK_IF_EMAIL_USED(userEmail){
        return axios.get(`api/users/exists/?`, {
            params: {
                email: userEmail,
            }
        });
    }
}