<template>
    <div class='parent-container'>

        <div>
        <GlobalDialog dialogTitle="Preview" :onClickClose="false" :dialogVisible.sync="openContractList" 
          @handleClose="closeDialog" :width="'750px'" :height="'calc(100vh - 300px)'" :isModal="false" :isModalAppend="false">
          <template v-if="selectedContracts.length" v-slot:body>
            <div
            >
                <ChangeOrderPreview v-if="openPreview" 
        :open.sync="openPreview"
        :editorType="editorType"
        :isContractInDesignLevel="isContractInDesignLevel"
        :includePaymentSchedule="includePaymentSchedule"
      />
            </div>
      
          </template>
          <template v-else v-slot:body>
            No contract selected!
          </template>
          <template v-slot:footer>
            <div class="footer">
              <el-button plain @click="backtoCustomContract">
                Back
              </el-button>
              <el-tooltip
                class="item"
                effect="dark"
                :content=tooltipMessageForShareButton
                placement="top"
                :disabled="!(isFileBeingPrepared || !selectedContracts.length)"
              >
                <span>
                  <el-button class="footer-btn" 
                    :loading="isFileBeingPrepared || !selectedContracts.length"
                    :disabled="isFileBeingPrepared || !selectedContracts.length" 
                    type="primary" 
                    @click="openMailDilaog"
                  >
                    Share
                  </el-button>
                </span>
              </el-tooltip>
  
            </div>
          </template>
        </GlobalDialog>
      </div>
      <!-- <ContractPreview v-if="openPreview" 
        :open.sync="openPreview"
        :editorType="editorType"
        :isContractInDesignLevel="isContractInDesignLevel"
        :includePaymentSchedule="includePaymentSchedule"
      /> -->
      <EditTemplateEditorDialog v-if="openMailTemplate" :defaultUser="leadMailID" :subject="templateSubject"
        :content="mailContent" :title="templateTitle" :dialogLoading="mailLoading"
        :handleClearEmailData="handleClearEmailData" :fileData="fileData" :handleSendInvoice="handleSendContract"
        :isEmailTemplateForContractManagement="true"
        :showCC="true"
        @emitFinalPayload="sendContractWithoutDocusign"
        :isModalAppend="false"
        />
  
      <ConfirmationForDocusignPopup 
        v-if="isConfirmationForDocusignPopupVisible"
        :isConfirmationForDocusignPopupVisible.sync="isConfirmationForDocusignPopupVisible"
        @proceedBtnFunc="proceedBtnFunc"
        :isModal="false"
      />
      <ClientInformationPopup 
        v-if="isClientInformationPopupVisible" 
        :isClientInformationPopupVisible.sync = "isClientInformationPopupVisible"
        :placeholder="selectedContracts"
        @sendBtnFunc="sendBtnFunc"
        :isModal="false"
      />
      <EditContractPopup 
        v-if="isEditContractPopupVisible" 
        :isEditContractPopupVisible.sync="isEditContractPopupVisible"
        :selectedContracts="selectedContracts" 
        @closeEditContractPopup="closeEditContractPopup"
        :isModal="false"
        />
      <StatusPopup 
        v-if="isStatusPopupVisible" 
        :isStatusPopupVisible.sync="isStatusPopupVisible"
        @checkStatus="checkStatus"
        />
    </div>
  </template>
  
  <script>
  import API from "@/services/api/";
  import GlobalDialog from "../../../commonComponents/GlobalDialog.vue";
  import ChangeOrderPreview from '../../../documentManagement/components/changeOrderPreview.vue';
  import EditTemplateEditorDialog from '../../../arkaOrders/components/emailTemplateEditorDialog.vue';
  import { mapState, mapActions } from "pinia";
  import { useLeadStore } from "../../../../stores/lead";
  import { useProjectStore } from "../../../../stores/project";
  import { useContractManagementStore } from '../../../../stores/contractManagement';
  import {fetchSasToken, getUserTokenFromLocalStorage, uploadFileToBlob, isUSFlagEnabled} from '../../../../utils'
  import { containerNameForContracts, BASE_URL_FOR_REPORT_IMAGES, SAVE_CONTRACTS_ENDPOINT } from "../../../../constants";
  import ConfirmationForDocusignPopup from './confirmationForDocusignPopup.vue';
  import ClientInformationPopup from './clientInformationPopup.vue';
  import StatusPopup from './statusPopup.vue';
  import EditContractPopup from './editContractPopup.vue';
  import { v4 } from 'uuid';
  import axios from 'axios';
  import { PDFDocument } from 'pdf-lib';
  
  export default {
      emits: ["handleClose", "update:changeContract"],
      components:{GlobalDialog,ChangeOrderPreview,EditTemplateEditorDialog,ConfirmationForDocusignPopup, ClientInformationPopup,StatusPopup, EditContractPopup},
      data() {
          return {
            // selectedDesign: null,
            selectedContracts:[],
            checkedContract:[],
            mailLoading:false,
            value: [],
            openContractList:false,
            selectedContatctList:[],
            contractsData: [],
            openPreview:false,
            openMailTemplate:false,
            organisationData:JSON.parse(localStorage.getItem("organisation"))||{},
            userData:JSON.parse(localStorage.getItem('user')),
            leadMailID:'',
            templateTitle:'Share Contract',
            mailContent:`Hi John Doe,\nThank you for choosing ${this.organisationData?.name}. Please refer to the sales contract attached below.\n Thanks, \n Arka Team \n Sales Executive` ,
            templateSubject:`Contract between party 1 and 2`,
            nextUrl: null,
            editorType:'',
            fileName:'',
            fileLink:null,
                    htmlContent:null,
            loading: false,
            isConfirmationForDocusignPopupVisible: false,
            isClientInformationPopupVisible: false,
            isStatusPopupVisible: false,
            isContractInDesignLevel: true,
            allAddedPlaceholders:[],
            keyValuePairForAddedPlaceholders:{},
            finalPDFLinks:[],
            contractWithLinkAndPlaceholders:[],
            isFileBeingPrepared:false,
            isEditContractPopupVisible: false,
            includePaymentSchedule: false,
            allAddedCustomPlaceholders:[],
                  allAddedNotCustomPlaceholders:[],
            counterForContractList:0,
            currentRunningCounter:0,
            componentKey:0,
          };
      },
      props:{
        changeContract:{
            type:Array,
            default:()=>[]
        },
        contractDesignId:{
          type:Number,
          default:null
        }
      },
      computed:{
        ...mapState(useLeadStore, {
          leadInfo: (state) => state,
        }),
        ...mapState(useProjectStore, {
          designs: "GET_DESIGNS_DETAILS",
        }),
        ...mapState(useContractManagementStore, {
                  CMStore: (state) => state
          }),
        fileData() {
          return this.CMStore.contractWithLinkAndPlaceholders.map((eachContract)=>{
                      let fileJson = {
                          "display_name" : eachContract.display_name,
                          "file_url" : eachContract.file_url,
                      }
                      return fileJson
                  })
          // return this.contractsData.filter(contract => this.selectedContracts.includes(contract.id));
        },
        selectedDesignDetail(){
          return this.designs.filter((design)=>{
            return design.id == this.CMStore.selectedDesignIdForPreview
          })[0] || []
        },
        tooltipMessageForShareButton(){
          if(this.isFileBeingPrepared){
            return "Please wait while we are preparing your document"
          }
          else if(!this.selectedContracts.length){
            return "Please select atleast 1 contract"
          }
          return ""
        },
        disablePreviewAndShareButton(){
          return (!this.CMStore.selectedDesignIdForPreview || !this.selectedContracts.length || !Number(this.selectedDesignDetail.versions.basic_cost))
        },
        tooltipMessageForPreview(){
          if(!this.CMStore.selectedDesignIdForPreview || !this.selectedContracts.length){
            return "Select design and contract"
          }
          else if(!Number(this.selectedDesignDetail.versions.basic_cost)){
            return "This design doesn't have pricing added. Please add pricing to view contract"
          }
          return ""
        }
  
    },
    mounted() {
      this.CMStore.selectedDesignIdForPreview = null;
      this.componentKey++;
      this.fetchAllContractTemplates();
      this.leadMailID = this.leadInfo?.email||this.leadInfo?.lead_details?.email;
      this.openPreview = false;
      this.mailContent = `Hi ${this.leadInfo?.name},\nThank you for choosing ${this.organisationData?.name}. Please refer to the sales contract attached below.\n\n \n Thanks, \n ${this.organisationData?.name} Team \n ${this.userData?.designation || ''}`;
      this.templateSubject = `Contract between ${this.organisationData?.name} and ${this.leadInfo?.name||this.leadInfo?.lead_details?.name}`;
      this.SET_SELECTED_DESIGN_ID(this.contractDesignId)

    },
    methods: {
      ...mapActions(useContractManagementStore, [
        "SET_HTML_CONTENT",
              "SET_FILE_LINK",
              "UPDATE_PLACEHOLDERS_ARRAY",
              "SET_KEY_VALUE_PLACEHOLDER",
        "SET_KEY_VALUE_FOR_ADDED_PLACEHOLDER",
        "SET_CONTRACT_WITH_LINK_AND_PLACEHOLDERS",
        "SET_SELECTED_DESIGN_ID"
      ]),
  
      fetchSasToken,
      isUSFlagEnabled,
      previewAndShare(){
        if(this.areCustomPlaceholdersPresent()){
          this.isEditContractPopupVisible = true;
        }
        else{
          this.openContractList = true;
        }
      },
      areCustomPlaceholdersPresent(){
        let indexWithCustomPH
        for(let i=0;i<this.selectedContracts.length;i++){
          let placeholders = this.selectedContracts[i]?.additional_details?.placeholders;
          if(!Array.isArray(placeholders)){
            continue;
          }
          indexWithCustomPH =  placeholders.findIndex((placeholder)=>{
            return placeholder.placeholder_type =='custom_placeholders';
          })
          if(indexWithCustomPH>=0){
            break;
          }
        }
        if(indexWithCustomPH>=0){
          return true;
        }
        return false;
      },
      async sendContractWithoutDocusign(payload){
        try{
          const response = await API.CONTRACT_TEMPLATES.SEND_CONTRACT(payload);
          this.$message({
            showClose: true,
            message: "Contract has been sent successfully.",
            type: "success",
            center: true,
          });
          this.CMStore.selectedDesignIdForPreview = null;
          this.componentKey++;
          this.selectedContracts = [];
          this.openMailTemplate = false;
          this.openContractList = false;
this.$emit('handleClose')  
        }
        catch(e){
            this.isEditContractPopupVisible=false;
          console.error('error ', e);
          this.$message({
            showClose: true,
            message: "Error! There is some issue.",
            type: "error",
            center: true,
          });
        }
      },
      proceedBtnFunc(data) {
        if(data.selectedOption == 'Docusign') {
          this.isClientInformationPopupVisible = true;
        } else {
          this.openMailTemplate = true;
        }
      },
      sendBtnFunc() {
        this.isClientInformationPopupVisible = false;
        this.openContractList = false;
        this.isStatusPopupVisible = true;
      },
      checkStatus(wantToCheckStatus){
        this.isStatusPopupVisible = false;
        this.CMStore.selectedDesignIdForPreview = null;
        this.componentKey++;
        this.selectedContracts = [];
        if(wantToCheckStatus){
          this.CMStore.isDocumentTabsOpenCounter ++;
        }
      },
      async fetchAllContractTemplates() {
        try {
            this.isLoading = true;
            let response;
            if (!this.nextUrl) {
                response = await API.CONTRACT_TEMPLATES.FETCH_ALL_CONTRACT_TEMPLATES("is_default");
            } else {
                response = await API.CONTRACT_TEMPLATES.LOAD_MORE_CONTRACT_TEMPLATES(this.nextUrl);
            }
            // Append the results to the existing data
            this.contractsData = this.contractsData.concat(response.data.results);
            // Update nextUrl for the next iteration
            this.nextUrl = response.data.next;
            this.isLoading = false;
        } catch (e) {
            this.isLoading = false;
            console.error(e);
        }
      },
      handleLoadMore() {
          if (this.nextUrl) {
              this.fetchAllContractTemplates();
          }
      },
      removeItem() {
      },
      handleOpenContractList() {
        this.openContractList = true;
        this.handlePreview(this.selectedContracts[0])
      },
      closeDialog() {
        this.openContractList = false;
        this.isEditContractPopupVisible=false;
      },
      handleRemoveContract(contractToBeRemoved,index) {
        let contractToBeRemovedIndex = this.selectedContracts.findIndex((contract)=>{
          return contract.id == contractToBeRemoved.id
        })
        if(contractToBeRemovedIndex>=0){
          this.selectedContracts.splice(contractToBeRemovedIndex,1)
        }
        this.contractWithLinkAndPlaceholders.splice(index,1);
        this.SET_CONTRACT_WITH_LINK_AND_PLACEHOLDERS(this.contractWithLinkAndPlaceholders)
      },
      segregateCustomAndOtherPlaceholder(allAddedPlaceholders){
        this.allAddedCustomPlaceholders = allAddedPlaceholders.filter((placeholder)=>{
          return placeholder.placeholder_type =="custom_placeholders"
        })
        this.allAddedNotCustomPlaceholders = allAddedPlaceholders.filter((placeholder)=>{
          return placeholder.placeholder_type !="custom_placeholders"
        })
      },
      async handlePreview(contract) {
        this.editorType = contract.template_type
        this.fileName =  contract.file_name
        this.contractId = contract.id
        this.allAddedPlaceholders = contract.additional_details.placeholders
        this.segregateCustomAndOtherPlaceholder(this.allAddedPlaceholders)
        this.includePaymentSchedule = contract.include_payment_schedule;
        this.UPDATE_PLACEHOLDERS_ARRAY(this.allAddedPlaceholders || [])
        await this.fetchAddedPlaceholdersWithActualValues(this.allAddedPlaceholders)
        await this.fetchDocumentFromBlobStorage()
        this.openPreview = true;
      },
      async fetchAddedPlaceholdersWithActualValues(){
        let addedNotCustomPlaceholdersIdentifier = [];
        let addedCustomPlaceholdersIdentifier = [];
        this.allAddedNotCustomPlaceholders.forEach((addedPlaceholder)=>{
          addedNotCustomPlaceholdersIdentifier.push(addedPlaceholder.placeholder_key)
        })
        this.allAddedCustomPlaceholders.forEach((addedPlaceholder)=>{
          addedCustomPlaceholdersIdentifier.push(addedPlaceholder.placeholder_key)
        })
        let payload = {
          "placeholders":[...addedNotCustomPlaceholdersIdentifier],
          "design_id": this.CMStore.selectedDesignIdForPreview,
          "contract_id":this.contractId,
          "custom_placeholders":[...addedCustomPlaceholdersIdentifier]
        }
        const response = await API.CONTRACT_TEMPLATES.FETCH_ADDED_PLACEHOLDERS_WITH_ACTUAL_VALUES(payload);
        this.prepareKeyValuePairForAddedPlaceholders(response.data)
      },
      prepareKeyValuePairForAddedPlaceholders(allPlaceholdersTypes){
        allPlaceholdersTypes.forEach((eachPlaceholderType)=>{
          let placeholdersArray = eachPlaceholderType.placeholders
          let placeholderCategory = eachPlaceholderType.identifier
          placeholdersArray.forEach((eachPlaceholder)=>{
            eachPlaceholder.category = placeholderCategory
            this.keyValuePairForAddedPlaceholders[eachPlaceholder?.identifier] = eachPlaceholder
          })
        })
        this.SET_KEY_VALUE_FOR_ADDED_PLACEHOLDER(this.keyValuePairForAddedPlaceholders)
      },
      async fetchDocumentFromBlobStorage(){
        let sasToken =  await fetchSasToken(containerNameForContracts)
        this.fileLink = `https://downloadstsl.blob.core.windows.net/${containerNameForContracts}/${this.fileName}?${sasToken}`
        const response = await fetch(this.fileLink);
        this.htmlContent = await response.text();
        this.SET_HTML_CONTENT(this.htmlContent)
        this.SET_FILE_LINK(this.fileLink)
      },
      openMailDilaog() {
        let isDocuSignIntegrated = JSON.parse(localStorage.getItem("isDocuSignIntegrated")) || false;
        if(isDocuSignIntegrated) {
          this.openConfirmationForDocusignPopup()
        } else {
          this.openMailTemplate = true;
        }
      },
      openConfirmationForDocusignPopup() {
        // this.closeDialog();
        this.isConfirmationForDocusignPopupVisible = true;
      },
      handleShare() {
  
      },
      handleChange(value) {
        //   this.selectedContracts = value;
      },
      toggleSelection(value) {
        const index = this.selectedContracts.indexOf(value);
        if (index > -1) {
          this.selectedContracts.splice(index, 1);
        } else {
          this.selectedContracts.push(value);
        }
      },
      isSelected(value) {
        return this.selectedContracts.includes(value);
      },
      handleClearEmailData() {
        this.openMailTemplate = false;
  
      },
      handleSendContract(users, subject, content, documents, ccUsers) {
        this.mailLoading = false;
        this.openMailTemplate = false;
      },
      preparePDFLinks(){
        let isUSFlagEnabledValue = isUSFlagEnabled()
        this.counterForContractList++;
        this.currentRunningCounter = this.counterForContractList;
        this.isFileBeingPrepared = true;
        this.contractWithLinkAndPlaceholders = [];
        let token = getUserTokenFromLocalStorage();
        let selectedDesign = this.CMStore.selectedDesignIdForPreview;
        let baseUrl;
        let baseURLForPaymentSchedule = `${BASE_URL_FOR_REPORT_IMAGES}paymentSchedulePuppeteer/${selectedDesign}/${token}/${isUSFlagEnabledValue}`
        let arrayOfContractsLink = []
        let promiseArray=[]
        let psLink=''
        this.selectedContracts.forEach(async (contract,index)=>{
          let type = contract.template_type
          let id = contract.id;
          if(type=='text'){
            baseUrl =  `${BASE_URL_FOR_REPORT_IMAGES}paginatedContentForPuppeteer/${id}/${token}/${selectedDesign}/${isUSFlagEnabledValue}`;
          }
          else if(type=='pdf'){ 
            baseUrl =  `${BASE_URL_FOR_REPORT_IMAGES}pdfPuppeteer/${id}/${token}/${selectedDesign}`;
          }
          let pdfLink =  this.returnPuppeteerLink(baseUrl,type)
          promiseArray.push(pdfLink)
        })
        promiseArray.push(this.returnPuppeteerLink(baseURLForPaymentSchedule,'text'))
        Promise.all(promiseArray).then((results)=>{
          this.selectedContracts.forEach((contract,index)=>{
            let json = {
              "link" : results[index],
              "contractId": contract.id,
              "type":  contract.template_type,
              "display_name" : contract.title + '.pdf',
              "include_payment_schedule" : contract.include_payment_schedule
            }
            arrayOfContractsLink.push(json)
          })
          psLink = results[results.length-1]
          this.mergeContractsWithPaymentSchedule(psLink,[... arrayOfContractsLink])
        })
        .catch(error => {
          this.$message({
            showClose: true,
            message: "Error in generating files",
            type: "error",
            center: true
          });
          console.error('One or more promises failed:', error);
        });
      },
      async mergeContractsWithPaymentSchedule(psLink,arrayOfContractsLink){
        let mergedLinkArray =[]
        for(let contractJson of arrayOfContractsLink ){
          let mergedLink;
          let contractLink = contractJson.link
          if(contractJson.include_payment_schedule && this.selectedDesignDetail.pricing[0].payment_method_type!='loan'){
            mergedLink = await this.mergeWithFinancialPDF(contractLink,psLink)
            contractJson.finalLink = mergedLink
          }
          else{
            contractJson.finalLink = contractLink
          }
        }
        this.finalPDFLinks = [... arrayOfContractsLink]
        this.preparePDFLinksAndPlaceholders()
      },
      preparePDFLinksAndPlaceholders(){
        this.finalPDFLinks.forEach(eachContract=>{
          let contractId = eachContract.contractId
          let currentContract = this.selectedContracts.filter((contract)=>contract.id == contractId)[0]
          if(!currentContract.additional_details.placeholders){
            currentContract.additional_details.placeholders=[]
          }
          let docusignPlaceholders = currentContract.additional_details.placeholders.filter(placeholder=>placeholder.placeholder_type == 'docusign')
          docusignPlaceholders.forEach((placeholder)=>{
            if(eachContract.type=='text'){
              placeholder.anchor_string = `{${placeholder.placeholder_key}}`  // this extra key is needed in case of anchor_string
            } else{
              placeholder.x_position = Number(String(placeholder.x_position).replace("px",""))
              placeholder.y_position = Number(String(placeholder.y_position).replace("px",""))
            }
            placeholder.placeholder = placeholder.placeholder_key
            placeholder.page_number = placeholder.page_no
          })
          let eachContractJson = {
            "file_url" : eachContract.finalLink,
            "id" : contractId,
            "placeholders" : [... docusignPlaceholders],
            "display_name" : eachContract.display_name,
          }
          this.contractWithLinkAndPlaceholders.push(eachContractJson)
        })
        this.SET_CONTRACT_WITH_LINK_AND_PLACEHOLDERS(this.contractWithLinkAndPlaceholders)
        this.isFileBeingPrepared = false;
      },
      async mergeWithFinancialPDF(contractLink,psLink){
          let sasToken1 =  await fetchSasToken('dummy')
                  const pdfUrls = [
            contractLink,
            psLink
                  ];
                  try {
                      const pdfBuffers = await Promise.all(pdfUrls.map(url => this.fetchPDF(url)));
                      const mergedPdfBuffer = await this.mergePDFs(pdfBuffers);
                      const blob = new Blob([mergedPdfBuffer], { type: "application/pdf" });
                      // saveAs(blob, `merged.pdf`);
                      let files=[];
                      files.push(blob)
                      const uuid =  v4();
                      await uploadFileToBlob(files,uuid,containerNameForContracts);
            let sasToken =  await fetchSasToken(containerNameForContracts)
            return `https://downloadstsl.blob.core.windows.net/${containerNameForContracts}/${uuid}?${sasToken}`
                  } catch (error) {
                          console.error('Error merging or uploading PDFs:', error);
                  }
      },
      async fetchPDF(url) {
        try {
          const response = await fetch(url, {
              method: 'GET',
              headers: {}, // No Authorization header
          });
          if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.arrayBuffer();  // Wait for the arrayBuffer to resolve
          return data;  // Return the data correctly
        } catch (error) {
          console.error('There was a problem with the fetch operation:', error);
          throw error;  // Rethrow the error to be caught in the calling function
        }
      },
      async mergePDFs(pdfBuffers) {
        const mergedPdf = await PDFDocument.create();
        for (const pdfBuffer of pdfBuffers) {
          const pdf = await PDFDocument.load(pdfBuffer);
          const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
          copiedPages.forEach((page) => {
            mergedPdf.addPage(page);
          });
        }
        const mergedPdfBuffer = await mergedPdf.save();
        return mergedPdfBuffer;
      },
      base64ToBlob(base64, mimeType) {
        const byteCharacters = atob(base64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        return new Blob([byteArray], { type: mimeType });
      },
      async returnPuppeteerLink(baseUrl,type){
        let payload = {
          "base_url": baseUrl,
          "scale": 1.33,
          "format" : type=='text' ? (isUSFlagEnabled()? 'Letter' : "A4") : 'A4',
          'canvasWidth' :   612,
          'canvasHeight' : 792 ,
          "reference_id": v4(),
        }
        try{
          let response = await fetch(SAVE_CONTRACTS_ENDPOINT, {
            method: 'POST',
            body: JSON.stringify(payload),
          })
          let respText = await response.text();
          if (!response.ok) {
            throw respText
          }
          return respText
        }
        catch(e){
  
        }
      },
      closeEditContractPopup(){
        this.isEditContractPopupVisible = false;
        this.handleOpenContractList()
      },
      backtoCustomContract(){
        this.openContractList=false;
        this.openPreview=false;
       this.isEditContractPopupVisible = true;
      }
  
    },
  
    watch:{
      openContractList:{
        handler(val){
          if(val){
            this.counterForContractList++;
            if(!this.isFileBeingPrepared){
              this.preparePDFLinks()
            }
          }else{
            this.$emit("update:changeContract",[])
          }
        }
      },
      isFileBeingPrepared:{
        handler(val){
          // i.e when isFileBeingPrepared turned false after completing previous request and if the user once again opened the popup so counterForContractList will be increased
          if(!val && this.openContractList &&  this.counterForContractList > this.currentRunningCounter){ 
            this.preparePDFLinks()
          }
        }
      },
      selectedContracts:{
        handler(val){
          if(!val.length){
            this.isFileBeingPrepared = false;
          }
        }
      },
      changeContract:{
        immediate:true,
        handler(val){
            if(val.length){
                this.SET_SELECTED_DESIGN_ID(468092)
                this.isEditContractPopupVisible=true;
                this.selectedContracts=val;
            }
        }

      }
    }
  
  };
  </script>
  
  <style scoped>
  .parent-container {
    padding-top: 33px;
    position: relative;
    height: 100%;
  }
  
  .selection-container {
    margin-bottom: 16px;
    width: 320px
  }
  
  .contract-list-item {
    display: flex;
    justify-content: space-between;
    padding: 0px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 16px;
  }
  
  .contract-name {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
  }
  
  .close-icon {
    padding-right: 12px;
    font-size: 7px;
  }
  
  .footer {
    padding: 16px;
    display: flex;
    justify-content: end;
    gap: 16px;
  }
  
  /* .el-checkbox__input{
    transform: scale(1.2);
  } */
  
  .el-checkbox__inner::after {
    border-width: 2px;
    top: 0;
  }
  
  .el-checkbox__input.is-checked .el-checkbox__inner {
    border: none !important;
    background-color: #1C3366;
  }
  
  .el-checkbox__input.is-checked+.el-checkbox__label {
    font-size: 14px;
    font-weight: 600;
    color: #1C3366;
  }
  
  .el-tag.el-tag--info {
    background-color: #E8EDF2;
    color: black;
    border-radius: 50px;
  }
  
  .el-select .el-tag__close.el-icon-close::before {
    display: block;
    transform: translate(0, 0.5px);
    color: black;
    font-size: 14px;
    background: #E8EDF2;
  
  }
  
  .el-checkbox__inner {
    width: 18px !important;
    height: 18px;
    border-color: #777777;
    border-radius: 2px;
  }
  
  .el-checkbox__inner::after {
    margin: 3px;
  }</style>